
import { defineComponent } from 'vue';
import { XCircleIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateSpin.vue';
import { Field, ErrorMessage } from 'vee-validate';

export default defineComponent({
  components: {
    Spin,
    XCircleIcon,
    ErrorMessage,
    Field,
  },
  mixins: [BaseComponent],
  props: {
    templates: {
      type: Array as any,
      required: true,
    },
  },
  created() {
    this.templatesFiltered = this.templates;
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
  },
  data() {
    return {
      allowSelect: [] as any,
      pickup: '',
      delivery: '',
      templateName: '',
      trailerTypeId: '',
      commodity: '',
      templateFilterQuery: '',
      templatesFiltered: [] as any,
      scrollY: 0,
    };
  },
  computed: {
    collapsedSidebar() {
      return this.$store.getters['UserStore/getCollapsedSidebar'];
    },
    isFiltering(): any {
      const { pickup, delivery, trailerTypeId, commodity, templateName } = this;
      return !!pickup || !!delivery || !!trailerTypeId || !!commodity || !!templateName;
    },
    showSticky(): boolean {
      return this.scrollY > 0;
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
  },
  methods: {
    clearFilters() {
      this.pickup = '';
      this.delivery = '';
      this.trailerTypeId = '';
      this.commodity = '';
      this.templateFilterQuery = '';
      this.templateName = '';
      this.submitSearch();
    },
    clearInput(data) {
      this[data] = '';
      this.submitSearch();
    },
    closeModal(id, idx) {
      // this.$emit('close');
      if (this.allowSelect?.length === 0) {
        this.allowSelect[idx] = true;
        this.$emit('closeModal', 'fill', id);
      } else if (this.allowSelect[idx]) {
        this.allowSelect[idx] = true;
        this.$emit('closeModal', 'fill', id);
      }
    },
    commodityMastersString(commodityMasters: any) {

      const commodityString = commodityMasters.map((item) => {
        return `${item.commodityName} ${this.formatWeight(item.weight)}`;
      }).join(', ');

      return `${commodityString}`;
    },
    deliveriesQuantity(data: any) {
      let sum = 1;
      data.stops.forEach((stop: any) => {
        if (stop.type === 'DELIVERY') {
          sum++;
        }
      });
      return sum;
    },
    editTemplate(id: any, newName: any) {

      if (newName === '' || newName === undefined) {
        BaseComponent.methods?.showToastError(this.$t('new-template-empty'));
      }
      else {
        this.$store
          .dispatch('LoadsStore/putSaveLoads', {
            id: id,
            templateName: newName,
          })
          .then(() => {

            // Fetch templates
            const query = {
              page: 1,
            };
            this.$store.dispatch('LoadsStore/getSaveLoads', query);
            BaseComponent.methods?.showToastSuccess(this.$t('message-load-template-modified'));
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      }
    },
    picksQuantity(data: any) {
      let sum = 1;
      data.stops.forEach((stop: any) => {
        if (stop.type === 'PICKUP') {
          sum++;
        }
      });
      return sum;
    },
    setFilter() {
      let query = {
        origin: this.pickup,
        destiny: this.delivery,
        vehicle: this.trailerTypeId,
        commodity: this.commodity,
        templateName: this.templateName,
      };
      this.templateFilterQuery = Object.fromEntries(
        Object.entries(query).filter(
          ([key, value]) => value !== undefined && value !== null && value !== '',
        ),
      ) as any;
    },
    async submitSearch() {
      this.setFilter();
      // Llamada templates usando el filtro
      await this.$store
        .dispatch('LoadsStore/getSaveLoadsSearch', this.templateFilterQuery)
        .then((res) => {
          this.templatesFiltered = this.$store.getters['LoadsStore/getSaveLoadsSearch'];
        })
        .catch((err) => console.log(err));
    },
  },
});
