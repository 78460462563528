import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  chatId: null,
  conversations: [],
  currentConversation: null,
  currentMessages: [],
  laneForLoad: null,
  rankingForChat: null,
  totalUnreadChats: 0,
  unreadMessages: [],
  userForChat: null,
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
