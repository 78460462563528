import { createI18n } from 'vue-i18n';
import { watch } from 'vue';

import * as en from '@/resources/locales/en.json';
import * as es from '@/resources/locales/es.json';

const userLocale = localStorage.getItem('userLocale') || navigator.language.substring(0, 2) || 'en';
localStorage.setItem('userLocale', userLocale);

const i18n = createI18n({
  legacy: false,
  locale: userLocale,
  fallbackLocale: 'en',
  messages: {
    en: en,
    es: es,
  },
});

export default i18n;
