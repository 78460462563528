import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_header_search = _resolveComponent("plan-header-search")!

  return (_openBlock(), _createBlock(_component_plan_header_search, {
    onChangeQuery: _ctx.changeInputStatus,
    placeholder: _ctx.$t('search-id') + '/PO',
    "old-query": _ctx.inputValue,
    "bg-color": _ctx.bgColor
  }, null, 8, ["onChangeQuery", "placeholder", "old-query", "bg-color"]))
}