import addLanesRFP from '@/modules/rfp/_components/AddLanes.vue';
import CreationLanesMode from '@/modules/rfp/lanesCreationType/CreationLanesMode.vue';
import DayDetail from '@/modules/planning/calendar/DayDetail.vue';
import LaneDetail from '@/modules/rfp/lanesDetail/LaneDetailLoads.vue';
import LanesView from '@/modules/rfq/LanesView.vue';
import NewLoad from '@/modules/rfp/lanesCreateLoads/NewLoads.vue';
import Planification from '@/modules/planning/plannification/Planification.vue';
import ProjectLaneDetail from '@/modules/rfp/lanesDetail/ProjectLaneDetail.vue';
import RFPLanes from '@/modules/rfp/RFPLanes.vue';
import PlanningRFP from '@/modules/rfp/PlanningRFP.vue';

const RFPRouter = [
  {
    path: 'lanesRFP',
    name: 'lanesRFP',
    component: LanesView,
    redirect: { name: 'lanesRFPList' },
    children: [
      {
        path: '',
        name: 'lanesRFPList',
        component: RFPLanes,
        meta: {
          activeButton: 'lanesRfp',
        },
      },
      {
        path: 'day/:date/:projectId?/:laneId?',
        name: 'RFP-laneDay',
        component: DayDetail,
        props: true,
        children: [
          {
            path: ':id',
            name: 'RFP-dayDetailId',
            component: DayDetail,
            props: true,
          },
        ],
      },
      {
        path: ':projectId',
        name: 'ProjectLaneDetail',
        component: ProjectLaneDetail,
        meta: {
          activeButton: 'lanesRfp',
        },
        props: true,
        children: [],
      },
      {
        path: ':projectId/creation',
        name: 'lanesRFPCreation',
        component: CreationLanesMode,
        meta: {
          activeButton: 'lanesRfp',
        },
        props: true,
        children: [],
      },
      {
        path: ':projectId/creation/:laneId/newLoad',
        name: 'NewLoadRFP',
        component: NewLoad,
        props: true,
        meta: {
          activeButton: 'lanesRfp',
        },
      },
      {
        path: ':projectId/creation/addLanesRFP',
        name: 'addLanesRFP',
        component: addLanesRFP,
        props: true,
        meta: {
          activeButton: 'lanesRfp',
        },
      },
      {
        path: 'PlanningRFP/:laneId',
        name: 'PlanningRFP',
        component: PlanningRFP,
      },
      {
        path: 'laneDetail/:projectId/:laneId',
        name: 'RFP-laneDetail',
        component: LaneDetail,
        props: true,
        children: [
          {
            path: 'service/:id',
            name: 'RFP-laneService',
            component: LaneDetail,
            props: true,
          },
        ],
      },
    ],
  },
];

export default RFPRouter;
