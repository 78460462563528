import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between xl:container mb-10" }
const _hoisted_2 = { class: "text-corp font-bold text-3xl md:text-4xl" }
const _hoisted_3 = {
  class: "flex flex-wrap gap-4 mt-4",
  style: {"max-width":"1200px","margin":"0 auto"}
}
const _hoisted_4 = { class: "min-w-[350px] relative" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { class: "w-40" }
const _hoisted_7 = { class: "w-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!
  const _component_DatePickerComponent = _resolveComponent("DatePickerComponent")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["mb-7 bg-white w-full p-5 md:p-10 sticky top-0 z-20", { 'box-shadow': _ctx.showSticky }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('rfp-lanes')), 1),
      (!_ctx.isShipperViewer)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "bg-corp flex px-4 md:px-8 py-2 ml-3 w-fit rounded-md cursor-pointer text-white border border-corp text-center hover:opacity-70",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.displayModal && _ctx.displayModal(...args)))
          }, _toDisplayString(_ctx.$t('new-project')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.name !== '')
          ? (_openBlock(), _createBlock(_component_XCircleIcon, {
              key: 0,
              class: "w-4 h-4 text-corp absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearInput('name')))
            }))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          id: "search",
          type: "text",
          class: _normalizeClass(["h-full border border-gray-300 text-gray-950 placeholder:text-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2.5 truncate", { 'bg-white': _ctx.name, 'bg-gray-50': !_ctx.name }]),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.name) = $event)),
          placeholder: _ctx.$t('project-name'),
          onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submitSearch && _ctx.submitSearch(...args)))
        }, null, 42, _hoisted_5), [
          [_vModelText, _ctx.name]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_DatePickerComponent, {
          modelValue: _ctx.startDate,
          "onUpdate:modelValue": [
            _cache[4] || (_cache[4] = ($event: any) => ((_ctx.startDate) = $event)),
            _ctx.submitSearch
          ],
          customClass: 'text-gray-950 bg-gray-50 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full',
          iconClear: true,
          minDate: '',
          maxDate: _ctx.endDate ? _ctx.endDate : '',
          placeholder: _ctx.$t('start-date')
        }, null, 8, ["modelValue", "maxDate", "placeholder", "onUpdate:modelValue"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_DatePickerComponent, {
          modelValue: _ctx.endDate,
          "onUpdate:modelValue": [
            _cache[5] || (_cache[5] = ($event: any) => ((_ctx.endDate) = $event)),
            _ctx.submitSearch
          ],
          customClass: 'text-gray-950 text-sm bg-gray-50 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full',
          iconClear: true,
          minDate: _ctx.startDate,
          placeholder: _ctx.$t('end-date')
        }, null, 8, ["modelValue", "minDate", "placeholder", "onUpdate:modelValue"])
      ])
    ])
  ], 2))
}