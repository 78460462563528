
import { defineComponent, nextTick, onMounted, onUnmounted, ref, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PlanCarrierSizeCalculator',
  setup () {
    const store = useStore()
    const windowWidth = ref(window.innerWidth)

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const resizeWindow = () => {
      windowWidth.value = window.innerWidth
      store.commit('CarrierStore/setActualWidth', windowWidth.value)
    }

    resizeWindow()

    onMounted(() => {
      window.addEventListener('resize', resizeWindow);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeWindow);
    });

    return {
      actualWidth,
      windowWidth
    }
  }
})
