export default {
  getDemoRequestData(state: any): any {
    return state.dataDemoRequest;
  },
  getDemoRequestDataForm(state: any): any {
    return state.dataDemoRequestForm;
  },
  getDemoRequestsDataTotal(state: any): any {
    return state.dataDemoRequestTotals;
  },
  getDemoRequestsTotals(state: any): number {
    return state.demoRequestsTotals;
  },
  getIsWelcomeOpen(state: any): any {
    return state.isWelcomeOpen;
  },
};
