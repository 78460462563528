
import { defineComponent, computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import i18n from '@/resources/locales';
import * as Routing from '@/router/Routing';
import { Form, Field, ErrorMessage } from 'vee-validate';

import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { formatPriceDecimals } from '@/utils/formatString';
// Mixins
import CountryMixins from '@/services/country/_mixins';

export default defineComponent({
  name: 'SpecialRequirementsModal',
  components: { Field, ErrorMessage, PlanDefaultModal, PlanButton },
  mixins: [CountryMixins],
  emits: ['close'],
  props: {
    load: Object as any,
    showModal: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const $t = i18n.global.t;
    const permisions = ref(props?.load?.specialRequirement ?? (null as any));

    onMounted(() => {
      permisions.value = deepClone(props?.load?.specialRequirement);
    });

    const accept = () => {
      emit('close', false, null, null, permisions.value);
    };

    const close = () => {
      emit('close', false);
    };

    const deepClone = (obj) => {
      return JSON.parse(JSON.stringify(obj));
    };

    return {
      accept,
      close,
      formatPriceDecimals,
      permisions,
    };
  },
});
