
import { defineComponent } from 'vue';
import isEqual from 'lodash/isEqual';
import { CheckIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import SkeletonTableGeneric from '@/components/skeletons/GenericTableSkeleton.vue';

export default defineComponent({
  name: 'LoadQuoteStepThree',
  components: {
    CheckIcon,
    SkeletonTableGeneric,
  },
  mixins: [BaseComponent],
  props: {
    alpineLoadId: {
      type: Number,
    },
    load: {
      type: Object as () => any,
    },
    currentView: {
      type: String,
    },
  },
  data() {
    return {
      loadCreatedId: 0,
      alpineTypeEnum: {
        ALPINE_DENSITY: 'Density',
        ALPINE_NMFC: 'NMFC',
      },
      acceptedQuoteId: null,
      creatingLoad: false,
      companySaved: [] as any[],
      previousLoad: null as any,
      isLoadingQuotes: false,
    };
  },
  updated() {
    if (this.load.freight.length > 0 && this.currentView === 'step-three') {
      if (!this.previousLoad || !this.isPreviousLoadEqualToCurrent) {
        this.previousLoad = this.load;
        this.getQuotes();
      }
    }
  },
  computed: {
    acceptedQuote(): any {
      return this.acceptedQuoteId;
    },
    isPreviousLoadEqualToCurrent(): any {
      return (
        isEqual(this.load?.freight, this.previousLoad?.freight) &&
        isEqual(this.load?.stops, this.previousLoad?.stops)
      );
    },
    quotes(): any {
      return this.$store.getters['LoadsStore/ltlQuotes'];
    },
  },
  methods: {
    async acceptQuote(quoteId) {
      if (this.acceptedQuoteId) {
        return;
      }

      this.acceptedQuoteId = quoteId;
      try {
        await this.createLoad();
        this.$store.dispatch('ShipmentStore/acceptLTLQuote', quoteId).then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('quotation-accepted'));
          setTimeout(() => {
            this.$store.dispatch('LoadsStore/loadId', this.loadCreatedId);
            this.$store.dispatch('LoadsStore/showModalQuoteSended', true);
            this.$router.push('/planning');
          }, 2000);
        });
      } catch (err) {
        this.acceptedQuoteId = null;
        console.error(err);
      }
    },
    async createLoad() {
      try {
        await this.$store
          .dispatch('LoadsStore/createLoadLTL', {
            load: this.load,
          })
          .then((response) => {
            if (response) {
              this.loadCreatedId = response.id;
            }
          })
          .finally(() => {
            this.creatingLoad = false;
          });

        BaseComponent.methods?.showToastSuccess(this.$t('load-created-correctly'));
      } catch (err: any) {
        BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        throw err;
      }
    },
    async getQuotes() {
      this.isLoadingQuotes = true;
      await this.$store
        .dispatch('LoadsStore/getLTLQuotes', this.load)
        .then((response) => {
          this.$emit('setLoadId', response[0].alpineLoadId);
        })
        .catch((err) => {
          // BaseComponent.methods?.showToastError(this.$t('message-error-getting-quotes'));
        })
        .finally(() => {
          // this.allowed = true;
          this.isLoadingQuotes = false;
        });
    },
    isSelected(contactId: any) {
      return this.companySaved.some((item) => item.id === contactId);
    },
    removeFromArray(id) {
      this.companySaved = this.companySaved.filter((item) => item.id !== id);
    },
    selectContact(id: any) {
      if (this.acceptedQuoteId) {
        return;
      }

      if (!this.companySaved.some((item) => item.id === id)) {
        this.companySaved.push({ id: id });
      }
    },
    async submit() {
      try {
        this.creatingLoad = true;
        await this.createLoad();
        this.$store
          .dispatch('ShipmentStore/saveShipmentBids', { shipmentBids: this.companySaved })
          .then(() => {
            this.creatingLoad = true;
            BaseComponent.methods?.showToastSuccess(this.$t('message-load-saved-correctly'));
            setTimeout(() => {
              this.$store.dispatch('LoadsStore/loadId', this.loadCreatedId);
              this.$store.dispatch('LoadsStore/showModalQuoteSended', true);
              this.$router.push('/planning');
            }, 2000);
          });
      } catch (err) {
        this.acceptedQuoteId = null;
        console.error(err);
      }
    },
  },
});
