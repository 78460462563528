import globalAxios from 'axios';

const getEntryNotifications = async () => {
  const response = await globalAxios.get('/entry_notifications');
  return Promise.resolve(response.data);
};

export default {
  getEntryNotifications,
};
