import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "font-bold text-center md:text-2xl" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { class: "flex justify-center space-x-2" }
const _hoisted_4 = { class: "mt-5 flex flex-wrap sm:flex-nowrap sm:space-x-10 space-y-4 sm:space-y-0 w-full" }
const _hoisted_5 = { class: "flex flex-col w-full sm:w-3/5 md:text-sm" }
const _hoisted_6 = {
  for: "minTemp",
  class: "mb-1 text-bold"
}
const _hoisted_7 = { class: "flex flex-col w-full sm:w-3/5 md:text-sm" }
const _hoisted_8 = {
  for: "maxTemp",
  class: "mb-1 text-bold"
}
const _hoisted_9 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Spin = _resolveComponent("Spin")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    show: _ctx.showModal,
    modalSize: "small",
    onCallbackClose: _ctx.onClickClose
  }, {
    title: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('add-temperature')), 1)
    ]),
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Form, {
            ref: "form",
            onSubmit: _ctx.sendTemperature
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('min-temp', { unit: _ctx.currentCountryTemperatureUnit })), 1),
                  _createVNode(_component_Field, {
                    modelValue: _ctx.minTemp,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.minTemp) = $event)),
                    type: "number",
                    name: "minTemp",
                    rules: "required|max_value:2147483647",
                    id: "minTemp",
                    class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex p-2.5",
                    onKeyup: _withKeys(_ctx.sendTemperature, ["enter"])
                  }, null, 8, ["modelValue", "onKeyup"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "minTemp",
                    class: "text-rose-500"
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('max-temp', { unit: _ctx.currentCountryTemperatureUnit })), 1),
                  _createVNode(_component_Field, {
                    id: "maxTemp",
                    name: "maxTemp",
                    modelValue: _ctx.maxTemp,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.maxTemp) = $event)),
                    type: "number",
                    class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex p-2.5",
                    rules: 
                    _ctx.minTemp && _ctx.maxTemp
                      ? `min_value:${_ctx.minTemp}|max_value:2147483647`
                      : 'max_value:2147483647'
                  ,
                    onKeyup: _withKeys(_ctx.sendTemperature, ["enter"])
                  }, null, 8, ["modelValue", "rules", "onKeyup"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "maxTemp",
                    class: "text-rose-500"
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          class: "inline-flex w-2/3 justify-center items-center bg-btnaction rounded-md border border-transparent text-base font-medium text-white shadow-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2",
          type: "button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sendTemperature && _ctx.sendTemperature(...args)))
        }, [
          (_ctx.sending)
            ? (_openBlock(), _createBlock(_component_Spin, { key: 0 }))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.sending ? _ctx.$t('saving') : _ctx.$t('save')), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["show", "onCallbackClose"]))
}