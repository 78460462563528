import i18n from '@/resources/locales';
import store from '@/services/store';
import moment from 'moment-timezone';

const $t = i18n.global.t;

export function calculateDaysDifference(date) {
  if (date) {
    const today = new Date();
    const selectedDate = new Date(date);
    const timeDifference = selectedDate.getTime() - today.getTime();
    return Math.ceil(timeDifference / (1000 * 3600 * 24));
  } else {
    return null;
  }
}
export function dateFormatLocale() {
  const currentCountry = store.getters['CountryStore/getCountry'];
  return currentCountry?.dateFormatFront ?? 'MM-dd-yyyy';
}

export function dateTimeTZ(date) {
  const dtf = new Intl.DateTimeFormat();
  const localTimezone = dtf.resolvedOptions().timeZone;

  const timezone = store.getters['UserStore/getCurrentUser']?.timeZone ?? localTimezone;
  // Aug 16, 2023 12:45
  return moment(date).tz(timezone).format('lll');
}

export function dateTimeMDH(date) {
  const dtf = new Intl.DateTimeFormat();
  const localTimezone = dtf.resolvedOptions().timeZone;
  const timezone = store.getters['UserStore/getCurrentUser']?.timeZone ?? localTimezone;

  return moment.utc(date).tz(timezone).format('MMM DD, HH:mm');
}

export function formatDate(dateString: any, showEnglishFormat?: boolean | false, utc = true) {
  if (dateString !== '' && dateString !== undefined) {
    const date = new Date(dateString);
    const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
    const localeValue = showEnglishFormat ? 'en-GB' : locale;

    const options: any = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };

    if (utc) {
      options.timeZone = 'UTC';
    }

    // August 16, 2023
    return new Intl.DateTimeFormat(localeValue, options).format(date);
  }

  return dateString;
}

export function formatDateString(date: Date | undefined) {
  if (date !== undefined && date) {
    const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
    const dateShort = new Intl.DateTimeFormat(locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);

    // 15-08-2023 | 08-15-2023
    return dateShort.split('/').join('-');
  }

  return date;
}

export function formatDateStringReverse(date: Date | string | undefined) {
  console.log('date: ', date);

  if (date !== undefined) {
    const dateValue = typeof date === 'string' ? new Date(date) : date;
    console.log('dateValue: ', dateValue);
    const dateShort = new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'short',
    }).format(dateValue);
    return dateShort.split('/').reverse().join('-');
  }
  // 2023-07-20
  return date;
}

export function formatDateStringTodayYesterday(date: Date | undefined) {
  if (date !== undefined) {
    const currentDate = new Date();

    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);

    // Format the date based on its proximity to today's date
    if (date.toDateString() === currentDate.toDateString()) {
      return $t('label.today');
    } else if (date.toDateString() === yesterday.toDateString()) {
      return $t('label.yesterday');
    } else {
      const dateShort = new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'short',
      }).format(date);
      return dateShort.split('/').join('-');
    }
  }
  return date;
}

export function formatDateTime(dateString: any) {
  if (dateString !== '' && dateString !== undefined) {
    const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
    const date = new Date(dateString);

    // August 16, 2023 12:45
    return new Intl.DateTimeFormat(locale, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  }
  return dateString;
}

export function getDayAfter(date: any) {
  const day = new Date(date);
  if (day.getTimezoneOffset() > 0) {
    new Date(day.setDate(day.getDate() + 1));
  }
  return day;
}

export function getDayBefore(date: any) {
  const day = new Date(date);
  if (day.getTimezoneOffset() <= 0) {
    new Date(day.setDate(day.getDate() - 1));
  }
  return day;
}

export function getDayBeforeFilters(date: any) {
  const day = new Date(date);

  if (day.getTimezoneOffset() > 0) {
    new Date(day.setDate(day.getDate() - 1));
  }
  return day;
}

export function getTimeDifference(date1, date2) {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (seconds < 60) {
    return $t('seconds-ago', { seconds: seconds });
  } else if (minutes < 60) {
    return $t('minutes-ago', { minutes: minutes });
  } else if (hours < 24) {
    return $t('hours-ago', { hours: hours });
  } else if (days < 7) {
    return $t('days-ago', { days: days });
  } else {
    return $t('weeks-ago', { weeks: weeks });
  }
}

export function stringToShortDate(string) {
  const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
  const date = getDayAfter(string);
  // "Feb 5"
  return date.toLocaleDateString(locale, {
    month: 'short',
    day: 'numeric',
  });
}
