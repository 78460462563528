
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import BaseComponent from '@/base/BaseComponent';
import { SubscriptionStatus } from '@/components/ComponentsTypes';
import i18n from '@/resources/locales';
import { useRouter } from 'vue-router';
import PlanTooltipDark from '@/components/tooltip/PlanTooltipDark.vue';
import { saveAs } from 'file-saver';

export default defineComponent({
  components: {},
  props: {},
  mixins: [BaseComponent],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const $t = i18n.global.t;

    const activeAccordion = ref<number | null>(null);

    const monthNamesUSA = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];

    const monthNamesSpanish = [
      'ENE',
      'FEB',
      'MAR',
      'ABR',
      'MAY',
      'JUN',
      'JUL',
      'AGO',
      'SEP',
      'OCT',
      'NOV',
      'DIC',
    ];

    const paymentInfo = computed(() => {
      return store.getters['PaymentStore/getPaymentInfo'];
    });

    const countriesList = computed(() => {
      return store.getters['CountryStore/getCountries'];
    });

    const company = computed((): any => {
      return store.getters['CompanyStore/getCompany'];
    });

    const isEuropeCompany = computed((): any => {
      const country = countriesList.value.find(
        (country) => company.value?.country?.id === country?.id,
      );

      return country?.region?.name === 'EUROPE';
    });

    const subscriptionStatusText = (subscriptionStatus, accessPlan) => {
      if (subscriptionStatus === SubscriptionStatus.CREATED) {
        return $t('subscription-type-info', { subscription: $t(`${accessPlan.toLowerCase()}`) });
      } else if (subscriptionStatus === SubscriptionStatus.CANCELED) {
        return $t('subscription-type-canceled', {
          subscription: $t(`${accessPlan.toLowerCase()}`),
        });
      } else {
        return '';
      }
    };

    const viewInvoice = async (index: number, identifier: any) => {
      const response = await store.dispatch('PaymentStore/getInvoice', {
        id: index,
      });

      const url = URL.createObjectURL(response);
      const newWindow = window.open(url, '_blank');
    };

    const toggleAccordion = (index: number) => {
      activeAccordion.value = activeAccordion.value === index ? null : index;
    };

    const push = (loadId) => {
      router.push('/planning/service/' + loadId);
    };

    const dateFormat = (date) => {
      if (isEuropeCompany.value) {
        const dateObject = new Date(date);
      } else {
        const dateObject = new Date(BaseComponent.methods?.formatDate(date));
      }

      let monthNames = isEuropeCompany.value ? monthNamesSpanish : monthNamesUSA;

      const dateObject = new Date(date);

      const month = monthNames[dateObject.getMonth()];
      const day = dateObject.getDate();

      return `${month} ${day}`;
    };

    return {
      activeAccordion,
      dateFormat,
      isEuropeCompany,
      paymentInfo,
      push,
      toggleAccordion,
      SubscriptionStatus,
      subscriptionStatusText,
      viewInvoice,
    };
  },
});
