<template>
  <div class="flex flex-col">
    <div
      v-for="(item, i) in dataCommodities"
      class="flex min-h-[74px] w-full justify-between items-start inline-flex pb-3"
      :key="i"
    >
      <div class="flex w-full justify-start items-start gap-4 inline-flex">
        <!-- Commodity -->
        <div class="min-h-[74px] flex flex-col grow shrink basis-0 justify-start items-start gap-2">
          <label
            class="h-[18px] text-center text-slate-900 text-[15px] font-normal font-['Nunito']"
            :ref="'commodityName' + i"
          >
            {{ `${$t('commodity')}*` }}
          </label>
          <Field
            class="h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500"
            rules="required"
            v-model="item.commodityName"
            :id="'commodityName' + i"
            :name="'commodityName' + i"
            @change="onChangeDataForm"
          />
          <ErrorMessage
            v-if="clickConfirmed"
            class="text-sm text-rose-500"
            :name="'commodityName' + i"
          />
        </div>

        <!-- Weight, Pallets & PackageType -->
        <div class="min-h-[74px] justify-start items-start gap-4 flex">
          <!-- Weight -->
          <div
            class="h-full w-[130px] flex flex-col grow shrink basis-0 justify-start items-start gap-2"
          >
            <label
              class="h-[18px] text-center text-slate-900 text-[15px] font-normal font-['Nunito']"
              :ref="'weight' + i"
            >
              {{ `${$t('weight')}*` }}
            </label>
            <div class="h-full w-[130px] min-h-[46px] flex text-gray-900 text-sm">
              <Field
                rules="required|integer|min_value:1|max_value:2147483647"
                v-model="item.weight"
                :id="'weight' + i"
                :name="'weight' + i"
              >
                <InputAmericanFormat
                  class="flex"
                  class-custom="!rounded-lg"
                  v-model="item.weight"
                  :symbol="currentCountryWeightUnit"
                  :hidden="true"
                  :placeholder="'0'"
                  @change="onChangeDataForm"
                />
              </Field>
            </div>
            <ErrorMessage
              v-if="clickConfirmed"
              class="text-sm text-rose-500"
              :name="'weight' + i"
            />
          </div>

          <!-- Pallets & Package type -->
          <div class="flex">
            <!-- nPallets -->
            <div
              class="h-full w-[50px] flex flex-col grow shrink basis-0 justify-start items-start gap-2"
            >
              <label
                class="h-[18px] text-center text-slate-900 text-[15px] font-normal font-['Nunito']"
                :ref="'nPallets' + i"
              >
                {{ `${$t('units')}*` }}
              </label>
              <Field
                rules="required_short|numeric|min_value:1|max_value:2147483647"
                v-model="item.nPallets"
                :id="'nPallets' + i"
                :name="'nPallets' + i"
              >
                <InputAmericanFormat
                  class="flex"
                  class-custom="rounded-l-lg rounded-r-none"
                  v-model="item.nPallets"
                  :hidden="true"
                  :classicInput="true"
                  :placeholder="'0'"
                  @change="onChangeDataForm"
                />
              </Field>
              <ErrorMessage
                v-if="clickConfirmed"
                class="text-sm text-rose-500"
                :name="'nPallets' + i"
              />
            </div>

            <!-- Package type -->
            <div class="h-full flex flex-col justify-start items-start gap-2 grow shrink">
              <label
                class="h-[18px] text-center text-slate-900 text-[15px] font-normal font-['Nunito']"
                :ref="'packageType' + i"
              >
                {{ `${$t('package')}*` }}
              </label>
              <Field
                as="select"
                class="h-full min-h-[46px] flex bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-lg focus:ring-blue-500 focus:border-blue-500"
                rules="requiredIPackagefNegativeOne"
                v-model="item.packageType.id"
                :id="'packageType' + i"
                :name="'packageType' + i"
                @change="onChangeDataForm"
              >
                <option
                  value="-1"
                  disabled
                >
                  {{ $t('select') }}
                </option>
                <option
                  v-for="packageType in dataCommodityUnitTypes"
                  :value="packageType.id"
                  :key="packageType.id"
                >
                  {{ $t(`types.${packageType.fullName}`) }}
                </option>
              </Field>
              <ErrorMessage
                v-if="clickConfirmed"
                :name="'packageType' + i"
                class="text-sm text-rose-500 ml-6"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Button add more freight -->
    <div class="inline-flex self-stretch justify-start items-end gap-2.5">
      <button
        v-if="addMoreAllow"
        class="text-sky-500 text-[15px] font-semibold font-['Nunito'] hover:opacity-40"
        @click="onClickAddMoreFreight"
      >
        {{ $t('add-more-freight') }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue';
import { ErrorMessage, Field } from 'vee-validate';
import { useStore } from 'vuex';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';

export default defineComponent({
  name: 'MultiCommodity',
  components: {
    ErrorMessage,
    Field,
    InputAmericanFormat,
  },
  mixins: [BaseComponent],
  emits: ['changeDataForm'],
  props: {
    item: { type: Object },
    addMoreAllow: { type: Boolean, default: true },
    clickConfirmed: { type: Boolean },
  },
  setup(props, { emit }) {
    /** Global */
    const store = useStore();

    const item = ref(props.item);

    const dataCommodities = ref([
      {
        id: null,
        nPallets: null,
        packageType: { id: 1 },
        weight: null,
        commodityName: '',
      },
    ]);

    /** Lifecycle */
    onBeforeMount(() => {
      store.dispatch('LoadsStore/packageTypes');
    });

    onMounted(() => {
      if (item.value.commodities.length !== 0) {
        dataCommodities.value = item.value.commodities;
      } else {
        dataCommodities.value = [
          {
            id: null,
            nPallets: null,
            packageType: { id: 1 },
            weight: null,
            commodityName: '',
          },
        ];
      }
    });

    /** Computed */
    const dataCommodityUnitTypes = computed(() => {
      return store.getters['LoadsStore/packageTypes'].FTL;
    });

    /** Methods */
    const onChangeDataForm = () => {
      dataCommodities.value.forEach((commodity) => {
        if(commodity.nPallets){
          commodity.nPallets = Number(commodity.nPallets);
        } else{
          commodity.nPallets = null;
        }
      });
      emit('changeDataForm', dataCommodities.value, item.value);
    };

    const onClickAddMoreFreight = () => {
      dataCommodities.value.push({
        id: null,
        nPallets: null,
        packageType: { id: 1 },
        weight: null,
        commodityName: '',
      });
    };

    return {
      dataCommodities,
      dataCommodityUnitTypes,
      onChangeDataForm,
      onClickAddMoreFreight,
    };
  },
});
</script>

<style scoped></style>
