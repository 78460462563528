
import { defineComponent } from 'vue';
import { ArrowSmallDownIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import { statusBackground } from '@/components/ComponentsTypes';

export default defineComponent({
  mixins: [BaseComponent],
  props: {
    loads: {
      type: Object,
      default: null,
    },
    filters: {
      type: Object,
    },
    currentPage: {
      type: Object,
    },
  },
  components: { ArrowSmallDownIcon },
  data() {
    return {
      initialMonth: this.month || new Date().getMonth() + 1,
      initialYear: this.year || new Date().getFullYear(),

      masks: {
        weekdays: 'WWW',
      },
      currentMonth: '',
      currentYear: '',
      statusBackground,
      timezone: 'UTC',
      // attributes: [],
    };
  },
  computed: {
    calendarInitialPage() {
      if (
        this.currentPage &&
        typeof this.currentPage === 'object' &&
        'month' in this.currentPage &&
        'year' in this.currentPage
      ) {
        const { month, year } = this.currentPage;
        return { month, year };
      } else {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        return { month, year };
      }
    },
    attributes(): any {
      let calendar: any[] = [];
      Object.keys(this.loads).forEach((load, index) => {
        const date = load;
        calendar.push({
          key: index,
          customData: [],
          dates: new Date(date),
        });
        this.loads[load].forEach((statusQuantity) => {
          calendar[index].customData.push({
            title: statusQuantity.total + ' ' + this.$t(statusQuantity.status.toLowerCase()),
            class: statusBackground[statusQuantity.status],
          });
        });
      });
      return calendar;
    },
    isLaneDetail(): any {
      return this.$route.name === 'RFP-laneDetail';
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    routeToDay() {
      if (this.isSuperAdminRoute) {
        return 'dayDetailAdmin';
      } else {
        if (this.isLaneDetail) {
          return 'RFP-laneDay';
        } else {
          return 'dayDetail';
        }
      }
    },
  },
  methods: {
    searchByDate(month, year, title) {
      if (this.currentMonth !== month || this.currentYear !== year) {
        this.$emit('filterCalendar', { month, year });
        this.$emit('fetchLoadsCalendar', true, { month: month, year: year });

        this.currentMonth = month;
        this.currentYear = year;
      }
      return title;
    },
  },
});
