
import { defineComponent, reactive, toRefs, computed, watch, ref } from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import { useStore } from 'vuex';
import { Form, Field, ErrorMessage } from 'vee-validate';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import { SmartTagz } from 'smart-tagz';
// Base
import BaseComponent from '@/base/BaseComponent';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
import { useI18n } from 'vue-i18n';
import PlanNewContactTags from '@/components/forms/PlanNewContactTags.vue';

export default defineComponent({
  name: 'ContactForm',
  components: {
    PlanNewContactTags,
    PlanInputText,
    PlanInputNumber,
    PlanSelect,
    Field,
    SmartTagz,
  },
  emits: ['update', 'updateCurrentView', 'updatePermits', 'updateCompany'],
  mixins: [BaseComponent, CountryMixins],
  props: {
    contactInfo: { type: Object, required: true },
    specialPermits: { type: Object, required: true },
    company: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { contactInfo, specialPermits, company } = toRefs(props);

    const translate = useI18n();

    const contactInfoProxy = reactive({ ...contactInfo.value });
    const specialPermitsProxy = reactive({ ...specialPermits.value });
    const companyProxy = reactive({ ...company.value });
    const emailArray = ref([]);

    watch(contactInfo, (newVal) => {
      Object.assign(contactInfoProxy, newVal);
    });

    watch(specialPermits, (newVal) => {
      Object.assign(specialPermitsProxy, newVal);
    });

    const currentCompany = computed(() => {
      return store.getters['CompanyStore/getCompanyById'];
    });

    const user = computed(() => {
      return store.getters['UserStore/getUser'];
    });

    const emitUpdate = () => {
      emit('update', contactInfoProxy);
      emit('updatePermits', specialPermitsProxy);
      emit('updateCompany', companyProxy);
    };

    const checkEmail = (result) => {
      emailArray.value = result;
      sendEmails();
    };

    const sendEmails = () => {
      if (areAllEmailsValid.value) {
        store
          .dispatch('CompanyStore/updateLoadEmails', {
            id: currentCompany.value.id,
            loadNotificationEmails: emailArray.value,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(translate.t('message-updated-successfully'));
            store.dispatch('CompanyStore/getCompany', currentCompany.value.id);
          });
      } else {
        BaseComponent.methods?.showToastError(translate.t('email-not-valid'));
      }
    };

    const orderPrefixAsc = (prefixes: any) => {
      return prefixes.sort((a, b) => {
        let prefixA = parseInt(a.value, 10);
        let prefixB = parseInt(b.value, 10);

        if (prefixA < prefixB) {
          return -1;
        }
        if (prefixA > prefixB) {
          return 1;
        }
        return 0;
      });
    };

    const isValidEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    const areAllEmailsValid = computed(() => {
      return emailArray.value.every((email) => isValidEmail(email));
    });

    const isMexico = computed(() => {
      let findMex = store.getters['CountryStore/getCountries'].find((country) => {
        return country.name === 'Mexico';
      });

      return +findMex?.id === +contactInfoProxy.country.id;
    });

    const countryList = computed(() => {
      let result: Array<any> = [];

      let countries = store.getters['CountryStore/getCountries'];

      console.log(countries);

      if (countries.length === 0) {
        store.dispatch('CountryStore/getCountries');
        countries = store.getters['CountryStore/getCountries'];
      }

      result = countries.filter(
        (item) =>
          item?.region?.name.includes(store.getters['UserStore/getRegion']) &&
          contactInfoProxy.country !== item.iso,
      );

      return result;
    });

    const prefixes = computed(() => {
      let result: Array<{ label: string; value: string }> = [];
      const northAmericaRegionCountries = ['CA', 'MX', 'US'];
      const region = store.getters['UserStore/getRegion'];

      store.getters['CountryStore/getCountries'].forEach((country: any) => {
        if (region === 'NORTH_AMERICA') {
          if (
            northAmericaRegionCountries.includes(country.iso) &&
            !result.includes({ label: `+ ${country.phonePrefix}`, value: country.phonePrefix }) &&
            !result.find((prefix) => prefix.value === country.phonePrefix)
          ) {
            result.push({ label: `+ ${country.phonePrefix}`, value: country.phonePrefix });
          }
        } else {
          if (!northAmericaRegionCountries.includes(country.iso))
            result.push({ label: `+ ${country.phonePrefix}`, value: country.phonePrefix });
        }
      });

      return orderPrefixAsc(result);
    });

    const changeCompanyTags = (newTags: string) => {
      companyProxy.tags = newTags
      emitUpdate()
    }

    return {
      currentCompany,
      changeCompanyTags,
      checkEmail,
      emitUpdate,
      companyProxy,
      specialPermitsProxy,
      countryList,
      isMexico,
      contactInfoProxy,
      prefixes,
      user,
    };
  },
});
