
import { computed, defineComponent, onBeforeMount, onMounted, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import PlanLegsSelector from '@/components/tabs/PlanLegsSelector.vue';
import PlanContactBanner from '@/components/banner/PlanContactBanner.vue';
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
import ContactsTabFilter from '@/components/filters/ContactsTabFilter.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import BaseComponent from '@/base/BaseComponent';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

export default defineComponent({
  name: 'PlanQuoteStep',
  mixins: [BaseComponent],
  components: {
    AddCarrierModal,
    ContactsTabFilter,
    PlanLegsSelector,
    PlanContactBanner,
    PlanHeaderSearch,
    PlanDefaultTable,
    PlanButtonIcon,
  },
  props: {
    loadTemplate: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const router = useRouter();
    const data = reactive({
      contactModalVisible: false,
      legSelected: 0,
      loadSelected: {} as any,
      loadingView: true,
      isCrossBorderLoad: false,
      order: {
        value: 'companyName',
        direction: 'asc',
      },
      searchCompanyQuery: {
        type: 'CARRIER|BROKER',
        name: '',
        page: 1,
        sortBy: '',
        destinyZIP: '',
        originZIP: '',
        period: '',
        trailerTypeStats: '',
        country: '',
      },
      loadingContacts: false,
    });

    onMounted(async () => {
      await getContactList();
    });

    const getContactList = () => {
      data.loadingContacts = true;
      store
        .dispatch('UserStore/carrierContactList', data.searchCompanyQuery)
        .catch((err) => console.log(err))
        .finally(() => {
          data.loadingContacts = false;
        });
    };

    const loadTemplate = computed(() => props.loadTemplate);

    if (loadTemplate.value.legs.length > 0) {
      data.loadSelected = loadTemplate.value.legs[data.legSelected];
      data.isCrossBorderLoad = true;
    } else {
      data.loadSelected = JSON.stringify(loadTemplate.value);
    }

    const handleChangeLeg = (leg) => {
      data.legSelected = leg;
      data.loadSelected = loadTemplate.value.legs[data.legSelected];
    };

    const contacts = computed(() => {
      return store.getters['UserStore/carrierContactList'];
    });

    const loadMoreContacts = () => {
      data.searchCompanyQuery.page = data.searchCompanyQuery.page + 1;
      getContactList();
    };

    const openChat = (user) => {
      store.commit('GlobalStore/setOpenChat', false);
      setTimeout(() => {
        store.commit('ChatsStore/setUserForChat', user);
        store.commit('ChatsStore/setChatId', user.chat_id ? user.chat_id : null);
        store.commit('GlobalStore/setOpenChat', true);
      }, 500);
    };

    const handleChangeSearchByName = () => {
      // this.searchByUserName = event;
      // this.debouncedFetchContactList();
    };

    const fetch = () => {
      //
    };

    const headers = computed(() => {
      return [
        { label: translate.t('company'), value: 'companyName', sortable: true },
        { label: translate.t('rating'), value: 'rating', sortable: true },
        { label: translate.t('rol'), value: 'rol', sortable: false },
        { label: translate.t('loads'), value: 'loadsQuantity', sortable: true },
        { label: '', value: 'actions' },
      ];
    });

    const fetchOrder = (orderObject) => {
      data.order = orderObject;
      data.searchCompanyQuery.sortBy = `${
        orderObject.value
      }[${orderObject.direction.toUpperCase()}]`;
      // getContactList();
    };

    return {
      ...toRefs(data),
      contacts,
      fetch,
      fetchOrder,
      handleChangeLeg,
      handleChangeSearchByName,
      headers,
      loadMoreContacts,
      openChat,
    };
  },
});
