
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierLoadHeader from '@/components/carrier/PlanCarrierLoadHeader.vue';
import PlanCarrierLoadSubHeader from '@/components/carrier/PlanCarrierLoadSubHeader.vue';
import PlanCarrierLoadHeaderNotification from '@/components/carrier/PlanCarrierLoadHeaderNotification.vue';
import PlanCarrierLoadDetails from '@/components/carrier/loadDetail/PlanCarrierLoadDetails.vue';
import PlanCarrierLoadStopData from '@/components/carrier/loadDetail/PlanCarrierLoadStopData.vue';
import PlanCarrierLoadBid from '@/components/carrier/_quoting/PlanCarrierLoadBid.vue';
import { useStore } from 'vuex';
import PlanCarrierLoadingFullScreen from '@/components/carrier/PlanCarrierLoadingFullScreen.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useRouter } from 'vue-router';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import PlanCarrierTrackingElement from '@/components/carrier/PlanCarrierTrackingElement.vue';
import MySignaturePad from '@/modules/carrier/rateConfirmation/CarrierRateConfirmation.vue';
import { useI18n } from 'vue-i18n';
import PlanCarrierTrackingTab from '@/components/carrier/_assigned/PlanCarrierTrackingTab.vue';
import PlanCarrierDefaultTab from '@/components/carrier/PlanCarrierDefaultTab.vue';
import PlanCarrierDocumentsTab from '@/components/carrier/_assigned/PlanCarrierDocumentsTab.vue';

interface carrierBase {
  load: any,
  loadingLoad: boolean
}

export default defineComponent({
  name: 'CarrierLoadAssigned',
  components: { PlanCarrierDocumentsTab, PlanCarrierDefaultTab, PlanCarrierTrackingTab, PlanCarrierLoadStopData, PlanCarrierLoadDetails, PlanCarrierLoadSubHeader, PlanCarrierLoadHeader, PlanDefaultCarrierView },
  props: {
    id: { type: String, required: false, default: null }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      activeTab: 'tracking'
    })

    const changeTab = (newTab: string) => {
      data.activeTab = newTab
    }

    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    })

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData']
    })

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    const stopsData = computed(() => {
      let result: Array<any> = []

      loadData.value.stops.forEach((stop: any, index: number) => {
        let toCity = stop.location.city
        if (index < loadData.value.stops.length - 1) {
          toCity = loadData.value.stops[index + 1].location.city
        }

        result.push({
          stop: stop,
          toCity: toCity
        })
      })

      return result
    })

    const tabs = computed(() => {
      return [
        { label: translate.t('load-info') },
        { label: translate.t('tracking') },
        { label: translate.t('documents') }
      ]
    })

    const getLoad = async () => {
      await store.dispatch('LoadsStore/getFeeExplanations')
      await store.dispatch('CarrierStore/getLoadInfoForCarrier', store.getters['CarrierStore/getLoadData'].id)
    }

    const getUserCountry = async () => {
      await store.dispatch('CountryStore/getCountryById', currentUser.value?.company?.country?.id)
    }

    getLoad()
    getUserCountry()

    return {
      ...toRefs(data),
      changeTab,
      isDriver,
      loadData,
      stopsData,
      tabs
    }
  }
})
