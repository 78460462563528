
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanDefaultWindows from '@/components/defaults/PlanDefaultWindows.vue';
import { useStore } from 'vuex';
import PlanLoading from '@/components/loadings/PlanLoading.vue';
import LoadDetailHeader from '@/components/loads/detail/LoadDetailHeader.vue';
import LoadDetailRight from '@/components/loads/detail/rightColumn/LoadDetailRight.vue';
import LoadDetailQuote from '@/components/loads/detail/quote/LoadDetailQuote.vue';
import LoadDetailMap from '@/components/loads/detail/LoadDetailMap.vue';
import LoadDetailAccepting from '@/components/loads/detail/accepting/LoadDetailAccepting.vue';
import LoadDetailTracking from '@/components/loads/detail/tracking/LoadDetailTracking.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanDefaultConfirmationModal from '@/components/defaults/PlanDefaultConfirmationModal.vue';
import LoadDetailNewTemplateModal from '@/components/loads/detail/LoadDetailNewTemplateModal.vue';
import LoadDetailDelivered from '@/components/loads/detail/delivered/LoadDetailDelivered.vue';
import { useI18n } from 'vue-i18n';
import LoadForm from '@/components/loads/form/LoadForm.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import LoadModifyModal from '@/components/loads/LoadModifyModal.vue';
import LoadDetailHeaderContentButtons from '@/components/loads/detail/LoadDetailHeaderContentButtons.vue';

interface loadDetail {
  loadingLoad: boolean,
  selectedTab: number,
  showForm: boolean,
  showModals: {
    cancel: boolean,
    delete: boolean,
    newTemplate: boolean
  },
  headerContentLeftButtons: Array<any>,
  headerContentRightButtons: Array<any>
}

export default defineComponent({
  name: 'LoadDetail',
  components: { LoadDetailHeader, LoadDetailHeaderContentButtons, PlanDefaultWindows },
  props: {
    loadId: { type: Number, required: true, default: 0 }
  },
  emits: ['close'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive<loadDetail>({
      loadingLoad: false,
      selectedTab: 0,
      showForm: false,
      showModals: {
        cancel: false,
        delete: false,
        newTemplate: false
      },
      headerContentLeftButtons: [
        { title: 'LEG 1 - MX Domestic', subtitle: 'Planimatik Assigned', value: 'leg1' },
        { title: 'LEG 2 - Transfer MX to USA', subtitle: 'XPO Logistics Assigned', value: 'leg2' },
        { title: 'LEG 3 - USA Domestic', subtitle: 'Planimatik Assigned', value: 'leg3' },
      ],
      headerContentRightButtons: [
        { title: 'LEG 1', subtitle: '', value: 'leg1' },
        { title: 'LEG 2', subtitle: '', value: 'leg2' },
        { title: 'LEG 3', subtitle: '', value: 'leg3' },
      ]
    })

    const load = computed(() => {
      return store.getters['LoadsStore/currentLoad']
    })

    const userRegion = computed(() => {
      return store.getters['UserStore/getCurrentUser'].region
    })

    const changeTab = (newTab: number) => { data.selectedTab = newTab }

    const close = () => {
      store.commit('LoadsStore/setLoadInfo', null)
      emit('close')
    }

    const getLoad = async () => {
      data.loadingLoad = true
      await store.dispatch('LoadsStore/getLoadInfo', props.loadId)
      data.loadingLoad = false
    }

    const tabsToShow = computed(() => {
      return [
        { label: 'Quote', value: 'quoute' },
        { label: 'Assigned', value: 'assigned' },
        { label: 'In transit', value: 'inTransit' },
        { label: 'Delivered', value: 'delivered' }
      ]
    })

    if (props.loadId !== 0) getLoad()

    return {
      ...toRefs(data),
      load,
      changeTab,
      close,
      tabsToShow
    }
  }
})
