
import { defineComponent } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateSpin.vue';

export default defineComponent({
  name: 'ModalHash',
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Form,
    Field,
    ErrorMessage,
    Spin,
  },
  mixins: [BaseComponent],
  emits: ['fetchInfo'],
  props: {
    invitation: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      code: undefined as any,
      sending: false,
    };
  },
  computed: {
    showHash(): any {
      return this.$store.getters['UserStore/getShowHash'];
    },
  },
  watch: {
    showHash() {
      if (this.showHash) {
        this.requestLoginCode();
      }
    },
  },
  methods: {
    toggleOpen(isOpen: boolean) {
      this.$store.commit('UserStore/setShowHash', isOpen);
    },
    requestLoginCode() {
      let data = {
        id: localStorage.hash,
        shipmentId: Number(this.$route.params.id),
      };
      this.$store.dispatch('UserStore/requestLoginCode', data);
    },
    async hashValidation() {
      this.sending = true;
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid) {
        this.sending = false;
        return;
      }
      await this.$store
        .dispatch('UserStore/hashValidation', {
          hashId: localStorage.hash,
          secureCode: this.code,
        })
        .then(() => {
          this.$store.dispatch('PusherStore/initializePusher');
          this.$emit('fetchInfo', this.code);
          this.toggleOpen(false);
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.title);
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
});
