export const isValidHttpUrl = (string) => {
  // Option 1
  // const pattern = new RegExp(
  //   '^([a-zA-Z]+:\\/\\/)?' + // protocol
  //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
  //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  //   '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  //   '(\\#[-a-z\\d_]*)?$', // fragment locator
  //   'i'
  // );
  // return pattern.test(string);

  // Option 2
  const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const regEx = new RegExp(expression);
  return regEx.test(string);
};

export const validPhoneNumberPaste = (evt) => {
  const regex = /(\d+)/g;
  const pastedValue = evt.clipboardData.getData('text');
  const numbersFound = pastedValue.match(regex);
  return numbersFound.join('');
};
