
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
import Spin from '@/components/spin/AnimateSpin.vue';

export default defineComponent({
  name: 'TemperatureModal',
  components: {
    BaseModal,
    ErrorMessage,
    Field,
    Form,
    Spin,
  },
  mixins: [BaseComponent],
  emits: ['setTemperature', 'toggleTemperature'],
  props: {
    showModal: { type: Boolean },
  },
  data() {
    return {
      minTemp: undefined as any,
      maxTemp: undefined as any,
      sending: false,
    };
  },
  computed: {},
  methods: {
    async sendTemperature() {
      this.sending = true;
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid) {
        this.sending = false;
        return;
      }
      this.$emit('toggleTemperature', false);
      this.$emit('setTemperature', this.minTemp, this.maxTemp);
      setTimeout(() => {
        this.sending = false;
        this.minTemp = null;
        this.maxTemp = null;
      }, 150);
    },
    onClickClose() {
      this.$emit('toggleTemperature', false);
    }
  },
});
