import { initialState } from '@/services/lanes/_store/index';

export default {
  clearLanesStore(state: any) {
    Object.assign(state, initialState);
  },
  getCurrentTendersByGroup(state: any, payload: any): void {
    state.getCurrentTendersByGroup = payload;
  },
  lastLaneListPeriod(state: any, payload: any): void {
    state.lastLaneListPeriod = payload;
  },
  editingLanes(state: any, payload: any): void {
    state.editingLanes = payload;
  },
  currentActiveGroup(state: any, payload: any): void {
    state.currentActiveGroup = payload;
  },
  getLaneData(state: any, payload: any): void {
    state.laneData = payload;
  },
  setLaneQuotesTotals(state: any, payload: any): void {
    state.laneQuotesTotals = payload;
  },
  setLaneQuotes(state: any, payload: any): void {
    state.laneQuotes = payload;
  },
  setLaneRanking(state: any, payload: any): void {
    state.laneRanking = payload;
  },
  setLanesGroupsList(state: any, payload: any): void {
    state.lanesGroups = payload;
  },
  setLanesList(state: any, payload: any): void {
    state.lanes = payload;
  },
  setTotalsLanes(state: any, payload: any): void {
    state.lanesTotals = payload;
  },
  setLanesTitle(state: any, payload: any): void {
    state.lanesTitle = payload;
  },
  setFiltersLanes(state: any, payload: any): void {
    state.filtersLanes = payload;
  },
  setActiveGroup(state: any, payload: any): void {
    state.activeGroup = payload;
  },
  setGroupBids(state: any, payload: any): void {
    state.groupBids = payload;
  },
  setShowModalStarted(state: any, payload: any): void {
    state.showModalStarted = payload;
  },
  setTotalTenders(state: any, payload: any): void {
    state.totalTenders = payload;
  },
  setBidsByCompany(state: any, payload: any): void {
    state.bidsByCompany = payload;
  },
};
