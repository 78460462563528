import { defineComponent } from 'vue';
import { localAssets } from '@/resources/assets/assets';

export default defineComponent({
  methods: {
    getCreditCard(card) {  
      switch (card) {
        case 'visa':
          return localAssets.icVisa;
        case 'mastercard':
          return localAssets.icMasterCard;
          case 'amex':
            return localAssets.icAmex;
        case 'discover':
            return localAssets.icDiscover;
        default:
          return '';
      }
    },
  },
});
