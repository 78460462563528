import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-center plan-b-sec-50" }
const _hoisted_2 = { class: "quick-filters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quick_filter = _resolveComponent("quick-filter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_quick_filter, {
        onChange: _ctx.change,
        id: "pickupsToday",
        label: _ctx.$t('pickup-today'),
        quantity: _ctx.pickupToday
      }, null, 8, ["onChange", "label", "quantity"]),
      _createVNode(_component_quick_filter, {
        onChange: _ctx.change,
        id: 'pickupsThisWeek' + _ctx.userRegion,
        label: _ctx.$t('pickup-this-week'),
        quantity: _ctx.pickupWeek
      }, null, 8, ["onChange", "id", "label", "quantity"]),
      _createVNode(_component_quick_filter, {
        onChange: _ctx.change,
        id: "deliveriesToday",
        label: _ctx.$t('delivery-today'),
        quantity: _ctx.deliveryToday
      }, null, 8, ["onChange", "label", "quantity"]),
      _createVNode(_component_quick_filter, {
        onChange: _ctx.change,
        id: 'deliveriesThisWeek' + _ctx.userRegion,
        label: _ctx.$t('delivery-this-week'),
        quantity: _ctx.deliveryWeek
      }, null, 8, ["onChange", "id", "label", "quantity"]),
      (_ctx.totalsClaims > 0)
        ? (_openBlock(), _createBlock(_component_quick_filter, {
            key: 0,
            onChange: _ctx.change,
            id: "claims",
            icon: "bell-claims",
            label: _ctx.$t('needing-attention'),
            quantity: _ctx.totalsClaims,
            type: "red-500"
          }, null, 8, ["onChange", "label", "quantity"]))
        : _createCommentVNode("", true)
    ])
  ]))
}