
import { computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BaseComponent from '@/base/BaseComponent';
import { calculateDaysDifference } from '@/utils/formatDates';
import i18n from '@/resources/locales';
// Mixins
import CountryMixins from '@/services/country/_mixins';
export default defineComponent({
  mixins: [BaseComponent, CountryMixins],
  components: {},
  props: {},
  setup(props) {
    /** Global */
    const store = useStore();
    const router = useRouter();
    const $t = i18n.global.t;
    const showBasicSpinner = ref(false);

    /** Computed */
    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const subscriptionType = computed(() => {
      return store.getters['PaymentStore/getSubscriptionType'];
    });

    /** Methods **/
    const upgrade = async () => {
      router.push({ name: 'plans' });
    };

    onMounted(() => {
      store.dispatch('PaymentStore/getSubscription');
    });

    const cancelSubscription = async () => {
      showBasicSpinner.value = true;
      store.dispatch('PaymentStore/deleteSubscription').then(async () => {
        BaseComponent.methods?.showToastSuccess($t('subscription-canceled', { plan: $t('basic') }));
        showBasicSpinner.value = false;
        const refreshToken = localStorage.refresh_token;
        const refresh = await store.dispatch('AuthStore/refreshToken', refreshToken);
        store.dispatch('PaymentStore/getSubscription');
      });
    };

    return {
      calculateDaysDifference,
      cancelSubscription,
      currentUser,
      showBasicSpinner,
      subscriptionType,
      upgrade,
    };
  },
});
