
import '@/resources/assets/styles/css/application.tailwind.css';
import BackTopButton from '@/components/buttons/BackTopButton.vue';
import CountryLoadingScreen from '@/components/CountryLoadingScreen.vue';
import ChangeTimeZoneModal from '@/components/modals/ChangeTimeZoneModal.vue';
import ModalEmailConfig from '@/components/modals/EmailConfigModal.vue';
import UserLanguageModal from '@/components/modals/UserLanguageModal.vue';
import Notification from '@/services/notification/Notification.vue';
import Toast from '@/components/toast/Toast.vue';

import { defineComponent } from 'vue';
import PlanToast from '@/components/toast/PlanToast.vue';
export default defineComponent({
  components: {
    PlanToast,
    BackTopButton,
    CountryLoadingScreen,
    ChangeTimeZoneModal,
    ModalEmailConfig,
    Notification,
    UserLanguageModal,
  },
  computed: {
    collapsedSidebar() {
      return this.$store.getters['UserStore/getCollapsedSidebar'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    userLanguageModal() {
      return this.$store.getters['GlobalStore/getUserLanguageModal'];
    },
    emailConfigModal() {
      return this.$store.getters['GlobalStore/getEmailConfigModal'];
    },
    isLoadingCountry() {
      return this.$store.getters['CountryStore/getIsLoadingCountry'];
    },
    notification(): any {
      return this.$store.getters['UserStore/notification'];
    },
    notificationItems(): any {
      return this.$store.getters['UserStore/notificationItems'];
    },
    showModalTimezone(): any {
      return this.$store.getters['UserStore/getShowModalTimezone'];
    },
    toastValues(): any {
      return this.$store.getters['UserStore/toast'];
    },
  },
  async created() {
    await this.$store.dispatch('GlobalStore/apiGetGlobalConfig');
    this.$store.dispatch('UserStore/currentUser');
  },
});
