import { createApp } from 'vue';
import Popper from 'vue3-popper';
import VCalendar from 'v-calendar';
import App from './App.vue';
import router from './router';
// Resources
import i18n from '@/resources/locales/index';
// Services
import store from '@/services/store';
// Styles
import './resources/assets/styles/scss/custom.scss';
import './resources/assets/styles/css/input.css';
import 'v-calendar/dist/style.css';
import 'vue3-timepicker/dist/VueTimepicker.css';
// Utils
import '@/utils/validationRules';

// Axios conf
import InterceptorsSetup from '@/services/api/axiosConfig';
new InterceptorsSetup();

// Mount
const app = createApp(App);
app.config.globalProperties.$store = store;
app.use(store).use(router).use(i18n).use(VCalendar, {}).component('Popper', Popper).mount('#app');
