
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'incidenceTooltipContent',
  props: {
    currentLane: {
      type: Object,
      required: true,
    },
    week: {
      type: Number,
      required: true,
    },
    fetchDataOnHover: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Global
    const store = useStore();
    const route = useRoute();

    // Variables
    const currentLane = ref(props.currentLane);
    const loading = ref(true);
    const testLanesTooltip = ref([] as any);
    const week = ref(props.week);

    // Methods
    const fetchData = async () => {
      // const existingItemIndex = getLanesClaimsInfo.value.findIndex(
      //   (item) => item.laneId === currentLane.value.id,
      // );
      // store.commit('setLanesClaimsInfo', [...claimLanesInfo, { laneId, items: response }]);
      // if (existingItemIndex === -1 || getLanesClaimsInfo.value.length === 0) {
        const response = await store.dispatch('RFPStore/getLanesClaimsInfo', {
          laneId: currentLane.value.id,
          week: week.value,
        });
        testLanesTooltip.value = response;
      // } else {
      //   const existingItem = getLanesClaimsInfo.value[existingItemIndex];
      //   testLanesTooltip.value = existingItem.items;
      // }

      loading.value = false;
    };

    const openLaneDetail = (object) => {
      emit('openLaneDetail', {
        laneId: currentLane.value.id,
        loadId: object.load,
        projectId: route.params.projectId,
      });
    };

    // Computed

    const getLanesClaimsInfo = computed(() => {
      return store.getters['RFPStore/getLanesClaimsInfo'];
    });

    // Lifecycle

    onMounted(() => {
      if (props.fetchDataOnHover) {
        fetchData();
      }
    });

    // Watchers
    watch(
      () => props.fetchDataOnHover,
      (newValue) => {
        if (newValue) {
          fetchData();
        }
      },
    );

    watch(() => props.week, (currentValue, oldValue) => {
      week.value = props.week
      fetchData();
    })

    return {
      loading,
      testLanesTooltip,
      openLaneDetail,
    };
  },
});
