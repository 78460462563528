export default {
  getChatId(state: any): object {
    return state.chatId;
  },
  getLaneForChat(state: any): any {
    return state.laneForLoad;
  },
  getRankingForChat(state: any): any {
    return state.rankingForChat;
  },
  getUnreadMessages(state: any): object {
    return state.company;
  },
};
