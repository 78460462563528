
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  mixins: [BaseComponent],
  props: {
    currentAssignConfirmation: {
      type: Object,
    },
  },
  data() {
    return {
      count: 0,
    };
  },
  async created(): Promise<void> {
    this.checkCountry();
  },
  computed: {
    currentLane(): any {
      return this.$store.getters['RFPStore/getCurrentLane'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    deliveriesQuantity(): any {
      let sum = 1;
      this.currentLane?.loadTemplate?.stops?.forEach((stop: any) => {
        if (stop.type === 'DELIVERY') {
          sum++;
        }
      });
      return sum;
    },
    isStatusInValid(): any {
      const validStatuses = ['ACCEPTING', 'REFUSED', 'CONFIRM_EXPIRED', 'QUOTED'];
      return validStatuses.includes(this.currentAssignConfirmation?.quote?.quoteRequest?.status);
    },
    picksQuantity(): any {
      let sum = 1;
      this.currentLane?.loadTemplate?.stops?.forEach((stop: any) => {
        if (stop.type === 'PICKUP') {
          sum++;
        }
      });
      return sum;
    },
    remainingTime(): any {
      const date = new Date(this.currentAssignConfirmation?.expirationDate);

      const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
      const day = date.getDate();

      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      const formattedDateTime = `${month} ${day}, ${this.$t('at-time')} ${hours
        .toString()
        .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;

      return formattedDateTime;
    },
  },
  methods: {
    checkCountry() {
      this.$store
        .dispatch('CountryStore/getCountryById', this.currentUser?.company?.country?.id)
        .then(() => {
          const lang = this.getLocale?.substring(0, 2);
          this.$i18n.locale = lang;
          this.$emit('forceUpdate');
        });
    },
    handleAccept() {
      if (this.isStatusInValid) {
        this.$store.dispatch(
          'RFPStore/postCarrierConfirm',
          this.currentAssignConfirmation?.quote?.id,
        );
      }
      this.$store
        .dispatch('RFPStore/postAssignConfirmationRFP', this.$route.params.assignId)
        .then(() => {
          this.$router.push({
            name: 'CarrierAuthentication',
            params: {
              id: this.currentAssignConfirmation?.shipment?.loads[0]?.id,
              hash: this.$route.params.hash,
              code: this.$route.params.code,
            },
          });
        })
        .catch((err) => {
          this.showToastError(err.response?.data?.title ?? 'unexpected-error');
        });
    },
    handleDeny() {
      if (this.isStatusInValid) {
        this.$store.dispatch(
          'RFPStore/postCarrierRefuse',
          this.currentAssignConfirmation?.quote?.id,
        );
      }
      this.$store
        .dispatch('RFPStore/postAssignDeclineRFP', this.$route.params.assignId)
        .then(() => {
          this.$router.push({
            name: 'refusedOffer',
            params: {
              id: this.$route.params.assignId,
              hash: this.$route.params.hash,
              code: this.$route.params.code,
            },
          });
        });
    },
  },
});
