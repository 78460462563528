
import { onBeforeMount, computed, defineComponent, ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Services
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  name: 'StartFreeTrial',
  mixins: [BaseComponent],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    code: String,
    hashId: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    // Global
    const store = useStore();

    // Variables
    const form = ref(null);
    const loading = ref(false);
    const password = ref(null as any);

    // Computed
    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });


    /** onBeforeMount */
    onBeforeMount(() => {
      localStorage.setItem('hash', props.hashId);
      if (props.hashId && props.code) {
        store
          .dispatch('UserStore/hashValidation', {
            hashId: props.hashId,
            secureCode: props.code,
          })
          .then(() => {
            store.dispatch('PusherStore/initializePusher');
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
          });
      }
    });

    /** Methods */
    const signUp = async () => {
      if (loading.value === true) return;
      loading.value = true;
      const validation = await (form as any).value.validate();
      if (!validation.valid) {
        return;
      }
      await store
        .dispatch('UserStore/registrationUpdatePassword', {
          id: currentUser.value.id,
          password: password.value,
        })
        .then(() => {
          store
            .dispatch('AuthStore/signIn', {
              username: currentUser.value.email,
              password: password.value,
            })
            .then(() => {
              router.push('/planning');
            })
            .finally(() => {
              loading.value = false;
            });
        })
        .finally(() => (loading.value = false));
    };

    return {
      currentUser,
      form,
      loading,
      signUp,
      password,
    };
  },
});
