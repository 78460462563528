import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  loadToSend: null as any,
  defaultLoad: {
    observations: '',
    poNumber: '',
    stops: [
      {
        type: 'PICKUP_INITIAL',
        executionDate: '2024-08-11',
        location: {
          lat: 37.7030051,
          long: -85.8647201,
          zip: '42701',
          city: 'Elizabethtown',
          googlePlaceId: 'ChIJ28mWXr7oaIgRqDn6wy6K16g',
          address: '',
        },
        portStop: false,
        airportStop: false,
        observations: '',
        deliveryStop: null,
        lumperFeePrice: 0,
        multiStopIdentifierArray: [],
        lumperFee: false,
        multiStopIdentifier: '',
        executionWindowEndTime: null,
        executionWindowStartTime: null,
        companyName: '',
        address: '',
        phoneNumber: '',
        phonePrefix: '',
        defaultCountrySelected: {
          id: 1,
          name: 'United States',
          iso: 'US',
          contextUse: ['COMPANY_COUNTRY'],
          phonePrefix: '1',
          region: {
            id: 1,
            name: 'NORTH_AMERICA',
          },
        },
        poNumber: '',
        contact: '',
        warehouseTemplate: null,
        commodities: [
          {
            id: null,
            nPallets: 4,
            packageType: {
              id: 3,
            },
            weight: 2100,
            commodityName: 'bikes',
          },
        ],
      },
      {
        type: 'DELIVERY_FINAL',
        executionDate: '2024-08-16',
        location: {
          lat: 38.9120932,
          long: -94.3773126,
          zip: '64002',
          city: "Lee's Summit",
          googlePlaceId: 'ChIJoc7sS_MfwYcR3AYp9BuHWcM',
          address: '',
        },
        portStop: false,
        airportStop: false,
        multiStopIdentifier: '',
        observations: '',
        lumperFeePrice: 0,
        multiStopIdentifierArray: [],
        lumperFee: false,
        executionWindowEndTime: null,
        executionWindowStartTime: null,
        companyName: '',
        deliveryStop: null,
        address: '',
        phoneNumber: '',
        phonePrefix: '',
        defaultCountrySelected: {
          id: 1,
          name: 'United States',
          iso: 'US',
          contextUse: ['COMPANY_COUNTRY'],
          phonePrefix: '1',
          region: {
            id: 1,
            name: 'NORTH_AMERICA',
          },
        },
        poNumber: '',
        contact: '',
        warehouseTemplate: null,
        commodities: [
          {
            id: null,
            nPallets: 4,
            packageType: {
              id: 3,
            },
            weight: 2100,
            commodityName: 'bikes',
          },
        ],
      },
    ],
    commodityMaster: {
      id: null,
      nPallets: 4,
      packageType: {
        id: 3,
      },
      weight: 2100,
      commodityName: 'bikes',
    },
    status: 'DRAFT',
    trailerType: {
      id: 1,
    },
    commodityMasters: [
      {
        id: null,
        nPallets: 4,
        packageType: {
          id: 3,
        },
        weight: 2100,
        commodityName: 'bikes',
      },
    ],
    specialRequirement: {
      teamDriver: false,
      bondedPermission: false,
      TWICCard: false,
      tsa: false,
      hazardousLoad: false,
      specialPermissions: false,
      specialPermissionsDescription: '',
      tir: false,
    },
    legs: [
      {
        observations: '',
        poNumber: '',
        stops: [
          {
            type: 'PICKUP_INITIAL',
            executionDate: '2024-08-11',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            observations: '',
            deliveryStop: null,
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            multiStopIdentifier: '',
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
          {
            type: 'DELIVERY_FINAL',
            executionDate: '2024-08-16',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            multiStopIdentifier: '',
            observations: '',
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            deliveryStop: null,
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
        ],
        commodityMaster: {
          id: null,
          nPallets: 4,
          packageType: {
            id: 3,
          },
          weight: 2100,
          commodityName: 'bikes',
        },
        status: 'DRAFT',
        trailerType: {
          id: 1,
        },
        commodityMasters: [
          {
            id: null,
            nPallets: 4,
            packageType: {
              id: 3,
            },
            weight: 2100,
            commodityName: 'bikes',
          },
        ],
        specialRequirement: {
          teamDriver: false,
          bondedPermission: false,
          TWICCard: false,
          tsa: false,
          hazardousLoad: false,
          specialPermissions: false,
          specialPermissionsDescription: '',
          tir: false,
        },
        legOrder: 1,
      },
      {
        observations: '',
        poNumber: '',
        stops: [
          {
            type: 'PICKUP_INITIAL',
            executionDate: '2024-08-11',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            observations: '',
            deliveryStop: null,
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            multiStopIdentifier: '',
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
          {
            type: 'DELIVERY_FINAL',
            executionDate: '2024-08-16',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            multiStopIdentifier: '',
            observations: '',
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            deliveryStop: null,
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
        ],
        commodityMaster: {
          id: null,
          nPallets: 4,
          packageType: {
            id: 3,
          },
          weight: 2100,
          commodityName: 'bikes',
        },
        status: 'DRAFT',
        trailerType: {
          id: 1,
        },
        commodityMasters: [
          {
            id: null,
            nPallets: 4,
            packageType: {
              id: 3,
            },
            weight: 2100,
            commodityName: 'bikes',
          },
        ],
        specialRequirement: {
          teamDriver: false,
          bondedPermission: false,
          TWICCard: false,
          tsa: false,
          hazardousLoad: false,
          specialPermissions: false,
          specialPermissionsDescription: '',
          tir: false,
        },
        legOrder: 1,
      },
      {
        observations: '',
        poNumber: '',
        stops: [
          {
            type: 'PICKUP_INITIAL',
            executionDate: '2024-08-11',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            observations: '',
            deliveryStop: null,
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            multiStopIdentifier: '',
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
          {
            type: 'DELIVERY_FINAL',
            executionDate: '2024-08-16',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            multiStopIdentifier: '',
            observations: '',
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            deliveryStop: null,
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
        ],
        commodityMaster: {
          id: null,
          nPallets: 4,
          packageType: {
            id: 3,
          },
          weight: 2100,
          commodityName: 'bikes',
        },
        status: 'DRAFT',
        trailerType: {
          id: 1,
        },
        commodityMasters: [
          {
            id: null,
            nPallets: 4,
            packageType: {
              id: 3,
            },
            weight: 2100,
            commodityName: 'bikes',
          },
        ],
        specialRequirement: {
          teamDriver: false,
          bondedPermission: false,
          TWICCard: false,
          tsa: false,
          hazardousLoad: false,
          specialPermissions: false,
          specialPermissionsDescription: '',
          tir: false,
        },
        legOrder: 1,
      },
    ],
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
