import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-center section mx-8 mb-8 rounded shadow" }
const _hoisted_2 = { class: "flex flex-col h-full z-10 overflow-hidden" }
const _hoisted_3 = { class: "flex flex-row justify-between" }
const _hoisted_4 = { class: "sticky bottom-0 flex justify-end" }
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowSmallDownIcon = _resolveComponent("ArrowSmallDownIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_calendar = _resolveComponent("v-calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_calendar, {
      class: "custom-calendar max-w-full",
      attributes: _ctx.attributes,
      locale: _ctx.getLocale,
      masks: _ctx.masks,
      timezone: _ctx.timezone,
      "from-page": _ctx.calendarInitialPage,
      "disable-page-swipe": "",
      "is-expanded": ""
    }, {
      "day-content": _withCtx(({ day, attributes }) => [
        _createVNode(_component_router_link, {
          to: {
            name: _ctx.routeToDay,
            params: { date: day.id },
          }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (attributes?.length === 0)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(["day-label flex flex-row justify-between w-full rounded-full text-sm text-gray-900", { 'border-sky-500 border-2': day.isToday }])
                  }, _toDisplayString(day.day), 3))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attributes, (attr) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: attr.key,
                  class: "flex-grow overflow-y-auto overflow-x-auto relative"
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["day-label flex justify-center items-center rounded-full text-sm text-gray-900", { 'border-sky-500 border-2 p-0.5': day.isToday }])
                      }, _toDisplayString(day.day), 3)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attr.customData, (data, index) => {
                      return (_openBlock(), _createElementBlock("p", {
                        key: index,
                        class: _normalizeClass(["text-xs leading-tight rounded-sm p-1 mt-0 mb-1", data.class])
                      }, _toDisplayString(data.title), 3))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    (attr.customData.length > 2)
                      ? (_openBlock(), _createBlock(_component_ArrowSmallDownIcon, {
                          key: 0,
                          class: "justify-end h-5 w-5 p-1 text-white rounded-full text-sm bg-red-500"
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 2
        }, 1032, ["to"])
      ]),
      "header-title": _withCtx(({ month, year, title }) => [
        _createElementVNode("input", {
          class: "bg-gray-100 text-center",
          value: _ctx.searchByDate(month, year, title)
        }, null, 8, _hoisted_5)
      ]),
      _: 1
    }, 8, ["attributes", "locale", "masks", "timezone", "from-page"])
  ]))
}