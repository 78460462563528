import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  company: {},
  companyById: {},
  companiesList: [],
  companiesTotals: {},
  contactsBlastEmail: [],
  vehiclesList: [],
  driversList: [],
  adminAgents: [] as any,
  updatingInsurances: false,
  searchCompany: '',
  selectedEmailToSend: null as any,
  selectedTemplate: null as any,
  selectedTemplateCategory: null as any,
  selectedTemplateToSend: null as any,
  templateToClone: null as any
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
