import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center items-center plan-gap-sm" }
const _hoisted_2 = { class: "plan-text-xl text-center font-bold" }
const _hoisted_3 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    size: "xs-auto",
    "only-content": "",
    "without-sidebar": "",
    "hide-close-button": ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.status)
          ? (_openBlock(), _createBlock(_component_plan_icon, {
              key: 0,
              name: "fill-circle-check",
              "is-svg": "",
              "icon-color": "#00DB50",
              size: "40"
            }))
          : (_openBlock(), _createBlock(_component_plan_icon, {
              key: 1,
              name: "fill-circle-close",
              "is-svg": "",
              "icon-color": "#EF4444",
              size: "40"
            })),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.message), 1),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          type: "primary",
          label: _ctx.$t('continue'),
          class: "w-full"
        }, null, 8, ["onOnClick", "label"])
      ])
    ]),
    _: 1
  }))
}