import PaymentsBase from '@/modules/payments/PaymentsBase.vue';
import PlansComponent from '@/modules/payments/PlansComponent.vue';
import PaymentsMethods from '@/modules/payments/PaymentsMethods.vue';
const SubscriptionRouter = [
  {
    path: 'payments',
    name: 'payments',
    component: PaymentsBase,
    meta: {
      activeButton: 'planningPayments'
    }
  },
  {
    path: 'paymentsMethods',
    name: 'paymentsMethods',
    component: PaymentsMethods,
    meta: {
      activeButton: 'planningPaymentsMethods'
    }
  },
  {
    path: 'plans',
    name: 'plans',
    component: PlansComponent,
  },
  {
    path: 'plans/:hash/:code',
    name: 'plansCode',
    component: PlansComponent,
  },
];

export default SubscriptionRouter;
