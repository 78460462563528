
import { defineComponent } from 'vue';
// Components
import Spin from '@/components/spin/AnimateSpin.vue'

export default defineComponent({
  name: 'EndQuotationModal',
  components: {
    Spin
  },
  props: {
    loading: Boolean,
  },
  setup(props,{ emit }) {
    const onClickClose = () => { emit('close') };
    const onClickSend = () => { emit('send') };

    return {
      onClickClose,
      onClickSend
    };
  },

});

