
import { defineComponent, computed, ref, onMounted, onUnmounted, reactive, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import i18n from '@/resources/locales';
import LoadAlertsInfo from '@/components/loads/LoadAlertsInfo.vue';
import LoadRankingInfo from '@/components/loads/card/LoadRankingInfo.vue';
import LoadFirstTwoRankingInfo from '@/components/loads/card/LoadFirstTwoRankingInfo.vue';
import LoadDetail from '@/components/loads/detail/LoadDetail.vue';
export default defineComponent({
  name: 'LoadCardState',
  components: {
    LoadDetail,
    LoadFirstTwoRankingInfo,
    LoadAlertsInfo,
    PlanAvatar,
    PlanButtonIcon,
    PlanButton,
  },
  props: {
    state: { type: String, required: true, default: '' },
    load: { type: Object, required: true },
    chats: { type: Array, required: true },
  },
  emits: ['onClick', 'onOptionsClick', 'openDetail'],
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const windowWidth = ref(window.innerWidth);

    const showUiComponents = computed(() => {
      return process.env.VUE_APP_SHOW_UI_COMPONENTS === 'true';
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const data = reactive({
      showNewDetail: false,
    });
    const onClickShowNewDetail = () => {
      data.showNewDetail = true;
    };

    const onClick = (type: string) => {
      if (type === 'state') {
        emit('onClick');
      } else {
        onOptionsClick(type);
      }
    };

    const openDetail = () => {
      emit('openDetail');
      store.commit('GlobalStore/setSectionFromPlanification', true);
    };
    const isCarrierFacade = computed(() => {
      let valueResult = false;
      let company = '';
      let logo = '';
      if (props.load?.shipment?.assignedCompany?.carrierFacade) {
        valueResult = true;
        company = props.load?.shipment?.assignedCompany?.carrierFacade?.companyName;
        logo = props.load?.shipment?.assignedCompany?.carrierFacade?.profilePhotoURL;
      }

      return {
        value: valueResult,
        company: company,
        logo: logo,
      };
    });

    const chatsToShow = computed(() => {
      let unread = false;
      let result: Array<any> = [];

      if (
        props.load.hasRanking &&
        props?.load?.status != 'ASSIGNED' &&
        props?.load?.status != 'IN_PROGRESS' &&
        props?.load?.status != 'FINISHED' &&
        props?.load?.status != 'CANCELED'
      ) {
        return {
          chats: result,
          unread: unread,
        };
      }

      if (props.chats.length > 0) {
        props.chats.forEach(function (chat: any) {
          if (props.state === 'QUOTING') {
            if (!chat.readed) {
              result.push(chat);
              unread = true;
            }
          } else {
            if (!chat.readed) unread = true;
            result.push(chat);
          }
        });
      }

      return {
        chats: result,
        unread: unread,
      };
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const actualUserId = computed(() => {
      return currentUser.value.id;
    });

    const bestBid = computed(() => {
      let currencySymbol = userRegion.value === 'EUROPE' ? 'EUR' : 'USD';

      if (props.load.shipment.bestBids.length > 0) {
        const bestBidsCopy = props.load.shipment.bestBids;
        const bestBidsOrdered = bestBidsCopy.sort((a, b) => a.bid - b.bid);
        return new Intl.NumberFormat(i18n.global.locale.value, {
          style: 'currency',
          currency: currencySymbol,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(bestBidsOrdered[0].bid);
      } else {
        return '';
      }
    });

    const chatData = computed(() => {
      let resultChat = {
        id: -1,
        readed: false,
        chatMember: null,
        user: '',
        userId: -1,
      };

      if (
        props.state === 'CONFIRMED' ||
        props.state === 'ASSIGNED' ||
        props.state === 'IN_PROGRESS' ||
        props.state === 'FINISHED' ||
        props.state === 'CANCELED' ||
        props.state === 'DELETED'
      ) {
        props.load.chat?.chatMembers.forEach(function (chat: any) {
          if (chat.user.id !== actualUserId.value) {
            resultChat.id = props.load.chat.id;
            resultChat.user = chat.user.name;
            resultChat.userId = chat.user.id;
            resultChat.chatMember = chat;
          } else {
            resultChat.readed = chat.readed;
          }
        });
        return resultChat;
      }

      return resultChat;
    });

    const isQuotingUnassigned = computed(() => {
      let result = false;
      if (props.load.shipment.assignConfirmationRFP.length > 0) {
        props.load.shipment.assignConfirmationRFP.forEach(function (item: any) {
          result = item.status === 'EXPIRED';
        });
      }

      return result;
    });

    const loadCardStateBackground = computed(() => {
      if (props.state === 'QUOTING') return 'load-card-quoting';
      if (props.state === 'CONFIRMED') return 'load-card-confirmed';
      if (props.state === 'ASSIGNED') return 'load-card-assigned';
      if (props.state === 'IN_PROGRESS') return 'load-card-in-progress';
      if (props.state === 'FINISHED') return 'load-card-finished';
      if (props.state === 'CANCELED') return 'load-card-canceled';
      if (props.state === 'DELETED') return 'load-card-deleted';

      return 'load-card-default';
    });

    const stateTitle = computed(() => {
      let result = translate.t(props.state.toLowerCase());
      if (isQuotingUnassigned.value && props.state === 'QUOTING')
        result = translate.t('unassigned');
      return result;
    });

    const userRegion = computed(() => {
      return currentUser.value.company?.country?.region?.name;
    });

    const openChat = (chat: any) => {
      chat.readed = true;
      store.commit('LoadsStore/loadId', props.load.id);
      store.commit('ChatsStore/setUserForChat', chat);
      store.commit('ChatsStore/setChatId', chat.chat);
      store.commit('GlobalStore/setOpenChat', true);
    };

    const onOptionsClick = (type: string) => {
      emit('onOptionsClick', { type: type, id: props.load.id, load: props.load });
    };

    onMounted(() => {
      window.addEventListener('resize', resizeWindow);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeWindow);
    });

    const resizeWindow = () => {
      windowWidth.value = window.innerWidth;
    };

    return {
      ...toRefs(data),
      showUiComponents,
      onClickShowNewDetail,
      bestBid,
      chatsToShow,
      chatData,
      isCarrierFacade,
      isQuotingUnassigned,
      loadCardStateBackground,
      stateTitle,
      userRegion,
      windowWidth,
      onClick,
      openChat,
      openDetail,
      onOptionsClick,
    };
  },
});
