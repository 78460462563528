
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  components: {
    BaseModal,
    // PlanLoading,
    PlanButton,
  },
  mixins: [BaseComponent],
  emits: ['close'],
  props: {
    continueAllowed: { type: Boolean },
    showModal: { type: Boolean },
  },
  data() {
    return {
      loading: false as any,
    };
  },
  computed: {
    quoteForCarrier(): any {
      return this.$store.getters['RFPStore/getQuoteForCarrier'];
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
});
