import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "flex flex-wrap flex-col w-full items-start justify-end plan-gap-sm max-w-[1500px]"
}
const _hoisted_2 = { class: "w-full flex justify-between items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSignaturePad = _resolveComponent("VueSignaturePad")!
  const _component_plan_button = _resolveComponent("plan-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.signedRate)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "inline-flex top-6 bg-white absolute px-4 text-purple-600 ml-2 hover:text-purple-700 focus:text-purple-700 hover:bg-purple-100 focus:bg-purple-100 border border-purple-600 rounded-md mb-3",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)))
        }, _toDisplayString(_ctx.$t('undo')), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_VueSignaturePad, {
      width: "100%",
      height: "250px",
      ref: "signaturePad",
      class: "rounded border plan-border-prim-500",
      options: { onBegin: _ctx.onBegin, onEnd: _ctx.onEnd }
    }, null, 8, ["options"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_plan_button, {
        onOnClick: _ctx.undo,
        disabled: _ctx.loading,
        type: "cancel",
        label: _ctx.$t('cancel'),
        class: "w-full"
      }, null, 8, ["onOnClick", "disabled", "label"]),
      _createVNode(_component_plan_button, {
        onOnClick: _ctx.save,
        loading: _ctx.loading,
        type: "primary",
        label: _ctx.$t('save'),
        class: "w-full"
      }, null, 8, ["onOnClick", "loading", "label"])
    ])
  ]))
}