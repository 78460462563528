import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-1 xl:ml-90" }
const _hoisted_2 = { class: "mb-7 bg-white w-full" }
const _hoisted_3 = { class: "xl:container p-5 md:p-10" }
const _hoisted_4 = { class: "mb-10" }
const _hoisted_5 = { class: "text-corp font-bold text-3xl md:text-4xl" }
const _hoisted_6 = {
  class: "space-x-10 flex justify-start items-center",
  style: {"max-width":"1200px","margin":"0 auto"}
}
const _hoisted_7 = { class: "bg-red-400 text-white text-sm rounded-full flex justify-center items-center w-8 h-8 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('messages')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_router_link, {
            to: "unread",
            class: _normalizeClass(["md:text-3xl font-bold flex items-center space-x-3", { 'opacity-40  hover:opacity-100': _ctx.actualRoute !== 'unreadMessages' }])
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('unread')), 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.totalUnreadChats), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_router_link, {
            to: "conversations",
            class: _normalizeClass(["md:text-3xl font-bold transition-all", { 'opacity-40  hover:opacity-100': _ctx.actualRoute !== 'conversations' }])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('conversations')), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ])
      ])
    ]),
    _createElementVNode("section", null, [
      _createVNode(_component_router_view, { class: "p-5 md:p-10 md:pb-52" })
    ])
  ]))
}