import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["plan-header-search", _ctx.computedClasses])
  }, [
    _createVNode(_component_plan_icon, {
      name: "search",
      "is-svg": "",
      "icon-color": "#0097FF"
    }),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeQuery && _ctx.changeQuery(...args))),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.inputFocused = true)),
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inputFocused = false)),
      onKeyup: [
        _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.changeQuery && _ctx.changeQuery(...args)), ["enter"])),
        _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.changeQuery && _ctx.changeQuery(...args)))
      ],
      style: {"background":"rgba(0, 0, 0, 0)"},
      type: "text",
      placeholder: _ctx.placeholder
    }, null, 40, _hoisted_1), [
      [_vModelText, _ctx.query]
    ]),
    (_ctx.query.length > 0)
      ? (_openBlock(), _createBlock(_component_plan_icon, {
          key: 0,
          onClick: _ctx.cleanQuery,
          name: "close",
          class: "cursor-pointer",
          "is-svg": "",
          size: "15",
          "icon-color": "#4F5264"
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 2))
}