import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "load-card-initial-and-final-stop" }
const _hoisted_2 = { class: "load-card-initial-stop" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "date" }
const _hoisted_5 = { class: "load-card-final-stop" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "date" }
const _hoisted_8 = { class: "flex justify-center items-center plan-gap-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.pickupCity) + ", " + _toDisplayString(_ctx.pickupIsoCode) + " " + _toDisplayString(_ctx.pickupCt), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.formattedPickupDate.date), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.formattedPickupDate.hour), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.deliveryCity) + ", " + _toDisplayString(_ctx.deliveryIsoCode) + " " + _toDisplayString(_ctx.deliveryCt), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_ctx.dateTimeEta !== '')
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(_ctx.formattedDateTimeEta.date > _ctx.formattedDeliveryDate.date ? 'load-card-eta-red':'load-card-eta-sec')
              }, "ETA " + _toDisplayString(_ctx.formattedDateTimeEta.date), 3))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.formattedDeliveryDate.date), 1)
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.formattedDeliveryDate.hour), 1)
      ])
    ])
  ]))
}