
import { ref, computed, watchEffect, watch, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import i18n from '@/resources/locales';
import { Field, ErrorMessage } from 'vee-validate';

// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import { saveAs } from 'file-saver';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';

export default {
  mixins: [BaseComponent, CountryMixins],
  components: {
    PhoneNumberSelector,
    PlanDefaultModal,
    PlanButton,
    BaseModal,
    PlanButtonIcon,
    ExclamationCircleIcon,
    Field,
    ErrorMessage
  },
  props: {
    loadSelected: Object,
    open: Boolean,
    stopType: String,
  },
  setup(props, { emit }) {
    //* Global /
    const store = useStore();
    const $t = i18n.global.t;
    const route = useRoute();
    // Data
    const loadingPostDocuments = ref(false);
    const uploadDocumentAction = ref(false);
    const FILES = ref([] as any);
    const documentUploaded = ref(null);
    const mediaObjectUrls = ref([] as any);
    const stopData = ref(props.loadSelected as any);
    const data = reactive({
      driver: {} as any,
      showUpdateCarrierData: false,
    });


    // Computed

    const isDriver = computed(() => {
      return route.path.includes('driver');
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const checkDriver = computed(() => {
      return data.driver.email !== '' && data.driver.phonePrefix !== '' && data.driver.phone !== '';
    });

    const documentTypeUpload = computed(() => {
      switch (stopData?.value.type) {
        case 'PICKUP':
        case 'PICKUP_INITIAL':
          return $t('bol');
        case 'DELIVERY':
        case 'DELIVERY_FINAL':
          return $t('pod');
        default:
          return '';
      }
    });

    // Methods

    const changePhone = (type, phone) => {
      switch (type) {
        case 'prefix':
          data.driver.phonePrefix = phone.prefix;
          break;
        case 'number':
          data.driver.phone = phone.number;
          break;
        default:
          return;
      }
    };

    const onClickClose = () => {
      emit('close');
    };

    const handleFileChange = (event) => {
      FILES.value = event.target.files;
    };

    const handleShowRequestModal = () => {
      if (shipment.value?.driver?.email) {
        store
          .dispatch(
        'LoadsStore/sendEmailDriver',
              stopData.value.id,
          )
          .then((response) => {
            store.dispatch(
              'UserStore/showToast',
              $t('email-sended-driver'),
            );
          });
      } else {
        //Check Pre registered data
        data.driver.phonePrefix = shipment.value?.driver?.prefix || '1';
        data.driver.phone = shipment.value?.driver?.phone || '';
        data.driver.showContactInformation = shipment.value?.driver?.showContactInformation;

        data.showUpdateCarrierData = true;
      }
    };


    const removeFile = (index: number): void => {
      const filesArray = Array.from(FILES.value);
      filesArray.splice(index, 1);
      FILES.value = filesArray;
    };

    const getDocumentsUploaded = () => {
      let getDocumentsUploaded = document.getElementById('getDocumentsUploaded');
      if (getDocumentsUploaded) getDocumentsUploaded.click();
    };

    const fileChange = (event) => {
      for (const file of event.target.files) {
        if (file.type !== 'application/pdf') {
          FILES.value[URL.createObjectURL(file)] = file;
        }
      }
    };

    const UploadDocuments = async () => {
      if (!uploadDocumentAction.value) {
        uploadDocumentAction.value = true;

        if (Object.keys(FILES.value).length !== 0) {
          try {
            loadingPostDocuments.value = true;
            const files = Object.values(FILES.value);
            for (let i = 0; i < files.length; i++) {
              try {
                await store.dispatch('ShipmentStore/postMediaObjectStops', {
                  entityId: props?.loadSelected?.id,
                  file: files[i],
                });
              } catch (error) {
                const errorResponse = error as { response: { data: { detail: string } } };
                BaseComponent.methods?.showToastError(errorResponse?.response?.data?.detail);
              } finally {
              }
              await new Promise((resolve) => setTimeout(resolve, 500));
            }

            FILES.value = {} as any;
          } catch (error) {
            const errorResponse = error as { response: { data: { detail: string } } };
            BaseComponent.methods?.showToastError(errorResponse?.response?.data?.detail);
          } finally {
            uploadDocumentAction.value = false;
            BaseComponent.methods?.showToastSuccess($t('documents-uploaded-done'));
            emit('fetch');
            uploadDocumentAction.value = false;
            onClickClose();
          }
        } else {
          BaseComponent.methods?.showToastError($t('information-required'));
        }
      }
    };

    const UpdateCarrier = () => {
      store
        .dispatch('CompanyStore/putDriver', {
          id: shipment.value.driver.id,
          params: {
            email: data.driver.email,
            phonePrefix: data.driver.prefix || '1',
            phone: data.driver.phone,
            showContactInformation: data.driver.showContactInformation,
          },
        })
        .then(async () => {
          await store.dispatch('ShipmentStore/shipment', { id: shipment.value.id });
          store.dispatch('UserStore/showToast', $t('Updated Correctly.')).then(() => {
            data.showUpdateCarrierData = false;
          });
        });
    };

    const viewDocumentUploaded = async (id, entityClass) => {
      const result = await store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: id,
        class: entityClass,
      });

      const url = URL.createObjectURL(result);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${''}`;
        const blob = new Blob([result], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    };

    const getMediaObjectOfAdditionalFee = async (id: number) => {
      const result = await store.dispatch('ShipmentStore/thumbnailBig', {
        id: id,
        class: 'STOP',
      });

      mediaObjectUrls.value.push({ url: URL.createObjectURL(result), id: id });
    };

    if (props?.loadSelected?.mediaObjects.length > 0) {
      props?.loadSelected?.mediaObjects.forEach(function (mediaObject: any) {
        getMediaObjectOfAdditionalFee(mediaObject.id);
      });
    }

    const shipment = computed(() => {
      return store.getters['ShipmentStore/shipment'];
    });

    return {
      ...toRefs(data),
      isDriver,
      uploadDocumentAction,
      loadingPostDocuments,
      FILES,
      documentUploaded,
      currentUser,
      checkDriver,
      fileChange,
      handleFileChange,
      handleShowRequestModal,
      UploadDocuments,
      UpdateCarrier,
      mediaObjectUrls,
      getMediaObjectOfAdditionalFee,
      getDocumentsUploaded,
      removeFile,
      documentTypeUpload,
      onClickClose,
      viewDocumentUploaded,
      shipment,
      changePhone,
    };
  },
};
