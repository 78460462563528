
import { defineComponent, nextTick, reactive, toRefs, computed } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import { UserRoles } from '@/services/user/UserTypes';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import BaseComponent from '@/base/BaseComponent';

interface signingView {
  agreeTermsConditions: boolean,
  loading: boolean,
  loadingSendEmail: boolean,
  email: string,
  emailSent: boolean,
  password: string,
  showLogin: boolean,
  showPassword: boolean,
  urls: {
    privacy: string,
    terms: string
  }
}

export default defineComponent({
  name: 'SigningView',
  components: { PlanIcon },
  setup () {
    const store = useStore()
    const router = useRouter()
    const translate = useI18n()

    const data = reactive<signingView>({
      agreeTermsConditions: false,
      loading: false,
      loadingSendEmail: false,
      email: '',
      emailSent: false,
      password: '',
      showLogin: true,
      showPassword: false,
      urls: {
        privacy: 'https://www.planimatik.com/privacy-policy?__hstc=171743039.61946906491746eea468b8df2b97b5ee.1686845245471.1686845245471.1686847406765.2&__hssc=171743039.1.1686847406765&__hsfp=370058895',
        terms: 'https://www.planimatik.com/terms-of-service?__hstc=171743039.61946906491746eea468b8df2b97b5ee.1686845245471.1686845245471.1686847406765.2&__hssc=171743039.12.1686847406765&__hsfp=370058895'
      }
    })

    const currentUser = computed(() => {
      if (store.getters['UserStore/getCurrentUserImpersonating'] && store.getters['UserStore/getImpersonatedUserId']) {
        return store.getters['UserStore/getCurrentUserImpersonating']
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    })

    const signIn = () => {
      if (data.email.length === 0 || data.password.length === 0) return
      data.loading = true
      store.dispatch('AuthStore/signIn', {
        username: data.email.trim(),
        password: data.password,
      }).then(() => {
        data.loading = false
        checkUserType()
      }).catch((error) => {
        store.dispatch('UserStore/updateToast', {
          text: translate.t('error-signing'),
          type: 'error',
          visible: true
        })
        data.loading = false
      })
    }

    const checkUserType = () => {
      if (currentUser.value && currentUser.value.roles) {
        let isShipper = false

        currentUser.value.roles.forEach((role) => {
          if (role.includes(UserRoles.SHIPPER)) {
            isShipper = true;
            router.push('/planning');
          }
          if (role.includes(UserRoles.ADMIN)) {
            isShipper = true;
            router.push('/superadmin');
          }
        })
      }
    }

    const sendMail = () => {
      data.loadingSendEmail = true
      store.dispatch('UserStore/emailResetPassword', { email: data.email }).then(() => {
        store.dispatch('UserStore/showToast', translate.t('email-sent'))
        data.loadingSendEmail = true
        data.emailSent = true
        data.loadingSendEmail = false
      }).catch((error) => {
        store.dispatch('UserStore/updateToast', {
          text: error?.response?.data?.detail,
          type: 'error',
          visible: true
        })
      })
    }

    return {
      ...toRefs(data),
      signIn,
      sendMail
    }
  }
});
