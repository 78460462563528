import { initialState } from '@/services/shipment/_store/index';

export default {
  companyAccepted(state: any, payload: any) {
    state.companyAccepted = payload;
  },
  clearShipmentStore(state: any) {
    Object.assign(state, initialState);
  },
  getImage(state: any, payload: any) {
    state.getImage = payload;
  },
  getSavedShipmentBids(state: any, payload: any) {
    state.getSavedShipmentBids = payload;
  },
  shipment(state: any, payload: any) {
    state.shipment = payload;
  },
  setActivitySummary(state: any, payload: any) {
    state.activitySummary = payload;
  },
  totalBids(state: any, payload: any) {
    state.totalBids = payload;
  },
};
