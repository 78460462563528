<template>
  <section class="flex flex-col bg-gradient-to-b from-[#002C4D] to-[#003A4D] pt-4 min-h-screen min-w-fit">
    <slot name="header">
      <div class="bg-corp rounded-full mx-4 flex justify-center md:justify-center items-center">
        <a
          href="#"
          class="flex items-center text-5xl font-bold py-6 text-gray-900 dark:text-white"
        >
          <img
            class="hidden md:block max-w-[300px]"
            alt="logo"
            :src="getAsset.imgLogoV2"
          />
          <img
            class="block md:hidden max-w-[150px] xs:max-w-[200px] ml-4"
            alt="logo"
            :src="getAsset.imgLogoV2"
          />
        </a>
      </div>
    </slot>

    <slot name="main">

    </slot>

    <slot name="footer">
      <footer class="mt-auto bg-corp grid gap-4 grid-cols-3 grid-rows-1">
        <div class="flex justify-center items-center h-20 w-max-[33rem]">
          <div
            class="text-xs sm:text-base text-white"
            style="text-transform: uppercase"
          >
            {{ $t('contact-us').toUpperCase() }}<br />
            <a
              :href="`mailto:${contactEmail}`"
              rel="noopener"
            >
              {{ contactEmail }}
            </a>
          </div>
        </div>
        <div class="flex justify-center items-center h-20 w-max-[33rem]">
          <span>
            <div
              class="text-xs sm:text-base text-white"
              style="text-align: center; text-transform: uppercase"
            >
              <a
                :href="URLPrivacy"
                rel="noopener"
              >
                {{ $t('privacy-police').toUpperCase() }}
              </a>
              <br />
              <a
                :href="URLTerms"
                rel="noopener"
              >
                {{ $t('terms-and-conditions').toUpperCase() }}
              </a>
            </div>
          </span>
        </div>
        <div class="flex justify-center items-center h-20 w-max-[33rem]">
          <div
            class="text-xs sm:text-base text-white"
            style="text-align: right; text-transform: uppercase"
          >
            <span>
              <a
                :href="`tel:${contactPhone}`"
                rel="noopener"
              >
                {{ contactPhone }}
              </a><br />
              miami &gt; usa
            </span>
          </div>
        </div>
      </footer>
    </slot>
  </section>
</template>

<script>
import { defineComponent, ref } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Services
import * as Global from '@/services/global/GlobalConst';

export default defineComponent({
  name: 'AuthTemplate',
  mixins: [BaseComponent],
  setup() {
    const contactEmail = ref(Global.CONTACT_EMAIL);
    const contactPhone = ref(Global.CONTACT_PHONE);
    const URLPrivacy = ref(Global.URL_PRIVACY);
    const URLTerms = ref(Global.URL_TERMS);

    return {
      contactEmail,
      contactPhone,
      URLPrivacy,
      URLTerms,
    }
  }
});
</script>

<style scoped>

</style>
