
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
// Base
import BaseComponent from '@/base/BaseComponent';
// Types
import { RFPStatus } from '@/modules/rfp/RFPTypes';
import SetMaxResponseTimeModal from '@/modules/rfp/_components/modals/SetMaxResponseTimeModal.vue';

export default defineComponent({
  components: {
    SetMaxResponseTimeModal,
  },
  mixins: [BaseComponent],
  props: {
    origin: {
      type: Object,
      default: null,
    },
    destiny: {
      type: Object,
      default: null,
    },
  },
  data() {
    const router = useRouter();
    return {
      router,
      showResponseMaxTimeModal: false,
    };
  },
  computed: {
    currentLane(): any {
      return this.$store.getters['RFPStore/getCurrentLane'];
    },
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
  },
  methods: {
    callbackFetchLanesDetail() {
      const projectId = this.$route.params.projectId;

      this.$store.dispatch('RFPStore/getProjectById', {
        projectId: projectId,
        page: 1,
      });

      this.$store.dispatch('RFPStore/getLanesByProjectId', {
        projectId: projectId,
        page: 1,
      });
    },
    callbackToggleResponseTimeModal() {
      this.showResponseMaxTimeModal = !this.showResponseMaxTimeModal;
    },
    isStatusDraft() {
      return this.currentProject?.project?.status === RFPStatus.DRAFT;
    },
    onClickBack() {
      const projectId = this.$route.params.projectId;
      return this.router.push({ name: 'ProjectLaneDetail', params: { projectId: projectId } });
    },
  },
});
