import DayDetail from '@/modules/planning/calendar/DayDetail.vue';
import LoadsList from '@/modules/superAdmin/LoadsList.vue';
import RequestDemoList from '@/modules/superAdmin/RequestDemoList.vue';
import ShippersList from '@/modules/superAdmin/ShippersList.vue';
import SuperAdmin from '@/modules/superAdmin/SuperAdmin.vue';
import ShipperDashboard from '@/modules/planning/dashboard/ShipperDashboard.vue';

const SuperAdminRouter = [
  {
    path: '/superadmin',
    name: 'superadmin',
    component: SuperAdmin,
    redirect: { name: 'shippersList' },
    children: [
      {
        path: 'shipperslist',
        name: 'shippersList',
        component: ShippersList,
        meta: {
          activeButton: 'shippersList',
        },
      },
      {
        path: 'requestDemoList',
        name: 'requestDemoList',
        component: RequestDemoList,
        meta: {
          activeButton: 'requestDemoList',
        },
      },
      {
        path: 'dashboard',
        name: 'superAdminDashboard',
        component: ShipperDashboard,
        meta: {
          activeButton: 'superAdminDashboard',
        },
      },
      {
        path: 'loadsList',
        name: 'loadsList',
        redirect: { name: 'loadsList' },
        meta: {
          activeButton: 'loadsList',
        },
        children: [
          {
            path: '',
            name: 'loadsListAdmin',
            component: LoadsList,
          },
          {
            path: ':id',
            name: 'loadDetailAdmin',
            component: LoadsList,
            props: true,
          },
        ],
      },
      {
        path: 'day/:date',
        name: 'dayDetailAdmin',
        component: DayDetail,
        props: true,
        children: [
          {
            path: ':id',
            name: 'dayDetailAdminId',
            component: DayDetail,
            props: true,
          },
        ],
      },
    ],
  },
];

export default SuperAdminRouter;
