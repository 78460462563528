
import { defineComponent, reactive, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useRoute } from 'vue-router';
import router from '@/router';

export default defineComponent({
  name: 'PlanToast',
  components: { PlanIcon },
  props: {
    text: { type: String, default: '' },
    textList: { type: Array, default: null },
    type: { type: String, default: 'success' },
    id: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();

    const data = reactive({
      showToast: true,
      animateClass: 'fade-in-up',
    });

    const toastStyles = computed(() => {
      const carrierRoutes: Array<any> = [
        'CarrierAuthentication',
        'CarrierLoadDetails',
        'CarrierLoadAcceptation',
        'CarrierBase',
        'CarrierLoadAccepted',
        'CarrierSignOrder',
        'CarrierLoadAssigned',
      ];

      let backgroundToShow = '#151830';
      let widthToShow = '480px';
      let leftToShow = 'calc((100vw - 480px)/2)';

      if (carrierRoutes.includes(route.name)) {
        const actualWidthInCarrier = store.getters['CarrierStore/getActualWidth'];
        if (actualWidthInCarrier <= 640 && actualWidthInCarrier > 375) {
          widthToShow = '300px';
          leftToShow = 'calc((100vw - 300px)/2)';
        } else if (actualWidthInCarrier <= 375) {
          widthToShow = '355px';
          leftToShow = '0px';
        }
      }

      if (props.type === 'success') backgroundToShow = '#00A63D';
      if (props.type === 'error') backgroundToShow = '#EF4444';
      if (props.type === 'warning') backgroundToShow = '#B38002';
      if (props.type === 'info') backgroundToShow = '#0071BF';

      return {
        background: backgroundToShow,
        width: widthToShow,
        left: leftToShow,
        maxWidth: '100%',
      };
    });

    const hideToast = () => {
      data.animateClass = 'fade-out-down';
      setTimeout(() => {
        data.showToast = false;
        store.dispatch('UserStore/updateToast', {
          text: '',
          type: '',
          visible: false,
        });
      }, 3000);
    };

    const beforeEnter = (el: HTMLElement) => {
      el.classList.add('fade-in-up');
    };

    const beforeLeave = (el: HTMLElement) => {
      el.classList.add('fade-out-down');
    };

    const afterLeave = (el: HTMLElement) => {
      el.classList.remove('fade-out-down');
    };

    const routingTo = (id) => {
      router.push({ name: 'planificationDetail', params: { id: id } });
    };

    setTimeout(hideToast, 4000);

    return {
      ...toRefs(data),
      toastStyles,
      hideToast,
      beforeEnter,
      beforeLeave,
      afterLeave,
      routingTo,
    };
  },
});
