
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import HeaderLaneDetails from '@/modules/rfp/_components/HeaderLaneDetails.vue';
import FileContainer from '@/modules/rfp/lanesCreationType/ShowImportFileContainer.vue';
import store from '@/services/store';

export default defineComponent({
  components: {
    FileContainer,
    HeaderLaneDetails,
  },
  props: {
    projectId: Number,
  },
  setup(props) {
    const currentType = ref('createFromInterface');
    const ShowImportFile = ref(false);
    const router = useRouter();
    const route = useRoute();

    onBeforeMount(() => {
      getCurrentProject();
    });

    const nextStep = () => {
      if (currentType.value === 'createFromExel') {
        ShowImportFile.value = true;
      } else {
        store.commit('RFPStore/setCurrentEditingLanes', []);
        const { projectId } = props;
        router.push({ name: 'addLanesRFP', params: { projectId } });
      }
    };

    const getCurrentProject = () => {
      store.dispatch('RFPStore/getProjectById', {
        projectId: +route.params.projectId,
        page: 1,
      });
    };

    const hideImportFile = () => {
      ShowImportFile.value = false;
    };

    return {
      currentType,
      getCurrentProject,
      hideImportFile,
      nextStep,
      ShowImportFile,
    };
  },
});
