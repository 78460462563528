
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateSpin.vue';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';
import PlanSelectSearch from '@/components/forms/PlanSelectSearch.vue';
// Utils
import { timezones } from '@/services/timezones/index';

interface ICompany {
  company: {
    type: string;
    name: string;
    location: {
      address: string;
      city: string;
      state: {
        id: number;
      };
    };
  };
}

interface IUser {
  name: string;
  email: string;
  surname: string;
  prefix: string;
  phone: number;
  company: ICompany;
  plainPassword: string;
  hashNewRegister: string;
}

export default defineComponent({
  name: 'SignupView',
  components: {
    ErrorMessage,
    Field,
    Form,
    PhoneNumberSelector,
    PlanSelectSearch,
    Spin,
  },
  mixins: [BaseComponent],
  data() {
    return {
      name: '',
      surname: '',
      prefix: '1',
      phone: '',
      company: '',
      address: '',
      state: '',
      city: '',
      email: '',
      password: '',
      accountingEmail: '',
      repeatPassword: '',
      timeZone: '',
      hash: '',
      error: false,
      notShipper: false,
      loading: false,
      region: 1,
      differentPassword: false,
    };
  },
  created() {
    this.$store.dispatch('CountryStore/getCountries');
    this.$store.dispatch('LoadsStore/states');
    const url = window.location.href.toString().split('/');
    this.hash = url[5];
    this.email = decodeURIComponent(url[6]);
    const dtf = new Intl.DateTimeFormat();
    this.timeZone = dtf.resolvedOptions().timeZone;
  },
  computed: {
    countriesList(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
    timezones(): any {
      return timezones.map((time) => {
        return {
          label: time.label,
          value: time.tzCode,
        };
      });
    },
  },
  methods: {
    changePhone(type, phone) {
      switch (type) {
        case 'prefix':
          this.prefix = phone.prefix;
          break;
        case 'number':
          this.phone = phone.number;
          break;
        default:
          return;
      }
    },
    signIn() {
      this.$store
        .dispatch('AuthStore/signIn', {
          username: this.email,
          password: this.password,
        })
        .catch((err) => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
          // this.checkUserType();
          this.$router.push('/planning');
        });
    },
    signUp() {
      this.loading = true;
      this.error = false;
      const data = {
        name: this.name,
        email: this.email,
        surname: this.surname,
        phonePrefix: this.prefix,
        phone: this.phone,
        company: {
          type: 'SHIPPER',
          name: this.company,
          accountingEmail: this.accountingEmail,
          location: {
            address: this.address,
            city: this.city,
            state: {
              id: this.state,
            },
          },
          country: { id: Number(this.region) },
        },
        plainPassword: this.password,
        hashNewRegister: this.hash,
        timeZone: this.timeZone,
      };
      this.$store
        .dispatch('UserStore/newShipperRegister', data)
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('user-registered'));
          this.signIn();
        })
        .catch((err) => {
          this.error = true;
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          this.loading = false;
        });
    },
    timezoneOnChange(value) {
      this.timeZone = value;
    },
    async validateForm() {
      this.differentPassword = false;

      const validation = await (this.$refs.registerForm as any).validate();
      if (this.password !== this.repeatPassword) {
        this.differentPassword = true;
      }
      if (!validation.valid || this.differentPassword) {
        return;
      }
      this.signUp();
    },
  },
});
