
import { defineComponent, ref, computed } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
export default defineComponent({
  name: 'PlanDefaultWindowsTab',
  components: { PlanIcon },
  props: {
    tabs: { type: Array, required: true },
    align: { type: String, required: false, default: 'left' }
  },
  emits: ['changeTab'],
  setup (props, { emit }) {
    const selectedValue = ref(0)
    const selectedHover = ref(0)

    const tabsAlign = computed(() => {
      let result = 'justify-start'
      if (props.align === 'center') result = 'justify-center'
      if (props.align === 'around') result = 'justify-around'
      if (props.align === 'between') result = 'justify-between'
      if (props.align === 'right') result = 'justify-end'
      return result
    })

    const badgeType = (index: number) => {
      let result = 'gray-400'
      if (selectedHover.value === index) result = 'primary-600'
      if (selectedValue.value === index) result = 'primary-dark'
      return result
    }

    const selectTab = (tab: any, index: number) => {
      if (!tab.disabled) {
        selectedValue.value = index
        emit('changeTab', index)
      }
    }

    return {
      selectedHover,
      selectedValue,
      tabsAlign,
      badgeType,
      selectTab

    }
  }
})
