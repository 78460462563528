import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  laneQuotesTotals: null as any,
  currentActiveGroup: null,
  laneQuotes: [],
  laneRanking: [],
  lanesGroups: [],
  lastLaneListPeriod: [],
  editingLanes: [],
  lanes: [],
  laneData: [],
  bidsByCompany: [],
  getCurrentTendersByGroup: [],
  lanesTotals: null,
  lanesTitle: '',
  filtersLanes: null as any,
  activeGroup: null as any,
  groupBids: null as any,
  showModalStarted: false,
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
