<template>
  <input
    ref="autocomplete"
    type="text"
    class="datepicker w-full border border-gray-300 bg-gray-text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex p-2.5 pr-4"
    :class="{ 'border-red-500': error }"
    :style="{ 'background-color': backgroundColor }"
    :id="id"
    :disabled="disabled"
    value="autocompleteText"
    :types="'(regions)'"
    @focus="onFocus()"
    @blur="onBlur()"
    @change="onChange"
    @keypress="onKeyPress"
    @keyup="onKeyUp"
  />
</template>

<script>
// Base
import BaseComponent from '@/base/BaseComponent';

const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  sublocality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  administrative_area_level_3: 'long_name',
  country: 'long_name',
  postal_code: 'short_name',
};

const CITIES_TYPE = ['locality', 'administrative_area_level_3', 'sublocality'];
const REGIONS_TYPE = [
  'locality',
  'sublocality',
  'postal_code',
  'country',
  'administrative_area_level_1',
  'administrative_area_level_2',
];

/*
      By default, we're only including basic place data because requesting these
      fields place data is not additionally charged by Google. Please refer to:

      https://developers.google.com/maps/billing/understanding-cost-of-use#basic-data
    */
const BASIC_DATA_FIELDS = [
  'address_components',
  'adr_address',
  'alt_id',
  'formatted_address',
  'geometry',
  'icon',
  'id',
  'name',
  'business_status',
  'photo',
  'place_id',
  'scope',
  'type',
  'url',
  'utc_offset_minutes',
  'vicinity',
];

export default {
  name: 'VueGoogleAutocomplete',
  mixins: [BaseComponent],
  props: {
    id: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: 'rgb(249, 250, 251)', //bg-gray-50
    },
    index: {
      type: Number,
      default: 0,
    },
    lane: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },

    types: {
      type: String,
      default: 'address',
    },

    fields: {
      type: Array,
      default: function () {
        return BASIC_DATA_FIELDS;
      },
    },

    country: {
      type: [String, Array],
      default: null,
    },

    enableGeolocation: {
      type: Boolean,
      default: false,
    },

    geolocationOptions: {
      type: Object,
      default: null,
    },
  },
  mounted: function () {
    if (this.currentCountry) {
      this.mountInput();
    }
  },
  data() {
    return {
      /**
       * The Autocomplete object.
       *
       * @type {Autocomplete}
       * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
       */
      autocomplete: null,

      /**
       * Autocomplete input text
       * @type {String}
       */
      autocompleteText: '',

      geolocation: {
        /**
         * Google Geocoder Objet
         * @type {Geocoder}
         * @link https://developers.google.com/maps/documentation/javascript/reference#Geocoder
         */
        geocoder: null,

        /**
         * Filled after geolocate result
         * @type {Coordinates}
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
         */
        loc: null,

        /**
         * Filled after geolocate result
         * @type {Position}
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Position
         */
        position: null,
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters['UserStore/getCurrentUser'];
    },
  },
  methods: {
    updateComponentRestrictions(newCountry) {
      if (this.autocomplete) {
        this.autocomplete.setComponentRestrictions({ country: newCountry });
      }
    },
    mountInput() {
      let options = {};

      if (this.types) {
        options.types = [this.types];
      }
      let countries = [];
      const userLanguage = navigator.language.substring(0, 2);

      //todo refactor
      if (this.currentCountry) {
        countries = this.getLocale.substring(0, 2) === 'es' ? ['es'] : ['us'];
      }

      if (!this.currentCountry) {
        countries = userLanguage === 'es' ? ['es'] : ['us'];
      }

      if (this.country) {
        countries = [this.country];
      }

      options.componentRestrictions = {
        country: countries,
      };

      this.autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById(this.id),
        options,
      );

      this.autocomplete.setFields(this.fields);
      this.autocomplete.addListener('place_changed', this.onPlaceChanged);
    },
    /**
     * When a place changed
     */
    async onPlaceChanged() {
      let place = this.autocomplete.getPlace();

      // ADDED FOR RETURN ISO FOR WAREHOUSES
      if (this.$route.name === 'StepsContainer' || this.$route.name === 'planification') {
        let isoCountryCode;
        if (place?.address_components) {
          for (let i = 0; i < place.address_components.length; i++) {
            let addressComponent = place.address_components[i];
            if (addressComponent.types.includes('country')) {
              isoCountryCode = addressComponent.short_name;
            }
          }
        }

        // Added for warehouses Id
        this.$store.dispatch('LoadsStore/updateShipmentNextStep', false);

        console.log('here');

        setTimeout(() => {
          this.$emit('handleChange', place.name, isoCountryCode);
        }, 250);
      }

      const postalCode = place.address_components.find((comp) =>
        comp.types.includes('postal_code'),
      );

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('no-results-found', place, this.id);
        return;
      }

      if (place.address_components !== undefined) {
        // return returnData object and PlaceResult object
        this.$emit('placechanged', this.formatResult(place), place, this.id, this.index, this.lane);

        // update autocompleteText then emit change event
        this.autocompleteText = postalCode ? document.getElementById(this.id).value : '';
        this.onChange();
      }
    },
    /**
     * When the input gets focus
     */
    onFocus() {
      this.biasAutocompleteLocation();
      this.$emit('focus');
    },
    /**
     * When the input loses focus
     */
    onBlur() {
      this.$emit('blur');
    },
    /**
     * When the input got changed
     */
    onChange() {
      this.$emit('change', this.autocompleteText);
    },
    /**
     * When a key gets pressed
     * @param  {Event} event A keypress event
     */
    onKeyPress(event) {
      this.$emit('keypress', event);
    },
    /**
     * When a keyup occurs
     * @param  {Event} event A keyup event
     */
    onKeyUp(event) {
      this.$emit('keyup', event);
    },
    /**
     * Clear the input
     */
    clear() {
      this.$refs.autocomplete.value = '';
    },
    /**
     * Focus the input
     */
    focus() {
      this.$refs.autocomplete.focus();
    },
    /**
     * Blur the input
     */
    blur() {
      this.$refs.autocomplete.blur();
    },
    /**
     * Update the value of the input
     * @param  {String} value
     */
    update(value) {
      this.autocompleteText = value;
    },
    /**
     * Update the coordinates of the input
     * @param  {Coordinates} value
     */
    updateCoordinates(value) {
      if (!value && !(value.lat || value.lng)) return;
      if (!this.geolocation.geocoder) this.geolocation.geocoder = new window.google.maps.Geocoder();
      this.geolocation.geocoder.geocode({ location: value }, (results, status) => {
        if (status === 'OK') {
          results = this.filterGeocodeResultTypes(results);
          if (results[0]) {
            this.$emit('placechanged', this.formatResult(results[0]), results[0], this.id);
            this.update(results[0].formatted_address);
          } else {
            this.$emit('error', 'no result for provided coordinates');
          }
        } else {
          this.$emit('error', 'error getting address from coords');
        }
      });
    },
    /**
     * Update location based on navigator geolocation
     */
    geolocate() {
      this.updateGeolocation((geolocation, position) => {
        this.updateCoordinates(geolocation);
      });
    },
    /**
     * Update internal location from navigator geolocation
     * @param  {Function} (geolocation, position)
     */
    updateGeolocation(callback = null) {
      if (navigator.geolocation) {
        let options = {};
        if (this.geolocationOptions) Object.assign(options, this.geolocationOptions);
        navigator.geolocation.getCurrentPosition(
          (position) => {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.geolocation.loc = geolocation;
            this.geolocation.position = position;

            if (callback) callback(geolocation, position);
          },
          (err) => {
            this.$emit('error', 'Cannot get Coordinates from navigator', err);
          },
          options,
        );
      }
    },
    // Bias the autocomplete object to the user's geographical location,
    // as supplied by the browser's 'navigator.geolocation' object.
    biasAutocompleteLocation() {
      if (this.enableGeolocation) {
        this.updateGeolocation((geolocation, position) => {
          let circle = new window.google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy,
          });
          this.autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    /**
     * Format result from Geo google APIs
     * @param place
     * @returns {{formatted output}}
     */
    formatResult(place) {
      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        for (let index = 0; index < place.address_components[i].types.length; index++) {
          let addressType = place.address_components[i].types[index];

          if (ADDRESS_COMPONENTS[addressType]) {
            if (addressType === 'administrative_area_level_1') {
              const val = place.address_components[i]['long_name'];
              if (!returnData.administrative_area_level_2) {
                returnData['administrative_area_level_2'] = val;
              }
              if (!returnData.locality) {
                returnData['locality'] = val;
              }
            }
            let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
            returnData[addressType] = val;
          }
        }
      }

      // When locality undefined, set the administrative_area_level_3 as locality

      if (
        returnData['locality'] === undefined &&
        returnData['administrative_area_level_3'] !== undefined
      ) {
        returnData['locality'] = returnData['administrative_area_level_3'];
      }

      // When locality undefined, set the subloacality as locality
      if (returnData['locality'] === undefined && returnData['sublocality'] !== undefined) {
        returnData['locality'] = returnData['sublocality'];
      }

      if (returnData['locality'] === undefined && place['formatted_address'] !== undefined) {
        returnData['locality'] = place['formatted_address'].split(',')[0];
      }

      if (returnData['locality'] === returnData['administrative_area_level_1']) {
        returnData['locality'] = returnData['administrative_area_level_3'];
      }

      returnData['place_id'] = place.place_id;
      returnData['latitude'] = place.geometry.location.lat();
      returnData['longitude'] = place.geometry.location.lng();

      return returnData;
    },
    /**
     * Extract configured types out of raw result as
     * Geocode API does not allow to do it
     * @param results
     * @returns {GeocoderResult}
     * @link https://developers.google.com/maps/documentation/javascript/reference#GeocoderResult
     */
    filterGeocodeResultTypes(results) {
      if (!results || !this.types) return results;
      let output = [];
      let types = [this.types];
      if (types.includes('(cities)')) types = types.concat(CITIES_TYPE);
      if (types.includes('(regions)')) types = types.concat(REGIONS_TYPE);

      for (let r of results) {
        for (let t of r.types) {
          if (types.includes(t)) {
            output.push(r);
            break;
          }
        }
      }
      return output;
    },
  },
  watch: {
    autocompleteText: function (newVal, oldVal) {
      this.$emit('inputChange', { newVal, oldVal }, this.id);
    },
    currentCountry() {
      this.mountInput();
    },
    country: function (newCountry) {
      this.$emit('clearInput');
      this.autocomplete.set('place_changed', null);
      this.$refs.autocomplete.value = '';
      this.updateComponentRestrictions(newCountry);
    },
  },
};
</script>
<style lang="scss">
.pac-container {
  width: auto !important;
  z-index: 99999999999 !important;
}
</style>
