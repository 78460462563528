
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import EmailTemplateForm from '@/modules/planning/contacts/BlastEmail/_forms/EmailTemplateForm.vue';
import { useStore } from 'vuex';
import PlanLoading from '@/components/spin/PlanLoading.vue';

interface updateTemplateModal {
  category: number | null,
  loadingData: boolean,
  loadingPut: boolean,
  templateName: string,
  subject: string,
  message: string
}

export default defineComponent({
  name: 'UpdateTemplateModal',
  components: { EmailTemplateForm, PlanButton, PlanDefaultModal },
  emits: ['close'],
  props: {
    id: { type: Number, required: true, default: -1 }
  },
  setup (props, {emit}) {
    const store = useStore()

    const data = reactive<updateTemplateModal>({
      category: null,
      loadingData: false,
      loadingPut: false,
      templateName: '',
      subject: '',
      message: ''
    })
    const close = () => { emit('close') }

    const putEmailTemplate = () => {
      data.loadingPut = true
      store.dispatch('CompanyStore/putEmailTemplate', {
        id: store.getters['CompanyStore/getSelectedTemplate'].id,
        category: data.category,
        templateName: data.templateName,
        subject: data.subject,
        message: data.message
      }).then((response) => {
        store.commit('CompanyStore/selectTemplate', response)
        emit('close')
      }).finally(() => {
        data.loadingPut = false
      })
    }

    const update = (newData: any) => {
      data.category = newData.category
      data.templateName = newData.templateName
      data.subject = newData.subject
      data.message = newData.message
    }

    const validate = computed(() => {
      return data.templateName.length > 0 && data.subject.length > 0 && data.message.length > 0
    })

    data.templateName = store.getters['CompanyStore/getSelectedTemplate'].name
    data.subject = store.getters['CompanyStore/getSelectedTemplate'].subject
    data.message = store.getters['CompanyStore/getSelectedTemplate'].message

    return {
      ...toRefs(data),
      close,
      putEmailTemplate,
      update,
      validate
    }
  }
})
