
import { defineComponent, reactive, computed } from 'vue';

export default defineComponent({
  name: 'PlanLegsSelector',
  props: {
    loadTemplate: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const data = reactive({
      selectedLeg: 0,
      legs: [
        { id: 0, title: 'LEG 1 - MX domestic', tender: '' },
        { id: 1, title: 'LEG 2 - Transfer MX > USA', tender: '' },
        { id: 2, title: 'LEG 3 - USA domestic', tender: '' },
      ],
    });

    const selectLeg = (leg: number) => {
      data.selectedLeg = leg;
      emit('handleChangeLeg', leg);
    };

    const getLegClass = (legId: number) => {
      return {
        'bg-blue-500 text-white': data.selectedLeg === legId,
        'bg-white text-gray-600': data.selectedLeg !== legId,
      };
    };

    return {
      ...data,
      selectLeg,
      getLegClass,
    };
  },
});
