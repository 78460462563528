
import { defineComponent } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'PlanDefaultLoadingModal',
  components: { PlanDefaultModal, PlanIcon },
  props: {
    title: { type: String, required: true, default: '' },
    message: { type: String, required: true, default: '' }
  }
})
