
import { computed, defineComponent, ref, onMounted, onUnmounted } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import moment from 'moment';
import PlanButton from '@/components/buttons/PlanButton.vue';
import CountryMixins from '@/services/country/_mixins/index';
import { useStore } from 'vuex';
import { saveAs } from 'file-saver';

export default defineComponent({
  name: 'LoadTimeline',
  mixins: [CountryMixins],
  computed: {
    moment() {
      return moment;
    },
  },
  components: { PlanButton, PlanIcon },
  props: {
    stops: { type: Array, required: true },
    detail: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const store = useStore();
    const windowWidth = ref(window.innerWidth);

    const currentStop = computed(() => {
      let currentIndex = -1;
      props.stops.forEach(function (stop: any, index: number) {
        if ('doneAt' in stop) currentIndex = index;
      });
      return currentIndex;
    });

    const downloadFile = async (mediaObjectId, mediaObjectType) => {
      const response = await store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: mediaObjectId,
        class: mediaObjectType,
      });

      const url = URL.createObjectURL(response);

      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `${mediaObjectType}-${mediaObjectId}`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    };

    const resizeWindow = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', resizeWindow);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeWindow);
    });

    return {
      windowWidth,
      currentStop,
      downloadFile,
    };
  },
});
