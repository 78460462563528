import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col gap-2"
}
const _hoisted_2 = {
  key: 1,
  class: "w-full"
}
const _hoisted_3 = { class: "w-full flex justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanIcon = _resolveComponent("PlanIcon")!
  const _component_sidebar_button = _resolveComponent("sidebar-button")!
  const _component_sidebar_content_in_mobile = _resolveComponent("sidebar-content-in-mobile")!
  const _component_sidebar_user = _resolveComponent("sidebar-user")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar-component", _ctx.collapsed ? 'sidebar-component-collapsed' : ''])
  }, [
    (_ctx.screenComputedWidth > 1279)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["w-full flex items-center text-white plan-gap-md transition duration-5", _ctx.collapsed ? 'flex-col justify-center' : 'justify-between'])
          }, [
            _createVNode(_component_PlanIcon, {
              name: _ctx.collapsed ? 'planimatick-collapsed' : 'planimatick',
              size: "29",
              "is-svg": ""
            }, null, 8, ["name"]),
            _createVNode(_component_PlanIcon, {
              onClick: _ctx.changeCollapsedSidebarStatus,
              name: "download",
              class: _normalizeClass(["sidebar-collapsed-button", _ctx.collapsed ? 'rotate-180' : 'rotate-0']),
              isSvg: ""
            }, null, 8, ["onClick", "class"])
          ], 2),
          (!_ctx.isSuperAdmin)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "sidebar-button-container",
                style: _normalizeStyle(
          _ctx.collapsed ? 'width: calc(100% + 32px); padding-left: 28px;' : 'width: calc(100% + 48px)'
        )
              }, [
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openPlanning,
                  label: _ctx.$t('planning'),
                  "when-active": "planning",
                  icon: "calendar"
                }, null, 8, ["onClick", "label"]),
                (_ctx.isTrialPremUser)
                  ? (_openBlock(), _createBlock(_component_sidebar_button, {
                      key: 0,
                      onClick: _ctx.Routing.openLanesRfp,
                      label: _ctx.$t('rfp-lanes'),
                      "when-active": "lanesRfp",
                      icon: "rfp-lanes"
                    }, null, 8, ["onClick", "label"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openWarehousesList,
                  label: _ctx.$t('warehouses'),
                  icon: "warehouse",
                  class: "plan-mt-lg",
                  "when-active": "warehouses"
                }, null, 8, ["onClick", "label"]),
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openUnreadMessages,
                  label: _ctx.$t('messages'),
                  "when-active": "messages",
                  icon: "message-new",
                  "with-notifications": _ctx.totalUnreadChats > 0,
                  class: "plan-mt-lg"
                }, null, 8, ["onClick", "label", "with-notifications"]),
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openContacts,
                  label: _ctx.$t('contacts'),
                  "when-active": "contacts",
                  icon: "user-new"
                }, null, 8, ["onClick", "label"]),
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openShipperDashboard,
                  label: _ctx.$t('performance'),
                  "when-active": "shipperDashboard",
                  icon: "circle-stats",
                  class: "plan-mt-lg"
                }, null, 8, ["onClick", "label"])
              ], 4))
            : _createCommentVNode("", true),
          (_ctx.isSuperAdmin && !_ctx.isAdminSuplanting)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "sidebar-button-container",
                style: _normalizeStyle(_ctx.collapsed ? 'width: calc(100% + 32px);' : 'width: calc(100% + 48px)')
              }, [
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openShippersList,
                  label: _ctx.$t('users'),
                  "when-active": "shippersList",
                  icon: "user-new",
                  class: "mt-10"
                }, null, 8, ["onClick", "label"]),
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openSuperAdminDashboard,
                  label: _ctx.$t('performance'),
                  "when-active": "superAdminDashboard",
                  icon: "circle-stats"
                }, null, 8, ["onClick", "label"]),
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openLoadsList,
                  label: _ctx.$t('loads'),
                  "when-active": "loadsList",
                  icon: "calendar"
                }, null, 8, ["onClick", "label"]),
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openRequestDemoList,
                  label: _ctx.$t('demo-request'),
                  "when-active": "requestDemoList",
                  icon: "message-new"
                }, null, 8, ["onClick", "label"])
              ], 4))
            : _createCommentVNode("", true),
          (_ctx.showUiComponents && !_ctx.isSuperAdmin)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "sidebar-button-container",
                style: _normalizeStyle([
          _ctx.collapsed ? 'width: calc(100% + 32px); padding-left: 28px;' : 'width: calc(100% + 48px)'
        , {"margin":"0px -28px"}])
              }, [
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openUiComponents,
                  label: "UI Components",
                  "when-active": "ui",
                  icon: "ui"
                }, null, 8, ["onClick"]),
                _createVNode(_component_sidebar_button, {
                  onClick: _ctx.Routing.openTestDemo,
                  label: "Old planning",
                  icon: "eye"
                }, null, 8, ["onClick"])
              ], 4))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_PlanIcon, {
              name: _ctx.collapsed ? 'planimatick-collapsed' : 'planimatick',
              size: "30",
              "is-svg": ""
            }, null, 8, ["name"]),
            _createVNode(_component_PlanIcon, {
              onClick: _ctx.changeSidebarIsOpenInMobile,
              name: _ctx.sidebarIsOpenInMobile ? 'close' : 'menu-ham',
              size: "40",
              "is-svg": ""
            }, null, 8, ["onClick", "name"])
          ]),
          _createVNode(_component_sidebar_content_in_mobile, {
            onCloseSidebarContentMobile: _ctx.changeSidebarIsOpenInMobile,
            class: _normalizeClass(
          _ctx.sidebarIsOpenInMobile ? 'sidebar-content-in-mobile' : 'sidebar-content-in-mobile-hidden'
        ),
            isDevPreEnv: _ctx.isDevPreEnv,
            isTrialPremUser: _ctx.isTrialPremUser,
            totalUnreadChats: _ctx.totalUnreadChats > 0,
            isSuperAdmin: _ctx.isSuperAdmin
          }, null, 8, ["onCloseSidebarContentMobile", "class", "isDevPreEnv", "isTrialPremUser", "totalUnreadChats", "isSuperAdmin"])
        ])),
    (_ctx.screenComputedWidth > 1279)
      ? (_openBlock(), _createBlock(_component_sidebar_user, { key: 2 }))
      : _createCommentVNode("", true)
  ], 2))
}