import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col plan-p-md plan-gap-sm" }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col justify-start items-start plan-gap-sm plan-pl-md w-full"
}
const _hoisted_4 = { class: "w-full flex justify-between items-center" }
const _hoisted_5 = {
  key: 0,
  class: "w-full flex justify-between items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_plan_input_checkbox, { onChange: _ctx.changeShowForm }, null, 8, ["onChange"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('special-requirements')), 1)
    ]),
    (_ctx.showForm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_plan_input_checkbox, {
              onChange: _ctx.changeTeamDriver,
              label: _ctx.$t('team-driver'),
              class: "w-full"
            }, null, 8, ["onChange", "label"]),
            (!_ctx.isEuropeCompany())
              ? (_openBlock(), _createBlock(_component_plan_input_checkbox, {
                  key: 0,
                  onChange: _ctx.changeBondedPermission,
                  label: _ctx.$t('bonded-permission'),
                  class: "w-full"
                }, null, 8, ["onChange", "label"]))
              : _createCommentVNode("", true)
          ]),
          (!_ctx.isEuropeCompany())
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_plan_input_checkbox, {
                  onChange: _ctx.changeTsa,
                  label: _ctx.$t('tsa'),
                  class: "w-full"
                }, null, 8, ["onChange", "label"]),
                _createVNode(_component_plan_input_checkbox, {
                  onChange: _ctx.changeTwicCard,
                  label: _ctx.$t('twic-card'),
                  class: "w-full"
                }, null, 8, ["onChange", "label"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["w-full flex justify-between items-center", { 'flex-col gap-2': _ctx.isEuropeCompany() }])
          }, [
            _createVNode(_component_plan_input_checkbox, {
              onChange: _ctx.changeHazordousLoad,
              label: _ctx.$t('hazardous-load'),
              class: "w-full"
            }, null, 8, ["onChange", "label"]),
            _createVNode(_component_plan_input_checkbox, {
              onChange: _ctx.changeTir,
              label: _ctx.$t('tir'),
              class: "w-full"
            }, null, 8, ["onChange", "label"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["w-full flex justify-between items-center", { 'flex-col gap-2': _ctx.isEuropeCompany() }])
          }, [
            _createVNode(_component_plan_input_checkbox, {
              onChange: _ctx.changeSpecialPermits,
              label: _ctx.$t('special-permits'),
              class: "w-full"
            }, null, 8, ["onChange", "label"])
          ], 2),
          (_ctx.specialPermits)
            ? (_openBlock(), _createBlock(_component_plan_input_text, {
                key: 1,
                onChange: _ctx.change,
                "model-value": _ctx.specialPermitsDescription,
                "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.specialPermitsDescription) = $event)),
                placeholder: _ctx.$t('specify-special-permits')
              }, null, 8, ["onChange", "model-value", "placeholder"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}