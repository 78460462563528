import moment from 'moment';

export default {
  getDefaultLoad(state: any) {
    return state.defaultLoad;
  },
  getLoadToSend(state: any) {
    return state.loadToSend;
  },
};
