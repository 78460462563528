
import { defineComponent } from 'vue';
import { XCircleIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';

export default defineComponent({
  components: {
    DatePickerComponent,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    showSticky: Boolean,
  },
  data() {
    return {
      endDate: null as any,
      name: '',
      startDate: null as any,
    };
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
  },
  methods: {
    clearInput(data) {
      this[data] = '';
      this.submitSearch();
    },
    displayModal() {
      this.$emit('displayModal', true);
    },
    submitSearch() {
      this.$emit('submitSearch', {
        name: this.name,
        endDate: this.endDate ? this.formatDateStringReverse(this.endDate) : null,
        startDate: this.startDate ? this.formatDateStringReverse(this.startDate) : null,
      });
    },
  },
});
