
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { formatPriceDecimals } from '@/utils/formatString';
import ReviewAdditionalFeeOfCarrierModal
  from '@/modules/planning/loadDetail/finished/_modals/ReviewAdditionalFeeOfCarrierModal.vue';
import { useStore } from 'vuex';
import {useI18n} from "vue-i18n";

interface additionalFee {
  id: string
  name: string,
  total: number
}
export default defineComponent({
  name: 'AdditionalFeesOfCarrier',
  methods: { formatPriceDecimals },
  components: { ReviewAdditionalFeeOfCarrierModal },
  props: {
    shipmentId: { type: String, required: true, default: '0'}
  },
  setup (props) {
    const store = useStore()
    const { t } = useI18n();

    const pendingFees = computed(() => {
      return store.getters['LoadsStore/getShipmentFees'].filter((item) => item.status === 'STATUS_PENDING');
    })

    const currentLoad: any = computed(() => {
      return store.getters['LoadsStore/currentLoad'];
    })

    const fees = computed(() => {
      return store.getters['LoadsStore/getShipmentFees']
    })

    

    const isCanceled: any = computed(() => {
      return currentLoad.value.status === 'CANCELED';
    })

    store.dispatch('LoadsStore/getShipmentFees', props.shipmentId)

    const getFeeTranslation = (feeType) => {
      let label = feeType.replace(/[-\s]/g, "").toLowerCase();
      return t(`additional-fees-types.${label}`) ?? '';
    }

    return {
      fees,
      pendingFees,
      getFeeTranslation,
      isCanceled
    }
  }
})
