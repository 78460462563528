
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Modules
import RankingRFPModalSkeleton from '@/modules/rfp/_components/skeleton/RankingRFPModalSkeleton.vue';
import RankingTableLeft from '@/modules/rfp/_components/ranking/RankingTableLeft.vue';
import RankingTableRight from '@/modules/rfp/_components/ranking/RankingTableRight.vue';
import RequestQuotingView from '@/modules/rfp/_components/modals/RequestQuotingModal.vue';

import SetMaxResponseTimeModal from '@/modules/rfp/_components/modals/SetMaxResponseTimeModal.vue';
import SetQuotationTimeModal from '@/modules/rfp/_components/modals/SetQuotationTimeModal.vue';
// Types
import { RankingQuoteType, RFPStatus } from '@/modules/rfp/RFPTypes';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  components: {
    PlanIcon,
    RankingRFPModalSkeleton,
    RankingTableLeft,
    RankingTableRight,
    RequestQuotingView,
    SetMaxResponseTimeModal,
    SetQuotationTimeModal,
  },
  mixins: [BaseComponent],
  props: {
    currentLane: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeTab: 'quotes',
      automaticRanking: false,
      currentElement: {} as any,
      controlOnStart: true as any,
      finalList: [] as any,
      isLoading: false,
      isMoving: false,
      showQuotationTimeModal: false,
      showResponseMaxTimeModal: false,
      showRequestQuotingModal: false,
    };
  },
  created() {
    this.isLoading = true;
    this.$store
      .dispatch('RFPStore/getRankingList', {
        id: this.currentLane.id,
      })
      .finally(() => {
        this.isLoading = false;
      });
    this.finalList = this.quotedQuotes();
  },
  computed: {
    collapsedSidebar() {
      return this.$store.getters['UserStore/getCollapsedSidebar'];
    },
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    deliverCount(): any {
      if (!this.currentLane?.loadTemplate?.stops) {
        return '';
      }
      return (
        this.currentLane?.loadTemplate?.stops?.filter((stop) => stop.type === 'DELIVERY').length + 1
      );
    },
    destiny(): any {
      return this.currentLane?.loadTemplate?.stops?.find((obj) => obj.type === 'DELIVERY_FINAL');
    },
    getRankingList(): any {
      return this.$store.getters['RFPStore/getRankingList'];
    },
    isOpenChat(): any {
      return this.$store.getters['GlobalStore/isOpenChat'];
    },
    origin(): any {
      return this.currentLane?.loadTemplate?.stops?.find((obj) => obj.type === 'PICKUP_INITIAL');
    },
    pickUpsCount(): any {
      if (!this.currentLane?.loadTemplate?.stops) {
        return '';
      }
      return (
        this.currentLane?.loadTemplate?.stops?.filter((stop) => stop.type === 'PICKUP').length + 1
      );
    },
    hasQuotesAccepted(): any {
      return this.getRankingList.some(quote => quote.status === RFPStatus.ACCEPTING || quote.status === RFPStatus.CONFIRMED);
    },
    quotes(): any {
      const allQuotes = this.getRankingList;
      const quotedQuotes = allQuotes.filter(
        (quote) =>
          quote.status !== this.rankingQuoteType.WAITING &&
          quote.status !== this.rankingQuoteType.REMOVED &&
          quote.status !== this.rankingQuoteType.REFUSED,
      );
      const waitingQuotes = allQuotes.filter(
        (quote) => quote.status === this.rankingQuoteType.WAITING,
      );
      const refusedQuotes = allQuotes.filter(
        (quote) => quote.status === this.rankingQuoteType.REFUSED,
      );

      return {
        quotedQuotes,
        refusedQuotes,
        waitingQuotes,
      };
    },
    rankingQuoteType() {
      return RankingQuoteType;
    },
  },
  methods: {
    checkMove(evt) {
      return this.statusDeclined(evt.draggedContext.element.status);
    },
    callbackToggleResponseTimeModal() {
      this.showResponseMaxTimeModal = !this.showResponseMaxTimeModal;
    },
    callbackToggleRequestQuotingModal() {
      this.showRequestQuotingModal = !this.showRequestQuotingModal;
    },
    callbackFetchLanesDetail() {
      const projectId = this.$route.params.projectId;

      this.$store.dispatch('RFPStore/getProjectById', {
        projectId: projectId,
        page: 1,
      });

      this.$store.dispatch('RFPStore/getLanesByProjectId', {
        projectId: projectId,
        page: 1,
      });
    },
    callbackToggleQuotationTimeModal() {
      this.showQuotationTimeModal = !this.showQuotationTimeModal;
    },
    fetch() {
      this.isLoading = true;
      this.$store
        .dispatch('RFPStore/getRankingList', {
          id: this.currentLane.id,
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.finalList = this.quotedQuotes();
    },
    getRowStyle(status) {
      if (
        status === this.rankingQuoteType.CONFIRMED ||
        status === this.rankingQuoteType.WAITING ||
        status === this.rankingQuoteType.ACCEPTING ||
        status === this.rankingQuoteType.QUOTED ||
        status === this.rankingQuoteType.CONFIRM_EXPIRED
      ) {
        return {
          backgroundColor: '#edf5ff',
        };
      }
      if (status === this.rankingQuoteType.REFUSED || status === this.rankingQuoteType.DECLINED) {
        return {
          backgroundColor: '#ffe6e6',
        };
      }
      return {};
    },
    isLastInGroup(index) {
      if (index === this.finalList.length - 1) {
        return false;
      }

      const currentElement = this.finalList[index];
      const nextElement = this.finalList[index + 1];
      return currentElement?.quote?.rankingOrder !== nextElement?.quote?.rankingOrder;
    },
    isRead(user): any {
      return user.lanesChatMemberForShipper?.readed ?? true;
    },
    onClickClose() {
      this.$emit('close');
    },
    onDragEnd(event) {
      const foundItem = this.finalList.find((item) => item.id === this.currentElement);

      if (foundItem && this.statusDeclined(foundItem.status)) {
        this.$store
          .dispatch('RFPStore/postMoveRankingPosition', {
            id: foundItem.quote.id,
            body: {
              newPosition: event.newDraggableIndex + 1,
            },
          })
          .then(() => {
            this.$store.dispatch('RFPStore/getRankingList', {
              id: this.currentLane.id,
            });
          });
      }
    },
    async openChat(event, ranking) {
      event.stopPropagation();
      if (!this.isOpenChat) {
        this.$store.commit('LoadsStore/loadId', null);
        this.$store.commit('GlobalStore/setOpenChat', false);
        if (ranking.lanesChatMemberForShipper) {
          ranking.lanesChatMemberForShipper.readed = true;
        }
        setTimeout(() => {
          this.$store.commit('ChatsStore/setRankingForChat', ranking);
          this.$store.commit('ChatsStore/setUserForChat', {
            owner: { id: ranking.grouper.company.owner.id },
          });
          this.$store.commit('ChatsStore/setLaneForChat', this.currentLane);
          this.$store.commit(
            'ChatsStore/setChatId',
            ranking?.lanesChatMemberForShipper?.chat?.id ?? null,
          );
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    quotedQuotes() {
      return this.quotes.quotedQuotes;
    },
    onDragStart(event) {
      const draggedElement = this.finalList[event.oldIndex];
      const elementId = draggedElement.id;
      this.currentElement = elementId;
    },
    statusDeclined(status) {
      return (
        status !== this.rankingQuoteType.REFUSED &&
        status !== this.rankingQuoteType.CONFIRM_EXPIRED &&
        status !== this.rankingQuoteType.CONFIRMED &&
        status !== this.rankingQuoteType.DECLINED &&
        status !== this.rankingQuoteType.ACCEPTING
      );
    },
  },
  watch: {
    quotes() {
      this.finalList = this.quotedQuotes();
    },
  },
});
