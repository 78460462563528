export const RFPStatus = {
  ACCEPTING: 'ACCEPTING',
  CONFIRMED: 'CONFIRMED',
  DELIVERED: 'DELIVERED',
  DRAFT: 'DRAFT',
  QUOTING: 'QUOTING',
  QUOTED: 'QUOTED',
  IN_PROGRESS: 'ACTIVE',
  FINISHED: 'FINISHED',
};

export const StopStatus = {
  DELIVERY_FINAL: 'DELIVERY_FINAL',
  DELIVERY: 'DELIVERY',
  PICKUP_INITIAL: 'PICKUP_INITIAL',
  PICKUP: 'PICKUP',
};

export const RankingQuoteType = {
  WAITING: 'WAITING',
  QUOTED: 'QUOTED',
  ACCEPTING: 'ACCEPTING',
  CONFIRMED: 'CONFIRMED',
  REFUSED: 'REFUSED',
  CONFIRM_EXPIRED: 'CONFIRM_EXPIRED',
  DECLINED: 'DECLINED',
  REMOVED: 'REMOVED',
};
