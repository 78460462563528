
import { defineComponent, PropType } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';

interface FreightItem {
  id: number;
  nPallets: number;
  weight: number;
  commodityName: string;
  units: string;
  stackable: boolean;
  hazardous: boolean;
  UNNANumber: number;
  hazmatDescription: string;
  length: number;
  width: number;
  height: number;
  STC: string;
  NMFCCode: string;
  packageType: {
    id: number;
    fullName: string;
  };
  PCF: number;
  volume: number;
  densityClass: {
    id: number;
    class: number;
  };
}

export default defineComponent({
  data() {
    return {};
  },
  components: {},
  mixins: [BaseComponent],
  props: {
    freight: {
      type: Array as PropType<FreightItem[]>,

      required: true,
    },
  },

  computed: {
    origin(): any {
      return this.currentLoad.stops.find((obj) => obj.type === 'PICKUP_INITIAL');
    },
    destiny(): any {
      return this.currentLoad.stops.find((obj) => obj.type === 'DELIVERY_FINAL');
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    totalPallets(): any {
      return this.freight.reduce((total, item) => total + item.nPallets, 0);
    },
    totalWeight(): any {
      return this.freight.reduce((total, item) => total + (item.nPallets * item.weight), 0);
    },
    totalLength(): any {
      return this.freight.reduce((total, item) => total + item.length, 0);
    },
    totalWidth(): any {
      return this.freight.reduce((total, item) => total + item.width, 0);
    },
    totalHeight(): any {
      return this.freight.reduce((total, item) => total + item.height, 0);
    },
    totalVolume(): any {
      return this.freight.reduce((total, item) => total + (item.nPallets * item.volume), 0);
    },
    totalPCF(): any {
      return this.freight.reduce((total, item) => total + item.PCF, 0);
    },
    totalClass(): any {
      return this.freight.reduce((total, item) => total + item.densityClass.class, 0);
    },
  },
  methods: {},
});
