import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen" }
const _hoisted_2 = { class: "w-full h-full flex justify-center items-center bg-white" }
const _hoisted_3 = { class: "justify-center items-center w-full h-50 bg-white border border-white" }
const _hoisted_4 = { class: "text-center text-zinc-800 text-2xl font-bold" }
const _hoisted_5 = { class: "px-12 py-10 text-center" }
const _hoisted_6 = { class: "text-zinc-800 text-base font-normal" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { class: "text-white text-base font-normal font-['Roboto']" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('refused-offer')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('refused-offer-body')), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.routerRequestForQuote && _ctx.routerRequestForQuote(...args))),
            class: "w-40 px-12 py-2.5 text-center bg-slate-900 rounded justify-center items-center gap-2.5 inline-flex"
          }, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('continue')), 1)
          ])
        ])
      ])
    ])
  ]))
}