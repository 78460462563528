import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "sidebar-user justify-start gap-2" }
const _hoisted_3 = {
  class: "user-avatar",
  style: {"box-shadow":"none"}
}
const _hoisted_4 = { class: "text-white" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = {
  key: 0,
  style: {"background":"#00db50"},
  class: "inline-block text-xs font-bold bg-gray-600 rounded-full box-border px-1.5 py-1 mb-2"
}
const _hoisted_7 = { class: "bg-white/20 px-1.5 py-1 rounded-full ml-1 -mr-1.5" }
const _hoisted_8 = {
  key: 1,
  class: "inline-block text-xs font-bold bg-gray-600 rounded-full box-border px-1.5 py-1 mb-2",
  style: {"background":"#f1ad02"}
}
const _hoisted_9 = { class: "text-sm font-medium text-gray-900 space-x-1" }
const _hoisted_10 = { class: "font-semibold" }
const _hoisted_11 = { class: "font-bold" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = { class: "font-bold" }
const _hoisted_14 = { class: "text-xs text-gray-500" }
const _hoisted_15 = { class: "text-sm font-medium text-gray-900 space-x-1" }
const _hoisted_16 = { class: "font-bold" }
const _hoisted_17 = { class: "text-xs text-gray-500" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "text-sm font-medium text-gray-900" }
const _hoisted_20 = { class: "font-semibold" }
const _hoisted_21 = {
  style: {"color":"#0097ff"},
  class: "text-sm font-medium"
}
const _hoisted_22 = { class: "flex justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_toggle_button = _resolveComponent("plan-toggle-button")!
  const _component_plan_tabs = _resolveComponent("plan-tabs")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_PlanIcon = _resolveComponent("PlanIcon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
    title: `${_ctx.carrierSummary?.totals?.total} ${_ctx.translate.t('alert')}`,
    size: _ctx.actualWidth <= 640 ? 'full' : 'lg-auto',
    "is-carrier-view": "",
    "hide-close-button": ""
  }, {
    "header-buttons": _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(["font-bold plan-t-prim-100", { 'plan-t-sec-500': _ctx.mode === 'auto' }])
      }, _toDisplayString(_ctx.translate.t('carrier_email-summary')), 3),
      _createVNode(_component_plan_toggle_button, {
        isActive: _ctx.carrierWhenActivitySummary,
        onChangeStatus: _ctx.disableResumeEmail
      }, null, 8, ["isActive", "onChangeStatus"])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_tabs, {
          align: 'between',
          startTab: _ctx.selectedTab,
          "show-badges": true,
          "tabs-width": 'w-1/3',
          tabs: [
            { label: _ctx.translate.t('quotes'), quantity: _ctx.carrierSummary?.totals?.totalQuotes },
            { label: _ctx.translate.t('confirmations'), quantity: _ctx.carrierSummary?.totals?.totalConfirm },
            { label: _ctx.translate.t('in-course'), quantity: _ctx.carrierSummary?.totals?.totalInProgress },
          ],
          onChangeTab: _ctx.changeTab,
          size: "plan-text-lg"
        }, null, 8, ["startTab", "tabs", "onChangeTab"])
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_plan_loading, {
            key: 0,
            class: "plan-t-prim-100"
          }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.carrierSummary?.values, (value, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "w-full flex flex-col justify-start p-2 gap-2"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_PlanIcon, {
                    name: "refresh-cw",
                    "is-svg": "",
                    rotate: ""
                  }),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(value.info.name.slice(0, 1)), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(value.info.name), 1)
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.items, (item, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: i,
                  style: {"background-color":"#c7e8ff"},
                  class: "flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between p-4 rounded-md"
                }, [
                  _createElementVNode("div", null, [
                    (item.shipment.type === 'FTL')
                      ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                          _createTextVNode(_toDisplayString(item.shipment.type), 1),
                          _createElementVNode("span", _hoisted_7, "#" + _toDisplayString(item.load.identifier), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_8, " RFP ")),
                    (item.shipment.type === 'FTL' || item.isLane)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.formattedDate(item.shipment?.origin?.date?.date)), 1),
                            _createElementVNode("span", _hoisted_11, _toDisplayString(item.shipment?.origin?.city) + ", " + _toDisplayString(item.shipment?.origin?.state), 1),
                            _createTextVNode("   "),
                            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.formattedDate(item.shipment?.destiny?.date?.date)), 1),
                            _createElementVNode("span", _hoisted_13, _toDisplayString(item.shipment?.destiny?.city) + ", " + _toDisplayString(item.shipment?.destiny?.state), 1)
                          ]),
                          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.formatWeight(item?.load?.weight)) + ", " + _toDisplayString(item.load?.commodities), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    (item.shipment.type === 'RFP')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("span", _hoisted_16, _toDisplayString(item.project?.name), 1)
                          ]),
                          _createElementVNode("div", _hoisted_17, _toDisplayString(item.lanes) + " " + _toDisplayString(_ctx.translate.t('lanes')), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["text-center border-2 border-sky-500 w-40 min-h-[50px] cursor-pointer", { 'flex items-center justify-center': !item.message }]),
                    onClick: ($event: any) => (_ctx.handleRouteRedirecction(item))
                  }, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("span", _hoisted_20, _toDisplayString(item.message || ' '), 1)
                    ]),
                    _createElementVNode("div", _hoisted_21, _toDisplayString(item.linkDescription), 1)
                  ], 10, _hoisted_18)
                ]))
              }), 128))
            ]))
          }), 128))
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_plan_button, {
          type: "primary",
          label: 'OK',
          onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
          style: {"width":"120px"}
        })
      ])
    ]),
    _: 1
  }, 8, ["title", "size"]))
}