import globalAxios from 'axios';

const getCompanyCredit = async (id) => {
  const response = await globalAxios.get(`/companies/${id}/credit/basic_info`);
  return Promise.resolve(response.data);
};

const getCompanyCreditBreakdown = async (data) => {
  const response = await globalAxios.get(`/companies/${data.id}/credit/breakdown`, {
    params: data.params,
  });
  return Promise.resolve(response.data);
};

const postCreditRequests = async (data) => {
  const response = await globalAxios.post(`/credit_requests`, data);
  return Promise.resolve(response.data);
};

const putCreditGranted = async (data) => {
  const response = await globalAxios.put(`/companies/${data.id}/update/creditGranted`, data.params);
  return Promise.resolve(response.data);
};

export default {
  getCompanyCredit,
  getCompanyCreditBreakdown,
  postCreditRequests,
  putCreditGranted,
};
