
import { computed, defineComponent } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
export default defineComponent({
  name: 'PlanButton',
  components: { PlanIcon },
  props: {
    label: { type: String, required: true, default: '' },
    showIcon: { type: Boolean, required: false, default: false },
    icon: { type: String, required: false, default: '' },
    iconType: { type: String, required: false, default: 'svg' },
    iconColor: { type: String, required: false, default: 'white' },
    iconSize: { type: String, required: false, default: '20' },
    disabled: { type: Boolean, required: false, default: false },
    size: { type: String, required: false, default: 'md' },
    type: { type: String, required: true, default: 'primary' },
    fullRounded: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    justifyAlign: { type: String, required: false, default: 'justify-center' },
    rowReverse: { type: Boolean, required: false, default: false },
    multiline: { type: Boolean, required: false, default: false },
    withNotification: { type: Boolean, required: false, default: false }
  },
  emits: ['onClick'],
  setup (props, { emit }) {
    const buttonClasses = computed(() => {
      const availableButtonsTypes = ['white-gold', 'white-silver', 'white-bronze', 'cancel', 'red-static', 'sec-600', 'primary-light', 'white', 'gray-800-white', 'primary-white', 'primary-dark-to-light', 'black-gray-100', 'black-gray-200', 'black-gray-400', 'primary', 'primary-transparent', 'primary-200', 'primary-dark', 'success', 'warning', 'error', 'primary-outline', 'primary-dark-outline', 'success-outline', 'warning-outline', 'error-outline','error-outline-500', 'gray-outline', 'orange-yellow']
      const availableButtonsSizes = ['lg', 'md', 'sm', 'auto-md', 'auto-md-first', 'auto-md-last']

      let result = 'plan-button-'
      result += availableButtonsTypes.includes(props.type) ? props.type : 'primary'
      result += availableButtonsSizes.includes(props.size) ? ' plan-button-' + props.size : ' plan-button-md'
      result += ' ' + props.justifyAlign
      if (props.fullRounded) result += ' rounded-full'
      if (props.disabled) {
        if (props.type === 'primary') {
          result += ' plan-button-primary-disabled'
        } else if (props.type === 'primary-transparent') {
          result += ' plan-button-primary-transparent-disabled'
        } else if (props.type === 'red-static') {
          result += ' plan-button-red-static'
        } else {
          result += ' opacity-50 cursor-not-allowed'
        }
      }
      if (props.rowReverse) result += ' flex-row-reverse'
      if (props.multiline) result += ' h-fit'

      return result
    })

    const onClick = () => {
      if (!props.disabled && !props.loading) emit('onClick')
    }



    return {
      buttonClasses,
      onClick
    }
  }
})
