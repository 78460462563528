import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex flex-col plan-gap-sm p-6" }
const _hoisted_2 = { class: "flex justify-end items-center plan-gap-sm" }
const _hoisted_3 = { class: "flex flex-col plan-gap-sm p-6" }
const _hoisted_4 = {
  key: 0,
  class: "text-xs text-gray-500"
}
const _hoisted_5 = { class: "flex justify-end items-center plan-gap-sm" }
const _hoisted_6 = { class: "flex flex-col plan-gap-md" }
const _hoisted_7 = { class: "flex flex-col p-6" }
const _hoisted_8 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_9 = { class: "font-bold plan-t-prim-100" }
const _hoisted_10 = { class: "font-bold plan-t-prim-100" }
const _hoisted_11 = {
  key: 0,
  class: "plan-t-xs plan-t-prim-100"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "flex justify-start items-center plan-gap-sm plan-t-prim-100 plan-t-xs" }
const _hoisted_14 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_15 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_16 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_17 = { class: "w-full flex flex-col plan-gap-xs" }
const _hoisted_18 = { class: "flex justify-center items-center plan-gap-sm" }
const _hoisted_19 = { class: "flex flex-col plan-gap-sm p-6" }
const _hoisted_20 = { class: "plan-pl-md plan-pr-md plan-t-prim-100 plan-t-xs text-center" }
const _hoisted_21 = { class: "plan-pl-md plan-pr-md plan-t-prim-100 plan-t-xs text-center" }
const _hoisted_22 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_23 = { class: "flex justify-center items-center plan-gap-xs" }
const _hoisted_24 = { class: "flex justify-start items-center" }
const _hoisted_25 = { class: "flex flex-col" }
const _hoisted_26 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_27 = { class: "" }
const _hoisted_28 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_29 = { class: "text-xs" }
const _hoisted_30 = { class: "sticky-right-column" }
const _hoisted_31 = {
  key: 0,
  class: "flex justify-end items-center plan-gap-sm"
}
const _hoisted_32 = {
  key: 0,
  class: "sticky-right-column"
}
const _hoisted_33 = { class: "flex flex-col gap-2" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { key: 1 }
const _hoisted_36 = { class: "flex justify-end items-center w-full plan-gap-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestQuotingView = _resolveComponent("RequestQuotingView")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_Popper = _resolveComponent("Popper")!
  const _component_plan_toggle_button = _resolveComponent("plan-toggle-button")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_rating = _resolveComponent("plan-rating")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showContactsModal)
      ? (_openBlock(), _createBlock(_component_RequestQuotingView, {
          key: 0,
          directAssign: true,
          onClose: _ctx.closeRequestQuoteModal
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_plan_default_modal, {
      title: 
      _ctx.getCurrentLoadsToAddAssigment
        ? _ctx.$t('plan-assign-loads', { number: _ctx.countNonEmptyExecutionDates })
        : _ctx.modalTitle
    ,
      onClose: _ctx.close,
      size: "lg-auto",
      "hide-close-button": "",
      contentWithoutPadding: ""
    }, {
      "header-buttons": _withCtx(() => [
        (_ctx.mode === 'manual')
          ? (_openBlock(), _createBlock(_component_plan_button, {
              key: 0,
              onOnClick: _ctx.handleShowContactsModal,
              type: "primary-transparent",
              label: _ctx.$t('rankingModal.assign-other-contact')
            }, null, 8, ["onOnClick", "label"]))
          : _createCommentVNode("", true)
      ]),
      content: _withCtx(() => [
        (_ctx.showDirectAssignModal)
          ? (_openBlock(), _createBlock(_component_plan_default_modal, {
              key: 0,
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDirectAssignModal = false)),
              title: _ctx.translate.t('direct-assign'),
              size: "sm-auto"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.translate.t('rankingModal.assigning')) + " " + _toDisplayString(_ctx.carrierToAssign?.tender), 1),
                  _createVNode(_component_plan_input_number, {
                    "model-value": _ctx.price,
                    "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.price) = $event)),
                    label: _ctx.translate.t('rankingModal.select-price'),
                    currency: "",
                    placeholder: "0"
                  }, null, 8, ["model-value", "label"])
                ])
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_plan_button, {
                    onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDirectAssignModal = false)),
                    type: "cancel",
                    label: _ctx.$t('cancel')
                  }, null, 8, ["label"]),
                  _createVNode(_component_plan_button, {
                    onOnClick: _ctx.handleEmitUserSelected,
                    type: "primary",
                    label: _ctx.$t('send')
                  }, null, 8, ["onOnClick", "label"])
                ])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        (_ctx.showModifyModal)
          ? (_openBlock(), _createBlock(_component_plan_default_modal, {
              key: 1,
              onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showModifyModal = false)),
              title: _ctx.$t('modify-rate'),
              size: "xs-auto"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_plan_input_number, {
                    "model-value": _ctx.price,
                    "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.price) = $event)),
                    placeholder: "0",
                    currency: "",
                    "hide-validations": ""
                  }, null, 8, ["model-value"]),
                  (_ctx.currentLane?.loadTemplate?.totalDistance)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(`${_ctx.formatPriceDecimals(
                  +_ctx.pricePerMile(_ctx.price, _ctx.currentLane?.loadTemplate?.totalDistance),
                )} /`) + " " + _toDisplayString(_ctx.userRegion === 'EUROPE' ? 'km' : ' mi'), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_plan_button, {
                    onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModifyModal = false)),
                    type: "cancel",
                    label: _ctx.$t('cancel')
                  }, null, 8, ["label"]),
                  _createVNode(_component_plan_button, {
                    onOnClick: _ctx.handleEmitModifyQuote,
                    type: "primary",
                    label: _ctx.$t('send')
                  }, null, 8, ["onOnClick", "label"])
                ])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(`${_ctx.origin?.location?.city}, ${_ctx.origin?.location?.state?.isoGoogle}`), 1),
              _createVNode(_component_plan_icon, {
                name: "plan-arrow-right",
                "icon-color": "#8A8B97",
                "is-svg": ""
              }),
              _createElementVNode("p", _hoisted_10, _toDisplayString(`${_ctx.destiny?.location?.city},
              ${_ctx.destiny?.location?.state?.isoGoogle}`), 1)
            ]),
            (!_ctx.getCurrentLoadsToAddAssigment)
              ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(`${_ctx.formatDate(_ctx.origin?.executionDate)}
            ${_ctx.$t('to').toLowerCase()}
            ${_ctx.formatDate(_ctx.destiny?.executionDate)}`), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_Popper, {
                    arrow: "",
                    hover: ""
                  }, {
                    content: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCurrentLoadsToAdd, (load, index) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: index,
                          class: "plan-t-xs plan-t-prim-100"
                        }, _toDisplayString(`${_ctx.formatDate(_ctx.formatDateValue(load.stops[0].executionDate))}
            ${_ctx.$t('to').toLowerCase()}
            ${_ctx.formatDate(_ctx.formatDateValue(load.stops[load.stops.length - 1].executionDate))}`) + " #" + _toDisplayString(index + 1), 1))
                      }), 128))
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("p", null, "[" + _toDisplayString(_ctx.$t('show-dates')) + "]", 1)
                    ]),
                    _: 1
                  })
                ])),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_plan_icon, {
                  name: "distance",
                  "icon-color": "#8A8B97",
                  size: "12",
                  "is-svg": ""
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.formatMiles(_ctx.currentLane?.loadTemplate?.totalDistance)), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_plan_icon, {
                  name: "icInRoute",
                  "icon-color": "#8A8B97",
                  size: "12",
                  "is-svg": ""
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.currentLane?.loadTemplate?.trailerType?.description), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_plan_icon, {
                  name: "package",
                  "icon-color": "#8A8B97",
                  size: "12",
                  "is-svg": ""
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.currentLane?.loadTemplate?.commodityMaster?.commodityName) + " " + _toDisplayString(_ctx.formatWeight(_ctx.currentLane?.loadTemplate?.commodityMaster?.weight)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("span", {
                class: _normalizeClass(["font-bold plan-t-prim-100", { 'plan-t-sec-500': _ctx.mode === 'auto' }])
              }, _toDisplayString(_ctx.translate.t('rankingModal.auto')), 3),
              _createVNode(_component_plan_toggle_button, { onChangeStatus: _ctx.changeAssigmentMode }, null, 8, ["onChangeStatus"]),
              _createElementVNode("span", {
                class: _normalizeClass(["font-bold plan-t-prim-100", { 'plan-t-sec-500': _ctx.mode === 'manual' }])
              }, _toDisplayString(_ctx.translate.t('rankingModal.manual')), 3)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.translate.t('rankingModal.auto-info')), 1),
              _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.translate.t('rankingModal.no-confirmation')), 1),
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_plan_loading, {
                    key: 0,
                    class: "plan-t-prim-100 h-44"
                  }))
                : (_openBlock(), _createBlock(_component_plan_default_table, {
                    key: 1,
                    items: _ctx.items,
                    headers: _ctx.autoHeaders,
                    class: "plan-mt-lg"
                  }, {
                    default: _withCtx((props) => [
                      _createElementVNode("td", null, _toDisplayString(props.item.ranking.quote?.rankingOrder
                      ? _ctx.addOrdinalSuffix(props.item.ranking.quote?.rankingOrder)
                      : ''), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, [
                            _createVNode(_component_plan_avatar, {
                              "user-name": props.item.tender,
                              description: "company",
                              maxCharacters: 20
                            }, null, 8, ["user-name"]),
                            _createVNode(_component_plan_button_icon, {
                              onOnClick: ($event: any) => (_ctx.openChat(props.item.ranking)),
                              icon: "chat"
                            }, null, 8, ["onOnClick"])
                          ])
                        ])
                      ]),
                      _createElementVNode("td", null, [
                        _createElementVNode("div", _hoisted_24, [
                          _createVNode(_component_plan_rating, {
                            rating: props.item.rating
                          }, null, 8, ["rating"])
                        ])
                      ]),
                      _createElementVNode("td", null, [
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('loads-with-param', {
                          n: props.item?.ranking?.grouper?.company?.rfpStats?.loadAmount,
                        })), 1),
                          (props.item?.ranking?.grouper?.company?.rfpStats?.lastLoadDate)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.$t('last')) + ": " + _toDisplayString(_ctx.formatDate(props.item?.ranking?.grouper?.company?.rfpStats?.lastLoadDate)), 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_27, _toDisplayString(props.item?.ranking?.grouper?.company?.rfpStats?.currentPeriodLoads) + " / " + _toDisplayString(props?.item?.ranking?.quote?.assignedCapacity
                      ? props?.item?.ranking?.quote?.assignedCapacity
                      : props?.item?.ranking?.quote?.autoSuggestedCapacity || 0) + " " + _toDisplayString(_ctx.translate.t('loads')), 1),
                      _createElementVNode("td", null, [
                        (props.item.ranking?.quote)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                              _createElementVNode("span", null, _toDisplayString(props.item.ranking?.quote?.maxCapacity) + " " + _toDisplayString(_ctx.$t(`${_ctx.currentLane?.loadsFrequencyPeriod.toLowerCase()}`)), 1),
                              _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.period ? props.item.ranking?.quote?.maxCapacity * _ctx.period : '-') + " " + _toDisplayString(_ctx.$t('truck', props.item.ranking?.quote?.maxCapacity * _ctx.period)), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", null, _toDisplayString(props.item.rate > 0 ? _ctx.formatPrice(props.item.rate) : '-'), 1),
                      _createElementVNode("td", _hoisted_30, [
                        (_ctx.mode === 'manual' && props.item?.ranking?.quote?.id)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                              _createVNode(_component_plan_button, {
                                onOnClick: ($event: any) => (_ctx.handleShowAssignModal(props.item, props.item.rate)),
                                type: "primary",
                                label: "Direct assign"
                              }, null, 8, ["onOnClick"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      (props.item?.ranking?.quote?.id && _ctx.mode !== 'manual')
                        ? (_openBlock(), _createElementBlock("td", _hoisted_32, [
                            _createVNode(_component_Popper, {
                              arrow: "",
                              hover: "",
                              placement: "right"
                            }, {
                              content: _withCtx(() => [
                                _createElementVNode("div", _hoisted_33, [
                                  _createElementVNode("div", {
                                    onClick: _withModifiers(($event: any) => (_ctx.handleShowModifyModal(props.item?.ranking)), ["stop"])
                                  }, [
                                    _createVNode(_component_plan_button, {
                                      type: "white",
                                      label: _ctx.$t('modify-rate'),
                                      "justify-align": "justify-start",
                                      size: "sm"
                                    }, null, 8, ["label"])
                                  ], 8, _hoisted_34)
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_plan_button_icon, {
                                  onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"])),
                                  icon: "options",
                                  type: "transparent-gray-800"
                                })
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        : (_openBlock(), _createElementBlock("td", _hoisted_35))
                    ]),
                    _: 1
                  }, 8, ["items", "headers"]))
            ])
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_36, [
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.close,
            type: "cancel",
            label: _ctx.$t('cancel')
          }, null, 8, ["onOnClick", "label"]),
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.createLoad,
            disabled: _ctx.mode !== 'auto' || _ctx.directAssign !== null,
            type: "primary",
            label: _ctx.$t('accept')
          }, null, 8, ["onOnClick", "disabled", "label"])
        ])
      ]),
      _: 1
    }, 8, ["title", "onClose"])
  ], 64))
}