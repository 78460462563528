
import { defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

interface resetPassword {
  complete: boolean,
  loading: boolean,
  password: string,
  repeatPassword: string,
  urls: {
    privacy: string,
    terms: string
  }
}

export default defineComponent({
  name: 'ResetPassword',
  components: { PlanIcon },
  props: {
    hashId: { type: String, default: null },
    code: { type: String, default: null }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()
    const translate = useI18n()

    const data = reactive<resetPassword>({
      complete: false,
      loading: false,
      password: '',
      repeatPassword: '',
      urls: {
        privacy: 'https://www.planimatik.com/privacy-policy?__hstc=171743039.61946906491746eea468b8df2b97b5ee.1686845245471.1686845245471.1686847406765.2&__hssc=171743039.1.1686847406765&__hsfp=370058895',
        terms: 'https://www.planimatik.com/terms-of-service?__hstc=171743039.61946906491746eea468b8df2b97b5ee.1686845245471.1686845245471.1686847406765.2&__hssc=171743039.12.1686847406765&__hsfp=370058895'
      }
    })

    const goToLogin = () => {
      router.push('/signin')
    }

    const resetPassword = () => {
      data.loading = true
      store.dispatch('UserStore/resetPassword', {
        hashId: props.hashId,
        secureCode: props.code,
        password: data.password,
      }).then(() => {
        data.loading = false
        store.dispatch('UserStore/showToast', translate.t('password-changed'))
        data.complete = true

      }).catch((error) => {
        store.dispatch('UserStore/updateToast', {
          text: translate.t(error?.response?.data?.detail),
          type: 'error',
          visible: true
        })
        data.loading = false
      })
    }

    return {
      ...toRefs(data),
      goToLogin,
      resetPassword
    }
  }
})
