import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex justify-center items-center relative",
  style: {"z-index":"9499"}
}
const _hoisted_2 = {
  class: "w-full flex flex-col justify-start items-start plan-gap-sm",
  style: {"min-width":"300px"}
}
const _hoisted_3 = { class: "flex justify-end items-center plan-gap-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_sidebar_user_button = _resolveComponent("sidebar-user-button")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.actualWidth <= 640 ? 'carrier-sidebar-top':'carrier-sidebar'),
    style: {"z-index":"9499"}
  }, [
    _createVNode(_component_plan_icon, {
      name: "planimatick-collapsed",
      size: "32",
      "is-svg": ""
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_popper, {
        hover: "",
        offsetSkid: "0",
        offsetDistance: "10"
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_sidebar_user_button, {
              label: _ctx.$t('language'),
              icon: "language-exchange",
              class: "w-full"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_plan_button, {
                    onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLanguage('es'))),
                    type: _ctx.selectedLanguage === 'es' ? 'primary':'black-gray-200',
                    label: "ES",
                    size: "sm"
                  }, null, 8, ["type"]),
                  _createVNode(_component_plan_button, {
                    onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLanguage('en'))),
                    type: _ctx.selectedLanguage === 'en' ? 'primary':'black-gray-200',
                    label: "EN",
                    size: "sm"
                  }, null, 8, ["type"])
                ])
              ]),
              _: 1
            }, 8, ["label"])
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_plan_avatar, {
            "user-name": _ctx.actualUser.name,
            onlyAvatar: ""
          }, null, 8, ["user-name"])
        ]),
        _: 1
      })
    ])
  ], 2))
}