import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-center plan-gap-lg" }
const _hoisted_2 = { class: "cursor-pointer truncate max-w-xs" }
const _hoisted_3 = { class: "cursor-pointer truncate max-w-xs" }
const _hoisted_4 = { class: "w-full flex flex-col justify-center items-center plan-gap-sm" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "flex justify-end items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_header_search = _resolveComponent("plan-header-search")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.$t('select-recipients'),
    size: "md-auto",
    "content-without-padding": "",
    "hide-close-button": ""
  }, {
    "header-buttons": _withCtx(() => [
      _createVNode(_component_plan_header_search, {
        "old-query": _ctx.searchCompanyQuery.name,
        placeholder: _ctx.$t('search-company'),
        onChangeQuery: _ctx.handleInputChange
      }, null, 8, ["old-query", "placeholder", "onChangeQuery"])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_default_table, {
          onSelectedItems: _ctx.changeSelectedContacts,
          items: _ctx.contacts?.values,
          headers: _ctx.headers,
          selectable: "",
          selectedItems: _ctx.selectedContacts,
          class: "w-full"
        }, {
          default: _withCtx((props) => [
            _createElementVNode("td", _hoisted_2, _toDisplayString(props.item?.name), 1),
            _createElementVNode("td", _hoisted_3, _toDisplayString(props.item?.owner_surname), 1)
          ]),
          _: 1
        }, 8, ["onSelectedItems", "items", "headers", "selectedItems"]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.contacts.values.length > 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('showing')) + " " + _toDisplayString(_ctx.contacts.values.length) + " " + _toDisplayString(_ctx.$t('of')) + " " + _toDisplayString(_ctx.contacts.total), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.loadMoreContacts,
            loading: _ctx.loadingContacts,
            type: "primary-outline",
            "full-rounded": "",
            label: _ctx.$t('show-more-contacts'),
            class: "plan-mb-xl",
            style: {"width":"328px"}
          }, null, 8, ["onOnClick", "loading", "label"])
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          type: "cancel",
          label: _ctx.$t('cancel')
        }, null, 8, ["onOnClick", "label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          type: "primary",
          label: _ctx.$t('continue-with-the-email')
        }, null, 8, ["onOnClick", "label"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}