<template>
  <div
    class="fixed inset-0 flex items-center justify-center h-full w-full z-50 bg-gray-500 bg-opacity-75 transition-opacity"
    id="modal"
  >
    <div
      class="bg-white p-8 lg:rounded-lg flex w-full lg:w-1/2 h-screen lg:h-5/6 flex-col overflow-hidden max-w-[50rem]"
    >
      <header class="sticky-top bg-white h-fi justify-end flex flex-row">
        <button
          class="text-gray-500 hover:text-gray-800 justify-end"
          @click="$emit('closeModal')"
        >
        <XCircleIcon class="w-8 h-8 text-corp" />
        </button>
      </header>
      <div class="border-b justify-center flex flex-row items-center self-center">
        <ul
          class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
        >
          <li class="mr-2">
            <a
              @click="activeTab = 'Driver&Vehicle'"
              class="inline-flex p-4 rounded-t-lg active group cursor-pointer"
              :class="
                activeTab === 'Driver&Vehicle'
                  ? 'text-blue-600 border-b-2 border-blue-600'
                  : 'hover:text-gray-600 hover:border-gray-300'
              "
              aria-current="page"
            >
              <InformationCircleIcon class="h-6 w-6 text-gray-500" />
              <p class="mt-0.5">{{ $t('driver-vehicle') }}</p>
            </a>
          </li>
          <li class="mr-2">
            <a
              @click="activeTab = 'Insurances'"
              :class="
                activeTab === 'Insurances'
                  ? 'text-blue-600 border-b-2 border-blue-600'
                  : 'hover:text-gray-600 hover:border-gray-300'
              "
              class="inline-flex p-4 border-b-2 border-transparent rounded-t-lg cursor-pointer group"
            >
              <DocumentIcon class="h-6 w-6 text-gray-500" />
              <p class="mt-0.5">{{ $t('insurances') }}</p>
            </a>
          </li>
        </ul>
      </div>

      <UpdateModalCarrier
        :shippmentId="$route.params.id"
        :mainBodyClasses="'flex justify-center justify-items-center'"
        @close="showUpdateCarrier = !showUpdateCarrier"
        @closeModal="$emit('closeModal')"
        v-if="activeTab === 'Driver&Vehicle'"
      />

      <div class="flex justify-center justify-items-center overflow-y-scroll">
        <div class="w-[70%]">
          <ServiceInsurances
            v-if="activeTab === 'Insurances'"
            :shippmentId="$route.params.id"
            :type="'UpdateInsurances'"
            @closeModal="$emit('closeModal')"
            @nextStep="nextStep"
            @setCompleted="setCompleted"
            @isFilledRC="isFilledRC = true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DocumentIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
// Modules
import UpdateModalCarrier from '@/modules/carrier/modals/UpdateModalCarrier.vue';
import ServiceInsurances from '@/modules/carrier/acceptService/AcceptServiceStep3.vue';

export default {
  components: {
    DocumentIcon,
    InformationCircleIcon,
    ServiceInsurances,
    UpdateModalCarrier,
    XCircleIcon
  },
  data() {
    return {
      activeTab: 'Driver&Vehicle',
      showUpdateCarrier: false,
    };
  },
  mounted() {
    console.log();
  },
  methods: {},
};
</script>
