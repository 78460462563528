
import { defineComponent, ref, computed } from 'vue';
import BaseComponent from '@/base/BaseComponent';
import CountryMixins from '@/services/country/_mixins';
import { useStore } from 'vuex';

export default defineComponent({
  mixins: [BaseComponent, CountryMixins],
  props: {
    title: {
      type: String,
      required: true,
    },
    unitAmount: {
      required: false,
      default: 0,
    },
    type: {
      type: String,
      required: true,
    },
    showSpinner: {
      required: false,
      default: false,
    },
    subscribeBasicPlan: {
      type: Function,
      required: false,
      default: () => null,
    },
  },
  setup() {
    const isHovered = ref(false);
    const store = useStore();

    const handleMouseOver = () => {
      isHovered.value = true;
    };

    const handleMouseLeave = () => {
      isHovered.value = false;
    };

    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    const userRegion = computed(() => {
      return currentUser.value.company?.country?.region?.name
    });

    return { isHovered, handleMouseOver, handleMouseLeave, currentUser, userRegion };
  },
});
