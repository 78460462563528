
import { defineComponent, ref, onMounted, useSlots, watch } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'BaseModal',
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  emits: ['callbackClose'],
  props: {
    // full: w-full | large: max-w-screen-lg | medium: max-w-screen-md | small: max-w-screen-sm | extra-small: max-w-screen-xs
    modalSize: String,
    show: Boolean,
    hideClose: Boolean
  },
  setup(props, { emit }) {
    /** Global */
    const slots = useSlots();
    const modalWidthSize = ref('');

    /** onMounted */
    onMounted(() => {
      modalWidthSize.value = getScreenSize();
    });

    /** Methods */
    const getScreenSize = () => {
      switch (props.modalSize) {
        case 'full':
          return 'w-full';
        case 'large':
          return 'max-w-screen-lg';
        case 'medium':
          return 'max-w-screen-md';
        case 'small':
          return 'max-w-screen-sm';
        case 'extra-small':
          return 'max-w-screen-xs';
        default:
          return 'max-w-screen-md';
      }
    };

    const callbackClose = (isOpen: boolean) => {
      emit('callbackClose', isOpen);
    };

    /** Watchers */
    watch(
      () => props.show,
      (newValue) => {
        if (newValue === true) {
          modalWidthSize.value = getScreenSize();
        }
      },
    );

    return {
      modalWidthSize,
      slots,
      callbackClose,
      getScreenSize,
    };
  },
});
