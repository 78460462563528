<template>
  <div
    id="moveHorizontalScroll"
    class="h-full w-2/3 bg-white cursor-grab overflow-x-scroll"
  >
    <table class="w-full text-left">
      <thead
        class="h-16 sticky top-0 bg-white z-10 bg-gray-50 border-b border-sky-500 border-opacity-50"
      >
        <tr>
          <th
            scope="col"
            class="px-5 md:px-auto 2xl:px-2 md:w-3/12 lg:w-4/12 w-2/12"
          >
            <div class="flex items-center space-x-2 text-sm uppercase cursor-pointer">
              <span class="text-xs lg:text-[14px]"></span>
            </div>
          </th>
          <th
            scope="col"
            class="min-w-[85px] text-opacity-80 pt-3 align-top truncate text-small text-gray-600 font-semibold whitespace-normal py-2 px-3 text-center lg:text-left"
          >
            <div class="flex-items text-black text-opacity-80 font-normal">
              {{ $t('rating') }}
            </div>
          </th>
          <th
            scope="col"
            class="min-w-[85px] text-opacity-80 pt-3 align-top truncate text-small text-gray-600 font-semibold whitespace-normal py-2 px-3 text-center lg:text-left"
          >
            <div class="text-black text-opacity-80 font-normal">
              {{ $t('lane-history') }}
            </div>
          </th>
          <th
            scope="col"
            class="min-w-[85px] text-opacity-80 pt-3 align-top truncate text-small text-gray-600 font-semibold whitespace-normal py-2 px-3 text-center lg:text-left"
          >
            <div class="text-black text-opacity-80 font-normal">
              {{ $t('rankingModal.assigned-week') }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="border-b-2 border-sky-500">
        <tr
          v-for="(element, index) in data"
          class="h-[80px]"
          :key="index"
          :class="{
            'border-sky-500 border-b-2': isLastInGroup(index),
          }"
        >
          <td class="px-5 font-medium text-gray-900">
            <div class="flex">
              <div class="flex items-center space-x-3">
                <p class="text-gray-900 whitespace-no-wrap min-w-[1rem]">
                  {{ element.quote?.rankingOrder ?? '-' }}
                </p>
                <div
                  class="flex w-10 h-10 justify-center items-center rounded-full text-center text-white"
                  :class="getUserDefaultImage(element.company.name).color"
                >
                  {{ getUserDefaultImage(element.company.name).name }}
                </div>
                <div class="flex flex-col pr-4 w-36">
                  <div class="text-black text-[15px] font-medium">
                    {{ element.company?.name }}
                  </div>
                  <div class="text-stone-500 text-[15px] font-normal">
                    {{ element.company?.owner?.name + ' ' + element.company?.owner?.surname }}
                  </div>
                </div>
                <a
                  v-if="!isShipperViewer"
                  class="hover:opacity-70 relative cursor-pointer"
                  @click="onClickOpenChat($event, element)"
                >
                  <img
                    alt="Chat icon"
                    class="w-7 h-7 m-width-none"
                    :src="getAsset.icChatNew"
                  />
                  <span
                    v-if="!isRead(element)"
                    class="absolute -top-1 -right-1 flex h-3 w-3"
                  >
                    <span
                      class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"
                    ></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                  </span>
                </a>
              </div>
            </div>
          </td>
          <td class="px-5">
            <div class="flex items-center space-x-1">
              <StarIcon class="w-4 h-4" />
              <span class="text-black text-[15px] font-normal">
                {{ formatValoration(element.company.stats.amountValorations) }}
              </span>
            </div>
          </td>
          <td class="px-5">
            <div class="flex flex-col">
              <span>
                {{
                  $t('loads-with-param', {
                    n: element?.company?.rfpStats?.loadAmount,
                  })
                }}
              </span>
              <span
                v-if="element?.company?.rfpStats?.lastLoadDate"
                class="text-xs"
              >
                {{ $t('last') }}:
                {{ formatDate(element?.company?.rfpStats?.lastLoadDate) }}
              </span>
            </div>
          </td>
          <td class="px-5">
              {{ element?.company?.rfpStats?.currentPeriodLoads }} /
              {{ element?.company?.rfpStats?.loadAmount }}
              {{ $t('loads') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Styles
import HorizontalScroll from '@/resources/assets/styles/animation/HorizontalScroll.vue';

export default defineComponent({
  name: 'TopOffersLeftTable',
  mixins: [BaseComponent, HorizontalScroll],
  emits: ['onClickOpenChat'],
  props: {
    data: {
      type: Array,
      required: true,
    },
    isLastInGroup: {
      type: Function,
      required: true,
    },
    isRead: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isShipperViewer() {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
  },
  methods: {
    onClickOpenChat(event, element) {
      this.$emit('onClickOpenChat', event, element);
    },
  },
});
</script>

<style>
.text-small {
  font-size: 0.75rem;
}
</style>
