
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import { locationString, locationObject } from '@/utils/formatLocation';

// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateSpin.vue';
import WaitingLanesModal from '@/modules/rfp/_components/modals/WaitingLanesModal.vue';
// Resources
import i18n from '@/resources/locales';
// Router
import router from '@/router';
// Services
import store from '@/services/store';

import { formatDate } from '@/utils/formatDates';

export default defineComponent({
  name: 'ShowImportFileContainer',
  components: {
    ChevronLeftIcon,
    Spin,
    WaitingLanesModal,
  },
  mixins: [BaseComponent],
  emits: ['hideImportFile'],
  setup: function (props, { emit }) {
    // GLOBALS
    const $t = i18n.global.t;
    const route = useRoute();
    const loading = ref(false);

    // DATA
    const selectedFile = ref('');

    const goback = () => {
      emit('hideImportFile');
    };

    const ADDRESS_COMPONENTS = {
      subpremise: 'short_name',
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      sublocality: 'long_name',
      administrative_area_level_1: 'short_name',
      administrative_area_level_2: 'long_name',
      administrative_area_level_3: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };

    const downloadExcel = async () => {
      let blob = await store.dispatch('RFPStore/getRFPExcel', route.params.quoteId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'planimatik-lane-template.xlsx';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };

    const handleFileChange = (event) => {
      selectedFile.value = event.target.files[0];
      uploadExcel();
    };

    const asyncResultLanes = computed(() => {
      return store.getters['RFPStore/getAsyncResultLanes'];
    });

    const editingLanes = computed(() => {
      return store.getters['RFPStore/getCurrentEditingLanes'];
    });

    const editingLanesLengh = computed(() => {
      return editingLanes.value.length;
    });

    const showWaitingLanes = computed(() => {
      return store.getters['RFPStore/getShowWaitingLanes'];
    });

    const onClickFile = () => {
      const fileInput = document.getElementById('file-input');
      if (fileInput) {
        fileInput.click();
      }
    };

    const formatLanes = (response) => {
      let list = [] as any;
      for (let index = 0; index < response.length; index++) {
        let pickupPlace = formatLocation(response[index].placeDetailsPickup);
        let pickLocation = locationObject(pickupPlace);

        pickLocation.lat = response[index].placeDetailsPickup?.geometry.location.lat;
        pickLocation.long = response[index].placeDetailsPickup?.geometry.location.lng;

        const countryObjectPick = response[index]?.placeDetailsPickup?.address_components?.find(
          (component) =>
            component.types.includes('country') && component.types.includes('political'),
        );

        pickLocation.country = countryObjectPick?.short_name;
        pickLocation.googlePlaceId = response[index].placeDetailsPickup?.place_id;

        let delPlace = formatLocation(response[index].placeDetailsDelivery);
        let delLocation = locationObject(delPlace);

        const countryObjectDel = response[index]?.placeDetailsDelivery?.address_components?.find(
          (component) =>
            component.types.includes('country') && component.types.includes('political'),
        );

        delLocation.country = countryObjectDel?.short_name;
        delLocation.googlePlaceId = response[index].placeDetailsDelivery?.place_id;

        delLocation.lat = response[index].placeDetailsDelivery?.geometry.location.lat;
        delLocation.long = response[index].placeDetailsDelivery?.geometry.location.lng;

        list.push({
          loadsFrequency: response[index]?.loadsFrequency || null,
          loadsFrequencyPeriod: response[index]?.loadsFrequencyPeriod || null,
          loadTemplate: {
            stops: [
              {
                executionDate: formatDate(response[index]?.startDate, true) || '',
                type: 'PICKUP_INITIAL',
                place: locationString(pickupPlace),
                location: pickLocation,
              },
              {
                executionDate: formatDate(response[index]?.endDate, true) || '',
                type: 'DELIVERY_FINAL',
                place: locationString(delPlace),
                location: delLocation,
              },
            ],
            commodityMaster: {
              weight: response[index]?.weight || null,
              commodityName: response[index]?.commodity || null,
              nPallets: response[index]?.nPallets ||  null,
              packageType: { id: response[index]?.packageType?.id || null },
              minTemp: response[index]?.minTemp || null,
              maxTemp: response[index]?.maxTemp || null
            },
            minTemp: response[index]?.minTemp || null,
            maxTemp: response[index]?.maxTemp || null,
            trailerType: {
              id: response[index]?.trailerType?.id || null,
            },
          },
          observations: response[index]?.observations || ''
        });
      }
      return list;
    };

    const formatLocation = (place) => {
      let returnData = {} as any;
      if (place) {
        for (let i = 0; i < place.address_components.length; i++) {
          for (let index = 0; index < place.address_components[i].types.length; index++) {
            let addressType = place.address_components[i].types[index];

            if (ADDRESS_COMPONENTS[addressType]) {
              if (addressType === 'administrative_area_level_1') {
                const val = place.address_components[i]['long_name'];
                if (!returnData.administrative_area_level_2) {
                  returnData['administrative_area_level_2'] = val;
                }
                if (!returnData.locality) {
                  returnData['locality'] = val;
                }
              }
              let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
              returnData[addressType] = val;
            }
          }
        }
      }

      return returnData;
    };

    const uploadExcel = () => {
      if (!selectedFile.value) {
        BaseComponent.methods?.showToastSuccess($t('no-files-selected'));
        return;
      }
      let laneList = [] as any;
      const formData = new FormData();
      formData.append('file', selectedFile.value);

      loading.value = true;
      store
        .dispatch('RFPStore/postImportLanesByExcel', formData)
        .then((response) => {
          if (response === 'Async') {
            store.commit('RFPStore/setShowWaitingLanes', true);
            return;
          }
          if (response.length === 0) {
            BaseComponent.methods?.showToastError($t('file-not-valid'));
            loading.value = false;
            return;
          }
          laneList = formatLanes(response);
          loading.value = false;
          store.commit('RFPStore/setCurrentEditingLanes', laneList);
          router.push({
            name: 'addLanesRFP',
            query: { loadCreationMode: 'importExcel' },
            params: { projectId: route.params.projectId },
          });
        })
        .catch((err) => {
          if (err === 'Async') {
            store.commit('RFPStore/setShowWaitingLanes', true);
            return;
          }
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          loading.value = false;
        });
    };

    watch(
      () => store.getters['RFPStore/getAsyncResultLanes'],
      (newValue, oldValue) => {
        try {
          store.commit('RFPStore/setCurrentEditingLanes', formatLanes(newValue.data));
          store.commit('RFPStore/setShowWaitingLanes', false);
          router.push({
            name: 'addLanesRFP',
            query: { loadCreationMode: 'importExcel' },
            params: { projectId: route.params.projectId },
          });
        } catch (error) {
          console.log('error: ', error);
          BaseComponent.methods?.showToastError('Lane format invalid');
          store.commit('RFPStore/setShowWaitingLanes', false);
          loading.value = false;
        }
      },
    );

    return {
      editingLanesLengh,
      editingLanes,
      downloadExcel,
      goback,
      handleFileChange,
      loading,
      onClickFile,
      selectedFile,
      showWaitingLanes,
      uploadExcel,
    };
  },
});
