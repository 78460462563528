
import { defineComponent } from 'vue';
import { saveAs } from 'file-saver';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CarrierHeader from '@/components/headers/CarrierHeader.vue';
import CarrierHeaderInfo from '@/modules/carrier/_components/CarrierHeader.vue';
import CarrierLTLBody from '@/modules/carrier/quotationRequest/QuotationLTLBody.vue';
import CarrierRateConfirmationVue from '../rateConfirmation/CarrierRateConfirmation.vue';
import FixedBottomContainerButtonVue from '../_components/FixedBottomContainerButton.vue';
import ModalHash from '@/components/modals/HashModal.vue';
import ModalInvoices from '@/components/modals/InvoicesModal.vue';
import NotifyIncidenceCarrier from '@/modules/carrier/modals/NotifyIncidenceCarrier.vue';
import UploadDocuments from '@/modules/carrier/modals/UploadDocuments.vue';
import NotifyPosition from '@/modules/carrier/modals/NotifyPosition.vue';
import ServiceAcceptanceVue from '../acceptService/ServiceAcceptance.vue';
import SidebarChat from '@/components/sidebar/ChatSidebar.vue';
import SkeletonCarrier from '@/components/skeletons/CarrierSkeleton.vue';
import SmallModalSuccess from '../../planning/loadDetail/success/SmallModalSuccess.vue';
import UpdateInfoModal from '@/modules/carrier/modals/UpdateInfoModal.vue';
import UpdateModalCarrier from '../modals/UpdateModalCarrier.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import ReportAdditionalFeeModal from '@/modules/carrier/quotationRequest/_componentes/ReportAdditionalFeeModal.vue';
import ImpersonatingBar from '@/components/sidebar/ImpersonatingBar.vue';
import i18n from '@/resources/locales';
import UpdatePositionModal from '@/modules/carrier/quotationRequest/_componentes/UpdatePositionModal.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
interface IQuotationRequest {
  price: number;
  carrierBidRequest: number;
  sendingAction: boolean;
  allowed: boolean;
  showQuoteInput: boolean;
  completed: boolean;
  isCollapsed: any;
  carrierConfirInformation: any;
  currentStep: any;
  showRateConfirmation: any;
  showIncidenceModal: any;
  showUploadBill: any;
  loadSelected: any;
  showRateModal: any;
  showUpdateCarrier: any;
  openInvoices: boolean;
  loading: boolean;
  showNotifyModal: boolean;
  showUpdateInfoModal: boolean;
}

export default defineComponent({
  name: 'QuotationRequestView',
  components: {
    UpdatePositionModal,
    ReportAdditionalFeeModal,
    PlanButton,
    CarrierHeader,
    CarrierHeaderInfo,
    CarrierLTLBody,
    CarrierRateConfirmationVue,
    ErrorMessage,
    Field,
    Form,
    FixedBottomContainerButtonVue,
    ImpersonatingBar,
    ModalHash,
    ModalInvoices,
    NotifyIncidenceCarrier,
    NotifyPosition,
    ServiceAcceptanceVue,
    SidebarChat,
    SkeletonCarrier,
    SmallModalSuccess,
    UpdateInfoModal,
    UpdateModalCarrier,
    UploadDocuments,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    id: String,
    hash: {
      type: String,
      default: null,
    },
    code: String,
  },
  data() {
    return {
      carrierConfirInformation: false,
      currentStep: 'default',
      showUpdateInfoModal: false,
      showNotifyModal: false,
      showUpdateCarrier: false,
      loadSelected: null as any,
      showRateModal: true,
      showIncidenceModal: false,
      showUploadDocumentsModal: false,
      showIncidenceModalOld: false,
      showUploadBill: false,
      isCollapsed: false,
      carrierBidRequest: 0,
      price: 0 as any,
      sendingAction: false,
      allowed: true,
      showQuoteInput: false,
      completed: false,
      showRateConfirmation: false,
      openInvoices: false,
      loading: false,
    };
  },
  beforeCreate() {
    this.$store.dispatch('PusherStore/initializePusher');
  },
  async created() {
    this.fetchAllInfo();
    if (
      this.currentRouteName === 'carrierQuoteEmail' ||
      this.currentRouteName === 'carrierQuoteEmailCode'
    ) {
      this.$store.commit('GlobalStore/setEmailConfigModal', true);
    }
  },
  computed: {
    isAdminSuplanting() {
      return (
        this.currentUser && this.currentUser?.company?.type === 'ADMIN' && this.isSuplanting !== ''
      );
    },
    isEurope(): boolean {
      const country = this.dataCountries?.find(
        (country) => this.currentUser?.company?.country?.id === country?.id,
      );
      return country?.region?.name === 'EUROPE';
    },
    allDocumentsDone(): any {
      const obj = this.shipment;
      return obj.shipmentAcceptCompleted;
    },
    bidAccepted(): any {
      return this.shipment?.assignedCompany?.company?.id;
    },
    carrierBidData(): any {
      return this.$store.getters['LoadsStore/carrierBidData'];
    },
    company(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    consigneeFilledValue(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.loads[0].isConsigneeFilled !== 'undefined'
      ) {
        return this.shipment.loads[0].isConsigneeFilled;
      } else {
        return null;
      }
    },
    currenBid(): any {
      return this.$store.getters['LoadsStore/currentBid'];
    },

    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    currentRouteName(): any {
      return this.$route.name;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    file(): any {
      return this.$store.getters['QuotationRequestStore/getFile'];
    },
    filesToUpload(): any {
      return this.$store.getters['QuotationRequestStore/getFilesToUpload'];
    },
    hasDriver(): any {
      return (
        this.shipment.carrierValidation.hasDriver || this.shipment.carrierValidation.hasVehicle
      );
    },
    hasTrueProperties(): any {
      const propertiesToCheck = [
        'teamDriver',
        'bondedPermission',
        'tsa',
        'hazardousLoad',
        'specialPermissions',
        'TWICCard',
      ];

      if (this.shipment.type !== 'Alpine_LTL' && this.shipment?.loads[0]?.specialRequirement) {
        return propertiesToCheck.some((property) => {
          return this.shipment?.loads[0]?.specialRequirement[property];
        });
      } else {
        return null;
      }
    },
    isOpenChat(): any {
      return this.$store.state.GlobalStore.isOpenChat;
    },
    lowestBid(): any {
      if (this.shipment.bestBids && this.shipment.bestBids.length > 0) {
        const lowestBid = this.shipment.bestBids.reduce((lowest, current) => {
          return current.bid < lowest.bid ? current : lowest;
        });
        return lowestBid;
      } else {
        return null;
      }
    },
    nextStopDocumentStep(): any {
      if (
        this.shipment &&
        this.shipment.nextStopDocumentStep &&
        this.shipment.nextStopDocumentStep[0] &&
        typeof this.shipment.nextStopDocumentStep[0] !== 'undefined'
      ) {
        return this.shipment.nextStopDocumentStep[0];
      } else {
        return null;
      }
    },
    orderedStopsSequence(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.loads[0].stops !== 'undefined'
      ) {
        let stopsCopy = this.shipment.loads[0].stops.slice();

        let sortedArray = stopsCopy.sort((a, b) => a.sequence - b.sequence);

        return sortedArray;
      } else {
        return null;
      }
    },
    returnCurrentLoadForUploadDocs(): any {
      let loadSequence = this.nextStopDocumentStep.nextStepSequence;
      const stop = this.shipment?.loads[0].stops.find((stop) => stop.sequence === loadSequence);
      return stop ? stop : null;
    },
    sendingFile(): any {
      return this.$store.getters['QuotationRequestStore/getSendingFile'];
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
    shipmentId(): any {
      return this.$route.params.id;
    },
    stops(): any {
      const shipment = this.shipment;
      if (!shipment || !shipment.loads || shipment.loads.length === 0) {
        return [];
      }
      return shipment.loads[0].stops;
    },
    token(): any {
      return localStorage.getItem('token') ? localStorage.getItem('token') : null;
    },
    userEmailNotificationSetup(): any {
      return this.$store.getters['UserStore/getUserEmailNotificationSetup'];
    },
  },
  watch: {
    currentUser() {
      if (this.currentUser?.company) {
        this.checkCountry();
      }
    },
  },
  methods: {
    async fetch() {
      await this.$store.dispatch('ShipmentStore/shipment', { id: this.$route.params.id });
    },
    async addMemberToChatLoad(chatId) {
      await this.$store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    },
    changeComponent() {
      this.completed = !this.completed;
    },
    checkCountry() {
      this.$store
        .dispatch('CountryStore/getCountryById', this.currentUser?.company?.country?.id)
        .then(() => {
          i18n.global.locale = this.$store.getters[
            'CountryStore/getCountryDefaultLanguageLocale'
          ].substring(0, 2);
          localStorage.setItem(
            'userLocale',
            this.$store.getters['CountryStore/getCountryDefaultLanguageLocale'].substring(0, 2),
          );
          this.$emit('forceUpdate');
        });
    },
    clearInputFiles(sequence) {
      this.$store.commit('QuotationRequestStore/removeFile', sequence);
      if (this.filesToUpload.find((file) => file.sequence === sequence)) {
        this.$store.commit('QuotationRequestStore/setFile', null);
      }
    },
    confirmInformation() {
      this.completed = true;
      this.carrierConfirInformation = !this.carrierConfirInformation;
    },
    convertKeyToString(key) {
      let convertedString;
      switch (key) {
        case 'TWICCard':
          convertedString = this.$t('twic-card');
          break;
        case 'tsa':
          convertedString = this.$t('tsa-card');
          break;
        case 'hazardousLoad':
          convertedString = this.$t('hazardousLoad');
          break;
        case 'specialPermissions':
          convertedString = this.$t('specialPermissions');
          break;
        case 'bondedPermission':
          convertedString = this.$t('bondedPermission');
          break;
        case 'teamDriver':
          convertedString = this.$t('teamDriver');
          break;
        case 'tir':
          convertedString = this.$t('tir');
          break;
      }

      return convertedString;
    },
    async download(stop, mediaObjectId, mediaObjectType) {
      if (stop.mediaObjects.length > 1) {
        this.handleUploadDocumentsModal(stop);
      } else {
        const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
          id: mediaObjectId,
          class: mediaObjectType,
        });
        const fileName = `${
          stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP'
            ? `${this.$t('bol')}-`
            : `${this.$t('pod')}-`
        }#${stop.sequence}-${mediaObjectId}`;
        let file = document.createElement('a');
        file.href = URL.createObjectURL(response);
        file.download = `documents-${fileName}`;
        file.click();
      }
    },
    enableAdditionalFees(loadStatus) {
      return loadStatus === 'ASSIGNED' || loadStatus === 'IN_PROGRESS' || loadStatus === 'FINISHED';
    },
    async fetchAllInfo() {
      this.loading = true;
      const id = this.$route.params.id;
      const hash: any = this.$route.params.hash;
      const code: any = this.$route.params.code;

      if (hash && code) {
        await this.$store
          .dispatch('UserStore/hashValidation', {
            hashId: hash,
            secureCode: code,
          })
          .then(() => {
            this.$store.dispatch('PusherStore/initializePusher');
            switch (this.$route.name) {
              case 'carrierQuoteEmailCode':
                this.$router.push('/carrier/shipment/email/' + id);
                break;
              case 'carrierQuoteChatCode':
                this.$router.push('/carrier/shipment/chat/' + id);
                break;
              case 'carrierQuoteCode':
                this.$router.push('/carrier/shipment/' + id);
                break;
              default:
                break;
            }
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
            this.$store.commit('UserStore/setShowHash', true);
          });
      }

      this.$store.dispatch('ShipmentStore/getActivitySummary', 'QUOTES');

      await this.$store.dispatch('ShipmentStore/shipment', { id: id }).then(() => {
        let currentCompanyId = this.isAdminSuplanting
          ? this.currentUserImpersonating?.company?.id
          : this.currentUser?.company?.id;

        const data = this.shipment?.bestBids.find((obj) => obj.companyId === currentCompanyId);

        this.$store.dispatch('LoadsStore/currentBid', data ? data.bid : null);
        this.$store.dispatch('LoadsStore/carrierBidData', data);
      });
      await this.$store.dispatch('LoadsStore/loadById', this.shipment.loads[0].id);

      this.loading = false;
      if (this.$route.name === 'carrierQuoteChat' || this.$route.name === 'carrierQuoteChatHash') {
        this.openChat();
      }
      this.$store.dispatch('UserStore/getUserEmailNotificationSetup').then(() => {
        if (!this.userEmailNotificationSetup) {
          this.$store.dispatch('UserStore/postUserEmailNotificationSetup').then(() => {
            this.$store.dispatch('UserStore/getUserEmailNotificationSetup');
          });
        }
      });
    },
    async fileChange(event, sequence) {
      this.clearInputFiles(sequence);
      let file = {
        sequence,
        name: event.target.files[0].name,
        url: URL.createObjectURL(event.target.files[0]),
      };
      this.$store.commit('QuotationRequestStore/pushFile', file);
      this.$store.commit('QuotationRequestStore/setFile', event.target.files[0]);
    },
    formatNumber(value) {
      if (!value) {
        return '';
      }
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },
    getLastDocumentUploaded(index): any {
      const biggestObject = this.orderedStopsSequence[index].mediaObjects.reduce((acc, cur) => {
        return cur.id > acc.id ? cur : acc;
      });

      return biggestObject;
    },
    async openChat() {
      if (this.currentLoad.status === 'QUOTING') {
        this.openChatQuoting();
      } else {
        const member = this.currentLoad?.chat?.chatMembers.find(
          (member) => member.user.id === this.currentUser?.id,
        );
        if (member) {
          member.readed = true;
        } else {
          if (this.currentLoad?.chat?.id) {
            await this.addMemberToChatLoad(this.currentLoad.chat.id);
          }
        }
        this.$store.commit('LoadsStore/loadId', this.currentLoad.id);
        this.$store.commit(
          'ChatsStore/setChatId',
          this.currentLoad.chat ? this.currentLoad.chat.id : null,
        );
        this.$store.commit('GlobalStore/setOpenChat', true);
      }
    },
    openChatQuoting() {
      if (!this.isOpenChat) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        const findChat = this.currentLoad.quotingChats.find((chat) =>
          chat.chatMembers.some((member) => member.user.id === this.currentUser?.id),
        );
        const memberMe = findChat.chatMembers.find(
          (member) => member.user.id === this.currentUser?.id,
        );
        const member = findChat.chatMembers.find(
          (member) => member.user.id !== this.currentUser?.id,
        );
        if (memberMe) memberMe.readed = true;
        setTimeout(() => {
          this.$store.commit('LoadsStore/loadId', this.currentLoad.id);
          this.$store.commit('ChatsStore/setUserForChat', member);
          this.$store.commit('ChatsStore/setChatId', findChat ? findChat.id : null);
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    showBillModalEvent() {
      this.showUploadBill = true;
    },
    handleUploadDocumentsModal(load) {
      this.loadSelected = load;
      this.showUploadDocumentsModal = true;
    },
    showIncidenceModalEvent(load) {
      this.loadSelected = load;
      this.showIncidenceModal = true;
    },
    showIncidenceModalEventOld(load) {
      this.loadSelected = load;
      this.showIncidenceModalOld = true;
    },
    signRate() {
      if (!this.allDocumentsDone) {
        BaseComponent.methods?.showToastSuccess(this.$t('message-documents-pending-upload'));
        this.completed = !this.completed;
      } else {
        if (this.completed) {
          this.completed = !this.completed;
        }
        this.showRateConfirmation = true;
        this.currentStep = 'signRate';
      }
    },
    toggleInvoices(bool) {
      this.openInvoices = bool;
    },
    toggleNotifyIncidence(bool) {
      this.$store.dispatch('LoadsStore/loadById', this.shipment.loads[0].id).then(() => {
        this.showIncidenceModal = bool;
      });
    },
    updateInvoicesVisibility(isVisible) {
      this.openInvoices = isVisible;
    },
    updateModalVisibility(isVisible) {
      this.showNotifyModal = isVisible;
    },
    async uploadDocument(stop) {
      const validation = await (this.$refs['formFiles' + stop.sequence] as any)[0].validate();
      if (!validation.valid) {
        return;
      }

      if (this.file) {
        this.$store.commit('QuotationRequestStore/setSendingFile', stop.sequence);
        this.$store
          .dispatch('ShipmentStore/postMediaObjectStops', {
            entityId: stop.id,
            file: this.file,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('documents-uploaded-done'));
            this.file = null;
            const id = this.$route.params.id;
            this.$store.dispatch('ShipmentStore/shipment', { id: id });
            this.clearInputFiles(stop.sequence);
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          })
          .finally(() => {
            this.$store.commit('QuotationRequestStore/setSendingFile', -1);
          });
      }
    },
    async viewDocument(stop, mediaObjectId, mediaObjectType) {
      this.handleUploadDocumentsModal(stop);
    },
  },
});
