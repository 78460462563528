
import { defineComponent, computed, ref } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'QuickFilter',
  components: { PlanIcon },
  props: {
    id: { type: String, required: true, default: '' },
    icon: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    quantity: { type: Number, required: true, default: 0 },
    type: { type: String, required: false, default: 'sec-500' },
    onlyIcon: { type: Boolean, required: false, default: false }
  },
  emits: ['change'],
  setup (props, { emit }) {
    const store = useStore()

    const isActive = computed(() => {
      if (props.quantity === 0) return false
      return store.getters['LoadsStore/getSelectedQuickFilter'] === props.id
    })

    const iconColor = computed(() => {
      if (props.quantity === 0) return '#8A8B97'
      if (props.type === 'red-500') {
        return isActive.value ? 'white' : '#EF4444'
      }
      return isActive.value ? '#FFFFFF':'#0097FF'
    })

    const changeStatus = () => {
      if (props.quantity === 0) return

      if (store.getters['LoadsStore/getSelectedQuickFilter'] === props.id) {
        store.commit('LoadsStore/setSelectedQuickFilter', '')
      } else {
        store.commit('LoadsStore/setSelectedQuickFilter', props.id)
      }
      emit('change')
    }

    return {
      iconColor,
      isActive,
      changeStatus
    }
  }
})
