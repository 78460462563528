import { initialState } from '@/services/global/_store/index';

export default {
  clearGlobalStore(state: any) {
    Object.assign(state, initialState);
  },
  setActiveStatus(state: any, payload: string) {
    state.activeStatus = payload;
  },
  setUserLanguageModal(state: any, payload: any) {
    state.userLanguageModal = payload;
  },
  setEmailConfigModal(state: any, payload: any) {
    state.emailConfigModal = payload;
  },
  setGlobalConfig(state: any, payload: any) {
    state.globalConfig = payload;
  },
  setSection(state: any, payload: string) {
    state.section = payload;
  },
  setOpenChat(state: any, payload: boolean) {
    state.isOpenChat = payload;
  },
  setPlanificationFilters(state: any, payload: any) {
    state.planificationFilters = payload;
  },
  setSectionFromPlanification(state: any, payload: any) {
    state.sectionFromPlanification = payload
  },
}
