
import { defineComponent } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'PlanDefaultStatusModal',
  components: { PlanIcon, PlanButton, PlanDefaultModal },
  props: {
    title: { type: String, required: true, default: '' },
    message: { type: String, required: true, default: '' },
    status: { type: Boolean, required: false, default: true}
  },
  emits: ['close'],
  setup (props, { emit }) {
    const close = () => { emit('close') }

    return {
      close
    }
  }
})
