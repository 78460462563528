
import { defineComponent, reactive, ref, toRefs, watch } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

interface planDefaultTable {
  selectAll: boolean;
  selectedItems: Array<any>;
  selectedOrder: {
    value: string;
    direction: string;
  };
}

export default defineComponent({
  name: 'PlanDefaultTable',
  components: { PlanIcon },
  props: {
    headers: { type: Array, required: true },
    truncateHeaders: { type: Boolean, required: false, default: false },
    items: { type: Array, required: true },
    selectable: { type: Boolean, required: false, default: false },
    order: { type: String, required: false, default: '' },
    orderDirection: { type: String, required: false, default: 'asc' },
    selectedItems: { type: Array, default: () => [] },
  },
  emits: ['changeOrder', 'selectedItems'],
  setup(props, { emit }) {
    const data = reactive<planDefaultTable>({
      selectAll: false,
      selectedItems: [],
      selectedOrder: {
        value: '',
        direction: 'desc',
      },
    });

    data.selectedOrder.value = props.order + '';
    data.selectedOrder.direction = props.orderDirection + '';

    const changeOrder = (header: any) => {
      if (header.sortable) {
        if (data.selectedOrder.value === header.value) {
          data.selectedOrder.direction = data.selectedOrder.direction === 'desc' ? 'asc' : 'desc';
        } else {
          data.selectedOrder.value = header.value;
          data.selectedOrder.direction = 'desc';
        }
        emit('changeOrder', data.selectedOrder);
      }
    };

    const changeSelectAll = () => {
      if (data.selectAll) {
        data.selectedItems = props.items.slice();
      } else {
        data.selectedItems = [];
      }
      emit('selectedItems', data.selectedItems);
    };

    const changeSelectedItem = (row: any) => {
      if (data.selectedItems.indexOf(row) === -1) {
        data.selectedItems.push(row);
      } else {
        data.selectedItems.splice(data.selectedItems.indexOf(row), 1);
      }

      data.selectAll = data.selectedItems.length === props.items.length;
      emit('selectedItems', data.selectedItems);
    };

    const isSelected = (row: any) => {
      return data.selectedItems.includes(row);
    };

    watch(
      () => props.selectedItems,
      (newSelectedItems) => {
        if (newSelectedItems.length === 0) {
          data.selectedItems = [];
        } else {
          data.selectedItems = props.selectedItems.concat([])
        }
      },
    );

    if (props.selectedItems.length > 0) data.selectedItems = props.selectedItems.concat([])

    return {
      ...toRefs(data),
      changeOrder,
      changeSelectAll,
      changeSelectedItem,
      isSelected,
    };
  },
});
