import globalAxios from 'axios';

export default {
  async getDemoRequestById(id: number) {
    const response = await globalAxios.get(`/demo_requests/${id}`);
    return Promise.resolve(response.data);
  },
  async getDemoRequests(params: object) {
    const response = await globalAxios.get('/demo_requests', { params });
    return Promise.resolve(response.data);
  },
  async getDemoRequestsTotal() {
    const response = await globalAxios.get('/demo_requests/totals/by_status');
    return Promise.resolve(response.data);
  },
  async postDemoRequests(params: object) {
    const response = await globalAxios.post(`/demo_requests`, params);
    return Promise.resolve(response.data);
  },
  async postDemoRequestsAccept(id: number) {
    const response = await globalAxios.post(`/demo_requests/${id}/accept`);
    return Promise.resolve(response.data);
  },
  async postDemoRequestsDecline(id: number) {
    const response = await globalAxios.post(`/demo_requests/${id}/decline`);
    return Promise.resolve(response.data);
  },
  async putDemoRequests(params: any) {
    const { id, body } = params;
    const response = await globalAxios.put(`/demo_requests/${id}`, body);
    return Promise.resolve(response.data);
  },
}
