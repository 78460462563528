import notificationsApi from '../_api';

export default {
  async clearNotificationStore(context: any) {
    context.commit('clearNotificationStore');
  },
  async getEntryNotifications({ commit }) {
    const response = await notificationsApi.getEntryNotifications();
    commit('setEntryNotifications', response);
  },
};
