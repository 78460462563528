
import { defineComponent, ref } from 'vue';
import { ErrorMessage } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/outline';
//Base
import BaseComponent from '@/base/BaseComponent';
// Components
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
import PlanCountrySelector from '@/components/forms/PlanCountrySelector.vue';

// Utils
import { objectLocationToString } from '@/utils/formatLocation';

interface Position {
  shipment: {
    id: number;
  };
  location: {
    lat: number | null;
    long: number | null;
    zip: number | null;
    city: string | undefined;
    state: {
      id: number;
    };
  };
}

export default defineComponent({
  name: 'LocationUpdateModal',
  components: {
    PlanCountrySelector,
    ErrorMessage,
    GoogleAutocomplete,
    Spin,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    shipmentId: {
      type: String,
    },
  },
  data() {
    return {
      allowed: false,
      sending: false,
      truckLocation: ref<Position | null>(null),
      status: 'IN_TRANSIT' as any,
      stop: null as any,
      defaultCountrySelected: '' as any,
    };
  },
  computed: {
    countriesList(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    currentCountryIso(): any {
      return this.defaultCountrySelected
        ? this.defaultCountrySelected?.iso
        : this.countriesList?.find((country) => country?.id === this.currentCountry?.id);
    },
    objectLocationToString(): any {
      return objectLocationToString;
    },
    shipmentTrackingSubStatus(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']?.ShipmentTrackingSubStatus;
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
    location(): any {
      if (this.truckLocation !== null) {
        var zip: any = '';
        var city: any = '';
        var coma = ['', ''];
        if (this.truckLocation?.location?.zip !== null) {
          zip = this.truckLocation?.location?.zip;
          coma[0] = ', ';
        }
        if (this.truckLocation?.location?.city !== '') {
          city = this.truckLocation?.location?.city;
          coma[1] = ', ';
        }
        return `${zip}${coma[0]}${city}${coma[1]}${
          this.states.find((state) => state.id === this.truckLocation?.location.state.id).name
        }`;
      } else {
        return '';
      }
    },
    origin(): any {
      return this.currentLoad.stops.find((obj) => obj.type === 'PICKUP_INITIAL');
    },
    destiny(): any {
      return this.currentLoad.stops.find((obj) => obj.type === 'DELIVERY_FINAL');
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    stopSelected(): any {
      return this.stopsList.find((stop) => stop.id === this.stop);
    },
    stopsList(): any {
      let list = [] as any;
      switch (this.status) {
        case 'ON_SITE':
          list = this.currentLoad.stops;
          break;
        case 'LOADING':
          list = this.currentLoad.stops.filter(
            (stop) => stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP',
          );
          break;
        case 'UNLOADING':
          list = this.currentLoad.stops.filter(
            (stop) => stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL',
          );
          break;
        default:
          break;
      }
      return list;
    },
    pickUpQuantity(): any {
      let sum = 1;
      if (!this.currentLoad.stops || this.currentLoad.stops === undefined) return;
      this.currentLoad.stops.forEach((stop: any) => {
        if (stop.type === 'PICKUP') {
          sum++;
        }
      });
      return sum;
    },
    deliveriesQuantity(): any {
      let sum = 1;
      if (!this.currentLoad.stops || this.currentLoad.stops === undefined) return;
      this.currentLoad.stops.forEach((stop: any) => {
        if (stop.type === 'DELIVERY') {
          sum++;
        }
      });
      return sum;
    },
  },
  watch: {
    shipment() {
      if (this.shipment?.trackingSubStatus) {
        this.status = this.shipment?.trackingSubStatus;
      }
    },
    stopsList() {
      if (this.stopsList.length > 0) {
        this.stop = this.stopsList[0].id;
        this.setTruckLocation();
      }
    },
    status() {
      if (this.status == 'IN_TRANSIT') {
        this.truckLocation = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch('ShipmentStore/shipment', {
      id: this.currentLoad.shipment.id,
    });
  },
  methods: {
    clearInput() {
      this.truckLocation = null;
    },
    handleClick(country) {
      this.defaultCountrySelected = country;
    },
    filteredPickupsCompleted(load): any {
      const filteredObjects = load.filter(
        (obj) => (obj.type === 'PICKUP_INITIAL' || obj.type === 'PICKUP') && obj.doneAt,
      ).length;
      return filteredObjects;
    },
    filteredDeliveriesCompleted(load): any {
      const filteredObjects = load.filter(
        (obj) => (obj.type === 'DELIVERY_FINAL' || obj.type === 'DELIVERY') && obj.doneAt,
      ).length;
      return filteredObjects;
    },
    onPlaceChanged(event: any) {
      const states: any = this.$store.getters['LoadsStore/getStates'];
      let stateObj = states.find((state) => state.iso === event.administrative_area_level_1);

      if (!stateObj && event.administrative_area_level_2) {
        stateObj = states.find((state) => state.name === event.administrative_area_level_2);
      }

      let location;

      if (stateObj?.id) {
        location = {
          lat: event.latitude,
          long: event.longitude,
          zip: event.postal_code,
          city: event.locality,
          state: { id: stateObj.id },
          googlePlaceId: event.place_id,
        };
      } else {
        location = {
          lat: event.latitude,
          long: event.longitude,
          zip: event.postal_code,
          city: event.locality,
          googlePlaceId: event.place_id,
        };
      }

      this.truckLocation = {
        shipment: {
          id: Number(this.shipmentId),
        },
        location,
      };

      this.allowed = true;
    },
    setTruckLocation() {
      this.truckLocation = {
        shipment: {
          id: Number(this.shipmentId),
        },
        location: {
          lat: this.stopSelected.location.lat,
          long: this.stopSelected.location.long,
          zip: this.stopSelected.location.zip,
          city: this.stopSelected.location.city,
          state: { id: this.stopSelected.location.state.id },
        },
      };
      this.allowed = true;
    },
    sendLocation() {
      if (!this.allowed) {
        return;
      }
      this.sending = true;
      this.$emit('send', this.truckLocation);
      this.$store
        .dispatch('ShipmentStore/updateTrackingSubStatus', {
          id: this.currentLoad.shipment.id,
          body: { trackingSubStatus: this.status },
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    },
    closeLocation() {
      this.$emit('send', null);
    },
  },
});
