
import { defineComponent } from 'vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import { StarIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  components: { XCircleIcon, StarIcon },
  mixins: [BaseComponent],
  props: {},
  data() {
    return {
      selectedContacts: [] as any,
      searchTimeout: null as any,
      allowed: false,
      showSpinner: false,
      isSending: false,
      currentActiveTab: 'tenders',
      useContactPage: 1,
    };
  },
  created(): void {
    this.fetchInfo();
  },
  computed: {
    currentActiveGroup(): any {
      return this.$store.getters['LanesStore/currentActiveGroup'];
    },
    currentContacts(): any {
      return this.$store.getters['UserStore/carrierContactList'].filter(
        (item) =>
          item.source !== 'CARGOBOT' &&
          item.source !== 'ALPINE_CARRIER_API' &&
          item.name !== 'Planinatik',
      );
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    groupsBids(): any {
      return this.$store.getters['LanesStore/getGroupBids'];
    },
    lanesList(): any {
      return this.$store.getters['LanesStore/getLanes'];
    },
    paginationMaxItems(): number {
      return (
        +this.$store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 0
      );
    },
    tenders(): any {
      return this.$store.getters['LanesStore/getCurrentTendersByGroup'];
    },
    userForChat(): any {
      return this.$store.state.ChatsStore.userForChat;
    },
  },
  methods: {
    fetchInfo() {
      this.$store.dispatch('LanesStore/getCurrentTendersByGroup', this.groupsBids.id);
      this.findUser('');
    },
    submit() {
      this.isSending = true;
      this.$store
        .dispatch('LanesStore/lanesRFQRequestBidsGroupRequestMore', {
          id: this.groupsBids?.id,
          body: {
            companies: this.selectedContacts.map((contact) => {
              return { id: contact.id };
            }),
          },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('invitation-sent'));
          this.$emit('close');
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    sendRequestEmail(carrier) {
      this.$store
        .dispatch('LanesStore/sendRequestEmail', {
          group: this.groupsBids.id,
          id: carrier.company.id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('toast-re-send-quote'));
        });
    },
    async openChat(event, user) {
      event.stopPropagation();
      if (JSON.stringify(this.userForChat) !== JSON.stringify(user)) {
        this.$store.commit('LoadsStore/loadId', null);
        this.$store.commit('GlobalStore/setOpenChat', false);
        if (user.generalChatsWithNetworkOwner.length > 0) {
          let imMember = user.generalChatsWithNetworkOwner.find(
            (chat) => chat.user === this.currentUser.id,
          );
          if (imMember) {
            imMember.readed = true;
          } else {
            await this.$store.dispatch('ChatsStore/addNewMember', {
              id: user.generalChatsWithNetworkOwner[0].id,
              data: {
                users: {
                  id: this.currentUser.id,
                },
              },
            });
          }
        }
        setTimeout(() => {
          this.$store.commit('ChatsStore/setUserForChat', user);
          this.$store.commit(
            'ChatsStore/setChatId',
            user.generalChatsWithNetworkOwner[0] ? user.generalChatsWithNetworkOwner[0].id : null,
          );
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    loadMoreContacts() {
      this.showSpinner = true;
      this.useContactPage += 1;
      this.findUser('');
    },
    addSelectedContact(id: number) {
      let index = this.selectedContacts.map((e) => e.id).indexOf(id);
      if (index > -1) {
        this.selectedContacts.splice(index, 1);
      } else {
        this.selectedContacts.push({ id: id });
      }
    },
    findUser(newValue: string) {
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.$store
          .dispatch('UserStore/carrierContactList', {
            page: this.useContactPage,
            name: newValue,
            type: 'CARRIER|BROKER',
          })
          .finally(() => {
            this.allowed = true;
          });
      }, 1000);
    },
    sourceCarrier() {
      let source: any = this.currentContacts.filter(
        (item: any) => item.source === 'CARGOBOT' || item.source === 'CARGOBOT_EU',
      );
      return source;
    },
    isSelected(id) {
      return this.selectedContacts.some((user) => user.id === id);
    },
    selectAll(isChecked: boolean) {
      if (isChecked) {
        let newIds = this.currentContacts
          .map((item) => {
            if (!this.isSelected(item.id)) return item.id;
          })
          .filter((id) => id !== undefined);
        this.selectedContacts = [...this.selectedContacts, ...newIds] as Array<number>;
      } else if (this.selectedContacts.length) {
        this.selectedContacts = [];
        if (!this.selectedContacts.some((item) => item === this.sourceCarrier()[0].id)) {
          this.selectedContacts.push(this.sourceCarrier()[0].id);
        }
      }
    },
  },
});
