
import { computed, defineComponent, ref, watch } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import store from '@/services/store';
import { formatDate } from '@/utils/formatDates';

interface NewProject {
  name: string | null;
  periodStartDate: Date;
  periodEndDate: Date;
}

export default defineComponent({
  components: {
    DatePickerComponent,
    ErrorMessage,
    Field,
  },
  props: {
    newProject: {
      type: Object as () => NewProject,
    },
    creationTypeModal: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const localName = ref<string | null>(props.newProject?.name || null);
    const localStartDate = ref<Date | null>(props.newProject?.periodStartDate || null);
    const localEndDate = ref<Date | null>(props.newProject?.periodEndDate || null);

    /** Computed */
    const projectToDuplicate = computed(() => {
      return store.getters['RFPStore/getProjectToduplicate'];
    });

    if (!(Object.keys(projectToDuplicate).length === 0)) {
      localName.value = projectToDuplicate.value.name;
      localStartDate.value = projectToDuplicate.value.periodStartDate;
      localEndDate.value = projectToDuplicate.value.periodEndDate;
      emit('update:newProject', {
        name: localName.value,
        periodStartDate: formatDate(localStartDate.value, true),
        periodEndDate: formatDate(localEndDate.value, true),
      });
    }

    /** Watchers */
    // watch(
    //   () => props.newProject,
    //   (newVal) => {
    //     if (newVal) {
    //       localName.value = newVal.name;
    //       localStartDate.value = newVal.periodStartDate;
    //       localEndDate.value = newVal.periodEndDate;
    //     }
    //   },
    // );

    watch([localName, localStartDate, localEndDate], ([name, startDate, endDate]) => {
      emit('update:newProject', {
        name,
        periodStartDate: startDate,
        periodEndDate: endDate,
      });
    });

    return {
      localName,
      localStartDate,
      localEndDate,
    };
  },
});
