
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';

// Header
import HeaderNewLoad from '@/modules/rfp/_components/headers/HeaderNewLoadV2.vue';
import HeaderRFPLoadInfo from '@/modules/rfp/_components/headers/HeaderRFPLoadInfo.vue';

//Components
import PlanRfpTrackingPlannerTable from '@/components/rfp/PlanRfpTrackingPlannerTable.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { debounce } from 'lodash';
import PlanRfpSelectorWeek from '@/components/rfp/PlanRfpSelectorWeek.vue';
import PlanFilter from '@/components/filters/PlanFilter.vue';
import LoadsCalendar from '@/components/loads/LoadsCalendar.vue';

// Base
import BaseComponent from '@/base/BaseComponent';

import { defineComponent, reactive, toRefs, computed, onBeforeMount, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import i18n from '@/resources/locales';

export default defineComponent({
  name: 'RFPPlaning',
  mixins: [BaseComponent],
  components: {
    HeaderNewLoad,
    HeaderRFPLoadInfo,
    PlanDefaultView,
    PlanRfpTrackingPlannerTable,
    PlanLoading,
    PlanButton,
    PlanRfpSelectorWeek,
    LoadsCalendar,
    PlanFilter,
  },
  setup() {
    /** Global */
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const translate = useI18n();

    const data = reactive({
      headers: [] as any,
      loads: [] as any,
      isSearchingFilter: false,
      loadingLoads: true,
      loadingCalendar: false,
      filters: {
        multisearch: '',
        status: '',
        periodStart: 0,
        periodEnd: 0,
        page: 1,
        maxResults: 4,
      },
      startWeek: 0,
      page: 1,
      maxResults: 4,
      selectedTotalTab: { index: 0, loadParamFilter: '' },
      endweek: 0,
      currentWeek: 1,
      loadingPlanning: false,
      showMessage: true,
      totalDates: [],
      loadsTotals: {
        TOTAL: 0,
        FINISHED: 0,
        QUOTING: 0,
        CONFIRMED: 0,
        IN_PROGRESS: 0,
        ASSIGNED: 0,
        CANCELED: 0,
        UNASSIGNED: 0,
      },
    });

    /** onMounted */
    onBeforeMount(async () => {
      store.commit('RFPStore/setCurrentLoadsToAdd', []);
      store.commit('RFPStore/setCurrentLoadsToAddAssigment', false);

      const storedValue = localStorage.getItem('showMessage');
      if (storedValue !== null) {
        data.showMessage = JSON.parse(storedValue);
      }

      await dispatchApiCall('RFPStore/getLaneById', route.params.laneId);
      await dispatchApiCall('RFPStore/getPlannedLanesWeeks', route.params.laneId);

      if (Object.keys(currentProject.value).length === 0) {
        await dispatchApiCall('RFPStore/getProjectById', {
          projectId: currentLane.value.projectId,
          page: 1,
        });
      }

      await dispatchApiCall('RFPStore/getCalendar_intervals', route.params.laneId).then(
        async () => {
          data.currentWeek = getCalendarIntervals?.value?.current;
          data.startWeek =
            getCalendarIntervals?.value?.current > 0
              ? getCalendarIntervals?.value?.current > getCalendarIntervals?.value?.intervals.length
                ? getCalendarIntervals?.value?.intervals.length
                : getCalendarIntervals?.value?.current
              : 1;
          data.endweek = getCalendarIntervals?.value?.intervals.length;

          data.filters.multisearch = store.getters['LoadsStore/getAppliedFiltersQuery'].identifier;
          data.filters.periodStart = getCalendarIntervals?.value?.current;
          data.filters.periodEnd = getCalendarIntervals?.value?.current;
          data.filters.page = data.page;
          data.filters.maxResults = data.maxResults;

          await dispatchApiCall('RFPStore/getLoadsInfo', {
            id: route.params.laneId,
            body: {
              ...data.filters,
            },
          }).then(() => {
            getLoadsInfo.value.totals.forEach(function (total: any) {
              if (total.status === 'TOTAL') data.loadsTotals.TOTAL = total.total;
              if (total.status === 'FINISHED') data.loadsTotals.FINISHED = total.total;
              if (total.status === 'QUOTING') data.loadsTotals.QUOTING = total.total;
              if (total.status === 'CONFIRMED') data.loadsTotals.CONFIRMED = total.total;
              if (total.status === 'IN_PROGRESS') data.loadsTotals.IN_PROGRESS = total.total;
              if (total.status === 'ASSIGNED') data.loadsTotals.ASSIGNED = total.total;
              if (total.status === 'CANCELED') data.loadsTotals.CANCELED = total.total;
            });

            if (currentLane.value.loadTemplate?.stops) {
              sortStops(currentLane.value.loadTemplate.stops).forEach((stop) => {
                const city = stop?.location?.city ?? '';
                const countryISO = stop?.location?.state?.isoGoogle || stop?.location?.state?.iso;
                data.headers.push(`${city},${countryISO}`);
              });
            }

            data.loadingLoads = false;
          });
        },
      );
    });

    onMounted(async () => {
      await getLoadsInfoCalendar();
    });

    const getLoadsInfoCalendar = async () => {
      let query = store.getters['LoadsStore/getAppliedFiltersQuery'];

      query.page = data.page;
      query.maxResults = 10;
      query.infoType = ['TOTALS_DATES'];
      query.lanesRFPId = +route.params.laneId;
      data.loadingCalendar = true;

      await store
        .dispatch('LoadsStore/getLoadsInfo', query)
        .then((response) => {
          data.totalDates = response.data.totalsDates;
        })
        .catch((err) => {
          data.loadingCalendar = false;
        });

      data.loadingCalendar = false;
    };

    const hideMessage = () => {
      data.showMessage = false;
      localStorage.setItem('showMessage', JSON.stringify(false));
    };

    /** Computed */

    const defaultView = computed(() => {
      return store.getters['RFPStore/getPreviousStatusLanes'];
    });

    const loadsInfoCalendar = computed(() => {
      return store.getters['LoadsStore/getLoadsInfo'];
    });

    const totalStops = computed(() => {
      return currentLane.value.loadTemplate.stops.length;
    });

    const goToDay = (date, rfpId) => {
      let query = store.getters['LoadsStore/getAppliedFiltersQuery'];
      const formattedDate = `${date.split('-')[2]}-${date.split('-')[1]}-${date.split('-')[0]}`;

      router.push({
        name: 'RFP-laneDay',
        params: {
          date: formattedDate,
          projectId: currentLane.value.projectId,
          laneId: route.params.laneId,
        },
      });
    };

    const getCurrentLoadsToAdd = computed(() => {
      return store.getters['RFPStore/getCurrentLoadsToAdd'];
    });

    const countNonEmptyExecutionDates = computed(() => {
      return getCurrentLoadsToAdd.value.reduce((count, load) => {
        const allNonEmpty = load.stops.every((stop) => stop.executionDate !== '');
        return count + (allNonEmpty ? 1 : 0);
      }, 0);
    });

    const currentPlanningPeriod = computed(() => {
      return store.getters['RFPStore/getCurrentQuotesPlanned'];
    });

    const currentUserLocale = computed(() => {
      return i18n.global.locale.value;
    });

    const getCalendarIntervals = computed(() => {
      return store.getters['RFPStore/getCalendarIntervals'];
    });

    const currentLane = computed(() => {
      return store.getters['RFPStore/getCurrentLane'];
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    const pickupInitialStop = computed(() => {
      return currentLane.value?.loadTemplate?.stops?.find((stop) => stop.type === 'PICKUP_INITIAL');
    });

    const deliveryFinalStop = computed(() => {
      return currentLane.value?.loadTemplate?.stops?.find((stop) => stop.type === 'DELIVERY_FINAL');
    });

    const getLoadsInfo = computed(() => {
      return store.getters['RFPStore/getLoadsInfo'];
    });

    const totalTabs = computed(() => {
      return [
        { label: translate.t('total'), quantity: data.loadsTotals.TOTAL },
        { label: translate.t('unassigned'), quantity: data.loadsTotals.QUOTING },
        { label: translate.t('confirmed'), quantity: data.loadsTotals.CONFIRMED },
        { label: translate.t('assigned'), quantity: data.loadsTotals.ASSIGNED },
        { label: translate.t('in_progress'), quantity: data.loadsTotals.IN_PROGRESS },
        { label: translate.t('finished'), quantity: data.loadsTotals.FINISHED },
        { label: translate.t('canceled'), quantity: data.loadsTotals.CANCELED },
      ];
    });

    /** Methods */

    const handleShowDirectAssignModal = () => {
      let array = [] as any;
      getCurrentLoadsToAdd.value.forEach((load, index) => {
        const allNonEmpty = load.stops.every((stop) => stop.executionDate !== '');
        if (allNonEmpty) {
          array.push(index);
        }
      });
      store.commit('RFPStore/setIndexLoadToRemove', array);
      store.commit('RFPStore/setCurrentLoadsToAddAssigment', true);
    };

    const updateStartWeek = (value) => {
      data.filters.periodStart = value + 1;
      getLoadsInfoApi();
    };
    const updateEndWeek = (value) => {
      data.filters.periodEnd = value + 1;
      getLoadsInfoApi();
    };

    const cleanFilters = () => {
      store.commit('LoadsStore/cleanAppliedFilters');
      getLoadsInfoApi();
    };

    const objectKeys = (obj) => {
      return Object.keys(obj);
    };
    //REFACTOR THIS METHODS
    const getLoads = (keyValue) => {
      return keyValue.map((item) => item.load);
    };

    const getChats = (keyValue) => {
      return keyValue.map((item) => item.chats);
    };

    const getAssignedInfo = (keyValue) => {
      return keyValue.map((item) => item.assignedInfo);
    };

    const dispatchApiCall = async (actionName, param) => {
      try {
        await store.dispatch(actionName, param);
      } catch (error) {
        console.error(`Error en ${actionName}:`, error);
      }
    };

    const changeCurrentView = (currentView) => {
      if (currentView === 'table') {
        store.commit('RFPStore/setPreviousStatusLanes', 'calendar');
      } else {
        store.commit('RFPStore/setPreviousStatusLanes', 'table');
      }
    };

    const changeFilterStatus = (status: any) => {
      store.commit('LoadsStore/applyFilter', status);
      getLoadsInfoCalendar();
    };

    const changeTab = (indexTab: number) => {
      const loadParamFilters = [
        '',
        'QUOTING',
        'CONFIRMED',
        'ASSIGNED',
        'IN_PROGRESS',
        'FINISHED',
        'CANCELED',
      ];
      const statusFilters = [
        '',
        'Quoting',
        'Confirmed',
        'Assigned',
        'In Progress',
        'Finished',
        'Canceled',
      ];
      data.selectedTotalTab.index = indexTab;
      data.selectedTotalTab.loadParamFilter = loadParamFilters[indexTab];
      data.filters.status = statusFilters[indexTab];
      getLoadsInfoApi();
    };

    const getLeftLoads = (loadsPlanned) => {
      if (loadsPlanned >= currentLane?.value.loadsFrequency) {
        return 0;
      } else {
        if (loadsPlanned === 0) {
          return currentLane?.value.loadsFrequency;
        } else {
          return currentLane?.value.loadsFrequency - loadsPlanned;
        }
      }
    };

    const maxDateFilter = computed(() => {
      const maxFromFilter =
        store.getters['LoadsStore/getAppliedFiltersQuery'].pickupInitialDateBefore;
      if (maxFromFilter) {
        return maxFromFilter;
      }
      return null;
    });

    const minDateFilter = computed(() => {
      const minFromFilter =
        store.getters['LoadsStore/getAppliedFiltersQuery'].pickupInitialDateAfter;
      if (minFromFilter) {
        return minFromFilter;
      }
      return null;
    });

    const planFilters = computed(() => {
      let traylerTypeMin = i18n.global.locale.value === 'es' ? '60px' : '55px';
      let carrierBrokerMin = i18n.global.locale.value === 'es' ? '160px' : '95px';
      let customerMin = i18n.global.locale.value === 'es' ? '55px' : '70px';
      let warehouseMin = i18n.global.locale.value === 'es' ? '70px' : '80px';

      return [
        {
          id: 'TRAILER_TYPE',
          icon: 'icInRoute',
          title: translate.t('vehicles'),
          placeholder: translate.t('vehicle'),
          min: traylerTypeMin,
        },
        {
          id: 'CARRIER_BROKER',
          icon: 'user-new',
          title: translate.t('carrier-broker'),
          placeholder: translate.t('carrier-broker'),
          min: carrierBrokerMin,
        },
        {
          id: 'CUSTOMER',
          icon: 'user-new',
          title: translate.t('customers'),
          placeholder: translate.t('customer'),
          min: customerMin,
        },
        {
          id: 'WAREHOUSE',
          icon: 'warehouse',
          title: translate.t('warehouses'),
          placeholder: translate.t('warehouse'),
          min: warehouseMin,
        },
      ];
    });

    const sortStops = (stops) => {
      const initialPickup = stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      const additionalStops = stops
        .filter((stop) => stop.type === 'PICKUP' || stop.type === 'DELIVERY')
        .sort((a, b) => a.id - b.id);
      const finalDelivery = stops.find((stop) => stop.type === 'DELIVERY_FINAL');
      return [initialPickup, ...additionalStops, finalDelivery];
    };

    const loadMoreLoads = async () => {
      data.loadingPlanning = true;
      // data.loadingLoads = true;
      data.filters.page = data.filters.page + 1;

      await dispatchApiCall('RFPStore/getLoadsInfo', {
        id: route.params.laneId,
        body: {
          ...data.filters,
        },
      })
        .then(() => {
          //
        })
        .finally(() => {
          data.loadingPlanning = false;
          // data.loadingLoads = false;
        });
    };

    const getLoadsInfoApi = async () => {
      data.loadingLoads = true;
      await dispatchApiCall('RFPStore/getLoadsInfo', {
        id: route.params.laneId,
        body: {
          ...data.filters,
        },
      })
        .then(async () => {
          await getLoadsInfoCalendar();
        })
        .finally(() => {
          data.loadingLoads = false;
        });

      await dispatchApiCall('RFPStore/getPlannedLanesWeeks', route.params.laneId);
    };

    const debouncedApplyFilters = debounce(getLoadsInfoApi, 500);

    const handleChangeWeek = (value) => {
      store.commit('RFPStore/setCurrentLoadsToAdd', []);
      data.currentWeek = value;
      data.filters.periodStart = value;
      data.filters.periodEnd = value;
      debouncedApplyFilters();
    };

    const applyFilterByIdPo = () => {
      data.filters.multisearch = store.getters['LoadsStore/getAppliedFiltersQuery'].identifier;

      if (data.filters.multisearch === undefined && data.isSearchingFilter) {
        data.isSearchingFilter = false;
        data.filters.periodStart = getCalendarIntervals?.value?.current;
        data.filters.periodEnd = getCalendarIntervals?.value?.current;
      } else {
        data.isSearchingFilter = true;
        data.filters.periodStart = 1;
        data.filters.periodEnd = data.endweek;
      }

      debouncedApplyFilters();
    };

    return {
      ...toRefs(data),
      applyFilterByIdPo,
      defaultView,
      route,
      handleChangeWeek,
      changeTab,
      changeCurrentView,
      currentLane,
      currentProject,
      currentPlanningPeriod,
      currentUserLocale,
      getCalendarIntervals,
      goToDay,
      getChats,
      getAssignedInfo,
      totalTabs,
      getCurrentLoadsToAdd,
      totalStops,
      translate,
      loadMoreLoads,
      updateStartWeek,
      handleShowDirectAssignModal,
      updateEndWeek,
      getLoadsInfoApi,
      countNonEmptyExecutionDates,
      pickupInitialStop,
      changeFilterStatus,
      deliveryFinalStop,
      getLoadsInfo,
      objectKeys,
      getLoads,
      getLeftLoads,
      hideMessage,
      maxDateFilter,
      minDateFilter,
      loadsInfoCalendar,
      planFilters,
      cleanFilters,
    };
  },
});
