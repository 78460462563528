<template>
  <div
    class="w-4 h-4 min-w-[16px] min-h-[16px] mx-3 justify-center items-center self-center cursor-pointer"
    @click="onClickSort"
  >
    <span v-if="sortType === 'ASC'">
      <img
        alt="Arrow up"
        class="w-full h-full"
        :class="
          (isSort && sortType === 'ASC')
            ? 'border border-black rounded-full'
            : ''
        "
        :src="getAsset.icArrowUp"
      />
    </span>
    <span v-else-if="sortType === 'DESC'">
      <img
        alt="Arrow down"
        class="w-full h-full"
        :class="
          (isSort && sortType === 'DESC')
            ? 'border border-black rounded-full'
            : ''
        "
        :src="getAsset.icArrowDown"
      />
    </span>
    <span v-else>
      <img
        alt="Arrow down"
        class="w-full h-full"
        :src="getAsset.icArrowDown"
      />
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'SortIcon',
  mixins: [BaseComponent],
  emits: ['onClickSort'],
  props: {
    isSort: { type: Boolean },
    sortType: { type: String }
  },
  setup(props, { emit }) {

    /** Methods */
    const onClickSort = () => {
      emit('onClickSort');
    };

    return {
      onClickSort,
    }
  },
});
</script>

<style scoped>

</style>
