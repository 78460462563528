import globalApi from '../_api';

export default {
  async apiGetGlobalConfig({ commit }) {
    const response = await globalApi.getGlobalConfig();
    commit('setGlobalConfig', response);
  },
  async clearGlobalStore(context: any) {
    context.commit('clearGlobalStore');
  },
}
