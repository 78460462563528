
import { computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue';
import store from '@/services/store';
import { useRoute } from 'vue-router';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
import ModalInput from '@/components/modals/InputModal.vue';
import SortIcon from '@/components/filters/SortIcon.vue';
// Resources
import i18n from '@/resources/locales';

const SortType = {
  LOADS: 'LOADS',
  RATING: 'RATING',
  TENDERS: 'TENDERS',
};
export default defineComponent({
  name: 'DirectAssignModal',
  components: {
    AddCarrierModal,
    ModalInput,
    SortIcon,
  },
  mixins: [BaseComponent],
  props: {
    directAssignUser: {
      type: Object as any,
    },
  },
  setup(props, { emit }) {
    /** Global */
    const route = useRoute();
    const $t = i18n.global.t;

    const allowed = ref(false);
    const carrierAdded = ref(null as any);
    const carrierName = ref('');
    const carrierToAssign = ref(null as any);
    let directAssignUser = ref(props.directAssignUser);
    const isLoading = ref(true);
    const isLoadingSort = ref(false);
    const isSortLoads = ref(false);
    const isSortRating = ref(false);
    const isSortTenders = ref(false);
    const modalVisible = ref(false);
    const paginationMaxItems = ref(
      store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 0,
    );
    const projectId = ref(null as any);
    const searchTimeout = ref(null as any);
    let selectedContacts = ref([] as any);
    const showFilters = ref(false);
    const showModalDirectAssign = ref(false);
    let showSpinner = ref(false);
    const sortBy = ref('');
    const sortTypeLoads = ref('');
    const sortTypeRating = ref('');
    const sortTypeTenders = ref('');
    const typeUser = ref('CARRIER|BROKER');
    const useContactPage = ref(1);

    /** Lifecycle */
    onBeforeMount(() => {
      store.dispatch('LoadsStore/trailers');
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    onMounted(() => {
      findUser('');
      projectId.value = currentProject.value.project.id;
    });

    const collapsedSidebar = computed(() => {
      return store.getters['UserStore/getCollapsedSidebar'];
    });

    /** Computed */
    const currentContacts = computed(() => {
      if (carrierAdded?.value?.id !== undefined) {
        let current = store.getters['RFPStore/getCarrierQuotetionList'].filter(
          (item) => item.source !== 'ALPINE_CARRIER_API',
        );
        current.unshift(carrierAdded.value);
        return current;
      } else {
        return store.getters['RFPStore/getCarrierQuotetionList'].filter(
          (item) => item.source !== 'ALPINE_CARRIER_API',
        );
      }
    });

    const userImage = computed(() => {
      return BaseComponent.methods?.getUserDefaultImage;
    });

    /** Methods */
    const apiGetCarrierQuotationList = () => {
      isLoadingSort.value = true;
      store
        .dispatch('RFPStore/getCarrierQuotetionList', {
          id: projectId.value,
          maxResults: 100,
          name: '',
          page: useContactPage.value,
          sortBy: sortBy.value,
          type: typeUser.value,
        })
        .then(() => (isLoadingSort.value = false))
        .catch(() => (isLoadingSort.value = false))
        .finally(() => (allowed.value = true));
    };

    const assignUserSelected = (itemSelected) => {
      carrierToAssign.value = itemSelected?.company?.owner;
      directAssignUser.value.directAssign = itemSelected;

      console.log(directAssignUser.value, 'directAssignUser.value');
      console.log(carrierToAssign.value, 'carrierToAssign.value');

      showModalDirectAssign.value = true;
    };

    const directAssign = (price) => {
      if (price) {
        directAssignUser.value.directAssign.price = price;
        emit('close');
      } else {
        directAssignUser.value.directAssign = {};
        showModalDirectAssign.value = false;
      }
      console.log(price, 'price');
      console.log(directAssignUser.value, 'directAssignUser.value');
      console.log(carrierToAssign.value, 'carrierToAssign.value');
    };

    const hideCarrierModal = (carrier) => {
      carrierAdded.value = carrier;
      modalVisible.value = !modalVisible.value;
    };

    const onClickSortLoads = () => {
      resetFilters(SortType.LOADS);
      isSortLoads.value = true;

      if (sortTypeLoads.value === '') {
        sortTypeLoads.value = 'DESC';
        sortBy.value = 'loadsQuantity[DESC]';
      } else if (sortTypeLoads.value === 'DESC') {
        sortTypeLoads.value = 'ASC';
        sortBy.value = 'loadsQuantity[ASC]';
      } else {
        sortTypeLoads.value = '';
        sortBy.value = '';
      }
      apiGetCarrierQuotationList();
    };

    const onClickSortRating = () => {
      resetFilters(SortType.RATING);
      isSortRating.value = true;

      if (sortTypeRating.value === '') {
        sortTypeRating.value = 'DESC';
        sortBy.value = 'rating[DESC]';
      } else if (sortTypeRating.value === 'DESC') {
        sortTypeRating.value = 'ASC';
        sortBy.value = 'rating[ASC]';
      } else {
        sortTypeRating.value = '';
        sortBy.value = '';
      }
      apiGetCarrierQuotationList();
    };

    const onClickSortTenders = () => {
      resetFilters(SortType.TENDERS);
      isSortTenders.value = true;

      if (sortTypeTenders.value === '') {
        sortTypeTenders.value = 'DESC';
        sortBy.value = 'companyName[DESC]';
      } else if (sortTypeTenders.value === 'DESC') {
        sortTypeTenders.value = 'ASC';
        sortBy.value = 'companyName[ASC]';
      } else {
        sortTypeTenders.value = '';
        sortBy.value = '';
      }
      apiGetCarrierQuotationList();
    };

    const resendQuote = (_id: number) => {
      store
        .dispatch('RFPStore/postResendQuote', {
          projectId: projectId.value,
          companyId: _id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess($t('toast-re-send-quote'));
        });
    };

    const resetFilters = (type) => {
      switch (type) {
        case SortType.LOADS:
          isSortRating.value = false;
          isSortTenders.value = false;
          sortTypeRating.value = '';
          sortTypeTenders.value = '';
          break;
        case SortType.RATING:
          isSortLoads.value = false;
          isSortTenders.value = false;
          sortTypeLoads.value = '';
          sortTypeTenders.value = '';
          break;
        case SortType.TENDERS:
          isSortLoads.value = false;
          isSortRating.value = false;
          sortTypeLoads.value = '';
          sortTypeRating.value = '';
          break;
        default:
          isSortLoads.value = false;
          isSortRating.value = false;
          isSortTenders.value = false;
          sortTypeLoads.value = '';
          sortTypeRating.value = '';
          sortTypeTenders.value = '';
      }
    };

    const updateSearchTypeUser = (_userType: string) => {
      console.log('updateSearchTypeUser:', _userType);
      let searchArray = typeUser.value.split('|');
      if (searchArray.includes(_userType)) {
        searchArray = searchArray.filter((el) => el !== _userType);
        typeUser.value = searchArray.join('|');
      } else {
        if (typeUser.value === '') {
          typeUser.value = _userType;
        } else {
          typeUser.value += '|' + _userType;
        }
      }

      store.dispatch('RFPStore/getCarrierQuotetionList', {
        id: projectId.value,
        name: '',
        maxResults: 100,
        page: useContactPage.value,
        sortBy: sortBy.value,
        type: typeUser.value === '' ? 'CARRIER|BROKER' : typeUser.value,
      });
    };

    const searchCompany = () => {
      useContactPage.value = 1;
      if (carrierName.value.length > 2) {
        findUser(carrierName.value);
      } else {
        findUser('');
      }
    };

    const findUser = (_newValue: string) => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value);
      }

      searchTimeout.value = setTimeout(() => {
        store
          .dispatch('RFPStore/getCarrierQuotetionList', {
            id: currentProject.value.project.id,
            maxResults: 100,
            name: _newValue,
            page: useContactPage.value,
            sortBy: sortBy.value,
            type: typeUser.value,
          })
          .then(() => {
            isLoading.value = false;
          })
          .finally(() => {
            allowed.value = true;
          });
      }, 1000);
    };

    const isSelected = (_id: number) => {
      return selectedContacts.value.some((user) => user.id === _id);
    };

    const loadMoreContacts = () => {
      showSpinner.value = true;
      useContactPage.value += 1;
      findUser(carrierName.value);
    };

    return {
      allowed,
      carrierToAssign,
      carrierName,
      collapsedSidebar,
      currentContacts,
      isLoading,
      isLoadingSort,
      isSortLoads,
      isSortRating,
      isSortTenders,
      modalVisible,
      paginationMaxItems,
      projectId,
      selectedContacts,
      showFilters,
      showModalDirectAssign,
      showSpinner,
      sortTypeLoads,
      sortTypeRating,
      sortTypeTenders,
      userImage,
      assignUserSelected,
      directAssign,
      findUser,
      hideCarrierModal,
      isSelected,
      loadMoreContacts,
      onClickSortLoads,
      onClickSortRating,
      onClickSortTenders,
      resendQuote,
      searchCompany,
      updateSearchTypeUser,
    };
  },
});
