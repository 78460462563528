import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "plan-form" }
const _hoisted_3 = { class: "flex justify-between items-center" }
const _hoisted_4 = ["placeholder", "disabled", "min"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "flex flex-col w-full mr-1"
  }, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        type: "time",
        class: _normalizeClass(["w-full", [_ctx.validations.length > 0 ? `${_ctx.innerClass} plan-form-error`: _ctx.innerClass]]),
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeTheValue && _ctx.changeTheValue(...args))),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeTheValue && _ctx.changeTheValue(...args))),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.internalValue) = $event)),
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        min: _ctx.minValue + ':00.000',
        style: { 'width': '140px' }
      }, null, 42, _hoisted_4), [
        [_vModelText, _ctx.internalValue]
      ])
    ]),
    _createElementVNode("span", {
      class: _normalizeClass(["text-xs text-plan-error-200", _ctx.validationResult.length > 0 ? 'text-plan-error-200':'text-transparent'])
    }, _toDisplayString(_ctx.validationResult), 3)
  ], 8, _hoisted_1))
}