import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-bold text-xs text-plan-primary-600",
  style: {"font-size":"13px"}
}
const _hoisted_2 = {
  key: 1,
  class: "font-bold text-xs text-plan-primary-600",
  style: {"font-size":"13px"}
}
const _hoisted_3 = { class: "w-full flex justify-between items-center gap-1" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "plan-filter-items-title" }
const _hoisted_6 = { class: "font-semibold text-sm" }
const _hoisted_7 = {
  key: 1,
  class: "text-xs"
}
const _hoisted_8 = {
  class: "plan-filter-items",
  style: {"width":"300px"}
}
const _hoisted_9 = ["onClick", "onMouseover"]
const _hoisted_10 = { class: "flex justify-center items-center gap-2" }
const _hoisted_11 = {
  key: 0,
  class: "plan-filter-info"
}
const _hoisted_12 = {
  key: 1,
  class: "plan-filter-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", {
    class: "plan-filter-pickup-and-delivery-input",
    style: _normalizeStyle(_ctx.inputValue.length === 0 ? 'background: white':'background: #C7E8FF')
  }, [
    (!_ctx.delivery)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('pickup-location')), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('delivery-location')), 1)),
    _createVNode(_component_popper, {
      "onOpen:popper": _ctx.onOpenPopper,
      show: _ctx.isInputActive,
      arrow: "",
      class: "w-full",
      offsetDistance: "0",
      style: {"--popper-theme-padding":"0px"}
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.title), 1),
            (_ctx.loadingItems)
              ? (_openBlock(), _createBlock(_component_plan_icon, {
                  key: 0,
                  name: "refresh-cw",
                  "is-svg": "",
                  "icon-color": "#757D84",
                  size: "10",
                  rotate: ""
                }))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.totalResults) + " " + _toDisplayString(_ctx.$t('results')), 1))
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                onClick: ($event: any) => (_ctx.selItem(item.value, item.load_filter_param)),
                onMouseover: ($event: any) => (_ctx.hoverIndex = index),
                onMouseout: _cache[7] || (_cache[7] = ($event: any) => (_ctx.hoverIndex = -1)),
                key: index,
                class: "plan-filter-item"
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_plan_icon, {
                    name: "location",
                    "is-svg": "",
                    "icon-color": item.value !== _ctx.inputValue && _ctx.hoverIndex === index ? '#0097FF':'#4F5264'
                  }, null, 8, ["icon-color"]),
                  _createElementVNode("p", {
                    class: _normalizeClass({'plan-filter-item-active': item.value === _ctx.inputValue})
                  }, _toDisplayString(item.value), 3)
                ]),
                (item.value === _ctx.inputValue)
                  ? (_openBlock(), _createBlock(_component_plan_icon, {
                      key: 0,
                      name: "check",
                      "is-svg": "",
                      "icon-color": "#151830"
                    }))
                  : _createCommentVNode("", true)
              ], 40, _hoisted_9))
            }), 128))
          ]),
          (_ctx.loadingItems && _ctx.items.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t('default-loading')), 1))
            : _createCommentVNode("", true),
          (!_ctx.loadingItems && _ctx.items.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.$t('default-no-results')), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_plan_icon, {
            name: "location",
            size: "15",
            "icon-color": _ctx.iconColor,
            "is-svg": ""
          }, null, 8, ["icon-color"]),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitStatus && _ctx.emitStatus(...args))),
            onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleInputFocus && _ctx.handleInputFocus(...args))),
            onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleInputBlur && _ctx.handleInputBlur(...args))),
            onKeyup: [
              _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.getLoadFieldAutocomplete && _ctx.getLoadFieldAutocomplete(...args)), ["enter"])),
              _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.deleteOnKeUp && _ctx.deleteOnKeUp(...args)), ["delete"])),
              _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
            ],
            class: _normalizeClass([{'font-bold text-plan-primary-700': _ctx.inputValue.length > 0}, "w-full"]),
            style: {"font-size":"15px"},
            type: "text",
            placeholder: _ctx.$t('city-zip-state-country')
          }, null, 42, _hoisted_4), [
            [_vModelText, _ctx.inputValue]
          ]),
          (_ctx.inputValue !== '')
            ? (_openBlock(), _createBlock(_component_plan_button_icon, {
                key: 0,
                onOnClick: _ctx.cleanInputValue,
                icon: "close",
                size: "15",
                type: "transparent-gray-800",
                style: {"padding":"0.1rem"}
              }, null, 8, ["onOnClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["onOpen:popper", "show"])
  ], 4))
}