
import { defineComponent } from 'vue';
// Components
import CarrierHeader from '@/components/headers/CarrierHeader.vue';
import NotFound from '@/components/messages/NotFound.vue';

export default defineComponent({
  name: 'NotFoundView',
  components: {
    CarrierHeader,
    NotFound,
  },
  props: {
    shippmentId: String,
    token: String,
  },
});
