import chat from '@/services/chats/_api';

export default {
  async clearChatsStore(context: any) {
    context.commit('clearChatsStore');
  },
  async addMemberToChatLoad(context: any, id: string) {
    const response = await chat.addMemberToChatLoad(id);
    // do something with response
  },
  async addNewMember(context: any, payload: { id: string; data: object }) {
    const response = await chat.addNewMember(payload.id, payload.data);
    // do something with response
  },
  async createChat(context: any, data: any) {
    const response: any = await chat.createChat(data);
    context.commit('setChatId', response.id);
  },
  async createChatGeneral(context: any, data: any) {
    const response: any = await chat.createChatGeneral(data);
    context.commit('setChatId', response.data.id);
  },
  async createChatForLanesRFP(context: any, data: any) {
    const response: any = await chat.createChatForLanesRFP(data);
    context.commit('setChatId', response.data.id);
  },
  async createChatForLoad(context: any, data: any) {
    const response: any = await chat.createChatForLoad(data);
    context.commit('setChatId', response.data.id);
  },
  async createQuotingChatForLoad(context: any, data: any) {
    const response: any = await chat.createQuotingChatForLoad(data);
    context.commit('setChatId', response.data.id);
  },
  async createNewMessage(context: any, payload: { id: string; data: object }) {
    const response = await chat.createNewMessage(payload.id, payload.data);
    // do something with response
  },
  async getConversations(context: any, params: any) {
    const response = await chat.getConversations(params);
    if (params.page === 1) {
      context.commit('setConversations', response.data);
    } else {
      context.commit('pushConversations', response.data);
    }
  },
  async getConversationById(context: any, id: string) {
    const response = await chat.getConversationById(id);
    context.commit('setConversation', response.data);
  },
  async getMessages(context: any, data: any) {
    const response = await chat.getMessages(data);
    if (data.params.page === 1) {
      context.commit('setCurrentMessages', response.data);
    } else {
      context.commit('pushCurrentMessages', response.data);
    }
  },
  async getTotalUnreadChats(context: any) {
    const response = await chat.getTotalUnreadChats();
    context.commit('setTotalUnreadChats', response.data);
  },
  async getUnreadMessages(context: any, data: any) {
    const response = await chat.getUnreadMessages(data);
    if (data.page === 1) {
      context.commit('setUnreadMessages', response.data);
    } else {
      context.commit('pushUnreadMessages', response.data);
    }
  },
  async setChatToClose(context: any, payload: { id: string; data: object }) {
    const response = await chat.setChatToClose(payload.id, payload.data);
    // do something with response
  },
  async setChatToRead(context: any, id: string) {
    const response = await chat.setChatToRead(id);
    // do something with response
  },
};
