
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
// Utils
import { numberSuffixAndColor } from '@/utils/formatString';

export default defineComponent({
  components: {
    BaseModal,
    PlanButton,
    // PlanLoading,
  },
  mixins: [BaseComponent],
  emits: ['close'],
  props: {
    currentLane: { type: Object },
    ranking: { type: Object as any, required: true },
    rankingQuoteType: { type: Object, required: true },
    showModal: { type: Boolean },
  },
  data() {
    return {
      loading: false as any,
    };
  },
  computed: {
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    quoteForCarrier(): any {
      return this.$store.getters['RFPStore/getQuoteForCarrier'];
    },
    numberSuffixAndColor(): any {
      return numberSuffixAndColor;
    },
  },
  created() {
    switch (this.ranking.status) {
      case this.rankingQuoteType.CONFIRM_EXPIRED:
      case this.rankingQuoteType.QUOTED:
      case this.rankingQuoteType.WAITING:
        this.confirmRanking(null);
        break;
    }
  },
  methods: {
    confirmRanking(typeLoading) {
      const bidId = this.ranking.quote.id;
      this.loading = typeLoading;
      this.$store
        .dispatch('RFPStore/postRequestQuoteConfirmation', {
          quoteId: bidId,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('ranking-confirm'));
          this.$store.dispatch('RFPStore/getProjectById', {
            projectId: Number(this.currentProject.project.id),
            page: 1,
          }),
            this.$store.dispatch('RFPStore/getLanesByProjectId', {
              projectId: Number(this.currentProject.project.id),
              page: 1,
            }),
            this.$store.dispatch('RFPStore/getRankingList', {
              id: this.currentLane?.id,
            });
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(this.$t('error'));
        })
        .finally(() => {
          if (typeLoading) {
            this.onClickClose();
            this.loading = false;
          }
        });
    },
    onClickClose() {
      this.$emit('close', false, null);
    },
    revoke() {
      this.loading = 'revoke';
      this.$store
        .dispatch('RFPStore/postRevokeQuote', this.ranking.quote.id)
        .then(() => {
          this.$store.dispatch('RFPStore/getProjectById', {
            projectId: Number(this.currentProject.project.id),
            page: 1,
          }),
            this.$store.dispatch('RFPStore/getLanesByProjectId', {
              projectId: Number(this.currentProject.project.id),
              page: 1,
            }),
            this.$store.dispatch('RFPStore/getRankingList', {
              id: this.currentLane?.id,
            });
          this.onClickClose();
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(this.$t('error'));
        })
        .finally(() => (this.loading = false));
    },
  },
});
