import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex border border-gray-300 rounded-lg overflow-hidden cursor-pointer" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legs, (leg) => {
      return (_openBlock(), _createElementBlock("div", {
        key: leg.id,
        class: _normalizeClass([_ctx.getLegClass(leg.id), "p-4 w-1/3 border-l border-gray-300:first:border-l-0"]),
        onClick: ($event: any) => (_ctx.selectLeg(leg.id))
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(leg.title), 1),
        _createElementVNode("p", null, _toDisplayString(leg.tender), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}