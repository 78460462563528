
import { defineComponent } from 'vue';
import { Dialog, TransitionChild, TransitionRoot } from '@headlessui/vue';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  components: {
    Dialog,
    TransitionChild,
    TransitionRoot,
  },
  mixins: [BaseComponent],
  props: {
    groupsBids: {
      type: Object,
    },
  },
  data() {
    return {
      count: 0,
      showInputLessHistoric: false,
      showInputCoverage: false,
      showInputRatingLower: false,
      loadCoverageValue: '',
      ratingLowerValue: '',
      lessHistoricValue: '',
      isFeatureEnabled: false,
    };
  },
  computed: {
    lanesList(): any {
      return this.$store.getters['LanesStore/getLanes'];
    },
    currentActiveGroup(): any {
      return this.$store.getters['LanesStore/currentActiveGroup'];
    },
  },
  methods: {
    toggleFeature() {
      this.isFeatureEnabled = !this.isFeatureEnabled;
      if (!this.isFeatureEnabled) {
        this.loadCoverageValue = '';
        this.ratingLowerValue = '';
        this.lessHistoricValue = '';
      }
    },
    disableTracking() {
      if (this.isFeatureEnabled) {
        this.toggleFeature();
        this.$store
          .dispatch('LanesStore/rankingConfiguration', {
            id: this.lanesList[0].lanesRFQRequestBidsGroup.id,
            body: {
              lanesRFQ: {
                automaticRankingEnable: this.isFeatureEnabled,
              },
            },
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('message-tracking-disabled'));
            this.$emit('close');
          });
      } else {
        this.$emit('close');
      }
    },
    submit() {
      if (!this.isFeatureEnabled) {
        this.toggleFeature();
      }
      if (this.showInputCoverage && this.loadCoverageValue === '') {
        BaseComponent.methods?.showToastError(this.$t('message-fill-load-coverage'));
        return;
      }
      if (this.showInputRatingLower && this.ratingLowerValue === '') {
        BaseComponent.methods?.showToastError(this.$t('message-fill-rating-lower-value'));
        return;
      }
      if (this.showInputLessHistoric && this.lessHistoricValue === '') {
        BaseComponent.methods?.showToastError(this.$t('message-fill-less-historic-value'));
        return;
      }
      if (!this.showInputCoverage && !this.showInputRatingLower && !this.showInputLessHistoric) {
        BaseComponent.methods?.showToastError(this.$t('message-activate-tracking'));
        return;
      }

      this.$store
        .dispatch('LanesStore/rankingConfiguration', {
          id: this.lanesList[0].lanesRFQRequestBidsGroup.id,
          body: {
            lanesRFQ: {
              automaticRankingConfiguration: {
                discardLoadCoverageLowerThan: +this.loadCoverageValue,
                discardCarriersRatingLowerThan: +this.lessHistoricValue,
                discardCarriersLessHistoricLoadInLaneThan: +this.lessHistoricValue,
              },
              automaticRankingEnable: this.isFeatureEnabled,
            },
          },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('message-tracking-updated'));
          this.$emit('close');
        });
    },
  },
  created(): void {
    this.isFeatureEnabled = this.groupsBids?.automaticRankingEnable;
    if (this.groupsBids?.automaticRankingEnable) {
      this.showInputCoverage = this.groupsBids?.automaticRankingConfiguration
        .discardLoadCoverageLowerThan
        ? true
        : false;
      this.loadCoverageValue =
        this.groupsBids?.automaticRankingConfiguration.discardLoadCoverageLowerThan || '';

      this.showInputRatingLower = this.groupsBids?.automaticRankingConfiguration
        .discardCarriersRatingLowerThan
        ? true
        : false;
      this.ratingLowerValue =
        this.groupsBids?.automaticRankingConfiguration.discardCarriersRatingLowerThan || '';

      this.showInputLessHistoric = this.groupsBids?.automaticRankingConfiguration
        .discardCarriersLessHistoricLoadInLaneThan
        ? true
        : false;
      this.lessHistoricValue =
        this.groupsBids?.automaticRankingConfiguration.discardCarriersLessHistoricLoadInLaneThan ||
        '';
    }
  },
});
