
import { defineComponent, computed } from 'vue';
import PlanFilterPickupAndDelivery from '@/components/filters/PlanFilterPickupAndDelivery.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'PlanSelectedRecipientButton',
  components: { PlanIcon },
  props: {
    company: { type: String, required: false, default: '' },
    user: { type: String, required: false, default: '' },
    addRecipients: { type: Boolean, required: false, default: false }
  },
  emits: ['remove'],
  setup (props, {emit}) {
    const dataToShow = computed(() => {
      return {
        user: props.user.length > 20 ? props.user.slice(0, 20) + '..' : props.user,
        company: props.company.length > 20 ? props.company.slice(0, 20) + '..' : props.company
      }
    })
    const remove = () => { emit('remove') }

    return {
      dataToShow,
      remove
    }
  }
})
