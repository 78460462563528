import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mx-auto tabs-nav overflow-x-auto" }
const _hoisted_2 = {
  class: "flex justify-start border-b-2 pb-3 space-x-6",
  style: {"max-width":"1200px","margin":"0 auto"}
}
const _hoisted_3 = { class: "whitespace-nowrap" }
const _hoisted_4 = { class: "bubble" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "whitespace-nowrap" }
const _hoisted_7 = { class: "bubble" }
const _hoisted_8 = {
  class: "flex justify-end items-center space-x-3 my-5 mr-8",
  style: {"max-width":"1200px","margin":"8px auto 8px auto"}
}
const _hoisted_9 = {
  for: "sortBy",
  class: "block text-sm font-medium text-gray-950"
}
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { value: "startDate-ASC" }
const _hoisted_12 = { value: "endDate-ASC" }
const _hoisted_13 = { value: "id-ASC" }
const _hoisted_14 = { value: "startDate-DESC" }
const _hoisted_15 = { value: "endDate-DESC" }
const _hoisted_16 = { value: "id-DESC" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectFiltersSkeleton = _resolveComponent("ProjectFiltersSkeleton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (!_ctx.isLoadingFilters || _ctx.projectTotalsStatus?.length > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("li", {
                class: _normalizeClass(["relative", !_ctx.activeStatus? 'active': ''])
              }, [
                _createElementVNode("a", {
                  href: "#",
                  class: "flex",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterProjects('')))
                }, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('total')), 1),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getTotal('TOTAL')), 1)
                ])
              ], 2),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectStatus, (status, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: _normalizeClass(["relative", {
            active: _ctx.activeStatus === status,
          }])
                }, [
                  _createElementVNode("a", {
                    href: "#",
                    class: "flex",
                    onClick: ($event: any) => (_ctx.filterProjects(status))
                  }, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(status.toLowerCase())), 1),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getTotal(status)), 1)
                  ], 8, _hoisted_5)
                ], 2))
              }), 128))
            ], 64))
          : (_openBlock(), _createBlock(_component_ProjectFiltersSkeleton, { key: 1 }))
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('sort-by')), 1),
      _withDirectives(_createElementVNode("select", {
        id: "sortBy",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.order) = $event)),
        class: "bg-gray-50 border border-gray-300 text-gray-950 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-44 md-w-26 p-2.5 cursor-pointer",
        disabled: _ctx.isLoadingFilters && (!_ctx.projectsList || _ctx.projectsList.length === 0),
        onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sortProjects && _ctx.sortProjects(...args)))
      }, [
        _createElementVNode("option", _hoisted_11, _toDisplayString(_ctx.$t('start-date-asc')), 1),
        _createElementVNode("option", _hoisted_12, _toDisplayString(_ctx.$t('end-date-asc')), 1),
        _createElementVNode("option", _hoisted_13, _toDisplayString(_ctx.$t('creation-asc')), 1),
        _createElementVNode("option", _hoisted_14, _toDisplayString(_ctx.$t('start-date-desc')), 1),
        _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.$t('end-date-desc')), 1),
        _createElementVNode("option", _hoisted_16, _toDisplayString(_ctx.$t('creation-desc')), 1)
      ], 40, _hoisted_10), [
        [_vModelSelect, _ctx.order]
      ])
    ])
  ], 64))
}