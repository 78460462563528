import AssignedLane from '@/modules/carrier/rfp/AssignedLane.vue';
import CarrierChat from '@/modules/carrier/_components/CarrierChat.vue';
import CarrierRateConfirmation from '@/modules/carrier/rateConfirmation/CarrierRateConfirmation.vue';
import LoadAssign from '@/modules/carrier/rfp/LoadAssignConfirmation/LoadAssign.vue';
import NotifyPosition from '@/modules/carrier/modals/NotifyPosition.vue';
import QuotesRanking from '@/modules/carrier/rfq/quotesRanking/QuotesRanking.vue';
import QuotationRequest from '@/modules/carrier/quotationRequest/QuotationRequest.vue';
import QuotationRequestDuplicated from '@/modules/carrier/quotationRequest/QuotationRequestDuplicated.vue';
import RefusedLane from '@/modules/carrier/rfp/RefusedLane.vue';
import RefusedOffer from '@/modules/carrier/rfp/LoadAssignConfirmation/RefusedOffer.vue';
import RequestForQuoteCarrier from '@/modules/carrier/rfp/requestForQuote/RequestForQuote.vue';
import ServiceAcceptance from '@/modules/carrier/acceptService/ServiceAcceptance.vue';
import UploadBill from '@/modules/carrier/upload/UploadBill.vue';
import UploadDocuments from '@/modules/carrier/upload/UploadDocuments.vue';
import store from '@/services/store';
import { checkCountry } from '@/utils/beforeEnterFunctions';

const CarrierRouter = [
  {
    path: '/carrier',
    name: 'Carrier',
    redirect: { name: 'notFoundView' },
    beforeEnter: async (to, from, next) => {
      await checkCountry(to);
      await next();
    },
    children: [
      {
        path: 'confirm/:id/:token',
        name: 'carrierConfirm',
        component: CarrierRateConfirmation,
      },
      {
        path: 'requestForQuote/:id',
        name: 'RequestForQuoteCarrier',
        component: RequestForQuoteCarrier,
      },
      {
        path: 'requestForQuote/:id/:hash/:code',
        name: 'RequestForQuoteCarrierCode',
        component: RequestForQuoteCarrier,
      },
      {
        path: 'requestForQuote/:id/chat',
        name: 'RequestForQuoteCarrierChat',
        component: RequestForQuoteCarrier,
      },
      {
        path: 'requestForQuote/:id/chat/:hash/:code',
        name: 'RequestForQuoteCarrierCodeChat',
        component: RequestForQuoteCarrier,
      },
      {
        path: 'requestForQuote/:id/:quoteId/deny/:hash?/:code?',
        name: 'refusedOfferLane',
        component: RefusedLane,
      },
      {
        path: 'quotesRanking/:id',
        name: 'QuotesRanking',
        component: QuotesRanking,
      },
      {
        path: 'requestForQuote/:id/:quoteId/accept/:hash?/:code?',
        name: 'assignedLane',
        component: AssignedLane,
      },
      {
        path: 'requestForQuote/:assignId/:hash/:code',
        name: 'refusedOffer',
        component: RefusedOffer,
      },
      {
        path: 'assignConfirmation/:assignId/:hash/:code',
        name: 'LoadAssign',
        component: LoadAssign,
      },
      {
        path: 'quotesRanking/:id/:hash/:code',
        name: 'QuotesRankingCode',
        component: QuotesRanking,
      },
      {
        path: 'shipment',
        name: 'carrierQuote',
        redirect: { name: 'carrierQuote' },
        children: [
          {
            path: 'driver/:id',
            name: 'carrierDuplQuoteDuplicated',
            component: QuotationRequestDuplicated,
            props: true,
          },
          {
            path: 'driver/:id/:hash',
            name: 'carrierQuoteHashDuplicated',
            component: QuotationRequestDuplicated,
          },
          {
            path: 'driver/:id/:hash/:code',
            name: 'carrierQuoteCodeDuplicated',
            component: QuotationRequestDuplicated,
          },
          {
            path: ':id',
            name: 'carrierQuote',
            component: QuotationRequest,
            props: true,
          },
          {
            path: ':id/:hash',
            name: 'carrierQuoteHash',
            component: QuotationRequest,
          },
          {
            path: ':id/:hash/:code',
            name: 'carrierQuoteCode',
            component: QuotationRequest,
          },
          {
            path: 'email/:id',
            name: 'carrierQuoteEmail',
            component: QuotationRequest,
            props: true,
          },
          {
            path: 'email/:id/:hash',
            name: 'carrierQuoteEmailHash',
            component: QuotationRequest,
            props: true,
          },
          {
            path: 'email/:id/:hash/:code',
            name: 'carrierQuoteEmailCode',
            component: QuotationRequest,
            props: true,
          },
          {
            path: 'chat/:id',
            name: 'carrierQuoteChat',
            component: QuotationRequest,
          },
          {
            path: 'chat/:id/:hash',
            name: 'carrierQuoteChatHash',
            component: QuotationRequest,
          },
          {
            path: 'chat/:id/:hash/:code',
            name: 'carrierQuoteChatCode',
            component: QuotationRequest,
          },
        ],
      },
      {
        path: 'bill/:id/:token',
        name: 'carrierBill',
        component: UploadBill,
      },
      {
        path: 'upload-documents/:sequence/:id/:token',
        name: 'carrierUploadDocuments',
        component: UploadDocuments,
      },
      {
        path: 'service-acceptance/:shippmentId/:token',
        name: 'ServiceAcceptance',
        component: ServiceAcceptance,
        props: true,
      },
      {
        path: 'notify-position/:shippmentId/:token',
        name: 'NotifyPosition',
        component: NotifyPosition,
        props: true,
      },
      {
        path: 'chat/:chatId/:hash/:code',
        name: 'CarrierChat',
        component: CarrierChat,
        props: true,
      },
    ],
  },
];

export default CarrierRouter;
