import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/resources/assets/icons/chat-new.svg'
import _imports_1 from '@/resources/assets/icons/close.svg'


const _hoisted_1 = { class: "mt-14 md:flex-row md:space-x-5 space-y-5 md:space-y-0" }
const _hoisted_2 = { class: "mb-4 text-bold" }
const _hoisted_3 = { class: "flex space-x-3 w-full md:w-1/2" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "relative w-2/3 mt-1 rounded-md shadow-sm" }
const _hoisted_6 = { class: "mt-14 md:flex-nowrap w-full" }
const _hoisted_7 = { class: "mb-4 text-bold" }
const _hoisted_8 = {
  key: 0,
  class: "flex flex-col md:flex-row justify-between items-baseline"
}
const _hoisted_9 = { class: "flex flex-col w-1/3" }
const _hoisted_10 = {
  for: "carrier",
  class: "mb-1 text-bold text-sm"
}
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = {
  for: "carrier",
  class: "mb-1 text-bold text-sm"
}
const _hoisted_14 = { class: "flex flex-col" }
const _hoisted_15 = { class: "mr-2" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "text-left" }
const _hoisted_18 = { class: "bg-white border-b" }
const _hoisted_19 = {
  scope: "row",
  class: "py-4 px-6 font-medium text-gray-900"
}
const _hoisted_20 = { class: "flex" }
const _hoisted_21 = { class: "flex items-center space-x-3" }
const _hoisted_22 = { class: "flex flex-col" }
const _hoisted_23 = { class: "font-bold text-corp" }
const _hoisted_24 = { class: "text-sm text-gray-600 truncate max-w-[300px]" }
const _hoisted_25 = { class: "py-4 px-6 text-right" }
const _hoisted_26 = {
  key: 2,
  class: "text-rose-500"
}
const _hoisted_27 = {
  key: 3,
  class: "mt-14"
}
const _hoisted_28 = {
  key: 4,
  class: "flex flex-columns w-full overflow-x-auto relative rounded-xl mt-14"
}
const _hoisted_29 = { class: "w-full text-left h-auto sm:h-32" }
const _hoisted_30 = { class: "bg-gray-50" }
const _hoisted_31 = {
  scope: "col",
  colspan: "2",
  class: "py-3 px-6 md:w-4/12"
}
const _hoisted_32 = { class: "flex items-center space-x-2 text-sm uppercase" }
const _hoisted_33 = {
  scope: "row",
  class: "py-4 px-6 font-medium text-gray-900"
}
const _hoisted_34 = { class: "flex" }
const _hoisted_35 = { class: "flex items-center space-x-3" }
const _hoisted_36 = { class: "flex flex-col" }
const _hoisted_37 = { class: "font-bold text-corp" }
const _hoisted_38 = { class: "text-sm text-gray-600 truncate max-w-[300px]" }
const _hoisted_39 = { class: "py-4 px-6 text-right" }
const _hoisted_40 = ["onClick"]
const _hoisted_41 = {
  key: 0,
  class: "bg-white border-b"
}
const _hoisted_42 = {
  key: 0,
  class: "text-center py-8"
}
const _hoisted_43 = { key: 1 }
const _hoisted_44 = { class: "text-center mb-3 p-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FindCarrierByLoadVue = _resolveComponent("FindCarrierByLoadVue")!
  const _component_add_carrier_modal = _resolveComponent("add-carrier-modal")!
  const _component_InputAmericanFormat = _resolveComponent("InputAmericanFormat")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Spin = _resolveComponent("Spin")!

  return (_openBlock(), _createElementBlock("section", null, [
    (_ctx.showFindCarriermodalVisible)
      ? (_openBlock(), _createBlock(_component_FindCarrierByLoadVue, {
          key: 0,
          onSelectUser: _ctx.selectUser,
          onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFindCarriermodalVisible = !_ctx.showFindCarriermodalVisible))
        }, null, 8, ["onSelectUser"]))
      : _createCommentVNode("", true),
    (_ctx.modalVisible)
      ? (_openBlock(), _createBlock(_component_add_carrier_modal, {
          key: 1,
          onHideModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalVisible = !_ctx.modalVisible)),
          onSetCompanySearch: _ctx.setCompanySearch
        }, null, 8, ["onSetCompanySearch"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('indicates-the-price-performance-service')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Form, { ref: "form" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Field, {
                  name: "Price",
                  rules: "required|min_value:1|max_value:2147483647",
                  modelValue: _ctx.price,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.price) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InputAmericanFormat, {
                      modelValue: _ctx.price,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.price) = $event)),
                      symbol: _ctx.currency,
                      hidden: true,
                      classicInputLeft: true,
                      placeholder: '0'
                    }, null, 8, ["modelValue", "symbol"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createVNode(_component_ErrorMessage, {
                name: "Price",
                class: "text-rose-500"
              })
            ]),
            _: 1
          }, 512)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.$t('include-carrier-assign-load')), 1),
      (!_ctx.userSelected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('company')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "carrier",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.name) = $event)),
                placeholder: _ctx.$t('company-name-surname'),
                class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex p-2.5",
                onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.findUser($event.target?.value))),
                required: ""
              }, null, 40, _hoisted_11), [
                [_vModelText, _ctx.name]
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('')), 1),
              _createElementVNode("button", {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.modalVisible = !_ctx.modalVisible)),
                class: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              }, _toDisplayString(_ctx.$t('add-manually')), 1)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('dont-remember-name-company')), 1),
              _createElementVNode("a", {
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showFindCarriermodalVisible = !_ctx.showFindCarriermodalVisible)),
                class: "flex items-center border-b-2 border-corp pb-1 mt-3 md:mt-0 text-corp hover:border-solid"
              }, [
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('find-it-from-load')), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.userSelected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("table", _hoisted_17, [
              _createElementVNode("tbody", null, [
                _createElementVNode("tr", _hoisted_18, [
                  _createElementVNode("th", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("span", {
                          class: _normalizeClass(["flex justify-center items-center h-9 w-9 rounded-full ring-2 ring-white text-center text-white", _ctx.getUserDefaultImage(_ctx.userSelected.name).color])
                        }, _toDisplayString(_ctx.getUserDefaultImage(_ctx.userSelected.name).name), 3),
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.userSelected.company), 1),
                          _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.userSelected.name), 1)
                        ])
                      ]),
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: "Chat icon",
                        class: "w-6 h-6 ml-5 cursor-pointer self-center",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openChat($event, _ctx.userSelected)))
                      })
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_25, [
                    _createElementVNode("img", {
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.toggleUser(null, false))),
                      src: _imports_1,
                      alt: "Chat icon",
                      class: "w-6 h-6 ml-2 cursor-pointer"
                    })
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.userSelected && _ctx.validating)
        ? (_openBlock(), _createElementBlock("p", _hoisted_26, _toDisplayString(_ctx.$t('select-user')), 1))
        : _createCommentVNode("", true),
      (_ctx.name.length && !_ctx.currentContacts.length && !_ctx.networtContacts.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('found-no-match-contact')), 1)
          ]))
        : (_ctx.name.length && !_ctx.userSelected)
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _createElementVNode("table", _hoisted_29, [
                _createElementVNode("thead", _hoisted_30, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('results-agenda')), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentContacts, (user) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: user.id,
                      class: "bg-white border-b"
                    }, [
                      _createElementVNode("th", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, [
                            _createElementVNode("span", {
                              class: _normalizeClass(["flex justify-center items-center h-9 w-9 rounded-full ring-2 ring-white text-center text-white", _ctx.getUserDefaultImage(user.name).color])
                            }, _toDisplayString(_ctx.getUserDefaultImage(user.name).name), 3),
                            _createElementVNode("div", _hoisted_36, [
                              _createElementVNode("span", _hoisted_37, _toDisplayString(user.name), 1),
                              _createElementVNode("span", _hoisted_38, _toDisplayString(user.owner.name + ' ' + user.owner.surname), 1)
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_39, [
                        _createElementVNode("a", {
                          onClick: ($event: any) => (_ctx.toggleUser(user, true)),
                          class: "bg-btnaction text-white cursor-pointer flex justify-center items-center border space-x-2 font-semibold py-3 px-4 rounded w-full focus:outline-none focus:shadow-outline text-cente"
                        }, _toDisplayString(_ctx.$t('select')), 9, _hoisted_40)
                      ])
                    ]))
                  }), 128)),
                  (_ctx.currentContacts.length !== 0)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_41, [
                        (
                  _ctx.currentContacts.length === _ctx.paginationMaxItems ||
                  _ctx.currentContacts.length % _ctx.paginationMaxItems === 0
                )
                          ? (_openBlock(), _createElementBlock("td", _hoisted_42, [
                              _createElementVNode("button", {
                                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.loadMoreContacts('contacts'))),
                                class: _normalizeClass(["w-full inline-flex justify-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800", { 'cursor-not-allowed hover:bg-blue-400': _ctx.showSpinner }])
                              }, [
                                (_ctx.showSpinner)
                                  ? (_openBlock(), _createBlock(_component_Spin, { key: 0 }))
                                  : _createCommentVNode("", true),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('load-more')), 1)
                              ], 2)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : (_openBlock(), _createElementBlock("tr", _hoisted_43, [
                        _createElementVNode("p", _hoisted_44, _toDisplayString(_ctx.$t('found-no-match-contact')), 1)
                      ]))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
    ])
  ]))
}