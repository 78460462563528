import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-semibold"
}
const _hoisted_2 = { class: "flex justify-end items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_review_additional_fee_of_carrier_modal = _resolveComponent("review-additional-fee-of-carrier-modal")!

  return (_ctx.fees.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["border border-plan-primary-400 rounded-lg p-2 flex flex-col gap-2", _ctx.isCanceled ? 'pointer-events-none' : ''])
      }, [
        (_ctx.pendingFees.length > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('new-additional-fees-received', { n: _ctx.pendingFees.length })), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fees, (fee) => {
          return (_openBlock(), _createElementBlock("div", {
            key: fee.id,
            class: _normalizeClass(["w-full flex justify-between items-center", {
           'text-plan-primary-400': fee.status === 'STATUS_PENDING',
           'text-plan-success-400': fee.status === 'STATUS_APPROVED',
           'text-plan-error-300': fee.status === 'STATUS_DENIED'
         }])
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.getFeeTranslation(fee?.feeExplanation?.name)), 1),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_ctx.formatPriceDecimals(fee.value)), 1),
              _createVNode(_component_review_additional_fee_of_carrier_modal, {
                "fee-info": fee,
                "shipment-id": _ctx.shipmentId
              }, null, 8, ["fee-info", "shipment-id"])
            ])
          ], 2))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}