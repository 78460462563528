import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CountryLoadingScreen = _resolveComponent("CountryLoadingScreen")!
  const _component_ChangeTimeZoneModal = _resolveComponent("ChangeTimeZoneModal")!
  const _component_plan_toast = _resolveComponent("plan-toast")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_ModalEmailConfig = _resolveComponent("ModalEmailConfig")!
  const _component_UserLanguageModal = _resolveComponent("UserLanguageModal")!
  const _component_BackTopButton = _resolveComponent("BackTopButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, {
      class: _normalizeClass(_ctx.collapsedSidebar ? 'router-view-container-expanded' : 'router-view-container')
    }, null, 8, ["class"]),
    (_ctx.isLoadingCountry)
      ? (_openBlock(), _createBlock(_component_CountryLoadingScreen, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_ChangeTimeZoneModal, { showModal: _ctx.showModalTimezone }, null, 8, ["showModal"]),
    (_ctx.toastValues.visible)
      ? (_openBlock(), _createBlock(_component_plan_toast, {
          key: 1,
          text: _ctx.toastValues.text,
          textList: _ctx.toastValues.textList,
          type: _ctx.toastValues.type,
          id: _ctx.toastValues.id
        }, null, 8, ["text", "textList", "type", "id"]))
      : _createCommentVNode("", true),
    (_ctx.notificationItems.length)
      ? (_openBlock(), _createBlock(_component_Notification, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.emailConfigModal)
      ? (_openBlock(), _createBlock(_component_ModalEmailConfig, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.userLanguageModal)
      ? (_openBlock(), _createBlock(_component_UserLanguageModal, { key: 4 }))
      : _createCommentVNode("", true),
    _createVNode(_component_BackTopButton)
  ], 64))
}