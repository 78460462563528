
import { defineComponent } from 'vue';
import {
  Bars2Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Cog8ToothIcon,
  DocumentDuplicateIcon,
  PlusCircleIcon,
} from '@heroicons/vue/24/outline';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import Datepicker from '@vuepic/vue-datepicker';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import ModalBasic from '@/components/modals/BasicModal.vue';
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import ModalHash from '@/components/modals/HashModal.vue';
import ModalQuotingLanes from '@/components/modals/QuotingLanesModal.vue';
import ModalRFQ from '@/components/modals/RFQConfigModal.vue';
import ModalTenders from '@/components/modals/RFQTendersModal.vue';

export default defineComponent({
  name: 'LanesRFQ',
  components: {
    Bars2Icon,
    CheckCircleIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    Cog8ToothIcon,
    Datepicker,
    DocumentDuplicateIcon,
    ModalBasic,
    ModalConfirm,
    ModalHash,
    ModalQuotingLanes,
    ModalRFQ,
    ModalTenders,
    PlusCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    id: String,
    hash: {
      type: String,
      default: null,
    },
    code: String,
  },
  data() {
    return {
      isDragging: false,
      startX: 0,
      currentX: 0,
      dragX: 0,
      showTendersModal: false,
      scrollY: 0,
      isFeatureEnabled: false,
      showTrackingModal: false,
      dateFormat: 'MM-dd-yyyy',
      openModalQuoting: false,
      showDeleteGroup: false,
      isEndingQuotes: false,
      isMoving: false,
      selectAll: false,
      startDate: new Date(), // Initialize with current date
      endDate: new Date(), // Initialize with current date
      timeConfig: 'quarter',
      groupToDelete: null as any,
      groupToDeleteName: null as any,
      groupToRename: null as any,
      popperGroup: null as any,
      searchTimeout: null as any,
      activeLane: null as any,
      lanesToConfirm: [] as any,
      textsModalBasic: {
        header: this.$t('rfq-started'),
        body: this.$t('rfq-started-body-modal'),
      } as any,
      buttonsModalBasic: [
        {
          text: this.$t('continue'),
          type: 'corp',
          callback: () => this.trackingModal(),
        },
      ],
      isHashMatchLocal: true,
      isHashMatchUser: true,
    };
  },
  async created() {
    // if (this.hash !== localStorage.hash && this.hash) {
    //   this.isHashMatchLocal = false;
    // }
    localStorage.setItem('hash', this.hash);
    if (this.hash && this.code) {
      await this.$store
        .dispatch('UserStore/hashValidation', {
          hashId: localStorage.hash,
          secureCode: this.code,
        })
        .then(() => {
          this.$store.dispatch('PusherStore/initializePusher');
          this.$router.push({ name: 'lanesRFQId', params: { id: this.id } });
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.title);
          this.$store.commit('UserStore/setShowHash', true);
        });
    }
    this.fetchAllInfo();
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
    this.updateDates(0);
  },
  computed: {
    activeGroup(): any {
      return this.$store.getters['LanesStore/getActiveGroup'];
    },
    currentActiveGroup(): any {
      return this.$store.getters['LanesStore/currentActiveGroup'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    editingLanes(): any {
      return this.$store.getters['LanesStore/editingLanes'];
    },
    firstLaneStatus(): any {
      return this.lanesList[0].lanesRFQStatus;
    },
    firstLanesRFQRequestBidsGroup(): any {
      return this.lanesList[0]?.lanesRFQRequestBidsGroup?.id;
    },
    formattedDate(): any {
      let date = null as any;
      switch (this.timeConfig) {
        case 'year':
          date = this.startDate.getFullYear();
          break;
        case 'quarter':
          date = this.formattedQuarter;
          break;
        case 'month':
          date = this.formattedMonth;
          break;
        case 'week':
          date = this.formattedWeek;
          break;
      }
      return date;
    },
    formattedMonth(): any {
      const currentMonth = this.startDate.toLocaleString('default', {
        month: 'long',
      });
      const currentYear = this.startDate.getFullYear();

      return `${currentMonth} ${currentYear}`;
    },
    formattedQuarter(): any {
      const currentMonth = this.startDate.getMonth();
      const currentYear = this.startDate.getFullYear();

      const startMonth = this.getMonthName((Math.floor(currentMonth / 3) * 3) % 12);
      const endMonth = this.getMonthName((Math.floor(currentMonth / 3) * 3 + 2) % 12);
      const year = currentYear;

      return `${startMonth} - ${endMonth} ${year}`;
    },
    formattedWeek(): any {
      const firstDayOfWeek = new Date(
        this.startDate.getFullYear(),
        this.startDate.getMonth(),
        this.startDate.getDate() - this.startDate.getDay(),
      );
      const lastDayOfWeek = new Date(
        this.startDate.getFullYear(),
        this.startDate.getMonth(),
        this.startDate.getDate() + 6,
      );
      const formattedFirstDay = firstDayOfWeek.toLocaleDateString('default', {
        month: 'short',
        day: 'numeric',
      });
      const formattedLastDay = lastDayOfWeek.toLocaleDateString('default', {
        month: 'short',
        day: 'numeric',
      });
      const currentYear = this.startDate.getFullYear();

      return `${formattedFirstDay} - ${formattedLastDay}, ${currentYear}`;
    },
    groupBidsStatus(): any {
      return this.groupsBids?.status;
    },
    groups(): any {
      return this.$store.getters['LanesStore/getLanesGroups'];
    },
    groupsBids(): any {
      return this.$store.getters['LanesStore/getGroupBids'];
    },
    isShowModal(): any {
      if (!this.isHashMatchLocal && !this.isHashMatchUser) {
        this.showHashModal();
      }
      return null;
    },
    lanesList(): any {
      return this.$store.getters['LanesStore/getLanes'];
    },
    lanesTotals(): any {
      return this.$store.getters['LanesStore/getLanesTotals'];
    },
    lastLaneListPeriod(): any {
      return this.$store.getters['LanesStore/lastLaneListPeriod'];
    },
    showModalBasic(): any {
      return this.$store.getters['LanesStore/getShowModalStarted'];
    },
    showSticky(): boolean {
      return this.scrollY > 0;
    },
    totalTenders(): any {
      return this.$store.getters['LanesStore/getTotalTenders'];
    },
  },
  methods: {
    checkObjects(array) {
      for (let i = 0; i < array.length; i++) {
        const obj = array[i];
        if (obj.totalBids <= 0) {
          BaseComponent.methods?.showToastError(this.$t('message-lanes-without-quotes-to-finish', { pos: i + 1 }));
          return false;
        }

        if (this.groupsBids.automaticRankingEnable) {
          return true;
        }
        if (obj.totalOnRanking <= 0) {
          BaseComponent.methods?.showToastError(this.$t('message-lanes-without-accepted-quotation', { pos: i + 1 }));
          return false;
        }
      }
      return true;
    },
    closeTendersModal() {
      this.fetchLanes();
      this.showTendersModal = !this.showTendersModal;
    },
    confirmLanes() {
      const lanesRFQ = this.lanesToConfirm.map((lane) => {
        const bestBidRanking = this.getBestBidRanking(lane.id);
        return {
          id: lane.id,
          bid: bestBidRanking ? bestBidRanking.bid : null,
          coverage: bestBidRanking ? bestBidRanking.coverage : null,
        };
      });

      this.$store
        .dispatch('LanesStore/lanesRFQRequestBidsGroupConfirm', {
          id: this.groupsBids.id,
          body: {
            lanesRFQ: lanesRFQ,
          },
        })
        .then(() => {
          this.fetchLanes();
          this.textsModalBasic = {
            header: this.$t('lanes-confirmed'),
            body: this.$t('modal-confirmed-body', { period: this.formattedDate }),
            message: this.$t('now-you-can-plan'),
          };
          this.buttonsModalBasic = [
            {
              text: this.$t('continue'),
              type: 'corp',
              callback: () => this.toggleModalBasic(false),
            },
          ];
          this.toggleModalBasic(true);
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.title);
        });
    },
    copyTimeString(timeConfig){
      let translation = '';

      switch (timeConfig){
        case 'month':
          translation = this.$t('copy-last-month');
          break;
        case 'quarter':
          translation = this.$t('copy-last-quarter');
          break;
        case 'year':
          translation = this.$t('copy-last-year');
          break;
        case 'week':
          translation = this.$t('copy-last-week');
          break;
      }
      return translation;
    },
    deleteGroupLane(confirm) {
      this.showDeleteGroup = false;
      if (confirm) {
        this.$store
          .dispatch('LanesStore/deleteGroupLane', {
            id: this.groupToDelete,
          })
          .then(() => {
            this.$store.dispatch('LanesStore/fetchLanesGroups', false);
            BaseComponent.methods?.showToastSuccess(this.$t('group-success-deleted'));
          });
      }
    },
    draftCurrentPeriod() {
      const convertedArray = this.lastLaneListPeriod.map((item) => {
        return {
          loadsPerWeek: item.loadsPerWeek,
          loadTemplate: {
            stops: item.loadTemplate.stops.map((stop) => {
              return {
                type: stop.type,
                place: `${stop.location.zip}, ${stop.location.city}, ${stop.location.state.isoGoogle}`,
                location: {
                  lat: stop.location.lat,
                  long: stop.location.long,
                  zip: stop.location.zip,
                  city: stop.location.city,
                  state: { id: stop.location.state.id },
                },
                portStop: false,
                airportStop: false,
                observations: '',
                lumperFeePrice: null as null | number,
                lumperFee: false,
              };
            }),
            commodityMaster: {
              weight: item.loadTemplate.commodityMaster.weight,
              commodityName: item.loadTemplate.commodityMaster.commodityName,
              minTemp: null,
              maxTemp: null,
            },
            trailerType: { id: item.loadTemplate.trailerType.id },
          },
        };
      });

      this.$store.dispatch('LanesStore/editingLanes', convertedArray);
      this.$router.push({ name: 'addLanes', params: { id: this.currentActiveGroup } });
    },
    duplicateGroupLane(id) {
      this.$store
        .dispatch('LanesStore/duplicateGroupLane', {
          sourceId: id,
        })
        .then(() => {
          this.$store.dispatch('LanesStore/fetchLanesGroups', false);
          BaseComponent.methods?.showToastSuccess(this.$t('group-success-duplicated'));
        });
    },
    editCurrentLanes() {
      this.$store.dispatch('LanesStore/editingLanes', this.lanesList);

      this.$router.push({
        name: 'addLanes',
        params: { id: this.currentActiveGroup },
        query: { loadCreationMode: 'Editing' },
      });
    },
    fetchAllInfo() {
      this.$store.dispatch('LanesStore/fetchLanesGroups', false).then(() => {
        if (this.groups.length > 0) {
          const gruopId = this.id
            ? Number(this.id)
            : this.currentActiveGroup
              ? this.currentActiveGroup
              : this.groups[0].id;
          this.$store.commit('LanesStore/currentActiveGroup', gruopId);
          this.$store.commit('LanesStore/setActiveGroup', gruopId);
          this.fetchLanes();
          this.fetchLanesTotals();
        }
      });
    },
    fetchGroupBids() {
      if (this.firstLanesRFQRequestBidsGroup !== undefined) {
        this.$store.dispatch('LanesStore/totalTenders', this.firstLanesRFQRequestBidsGroup);
        this.$store.dispatch('LanesStore/lanesRFQRequestBidsGroup', this.firstLanesRFQRequestBidsGroup);
      } else {
        this.$store.commit('LanesStore/setGroupBids', null);
      }
    },
    fetchLanes() {
      this.$store
        .dispatch('LanesStore/fetchLanes', {
          id: this.activeGroup,
          params: {
            after: this.formatDateStringReverse(this.startDate),
            before: this.formatDateStringReverse(this.endDate),
            page: 1,
            maxResults: 30,
          },
        })
        .then(() => {
          this.fetchGroupBids();
        });
    },
    fetchLanesTotals() {
      this.$store.dispatch('LanesStore/getLanesTotals', {
        id: this.activeGroup,
        params: {
          after: this.formatDateStringReverse(this.startDate),
          before: this.formatDateStringReverse(this.endDate),
        },
      });
    },
    finishQuotes() {
      if (!this.isEndingQuotes) {
        if (this.checkObjects(this.lanesList)) {
          this.isEndingQuotes = true;
          this.$store
            .dispatch('LanesStore/finishQuotes', {
              id: this.groupsBids.id,
            })
            .then(() => {
              this.fetchLanes();
              BaseComponent.methods?.showToastSuccess(this.$t('quotes-ended'));
            })
            .finally(() => {
              this.isEndingQuotes = false;
            });
        }
      }
    },
    getLanesQuoted() {
      if (this.groupsBids) {
        // Count the number of objects with different lanesRFQ.id
        const uniqueLaneIds = new Set(this.groupsBids.lanesRFQBids.map((obj) => obj.lanesRFQ.id));
        const count = uniqueLaneIds.size;
        return count;
      }
      return null;
    },
    getTotalBids(id) {
      // Position parameter is used to get one of the best bids from a lane,
      // usually the 0, 1 and 2 position of the array
      if (this.groupsBids.id) {
        let totals = 0;
        this.groupsBids.lanesRFQBids.forEach((bid) => {
          if (bid.lanesRFQ?.id === id) {
            totals = ++totals;
          }
        });
        return totals;
      }
      return '-';
    },
    getBestBidRanking(id) {
      let bid = null as any;
      this.groupsBids.lanesRFQBids.forEach((bidLane) => {
        if (bidLane.lanesRFQ.id === id && bidLane.ranking && bidLane.ranking.position === 0) {
          bid = bidLane;
        }
      });
      return bid;
    },
    getBestBids(position, id) {
      if (this.groupsBids) {
        let bids = [] as any;
        this.groupsBids.lanesRFQBids.forEach((bid) => {
          if (bid.lanesRFQ.id === id) {
            bids.push(bid);
          }
        });
        if (bids.length > 0 && position < bids.length) {
          if (bids.some((bid) => bid.ranking)) {
            bids = bids.filter((bid) => bid.ranking);
            bids.sort((a, b) => a.ranking?.position - b.ranking?.position);
          } else {
            bids.sort((a, b) => a.bid - b.bid);
          }
          if (bids[position]) {
            return this.formatPrice(bids[position].bid);
          } else {
            return '-';
          }
        }
      }
      return '-';
    },
    getIncrement() {
      if (this.timeConfig === 'year') {
        return 1;
      } else if (this.timeConfig === 'quarter') {
        return 3;
      } else if (this.timeConfig === 'month') {
        return 1;
      } else if (this.timeConfig === 'week') {
        return 7;
      }
      return 0;
    },
    getMonthName(monthIndex) {
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      return monthNames[monthIndex];
    },
    goToRequestQuote() {
      const groupName = this.groups.find((group) => group.id === this.activeGroup).name;
      this.$store.commit('LanesStore/setLanesTitle', `${groupName} - ${this.formattedDate}`);
      this.$store.commit('LanesStore/setFiltersLanes', {
        id: this.activeGroup,
        params: {
          after: this.formatDateStringReverse(this.startDate),
          before: this.formatDateStringReverse(this.endDate),
          page: 1,
          maxResults: 30,
        },
      });
      this.$router.push(`lanesRFQ/requestforquote`);
    },
    moveGroup(direction, group) {
      if (
        (!this.isMoving && direction === 'LEFT' && group.tabSequence !== 0) ||
        (!this.isMoving && direction === 'RIGHT' && group.tabSequence + 1 < this.groups.length)
      ) {
        this.isMoving = true;
        this.$store
          .dispatch('LanesStore/moveGroupLane', {
            id: group.id,
            data: {
              direction: direction,
            },
          })
          .then(() => {
            this.$store.dispatch('LanesStore/fetchLanesGroups', false);
          })
          .finally(() => {
            this.isMoving = false;
          });
      }
    },
    onDragEnd() {
      this.isDragging = false;
      this.startX = 0;
      this.currentX = 0;
      this.dragX = 0;
    },
    onDragMove(event) {
      if (this.isDragging) {
        this.currentX = event.clientX;
        this.dragX = this.currentX - this.startX;
      }
    },
    onDragStart(event) {
      this.isDragging = true;
      this.startX = event.clientX;
    },
    postFirstGroup() {
      this.$store
        .dispatch('LanesStore/postGroupLane', {
          name: this.$t('lanes-group-n', { n: (Number(this.groups.length) + 1) }),
        })
        .then(() => {
          this.fetchAllInfo();
          BaseComponent.methods?.showToastSuccess(this.$t('group-success-created'));
        });
    },
    postGroupLane() {
      this.$store
        .dispatch('LanesStore/postGroupLane', {
          name: this.$t('lanes-group-n', { n: (Number(this.groups.length) + 1) }),
        })
        .then((response) => {
          this.$store.dispatch('LanesStore/fetchLanesGroups', false);
          this.setActiveGroup(response);
          BaseComponent.methods?.showToastSuccess(this.$t('group-success-created'));
        });
    },
    renameGroup(event, group) {
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.$store.dispatch('LanesStore/renameGroupLane', {
          id: group.id,
          data: {
            name: event.target.innerText,
          },
        });
      }, 700);

      // .then(() => {
      // });
    },
    setActiveGroup(group) {
      if (group.id !== this.activeGroup) {
        this.$store.commit('LanesStore/currentActiveGroup', group.id);
        this.$store.commit('LanesStore/setActiveGroup', group.id);
        this.fetchLanes();
        this.fetchLanesTotals();
      }
    },
    showDeleteModal(id, name) {
      this.groupToDelete = id;
      this.groupToDeleteName = name;
      this.showDeleteGroup = true;
    },
    showHashModal() {
      // this.$store.commit('UserStore/setShowHash', false);
      // localStorage.setItem('hash', this.hash);
      // setTimeout(() => {
      //   this.$store.commit('UserStore/setShowHash', true);
      // }, 500);
    },
    toggleFeature() {
      this.isFeatureEnabled = !this.isFeatureEnabled;
      this.showTrackingModal = !this.showTrackingModal;
    },
    toggleLane(lane) {
      let index = this.lanesToConfirm.map((e) => e.id).indexOf(lane.id);
      if (index > -1) {
        this.lanesToConfirm.splice(index, 1);
      } else {
        this.lanesToConfirm.push(lane);
      }
    },
    toggleModalBasic(bool) {
      this.$store.commit('LanesStore/setShowModalStarted', bool);
      if (!bool) {
        this.textsModalBasic = {
          header: this.$t('rfq-started'),
          body: this.$t('rfq-started-body-modal'),
        };
        this.buttonsModalBasic = [
          {
            text: this.$t('continue'),
            type: 'corp',
            callback: () => this.toggleModalBasic(false),
          },
        ];
      }
    },
    toggleModalQuoting(bool, lane) {
      this.activeLane = lane;
      if (
        lane.lanesRFQStatus === 'QUOTING' ||
        lane.lanesRFQStatus === 'PLANNING' ||
        lane.lanesRFQStatus === 'CONFIRMED'
      ) {
        this.openModalQuoting = bool;
      }
    },
    toggleRename(index) {
      this.groupToRename = index;
      const div: any = document.getElementById(`groupName${index}`);
      setTimeout(function () {
        div.focus();
        const range = document.createRange();
        // Select the contents of the editable div
        range.selectNodeContents(div);
        // Collapse the range to the end
        range.collapse(false);
        // Get the Selection object
        const selection: any = window.getSelection();
        // Remove all existing ranges from the Selection
        selection.removeAllRanges();
        // Add the new range to the Selection
        selection.addRange(range);
      }, 0);
      this.popperGroup = null;
    },
    toggleSelectAll() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.lanesToConfirm = this.lanesList;
      } else {
        this.lanesToConfirm = [] as any;
      }
    },
    trackingModal() {
      this.toggleModalBasic(false);
      this.showTrackingModal = true;
    },
    updateBiddingTime() {
      this.$store
        .dispatch('LanesStore/updateBiddingEndTime', {
          id: this.groupsBids.id,
          body: {
            quotationDateLimitTZ: this.groupsBids.quotationDateLimitTZ,
          },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('updated-correctly'));
        });
    },
    updateDates(direction) {
      const increment = this.getIncrement();
      const currentYear = this.startDate.getFullYear();
      const currentMonth = this.startDate.getMonth();
      const startMonthQuarter = (Math.floor(currentMonth / 3) * 3) % 12;
      const endMonthQuarter = (Math.floor(currentMonth / 3) * 3 + 3) % 12;
      const firstDayOfWeek = this.startDate.getDate() - this.startDate.getDay(); // First day is the day of the month - the day of the week
      const lastDayOfWeek = firstDayOfWeek + 6; // last day is the first day + 6

      switch (this.timeConfig) {
        case 'year':
          this.startDate = new Date(currentYear + direction * increment, 0, 1);
          this.endDate = new Date(currentYear + direction * increment, 12, 0);
          break;
        case 'quarter':
          this.startDate = new Date(currentYear, startMonthQuarter + direction * increment, 1);
          this.endDate = new Date(
            direction === -1
              ? currentMonth === 0
                ? currentYear
                : currentMonth === 9
                ? currentYear + 1
                : this.startDate.getFullYear()
              : this.startDate.getFullYear(),
            endMonthQuarter === 0 ? 12 : endMonthQuarter + direction * increment,
            0,
          );
          break;
        case 'month':
          this.startDate = new Date(currentYear, currentMonth + direction * increment, 1);
          this.endDate = new Date(
            direction === -1 && currentMonth === 0
              ? currentYear - 1
              : direction === 1 && currentMonth === 11
              ? currentYear + 1
              : currentYear,
            this.startDate.getMonth() + 1,
            0,
          );
          break;
        case 'week':
          this.startDate = new Date(
            currentYear,
            currentMonth,
            firstDayOfWeek + direction * increment,
          );
          this.endDate = new Date(currentYear, currentMonth, lastDayOfWeek + direction * increment);
          break;
      }
      if (this.activeGroup) {
        this.fetchLanes();
        this.fetchLanesTotals();
      }
    },
    updateEndDate() {
      const increment = this.getIncrement();
      this.endDate = new Date(
        this.startDate.getFullYear(),
        this.startDate.getMonth() + increment,
        0,
      );
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.$store.commit('LanesStore/setActiveGroup', this.id);
      }
    },
    currentUser(): any {
      if (this.currentUser.hashId !== this.hash && this.hash) {
        this.isHashMatchUser = false;
      }
    },
    timeConfig() {
      this.updateDates(0);
    },
  },
});
