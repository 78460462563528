import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "flex flex-col justify-start items-start absolute",
  style: {"margin-top":"8px","margin-left":"8px"}
}
const _hoisted_2 = { class: "flex flex-col w-full overflow-y-auto plan-p-sm plan-gap-sm" }
const _hoisted_3 = { class: "flex flex-col plan-t-xs w-fit" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "plan-t-prim-100 plan-t-xxs" }
const _hoisted_6 = { class: "flex flex-col plan-t-xs flex-1" }
const _hoisted_7 = { class: "font-semibold" }
const _hoisted_8 = { class: "plan-t-prim-100 plan-t-xxs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_loading = _resolveComponent("plan-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_button, {
      onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true)),
      type: "primary-white",
      label: _ctx.$t('tracking-history'),
      "show-icon": "",
      icon: "clock",
      "icon-color": "#0097FF"
    }, null, 8, ["label"]),
    (_ctx.showModal)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "absolute w-full rounded flex flex-col",
          style: _normalizeStyle(_ctx.trackingHistoryStyle)
        }, [
          _createVNode(_component_plan_button, {
            onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false)),
            type: "primary-white",
            label: _ctx.$t('tracking-history'),
            "show-icon": "",
            icon: "close",
            "icon-color": "#0097FF"
          }, null, 8, ["label"]),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.loadingTrackingHistory)
              ? (_openBlock(), _createBlock(_component_plan_loading, { key: 0 }))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trackingHistory, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: "flex justify-start items-start plan-gap-md plan-pb-sm",
                style: {"border-bottom":"1px solid #8A8B97"}
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getDateAndHour(item.createdDate).date), 1),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getDateAndHour(item.createdDate).hour), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(`${item.location.city} ${item.location.state?.iso} ${item.location.state?.country?.iso}`), 1),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(item.observations), 1)
                ])
              ]))
            }), 128))
          ])
        ], 4))
      : _createCommentVNode("", true)
  ]))
}