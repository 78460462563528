
import { defineComponent, reactive, toRefs } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';

interface loadRankingInfo {
  isHover: boolean,
  loading: boolean,
  ranking: Array<any>
}

export default defineComponent({
  name: 'LoadFirstTwoRankingInfo',
  components: { PlanAvatar, PlanIcon, PlanButtonIcon },
  props: {
    loadId: { type: Number, required: true, default: -1 },
    chats: { type: Array, required: true }
  },
  setup (props) {
    const store = useStore()

    const data = reactive<loadRankingInfo>({
      isHover: false,
      loading: false,
      ranking: []
    })

    const getChatOfCompany = (id: number) => {
      let result = null
      props.chats.forEach((chat: any) => {
        if (chat.company_id === id) result = chat
      })

      return result
    }

    const getRanking = async () => {
      data.loading = true
      const query = {
        id: props.loadId,
        data: {
          page: 1,
          maxResults: 10,
        }
      }
      await store.dispatch('RFPStore/getRankingList', query)
        .then((response: any) => {
          if (response.length > 0) {
            response.forEach(function(company: any) {
              if (data.ranking.length < 2) data.ranking.push({
                companyName: company.grouper?.company?.name,
                chat: getChatOfCompany(company.grouper?.company?.id)
              })
            })
          }
          data.loading = false
        })
    }

    const openChat = (chat: any) => {
      console.log('abrir chat')
      store.commit('LoadsStore/loadId', props.loadId)
      store.commit('ChatsStore/setUserForChat', chat)
      store.commit('ChatsStore/setChatId', chat.chat)
      store.commit('GlobalStore/setOpenChat', true)
    }

    getRanking()

    return {
      ...toRefs(data),
      getRanking,
      openChat
    }
  }
})
