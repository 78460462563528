
import { computed, defineComponent, onBeforeMount, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import PlanInputMultiSelect from '@/components/forms/PlanInputMultiSelect.vue';

import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';

export default defineComponent({
  name: 'ContactsTabFilter',
  components: { PlanInputMultiSelect, PlanSelectSimple, PlanIcon },
  emits: ['updateSearchTypeUser', 'changeSelectedPermits', 'changePeriod', 'filterByCountry'],
  props: {
    load: { type: Object, required: true },
    trailerSelected: { type: Object, required: true },
    isCrossBorderLoad: { type: Boolean, required: false, default: false },
    showHistoricalRange: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const router = useRouter();

    const data = reactive({
      permits: [
        {
          value: 'hasTransportAndDeliveryOfAlcoholicBeveragesPermit',
          translation: 'permits-contact.hasTransportAndDeliveryOfAlcoholicBeveragesPermit',
        },
        {
          value: 'hasHazardousMaterialPermit',
          translation: 'permits-contact.hasHazardousMaterialPermit',
        },
        { value: 'hasTWICCard', translation: 'permits-contact.hasTWICCard' },
        { value: 'hasTSA', translation: 'permits-contact.hasTSA' },
        {
          value: 'hasOverweightLoadPermit',
          translation: 'permits-contact.hasOverweightLoadPermit',
        },
        { value: 'hasTeamDriverPermit', translation: 'permits-contact.hasTeamDriverPermit' },
        { value: 'hasBondPermit', translation: 'permits-contact.hasBondPermit' },
        { value: 'hasTirPermit', translation: 'permits-contact.hasTirPermit' },
        {
          value: 'hasMoveFullTruckPermit',
          translation: 'permits-contact.hasMoveFullTruckPermit',
        },
        { value: 'hasMovePartialPermit', translation: 'permits-contact.hasMovePartialPermit' },
        {
          value: 'hasOversizeLoadPermit',
          translation: 'permits-contact.hasMovePartialPermit',
        },
      ],
    });

    const changeSelectedPermits = (permitsSelected) => {
      emit('changeSelectedPermits', permitsSelected);
    };

    const changePeriod = (changePeriod) => {
      emit('changePeriod', changePeriod);
    };

    const periodTypes = computed(() => {
      return [
        { label: translate.t('last-average-stats.LAST_MONTH'), value: 'LAST_MONTH' },
        { label: translate.t('last-average-stats.LAST_3_MONTHS'), value: 'LAST_3_MONTHS' },
        { label: translate.t('last-average-stats.LAST_6_MONTHS'), value: 'LAST_6_MONTHS' },
        { label: translate.t('last-average-stats.LAST_YEAR'), value: 'LAST_YEAR' },
      ];
    });

    return {
      ...toRefs(data),
      changeSelectedPermits,
      changePeriod,
      periodTypes,
    };
  },
});
