import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center plan-gap-md" }
const _hoisted_2 = { class: "new-load-button-popper-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanButtonGroup = _resolveComponent("PlanButtonGroup")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PlanButtonGroup, {
      onChange: _ctx.changeDisplayMode,
      type: "primary-dark",
      "active-button-index": _ctx.activeButton,
      buttons: [
        { label: '', icon: 'planning-cards' },
        { label: '', icon: 'calendar' },
      ]
    }, null, 8, ["onChange", "active-button-index"]),
    _createVNode(_component_popper, {
      hover: "",
      offsetDistance: "-6",
      class: "new-load-button-popper"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.openNewFtlLoad,
            type: "sec-600",
            label: _ctx.$t('ftl-load')
          }, null, 8, ["onOnClick", "label"]),
          (_ctx.userRegion === 'NORTH_AMERICA')
            ? (_openBlock(), _createBlock(_component_plan_button, {
                key: 0,
                onOnClick: _ctx.Routing.openNewLTLload,
                type: "sec-600",
                label: _ctx.$t('ltl-load')
              }, null, 8, ["onOnClick", "label"]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_plan_button, {
          type: "primary",
          label: _ctx.$t('add-load')
        }, null, 8, ["label"])
      ]),
      _: 1
    })
  ]))
}