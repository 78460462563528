
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanInputDate from '@/components/forms/PlanInputDate.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import moment from 'moment';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PlanUpdateMultipleLanesEndDate',
  components: { PlanInputCalendar, PlanInputCheckbox },
  emits: ['change'],
  props: {
    min: { type: String, required: true, default: '' },
    max: { type: String, required: true, default: '' },
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      showForm: false,
      selectedDate: '',
    });

    const change = () => {
      emit('change', { isActive: data.showForm, value: data.selectedDate });
    };

    const changeSelectedDate = (newDate: string) => {
      const reverseDate = newDate.split('-').reverse().join('-');
      data.selectedDate = reverseDate;
      change();
    };

    const changeShowForm = (value: boolean) => {
      data.showForm = value;
      change();
    };

    const initialValues = computed(() => {
      const formatByRegion =
        store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'MM-DD-YYYY' : 'DD-MM-YYYY';

      return {
        min: moment(props.min).format(formatByRegion),
        max: moment(props.max).format(formatByRegion),
      };
    });

    return {
      ...toRefs(data),
      changeSelectedDate,
      changeShowForm,
      initialValues,
    };
  },
});
