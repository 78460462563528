import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col rounded border plan-border-prim-50 bg-white plan-p-sm plan-gap-md" }
const _hoisted_2 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_3 = { class: "flex justify-start items-start plan-gap-sm" }
const _hoisted_4 = { class: "w-1/2" }
const _hoisted_5 = { class: "w-1/2" }
const _hoisted_6 = { class: "w-full flex flex-col plan-p-sm plan-gap-sm rounded plan-b-sec-50" }
const _hoisted_7 = { class: "font-semibold plan-t-h5" }
const _hoisted_8 = { class: "plan-t-xs" }
const _hoisted_9 = { class: "w-full flex justify-center items-end" }
const _hoisted_10 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_input_file = _resolveComponent("plan-input-file")!
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_plan_button = _resolveComponent("plan-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
      }, [
        _createVNode(_component_plan_input_text, {
          "model-value": _ctx.fistName,
          "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fistName) = $event)),
          label: _ctx.$t('firstname'),
          mandatory: ""
        }, null, 8, ["model-value", "label"]),
        _createVNode(_component_plan_input_text, {
          "model-value": _ctx.lastName,
          "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
          label: _ctx.$t('lastname'),
          mandatory: ""
        }, null, 8, ["model-value", "label"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
      }, [
        _createVNode(_component_plan_input_text, {
          "model-value": _ctx.driverLicense,
          "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.driverLicense) = $event)),
          label: _ctx.$t('driver-license'),
          mandatory: ""
        }, null, 8, ["model-value", "label"])
      ], 2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_plan_input_file, {
            onChange: _ctx.changeDriverLicenseFront,
            label: _ctx.$t('driver-license-front'),
            icon: "id-front",
            types: ['application/pdf', 'image/*'],
            maxSize: 10
          }, null, 8, ["onChange", "label"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_plan_input_file, {
            onChange: _ctx.changeDriverLicenseBack,
            label: _ctx.$t('driver-license-back'),
            icon: "id-back",
            types: ['application/pdf', 'image/*'],
            maxSize: 10
          }, null, 8, ["onChange", "label"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('driver-info-title')), 1),
        _createElementVNode("p", _hoisted_8, _toDisplayString(!_ctx.isEuropeCompany() ? _ctx.$t('driver-info-text-usa') : _ctx.$t('driver-info-text-eu')), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
        }, [
          _createVNode(_component_plan_input_text, {
            "model-value": _ctx.email,
            "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.email) = $event)),
            label: _ctx.$t('email')
          }, null, 8, ["model-value", "label"]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_plan_select, {
              onChange: _ctx.changePrefix,
              value: _ctx.prefix,
              items: _ctx.prefixes,
              label: _ctx.$t('prefix'),
              placeholder: "+1",
              "hide-right": "",
              style: {"width":"115px"}
            }, null, 8, ["onChange", "value", "items", "label"]),
            _createVNode(_component_plan_input_text, {
              "model-value": _ctx.phoneNumber,
              "onUpdate:model-value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.phoneNumber) = $event)),
              label: _ctx.$t('phone'),
              "hide-left": ""
            }, null, 8, ["model-value", "label"])
          ])
        ], 2),
        _createVNode(_component_plan_input_checkbox, {
          onChange: _ctx.changeShowContactInformation,
          "pre-value": _ctx.showContactInformation,
          label: _ctx.$t('share-driver-with-shipper')
        }, null, 8, ["onChange", "pre-value", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_plan_button, {
        onOnClick: _ctx.postDriver,
        disabled: !_ctx.validateDriver,
        loading: _ctx.loadingPost,
        type: "primary",
        label: _ctx.$t('save'),
        style: {"min-width":"100px"}
      }, null, 8, ["onOnClick", "disabled", "loading", "label"])
    ])
  ]))
}