
import { defineComponent, ref, computed, reactive } from 'vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PlanUploadFile',
  emits: ['change'],
  components: {
    PlanInputFile,
  },
  props: {
    isModify: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const isDragging = ref(false);
    const data = reactive({
      additionalDocuments: null as any,
      FILES: null as any,
      mediaObjectUrls: null as any,
    });

    const getLoadToSend = computed(() => {
      return store.getters['CrossBorderStore/getLoadToSend'];
    });

    const changeFiles = async (event) => {
      isDragging.value = true;
      const files = event.target.files;
      const filesArray: File[] = Array.from(files);
      const acceptedTypes = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png',
      ];
      const maxSize = 100 * 1024 * 1024;

      const validFiles = filesArray.filter((file) => {
        if (!acceptedTypes.includes(file.type)) {
          //   BaseComponent.methods?.showToastError(
          //     this.$t('file-not-valid-type', { file: file.name }),
          //   );
          return false;
        }

        if (file.size > maxSize) {
          //   BaseComponent.methods?.showToastError(
          //     this.$t('file-not-valid-size', { file: file.name }),
          //   );
          return false;
        }

        return true;
      });

      data.FILES = validFiles;

      if (props.isModify) {
        if (validFiles.length > 0) {
          await Promise.all(
            validFiles.map(async (file) => {
              await store
                .dispatch('LoadsStore/postMediaObjectLoad', {
                  id: getLoadToSend.value.id,
                  file: file,
                })
                .then((response) => {
                  //
                })
                .catch((err) => {
                  console.log(err);
                });
            }),
          ).then(() => {
            store.dispatch('LoadsStore/loadById', getLoadToSend.value?.id).then((response) => {
              data.mediaObjectUrls = [];
              //   BaseComponent.methods?.showToastSuccess(this.$t('documents-uploaded-done'));
              //   response?.mediaObjects.forEach(async (mediaObject: any) => {
              //     await this.getMediaObjectOfAdditionalFee(mediaObject);
              //   });
            });
          });
        }
      } else {
        if (data.additionalDocuments.length > 0) {
          const existingFiles = store.getters['LoadsStore/getLoadAditionalDocuments'];
          const mergedFiles = [...existingFiles, ...validFiles];
          store.commit('LoadsStore/setLoadAditionalDocuments', mergedFiles);
        } else {
          store.commit('LoadsStore/setLoadAditionalDocuments', validFiles);
        }
      }
    };

    return {
      changeFiles,
    };
  },
});
