
import { computed, defineComponent, reactive, toRefs } from 'vue';
import BaseModal from '@/components/modals/BaseModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { formatPriceDecimals } from '../../../../../utils/formatString';
import { formatDateTime } from '@/utils/formatDates';
import BaseComponent from '@/base/BaseComponent';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

interface reviewAdditionalFeeOfCarrierModal {
  confirmationModalType: string;
  loadingFeeStateChange: boolean;
  showConfirmationModal: boolean;
  showModal: boolean;
  mediaObjectUrls: Array<string>;
}
export default defineComponent({
  name: 'ReviewAdditionalFeeOfCarrierModal',
  mixins: [BaseComponent],
  methods: { formatPriceDecimals },
  props: {
    feeInfo: { type: Object, required: true },
    shipmentId: { type: String, required: true, default: '0' },
  },
  components: {
    PlanButton,
    BaseModal,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();

    const data = reactive<reviewAdditionalFeeOfCarrierModal>({
      confirmationModalType: 'approve',
      loadingFeeStateChange: false,
      showConfirmationModal: false,
      showModal: false,
      mediaObjectUrls: [],
    });

    const reviewButton = computed(() => {
      let label = 'review';
      let buttonColor = 'primary';

      if (props.feeInfo.status !== 'STATUS_PENDING') {
        label = props.feeInfo.status === 'STATUS_APPROVED' ? 'approved' : 'rejected';
        buttonColor = props.feeInfo.status === 'STATUS_APPROVED' ? 'success' : 'error';
      }

      return {
        label: t(label),
        buttonColor: buttonColor,
      };
    });

    const acceptFee = async () => {
      try {
        data.confirmationModalType = 'approve';
        data.loadingFeeStateChange = true;
        await store.dispatch('LoadsStore/putApproveAdditionalFee', props.feeInfo.id);
        await store.dispatch('LoadsStore/getShipmentFees', props.shipmentId);
        data.showModal = false;
        data.showConfirmationModal = true;
        data.loadingFeeStateChange = false;
      } catch (error) {
        store.dispatch('UserStore/updateToast', {
          text: t('general-error'),
          type: 'error',
          visible: true,
        });
      }
    };

    const isShipperViewer = computed(() => {
      return store.getters['UserStore/getIsShipperViewer'];
    });

    const denyFee = async () => {
      try {
        data.confirmationModalType = 'deny';
        data.loadingFeeStateChange = true;
        await store.dispatch('LoadsStore/putDenyAdditionalFee', props.feeInfo.id);
        await store.dispatch('LoadsStore/getShipmentFees', props.shipmentId);
        data.showModal = false;
        data.showConfirmationModal = true;
        data.loadingFeeStateChange = false;
      } catch (error) {
        store.dispatch('UserStore/updateToast', {
          text: t('general-error'),
          type: 'error',
          visible: true,
        });
      }
    };

    const getFeeTranslation = (feeType) => {
      let label = feeType.replace(/[-\s]/g, "").toLowerCase();
      return t(`additional-fees-types.${label}`) ?? '';
    }

    const getMediaObjectOfAdditionalFee = async (id: number) => {
      const result = await store.dispatch('ShipmentStore/thumbnailBig', {
        id: id,
        class: 'ADDITIONAL_FEE',
      });

      data.mediaObjectUrls.push(URL.createObjectURL(result));
    };

    if (props.feeInfo.mediaObjects.length > 0) {
      props.feeInfo.mediaObjects.forEach(function (mediaObject: any) {
        getMediaObjectOfAdditionalFee(mediaObject.id);
      });
    }

    return {
      ...toRefs(data),
      reviewButton,
      acceptFee,
      denyFee,
      isShipperViewer,
      getFeeTranslation,
      getMediaObjectOfAdditionalFee,
    };
  },
});
