import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_2 = { class: "relative w-full flex justify-between items-start" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  class: "relative w-full flex items-end",
  style: {"border-bottom":"1px solid #d3d4da"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          onClick: ($event: any) => (_ctx.change(tab.value)),
          key: tab.value,
          class: "w-full flex justify-center items-end plan-pb-sm cursor-pointer text-center"
        }, [
          _createElementVNode("p", {
            class: _normalizeClass({ 'font-bold plan-t-sec-500': _ctx.activeTab === tab.value })
          }, _toDisplayString(tab.label), 3)
        ], 8, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "absolute w-1/3 h-1 transition-transform ease-linear duration-300 plan-b-sec-500",
        style: _normalizeStyle(_ctx.indicatorStyle)
      }, null, 4)
    ])
  ]))
}