import { initialState } from '@/modules/carrier/quotationRequest/_store/index';

export default {
  clearQuotationRequestStore(state: any) {
    Object.assign(state, initialState);
  },
  pushFile(state: any, payload: any) {
    state.filesToUpload.push(payload);
  },
  removeFile(state: any, payload: any) {
    state.filesToUpload = state.filesToUpload.filter((item) => item.sequence !== payload);
  },
  setFile(state: any, payload: any) {
    state.file = payload;
  },
  setSendingFile(state: any, payload: any) {
    state.sendingFile = payload;
  },
};
