
import { Field, ErrorMessage } from 'vee-validate';
import { computed, defineComponent, ref, watch } from 'vue';
import store from '@/services/store';
//Components
import CustomMultiselect from '@/components/forms/CustomMultiselect.vue';
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';

interface NewProject {
  name: string | null;
  periodStartDate: Date;
  periodEndDate: Date;
}

export default defineComponent({
  components: {
    CustomMultiselect,
    DatePickerComponent,
    ErrorMessage,
    Field,
  },
  props: {
    newProject: {
      type: Object as () => NewProject,
    },
  },
  setup(props, { emit }) {
    const isSendingRequest = ref(false);
    const localName = ref<string | null>(props.newProject?.name || null);
    const localStartDate = ref<Date | null>(props.newProject?.periodStartDate || null);
    const localEndDate = ref<Date | null>(props.newProject?.periodEndDate || null);
    const projectSelected = ref<any>('');
    const options = ref([]);

    /** Computed */
    const projectToDuplicate = computed(() => {
      return store.getters['RFPStore/getProjectToduplicate'];
    });

    if (!(Object.keys(projectToDuplicate).length === 0)) {
      projectSelected.value = projectToDuplicate.value.name;
    }

    /** Methods */
    const findShipper = async (query) => {
      if (!query.text) return;

      try {
        if (query.text) {
          projectSelected.value = query.text;
          isSendingRequest.value = true;
          const response = await store.dispatch('RFPStore/getProjectRFPSearcher', {
            name: query.text,
            page: 1,
          });

          const newData = response.map((obj) => {
            const { id, name } = obj;
            return { value: id, name };
          });

          options.value = newData;
        }
      } finally {
        isSendingRequest.value = false;
      }
    };

    const handleShipperSelection = (event) => {
      if (event !== null) {
        projectSelected.value = event.item.name;
        store.commit('RFPStore/setProjectToDuplicate', { id: event.item.value });
      }
    };

    /** Watchers */
    // watch(
    //   () => props.newProject,
    //   (newVal) => {
    //     if (newVal) {
    //       localName.value = newVal.name;
    //       localStartDate.value = newVal.periodStartDate;
    //       localEndDate.value = newVal.periodEndDate;
    //     }
    //   },
    // );

    watch([localName, localStartDate, localEndDate], ([name, startDate, endDate]) => {
      emit('update:newProject', {
        name,
        periodStartDate: startDate,
        periodEndDate: endDate,
      });
    });

    return {
      findShipper,
      handleShipperSelection,
      isSendingRequest,
      localName,
      localStartDate,
      localEndDate,
      options,
      projectSelected,
      projectToDuplicate,
    };
  },
});
