
import { defineComponent, reactive, toRefs, ref, computed, watch, nextTick } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanCalendar from '@/components/forms/PlanCalendar.vue';
import { useStore } from 'vuex';
import moment from 'moment';
import i18n from '@/resources/locales';

interface planFilterPickupAndDeliveryDate {
  inputValue: string,
  isInputActive: boolean,
  loadingItems: boolean,
  showPopper: boolean
}

export default defineComponent({
  name: 'PlanFilterPickupAndDeliveryDate',
  components: { PlanCalendar, PlanButtonIcon, PlanIcon },
  emits: ['status'],
  props: {
    id: { type: String, required: true, default: '' },
    delivery: { type: Boolean, required: false, default: false },
    title: { type: String, required: true, default: '' }
  },
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive<planFilterPickupAndDeliveryDate>({
      inputValue: '',
      isInputActive: false,
      loadingItems: false,
      showPopper: false
    })

    const iconColor = computed(() => {
      if (data.inputValue.length > 0) return '#151830'
      return data.isInputActive ? '#35414D' : '#757D84';
    })

    const changeDate = (date: string | { start: string, end: string }) => {

      if (typeof date === 'string') {
        data.inputValue = date
        emit('status', { id: props.id, value: data.inputValue, loadFilterParam: moment(date).format('YYYY-MM-DD')  })

      } else {
        data.inputValue = date.start + ' to ' + date.end
        emit('status', { id: props.id, value: data.inputValue, loadFilterParam: moment(date.start, 'DD-MM-YYYY').format('YYYY-MM-DD') + '#' + moment(date.end, 'DD-MM-YYYY').format('YYYY-MM-DD') })
      }

      data.showPopper = false
    }

    const cleanInputValue = () => {
      data.inputValue = ''
      emit('status', { id: props.id, value: ''})
      data.showPopper = false
    }

    const handleInputFocus = () => {
      data.showPopper = true;
      data.isInputActive = true;
    };

    const handleInputBlur = () => {
      data.isInputActive = false;
    };

    const handleMouseLeave = () => {
      setTimeout(() => {
        data.showPopper = false;
      }, 300);
    }

    watch(() => store.getters['LoadsStore/getAppliedFilters'], (newFilters, oldFilters) => {
      if (Object.keys(newFilters).length === 0) data.inputValue = ''
    })

    return {
      ...toRefs(data),
      iconColor,
      i18n,
      cleanInputValue,
      changeDate,
      handleInputFocus,
      handleInputBlur,
      handleMouseLeave
    }
  }
})
