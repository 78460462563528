
import { defineComponent } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import { GlobeEuropeAfricaIcon } from '@heroicons/vue/24/outline';
import { localize, setLocale } from '@vee-validate/i18n';

export default defineComponent({
  name: 'ModalEmailConfig',
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    // GlobeEuropeAfricaIcon,
    TransitionChild,
    TransitionRoot,
    XCircleIcon,
  },
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      languageList: [
        { code: 'ES', name: 'languages.spanish' },
        { code: 'EN', name: 'languages.english' },
      ],
    };
  },
  computed: {
    countriesList(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    userLocale(): any {
      return this.$i18n.locale.toUpperCase();
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
  },
  methods: {
    changeLanguage(event) {
      this.$i18n.locale = event.target.value.toLowerCase();
      localStorage.setItem('userLocale', event.target.value.toLowerCase());
      setLocale(event.target.value.toLowerCase());
    },
    toggleEmailConfigModal(isOpen: boolean) {
      this.$store.commit('GlobalStore/setUserLanguageModal', isOpen);
    },
  },
});
