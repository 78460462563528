
import { defineComponent } from 'vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { StarIcon } from '@heroicons/vue/24/solid';
import draggable from 'vuedraggable';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import LoadDetails from '@/modules/planning/loadDetail/_components/LoadDetails.vue';
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import ModalInput from '@/components/modals/InputModal.vue';
import ShipperLoadContainerQuotesVue from '../ltl/ShipperLoadContainerQuotes.vue';
import ShipperLoadDetailQuotingRFP from '@/modules/planning/loadDetail/rfp/ShipperLoadContainerQuotes.vue';
import SortIcon from '@/components/filters/SortIcon.vue';

import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import UserSection from '@/modules/superAdmin/_components/UserSection.vue';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import ContactsTabFilter from '@/components/filters/ContactsTabFilter.vue';
import PlanFlag from '@/components/icons/PlanFlag.vue';

// Styles
import HorizontalScroll from '@/resources/assets/styles/animation/HorizontalScroll.vue';
import PlanHistoricalPaidRate from '@/components/loads/detail/quote/PlanHistoricalPaidRate.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
interface User {
  id: number;
  name: string;
  company: any;
  owner: {
    name: string;
  };
  stats: {
    amountValorations: number;
    shipmentAmount: number;
    averageShipmentPrice: number;
  };
  source: string;
  LaneAssignedShipmentsFinishedCount: number;
  LaneAverageShipmentPrice: number;
}

export default defineComponent({
  name: 'ShipperLoadDetailQuoting',
  components: {
    PlanButtonIcon,
    PlanHeaderSearch,
    PlanHistoricalPaidRate,
    AddCarrierModal,
    draggable,
    InputAmericanFormat,
    LoadDetails,
    ModalConfirm,
    ModalInput,
    PlusCircleIcon,
    StarIcon,
    ShipperLoadContainerQuotesVue,
    ShipperLoadDetailQuotingRFP,
    ContactsTabFilter,
    SortIcon,
    PlanDefaultTable,
    UserSection,
    PlanIcon,
    PlanFlag,
  },
  mixins: [BaseComponent, HorizontalScroll],
  props: {
    shipment: {
      type: Number,
      default: null,
    },
    loadId: {
      type: Number,
      default: null,
    },
  },
  data(): any {
    return {
      permits: [
        {
          value: 'hasTransportAndDeliveryOfAlcoholicBeveragesPermit',
          translation: 'permits-contact.hasTransportAndDeliveryOfAlcoholicBeveragesPermit',
        },
        {
          value: 'hasHazardousMaterialPermit',
          translation: 'permits-contact.hasHazardousMaterialPermit',
        },
        { value: 'hasTWICCard', translation: 'permits-contact.hasTWICCard' },
        { value: 'hasTSA', translation: 'permits-contact.hasTSA' },
        {
          value: 'hasOverweightLoadPermit',
          translation: 'permits-contact.hasOverweightLoadPermit',
        },
        { value: 'hasTeamDriverPermit', translation: 'permits-contact.hasTeamDriverPermit' },
        { value: 'hasBondPermit', translation: 'permits-contact.hasBondPermit' },
        { value: 'hasTirPermit', translation: 'permits-contact.hasTirPermit' },
        {
          value: 'hasMoveFullTruckPermit',
          translation: 'permits-contact.hasMoveFullTruckPermit',
        },
        { value: 'hasMovePartialPermit', translation: 'permits-contact.hasMovePartialPermit' },
        {
          value: 'hasOversizeLoadPermit',
          translation: 'permits-contact.hasMovePartialPermit',
        },
      ],
      searchByUserName: '',
      debouncedFetchContactList: null as any,
      searchRequestBidQuery: {
        page: 1,
        originZip: 0,
        destinyZip: 0,
        trailerTypeStats: 0,
        id: 0,
      },
      draggableList: [],
      isLeft: true,
      bgColor: 'white',
      isSending: [] as any,
      allowed: true,
      selectedContacts: [],
      showSpinner: false,
      useContactPage: 1,
      netWorkContactPage: 1,
      modalVisible: false,
      searchQuery: '',
      userType: 'CARRIER|BROKER',
      showReassignModal: false,
      reassignModalText: this.$t('reassign-confirm'),
      assignModalText: this.$t('direct-assign'),
      reassignCompany: null,
      searchByCompanyName: '',
      searchByRating: '',
      searchByLoadsQuantity: '',
      searchByAverageBid: '',
      selectedRequeriments: [],
      acceptedBidByCompanyName: '',
      acceptedBidByRating: '',
      acceptedBidByQuantity: '',
      acceptedBidByAverage: '',
      acceptedBidByRate: '',
      isSortByName: false,
      isSortByRating: false,
      isSortByQuantity: false,
      isSortByAverage: false,
      isSortByBidName: false,
      isSortByBidRating: false,
      isSortByBidQuantity: false,
      isSortByBidAverage: false,
      isSortByBidRate: false,
      sortBy: '',
      carrierAdded: {} as User,
      trailerTypeIdToSearch: 0,
      isTrailerFiltered: true,
      isUpdateList: true,
      showModalDirectAssign: false,
      carrierToAssign: {} as any,
      section: 'bids',
      bestBidId: 0,
      isDeassign: false,
      showDeAssign: false,
      deassignModalText: this.$t('de-assign-confirm'),
      isBookNow: false,
      bookNow: undefined,
      searchTimeout: null as any,
      historicalRange: 'LAST_MONTH',
      showFilters: false,
      showDirectAssign: false,
      isMoving: false,
      drag: false,
      isLoading: false,
      order: {
        value: 'tender',
        direction: 'asc',
      },
      target: null,
    };
  },
  created() {
    this.debouncedFetchContactList = debounce(this.fetchContactList, 500);
    this.$store.dispatch('LoadsStore/quotesAverageStats', {
      shipment: this.shipment,
      trailerType: this.currentLoad?.trailerType?.id,
      originZip: this.originZip,
      destinyZip: this.destinyZip,
      period: this.historicalRange,
    });

    this.$store.commit('CreditStore/setNoCreditModal', false);
    this.$store.commit('CreditStore/setPlanimatikCreditModal', false);

    this.setRequestBidStatsByLoad();
    if (!this.isSuperAdminRoute && this.currentLoad.shipment.type !== 'Alpine_LTL') {
      // Contactos filtrados por defecto por trailer type carrier === trailer type carga
      this.trailerTypeIdToSearch = this.currentLoad?.trailerType?.id;

      this.setCarrierContactListStatsByLoad();
      this.showDirectAssign = true;
    }
    if (this.currentLoad.source === 'RFQ') {
      this.fetchQuotesRanking();
    }
  },
  mounted() {
    if (this.currentLoad) {
      this.isBookNow = this.currentLoad.bookNowEnable;
      this.bookNow = this.currentLoad.bookNowPrice;
    }
  },

  computed: {
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
    getTrailerSelected(): any {
      let Id = this.currentLoad?.trailerType?.id;
      return this.trailerTypes.find((trailer) => trailer.id === Id);
    },
    formatDateLocale() {
      return this.$store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? 'MMM DD, YYYY HH:MM'
        : 'DD MMM, YYYY HH:MM';
    },
    moment() {
      return moment;
    },
    userRegion() {
      return this.currentUser.company?.country?.region?.name;
    },
    headers() {
      return [
        { label: this.$t('age'), value: 'bidDate', sortable: true },
        { label: this.$t('tenders'), value: 'companyName', sortable: true },
        { label: this.$t('rating'), value: 'rating' },
        { label: this.$t('lane-history'), value: 'completed-loads' },
        { label: this.$t('last-bid-lane'), value: 'historical-average' },
        { label: this.$t('bid'), value: 'rate', sortable: true, sticky: true },
      ];
    },
    calculateBidAverage() {
      const validBids = this.carrierRequestedBid
        .map((item) => +item.company.bestBid)
        .filter((bid) => bid !== null && bid !== 0);

      if (validBids.length === 0) {
        return null;
      }
      const sum = validBids.reduce((acc, bid) => acc + bid, 0);
      const average = sum / validBids.length;

      return average;
    },
    calculatePercentageSaved() {
      const quoteAverage = this.quotesAverageStats?.quoteAverage || 0;
      const rateAverage = this.quotesAverageStats?.rateAverage || 0;

      if (quoteAverage === 0) {
        return 0;
      }

      const percentageSaved = ((quoteAverage - rateAverage) / quoteAverage) * 100;
      return percentageSaved.toFixed(0);
    },
    creditAvailable(): any {
      return this.companyCredit.creditGranted - this.companyCredit.creditLocked;
    },
    quotesAverageStats(): any {
      return this.$store.getters['LoadsStore/quotesAverageStats'];
    },
    companyCredit(): any {
      return this.$store.getters['CreditStore/getCredit'];
    },
    carrierBidSent(): User[] {
      return this.$store.getters['UserStore/requestBidStatsByLoad'].filter(
        (carrier: any) => carrier.status !== 'WAITING',
      );
    },
    carrierRequestedBid(): User[] {
      return this.$store.getters['UserStore/requestBidStatsByLoad'];
    },
    currentContacts(): User[] {
      if (this.carrierAdded?.id !== undefined) {
        let current = this.$store.getters['UserStore/carrierContactListStatsByLoad'];
        current.unshift(this.carrierAdded);
        return current;
      } else {
        return this.$store.getters['UserStore/carrierContactListStatsByLoad'];
      }
    },
    currentLane(): any {
      return this.$store.getters['RFPStore/getCurrentLane'];
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    currentStatus(): any {
      return this.currentLoad ? this.currentLoad.status : null;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    destinyZip(): any {
      return this.currentLoad.stops.filter((stop) => stop.type === 'DELIVERY_FINAL')[0].location
        .zip;
    },
    dragOptions(): any {
      return {
        animation: 200,
        group: 'description',
        disabled: this.isMoving,
        ghostClass: 'ghost',
      };
    },
    isCanceled(): boolean {
      return this.currentLoad.status === 'CANCELED';
    },
    isRFPLoad(): any {
      return this.currentLoad?.source === 'RFP';
    },
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    maxBidsRequest(): number {
      return +this.$store.getters['GlobalStore/getGlobalConfig']
        .LIMIT_AMOUNT_OF_COMPANIES_TO_SEND_BID_REQUEST;
    },
    originZip(): any {
      return this.currentLoad.stops.filter((stop) => stop.type === 'PICKUP_INITIAL')[0].location
        .zip;
    },
    paginationMaxItems(): number {
      return (
        +this.$store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 0
      );
    },
    rankingList(): any {
      return this.$store.getters['LanesStore/getLaneRankingList'];
    },
    rankingListTotalsSelected(): any {
      return this.rankingList.reduce((count, item) => {
        if (item.status === 'SELECTED' || item.status === 'ASSIGNED') {
          return count + 1;
        }
        return count;
      }, 0);
    },
    shipmentId(): any {
      return this.$store.getters['LoadsStore/currentLoad'].shipment.id;
    },
    shipmentType(): any {
      return this.currentLoad?.shipment?.type;
    },
    totalBids(): any {
      return this.$store.getters['ShipmentStore/totalBids'];
    },
    totalQuotes(): any {
      return this.$store.getters['LanesStore/getLaneQuotesTotals'];
    },
    userForChat(): any {
      return this.$store.state.ChatsStore.userForChat;
    },
  },
  methods: {
    changeSelectedPermits(permitsSelected) {
      this.selectedRequeriments = permitsSelected;
      this.debouncedFetchContactList();
    },
    handleInputChange(event) {
      this.searchByUserName = event;
      this.debouncedFetchContactList();
    },
    fetchContactList() {
      console.log(this.trailerTypeIdToSearch);
      this.$store
        .dispatch('UserStore/carrierContactListStatsByLoad', {
          page: this.useContactPage,
          name: this.searchByUserName,
          type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
          trailerType: !this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.trailerTypeIdToSearch ? this.trailerTypeIdToSearch : null,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
          permits: this.selectedRequeriments,
        })
        .then(() => {
          if (this.sourceCarrier && this.sourceCarrier.length > 0) {
            if (!this.selectedContacts.some((item) => item === this.sourceCarrier[0].id)) {
              this.selectedContacts.push(this.sourceCarrier[0].id);
            }
          }
        });
    },
    dateTime(date) {
      const dtf = new Intl.DateTimeFormat();
      const localTimezone = dtf.resolvedOptions().timeZone;
      const timezone = this.$store.getters['UserStore/getCurrentUser']?.timeZone ?? localTimezone;

      const formattedDateTime = moment.utc(date).tz(timezone).format('MMM DD, HH:mm');
      const [datePart, timePart] = formattedDateTime.split(', ');

      return {
        date: datePart,
        time: timePart,
      };
    },

    pricePerMile(quote, totalMiles) {
      return (quote / totalMiles).toFixed(2);
    },
    changeGoal(newTargetValue) {
      this.target = newTargetValue;
    },
    calculateGoal(bid) {
      console.log(this.target);
      console.log(bid);
      let result = {
        label: '',
        class: '',
      };

      if (bid) {
        if (this.target !== null) {
          const targetDifference = this.target - bid;
          const localeToShow =
            this.$store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es';
          const formatCurrency = new Intl.NumberFormat(localeToShow, {
            style: 'currency',
            currency: localeToShow === 'en' ? 'USD' : 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });

          if (targetDifference < 0) {
            result.label = this.$t('set-a-goal-over', {
              target: formatCurrency.format(Math.abs(targetDifference)),
            });
            result.class = 'plan-t-prim-100 plan-t-xxs';
          } else if (targetDifference === 0) {
            result.label = this.$t('set-a-goal-target');
            result.class =
              'plan-t-prim-200 plan-b-green-200 plan-t-xxs rounded-full plan-pl-xs plan-pr-xs';
          } else {
            result.label = this.$t('set-a-goal-below', {
              target: formatCurrency.format(Math.abs(targetDifference)),
            });
            result.class =
              'plan-t-prim-200 plan-b-green-200 plan-t-xxs rounded-full plan-pl-xs plan-pr-xs';
          }
        }
      }

      return result;
    },
    getPosition(bidId) {
      return this.carrierRequestedBid.findIndex((bid) => bid.id === bidId);
    },
    fetchOrder(orderObject) {
      this.searchRequestBidQuery.shipment = this.shipment;
      this.searchRequestBidQuery.trailerTypeStats = this.currentLoad?.trailerType?.id;
      this.searchRequestBidQuery.originZip = this.originZip;
      this.searchRequestBidQuery.destinyZip = this.destinyZip;
      this.searchRequestBidQuery.period = this.historicalRange;

      this.order = orderObject;
      this.searchRequestBidQuery.sortBy = `${
        orderObject.value
      }[${orderObject.direction.toUpperCase()}]`;
      this.getRequestBid();
    },
    getRequestBid() {
      this.$store.dispatch('UserStore/requestBidStatsByLoad', this.searchRequestBidQuery);
    },
    calculatedSaved(bid) {
      let averageToUse = this.calculateBidAverage;
      const percentageSaved = ((averageToUse - bid) / averageToUse) * 100;
      return percentageSaved.toFixed(0);
    },
    async acceptBid(company) {
      if (this.isSuperAdminRoute) {
        return;
      }
      // Check to show Plans modal
      this.$store.dispatch('UserStore/checkIsShowPlanModal').then((resp) => {
        if (!resp) {
          this.reassignCompany = company;
          if (this.currentLoad.status === 'QUOTING') {
            this.$store
              .dispatch('ShipmentStore/acceptBid', {
                id: company.bestBidId,
                // id: this.shipmentId,
                // companyId: company,
              })
              .then(() => {
                BaseComponent.methods?.showToastSuccess(this.$t('bid-accepted'));
                this.$store.dispatch('LoadsStore/loadById', this.loadId);
              })
              .catch((err) => {
                BaseComponent.methods?.showToastError(err?.response?.data?.title);
                if (err?.response?.data?.errorCode === 'C-18') {
                  if (this.creditAvailable === 0) {
                    this.$store.commit('CreditStore/setNoCreditModal', true);
                  } else if (+company.bestBid > this.creditAvailable) {
                    this.$store.commit('CreditStore/setPlanimatikCreditModal', true);
                  }
                }
              })
              .finally(() => console.log(''));
          } else {
            this.showReassignModal = true;
          }
        }
      });
    },
    async addMemberToChatLoad(chatId) {
      await this.$store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    },
    addSelectedContact(id: number, i) {
      if (!this.allowed) return;
      this.isSending[i] = true;
      this.selectedContacts.push(id);
      this.validateForm(i);
    },
    checkCompanyId(currentBids, contact) {
      return currentBids.some((item) => item.company.id === contact);
    },
    directAssign(price: any) {
      if (price === null) {
        this.showModalDirectAssign = false;
        return;
      }
      if (this.currentLoad.status !== 'QUOTING') {
        BaseComponent.methods?.showToastError(this.$t('message-only-quoting-state'));
        this.showModalDirectAssign = false;

        return;
      }
      if (price !== null) {
        this.showModalDirectAssign = false;

        // Lanzar la assignación directa
        const baseObject = {
          shipments: [{ id: this.currentLoad.shipment.id }],
          company: { id: this.carrierToAssign.id },
          price: price,
        };
        this.$store
          .dispatch('UserStore/assignLoad', baseObject)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('carrier-assigned'));
            this.setCarrierContactListStatsByLoad();
            this.setRequestBidStatsByLoad();

            // this.$store.dispatch('LoadsStore/showModalCarrierAssigned', true);
            // this.nextRoute(route);
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
            if (err?.response?.data?.errorCode === 'C-18') {
              if (this.creditAvailable === 0) {
                this.$store.commit('CreditStore/setNoCreditModal', true);
              } else if (+price > this.creditAvailable) {
                this.$store.commit('CreditStore/setPlanimatikCreditModal', true);
              }
            }
          })
          .finally(() => {
            this.$store.dispatch('LoadsStore/currentLoadEditing', null);
            this.allowed = true;
            // this.sendingAction = false;
          });
      }
    },
    fetchQuotesRanking() {
      // Get Total of rankings
      this.$store.dispatch('LanesStore/getLaneQuotedRanking', {
        id: this.currentLoad.lanesRFQId,
        params: { page: 1, totals: 'ranking', ranking: 'ranking' },
      });
      // Get Ranking list
      this.$store.dispatch('LanesStore/getLaneQuotedRanking', {
        id: this.currentLoad.lanesRFQId,
        params: { page: 1, ranking: 'ranking' },
      });
    },
    hideModal(carrier: any) {
      if (carrier !== 0) {
        this.carrierAdded = carrier;
        this.selectedContacts.push(carrier.company.id);
        if (!this.selectedContacts.some((item) => item === this.sourceCarrier()[0].id)) {
          this.selectedContacts.push(this.sourceCarrier()[0].id);
        }
        this.modalVisible = !this.modalVisible;
      } else {
        this.modalVisible = !this.modalVisible;
      }
    },
    historicalRangeFilter() {
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        lanesRFP: this.currentLane?.id,
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
      this.$store.dispatch('LoadsStore/quotesAverageStats', {
        shipment: this.shipment,
        trailerType: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
      if (this.shipment !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipment,
          sortBy: `${this.acceptedBidByRate}` ? `rate[${this.acceptedBidByRate}]` : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    isRead(user): any {
      if (user?.chat_readed) {
        return user.chat_readed;
      }

      if (user?.company?.quotingChat) {
        return user.company?.quotingChat?.read;
      }

      // true by default if its not readed
      return true;
    },

    isSelected(id) {
      return this.selectedContacts.includes(id);
    },
    loadMoreContacts() {
      this.showSpinner = true;
      this.useContactPage += 1;
      this.$store
        .dispatch('UserStore/carrierContactListStatsByLoad', {
          destinyZip: this.destinyZip,
          lanesRFP: this.currentLane?.id,
          name: null,
          originZip: this.originZip,
          page: this.useContactPage,
          period: this.historicalRange,
          trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        })
        .then(() => {
          this.showSpinner = false;
        })
        .catch((err) => console.log(err));
    },
    modalDirectAssign(data: any) {
      this.showModalDirectAssign = true;
      this.carrierToAssign = data;
    },
    moveLeft(section) {
      this.isLeft = true;
      this.section = section;
    },
    moveRight(section) {
      this.isLeft = false;
      this.section = section;
    },
    onDragEnd(event) {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store
          .dispatch('LanesStore/moveRankingPosition', {
            id: event.item.__draggable_context.element.id,
            params: { newPosition: event.newIndex },
          })
          .then(() => {
            this.fetchQuotesRanking();
            this.$emit('fetchLanes');
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    async openChat(event, user) {
      event.stopPropagation();
      if (JSON.stringify(this.userForChat) !== JSON.stringify(user)) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        const member = user?.company?.quotingChat;

        if (member) {
          setTimeout(async () => {
            if (this.checkCompanyId(this.carrierRequestedBid, user.id)) {
              this.$store.commit('LoadsStore/loadId', this.loadId);
            } else {
              this.$store.commit('LoadsStore/loadId', null);
            }
            this.$store.commit('ChatsStore/setUserForChat', user);
            const id = user.company ? user.company.owner.id : user.owner_id;
            const findChat = user?.company?.quotingChat;

            if (!member.read) {
              member.read = true;
            } else {
              if (findChat?.id) {
                await this.addMemberToChatLoad(findChat.id);
              }
            }
            this.$store.commit('ChatsStore/setChatId', findChat ? findChat.id : null);
            this.$store.commit('GlobalStore/setOpenChat', true);
          }, 500);
        } else {
          setTimeout(async () => {
            if (this.checkCompanyId(this.carrierRequestedBid, user.id)) {
              this.$store.commit('LoadsStore/loadId', this.loadId);
            } else {
              this.$store.commit('LoadsStore/loadId', null);
            }
            this.$store.commit('ChatsStore/setUserForChat', user);
            const id = user.owner_id;
            const findChat = user?.chat_id;

            if (!user.chat_readed) {
              user.chat_readed = true;
            } else {
              if (findChat?.id) {
                await this.addMemberToChatLoad(findChat.id);
              }
            }
            this.$store.commit('ChatsStore/setChatId', findChat ? findChat.id : null);
            this.$store.commit('GlobalStore/setOpenChat', true);
          }, 500);
        }
      }
    },

    openChatCarrier(event, user) {
      event.stopPropagation();
      if (JSON.stringify(this.userForChat) !== JSON.stringify(user.company)) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        setTimeout(() => {
          this.$store.commit('LoadsStore/loadId', this.currentLoad.id);
          this.$store.commit(
            'ChatsStore/setChatId',
            this.currentLoad.chat ? this.currentLoad.chat.id : null,
          );

          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    async onBookNow() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.$store
          .dispatch('LoadsStore/setBookNowPrice', {
            id: this.currentLoad.id,
            params: { bookNowPrice: this.bookNow, bookNowEnable: this.isBookNow },
          })
          .then((response) => {
            BaseComponent.methods?.showToastSuccess(this.$t('book-now-updated'));
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      }, 800);
    },
    reSendQuote(bidId: any) {
      const index = this.getPosition(bidId);

      if (this.isSuperAdminRoute) {
        return;
      }
      this.isSending[index] = true;
      this.$store
        .dispatch('ShipmentStore/reSendRequestBid', bidId)
        .then((res) => {
          this.carrierRequestedBid[index].lastSent = res.lastSent;

          BaseComponent.methods?.showToastSuccess(this.$t('toast-re-send-quote'));
          this.isSending[index] = false;
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.details);
          this.isSending[index] = false;
        });
    },
    selectBidRanking(id) {
      this.$store
        .dispatch('LanesStore/selectAssignedQuotesBatch', {
          id: this.currentLoad.lanesRFQId,
          LanesRFQBids: { LanesRFQBids: [{ id: id }] },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('message-quotes-sent-correctly'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    },
    setCarrierContactListStatsByLoad() {
      this.$store
        .dispatch('UserStore/carrierContactListStatsByLoad', {
          destinyZip: this.destinyZip,
          lanesRFP: this.currentLane?.id,
          name: null,
          originZip: this.originZip,
          page: this.useContactPage,
          period: this.historicalRange,
          sortBy: null,
          trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          type: 'CARRIER|BROKER',
        })
        .then(() => {
          // 1. Check Cargobot in the contact list (for the Brokers)
          // 2. Check there isn't Quoting in Cargobot
          // 3. Add Cargobot to contact list to send Quoting
          if (this.sourceCarrier().length > 0) {
            if (!this.checkCompanyId(this.carrierRequestedBid, this.sourceCarrier()[0].id)) {
              this.selectedContacts.push(this.sourceCarrier()[0].id);
            }
          }
        });
    },
    setRequestBidStatsByLoad() {
      if (this.shipment !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipment,
          sortBy: `rate[ASC]`,
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });

        this.$store.dispatch('ShipmentStore/totalBids', {
          id: this.shipment,
        });
        // .then(() => (this.showDirectAssign = true));
      }
    },
    showModalDeAssign() {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.showDeAssign = true;
    },
    sourceCarrier() {
      let source: any = this.currentContacts.filter(
        (item: any) => item.source === 'CARGOBOT' || item.source === 'CARGOBOT_EU',
      );
      return source;
    },
    submitDeAssign(data: boolean) {
      if (!data) {
        this.showDeAssign = false;
        return;
      } else {
        this.showDeAssign = false;
        this.isDeassign = true;
        this.$store
          .dispatch('UserStore/reAssignLoad', this.currentLoad.shipment.assignedCompany.id)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('message-unassigned-shipment'));
            this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id).then(() => {
              const query = { page: 1 };
              this.$store.dispatch('LoadsStore/loads', query).then(() => {
                BaseComponent.methods?.showToastSuccess(this.$t('message-load-modified'));
              });
              this.isDeassign = false;
            });
          })
          .catch((err) => {
            console.log(err);
            BaseComponent.methods?.showToastError(err?.response?.data?.details);
            this.isDeassign = false;
          });
      }
    },
    async submitReassign(data: boolean) {
      if (!data) {
        this.showReassignModal = false;
      } else {
        this.$store
          .dispatch('UserStore/reAssignLoad', this.currentLoad.shipment.assignedCompany.id)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('message-reassign-shipment'));
            this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id).then(() => {
              // const query = this.filters;
              this.showReassignModal = false;
              this.acceptBid(this.reassignCompany);
              // const query = { page: 1 };
              // BaseComponent.methods?.showToastSuccess(this.$t('message-load-modified'))
            });
          })
          .catch((err) => {
            console.log(err);
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            this.showReassignModal = false;
          });
      }
    },
    trailerTypeSearch(data: boolean) {
      if (data) {
        this.trailerTypeIdToSearch = this.currentLoad?.trailerType?.id;
      } else {
        this.trailerTypeIdToSearch = 0;
      }
    },
    updateAcceptedBidByAverage() {
      this.isSortByBidName = false;
      this.isSortByBidRating = false;
      this.isSortByBidQuantity = false;
      this.isSortByBidAverage = true;
      this.isSortByBidRate = false;

      if (this.acceptedBidByAverage === '') {
        this.acceptedBidByAverage = 'DESC';
      } else {
        if (this.acceptedBidByAverage === 'DESC') {
          this.acceptedBidByAverage = 'ASC';
        } else {
          this.acceptedBidByAverage = '';
        }
      }
      // this.isSortBy();
      if (this.shipment !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipment,
          sortBy: `${this.acceptedBidByAverage}`
            ? `averageBid[${this.acceptedBidByAverage}]`
            : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    updateAcceptedBidByCompanyName() {
      this.isSortByBidName = true;
      this.isSortByBidRating = false;
      this.isSortByBidQuantity = false;
      this.isSortByBidAverage = false;
      this.isSortByBidRate = false;

      if (this.acceptedBidByCompanyName === '') {
        this.acceptedBidByCompanyName = 'DESC';
      } else {
        if (this.acceptedBidByCompanyName === 'DESC') {
          this.acceptedBidByCompanyName = 'ASC';
        } else {
          this.acceptedBidByCompanyName = '';
        }
      }
      // this.isSortBy();
      if (this.shipment !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipment,
          sortBy:
            `${this.acceptedBidByCompanyName}` !== ''
              ? `companyName[${this.acceptedBidByCompanyName}]`
              : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    updateAcceptedBidByQuantity() {
      this.isSortByBidName = false;
      this.isSortByBidRating = false;
      this.isSortByBidQuantity = true;
      this.isSortByBidAverage = false;
      this.isSortByBidRate = false;

      if (this.acceptedBidByQuantity === '') {
        this.acceptedBidByQuantity = 'DESC';
      } else {
        if (this.acceptedBidByQuantity === 'DESC') {
          this.acceptedBidByQuantity = 'ASC';
        } else {
          this.acceptedBidByQuantity = '';
        }
      }
      // this.isSortBy();
      if (this.shipment !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipment,
          sortBy: `${this.acceptedBidByQuantity}`
            ? `shipmentAmount[${this.acceptedBidByQuantity}]`
            : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    updateAcceptedBidByRate() {
      this.isSortByBidName = false;
      this.isSortByBidRating = false;
      this.isSortByBidQuantity = false;
      this.isSortByBidAverage = false;
      this.isSortByBidRate = true;

      if (this.acceptedBidByRate === '') {
        this.acceptedBidByRate = 'DESC';
      } else {
        if (this.acceptedBidByRate === 'DESC') {
          this.acceptedBidByRate = 'ASC';
        } else {
          this.acceptedBidByRate = '';
        }
      }
      // this.isSortBy();
      if (this.shipment !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipment,
          sortBy: `${this.acceptedBidByRate}` ? `rate[${this.acceptedBidByRate}]` : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    updateAcceptedBidByRating() {
      this.isSortByBidName = false;
      this.isSortByBidRating = true;
      this.isSortByBidQuantity = false;
      this.isSortByBidAverage = false;
      this.isSortByBidRate = false;

      if (this.acceptedBidByRating === '') {
        this.acceptedBidByRating = 'DESC';
      } else {
        if (this.acceptedBidByRating === 'DESC') {
          this.acceptedBidByRating = 'ASC';
        } else {
          this.acceptedBidByRating = '';
        }
      }
      // this.isSortBy();
      if (this.shipment !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipment,
          sortBy:
            `${this.acceptedBidByRating}` !== ''
              ? `rating[${this.acceptedBidByRating}]`
              : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    updateCompetitiveQuoting() {
      this.$store
        .dispatch('UserStore/showQuoteCarrier', {
          id: this.currentLoad.id,
          competitiveQuoting: this.currentLoad.competitiveQuoting,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('change-updated'));
          this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
        });
    },
    updateSearchByAverageBid() {
      this.carrierAdded = {} as User;

      this.isSortByName = false;
      this.isSortByRating = false;
      this.isSortByQuantity = false;
      this.isSortByAverage = true;

      if (this.searchByAverageBid === '') {
        this.searchByAverageBid = 'DESC';
      } else {
        if (this.searchByAverageBid === 'DESC') {
          this.searchByAverageBid = 'ASC';
        } else {
          this.searchByAverageBid = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        lanesRFP: this.currentLane?.id,
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: `averageBid[${this.searchByAverageBid}]`,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
    },
    updateSearchByCompanyName() {
      this.carrierAdded = {} as User;

      this.isSortByName = true;
      this.isSortByRating = false;
      this.isSortByQuantity = false;
      this.isSortByAverage = false;

      if (this.searchByCompanyName === '') {
        this.searchByCompanyName = 'DESC';
      } else {
        if (this.searchByCompanyName === 'DESC') {
          this.searchByCompanyName = 'ASC';
        } else {
          this.searchByCompanyName = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        lanesRFP: this.currentLane?.id,
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: `companyName[${this.searchByCompanyName}]`,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
    },
    updateSearchByLoadsQuantity() {
      this.carrierAdded = {} as User;

      this.isSortByName = false;
      this.isSortByRating = false;
      this.isSortByQuantity = true;
      this.isSortByAverage = false;

      if (this.searchByLoadsQuantity === '') {
        this.searchByLoadsQuantity = 'DESC';
      } else {
        if (this.searchByLoadsQuantity === 'DESC') {
          this.searchByLoadsQuantity = 'ASC';
        } else {
          this.searchByLoadsQuantity = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        lanesRFP: this.currentLane?.id,
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: `loadsQuantity[${this.searchByLoadsQuantity}]`,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
    },
    updateSearchByRating() {
      this.carrierAdded = {} as User;

      this.isSortByName = false;
      this.isSortByRating = true;
      this.isSortByQuantity = false;
      this.isSortByAverage = false;

      if (this.searchByRating === '') {
        this.searchByRating = 'DESC';
      } else {
        if (this.searchByRating === 'DESC') {
          this.searchByRating = 'ASC';
        } else {
          this.searchByRating = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: `rating[${this.searchByRating}]`,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
    },
    updateSearchTypeUser(userType: string) {
      this.carrierAdded = {} as User;

      if (userType === 'TRAILER') {
        this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
          lanesRFP: this.currentLane?.id,
          page: this.useContactPage,
          name: null,
          type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
          trailerType: !this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      } else {
        let searchArray = this.userType.split('|');
        if (searchArray.includes(userType)) {
          searchArray = searchArray.filter((el) => el !== userType);
          this.userType = searchArray.join('|');
        } else {
          if (this.userType === '') {
            this.userType = userType;
          } else {
            this.userType += '|' + userType;
          }
        }
        this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
          lanesRFP: this.currentLane?.id,
          page: this.useContactPage,
          name: null,
          type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
          trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    async validateForm(i) {
      if (this.selectedContacts.length > this.maxBidsRequest) {
        BaseComponent.methods?.showToastError(this.$t('number-max-send-quotes'));
        return;
      }

      if (!this.allowed) return;

      let baseObject = {
        companies: this.selectedContacts.map((item) => ({ id: item })),
      };

      this.isSending[i] = true;
      this.allowed = false;

      this.$store
        .dispatch('LoadsStore/shipmentRequestBatch', {
          companies: baseObject.companies,
          shipments: [{ id: this.shipment }],
        })
        .then(() => {
          this.selectedContacts = [];
          BaseComponent.methods?.showToastSuccess(this.$t('quote-send-correctly'));
          this.$store.dispatch('UserStore/requestBidStatsByLoad', {
            page: 1,
            shipment: this.shipment,
            trailerTypeStats: this.currentLoad?.trailerType?.id,
            originZip: this.originZip,
            destinyZip: this.destinyZip,
            period: this.historicalRange,
          });
          this.$store.dispatch('ShipmentStore/totalBids', {
            id: this.shipment,
          });
          // .then(() => (this.showDirectAssign = true));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.details);
        })
        .finally(() => {
          this.isSending[i] = false;
          this.allowed = true;
        });
    },
  },
});
