import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col plan-gap-xs" }
const _hoisted_2 = {
  key: 1,
  class: "w-full flex flex-col justify-start items-start plan-gap-md"
}
const _hoisted_3 = { class: "w-full flex justify-between items-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "w-fit"
}
const _hoisted_6 = {
  key: 2,
  class: "text-right plan-t-prim-100"
}
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = { style: {"white-space":"pre-line"} }
const _hoisted_9 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!
  const _component_plan_default_delete_modal = _resolveComponent("plan-default-delete-modal")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.template.name,
    size: "lg-auto",
    "hide-close-button": ""
  }, {
    "header-buttons": _withCtx(() => [
      (!_ctx.template.readOnly)
        ? (_openBlock(), _createBlock(_component_popper, {
            key: 0,
            hover: ""
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_plan_button, {
                  onOnClick: _ctx.updateTemplate,
                  type: "cancel",
                  label: _ctx.$t('update'),
                  "justify-align": "justify-start"
                }, null, 8, ["onOnClick", "label"]),
                _createVNode(_component_plan_button, {
                  onOnClick: _ctx.cloneTemplate,
                  type: "cancel",
                  label: _ctx.$t('clone'),
                  "justify-align": "justify-start"
                }, null, 8, ["onOnClick", "label"]),
                _createVNode(_component_plan_button, {
                  onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDelete = true)),
                  type: "cancel",
                  label: _ctx.$t('remove'),
                  "justify-align": "justify-start"
                }, null, 8, ["label"])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_plan_button_icon, { icon: "options" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    content: _withCtx(() => [
      (_ctx.showDelete)
        ? (_openBlock(), _createBlock(_component_plan_default_delete_modal, {
            key: 0,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDelete = false)),
            onDelete: _ctx.deleteTemplate,
            "hide-icon": "",
            title: _ctx.$t('delete-template'),
            loading: _ctx.loadingDelete,
            message: ""
          }, null, 8, ["onDelete", "title", "loading"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.template.readOnly)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Planimatik"))
                : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.templateCategory), 1)),
              (!_ctx.template.readOnly)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('email-template-updated-by', { user: _ctx.template.updatedBy.name, date: _ctx.templateDate } )), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.template.subject), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.template.message), 1)
          ]))
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          type: "cancel",
          label: _ctx.$t('cancel')
        }, null, 8, ["onOnClick", "label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.useTemplate,
          type: "primary",
          label: _ctx.$t('reuse-template')
        }, null, 8, ["onOnClick", "label"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}