
import { defineComponent, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';

interface User {
  id: number;
  name: string;
  company: string;
  owner: {
    name: string;
  };
}

export default defineComponent({
  name: 'ShipmentDataView',
  components: {
    DatePickerComponent,
    ErrorMessage,
    Field,
    Form,
    GoogleAutocomplete,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  data() {
    return {
      currentContacts: [
        {
          id: 9,
          type: 'CARRIER',
          name: 'asda',
          owner: {
            id: 8,
            name: 'asda',
            surname: 'dsadsa',
            roles: ['ROLE_SUPER_CARRIER', 'ROLE_DRIVER'],
          },
          stats: { amountValorations: 0 },
          loadCounts: 5,
        },
        {
          id: 17,
          type: 'CARRIER',
          name: 'asad',
          owner: {
            id: 16,
            name: 'asd',
            surname: 'asd',
            roles: ['ROLE_SUPER_CARRIER', 'ROLE_DRIVER'],
          },
          stats: { amountValorations: 0 },
          loadCounts: 3,
        },
        {
          id: 19,
          type: 'CARRIER',
          name: 'SampleComp',
          owner: {
            id: 18,
            name: 'asdd',
            surname: 'aaa',
            roles: ['ROLE_SUPER_CARRIER', 'ROLE_DRIVER'],
          },
          stats: { amountValorations: 0 },
          loadCounts: 1,
        },
        {
          id: 21,
          type: 'CARRIER',
          name: 'adsada',
          owner: {
            id: 20,
            name: 'sdad',
            surname: 'asdad',
            roles: ['ROLE_SUPER_CARRIER', 'ROLE_DRIVER'],
          },
          stats: { amountValorations: 0 },
          loadCounts: 0,
        },
        {
          id: 32,
          type: 'BROKER',
          name: 'adad',
          owner: {
            id: 31,
            name: 'sadasd',
            surname: 'sada',
            roles: ['ROLE_SUPER_BROKER', 'ROLE_DRIVER'],
          },
          stats: { amountValorations: 0 },
          loadCounts: 0,
        },
        {
          id: 36,
          type: 'CARRIER',
          name: 'sdsadsad',
          owner: {
            id: 35,
            name: 'asda',
            surname: 'dsadsad',
            roles: ['ROLE_SUPER_CARRIER', 'ROLE_DRIVER'],
          },
          stats: { amountValorations: 0 },
          loadCounts: 1,
        },
        {
          id: 55,
          type: 'CARRIER',
          name: 'asa',
          owner: {
            id: 54,
            name: 'asdasdsa',
            surname: 'sss',
            roles: ['ROLE_SUPER_CARRIER', 'ROLE_DRIVER'],
          },
          stats: { amountValorations: 0 },
          loadCounts: 1,
        },
        {
          id: 184,
          type: 'CARRIER',
          name: 'adadsad',
          owner: {
            id: 191,
            name: 'sdada',
            surname: 'asdasda',
            roles: ['ROLE_SUPER_CARRIER', 'ROLE_DRIVER'],
          },
          stats: { amountValorations: 0 },
          loadCounts: 1,
        },
      ],
      carriersFiltered: [] as any,
      userSelected: ref<User | null>(null),
      currentDate: new Date(),
      allowed: true,
      isAssigning: false,
      isCreating: false,
      address: '',
      originStop: '',
      destinationStop: '',
      newAdditionalStop: '',
      isAdditionalStops: false,
      specialRequirements: false,
      isValidating: false,
      getToData: null,
      additionalStops: [] as any,
      load: {
        observations: '',
        // Create Interface!!
        stops: [
          {
            type: 'PICKUP_INITIAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
          },
          {
            type: 'DELIVERY_FINAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
          },
          // Remove it
          {
            type: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
          },
        ],
        commodityMaster: {
          weight: null as null | number,
          commodityName: '',
          minTemp: null,
          maxTemp: null,
        },
        specialRequirement: {
          teamDriver: false,
          bondedPermission: false,
          tsa: false,
          hazardousLoad: false,
          specialPermissions: false,
          specialPermissionsDescription: '',
        },
        trailerType: { id: null },
        status: 'DRAFT',
      },
      dateFormat: 'MM-dd-yyyy',
      after: undefined as any,
      before: undefined as any,
      dateAfter: '',
      dateBefore: '',
    };
  },
  created() {
    //this.$store.dispatch('LoadsStore/currentLoadEditing', this.asd);
    this.$store.dispatch('LoadsStore/trailers');
    if (this.states.length === 0) {
      this.$store.dispatch('LoadsStore/states');
    }

    if (
      this.currentEditingLoad &&
      this.currentEditingLoad.stops &&
      this.currentEditingLoad.stops.length > 0
    ) {
      this.load = this.getStops();
    }
  },
  computed: {
    currentAdditionalStops(): any {
      if (
        this.currentEditingLoad &&
        this.currentEditingLoad.stops &&
        this.currentEditingLoad.stops.length > 0
      ) {
        return this.currentEditingLoad.stops
          .filter((obj) => obj.type === 'PICKUP' || obj.type === 'DELIVERY')
          .map((obj) => {
            return {
              ...obj,
              valueOrder: obj.location.zip + ', ' + obj.location.city,
              pick: obj.type === 'PICKUP' ? 'PICKUP' : 'DELIVERY',
            };
          });
      }
      return null;
    },
    currentEditingLoad(): any {
      return null;
    },
    destiny(): any {
      if (
        this.currentEditingLoad &&
        this.currentEditingLoad.stops &&
        this.currentEditingLoad.stops.length > 0
      ) {
        return this.currentEditingLoad.stops.find((obj) => obj.type === 'DELIVERY_FINAL');
      }
      return null;
    },
    filteredCarriers(): any {
      return this.$store.getters['LoadsStore/filteredCarriers'];
    },
    isVehicleRefrigerated(): any {
      if (this.load?.trailerType?.id !== null) {
        const trailer = this.trailerTypes.find((trail) => trail.id === this.load?.trailerType?.id);
        return trailer.type === 'REFRIGERATED' || trailer.type === 'REFRIGERATEDOPTIONAL';
      } else {
        return false;
      }
    },
    loadId(): any {
      return this.$store.getters['LoadsStore/loadId'];
    },
    minOtherSelectedDate(): any {
      return this.load.stops[0].executionDate;
    },
    origin(): any {
      if (
        this.currentEditingLoad &&
        this.currentEditingLoad.stops &&
        this.currentEditingLoad.stops.length > 0
      ) {
        return this.currentEditingLoad.stops.find((obj) => obj.type === 'PICKUP_INITIAL');
      }
      return null;
    },
    paginationMaxItems(): number {
      return (
        +this.$store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 0
      );
    },
    shipmentId(): any {
      return this.$store.getters['LoadsStore/loadShipmentId'];
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
  },
  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    clearInput(item: string) {
      if (item === 'origin') {
        this.originStop = '';
      }
      if (item === 'destination') {
        this.destinationStop = '';
      }
    },
    closeModal() {
      this.$store.commit('LoadsStore/filteredCarriers', []);
      this.$emit('closeModal', true);
    },
    formatDateString() {
      if (this.after !== null) {
        // this.dateAfter = this.after.toISOString().slice(0, 10)
        this.dateAfter = new Intl.DateTimeFormat('en-GB', {
          dateStyle: 'short',
        }).format(this.after);
        const parts = this.dateAfter.split('/').join('-');
        this.dateAfter = parts;
      }
      if (this.before !== null) {
        this.dateBefore = new Intl.DateTimeFormat('en-GB', {
          dateStyle: 'short',
        }).format(this.before);
        const parts = this.dateBefore.split('/').join('-');
        this.dateBefore = parts;
      }
    },
    async formSubmit() {
      const query = {
        originCity: this.originStop,
        destinyCity: this.destinationStop,
        page: 1,
        originDate: this.after ? new Date(this.after).toISOString().slice(0, 10) : '',
        destinyDate: this.before ? new Date(this.before).toISOString().slice(0, 10) : '',
        trailerType: this.load.trailerType.id,
        commodityName: this.load.commodityMaster.commodityName,
      };

      this.$store
        .dispatch('LoadsStore/findCarriers', query)
        .then()
        .catch((err) => console.log(err));
    },
    getDestination(addressData: any) {
      this.address = addressData;
      this.destinationStop = `${addressData.locality || ''}, ${
        addressData.administrative_area_level_1
      }`;
    },
    getOrigin(addressData: any) {
      this.address = addressData;
      this.originStop = `${addressData.locality || ''}, ${addressData.administrative_area_level_1}`;
    },
    getStops(): any {
      if (
        this.currentAdditionalStops &&
        this.currentAdditionalStops &&
        this.currentAdditionalStops.length > 0
      ) {
        this.isAdditionalStops = true;
        this.additionalStops = this.currentAdditionalStops;
      }

      const { teamDriver, bondedPermission, tsa, hazardousLoad, specialPermissions } =
        this.currentEditingLoad.specialRequirement;

      if ([teamDriver, bondedPermission, tsa, hazardousLoad, specialPermissions].some(Boolean)) {
        this.specialRequirements = true;
      } else {
        //this.specialRequirements = false
      }

      this.originStop = this.origin.location.city;
      this.destinationStop = this.destiny.location.city;

      this.after = this.revertDate(this.origin.executionDate);
      this.before = this.revertDate(this.destiny.executionDate);

      return this.currentEditingLoad;
    },
    isEmptyString(str) {
      return str.trim() === '';
    },
    nextRoute(route: string) {
      this.$router.push(route);
    },
    revertDate(date): any {
      const originalDate = date;
      const [day, month, year] = originalDate.split('-');
      const dateObj = new Date(`${year}-${month}-${day}`);
      dateObj.setHours(0);
      dateObj.setMinutes(0);
      dateObj.setSeconds(0);
      dateObj.setDate(dateObj.getDate());
      return dateObj;
    },
    selectUser(user) {
      this.$emit('selectUser', user);
      this.$emit('closeModal', true);
      this.$store.commit('LoadsStore/filteredCarriers', []);
    },
    toggleUser(user: User | null, add: boolean) {
      if (add) {
        this.userSelected = user;
      } else {
        this.userSelected = null;
      }
    },
    async validate() {
      this.isValidating = true;
      let isValidAdditionalStop = true;
      const validation = await (this.$refs.form as any).validate();
      if (
        !validation.valid ||
        !this.originStop ||
        !this.destinationStop ||
        !this.after ||
        !this.before
      ) {
        return false;
      }
      this.additionalStops.forEach((stop) => {
        if (!stop.valueOrder) {
          isValidAdditionalStop = false;
        }
      });
      if (!isValidAdditionalStop && this.isAdditionalStops) {
        return false;
      }
      return true;
    },
  },
  watch: {
    currentEditingLoad(): any {
      this.load = this.getStops();
    },
  },
});
