
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanInputDate from '@/components/forms/PlanInputDate.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PlanInputText from '@/components/forms/PlanInputText.vue';

export default defineComponent({
  name: 'PlanUpdateMultipleLanesCommodity',
  components: { PlanInputText, PlanInputCheckbox },
  emits: ['change'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      showForm: false,
      commodity: ''
    })

    const change = () => {
      emit('change', { isActive: data.showForm, commodity: data.commodity })
    }

    const changeShowForm = (value: boolean) => {
      data.showForm = value
      change()
    }

    return {
      ...toRefs(data),
      change,
      changeShowForm
    }
  }
})
