
import { computed, defineComponent, ref } from 'vue';
import { formatPriceDecimals } from '@/utils/formatString';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import CarrierAdditionalFeeMediaObjects
  from '@/modules/carrier/quotationRequest/_componentes/CarrierAdditionalFeeMediaObjects.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PlanAdditionalFees',
  components: { CarrierAdditionalFeeMediaObjects, PlanIcon },
  props: {
    shipmentId: { type: String, required: true, default: '0' }
  },
  setup (props) {
    const store = useStore()
    const loadingGetShipmentFees = ref(true);
    const { t } = useI18n();

    const fees = computed(() => {
      return store.getters['LoadsStore/getShipmentFees']
    })

    const getShipmentFees = async () => {
      loadingGetShipmentFees.value = true;
      await store.dispatch('LoadsStore/getShipmentFees', props.shipmentId)
      loadingGetShipmentFees.value = false;
    }

    const getFeeTranslation = (feeType) => {
      let label = feeType.replace(/[-\s]/g, "").toLowerCase();
      return t(`additional-fees-types.${label}`) ?? '';
    }

    getShipmentFees()

    return {
      loadingGetShipmentFees,
      fees,
      formatPriceDecimals,
      getFeeTranslation,
    }
  }
});
