import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "text-xs p-1 flex flex-col plan-gap-xs",
  offsetDistance: "0",
  style: {"width":"250px","font-size":"12px"}
}
const _hoisted_2 = {
  class: "flex justify-between items-center plan-gap-xs",
  style: {"font-size":"14px"}
}
const _hoisted_3 = { class: "font-bold flex justify-start items-center plan-gap-2xs" }
const _hoisted_4 = {
  key: 0,
  class: "flex justify-start items-center plan-gap-2xs"
}
const _hoisted_5 = { class: "font-bold plan-t-green-500" }
const _hoisted_6 = {
  key: 1,
  class: "font-bold plan-t-comp-500"
}
const _hoisted_7 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_8 = { class: "mt-4 flex justify-start items-center plan-gap-xs" }
const _hoisted_9 = { class: "truncate" }
const _hoisted_10 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_11 = { class: "mt-4 flex justify-start items-center plan-gap-xs" }
const _hoisted_12 = {
  key: 0,
  class: "mt-4 plan-t-prim-100 italic truncate"
}
const _hoisted_13 = {
  key: 1,
  class: "mt-4 flex justify-end items-center plan-gap-xs flex-wrap"
}
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.detail ? 'load-timeline-detail' : 'load-timeline')
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stops, (stop, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["flex items-center", [
        index !== _ctx.stops.length - 1 ? 'w-full' : '',
        _ctx.windowWidth < 832 ? 'flex-col justify-between' : 'flex-row justify-center',
        _ctx.windowWidth < 832 && index !== _ctx.stops.length - 1 ? 'h-full' : 'flex-row justify-center',
      ]])
      }, [
        _createVNode(_component_Popper, {
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
          hover: "",
          arrow: "",
          placement: "right",
          class: "popper-padding-1 w-max popper-dark"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", null, "#" + _toDisplayString(stop.sequence), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t(stop.type.toLowerCase())), 1)
                ]),
                ('doneAt' in stop)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_plan_icon, {
                        name: "check",
                        "is-svg": "",
                        "icon-color": "#00DB50"
                      }),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('completed')), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('pending')), 1))
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(_ctx.moment(stop.executionDate).format('MMM DD, YYYY')), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", null, _toDisplayString(stop.location.zip), 1),
                _createElementVNode("span", _hoisted_9, _toDisplayString(stop.location.city) + ",", 1),
                _createElementVNode("span", null, _toDisplayString(stop.location.state.iso), 1),
                _createElementVNode("span", null, _toDisplayString(stop.location.state.country.iso), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", null, _toDisplayString(stop.address), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", null, _toDisplayString(stop.companyName), 1)
              ]),
              (stop.observations)
                ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(stop.observations), 1))
                : _createCommentVNode("", true),
              (stop.mediaObjects)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stop.mediaObjects, (mediaObject) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: mediaObject.id,
                        onClick: _withModifiers(($event: any) => (_ctx.downloadFile(mediaObject.id, mediaObject.entityClass)), ["stop"])
                      }, [
                        _createVNode(_component_plan_button, {
                          type: "success",
                          size: "sm",
                          "show-icon": "",
                          icon: "checked-document",
                          "icon-type": "svg",
                          "icon-color": "white",
                          "icon-size": "14",
                          label: 
                    !_ctx.isEuropeCompany() ? (stop.type.includes('DELIVERY') ? 'POD' : 'BOL') : 'CMR'
                  
                        }, null, 8, ["label"])
                      ], 8, _hoisted_14))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
              class: _normalizeClass([
            index === 0 || index === _ctx.stops.length - 1
              ? 'load-timeline-big-circle'
              : 'load-timeline-small-circle',
            index <= _ctx.currentStop ? 'load-timeline-fill-circle' : '',
          ])
            }, [
              (index <= _ctx.currentStop)
                ? (_openBlock(), _createBlock(_component_plan_icon, {
                    key: 0,
                    name: "check",
                    "is-svg": "",
                    "icon-color": "#151830"
                  }))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          _: 2
        }, 1024),
        (index !== _ctx.stops.length - 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["load-card-default-line", { 'load-card-fill-line': index < _ctx.currentStop }])
            }, null, 2))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ], 2))
}