
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import moment from 'moment/moment';
export default defineComponent({
  name: 'PlanCarrierTrackingHistory',
  components: { PlanLoading, PlanButton },
  setup () {
    const store = useStore()

    const data = reactive({
      history: [],
      loadingTrackingHistory: false,
      showModal: false
    })

    const getDateAndHour = (input: string) => {
      const formatDate = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'MMM DD, YYYY' : 'DD MMM, YYYY'
      return {
        date: moment(input).format(formatDate),
        hour: moment(input).format('HH:mm')
      }
    }

    const getTrackingHistory = () => {
      data.loadingTrackingHistory = true
      store.dispatch('LoadsStore/getTrackingHistory', store.getters['CarrierStore/getLoadData'].shipment?.id)
        .then((response) => {
          data.history = response
          store.commit('CarrierStore/setTrackingHistory', response)
        }).finally(() => {
        data.loadingTrackingHistory = false
      })
    }

    const trackingHistory = computed(() => {
      return store.getters['CarrierStore/getTrackingHistory']
    })

    const trackingHistoryStyle = computed(() => {
      let widthToShow =store.getters['CarrierStore/getActualWidth'] <= 640 ? '300px':'450px'
      if (store.getters['CarrierStore/getActualWidth'] > 1536) widthToShow = '600px'

      return {
        background: 'rgba(255, 255, 255, 0.9)',
        width: widthToShow,
        height: '234px'
      }
    })

    getTrackingHistory()

    return {
      ...toRefs(data),
      getDateAndHour,
      getTrackingHistory,
      trackingHistory,
      trackingHistoryStyle
    }
  }
})
