
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import EmailTemplateForm from '@/modules/planning/contacts/BlastEmail/_forms/EmailTemplateForm.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanDefaultDeleteModal from '@/components/defaults/PlanDefaultDeleteModal.vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

interface templateDetail {
  showDelete: boolean,
  loading: boolean,
  loadingDelete: boolean
}

export default defineComponent({
  name: 'TemplateDetail',
  components: { PlanDefaultDeleteModal, PlanButtonIcon, PlanButton, PlanDefaultModal },
  emits: ['close', 'updateTemplate', 'useTemplate', 'cloneTemplate'],
  props: {
    id: { type: Number, required: true, default: -1 }
  },
  setup (props, {emit}) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      showDelete: false,
      loading: false,
      loadingDelete: false
    })

    const cloneTemplate = () => {
      store.commit('CompanyStore/setTemplateToClone', store.getters['CompanyStore/getSelectedTemplate'])
      emit('cloneTemplate')
    }

    const close = () => { emit('close') }

    const deleteTemplate = () => {
      data.loadingDelete = true
      store.dispatch('CompanyStore/deleteEmailTemplate', store.getters['CompanyStore/getSelectedTemplate'].id)
        .then((response) => {
          store.dispatch('UserStore/showToast', translate.t('success-template-deleted'))
          emit('close')
        }).finally(() => {
          data.loadingDelete = false
      })
    }

    const template = computed(() => {
      return store.getters['CompanyStore/getSelectedTemplate']
    })

    const templateCategory = computed(() => {
      return store.getters['CompanyStore/getSelectedTemplateCategory'] !== null ? store.getters['CompanyStore/getSelectedTemplateCategory'].name : ''
    })

    const templateDate = computed(() => {
      const format = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'MMM DD, YYYY':'DD MMM, YYYY'
      return moment(store.getters['CompanyStore/getSelectedTemplate'].updatedAt).format(format)
    })

    const updateTemplate = () => { emit('updateTemplate', props.id) }

    const useTemplate = () => {
      store.commit('CompanyStore/selectTemplateToSend', store.getters['CompanyStore/getSelectedTemplate'])

      emit('useTemplate', {
        subject: store.getters['CompanyStore/getSelectedTemplate'].subject,
        message: store.getters['CompanyStore/getSelectedTemplate'].message
      })
    }

    return {
      ...toRefs(data),
      cloneTemplate,
      close,
      deleteTemplate,
      template,
      templateCategory,
      templateDate,
      updateTemplate,
      useTemplate
    }
  }
})
