import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = {
  key: 1,
  class: "plan-t-prim-100"
}
const _hoisted_3 = {
  class: "plan-t-xs",
  style: {"max-width":"100px"}
}
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "plan-t-xs" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "flex justify-between items-start" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { class: "plan-t-xs plan-t-prim-100" }
const _hoisted_11 = { class: "plan-mt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_default_modal, {
      title: _ctx.$t('tracking-history'),
      onClose: _ctx.close,
      size: "sm-auto",
      "without-sidebar": ""
    }, {
      content: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_plan_loading, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.loading && _ctx.items.length === 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('default-no-results')), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_plan_default_table, {
          headers: [],
          items: _ctx.items
        }, {
          default: _withCtx((props) => [
            _createElementVNode("td", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.moment(props.item.createdDate).format(_ctx.formatDate)), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(props.item.location?.zip) + " " + _toDisplayString(props.item.location?.city) + ", " + _toDisplayString(props.item.location?.state?.iso) + " " + _toDisplayString(props.item.location?.state?.country?.iso), 1),
                (props.item.observations)
                  ? (_openBlock(), _createBlock(_component_plan_text_button, {
                      key: 0,
                      onOnClick: ($event: any) => (_ctx.selectedItem = props.item),
                      label: _ctx.$t('view-comments')
                    }, null, 8, ["onOnClick", "label"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }, 8, ["items"])
      ]),
      _: 1
    }, 8, ["title", "onClose"]),
    (_ctx.selectedItem !== null)
      ? (_openBlock(), _createBlock(_component_plan_default_modal, {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedItem = null)),
          size: "sm-auto",
          "only-content": "",
          "without-sidebar": ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.selectedItem.location?.zip) + " " + _toDisplayString(_ctx.selectedItem.location?.city) + ", " + _toDisplayString(_ctx.selectedItem.location?.state?.iso) + " " + _toDisplayString(_ctx.selectedItem.location?.state?.country?.iso), 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.moment(_ctx.selectedItem.createdDate).format(_ctx.formatDate)), 1)
              ]),
              _createVNode(_component_plan_button_icon, {
                onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedItem = null)),
                icon: "close",
                type: "transparent-gray-800"
              })
            ]),
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.selectedItem.observations), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}