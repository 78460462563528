<template>
  <BaseModal
    :show="show"
    @callbackClose="onClickClose"
  >
    <template v-slot:title>
      <h3 class="md:text-3xl font-bold text-center">
        {{ $t('assign-credit-to-shipper') }}
      </h3>
    </template>

    <template v-slot:main>
      <div class="flex flex-row justify-center py-5">
        <Form
          ref="form"
          @submit="null"
          @keypress.enter.prevent
        >
          <span class="text-sm">
            {{ $t('indicate-credit-price') }}
          </span>
          <div class="relative w-2/3 my-2 justify-center mx-auto rounded-md shadow-sm">
            <Field
              name="Price"
              rules="required|min_value:1|max_value:2147483647"
              v-model="creditPrice"
            >
              <InputAmericanFormat
                v-model="creditPrice"
              />
            </Field>
          </div>
          <ErrorMessage
            name="Price"
            class="text-rose-500"
          />
        </Form>
      </div>
      <div class="flex flex-row justify-center">
        <button
          class="inline-flex items-center bg-green-500 text-white text-sm text-center rounded-md px-6 py-2.5
            hover:opacity-70 focus:ring-3 focus:outline-none focus:ring-green-300"
          type="button"
          @click="onClickSend"
        >
          <Spin v-if="loading" />
          {{ $t('send') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import Spin from '@/components/spin/AnimateSpin.vue'

export default defineComponent({
  name: 'CreditQuantityModal',
  components: {
    BaseModal,
    InputAmericanFormat,
    Spin,
  },
  mixins: [BaseComponent],
  emits: ['callbackClose', 'callbackSend'],
  props: {
    loading: Boolean,
    show: Boolean,
  },
  setup(props, { emit }) {
    /** Global */
    const creditPrice = ref(0);

    /** Methods */
    const onClickClose = () => {
      creditPrice.value = 0;
      emit('callbackClose');
    };

    const onClickSend = () => {
      emit('callbackSend', creditPrice.value);
    };

    /** Watchers */
    watch(
      () => props.show,
      (newValue) => {
        if (newValue === true) {
          creditPrice.value = 0;
        }
      }
    );

    return {
      creditPrice,
      onClickClose,
      onClickSend,
    }
  }
});
</script>

<style scoped>

</style>
