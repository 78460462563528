import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "new-load-button-popper-items",
  style: {"width":"186px"}
}
const _hoisted_2 = { class: "w-full flex flex-col justify-start items-center plan-gap-md" }
const _hoisted_3 = {
  key: 4,
  class: "w-full flex flex-col justify-start items-center plan-gap-md"
}
const _hoisted_4 = { class: "w-full flex justify-between items-center" }
const _hoisted_5 = { class: "font-bold plan-text-sm" }
const _hoisted_6 = {
  key: 0,
  class: "flex justify-center items-center plan-gap-xs plan-t-prim-100 plan-text-sm italic"
}
const _hoisted_7 = { class: "flex justify-end items-center plan-gap-sm" }
const _hoisted_8 = {
  key: 5,
  class: "w-full flex flex-col justify-start items-center plan-gap-md"
}
const _hoisted_9 = { class: "w-full flex justify-between items-center" }
const _hoisted_10 = { class: "font-bold plan-text-lg" }
const _hoisted_11 = {
  key: 1,
  class: "plan-mt-xl w-full flex justify-between items-center plan-gap-xl"
}
const _hoisted_12 = { class: "plan-t-red-500 font-bold plan-text-lg" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 0,
  class: "w-full flex justify-end items-center plan-gap-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_header_search = _resolveComponent("plan-header-search")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!
  const _component_import_warehouses_from_excel_modal = _resolveComponent("import-warehouses-from-excel-modal")!
  const _component_add_warehouse_modal = _resolveComponent("add-warehouse-modal")!
  const _component_update_warehouse_modal = _resolveComponent("update-warehouse-modal")!
  const _component_plan_default_delete_modal = _resolveComponent("plan-default-delete-modal")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_plan_default_view = _resolveComponent("plan-default-view")!

  return (_openBlock(), _createBlock(_component_plan_default_view, {
    title: _ctx.viewMode === 'list' ? _ctx.$t('warehouses') : _ctx.$t('import-result'),
    "show-go-top": _ctx.viewMode === 'list',
    "show-footer": _ctx.viewMode === 'import'
  }, {
    "header-content": _withCtx(() => [
      (_ctx.viewMode === 'list')
        ? (_openBlock(), _createBlock(_component_plan_header_search, {
            key: 0,
            onChangeQuery: _ctx.changeQuery,
            "old-query": '',
            placeholder: _ctx.$t('search-warehouse'),
            class: "plan-ml-md"
          }, null, 8, ["onChangeQuery", "placeholder"]))
        : _createCommentVNode("", true)
    ]),
    "header-buttons": _withCtx(() => [
      (_ctx.viewMode === 'list')
        ? (_openBlock(), _createBlock(_component_popper, {
            key: 0,
            hover: "",
            offsetDistance: "-6",
            class: "new-load-button-popper",
            style: {"width":"220px"}
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_plan_button, {
                  onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddWarehouse = true)),
                  type: "sec-600",
                  label: _ctx.$t('add-a-warehouse'),
                  class: "plan-form"
                }, null, 8, ["label"]),
                _createVNode(_component_plan_button, {
                  onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showImportWarehouses = true)),
                  type: "sec-600",
                  label: _ctx.$t('import-warehouses-xlsx'),
                  multiline: ""
                }, null, 8, ["label"])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_plan_button, {
                type: "primary",
                label: _ctx.$t('add-warehouse'),
                class: "plan-form"
              }, null, 8, ["label"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    "view-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showImportWarehouses)
          ? (_openBlock(), _createBlock(_component_import_warehouses_from_excel_modal, {
              key: 0,
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showImportWarehouses = false)),
              onWarehouses: _ctx.getWarehousesToImport
            }, null, 8, ["onWarehouses"]))
          : _createCommentVNode("", true),
        (_ctx.showAddWarehouse)
          ? (_openBlock(), _createBlock(_component_add_warehouse_modal, {
              key: 1,
              onCloseAndUpdate: _ctx.closeAddAndUpdateWarehouse,
              onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showAddWarehouse = false))
            }, null, 8, ["onCloseAndUpdate"]))
          : _createCommentVNode("", true),
        (_ctx.showUpdateWarehouse)
          ? (_openBlock(), _createBlock(_component_update_warehouse_modal, {
              key: 2,
              onCloseAndUpdate: _ctx.closeUpdatedWarehouse,
              onClose: _ctx.closeUpdateWarehouse
            }, null, 8, ["onCloseAndUpdate", "onClose"]))
          : _createCommentVNode("", true),
        (_ctx.deleteItem !== null)
          ? (_openBlock(), _createBlock(_component_plan_default_delete_modal, {
              key: 3,
              onClose: _ctx.closeDeleteModal,
              onDelete: _ctx.deleteWarehouse,
              loading: _ctx.deleteItemLoading,
              title: _ctx.$t('delete-warehouse'),
              message: _ctx.$t('are-you-sure-message'),
              "hide-icon": ""
            }, null, 8, ["onClose", "onDelete", "loading", "title", "message"]))
          : _createCommentVNode("", true),
        (_ctx.viewMode === 'list')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.totalWarehouses) + " " + _toDisplayString(_ctx.$t('warehouses')), 1),
                (_ctx.loadingWarehouses)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_plan_icon, {
                        name: "spinner",
                        "icon-color": "#8A8B97",
                        size: "15",
                        "is-svg": "",
                        rotate: ""
                      }),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('updating-list')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_plan_default_table, {
                onChangeOrder: _ctx.changeOrder,
                order: _ctx.order.value,
                "order-direction": _ctx.order.direction,
                headers: _ctx.headers,
                items: _ctx.warehouses,
                style: {"background":"white"},
                class: "w-full"
              }, {
                default: _withCtx((props) => [
                  _createElementVNode("td", null, _toDisplayString(props.item.companyWarehouse), 1),
                  _createElementVNode("td", null, _toDisplayString(props.item.address), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.getLocationOfWarehouse(props.item).city), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.getLocationOfWarehouse(props.item).country), 1),
                  _createElementVNode("td", null, _toDisplayString(props.item.contactName), 1),
                  _createElementVNode("td", null, "+" + _toDisplayString(props.item.phonePrefix) + " " + _toDisplayString(props.item.phoneNumber), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_popper, {
                        placement: "left",
                        arrow: "",
                        hover: ""
                      }, {
                        content: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('warehouse-form.you-must-update-warehouse')), 1)
                        ]),
                        default: _withCtx(() => [
                          (_ctx.warehouseHasProblems(props.item))
                            ? (_openBlock(), _createBlock(_component_plan_button_icon, {
                                key: 0,
                                icon: "bell-claims",
                                size: "18",
                                color: "#EF4444",
                                type: "transparent-red"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_plan_button_icon, {
                        onOnClick: ($event: any) => (_ctx.showWarehouse(props.item)),
                        icon: "plan-edit",
                        size: "24",
                        color: "#EF4444",
                        type: "transparent-red"
                      }, null, 8, ["onOnClick"]),
                      _createVNode(_component_plan_button_icon, {
                        onOnClick: ($event: any) => (_ctx.deleteItem = props.item),
                        icon: "plan-trash",
                        size: "24",
                        color: "#EF4444",
                        type: "transparent-red"
                      }, null, 8, ["onOnClick"])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["onChangeOrder", "order", "order-direction", "headers", "items"]),
              (
              !_ctx.loadingWarehouses && _ctx.warehouses.length > 0 && _ctx.warehouses.length < _ctx.totalWarehouses
            )
                ? (_openBlock(), _createBlock(_component_plan_button, {
                    key: 0,
                    onOnClick: _ctx.loadMoreWarehouses,
                    loading: _ctx.loadingWarehouses,
                    type: "primary-outline",
                    "full-rounded": "",
                    label: _ctx.$t('show-more-warehouses'),
                    style: {"width":"328px"}
                  }, null, 8, ["onOnClick", "loading", "label"]))
                : _createCommentVNode("", true),
              (_ctx.loadingWarehouses)
                ? (_openBlock(), _createBlock(_component_plan_loading, {
                    key: 1,
                    class: "plan-t-prim-100"
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.viewMode === 'import')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.warehousesToImport.length) + " " + _toDisplayString(_ctx.$t('warehouse-available-to-generate')), 1),
                _createVNode(_component_plan_button_icon, {
                  onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showWarehousesToImport = !_ctx.showWarehousesToImport)),
                  icon: _ctx.showWarehousesToImport ? 'chevron-down' : 'chevron-up',
                  type: "transparent-gray-800"
                }, null, 8, ["icon"])
              ]),
              (_ctx.showWarehousesToImport)
                ? (_openBlock(), _createBlock(_component_plan_default_table, {
                    key: 0,
                    onSelectedItems: _ctx.changeSelectedWarehousesToImport,
                    headers: _ctx.importHeaders,
                    items: _ctx.warehousesToImport,
                    selectable: "",
                    style: {"background":"white"}
                  }, {
                    default: _withCtx((props) => [
                      _createElementVNode("td", null, _toDisplayString(props.item.CompanyName), 1),
                      _createElementVNode("td", null, _toDisplayString(props.item.Address), 1),
                      _createElementVNode("td", null, _toDisplayString(props.item.Country), 1),
                      _createElementVNode("td", null, _toDisplayString(props.item.Zipcode), 1),
                      _createElementVNode("td", null, _toDisplayString(props.item.Contact), 1),
                      _createElementVNode("td", null, "+" + _toDisplayString(props.item.PhonePrefix) + " " + _toDisplayString(props.item.Phone), 1)
                    ]),
                    _: 1
                  }, 8, ["onSelectedItems", "headers", "items"]))
                : _createCommentVNode("", true),
              (_ctx.warehousesCannotBeImport.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.warehousesCannotBeImport.length) + " " + _toDisplayString(_ctx.$t('warehouse-cannot-be-generate')), 1),
                    (_ctx.warehousesCannotBeImport.length > 0)
                      ? (_openBlock(), _createBlock(_component_plan_button, {
                          key: 0,
                          onOnClick: _ctx.createExcelFile,
                          type: "primary",
                          label: _ctx.$t('download-warehouses-to-review'),
                          "show-icon": "",
                          icon: "download-down"
                        }, null, 8, ["onOnClick", "label"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.warehousesCannotBeImport.length > 0)
                ? (_openBlock(), _createBlock(_component_plan_default_table, {
                    key: 2,
                    headers: _ctx.importHeaders,
                    items: _ctx.warehousesCannotBeImport,
                    style: {"background":"white"}
                  }, {
                    default: _withCtx((props) => [
                      _createElementVNode("td", null, _toDisplayString(props.item.CompanyName), 1),
                      _createElementVNode("td", null, _toDisplayString(props.item.Address), 1),
                      _createElementVNode("td", null, _toDisplayString(props.item.Country), 1),
                      _createElementVNode("td", null, _toDisplayString(props.item.Zipcode), 1),
                      _createElementVNode("td", null, _toDisplayString(props.item.Contact), 1),
                      _createElementVNode("td", null, [
                        (props.item.PhonePrefix)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, "+"))
                          : _createCommentVNode("", true),
                        _createTextVNode(_toDisplayString(props.item.PhonePrefix) + " " + _toDisplayString(props.item.Phone), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["headers", "items"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => [
      (_ctx.viewMode !== 'list')
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.cleanImport,
              icon: "close",
              type: "cancel",
              label: "Cancel"
            }, null, 8, ["onOnClick"]),
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.postCreateWarehouseFromJson,
              loading: _ctx.loadingWarehousesFromJson,
              disabled: _ctx.selectedWarehousesToImport.length === 0,
              type: "primary",
              label: `${_ctx.$t('add')} ${_ctx.selectedWarehousesToImport.length} ${_ctx.$t('warehouses')}`
            }, null, 8, ["onOnClick", "loading", "disabled", "label"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "show-go-top", "show-footer"]))
}