import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex justify-start items-center flex-row-reverse" }
const _hoisted_3 = {
  key: 0,
  class: "truncate"
}
const _hoisted_4 = {
  key: 0,
  class: "truncate plan-mr-sm"
}
const _hoisted_5 = { class: "w-full flex flex-col plan-gap-sm py-5" }
const _hoisted_6 = {
  key: 0,
  class: "w-full flex justify-center items-center plan-gap-xs plan-t-prim-100 plan-t-xxs italic"
}
const _hoisted_7 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_popper = _resolveComponent("popper")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_input_location = _resolveComponent("plan-input-location")!
  const _component_plan_input_text_area = _resolveComponent("plan-input-text-area")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_popper, {
        placement: "left",
        hover: "",
        class: _normalizeClass(
          _ctx.actualWidth < 640
            ? 'plan-carrier-fixed-button-popper'
            : 'plan-carrier-fixed-button-popper-hidden'
        ),
        offsetDistance: "2"
      }, {
        content: _withCtx(() => [
          (_ctx.actualWidth < 640)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('add-truck-position')), 1))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_plan_button_icon, {
            onOnClick: _ctx.goToNotifyPosition,
            icon: "add-location",
            type: "primary",
            color: "white",
            class: "plan-button-icon-box-shadow",
            style: {"min-width":"48px","min-height":"48px"},
            size: "25"
          }, null, 8, ["onOnClick"])
        ]),
        _: 1
      }, 8, ["class"]),
      (_ctx.actualWidth >= 640)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('add-truck-position')), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
          key: 0,
          onClose: _ctx.closeModal,
          title: _ctx.$t('update-position'),
          size: "sm-auto",
          "hide-close-button": ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.loadingInitialLocation)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_plan_icon, {
                      name: "spinner",
                      "icon-color": "#8A8B97",
                      size: "15",
                      "is-svg": "",
                      rotate: ""
                    }),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('getting-your-location')) + "...", 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_plan_input_location, {
                onChange: _ctx.changeInputLocation,
                "hide-label": "",
                "placement-flags": 'right',
                "pre-country": _ctx.initialLocation.country,
                "pre-location": _ctx.initialLocation.zip,
                zipCodeSearch: false
              }, null, 8, ["onChange", "pre-country", "pre-location"]),
              _createVNode(_component_plan_input_text_area, {
                "model-value": _ctx.observations,
                "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.observations) = $event)),
                placeholder: _ctx.$t('add-observations'),
                rows: 4
              }, null, 8, ["model-value", "placeholder"])
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.closeModal,
                disabled: _ctx.loadingPostNotifyPosition,
                type: "cancel",
                label: _ctx.$t('cancel')
              }, null, 8, ["onOnClick", "disabled", "label"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.postNotifyPosition,
                loading: _ctx.loadingPostNotifyPosition,
                type: "primary",
                label: _ctx.$t('send'),
                style: {"min-width":"100px"}
              }, null, 8, ["onOnClick", "loading", "label"])
            ])
          ]),
          _: 1
        }, 8, ["onClose", "title"]))
      : _createCommentVNode("", true)
  ]))
}