
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'RankingRFPModalSkeleton',
  mixins: [BaseComponent],
  props: {
    totalRows: { type: Number, default: 3 }
  },
  components: {},
  methods: {
    onClickClose() {
      this.$emit('callbackClose')
    }
  }
});
