import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    modelValue: _ctx.date,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
    "auto-apply": "",
    class: _normalizeClass(_ctx.customClass),
    "enable-time-picker": false,
    format: _ctx.dateFormatLocale(),
    locale: _ctx.getLocale,
    "min-date": _ctx.minDate,
    "max-date": _ctx.maxDate,
    placeholder: _ctx.computedPlaceholder,
    range: _ctx.rangeEnabled,
    "start-date": _ctx.startDate,
    teleport: _ctx.teleport,
    "week-start": _ctx.startWeek,
    "disabled-dates": _ctx.disableWeekends,
    value: _ctx.date,
    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, _createSlots({ _: 2 }, [
    (_ctx.iconClear)
      ? {
          name: "clear-icon",
          fn: _withCtx(({ clear }) => [
            _createVNode(_component_XCircleIcon, {
              class: "w-4 h-4 text-corp mr-2",
              onClick: clear
            }, null, 8, ["onClick"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["modelValue", "class", "format", "locale", "min-date", "max-date", "placeholder", "range", "start-date", "teleport", "week-start", "disabled-dates", "value"]))
}