import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row flex-1 xl:ml-90 h-auto p-16 xl:p-20 gap-16 bg-white" }
const _hoisted_2 = { class: "flex-col justify-start items-start gap-14 inline-flex" }
const _hoisted_3 = { class: "self-stretch text-black text-3xl font-bold" }
const _hoisted_4 = { class: "flex-col justify-start items-start gap-8 flex" }
const _hoisted_5 = { class: "max-w-[24rem] text-black text-2xl font-medium leading-10" }
const _hoisted_6 = { class: "flex flex-col gap-4" }
const _hoisted_7 = { class: "flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_8 = {
  class: "text-black text-sm font-normal",
  for: "email"
}
const _hoisted_9 = { class: "flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_10 = {
  class: "text-black text-sm font-normal",
  for: "credit"
}
const _hoisted_11 = { class: "flex flex-col gap-8" }
const _hoisted_12 = { class: "text-black text-2xl font-medium" }
const _hoisted_13 = { class: "flex-col justify-start items-start gap-4 flex" }
const _hoisted_14 = { class: "w-full flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_15 = {
  class: "text-black text-sm font-normal",
  for: "dunNumber"
}
const _hoisted_16 = { class: "w-full flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_17 = {
  class: "text-black text-sm font-normal",
  for: "bradstreetNumber"
}
const _hoisted_18 = { class: "w-full flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_19 = {
  class: "text-black text-sm font-normal",
  for: "taxId"
}
const _hoisted_20 = { class: "flex flex-col gap-8" }
const _hoisted_21 = { class: "text-black text-2xl font-medium" }
const _hoisted_22 = { class: "w-full flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_23 = { class: "w-full flex justify-between relative" }
const _hoisted_24 = ["for"]
const _hoisted_25 = { class: "w-full flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_26 = ["for"]
const _hoisted_27 = { class: "w-full flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_28 = ["for"]
const _hoisted_29 = { class: "flex flex-col gap-8" }
const _hoisted_30 = { class: "text-black text-2xl font-medium" }
const _hoisted_31 = { class: "flex-col justify-start items-start gap-4 flex" }
const _hoisted_32 = { class: "w-full flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_33 = {
  class: "text-black text-sm font-normal",
  for: "financeDirectorEmail"
}
const _hoisted_34 = { class: "w-full flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_35 = {
  class: "text-black text-sm font-normal",
  for: "companyOwnerEmail"
}
const _hoisted_36 = { class: "flex justify-end gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_InputAmericanFormat = _resolveComponent("InputAmericanFormat")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_PlanButtonVue = _resolveComponent("PlanButtonVue")!
  const _component_Spin = _resolveComponent("Spin")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('get-your-credit-planimatik')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('provide-information-planimatik')), 1)
      ])
    ]),
    _createVNode(_component_Form, {
      class: "flex flex-col gap-16",
      ref: "form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('accounts-payable-email')) + " * ", 1),
            _createVNode(_component_Field, {
              class: "h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500",
              id: "email",
              name: "email",
              rules: "required|email",
              modelValue: $setup.data.accountPayableEmail,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.data.accountPayableEmail) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              class: "text-sm text-rose-500",
              name: "email"
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('credit-amount-that-you-need')) + " * ", 1),
            _createVNode(_component_Field, {
              class: "h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500",
              id: "credit",
              name: "credit",
              rules: "required",
              modelValue: $setup.data.creditRequested,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.data.creditRequested) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputAmericanFormat, {
                  class: "flex w-full",
                  "class-custom": "!rounded-lg",
                  modelValue: $setup.data.creditRequested,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.data.creditRequested) = $event)),
                  symbol: _ctx.currency,
                  hidden: true
                }, null, 8, ["modelValue", "symbol"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              class: "text-sm text-rose-500",
              name: "credit"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('bank-references')), 1),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('dun-number')) + " * ", 1),
              _createVNode(_component_Field, {
                class: "h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500",
                id: "dunNumber",
                name: "dunNumber",
                rules: "required",
                modelValue: $setup.data.dunNumber,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.data.dunNumber) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                class: "text-sm text-rose-500",
                name: "dunNumber"
              })
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('bradstreet-number')) + " * ", 1),
              _createVNode(_component_Field, {
                class: "h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500",
                id: "bradstreetNumber",
                name: "bradstreetNumber",
                rules: "required",
                modelValue: $setup.data.bradstreetNumber,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.data.bradstreetNumber) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                class: "text-sm text-rose-500",
                name: "bradstreetNumber"
              })
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('tax-id-ein-number')) + " * ", 1),
              _createVNode(_component_Field, {
                class: "h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500",
                id: "taxId",
                name: "taxId",
                rules: "required",
                modelValue: $setup.data.taxId,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.data.taxId) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                class: "text-sm text-rose-500",
                name: "taxId"
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('supplier-comercial-references')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data.suppliers, (supp, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["flex-col justify-start items-start gap-4 flex", { 'border-t border-gray-300  pt-8': index > 0 }]),
              key: index
            }, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("label", {
                    class: "text-black text-sm font-normal",
                    for: `companyName${index}`
                  }, _toDisplayString(_ctx.$t('label.company-name')) + " * ", 9, _hoisted_24),
                  (index > 0)
                    ? (_openBlock(), _createBlock(_component_XMarkIcon, {
                        key: 0,
                        class: "w-6 h-6 text-gray-500 absolute right-0 top-[-1rem] cursor-pointer",
                        onClick: ($event: any) => ($setup.removeReference(index))
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_Field, {
                  class: "h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500",
                  id: `companyName${index}`,
                  name: `companyName${index}`,
                  rules: "required",
                  modelValue: supp.companyName,
                  "onUpdate:modelValue": ($event: any) => ((supp.companyName) = $event)
                }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  class: "text-sm text-rose-500",
                  name: `companyName${index}`
                }, null, 8, ["name"])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("label", {
                  class: "text-black text-sm font-normal",
                  for: `contactPerson${index}`
                }, _toDisplayString(_ctx.$t('contact-person')) + "* ", 9, _hoisted_26),
                _createVNode(_component_Field, {
                  class: "h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500",
                  id: `contactPerson${index}`,
                  name: `contactPerson${index}`,
                  rules: "required",
                  modelValue: supp.contactPerson,
                  "onUpdate:modelValue": ($event: any) => ((supp.contactPerson) = $event)
                }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  class: "text-sm text-rose-500",
                  name: `contactPerson${index}`
                }, null, 8, ["name"])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("label", {
                  class: "text-black text-sm font-normal",
                  for: `email${index}`
                }, _toDisplayString(_ctx.$t('email')) + "* ", 9, _hoisted_28),
                _createVNode(_component_Field, {
                  class: "h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500",
                  id: `email${index}`,
                  name: `email${index}`,
                  rules: "required|email",
                  modelValue: supp.email,
                  "onUpdate:modelValue": ($event: any) => ((supp.email) = $event)
                }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  class: "text-sm text-rose-500",
                  name: `email${index}`
                }, null, 8, ["name"])
              ])
            ], 2))
          }), 128)),
          _createElementVNode("p", {
            class: "w-fit px-4 py-2 bg-white rounded-3xl border border-black text-center cursor-pointer hover:opacity-70",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($setup.addReference && $setup.addReference(...args)))
          }, " + " + _toDisplayString(_ctx.$t('add-reference')), 1)
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('contacts')), 1),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('finance-director-email')) + "* ", 1),
              _createVNode(_component_Field, {
                class: "h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500",
                id: "financeDirectorEmail",
                name: "financeDirectorEmail",
                rules: "required|email",
                modelValue: $setup.data.financeDirectorEmail,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.data.financeDirectorEmail) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                class: "text-sm text-rose-500",
                name: "financeDirectorEmail"
              })
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t('company-owner-email')) + "* ", 1),
              _createVNode(_component_Field, {
                class: "h-full w-full min-h-[46px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500",
                id: "companyOwnerEmail",
                name: "companyOwnerEmail",
                rules: "required|email",
                modelValue: $setup.data.companyOwnerEmail,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.data.companyOwnerEmail) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                class: "text-sm text-rose-500",
                name: "companyOwnerEmail"
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_36, [
          _createVNode(_component_PlanButtonVue, {
            label: _ctx.$t('cancel'),
            type: 'primary-dark-outline',
            size: "lg",
            onOnClick: $setup.goPrevRoute
          }, null, 8, ["label", "onOnClick"]),
          _createElementVNode("div", {
            class: _normalizeClass(["plan-button-primary-dark plan-button-lg", $setup.sending ? 'cursor-not-allowed opacity-75' : 'cursor-pointer']),
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => ($setup.postCreditRequests && $setup.postCreditRequests(...args)))
          }, [
            ($setup.sending)
              ? (_openBlock(), _createBlock(_component_Spin, { key: 0 }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString($setup.sending ? _ctx.$t('sending') : _ctx.$t('send')), 1)
          ], 2)
        ])
      ]),
      _: 1
    }, 512)
  ]))
}