import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-045a94ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-6" }
const _hoisted_2 = {
  key: 0,
  class: "w-full h-56 px-4 py-6 bg-white rounded-lg shadow border-neutral-400 flex-col justify-start items-start gap-6 inline-flex"
}
const _hoisted_3 = { class: "self-stretch justify-between items-end inline-flex" }
const _hoisted_4 = { class: "grow shrink basis-0 flex-col justify-start items-start inline-flex" }
const _hoisted_5 = { class: "text-slate-900 text-base font-bold font-['Nunito']" }
const _hoisted_6 = {
  key: 0,
  class: "w-20 text-right"
}
const _hoisted_7 = { class: "text-slate-900 text-base font-bold" }
const _hoisted_8 = { class: "scrollbar-thin w-full scrollbar-thumb-gray-300 scrollbar-track-gray-100 relative mx-auto flex h-72 flex-col overflow-y-auto rounded-xl bg-white" }
const _hoisted_9 = { class: "inline-flex h-48 w-full flex-col items-start justify-start gap-6 rounded-lg border-neutral-400 bg-white" }
const _hoisted_10 = {
  key: 0,
  class: "self-stretch h-24 flex-col justify-start items-start gap-2 flex"
}
const _hoisted_11 = { class: "justify-center items-center flex" }
const _hoisted_12 = { class: "flex-col justify-start items-center inline-flex" }
const _hoisted_13 = { class: "w-16 text-neutral-400 text-xs font-normal font-['Nunito']" }
const _hoisted_14 = {
  key: 0,
  class: "justify-center items-center gap-2 flex"
}
const _hoisted_15 = { class: "text-slate-900 whitespace-nowrap text-base font-normal font-['Nunito']" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "flex justify-end" }
const _hoisted_18 = {
  class: "ml-2",
  style: {"min-width":"100px"}
}
const _hoisted_19 = { class: "plan-button-primary plan-button-sm" }
const _hoisted_20 = { class: "font-semibold whitespace-nowrap" }
const _hoisted_21 = {
  key: 1,
  class: "justify-center cursor-pointer items-center gap-2 flex"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "flex justify-end" }
const _hoisted_25 = {
  class: "ml-2",
  style: {"min-width":"100px"}
}
const _hoisted_26 = { class: "plan-button-primary plan-button-sm" }
const _hoisted_27 = { class: "font-semibold whitespace-nowrap" }
const _hoisted_28 = {
  key: 2,
  class: "justify-center items-center gap-2 flex"
}
const _hoisted_29 = { class: "text-slate-900 text-base font-normal font-['Nunito']" }
const _hoisted_30 = { class: "w-20 text-right" }
const _hoisted_31 = { class: "text-slate-900 text-base font-normal" }
const _hoisted_32 = {
  key: 1,
  class: "flex flex-row"
}
const _hoisted_33 = { class: "w-full text-neutral-400 text-lg font-normal font-['Nunito']" }
const _hoisted_34 = {
  key: 0,
  class: "p-6 mt-10"
}
const _hoisted_35 = { class: "w-96 h-6 flex-col justify-start items-start gap-2.5 inline-flex" }
const _hoisted_36 = { class: "justify-center items-center inline-flex" }
const _hoisted_37 = { class: "justify-center items-center gap-2 flex" }
const _hoisted_38 = { class: "text-slate-900 text-lg font-bold font-['Nunito']" }
const _hoisted_39 = { class: "overflow-y-auto h-72 relative mx-auto bg-white rounded-b-xl flex flex-col scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100" }
const _hoisted_40 = { class: "w-full cursor-pointer h-48 px-4 py-6 bg-gray-50 rounded-lg border-neutral-400 flex-col justify-start items-start gap-2 inline-flex" }
const _hoisted_41 = {
  key: 0,
  class: "w-full h-auto p-4 bg-sky-200 rounded-lg border border-sky-200 flex-col justify-start items-start gap-6 inline-flex"
}
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { class: "grow shrink basis-0 flex-col justify-start items-start inline-flex" }
const _hoisted_44 = { class: "text-slate-900 text-xs font-medium font-['Nunito']" }
const _hoisted_45 = { class: "w-20 text-right" }
const _hoisted_46 = { style: {} }
const _hoisted_47 = {
  key: 0,
  class: "accordion-content"
}
const _hoisted_48 = { class: "self-stretch justify-between items-center inline-flex" }
const _hoisted_49 = { class: "justify-center items-center flex" }
const _hoisted_50 = { class: "flex-col justify-start items-center inline-flex" }
const _hoisted_51 = { class: "w-16 text-neutral-400 text-xs font-normal font-['Nunito']" }
const _hoisted_52 = { class: "justify-center items-center gap-2 flex" }
const _hoisted_53 = ["onClick"]
const _hoisted_54 = {
  key: 1,
  class: "text-slate-900 text-base font-normal font-['Nunito']"
}
const _hoisted_55 = { class: "w-20 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.paymentInfo?.payments[0])
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.dateFormat(_ctx.paymentInfo?.payments[0].startDate)) + ", " + _toDisplayString(new Date(_ctx.paymentInfo?.payments[0].startDate).getFullYear()) + " to " + _toDisplayString(_ctx.dateFormat(_ctx.paymentInfo?.payments[0].endDate)) + ", " + _toDisplayString(new Date(_ctx.paymentInfo?.payments[0].endDate).getFullYear()), 1)
              ]),
              (_ctx.paymentInfo?.payments[0])
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.formatPrice(_ctx.paymentInfo?.payments[0]?.totalAmount)), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                (_ctx.paymentInfo.payments.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentInfo.payments[0].items, (item, i) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: i,
                          class: "self-stretch justify-between items-center inline-flex"
                        }, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.dateFormat(item.created)), 1)
                            ]),
                            (item.status === 'succeeded' && item.invoiceId)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  _createElementVNode("div", _hoisted_15, _toDisplayString(item.description), 1),
                                  _createElementVNode("div", {
                                    onClick: _withModifiers(($event: any) => (_ctx.viewInvoice(item.invoiceId, item.description)), ["prevent"]),
                                    class: "w-full flex text-plan-primary-400"
                                  }, [
                                    _createElementVNode("div", _hoisted_17, [
                                      _createElementVNode("div", null, [
                                        _createElementVNode("div", _hoisted_18, [
                                          _createElementVNode("div", _hoisted_19, [
                                            _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('view-invoice')), 1)
                                          ])
                                        ])
                                      ])
                                    ])
                                  ], 8, _hoisted_16)
                                ]))
                              : (item.loadId)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                    _createElementVNode("div", {
                                      onClick: ($event: any) => (_ctx.push(item.loadId)),
                                      class: "text-slate-900 whitespace-nowrap text-base font-normal font-['Nunito']"
                                    }, _toDisplayString(item.description), 9, _hoisted_22),
                                    (item.status === 'succeeded')
                                      ? (_openBlock(), _createElementBlock("div", {
                                          key: 0,
                                          onClick: _withModifiers(($event: any) => (_ctx.viewInvoice(item.invoiceId, item.description)), ["prevent"]),
                                          class: "w-full flex text-plan-primary-400"
                                        }, [
                                          _createElementVNode("div", _hoisted_24, [
                                            _createElementVNode("div", null, [
                                              _createElementVNode("div", _hoisted_25, [
                                                _createElementVNode("div", _hoisted_26, [
                                                  _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.$t('view-invoice')), 1)
                                                ])
                                              ])
                                            ])
                                          ])
                                        ], 8, _hoisted_23))
                                      : _createCommentVNode("", true)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                    _createElementVNode("div", _hoisted_29, _toDisplayString(item.description), 1)
                                  ]))
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.formatPrice(item.amount)), 1)
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('not-payment-yet')), 1)
          ]))
    ]),
    (_ctx.paymentInfo.payments.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.$t('completed')), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentInfo.payments, (item, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "w-full",
                  key: i
                }, [
                  (i > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                        _createElementVNode("div", {
                          class: "self-stretch justify-between items-center inline-flex cursor-pointer",
                          onClick: ($event: any) => (_ctx.toggleAccordion(i))
                        }, [
                          _createElementVNode("div", _hoisted_43, [
                            _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.dateFormat(item.startDate)) + ", " + _toDisplayString(new Date(item.startDate).getFullYear()) + " to " + _toDisplayString(_ctx.dateFormat(item.endDate)) + ", " + _toDisplayString(new Date(item.endDate).getFullYear()), 1)
                          ]),
                          _createElementVNode("div", _hoisted_45, [
                            _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.formatPrice(item.totalAmount)), 1)
                          ])
                        ], 8, _hoisted_42),
                        (_ctx.activeAccordion === i)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentInfo.payments[i].items, (item, z) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: z,
                                  class: "self-stretch h-auto flex-col justify-start items-start gap-2 flex"
                                }, [
                                  _createElementVNode("div", _hoisted_48, [
                                    _createElementVNode("div", _hoisted_49, [
                                      _createElementVNode("div", _hoisted_50, [
                                        _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.dateFormat(item.created)), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_52, [
                                        (item.loadId)
                                          ? (_openBlock(), _createElementBlock("div", {
                                              key: 0,
                                              onClick: ($event: any) => (_ctx.push(item.loadId)),
                                              class: "text-slate-900 text-base font-normal font-['Nunito'] cursor-pointer"
                                            }, _toDisplayString(item.description), 9, _hoisted_53))
                                          : (_openBlock(), _createElementBlock("div", _hoisted_54, _toDisplayString(item.description), 1))
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_55, [
                                      _createElementVNode("span", null, _toDisplayString(_ctx.formatPrice(item.amount)), 1)
                                    ])
                                  ])
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}