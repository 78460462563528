
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { Field } from 'vee-validate';
import { useStore } from 'vuex';
import { EllipsisHorizontalCircleIcon } from '@heroicons/vue/24/outline';
import { XCircleIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CreditQuantityModal from '@/modules/demo/shipper/_components/CreditQuantityModal.vue';
import RequestDemoDetailModal from '@/modules/demo/shipper/_components/RequestDemoDetailModal.vue';
import Spin from '@/components/spin/AnimateBlackSpin.vue';
// Resources
import i18n from '@/resources/locales';
// Types
import { DemoStatus } from '@/modules/demo/DemoTypes';

export default defineComponent({
  name: 'RequestDemoList',
  components: {
    CreditQuantityModal,
    EllipsisHorizontalCircleIcon,
    Field,
    RequestDemoDetailModal,
    Spin,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  setup() {
    /** Global */
    const $t= i18n.global.t;
    const store = useStore();

    const dataActions = ref([
      {
        id: 1,
        classStyle: 'text-gray-700',
        name: $t('create-user'),
        callback: (rowId, buttonId) => onClickCreateUser(rowId, buttonId),
      },
      {
        id: 2,
        classStyle: 'text-gray-700',
        name: $t('accept'),
        callback: (rowId, buttonId) => onClickAccept(rowId, buttonId),
      },
      {
        id: 3,
        classStyle: 'text-red-500',
        name: $t('decline'),
        callback: (rowId, buttonId) => onClickDecline(rowId, buttonId),
      }
    ]);
    const dataDemoRequestStatus = ref([
      { status: DemoStatus.ACCEPTED, name: $t('demo-request-status.accepted') },
      { status: DemoStatus.DECLINED, name: $t('demo-request-status.declined') },
      { status: DemoStatus.REQUESTED, name: $t('demo-request-status.requested') },
      { status: DemoStatus.USER_CREATED, name: $t('demo-request-status.user-created') },
    ]);
    const dataHeaders = ref([
      { label: 'company', key: 'company' },
      { label: 'name', key: 'name' },
      { label: 'status', key: 'status' },
    ]);
    const demoRequestId = ref(-1);
    const loading = ref(false);
    const loadingFilter = ref(false);
    const loadingId = ref(-1);
    const loadingMore = ref(false);
    const loadingUserCreate = ref(false);
    const page = ref(1);
    const requestFilterByStatus = ref('');
    const rowId = ref(-1);
    const showCreditModal = ref(false);
    const showViewDetailModal = ref(false);
    const userIdSelected = ref(-1);

    /** Lifecycle */
    onBeforeMount(() => {
      apiGetDemoRequests();
      apiGetDemoRequestsTotal();
    });

    /** Computed */
    const dataDemoRequest = computed(() =>
      store.getters['DemoStore/getDemoRequestData']
    );

    const demoRequestsTotals = computed(() =>
      store.getters['DemoStore/getDemoRequestsTotals']
    );

    const hasMoreItems = computed(() => {
      return page.value * paginationMaxItems.value <= dataDemoRequest.value.length + 1;
    });

    const paginationMaxItems = computed(() => {
      return store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 30
    });

    // const demoRequestStatusType = computed(() => {
    //   return store.getters['GlobalStore/getGlobalConfig']?.DemoRequestStatus ?? [];
    // })

    /** Methods */
    const apiGetDemoRequests = () => {
      if (!loadingMore.value) {
        setLoading(true);
      }
      return store.dispatch('DemoStore/apiGetDemoRequests', {
        maxResults: paginationMaxItems.value,
        page: page.value,
        status: requestFilterByStatus.value,
      }).then(() => {
        setLoading(false);
        setLoadingFilter(false);
        setLoadingMore(false);
      }).catch(() => {
        setLoading(false);
        setLoadingFilter(false);
        setLoadingMore(false);
      });
    };

    const apiGetDemoRequestsTotal = () => {
      return store.dispatch('DemoStore/apiGetDemoRequestsTotal');
    };

    const apiPostDemoRequestsAccept = (id) => {
      return store.dispatch('DemoStore/apiPostDemoRequestsAccept', id)
        .then((response) => {
          store.dispatch('DemoStore/updateDemoRequestById', response);
          setLoadingId(-1);
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          setLoadingId(-1);
        })
    };

    const apiPostDemoRequestsDecline = (id) => {
      return store.dispatch('DemoStore/apiPostDemoRequestsDecline', id)
        .then((response) => {
          store.dispatch('DemoStore/updateDemoRequestById', response);
          setLoadingId(-1);
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          setLoadingId(-1);
        })
    };

    const apiPostUserDemoRequest = (id, creditPrice) => {
      setLoadingUserCreate(true);
      return store.dispatch('UserStore/apiPostCreateUserDemoRequests', id)
        .then((response) => {
          store.dispatch('DemoStore/updateDemoRequestStatusById', {
            id: id,
            newStatus: DemoStatus.USER_CREATED,
          });
          const companyId = response?.company?.id;
          const price = Number(creditPrice) >= 0 ? Number(creditPrice) : 0;
          apiPutCompanyCreditGranted(companyId, price);
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          setLoadingUserCreate(false);
        });
    };

    const apiPutCompanyCreditGranted = (companyId, creditPrice) => {
      const params = { id: companyId, creditGranted: creditPrice };
      return store.dispatch('CompanyStore/apiPutCompanyCreditGranted', params)
        .then(() => {
          BaseComponent.methods?.showToastSuccess($t('user-created'));
          setLoadingUserCreate(false);
          callbackCreditModalClose();
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          setLoadingUserCreate(false);
        });
    };

    const callbackCreditModalClose = () => showCreditModal.value = false;

    const callbackCreditModalSend = (creditPrice) => {
      apiPostUserDemoRequest(userIdSelected.value, creditPrice);
    };

    const callbackViewDetailModalClose = () => showViewDetailModal.value = false;

    const getDemoRequestStatusName = (status) => {
      const result = dataDemoRequestStatus.value.find((item) => item.status === status);
      return result ? result.name : '-';
    };

    const getDemoRequestStatusStyle = (status) => {
      switch (status) {
        case DemoStatus.ACCEPTED:
          return 'text-green-500';
        case DemoStatus.DECLINED:
          return 'text-red-500';
        case DemoStatus.REQUESTED:
          return 'text-slate-400';
        case DemoStatus.USER_CREATED:
          return 'text-blue-500';
        default:
          return '';
      }
    };

    const isActionVisible = (status, buttonId) => {
      const buttonCreateUser = 1;
      const buttonAccept = 2;
      const buttonDecline = 3;

      if (status === DemoStatus.USER_CREATED) {
        return false;
      }

      if (buttonId === buttonCreateUser && status === DemoStatus.ACCEPTED) {
        return true;
      }

      if ((buttonId === buttonAccept) &&
        (status === DemoStatus.DECLINED || status === DemoStatus.REQUESTED)) {
        return true;
      }

      return (buttonId === buttonDecline) &&
        (status === DemoStatus.ACCEPTED || status === DemoStatus.REQUESTED);
    };

    const loadMore = () => {
      page.value += 1;
      setLoadingMore(true);
      apiGetDemoRequests();
    };

    const onClickAccept = (rowId, buttonId) => {
      setLoadingId(buttonId);
      setRowId(rowId);
      apiPostDemoRequestsAccept(rowId);
    };

    const onClickClearRequestFilter = () => {
      requestFilterByStatus.value = '';
      setLoadingFilter(true);
      apiGetDemoRequests();
    };

    const onClickCreateUser = (rowId, buttonId) => {
      showCreditModal.value = true;
      userIdSelected.value = rowId;
    };

    const onClickDecline = (rowId, buttonId) => {
      setLoadingId(buttonId);
      setRowId(rowId);
      apiPostDemoRequestsDecline(rowId);
    };

    const onClickRow = (rowId) => {
      showViewDetailModal.value = true;
      // apiGetDemoRequestById(rowId);
      demoRequestId.value = rowId;
    };

    const onClickViewDetail = (event, id) => {
      event.stopPropagation();
      showViewDetailModal.value = true;
      demoRequestId.value = id;
      // apiGetDemoRequestById(id);
    };

    const onSelectStatus = () => {
      setLoadingFilter(true);
      apiGetDemoRequests();
    };

    const setLoading = (value) => loading.value = value;

    const setLoadingFilter = (value) => loadingFilter.value = value;

    const setLoadingId = (value) => loadingId.value = value;

    const setLoadingMore = (value) => loadingMore.value = value;

    const setLoadingUserCreate = (value) => loadingUserCreate.value = value;

    const setRowId = (value) => rowId.value = value;

    return {
      dataActions,
      dataDemoRequest,
      dataDemoRequestStatus,
      dataHeaders,
      demoRequestId,
      demoRequestsTotals,
      hasMoreItems,
      loading,
      loadingFilter,
      loadingId,
      loadingMore,
      loadingUserCreate,
      requestFilterByStatus,
      rowId,
      showCreditModal,
      showViewDetailModal,
      callbackCreditModalClose,
      callbackCreditModalSend,
      callbackViewDetailModalClose,
      getDemoRequestStatusName,
      getDemoRequestStatusStyle,
      isActionVisible,
      loadMore,
      onClickAccept,
      onClickClearRequestFilter,
      onClickCreateUser,
      onClickDecline,
      onClickRow,
      onClickViewDetail,
      onSelectStatus,
    }
  },
});
