import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex justify-center items-center plan-gap-sm header-notification-sec", {
          'header-notification-prim': _ctx.type === 'prim',
          'header-notification-error': _ctx.type === 'error',
          'header-notification-warning': _ctx.type === 'warning',
          'header-notification-success': _ctx.type === 'success',
       }]),
    style: {"margin-bottom":"-16px","margin-left":"-32px"}
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["w-full flex justify-center items-center plan-gap-sm", _ctx.actualWidth <= 640 ? 'flex-col text-center':''])
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.message), 1),
      _renderSlot(_ctx.$slots, "buttons")
    ], 2)
  ], 2))
}