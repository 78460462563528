
import { defineComponent } from 'vue';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
import PlanDefaultCityInformation from '@/components/defaults/PlanDefaultCityInformation.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  components: {
    PlanButton,
    PlanDefaultCityInformation,
    PlanIcon,
  },
  mixins: [BaseComponent],
  props: {
    notifyModalVisible: Boolean,
    openInvoicesVisible: Boolean,
  },
  data() {
    return {
      openInvoices: false,
      mediaObjectUrls: [] as any,
    };
  },

  mounted() {
    if (this.loadHasMediaObjects) {
      this.currentLoad?.mediaObjects.forEach(async (mediaObject: any) => {
        await this.getMediaObjectOfAdditionalFee(mediaObject);
      });
    }
  },
  computed: {
    loadHasMediaObjects(): any {
      if (
        this.currentLoad &&
        this.currentLoad?.mediaObjects?.length &&
        typeof this.currentLoad?.mediaObjects?.length !== 'undefined'
      ) {
        return true;
      } else {
        return null;
      }
    },
    commodityMasters(): any {
      let totalWeight = 0;
      let totalWeightStr = this.$t('total-weight');

      const commodityString = this.shipment?.loads[0]?.commodityMasters
        .map((item) => {
          totalWeight += item.weight;
          return `${item.commodityName} ${this.formatWeight(item.weight)}`;
        })
        .join(', ');

      return `${commodityString} - ${totalWeightStr}: ${this.formatWeight(`${totalWeight}`)}`;
    },

    assignedPrice(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.assignedCompany.price !== 'undefined'
      ) {
        return this.shipment.assignedCompany.price;
      } else {
        return null;
      }
    },
    bidAccepted(): any {
      return this.shipment?.assignedCompany?.company?.id;
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    deliverCount(): any {
      if (!this.shipment.loads) {
        return '';
      }
      return this.shipment.loads[0].stops.filter((stop) => stop.type === 'DELIVERY').length + 1;
    },
    deliverFinalStop(): any {
      if (!this.shipment.loads) {
        return '';
      }
      const initialStop = this.shipment.loads[0].stops.find(
        (stop) => stop.type === 'DELIVERY_FINAL',
      );
      return initialStop ? initialStop : '';
    },
    isDriver(): any {
      return this.$route.path.includes('driver')
    },
    isOpenChat(): any {
      return this.$store.state.GlobalStore.isOpenChat;
    },
    isRead(): any {
      const member = this.currentLoad?.chat?.chatMembers.find(
        (member) => member.user.id === this.currentUser?.id,
      );
      return member ? member.readed : true;
    },
    pickupInitialStop(): any {
      if (!this.shipment.loads) {
        return '';
      }
      const initialStop = this.shipment.loads[0].stops.find(
        (stop) => stop.type === 'PICKUP_INITIAL',
      );
      return initialStop ? initialStop : '';
    },
    pickUpsCount(): any {
      if (!this.shipment.loads) {
        return '';
      }
      return this.shipment.loads[0].stops.filter((stop) => stop.type === 'PICKUP').length + 1;
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
  },
  methods: {
    async getMediaObjectOfAdditionalFee(mediaobject) {
      const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
        id: mediaobject.id,
        class: 'LOAD',
      });

      this.mediaObjectUrls.push({
        url: URL.createObjectURL(result),
        name: mediaobject.name,
        id: mediaobject.id,
      });
    },
    async addMemberToChatLoad(chatId) {
      await this.$store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    },
    async downloadRC() {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: this.shipment?.mediaObjects[0]?.id,
        class: 'SHIPMENT',
      });

      const url = URL.createObjectURL(response);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${this.shipment.id}`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
    async openChat() {
      if (this.currentLoad.status === 'QUOTING') {
        this.openChatQuoting();
      } else {
        const member = this.currentLoad?.chat?.chatMembers.find(
          (member) => member.user.id === this.currentUser?.id,
        );
        if (member) {
          member.readed = true;
        } else {
          if (this.currentLoad?.chat?.id) {
            await this.addMemberToChatLoad(this.currentLoad.chat.id);
          }
        }
        this.$store.commit('LoadsStore/loadId', this.currentLoad.id);
        this.$store.commit(
          'ChatsStore/setChatId',
          this.currentLoad.chat ? this.currentLoad.chat.id : null,
        );
        this.$store.commit('GlobalStore/setOpenChat', true);
      }
    },
    openChatQuoting() {
      if (!this.isOpenChat) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        const findChat = this.currentLoad.quotingChats.find((chat) =>
          chat.chatMembers.some((member) => member.user.id === this.currentUser?.id),
        );
        const memberMe = findChat.chatMembers.find(
          (member) => member.user.id === this.currentUser?.id,
        );
        const member = findChat.chatMembers.find(
          (member) => member.user.id !== this.currentUser?.id,
        );
        if (memberMe) memberMe.readed = true;
        setTimeout(() => {
          this.$store.commit('LoadsStore/loadId', this.currentLoad.id);
          this.$store.commit('ChatsStore/setUserForChat', member);
          this.$store.commit('ChatsStore/setChatId', findChat ? findChat.id : null);
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    showNotifyModal() {
      this.$emit('showNotifyModal', !this.notifyModalVisible);
    },
    toggleInvoices() {
      this.$emit('toggleInvoices', !this.openInvoicesVisible);
    },
  },
});
