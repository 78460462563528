
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardSkeleton',
  props: {
    totalRows: {
      type: Number,
      default: 1,
    },
  },
});
