
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanButtonGroup from '@/components/buttons/PlanButtonGroup.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import * as Routing from '@/router/Routing';

export default defineComponent({
  name: 'LoadHeaderButtons',
  components: { PlanButton, PlanButtonGroup },
  emits: ['changeDisplayMode'],
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      activeButton: store.getters['LoadsStore/getPlanningType'] === 'cards' ? 0 : 1
    })

    const openNewFtlLoad = () => {
      store.commit('LoadsStore/loadId', null);
      Routing.openNewFtlLoad();
    };

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const userRegion = computed(() => {
      return currentUser.value.company?.country?.region?.name;
    });

    const activeDisplayMode = computed(() => {
      return store.getters['LoadsStore/getPlanningType']
    })

    const changeDisplayMode = (index: number) => {
      data.activeButton = index
      store.commit('LoadsStore/setPlanningType', index === 0 ? 'cards' : 'calendar')
      emit('changeDisplayMode', index === 0 ? 'cards' : 'calendar');
    };

    watch(store.getters['LoadsStore/getPlanningType'], (newValue, oldValue) => {
      data.activeButton = store.getters['LoadsStore/getPlanningType'] === 'cards' ? 0 : 1
      emit('changeDisplayMode', data.activeButton === 0 ? 'cards' : 'calendar')
    })

    return {
      ...toRefs(data),
      userRegion,
      Routing,
      activeDisplayMode,
      changeDisplayMode,
      openNewFtlLoad,
    };
  },
});
