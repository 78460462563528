
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'PlanCarrierLoadAcceptationStep',
  props: {
    isActive: { type: Boolean, required: true, default: false },
    withoutLeft: { type: Boolean, required: false, default: false },
    withoutRight: { type: Boolean, required: false, default: false },
    stepData: { type: String, required: true, default: '' }
  },
  setup () {
    const store = useStore()

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const planCarrierLoadAcceptationStepWidth = computed(() => {
      const widthToShow = actualWidth.value <= 450 ? '125px' : '150px'

      return {
        width: widthToShow
      }
    })

    return {
      actualWidth,
      planCarrierLoadAcceptationStepWidth
    }
  }
})
