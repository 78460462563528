
import { defineComponent } from 'vue';
// Components
import ChangePlanModal from '@/modules/planning/newLoad/_components/ChangePlanModal.vue';
import NoCreditModal from '@/modules/credit/_components/modals/NoCreditModal.vue';
import PlanimatikCreditModal from '@/modules/credit/_components/modals/PlanimatikCreditModal.vue';
import Sidebar from '@/components/sidebar/Sidebar.vue';
import SidebarChat from '@/components/sidebar/ChatSidebar.vue';
import WelcomeTrialModal from '@/components/modals/WelcomeTrialModal.vue';
import ImpersonatingBar from '@/components/sidebar/ImpersonatingBar.vue';
// Types
import { UserRoles } from '@/services/user/UserTypes';
import { notificationType } from '@/services/notification/NotifiactionTypes';

export default defineComponent({
  name: 'PlanningView',
  components: {
    ChangePlanModal,
    ImpersonatingBar,
    NoCreditModal,
    Sidebar,
    PlanimatikCreditModal,
    SidebarChat,
    WelcomeTrialModal,
  },
  data() {
    return {
      sideBarHidden: true,
      updateKey: 0,
    };
  },
  computed: {
    currentRouteName(): any {
      return this.$route.name;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    entryNotifications(): any {
      return this.$store.getters['NotificationStore/getEntryNotifications'];
    },
    isGrantedCreditModal(): any {
      return this.$store.getters['CreditStore/getGrantedCreditModal'];
    },
    isLoadDetailRoute(): any {
      return this.$route.name === 'planificationDetail';
    },
    isNoCredit(): any {
      return this.$store.getters['CreditStore/getNoCreditModal'];
    },
    isOpenChat(): any {
      return this.$store.state.GlobalStore.isOpenChat;
    },
    isPlanimatikCreditModal(): any {
      return this.$store.getters['CreditStore/getPlanimatikCreditModal'];
    },
    isWelcomeOpen(): any {
      return this.$store.getters['DemoStore/getIsWelcomeOpen'];
    },
    showModalPlans(): any {
      return this.$store.getters['UserStore/getShowModalPlans'];
    },
  },
  watch: {
    currentUser(): any {
      this.checkUserType();
    },
  },
  beforeCreate() {
    this.$store.dispatch('ChatsStore/getTotalUnreadChats');
  },
  created() {
    this.$store.dispatch('LoadsStore/states');
    this.$store.dispatch('CountryStore/getCountries').then(() => {
      this.$store.dispatch('CountryStore/getCountryById', this.currentUser?.company?.country?.id);
    });
    this.$store.dispatch('PusherStore/initializePusher');
    this.$store.dispatch('PaymentStore/paymentMethod');
    this.$store
      .dispatch('LoadsStore/trailers')
      .then()
      .catch((err) => console.log(err));
    this.checkUserType();
    this.getCredit();
    this.getEntryNotifications();
  },
  methods: {
    finishSuplanting() {
      this.$store.commit('UserStore/setUserIdentification', null);
      this.$router.push({ name: 'shippersList' });
    },
    checkUserType() {
      if (this.currentUser && this.currentUser.roles) {
        let isShipper = false;
        this.currentUser.roles.forEach((role) => {
          if (role.includes(UserRoles.SHIPPER) || role.includes(UserRoles.ADMIN)) {
            isShipper = true;
          }
        });
        if (
          !isShipper &&
          this.$route.path.includes('planning') &&
          this.$route.name !== 'planificationDetailToken'
        ) {
          this.$router.push('/signin');
        }
      }
    },
    forceUpdate() {
      this.updateKey += 1;
    },
    getCredit() {
      this.$store.dispatch('CreditStore/getCompanyCredit', this.currentUser?.company?.id);
    },
    getEntryNotifications() {
      this.$store.dispatch('NotificationStore/getEntryNotifications').then(() => {
        this.entryNotifications.forEach((notification) => {
          switch (notification.type) {
            case notificationType.CREDIT_UPDATED:
            case notificationType.GRANTED_CREDIT:
              this.$store.commit('CreditStore/setGrantedCreditInfo', notification.info);
              this.$store.commit('CreditStore/setGrantedCreditModal', true);
              break;
            case notificationType.TRIAL_ENDING:
            case notificationType.TRIAL_WELCOME:
              this.$store.commit('DemoStore/setIsWelcomeOpen', true);
              break;
          }
        });
        if (this.currentUser?.company?.accessPlanType === 'TRIAL_EXPIRED') {
          this.$store.commit('DemoStore/setIsWelcomeOpen', true);
        }
        if (this.currentUser?.company?.accessPlanType === 'SUBSCRIPTION_TERMINATED') {
          this.$store.commit('DemoStore/setIsWelcomeOpen', true);
        }
        if (this.currentUser?.company?.accessPlanType === 'SUBSCRIPTION_NOT_PAID') {
          this.$store.commit('DemoStore/setIsWelcomeOpen', true);
        }
      });
    },
    toggleChangePlanModal(bool: boolean) {
      this.$store.commit('UserStore/setShowModalPlans', bool);
    },
    toggleSideBarHidden() {
      this.sideBarHidden = !this.sideBarHidden;
    },
  },
});
