import token from '@/modules/auth/_api';
import store from '@/services/store';
import jwtDecode from 'jwt-decode';

export default {
  async autoLogin(context: { dispatch: any }) {
    const token = localStorage.getItem('token');
    if (!token) {
      await context.dispatch('logout');
      return false;
    }
    return true;
  },
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    // Clear stores
    store.dispatch('CompanyStore/clearCompanyStore');
    store.dispatch('CountryStore/clearCountryStore');
    store.dispatch('CreditStore/clearCreditStore');
    store.dispatch('CustomerStore/clearCustomerStore');
    store.dispatch('DemoStore/clearDemoStore');
    store.dispatch('GlobalStore/clearGlobalStore');
    store.dispatch('LanesStore/clearLanesStore');
    store.dispatch('LoadsStore/clearLoadsStore');
    store.dispatch('NotificationStore/clearNotificationStore');
    store.dispatch('PaymentStore/clearPaymentStore');
    store.dispatch('QuotationRequestStore/clearQuotationRequestStore');
    store.dispatch('PusherStore/clearPusherStore');
    store.dispatch('RFPStore/clearRFPStore');
    store.dispatch('ShipmentStore/clearShipmentStore');
    store.dispatch('UserStore/clearUserStore');

    // TODO: messages of logout
  },
  async refreshToken(context: object, refreshToken: string) {
    const response = await token.refreshToken(refreshToken);
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('refresh_token', response.data.refresh_token);
    store.dispatch('PusherStore/initializePusher');
    store.dispatch('GlobalStore/apiGetGlobalConfig');
    store.dispatch('UserStore/currentUser');
    return response;
  },
  async signIn(context: object, user: object) {
    const response = await token.postSignin(user);
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('refresh_token', response.data.refresh_token);
    store.dispatch('PusherStore/initializePusher');
    store.dispatch('GlobalStore/apiGetGlobalConfig');
    await store.dispatch('UserStore/currentUser');
    const responseToken = jwtDecode(response.data.token) as any
    store.commit('UserStore/setRegion', responseToken.region)

    return response;
  },
};
