import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "wire:loading": "",
  class: "fixed top-0 left-0 right-0 bottom-0 w-full h-full z-50 overflow-hidden text-white bg-gray-700 opacity-75 flex flex-col items-center justify-center"
}
const _hoisted_2 = { class: "text-center text-white text-xl font-semibold" }
const _hoisted_3 = { class: "w-1/3 text-center text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spin = _resolveComponent("Spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Spin, { class: "loader ease-linear h-12 w-12 mb-4" }),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('loading')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('this-may-take-a-few-seconds')), 1)
  ]))
}