import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "load-card-tag" }
const _hoisted_2 = { class: "flex flex-wrap gap-1 md:gap-0" }
const _hoisted_3 = { class: "load-card-tag-left" }
const _hoisted_4 = { class: "flex justify-start items-center gap-2" }
const _hoisted_5 = {
  key: 1,
  class: "tag-ltl"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "load-card-tag-show-ltl-info"
}
const _hoisted_8 = {
  key: 0,
  class: "load-card-tag-show-ltl-info"
}
const _hoisted_9 = {
  class: "truncate",
  style: {"max-width":"100px"}
}
const _hoisted_10 = {
  key: 1,
  class: "flex justify-start items-center gap-1"
}
const _hoisted_11 = {
  key: 0,
  class: "inline-block bg-red-500 rounded-md px-2 py-1 md:mx-2"
}
const _hoisted_12 = { class: "font-sans text-white text-xs m-0" }
const _hoisted_13 = {
  key: 0,
  class: "load-card-tag-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.loadType === 'FTL')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["tag-ftl flex flex-row", { '!w-fit px-2 pb-0.5 gap-1': _ctx.source === 'RFP' }])
              }, [
                (_ctx.source === 'RFP')
                  ? (_openBlock(), _createBlock(_component_plan_icon, {
                      key: 0,
                      name: "rfp-lanes",
                      "is-svg": "",
                      "icon-color": "#000000",
                      size: "13"
                    }))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(`${_ctx.source === 'RFP' ? 'RFP ' : ''} FTL`), 1)
              ], 2))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, " LTL ")),
          _createElementVNode("p", {
            class: _normalizeClass(["m-0", !_ctx.rfpInfo.length && _ctx.rfpPo ? 'flex' : ''])
          }, [
            _createTextVNode(" #" + _toDisplayString(_ctx.loadId) + " ", 1),
            (_ctx.loadType === 'Alpine_LTL' && _ctx.ltlIdentifier)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, " PRO #" + _toDisplayString(_ctx.ltlIdentifier), 1))
              : _createCommentVNode("", true),
            (!_ctx.rfpInfo.length && _ctx.rfpPo)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                  _createVNode(_component_plan_icon, {
                    name: "chevron-right",
                    "is-svg": "",
                    "icon-color": "#8A8B97",
                    size: "16"
                  }),
                  _createTextVNode(" PO " + _toDisplayString(_ctx.rfpPo), 1)
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        (_ctx.rfpInfo.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewProjectDetail())),
                class: "flex justify-start items-center gap-1"
              }, [
                _createVNode(_component_plan_icon, {
                  name: "rfp-lanes",
                  "is-svg": "",
                  "icon-color": "#8A8B97",
                  size: "16"
                }),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.rfpInfo), 1)
              ]),
              (_ctx.rfpLane.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.viewLaneDetail())),
                    class: "flex justify-start items-center gap-1"
                  }, [
                    _createVNode(_component_plan_icon, {
                      name: "chevron-right",
                      "is-svg": "",
                      "icon-color": "#8A8B97",
                      size: "16"
                    }),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('lane')) + " " + _toDisplayString(_ctx.rfpLane), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.rfpPo.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_plan_icon, {
                      name: "chevron-right",
                      "is-svg": "",
                      "icon-color": "#8A8B97",
                      size: "16"
                    }),
                    _createElementVNode("p", null, "PO " + _toDisplayString(_ctx.rfpPo), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.multiStopData.loadBatchAmount && _ctx.multiStopData.loadBatchSequence)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('multiple-load')) + " (" + _toDisplayString(_ctx.multiStopData.loadBatchSequence) + "/" + _toDisplayString(_ctx.multiStopData.loadBatchAmount) + ") ", 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.rightItems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rightItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item,
              class: "right-item"
            }, _toDisplayString(item), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}