
import { defineComponent } from 'vue';
import { StarIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  components: {
    StarIcon,
  },
  mixins: [BaseComponent],
  props: {
    quotes: Object,
  },
  data() {
    return {};
  },
  computed: {
    currentLane(): any {
      return this.$store.getters['RFPStore/getCurrentLane'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    isOpenChat(): any {
      return this.$store.getters['GlobalStore/isOpenChat'];
    },
  },
  methods: {
    isReaded(user): any {
      return user.lanesChatMemberForShipper?.readed ?? true;
    },
    async openChat(event, ranking) {
      event.stopPropagation();
      if (!this.isOpenChat) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        if (ranking.lanesChatMemberForShipper) {
          ranking.lanesChatMemberForShipper.readed = true;
        }
        setTimeout(() => {
          this.$store.commit('ChatsStore/setRankingForChat', ranking);
          this.$store.commit('ChatsStore/setUserForChat', {
            owner: { id: this.currentUser?.id },
          });
          this.$store.commit('ChatsStore/setLaneForChat', this.currentLane);
          this.$store.commit(
            'ChatsStore/setChatId',
            ranking?.lanesChatMemberForShipper?.chat?.id ?? null,
          );
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
  },
});
