import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-start items-start plan-gap-md" }
const _hoisted_2 = { class: "w-full flex justify-between items-end plan-gap-md" }
const _hoisted_3 = { class: "w-full flex flex-col" }
const _hoisted_4 = { class: "plan-form-label" }
const _hoisted_5 = {
  key: 0,
  class: "plan-div-form flex justify-start items-center plan-t-prim-100 plan-gap-sm"
}
const _hoisted_6 = { class: "flex justify-center items-center plan-pl-sm" }
const _hoisted_7 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_template_category_modal = _resolveComponent("add-template-category-modal")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_input_text_area = _resolveComponent("plan-input-text-area")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showNewCategoryName)
      ? (_openBlock(), _createBlock(_component_add_template_category_modal, {
          key: 0,
          onChange: _ctx.update,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewCategoryName = false)),
          onNewCategory: _ctx.selectCategory
        }, null, 8, ["onChange", "onNewCategory"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('category')) + " *", 1),
        (_ctx.loadingCategories)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_plan_icon, {
                  name: "spinner",
                  "icon-color": "#8A8B97",
                  "is-svg": "",
                  rotate: ""
                })
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('loading')), 1)
            ]))
          : _withDirectives((_openBlock(), _createElementBlock("select", {
              key: 1,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.category) = $event)),
              onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
              class: "plan-form w-full"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: category.value,
                  value: category.value
                }, _toDisplayString(category.label), 9, _hoisted_7))
              }), 128))
            ], 544)), [
              [_vModelSelect, _ctx.category]
            ])
      ]),
      _createVNode(_component_plan_button, {
        onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showNewCategoryName = true)),
        "show-icon": "",
        icon: "plus",
        "icon-color": "#0097FF",
        "icon-size": "15",
        type: "primary-white",
        label: _ctx.$t('add-new-category')
      }, null, 8, ["label"])
    ]),
    _createVNode(_component_plan_input_text, {
      onChange: _ctx.update,
      "model-value": _ctx.templateName,
      "onUpdate:model-value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.templateName) = $event)),
      label: _ctx.$t('template-name'),
      placeholder: _ctx.$t('template-name-placeholder'),
      mandatory: ""
    }, null, 8, ["onChange", "model-value", "label", "placeholder"]),
    _createVNode(_component_plan_input_text, {
      onChange: _ctx.update,
      "model-value": _ctx.subject,
      "onUpdate:model-value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.subject) = $event)),
      label: _ctx.$t('subject'),
      placeholder: _ctx.$t('subject-placeholder'),
      mandatory: ""
    }, null, 8, ["onChange", "model-value", "label", "placeholder"]),
    _createVNode(_component_plan_input_text_area, {
      onChange: _ctx.update,
      "model-value": _ctx.message,
      "onUpdate:model-value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.message) = $event)),
      rows: 8,
      placeholder: _ctx.$t('message')
    }, null, 8, ["onChange", "model-value", "placeholder"])
  ]))
}