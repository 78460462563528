import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-xs" }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-sm font-bold" }
const _hoisted_3 = { class: "flex justify-start items-center plan-gap-sm plan-t-prim-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex plan-gap-sm", 
      _ctx.actualWidth <= 640 ? 'flex-col justify-start items-end' : 'justify-between items-center'
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(_ctx.currentProject.project?.periodStartDate)), 1),
        _createVNode(_component_plan_icon, {
          name: "arrow-right",
          "icon-color": "black",
          "is-svg": ""
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(_ctx.currentProject.project?.periodEndDate)), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('total-lanes', {
              lanes:
                _ctx.getLanesAndQuotesForCarrier?.length >= 1
                  ? _ctx.currentProject.computed?.lanes?.totalAmount
                  : 0,
            })) + ", " + _toDisplayString(_ctx.$t('total-trucks', {
              trucks:
                _ctx.getLanesAndQuotesForCarrier?.length >= 1
                  ? _ctx.getLanesAndQuotesForCarrier[_ctx.getLanesAndQuotesForCarrier.length - 1].lane
                      .computed.totalTrucks
                  : 0,
            })), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center plan-gap-sm", _ctx.actualWidth <= 640 ? 'justify-end' : 'justify-center'])
    }, [
      _createVNode(_component_plan_avatar, {
        "user-name": 
          _ctx.currentProject?.project?.shipperOwner?.name +
          ' ' +
          _ctx.currentProject?.project?.shipperOwner?.surname
        ,
        description: _ctx.currentProject?.project?.shipperOwner?.company?.name
      }, null, 8, ["user-name", "description"]),
      _createVNode(_component_plan_button_icon, {
        onOnClick: _ctx.openChat,
        icon: "chat",
        type: "transparent-gray-800"
      }, null, 8, ["onOnClick"])
    ], 2)
  ], 2))
}