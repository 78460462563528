import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-start items-start mt-2" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-wrap justify-between items-start gap-2 overflow-auto w-full",
  style: {"max-height":"200px"}
}
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.thumbnails.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.thumbnails, (mediaObjectUrl) => {
            return (_openBlock(), _createElementBlock("a", {
              key: mediaObjectUrl,
              href: mediaObjectUrl,
              target: "_blank"
            }, [
              _createElementVNode("div", {
                style: _normalizeStyle(`width: 150px; height: 50px; background: url(${mediaObjectUrl}); background-size: cover; background-position: center;`),
                class: "border-plan-primary-100 hover:border-plan-primary-200 cursor-pointer border-4 rounded-lg transition-all duration-3"
              }, null, 4)
            ], 8, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}