
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
// Base
import BaseComponent from '@/base/BaseComponent';
import { ref, computed, watchEffect, watch } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import i18n from '@/resources/locales';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
export default {
  mixins: [BaseComponent],
  components: { BaseModal, PlanButton },
  props: {
    open: Boolean,
    loadTemplateId: Number,
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const $t = i18n.global.t;
    const inputText = ref('');
    const loading = ref(false);
    const onClickClose = () => {
      emit('close');
    };

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const sendTemplateEmail = () => {
      if (inputText.value === '') {
        BaseComponent.methods?.showToastError($t('new-template-empty'));
        return;
      }

      loading.value = true;

      store
        .dispatch('LoadsStore/postSaveLoads', {
          load: { id: props?.loadTemplateId || route.params.id },
          user: { id: currentUser.value.id },
          templateName: inputText.value,
        })
        .then(() => {
          store.dispatch('LoadsStore/getSaveLoads', { page: 1 });
          BaseComponent.methods?.showToastSuccess($t('message-load-template-created'));
          emit('close');
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return { onClickClose, loading, inputText, sendTemplateEmail };
  },
};
