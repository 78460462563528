import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-filter-pickup-and-delivery" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_filter_pickup_and_delivery_location = _resolveComponent("plan-filter-pickup-and-delivery-location")!
  const _component_plan_filter_pickup_and_delivery_date = _resolveComponent("plan-filter-pickup-and-delivery-date")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_filter_pickup_and_delivery_location, {
      onStatus: $setup.changeStatus,
      id: "PICKUP_INITIAL_CITY",
      title: _ctx.$t('city-zip-state-country')
    }, null, 8, ["onStatus", "title"]),
    _createVNode(_component_plan_filter_pickup_and_delivery_location, {
      onStatus: $setup.changeStatus,
      id: "DELIVERY_FINAL_CITY",
      delivery: "",
      title: _ctx.$t('city-zip-state-country')
    }, null, 8, ["onStatus", "title"]),
    _createVNode(_component_plan_filter_pickup_and_delivery_date, {
      onStatus: $setup.changeStatus,
      id: "pickup-date",
      title: "City"
    }, null, 8, ["onStatus"]),
    _createVNode(_component_plan_filter_pickup_and_delivery_date, {
      onStatus: $setup.changeStatus,
      id: "delivery-date",
      delivery: "",
      title: "City"
    }, null, 8, ["onStatus"])
  ]))
}