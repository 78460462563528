import { initialState } from '@/services/company/_store/index';

export default {
  clearCompanyStore(state: any) {
    Object.assign(state, initialState);
  },
  clearSelectedEmailToSend (state: any) {
    state.selectedEmailToSend = null
  },
  clearSelectedTemplate (state: any) {
    state.selectedTemplate = null
  },
  clearSelectedTemplateCategory (state: any) {
    state.selectedTemplateCategory = null
  },
  clearSelectedTemplateToSend (state: any) {
    state.selectedTemplateToSend = null
  },
  clearTemplateToClone (state: any) {
    state.templateToClone = null
  },
  searchCompany (state: any, query: string) {
    state.searchCompany = query
  },
  setAdminAgents(state: any, payload: object) {
    state.adminAgents = payload;
  },
  setCompany(state: any, payload: object) {
    state.company = payload;
  },
  setCompanyById(state: any, payload: object) {
    state.companyById = payload;
  },
  updatingInsurances(state: any, payload: object) {
    state.updatingInsurances = payload;
  },
  setCompaniesList(state: any, payload: any) {
    if (payload.params.page === 1) {
      state.companiesList = payload.response;
    } else {
      state.companiesList.push(...payload.response);
    }
  },
  setCompaniesTotals(state: any, payload: object) {
    state.companiesTotals = payload;
  },
  setContactsBlastEmail(state: any, payload: object) {
    state.contactsBlastEmail = payload;
  },
  setDriversList(state: any, payload: object) {
    state.driversList = payload;
  },
  setTemplateToClone (state: any, template: any) {
    state.templateToClone = template
  },
  selectEmailToSend (state: any, template: any) {
    state.selectedEmailToSend = template
  },
  selectTemplate (state: any, template: any) {
    state.selectedTemplate = template
  },
  selectTemplateCategory (state: any, category: any) {
    state.selectedTemplateCategory = category
  },
  selectTemplateToSend (state: any, template: any) {
    state.selectedTemplateToSend = template
  },
  setVehiclesList(state: any, payload: object) {
    state.vehiclesList = payload;
  },
};
