
import { defineComponent, computed } from 'vue';
import moment from 'moment';
export default defineComponent({
  name: 'LoadCardInitialAndFinalStop',
  props: {
    pickupCity: { type: String, required: true, default: '' },
    pickupCt: { type: String, required: true, default: '' },
    pickupIsoCode: { type: String, required: true, default: '' },
    pickupDate: { type: String, required: true, default: '' },
    deliveryCity: { type: String, required: true, default: '' },
    deliveryCt: { type: String, required: true, default: '' },
    deliveryIsoCode: { type: String, required: true, default: '' },
    deliveryDate: { type: String, required: true, default: '' },
    dateTimeEta: { type: String, required: false, default: '' },
  },
  setup (props) {
    const formattedDeliveryDate = computed(() => {
      return {
        date: moment(props.deliveryDate).format('MMM DD, YYYY'),
        hour: ''
      }
    })

    const formattedPickupDate = computed(() => {
      return {
        date: moment(props.pickupDate).format('MMM DD, YYYY'),
        hour: ''
      }
    })

    const formattedDateTimeEta = computed(() => {
      if (props.dateTimeEta === '') return ''

      return {
        date: moment(props.dateTimeEta).format('MMM DD, YYYY'),
        hour: ''
      }
    })

    return {
      formattedDeliveryDate,
      formattedPickupDate,
      formattedDateTimeEta
    }
  }
})
