
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onUnmounted,
  reactive,
  toRefs,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
// Base
import BaseComponent from '@/base/BaseComponent';

import PlanButtonGroup from '@/components/buttons/PlanButtonGroup.vue';

// Utils
import { formatDate } from '@/utils/formatDates';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  mixins: [BaseComponent],
  components: { PlanIcon, PlanButtonGroup },
  emits: ['changeView', 'fetchCalendarByLane', 'cleanFilters'],
  props: {
    projectId: Number,
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      activeButton: store.getters['RFPStore/getPreviousStatusProject'] === 'table' ? 0 : 1,
      showSelectItems: false,
      id: 'plan-select-warehouse-popper' + Math.random().toString(36).substr(2, 9),
      selectWidth: 0,
      selectedLane: null,
      laneFilteringTotalLoads: null,
    });

    const handleClickOutside = (event) => {
      const popperElement = document.querySelector('#' + data.id);
      if (popperElement && !popperElement.contains(event.target)) closePopper();
    };

    const closePopper = () => {
      data.showSelectItems = false;
    };

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    onBeforeMount(async () => {
      document.addEventListener('click', handleClickOutside);
      changeWidth();

      await store
        .dispatch('RFPStore/getLaneListFilter', {
          projectId: route.params.projectId,
          query: 'ASC',
        })
        .then(() => {
          changeWidth();
        });
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    const currentLane = computed(() => {
      return store.getters['RFPStore/getCurrentLane'];
    });

    const changeWidth = () => {
      const planSelectWarehousePopper = document.getElementById(data.id);

      nextTick(() => {
        const planLaneList = document.getElementById('lanesList');
        if (planLaneList) {
          nextTick(() => {
            data.selectWidth = planLaneList.offsetWidth;
          });
        }
      });
    };

    const lanesListFilter = computed(() => {
      return store.getters['RFPStore/getLanesListFilter'];
    });

    const handleChange = () => {
      emit('changeView', store.getters['RFPStore/getPreviousStatusProject']);
    };
    const formattedDate = computed((date) => {
      return formatDate;
    });

    const showLanes = () => {
      data.showSelectItems = true;
    };

    const currentView = computed(() => {
      return store.getters['RFPStore/getPreviousStatusProject'];
    });

    const currentProjectLoaded = computed(() => {
      if (currentProject.value) {
        let { project } = currentProject.value;
        return project && project.id && +route.params.projectId === project.id;
      }

      return false;
    });

    const selectLane = async (lane) => {
      data.selectedLane = lane.identifier;
      data.showSelectItems = false;

      await store.dispatch('RFPStore/getLaneById', lane.id);

      emit('fetchCalendarByLane', lane.id);
    };

    const cleanFilters = () => {
      data.selectedLane = null;
      data.showSelectItems = false;
      store.commit('RFPStore/setCurrentLane', null);
      emit('cleanFilters');
    };

    watch(
      () => store.getters['RFPStore/getPreviousStatusProject'],
      (newView, oldView) => {
        if (newView === 'calendar') {
          changeWidth();
        }
      },
    );

    const onClickBack = () => router.push({ name: 'lanesRFP' });

    return {
      ...toRefs(data),
      currentLane,
      currentProject,
      currentProjectLoaded,
      currentView,
      cleanFilters,
      lanesListFilter,
      selectLane,
      handleChange,
      handleClickOutside,
      formattedDate,
      onClickBack,
      showLanes,
    };
  },
});
