import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-between items-center plan-gap-xs" }
const _hoisted_2 = { class: "w-full flex flex-col justify-start items-center plan-gap-md" }
const _hoisted_3 = { class: "w-full flex justify-between items-center plan-gap-xl" }
const _hoisted_4 = {
  key: 4,
  class: "w-full flex flex-col justify-start items-center plan-gap-md"
}
const _hoisted_5 = { class: "max-width-container w-full flex justify-end" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 5,
  class: "w-full flex flex-col justify-start items-center plan-gap-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_load_filter_by_id_po = _resolveComponent("load-filter-by-id-po")!
  const _component_plan_filter = _resolveComponent("plan-filter")!
  const _component_load_header_buttons = _resolveComponent("load-header-buttons")!
  const _component_planning_filters = _resolveComponent("planning-filters")!
  const _component_quick_filters = _resolveComponent("quick-filters")!
  const _component_plan_tabs = _resolveComponent("plan-tabs")!
  const _component_shipper_load_detail_content = _resolveComponent("shipper-load-detail-content")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!
  const _component_plan_default_confirmation_modal = _resolveComponent("plan-default-confirmation-modal")!
  const _component_plan_default_delete_modal = _resolveComponent("plan-default-delete-modal")!
  const _component_plan_loads_order_filter = _resolveComponent("plan-loads-order-filter")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_load_card = _resolveComponent("load-card")!
  const _component_loads_calendar = _resolveComponent("loads-calendar")!
  const _component_plan_default_view = _resolveComponent("plan-default-view")!

  return (_openBlock(), _createBlock(_component_plan_default_view, {
    title: _ctx.$t('planning'),
    "max-width-content": "",
    "show-go-top": ""
  }, {
    "header-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_load_filter_by_id_po, { onApplyFilters: _ctx.applyFilterByIdPo }, null, 8, ["onApplyFilters"]),
        _createVNode(_component_plan_filter, {
          onStatus: _ctx.applyFilters,
          id: "TEAM_MEMBER",
          icon: "user-new",
          title: _ctx.$t('team-member'),
          placeholder: _ctx.$t('team-member'),
          "min-input-width": "135px",
          items: []
        }, null, 8, ["onStatus", "title", "placeholder"])
      ])
    ]),
    "header-buttons": _withCtx(() => [
      _createVNode(_component_load_header_buttons, { onChangeDisplayMode: _ctx.changeDisplayMode }, null, 8, ["onChangeDisplayMode"])
    ]),
    "header-sticky": _withCtx(() => [
      (!_ctx.activeQuickFilter)
        ? (_openBlock(), _createBlock(_component_planning_filters, {
            key: 0,
            onApplyFilters: _ctx.applyFilters,
            minimize: _ctx.minimize
          }, null, 8, ["onApplyFilters", "minimize"]))
        : _createCommentVNode("", true)
    ]),
    "header-sub-sticky": _withCtx(() => [
      _createVNode(_component_quick_filters, { onChange: _ctx.applyFilters }, null, 8, ["onChange"]),
      (_openBlock(), _createBlock(_component_plan_tabs, {
        align: "center",
        tabs: _ctx.totalTabs,
        "start-tab": _ctx.selectedTotalTab.index,
        key: _ctx.totalTabs,
        onChangeTab: _ctx.changeTab,
        style: {"padding-top":"16px"}
      }, null, 8, ["tabs", "start-tab", "onChangeTab"]))
    ]),
    "view-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showModalUpdate !== -1 || _ctx.loadId !== '')
          ? (_openBlock(), _createBlock(_component_shipper_load_detail_content, {
              key: 0,
              onHideModal: _cache[0] || (_cache[0] = ($event: any) => {
            _ctx.showModalUpdate = -1;
            _ctx.loadId = '';
          }),
              isEditing: _ctx.isEditable,
              filters: {},
              "load-id": _ctx.loadId,
              "new-planning": true
            }, null, 8, ["isEditing", "load-id"]))
          : _createCommentVNode("", true),
        (_ctx.showModalTemplate !== -1)
          ? (_openBlock(), _createBlock(_component_plan_default_modal, {
              key: 1,
              onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModalTemplate = -1)),
              title: _ctx.$t('create-template'),
              size: "xs-auto"
            }, {
              content: _withCtx(() => [
                _createVNode(_component_plan_input_text, {
                  "model-value": _ctx.templateName,
                  "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.templateName) = $event)),
                  label: "Name",
                  placeholder: "Enter the template name"
                }, null, 8, ["model-value"])
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_plan_button, {
                    onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModalTemplate = -1)),
                    disabled: _ctx.loadingTemplate,
                    type: "cancel",
                    label: "Cancel",
                    class: "w-full"
                  }, null, 8, ["disabled"]),
                  _createVNode(_component_plan_button, {
                    onOnClick: _ctx.createTemplate,
                    loading: _ctx.loadingTemplate,
                    type: "primary",
                    label: "Create",
                    class: "w-full"
                  }, null, 8, ["onOnClick", "loading"])
                ])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        (_ctx.showModalCancel !== -1)
          ? (_openBlock(), _createBlock(_component_plan_default_confirmation_modal, {
              key: 2,
              onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModalCancel = -1)),
              onAccept: _ctx.cancelLoad,
              title: _ctx.$t('cancel-load'),
              message: _ctx.$t('are-you-sure-message'),
              loading: _ctx.loadingCancel
            }, null, 8, ["onAccept", "title", "message", "loading"]))
          : _createCommentVNode("", true),
        (_ctx.showModalDelete !== -1)
          ? (_openBlock(), _createBlock(_component_plan_default_delete_modal, {
              key: 3,
              onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showModalDelete = -1)),
              onDelete: _ctx.deleteLoad,
              title: "Delete Load",
              message: _ctx.$t('are-you-sure-message'),
              loading: _ctx.loadingDelete,
              "hide-icon": ""
            }, null, 8, ["onDelete", "message", "loading"]))
          : _createCommentVNode("", true),
        (_ctx.displayMode === 'cards')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_plan_loads_order_filter, { onChange: _ctx.applyFilters }, null, 8, ["onChange"])
              ]),
              (_ctx.loadingLoads)
                ? (_openBlock(), _createBlock(_component_plan_loading, {
                    key: 0,
                    class: "plan-mt-lg",
                    message: _ctx.$t('getting-your-loads'),
                    "sub-message": _ctx.$t('loading-ltl-loading')
                  }, null, 8, ["message", "sub-message"]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loads, (load) => {
                return (_openBlock(), _createBlock(_component_load_card, {
                  key: load,
                  onOnClick: ($event: any) => (_ctx.showLoadDetails(load.load.id)),
                  onOnOptionsClick: _ctx.onOptionsClick,
                  onOpenDetail: ($event: any) => (_ctx.showLoadDetails(load.load.id)),
                  load: load.load,
                  chats: load.chats,
                  state: load.load.status
                }, null, 8, ["onOnClick", "onOnOptionsClick", "onOpenDetail", "load", "chats", "state"]))
              }), 128)),
              (_ctx.loads.length > 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('showing-of', { items: _ctx.loads.length, total: _ctx.totalToShow })), 1))
                : _createCommentVNode("", true),
              (!_ctx.loadingLoads && _ctx.loads.length > 0 && _ctx.loads.length < _ctx.totalToShow)
                ? (_openBlock(), _createBlock(_component_plan_button, {
                    key: 2,
                    onOnClick: _ctx.loadMoreLoads,
                    loading: _ctx.loadingLoads,
                    type: "primary-outline",
                    "full-rounded": "",
                    label: _ctx.$t('show-more-loads'),
                    style: {"width":"328px"}
                  }, null, 8, ["onOnClick", "loading", "label"]))
                : _createCommentVNode("", true),
              (_ctx.loads.length > 0 && _ctx.loadingLoads)
                ? (_openBlock(), _createBlock(_component_plan_loading, { key: 3 }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.displayMode === 'calendar')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.loadingLoads)
                ? (_openBlock(), _createBlock(_component_plan_loading, {
                    key: 0,
                    class: "plan-mt-lg",
                    message: _ctx.$t('getting-your-loads'),
                    "sub-message": _ctx.$t('loading-ltl-loading')
                  }, null, 8, ["message", "sub-message"]))
                : (_openBlock(), _createBlock(_component_loads_calendar, {
                    key: 1,
                    onChangeDate: _ctx.goToDay,
                    id: "loads-calendar",
                    events: _ctx.totalDates,
                    locale: _ctx.currentUserLocale,
                    max: _ctx.maxDateFilter,
                    min: _ctx.minDateFilter,
                    class: "w-full",
                    style: {"padding-bottom":"100px"}
                  }, null, 8, ["onChangeDate", "events", "locale", "max", "min"]))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}