import IncreaseCreditForm from '@/modules/credit/IncreaseCreditForm.vue';

const CreditRouter = [
  {
    path: 'increase-credit',
    name: 'increaseCredit',
    component: IncreaseCreditForm,
  },
];

export default CreditRouter;
