
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
// Types
import { NewLoadStep } from '@/modules/planning/newLoad/NewLoadTypes';

export default defineComponent({
  name: 'FixedBottomBar',
  mixins: [BaseComponent],
  components: {
    ErrorMessage,
    Field,
    Form,
    InputAmericanFormat,
  },
  props: {
    currentView: String,
    isAdditionalStops: Boolean,
    isAssigning: Boolean,
    isCreating: Boolean,
    isSendingDirectAssign: Boolean,
    isSendingQuotes: Boolean,
    selectedContacts: Array as any,
  },
  data() {
    return {
      bookNow: null as any,
      isBookNow: false,
      isCheckedShowQuotes: false,
      petitionSent: false,
    };
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    collapsedSidebar() {
      return this.$store.getters['UserStore/getCollapsedSidebar'];
    },
    currentViewType() {
      return NewLoadStep;
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    showModalPlans(): any {
      return this.$store.getters['UserStore/getShowModalPlans'];
    },
  },
  methods: {
    formSubmit(view) {
      this.$emit('formSubmit', view);
    },
    onClickAssignCarrier() {
      // Check to show Plans modal
      this.$store.dispatch('UserStore/checkIsShowPlanModal').then((resp) => {
        if (!resp) {
          this.formSubmit(this.currentViewType.CARRIER);
        }
      });
    },
    onClickContinue() {
      return this.isAdditionalStops && this.currentView !== this.currentViewType.FREIGHT_INFO
        ? this.formSubmit(this.currentViewType.FREIGHT_INFO)
        : this.formSubmit(this.currentViewType.QUOTE);
    },
    onClickContinueQuote() {
      return this.selectedContacts.length > 0
        ? this.onClickSendQuotes()
        : BaseComponent.methods?.showToastError(this.$t('required-one-carrier'));
    },
    onClickSendDirectAssign() {
      this.$emit('sendDirectAssign');
    },
    async onClickSendQuotes() {
      if (this.isBookNow) {
        const validation = await (this.$refs.form as any).validate();
        if (!validation.valid) {
          return;
        }
      }
      this.petitionSent = true;
      this.$emit('sendQuotes', this.bookNow, this.isBookNow, this.isCheckedShowQuotes);
    },
    toggleChangePlanModal(bool: boolean) {
      this.$store.commit('UserStore/setShowModalPlans', bool);
    },
  },
});
