import { initialState } from '@/modules/credit/_store/index';

export default {
  clearCreditStore(state: any) {
    Object.assign(state, initialState);
  },
  setCompanyCredit(state: any, payload: string) {
    state.credit = payload;
  },
  setCompanyCreditBreakdown(state: any, payload: any) {
    if (payload?.data?.params?.page > 1) {
      state.creditBreakdown.loads.push(...payload.response.loads);
    } else {
      state.creditBreakdown = payload.response;
    }
  },
  setGrantedCreditModal(state: any, payload: any) {
    state.grantedCreditModal = payload;
  },
  setGrantedCreditInfo(state: any, payload: any) {
    state.grantedCreditInfo = payload;
  },
  setNoCreditModal(state: any, payload: boolean) {
    state.noCreditModal = payload;
  },
  setPlanimatikCreditModal(state: any, payload: boolean) {
    state.planimatikCreditModal = payload;
  },
};
