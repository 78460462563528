const projectStatusBackground = {
  DRAFT: 'bg-white border-t md:border-l',
  QUOTING: 'bg-blue-100',
  QUOTED: 'bg-blue-300',
  ACTIVE: 'text-corp bg-sec-500',
  FINISHED: 'text-corp bg-green-400',
  DELIVERED: 'text-corp bg-green-500',
  IN_PROGRESS: 'text-corp bg-blue-500',
  CANCELED: 'text-corp bg-red-100',
};

const statusBackground = {
  DRAFT: 'bg-white',
  QUOTING: 'bg-blue-100',
  CONFIRMED: 'bg-blue-300',
  ASSIGNED: 'text-corp bg-blue-200',
  IN_PROGRESS: 'text-corp bg-blue-500',
  DELIVERED: 'text-corp bg-blue-in-progress',
  FINISHED: 'text-corp bg-green-400',
  CANCELED: 'text-corp bg-red-100 text-red-500',
};

const WarehouseFillModes = {
  FILLED: 'filled',
  DEFAULT: 'default',
  OVER_ONE: 'overOne',
};

const paymentOptions = {
  SUBSCRIPTION: 'subscription',
  PAYMENT: 'payment',
}

const SubscriptionStatus = {
  CREATED: 'subscription_create',
  CANCELED: 'subscription_cycle'
};

export { projectStatusBackground, statusBackground, SubscriptionStatus, WarehouseFillModes, paymentOptions };
