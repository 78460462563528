import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-6xl mx-auto sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "mt-8 overflow-hidden" }
const _hoisted_3 = { class: "grid grid-cols-1" }
const _hoisted_4 = { class: "p-6 mr-2 bg-white" }
const _hoisted_5 = { class: "text-4xl sm:text-5xl text-gray-800 tracking-tight" }
const _hoisted_6 = { class: "text-normal text-lg sm:text-2xl font-medium text-gray-600 dark:text-gray-400 mt-2" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = {
  for: "billId",
  class: ""
}
const _hoisted_9 = { class: "flex flex-col mt-2" }
const _hoisted_10 = {
  for: "concept",
  class: ""
}
const _hoisted_11 = { class: "flex flex-col mt-2" }
const _hoisted_12 = {
  for: "totalCost",
  class: ""
}
const _hoisted_13 = { class: "xl:container mx-auto p-5 md:p-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_InputAmericanFormat = _resolveComponent("InputAmericanFormat")!
  const _component_Spin = _resolveComponent("Spin")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('upload-bill')), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('fill-form-to-upload-invoice')), 1)
        ]),
        _createVNode(_component_Form, {
          class: "p-6 flex flex-col",
          ref: "form",
          onSubmit: _ctx.submitInvoice,
          onInvalidSubmit: _cache[6] || (_cache[6] = ($event: any) => (_ctx.validating = true))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('bill-number')), 1),
              _createVNode(_component_Field, {
                type: "text",
                placeholder: "",
                name: "billId",
                id: "billId",
                modelValue: _ctx.billId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billId) = $event)),
                rules: "required|max:256",
                class: "block w-full rounded-md bg-gray-50 border-gray-300 py-3 px-3 mt-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                name: "billId",
                class: "text-rose-500"
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('concept')), 1),
              _createVNode(_component_Field, {
                as: "textarea",
                id: "concept",
                placeholder: "",
                modelValue: _ctx.concept,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.concept) = $event)),
                name: "concept",
                rules: "required|max:256",
                class: "block w-full rounded-md bg-gray-50 border-gray-300 py-3 px-3 mt-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                name: "concept",
                class: "text-rose-500"
              })
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('total-amount')), 1),
              _createVNode(_component_Field, {
                name: "totalCost",
                rules: "required|numeric|min_value:1|max_value:2147483647",
                modelValue: _ctx.totalCost,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.totalCost) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputAmericanFormat, {
                    class: "mt-2",
                    modelValue: _ctx.totalCost,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.totalCost) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                name: "totalCost",
                class: "text-rose-500"
              })
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_Field, {
                name: "file",
                rules: "required|size:10000",
                modelValue: _ctx.file,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.file) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("input", {
                    ref: "invoiceFile",
                    type: "file",
                    rules: "required",
                    accept: "application/pdf,application/xpdf,image/jpeg,image/png,image/jpg",
                    class: "block cursor-pointer mt-6 file:cursor-pointer w-full text-sm text-corp file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-corp file:text-white hover:file:bg-slate-700",
                    onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
                  }, null, 544)
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_ErrorMessage, {
                name: "file",
                class: "text-rose-500"
              })
            ]),
            _createElementVNode("button", {
              type: "submit",
              class: _normalizeClass(["w-full inline-flex justify-center md:w-100 bg-blue-600 hover:bg-blue-dark text-white font-bold py-3 px-6 rounded-lg mt-3 hover:bg-blue-500 transition ease-in-out duration-300", { 'mb-10': _ctx.isSuplanting !== '' }])
            }, [
              (_ctx.sendingAction)
                ? (_openBlock(), _createBlock(_component_Spin, {
                    key: 0,
                    class: "self-center"
                  }))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('send-docs')), 1)
            ], 2)
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ])
  ]))
}