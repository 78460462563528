import { initialState } from '@/services/loads/_store/index';

export default {
  removePopertyFromLoad(state: any, payload: any) {
    delete state.loadToSend[payload];
    console.log('updateLoadToSend: ', state.loadToSend);
  },
  updateDefaultLoad(state: any, payload: object) {
    state.defaultLoad = { ...payload };
  },
  updateLoadToSend(state: any, payload: any) {
    state.loadToSend = { ...payload };
    console.log('updateLoadToSend: ', state.loadToSend);
  },
  updatePopertyFromLoad(state: any, payload: any) {
    state.loadToSend[payload.key] = payload.value;
    console.log('updateLoadToSend: ', state.loadToSend);
  },
  updateLegFromLoad(state: any, payload: any) {
    state.loadToSend.legs[payload.index][payload.key] = payload.value;
    console.log('updateLoadToSend: ', state.loadToSend.legs[payload.index]);
  },
  updateLegStopFromLoad(state: any, payload: any) {
    state.loadToSend.legs[payload.legIndex].stops[payload.stopIndex][payload.key] = payload.value;
    console.log(
      'updateLegStopFromLoad: ',
      state.loadToSend.legs[payload.legIndex].stops[payload.stopIndex][payload.key],
    );
  },
};
