import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-between items-center" }
const _hoisted_2 = { class: "flex justify-start items-center gap-2 truncate" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col justify-center items-start w-full"
}
const _hoisted_5 = { class: "truncate text-xs plan-t-prim-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "plan-avatar-primary",
        style: _normalizeStyle(_ctx.logo.length > 0 ? `background: url(${_ctx.logo}); background-size: cover; background-position: center;` : '#00DB50')
      }, [
        (_ctx.logo.length === 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.userName.slice(0,1)), 1))
          : _createCommentVNode("", true)
      ], 4),
      (!_ctx.onlyAvatar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", {
              class: _normalizeClass(["truncate text-md font-bold w-full",  _ctx.breakWord ? 'break-words whitespace-normal' : ''])
            }, _toDisplayString(_ctx.userToShow), 3),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.description), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.withChat)
      ? (_openBlock(), _createBlock(_component_plan_button_icon, {
          key: 0,
          icon: "chat",
          type: "transparent-gray-800"
        }))
      : _createCommentVNode("", true)
  ]))
}