
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateBlackSpin.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
// Modules
import CarrierCompanyIcons from '@/modules/carrier/_components/CarrierCompanyIcons.vue';

export default defineComponent({
  name: 'CarrierValoration',
  components: {
    CarrierCompanyIcons,
    Spin,
    PlanLoading,
  },
  mixins: [BaseComponent],
  data() {
    return {
      value: 0,
      likeCarrier: false,
      isGettingLoad: false,
      isLoaded: false,
      loadingOne: false,
      loadingTwo: false,
    };
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
  },
  created() {
    if (this.currentLoad?.valoration) {
      this.value = this.currentLoad.valoration.value;
    }
    this.isLoaded = true;
  },
  methods: {
    showAccessDenied() {
      BaseComponent.methods?.showToastError(this.$t('access-denied-no-action'));
    },
    addValue(index) {
      this.value = index;
      this.saveRating();
    },
    saveRating() {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.loadingOne = true;
      if(this.currentLoad?.valoration?.id){
        const data = {
          id: this.currentLoad.valoration.id,
          value: this.value,
        };

        this.$store
          .dispatch('LoadsStore/putValoration', data)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(
              this.$t('company-rated', {
                companyName: this.currentLoad?.shipment?.assignedCompany?.company?.name,
              }),
            );

            this.loadingOne = false;
            this.isGettingLoad = true;
            this.$store
              .dispatch('LoadsStore/loadById', this.currentLoad.id)
              .then(() => { this.isGettingLoad = false; })
              .catch((err) => {
                this.isGettingLoad = false;
                BaseComponent.methods?.showToastError(err?.response?.data?.detail);
              });
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            this.loadingOne = false;
          });

      } else {
        const data = {
          value: this.value,
          load: { id: this.currentLoad.id },
          company: { id: this.currentLoad.shipment.assignedCompany.company.id },
        };
        this.$store
          .dispatch('LoadsStore/postValoration', data)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(
              this.$t('company-rated', {
                companyName: this.currentLoad?.shipment?.assignedCompany?.company?.name,
              }),
            );

            this.loadingOne = false;
            this.$store
              .dispatch('LoadsStore/loadById', this.currentLoad.id)
              // .then(() => {})
              .catch((err) => {
                BaseComponent.methods?.showToastError(err?.response?.data?.detail);
              });
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            this.loadingOne = false;
          });
      }

    },
    saveNotes() {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.loadingTwo = true;
      const data = {
        id: this.currentLoad.id,
        annotations: {
          annotations: this.currentLoad.annotations,
        },
      };
      this.$store
        .dispatch('LoadsStore/annotations', data)
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('message-annotation-saved'));
          this.loadingTwo = false;

          this.$store
            .dispatch('LoadsStore/loadById', this.currentLoad.id)
            // .then(() => {})
            .catch((err) => {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            });
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          this.loadingTwo = false;
        });
    },
  },
});
