import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center w-full" }
const _hoisted_2 = {
  key: 0,
  class: "font-bold text-[13px] text-plan-primary-600",
  style: {"font-size":"13px"}
}
const _hoisted_3 = {
  key: 1,
  class: "font-bold text-[13px] text-plan-primary-600",
  style: {"font-size":"13px"}
}
const _hoisted_4 = { class: "w-full flex justify-between items-center gap-1" }
const _hoisted_5 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_calendar = _resolveComponent("plan-calendar")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", {
    class: "plan-filter-pickup-and-delivery-input",
    style: _normalizeStyle(_ctx.inputValue.length === 0 ? 'background: white':'background: #C7E8FF')
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.delivery)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('pick-up-date')), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('placeholder-delivery-date')), 1))
    ]),
    _createVNode(_component_popper, {
      onMouseleave: _ctx.handleMouseLeave,
      show: _ctx.showPopper,
      interactive: "",
      arrow: "",
      class: "w-full",
      offsetDistance: "0"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", null, [
          (_openBlock(), _createBlock(_component_plan_calendar, {
            onChangeDate: _ctx.changeDate,
            id: _ctx.id,
            key: new Date().getTime(),
            locale: _ctx.i18n.global.locale.value,
            range: ""
          }, null, 8, ["onChangeDate", "id", "locale"]))
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_plan_icon, {
            name: "input-calendar",
            size: "15",
            "icon-color": _ctx.iconColor,
            "is-svg": ""
          }, null, 8, ["icon-color"]),
          _createElementVNode("input", {
            onClic: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showPopper && _ctx.showPopper(...args))),
            value: _ctx.inputValue,
            onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInputFocus && _ctx.handleInputFocus(...args))),
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleInputBlur && _ctx.handleInputBlur(...args))),
            class: _normalizeClass([{'font-bold text-plan-primary-700': _ctx.inputValue.length > 0}, "w-full"]),
            style: {"font-size":"15px"},
            type: "text",
            placeholder: _ctx.$t('select-date')
          }, null, 42, _hoisted_5),
          (_ctx.inputValue !== '')
            ? (_openBlock(), _createBlock(_component_plan_button_icon, {
                key: 0,
                onOnClick: _ctx.cleanInputValue,
                icon: "close",
                size: "15",
                type: "transparent-gray-800",
                style: {"padding":"0.1rem"}
              }, null, 8, ["onOnClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["onMouseleave", "show"])
  ], 4))
}