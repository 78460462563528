
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import IncidenceTooltipContent from '@/components/tooltip/IncidenceTooltipContent.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
// Types
import { RFPStatus } from '@/modules/rfp/RFPTypes';
// Utils
import { roundToXDecimals } from '@/utils/formatString';

export default defineComponent({
  components: {
    IncidenceTooltipContent,
    PlanIcon,
    Spin,
  },
  mixins: [BaseComponent],
  props: {
    currentWeek: {
      type: Number,
      required: true,
    },
    dataLanes: Array as any,
    maxTotalWeeks: {
      type: Number,
      required: true,
    },
    status: String,
    currentWeekStart: String,
    currentWeekEnd: String,
    isCurrentWeek: {
      type: Boolean,
      default: false,
    },
    completedPercentage: {
      type: Number,
      required: true,
    },
    unassignedPercentage: {
      type: Number,
      required: true,
    },
    unplannedPercentage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fetchDataOnHover: false,
      isLoading: false,
      testLanes: [
        {
          id: '#24-004679',
          status: 'Additional cost',
          type: 'DEL#2',
        },
        {
          id: '#24-004674',
          status: 'Undelivered',
          type: '',
        },
        {
          id: '#24-004676',
          status: 'Unpicked',
          type: 'PICK#1',
        },
      ],
    };
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    completedAmount(): any {
      let totalAmount = '0';
      const { targetAmount, completedAmount } = this.currentProject.computed?.loads;

      if (targetAmount && completedAmount) {
        totalAmount = ((completedAmount / targetAmount) * 100).toFixed(2);
      }

      return totalAmount;
    },
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    dates(): any {
      const result = {
        weekStart: this.$props.currentWeekStart,
        weekEnd: this.$props.currentWeekEnd,
      };
      return result;
    },
    getNumLanes(): any {
      return this.currentProject?.computed?.lanes?.totalAmount;
    },
    lanesList(): any {
      return this.$store.getters['RFPStore/getLanes'];
    },
    RFPStatus(): any {
      return RFPStatus;
    },
    totalPercentage(): any {
      let totalPlanned = '0';
      const { quotedAmount, totalAmount } = this.currentProject?.computed?.lanes;

      if (quotedAmount && totalAmount) {
        totalPlanned = ((quotedAmount / totalAmount) * 100).toFixed(2);
      }

      return totalPlanned;
    },
    totalUnassigned(): any {
      let totalUnassigned = '0';
      const { targetAmount, unassignedAmount } = this.currentProject.computed?.loads;

      if (targetAmount && unassignedAmount) {
        totalUnassigned = ((unassignedAmount / targetAmount) * 100).toFixed(2);
      }

      return totalUnassigned;
    },
    totalUncompleted(): any {
      let totalUncompleted = '0';
      const { targetAmount, uncompletedAmount } = this.currentProject.computed?.loads;

      if (targetAmount && uncompletedAmount) {
        totalUncompleted = ((uncompletedAmount / targetAmount) * 100).toFixed(2);
      }

      return totalUncompleted;
    },
    totalUnplanned(): any {
      let totalUnplanned = '0';
      const { targetAmount, unplannedAmount } = this.currentProject.computed?.loads;

      if (targetAmount && unplannedAmount) {
        totalUnplanned = ((unplannedAmount / targetAmount) * 100).toFixed(2);
      }

      return totalUnplanned;
    },
    targetAmount(): any {
      return this.currentProject.computed?.loads?.targetAmount ?? '-';
    },
    loadsCompletedAmount(): any {
      return this.currentProject.computed?.loads?.completedAmount ?? '-';
    },
    loadsPlannedAmount(): any {
      return this.currentProject.computed?.loads?.plannedAmount ?? '-';
    },
    loadsUnplannedAmount(): any {
      return this.currentProject.computed?.loads?.unplannedAmount ?? '-';
    },
    loadsUnassignedAmount(): any {
      return this.currentProject.computed?.loads?.unassignedAmount ?? '-';
    },
    loadsUncompletedAmount(): any {
      return this.currentProject.computed?.loads?.uncompletedAmount ?? '-';
    },
    tendersAmount(): any {
      return this.currentProject.computed?.tenders?.totalAmount ?? '-';
    },
    lanesQuotesAmount(): any {
      return this.currentProject.computed?.lanes?.quotedAmount ?? '-';
    },
    lanesTotalAmount(): any {
      return this.currentProject.computed?.lanes?.totalAmount ?? '-';
    },
  },
  methods: {
    roundToXDecimals,
    handlePopperHover() {
      this.fetchDataOnHover = true;
    },
    handlePopperLeave() {
      this.fetchDataOnHover = false;
    },
    callbackEndQuotationModal() {
      this.$emit('callbackEndQuotationModal');
    },
    callbackRequestQuotingModal() {
      this.$emit('callbackRequestQuotingModal');
    },
    onClickAddLanes() {
      let projectId = this.$route.params.projectId;
      this.$router.push({ name: 'lanesRFPCreation', params: { projectId } });
    },
    onClickEdit() {
      if (this.getNumLanes === 0) return;
      this.$store.commit('RFPStore/setCurrentEditingLanes', this.dataLanes);
      const projectId = this.$route.params.projectId;

      this.$router.push({
        name: 'addLanesRFP',
        params: { projectId },
        query: { loadCreationMode: 'Editing' },
      });
    },
    onClickIncidences() {
      // TODO
    },
    onClickNextWeek() {
      if (this.maxTotalWeeks > this.currentWeek) {
        this.$emit('next-week');
      }
    },
    onClickPreviousWeek() {
      if (this.currentWeek !== 0) {
        this.$emit('previous-week');
      }
    },
    onClickEndQuoting() {
      this.isLoading = true;
      const projectId = this.$route.params.projectId;
      const body = {};
      this.$store
        .dispatch('RFPStore/postProjectRFPEndQuotation', { projectId, body })
        .then(() => {
          this.$store.dispatch('UserStore/showToast', this.$t('quotation-finished'));
          this.$store.dispatch('RFPStore/getProjectById', { projectId, page: 1 });
          this.$store.dispatch('RFPStore/getLanesByProjectId', { projectId, page: 1 });
          this.callbackEndQuotationModal();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    openLaneDetail(item) {
      this.$router.push({
        name: 'RFP-laneService',
        params: { projectId: item.projectId, laneId: item.laneId, id: item.loadId },
      });
    },
  },
});
