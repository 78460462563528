
import { defineComponent } from 'vue';
// Icons
import Spin from '@/components/spin/AnimateSpin.vue';

export default defineComponent({
  name: 'SkeletonUnreadMessages',
  components: {
    Spin,
  },
});
