export default {
    companyAccepted(state: any): object {
        return state.companyAccepted;
    },
    getImage(state: any): object {
        return state.getImage;
    },
    getSavedShipmentBids(state: any): object {
        return state.getSavedShipmentBids;
    },
    shipment(state: any): object {
        return state.shipment;
    },
    getActivitySummary(state: any): object {
        return state.activitySummary;
    },
    totalBids(state: any): object {
        return state.totalBids;
    }
};
