
import { defineComponent, computed, watch } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import moment from 'moment';

export default defineComponent({
  name: 'PlanCarrierLoadSubHeader',
  components: { PlanIcon, PlanAvatar, PlanButtonIcon },
  setup() {
    const store = useStore();

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const addMemberToChatLoad = async (chatId) => {
      await store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    };

    const openChat = async () => {
      if (data.value.status === 'QUOTING') {
        openChatQuoting();
      } else {
        const member = data.value?.chat?.chatMembers.find(
          (member) => member.user.id === currentUser?.value.id,
        );
        if (member) {
          member.readed = true;
        } else {
          if (data.value?.chat?.id) {
            await addMemberToChatLoad(data.value.chat.id);
          }
        }

        store.commit('LoadsStore/loadId', data.value.id);
        store.commit(
          'ChatsStore/setChatId',
          data.value.chat ? data.value.chat.id : carrierChat.value.chat,
        );
        store.commit('GlobalStore/setOpenChat', true);
        store.commit('CarrierStore/openChat', false);
      }
    };

    const isOpenChat = computed(() => {
      return store.state.GlobalStore.isOpenChat;
    });

    const isRead = computed(() => {
      return carrierChat.value.readed;
    });

    const openChatQuoting = async () => {
      if (!isOpenChat.value) {
        carrierChat.value.readed = true;
        store.commit('GlobalStore/setOpenChat', false);
        setTimeout(() => {
          store.commit('LoadsStore/loadId', data.value.id);
          store.commit('ChatsStore/setUserForChat', { user: data.value.shipperOwner });
          store.commit('ChatsStore/setChatId', carrierChat.value ? carrierChat.value.chat : null);
          store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    };

    const shipperOwner = computed(() => {
      return {
        user: store.getters['CarrierStore/getLoadData'].shipperOwner.name,
        company: store.getters['CarrierStore/getLoadData'].shipperOwner.company?.name,
      };
    });

    const data = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const carrierChat = computed(() => {
      return store.getters['CarrierStore/getCarrierChat'];
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    const stopsData = computed(() => {
      let pickup = '';
      let pickupDate = '';
      let delivery = '';
      let deliveryDate = '';

      store.getters['CarrierStore/getLoadData'].stops.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL') {
          pickupDate =
            store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
              ? moment(stop.executionDate).format('MMM DD, YYYY')
              : moment(stop.executionDate).format('DD MMM, YYYY');
          pickup = `${stop.location.city}, ${stop.location.state.iso} ${stop.location.state.country.iso}`;
        }
        if (stop.type === 'DELIVERY_FINAL') {
          deliveryDate =
            store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
              ? moment(stop.executionDate).format('MMM DD, YYYY')
              : moment(stop.executionDate).format('DD MMM, YYYY');
          delivery = `${stop.location.city}, ${stop.location.state.iso} ${stop.location.state.country.iso}`;
        }
      });

      return {
        pickup: pickup,
        pickupDate: pickupDate,
        delivery: delivery,
        deliveryDate: deliveryDate,
      };
    });

    if (store.getters['CarrierStore/openChat']) {
      openChat();
    }

    return {
      actualWidth,
      carrierChat,
      data,
      isRead,
      isDriver,
      openChat,
      openChatQuoting,
      shipperOwner,
      stopsData,
    };
  },
});
