import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-blue-100 bg-opacity-25 p-6 md:px-10 justify-between rounded-xl border border-blue-100 flex flex-col" }
const _hoisted_2 = { class: "flex justify-between md:text-center md:space-x-2" }
const _hoisted_3 = { class: "flex flex-row mt-4 items-center" }
const _hoisted_4 = { class: "text-xl font-bold opacity-70 ml-6" }
const _hoisted_5 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_plan_icon, {
          name: "contacts-bell",
          "icon-color": "gray",
          size: "22",
          "is-svg": ""
        }),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentContacts.length) + " " + _toDisplayString(_ctx.$t('address-book-info')), 1)
      ]),
      _createElementVNode("a", {
        class: "border mt-2 border-corp text-center px-3 py-2 rounded-md flex justify-center items-center space-x-2 opacity-70 transition duration-500 hover:opacity-100 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('modalVisible')))
      }, [
        _createVNode(_component_plan_icon, {
          name: "plus",
          "icon-color": "black",
          size: "16",
          "is-svg": ""
        }),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('new-contact')), 1)
      ])
    ])
  ]))
}