
import { defineComponent } from 'vue';
import PlanCarrierLoadAcceptationStep from '@/components/carrier/PlanCarrierLoadAcceptationStep.vue';
export default defineComponent({
  name: 'PlanCarrierLoadAcceptationSteps',
  components: { PlanCarrierLoadAcceptationStep },
  emits: ['goToStep'],
  props: {
    activeStep: { type: String, required: true, default: '' }
  },
  setup (props, { emit }) {
    const goToStep = (step: string) => {
      emit('goToStep', step)
    }

    return {
      goToStep
    }
  }
})
