
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PlanModal',
  props: {
    size: { type: String, required: false, default: 'md' },
    hideBackground: { type: Boolean, required: false, default: false },
    withoutPadding: { type: Boolean, required: false, default: false },
    animation: { type: Boolean, required: false, default: false },
  },
  emits: ['close'],
  setup(props, { emit }) {
    function close() {
      emit('close');
    }

    return {
      close,
    };
  },
});
