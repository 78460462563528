import globalAxios from 'axios';

interface Headers {
  common: any;
  Authorization: any;
}
const postSignin = (data: object) => {
  const response = globalAxios.post('/jwt/token', data);
  return response;
};

const refreshToken = async (refreshToken: string) => {
  localStorage.removeItem('token');
  const response = await globalAxios.post('/jwt/token/refresh', {
    refresh_token: refreshToken,
  });
  return response;
};

export default {
  postSignin,
  refreshToken,
};
