import mapCurrencySymbol from '@/resources/locales/currency/mapCurrencySymbol.js';

export default {
  getCountries(state: any): object {
    return state.countries?.sort((a, b) => (a.name > b.name ? 1 : -1));
  },
  getCountry(state: any): object {
    return state.country;
  },
  getCurrentCountry(state: any): object {
    return state.currentCountry;
  },
  getCountryCurrency(state: any): object {
    return state.country?.currency || 'USD';
  },
  getCountryDefaultLanguageLocale(state: any) {
    return state.country?.defaultLanguageLocale || 'en-US';
  },
  getCurrencySymbol(state: any) {
    const currencyISO = state.country?.currency || 'USD';
    return mapCurrencySymbol[currencyISO.toUpperCase()];
  },
  getIsLoadingCountry(state: any): any {
    return state.isLoadingCountry;
  },
  getMeasurementSystemDistanceUnit(state: any): object {
    return state.country?.measurementSystem?.distanceUnit || 'Mi';
  },
  getMeasurementSystemDistanceUnitIntl(state: any): object {
    return state.country?.measurementSystem?.distanceUnitIntl || 'mile';
  },
  getMeasurementSystemTemperatureUnit(state: any): object {
    return state.country?.measurementSystem?.temperatureUnit || 'F';
  },
  getMeasurementSystemTemperatureUnitIntl(state: any): object {
    return state.country?.measurementSystem?.temperatureUnitIntl || 'fahrenheit';
  },
  getMeasurementSystemWeightUnit(state: any): object {
    return state.country?.measurementSystem?.weightUnit || 'lbs';
  },
  getMeasurementSystemWeightUnitIntl(state: any): object {
    return state.country?.measurementSystem?.weightUnitIntl || 'pound';
  },
};
