
import { PropType, defineComponent } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
// Base
import BaseComponent from '@/base/BaseComponent';

interface Texts {
  body: string;
  header: string;
  message: string;
}

interface Button {
  text: string;
  type: string;
  callback: () => void;
}

export default defineComponent({
  name: 'Modal-basic',
  mixins: [BaseComponent],
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  emits: ['setOpen'],
  props: {
    buttons: {
      type: Array as PropType<Button[]>,
    },
    icSuccess: Boolean,
    loadId: {
      type: String,
    },
    noMargin: Boolean,
    open: Boolean,
    textKeys: {
      type: Object as PropType<Texts>,
      required: true,
    },
  },
  methods: {
    toggleOpen(isOpen: boolean) {
      this.$emit('setOpen', isOpen);
    },
  },
});
