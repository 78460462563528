
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  onBeforeUnmount,
  ref,
  watch,
} from 'vue';
import PlanFilter from '@/components/filters/PlanFilter.vue';
import PlanFilterPickupAndDelivery from '@/components/filters/PlanFilterPickupAndDelivery.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import { is } from '@vee-validate/rules';
import { useI18n } from 'vue-i18n';
import i18n from '@/resources/locales';
import QuickFilters from '@/components/filters/QuickFilters.vue';
import PlanBadge from '@/components/badges/PlanBadge.vue';
import PlanFilterLtlFtlSelector from '@/components/filters/PlanFilterLtlFtlSelector.vue';
import PlanLoadsOrderFilter from '@/components/filters/PlanLoadsOrderFilter.vue';
// Base
import BaseComponent from '@/base/BaseComponent';

interface appliedFilter {
  id: string | number;
  value: string | number;
  loadFilterParam: string | number;
}

interface planningFilters {
  appliedFilters: Array<appliedFilter>;
  ftlLoads: boolean;
  isHover: boolean;
  ltlLoads: boolean;
  scrollMode: string;
  showFilters: boolean;
}

export default defineComponent({
  name: 'PlanningFilters',
  methods: { is },
  components: {
    PlanFilterLtlFtlSelector,
    PlanIcon,
    PlanBadge,
    PlanButton,
    PlanFilterPickupAndDelivery,
    PlanFilter,
  },
  mixins: [BaseComponent],
  emits: ['applyFilters'],
  props: {
    minimize: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive<planningFilters>({
      appliedFilters: [],
      ftlLoads: true,
      isHover: false,
      ltlLoads: true,
      scrollMode: 'automatic',
      showFilters: true,
    });

    const activeFiltersTitle = computed(() => {
      let result = data.showFilters ? 'Hide filters' : 'Show filters';

      if (data.appliedFilters.length > 0) {
        result = data.showFilters
          ? `Hide ${data.appliedFilters.length} filters`
          : `Show ${data.appliedFilters.length} filters`;
      }

      return result;
    });

    const activeQuickFilter = computed(() => {
      return store.getters['LoadsStore/getSelectedQuickFilter'];
    });

    const planFilters = computed(() => {
      let traylerTypeMin = i18n.global.locale.value === 'es' ? '60px' : '55px';
      let carrierBrokerMin = i18n.global.locale.value === 'es' ? '160px' : '95px';
      let customerMin = i18n.global.locale.value === 'es' ? '55px' : '70px';
      let warehouseMin = i18n.global.locale.value === 'es' ? '70px' : '80px';
      let rfpProjectMin = i18n.global.locale.value === 'es' ? '105px' : '95px';

      return [
        {
          id: 'RFP_PROJECT',
          icon: 'rfp-lanes',
          title: translate.t('rfp-plan'),
          placeholder: translate.t('rfp-plan'),
          min: rfpProjectMin,
        },
        {
          id: 'TRAILER_TYPE',
          icon: 'icInRoute',
          title: translate.t('vehicles'),
          placeholder: translate.t('vehicle'),
          min: traylerTypeMin,
        },
        {
          id: 'CARRIER_BROKER',
          icon: 'user-new',
          title: translate.t('carrier-broker'),
          placeholder: translate.t('carrier-broker'),
          min: carrierBrokerMin,
        },
        {
          id: 'CUSTOMER',
          icon: 'user-new',
          title: translate.t('customers'),
          placeholder: translate.t('customer'),
          min: customerMin,
        },
        {
          id: 'WAREHOUSE',
          icon: 'warehouse',
          title: translate.t('warehouses'),
          placeholder: translate.t('warehouse'),
          min: warehouseMin,
        },
      ];
    });

    const planFiltersRfpTeam = computed(() => {
      let rfpProjectMin = i18n.global.locale.value === 'es' ? '105px' : '95px';
      let teamMemberMin = i18n.global.locale.value === 'es' ? '135px' : '105px';

      return [
        {
          id: 'RFP_PROJECT',
          icon: 'rfp-lanes',
          title: translate.t('rfp-plan'),
          placeholder: translate.t('rfp-plan'),
          min: rfpProjectMin,
        },
        {
          id: 'TEAM_MEMBER',
          icon: 'user-new',
          title: translate.t('team-member'),
          placeholder: translate.t('team-member'),
          min: teamMemberMin,
        },
      ];
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const userRegion = computed(() => {
      return currentUser.value.company?.country?.region?.name;
    });

    const deliveryDates = computed(() => {
      const delivery = store.getters['LoadsStore/getAppliedFilters']['delivery-date'];
      if (delivery) {
        return delivery.loadFilterParam;
      }
      return null;
    });

    const pickUpDates = computed(() => {
      const pickup = store.getters['LoadsStore/getAppliedFilters']['pickup-date'];
      if (pickup) {
        return pickup.loadFilterParam;
      }
      return null;
    });

    const changeFilterStatus = (status: any) => {
      store.commit('LoadsStore/applyFilter', status);
      emit('applyFilters', store.getters['LoadsStore/getAppliedFiltersQuery']);
    };

    const changeQuickFilter = (status: any) => {
      emit('applyFilters', store.getters['LoadsStore/getAppliedFiltersQuery']);
    };

    const changeHover = (newState: boolean) => {
      data.isHover = newState;
    };

    const changeLoadTypeStatus = (loadType: string) => {
      let loadStatus = {
        id: loadType === 'ftlLoads' ? 'FTL_LOAD' : 'LTL_LOAD',
        value: loadType === 'ftlLoads' ? (data.ftlLoads ? true : '') : data.ltlLoads ? true : '',
        loadFilterParam: loadType === 'ftlLoads' ? 'FTL' : 'LTL',
      };
      changeFilterStatus(loadStatus);
    };

    const changeScrollStatus = () => {
      data.scrollMode = 'manual';
      data.showFilters = !data.showFilters;
    };

    const cleanFilters = () => {
      store.commit('LoadsStore/cleanAppliedFilters');
      emit('applyFilters');
    };

    watch(
      () => store.getters['UserStore/getPlanDefaultViewScrollTop'],
      (newValue, oldValue) => {
        if (newValue > 0 && data.scrollMode === 'automatic') {
          data.showFilters = false;
          data.scrollMode = 'automatic';
        }

        if (newValue !== oldValue && data.scrollMode === 'automatic') {
          data.showFilters = false;
        }

        if (newValue !== oldValue && data.scrollMode === 'manual') {
          data.scrollMode = 'automatic';
        }

        if (newValue === 0) {
          data.showFilters = true;
          data.scrollMode = 'automatic';
        }
      },
    );

    watch(
      () => store.getters['LoadsStore/getAppliedFilters'],
      (newFilters, oldFilters) => {
        if (Object.keys(newFilters).length === 0) {
          data.ltlLoads = false;
          data.ftlLoads = false;
        }
      },
    );

    watch(
      () => props.minimize,
      (newValue, oldValue) => {
        if (props.minimize) data.showFilters = false;
      },
    );

    if (props.minimize) data.showFilters = false;

    return {
      ...toRefs(data),
      activeFiltersTitle,
      activeQuickFilter,
      deliveryDates,
      pickUpDates,
      planFilters,
      planFiltersRfpTeam,
      userRegion,
      changeFilterStatus,
      changeQuickFilter,
      changeHover,
      changeLoadTypeStatus,
      changeScrollStatus,
      cleanFilters,
    };
  },
});
