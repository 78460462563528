import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "font-bold text-center md:text-2xl" }
const _hoisted_2 = { class: "mt-4 flex flex-col justify-center items-center gap-4" }
const _hoisted_3 = { class: "text-center font-semibold w-fit rounded-full px-2" }
const _hoisted_4 = {
  key: 0,
  class: "text-center text-gray-500 font-semibold w-fit rounded-full px-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    hideClose: "",
    modalSize: "small",
    show: _ctx.showModal
  }, {
    title: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('waiting-for-sync-lanes.title')), 1)
    ]),
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('waiting-for-sync-lanes.body')), 1),
        (!_ctx.continueAllowed)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('waiting-for-sync-lanes.second-text')), 1))
          : _createCommentVNode("", true),
        (_ctx.continueAllowed)
          ? (_openBlock(), _createBlock(_component_plan_button, {
              key: 1,
              class: "w-full",
              type: "primary",
              label: _ctx.$t('continue'),
              onOnClick: _ctx.closeModal
            }, null, 8, ["label", "onOnClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}