
import { defineComponent } from 'vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CarrierHeader from '@/components/headers/CarrierHeader.vue';
import ModalHash from '@/components/modals/HashModal.vue';
import SidebarChat from '@/components/sidebar/ChatSidebar.vue';
import SkeletonCarrier from '@/components/skeletons/CarrierSkeleton.vue';
// Utils
import { numberSuffixAndColor } from '@/utils/formatString';

export default defineComponent({
  name: 'QuotesRanking',
  components: {
    CarrierHeader,
    InformationCircleIcon,
    ModalHash,
    SidebarChat,
    SkeletonCarrier,
  },
  mixins: [BaseComponent],
  data() {
    return {
      loading: false,
      isSending: false,
      dateFormat: 'MM-dd-yyyy',
      activeTab: 'ranking',
    };
  },
  beforeCreate() {
    this.$store.dispatch('PusherStore/initializePusher');
  },
  async created() {
    this.fetchAllInfo();
  },
  computed: {
    company(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    companyBids(): any {
      return this.$store.getters['LanesStore/getBidsByCompany'];
    },
    companyShipperOwner(): any {
      return this.lanesRFQRequestBidsGroup[0]?.lanesRFQRequestBidsGroup?.shipperOwner;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    isChatRead(): any {
      let imMember = this.companyShipperOwner?.generalChatsWithNetworkOwner?.find(
        (chat) => chat.user === this.currentUser.id,
      );
      if (imMember) {
        return imMember.readed;
      }
      return true;
    },
    isOpenChat(): any {
      return this.$store.state.GlobalStore.isOpenChat;
    },
    lanesRFQRequestBidsGroup(): any {
      return this.$store.getters['LanesStore/getGroupBids'];
    },
    numberSuffixAndColor(): any {
      return numberSuffixAndColor;
    },
    rankingList(): any {
      return this.companyBids.filter((bid) => bid.ranking);
    },
    shipmentId(): any {
      return this.$route.params.id;
    },
    token(): any {
      return localStorage.getItem('token') ? localStorage.getItem('token') : null;
    },
    userForChat(): any {
      return this.$store.state.ChatsStore.userForChat;
    },
  },
  methods: {
    async fetchAllInfo() {
      this.loading = true;
      const id = this.$route.params.id;
      const hash: any = this.$route.params.hash;
      const code: any = this.$route.params.code;
      // if (hash !== localStorage.hash) {
      //   this.$store.commit('UserStore/setShowHash', false);
      //   localStorage.setItem('hash', hash);
      //   setTimeout(() => {
      //     this.$store.commit('UserStore/setShowHash', true);
      //   }, 500);
      // }
      if (hash && code) {
        await this.$store
          .dispatch('UserStore/hashValidation', {
            hashId: hash,
            secureCode: code,
          })
          .then(() => {
            this.$store.dispatch('PusherStore/initializePusher');
            this.$router.push('/carrier/quotesRanking/' + id);
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
            this.$store.commit('UserStore/setShowHash', true);
          });
      }
      await this.$store.dispatch('LanesStore/lanesRFQRequestBidsGroupCompany', id).then();
      this.$store.dispatch('LanesStore/lanesRFQRequestBidsGroupOnlyBids', id).then();
      this.loading = false;
    },
    getDateString(load) {
      const initialPick = new Date(
        load.stops.find((stop) => stop.type === 'PICKUP_INITIAL').executionDate,
      );
      const finalDelivery = new Date(
        load.stops.find((stop) => stop.type === 'DELIVERY_FINAL').executionDate,
      );
      const firstDayOfWeek = new Date(
        initialPick.getFullYear(),
        initialPick.getMonth(),
        initialPick.getDate() - initialPick.getDay(),
      );
      const lastDayOfWeek = new Date(
        finalDelivery.getFullYear(),
        finalDelivery.getMonth(),
        finalDelivery.getDate() + 6,
      );
      const formattedFirstDay = firstDayOfWeek.toLocaleDateString('default', {
        month: 'short',
        day: 'numeric',
      });
      const formattedLastDay = lastDayOfWeek.toLocaleDateString('default', {
        month: 'short',
        day: 'numeric',
      });
      const currentYear = initialPick.getFullYear();
      return `${formattedFirstDay} - ${formattedLastDay}, ${currentYear}`;
    },
    getLocationString(load) {
      const initialPick = load.stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      const finalDelivery = load.stops.find((stop) => stop.type === 'DELIVERY_FINAL');
      return `${initialPick?.location?.city}, ${initialPick?.location?.state?.iso} - ${finalDelivery?.location?.city}, ${finalDelivery?.location?.state?.iso}`;
    },
    getMonthName(monthIndex) {
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      return monthNames[monthIndex];
    },
    async openChat(user) {
      if (JSON.stringify(this.userForChat) !== JSON.stringify(user)) {
        this.$store.commit('LoadsStore/loadId', null);
        this.$store.commit('GlobalStore/setOpenChat', false);
        if (user.company.generalChatsWithNetworkOwner.length > 0) {
          let imMember = user.company.generalChatsWithNetworkOwner.find(
            (chat) => chat.user === this.currentUser.id,
          );

          if (imMember) {
            imMember.readed = true;
          } else {
            await this.$store.dispatch('ChatsStore/addNewMember', {
              id: user.company.generalChatsWithNetworkOwner[0].id,
              data: {
                users: {
                  id: this.currentUser.id,
                },
              },
            });
          }
          setTimeout(() => {
            this.$store.commit('ChatsStore/setUserForChat', user);
            this.$store.commit('ChatsStore/setChatId', imMember ? imMember.id : null);
            this.$store.commit('GlobalStore/setOpenChat', true);
          }, 500);
        }
      }
    },
  },
  watch: {
    // currentUser(): any {
    //   const hash: any = this.$route.params.hash;
    //   if (hash !== localStorage.hash || this.currentUser.hashId !== hash) {
    //     this.$store.commit('UserStore/setShowHash', true);
    //   }
    // },
  },
});
