
import { defineComponent } from 'vue';
import {
  CheckCircleIcon,
  InformationCircleIcon,
  MinusSmallIcon,
  PlusSmallIcon,
} from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Types
import { NewLoadStep } from '@/modules/planning/newLoad/NewLoadTypes';

export default defineComponent({
  name: 'StepsTab',
  components: {
    CheckCircleIcon,
    InformationCircleIcon,
    MinusSmallIcon,
    PlusSmallIcon,
  },
  mixins: [BaseComponent],
  emits: ['close', 'handleForm', 'updateMultipleLoad'],
  props: {
    allowMultipleLoad: Boolean,
    currentView: String,
    isAdditionalStops: { type: Boolean, default: false },
    load: Object as any,
    totalLoadsMultiple: { default: 2, type: Number },
  },
  computed: {
    isAllStopsFilled() {
      if (!this.load) {
        return false;
      }

      let result = true;
      this.load?.stops?.map((stop) => {
        if (!stop?.commodityMaster) {
          result = false;
        }

        stop?.commodityMaster?.map((item) => {
          if (item.commodityName?.length === 0 || item.nPallets === 0 ||
            item.packageType?.id <= 0 || item.weight === 0) {
            result = false;
          }
        })
      });

      return result;
    },
    NewLoadStep() {
      return NewLoadStep
    }
  },
  methods: {
    onClickTab(view) {
      if (view === NewLoadStep.DATA) {
        this.$emit('close', NewLoadStep.DATA);
        return;
      }
      this.load ? this.$emit('handleForm', view) : null
    },
    updateMultipleLoad(sumatory, allow) {
      this.$emit('updateMultipleLoad', this.totalLoadsMultiple + sumatory, allow);
    },
  },
});
