import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "plan-default-modal-header"
}
const _hoisted_2 = { class: "flex justify-center items-center plan-gap-sm" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "plan-text-xl font-bold" }
const _hoisted_5 = {
  key: 0,
  class: "plan-text-xs plan-t-prim-100 font-semibold"
}
const _hoisted_6 = { class: "flex justify-center items-center plan-gap-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["plan-carrier-default-modal", { 'plan-carrier-default-modal-without-padding': _ctx.actualWidth <= 640 }]),
    style: _normalizeStyle(_ctx.planDefaultModalStyle)
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
      class: "plan-default-modal-bg",
      style: _normalizeStyle(_ctx.planDefaultModalStyle)
    }, null, 4),
    _createElementVNode("div", {
      class: "plan-default-modal-box",
      style: _normalizeStyle(_ctx.modalStyle)
    }, [
      (!_ctx.onlyContent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1),
                (_ctx.subtitle.length > 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.subtitle), 1))
                  : _createCommentVNode("", true)
              ]),
              _renderSlot(_ctx.$slots, "header-near-title")
            ]),
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "header-buttons"),
              (!_ctx.hideCloseButton)
                ? (_openBlock(), _createBlock(_component_plan_button_icon, {
                    key: 0,
                    onOnClick: _ctx.close,
                    icon: "close",
                    type: "white"
                  }, null, 8, ["onOnClick"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["plan-default-modal-content", { 'plan-default-modal-content-without-padding': _ctx.contentWithoutPadding }])
      }, [
        _renderSlot(_ctx.$slots, "content")
      ], 2),
      (!_ctx.hideFooter && !_ctx.onlyContent)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["plan-default-modal-footer", _ctx.actualWidth <= 640 ? 'w-full fixed bottom-0 bg-white':''])
          }, [
            _renderSlot(_ctx.$slots, "footer")
          ], 2))
        : _createCommentVNode("", true)
    ], 4)
  ], 6))
}