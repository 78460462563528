import globalAxios, { isAxiosError } from 'axios';
import { AxiosError } from 'axios';
import { IErrorMessage } from '@/services/user/interfaces';

const addContact = async (data: any) => {
  const response = await globalAxios.post('/users/addnewcontact', data, {});
  return Promise.resolve(response.data);
};

const assignLoad = async (data: any) => {
  const response = await globalAxios.post('/assigned_companies/direct/batch_assign', data, {});
  return Promise.resolve(response.data);
};

const changePassword = async (id: any, oldPassword: any, plainPassword: any) => {
  const response = await globalAxios.put(`/users/${id}/updatePassword`, {
    oldPassword: oldPassword,
    plainPassword: plainPassword,
  });
  return Promise.resolve(response.data);
};

const postContactsExcel = async (excel) => {
  try {
    const response = await globalAxios.post(`/company/excel/extractContactsFromExcel`, excel, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const getContactsExcel = async () => {
  const response = await globalAxios.get('/company/excel/getDownloadExcelTemplateImportContacts', {
    responseType: 'blob',
  });
  return Promise.resolve(response.data);
};

const assignDirect = async (name: string, page: number, maxResults: number) => {
  const response = await globalAxios.get('/companies/direct_assign', {
    params: {
      name: name,
      page: page,
      maxResults: maxResults,
    },
  });
  return Promise.resolve(response.data);
};

const carrierContactList = async (
  name: string,
  page: number,
  type: string,
  sortBy: string,
  trailerType: number,
  tag: string
) => {
  const response = await globalAxios.get('/company/myNetworkV2', {
    params: {
      type: type,
      page: page,
      name: name,
      sortBy: sortBy,
      trailerType: trailerType,
      tag: tag
    },
  });
  return Promise.resolve(response.data);
};

const carrierContactListStatsByLoad = async (
  destinyZip: number,
  lanesRFP: number,
  name: string,
  originZip: number,
  page: number,
  period: number,
  sortBy: string,
  trailerType: number,
  trailerTypeStats: number,
  type: string,
  permits: any,
  country: any,
  tag: any
) => {
  const response = await globalAxios.get('/company/myNetworkWithZIPAndTrailerTypeV2', {
    params: {
      destinyZIP: destinyZip,
      lanesRFP,
      name: name,
      originZIP: originZip,
      page: page,
      period,
      sortBy: sortBy,
      trailerType: trailerType,
      trailerTypeStats: trailerTypeStats,
      type: type,
      permits: JSON.stringify(permits),
      country: country,
      tag: tag
    },
  });
  return response.data;
};

const carrierNetwork = async (name: string, page: number, type: string) => {
  const response = await globalAxios.get('/company/planningNetwork', {
    params: {
      type: type,
      page: page,
      name,
    },
  });
  return Promise.resolve(response.data);
};

const carrierNetworkTotal = async (data: any) => {
  const response = await globalAxios.get('/company/myNetwork/totals', {
    params: {
      name: data?.name ?? '',
    },
  });
  return Promise.resolve(response.data);
};

const carrierRequestedBid = async (page: number, shippment: number, sortBy: string) => {
  const response = await globalAxios.get(`/shipments/${shippment}/requestBid`, {
    params: {
      maxResults: 1000,
      page: page,
      sortBy: sortBy,
    },
  });
  return Promise.resolve(response.data);
};

const emailResetPassword = async (data: object) => {
  const response = await globalAxios.get('/email/send-reset-password', { params: data });
  return response.data;
};

const deAssignDriver = async (data: any) => {
  const response = await globalAxios.put(`/shipments/${data.id}/deAssignDriver`, {});
  return response.data;
};

const deAssignVehicle = async (data: any) => {
  const response = await globalAxios.put(`/shipments/${data.id}/deAssignVehicle`, {});
  return response.data;
};

const deleteUser = async (id: any) => {
  const response = await globalAxios.delete(`/users/${id}`);
};

const defaultTermsAndConditions = async (data: any) => {
  const response = await globalAxios.get(`/big_texts/type/${data}`);
  return response.data[0];
};

const getCompany = async (query: string, zip: number) => {
  const response = await globalAxios.get('/location/searchCompanyZip', {
    params: {
      zip: zip,
      company: query,
    },
  });
  return Promise.resolve(response.data);
};

const getGlobalConfig = async () => {
  const response = await globalAxios.get('/global-config');
  return Promise.resolve(response.data);
};

const getURLInvitation = async (id: any) => {
  const response = await globalAxios.get(`/users/${id}/invitationURL`);
  return response;
};

const getUser = async (data: any) => {
  const response = await globalAxios.get('/users/' + data.id);
  return Promise.resolve(response.data);
};

// const getUser = async (id: any) => {
//   const response = await globalAxios.get(`/users/${id}`, {});
//   return response.data;
// };

const getUserConfig = async (id: any) => {
  const response = await globalAxios.get('/users/' + id + '/config');
  return Promise.resolve(response.data);
};

const getUserEmailNotificationSetup = async () => {
  const response = await globalAxios.get(`/userEmailNotificationSetup/`, {});
  return Promise.resolve(response.data);
};

const getUsersCompany = async (data: any) => {
  const response = await globalAxios.get(`/users/company/${data.companyId}`, {
    params: data.params,
  });
  return Promise.resolve(response.data);
};

const getUsersCompanyTotals = async (companyId: any) => {
  const response = await globalAxios.get(`/users/company/${companyId}/totals`);
  return Promise.resolve(response.data);
};

const getUsersTeam = async (params: object) => {
  const response = await globalAxios.get('/users/team/fastFilter', { params });
  return Promise.resolve(response.data);
};

const getUserIdentification = async (id: number) => {
  const response = await globalAxios.get(`/users/${id}/userIdentification`);
  return Promise.resolve(response.data);
};

const hashValidation = async (data: any) => {
  const response = await globalAxios.get('/user/hashValidation', {
    params: data,
  });
  return response;
};

const newShipperRegister = async (data: object) => {
  const response = await globalAxios.post('/users/newShipperRegister', data, {});
  return response.data;
};

const postIncidence = async (body: any) => {
  const response = await globalAxios.post('/incidents', body);
  return Promise.resolve(response.data);
};

const checkContactsFromJson = async (body: any) => {
  const response = await globalAxios.post('/company/excel/checkContactsFromJson', body);
  return Promise.resolve(response.data);
};

const createContactsFromJson = async (body: any) => {
  const response = await globalAxios.post('/company/excel/createContactsFromJson', body);
  return Promise.resolve(response.data);
};

const postIncidenceFile = async (body: any) => {
  const response = await globalAxios.post('/mediaObjects/incident', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return Promise.resolve(response.data);
};

const AdminPostCreateNewPlanSubscription = async (body: any) => {
  const response = await globalAxios.post('/subscriptions/admin_managed', body);
  return Promise.resolve(response.data);
};

const AdminPutCreateNewPlanSubscription = async (body: any, id: number) => {
  const response = await globalAxios.put(`/subscriptions/${id}/admin_managed`, body);
  return Promise.resolve(response.data);
};

const AdminCancelPlanSubscription = async (id: number) => {
  const response = await globalAxios.post(`/subscriptions/${id}/admin_managed/cancel`, {});
  return Promise.resolve(response.data);
};

const postNewShipper = async (data: object) => {
  try {
    const response = await globalAxios.post('/users/newShipperInvite', data);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        const errorMessage = axiosError.response.data as IErrorMessage;
        if (errorMessage.detail) {
          //TODO BACK ONLY RETURN A STRING WITH THE ERROR
          throw errorMessage.detail.split(':')[1];
        }
      }
    }
    throw error;
  }
};

const postUser = async (data: any) => {
  const response = await globalAxios.post(`/users`, data);
  return response.data;
};

const postUserDemoRequests = async (id: number) => {
  const response = await globalAxios.post(`/users/createFrom/demo_requests/${id}`);
  return Promise.resolve(response.data);
};

const postUserEmailNotificationSetup = async () => {
  const response = await globalAxios.post(`/userEmailNotificationSetup/`, {});
  return Promise.resolve(response.data);
};

const putUser = async (data: any) => {
  const response = await globalAxios.put('/users/' + data.id, data.user);
  return Promise.resolve(response.data);
};

const putUserEmailNotificationSetup = async (data: any) => {
  const response = await globalAxios.put(
    `/userEmailNotificationSetup/${data.id}/update`,
    data.body,
  );
  return Promise.resolve(response.data);
};

const reAssignLoad = async (data: any) => {
  const response = await globalAxios.delete(`/assignedCompany/${data}/reassign`, {});
  return response.data;
};

const registerEmail = async (data: object) => {
  const response = await globalAxios.post('/users/registerEmail', data, {});
  return response.data;
};

const registrationUpdatePassword = async (data: any) => {
  const response = await globalAxios.put(`/users/${data.id}/registration/updatePassword`, {
    plainPassword: data.password,
  });
  return response.data;
};

const requestBid = async (shipment: number, object: object) => {
  const response = await globalAxios.post(`/shipment/${shipment}/requestBid/batch`, object, {});
  return Promise.resolve(response.data);
};

const requestBidStatsByLoad = async (
  page: number,
  shipment: number,
  sortBy: string,
  trailerTypeStats: number,
  originZip: number,
  destinyZip: number,
  period: string,
) => {
  const response = await globalAxios.get(`/shipments/${shipment}/shipmentAndBidStatsByLoad`, {
    params: {
      maxResults: 1000,
      page: page,
      sortBy: sortBy,
      trailerTypeStats: trailerTypeStats,
      originZip: originZip,
      destinyZip: destinyZip,
      period: period,
    },
  });
  return response.data;
};

const requestLoginCode = async (data: any) => {
  const response = await globalAxios.get(`/users/carrier/request_login_code`, {
    params: {
      hashId: data.id,
      shipmentId: data.shipmentId,
    },
  });
  return response;
};

const resendEmail = async (data: any) => {
  const response = await globalAxios.post(`/stops/${data.id}/BOL_POD/sendEmail`, data, {});
  return response.data;
};

const resendInvite = async (id: any) => {
  const response = await globalAxios.post(`/users/${id}/resendInvite`);
  return response;
};

const resetPassword = async (data: object) => {
  const response = await globalAxios.post('/users/resetPassword', data);
  return response.data;
};

const showQuoteCarrier = async (data: any) => {
  const response = await globalAxios.put('/loads/' + data.id + '/modifyCompetitiveQuoting', {
    competitiveQuoting: data.competitiveQuoting,
  });
  return Promise.resolve(response.data);
};

export default {
  addContact,
  assignLoad,
  changePassword,
  assignDirect,
  carrierContactList,
  carrierContactListStatsByLoad,
  carrierNetwork,
  carrierNetworkTotal,
  carrierRequestedBid,
  createContactsFromJson,
  checkContactsFromJson,
  deAssignDriver,
  deAssignVehicle,
  defaultTermsAndConditions,
  deleteUser,
  emailResetPassword,
  getCompany,
  getGlobalConfig,
  getURLInvitation,
  getUser,
  getUserConfig,
  getUserEmailNotificationSetup,
  getUserIdentification,
  getUsersCompany,
  getUsersCompanyTotals,
  getUsersTeam,
  getContactsExcel,
  hashValidation,
  newShipperRegister,
  postIncidence,
  postContactsExcel,
  postIncidenceFile,
  postNewShipper,
  postUser,
  AdminPostCreateNewPlanSubscription,
  AdminPutCreateNewPlanSubscription,
  AdminCancelPlanSubscription,
  postUserDemoRequests,
  postUserEmailNotificationSetup,
  putUser,
  putUserEmailNotificationSetup,
  registerEmail,
  resetPassword,
  reAssignLoad,
  registrationUpdatePassword,
  requestBid,
  requestBidStatsByLoad,
  requestLoginCode,
  resendEmail,
  resendInvite,
  showQuoteCarrier,
};
