import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "plan-default-table" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  style: {"width":"32px"}
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  style: {"width":"32px"}
}
const _hoisted_6 = ["checked", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    (_ctx.headers.length > 0)
      ? (_openBlock(), _createElementBlock("thead", _hoisted_2, [
          _createElementVNode("tr", null, [
            (_ctx.selectable)
              ? (_openBlock(), _createElementBlock("th", _hoisted_3, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectAll) = $event)),
                    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeSelectAll && _ctx.changeSelectAll(...args))),
                    type: "checkbox"
                  }, null, 544), [
                    [_vModelCheckbox, _ctx.selectAll]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
              return (_openBlock(), _createElementBlock("th", {
                key: header.value,
                class: _normalizeClass([{ 'text-right sticky right-0': header.sticky }, "bg-red-300"]),
                onClick: ($event: any) => (_ctx.changeOrder(header))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(["flex justify-start items-center plan-gap-xs", { 'plan-t-prim-500': _ctx.selectedOrder.value === header.value, 'truncate': _ctx.truncateHeaders }])
                }, [
                  _createElementVNode("span", null, _toDisplayString(header.label), 1),
                  (_ctx.selectedOrder.value === header.value)
                    ? (_openBlock(), _createBlock(_component_plan_icon, {
                        key: 0,
                        name: _ctx.selectedOrder.direction === 'desc' ? 'plan-arrow-up' : 'plan-arrow-down',
                        "is-svg": "",
                        "icon-color": "#151830"
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true),
                  (_ctx.selectedOrder.value !== header.value && header.sortable)
                    ? (_openBlock(), _createBlock(_component_plan_icon, {
                        key: 1,
                        name: "plan-arrow-down",
                        "is-svg": "",
                        "icon-color": "#8A8B97"
                      }))
                    : _createCommentVNode("", true)
                ], 2)
              ], 10, _hoisted_4))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (row, index) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: index,
          class: _normalizeClass({ 'selected-cell': _ctx.selectedItems.indexOf(row) !== -1 })
        }, [
          (_ctx.selectable)
            ? (_openBlock(), _createElementBlock("td", _hoisted_5, [
                _createElementVNode("input", {
                  checked: _ctx.isSelected(row),
                  onChange: ($event: any) => (_ctx.changeSelectedItem(row)),
                  type: "checkbox"
                }, null, 40, _hoisted_6)
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default", { item: row }, () => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (value, idx) => {
              return (_openBlock(), _createElementBlock("td", {
                key: idx,
                class: _normalizeClass({ 'selected-cel': _ctx.selectedItems.indexOf(row) !== -1 })
              }, null, 2))
            }), 128))
          ])
        ], 2))
      }), 128))
    ])
  ]))
}