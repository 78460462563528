import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-bold text-center md:text-2xl" }
const _hoisted_2 = { class: "text-center mt-4" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "mt-4"
}
const _hoisted_6 = { class: "font-bold mt-4" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "plan-mt-xl w-full flex justify-between items-center plan-gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_PlanDefaultModal = _resolveComponent("PlanDefaultModal")!

  return (_ctx.showModal)
    ? (_openBlock(), _createBlock(_component_PlanDefaultModal, {
        key: 0,
        size: "sm-auto",
        "hide-close-button": "",
        "only-content": "",
        onClose: _ctx.close
      }, {
        content: _withCtx(() => [
          _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.planType === 'PREMIUM' ? _ctx.$t('do-you-need-customized-plan') : _ctx.$t('change-of-plan')), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", {
              innerHTML: _ctx.$t('already-planned-n-loads-plan', { n: _ctx.nLoads, plan: _ctx.planTypeTraslated })
            }, null, 8, _hoisted_3),
            (_ctx.planType !== 'BASIC')
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('following-will-have-additional-cost', { price: _ctx.formatPriceDecimals(_ctx.BOLCost) })), 1))
              : _createCommentVNode("", true),
            (_ctx.planType === 'BASIC_PLUS')
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('upgrade-your-subscription')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('extend-your-subscription')), 1),
            (_ctx.planType === 'PREMIUM')
              ? (_openBlock(), _createElementBlock("p", {
                  key: 2,
                  innerHTML: _ctx.$t('need-a-plan-that-best-suits-your-needs'),
                  class: "mt-4"
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.close,
              type: "primary-dark",
              label: _ctx.$t('continue-in-plan', { plan: _ctx.planTypeTraslated })
            }, null, 8, ["onOnClick", "label"]),
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.accept,
              type: "primary",
              label: _ctx.planType === 'PREMIUM' ? _ctx.$t('lets-talk') : _ctx.$t('expand-plan')
            }, null, 8, ["onOnClick", "label"])
          ])
        ]),
        _: 1
      }, 8, ["onClose"]))
    : _createCommentVNode("", true)
}