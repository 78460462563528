import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }
const _hoisted_2 = { class: "flex justify-center items-center" }
const _hoisted_3 = { style: {"width":"200px"} }
const _hoisted_4 = { class: "font-bold plan-text-xs plan-t-prim-200" }
const _hoisted_5 = { class: "flex justify-start items-start" }
const _hoisted_6 = {
  key: 1,
  class: "flex flex-col justify-start items-start plan-gap-xs"
}
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_popper, {
      "onOpen:popper": _ctx.getClaimsTooltip,
      hover: "",
      arrow: "",
      placement: _ctx.placement,
      offsetSkid: "0",
      offsetDistance: "8"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('load-alerts')), 1),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_plan_loading, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claims, (claim, index) => {
                    return (_openBlock(), _createElementBlock("p", {
                      class: "plan-text-2xs plan-t-prim-100",
                      key: index
                    }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('load-alerts-' + claim.alertType)) + " ", 1),
                      (claim.totalAlerts > 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(' (' + claim.totalAlerts + ')'), 1))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.bigButton)
            ? (_openBlock(), _createBlock(_component_plan_button_icon, {
                key: 0,
                icon: "bell-claims",
                size: "20",
                type: "error",
                color: "white",
                style: {"width":"40px"}
              }))
            : (_openBlock(), _createBlock(_component_plan_button_icon, {
                key: 1,
                icon: "bell-claims",
                size: "16",
                type: "transparent-red",
                color: "#EF4444"
              }))
        ])
      ]),
      _: 1
    }, 8, ["onOpen:popper", "placement"])
  ]))
}