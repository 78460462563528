import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-center grid-cols-2 grid-rows-1" }
const _hoisted_2 = { class: "flex w-full justify-between col-span-2" }
const _hoisted_3 = { class: "w-full rounded-tl-[11px] rounded-tr-[11px] md:rounded-tr-none md:rounded-bl-[11px]" }
const _hoisted_4 = { class: "flex flex-col lg:flex-row justify-between w-full cursor-pointer px-5 py-6" }
const _hoisted_5 = { class: "flex align-left lg:w-2/3 md:space-x-3" }
const _hoisted_6 = { class: "flex w-1/2 space-x-2 break-all" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "font-bold two-line-ellipsis" }
const _hoisted_9 = { class: "flex flex-col w-1/2 space-x-2 items-center md:flex-row lg:items-start text-sm text-left" }
const _hoisted_10 = { class: "flex w-1/3 ml-2 mt-2 lg:mt-0 md:space-x-3 text-sm" }
const _hoisted_11 = { class: "space-y-2 w-1/2" }
const _hoisted_12 = { class: "text-zinc-800 whitespace-nowrap flex" }
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "text-zinc-800 opacity-50 whitespace-nowrap" }
const _hoisted_16 = { class: "space-y-2 w-1/2" }
const _hoisted_17 = { class: "text-zinc-800 whitespace-nowrap" }
const _hoisted_18 = { class: "text-zinc-800 opacity-50 whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowRightIcon = _resolveComponent("ArrowRightIcon")!
  const _component_Popper = _resolveComponent("Popper")!
  const _component_ProjectCardStatus = _resolveComponent("ProjectCardStatus")!
  const _component_ProjectCardOptions = _resolveComponent("ProjectCardOptions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "relative items-stretch bg-white border border-white w-full mb-4 rounded-xl flex flex-col md:flex-row justify-between hover:bg-[#ffffff99] shadow-xl",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.viewDetail && _ctx.viewDetail(...args)))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  class: "min-h-[1.5rem] min-w-[1.5rem] h-6 w-6 text-corp group-hover:transition-all",
                  alt: "Burger RFP",
                  src: _ctx.getAsset.icBurgerRFP
                }, null, 8, _hoisted_7),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.projectList.project?.name), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", null, _toDisplayString(_ctx.formatDate(_ctx.projectList.project?.periodStartDate)), 1),
                _createVNode(_component_ArrowRightIcon, { class: "min-h-[1.0rem] min-w-[1.0rem] h-5 w-5" }),
                _createElementVNode("p", null, _toDisplayString(_ctx.formatDate(_ctx.projectList.project?.periodEndDate)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, [
                  _createTextVNode(_toDisplayString(_ctx.$t('lanes-n', { n: _ctx.projectList.computed?.lanes.totalAmount })) + " ", 1),
                  (_ctx.projectList.project?.isCreatingLanes)
                    ? (_openBlock(), _createBlock(_component_Popper, {
                        key: 0,
                        hover: "",
                        arrow: "",
                        class: "popper-padding-1 popper-dark"
                      }, {
                        content: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('creating-your-lanes')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            alt: "Info",
                            class: "w-5 h-5 ml-1",
                            src: _ctx.getAsset.icInfo
                          }, null, 8, _hoisted_13)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.projectList.isClonedFinished)
                    ? (_openBlock(), _createBlock(_component_Popper, {
                        key: 1,
                        hover: "",
                        arrow: "",
                        class: "popper-padding-1 popper-dark"
                      }, {
                        content: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('notification-lanes-created')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            alt: "Info",
                            class: "w-5 h-5 ml-1",
                            src: _ctx.getAsset.icCheckCircleGreenFilled
                          }, null, 8, _hoisted_14)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('loads-with-param', { n: _ctx.projectList.computed?.loads.targetAmount })), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.projectList.computed?.tenders.totalAmount) + " " + _toDisplayString(_ctx.$t('tenders')), 1),
                _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('quotes-n', { n: _ctx.projectList.computed?.quotes.totalAmount })), 1)
              ])
            ])
          ])
        ]),
        _createVNode(_component_ProjectCardStatus, { projectList: _ctx.projectList }, null, 8, ["projectList"])
      ]),
      (!_ctx.isShipperViewer)
        ? (_openBlock(), _createBlock(_component_ProjectCardOptions, {
            key: 0,
            projectList: _ctx.projectList,
            onFetchProjects: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('fetchProjects')))
          }, null, 8, ["projectList"]))
        : _createCommentVNode("", true)
    ])
  ]))
}