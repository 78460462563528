import actions from './actions';
import getters from './getters';
import mutations from './mutations';
export const initialState = {
  paymentMethod: false,
  paymentInfo: [],
  subscriptionType:{},
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
