<template>
  <div
    class="autocomplete"
    :class="{ 'h-full': heightFull }"
  >
    <div class="autocomplete-icon-search"></div>
    <input
      class="input_text w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
      :class="{ 'h-full': heightFull }"
      type="text"
      v-model="inputValue"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :readonly="isReadOnly"
      @focusin="onFocusIn"
      @focusout="onFocusOut"
      @keydown="onKeyDown"
      @keyup="onInputKeypress"
    />
    <div
      class="autocomplete-spinner"
      :class="isSendRequest && index === currentIndex ? 'on' : ''"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
      >
        <path
          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
          opacity=".25"
        />
        <circle
          cx="12"
          cy="2.5"
          r="1.5"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="0.75s"
            values="0 12 12;360 12 12"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
    <ul
      class="autocomplete-list max-h-40 overflow-y-auto"
      v-show="getFilterOptions.length"
    >
      <li
        class="cursor-pointer"
        :class="{ active: item[propertyForSelectedText] === searchText }"
        v-for="(item, index) in getFilterOptions"
        :key="index"
        @click="selected(item)"
      >
        <div class="autocomplete-list-item px-3">
          {{ item[propertyForOptionText] }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'customAutocomplete',
  props: {
    isSendRequest: {
      type: Boolean,
      default: false,
    },
    minKeyPressForSearch: {
      type: Number,
      default: 1,
    },
    propertyForOptionId: {
      type: String,
      default: 'id',
    },
    propertyForOptionText: {
      type: String,
      default: 'data',
    },
    propertyForSelectedText: {
      type: String,
      default: 'companyName',
    },
    keyDelay: {
      type: Number,
      default: 250,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    searchText: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    heightFull: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataFilters: [],
      timeout: null,
      inputValue: null,
      focus: false,
    };
  },
  watch: {
    searchText: function (newVal) {
      this.inputValue = newVal;
    },
  },
  computed: {
    getFilterOptions() {
      return this.index === this.currentIndex &&
        this.inputValue &&
        this.inputValue.length > 0 &&
        this.focus
        ? // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          (this.dataFilters[this.index] = [...this.options])
        : [];
    },
  },
  created() {
    this.inputValue = this.searchText;
  },
  methods: {
    onFocusIn() {
      this.focus = true;
      this.$emit('autoCompleteFocusIn', {
        text: this.inputValue,
        index: this.index,
      });
    },
    onFocusOut(evt) {
      this.$emit('updateStop');
    },
    sendClearAll() {
      this.$emit('autoCompleteCloseOptions');
    },
    sendSearch() {
      this.$emit('autoCompleteSearch', {
        text: this.inputValue,
        index: this.index,
      });
    },
    selected(item) {
      this.$emit('autoCompleteOptionSelected', { item: item, index: this.index });
      this.focus = false;
    },
    onKeyDown(evt) {
      if (
        evt.keyCode === 9 ||
        (this.inputValue && this.inputValue.length === 0 && evt.keyCode === 8)
      ) {
        evt.preventDefault();
        return false;
      }
    },
    onInputKeypress(evt) {
      this.inputValue = evt.target.value;
      //Limpiamos el temporizador al pulsar una tecla
      clearTimeout(this.timeout);
      // eslint-disable-next-line
      const that = this;
      if (this.inputValue.length > this.minKeyPressForSearch && evt.keyCode !== 13) {
        this.timeout = setTimeout(function () {
          that.sendSearch();
        }, that.keyDelay);
      } else {
        this.sendClearAll();
      }
    },
  },
};
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 4px;
}

.overflow-indicator {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

$color-gray-light: #ccc;
$color-corp: rgb(207, 34, 34);
$color-disabled: rgb(8, 3, 37);
.autocomplete {
  position: relative;

  & > .autocomplete-icon-search {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+') !important;
    background-repeat: no-repeat;
    position: absolute;
    left: 0.5rem;
    top: 0.4rem;
    width: 2rem;
    height: 2rem;
    z-index: 1;
  }

  & > input {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border: solid 1px $color-gray-light !important;
  }

  & > .autocomplete-spinner {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: none;

    &.on {
      display: block;

      & > .spinner-svg {
        stroke: $color-corp;
      }
    }
  }

  & > .autocomplete-list {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    z-index: 3;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-top: none;
    border-right: 1px solid $color-gray-light;
    border-left: 1px solid $color-gray-light;
    border-bottom: 1px solid $color-gray-light;
    width: 100%;

    &:last-child {
      padding-bottom: 1rem;
      border-radius: 0 0 0.25rem 0.25rem;
    }

    & > li {
      padding: 0.16rem 0;
      transition: background-color 250ms ease-in-out;

      &:hover:not(.active) {
        background-color: transparentize($color-disabled, 0.9);
      }

      &.active {
        background-color: $color-disabled;
        color: white;

        & > .autocomplete-list-item {
          padding-left: 2.5rem;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
