
import { defineComponent } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
import BaseComponent from '@/base/BaseComponent';
import CountryMixins from '@/services/country/_mixins';
import { validPhoneNumberPaste } from '@/utils/validationForm';
// Icons
import { XCircleIcon } from '@heroicons/vue/24/solid';

export default defineComponent({
  name: 'PhoneNumberSelector',
  mixins: [BaseComponent, CountryMixins],
  data() {
    return {
      phone: {
        number: null,
        prefix: null,
      },
    };
  },
  components: { ErrorMessage, Field, XCircleIcon },
  props: {
    // Class for input border color
    borderColor: {
      type: String,
      default: 'border-gray-200',
    },
    // Field color
    color: {
      type: String,
      default: '',
    },
    // Classes to apply to the labels
    classesForLabels: {
      type: String,
      default: '',
    },
    // Field index
    index: {
      type: String,
      default: '0',
    },
    isCarrier: {
      type: Boolean,
      default: false,
    },
    // Rounded borders
    roundedBorders: {
      type: Boolean,
      default: false,
    },
    prefixStyles: {
      type: String,
      default:
        'w-1/3  sm:w-1/4  md:w-2/5 lg:w-1/3 xl:w-2/6 2xl:w-40 h-18 flex-col justify-start items-start gap-2 inline-flex',
    },
    numberStyles: {
      type: String,
      default:
        'w-2/3  sm:w-3/4  md:w-3/5 lg:w-2/3 xl:w-4/6 2xl:grow h-18 flex-col justify-start items-start gap-2 inline-flex',
    },
    textStyles: {
      type: String,
      default:
        'text-sm',
    },
    // Phone number
    number: String as any,
    // Phone prefix
    prefix: String as any,
    // Selected Country for defaultPrefix
    selectedCountry: Object as any,
    label: {
      type: String,
      required: false,
    },
  },
  created() {
    this.getDefaultPrefix();
    this.phone.number = this.number;
    if (this.getCountries.length === 0) {
      this.$store.dispatch('CountryStore/getCountries');
    }
  },
  watch: {
    prefix(newVal) {
      this.phone.prefix = newVal;
    },
    number(newVal) {
      this.phone.number = newVal;
    },
    selectedCountry() {
      this.getDefaultPrefix();
    },
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    fieldColor(): any {
      return this.getFieldColor();
    },
    currentCountry(): any {
      return this.$store.getters['CountryStore/getCurrentCountry'];
    },
    getCountries(): any {
      return this.$store.getters['CountryStore/getCountries'].filter(
        (country) => country.iso !== 'CA',
      );
    },
    prefixesByCountry(): any {
      return this.getPrefixesByCountry();
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
  },
  methods: {
    changePhoneNumber() {
      this.$emit('changePhone', 'number', this.phone, this.index);
    },
    changePhonePrefix() {
      this.$emit('changePhone', 'prefix', this.phone, this.index);
    },
    clearInput() {
      this.phone.number = null;
      this.changePhoneNumber();
    },
    getFieldColor() {
      let fieldColor = '';
      switch (this.color) {
        case 'grey':
          fieldColor =
            'bg-gray-200 text-gray-700 border border-gray-200 focus:outline-none focus:bg-white focus:border-gray-500';
          break;
        case 'blue':
          fieldColor =
            'h-11 bg-[#0086CC] border-none text-white placeholder:text-white sm:text-sm focus:text-white focus:bg-[#0086CC] focus:ring-white focus:border-white block w-full p-2.5';
          break;
      }
      return fieldColor;
    },
    getPrefixesByCountry() {
      let prefixes = [];
      if (this.currentCountry && this.currentCountry.region && !this.isSuperAdminRoute) {
        prefixes = this.getCountries?.filter(
          (country) => country?.region?.id === this.currentCountry?.region?.id,
        );
      } else {
        if(this.isSuperAdminRoute && this.currentUser?.region) {
          prefixes = this.getCountries?.filter(
              (country) => country?.region?.name === this.currentUser?.region,
          );
        } else if(this.isCarrier && this.currentUser?.company?.country?.region?.id){
          prefixes = this.getCountries?.filter(
            (country) => country?.region?.id === this.currentUser?.company?.country?.region?.id,
          );
        } else{
          prefixes = this.getCountries;
        }
      }
      return this.orderPrefixAsc(prefixes);
    },
    getDefaultPrefix() {
      if ((!this.prefix || !this.number) && this.selectedCountry?.id) {
        if (this.selectedCountry?.iso === 'CA') {
          let country = this.getCountries?.find((country) => country?.iso === 'US');
          this.phone.prefix = country.phonePrefix;
        } else {
          let country = this.getCountries?.find(
            (country) => country?.id === this.selectedCountry?.id,
          );
          this.phone.prefix = country?.phonePrefix;
        }
      } else {
        this.phone.prefix = this.prefix;
      }
    },
    orderPrefixAsc(prefixes: any){
      return prefixes.sort((a, b) => {
        let prefixA = parseInt(a.phonePrefix, 10);
        let prefixB = parseInt(b.phonePrefix, 10);

        if (prefixA < prefixB) {
          return -1;
        }
        if (prefixA > prefixB) {
          return 1;
        }
        return 0; // Si son iguales
      });
    },
    pasteAction($event){
      this.phone.number = validPhoneNumberPaste($event);
      this.changePhoneNumber();
    },
    validPhoneNumberPaste,
  },
});
