import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex justify-start items-center flex-row-reverse" }
const _hoisted_3 = {
  key: 0,
  class: "truncate"
}
const _hoisted_4 = {
  key: 0,
  class: "truncate plan-mr-sm"
}
const _hoisted_5 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  value: null,
  disabled: "",
  selected: ""
}
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_popper = _resolveComponent("popper")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_plan_input_text_area = _resolveComponent("plan-input-text-area")!
  const _component_plan_input_file = _resolveComponent("plan-input-file")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_popper, {
        placement: "left",
        hover: "",
        class: _normalizeClass(_ctx.actualWidth < 640 ? 'plan-carrier-fixed-button-popper':'plan-carrier-fixed-button-popper-hidden'),
        offsetDistance: "2"
      }, {
        content: _withCtx(() => [
          (_ctx.actualWidth < 640)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('add-additional-fee')), 1))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_plan_button_icon, {
            onOnClick: _ctx.openModal,
            icon: !_ctx.isEurope ? 'simple-dollar': 'simple-euro',
            type: "warning",
            color: "white",
            class: "plan-button-icon-box-shadow",
            style: {"min-width":"48px","min-height":"48px"},
            size: "25"
          }, null, 8, ["onOnClick", "icon"])
        ]),
        _: 1
      }, 8, ["class"]),
      (_ctx.actualWidth >= 640)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('add-additional-fee')), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
          key: 0,
          onClose: _ctx.closeModal,
          title: _ctx.$t('report-additional-fee'),
          size: "sm-auto",
          "hide-close-button": ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_plan_input_number, {
                "model-value": _ctx.feeValue,
                "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.feeValue) = $event)),
                label: _ctx.$t('fee'),
                placeholder: _ctx.formatPrice(0),
                currency: "",
                mandatory: "",
                "hide-validations": ""
              }, null, 8, ["model-value", "label", "placeholder"]),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.feeExplanation) = $event)),
                value: _ctx.feeExplanation,
                class: "ml-1 plan-form"
              }, [
                _createElementVNode("option", _hoisted_7, _toDisplayString(_ctx.$t('select-fee-explanation')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feeExplanationItems, (feeExplanationOption) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: feeExplanationOption.value,
                    value: feeExplanationOption.value
                  }, _toDisplayString(_ctx.getFeeTranslation(feeExplanationOption.label)), 9, _hoisted_8))
                }), 128))
              ], 8, _hoisted_6), [
                [_vModelSelect, _ctx.feeExplanation]
              ]),
              _createVNode(_component_plan_input_text_area, {
                "model-value": _ctx.observations,
                "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.observations) = $event)),
                placeholder: _ctx.$t('comments'),
                rows: 4
              }, null, 8, ["model-value", "placeholder"]),
              _createVNode(_component_plan_input_file, {
                onChange: _ctx.changeFiles,
                types: ['application/pdf', 'image/*'],
                maxSize: 10,
                multiple: ""
              }, null, 8, ["onChange"])
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.closeModal,
                disabled: _ctx.loading,
                type: "cancel",
                label: _ctx.$t('cancel')
              }, null, 8, ["onOnClick", "disabled", "label"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.postAdditionalFee,
                loading: _ctx.loading,
                disabled: _ctx.feeValue <= 0 || _ctx.feeExplanation.length === 0,
                type: "primary",
                label: _ctx.$t('send'),
                style: {"min-width":"100px"}
              }, null, 8, ["onOnClick", "loading", "disabled", "label"])
            ])
          ]),
          _: 1
        }, 8, ["onClose", "title"]))
      : _createCommentVNode("", true)
  ]))
}