import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-center plan-gap-md" }
const _hoisted_2 = { class: "font-bold plan-t-h2 plan-t-prim-500" }
const _hoisted_3 = { class: "font-bold plan-t-h2 plan-t-prim-200" }
const _hoisted_4 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_5 = {
  key: 0,
  class: "w-full flex flex-col justify-start plan-gap-sm"
}
const _hoisted_6 = { class: "font-bold truncate" }
const _hoisted_7 = {
  key: 1,
  class: "flex justify-between items-center plan-gap-sm"
}
const _hoisted_8 = { class: "w-full flex justify-center items-center plan-gap-sm max-width-carrier-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_load_header = _resolveComponent("plan-carrier-load-header")!
  const _component_plan_carrier_load_sub_header = _resolveComponent("plan-carrier-load-sub-header")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_tracking_map = _resolveComponent("plan-carrier-tracking-map")!
  const _component_plan_input_location = _resolveComponent("plan-input-location")!
  const _component_plan_input_text_area = _resolveComponent("plan-input-text-area")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_ctx.loadData !== null)
    ? (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
        key: 0,
        "show-footer": true,
        "show-header": false,
        "show-subheader": false
      }, {
        "header-content": _withCtx(() => [
          _createVNode(_component_plan_carrier_load_header)
        ]),
        "subheader-content": _withCtx(() => [
          _createVNode(_component_plan_carrier_load_sub_header)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex", _ctx.actualWidth <= 640 ? 'w-full justify-start items-start':'absolute top-[16px] left-[64px] plan-b-gray-50 rounded'])
            }, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.goToAcceptation,
                type: "cancel",
                label: _ctx.$t('go-back'),
                "show-icon": "",
                icon: "chevron-left",
                "icon-color": "#8A8B97"
              }, null, 8, ["onOnClick", "label"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["w-full flex flex-col justify-start items-center plan-gap-lg max-width-carrier-content", {'plan-mt-sm': _ctx.actualWidth > 640}])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex plan-gap-sm justify-center items-center", {'flex-col': _ctx.actualWidth <= 640 }])
              }, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('notify-position')), 1),
                _createElementVNode("span", _hoisted_3, "#" + _toDisplayString(_ctx.loadData.identifier), 1)
              ], 2)
            ], 2),
            _createVNode(_component_plan_carrier_tracking_map, { "center-in-pick-up": "" }),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.isDriver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('current-location')) + ": " + _toDisplayString(_ctx.currentLocation), 1)
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.isDriver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_plan_input_location, {
                      onChange: _ctx.changeInputLocation,
                      "hide-label": "",
                      "pre-country": _ctx.initialLocation.country,
                      "pre-location": _ctx.initialLocation.zip,
                      zipCodeSearch: false
                    }, null, 8, ["onChange", "pre-country", "pre-location"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_plan_input_text_area, {
                "model-value": _ctx.observations,
                "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.observations) = $event)),
                placeholder: _ctx.$t('add-observations'),
                rows: 4
              }, null, 8, ["model-value", "placeholder"])
            ])
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.goToAcceptation,
              disabled: _ctx.loadingPostNotifyPosition,
              type: "cancel",
              label: _ctx.$t('cancel'),
              class: "w-full"
            }, null, 8, ["onOnClick", "disabled", "label"]),
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.postNotifyPosition,
              loading: _ctx.loadingPostNotifyPosition,
              type: "primary",
              label: _ctx.$t('send'),
              class: "w-full"
            }, null, 8, ["onOnClick", "loading", "label"])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}