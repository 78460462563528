import customer from '@/services/customer/_api';

export default {
  async apiDelCustomers(context: any, { id }) {
    return await customer.delCustomers(id);
  },
  async apiGetCustomers(context: any, params: any) {
    const response = await customer.getCustomers(params);
    if (params.page === 1) {
      context.commit('setCustomersData', response);
    } else {
      context.commit('pushCustomersData', response);
    }
  },
  async apiPostCustomers(context: any, params) {
    return await customer.postCustomers(params);
  },
  async apiPutCustomers(context: any, { id, name }) {
    const params = { name: name };
    return await customer.putCustomers(id, params);
  },
  async clearCustomersSelected(context: any) {
    context.commit('clearCustomersSelected');
  },
  async clearCustomerStore(context: any) {
    context.commit('clearCustomerStore');
  },
  async pushCustomersSelectedData(context: any, payload: any) {
    context.commit('pushCustomersSelectedData', payload);
  },
  async setCustomersData(context: any, payload: any) {
    context.commit('setCustomersData', payload);
  },
  async setCustomersSelectedData(context: any, payload: any) {
    context.commit('setCustomersSelectedData', payload);
  },
};
