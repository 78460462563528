import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-text-md plan-t-prim-500 font-bold" }
const _hoisted_2 = { class: "plan-text-xs plan-t-prim-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "fadeInDown flex flex-col justify-center items-center fixed bg-white top-0 right-0 plan-gap-sm",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createVNode(_component_plan_icon, {
      name: "spinner",
      "icon-color": "#151830",
      "is-svg": "",
      size: "40",
      rotate: ""
    }),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('importing-contacts')) + "...", 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('importing-contacts-message')), 1)
  ], 4))
}