import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "plan-t-prim-100 font-bold"
}
const _hoisted_2 = {
  key: 1,
  class: "font-bold plan-t-prim-500"
}
const _hoisted_3 = {
  class: "w-full flex flex-col plan-gap-sm overflow-y-auto",
  style: {"max-height":"250px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_popper, {
      show: _ctx.showSelectItems,
      id: _ctx.id,
      interactive: "",
      offsetSkid: "0",
      offsetDistance: "1",
      placement: "bottom-start",
      class: "plan-multiselect"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", {
          class: "flex flex-col",
          style: _normalizeStyle(`min-width: ${_ctx.selectWidth - 34}px; width: ${_ctx.selectWidth - 34}px; max-width: ${
            _ctx.selectWidth - 34
          }px;`)
        }, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item,
                class: _normalizeClass([
                'flex justify-start items-center plan-gap-xs plan-p-sm cursor-pointer',
                { selected: _ctx.isSelected(item.value) },
              ]),
                style: {"border-bottom":"1px solid #e5e7eb"}
              }, [
                _createVNode(_component_plan_input_checkbox, {
                  onChange: ($event: any) => (_ctx.toggleLaneSelection(item.value)),
                  label: _ctx.$t(`${item.translation}`),
                  labelClass: 'text-blue-500',
                  class: "w-full"
                }, null, 8, ["onChange", "label"])
              ], 2))
            }), 128))
          ])
        ], 4)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showPopper && _ctx.showPopper(...args))),
          id: "showPopper",
          class: "plan-div-form w-[200px] flex justify-between items-center plan-p-sm"
        }, [
          (_ctx.selectedItems.length)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.selectedItems.length) + " " + _toDisplayString(_ctx.$t('selected')), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label ? _ctx.$t(_ctx.label) : `0 ${_ctx.$t('selected')}`), 1)),
          _createVNode(_component_plan_icon, {
            name: "chevron-down",
            "is-svg": "",
            "icon-color": "black"
          })
        ])
      ]),
      _: 1
    }, 8, ["show", "id"])
  ]))
}