import actions from './actions';
import getters from './getters'
import mutations from './mutations'

export const carrierState = {
  actualWidth: 0,
  loadData: null,
  userBid: null,
  openChat: false,
  carrierChat: null,
  isDriver: null,
  myDataForm: {
    hasModifications: false,
    type: '',
    firstName: '',
    lastName: '',
    email: '',
    prefix: '',
    phone: '',
    cif: '',
    companyName: '',
    companyAddress: '',
    dotNumber: '',
    mcNumber: ''
  },
  carrierPackageForm: {
    cargo: {
      id: -1,
      number: '',
      expirationDate: '',
      limitAmount: null,
      mediaObject: null
    },
    liabilityInsurance: {
      id: -1,
      number: '',
      expirationDate: '',
      limitAmount: null,
      mediaObject: null
    },
    cif: {
      number: '',
      mediaObject: null
    },
    mcNumber: {
      number: '',
      mediaObject: null
    }
  },
  drivers: [],
  vehicles: [],
  regionPrefixes: [],
  filesToUpload: {
    cargoInsurance: null,
    liabilityInsurance: null
  },
  trackingHistory: [],
  showNotifications: false,
  showShareLoad: false,
  actualPosition: {
    lat: null,
    lng: null
  }
};

export default {
  namespaced: true,
  state: { ...carrierState },
  actions,
  getters,
  mutations,
};