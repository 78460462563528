import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "plan-load-detail-header-content-buttons" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
      return (_openBlock(), _createElementBlock("div", {
        onClick: ($event: any) => (_ctx.selectButton(button.value)),
        key: button.value,
        class: _normalizeClass(["plan-load-detail-header-content-button", {'plan-load-detail-header-content-button-active': button.value === _ctx.selectedButton }])
      }, [
        _createElementVNode("span", null, _toDisplayString(button.title), 1),
        (button.subtitle.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(button.subtitle), 1))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}