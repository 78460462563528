
import { defineComponent, reactive, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'RenameTemplateCategoryModal',
  components: { PlanInputText, PlanDefaultModal, PlanButton },
  emits: ['close', 'closeAndUpdate'],
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive({
      categoryName: '',
      loading: false
    })

    const close = () => { emit('close') }

    const putCategory = () => {
      data.loading = true
      store.dispatch('LoadsStore/putEmailCategory', {
        id: store.getters['CompanyStore/getSelectedTemplateCategory'].id,
        name: data.categoryName
      }).then((response) => {
        emit('closeAndUpdate')
      }).finally(() => {
        data.loading = false
      })
    }

    data.categoryName = store.getters['CompanyStore/getSelectedTemplateCategory'].name

    return {
      ...toRefs(data),
      close,
      putCategory
    }
  }
})
