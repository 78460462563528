
import { defineComponent, PropType } from 'vue';

interface Texts {
  header: string;
  body: string;
}

interface Button {
  text: string;
  type: string;
  callback: () => void;
}

export default defineComponent({
  name: 'SuccessMessage',
  props: {
    textKeys: {
      type: Object as PropType<Texts>,
      required: true,
    },
    buttons: { type: Array as PropType<Button[]> },
  },
  components: {},
});
