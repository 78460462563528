
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useI18n } from 'vue-i18n';

interface planInputText {
  thereAreChanges: boolean;
}

export default defineComponent({
  name: 'PlanInputText',
  components: { PlanIcon },
  props: {
    mandatory: { type: Boolean, required: false, default: false },
    modelValue: { type: String, required: true },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    validations: { type: Array, required: false, default: () => [] },
    requirements: { type: Array, required: false, default: () => [] },
    hideLeft: { type: Boolean, required: false, default: false },
    hideRight: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    validateFromStart: { type: Boolean, required: false, default: false },
    password: { type: Boolean, required: false, default: false }
  },
  setup(props, { emit }) {
    const translate = useI18n()
    const data = reactive<planInputText>({
      thereAreChanges: false,
    });

    const changeTheValue = () => {
      data.thereAreChanges = true;
    };

    if (props.validateFromStart) changeTheValue()

    const internalValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const isComplete = computed(() => {
      let result = 0;
      if (props.requirements?.length > 0 && data.thereAreChanges) {
        if (props.requirements?.includes('greaterThan0')) {
          result += props.modelValue.length > 0 ? 1 : 0;
        }
      }
      return result === props.requirements?.length;
    });

    const validationResult = computed(() => {
      let result = '';
      if (props.validations?.length > 0 && data.thereAreChanges) {
        if (props.validations?.includes('empty')) {
          result = props.modelValue.length > 0 ? '' : translate.t('input-validations.empty');
        }

        if (props.validations?.includes('isMail')) {
          const regexMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          result = regexMail.test(props.modelValue) ? '' : translate.t('input-validations.isMail');
        }
      }
      return result;
    });

    watch(
      () => props.modelValue,
      (newValue) => {
        if (newValue) {
          emit('validation', validationResult.value);
        }
      },
    );

    return {
      ...toRefs(data),
      changeTheValue,
      internalValue,
      isComplete,
      validationResult,
    };
  },
});
