import lanes from '@/services/lanes/_api';

export default {
  async acceptQuote(context: any, data: any) {
    await lanes.acceptQuote(data);
  },
  async carrierQuoteLanes(context: any, data: any) {
    await lanes.carrierQuoteLanes(data);
  },
  async clearLanesStore(context: any) {
    context.commit('clearLanesStore');
  },
  async createBatchLanes(context: any, data: any) {
    await lanes.createBatchLanes(data);
  },
  async currentActiveGroup(context: any, data: any) {
    context.commit('currentActiveGroup', data);
  },
  async deleteGroupLane(context: any, data: any) {
    await lanes.deleteGroupLane(data);
  },
  async deleteLane(context: any, data: any) {
    const response = await lanes.deleteLane(data);
    return response;
  },
  async duplicateGroupLane(context: any, data: any) {
    await lanes.duplicateGroupLane(data);
  },
  async editingLanes(context: any, data: any) {
    context.commit('editingLanes', data);
  },
  async fetchLanes(context: any, data: any) {
    const response = await lanes.getLanesList(data);
    context.commit('setLanesList', response);
    if (response.length > 0) {
      context.commit('lastLaneListPeriod', response);
    }
  },
  async fetchLanesGroups(context: any, data: any) {
    const response = await lanes.getLanesGroupsList(data);
    context.commit('setLanesGroupsList', response);
    return response;
  },
  async finishQuotes(context: any, data: any) {
    await lanes.finishQuotes(data);
  },
  async getCurrentTendersByGroup(context: any, data: any) {
    const response = await lanes.getCurrentTendersByGroup(data);
    context.commit('getCurrentTendersByGroup', response);
  },
  async getLaneData(context: any, data: any) {
    const response = await lanes.getLaneData(data);
    context.commit('getLaneData', response);
  },
  async getLaneQuotedRanking(context: any, data: any) {
    const response = await lanes.getLaneQuotedRanking(data);
    if (!data.params.totals && !data.params.ranking) {
      context.commit('setLaneQuotes', response);
    } else {
      if (data.params.totals) {
        context.commit('setLaneQuotesTotals', response);
      } else {
        context.commit('setLaneRanking', response);
      }
    }
  },
  async getLanesTotals(context: any, data: any) {
    const response = await lanes.getLanesTotals(data);
    context.commit('setTotalsLanes', response);
  },
  async lanesRFQRequestBids(context: any, data: any) {
    await lanes.lanesRFQRequestBids(data);
  },
  async lanesRFQRequestBidsGroup(context: any, data: any) {
    const response = await lanes.lanesRFQRequestBidsGroup(data);
    context.commit('setGroupBids', response);
  },
  async lanesRFQRequestBidsGroupRequestMore(context: any, data: any) {
    await lanes.lanesRFQRequestBidsGroupRequestMore(data);
  },
  async lanesRFQRequestBidsGroupCompany(context: any, data: any) {
    const response = await lanes.lanesRFQRequestBidsGroupCompany(data);
    context.commit('setGroupBids', response);
  },
  async lanesRFQRequestBidsGroupOnlyBids(context: any, data: any) {
    const response = await lanes.lanesRFQRequestBidsGroupOnlyBids(data);
    context.commit('setBidsByCompany', response);
  },
  async lanesRFQRequestBidsGroupConfirm(context: any, data: any) {
    await lanes.lanesRFQRequestBidsGroupConfirm(data);
  },
  async moveGroupLane(context: any, data: any) {
    await lanes.moveGroupLane(data);
  },
  async moveRankingPosition(context: any, data: any) {
    await lanes.moveRankingPosition(data);
  },
  async planningLoadsBatch(context: any, data: any) {
    const response = await lanes.planningLoadsBatch(data);
    return response;
  },
  async postGroupLane(context: any, data: any) {
    const response = await lanes.postGroupLane(data);
    return response;
  },
  async rankingConfiguration(context: any, data: any) {
    const response = await lanes.rankingConfiguration(data);
  },
  async removeFromRanking(context: any, data: any) {
    await lanes.removeFromRanking(data);
  },
  async renameGroupLane(context: any, data: any) {
    await lanes.renameGroupLane(data);
  },
  async selectAssignedQuotesBatch(context: any, data: any) {
    const response = await lanes.selectAssignedQuotesBatch(data);
    return response;
  },
  async sendRequestEmail(context: any, data: any) {
    const response = await lanes.sendRequestEmail(data);
    return response;
  },
  async totalTenders(context: any, data: any) {
    const response = await lanes.totalTenders(data);
    context.commit('setTotalTenders', response);
  },
  async updateBiddingEndTime(context: any, data: any) {
    await lanes.updateBiddingEndTime(data);
  },
  async updateLanes(context: any, data: any) {
    const response = await lanes.updateLanes(data);
    return response;
  },
};
