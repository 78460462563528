import globalAxios from 'axios';

export default {
  async delCustomers(id: number) {
    const response = await globalAxios.delete(`/customers/${id}`);
    return Promise.resolve(response.data);
  },
  async getCustomers(params: object) {
    const response = await globalAxios.get('/customers', { params });
    return Promise.resolve(response.data);
  },
  async postCustomers(params: object) {
    const response = await globalAxios.post(`/customers`, params);
    return Promise.resolve(response.data);
  },
  async putCustomers(id: number, params: object) {
    const response = await globalAxios.put(`/customers/${id}`, params);
    return Promise.resolve(response.data);
  }
};
