
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';

interface planNewContactTags {
  newTag: string;
  tags: Array<string>;
}

export default defineComponent({
  name: 'PlanNewContactTags',
  components: { PlanIcon, PlanButton, PlanInputText },
  props: {
    previousTags: { type: String, required: false, default: '' },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const store = useStore();
    const data = reactive<planNewContactTags>({
      newTag: '',
      tags: [],
    });

    if (props.previousTags !== '') data.tags = JSON.parse(props.previousTags);

    const addTag = () => {
      if (!data.tags.includes(data.newTag)) {
        data.tags.push(data.newTag);
        data.newTag = '';
        emit('change', data.tags);
      }
    };

    const deleteTag = (tag: string) => {
      data.tags.splice(data.tags.indexOf(tag), 1);
      emit('change', data.tags);
    };

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    return {
      ...toRefs(data),
      addTag,
      deleteTag,
    };
  },
});
