
import { defineComponent, computed, reactive, toRefs } from 'vue';
import QuickFilter from '@/components/filters/QuickFilter.vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';

interface quickFilters {
  pickupToday: number,
  pickupWeek: number,
  deliveryToday: number,
  deliveryWeek: number
}

export default defineComponent({
  name: 'QuickFilters',
  components: { QuickFilter },
  emits: ['change'],
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive<quickFilters>({
      pickupToday: 0,
      pickupWeek: 0,
      deliveryToday: 0,
      deliveryWeek: 0
    })

    const isQuickFilterActive = computed(() => {
      return store.getters['LoadsStore/getSelectedQuickFilter'].length > 0
    })

    const totals = computed(() => {
      return store.state.LoadsStore.plannerTotalsDayWeek
    })

    const totalsClaims = computed(() => {
      return store.state.LoadsStore.totalClaims
    })

    const userRegion = computed(() => {
      return store.getters['UserStore/getRegion'] === 'EUROPE' ? 'Europe' : 'NorthAmerica'
    })

    const getQuickFiltersTotal = () => {
      store.dispatch('LoadsStore/getQuickFiltersTotal').then((response) => {
        data.pickupToday = response.pickup.today.amount
        data.pickupWeek = response.pickup.thisWeek.amount
        data.deliveryToday = response.delivery.today.amount
        data.deliveryWeek = response.delivery.thisWeek.amount
      })
    }

    getQuickFiltersTotal()

    const change = () => {
      emit('change')
    }

    return {
      ...toRefs(data),
      isQuickFilterActive,
      totals,
      totalsClaims,
      userRegion,
      change
    }
  }
})
