import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-full flex justify-center items-center"
}
const _hoisted_2 = {
  class: "flex justify-start items-start flex-wrap plan-gap-md overflow-y-auto",
  style: {"max-width":"620px","max-height":"396px"}
}
const _hoisted_3 = { class: "m-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_plan_template_button = _resolveComponent("plan-template-button")!
  const _component_plan_email_template_category_title = _resolveComponent("plan-email-template-category-title")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.$t('select-email-template'),
    size: "lg-auto",
    "hide-close-button": ""
  }, {
    "header-buttons": _withCtx(() => [
      _createVNode(_component_plan_button, {
        onOnClick: _ctx.addTemplate,
        type: "primary-white",
        label: _ctx.$t('add-template')
      }, null, 8, ["onOnClick", "label"])
    ]),
    content: _withCtx(() => [
      (_ctx.loadingTemplates)
        ? (_openBlock(), _createBlock(_component_plan_loading, {
            key: 0,
            message: _ctx.$t('loading-your-templates')
          }, null, 8, ["message"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templatesPlanimatik, (template) => {
                  return (_openBlock(), _createBlock(_component_plan_template_button, {
                    key: template,
                    onOnClick: ($event: any) => (_ctx.viewTemplate(template, null)),
                    title: template?.name,
                    user: template.createdBy?.name,
                    date: template.createdAt,
                    "is-planimatik-template": ""
                  }, null, 8, ["onOnClick", "title", "user", "date"]))
                }), 128))
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templates, (category) => {
                return (_openBlock(), _createBlock(_component_plan_email_template_category_title, {
                  key: category,
                  onCloseAndUpdate: _ctx.getTemplates,
                  category: category,
                  templates: category.emailTemplates.length,
                  title: category.name
                }, {
                  "category-content": _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.emailTemplates, (template) => {
                      return (_openBlock(), _createBlock(_component_plan_template_button, {
                        key: template,
                        onOnClick: ($event: any) => (_ctx.viewTemplate(template, category)),
                        title: template?.name,
                        user: template.createdBy?.name,
                        date: template.createdAt
                      }, null, 8, ["onOnClick", "title", "user", "date"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["onCloseAndUpdate", "category", "templates", "title"]))
              }), 128))
            ])
          ]))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_plan_button, {
        onOnClick: _ctx.close,
        type: "cancel",
        label: _ctx.$t('cancel')
      }, null, 8, ["onOnClick", "label"])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}