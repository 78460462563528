
import { defineComponent, computed, reactive, toRefs, watch, onMounted } from 'vue';
import * as Routing from '@/router/Routing';
import moment from 'moment';
import { useStore } from 'vuex';
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import LoadCard from '@/components/loads/card/LoadCard.vue';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ShipperLoadDetailContent from '@/modules/planning/loadDetail/ShipperLoadDetailContent.vue';
import PlanDefaultDeleteModal from '@/components/defaults/PlanDefaultDeleteModal.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanDefaultConfirmationModal from '@/components/defaults/PlanDefaultConfirmationModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import { onBeforeMount } from 'vue';

export default defineComponent({
  name: 'DayDetail',
  components: {
    PlanInputText,
    PlanButton,
    PlanDefaultConfirmationModal,
    PlanDefaultModal,
    PlanDefaultDeleteModal,
    ShipperLoadDetailContent,
    PlanTabs,
    LoadCard,
    PlanLoading,
    PlanTextButton,
    PlanButtonIcon,
    PlanDefaultView,
  },
  props: {
    date: { type: String, required: true, default: '' },
    id: { type: String, required: false, default: '' },
    projectId: { type: String, required: false, default: '' },
    laneId: { type: String, required: false, default: '' },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const router = useRouter();

    const saveFilters = () => {
      const filters = JSON.parse(JSON.stringify(store.getters['LoadsStore/getAppliedFilters']));

      store.commit('LoadsStore/setPreviousFilters', filters);
    };

    onBeforeMount(() => {
      // saveFilters();
    });

    const data = reactive({
      firstLoading: false,
      isEditable: false,
      loadId: '',
      loads: [],
      loadsTotals: {
        TOTAL: 0,
        FINISHED: 0,
        QUOTING: 0,
        CONFIRMED: 0,
        IN_PROGRESS: 0,
        ASSIGNED: 0,
        CANCELED: 0,
      },
      loadingCancel: false,
      loadingDelete: false,
      loadingLoads: false,
      loadingTemplate: false,
      page: 1,
      selectedTotalTab: { index: 0, loadParamFilter: '' },
      showModalCancel: -1,
      showModalDelete: -1,
      showModalTemplate: -1,
      showModalUpdate: -1,
      templateName: '',
      totalDates: 0,
    });

    const tabTotalsMap = {
      QUOTING: 1,
      CONFIRMED: 2,
      ASSIGNED: 3,
      IN_PROGRESS: 4,
      FINISHED: 5,
      CANCELED: 6,
    };

    const getAppliedFilters = computed(() => {
      return store.getters['LoadsStore/getAppliedFilters'];
    });

    const getPreviousFilters = computed(() => {
      return store.getters['LoadsStore/getPreviousFilters'];
    });

    const selectedTotalKey =
      tabTotalsMap[store.getters['LoadsStore/getAppliedFilters']['STATUS']?.value];

    data.selectedTotalTab = {
      index: selectedTotalKey || 0,
      loadParamFilter: store.getters['LoadsStore/getAppliedFilters']['STATUS']?.value || '',
    };

    const dateToShow = computed(() => {
      return moment(props.date).format('MMM DD, YYYY');
    });

    const totalTabs = computed(() => {
      return [
        { label: translate.t('total'), quantity: data.loadsTotals.TOTAL },
        { label: translate.t('quoting'), quantity: data.loadsTotals.QUOTING },
        { label: translate.t('confirmed'), quantity: data.loadsTotals.CONFIRMED },
        { label: translate.t('assigned'), quantity: data.loadsTotals.ASSIGNED },
        { label: translate.t('in_progress'), quantity: data.loadsTotals.IN_PROGRESS },
        { label: translate.t('finished'), quantity: data.loadsTotals.FINISHED },
        { label: translate.t('canceled'), quantity: data.loadsTotals.CANCELED },
      ];
    });

    const cancelLoad = () => {
      data.loadingCancel = true;
      store.dispatch('LoadsStore/cancelLoad', data.showModalCancel).then(() => {
        setFiltersOfDay();
        getLoadsInfo();
        data.showModalCancel = -1;
        data.loadingCancel = false;
        store.dispatch('UserStore/showToast', translate.t('success-canceled-load'));
      });
    };

    const changeTab = (indexTab: number) => {
      data.loads = [];
      data.page = 1;
      data.firstLoading = false;
      data.selectedTotalTab.index = indexTab;
      if (indexTab === 0) data.selectedTotalTab.loadParamFilter = '';
      if (indexTab === 1) data.selectedTotalTab.loadParamFilter = 'QUOTING';
      if (indexTab === 2) data.selectedTotalTab.loadParamFilter = 'CONFIRMED';
      if (indexTab === 3) data.selectedTotalTab.loadParamFilter = 'ASSIGNED';
      if (indexTab === 4) data.selectedTotalTab.loadParamFilter = 'IN_PROGRESS';
      if (indexTab === 5) data.selectedTotalTab.loadParamFilter = 'FINISHED';
      if (indexTab === 6) data.selectedTotalTab.loadParamFilter = 'CANCELED';
      store.commit('LoadsStore/applyFilter', {
        id: 'STATUS',
        value: data.selectedTotalTab.loadParamFilter,
        loadFilterParam: data.selectedTotalTab.loadParamFilter,
      });
      getLoadsInfo();
    };

    const createTemplate = () => {
      data.loadingTemplate = true;
      store
        .dispatch('LoadsStore/postSaveLoads', {
          load: { id: data.showModalTemplate },
          user: { id: store.getters['UserStore/getCurrentUser'].id },
          templateName: data.templateName,
        })
        .then(() => {
          data.loadingTemplate = false;
          store.dispatch('UserStore/showToast', translate.t('message-load-template-created'));
          data.templateName = '';
          data.showModalTemplate = -1;
        });
    };

    const deleteLoad = () => {
      data.loadingDelete = true;
      store.dispatch('LoadsStore/deleteLoad', data.showModalDelete).then(() => {
        setFiltersOfDay();
        getLoadsInfo();
        data.showModalDelete = -1;
        data.loadingDelete = false;
        store.dispatch('UserStore/showToast', translate.t('success-delete-load'));
      });
    };

    const getLoadsInfo = async () => {
      let query = store.getters['LoadsStore/getAppliedFiltersQuery'];

      query.page = 1;
      query.maxResults = 10;
      query.infoType = ['LOADS', 'TOTALS', 'TOTALS_DAY_WEEK', 'TOTALS_DATES'];

      if (props.laneId) {
        query.lanesRFPId = +props.laneId;
      } else if (props.projectId) {
        query.projectId = +props.projectId;
      }

      data.loadingLoads = true;

      await store
        .dispatch('LoadsStore/getLoadsInfo', query)
        .then((response) => {
          if (data.page === 1) {
            data.loads = response.data.loads;
          } else {
            data.loads = data.loads.concat(response.data.loads);
          }

          response.data.totals.forEach(function (total: any) {
            if (total.status === 'TOTAL') data.loadsTotals.TOTAL = total.total;
            if (total.status === 'FINISHED') data.loadsTotals.FINISHED = total.total;
            if (total.status === 'QUOTING') data.loadsTotals.QUOTING = total.total;
            if (total.status === 'CONFIRMED') data.loadsTotals.CONFIRMED = total.total;
            if (total.status === 'IN_PROGRESS') data.loadsTotals.IN_PROGRESS = total.total;
            if (total.status === 'ASSIGNED') data.loadsTotals.ASSIGNED = total.total;
            if (total.status === 'CANCELED') data.loadsTotals.CANCELED = total.total;
          });

          data.totalDates = response.data.totalsDates;
          data.firstLoading = true;
        })
        .catch((err) => {
          data.loadingLoads = false;
        });

      data.loadingLoads = false;
    };

    const goToDay = (type: string) => {
      data.loads = [];
      if (type === 'next') {
        router.push({
          name: 'dayDetail',
          params: { date: moment(props.date).add(1, 'days').format('YYYY-MM-DD') },
        });
      } else if (type === 'previous') {
        router.push({
          name: 'dayDetail',
          params: { date: moment(props.date).add(-1, 'days').format('YYYY-MM-DD') },
        });
      } else {
        // Set previousFilters on applyFilters
        store.commit(
          'LoadsStore/setAppliedFilters',
          store.getters['LoadsStore/getPreviousFilters'],
        );
        router.push({ name: 'planning' });
      }
      if (type !== 'back') {
        setFiltersOfDay();
      }
      getLoadsInfo();
    };

    const onOptionsClick = (params: any) => {
      if (params.type === 'cancel') {
        data.showModalCancel = params.id;
      } else if (params.type === 'delete') {
        data.showModalDelete = params.id;
      } else if (params.type === 'clone') {
        store.commit('LoadsStore/currentLoadEditing', null);
        store.dispatch('LoadsStore/currentLoadEditing', params.load);
        store.dispatch('LoadsStore/loadId', params.id);
        store.dispatch('LoadsStore/loadById', params.id);
        router.push('/planning/newLoad');
      } else if (params.type === 'createTemplate') {
        data.showModalTemplate = params.id;
        data.templateName = '';
      } else if (params.type === 'modify') {
        data.loadId = params.id;
        data.showModalUpdate = params.id;
        data.isEditable = true;
      }
    };

    const setFiltersOfDay = () => {
      saveFilters();
      store.commit('LoadsStore/applyFilter', {
        id: 'pickup-date',
        status: getAppliedFilters.value.status || '',
        value: moment(props.date).format('YYYY-MM-DD'),
        loadFilterParam:
          moment(props.date).format('YYYY-MM-DD') + '#' + moment(props.date).format('YYYY-MM-DD'),
      });
    };

    const showLoadDetails = (id: any) => {
      data.loadId = id;
      data.isEditable = false;
      router.push({ name: 'dayDetailId', params: { id: id } });
    };

    setFiltersOfDay();
    getLoadsInfo();

    if (props.id.length > 0) {
      data.loadId = props.id + '';
      data.isEditable = false;
    }

    watch(
      () => props.date,
      (newValue, oldValue) => {
        setFiltersOfDay();
        getLoadsInfo();
      },
    );

    return {
      ...toRefs(data),
      dateToShow,
      totalTabs,
      Routing,
      saveFilters,
      getPreviousFilters,
      cancelLoad,
      changeTab,
      deleteLoad,
      createTemplate,
      goToDay,
      onOptionsClick,
      getAppliedFilters,
      showLoadDetails,
    };
  },
  beforeRouteLeave(to, from, next) {
    const store = this.$store;
    const filtersSaved = store.getters['LoadsStore/getPreviousFilters'];
    store.dispatch('LoadsStore/savePreviousFilters', filtersSaved);
    next();
  },
});
