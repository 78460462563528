
import { defineComponent } from 'vue';

export default defineComponent ({
  data() {
    return {
      scrollY: 0,
      routesArray: ['quoteStep','directAssignStep']
    };
  },
  computed: {
    checkRoute(): boolean {
      return this.routesArray.includes(this.$route.name as string);
    },
    showButton(): boolean {
      return this.scrollY > 500;
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
  },
  methods: {
    backToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
});
