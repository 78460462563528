
import { defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanInputLocation from '@/components/forms/PlanInputLocation.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanRfpLane from '@/components/rfp/PlanRfpLane.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';

export default defineComponent({
  name: 'UiPrimitivesTab',
  components: { PlanInputNumber },
  setup () {
    const store = useStore()

    const data = reactive({
      inputNumber: 123456.78,
      items: [
        { pickup: '', delivery: '', stops: 3, requestInformation: '', vehicle: '', weight: '', commodities: '', loadFrecuency: '', stars: '', end: '' }
      ],
      headers: [
        { label: 'Pickup', value: 'a', sortable: false },
        { label: 'Delivery', value: 's', sortable: false },
        { label: 'Stops', value: 'd', sortable: false },
        { label: 'Request information', value: 'e', sortable: false },
        { label: 'Vehicle', value: 'c', sortable: false },
        { label: 'Weight', value: 'x', sortable: false },
        { label: 'Commodities', value: 'z', sortable: false },
        { label: 'Load / frecuency', value: 'u', sortable: false },
        { label: 'Starts', value: 'i', sortable: false },
        { label: 'End', value: 'p', sortable: false },
        { label: '', value: 'actions', sortable: false }
      ],
      colors: [
        { name: 'white', value: '#FFFFFF' },
        { name: 'black-80', value: '#22282B' },
        { name: 'black', value: '#0D0E10' },
        { name: 'gray-50', value: '#f9fafb' },
        { name: 'gray-100', value: '#eff0f3' },
        { name: 'gray-200', value: '#E5E7EB' },
        { name: 'gray-800', value: '#1F2937' },
        { name: 'gray-950', value: '#030712' },
        { name: 'comp-100', value: '#D9FFFF' },
        { name: 'comp-200', value: '#BFFFFF' },
        { name: 'comp-500', value: '#00FFFF' },
        { name: 'comp-600', value: '#00D9D9' },
        { name: 'comp-700', value: '#00BFBF' },
        { name: 'prim-50', value: '#D3D4DA' },
        { name: 'prim-100', value: '#8A8B97' },
        { name: 'prim-200', value: '#4F5264' },
        { name: 'prim-500', value: '#151830' },
        { name: 'sec-50', value: '#C7E8FF' },
        { name: 'sec-100', value: '#80CBFF' },
        { name: 'sec-200', value: '#59BBFF' },
        { name: 'sec-500', value: '#0097FF' },
        { name: 'sec-600', value: '#0071BF' },
        { name: 'sec-800', value: '#004C80' },
        { name: 'green-100', value: '#B2F4CA' },
        { name: 'green-200', value: '#80EDA7' },
        { name: 'green-500', value: '#00DB50' },
        { name: 'green-600', value: '#00BF46' },
        { name: 'green-700', value: '#00A63D' },
        { name: 'yellow-100', value: '#FFFDDE' },
        { name: 'yellow-200', value: '#FFFCC9' },
        { name: 'yellow-500', value: '#FFF426' },
        { name: 'yellow-600', value: '#E6DB22' },
        { name: 'yellow-700', value: '#BFB61C' },
        { name: 'amber-100', value: '#FAE2A6' },
        { name: 'amber-200', value: '#F7CE67' },
        { name: 'amber-500', value: '#F1AD02' },
        { name: 'amber-600', value: '#CC9202' },
        { name: 'amber-700', value: '#B38002' },
        { name: 'orange-100', value: '#F9BDA7' },
        { name: 'orange-200', value: '#F69471' },
        { name: 'orange-500', value: '#F15A24' },
        { name: 'orange-600', value: '#CC4C1F' },
        { name: 'orange-700', value: '#B3421B' },
        { name: 'red-100', value: '#FCD9D9' },
        { name: 'red-500', value: '#EF4444' }
      ]
    })

    const copyColorToClipboard = (color: any) => {
      navigator.clipboard.writeText(color.value)
        .then(() => {
          store.dispatch('UserStore/showToast', `Color ${color.name.toUpperCase()} has been copied to the clipboard.`)
        })
    }

    return {
      ...toRefs(data),
      copyColorToClipboard
    }
  }
})
