
import { defineComponent } from 'vue';
import { ExclamationTriangleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CarrierHeader from '@/components/headers/CarrierHeader.vue';
import NotFound from '@/components/messages/NotFound.vue';
import StatusIncorrect from '@/modules/carrier/acceptService/_components/StatusIncorrect.vue';
import Success from '@/components/toast/Success.vue';
// Views
import YourData from '@/modules/carrier/acceptService/AcceptServiceStep1.vue';
import DriverVehicle from '@/modules/carrier/acceptService/AcceptServiceStep2.vue';
import ServiceInsurances from '@/modules/carrier/acceptService/AcceptServiceStep3.vue';
import SmallModalSuccess from '@/modules/planning/loadDetail/success/SmallModalSuccess.vue';

export default defineComponent({
  name: 'ServiceAcceptance',
  components: {
    CarrierHeader,
    DriverVehicle,
    ExclamationTriangleIcon,
    NotFound,
    ServiceInsurances,
    SmallModalSuccess,
    StatusIncorrect,
    Success,
    XCircleIcon,
    YourData,
  },
  mixins: [BaseComponent],
  props: {
    shippmentId: {
      type: String,
    },
    token: {
      type: String,
    },
  },
  data() {
    return {
      step: 1,
      isFilledRC: false,
      shipperSignRC: false,
      currentStep: '',
      notFound: false,
      completed: false,
      differentStatus: false,
      successTexts: {
        header: this.$t('completed'),
        body: this.$t('all-is-done'),
      },
      successEmailPending: {
        header: this.$t('completed'),
        body: this.$t('mail-pending'),
      },
      successButton: {
        text: this.$t('close-window'),
        type: 'close',
        callback: () => (window as any).open('', '_self').close(),
      },
      buttonsModalBasic: [
        {
          text: this.$t('complete-rc'),
          type: 'success',
          callback: () => this.completeRC(),
        },
        {
          text: this.$t('complete-rc-later'),
          type: 'later',
          callback: () => this.sendEmail(),
        },
      ],
    };
  },
  computed: {
    companyhasTWICCard(): any {
      return this.currentCompany.hasTWICCard;
    },
    hasPortStop(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.loads[0] !== 'undefined'
      ) {
        const stops = this.shipment.loads[0].stops;
        return stops.some((stop) => stop.portStop === true || stop.airportStop === true);
      } else {
        return null;
      }
    },
    getW9Insurance(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.mediaObjects.find((media) => media.type === 'W9');
      }
      return undefined;
    },
    getDOTMCInsurance(): any {
      if (this.currentCompany && this.currentCompany.insurances) {
        return this.currentCompany.mediaObjects.find((media) => media.type === 'DOT_MC_NUMBER');
      }
      return undefined;
    },
    hasDriver(): any {
      return (
        this.shipment.carrierValidation.hasDriver && this.shipment.carrierValidation.hasVehicle
      );
    },
    isInsuranceValid(): any {
      const { carrierInsuranceValidity } = this.shipment;
      if (carrierInsuranceValidity) {
        const { InsuranceCargoValidity, InsuranceLiabilityValidity } = carrierInsuranceValidity;
        if (this.selectedOption === 'BROKER') {
          return InsuranceLiabilityValidity && this.getW9Insurance && this.getDOTMCInsurance;
        } else {
          return (
            InsuranceCargoValidity &&
            InsuranceLiabilityValidity &&
            this.getW9Insurance &&
            this.getDOTMCInsurance
          );
        }
      } else {
        return false;
      }
    },
    shipmentAcceptCompleted(): any {
      return this.shipment.shipmentAcceptCompleted;
    },
    selectedOption(): string {
      return this.currentCompany.type;
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
    allDocumentsDone(): any {
      const obj = this.shipment;
      return obj.shipmentAcceptCompleted;
    },
    currentCompany(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    consigneeFilledValue(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.loads[0].isConsigneeFilled !== 'undefined'
      ) {
        return this.shipment.loads[0].isConsigneeFilled;
      } else {
        return null;
      }
    },
    insuranceValidation(): any {
      const obj = this.shipment;
      return obj.shipmentAcceptCompleted;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    currentComponent(): any {
      switch (this.currentStep) {
        case 'DriverVehicle':
          return DriverVehicle;
        case 'ServiceInsurances':
          return ServiceInsurances;
        default:
          return YourData;
      }
    },
  },
  async beforeMount() {
    if (this.token) {
      console.log(this.currentUser);
      console.log(this.isSuplanting);
      try {
        if (this.shipment?.status && this.shipment?.status !== 'CONFIRMED') {
          this.differentStatus = true;
        }
        if (this.currentUser) {
          await this.$store.dispatch('CompanyStore/getCompany', this.currentUser?.company?.id);
        }
        let response = await this.$store.dispatch('LoadsStore/checkRateConfirmation', {
          id: this.shipment?.loads[0]?.id,
        });

        this.isFilledRC = response.isConsigneeFilled;
      } catch (err) {
        if (err) {
          console.log(err);
          BaseComponent.methods?.showToastError(err);
        }
      }
    }

    if (this.hasPortStop && !this.companyhasTWICCard) {
      this.currentStep = '';
    } else if (this.isInsuranceValid) {
      this.currentStep = 'DriverVehicle';
    } else {
      this.currentStep = 'ServiceInsurances';
    }
  },
  methods: {
    pushHome() {
      this.$emit('completed');
    },
    changetoCompleted() {
      this.$emit('completed');
    },
    sendEmail() {
      this.$store
        .dispatch('LoadsStore/postEmailForRate', {
          shipment: this.$route.params.id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('notification-rate-confirmation'));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.log('');
        });
    },
    completeRC() {
      this.$emit('not');
    },
    nextStep(step: string) {
      this.currentStep = step;
    },
    setCompleted(value: boolean) {
      this.completed = value;
    },
  },
});
