import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "space-y-2" }
const _hoisted_2 = { class: "flex flex-row gap-x-6" }
const _hoisted_3 = { class: "text-gray-400" }
const _hoisted_4 = { class: "flex flex-col gap-y-4 text-plan-primary-700" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.orderedTrackingLogs, (log, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "border border-gray-300 py-6 px-6 text-base leading-7 divide-gray-50 font-bold"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(`${_ctx.formatDate(log.dateLastUpdated)},
                        ${$setup.dateStringGetLocalHours(log.dateLastUpdated)}`), 1),
          _createElementVNode("div", _hoisted_4, [
            (log.stopMediaObject)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('load-completed', {
                type: $setup.formatLoadType(log.stopMediaObject.type),
                place: $setup.formatCity(log.stopMediaObject.type),
              })), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_6, [
                  _createTextVNode(_toDisplayString(log?.tracking?.observations || _ctx.$t('position-updated')) + " ", 1),
                  (log?.tracking?.location?.city && log?.tracking?.location?.state?.iso)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(log.tracking.location.city + ', ' + log.tracking.location.state.iso), 1))
                    : _createCommentVNode("", true)
                ])),
            (log.stopMediaObject)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 2,
                  class: "text-blue-400 cursor-pointer",
                  onClick: ($event: any) => (
              $setup.ViewDocuments(
                log.stopMediaObject.type,
                log.stopMediaObject.id,
                log.stopMediaObject.entityClass,
              )
            )
                }, _toDisplayString(_ctx.$t('view')) + " " + _toDisplayString(log.stopMediaObject.type) + " #" + _toDisplayString(log.stopMediaObject.type === 'BOL' ? 1 : 2), 9, _hoisted_8))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    }), 128))
  ]))
}