import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center items-center plan-gap-sm" }
const _hoisted_2 = { class: "plan-text-xl text-center font-bold" }
const _hoisted_3 = {
  key: 0,
  class: "plan-t-prim-200"
}
const _hoisted_4 = { class: "plan-mt-xl w-full flex justify-end items-center plan-gap-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.title,
    size: "xs-auto",
    "only-content": "",
    "hide-close-button": ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_ctx.message.length > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.message), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.close,
            disabled: _ctx.loading,
            type: "cancel",
            label: _ctx.$t('cancel'),
            class: "w-full"
          }, null, 8, ["onOnClick", "disabled", "label"]),
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.accept,
            loading: _ctx.loading,
            type: "primary",
            label: _ctx.$t(_ctx.acceptKey),
            class: "w-full"
          }, null, 8, ["onOnClick", "loading", "label"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}