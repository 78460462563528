function getUserDefaultImage(name: string) {
  const tailwindColors = [
    'bg-red-500',
    'bg-orange-500',
    'bg-yellow-500',
    'bg-green-500',
    'bg-teal-500',
    'bg-blue-500',
    'bg-indigo-500',
    'bg-purple-500',
    'bg-pink-500',
    'bg-gray-500',
    'bg-gray-600',
  ];
  let randomColor = tailwindColors[0];
  if (!name || name === undefined || name === '') {
    name = ':';
  } else {
    name = name.trim();
    const d = name.toUpperCase().split(' ');
    const i = d.map((item) => {
      return item.substring(0, 1);
    });
    name = i.join('').substring(0, 2);
    const firstCharCode = name.charCodeAt(0);
    const colorIndex = Math.abs(firstCharCode) % tailwindColors.length;
    randomColor = tailwindColors[colorIndex % tailwindColors.length];
  }

  return { name, color: randomColor };
}

export { getUserDefaultImage };
