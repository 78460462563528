
import { defineComponent, computed, reactive, toRefs, onBeforeMount } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';
import SidebarUser from '@/components/sidebar/SidebarUser.vue';

export default defineComponent({
  name: 'PlanCarrierRfpQuoteHeader',
  components: { PlanButtonIcon },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      loadingActivitySummary: false,
      notifications: 0,
      showCarrierResume: false,
    });

    onBeforeMount(() => {
      store.commit('CarrierStore/setShowNotifications', false);
    });

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    const bellButtonIconStyle = computed(() => {
      let buttonSize = '44px';

      if (actualWidth.value <= 640) buttonSize = '38px';

      return {
        'min-width': buttonSize,
        'min-height': buttonSize,
      };
    });

    const showNotifications = () => {
      store.commit('CarrierStore/setShowNotifications', true);
    };

    const getCarrierResume = async () => {
      data.loadingActivitySummary = true;
      await store
        .dispatch('ShipmentStore/getActivitySummary', 'QUOTES')
        .then((response) => {
          data.notifications = response.totals.total;
        })
        .finally(() => {
          data.loadingActivitySummary = false;
        });
    };

    getCarrierResume();

    return {
      ...toRefs(data),
      actualWidth,
      getCarrierResume,
      showNotifications,
      bellButtonIconStyle,
      isDriver,
      currentProject,
    };
  },
});
