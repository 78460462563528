<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <div style="z-index: 99999;" class="fixed bottom-0 w-full">
    <div
      v-for="(item, i) in filteredNotificationItems"
      :key="i"
      class="w-full h-auto relative p-3"
    >
      <div
        v-if="item.type === 'bid'"
        class="bg-blue-100 px-5 py-3.5 rounded-lg shadow hover:shadow-xl max-w-sm mx-auto transform hover:-translate-y-[0.125rem] transition duration-100 ease-linear"
      >
        <div class="w-full flex items-center justify-between">
          <span class="text-sm font-semibold">{{ $t('new-bid') }} | {{ item.data.company }} </span>
          <button
            @click="item.visible = false"
            class="-mr-1 bg-slate-100 hover:bg-slate-200 text-slate-400 hover:text-slate-600 h-5 w-5 rounded-full flex justify-center items-center"
          >
            <svg
              class="h-2 w-2 fill-current items-center"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
              />
            </svg>
          </button>
        </div>
        <div
          class="flex items-center mt-2 rounded-lg px-1 py-1 cursor-pointer"
          @click="pushTo(item.data.loadId)"
        >
          <div class="relative flex flex-shrink-0 items-end">
            <!-- <img
              class="h-16 w-16 rounded-full"
              src="https://i.pravatar.cc/300"
            /> -->
            <span
              class="flex justify-center items-center h-9 w-9 p-3 rounded-full ring-2 ring-white text-center text-white"
              :class="getUserDefaultImage(item.data.company)?.color"
            >
              {{ getUserDefaultImage(item.data.company)?.name }}
            </span>
            <span
              class="absolute h-4 w-4 bg-green-400 rounded-full bottom-0 right-0 border-2 border-white"
            ></span>
          </div>
          <div class="ml-3">
            <span class="font-semibold text-lg tracking-tight">{{ $t('bid-info') }}</span>
            <p class="text-sm leading-4 pt-2 italic opacity-70">
              {{
                $t('bid-for-load', {
                  quote: formatPrice(item.data.quote),
                  id: `#23-00${item.data.loadId}`,
                })
              }}
            </p>
            <span class="text-[10px] text-blue-500 font-medium leading-4 opacity-75"></span>
          </div>
        </div>
      </div>
      <div
        v-if="item.type === 'general'"
        class="bg-blue-100 px-5 py-3.5 rounded-lg shadow hover:shadow-xl max-w-sm mx-auto transform hover:-translate-y-[0.125rem] transition duration-100 ease-linear"
      >
        <div class="w-full flex items-center justify-between">
          <span class="text-sm font-semibold">{{ item.data.title }}</span>
          <button
            @click="item.visible = false"
            class="-mr-1 bg-slate-100 hover:bg-slate-200 text-slate-400 hover:text-slate-600 h-5 w-5 rounded-full flex justify-center items-center"
          >
            <svg
              class="h-2 w-2 fill-current items-center"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
              />
            </svg>
          </button>
        </div>
        <div
          @click="pushTo(item.data.loadId)"
          class="flex items-center mt-2 rounded-lg px-1 py-1 cursor-pointer"
        >
          <div class="relative flex flex-shrink-0 items-end">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-16 h-16 rounded-2xl p-3 border border-blue-100 text-blue-400 bg-blue-50"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
          <div class="ml-3">
            <span class="font-semibold text-lg tracking-tight">{{ item.data.text }}</span>
            <span class="text-[10px] text-blue-500 font-medium leading-4 opacity-75"></span>
          </div>
        </div>
      </div>
      <div
        v-if="item.type === 'generalButtons'"
        class="bg-blue-100 px-5 py-3.5 rounded-lg shadow hover:shadow-xl max-w-sm mx-auto transform hover:-translate-y-[0.125rem] transition duration-100 ease-linear"
      >
        <div class="w-full flex items-center justify-between">
          <span class="text-sm font-semibold"></span>
          <button
            @click="item.visible = false"
            class="-mr-1 bg-slate-100 hover:bg-slate-200 text-slate-400 hover:text-slate-600 h-5 w-5 rounded-full flex justify-center items-center"
          >
            <svg
              class="h-2 w-2 fill-current items-center"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
              />
            </svg>
          </button>
        </div>
        <div class="flex items-center mt-2 rounded-lg px-1 py-1">
          <div class="relative flex flex-shrink-0 items-end mb-12"></div>
          <div class="ml-3">
            <span class="font-semibold text-lg tracking-tight">
              {{ $t('load-with-param', { id: `#23-00${item.data.loadId}` }) }}
              {{ $t('toast-general-info-change') }}
              {{ item.data.status }}
            </span>
            <span class="text-[10px] text-blue-500 font-medium leading-4 opacity-75"></span>
            <div class="ml-3 text-sm font-normal">
              <div class="grid grid-cols-2 gap-2 pt-5 mr-6">
                <div>
                  <a
                    @click="pushTo(item.data.loadId)"
                    class="cursor-pointer inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                  >
                    {{ $t('check') }}
                  </a>
                </div>
                <div>
                  <a
                    @click="item.visible = false"
                    class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                  >
                    {{ $t('not-now') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="item.type === 'message'"
        @click="openChat(item.message)"
        class="bg-blue-100 px-5 py-3.5 rounded-lg shadow hover:shadow-xl max-w-sm mx-auto transform hover:-translate-y-[0.125rem] transition duration-100 ease-linear"
      >
        <div class="flex items-center mt-2 rounded-lg px-1 py-1 cursor-pointer">
          <div class="relative flex flex-shrink-0 items-end">
            <span
              class="flex justify-center items-center h-9 w-9 p-3 rounded-full ring-2 ring-white text-center text-white"
              :class="getUserDefaultImage(item.message.writer.companyName)?.color"
            >
              {{ getUserDefaultImage(item.message.writer.companyName)?.name }}
            </span>
            <span
              class="absolute h-4 w-4 bg-green-400 rounded-full bottom-0 right-0 border-2 border-white"
            ></span>
          </div>
          <div class="ml-3">
            <span class="font-semibold text-lg tracking-tight">{{ $t('new-message') }}</span>
            <p class="text-sm leading-4 italic opacity-70">
              {{ item.message.writer.companyName }}
            </p>
            <span class="text-[10px] text-blue-500 font-medium leading-4 opacity-75"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { formatPrice } from '@/utils/formatString';
import { getUserDefaultImage } from '@/utils/userImageDefault';

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'success',
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      showToast: true,
    };
  },
  created() {
    this.notificationItems.forEach((item) => {
      setTimeout(() => {
        item.visible = false;
      }, 5000);
    });
  },
  computed: {
    isSuplanting() {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating() {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser() {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    formatPrice,
    filteredNotificationItems() {
      return this.notificationItems.filter((item) => item.visible);
    },
    notificationItems() {
      return this.$store.getters['UserStore/notificationItems'];
    },
  },
  methods: {
    getUserDefaultImage,
    checkTimerNotification() {
      this.notificationItems.forEach((item) => {
        setTimeout(() => {
          item.visible = false;
        }, 3500);
      });
    },
    hideToast() {
      this.showToast = false;
    },
    hideNotificationForRFP(message) {
      //TODO REFACTOR THIS IN A FUTURE
      if (message.type === 'LANES_RFP') {
        const currentChatId = message.id;
        const currentRanking = this.$store.getters['RFPStore/getRankingList'];
        const currentRankingDetailLTL = this.$store.getters['LoadsStore/getRankingRFP'];

        // IF ROUTE IS RFP DETAIL

        if (this.$route.name === 'RFP-laneService') {
          if (currentRankingDetailLTL.length > 0) {
            const foundObject = currentRankingDetailLTL.find(
              (item) => item.lanesChat?.id === currentChatId,
            );

            if (foundObject) {
              const userFound = foundObject.lanesChat.chatMembers.find(
                (item) => item.users.id === this.currentUser.id,
              );

              if (userFound.readed !== true) {
                userFound.readed = true;

                this.$store.commit('LoadsStore/setRankingRFP', currentRankingDetailLTL, {
                  root: true,
                });
              }
            }
          }
        }

        // IF ROUTE IS RANKING RFP DETAIL

        if (currentRanking.length > 0) {
          const foundObject = currentRanking.find(
            (item) => item.lanesChatMemberForShipper?.chat?.id === currentChatId,
          );

          if (foundObject) {
            if (foundObject.lanesChatMemberForShipper.readed !== true) {
              foundObject.lanesChatMemberForShipper.readed = true;

              this.$store.commit('RFPStore/setRankingList', currentRanking, { root: true });
            }
          }
        }
      }
    },
    openChat(message) {
      this.hideNotificationForRFP(message);

      message.loadId
        ? this.$store.commit('LoadsStore/loadId', message.loadId)
        : this.$store.commit('ChatsStore/setUserForChat', message.writer);
      this.$store.commit('ChatsStore/setChatId', message.id);
      this.$store.commit('GlobalStore/setOpenChat', true);
    },
    pushTo(id) {
      this.$router.push('/planning/service/' + id);
    },
  },
  watch: {
    filteredNotificationItems() {
      this.checkTimerNotification();
    },
    notificationItems() {
      this.filteredNotificationItems();
      this.checkTimerNotification();
    },
  },
};
</script>

<style></style>
