import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col plan-p-md plan-gap-sm" }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-start items-center plan-gap-sm plan-pl-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_StopData = _resolveComponent("StopData")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_plan_input_checkbox, { onChange: _ctx.changeShowForm }, null, 8, ["onChange"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
    ]),
    (_ctx.showForm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_StopData, {
            ref: `stop-${_ctx.locationObject}`,
            showTime: _ctx.showTime,
            stop: _ctx.locationObject,
            warehousesAllowed: true,
            hideInputDates: true,
            onPortOrAirport: _ctx.portOrAirport,
            onSetStopLocation: _ctx.getLocationData,
            onUpdateHour: _ctx.updateHour,
            onUpdateLumperFee: _ctx.updateLumperFee,
            onUpdateObservations: _ctx.updateObservations,
            onUpdatePoNumber: _ctx.updatePoNumber,
            onUpdateWarehouse: _ctx.updateWarehouse,
            onCountry: _ctx.countrySelected
          }, null, 8, ["showTime", "stop", "onPortOrAirport", "onSetStopLocation", "onUpdateHour", "onUpdateLumperFee", "onUpdateObservations", "onUpdatePoNumber", "onUpdateWarehouse", "onCountry"])
        ]))
      : _createCommentVNode("", true)
  ]))
}