
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'LoadCardTag',
  components: { PlanIcon },
  props: {
    loadId: { type: String, required: true, default: '' },
    loadType: { type: String, required: true, default: 'ftl' },
    rightItems: { type: Array, required: false },
    rfpProjectId: { type: Number, required: true, default: 0 },
    rfpInfo: { type: String, required: false, default: '' },
    rfpLaneId: { type: Number, required: true, default: 0 },
    rfpLane: { type: String, required: false, default: '' },
    rfpPo: { type: String, required: false, default: '' },
    source: { type: String, required: false, default: '' },
    ltlIdentifier: { type: String, required: false },
    multiStopData: { type: Object as any },
  },
  data() {
    const router = useRouter();
    return {
      router,
    };
  },
  methods: {
    viewProjectDetail() {
      const projectId = this.rfpProjectId;
      this.$router.push({ name: 'ProjectLaneDetail', params: { projectId } });
      return;
    },
    viewLaneDetail() {
      this.router.push({
        name: 'PlanningRFP',
        params: { laneId: this.rfpLaneId },
      });
    },
  },
});
