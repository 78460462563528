import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 transition-opacity",
  style: {"z-index":"99999"}
}
const _hoisted_2 = { class: "bg-bg rounded-lg w-full max-w-md md:h-auto mx-auto my-auto md:mt-10 overflow-hidden" }
const _hoisted_3 = {
  key: 0,
  class: "bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
}
const _hoisted_4 = { class: "mt-3 sm:mt-0 sm:ml-4 sm:text-center justify-center h-fit" }
const _hoisted_5 = { class: "md:text-3xl font-bold" }
const _hoisted_6 = { class: "flex flex-col mt-10 mb-8 text-center" }
const _hoisted_7 = { for: "days" }
const _hoisted_8 = { class: "flex flex-wrap justify-center mt-4" }
const _hoisted_9 = { class: "flex mr-10" }
const _hoisted_10 = { class: "h-[42px] ml-2 flex items-center" }
const _hoisted_11 = { class: "flex justify-between space-x-2 px-4 pt-3 pb-6 sm:px-" }
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 1,
  class: "bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-"
}
const _hoisted_14 = { class: "mt-3 sm:mt-0 sm:ml-4 sm:text-center justify-center h-fit" }
const _hoisted_15 = { class: "md:text-3xl font-bold" }
const _hoisted_16 = { class: "py-2 mt-4 whitespace-pre-line pb-14" }
const _hoisted_17 = { class: "modal-footer flex flex-shrink-0 w-full flex-wrap items-center justify-center pt-4 border-t border-gray-200 rounded-b-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Field = _resolveComponent("Field")!
  const _component_DatePickerComponent = _resolveComponent("DatePickerComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.setQuotationTime)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('start-request')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(`${_ctx.$t('set-a-quotation-time-limit')}*`), 1),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Field, {
                      name: "days",
                      rules: "required",
                      modelValue: _ctx.days,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.days) = $event))
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          id: "days",
                          type: "number",
                          class: "w-16 border border-gray-300 bg-gray-50 rounded-lg flex text-center items-center",
                          placeholder: "--",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.days) = $event)),
                          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dateDifference('day')))
                        }, null, 544), [
                          [_vModelText, _ctx.days]
                        ]),
                        _createVNode(_component_ErrorMessage, {
                          name: "days",
                          class: "text-red-500 absolute mt-1"
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('days')), 1)
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_Field, {
                    name: "date",
                    rules: "required",
                    modelValue: _ctx.date,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.date) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DatePickerComponent, {
                        modelValue: _ctx.date,
                        "onUpdate:modelValue": [
                          _cache[3] || (_cache[3] = ($event: any) => ((_ctx.date) = $event)),
                          _cache[4] || (_cache[4] = ($event: any) => (_ctx.dateDifference('date')))
                        ],
                        customClass: 'w-36 text-gray-900 bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500 block',
                        teleport: true
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "date",
                    class: "text-red-500"
                  })
                ])
              ])
            ]),
            _createElementVNode("footer", _hoisted_11, [
              _createElementVNode("button", {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close'))),
                class: "inline-flex w-full border-corp justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-corp shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              }, _toDisplayString(_ctx.$t('cancel')), 1),
              _createElementVNode("button", {
                type: "button",
                class: "inline-flex w-full self-center items-center bg-corp border-corp justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.updateQuotationDateEnd && _ctx.updateQuotationDateEnd(...args)))
              }, [
                (_ctx.sendingAction)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "inline w-4 h-4 mr-3 text-white animate-spin",
                      src: _ctx.getAsset.icSpinner,
                      alt: "Spinner"
                    }, null, 8, _hoisted_12))
                  : _createCommentVNode("", true),
                _createElementVNode("p", null, _toDisplayString(_ctx.currentProject.project.quoteRequestTotal > 0 ? _ctx.$t('update') : _ctx.$t('start-now')), 1)
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("h3", _hoisted_15, _toDisplayString(_ctx.$t('quotation-started')), 1),
              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('quotation-started-info')), 1)
            ]),
            _createElementVNode("footer", _hoisted_17, [
              _createElementVNode("button", {
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('close'))),
                type: "button",
                class: "inline-block px-2 py-2 bg-black text-white rounded shadow-md hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1 flex-grow"
              }, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('continue')), 1)
              ])
            ])
          ]))
    ])
  ]))
}