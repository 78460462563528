import { initialState } from '@/services/customer/_store/index';

export default {
  clearCustomerStore(state: any) {
    Object.assign(state, initialState);
  },
  clearCustomersSelected(state: any) {
    state.dataCustomersSelected = [];
  },
  pushCustomersData(state: any, payload: any) {
    if (Array.isArray(payload)) {
      state.dataCustomers.push(...payload);
    } else {
      state.dataCustomers.push(payload);
    }
  },
  pushCustomersSelectedData(state: any, payload: any) {
    if (Array.isArray(payload)) {
      state.dataCustomersSelected.push(...payload);
    } else {
      state.dataCustomersSelected.push(payload);
    }
  },
  setCustomersData(state: any, payload: any) {
    state.dataCustomers = payload;
  },
  setCustomersSelectedData(state: any, payload: any) {
    state.dataCustomersSelected = payload;
  },
};
