
import { computed, defineComponent } from 'vue';
import { localAssets } from '@/resources/assets/assets';
import { integer } from '@vee-validate/rules';

export default defineComponent({
  name: 'PlanIcon',
  props: {
    name: { type: String, required: true, default: '' },
    iconColor: { type: String, required: false, default: 'white' },
    isSvg: { type: Boolean, required: false, default: false },
    rotate: { type: Boolean, required: false, default: false },
    size: { type: String, required: false, default: '20' },
  },
  setup(props) {
    const bellClaimsHeight = computed(() => {
      return String(parseInt(props.size) + 2);
    });

    const loadCardBgHeight = computed(() => {
      return String(parseInt(props.size) - 540);
    });

    const locationHeight = computed(() => {
      return String(parseInt(props.size) + 12);
    });

    const planimatikWidth = computed(() => {
      return String(parseInt(props.size) + 147);
    });

    const planimatikCollapsedWidth = computed(() => {
      return String(parseInt(props.size) + 10);
    });

    const statsHeight = computed(() => {
      return String(parseInt(props.size) - 12);
    });

    const simpleAddLocationWidth = computed(() => {
      return String(parseInt(props.size) - 4);
    });

    const simpleAlertWidth = computed(() => {
      return String(parseInt(props.size) - 15);
    });

    const simpleDollarWidth = computed(() => {
      return String(parseInt(props.size) - 8);
    });

    return {
      bellClaimsHeight,
      loadCardBgHeight,
      locationHeight,
      planimatikWidth,
      planimatikCollapsedWidth,
      statsHeight,
      simpleAddLocationWidth,
      simpleAlertWidth,
      simpleDollarWidth,
    };
  },
});
