
import { computed, defineComponent } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
export default defineComponent({
  name: 'PlanButtonIcon',
  components: { PlanIcon },
  props: {
    icon: { type: String, required: true, default: '' },
    color: { type: String, required: false, default: '#232F3C' },
    size: { type: String, required: false, default: '20' },
    type: { type: String, required: false, default: 'gray-800-white' },
    disabled: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    withNotification: { type: Boolean, required: false, default: false },
    quantity: { type: Number, required: false, default: -1 }
  },
  emits: ['onClick'],
  setup (props, { emit }) {
    const buttonClasses = computed(() => {
      const availableButtonsTypes = ['transparent-red', 'red', 'gray-800-white', 'transparent-gray-800', 'primary', 'primary-dark', 'success', 'warning', 'error']

      let result = 'plan-button-icon-'
      result += availableButtonsTypes.includes(props.type) ? props.type : 'gray-800-white'
      if (props.disabled) result += ' opacity-50 cursor-not-allowed'

      return result
    })

    const onClick = () => {
      if (!props.disabled && !props.loading) emit('onClick')
    }

    return {
      buttonClasses,
      onClick
    }
  }
})
