
import { defineComponent, computed, reactive, toRefs } from 'vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import { useStore } from 'vuex';
import PlanFormattedNumber from '@/components/labels/PlanFormattedNumber.vue';
import { useI18n } from 'vue-i18n';

interface planCarrierLoadDetails {
  documentsForDownload: Array<any>;
}

export default defineComponent({
  name: 'PlanCarrierLoadDetails',
  components: { PlanFormattedNumber, PlanTextButton, PlanIcon, PlanButtonIcon, PlanAvatar },
  setup() {
    const store = useStore();
    const translate = useI18n();

    const data = reactive<planCarrierLoadDetails>({
      documentsForDownload: [],
    });

    const additionalDocuments = computed(() => {
      let result: Array<any> = [];
      if (store.getters['CarrierStore/getLoadData'].mediaObjects) {
        store.getters['CarrierStore/getLoadData'].mediaObjects.forEach((mediaObject: any) => {
          if (mediaObject.type === 'LOAD' && mediaObject.entityClass === 'LOAD')
            result.push(mediaObject);
        });
      }
      return result;
    });

    const downloadDocument = async (mediaObject: any) => {
      data.documentsForDownload.push(mediaObject.id);

      await store
        .dispatch('ShipmentStore/getDownloadFileBlob', {
          id: mediaObject.id,
          class: mediaObject.entityClass,
        })
        .then((response) => {
          let file = document.createElement('a');
          file.href = URL.createObjectURL(response);
          file.download = `${mediaObject.name}`;
          file.click();
        })
        .finally(() => {
          data.documentsForDownload.splice(data.documentsForDownload.indexOf(mediaObject.id), 1);
        });
    };

    const loadDetailElementWidth = computed(() => {
      const actualWidth = store.getters['CarrierStore/getActualWidth'];
      if (actualWidth <= 640) {
        return 'width: 240px;';
      } else if (actualWidth > 640 && actualWidth <= 1536) {
        return 'width: 240px;';
      } else {
        return 'width: 280px;';
      }
    });

    const loadDetails = computed(() => {
      return {
        identifier: store.getters['CarrierStore/getLoadData'].identifier,
        poNumber: store.getters['CarrierStore/getLoadData'].poNumber
          ? store.getters['CarrierStore/getLoadData'].poNumber
          : '-',
        distance: store.getters['CarrierStore/getLoadData'].totalDistance,
        trailer: store.getters['CarrierStore/getLoadData'].trailerType.description,
        minTemperature: store.getters['CarrierStore/getLoadData'].minTemp,
        maxTemperature: store.getters['CarrierStore/getLoadData'].maxTemp,
        specialPermissions: store.getters['CarrierStore/getLoadData'].specialRequirement
          .specialPermissions
          ? store.getters['CarrierStore/getLoadData'].specialRequirement
              .specialPermissionsDescription
          : '',
        yourRate: store.getters['CarrierStore/getLoadData'].shipment?.assignedCompany?.price
          ? store.getters['CarrierStore/getLoadData'].shipment?.assignedCompany?.price
          : null,
        observations: store.getters['CarrierStore/getLoadData'].observations ? store.getters['CarrierStore/getLoadData'].observations : '',
      };
    });

    const carrierChat = computed(() => {
      return store.getters['CarrierStore/getCarrierChat'];
    });

    const isOpenChat = computed(() => {
      return store.state.GlobalStore.isOpenChat;
    });

    const openChatQuoting = async () => {
      if (!isOpenChat.value) {
        carrierChat.value.readed = true;
        store.commit('GlobalStore/setOpenChat', false);
        setTimeout(() => {
          store.commit('LoadsStore/loadId', loadData.value.id);
          store.commit('ChatsStore/setUserForChat', { user: loadData.value.shipperOwner });
          store.commit('ChatsStore/setChatId', carrierChat.value ? carrierChat.value.chat : null);
          store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    };

    const addMemberToChatLoad = async (chatId) => {
      await store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    };

    const openChat = async () => {
      if (loadData.value.status === 'QUOTING') {
        openChatQuoting();
      } else {
        const member = loadData.value?.chat?.chatMembers.find(
          (member) => member.user.id === currentUser?.value.id,
        );
        if (member) {
          member.readed = true;
        } else {
          if (loadData.value?.chat?.id) {
            await addMemberToChatLoad(loadData.value.chat.id);
          }
        }

        store.commit('LoadsStore/loadId', loadData.value.id);
        store.commit(
          'ChatsStore/setChatId',
          loadData.value.chat ? loadData.value.chat.id : carrierChat.value.chat,
        );
        store.commit('GlobalStore/setOpenChat', true);
        store.commit('CarrierStore/openChat', false);
      }
    };

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const shipperOwner = computed(() => {
      return {
        user: store.getters['CarrierStore/getLoadData'].shipperOwner.name,
        company: store.getters['CarrierStore/getLoadData'].shipperOwner.company?.name,
      };
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const isCarrierAssignedToThisLoad = computed(() => {
      return (
        store.getters['UserStore/getCurrentUser'].company?.id ===
        loadData.value.shipment?.assignedCompany?.company?.id
      );
    });

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    const specialRequirements = computed(() => {
      let result: Array<any> = [];
      const specialRequirementElements =
        store.getters['CarrierStore/getLoadData'].specialRequirement;
      if (specialRequirementElements.TWICCard) result.push(translate.t('twic-card'));
      if (specialRequirementElements.bondedPermission)
        result.push(translate.t('bonded-permission'));
      if (specialRequirementElements.hazardousLoad) result.push(translate.t('hazardous-load'));
      if (specialRequirementElements.teamDriver) result.push(translate.t('team-driver'));
      if (specialRequirementElements.tir) result.push(translate.t('tir'));
      if (specialRequirementElements.tsa) result.push(translate.t('tsa'));

      if (result.length > 1) {
        return result.slice(0, -1).join(', ') + ` ${translate.t('and')} ` + result.slice(-1);
      } else {
        return result.join('');
      }
    });

    return {
      ...toRefs(data),
      additionalDocuments,
      downloadDocument,
      isDriver,
      loadDetailElementWidth,
      loadDetails,
      openChat,
      shipperOwner,
      specialRequirements,
      isCarrierAssignedToThisLoad,
    };
  },
});
