import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full plan-gap-sm" }
const _hoisted_2 = { class: "flex flex-row plan-gap-sm" }
const _hoisted_3 = { class: "load-documents" }
const _hoisted_4 = { class: "text-xs text-plan-gray-600" }
const _hoisted_5 = {
  key: 0,
  class: "text-xs text-corp"
}
const _hoisted_6 = { class: "w-full flex justify-between items-start" }
const _hoisted_7 = { class: "flex justify-start items-center gap-2" }
const _hoisted_8 = { class: "load-document-icon" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "font-semibold" }
const _hoisted_11 = { class: "text-xs text-plan-gray-600" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "flex justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_text_area = _resolveComponent("plan-text-area")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showShareModal = !_ctx.showShareModal)),
    title: _ctx.$t('share-accounting'),
    isCarrierView: true,
    size: "sm-auto",
    "hide-close-button": ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_input_text, {
          "model-value": _ctx.recipientsInput,
          "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.recipientsInput) = $event)),
          label: _ctx.$t('recipients'),
          validations: ['empty'],
          mandatory: ""
        }, null, 8, ["model-value", "label"]),
        _createVNode(_component_plan_input_text, {
          "model-value": _ctx.subject,
          "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.subject) = $event)),
          label: _ctx.$t('subject'),
          validations: ['empty'],
          mandatory: ""
        }, null, 8, ["model-value", "label"]),
        _createVNode(_component_plan_text_area, {
          "model-value": _ctx.message,
          "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.message) = $event)),
          height: 'h-[10rem]'
        }, null, 8, ["model-value"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_plan_icon, {
            name: "paperclip",
            "is-svg": "",
            "icon-color": "black",
            size: "16"
          }),
          _createElementVNode("p", null, _toDisplayString(_ctx.totalDocumentsShared) + " " + _toDisplayString(_ctx.translate.t('attached-files')), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docsList, (section) => {
            return (_openBlock(), _createElementBlock("div", {
              key: section,
              class: "section border border-gray-300 p-2"
            }, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(section.title), 1),
              (!section.documents || section.documents.length === 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('not-documents-uploaded')), 1))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.documents, (document, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: document,
                  class: "w-full flex flex-col gap-2"
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_plan_icon, {
                          name: "checked-document",
                          "is-svg": "",
                          "icon-color": "black"
                        })
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t(document.displayName)), 1),
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.dateTimeTZ(document.createdDate)), 1)
                      ])
                    ])
                  ]),
                  (index < section.documents.length - 1)
                    ? (_openBlock(), _createElementBlock("hr", _hoisted_12))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          }), 128))
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_plan_button, {
          onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$store.commit('LoadsStore/showModalShareDocuments', false))),
          type: "cancel",
          label: _ctx.$t('cancel')
        }, null, 8, ["label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.sendEmail,
          type: "primary",
          disabled: !_ctx.isValid,
          label: _ctx.$t('send'),
          loading: _ctx.isSendingEmails,
          style: {"width":"120px"}
        }, null, 8, ["onOnClick", "disabled", "label", "loading"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}