import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-center items-center flex-col plan-t-prim-200 gap-2" }
const _hoisted_2 = {
  key: 0,
  class: "font-semibold"
}
const _hoisted_3 = {
  key: 1,
  class: "font-semibold"
}
const _hoisted_4 = {
  key: 2,
  class: "plan-t-prim-100 plan-t-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_icon, {
      name: "spinner",
      "icon-color": "#C4C6C8",
      "is-svg": "",
      size: "40",
      rotate: ""
    }),
    (_ctx.message.length === 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('default-loading')), 1))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.message), 1)),
    (_ctx.subMessage.length > 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.subMessage), 1))
      : _createCommentVNode("", true)
  ]))
}