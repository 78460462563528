import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_email_template_form = _resolveComponent("email-template-form")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    title: _ctx.$t('update-template'),
    size: "lg-auto",
    "hide-close-button": ""
  }, {
    content: _withCtx(() => [
      _createVNode(_component_email_template_form, {
        onChange: _ctx.update,
        "pre-template-name": _ctx.templateName,
        "pre-subject": _ctx.subject,
        "pre-message": _ctx.message
      }, null, 8, ["onChange", "pre-template-name", "pre-subject", "pre-message"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          type: "cancel",
          label: _ctx.$t('cancel')
        }, null, 8, ["onOnClick", "label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.putEmailTemplate,
          loading: _ctx.loadingPut,
          disabled: !_ctx.validate,
          type: "primary",
          label: _ctx.$t('save-template'),
          style: {"width":"150px"}
        }, null, 8, ["onOnClick", "loading", "disabled", "label"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}