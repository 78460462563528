import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-2" }
const _hoisted_2 = { class: "flex justify-between items-center plan-gap-lg" }
const _hoisted_3 = { class: "flex flex-col w-full" }
const _hoisted_4 = { class: "flex flex-col w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomMultiselect = _resolveComponent("CustomMultiselect")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_DatePickerComponent = _resolveComponent("DatePickerComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('project-select')), 1),
      _createVNode(_component_Field, {
        name: _ctx.$t('vehicle'),
        rules: "required",
        modelValue: _ctx.projectSelected,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.projectSelected) = $event)),
        class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 h-105",
        disabled: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CustomMultiselect, {
            id: 'projectSelected',
            isSendRequest: _ctx.isSendingRequest,
            options: _ctx.options,
            propertyForOptionText: 'name',
            propertyForSelectedText: 'name',
            searchText: _ctx.projectSelected,
            onAutoCompleteSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.findShipper($event))),
            onAutoCompleteOptionSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleShipperSelection($event)))
          }, null, 8, ["isSendRequest", "options", "searchText"])
        ]),
        _: 1
      }, 8, ["name", "modelValue"]),
      _createVNode(_component_ErrorMessage, {
        name: _ctx.$t('vehicle'),
        class: "text-rose-500"
      }, null, 8, ["name"]),
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('project-name')), 1),
      _createVNode(_component_Field, {
        class: "appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
        name: "projectName",
        rules: "required|max:256",
        modelValue: _ctx.localName,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localName) = $event)),
        placeholder: _ctx.$t('project-include-name')
      }, null, 8, ["modelValue", "placeholder"]),
      _createVNode(_component_ErrorMessage, {
        name: "projectName",
        class: "text-red-500"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('from')), 1),
        _createVNode(_component_Field, {
          name: "scratchStartDate",
          rules: "required",
          modelValue: _ctx.localStartDate,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localStartDate) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DatePickerComponent, {
              modelValue: _ctx.localStartDate,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localStartDate) = $event)),
              customClass: 'w-full custom-datepicker',
              "max-date": _ctx.localEndDate ? _ctx.localEndDate : null,
              teleport: true
            }, null, 8, ["modelValue", "max-date"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_ErrorMessage, {
          name: "scratchStartDate",
          class: "text-rose-500"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('to')), 1),
        _createVNode(_component_Field, {
          name: "scratchEndDate",
          rules: "required",
          modelValue: _ctx.localEndDate,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localEndDate) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DatePickerComponent, {
              modelValue: _ctx.localEndDate,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localEndDate) = $event)),
              customClass: 'w-full custom-datepicker',
              minDate: _ctx.localStartDate ?? new Date(),
              startDate: _ctx.localStartDate ?? new Date(),
              teleport: true
            }, null, 8, ["modelValue", "minDate", "startDate"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_ErrorMessage, {
          name: "scratchEndDate",
          class: "text-rose-500"
        })
      ])
    ])
  ], 64))
}