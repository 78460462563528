
import { defineComponent, computed, ref, onMounted, nextTick, onUnmounted } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PlanCarrierDefaultModal',
  components: { PlanButtonIcon },
  props: {
    hideCloseButton: { type: Boolean, required: false, default: false },
    withoutSidebar: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: '' },
    subtitle: { type: String, required: false, default: '' },
    size: { type: String, required: false, default: 'xl' },
    width: { type: String, required: false, default: '' },
    hideFooter: { type: Boolean, required: false, default: false },
    onlyContent: { type: Boolean, required: false, default: false },
    contentWithoutPadding: { type: Boolean, required: false, default: false },
    isCarrierView: { type: Boolean, required: false, default: false },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const store = useStore();
    const windowWidth = ref(window.innerWidth);

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const modalStyle = computed(() => {
      let widthSize = '100%';
      let heightSize = '100%';

      if (actualWidth.value <= 640) {
        return {
          width: '100%',
          height: '100%',
          'max-height': '100%',
          'z-index': 9999,
          'border-radius': 0
        }
      }

      if (props.size === 'lg') {
        widthSize = '75%';
        heightSize = '75%';
      }
      if (props.size === 'lg-auto') {
        widthSize = '75%';
        heightSize = 'auto';
      }
      if (props.size === 'md') {
        widthSize = windowWidth.value < 1280 ? '70%' : '50%';
        heightSize = windowWidth.value < 1280 ? '70%' : '50%';
      }
      if (props.size === 'md-auto') {
        widthSize = windowWidth.value < 1280 ? '70%' : '50%';
        heightSize = 'auto';
      }
      if (props.size === 'sm') {
        widthSize = windowWidth.value < 1280 ? '60%' : '40%';
        heightSize = windowWidth.value < 1280 ? '60%' : '40%';
      }
      if (props.size === 'sm-auto') {
        widthSize = windowWidth.value < 1280 ? '60%' : '40%';
        heightSize = 'auto';
      }
      if (props.size === 'xs') {
        widthSize = windowWidth.value < 1280 ? '50%' : '25%';
        heightSize = windowWidth.value < 1280 ? '50%' : '25%';
      }
      if (props.size === 'xs-auto') {
        widthSize = windowWidth.value < 1280 ? '50%' : '25%';
        heightSize = 'auto';
      }

      if (props.width !== '') widthSize = props.width;

      if (windowWidth.value <= 640) {
        return {
          width: '100%',
          height: heightSize,
          'max-height': '100%',
          'z-index': 9999
        }
      }

      return {
        width: widthSize,
        height: heightSize,
        'max-height': '100%'
      };
    });

    const planDefaultModalStyle = computed(() => {
      if (windowWidth.value <= 640) return { width: '100vw', left: '0', 'z-index': 9999 };

      return {
        width: 'calc(100vw - 58px)',
        left: '58px'
      };
    });

    const close = () => {
      emit('close');
    };

    const resizeWindow = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', resizeWindow);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeWindow);
    });

    return {
      actualWidth,
      modalStyle,
      planDefaultModalStyle,
      close,
    };
  },
});
