
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import WarehouseForm from '@/modules/planning/warehouses/_forms/WarehouseForm.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

interface updateWarehouseModal {
  loading: boolean,
  warehouseInfo: {
    name: string,
    country: string,
    zip: string,
    address: string,
    placeId: string,
    prefix: string,
    phone: number | null,
    contactPerson: string
  }
}

export default defineComponent({
  name: 'UpdateWarehouseModal',
  components: { PlanDefaultModal, WarehouseForm, PlanButton },
  emits: ['close', 'closeAndUpdate'],
  setup (props, { emit }) {
    const route = useRoute()
    const store = useStore()

    const data = reactive<updateWarehouseModal>({
      loading: false,
      warehouseInfo: {
        name: '',
        country: '',
        zip: '',
        address: '',
        placeId: '',
        prefix: '',
        phone: null,
        contactPerson: '',
      }
    })

    const isFormComplete = computed(() => {
      let result = true
      if (data.warehouseInfo.name.length === 0) result = false
      if (data.warehouseInfo.country.length === 0) result = false
      if (data.warehouseInfo.zip.length === 0) result = false
      if (data.warehouseInfo.address.length === 0) result = false
      if (data.warehouseInfo.placeId.length === 0) result = false
      if (data.warehouseInfo.prefix.length === 0) result = false
      if (data.warehouseInfo.phone === null) result = false
      return result
    })

    const withoutSidebar = computed(() => {
      return route.name !== 'warehousesList'
    })

    const close = () => { if (!data.loading) emit('close') }

    const putWarehouse = () => {
      if (!isFormComplete.value) return

      data.loading = true
      store.dispatch('LoadsStore/updateWarehouse', {
        id: store.getters['LoadsStore/getSelectedWarehouse'].id,
        companyWarehouse: data.warehouseInfo.name,
        location: {
          id: store.getters['LoadsStore/getSelectedWarehouse']?.location?.id,
          address: data.warehouseInfo.address,
        },
        phoneNumber: data.warehouseInfo?.phone?.toString(),
        phonePrefix: data.warehouseInfo.prefix.replace('+', ''),
        contactName: data.warehouseInfo.contactPerson,
        googlePlaceId: data.warehouseInfo.placeId,
        language: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es'
      }).then((response) => {
        emit('closeAndUpdate', response)
        data.loading = false
      })
    }

    const updateData = (newData: any) => {
      data.warehouseInfo = newData
    }

    data.warehouseInfo.country = store.getters['UserStore/getRegion'] === 'EUROPE' ? 'es' : 'us'

    return {
      ...toRefs(data),
      isFormComplete,
      withoutSidebar,
      close,
      putWarehouse,
      updateData
    }
  }
})
