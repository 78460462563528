import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_3 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_text_button, {
      onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true)),
      label: _ctx.$t('update')
    }, null, 8, ["label"]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
          key: 0,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModal = false)),
          title: _ctx.$t('update'),
          size: "sm-auto",
          "hide-close-button": ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
              }, [
                _createVNode(_component_plan_select, {
                  onChange: _ctx.changeSelectedTrailer,
                  value: _ctx.selectedTrailer,
                  items: _ctx.trailerTypes,
                  label: _ctx.$t('vehicle-type'),
                  placeholder: _ctx.$t('select'),
                  mandatory: ""
                }, null, 8, ["onChange", "value", "items", "label", "placeholder"])
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
              }, [
                _createVNode(_component_plan_input_text, {
                  "model-value": _ctx.licensePlate,
                  "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.licensePlate) = $event)),
                  label: _ctx.$t('license-plate'),
                  mandatory: ""
                }, null, 8, ["model-value", "label"]),
                _createVNode(_component_plan_input_text, {
                  "model-value": _ctx.trailerPlate,
                  "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.trailerPlate) = $event)),
                  label: _ctx.$t('trailer'),
                  mandatory: ""
                }, null, 8, ["model-value", "label"])
              ], 2)
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_plan_button, {
                onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModal = false)),
                disabled: _ctx.loadingPut,
                type: "cancel",
                label: _ctx.$t('cancel')
              }, null, 8, ["disabled", "label"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.putVehicle,
                disabled: !_ctx.validateVehicle,
                loading: _ctx.loadingPut,
                type: "primary",
                label: _ctx.$t('update'),
                style: {"min-width":"100px"}
              }, null, 8, ["onOnClick", "disabled", "loading", "label"])
            ])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ]))
}