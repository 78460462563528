import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-96 text-2xl font-semibold" }
const _hoisted_2 = { class: "self-stretch justify-start items-start gap-2.5 inline-flex" }
const _hoisted_3 = { class: "grow shrink basis-0" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "w-full pt-6 pb-2 border-b border-gray-200 flex justify-between gap-2.5" }
const _hoisted_6 = { class: "text-slate-900 text-base font-semibold" }
const _hoisted_7 = { class: "text-slate-900 text-base font-semibold" }
const _hoisted_8 = { class: "w-full flex flex-col space-y-2.5 mt-2.5" }
const _hoisted_9 = {
  key: 0,
  class: "w-full flex justify-center"
}
const _hoisted_10 = { class: "w-1/4 text-start text-neutral-400 text-xs font-normal truncate" }
const _hoisted_11 = { class: "w-2/4 justify-center items-center gap-2 flex" }
const _hoisted_12 = { class: "flex-col justify-start items-center inline-flex" }
const _hoisted_13 = { class: "text-neutral-400 text-xs font-normal" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "w-1/4 text-right text-slate-900 text-xs font-normal truncate" }
const _hoisted_16 = { class: "w-full flex justify-center mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpinBlack = _resolveComponent("SpinBlack")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    show: _ctx.planimatikCreditModal,
    modalSize: "extra-small",
    onCallbackClose: _ctx.onClickClose
  }, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('planimatik-credit')), 1)
    ]),
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", {
            class: "font-semibold",
            innerHTML: 
              _ctx.$t('credit-available-to-contract', {
                available: `${_ctx.h3}<strong>${
                  _ctx.creditAvailable ? _ctx.formatPrice(_ctx.creditAvailable) : '-'
                }</strong>`,
                spanEnd: '</span><br/>',
                total: _ctx.companyCredit?.creditGranted
                  ? _ctx.formatPrice(_ctx.companyCredit?.creditGranted)
                  : '-',
              })
            
          }, null, 8, _hoisted_4)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('loads-in-progress-n', _ctx.creditBreakdown?.totalAmount ?? '-')), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.companyCredit?.creditLocked
              ? _ctx.formatPrice(_ctx.companyCredit?.creditLocked)
              : _ctx.formatPrice(0)), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.showSpinner && !_ctx.companyCredit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_SpinBlack)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.creditBreakdown?.loads, (load) => {
          return (_openBlock(), _createElementBlock("div", {
            key: load,
            class: "w-full justify-between items-center inline-flex"
          }, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.formatDate(load.date)), 1),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(load.type), 1)
              ]),
              _createElementVNode("div", {
                class: "text-slate-900 text-base font-normal underline cursor-pointer",
                onClick: ($event: any) => (_ctx.toLoadDetail(load.id))
              }, _toDisplayString(load.identifier), 9, _hoisted_14)
            ]),
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formatPrice(load.creditLocked)), 1)
          ]))
        }), 128)),
        (_ctx.creditBreakdown.loads.length < _ctx.creditBreakdown.totalAmount)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["flex justify-between w-fit self-end text-xs font-semibold p-2 border border-corp text-corp rounded-lg hover:opacity-70'", _ctx.showSpinner ? 'cursor-not-allowed opacity-70' : 'cursor-pointer']),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadMore && _ctx.loadMore(...args)))
            }, _toDisplayString(_ctx.showSpinner ? _ctx.$t('loading') : _ctx.$t('load-more')), 3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_16, [
        (_ctx.companyCredit?.creditLocked > 0)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "w-48 px-2.5 py-2.5 bg-sky-500 rounded gap-2.5 text-white text-center text-base font-normal cursor-pointer hover:opacity-70",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toIncreaseCredit && _ctx.toIncreaseCredit(...args)))
            }, _toDisplayString(_ctx.$t('increase-your-credit')), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["show", "onCallbackClose"]))
}