import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col plan-p-md plan-gap-sm" }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col justify-start items-start plan-gap-sm plan-pl-md"
}
const _hoisted_4 = { class: "plan-div-form flex justify-start items-center plan-pl-sm plan-pr-sm w-fit" }
const _hoisted_5 = {
  key: 0,
  class: "flex justify-between items-center plan-gap-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_plan_select_simple = _resolveComponent("plan-select-simple")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_plan_input_checkbox, { onChange: _ctx.changeShowForm }, null, 8, ["onChange"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('vehicle')), 1)
    ]),
    (_ctx.showForm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_plan_select_simple, {
              onChange: _ctx.changeVehicle,
              value: _ctx.vehicle,
              items: _ctx.vehicles,
              placeholder: _ctx.$t('select-a-vehicle')
            }, null, 8, ["onChange", "value", "items", "placeholder"])
          ]),
          (_ctx.isRefrigerated.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_plan_input_number, {
                  onChange: _ctx.change,
                  "model-value": _ctx.isRefrigerated.min,
                  "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isRefrigerated.min) = $event)),
                  label: _ctx.$t('min-temp'),
                  "hide-validations": ""
                }, null, 8, ["onChange", "model-value", "label"]),
                _createVNode(_component_plan_input_number, {
                  onChange: _ctx.change,
                  "model-value": _ctx.isRefrigerated.max,
                  "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isRefrigerated.max) = $event)),
                  label: _ctx.$t('max-temp'),
                  "hide-validations": ""
                }, null, 8, ["onChange", "model-value", "label"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}