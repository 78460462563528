import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  countries: [],
  country: null as any,
  isLoadingCountry: false,
  currentCountry:{},
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
