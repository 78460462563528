
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import PlanLoading from '@/components/loadings/PlanLoading.vue';
import SidebarChat from '@/components/sidebar/ChatSidebar.vue';

export default defineComponent({
  name: 'CarrierChat',
  mixins: [BaseComponent],
  components: {
    PlanLoading,
    SidebarChat,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    currentConversation(): any {
      return this.$store.state.ChatsStore.currentConversation;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
  },
  async created() {
    const hash: any = this.$route.params.hash;
    const code: any = this.$route.params.code;
    const chatId: any = this.$route.params.chatId;
    this.loading = true;
    if (hash && code) {
      await this.$store
        .dispatch('UserStore/hashValidation', {
          hashId: hash,
          secureCode: code,
        })
        .then(() => {
          this.$store.dispatch('PusherStore/initializePusher');
          this.$store.commit('ChatsStore/setChatId', chatId);
          this.$store.dispatch('ChatsStore/getConversationById', chatId).then(() => {
            const member = this.currentConversation?.chatMembers.find(
              (member) => member.user.id !== this.currentUser?.id,
            );

            this.$store.commit('ChatsStore/setUserForChat', member);
            this.$store.commit(
              'LoadsStore/loadId',
              this.currentConversation?.quotingLoad
                ? this.currentConversation.quotingLoad.id
                : this.currentConversation?.load?.id,
            );
          });
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.title);
          this.$store.commit('UserStore/setShowHash', true);
        })
        .finally(() => (this.loading = false));
    }
  },
});
