
import { defineComponent } from 'vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import draggable from 'vuedraggable';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateSpin.vue';

export default defineComponent({
  name: 'LoadsAssignation',
  components: {
    draggable,
    CheckCircleIcon,
    Spin,
  },
  mixins: [BaseComponent],
  data() {
    return {
      draggableList: [],
      LanesRFQBids: [] as any,
      drag: false,
      isLoading: false,
      isMoving: false,
      showSpinner: false,
      maxBidsRequest: 10,
    };
  },
  created() {
    this.fetchQuotesRanking();
  },
  computed: {
    currentLane(): any {
      return this.$store.getters['LanesStore/getLaneData'];
    },
    dragOptions(): any {
      return {
        animation: 200,
        group: 'description',
        disabled: this.isMoving,
        ghostClass: 'ghost',
      };
    },
    rankingList(): any {
      return this.$store.getters['LanesStore/getLaneRankingList'];
    },
  },
  methods: {
    addSelectedContact(id) {
      const exists = this.isSelected(id);
      if (exists) {
        this.LanesRFQBids = this.LanesRFQBids.filter((bid) => bid.id !== id);
      } else {
        this.LanesRFQBids.push({ id: id });
      }
    },
    fetchQuotesRanking() {
      // Get Quotes list
      this.$store.dispatch('LanesStore/getLaneQuotedRanking', {
        id: this.$route.params.laneId,
        params: { page: 1 },
      });
      // Get Total of quotes
      this.$store.dispatch('LanesStore/getLaneQuotedRanking', {
        id: this.$route.params.laneId,
        params: { page: 1, totals: 'quoted' },
      });
      // Get Ranking list
      this.$store.dispatch('LanesStore/getLaneQuotedRanking', {
        id: this.$route.params.laneId,
        params: { page: 1, ranking: 'ranking' },
      });
    },
    goToStepOne() {
      this.$emit('goToStepOne', '');
    },
    isSelected(id) {
      return this.LanesRFQBids.some((bid) => bid.id === id);
    },
    nextRoute() {
      this.$router.push('quote');
    },
    onDragEnd(event) {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store
          .dispatch('LanesStore/moveRankingPosition', {
            id: event.item.__draggable_context.element.id,
            params: { newPosition: event.newIndex },
          })
          .then(() => {
            this.fetchQuotesRanking();
            this.$emit('fetchLanes');
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    showTemplates() {
      this.$store.dispatch('LoadsStore/showTemplateModal', true);
    },
    submit() {
      if (this.LanesRFQBids.length > 0 && !this.showSpinner) {
        this.showSpinner = true;
        this.$store
          .dispatch('LanesStore/selectAssignedQuotesBatch', {
            id: this.$route.params.laneId,
            LanesRFQBids: { LanesRFQBids: this.LanesRFQBids },
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('quotes-sent'));
            this.$router.push('/planning/lanesRFQ');
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          })
          .finally(() => {
            this.showSpinner = false;
          });
      }
    },
  },
  watch: {
    rankingList(): any {
      this.draggableList = this.rankingList.map((quote, index) => {
        if (index === 0) {
          this.addSelectedContact(quote.id);
        }
        return { ...quote, order: quote.ranking.position };
      });
    },
  },
});
