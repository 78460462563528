
import { defineComponent, reactive, toRefs, computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PlanInputLocation from '@/components/forms/PlanInputLocation.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

export default defineComponent({
  name: 'PlanUpdateMultipleLanesAdditionalStopInfo',
  components: { PlanButtonIcon, PlanInputLocation },
  emits: ['change', 'delete', 'updateLocation'],
  props: {
    position: { type: Number, required: true },
    locationObject: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      showForm: false,
      location: {
        lat: 0,
        long: 0,
        placeId: '',
        zip: '',
        city: '',
        state: {
          iso: '',
          country: {
            iso: '',
          },
          isoGoogle: '',
        },
      },
      place: '',
      type: 'PICKUP',
    });

    const getCountries: any = computed(() => {
      return store.getters['CountryStore/getCountries'];
    });

    const states: any = computed(() => {
      return store.getters['LoadsStore/getStates'];
    });

    const deleteStop = () => {
      emit('delete', props.position - 1);
    };

    const findIsoInCountrys = (iso) => {
      return getCountries.value.find((country) => country.iso === iso);
    };

    const getLocationData = (locationData: any) => {
      store
        .dispatch('LoadsStore/getPlaceDetailsGoogle', { placeId: locationData.placeId })
        .then((response) => {
          if (response.data.address_components) {
            data.place = response.data.formatted_address;
            data.location.lat = response.data.geometry.location.lat;
            data.location.long = response.data.geometry.location.lng;
            data.location.placeId = response.data.place_id;
            response.data.address_components.forEach((addressComponent: any) => {
              if (addressComponent.types.includes('postal_code'))
                data.location.zip = addressComponent['short_name'];
              if (addressComponent.types.includes('country'))
                data.location.state.country.iso = addressComponent['short_name'];
              if (addressComponent.types.includes('administrative_area_level_1')) {
                data.location.city = addressComponent['long_name'];
                data.location.state.iso = addressComponent['short_name'];
                data.location.state.isoGoogle = addressComponent['short_name'];
              }
            });
            updateLocation();
          }
        });
    };

    const updateLocation = () => {
      const country = findIsoInCountrys(data?.location?.state?.country?.iso);
      emit('updateLocation', {
        location: {
          googlePlaceId: data.location.placeId,
          type: data.type,
          location: {
            googlePlaceId: data.location.placeId,
            lat: data.location.lat,
            long: data.location.long,
            zip: data.location.zip,
            city: data.location.city,
            state: { country: country },
          },
          place: data.place,
        },
        position: props.position - 1,
      });
    };

    onMounted(() => {
      setTimeout(() => {
        data.location = props.locationObject.location;
        data.place = props.locationObject.place;
        data.type = props.locationObject.type;
      }, 500);
    });

    return {
      ...toRefs(data),
      getCountries,
      getLocationData,
      deleteStop,
      updateLocation,
    };
  },
});
