<template>
  <div class="flex items-center">
    <label
      for="periodRange"
      class="block font-medium mr-2"
    >
      {{ $t('historical-range') }}
    </label>
    <Spin
      v-if="loading"
      class="absolute right-2.5 bg-gray-50 ml-0 mr-0"
    />
    <select
      id="periodRange"
      class="w-40 bg-gray-50 border border-gray-300 text-gray-950 text-sm
        rounded-lg md-w-26 p-2.5 cursor-pointer
        focus:ring-blue-500 focus:border-blue-500 block"
      :class="{ 'pointer-events-none': loading }"
      v-model="periodRangeValue"
      @change="onChangeHistoricalRange"
    >
      <option :value="PeriodRange.LAST_MONTH">
        {{ $t('last-month') }}
      </option>
      <option :value="PeriodRange.LAST_3_MONTHS">
        {{ $t('last-3-months') }}
      </option>
      <option :value="PeriodRange.LAST_6_MONTHS">
        {{ $t('last-6-months') }}
      </option>
      <option :value="PeriodRange.LAST_YEAR">
        {{ $t('last-year') }}
      </option>
    </select>
  </div>
</template>

<script>
import { ref } from 'vue';
// Components
import Spin from '@/components/spin/AnimateBlackSpin.vue';
// Types
import { PeriodRange }  from './FiltersType';

export default {
  name: 'PlanPeriodRangeFilter',
  components: {
    Spin,
  },
  emits: ['callbackChange'],
  props: {
    loading: { type: Boolean },
    value: { type: String, required: true },
  },
  setup(props, { emit }) {
    const periodRangeValue = ref(props.value || PeriodRange.LAST_3_MONTHS);

    /** Methods */
    const onChangeHistoricalRange = () => {
      emit('callbackChange', periodRangeValue.value);
    };

    return {
      PeriodRange,
      periodRangeValue,
      onChangeHistoricalRange,
    }
  }
};
</script>

<style scoped>

</style>
