
import { defineComponent, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/outline';
// import TimePicker from 'vue3-timepicker';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateSpin.vue';
import PlanInputTime from '@/components/forms/PlanInputTime.vue';
// Utils
import { hourRange, minutesRange } from '@/utils/formatTime';

interface Position {
  shipment: {
    id: number;
  };
  location: {
    lat: number | null;
    long: number | null;
    zip: number | null;
    city: string | undefined;
    state: {
      id: number;
    };
  };
}

export default defineComponent({
  name: 'MailToCarrierModal',
  components: {
    ErrorMessage,
    Field,
    Form,
    Spin,
    // TimePicker,
    XCircleIcon,
    PlanInputTime,
  },
  mixins: [BaseComponent],
  props: {
    shipmentId: {
      type: String,
    },
  },
  data() {
    return {
      isValidating: false,
      howOftenSendMail: null,
      showTime: true,
      isAvailable: false,
      allowed: false,
      sending: false,
      truckLocation: ref<Position | null>(null),
      executionWindowStartTimeOrigin: '',
      executionWindowEndTimeOrigin: '',
      executionWindowStartTimeDestiny: '',
      executionWindowEndTimeDestiny: '',
      timeFormat: 'hh:mm a',
    };
  },
  created() {
    if (this.currentLoad.trackingUpdateAlertActive) {
      this.setValuesData();
    }
  },
  computed: {
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    deliveriesQuantity(): any {
      let sum = 1;
      if (!this.currentLoad.stops || this.currentLoad.stops === undefined) return;
      this.currentLoad.stops.forEach((stop: any) => {
        if (stop.type === 'DELIVERY') {
          sum++;
        }
      });
      return sum;
    },
    destiny(): any {
      return this.currentLoad.stops.find((obj) => obj.type === 'DELIVERY_FINAL');
    },
    hourRange(): any {
      return hourRange;
    },
    isValid(): any {
      if (
        this.currentLoad.trackingUpdateAlertTimeRangeEnable === false &&
        this.howOftenSendMail !== null
      ) {
        return true;
      } else {
        return (
          this.executionWindowStartTimeOrigin !== '' &&
          this.executionWindowEndTimeOrigin !== '' &&
          this.howOftenSendMail !== null
        );
      }
    },
    location(): any {
      if (this.truckLocation !== null) {
        var zip: any = '';
        var city: any = '';
        var coma = ['', ''];
        if (this.truckLocation?.location?.zip !== null) {
          zip = this.truckLocation?.location?.zip;
          coma[0] = ', ';
        }
        if (this.truckLocation?.location?.city !== '') {
          city = this.truckLocation?.location?.city;
          coma[1] = ', ';
        }
        return `${zip}${coma[0]}${city}${coma[1]}${
          this.states.find((state) => state.id === this.truckLocation?.location.state.id).name
        }`;
      } else {
        return '';
      }
    },
    minutesRange(): any {
      return minutesRange;
    },
    origin(): any {
      return this.currentLoad.stops.find((obj) => obj.type === 'PICKUP_INITIAL');
    },
    pickUpQuantity(): any {
      let sum = 1;
      if (!this.currentLoad.stops || this.currentLoad.stops === undefined) return;
      this.currentLoad.stops.forEach((stop: any) => {
        if (stop.type === 'PICKUP') {
          sum++;
        }
      });
      return sum;
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
  },
  methods: {
    closeLocation() {
      this.$emit('close');
    },
    convertDate(dateTimeObject) {
      const now = new Date();
      let hours = dateTimeObject ? dateTimeObject.HH : '';
      const minutes = dateTimeObject.mm ? dateTimeObject.mm : '';
      return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
    },
    filteredDeliveriesCompleted(load): any {
      const filteredObjects = load.filter(
        (obj) => (obj.type === 'DELIVERY_FINAL' || obj.type === 'DELIVERY') && obj.doneAt,
      ).length;
      return filteredObjects;
    },
    filteredPickupsCompleted(load): any {
      const filteredObjects = load.filter(
        (obj) => (obj.type === 'PICKUP_INITIAL' || obj.type === 'PICKUP') && obj.doneAt,
      ).length;
      return filteredObjects;
    },
    handleCheckboxClick() {
      if (this.currentLoad.trackingUpdateAlertActive) {
        this.currentLoad.trackingUpdateAlertTimeRangeEnable = false;
      }
    },
    onPlaceChanged(event: any) {
      const states: any = this.$store.getters['LoadsStore/getStates'];
      let stateObj = states.find((state) => state.iso === event.administrative_area_level_1);

      if (!stateObj && event.administrative_area_level_2) {
        stateObj = states.find((state) => state.name === event.administrative_area_level_2);
      }

      this.truckLocation = {
        shipment: {
          id: Number(this.shipmentId),
        },
        location: {
          lat: event.latitude,
          long: event.longitude,
          zip: event.postal_code,
          city: event.locality,
          state: { id: stateObj?.id },
        },
      };
      this.allowed = true;
    },
    async sendTracking() {
      this.sending = true;

      if (this.currentLoad.trackingUpdateAlertTimeRangeEnable === false) {
        let object = {
          trackingUpdateAlertPeriod: this.howOftenSendMail !== null ? +this.howOftenSendMail : 0,
          trackingUpdateAlertActive: this.currentLoad.trackingUpdateAlertActive,
          trackingUpdateAlertStartTime: '2023-05-02T00:00:00.000Z',
          trackingUpdateAlertEndTime: '2023-05-02T23:59:59.000Z',
          trackingUpdateAlertTimeRangeEnable: this.currentLoad.trackingUpdateAlertTimeRangeEnable,
        };
        this.$store
          .dispatch('LoadsStore/trackingUpdateAlert', {
            id: this.currentLoad.id,
            object: object,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('track-update'));
            this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
            this.sending = false;

            this.$emit('close');
          });
      } else {
        this.isValidating = true;
        this.sending = false;

        const validation = await (this.$refs.form as any).validate();

        if (!validation.valid) {
          return false;
        }
        // we overlap the hours because we have a problem fe with this  [10:00 PM] - [08:00 AM]

        let object = {
          trackingUpdateAlertPeriod: this.howOftenSendMail !== null ? +this.howOftenSendMail : 0,
          trackingUpdateAlertActive: this.currentLoad.trackingUpdateAlertActive,
          trackingUpdateAlertStartTime: this.executionWindowStartTimeOrigin,
          trackingUpdateAlertEndTime: this.executionWindowEndTimeOrigin,
          trackingUpdateAlertTimeRangeEnable: this.currentLoad.trackingUpdateAlertTimeRangeEnable,
        };

        this.$store
          .dispatch('LoadsStore/trackingUpdateAlert', {
            id: this.currentLoad.id,
            object: object,
          })
          .then(() => {
            this.sending = false;
            BaseComponent.methods?.showToastSuccess(this.$t('track-update'));
            this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
            this.$emit('close');
          });
      }
    },
    setValuesData() {
      if (this.currentLoad.trackingUpdateAlertActive) {
        this.howOftenSendMail = this.currentLoad.trackingUpdateAlertPeriod;

        const dateOrigin = new Date(this.currentLoad.trackingUpdateAlertStartTime);

        const hourOrigin = dateOrigin.getUTCHours();
        let minuteOrigin: string = dateOrigin.getUTCMinutes().toString();

        let formattedHourOrigin = +hourOrigin < 10 ? `0${hourOrigin}` : hourOrigin.toString();
        let formattedMinuteOrigin =
          +minuteOrigin < 10 ? `0${minuteOrigin}` : minuteOrigin.toString();

        this.executionWindowStartTimeOrigin =
          `${formattedHourOrigin}` + ':' + `${formattedMinuteOrigin}`;

        const dateDestiny = new Date(this.currentLoad.trackingUpdateAlertEndTime);

        const hourDestiny = dateDestiny.getUTCHours();
        let minuteDestiny: string = dateDestiny.getUTCMinutes().toString();

        let formattedHourDestiny = +hourDestiny < 10 ? `0${hourDestiny}` : hourDestiny.toString();
        let formattedMinuteDestiny =
          +minuteDestiny < 10 ? `0${minuteDestiny}` : minuteDestiny.toString();

        this.executionWindowEndTimeOrigin =
          `${formattedHourDestiny}` + ':' + `${formattedMinuteDestiny}`;
      }
    },
    updateHour() {
      if (
        this.executionWindowStartTimeOrigin !== undefined &&
        this.executionWindowStartTimeOrigin !== null &&
        this.executionWindowEndTimeOrigin !== null &&
        this.executionWindowEndTimeOrigin !== '' &&
        this.executionWindowStartTimeOrigin > this.executionWindowEndTimeOrigin
      ) {
        this.executionWindowEndTimeOrigin = this.executionWindowStartTimeOrigin;
        this.showTime = false;
        setTimeout(() => {
          this.showTime = true;
        }, 1);
      }
      if (
        this.executionWindowStartTimeDestiny !== undefined &&
        this.executionWindowStartTimeDestiny !== null &&
        this.executionWindowEndTimeDestiny !== null &&
        this.executionWindowEndTimeDestiny !== '' &&
        this.executionWindowStartTimeDestiny > this.executionWindowEndTimeDestiny
      ) {
        this.executionWindowEndTimeDestiny = this.executionWindowStartTimeDestiny;
        this.showTime = false;
        setTimeout(() => {
          this.showTime = true;
        }, 1);
      }
    },
    updateHourDestiny() {
      if (
        this.executionWindowStartTimeDestiny !== undefined &&
        this.executionWindowStartTimeDestiny !== null &&
        this.executionWindowEndTimeDestiny !== null &&
        this.executionWindowEndTimeDestiny !== '' &&
        this.executionWindowStartTimeDestiny > this.executionWindowEndTimeDestiny
      ) {
        this.executionWindowEndTimeDestiny = this.executionWindowStartTimeDestiny;
      }
    },
  },
});
