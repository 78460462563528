
import { computed, defineComponent, ref } from 'vue';
import { EllipsisHorizontalCircleIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'CardTooltipOptions',
  components: { EllipsisHorizontalCircleIcon },
  props: {
    createTemplate: {
      type: Function,
      required: false,
    },
    load: {
      type: Object,
      required: false,
    },
    isTemplate: {
      type: Function,
      required: false,
    },
    isModify: {
      type: Boolean,
      required: false,
      default: false,
    },
    statusToCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    statusToDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    isClone: {
      type: Boolean,
      required: false,
      default: true,
    },
    isView: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSend: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const isPopperVisible = ref(false);
    const store = useStore();

    const togglePopper = () => {
      isPopperVisible.value = !isPopperVisible.value;
    };

    const isShipperViewer = computed(() => {
      return store.getters['UserStore/getIsShipperViewer'];
    });

    return {
      isPopperVisible,
      isShipperViewer,
      togglePopper,
    };
  },
});
