
import { defineComponent } from 'vue';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EllipsisHorizontalCircleIcon,
} from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import ModalInvitation from '@/components/modals/InvitationModal.vue';
import AddShipperModal from '@/modules/planning/userProfile/AddShipperModal.vue';
import ModalNewUser from '@/components/modals/NewUserModal.vue';
import Spin from '@/components/spin/AnimateSpin.vue';

export default defineComponent({
  name: 'ShipperList',
  components: {
    AddShipperModal,
    ChevronDownIcon,
    ChevronUpIcon,
    EllipsisHorizontalCircleIcon,
    ModalConfirm,
    ModalInvitation,
    ModalNewUser,
    Spin,
  },
  mixins: [BaseComponent],
  data() {
    return {
      type: 'creation',
      openNewUser: false,
      companyForUser: null,
      showDelete: false,
      userToEdit: null,
      userToDelete: null,
      company: {},
      isSending: false,
      allowed: true,
      querySearch: '',
      showSpinner: false,
      loadingAllTable: false,
      page: 1,
      netWorkContactPage: 1,
      showModalShipper: false,
      showModalInvitation: false,
      idToDelete: 0,
      searchQuery: '',
      userType: 'CARRIER|BROKER',
      scrollY: 0,
      sortKey: null as any,
      sortOrder: null as any,
      columns: [
        { label: 'company', key: 'name' },
        { label: 'owner', key: 'owner.name' },
        { label: 'email', key: 'owner.email' },
        { label: 'admin-agent', key: '' },
        { label: 'acquisition-source', key: 'acquisitionSource' },
        { label: 'registration-date', key: 'registrationDate' },
        { label: 'plan', key: 'accessPlanType' },
        { label: 'activated', key: 'active' },
      ],
      invitationLink: null as any,
      invitationType: 'invitation',
      shipperSearch: '',
      searchTimeout: null as any,
      keyUpdateTable: 0,
    };
  },
  beforeUnmount() {
    window.removeEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
  },
  async mounted() {
    this.showSpinner = true;
    this.getCompaniesList();
    this.$store.dispatch('CompanyStore/getCompaniesTotals');
    this.$store.dispatch('CompanyStore/getAdminAgents', { page: 1, maxResults: 100 });

    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
  },
  created() {
    this.$store.dispatch('CountryStore/getCountries').then(() => {
      this.$store.dispatch('CountryStore/getCountryById', this.currentUser?.company?.country?.id);
    });
  },
  computed: {
    companiesList(): any {
      return this.$store.state.CompanyStore.companiesList;
    },
    companiesTotals(): any {
      return this.$store.state.CompanyStore.companiesTotals;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    hasMoreItems(): any {
      return this.page * this.paginationMaxItems <= this.companiesList.length + 1;
    },
    paginationMaxItems(): number {
      return (
        this.$store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 30
      );
    },
    shipperTotal(): any {
      if (Array.isArray(this.companiesTotals)) {
        const totalShipper = this.companiesTotals.reduce((acc, company) => {
          if (company.type === 'SHIPPER') {
            return acc + company.total;
          } else {
            return acc;
          }
        }, 0);
        return totalShipper;
      } else {
        return 0;
      }
    },
    showSticky(): boolean {
      return this.scrollY > 0;
    },
  },
  methods: {
    impersonate(id) {
      const currentRoutePath = this.$router.currentRoute.value.path;

      this.$store.dispatch('UserStore/getUserIdentification', id).then(() => {
        this.$store.dispatch('UserStore/getUserConfig', id).then((response) => {
          this.$store.commit('UserStore/setPreviousRoute', currentRoutePath);

          this.$store.commit('UserStore/setIsTheAdminImpersonating', true);
          this.$store.commit('UserStore/setCurrentUserImpersonating', response);
          this.$router.push('/planning');
        });
      });
    },
    deleteCompany(isDelete) {
      if (isDelete) {
        this.$store
          .dispatch('CompanyStore/deleteCompany', this.idToDelete)
          .then(() => {
            this.fetchCompaniestList();
          })
          .catch((err) => {
            if (err?.response?.data?.detail) {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            }
          })
          .finally(() => {
            this.showDelete = false;
          });
      } else {
        this.showDelete = false;
      }
    },
    async fetchCompaniestList() {
      this.$store.dispatch('CompanyStore/getCompaniesTotals', { name: this.shipperSearch });

      for (let index = 1; index <= this.page; index++) {
        if (index > 1) this.showSpinner = true;
        await this.$store
          .dispatch('CompanyStore/getCompanies', {
            page: index,
            maxResults: this.paginationMaxItems,
            type: 'SHIPPER',
            name: this.shipperSearch,
            sortBy: this.sortKey && this.sortOrder ? this.sortKey + this.sortOrder : null,
          })
          .catch((err) => console.log(err))
          .finally(() => {
            this.showSpinner = false;
            this.loadingAllTable = false;
            this.keyUpdateTable += 1;
          });
      }
    },
    async getCompaniesList() {
      await this.$store
        .dispatch('CompanyStore/getCompanies', {
          page: this.page,
          maxResults: this.paginationMaxItems,
          type: 'SHIPPER',
          sortBy: this.sortKey && this.sortOrder ? this.sortKey + this.sortOrder : null,
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.showSpinner = false;
        });
    },
    getURLInvitation(id, type) {
      this.$store
        .dispatch('UserStore/getURLInvitation', id)
        .then((resp) => {
          this.showModalInvitation = true;
          this.invitationType = type;
          this.invitationLink = resp.data.URL;
        })
        .catch((err) => {
          if (err?.response?.data?.title) {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
          }
        });
    },
    loadMoreCompanies() {
      this.showSpinner = true;
      this.page += 1;
      this.getCompaniesList();
    },
    nextRoute(route: string) {
      this.$router.push(route);
    },
    openCreationShipperModal() {
      this.type = 'creation';
      this.showModalShipper = true;
    },
    openOkModal(id) {
      this.showDelete = true;
      this.idToDelete = id;
    },
    resendInvite(id) {
      if (!this.allowed) return;
      this.allowed = false;
      this.$store
        .dispatch('UserStore/resendInvite', id)
        .then(() => {
          this.getURLInvitation(id, 'invitation');
        })
        .catch((err) => {
          if (err?.response?.data?.title) {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.allowed = true;
          }, 1000);
        });
    },
    showActivationModal(companyId) {
      this.getURLInvitation(companyId, '');
    },
    sortBy(key) {
      if (!this.showSpinner && !this.loadingAllTable && key) {
        if (this.sortKey === key) {
          this.sortOrder = this.sortOrder === '[ASC]' ? '[DESC]' : '[ASC]';
        } else {
          this.sortKey = key;
          this.sortOrder = '[ASC]';
        }
        this.loadingAllTable = true;
        this.fetchCompaniestList();
      }
    },
    submitSearch() {
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.loadingAllTable = true;
        this.fetchCompaniestList();
      }, 500);
    },
    toggleModalInvitation(toggle) {
      this.showModalInvitation = toggle;
    },
    toggleNewUser(bool, company) {
      this.companyForUser = company;
      this.openNewUser = bool;
      if (!bool) {
        this.userToEdit = null;
      }
    },
    viewUserDetail(company) {
      this.$store.dispatch('UserStore/getUser', { id: company.owner.id });
      this.$store.dispatch('CompanyStore/getCompanyAdmin', company.id);
      this.type = 'update';
      this.showModalShipper = true;
    },
  },
});
