
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Icons
import { XCircleIcon } from '@heroicons/vue/24/solid';
// Components
import Datepicker from '@vuepic/vue-datepicker';

export default defineComponent({
  mixins: [BaseComponent],
  data() {
    return {};
  },
  components: { Datepicker, XCircleIcon },
  props: {
    customClass: String,
    iconClear: Boolean,
    modelValue: String,
    minDate: { type: String, default: new Date() },
    maxDate: String,
    placeholder: String,
    startDate: String,
    rangeEnabled: Boolean,
    teleport: Boolean,
    disabledWeekends: Boolean,
  },
  computed: {
    computedPlaceholder(): any {
      return this.placeholder || this.dateFormatLocale().toLowerCase();
    },
    startWeek(): any {
      return this.getLocale === 'es-ES' ? 1 : 0;
    },
    date: {
      get(): any {
        const value = this.modelValue;
        return value?.length === 10 ? value?.replaceAll('-', '/') : value;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    disableWeekends(date) {
      if (this.disabledWeekends) {
        const day = date.getDay();
        return day === 0 || day === 6;
      }
    },
  },
  created(): void {
    //
  },
  mounted(): void {
    //
  },
  updated(): void {
    //
  },
  beforeUnmount(): void {
    //
  },
  unmounted(): void {
    //
  },
});
