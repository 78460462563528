import router from '@/router/index';
export const route = (name: string, params?: any) => {
  if (params) {
    router.push({ name, params });
    return;
  }

  router.push({ name });
};

export const openPlanning = (...params: any) => route('planning', ...params);
export const openNewFtlLoad = (...params: any) => route('newLoad', ...params);
export const openNewLTLload = (...params: any) => route('shipmentDataLTL', ...params);
export const openContacts = (...params: any) => route('contacts', ...params);
export const openUnreadMessages = (...params: any) => route('unreadMessages', ...params);
export const openShipperProfile = (...params: any) => route('shipperProfile', ...params);
export const openTeam = (...params: any) => route('teamView', ...params);
export const openPaymentMethods = (...params: any) => route('paymentsMethods', ...params);
export const openPayments = (...params: any) => route('payments', ...params);
export const openUiComponents = (...params: any) => route('UiComponents', ...params);
export const openTestDemo = (...params: any) => route('planningTestDemo', ...params);
export const openPlanningRFP = (...params: any) => route('PlanningRFP', ...params);
export const openShipperDashboard = (...params: any) => route('shipperDashboard', ...params);
export const openSuperAdminDashboard = (...params: any) => route('superAdminDashboard', ...params);
export const openShippersList = (...params: any) => route('shippersList', ...params);
export const openLoadsList = (...params: any) => route('loadsListAdmin', ...params);
export const openRequestDemoList = (...params: any) => route('requestDemoList', ...params);
export const openPlans = (...params: any) => route('plans', ...params);
export const openWarehousesList = (...params: any) => route('warehousesList', ...params);


//RFP ROUTING
export const openLanesRfp = (...params: any) => route('lanesRFP', ...params);
export const openProjectRFP = (...params: any) => route('ProjectLaneDetail', ...params);


