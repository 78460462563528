
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  mixins: [BaseComponent],
  props: {
    backgroundConfirm: { type: String },
    isLoading: { type: Boolean },
    text: { type: String, required: true },
    textButtonCancel: { type: String },
    textButtonConfirm: { type: String },
  },
  computed: {
    getButtonStyles(): any {
      const styles = this.backgroundConfirm;

      if (this.isLoading) {
        return `${styles} pointer-events-none`;
      }
      return styles;
    },
  }
});
