
import { defineComponent } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
export default defineComponent({
  name: 'PlanCarrierTrackingElement',
  components: { PlanIcon },
  props: {
    title: { type: String, required: false, default: '' },
    withBlankFooter: { type: Boolean, required: false, default: false },
    withLine: { type: Boolean, required: false, default: true },
    isActive: { type: Boolean, required: false, default: false },
    isNotification: { type: Boolean, required: false, default: false }
  }
})
