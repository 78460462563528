
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'ShipperLoadDetailContainerQuotesLTL',
  components: {},
  mixins: [BaseComponent],
  props: {
    loadCreatedId: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      section: 'savedBids',
      isLeft: false,
      alpineTypeEnum: {
        ALPINE_DENSITY: 'Density',
        ALPINE_NMFC: 'NMFC',
      },
      creatingLoad: false,
      companySaved: [] as number[],
    };
  },
  created(): void {
    if (this.currentLoad.status === 'QUOTING') {
      this.$store.dispatch('LoadsStore/getLTLQuotesById', this.$route.params.id).then(() => {
        this.isLoading = false;
      });
      this.$store.dispatch('LoadsStore/totalSavedBidsLTL', this.$route.params.id);
      this.$store.dispatch('ShipmentStore/getSavedShipmentBids', this.$route.params.id);
    } else {
      this.$store.dispatch(
        'ShipmentStore/getLTLShipmentBid',
        this.currentLoad.shipment.selectedBid.id,
      );
    }
  },
  computed: {
    companyAccepted(): any {
      return this.$store.getters['ShipmentStore/companyAccepted'];
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    quotes(): any {
      return this.$store.getters['LoadsStore/getLTLQuotesById'];
    },
    savedBidsLTL(): any {
      return this.$store.getters['ShipmentStore/getSavedShipmentBids'];
    },
    totalSavedBidsLTL(): any {
      return this.$store.getters['LoadsStore/totalSavedBidsLTL'];
    },
  },
  methods: {
    acceptQuote(quoteId) {
      this.$store.dispatch('ShipmentStore/acceptLTLQuote', quoteId).then(() => {
        BaseComponent.methods?.showToastSuccess(this.$t('quotation-accepted'));
        this.$store.dispatch('LoadsStore/loadId', this.$route.params.id);
      });
    },
    isSelected(contactId: number) {
      return this.companySaved.includes(contactId);
    },
    moveLeft(section) {
      this.isLeft = true;
      this.section = section;
    },
    moveRight(section) {
      this.isLeft = false;
      this.section = section;
    },
    removeFromArray(id) {
      this.companySaved = this.companySaved.filter((itemId) => itemId !== id);
    },
  },
});
