
import { defineComponent } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';

export default defineComponent({
  name: 'ModalNewUser',
  components: {
    PhoneNumberSelector,
    Dialog,
    DialogPanel,
    DialogTitle,
    ErrorMessage,
    Field,
    Form,
    TransitionChild,
    TransitionRoot,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  emits: ['setOpen', 'fetchUsers'],
  props: {
    open: Boolean,
    user: Object as any,
    company: Object,
  },
  data() {
    return {
      name: '',
      surname: '',
      email: '',
      prefix: '1',
      phone: '',
      loading: false,
      viewOnlyUser: false,
    };
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
  },
  watch: {
    user() {
      this.name = this.user?.name;
      this.surname = this.user?.surname;
      this.email = this.user?.email;
      this.prefix = this.user?.phonePrefix;
      this.phone = this.user?.phone;
      this.viewOnlyUser = this.user?.roles[0] === 'ROLE_SHIPPER_VIEWER' ? true : false;
    },
  },
  methods: {
    changePhone(type, phone) {
      switch (type) {
        case 'prefix':
          this.prefix = phone.prefix;
          break;
        case 'number':
          this.phone = phone.number;
          break;
        default:
          return;
      }
    },
    toggleOpen(isOpen: boolean) {
      this.$emit('setOpen', isOpen);
    },
    async submitForm() {
      if (this.loading) return;
      this.loading = true;
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid) {
        this.loading = false;
        return;
      }
      if (this.user) {
        await this.putUser();
        this.$emit('fetchUsers');
      } else {
        await this.postUser();
      }
    },
    async postUser() {
      this.loading = true;
      await this.$store
        .dispatch('UserStore/postUser', {
          name: this.name,
          surname: this.surname,
          email: this.email,
          phonePrefix: this.prefix,
          phone: this.phone,
          company: {
            id: this.$route.name === 'shippersList' ? this.company?.id : this.currentUser?.company?.id,
          },
          viewOnlyUser: this.viewOnlyUser,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(
            this.$t('message-user-created', { name: this.name }),
          );
          this.toggleOpen(false);
        })
        .catch((err) => {
          const error = err?.response?.data?.detail ?? err?.response?.data['hydra:description'];
          BaseComponent.methods?.showToastError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async putUser() {
      this.loading = true;
      await this.$store
        .dispatch('UserStore/putUser', {
          id: this.user.id,
          user: {
            name: this.name,
            surname: this.surname,
            email: this.email,
            phonePrefix: this.prefix,
            phone: this.phone,
            company: {
              id: this.currentUser?.company?.id,
            },
            viewOnlyUser: this.viewOnlyUser,
          },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(
            this.$t('message-user-created', { name: this.name }),
          );
          this.toggleOpen(false);
        })
        .catch((err) => {
          const error = err?.response?.data?.detail ?? err?.response?.data['hydra:description'];
          BaseComponent.methods?.showToastError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
