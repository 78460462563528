import creditApi from '../_api';

export default {
  async clearCreditStore(context: any) {
    context.commit('clearCreditStore');
  },
  async getCompanyCredit({ commit }, id) {
    const response = await creditApi.getCompanyCredit(id);
    commit('setCompanyCredit', response);
  },
  async getCompanyCreditBreakdown({ commit }, data) {
    const response = await creditApi.getCompanyCreditBreakdown(data);
    commit('setCompanyCreditBreakdown', { response, data });
  },
  async postCreditRequests({ commit }, data) {
    const response = await creditApi.postCreditRequests(data);
  },
  async putCreditGranted({ commit }, data) {
    const response = await creditApi.putCreditGranted(data);
  },
};
