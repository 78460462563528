
import { defineComponent, reactive, toRefs } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import { useStore } from 'vuex';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';

interface planSearchLocationByCoordinates {
  latitude: string | null,
  loading: boolean,
  longitude: string | null,
  resultsFor: {
    lat: string,
    lng: string
  },
  results: Array<any>
}

export default defineComponent({
  name: 'PlanSearchLocationByCoordinates',
  components: { PlanInputText, PlanDefaultTable, PlanButton, PlanDefaultModal },
  emits: ['close', 'getLocation'],
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive({
      latitude: null,
      loading: false,
      longitude: null,
      resultsFor: {
        lat: '',
        lng: ''
      },
      results: []
    })

    const getLocations = () => {
      data.loading = true
      store.dispatch('LoadsStore/getCoordinatesGoogle', {
        lat: data.latitude,
        lng: data.longitude
      }).then((response) => {
        data.resultsFor = {
          lat: data.latitude + '',
          lng: data.longitude + '',
        }
        data.results = response.data
      }).finally(() => {
        data.loading = false
      })
    }

    const selectLocation = (location: any) => {
      emit('getLocation', location)
    }

    const close = () => { emit('close') }

    return {
      ...toRefs(data),
      close,
      getLocations,
      selectLocation
    }
  }
})
