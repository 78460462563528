import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex justify-center items-center flex-col"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_4 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_5 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (!_ctx.heavyLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_plan_icon, {
          name: "spinner",
          isSvg: "",
          iconColor: _ctx.iconColor,
          rotate: ""
        }, null, 8, ["iconColor"]),
        (!_ctx.small)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.message.length === 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('loading')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.message), 1))
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["w-full flex justify-center items-center flex-col", [
             _ctx.color === 'gray-200' ? 'default-box-gray-200' : '',
             _ctx.color === 'transparent' ? 'default-box-transparent' : ''
         ]])
      }, [
        _createVNode(_component_plan_icon, {
          name: "spinner",
          isSvg: "",
          iconColor: _ctx.iconColor,
          rotate: ""
        }, null, 8, ["iconColor"]),
        _createVNode(_component_plan_icon, {
          name: "coffee",
          isSvg: "",
          class: "mt-8 text-gray-400",
          size: "36"
        }),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('general.heavyLoading')), 1)
      ], 2))
}