
import { defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'PlanDefaultCityInformation',
  props: {
    postalCode: { type: String, required: false, default: '' },
    city: { type: String, required: true, default: '' },
    maxCitySize: { type: Number, required: false, default: 0 },
    ct: { type: String, required: false, default: '' },
    isoCode: { type: String, required: false, default: '' },
  },
  setup(props) {
    const cityToShow = computed(() => {
      if (props.maxCitySize > 0 && props.city.length > props.maxCitySize) {
        return props.city.substring(0, props.maxCitySize) + '..';
      } else {
        return props.city;
      }
    });

    return {
      cityToShow,
    };
  },
});
