
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'PlanCarrierTrackingFileNotification',
  components: { PlanTextButton },
  props: {
    id: { type: Number, required: true, default: -1 },
    entityClass: { type: String, required: true, default: '' },
    label: { type: String, required: true, default: '' },
    description: { type: String, required: false, default: '' }
  },
  setup (props) {
    const store = useStore()

    const data = reactive({
      loading: false
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const goToThumbnailBig = () => {
      if (props.id === -1) return

      data.loading = true
      store.dispatch('ShipmentStore/thumbnailBig', {
        id: props.id,
        class: props.entityClass
      }).then((response) => {
        const imageUrl = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = imageUrl;
        link.target = '_blank'
        link.click();
        URL.revokeObjectURL(imageUrl);
        data.loading = false
      })
    }

    return {
      ...toRefs(data),
      actualWidth,
      goToThumbnailBig
    }
  }
})
