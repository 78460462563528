
import { defineComponent, ref, computed, watch, onMounted, nextTick } from 'vue';
import moment from 'moment';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import i18n from '@/resources/locales';

export default defineComponent({
  name: 'LoadsCalendar',
  components: { PlanIcon, PlanButton, PlanButtonIcon },
  emits: ['changeDate'],
  props: {
    id: { type: String, required: true, default: '' },
    locale: { type: String, required: false, default: 'es' },
    max: { type: String, required: false },
    min: { type: String, required: false },
    range: { type: Boolean, required: false, default: false },
    startWithMonday: { type: Boolean, required: false, default: false },
    full: { type: Boolean, required: false, default: false },
    events: { type: Object, required: false },
  },
  setup(props, { emit }) {
    const store = useStore();
    moment.updateLocale(i18n.global.locale.value, {
      week: { dow: i18n.global.locale.value === 'es' ? 1 : 0 },
    });

    const elementsToShow = ref('days');
    const minDate = ref(props.min ? moment(props.min) : null);
    const maxDate = ref(props.max ? moment(props.max) : null);

    console.log('minDate: ', minDate.value);
    console.log('maxDate: ', maxDate.value);

    const selectedDate = ref(moment().locale(i18n.global.locale.value));
    const selectedRange = ref<{ start: moment.Moment | null; end: moment.Moment | null }>({
      start: null,
      end: null,
    });

    if (store.getters['LoadsStore/getAppliedFilters'][props.id]) {
      const dates =
        store.getters['LoadsStore/getAppliedFilters'][props.id].loadFilterParam.split('#');
      if (dates.length === 2) {
        selectedRange.value = {
          start: moment(dates[0]).locale(i18n.global.locale.value),
          end: moment(dates[1]).locale(i18n.global.locale.value),
        };

        emit('changeDate', {
          start: selectedRange.value?.start ? selectedRange.value.start.format('DD-MM-YYYY') : null,
          end: selectedRange.value?.end ? selectedRange.value.end.format('DD-MM-YYYY') : null,
        });
      }
    }

    if (minDate.value && selectedDate.value.isBefore(minDate.value)) {
      selectedDate.value = minDate.value.clone();
    }

    if (maxDate.value && selectedDate.value.isAfter(maxDate.value)) {
      selectedDate.value = maxDate.value.clone();
    }

    const calendar = computed(() => {
      const firstDayOfMonth = selectedDate.value.clone().startOf('month').startOf('week');
      const lastDayOfMonth = selectedDate.value.clone().endOf('month').endOf('week');
      const calendar: Array<any> = [];
      let currentDay = firstDayOfMonth.clone();

      while (currentDay.isBefore(lastDayOfMonth)) {
        const week: Array<any> = [];
        for (let i = 0; i < 7; i++) {
          const isDisabled =
            (minDate.value && currentDay.isBefore(minDate.value)) ||
            (maxDate.value && currentDay.isAfter(maxDate.value));
          week.push({
            currentDay: currentDay,
            date: currentDay.clone(),
            formattedDate: currentDay.format('DD'),
            isDisabled: isDisabled,
          });
          currentDay.add(1, 'day');
        }
        calendar.push(week);
      }

      return calendar;
    });


    const currentDateDefault = computed(() => {
      const date = ref(moment().locale(i18n.global.locale.value));
      return date.value.format('YYYY-MM-DD');
    });

    const currentDay = computed(() => {
      const formattedMonth = selectedDate.value.format('DD');
      return formattedMonth;
    });

    const getAppliedFilters = computed(() => {
      return store.getters['LoadsStore/getAppliedFilters'];
    });

    const currentMonth = computed(() => {
      const formattedMonth = selectedDate.value.format('MMMM');
      return formattedMonth;
    });

    const currentYear = computed(() => {
      const formattedMonth = selectedDate.value.format('YYYY');
      return formattedMonth;
    });

    const daysOfWeek = computed(() => {
      const weekdaysMin = moment.localeData(i18n.global.locale.value).weekdaysMin();
      if (store.getters['UserStore/getRegion'] === 'EUROPE' && weekdaysMin.length > 0) {
        return weekdaysMin.slice(1).concat(weekdaysMin[0]);
      }
      return weekdaysMin;
    });

    const monthsOfYear = computed(() => {
      const allMonths = moment.localeData(i18n.global.locale.value).months() as string[];
      const monthsInRows: string[][] = [];
      const monthsPerRow = 3;

      for (let i = 0; i < allMonths.length; i += monthsPerRow) {
        const row = allMonths.slice(i, i + monthsPerRow);
        monthsInRows.push(row);
      }

      return monthsInRows;
    });

    const titleToShow = computed(() => {
      if (elementsToShow.value === 'days') {
        return currentMonth.value.toUpperCase() + ' ' + currentYear.value;
      } else if (elementsToShow.value === 'months') {
        return currentYear.value;
      } else {
        const currentYear = selectedDate.value.year();
        const startYear = currentYear - 12;
        const endYear = currentYear + 12;

        return `${startYear} / ${endYear}`;
      }
    });

    const yearsRange = computed(() => {
      const currentYear = selectedDate.value.year();
      const startYear = currentYear - 12;
      const endYear = currentYear + 12;
      const range: Array<any> = [];
      for (let year = startYear; year <= endYear; year++) {
        range.push(year);
      }
      return range;
    });

    const changeView = (view) => {
      elementsToShow.value = view;
    };

    const eventBackground = (state: string) => {
      if (state === 'QUOTING') return 'load-card-quoting';
      if (state === 'CONFIRMED') return 'load-card-confirmed';
      if (state === 'ASSIGNED') return 'load-card-assigned';
      if (state === 'IN_PROGRESS') return 'load-card-in-progress';
      if (state === 'FINISHED') return 'load-card-finished';
      if (state === 'CANCELED') return 'load-card-canceled';
      if (state === 'DELETED') return 'load-card-deleted';

      return 'load-card-default';
    };

    const hasEvents = (dayToCompare: string) => {
      if (props.events !== undefined && Object.keys(props.events).includes(dayToCompare)) {
        return props.events[dayToCompare];
      } else {
        return [];
      }
    };

    const onClickPrev = () => {
      if (elementsToShow.value === 'days') {
        selectedDate.value = selectedDate.value.clone().subtract(1, 'months');
      } else if (elementsToShow.value === 'months') {
        selectedDate.value = selectedDate.value.clone().subtract(1, 'years');
      } else {
        selectedDate.value = selectedDate.value.clone().subtract(12, 'years');
      }
    };

    const onClickNext = () => {
      if (elementsToShow.value === 'days') {
        selectedDate.value = selectedDate.value.clone().add(1, 'month');
      } else if (elementsToShow.value === 'months') {
        selectedDate.value = selectedDate.value.clone().add(1, 'year');
      } else {
        selectedDate.value = selectedDate.value.clone().add(12, 'year');
      }
    };

    const selectDate = (day) => {
      if (props.range) {
        if (!selectedRange.value.start) {
          selectedRange.value.start = day.date.clone();
        } else if (!selectedRange.value.end) {
          selectedRange.value.end = day.date.clone();

          if (selectedRange.value.start.isAfter(selectedRange.value.end)) {
            const temp = selectedRange.value.start;
            selectedRange.value.start = selectedRange.value.end;
            selectedRange.value.end = temp;
          }

          emit('changeDate', {
            start: selectedRange.value?.start
              ? selectedRange.value.start.format('DD-MM-YYYY')
              : null,
            end: selectedRange.value?.end ? selectedRange.value.end.format('DD-MM-YYYY') : null,
          });
        } else {
          selectedRange.value = { start: null, end: null };
          selectedRange.value.start = day.date.clone();
        }
      } else {
        emit('changeDate', day.date.format('DD-MM-YYYY'));
        selectedDate.value = day.date;
      }
    };

    const selectMonth = (month) => {
      selectedDate.value = selectedDate.value.clone().month(month);
      elementsToShow.value = 'days';
    };

    const selectYear = (year) => {
      selectedDate.value = selectedDate.value.clone().year(year);
      elementsToShow.value = 'months';
    };

    watch(
      () => props.locale,
      (newLocale) => {
        selectedDate.value.locale(newLocale);
      },
    );

    if (props.events) {
      if (Object.keys(store.getters['LoadsStore/getAppliedFilters']).length > 1) {
        let firstDate = '';
        firstDate = Object.keys(props.events)[0];
        selectedDate.value = moment(firstDate).locale(props.locale);
      }
    }

    return {
      calendar,
      currentDateDefault,
      currentDay,
      currentMonth,
      currentYear,
      elementsToShow,
      getAppliedFilters,
      selectedDate,
      selectedRange,
      daysOfWeek,
      monthsOfYear,
      titleToShow,
      yearsRange,
      changeView,
      eventBackground,
      hasEvents,
      selectDate,
      selectMonth,
      selectYear,
      onClickPrev,
      onClickNext,
    };
  },
});
