
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MessagesView',
  computed: {
    actualRoute(): any {
      return this.$route.name;
    },
    unreadMessages(): any {
      return this.$store.state.ChatsStore.unreadMessages;
    },
    totalUnreadChats(): any {
      return this.$store.state.ChatsStore.totalUnreadChats;
    },
  },
  data() {
    return {};
  },
  beforeCreate() {
    this.$store.dispatch('ChatsStore/getTotalUnreadChats');
  },
});
