import store from '@/services/store';
import i18n from '@/resources/locales';

export const checkCountry = async (url) => {
  let currentUser = null as any;
  const isSuplanting = store.getters['UserStore/getImpersonatedUserId'];
  const currentUserImpersonating = store.getters['UserStore/getCurrentUserImpersonating'];

  if (currentUserImpersonating && isSuplanting) {
    currentUser = currentUserImpersonating;
  } else {
    currentUser = store.getters['UserStore/getCurrentUser'];
  }

  const { params } = url;
  const { hash, code } = params;
  if (hash && code) {
    await store.dispatch('UserStore/hashValidation', {
      hashId: hash,
      secureCode: code,
    });
  }

  if (!currentUser?.company) {
    return;
  }

  return store
    .dispatch('CountryStore/getCountryById', currentUser?.company?.country?.id)
    .then(() => {
      i18n.global.locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'].substring(
        0,
        2,
      );
      localStorage.setItem(
        'userLocale',
        store.getters['CountryStore/getCountryDefaultLanguageLocale'].substring(0, 2),
      );
    });
};
