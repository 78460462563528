
import { computed, defineComponent } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import i18n from '@/resources/locales';
export default defineComponent({
  name: 'PlanCarrierPreAssignedBox',
  components: { PlanIcon, PlanButton },
  emit: ['handleConfirmLane'],
  props: {
    position: { type: Number, required: true, default: 1 },
    isAccepted: { type: Boolean, required: false, default: false },
    isPreAcceptedQuote: { type: Boolean, required: true },
    currentStatus: { type: String, default: '' },
  },
  setup(props) {
    const store = useStore();
    const translate = useI18n();

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const currentLaneStatus = computed(() => {
      switch (props.currentStatus) {
        case 'ACCEPTING':
          return translate.t('position-pre-assigned');
        case 'CONFIRMED':
          return `${getSuffix(props.position)} ${translate.t('position')}`;
        case 'REFUSED':
          return translate.t('ranking-refused');
        default:
          return translate.t('position-pre-assigned');
      }
    });

    const getSuffix = (number) => {
      if (Number(number)) {
        const colors = ['bg-amber-500', 'bg-gray-300'];
        let localLanguage = i18n.global.locale.value as any;
        if (localLanguage === undefined) {
          localLanguage =
            localStorage.getItem('userLocale') || navigator.language.substring(0, 2) || 'en';
        }

        const suffixesEnglish = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
        const suffixesSpanish = ['ª', 'ª', 'ª', 'ª', 'ª', 'ª', 'ª', 'ª', 'ª', 'ª'];
        const suffixes = localLanguage === 'es' ? suffixesSpanish : suffixesEnglish;

        const index = number < 4 ? 0 : 1;
        return number + suffixes[number % suffixes.length];
      } else {
        return 0;
      }
    };

    const buttonType = computed(() => {
      if (props.position === 1) return 'white-gold';
      if (props.position === 2) return 'white-silver';
      if (props.position === 3) return 'white-bronze';
      return 'primary';
    });

    return {
      actualWidth,
      buttonType,
      currentLaneStatus,
    };
  },
});
