
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  components: {
    ErrorMessage,
    Field,
    Form,
    PencilSquareIcon,
  },
  mixins: [BaseComponent],
  props: {
    load: {
      type: Object as () => any,
    },
    creatingLoad: {
      type: Boolean,
    },
  },
  data() {
    return {
      formFreight: true,
      measurement: this.load.measurementSystem,
      CalculationsTypes: '1728', // CHANGE THIS TO A FUTURE LIKE ALPINE IN A ENV VAR
      freight: [] as Array<any>,
      classAddButtonDisabled: this.addButtonClassDisabled(),
      classAddButtonNormal: this.addButtonClassNormal(),
      packageType: { id: 0 },
      nPallets: 0,
      STC: '',
      commodityName: '',
      nmfc: 0,
      NMFCClass: null,
      NMFCCode: '',
      hazardous: false,
      UNNANumber: 0,
      freezable: false,
      hazmatDescription: '',
      stackable: false,
      maxStackedPackages: null as any,
      weight: '',
      length: '',
      width: '',
      height: '',
      volume: 0,
      PCF: 0,
      classValue: 0,
      densityClass: { id: 0 },
      indexFreight: null,
    };
  },
  computed: {
    freezableValue: {
      get(): any {
        return this.load.freezable;
      },
      set(value): any {
        this.freezable = value === 'true';
        let updatedLoad = {
          ...this.load,
        };

        updatedLoad.freezable = this.freezable;
        this.$emit('update', updatedLoad);
      },
    },
    quantity: {
      get(): any {
        return this.nPallets > 0 ? this.nPallets : '';
      },
      set(value): any {
        this.nPallets = value;
      },
    },
    UNNAValue: {
      get(): any {
        return this.UNNANumber > 0 ? this.UNNANumber : '';
      },
      set(value): any {
        this.UNNANumber = value;
      },
    },
    commodityUnitTypes(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']?.CommodityUnitTypes;
    },
    density(): any {
      return this.$store.getters['LoadsStore/getdensityClass'];
    },
    freightVolumes(): any {
      return this.totalVolumeCalculator(this);
    },
    indexToModify(): any {
      let isEditing = false;

      if (this.indexFreight) {
        isEditing = true;
      }

      return isEditing;
    },
    isButtonAddEnabled(): any {
      const defaultFields =
        this.packageType.id > 0 &&
        this.nPallets > 0 &&
        this.commodityName &&
        parseInt(this.weight) > 0 &&
        parseInt(this.length) > 0 &&
        parseInt(this.width) > 0 &&
        parseInt(this.height) > 0;
      const isNMFC = this.nmfc === 1 && this.NMFCClass && this.NMFCCode.length > 0;
      const isHazardous =
        this.hazardous && this.UNNANumber > 0 && this.hazmatDescription.length > 0;
      const isStackable = this.stackable && this.maxStackedPackages;

      if (this.nmfc === 1 && this.hazardous && this.stackable) {
        // 1-1-1
        return defaultFields && isNMFC && isHazardous && isStackable;
      }
      if (this.nmfc === 1 && this.hazardous && !this.stackable) {
        // 1-1-0
        return defaultFields && isNMFC && isHazardous && !isStackable;
      }
      if (this.nmfc === 1 && !this.hazardous && this.stackable) {
        // 1-0-1
        return defaultFields && isNMFC && !isHazardous && isStackable;
      }
      if (this.nmfc === 1 && !this.hazardous && !this.stackable) {
        // 1-0-0
        return defaultFields && isNMFC;
      }
      if (this.nmfc === 0 && this.hazardous && this.stackable) {
        // 0-1-1
        return defaultFields && !isNMFC && isHazardous && isStackable;
      }
      if (this.nmfc === 0 && this.hazardous && !this.stackable) {
        // 0-1-0
        return defaultFields && isHazardous;
      }
      if (this.nmfc === 0 && !this.hazardous && this.stackable) {
        // 0-0-1
        return defaultFields && isStackable;
      }
      return defaultFields; // 0-0-0
    },
    NMFC(): any {
      return this.$store.getters['LoadsStore/NMFC']?.data;
    },
    packages(): any {
      return this.$store.getters['LoadsStore/packageTypes'].LTL;
    },
    PCFTotal(): any {
      return (parseFloat(this.weight) / this.totalVolumeCalculator(this)).toFixed(2);
    },
    sumHeight(): any {
      return this.freight.reduce((accumulator, obj) => accumulator + parseFloat(obj.height), 0);
    },
    sumLength(): any {
      return this.freight.reduce((accumulator, obj) => accumulator + parseFloat(obj.length), 0);
    },
    sumVolume(): any {
      return this.freight.reduce(
        (accumulator, obj) => accumulator + obj.nPallets * parseFloat(obj.volume),
        0,
      );
    },
    sumWeight(): any {
      return this.freight.reduce(
        (accumulator, obj) => accumulator + obj.nPallets * parseFloat(obj.weight),
        0,
      );
    },
    sumWidth(): any {
      return this.freight.reduce((accumulator, obj) => accumulator + parseFloat(obj.width), 0);
    },
  },
  methods: {
    addButtonClassDisabled() {
      return 'bg-btnaction w-1/6 float-right opacity-50 text-white text-center text-sm disabled disable-button py-2 px-3 rounded-2xl';
    },
    addButtonClassNormal() {
      return (
        'bg-btnaction w-1/6 float-right cursor-pointer hover:opacity-70 text-center text-white text-sm' +
        ' py-2 px-3 rounded-2xl focus:outline-none focus:shadow-outline'
      );
    },
    async addFreight() {
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid) {
        return;
      }

      if (!this.volume) {
        await BaseComponent.methods?.showToastError(this.$t('volume-null'));
      } else {
        this.freight.push({
          packageType: this.packageType,
          nPallets: this.nPallets,
          STC: this.STC,
          commodityName: this.commodityName,
          nmfc: this.nmfc,
          NMFCClass: this.NMFCClass,
          NMFCCode: this.NMFCCode,
          hazardous: this.hazardous,
          UNNANumber: this.UNNANumber,
          hazmatDescription: this.hazmatDescription,
          stackable: this.stackable,
          maxStackedPackages: this.maxStackedPackages,
          weight: this.weight,
          length: this.length,
          width: this.width,
          height: this.height,
          volume: this.volume,
          PCF: this.PCF,
          classValue: this.classValue,
          densityClass: this.densityClass,
        });
        this.closeFormFreight();
      }
    },
    async addMoreFreight() {
      this.formFreight = true;
      // this.packageType = { id: 0 };
      this.nPallets = 0;
      this.STC = '';
      this.commodityName = '';
      this.nmfc = 0;
      this.NMFCClass = null;
      this.NMFCCode = '';
      this.hazardous = false;
      this.UNNANumber = 0;
      this.hazmatDescription = '';
      this.stackable = false;
      this.maxStackedPackages = null;
      this.weight = '';
      this.length = '';
      this.width = '';
      this.height = '';
      this.volume = 0;
      this.PCF = 0;
      this.classValue = 0;
      this.densityClass = { id: 0 };
    },
    closeFormFreight() {
      this.indexFreight = null;
      this.formFreight = false;
    },
    deleteElement(index) {
      this.freight.splice(index, 1);
      const updatedLoad = {
        ...this.load,
        freight: [...this.freight],
      };

      this.$emit('update', updatedLoad);
    },
    async isValidForm() {
      const validation = await (this.$refs.form as any).validate();
      return validation.valid;
    },
    getPackageTypeById(id) {
      if (!this.packages || this.packages.length === 0) {
        return '';
      }
      const result = this.packages.filter((item) => item.id === id);
      return result.length > 0 ? result[0].fullName : '';
    },
    setEditedFreight(item, index) {
      this.formFreight = true;
      this.indexFreight = index + 1;
      this.packageType.id = item.packageType.id;
      this.nPallets = item.nPallets;
      this.STC = item.STC;
      this.commodityName = item.commodityName;
      this.weight = item.weight;
      this.length = item.length;
      this.width = item.width;
      this.height = item.height;
      this.volume = item.volume;
      this.PCF = item.PCF;
      this.classValue = item.classValue;
      this.densityClass.id = item.densityClass.id;
      this.nmfc = item.nmfc;
      this.NMFCClass = item.NMFCClass;
      this.NMFCCode = item.NMFCCode;
      this.hazardous = item.hazardous;
      this.hazmatDescription = item.hazmatDescription;
      this.UNNANumber = item.UNNANumber;
      this.stackable = item.stackable;
      this.maxStackedPackages = item.maxStackedPackages;
    },
    async submit() {
      if (this.freight.length === 0) {
        return;
      }

      if (await this.isValidForm()) {
        const updatedLoad = {
          ...this.load,
          freight: [...this.freight],
        };

        this.$emit('update', updatedLoad);
        this.$emit('setValidStep', 2);
        this.$emit('changeStep', 'step-three');
        // this.$emit('getQuotes');
      }
    },
    totalVolumeCalculator(item): any {
      return item.length && item.width && item.height
        ? (
            (parseFloat(item.length) * parseFloat(item.width) * parseFloat(item.height)) /
            parseFloat(this.CalculationsTypes)
          ).toFixed(2)
        : 0;
    },
    async updateFreight() {
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid || !this.indexFreight) {
        return;
      }

      if (!this.volume) {
        await BaseComponent.methods?.showToastError(this.$t('volume-null'));
      } else {
        let up = this.freight[this.indexFreight - 1];

        up.packageType = this.packageType;
        up.nPallets = this.nPallets;
        up.STC = this.STC;
        up.commodityName = this.commodityName;
        up.weight = this.weight;
        up.length = this.length;
        up.width = this.width;
        up.height = this.height;
        up.volume = this.volume;
        up.PCF = this.PCF;
        up.classValue = this.classValue;
        up.densityClass = this.densityClass;
        up.nmfc = this.nmfc;
        up.NMFCClass = this.nmfc ? this.NMFCClass : null;
        up.NMFCCode = this.nmfc ? this.NMFCCode : '';
        up.hazardous = this.hazardous;
        up.UNNANumber = this.hazardous ? this.UNNANumber : 0;
        up.hazmatDescription = this.hazardous ? this.hazmatDescription : '';
        up.stackable = this.stackable;
        up.maxStackedPackages = this.stackable ? this.maxStackedPackages : (null as any);

        this.closeFormFreight();
      }
    },
    updateLoadValue() {
      this.$emit('update:load', {
        ...this.load,
        freezable: this.freezable,
        measurementSystem: this.measurement,
      });
    },
    updateVolume() {
      this.volume = parseFloat(this.totalVolumeCalculator(this));
      this.PCF = parseFloat(this.weight) / this.volume;

      if (!this.weight && !this.length && !this.width && !this.height) {
        this.classValue = 0;
        return;
      }

      let results;
      if (this.PCF < 30) {
        results = this.density.filter(
          (item) => this.PCF >= item.fromValue && this.PCF <= item.toValue,
        );
        if (results[0] && results[0].class && results[0].id) {
          this.densityClass.id = results[0].id;
          this.classValue = results[0].class;
        }
      } else {
        results = this.density.find((item) => item.fromValue === 30 && item.toValue === -1);
        if (results && results.class && results.id) {
          this.densityClass.id = results.id;
          this.classValue = results.class;
        }
      }
    },
  },
  watch: {
    freight: {
      deep: true,
      handler(newVal) {
        newVal.forEach((item) => {
          if (item.maxStackedPackages) {
            item.maxStackedPackages = parseInt(item.maxStackedPackages);
          }
        });
      },
    },
    packages: {
      deep: true,
      handler(newVal) {
        newVal.forEach((item) => {
          if (item.fullName === 'Pallets') {
            this.packageType.id = item.id;
          }
        });
      },
    },
  },
});
