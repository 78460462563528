
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'ShipperAuth',
  components: { PlanIcon },
  props: {
    id: { type: String, required: false, default: null },
    hash: { type: String, required: false, default: null },
    code: { type: String, required: false, default: null },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const translate = useI18n();

    moment.locale(translate.locale.value);

    const loginUser = () => {
      store
        .dispatch('UserStore/hashValidation', {
          hashId: props.hash,
          secureCode: props.code,
        })
        .then(() => {
          store.dispatch('PusherStore/initializePusher');
          router.push({ name: 'ProjectLaneDetail', params: { projectId: props.id } });
        });
    };

    loginUser();
  },
});
