
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import i18n from '@/resources/locales';
import BaseComponent from '@/base/BaseComponent';
import BaseModal from '@/components/modals/BaseModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';

export default {
  mixins: [BaseComponent],
  components: { PlanButton, BaseModal, PlanButtonIcon, ExclamationCircleIcon },
  props: {
    open: Boolean,
    load: Object,
    isModify: Boolean,
  },
  setup(props, { emit }) {
    //* Global /
    const store = useStore();
    const $t = i18n.global.t;

    //**Variables */

    const loadingPostDocuments = ref(false);
    const uploadDocumentAction = ref(false);
    const FILES = ref([] as any);
    const documentUploaded = ref(null);
    const mediaObjectUrls = ref([] as Array<any>);

    const onClickClose = () => {
      emit('close');
    };

    const handleFileChange = (event) => {
      FILES.value = event.target.files;
    };

    const removeFile = (index: number): void => {
      const filesArray = Array.from(FILES.value);
      filesArray.splice(index, 1);
      FILES.value = filesArray;
    };

    const generateObjectURL = (file) => {
      return URL.createObjectURL(file);
    };

    const getDocumentsUploaded = () => {
      let getDocumentsUploaded = document.getElementById('getDocumentsUploaded');
      if (getDocumentsUploaded) getDocumentsUploaded.click();
    };

    /** Computed */

    const additionalDocuments = computed(() => {
      return store.getters['LoadsStore/getLoadAditionalDocuments'];
    });

    const buttonEnabled = computed(() => {
      const selectedFiles = Array.from(FILES.value);
      return selectedFiles.length > 0 ? 'primary-dark': 'gray-outline';
    });

    const deleteFile = (id, index) => {
      if (props?.load?.mediaObjects?.length > 0 && props?.isModify) {
        store.dispatch('LoadsStore/deleteLoadMediaObject', id).then(() => {
          store.dispatch('LoadsStore/loadById', props?.load?.id).then((response) => {
            BaseComponent.methods?.showToastSuccess($t('ranking-quote-delete'));
            emit('setLoad', response);
            mediaObjectUrls.value.splice(index, 1);
          });
        });
      } else {
        const files = [...store.getters['LoadsStore/getLoadAditionalDocuments']];
        files.splice(id, 1);
        store.commit('LoadsStore/setLoadAditionalDocuments', files);
      }
    };

    const UploadDocuments = async () => {
      const filesArray = Array.from(FILES.value);
      if (props?.isModify) {
        if (filesArray.length > 0) {
          loadingPostDocuments.value = true;
          await Promise.all(
            filesArray.map(async (file) => {
              await store
                .dispatch('LoadsStore/postMediaObjectLoad', {
                  id: props?.load?.id,
                  file: file,
                })
                .catch((err) => {
                  console.log(err);
                });
            }),
          ).then(() => {
            BaseComponent.methods?.showToastSuccess($t('created-successfully'));
            loadingPostDocuments.value = false;
            store.dispatch('LoadsStore/loadById', props?.load?.id).then((response) => {
              emit('setLoad', response);
              emit('close', response);
            });
          });
        }
      } else {
        if (additionalDocuments.value.length > 0) {
          const existingFiles = store.getters['LoadsStore/getLoadAditionalDocuments'];
          const mergedFiles = [...existingFiles, ...filesArray];
          store.commit('LoadsStore/setLoadAditionalDocuments', mergedFiles);
        } else {
          store.commit('LoadsStore/setLoadAditionalDocuments', filesArray);
        }

        FILES.value = [];
      }
    };

    const getMediaObjectOfAdditionalFee = async (id: number) => {
      const result = await store.dispatch('ShipmentStore/thumbnailBig', {
        id: id,
        class: 'LOAD',
      });

      mediaObjectUrls.value.push({ id: id, url: URL.createObjectURL(result) });
    };

    if (props?.load?.mediaObjects?.length > 0 && props?.isModify) {
      props?.load?.mediaObjects?.forEach(function (mediaObject: any) {
        if (mediaObject.entityClass === 'LOAD') {
          getMediaObjectOfAdditionalFee(mediaObject.id);
        }
      });
    }

    return {
      additionalDocuments,
      buttonEnabled,
      deleteFile,
      uploadDocumentAction,
      loadingPostDocuments,
      FILES,
      documentUploaded,
      generateObjectURL,
      handleFileChange,
      UploadDocuments,
      mediaObjectUrls,
      getDocumentsUploaded,
      removeFile,
      onClickClose,
    };
  },
};
