export function convertObjectToArray(data) {
  if (!data) {
    return [];
  }

  if (Array.isArray(data)) {
    return data;
  }

  // Convert object to array
  return new Array(data);
}
