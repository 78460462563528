
import { defineComponent } from 'vue';
import { AdjustmentsVerticalIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Calendar from '@/components/calendar/BaseCalendar.vue';
import CardComponent from '@/components/card/CardComponent.vue';
import HeaderLaneDetail from '@/modules/rfp/lanesDetail/_components/HeaderLaneDetail.vue';
import HeaderLaneDetailLoadsSkeleton from '@/modules/rfp/lanesDetail/_components/HeaderLaneDetailLoadsSkeleton.vue';
import RankingRFPModal from '../_components/modals/RankingRFPModal.vue';
import ShipperLoadDetailContent from '@/modules/planning/loadDetail/ShipperLoadDetailContent.vue';
import SkeletonCardComponent from '@/components/skeletons/CardSkeleton.vue';

export default defineComponent({
  name: 'LaneDetailLoads',
  components: {
    AdjustmentsVerticalIcon,
    Calendar,
    CardComponent,
    HeaderLaneDetail,
    HeaderLaneDetailLoadsSkeleton,
    RankingRFPModal,
    SkeletonCardComponent,
    ShipperLoadDetailContent,
  },
  mixins: [BaseComponent],
  data() {
    return {
      currentView: '',
      showDropdown: false,
      showRankingModal: false,
      modalVisible: false,
      componentKeyUpdate: false as any,
      allowed: true,
      isLoading: true,
      isLoadingCalendar: true,
      isLoadingTotals: true,
      isLoadingHeader: true,
      isFilteringCalendar: true,
      isFilteringByStatus: 0,
      isMoreLoads: false,
      currentDate: new Date(),
      filtersProp: {
        page: 1,
      },
      idFilter: '',
      pickup: '',
      delivery: '',
      dateAfter: undefined as any,
      dateBefore: undefined as any,
      trailerTypeId: '',
      commodity: '',
      querySearch: '',
      dateFormat: 'MM-dd-yyyy',
      loadsQuantity: [],
      isFilter: false,
      condition: false,
      sortBy: 'id-DESC',
      sortByExecutionDate: 'ASC',
      sortById: 'ASC',
      sortByQuery: 'executionDate[ASC]',
      showSpinner: false,
      page: 1,
      scrollY: 0,
      fixPlanning: true,
      searchTimeout: null as any,
      filterDate: null,
      isHashMatchLocal: true,
      isHashMatchUser: true,
      week: null as any,
    };
  },
  props: {
    id: String,
    hash: {
      type: String,
      default: null,
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
    this.fetchAllInfo();
  },
  created() {
    const id = this.laneId;
    this.filtersProp['lanesRFP.id'] = id;
    this.$store.dispatch('RFPStore/getLaneById', id);
    this.$store.dispatch('RFPStore/getProjectById', {
      projectId: this.projectId,
      page: 1,
    });

    if (this.$route.params.id) {
      this.modalVisible = true;
    }
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    activeStatus(): string {
      return this.$store.getters['GlobalStore/getActiveStatus'];
    },
    currentLane(): any {
      return this.$store.getters['RFPStore/getCurrentLane'];
    },
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    deliveryThisWeek(): any {
      if (this.dateBefore) {
        const today = new Date();
        // Calculate the start date of the week (Monday)
        const monday = new Date(today);
        monday.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));

        // Calculate the end date of the week (Sunday)
        const sunday = new Date(today);
        sunday.setDate(monday.getDate() + 6);
        return (
          this.dateBefore[0] === this.formatDateStringReverse(this.getDayAfter(monday)) &&
          this.dateBefore[1] === this.formatDateStringReverse(this.getDayAfter(sunday))
        );
      }
      return false;
    },
    deliveryToday(): any {
      if (this.dateBefore) {
        return (
          this.dateBefore[0] === this.formatDateStringReverse(this.getDayAfter(new Date())) &&
          this.dateBefore[1] === this.formatDateStringReverse(this.getDayAfter(new Date()))
        );
      }
      return false;
    },
    filters(): any {
      return this.$store.state.planificationFilters;
    },
    finalDeliveryStop(): any {
      let finalStop = null;
      if (this.currentLane?.loadTemplate?.stops) {
        this.currentLane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'DELIVERY_FINAL') {
            finalStop = stop;
          }
        });
      }
      return finalStop;
    },
    hasMoreItems(): any {
      return this.page * this.maxResults <= this.loadsInfo.length;
    },
    initialPickUpStop(): any {
      let initialStop = null;
      if (this.currentLane?.loadTemplate?.stops) {
        this.currentLane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'PICKUP_INITIAL') {
            initialStop = stop;
          }
        });
      }
      return initialStop;
    },
    isFiltering(): any {
      const { idFilter, pickup, delivery, dateAfter, dateBefore, trailerTypeId, commodity } = this;
      return (
        !!idFilter ||
        !!pickup ||
        !!delivery ||
        (!!dateAfter && this.section === 'list') ||
        (!!dateBefore && this.section === 'list') ||
        !!trailerTypeId ||
        !!commodity
      );
    },
    isLaneDetail(): any {
      return this.$route.name === 'RFP-laneDetail' || this.$route.name === 'RFP-laneService';
    },
    laneId(): any {
      return this.$route.params.laneId;
    },
    loadsCalendar(): any {
      return this.$store.getters['LoadsStore/getLoadsByDates'];
    },
    loadId(): any {
      return this.$store.getters['LoadsStore/loadId'];
    },
    loadsInfo(): any {
      return this.$store.getters['LoadsStore/getLoads'];
    },
    localStorageQuerySearch(): any {
      return JSON.parse(localStorage.getItem('filterQuery') as any);
    },
    maxResults(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']
        ? parseInt(
            this.$store.getters['GlobalStore/getGlobalConfig'].PAGINATION_DEFAULT_MAX_RESULTS,
          )
        : 30;
    },
    pickUpThisWeek(): any {
      if (this.dateAfter) {
        const today = new Date();
        // Calculate the start date of the week (Monday)
        const monday = new Date(today);
        monday.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));

        // Calculate the end date of the week (Sunday)
        const sunday = new Date(today);
        sunday.setDate(monday.getDate() + 6);
        return (
          this.dateAfter[0] === this.formatDateStringReverse(this.getDayAfter(monday)) &&
          this.dateAfter[1] === this.formatDateStringReverse(this.getDayAfter(sunday))
        );
      }
      return false;
    },
    pickUpToday(): any {
      if (this.dateAfter) {
        return (
          this.dateAfter[0] === this.formatDateStringReverse(this.getDayAfter(new Date())) &&
          this.dateAfter[1] === this.formatDateStringReverse(this.getDayAfter(new Date()))
        );
      }
      return false;
    },
    projectId(): any {
      return this.$route.params.projectId;
    },
    plannerTotalsDayWeek(): any {
      return this.$store.state.LoadsStore.plannerTotalsDayWeek;
    },
    section(): string {
      return this.$store.getters['GlobalStore/getSection'];
    },
    showSticky(): boolean {
      return this.scrollY > 0 && this.fixPlanning;
    },
    statusList(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']
        ? this.$store.getters['GlobalStore/getGlobalConfig'].LoadStatus.filter(
            (status) => status !== 'DELETED',
          )
        : null;
    },
    totalsList(): any {
      return this.$store.getters['LoadsStore/getTotals'].totals;
    },
  },
  methods: {
    clearFilterDates(type) {
      if (type === 'pickup') {
        this.dateAfter = undefined;
      } else {
        this.dateBefore = undefined;
      }
      this.filterDate = null;
      this.submitSearch();
    },
    clearFilters() {
      this.idFilter = '';
      this.pickup = '';
      this.delivery = '';
      this.dateAfter = undefined;
      this.dateBefore = undefined;
      this.trailerTypeId = '';
      this.commodity = '';
      localStorage.removeItem('filterQuery');
      this.submitSearch();
    },
    completeRC() {
      this.$store.dispatch('LoadsStore/showModalCarrierAssigned', false).then(() => {
        this.modalVisible = true;
      });
      this.$router.push('/planning/service/' + this.loadId);
    },
    fetchAllInfo() {
      if (this.hash !== localStorage.hash && this.hash) {
        this.isHashMatchLocal = false;
      }
      localStorage.setItem('hash', this.hash);
      this.setFilter();
      if (this.section === 'list') {
        this.fetchFilterLoads();
        this.getPlannerTotalsDayWeek();
      } else {
        this.fetchLoadsCalendar(true);
      }
      this.$store.dispatch('LoadsStore/trailers');
      if (this.id) {
        this.loadId = this.id;
        this.modalVisible = true;
      }
    },
    fetchAllLoads() {
      this.isLoading = true;
      const query = JSON.parse(localStorage.getItem('filterQuery') as any);
      query.page = this.page;
      query['lanesRFQ.id'] = this.laneId;

      this.getFilterLoads(query);
      this.getFilterLoadsTotals(query);
    },
    fetchFilterLoads() {
      if (!this.allowed) return;
      const query = JSON.parse(localStorage.getItem('filterQuery') as any);
      query.page = this.page;
      query['lanesRFP.id'] = this.laneId;

      this.allowed = false;
      this.isLoading = true;

      this.getFilterLoads(query);
      this.getFilterLoadsTotals(query);
    },
    fetchLoadsCalendar(loadCalendar) {
      this.isLoadingCalendar = loadCalendar;
      let query = JSON.parse(localStorage.getItem('filterQuery') as any);
      query.page = this.page;
      query['lanesRFP.id'] = this.laneId;

      if (
        !this.pickUpToday &&
        !this.pickUpThisWeek &&
        !this.deliveryToday &&
        !this.deliveryThisWeek
      ) {
        query = Object.fromEntries(
          Object.entries(query).filter(
            ([key, value]) =>
              key !== 'pickupInitial.date[after]' &&
              key !== 'deliveryFinal.date[before]' &&
              key !== 'pickupInitial.date[before]' &&
              key !== 'deliveryFinal.date[after]',
          ),
        ) as any;
      }

      this.$store
        .dispatch('LoadsStore/loadsByDates', query)
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.isLoadingCalendar = false;
          this.isLoadingHeader = false;
          this.allowed = true;
        });
    },
    filterCalendar(date: any) {
      // if (this.isFilteringCalendar) return;
      // this.isFilteringCalendar = true;
      const longDate = new Date(date.year, date.month - 1, 1);
      const searchDate = this.formatDateStringReverse(longDate);
      // Esta query no está pasada por el localStore
      let query = {
        identifier: this.idFilter,
        'pickupInitial.place': this.pickup,
        'deliveryFinal.place': this.delivery,
        'trailerType.id': this.trailerTypeId,
        commodity: this.commodity,
        sortBy: this.sortByQuery ? this.sortByQuery : 'id[DESC]',
        page: this.page,
        status: this.activeStatus,
      };
      query['lanesRFP.id'] = this.laneId;
      query['lanesRFP.week'] = this.week;
      query = Object.fromEntries(
        Object.entries(query).filter(
          ([key, value]) => value !== undefined && value !== null && value !== '',
        ),
      ) as any;

      this.getFilterLoadsTotals(query);
      // this.fetchLoadsCalendar(false);
    },
    filterLoads(status: string | null) {
      this.condition = true;
      this.page = 1;
      this.$store.commit('GlobalStore/setActiveStatus', status);
      this.setFilter();
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.getPlannerTotalsDayWeek();
        if (this.section !== 'calendar') {
          const query = JSON.parse(localStorage.getItem('filterQuery') as any);
          query.page = this.page;
          query['lanesRFP.id'] = this.laneId;

          this.allowed = false;
          this.isFilteringByStatus++;

          this.getFilterLoads(query);
        } else {
          this.fetchLoadsCalendar(false);
        }
      }, 500);
    },
    async getFilterLoads(query: any) {
      // Guarda y coge las cargas del store
      await this.$store
        .dispatch('LoadsStore/loads', query)
        .then(() => {
          this.isFilter = true;
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.isLoading = false;
          this.isLoadingHeader = false;
          this.isMoreLoads = false;
          this.allowed = true;
          if (this.isFilteringByStatus > 0) this.isFilteringByStatus--;
        });
    },
    getFilterLoadsTotals(query: any) {
      this.isLoadingTotals = true;
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => key !== 'sortBy' && key !== 'page'),
      ) as any;
      this.$store
        .dispatch('LoadsStore/loadsTotals', query)
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.isLoadingTotals = false;
          this.isFilteringCalendar = false;
        });
    },
    getPlannerTotalsDayWeek() {
      const query = JSON.parse(localStorage.getItem('filterQuery') as any);
      query['lanesRFP.id'] = this.laneId;
      this.$store.dispatch('LoadsStore/plannerTotalsDayWeek', query).catch((err) => {
        BaseComponent.methods?.showToastError(err?.response?.data?.detail);
      });
    },
    getTotal(status) {
      if (this.totalsList) {
        const statusTotal = this.totalsList.find((obj) => obj.status === status);
        if (statusTotal) {
          return statusTotal.total;
        }
      }
      return 0;
    },
    loadsFrequencyPeriod(loadsFrequencyPeriod) {
      let translation = '';

      switch (loadsFrequencyPeriod) {
        case 'WEEK':
          translation = this.$t('frequency-week');
          break;
        case 'MONTH':
          translation = this.$t('frequency-month');
          break;
        case 'TOTAL':
          translation = this.$t('frequency-total');
          break;
      }

      return translation;
    },
    moreLoads() {
      this.page++;
      this.isMoreLoads = true;
      this.fetchFilterLoads();
    },
    nextRoute(route: string) {
      this.$router.push(route);
    },
    seeLoad() {
      this.$store.dispatch('LoadsStore/showModalQuoteSended', false).then(() => {
        this.modalVisible = true;
      });
      this.$router.push('/planning/service/' + this.loadId);
    },
    setLoadId(value: any) {
      this.idFilter = value;
      this.submitSearch();
    },
    setSection(value: string) {
      this.$store.commit('GlobalStore/setSection', value);
      // Al pasar de calendar a list se vuelven a cargar los totales
      if (value === 'list') {
        this.fetchFilterLoads();
      } else {
        this.fetchLoadsCalendar(true);
      }
    },
    setWeeks(value: any, weeks: any) {
      if (value !== -1) {
        this.dateAfter = [];
        this.dateAfter[0] = weeks.start;
        this.dateAfter[1] = weeks.end;
      } else {
        this.clearFilters();
      }
      this.submitSearch();
    },
    setFilter() {
      let query = {
        identifier: this.idFilter,
        'pickupInitial.place': this.pickup,
        'pickupInitial.date[after]': this.dateAfter
          ? this.formatDateStringReverse(new Date(this.dateAfter[0]))
          : undefined,
        'pickupInitial.date[before]': this.dateAfter
          ? this.formatDateStringReverse(new Date(this.dateAfter[1]))
          : undefined,
        'deliveryFinal.place': this.delivery,
        'deliveryFinal.date[after]': this.dateBefore
          ? this.formatDateStringReverse(new Date(this.dateBefore[0]))
          : undefined,
        'deliveryFinal.date[before]': this.dateBefore
          ? this.formatDateStringReverse(new Date(this.dateBefore[1]))
          : undefined,
        'trailerType.id': this.trailerTypeId,
        commodity: this.commodity,
        sortBy: this.sortByQuery ? this.sortByQuery : 'id[DESC]',
        status: this.activeStatus,
        'lanesRFP.week': this.week,
      };
      query = Object.fromEntries(
        Object.entries(query).filter(
          ([key, value]) => value !== undefined && value !== null && value !== '',
        ),
      ) as any;
      localStorage.setItem('filterQuery', JSON.stringify(query));
    },
    setFilterDates(type) {
      this.filterDate = type;
      const today = new Date();
      // Calculate the start date of the week (Monday)
      const monday = new Date(today);
      monday.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));

      // Calculate the end date of the week (Sunday)
      const sunday = new Date(today);
      sunday.setDate(monday.getDate() + 6);
      switch (type) {
        case 'pickUpToday':
          if (!this.dateAfter) {
            this.dateAfter = [];
          }
          this.dateAfter[0] = this.formatDateStringReverse(this.getDayAfter(new Date()));
          this.dateAfter[1] = this.formatDateStringReverse(this.getDayAfter(new Date()));
          break;
        case 'pickUpThisWeek':
          if (!this.dateAfter) {
            this.dateAfter = [];
          }
          this.dateAfter[0] = this.formatDateStringReverse(this.getDayAfter(monday));
          this.dateAfter[1] = this.formatDateStringReverse(this.getDayAfter(sunday));
          break;
        case 'deliveryToday':
          if (!this.dateBefore) {
            this.dateBefore = [];
          }
          this.dateBefore[0] = this.formatDateStringReverse(this.getDayAfter(new Date()));
          this.dateBefore[1] = this.formatDateStringReverse(this.getDayAfter(new Date()));
          break;
        case 'deliveryThisWeek':
          if (!this.dateBefore) {
            this.dateBefore = [];
          }
          this.dateBefore[0] = this.formatDateStringReverse(this.getDayAfter(monday));
          this.dateBefore[1] = this.formatDateStringReverse(this.getDayAfter(sunday));
          break;
      }
      this.setFilter();
      this.fetchFilterLoads();
      this.getPlannerTotalsDayWeek();
      this.fetchLoadsCalendar(true);
    },
    showLoadDetail() {
      this.modalVisible = false;
    },
    sortLoadsBy() {
      if (this.sortBy) {
        const filter = this.sortBy.split('-');
        this.sortByQuery = `${filter[0]}[${filter[1]}]`;
      }
      this.setFilter();
      this.fetchFilterLoads();
    },
    submitSearch() {
      this.setFilter();
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.page = 1;
        this.getPlannerTotalsDayWeek();
        if (this.section === 'list') {
          this.fetchFilterLoads();
        } else {
          this.fetchLoadsCalendar(false);
        }
      }, 500);
    },
    toggleAddLoad() {
      this.$router.push({
        name: 'NewLoadRFP',
        params: { projectId: this.projectId, laneId: this.laneId },
      });
    },
    toggleModalBasic() {
      this.$store.dispatch('LoadsStore/showModalCarrierAssigned', false);
    },
    toggleOpenRanking() {
      this.showRankingModal = !this.showRankingModal;
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.loadId = this.id;
        this.modalVisible = true;
      } else {
        this.modalVisible = false;
      }
    },
    currentUser(): any {
      if (this.currentUser.hashId !== this.hash && this.hash) {
        this.isHashMatchUser = false;
      }
    },
    isLaneDetail() {
      this.fetchAllInfo();
    },
  },
});
