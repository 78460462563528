
import { defineComponent } from 'vue';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
import PlanDefaultCityInformation from '@/components/defaults/PlanDefaultCityInformation.vue';
// Components
import UploadDocumentsNotifyIncidents from '@/modules/carrier/quotationRequest/_componentes/UploadDocumentsNotifyIncidents.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
export default defineComponent({
  components: { PlanDefaultCityInformation },
  mixins: [BaseComponent, CountryMixins],
  data() {
    return {
      count: 0,
    };
  },
  props: {
    filesToUpload: { type: Array as any },
    returnCurrentLoadForUploadDocs: { type: Array as any },
  },
  computed: {
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    isEurope(): boolean {
      const country = this.dataCountries?.find(
        (country) => this.currentUser?.company?.country?.id === country?.id,
      );
      return country?.region?.name === 'EUROPE';
    },
    orderedStopsSequence(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.loads[0].stops !== 'undefined'
      ) {
        let stopsCopy = this.shipment.loads[0].stops.slice();

        let sortedArray = stopsCopy.sort((a, b) => a.sequence - b.sequence);

        return sortedArray;
      } else {
        return null;
      }
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
  },
  methods: {
    async download(load, mediaObjectId, mediaObjectType) {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: mediaObjectId,
        class: mediaObjectType,
      });

      const url = URL.createObjectURL(response);

      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `${
          load.type === 'PICKUP_INITIAL' || load.type === 'PICKUP' ? 'BOL-' : 'POD-'
        }#${load.sequence}-${mediaObjectId}`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
    getLastDocumentUploaded(index): any {
      const biggestObject = this.orderedStopsSequence[index].mediaObjects.reduce((acc, cur) => {
        return cur.id > acc.id ? cur : acc;
      });

      return biggestObject;
    },
    showBillModalEvent() {
      this.$emit('showBillModalEvent');
    },
    showIncidenceModalEvent(stop) {
      this.$emit('showIncidenceModal', stop);
    },
  },
});
