
import { computed, defineComponent, reactive } from 'vue';
import PlanGoogleMap from '@/components/map/PlanGoogleMap.vue';
import PlanCarrierTrackingElement from '@/components/carrier/PlanCarrierTrackingElement.vue';
import { useStore } from 'vuex';
import moment from 'moment/moment';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanCarrierTrackingElementNotification from '@/components/carrier/_assigned/PlanCarrierTrackingElementNotification.vue';
import PlanCarrierUploadCmr from '@/components/carrier/_assigned/PlanCarrierUploadCmr.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import { is } from '@vee-validate/rules';
import PlanCarrierUploadInvoice from '@/components/carrier/_assigned/PlanCarrierUploadInvoice.vue';
import PlanCarrierTrackingFileNotification from '@/components/carrier/PlanCarrierTrackingFileNotification.vue';
import { useI18n } from 'vue-i18n';
import PlanCarrierTrackingGoogleMap from '@/components/carrier/_assigned/PlanCarrierTrackingGoogleMap.vue';
import PlanCarrierTrackingMap from '@/components/carrier/_assigned/PlanCarrierTrackingMap.vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'PlanCarrierTrackingTab',
  methods: { is },
  components: {
    PlanTextButton,
    PlanCarrierTrackingMap,
    PlanCarrierTrackingFileNotification,
    PlanCarrierUploadInvoice,
    PlanCarrierUploadCmr,
    PlanCarrierTrackingElementNotification,
    PlanIcon,
    PlanCarrierTrackingElement,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      loadingThumbnailBig: false,
    });

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const additionalFees = computed(() => {
      return store.getters['CarrierStore/getLoadData'].shipment?.additionalFee
        ? store.getters['CarrierStore/getLoadData'].shipment?.additionalFee
        : [];
    });

    const assignedServiceData = computed(() => {
      let rateConfirmationDate = '';

      loadData.value.shipment.mediaObjects.forEach((mediaObject: any) => {
        if (mediaObject.type === 'RATE_CONFIRMATION') rateConfirmationDate = mediaObject.createdAt;
      });

      return {
        rateConfirmationDate: rateConfirmationDate,
      };
    });

    const formatPrice = (number: number) => {
      const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
      const options = {
        style: 'currency',
        currency: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'USD' : 'EUR',
        maximumFractionDigits: 0,
        useGrouping: true,
      };
      return new Intl.NumberFormat(locale, options).format(number);
    };

    const getFormattedDate = (date: string) => {
      moment.locale(translate.locale.value);
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? moment(date).format('MMM DD, YYYY')
        : moment(date).format('DD MMM, YYYY');
    };

    const getFormattedDateHour = (date: string) => {
      moment.locale(translate.locale.value);
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? moment(date).format('MMM DD, YYYY HH:mm')
        : moment(date).format('DD MMM, YYYY HH:mm');
    };

    const getStopTitle = (stop: any) => {
      let result = ''
      result += stop.sequence
      if (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') result += ` ${translate.t('pick-up')}`
      if (stop.type === 'DELIVERY_FINAL' || stop.type === 'DELIVERY') result += ` ${translate.t('delivery')}`
      result += ` - ${stop.location?.city}, ${stop.location?.state?.iso} ${stop.location?.state?.country?.iso}`
      return result
    }

    const goToAcceptance = () => {
      router.push({
        name: 'CarrierLoadAcceptation',
        params: { id: store.getters['CarrierStore/getLoadData'].id },
      });
    };

    const goToThumbnailBig = (id: number, entityClass: string) => {
      data.loadingThumbnailBig = true;
      store
        .dispatch('ShipmentStore/thumbnailBig', {
          id: id,
          class: entityClass,
        })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = imageUrl;
          link.target = '_blank';
          link.click();
          URL.revokeObjectURL(imageUrl);
          data.loadingThumbnailBig = false;
        });
    };

    const isLoadToBid = computed(() => {
      let result = true;
      loadData.value.stops.forEach((stop: any, stopIndex: number) => {
        if (stop.mediaObjects && stop.mediaObjects.length === 0) result = false;
      });
      return result;
    });

    const isNotificationStop = computed(() => {
      let index = -1;
      loadData.value.stops.forEach((stop: any, stopIndex: number) => {
        if (index === -1 && stop.mediaObjects && stop.mediaObjects.length === 0) {
          index = stopIndex;
        }
      });

      return index;
    });

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    const invoicesToShow = computed(() => {
      let result: Array<any> = [];
      if (loadData.value.invoices) {
        loadData.value.invoices.forEach((invoice: any) => {
          let paidDate = invoice.paidDate
            ? `${translate.t('paid-on')} ${getFormattedDateHour(invoice.paidDate)}`
            : translate.t('not-paid');
          let mediaObjectId = -1;
          let mediaObjectEntityClass = '';

          if (invoice.mediaObject) {
            mediaObjectId = invoice.mediaObject.id;
            mediaObjectEntityClass = invoice.mediaObject.entityClass;
          }

          result.push({
            label: invoice.number,
            description: paidDate,
            id: mediaObjectId,
            entityClass: mediaObjectEntityClass,
          });
        });
      }
      return result;
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const rateConfirmation = computed(() => {
      let rateConfirmationId = -1;
      loadData.value.shipment.mediaObjects.forEach((mediaObject: any) => {
        if (mediaObject.type === 'RATE_CONFIRMATION') rateConfirmationId = mediaObject.id;
      });
      return {
        id: rateConfirmationId,
        entityClass: 'SHIPMENT',
      };
    });

    return {
      actualWidth,
      additionalFees,
      assignedServiceData,
      formatPrice,
      getFormattedDate,
      getFormattedDateHour,
      getStopTitle,
      goToAcceptance,
      goToThumbnailBig,
      invoicesToShow,
      isLoadToBid,
      isDriver,
      isNotificationStop,
      loadData,
      rateConfirmation,
    };
  },
});
