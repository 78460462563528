import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end-items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.$t('add-new-category'),
    size: "sm-auto",
    "hide-close-button": ""
  }, {
    content: _withCtx(() => [
      _createVNode(_component_plan_input_text, {
        "model-value": _ctx.name,
        "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
        label: _ctx.$t('category-name'),
        placeholder: _ctx.$t('category-name-placeholder')
      }, null, 8, ["model-value", "label", "placeholder"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          disabled: _ctx.loading,
          type: "cancel",
          label: _ctx.$t('cancel')
        }, null, 8, ["onOnClick", "disabled", "label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.postCategory,
          loading: _ctx.loading,
          type: "primary",
          label: _ctx.$t('add'),
          style: {"min-width":"100px"}
        }, null, 8, ["onOnClick", "loading", "label"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}