
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierRfpQuoteHeader from '@/components/carrier/_rfpQuote/PlanCarrierRfpQuoteHeader.vue';
import PlanCarrierRfpQuoteSubHeader from '@/components/carrier/_rfpQuote/PlanCarrierRfpQuoteSubheader.vue';
import PlanCarrierRfpQuotingPerLane from '@/components/carrier/_rfpQuote/PlanCarrierRfpQuotingPerLane.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useRouter } from 'vue-router';
import PlanCarrierLoadHeaderNotification from '@/components/carrier/PlanCarrierLoadHeaderNotification.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'CarrierRfpQuote',
  components: {
    PlanCarrierLoadHeaderNotification,
    PlanButtonIcon,
    PlanButton,
    PlanCarrierRfpQuotingPerLane,
    PlanCarrierRfpQuoteSubHeader,
    PlanCarrierRfpQuoteHeader,
    PlanDefaultCarrierView,
  },
  props: {
    id: { type: Number, required: true, default: -1 },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const data = reactive({
      mode: 'view',
      item: {
        lane: {
          id: 10279,
          loadsFrequency: 12,
          loadsFrequencyPeriod: 'MONTH',
          loadTemplate: {
            id: 19695,
            stops: [
              {
                id: 38108,
                type: 'PICKUP_INITIAL',
                sequence: 1,
                executionDate: '2024-07-01',
                location: { id: 42163, zip: '01432', city: 'Ayer' },
              },
              {
                id: 38109,
                type: 'DELIVERY_FINAL',
                sequence: 2,
                executionDate: '2024-09-30',
                location: { id: 42164, zip: '07307', city: 'Jersey City' },
              },
            ],
            commodityMasters: [{ id: 37989, weight: 40000, commodityName: 'Food Products' }],
            commodityMaster: { id: 37990, weight: 40000, commodityName: 'Food Products' },
            trailerType: { id: 2, type: '', description: "Van 53'" },
          },
          identifier: 'LP1105/43',
          quoteRequests: [
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
            { status: 'WAITING' },
          ],
          computed: {
            loads: {
              planningAmount: 0,
              unassignedAmount: 0,
              completedAmount: 0,
              totalTargetLoadsForPlanningAmount: 36,
              claims: 0,
            },
            quotes: { quoteRequestsAmount: 31, quotedAmount: 0, topRanking: [] },
            totalTrucksPerLane: 48,
          },
        },
        computed: [],
      },
    });

    const changeMode = () => {
      if (data.mode === 'view') {
        data.mode = 'update';
      } else if (data.mode === 'update') {
        data.mode = 'view';
      }
    };

    const getLanesAndQuotesForCarrier = computed(() => {
      return store.getters['RFPStore/getLanesAndQuotesForCarrier'].laneAndQuote;
    });

    const goToCarrierRfpQuote = () => {
      router.push({ name: 'CarrierRfpQuote', params: { id: 1 } });
    };

    return {
      ...toRefs(data),
      getLanesAndQuotesForCarrier,
      changeMode,
      goToCarrierRfpQuote,
    };
  },
});
