
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import BlastEmailForm from '@/modules/planning/contacts/BlastEmail/_forms/BlastEmailForm.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import { useStore } from 'vuex';
import i18n from '@/resources/locales';
import SelectTemplateModal from '@/modules/planning/contacts/BlastEmail/SelectTemplateModal.vue';
import AddTemplateModal from '@/modules/planning/contacts/BlastEmail/AddTemplateModal.vue';
import TemplateDetail from '@/modules/planning/contacts/BlastEmail/TemplateDetail.vue';
import UpdateTemplateModal from '@/modules/planning/contacts/BlastEmail/UpdateTemplateModal.vue';

interface sendBlastEmailForm {
  subject: string;
  contactPerson: string;
  showCurrentContacts: boolean;
  isSendingEmails: boolean;
  additionalInfo: string;
  validationResults: object;
  selectedContacts: Array<any>;
  files: Array<any>;
  senderIncluded: boolean,
  showAddTemplate: boolean,
  showSelectTemplate: boolean,
  showUpdateTemplate: boolean,
  showViewTemplate: boolean,
  templateId: number
}

export default defineComponent({
  name: 'SendBlastEmailForm',
  components: { UpdateTemplateModal, TemplateDetail, AddTemplateModal, SelectTemplateModal, PlanDefaultModal, BlastEmailForm, PlanButton },
  emits: ['close', 'refresh', 'showModalSuccess'],
  setup(props, { emit }) {
    const store = useStore();
    const $t = i18n.global.t;
    const data = reactive<sendBlastEmailForm>({
      subject: '',
      showCurrentContacts: false,
      additionalInfo: '',
      contactPerson: '',
      selectedContacts: [],
      isSendingEmails: false,
      files: [],
      senderIncluded: false,
      validationResults: {
        subject: false,
        additionalInfoSecond: false,
      },
      showAddTemplate: false,
      showSelectTemplate: false,
      showUpdateTemplate: false,
      showViewTemplate: false,
      templateId: -1
    });

    const addTemplateOnClick = () => {
      data.showSelectTemplate = false
      data.showAddTemplate = true
    }

    const cloneTemplate = () => {
      data.showViewTemplate = false
      data.showAddTemplate = true
    }

    const close = () => {
      store.commit('CompanyStore/clearSelectedTemplate')
      emit('close');
    };

    const closeAddTemplateModal = () => {
      data.showAddTemplate = false
      data.showSelectTemplate = true
    }

    const closeAndShowTemplate = (id: any) => {
      data.templateId = id
      data.showAddTemplate = false
      data.showViewTemplate = true
    }

    const closeUpdateModal = () => {
      data.showUpdateTemplate = false
      data.showViewTemplate = true
    }

    const closeViewTemplateModal = () => {
      data.showViewTemplate = false
      data.showSelectTemplate = true
    }

    const isValid = computed(() => {
      if (store.getters['CompanyStore/getSelectedTemplateToSend'] !== null) {
        return data.selectedContacts.length > 0
      } else {
        return data.selectedContacts.length > 0 && data.subject.length > 0 && data.additionalInfo.length > 0
      }
    });

    const sendEmail = () => {
      data.isSendingEmails = true;
      store
        .dispatch('CompanyStore/postBlastEmail', {
          recipients: data.selectedContacts.map((item) => item?.owner?.id ?? item.owner_id),
          subject: data.subject,
          message: data.additionalInfo,
          files: data.files,
          senderIncluded: `${data.senderIncluded}`,
        })
        .then(() => {
          store.commit('CompanyStore/selectEmailToSend', {
            subject: data.subject,
            message: data.additionalInfo
          })
          emit('showModalSuccess');
          emit('refresh');
        })
        .finally(() => {
          data.isSendingEmails = false;
          close();
        });
    };

    const update = (props) => {
      data.subject = props.subject;
      data.selectedContacts = props.selectedContacts;
      data.additionalInfo = props.additionalInfo;
      data.files = props.files;
      data.senderIncluded = props.senderIncluded;
    };

    const updateTemplateOnClick = (id: number) => {
      data.templateId = id
      data.showViewTemplate = false
      data.showUpdateTemplate = true
    }

    const useTemplate = (templateData: any) => {
      data.subject = templateData.subject
      data.additionalInfo = templateData.message
      data.showViewTemplate = false
    }

    const viewTemplateOnClick = (id: number) => {
      data.templateId = id
      data.showViewTemplate = true
      data.showSelectTemplate = false
    }

    return {
      ...toRefs(data),
      addTemplateOnClick,
      sendEmail,
      cloneTemplate,
      close,
      closeAddTemplateModal,
      closeAndShowTemplate,
      closeUpdateModal,
      closeViewTemplateModal,
      update,
      updateTemplateOnClick,
      useTemplate,
      viewTemplateOnClick,
      isValid,
    };
  },
});
