import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar-content-in-mobile" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col plan-gap-xs plan-p-lg",
  style: {"border-top":"1px solid #1f2937","border-bottom":"1px solid #1f2937"}
}
const _hoisted_3 = {
  key: 1,
  class: "w-full flex flex-col gap-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_button = _resolveComponent("sidebar-button")!
  const _component_sidebar_user = _resolveComponent("sidebar-user")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isSuperAdmin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_sidebar_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo('planning'))),
            label: _ctx.$t('planning'),
            "when-active": "planning",
            icon: "calendar"
          }, null, 8, ["label"]),
          (_ctx.isTrialPremUser)
            ? (_openBlock(), _createBlock(_component_sidebar_button, {
                key: 0,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goTo('lanesRfp'))),
                label: _ctx.$t('rfp-lanes'),
                "when-active": "lanesRfp",
                icon: "rfp-lanes"
              }, null, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_sidebar_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goTo('warehouses'))),
            label: _ctx.$t('warehouses'),
            icon: "warehouse",
            class: "plan-mt-lg",
            "when-active": "warehouses"
          }, null, 8, ["label"]),
          _createVNode(_component_sidebar_button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goTo('messages'))),
            label: _ctx.$t('messages'),
            "when-active": "messages",
            icon: "message-new",
            "with-notifications": _ctx.totalUnreadChats,
            class: "plan-mt-lg"
          }, null, 8, ["label", "with-notifications"]),
          _createVNode(_component_sidebar_button, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goTo('contacts'))),
            label: _ctx.$t('contacts'),
            "when-active": "contacts",
            icon: "user-new"
          }, null, 8, ["label"]),
          _createVNode(_component_sidebar_button, {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goTo('performance'))),
            label: _ctx.$t('performance'),
            icon: "circle-stats",
            class: "plan-mt-lg"
          }, null, 8, ["label"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isSuperAdmin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_sidebar_button, {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goTo('shippersList'))),
            label: _ctx.$t('users'),
            "when-active": "shippersList",
            icon: "user-new"
          }, null, 8, ["label"]),
          _createVNode(_component_sidebar_button, {
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.goTo('superAdminDashboard'))),
            label: _ctx.$t('performance'),
            "when-active": "superAdminDashboard",
            icon: "circle-stats"
          }, null, 8, ["label"]),
          _createVNode(_component_sidebar_button, {
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.goTo('loadsList'))),
            label: _ctx.$t('loads'),
            "when-active": "loadsList",
            icon: "calendar"
          }, null, 8, ["label"]),
          _createVNode(_component_sidebar_button, {
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.goTo('requestDemoList'))),
            label: _ctx.$t('demo-request'),
            "when-active": "requestDemoList",
            icon: "message-new"
          }, null, 8, ["label"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_sidebar_user, { onCloseSidebarContentMobile: _ctx.goTo }, null, 8, ["onCloseSidebarContentMobile"])
  ]))
}