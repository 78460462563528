export default {
  getActualPosition (state: any) {
    return state.actualPosition
  },
  getActualWidth (state: any) {
    return state.actualWidth
  },
  getLoadData (state: any) {
    return state.loadData
  },
  getMyDataForm (state: any) {
    return state.myDataForm
  },
  getCarrierPackageForm (state: any) {
    return state.carrierPackageForm
  },
  getDrivers (state: any) {
    return state.drivers
  },
  getIsDriver (state: any) {
    return state.isDriver
  },
  openChat (state: any) {
    return state.openChat
  },
  getVehicles (state: any) {
    return state.vehicles
  },
  getUserBid (state: any) {
    return state.userBid
  },
  getCarrierChat(state: any){
    return state.carrierChat;
  },
  getRegionPrefixes (state: any) {
    return state.regionPrefixes
  },
  getFilesToUpload (state: any) {
    return state.filesToUpload
  },
  getTrackingHistory (state: any) {
    return state.trackingHistory
  },
  getShowNotifications (state: any) {
    return state.showNotifications
  },
  getShowShareLoad (state: any) {
    return state.showShareLoad
  }
}