
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import Datepicker from '@vuepic/vue-datepicker';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateSpin.vue';

export default defineComponent({
  name: 'LoadsLanes',
  components: {
    CheckCircleIcon,
    Datepicker,
    Field,
    Form,
    Spin,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  props: ['isCompleted', 'loadsCreation'],
  data() {
    return {
      loads: [] as any,
      dateFormat: 'MM-dd-yyyy',
      nLanes: 1,
      orderedStopss: ['Charleston, SC', '33125 Miami', '33125 Miami', 'Orlando, FL'],
      showSpinner: false,
    };
  },
  created() {
    if (!this.isCompleted) {
      const id = this.$route.params.laneId;
      this.$store.dispatch('LanesStore/getLaneData', id).then(() => {
        // this.loads.push(this.stops());
        for (let i = 0; i < this.currentLane.loadsPerWeek; i++) {
          this.addMore();
        }
      });
    } else {
      this.loads = this.loadsCreation;
    }
  },
  computed: {
    currentLane(): any {
      return this.$store.getters['LanesStore/getLaneData'];
    },
    finalDeliveryStop(): any {
      let finalStop = null;
      if (this.currentLane?.loadTemplate?.stops) {
        this.currentLane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'DELIVERY_FINAL') {
            finalStop = stop;
          }
        });
      }
      return finalStop;
    },
    initialPickUpStop(): any {
      let initialStop = null;
      if (this.currentLane?.loadTemplate?.stops) {
        this.currentLane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'PICKUP_INITIAL') {
            initialStop = stop;
          }
        });
      }
      return initialStop;
    },
    minDate(): any {
      const currentDate = new Date();
      const initialPickUpDate = new Date(this.initialPickUpStop.executionDate);
      return initialPickUpDate < currentDate
        ? currentDate.toISOString().split('T')[0]
        : this.initialPickUpStop.executionDate;
    },
    orderedStops(): any {
      const stops = this.currentLane?.loadTemplate?.stops;
      if (stops) {
        return stops.sort((a, b) => a.sequence - b.sequence);
      } else {
        return [];
      }
    },
  },
  methods: {
    areAllExecutionDatesNotEmpty(stops) {
      return stops.every(this.isExecutionDateNotEmpty);
    },
    addMore() {
      this.loads.push(this.stops());
    },
    isExecutionDateNotEmpty(stop) {
      return stop.executionDate !== '';
    },
    nextRoute() {
      this.$router.push('quote');
    },
    removeLoad(index) {
      this.loads.splice(index, 1);
    },
    showTemplates() {
      this.$store.dispatch('LoadsStore/showTemplateModal', true);
    },
    stops() {
      if (this.currentLane && this.currentLane.loadTemplate) {
        const stopsArray = this.currentLane.loadTemplate.stops.map((stop) => {
          return {
            loadTemplateStopId: stop.id,
            executionDate: '',
          };
        });

        return { stops: stopsArray };
      } else {
        return { stops: [] };
      }
    },
    async submit() {
      const validation = await (this.$refs.formLanes as any).validate();
      if (!validation.valid) {
        BaseComponent.methods?.showToastError(this.$t('fill-all-dates'));
        return;
      }

      this.$store
        .dispatch('LanesStore/planningLoadsBatch', {
          id: this.$route.params.laneId,
          loads: { loads: this.loads },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('loads-created'));
          this.$emit('creationCompleted');
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    },
  },
});
