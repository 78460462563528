
import { defineComponent } from 'vue';
import Spin from '@/components/spin/AnimateSpin.vue';

export default defineComponent({
  name: 'SekeletonChatTable',
  components: {
    Spin,
  },
  props: {
    rows: Number,
  },
});
