import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "w-full flex flex-col justify-center items-center" }
const _hoisted_3 = { class: "plan-text-xl plan-t-prim-500 font-bold" }
const _hoisted_4 = { class: "plan-text-xs plan-t-prim-100" }
const _hoisted_5 = { class: "plan-mt-lg flex justify-end" }
const _hoisted_6 = { class: "flex justify-between items-center plan-gap-xs plan-text-xs" }
const _hoisted_7 = { class: "flex justify-center items-end plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!
  const _component_plan_search_location_by_coordinates = _resolveComponent("plan-search-location-by-coordinates")!
  const _component_plan_search_location_by_warehouse_modal = _resolveComponent("plan-search-location-by-warehouse-modal")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_input_location = _resolveComponent("plan-input-location")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showErrorCoordinatesModal)
      ? (_openBlock(), _createBlock(_component_plan_default_modal, {
          key: 0,
          onlyContent: "",
          size: "sm-auto"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('error-location-by-coordinates-title')), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('error-location-by-coordinates-subtitle')), 1),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_plan_button, {
                  onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showErrorCoordinatesModal = false)),
                  type: "primary",
                  label: _ctx.$t('accept')
                }, null, 8, ["label"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showSearchByCoordinatesModal)
      ? (_openBlock(), _createBlock(_component_plan_search_location_by_coordinates, {
          key: 1,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSearchByCoordinatesModal = false)),
          onGetLocation: _ctx.getLocationByCoordinates
        }, null, 8, ["onGetLocation"]))
      : _createCommentVNode("", true),
    (_ctx.showSearchByWarehouseModal)
      ? (_openBlock(), _createBlock(_component_plan_search_location_by_warehouse_modal, {
          key: 2,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSearchByWarehouseModal = false)),
          onGetLocation: _ctx.getLocationByWarehouse,
          onOpenAddFacility: _ctx.openAddFacility
        }, null, 8, ["onGetLocation", "onOpenAddFacility"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.oldMode ? 'mb-1 font-semibold' : 'plan-form-label')
      }, _toDisplayString(_ctx.label ?? _ctx.$t('city-zip')) + " *", 3),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_plan_text_button, {
          onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showSearchByWarehouseModal = true)),
          label: _ctx.$t('warehouse')
        }, null, 8, ["label"]),
        _createVNode(_component_plan_text_button, {
          onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showSearchByCoordinatesModal = true)),
          label: _ctx.$t('coordinates')
        }, null, 8, ["label"])
      ])
    ]),
    _createVNode(_component_plan_input_location, {
      onChange: _ctx.changeInput,
      blockCountry: _ctx.blockCountry,
      "pre-country": _ctx.preCountry,
      "pre-location": _ctx.preLocation,
      plus_code: _ctx.plus_code,
      "old-mode": "",
      "hide-label": ""
    }, null, 8, ["onChange", "blockCountry", "pre-country", "pre-location", "plus_code"])
  ]))
}