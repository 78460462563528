import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61dbf638"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "plan-toast-content" }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-sm plan-pt-sm plan-pb-sm pr-2" }
const _hoisted_3 = { class: "text-lg" }
const _hoisted_4 = { class: "plan-toast-close h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createBlock(_Transition, {
    onBeforeEnter: _ctx.beforeEnter,
    onBeforeLeave: _ctx.beforeLeave,
    onLeave: _ctx.afterLeave
  }, {
    default: _withCtx(() => [
      (_ctx.showToast)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["plan-toast", [_ctx.animateClass]]),
            style: _normalizeStyle(_ctx.toastStyles)
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.type === 'success')
                  ? (_openBlock(), _createBlock(_component_plan_icon, {
                      key: 0,
                      name: "fill-circle-check",
                      "icon-color": "white",
                      "is-svg": ""
                    }))
                  : _createCommentVNode("", true),
                (_ctx.type === 'error')
                  ? (_openBlock(), _createBlock(_component_plan_icon, {
                      key: 1,
                      name: "triangle-alert",
                      "icon-color": "white",
                      "is-svg": ""
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textList, (value) => {
                  return (_openBlock(), _createElementBlock("p", {
                    key: value,
                    class: "text-lg"
                  }, _toDisplayString(value), 1))
                }), 128)),
                (_ctx.type === 'info')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 2,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.routingTo(_ctx.id))),
                      class: "bg-blue-500 text-white font-semibold py-2 px-6 rounded-full border border-blue-500 hover:bg-blue-600"
                    }, _toDisplayString(_ctx.$t('view')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_plan_icon, {
                  onClick: _ctx.hideToast,
                  name: "close",
                  "icon-color": "white",
                  "is-svg": "",
                  class: "cursor-pointer"
                }, null, 8, ["onClick"])
              ])
            ])
          ], 6))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onBeforeEnter", "onBeforeLeave", "onLeave"]))
}