export default {
  getAsyncResultLanes(state: any) {
    return state.asyncResultLanes;
  },
  getLanesInformationPerWeek(state: any) {
    return state.lanesArrayPerWeek;
  },
  getClaimProjectInfo(state: any) {
    return state.claimProjectInfo;
  },
  getCurrentQuotesPlanned(state: any) {
    return state.currentQuotesPlanned;
  },
  getCurrentCarrierRFPQuotes(state: any) {
    return state.currentCarrierRFPQuotes;
  },
  getCarrierQuotetionList(state: any) {
    return state.carrierQuotetionList;
  },
  getCalendarIntervals(state: any) {
    return state.calendarIntervals;
  },
  getIndexLoadToRemove(state: any) {
    return state?.indexLoadToRemove?.length > 0
      ? state.indexLoadToRemove.sort((a, b) => b - a)
      : [];
  },
  getLoadsInfo(state: any) {
    return state.loadsInfo;
  },
  getCurrentEditingLanes(state: any) {
    return state.currentEditingLanes;
  },
  getCurrentLane(state: any) {
    return state.currentLane;
  },
  getCurrentLoadsToAdd(state: any) {
    return state.currentLoadsToAdd;
  },
  getCurrentLoadsToAddAssigment(state: any) {
    return state.currentLoadsToAddAssigment;
  },
  getLanesAndQuotesForCarrier(state: any) {
    return state.lanesAndQuotesForCarrier;
  },
  getCurrentProject(state: any) {
    return state.currentProject;
  },
  getLanes(state: any) {
    return state.lanes;
  },
  getLanesClaimsInfo(state: any) {
    return state.lanesClaimsInfo;
  },
  getNewProjectModalOptions(state: any) {
    return state.newProjectModalOptions;
  },
  getPreviousStatusLanes(state: any) {
    return state.previousStatusLanes;
  },
  getLanesListFilter(state: any) {
    return state.lanesListFilter;
  },
  getPreviousStatusProject(state: any) {
    return state.previousStatusProject;
  },
  getProjectsRFPList(state: any) {
    return state.projectsRFPList;
  },
  getProjectsTotalsStatus(state: any) {
    return state.projectsTotalsStatus;
  },
  getProjectToduplicate(state: any) {
    return state.projectToduplicate;
  },
  getQuoteForCarrier(state: any) {
    return state.quoteForCarrier;
  },
  getRankingList(state: any) {
    return state.rankingList;
  },
  getShowWaitingLanes(state: any) {
    return state.showWaitingLanes;
  },
  getUnCompletedTootipInfo(state: any) {
    return state.unCompletedTootipInfo;
  },
};
