
import { computed, defineComponent, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import EditCurrentContactModal from '@/modules/planning/contacts/ContactsImport/EditCurrentContactModal.vue';
import { useStore } from 'vuex';
import PlanDefaultDeleteModal from '@/components/defaults/PlanDefaultDeleteModal.vue';
import { useRouter } from 'vue-router';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanContactTags from '@/components/labels/PlanContactTags.vue';

interface Carrier {
  type: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  prefix: string;
  phone: number;
  mcNumber: string;
  identificationNumber: string;
  dotNumber: string;
  validation: {
    passed: boolean;
    message: string;
    failedFields: string;
  }
}

interface contactsTable {
  activeTab: string,
  contactToDelete: number,
  currentContacts: Array<any>,
  loadingImportSuccessContacts: boolean,
  selectedContact: any,
  selectedSuccessContacts: Array<any>,
  selectedUpdateContacts: Array<any>,
  showEditModal: boolean,
  showDuplicatedContacts: boolean,
  showSuccessContacts: boolean,
  showUpdateContacts: boolean,
  showWarningContacts: boolean
}

export default defineComponent({
  name: 'ContactsTable',
  components: { PlanContactTags, PlanIcon, PlanDefaultTable, PlanDefaultDeleteModal, EditCurrentContactModal, PlanButtonIcon },
  emits: ['updateQuantities'],
  setup (props, { emit }) {
    const router = useRouter()
    const store = useStore()
    const translate = useI18n()

    const data = reactive<contactsTable>({
      activeTab: 'warning',
      contactToDelete: -1,
      currentContacts: [],
      loadingImportSuccessContacts: false,
      selectedContact: null,
      selectedSuccessContacts: [],
      selectedUpdateContacts: [],
      showEditModal: false,
      showDuplicatedContacts: false,
      showSuccessContacts: false,
      showUpdateContacts: false,
      showWarningContacts: true
    })

    data.currentContacts = store.getters['UserStore/getCurrentContacts']

    const changeTab = (index: number) => {
      if (index === 0) data.activeTab = 'warning'
      if (index === 1) data.activeTab = 'success'
    }

    const changeSelectedSuccessContacts = (newSelectedContacts: any) => {
      data.selectedSuccessContacts =  newSelectedContacts
      updateQuantities()
    }

    const changeSelectedUpdateContacts = (newSelectedContacts: any) => {
      data.selectedUpdateContacts =  newSelectedContacts
      updateQuantities()
    }

    const contactsToShow = computed(() => {
      const duplicatedContacts: Array<Carrier> = []
      const successContacts: Array<Carrier> = []
      const updateContacts: Array<Carrier> = []
      const warningContacts: Array<Carrier> = []

      if (data.currentContacts) {
        data.currentContacts.forEach((contact: any, index: number) => {
          if (contact.validation.passed) {
            successContacts.push(Object.assign({ frontIndex: index}, contact))
          } else {
            if (contact.validation.errorCodes.includes(8)) {
              updateContacts.push(Object.assign({ frontIndex: index}, contact))
            } else if (contact.validation.errorCodes.includes(13)) {
              duplicatedContacts.push(Object.assign({ frontIndex: index}, contact))
            } else {
              warningContacts.push(Object.assign({ frontIndex: index}, contact))
            }
          }
        })
      }

      emit('updateQuantities', {
        duplicated: duplicatedContacts.length,
        success: successContacts.length,
        update: updateContacts.length,
        warning: warningContacts.length,
        total: data.currentContacts.length
      })

      return {
        duplicated: duplicatedContacts,
        success: successContacts,
        update: updateContacts,
        warning: warningContacts
      }
    })

    const handleUpdateContact = (updatedContact: any) => {
      const indexToSearch = updatedContact.frontIndex
      delete updatedContact.frontIndex
      updatedContact.validation.passed = true
      data.currentContacts[indexToSearch] = updatedContact
      data.selectedContact = null
      data.showEditModal = false
      store.commit('UserStore/setCurrentContacts', data.currentContacts)

      store.dispatch('UserStore/updateToast', {
        text: translate.t('edited-successfully'),
        type: 'success',
        visible: true
      })
    };

    const headers = computed(() => {
      return [
        { label: translate.t('type'), value: 'type', align: 'left' },
        { label: translate.t('placeholder-company'), value: 'placeholder-company', align: 'left'},
        { label: translate.t('cif-carrier'), value: 'cif-carrier', align: 'left'},
        { label: translate.t('contacts-import.firstName'), value: 'contacts-import.firstName', align: 'left'},
        { label: translate.t('lastname'), value: 'lastname', align: 'left'},
        { label: translate.t('email'), value: 'email', align: 'left'},
        { label: translate.t('prefix'), value: 'prefix', align: 'left'},
        { label: translate.t('phone'), value: 'phone', align: 'left'},
        { label: translate.t('mc-number-minimized'), value: 'mc-number', align: 'left'},
        { label: translate.t('dot-number-minimized'), value: 'dot-number', align: 'left'},
        { label: translate.t('actions'), value: 'actions', align: 'left', sticky: true }
      ]
    })

    const removeContact = () => {
      data.currentContacts.splice(data.contactToDelete, 1)
      store.commit('UserStore/setCurrentContacts', data.currentContacts)
      data.contactToDelete = -1
    }

    const tabs = computed(() => {
      return [
        { label: `${translate.t('import-contacts-warning-title-minimized')}`, quantity: contactsToShow.value.warning.length },
        { label: `${translate.t('import-contacts-success-title-minimized')}`, quantity: contactsToShow.value.success.length }
      ]
    })

    const updateContact = (selectedContact: any) => {
      data.selectedContact = Object.assign({}, selectedContact)
      data.showEditModal = true
    }

    const updateQuantities = () => {
      emit('updateQuantities', {
        success: data.selectedSuccessContacts.length,
        update: data.selectedUpdateContacts.length,
        warning: contactsToShow.value.warning.length,
        total: data.currentContacts.length
      })
    }

    return {
      ...toRefs(data),
      changeTab,
      changeSelectedSuccessContacts,
      changeSelectedUpdateContacts,
      contactsToShow,
      handleUpdateContact,
      headers,
      removeContact,
      tabs,
      updateContact,
      updateQuantities
    }
  }
})
