export default {
  AUD: 'A$',
  AWG: 'ƒ',
  BDT: '৳',
  CAD: 'CAD$',
  CNY: '¥',
  CRC: '₡',
  DKK: 'kr',
  EGP: '£',
  EUR: '€',
  FKP: '£',
  GBP: '£',
  GIP: '£',
  ILS: '₪',
  INR: '₹',
  ISK: 'kr',
  JPY: '¥',
  KHR: '៛',
  KRW: '₩',
  KZT: '₸',
  LBP: '£',
  NGN: '₦',
  NOK: 'kr',
  PHP: '₱',
  PYG: '₲',
  RUB: '₽',
  SDG: '£',
  SEK: 'kr',
  SHP: '£',
  SYP: '£',
  TRY: '₺',
  UAH: '₴',
  USD: '$',
  '': '¤' // The currency sign ¤ is a character used to denote an unspecified currency.
};
