import { initialState } from '@/modules/payments/_store/index';

export default {
  clearPaymentStore(state: any) {
    Object.assign(state, initialState);
  },
  setPaymentMethod(state: any, payload: object) {
      state.paymentMethod = payload;
  },
  setPaymentInfo(state: any, payload: object) {
      state.paymentInfo = payload;
  },
  setSubscriptionType(state: any, payload: object) {
    state.subscriptionType = payload;
},
}
