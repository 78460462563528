
import { defineComponent, reactive, toRefs, computed, onMounted } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierLoadHeader from '@/components/carrier/PlanCarrierLoadHeader.vue';
import PlanCarrierLoadSubHeader from '@/components/carrier/PlanCarrierLoadSubHeader.vue';
import PlanInputTextArea from '@/components/forms/PlanTextArea.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import PlanInputLocation from '@/components/forms/PlanInputLocation.vue';
import PlanCarrierTrackingMap from '@/components/carrier/_assigned/PlanCarrierTrackingMap.vue';
import { locationString } from '@/utils/formatLocation';
import CarrierLoadAssigned from '@/modules/carrierViews/_views/CarrierLoadAssigned.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
interface carrierBase {
  load: any;
  loadingLoad: boolean;
}

export default defineComponent({
  name: 'CarrierNotifyPosition',
  components: {
    PlanCarrierTrackingMap,
    PlanCarrierLoadSubHeader,
    PlanCarrierLoadHeader,
    PlanDefaultCarrierView,
    PlanInputTextArea,
    PlanButton,
    PlanInputLocation,
  },
  props: {
    id: { type: String, required: false, default: null },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const data = reactive({
      activeTab: 'tracking',
      initialLocation: {
        lat: 0,
        lon: 0,
        zip: '',
        country: '',
      },
      placeId: '',
      observations: '',
      currentLocation: '',
      loadingPostNotifyPosition: false,
      loadingPosition: false,
    });

    onMounted(() => {
      getLocation();
    });

    const changeTab = (newTab: string) => {
      data.activeTab = newTab;
    };

    const getLatitudeLongitude = (position: GeolocationPosition) => {
      data.initialLocation.lat = position.coords.latitude;
      data.initialLocation.lon = position.coords.longitude;

      store.commit('CarrierStore/setActualPosition', {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      })

      getLocationsByGoogle();
    };

    const getLocationsByGoogle = async () => {
      await store
        .dispatch('LoadsStore/getCoordinatesGoogle', {
          lat: data.initialLocation.lat,
          lng: data.initialLocation.lon,
        })
        .then(async (response) => {
          if (response.data.length > 0) {
            data.placeId = response.data[0]['place_id'];
            response.data[0]['address_components'].forEach((addressComponent: any) => {
              if (addressComponent.types.includes('locality')) {
                data.currentLocation = addressComponent['long_name'];
              }
              if (addressComponent.types.includes('postal_code')) {
                data.initialLocation.zip = addressComponent['short_name'];
              }
              if (addressComponent.types.includes('country')) {
                data.initialLocation.country = addressComponent['short_name'];
              }
            });
          }
        });
      data.loadingPosition = false
    };

    const goToAcceptation = () => {
      router.push({ name: 'CarrierLoadAssigned', params: {
        id: store.getters['CarrierStore/getLoadData'].id
      }})
    }

    const showError = (error: GeolocationPositionError) => {
      let errorMessage = '';
      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = 'error.PERMISSION_DENIED';
          break;
        case error.POSITION_UNAVAILABLE:
          errorMessage = 'error.POSITION_UNAVAILABLE';
          break;
        case error.TIMEOUT:
          errorMessage = 'error.TIMEOUT';
          break;
      }
    };

    const postNotifyPosition = async () => {
      data.loadingPostNotifyPosition = true;
      const params = {
        shipment: { id: store.getters['CarrierStore/getLoadData'].shipment?.id },
        location: {
          googlePlaceId: data.placeId,
          language: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es',
        },
        observations: data.observations,
      };

      await store.dispatch('LoadsStore/postNotifyPosition', params);
      await store
        .dispatch(
          'LoadsStore/getTrackingHistory',
          store.getters['CarrierStore/getLoadData'].shipment?.id,
        )
        .then((response) => {
          store.commit('CarrierStore/setTrackingHistory', response);
        });
      await store.dispatch('UserStore/showToast', translate.t('success-update-position'));
      await store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id);
      data.observations = '';
      data.loadingPostNotifyPosition = false;
    };

    

    const getLocation = () => {
      if (navigator.geolocation && isMobile.value) {
        data.loadingPosition = true
        navigator.geolocation.getCurrentPosition(getLatitudeLongitude, showError);
      }
    };

    const onClickGetLocation = () => {
      if (navigator.geolocation) {
        data.loadingPosition = true
        navigator.geolocation.getCurrentPosition(getLatitudeLongitude, showError);
      }
    };

    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    const isMobile = computed(() => {
      return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    })

    const stopsData = computed(() => {
      let result: Array<any> = [];

      loadData.value.stops.forEach((stop: any, index: number) => {
        let toCity = stop.location.city;
        if (index < loadData.value.stops.length - 1) {
          toCity = loadData.value.stops[index + 1].location.city;
        }

        result.push({
          stop: stop,
          toCity: toCity,
        });
      });

      return result;
    });

    const tabs = computed(() => {
      return [
        { label: translate.t('load-info') },
        { label: translate.t('tracking') },
        { label: translate.t('documents') },
      ];
    });

    const getLoad = async () => {
      await store.dispatch('LoadsStore/getFeeExplanations');
      await store.dispatch(
        'CarrierStore/getLoadInfoForCarrier',
        store.getters['CarrierStore/getLoadData'].id,
      );
    };

    const getPlaceId = (newPlaceId: string) => {
      store
        .dispatch('LoadsStore/getPlaceDetailsGoogle', {
          placeId: newPlaceId,
        })
        .then((response) => {
          data.placeId = response.data['place_id'];
          data.initialLocation.lat = response.data.geometry.location.lat;
          data.initialLocation.lon = response.data.geometry.location.lng;
        });
    };

    const changeInputLocation = (newData: any) => {
      setTextValue(newData);
      getPlaceId(newData.placeId);
    };

    const setTextValue = (location: any) => {
      data.initialLocation.zip = locationString(location);
    };

    const getUserCountry = async () => {
      await store.dispatch('CountryStore/getCountryById', currentUser.value?.company?.country?.id);
    };

    getLoad();
    getUserCountry();

    return {
      ...toRefs(data),
      actualWidth,
      changeTab,
      changeInputLocation,
      postNotifyPosition,
      goToAcceptation,
      isDriver,
      isMobile,
      loadData,
      onClickGetLocation,
      stopsData,
      tabs,
    };
  },
});
