import globalAxios from 'axios';

const getLaneQuotedRanking = async (data: any) => {
  const response = await globalAxios.get(`/lanesRFQ/${data.id}/quotedOrRanking`, {
    params: data.params,
  });
  return response.data;
};

const planningLoadsBatch = async (data: any) => {
  const response = await globalAxios.post(`/lanesRFQ/${data.id}/planningLoadsBatch`, data.loads);
  return response.data;
};

const selectAssignedQuotesBatch = async (data: any) => {
  const response = await globalAxios.post(
    `/lanesRFQ/${data.id}/selectAssignedQuotesBatch`,
    data.LanesRFQBids,
  );
  return response.data;
};

const acceptQuote = async (id: any) => {
  const response = await globalAxios.post(`/lanesRFQBids/${id}/acceptQuote`, {});
  return response.data;
};

const deleteLane = async (id: any) => {
  const response = await globalAxios.delete(`/lanesRFQ/${id}`, {});
  return response.data;
};

const moveRankingPosition = async (data: any) => {
  const response = await globalAxios.post(
    `/lanesRFQBids/${data.id}/moveRankingPosition`,
    data.params,
  );
  return response.data;
};

const removeFromRanking = async (id: any) => {
  const response = await globalAxios.delete(`/lanesRFQBids/${id}/removeFromRanking`);
  return response.data;
};

const getLaneData = async (id: any) => {
  const response = await globalAxios.get(`/lanesRFQ/${id}`, {});
  return response.data;
};
const getCurrentTendersByGroup = async (data: any) => {
  const response = await globalAxios.get(`/lanesRFQRequestBidsGroup/${data}/tenders`, {
    params: {
      page: 1,
      maxResults: 100,
    },
  });
  return Promise.resolve(response.data);
};

const sendRequestEmail = async (data: any) => {
  const response = await globalAxios.post(
    `/lanesRFQRequestBidsGroup/${data.group}/sendRequestEmail/batch`,
    { companies: [{ id: data.id }] },
  );
  return response.data;
};

const getLanesGroupsList = async (data: any) => {
  const response = await globalAxios.get('/lanesRFQGroup');
  return response.data;
};

const postGroupLane = async (params: any) => {
  const response = await globalAxios.post('/lanesRFQGroup/create', params);
  return response.data;
};

const duplicateGroupLane = async (params: any) => {
  const response = await globalAxios.post('/lanesRFQGroup/duplicate', params);
  return response.data;
};

const updateLanes = async (params: any) => {
  const response = await globalAxios.put(`/lanesRFQGroup/${params.id}/updateLanes`, {
    lanesRFQ: params.data,
  });
  return response.data;
};

const moveGroupLane = async (params: any) => {
  const response = await globalAxios.post(`/lanesRFQGroup/${params.id}/move`, params.data);
  return response.data;
};

const renameGroupLane = async (params: any) => {
  const response = await globalAxios.put(`/lanesRFQGroup/${params.id}/rename`, params.data);
  return response.data;
};

const deleteGroupLane = async (data: any) => {
  const response = await globalAxios.delete(`/lanesRFQGroup/${data.id}/delete`);
  return response.data;
};

const getLanesList = async (data: any) => {
  const response = await globalAxios.get(`/lanesRFQGroup/${data.id}/getLanes`, {
    params: data.params,
  });
  return response.data;
};

const getLanesTotals = async (data: any) => {
  const response = await globalAxios.get(`/lanesRFQGroup/${data.id}/getLanesTotals`, {
    params: data.params,
  });
  return response.data;
};

const rankingConfiguration = async (data: any) => {
  const response = await globalAxios.put(
    `/lanesRFQRequestBidsGroup/${data.id}/rankingConfiguration`,
    data.body.lanesRFQ,
  );
  return response.data;
};

const createBatchLanes = async (data: any) => {
  const response = await globalAxios.post(`/lanesRFQGroup/${data.id}/createBatchLanes`, data.body);
  return response.data;
};

const lanesRFQRequestBids = async (params: any) => {
  const response = await globalAxios.post(`/lanesRFQRequestBids/batch`, params);
  return response.data;
};

const lanesRFQRequestBidsGroup = async (id: any) => {
  const response = await globalAxios.get(`/lanesRFQRequestBidsGroup/${id}`);
  return response.data;
};

const lanesRFQRequestBidsGroupRequestMore = async (data: any) => {
  const response = await globalAxios.post(
    `/lanesRFQRequestBidsGroup/${data.id}/requestMore/batch`,
    data.body,
  );
  return response.data;
};

const lanesRFQRequestBidsGroupCompany = async (id: any) => {
  const response = await globalAxios.get(`/lanesRFQRequestBidsGroup/${id}/company`);
  return response.data;
};

const lanesRFQRequestBidsGroupOnlyBids = async (id: any) => {
  const response = await globalAxios.get(`/lanesRFQRequestBidsGroup/${id}/companyOnlyBids`);
  return response.data;
};

const lanesRFQRequestBidsGroupConfirm = async (data: any) => {
  const response = await globalAxios.post(
    `/lanesRFQRequestBidsGroup/${data.id}/confirm`,
    data.body,
  );
  return response.data;
};

const totalTenders = async (id: any) => {
  const response = await globalAxios.get(`/lanesRFQRequestBidsGroup/${id}/totalTenders`);
  return response.data;
};

const carrierQuoteLanes = async (params: any) => {
  const response = await globalAxios.post(
    `/lanesRFQRequestBidsGroup/${params.id}/quote`,
    params.body,
  );
  return response.data;
};

const updateBiddingEndTime = async (params: any) => {
  const response = await globalAxios.put(
    `/lanesRFQRequestBidsGroup/${params.id}/biddingEndTime`,
    params.body,
  );
  return response.data;
};
const finishQuotes = async (params: any) => {
  const response = await globalAxios.put(`/lanesRFQRequestBidsGroup/${params.id}/finishQuotes`, {});
  return response.data;
};

export default {
  getLaneQuotedRanking,
  acceptQuote,
  moveRankingPosition,
  removeFromRanking,
  getLanesGroupsList,
  postGroupLane,
  duplicateGroupLane,
  moveGroupLane,
  renameGroupLane,
  deleteGroupLane,
  getLanesList,
  getLanesTotals,
  createBatchLanes,
  lanesRFQRequestBids,
  lanesRFQRequestBidsGroup,
  lanesRFQRequestBidsGroupRequestMore,
  lanesRFQRequestBidsGroupCompany,
  lanesRFQRequestBidsGroupOnlyBids,
  lanesRFQRequestBidsGroupConfirm,
  totalTenders,
  carrierQuoteLanes,
  updateBiddingEndTime,
  finishQuotes,
  getLaneData,
  planningLoadsBatch,
  selectAssignedQuotesBatch,
  rankingConfiguration,
  updateLanes,
  deleteLane,
  getCurrentTendersByGroup,
  sendRequestEmail,
};
