import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-40" }
const _hoisted_2 = { class: "xl:container p-5 xl:p-10" }
const _hoisted_3 = { class: "bg-white rounded-xl px-5 py-10 xl:p-10 w-full" }
const _hoisted_4 = { class: "flex items-center space-x-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_LoadInfoStepOneVue = _resolveComponent("LoadInfoStepOneVue")!
  const _component_LoadFreightStepTwo = _resolveComponent("LoadFreightStepTwo")!
  const _component_LoadQuoteStepThree = _resolveComponent("LoadQuoteStepThree")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", {
            class: _normalizeClass(["text-2xl md:text-3xl font-bold", {
              'opacity-25': _ctx.currentView !== 'step-one',
              'cursor-pointer hover:opacity-100': _ctx.stepOneAllowed,
              'flex items-center': _ctx.isValidStepOne,
            }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToStepOne && _ctx.goToStepOne(...args)))
          }, [
            _createTextVNode(_toDisplayString(`1. ${_ctx.$t('shipment-data')}`) + " ", 1),
            (_ctx.isValidStepOne)
              ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                  key: 0,
                  class: "h-8 w-8"
                }))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("h3", {
            class: _normalizeClass(["text-2xl md:text-3xl font-bold", {
              'opacity-25': _ctx.currentView !== 'step-two',
              'cursor-pointer hover:opacity-100': _ctx.currentView !== 'step-two' && _ctx.isValidStepOne,
              'flex items-center': _ctx.isValidStepTwo,
            }]),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToStepTwo && _ctx.goToStepTwo(...args)))
          }, [
            _createTextVNode(_toDisplayString(`2. ${_ctx.$t('freight-info')}`) + " ", 1),
            (_ctx.isValidStepTwo && _ctx.load.freight.length > 0)
              ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                  key: 0,
                  class: "h-8 w-8"
                }))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("h3", {
            class: _normalizeClass(["text-2xl md:text-3xl font-bold", {
              'opacity-25': _ctx.currentView !== 'step-three',
              'cursor-pointer hover:opacity-100': _ctx.stepThreeAllowed,
              'flex items-center': _ctx.isValidStepThree,
            }]),
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToStepThree && _ctx.goToStepThree(...args)))
          }, [
            _createTextVNode(_toDisplayString(`3. ${_ctx.$t('quote')}`) + " ", 1),
            (_ctx.isValidStepThree)
              ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                  key: 0,
                  class: "h-8 w-8"
                }))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _withDirectives(_createVNode(_component_LoadInfoStepOneVue, {
          ref: "stepOne",
          load: _ctx.load,
          onChangeStep: _ctx.changeStep,
          onSetValidStep: _ctx.setValidStep,
          onUpdate: _ctx.updateLoad,
          onUpdateExtraFields: _ctx.updateExtraFields
        }, null, 8, ["load", "onChangeStep", "onSetValidStep", "onUpdate", "onUpdateExtraFields"]), [
          [_vShow, _ctx.currentView === 'step-one']
        ]),
        _withDirectives(_createVNode(_component_LoadFreightStepTwo, {
          ref: "stepTwo",
          creatingLoad: _ctx.creatingLoad,
          load: _ctx.load,
          onChangeStep: _ctx.changeStep,
          onSetValidStep: _ctx.setValidStep,
          onUpdate: _ctx.updateLoad
        }, null, 8, ["creatingLoad", "load", "onChangeStep", "onSetValidStep", "onUpdate"]), [
          [_vShow, _ctx.currentView === 'step-two']
        ]),
        _withDirectives(_createVNode(_component_LoadQuoteStepThree, {
          ref: "stepThree",
          alpineLoadId: _ctx.alpineLoadId,
          currentView: _ctx.currentView,
          load: _ctx.load,
          loadCreatedId: _ctx.loadCreatedId,
          onSetLoadId: _ctx.setLoadId
        }, null, 8, ["alpineLoadId", "currentView", "load", "loadCreatedId", "onSetLoadId"]), [
          [_vShow, _ctx.currentView === 'step-three']
        ])
      ])
    ])
  ]))
}