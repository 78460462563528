import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-lg" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col plan-gap-sm"
}
const _hoisted_3 = { class: "font-semibold plan-t-h5" }
const _hoisted_4 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_5 = { class: "font-semibold plan-t-h5" }
const _hoisted_6 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_7 = { class: "font-semibold plan-t-h5" }
const _hoisted_8 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_9 = { class: "font-semibold plan-t-h5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_plan_input_calendar = _resolveComponent("plan-input-calendar")!
  const _component_plan_input_file = _resolveComponent("plan-input-file")!
  const _component_plan_thumbnail = _resolveComponent("plan-thumbnail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.myDataForm.type === 'CARRIER')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('cargo-insurance')), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
          }, [
            _createVNode(_component_plan_input_text, {
              onChange: _ctx.updateStore,
              "model-value": _ctx.carrierPackageForm.cargo.number,
              "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.carrierPackageForm.cargo.number) = $event)),
              label: _ctx.$t('number')
            }, null, 8, ["onChange", "model-value", "label"]),
            _createVNode(_component_plan_input_number, {
              onChange: _ctx.updateStore,
              "model-value": _ctx.carrierPackageForm.cargo.limitAmount,
              "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.carrierPackageForm.cargo.limitAmount) = $event)),
              label: _ctx.$t('limit'),
              "hide-validations": "",
              currency: ""
            }, null, 8, ["onChange", "model-value", "label"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
          }, [
            _createVNode(_component_plan_input_calendar, {
              onChange: _ctx.changeCargoExpirationDate,
              "default-date-label": _ctx.carrierPackageForm.cargo.expirationDate,
              min: _ctx.minDate,
              label: _ctx.$t('expiration'),
              class: "w-full",
              "hide-close": "",
              "hide-validation": ""
            }, null, 8, ["onChange", "default-date-label", "min", "label"])
          ], 2),
          (_openBlock(), _createBlock(_component_plan_input_file, {
            onChange: _ctx.changeCargoInsuranceFiles,
            key: _ctx.key,
            types: ['application/pdf', 'image/*'],
            maxSize: 10,
            loading: _ctx.loadingCargoInsuranceFile,
            "hide-loaded-items": _ctx.carrierPackageForm.cargo.id !== -1
          }, null, 8, ["onChange", "loading", "hide-loaded-items"])),
          (_ctx.carrierPackageForm.cargo.mediaObject)
            ? (_openBlock(), _createBlock(_component_plan_thumbnail, {
                key: _ctx.key,
                id: _ctx.carrierPackageForm.cargo.mediaObject.id,
                entityClass: _ctx.carrierPackageForm.cargo.mediaObject.entityClass
              }, null, 8, ["id", "entityClass"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('liability-insurance')), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
      }, [
        _createVNode(_component_plan_input_text, {
          onChange: _ctx.updateStore,
          "model-value": _ctx.carrierPackageForm.liabilityInsurance.number,
          "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.carrierPackageForm.liabilityInsurance.number) = $event)),
          label: _ctx.$t('number')
        }, null, 8, ["onChange", "model-value", "label"]),
        _createVNode(_component_plan_input_number, {
          onChange: _ctx.updateStore,
          "model-value": _ctx.carrierPackageForm.liabilityInsurance.limitAmount,
          "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.carrierPackageForm.liabilityInsurance.limitAmount) = $event)),
          label: _ctx.$t('limit'),
          "hide-validations": "",
          currency: ""
        }, null, 8, ["onChange", "model-value", "label"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
      }, [
        _createVNode(_component_plan_input_calendar, {
          onChange: _ctx.changeLiabilityInsuranceExpirationDate,
          "default-date-label": _ctx.carrierPackageForm.liabilityInsurance.expirationDate,
          label: _ctx.$t('expiration'),
          class: "w-full",
          "hide-close": "",
          "hide-validation": ""
        }, null, 8, ["onChange", "default-date-label", "label"])
      ], 2),
      (_openBlock(), _createBlock(_component_plan_input_file, {
        onChange: _ctx.changeLiabilityInsuranceFiles,
        key: _ctx.key,
        types: ['application/pdf', 'image/*'],
        maxSize: 10,
        loading: _ctx.loadingLiabilityInsuranceFile,
        "hide-loaded-items": _ctx.carrierPackageForm.liabilityInsurance.id !== -1
      }, null, 8, ["onChange", "loading", "hide-loaded-items"])),
      (_ctx.carrierPackageForm.liabilityInsurance.mediaObject)
        ? (_openBlock(), _createBlock(_component_plan_thumbnail, {
            key: _ctx.key,
            id: _ctx.carrierPackageForm.liabilityInsurance.mediaObject.id,
            entityClass: _ctx.carrierPackageForm.liabilityInsurance.mediaObject.entityClass
          }, null, 8, ["id", "entityClass"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('cif')), 1),
      (_openBlock(), _createBlock(_component_plan_input_file, {
        onChange: _ctx.changeCifFiles,
        key: _ctx.key,
        types: ['application/pdf', 'image/*'],
        maxSize: 10,
        loading: _ctx.loadingCifFile,
        "hide-loaded-items": ""
      }, null, 8, ["onChange", "loading"])),
      (_ctx.carrierPackageForm.cif.mediaObject !== null)
        ? (_openBlock(), _createBlock(_component_plan_thumbnail, {
            key: _ctx.key,
            id: _ctx.carrierPackageForm.cif.mediaObject.id,
            entityClass: _ctx.carrierPackageForm.cif.mediaObject.entityClass
          }, null, 8, ["id", "entityClass"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('contacts-import.mcNumber')), 1),
      (_openBlock(), _createBlock(_component_plan_input_file, {
        onChange: _ctx.changeMcNumberFile,
        key: _ctx.key,
        types: ['application/pdf', 'image/*'],
        maxSize: 10,
        loading: _ctx.loadingMcNumberFile,
        "hide-loaded-items": ""
      }, null, 8, ["onChange", "loading"])),
      (_ctx.carrierPackageForm.mcNumber.mediaObject !== null)
        ? (_openBlock(), _createBlock(_component_plan_thumbnail, {
            key: _ctx.key,
            id: _ctx.carrierPackageForm.mcNumber.mediaObject.id,
            entityClass: _ctx.carrierPackageForm.mcNumber.mediaObject.entityClass
          }, null, 8, ["id", "entityClass"]))
        : _createCommentVNode("", true)
    ])
  ]))
}