import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700" }
const _hoisted_4 = { class: "p-6 space-y-4 md:space-y-6 sm:p-8" }
const _hoisted_5 = { class: "text-center text-slate-900 text-4xl font-bold" }
const _hoisted_6 = { class: "text-center text-slate-900 text-base font-normal" }
const _hoisted_7 = {
  for: "password",
  class: "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Spin = _resolveComponent("Spin")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/signin",
      class: "flex items-center mb-6 text-5xl font-bold font-Quicksand text-gray-900 dark:text-white"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          alt: "Logo Planimatik",
          class: "max-w-[300px]",
          src: _ctx.getAsset.imgLogoV2
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('start-your-free-trial')), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('set-your-password-and-start', { n: _ctx.currentUser.companyName })), 1),
        _createVNode(_component_Form, {
          class: "space-y-4 md:space-y-6",
          ref: "form",
          onSubmit: _ctx.signUp
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(`${_ctx.$t('password')}*`), 1),
              _createVNode(_component_Field, {
                class: "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5",
                id: "password",
                type: "password",
                name: "password",
                rules: "required|min:6|max:256",
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                placeholder: _ctx.$t('create-password')
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_component_ErrorMessage, {
                name: "password",
                class: "text-rose-500"
              })
            ]),
            _createElementVNode("button", {
              class: _normalizeClass(["w-full inline-flex justify-center text-white text-base font-semibold bg-corp hover:opacity-70 focus:ring-4 focus:outline-none rounded-lg px-5 py-2.5 text-center", { 'cursor-not-allowed hover:opacity-60': _ctx.loading }]),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.signUp && _ctx.signUp(...args)))
            }, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_Spin, { key: 0 }))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.loading ? _ctx.$t('sending') : _ctx.$t('signup')), 1)
            ], 2)
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ])
  ]))
}