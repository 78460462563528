
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'PlanSelect',
  components: { PlanButton, PlanIcon },
  props: {
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: true, default: '' },
    align: { type: String, required: false, default: 'items-end' },
    items: { type: Array, required: true },
    value: { type: String, required: true },
    hideLeft: { type: Boolean, required: false, default: false },
    hideRight: { type: Boolean, required: false, default: false },
    rightLine: { type: Boolean, required: false, default: false },
    mandatory: { type: Boolean, required: false, default: false },
    addButtonLabel: { type: String, required: false, default: '' }
  },
  emits: ['change', 'addOnClick'],
  setup (props, { emit }) {
    const data = reactive({
      selectedValue: ''
    })

    if (props.value.length > 0) data.selectedValue = props.value + ''

    const selectedLabel = computed(() => {
      let result = '';
      props.items.forEach(function(item: any) {
        if (data.selectedValue === item.value) result = item.label;
      });
      return result;
    });

    const addOnClick = () => { emit('addOnClick') }

    const changeValue = (newValue) => {
      data.selectedValue = newValue
      emit('change', newValue)
    }

    watch(() => props.value, (newValue) => {
      data.selectedValue = data.selectedValue = props.value + ''
    })

    return {
      ...toRefs(data),
      selectedLabel,
      addOnClick,
      changeValue
    }
  }
})
