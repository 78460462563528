import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "load-card-footer-data" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-center items-center gap-1"
}
const _hoisted_3 = { class: "truncate" }
const _hoisted_4 = {
  key: 1,
  class: "flex justify-center items-center gap-1"
}
const _hoisted_5 = { class: "truncate" }
const _hoisted_6 = {
  key: 2,
  class: "flex justify-center items-center gap-1 truncate"
}
const _hoisted_7 = { class: "truncate" }
const _hoisted_8 = {
  key: 3,
  class: "flex justify-center items-center gap-1 truncate"
}
const _hoisted_9 = { class: "truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.distance.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_plan_icon, {
            name: "distance",
            "is-svg": "",
            size: "16"
          }),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.distance), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.vehicle.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_plan_icon, {
            name: "icInRoute",
            "is-svg": "",
            size: "16"
          }),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.vehicle), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.goodsType.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_plan_icon, {
            name: "package",
            "is-svg": "",
            size: "16"
          }),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.goodsType), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.specialRequirements.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.specialRequirements), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}