import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center relative" }
const _hoisted_2 = { class: "flex flex-col plan-gap-lg" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "plan-t-xxs plan-t-prim-100 italic" }
const _hoisted_5 = {
  key: 0,
  class: "w-full flex flex-col plan-gap-sm"
}
const _hoisted_6 = { class: "font-bold plan-t-h5" }
const _hoisted_7 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_8 = { class: "w-full flex justify-between items-center plan-gap-sm" }
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_carrier_default_modal, {
      onClose: _ctx.hideShareLoad,
      title: _ctx.$t('share-this-load'),
      size: "sm-auto"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex justify-between plan-gap-sm", _ctx.actualWidth <= 640 ? 'flex-col items-start':'items-center'])
            }, [
              _createVNode(_component_plan_input_text, {
                "model-value": _ctx.email,
                "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                placeholder: _ctx.$t('add-by-email')
              }, null, 8, ["model-value", "placeholder"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.putCompanyLoadNotificationEmails,
                loading: _ctx.loading,
                disabled: !_ctx.isValidEmail,
                type: "primary",
                label: _ctx.$t('add'),
                style: _normalizeStyle(_ctx.actualWidth <= 640 ? 'min-width: 100%;':'min-width: 120px;')
              }, null, 8, ["onOnClick", "loading", "disabled", "label", "style"])
            ], 2),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('share-this-load-definition')), 1)
          ]),
          (_ctx.loadNotificationEmails !== undefined && _ctx.loadNotificationEmails.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('shared-with-x-emails', { quantity: _ctx.loadNotificationEmails.length })), 1),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadNotificationEmails, (email, emailIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: email,
                      class: "w-full flex flex-col plan-gap-sm"
                    }, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_plan_avatar, { "user-name": email }, null, 8, ["user-name"]),
                        _createVNode(_component_plan_button_icon, {
                          onOnClick: ($event: any) => (_ctx.removeCompanyLoadNotificationEmails(email)),
                          loading: email === _ctx.emailToDelete,
                          disabled: _ctx.emailToDelete.length > 0,
                          icon: "close",
                          type: "transparent-gray-800"
                        }, null, 8, ["onOnClick", "loading", "disabled"])
                      ]),
                      (emailIndex !== _ctx.loadNotificationEmails.length -1)
                        ? (_openBlock(), _createElementBlock("hr", _hoisted_9))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["onClose", "title"])
  ]))
}