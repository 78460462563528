
import { defineComponent, reactive, toRefs, PropType } from 'vue';

export default defineComponent({
  name: 'PlanToggleButton',
  props: {
    isActive: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },
  emits: ['changeStatus'],
  setup (props, { emit }) {
    const data = reactive({
      isActive: props.isActive
    })

    const changeStatus = () => {
      data.isActive = !data.isActive
      emit('changeStatus', data.isActive)
    }

    return {
      ...toRefs(data),
      changeStatus
    }
  }
})
