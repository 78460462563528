
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanToggleButton from '@/components/buttons/PlanToggleButton.vue';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
// Base
import BaseComponent from '@/base/BaseComponent';
import { useRoute, useRouter } from 'vue-router';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';

export default defineComponent({
  name: 'CarrierResume',
  components: { PlanCarrierDefaultModal, PlanButton, PlanTabs, PlanLoading, PlanToggleButton },
  emits: ['close', 'refresh', 'showModalSuccess'],
  mixins: [BaseComponent],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const data = reactive({
      subject: '',
      mode: 'auto',
      loading: false,
      selectedTab: 0,
    });
    const translate = useI18n();

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const carrierSummary = computed(() => {
      return store.getters['ShipmentStore/getActivitySummary'];
    });

    const formattedDate = (date) => {
      return moment(date).format('DD MMM');
    };

    const changeTab = (tab) => {
      const tabsFilter = ['QUOTES', 'CONFIRM', 'IN_PROGRESS'];
      data.loading = true;
      data.selectedTab = tab;
      store.dispatch('ShipmentStore/getActivitySummary', tabsFilter[tab]).then(() => {
        data.loading = false;
      });
    };

    const userEmailNotificationSetup = computed(() => {
      return store.getters['UserStore/getUserEmailNotificationSetup'];
    });

    const carrierWhenActivitySummary = computed(() => {
      return store.getters['UserStore/getUserEmailNotificationSetup']?.carrier_WhenActivitySummary;
    });

    const handleRouteRedirecction = (object) => {
      //TODO REFACTOR THIS AND DELETE SPLIT
      store.commit('CarrierStore/setShowNotifications', false);
      let URL = object.link;
      if (URL.includes('accept')) {
        let items = URL.split('/');
        router.push({
          name: 'assignedLane',
          params: { id: items[items.length - 3], quoteId: items[items.length - 2] },
        });
      } else if (URL.includes('deny')) {
        let items = URL.split('/');
        router.push({
          name: 'refusedOfferLane',
          params: { id: items[items.length - 3], quoteId: items[items.length - 2] },
        });
      } else {
        let items = URL.split('/');
        router.push({ name: 'CarrierBalancer', params: { id: items[items.length - 1] } });
      }
    };

    const disableResumeEmail = () => {
      userEmailNotificationSetup.value.carrier_WhenActivitySummary =
        !userEmailNotificationSetup.value.carrier_WhenActivitySummary;

      store
        .dispatch('UserStore/putUserEmailNotificationSetup', {
          id: userEmailNotificationSetup.value.id,
          body: {
            carrierWhenActivitySummary:
              userEmailNotificationSetup.value.carrier_WhenActivitySummary,
          },
        })
        .then(() => {
          store.dispatch('UserStore/getUserEmailNotificationSetup');
          store.dispatch('UserStore/updateToast', {
            text: translate.t('updated-correctly'),
            type: 'success',
            visible: true,
          });
        })
        .catch((err) => {
          store.dispatch('UserStore/updateToast', {
            text: err?.response?.data?.title,
            type: 'error',
            visible: true,
          });
        });
    };

    return {
      ...toRefs(data),
      actualWidth,
      carrierSummary,
      changeTab,
      formattedDate,
      disableResumeEmail,
      translate,
      handleRouteRedirecction,
      userEmailNotificationSetup,
      carrierWhenActivitySummary,
    };
  },
});
