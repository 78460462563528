import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed top-0 left-0 w-full h-full flex items-center justify-center p-6 bg-gray-500 bg-opacity-75 transition-opacity z-50"
}
const _hoisted_2 = { class: "w-full sm:w-2/3 h-full bg-white p-16 rounded-lg shadow-lg" }
const _hoisted_3 = { class: "menu-top flex top-0 left-0 justify-end md:bg-none h-auto right-0 w-auto" }
const _hoisted_4 = { class: "menu-top flex justify-center md:bg-none h-auto right-0 w-auto" }
const _hoisted_5 = { class: "text-2xl font-black" }
const _hoisted_6 = { class: "overflow-y-auto bg-white p-6 h-5/6 sm:h-full w-full" }
const _hoisted_7 = { class: "text-2xl font-black h4--line" }
const _hoisted_8 = { class: "mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400" }
const _hoisted_9 = { class: "rounded-xl justify-center" }
const _hoisted_10 = { class: "text-2xl font-black h4--line" }
const _hoisted_11 = { class: "flex flex-col items-center justify-center p-10" }
const _hoisted_12 = ["onClick", "src"]
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!

  return (_ctx.showUploadModal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("nav", _hoisted_3, [
            _createElementVNode("button", {
              class: "float-right",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideModal && _ctx.hideModal(...args)))
            }, [
              _createVNode(_component_XCircleIcon, { class: "w-8 h-8 text-black" })
            ])
          ]),
          _createElementVNode("nav", _hoisted_4, [
            _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('notify-incidence-modal-info')) + " " + _toDisplayString(_ctx.$t('stop-with-param', { id: _ctx.load?.sequence }).toUpperCase()), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.load?.incidents, (incidence, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.$t('incidence-text')), 1),
                _createElementVNode("p", _hoisted_8, _toDisplayString(incidence.description), 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h4", _hoisted_10, _toDisplayString(_ctx.$t('pictures')), 1),
                  _createElementVNode("div", _hoisted_11, [
                    (incidence.mediaObjects.length > 0)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(incidence.mediaObjects, (mediaObject, index) => {
                          return (_openBlock(), _createElementBlock("img", {
                            key: index,
                            onClick: ($event: any) => (_ctx.download(mediaObject.id)),
                            src: 
                      _ctx.signImage[
                        (i > 0
                          ? _ctx.load?.incidents
                              .slice(0, i)
                              .reduce((acc, inc) => acc + inc.mediaObjects.length, 0)
                          : 0) + index
                      ] || ''
                    ,
                            class: "w-full sm:w-full object-cover cursor-pointer p-4"
                          }, null, 8, _hoisted_12))
                        }), 128))
                      : _createCommentVNode("", true),
                    (incidence.mediaObjects.length === 0)
                      ? (_openBlock(), _createElementBlock("h2", _hoisted_13, _toDisplayString(_ctx.$t('no-image')), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}