import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full rounded-lg border plan-border-prim-50 plan-p-sm flex flex-col plan-gap-sm" }
const _hoisted_2 = { class: "w-full flex justify-between items-center" }
const _hoisted_3 = { class: "font-bold plan-text-lg" }
const _hoisted_4 = { class: "flex justify-start items-center plan-gap-md" }
const _hoisted_5 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_6 = { class: "flex justify-start items-center plan-gap-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_input_location = _resolveComponent("plan-input-location")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('stop')) + " " + _toDisplayString(_ctx.position), 1),
      _createVNode(_component_plan_button_icon, {
        onOnClick: _ctx.deleteStop,
        icon: "trash",
        type: "transparent-gray-800"
      }, null, 8, ["onOnClick"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateLocation && _ctx.updateLocation(...args))),
          type: "radio",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event)),
          value: "PICKUP"
        }, null, 544), [
          [_vModelRadio, _ctx.type]
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('pick-up')), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateLocation && _ctx.updateLocation(...args))),
          type: "radio",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.type) = $event)),
          value: "DELIVERY"
        }, null, 544), [
          [_vModelRadio, _ctx.type]
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('delivery')), 1)
      ])
    ]),
    _createVNode(_component_plan_input_location, {
      "pre-location": _ctx.location.zip ? _ctx.location.zip : _ctx.location.city,
      onChange: _ctx.getLocationData
    }, null, 8, ["pre-location", "onChange"])
  ]))
}