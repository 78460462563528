import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col plan-p-md plan-gap-sm" }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_3 = {
  key: 0,
  class: "flex plan-pl-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_plan_input_calendar = _resolveComponent("plan-input-calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_plan_input_checkbox, { onChange: _ctx.changeShowForm }, null, 8, ["onChange"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('start-date')), 1)
    ]),
    (_ctx.showForm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_plan_input_calendar, {
            onChange: _ctx.changeSelectedDate,
            min: _ctx.initialValues.min,
            max: _ctx.initialValues.max,
            "hide-validation": ""
          }, null, 8, ["onChange", "min", "max"])
        ]))
      : _createCommentVNode("", true)
  ]))
}