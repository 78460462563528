
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
// Base
import BaseComponent from '@/base/BaseComponent';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
// Services
import * as Global from '@/services/global/GlobalConst';
// Templates
import AuthTemplate from '@/modules/auth/AuthTemplate.vue';
// Utils
import { isValidHttpUrl, validPhoneNumberPaste } from '@/utils/validationForm';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';

export default defineComponent({
  name: 'RequestDemo',
  components: {
    PhoneNumberSelector,
    AuthTemplate,
    ErrorMessage,
    Field,
    Form,
  },
  mixins: [BaseComponent, CountryMixins],
  setup() {
    /** Global */
    const router = useRouter();
    const store = useStore();

    const city = ref('');
    const companyAddress = ref('');
    const companyAddressExtra = ref('');
    const companyEmail = ref('');
    const companyName = ref('');
    const companyURL = ref('');
    const country = ref('');
    const countryIso = ref(-1);
    const fein = ref('');
    const firstName = ref('');
    const form = ref(null);
    const lastName = ref('');
    const loading = ref(false);
    const phoneNumber = ref('');
    const phonePrefix = ref('');
    const phonePrefixData = ref([]);
    const phonePrefixIso = ref(-1);
    const showSuccessView = ref(false);
    const termsConditions = ref(false);
    const termsConditionsError = ref(false);
    const URLLanding = ref(Global.URL_LANDING);
    const URLTerms = ref(Global.URL_TERMS);

    /** Lifecycle */
    onMounted(() => {
      apiGetCountries();
    });

    onUnmounted(() => {
      clearDemoData();
    });

    /** Computed */
    const countriesData = computed(() => {
      return store.getters['CountryStore/getCountries'];
    });

    /** Methods */
    const apiGetCountries = () => {
      return store.dispatch('CountryStore/getCountries');
    };

    const apiPostDemoRequest = () => {
      const params = {
        name: firstName.value,
        surname: lastName.value,
        email: companyEmail.value,
        phonePrefix: phonePrefix.value,
        phone: phoneNumber.value,
        companyName: companyName.value,
        companyIdentificationNumber: fein.value,
        companyURL: companyURL.value,
        location: {
          address: companyAddress.value,
          city: city.value,
        },
        country: {
          id: country.value,
        },
      };

      loading.value = true;
      store
        .dispatch('DemoStore/apiPostDemoRequests', params)
        .then(() => {
          setLoading(false);
          showSuccessView.value = true;
        })
        .catch(() => setLoading(false));
    };

    const changePhone = (type, phone) => {
      switch (type) {
        case 'prefix':
          phonePrefix.value = phone.prefix;
          break;
        case 'number':
          phoneNumber.value = phone.number;
          break;
        default:
          return;
      }
    };

    const clearDemoData = () => {
      store.dispatch('DemoStore/clearDemoData');
    };

    const onChangeCountry = () => {
      const result = countriesData.value.find((item) => item.id === country.value);
      if (result) {
        countryIso.value = result.iso;
      }
    };

    const onChangePrefix = () => {
      const result = countriesData.value.find((item) => item.id === phonePrefix.value);
      if (result) {
        phonePrefixIso.value = result.iso;
      }
    };

    const onClickGoToLogin = () => router.push({ name: 'signin' });

    const onSubmitForm = async () => {
      // Terms & Conditions checkbox
      if (!termsConditions.value) {
        termsConditionsError.value = true;
        return;
      }

      // Check form
      const validation = await (form as any).value.validate();
      termsConditionsError.value = false;
      if (!validation.valid) {
        BaseComponent.methods?.showToastError(validation.errors);
        return;
      }

      apiPostDemoRequest();
    };

    const setLoading = (value) => {
      loading.value = value;
    };

    /** Watchers */
    watch(
      () => countriesData.value,
      (countries) => {
        // Assign array
        if (JSON.stringify(countries) !== JSON.stringify(phonePrefixData.value)) {
          // Remove duplicate values
          const prefixes = countries.map((item) => item.phonePrefix);
          phonePrefixData.value = countries?.filter(
            (item, index) => prefixes?.indexOf(item.phonePrefix) === index,
          );
        }
      },
    );

    return {
      city,
      changePhone,
      companyAddress,
      companyAddressExtra,
      companyEmail,
      companyName,
      companyURL,
      countriesData,
      country,
      countryIso,
      fein,
      firstName,
      form,
      lastName,
      loading,
      phoneNumber,
      phonePrefix,
      phonePrefixData,
      phonePrefixIso,
      showSuccessView,
      termsConditions,
      termsConditionsError,
      URLLanding,
      URLTerms,
      isValidHttpUrl,
      onChangeCountry,
      onChangePrefix,
      onClickGoToLogin,
      onSubmitForm,
      validPhoneNumberPaste,
    };
  },
});
