import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex justify-start items-center text-ellipsis flex-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "mr-1",
  style: {"flex":"0 0 100%"}
}
const _hoisted_3 = { class: "text-ellipsis" }
const _hoisted_4 = {
  key: 1,
  class: "mr-1"
}
const _hoisted_5 = {
  key: 2,
  class: "mr-2"
}
const _hoisted_6 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.postalCode.length > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.postalCode), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.cityToShow), 1),
    (_ctx.isoCode.length > 0 || _ctx.ct.length > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, ","))
      : _createCommentVNode("", true),
    (_ctx.isoCode.length > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.isoCode !== _ctx.cityToShow ?  _ctx.isoCode : ''), 1))
      : _createCommentVNode("", true),
    (_ctx.ct.length > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.ct), 1))
      : _createCommentVNode("", true)
  ]))
}