import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex flex-col gap-5 my-10 justify-between lg:flex-row" }
const _hoisted_3 = { class: "w-full flex flex-col md:w-5/12 lg:w-1/5" }
const _hoisted_4 = {
  for: "po-number",
  class: "mb-0.5 text-bold"
}
const _hoisted_5 = { class: "mt-5 flex flex-col lg:flex-row space-x-0 lg:space-x-10 space-y-5 lg:space-y-0" }
const _hoisted_6 = { class: "flex space-x-3 w-full" }
const _hoisted_7 = { class: "flex-row w-full" }
const _hoisted_8 = { class: "flex flex-row w-full flex-wrap lg:flex-nowrap space-x-0 md:space-x-5 lg:space-x-5" }
const _hoisted_9 = { class: "flex flex-col w-full md:w-6/12 lg:w-2/3" }
const _hoisted_10 = {
  for: "origin",
  class: "mb-1 text-bold"
}
const _hoisted_11 = { class: "relative" }
const _hoisted_12 = { class: "flex flex-col w-full md:w-5/12 lg:w-1/3" }
const _hoisted_13 = {
  for: "datepicker_1",
  class: "mb-1 text-bold"
}
const _hoisted_14 = { class: "flex space-x-0 lg:space-x-3 w-full" }
const _hoisted_15 = { class: "flex-row w-full" }
const _hoisted_16 = { class: "flex flex-row w-full flex-wrap lg:flex-nowrap space-x-0 md:space-x-5 lg:space-x-5" }
const _hoisted_17 = { class: "flex flex-col w-full md:w-6/12 lg:w-2/3" }
const _hoisted_18 = {
  for: "destiny",
  class: "mb-1 text-bold"
}
const _hoisted_19 = { class: "relative" }
const _hoisted_20 = { class: "mt-10 mb-10 flex flex-col lg:flex-row space-x-0 lg:space-x-10 space-y-5 lg:space-y-0" }
const _hoisted_21 = { class: "flex flex-col w-full" }
const _hoisted_22 = { class: "flex flex-row w-full flex-wrap lg:flex-nowrap space-x-0 md:space-x-5 lg:space-x-5" }
const _hoisted_23 = { class: "container mx-auto border border-gray-300 p-4 rounded-md shadow-lg relative focus:outline-none" }
const _hoisted_24 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_25 = { class: "flex" }
const _hoisted_26 = { class: "flex items-center" }
const _hoisted_27 = { class: "mx-2" }
const _hoisted_28 = { class: "flex" }
const _hoisted_29 = { class: "flex items-center" }
const _hoisted_30 = { class: "mx-2" }
const _hoisted_31 = { class: "flex" }
const _hoisted_32 = { class: "flex items-center" }
const _hoisted_33 = { class: "mx-2" }
const _hoisted_34 = { class: "flex" }
const _hoisted_35 = { class: "flex items-center" }
const _hoisted_36 = { class: "mx-2" }
const _hoisted_37 = { class: "flex" }
const _hoisted_38 = { class: "flex items-center" }
const _hoisted_39 = { class: "mx-2" }
const _hoisted_40 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_41 = {
  value: "",
  disabled: ""
}
const _hoisted_42 = ["value"]
const _hoisted_43 = { class: "flex flex-col w-full" }
const _hoisted_44 = { class: "flex flex-row w-full flex-wrap lg:flex-nowrap space-x-0 md:space-x-5 lg:space-x-5" }
const _hoisted_45 = { class: "container mx-auto border border-gray-300 p-4 rounded-md shadow-lg relative focus:outline-none" }
const _hoisted_46 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_47 = { class: "flex" }
const _hoisted_48 = { class: "flex items-center" }
const _hoisted_49 = { class: "mx-2" }
const _hoisted_50 = { class: "flex" }
const _hoisted_51 = { class: "flex items-center" }
const _hoisted_52 = { class: "mx-2" }
const _hoisted_53 = { class: "flex" }
const _hoisted_54 = { class: "flex items-center" }
const _hoisted_55 = { class: "mx-2" }
const _hoisted_56 = { class: "flex" }
const _hoisted_57 = { class: "flex items-center" }
const _hoisted_58 = { class: "mx-2" }
const _hoisted_59 = { class: "flex" }
const _hoisted_60 = { class: "flex items-center" }
const _hoisted_61 = { class: "mx-2" }
const _hoisted_62 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_63 = {
  value: "",
  disabled: ""
}
const _hoisted_64 = ["value"]
const _hoisted_65 = { class: "flex items-start" }
const _hoisted_66 = { class: "flex items-center" }
const _hoisted_67 = { class: "mx-2" }
const _hoisted_68 = { class: "fixed bottom-0 left-0 bg-blue-100 w-screen z-10" }
const _hoisted_69 = { class: "w-full xl:container flex justify-center md:justify-end items-center py-6 px-10 space-x-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!
  const _component_google_autocomplete = _resolveComponent("google-autocomplete")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_DatePickerComponent = _resolveComponent("DatePickerComponent")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    ref: "form",
    onSubmit: ($event: any) => (null)
  }, {
    default: _withCtx(() => [
      (_ctx.pickup && _ctx.delivery)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('po-number')), 1),
                _createVNode(_component_Field, {
                  class: "bg-gray-50 w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex p-2.5",
                  rules: "max:256",
                  type: "text",
                  modelValue: _ctx.poNumber,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.poNumber) = $event)),
                  id: 'poNumber',
                  name: 'poNumber',
                  value: _ctx.poNumber,
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleExtraFields('poNumber', $event)))
                }, null, 8, ["modelValue", "value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("label", _hoisted_10, _toDisplayString(`${_ctx.$t('pick-up-zip')} *`), 1),
                      _createElementVNode("div", _hoisted_11, [
                        (_ctx.originStop !== '')
                          ? (_openBlock(), _createBlock(_component_XCircleIcon, {
                              key: 0,
                              class: "w-4 h-4 text-corp absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.originStop = ''))
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_Field, {
                          name: "Origin",
                          rules: "required",
                          modelValue: _ctx.originStop,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.originStop) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_google_autocomplete, {
                              ref: "origin",
                              name: "Origin",
                              id: "origin-input",
                              class: _normalizeClass(["h-11 bg-gray-50", _ctx.states.length === 0 ? 'cursor-not-allowed' : '']),
                              onPlacechanged: _ctx.getOrigin,
                              value: _ctx.originStop,
                              country: 'us',
                              types: '(regions)',
                              disabled: _ctx.states.length === 0,
                              placeholder: _ctx.$t('placeholder-enter-your-origin-zip')
                            }, null, 8, ["onPlacechanged", "value", "types", "class", "disabled", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _createVNode(_component_ErrorMessage, {
                        name: "Origin",
                        class: "text-rose-500"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("label", _hoisted_13, _toDisplayString(`${_ctx.$t('pickup-date')} *`), 1),
                      _createVNode(_component_Field, {
                        name: "datepicker_1",
                        rules: "required",
                        modelValue: _ctx.pickup.executionDateField,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pickup.executionDateField) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DatePickerComponent, {
                            modelValue: _ctx.pickup.executionDateField,
                            "onUpdate:modelValue": [
                              _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pickup.executionDateField) = $event)),
                              _ctx.checkDate
                            ],
                            "disabled-weekends": true,
                            customClass: "'text-gray-900 bg-gray-50 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full'"
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_ErrorMessage, {
                        name: "datepicker_1",
                        class: "text-rose-500"
                      })
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("label", _hoisted_18, _toDisplayString(`${_ctx.$t('deli-zip')} *`), 1),
                      _createElementVNode("div", _hoisted_19, [
                        (_ctx.destinationStop !== '')
                          ? (_openBlock(), _createBlock(_component_XCircleIcon, {
                              key: 0,
                              class: "w-4 h-4 text-corp absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.destinationStop = ''))
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_Field, {
                          name: "Destiny",
                          rules: "required",
                          modelValue: _ctx.destinationStop,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.destinationStop) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_google_autocomplete, {
                              ref: "destiny",
                              name: "Destiny",
                              id: "'destination-input'",
                              class: _normalizeClass(["h-11 bg-gray-50", _ctx.states.length === 0 ? 'cursor-not-allowed' : '']),
                              onPlacechanged: _ctx.getDestination,
                              value: _ctx.destinationStop,
                              country: 'us',
                              types: '(regions)',
                              disabled: _ctx.states.length === 0,
                              placeholder: _ctx.$t('placeholder-enter-your-destination-zip')
                            }, null, 8, ["onPlacechanged", "value", "types", "class", "disabled", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _createVNode(_component_ErrorMessage, {
                        name: "Destiny",
                        class: "text-rose-500"
                      })
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('accesorials-pickup')), 1),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("label", _hoisted_26, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            class: "form-checkbox",
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pickup.inside) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.pickup.inside]
                          ]),
                          _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('inside-pick')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("label", _hoisted_29, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            class: "form-checkbox",
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.pickup.airportStop) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.pickup.airportStop]
                          ]),
                          _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t('airport-pickup')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("label", _hoisted_32, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            class: "form-checkbox",
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.pickup.residential) = $event)),
                            onInput: _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateResidential(_ctx.pickup)))
                          }, null, 544), [
                            [_vModelCheckbox, _ctx.pickup.residential]
                          ]),
                          _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.$t('residential-pick')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("label", _hoisted_35, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            class: "form-checkbox",
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.pickup.liftgate) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.pickup.liftgate]
                          ]),
                          _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t('lift-gate')), 1)
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("label", _hoisted_38, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              class: "form-checkbox",
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.pickup.limitedDefault) = $event)),
                              onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.updateLimitedAccess(_ctx.pickup)))
                            }, null, 544), [
                              [_vModelCheckbox, _ctx.pickup.limitedDefault]
                            ]),
                            _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.$t('limited-access')), 1)
                          ])
                        ]),
                        (_ctx.pickup.limitedDefault && _ctx.pickup && _ctx.pickup.limitedAccess)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                              _createVNode(_component_Field, {
                                as: "select",
                                modelValue: _ctx.pickup.limitedAccess.id,
                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.pickup.limitedAccess.id) = $event)),
                                name: 'pickupLimitedAccess',
                                id: 'pickupLimitedAccess',
                                rules: "required",
                                class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("option", _hoisted_41, _toDisplayString(_ctx.$t('select')), 1),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limitedAccessList, (unit) => {
                                    return (_openBlock(), _createElementBlock("option", {
                                      value: unit.id,
                                      key: unit.id
                                    }, _toDisplayString(unit.fullName), 9, _hoisted_42))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"]),
                              _createVNode(_component_ErrorMessage, {
                                name: 'pickupLimitedAccess',
                                class: "text-rose-500"
                              })
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('accesorials-delivery')), 1),
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("label", _hoisted_48, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            class: "form-checkbox",
                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.delivery.inside) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.delivery.inside]
                          ]),
                          _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.$t('inside-del')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_50, [
                        _createElementVNode("label", _hoisted_51, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            class: "form-checkbox",
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.delivery.airportStop) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.delivery.airportStop]
                          ]),
                          _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.$t('airport-delivery')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_53, [
                        _createElementVNode("label", _hoisted_54, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            class: "form-checkbox",
                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.delivery.residential) = $event)),
                            onInput: _cache[19] || (_cache[19] = ($event: any) => (_ctx.updateResidential(_ctx.delivery)))
                          }, null, 544), [
                            [_vModelCheckbox, _ctx.delivery.residential]
                          ]),
                          _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.$t('residential-del')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_56, [
                        _createElementVNode("label", _hoisted_57, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            class: "form-checkbox",
                            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.delivery.liftgate) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.delivery.liftgate]
                          ]),
                          _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.$t('lift-gate')), 1)
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_59, [
                          _createElementVNode("label", _hoisted_60, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              class: "form-checkbox",
                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.delivery.limitedDefault) = $event)),
                              onChange: _cache[22] || (_cache[22] = ($event: any) => (_ctx.updateLimitedAccess(_ctx.delivery)))
                            }, null, 544), [
                              [_vModelCheckbox, _ctx.delivery.limitedDefault]
                            ]),
                            _createElementVNode("span", _hoisted_61, _toDisplayString(_ctx.$t('limited-access')), 1)
                          ])
                        ]),
                        (_ctx.delivery.limitedDefault && _ctx.delivery && _ctx.delivery.limitedAccess)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                              _createVNode(_component_Field, {
                                as: "select",
                                modelValue: _ctx.delivery.limitedAccess.id,
                                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.delivery.limitedAccess.id) = $event)),
                                name: 'deliverLimitedAccess',
                                id: 'deliverLimitedAccess',
                                rules: "required",
                                class: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("option", _hoisted_63, _toDisplayString(_ctx.$t('select')), 1),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limitedAccessList, (unit) => {
                                    return (_openBlock(), _createElementBlock("option", {
                                      value: unit.id,
                                      key: unit.id
                                    }, _toDisplayString(unit.fullName), 9, _hoisted_64))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"]),
                              _createVNode(_component_ErrorMessage, {
                                name: 'deliverLimitedAccess',
                                class: "text-rose-500"
                              })
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_65, [
                        _createElementVNode("label", _hoisted_66, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            class: "form-checkbox",
                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.delivery.appointment) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.delivery.appointment]
                          ]),
                          _createElementVNode("span", _hoisted_67, _toDisplayString(_ctx.$t('appointment')), 1)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_68, [
        _createElementVNode("div", _hoisted_69, [
          _createElementVNode("a", {
            onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
            class: "bg-confirm cursor-pointer flex px-4 md:px-8 py-2 rounded-md text-white border border-confirm text-center font-bold hover:opacity-70"
          }, _toDisplayString(_ctx.$t('continue')), 1)
        ])
      ])
    ]),
    _: 1
  }, 512))
}