
import { computed, defineComponent } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'SidebarUserButton',
  components: { PlanIcon },
  props: {
    icon: { type: String, required: true, default: '' },
    label: { type: String, required: true, default: '' },
    whenActive: { type: String, required: false, default: '' }
  },
  setup (props) {
    const route = useRouter()

    const activeButton = computed(() => {
      return route.currentRoute.value.meta.activeButton
    })

    return {
      activeButton
    }
  }
})
