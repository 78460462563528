import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  globalConfig: null,
  section: 'list',
  activeStatus: null as string | null,
  isOpenChat: false,
  planificationFilters: null,
  emailConfigModal: false,
  userLanguageModal: false,
  sectionFromPlanification: false
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
