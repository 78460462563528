import globalAxios from 'axios';

const getShipperDashboard = async (id: any) => {
  const response = await globalAxios.get(`/companies/${id}/metabase/dashboard`);
  return Promise.resolve(response.data);
};

export default {
  getShipperDashboard,
};
