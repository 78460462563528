import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "flex p-4" }
const _hoisted_2 = { class: "flex items-center mr-4" }
const _hoisted_3 = ["checked"]
const _hoisted_4 = {
  for: "inline-radio",
  class: "ml-2 text-sm font-medium text-gray-900"
}
const _hoisted_5 = { class: "flex items-center mr-4" }
const _hoisted_6 = ["checked"]
const _hoisted_7 = {
  for: "inline-2-radio",
  class: "ml-2 text-sm font-medium text-gray-900"
}
const _hoisted_8 = { class: "flex flex-wrap -mx-3 mb-6" }
const _hoisted_9 = {
  key: 0,
  class: "flex justify-center items-start"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "plan-form" }
const _hoisted_12 = {
  value: "",
  disabled: ""
}
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "w-full pr-3 pl-3" }
const _hoisted_15 = {
  key: 0,
  class: "container mx-auto"
}
const _hoisted_16 = {
  class: "text-xl my-5 font-bold",
  style: {"font-size":"24px"}
}
const _hoisted_17 = {
  key: 1,
  class: "mb-3 pb-6 border-b"
}
const _hoisted_18 = { class: "text-xl my-5 font-bold" }
const _hoisted_19 = { class: "flex items-center" }
const _hoisted_20 = {
  for: "usaDomestic",
  class: "ml-2 text-sm font-medium text-gray-900"
}
const _hoisted_21 = { class: "flex items-center" }
const _hoisted_22 = {
  for: "mexDomestic",
  class: "ml-2 text-sm font-medium text-gray-900"
}
const _hoisted_23 = { class: "flex items-center" }
const _hoisted_24 = {
  for: "transferLoad",
  class: "ml-2 text-sm font-medium text-gray-900"
}
const _hoisted_25 = {
  key: 2,
  class: "text-xl my-5 font-bold",
  style: {"font-size":"24px"}
}
const _hoisted_26 = { class: "flex flex-row" }
const _hoisted_27 = { class: "flex flex-col w-1/2 px-3 mb-6" }
const _hoisted_28 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_29 = ["checked"]
const _hoisted_30 = {
  for: "alcoholic-beverages",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_31 = { class: "flex items-center" }
const _hoisted_32 = ["checked"]
const _hoisted_33 = {
  for: "hazardous-materials",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_34 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_35 = ["checked"]
const _hoisted_36 = {
  for: "tir",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_37 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_38 = ["checked"]
const _hoisted_39 = {
  for: "TWIC-card",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_40 = { class: "flex items-center" }
const _hoisted_41 = ["checked"]
const _hoisted_42 = {
  for: "TWIC-card",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_43 = { class: "flex items-center" }
const _hoisted_44 = ["checked"]
const _hoisted_45 = {
  for: "TWIC-card",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_46 = { class: "flex items-center" }
const _hoisted_47 = ["checked"]
const _hoisted_48 = {
  for: "TWIC-card",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_49 = {
  key: 3,
  class: "flex items-center"
}
const _hoisted_50 = ["checked"]
const _hoisted_51 = {
  for: "TSA-card",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_52 = { class: "flex flex-col w-1/2 px-3 mb-6" }
const _hoisted_53 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_54 = ["checked"]
const _hoisted_55 = {
  for: "overweight",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_56 = { class: "flex items-center" }
const _hoisted_57 = ["checked"]
const _hoisted_58 = {
  for: "drivers-team",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_59 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_60 = ["checked"]
const _hoisted_61 = {
  for: "bond-permit",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_Field = _resolveComponent("Field")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_new_contact_tags = _resolveComponent("plan-new-contact-tags")!
  const _component_smart_tagz = _resolveComponent("smart-tagz")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
          id: "inline-radio",
          type: "radio",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contactInfoProxy.userType) = $event)),
          value: "CARRIER",
          name: "inline-radio-group",
          class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 focus:ring-blue-500",
          checked: _ctx.user?.company?.type === 'CARRIER'
        }, null, 40, _hoisted_3), [
          [_vModelRadio, _ctx.contactInfoProxy.userType]
        ]),
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('carrier')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
          checked: _ctx.user?.company?.type === 'BROKER',
          id: "inline-2-radio",
          type: "radio",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contactInfoProxy.userType) = $event)),
          value: "BROKER",
          name: "inline-radio-group",
          class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 focus:ring-blue-500 focus:ring-2"
        }, null, 40, _hoisted_6), [
          [_vModelRadio, _ctx.contactInfoProxy.userType]
        ]),
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('broker')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactInfo, (value, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          class: "w-full md:w-1/2 px-3 mb-6 mt-3 md:mb-0"
        }, [
          (key === 'phone')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_plan_select, {
                  onChange: _ctx.emitUpdate,
                  value: _ctx.contactInfoProxy.prefix,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contactInfoProxy.prefix) = $event)),
                  items: _ctx.prefixes,
                  label: _ctx.$t('warehouse-form.prefix'),
                  placeholder: "+1",
                  "hide-right": "",
                  "right-line": "",
                  style: {"width":"110px"}
                }, null, 8, ["onChange", "value", "items", "label"]),
                _createVNode(_component_plan_input_number, {
                  "model-value": _ctx.contactInfoProxy[key],
                  "onUpdate:model-value": ($event: any) => ((_ctx.contactInfoProxy[key]) = $event),
                  onChange: _ctx.emitUpdate,
                  label: _ctx.$t('warehouse-form.phone'),
                  placeholder: "000 000 0000",
                  validations: ['greaterThan9Digits'],
                  "hide-left": ""
                }, null, 8, ["model-value", "onUpdate:model-value", "onChange", "label"])
              ]))
            : (key === 'country')
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('country')), 1),
                  _createVNode(_component_Field, {
                    as: "select",
                    id: 'country',
                    name: 'country',
                    class: "plan-form w-full",
                    modelValue: _ctx.contactInfoProxy.country.id,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.contactInfoProxy.country.id) = $event)),
                    onChange: _ctx.emitUpdate,
                    rules: "required"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("option", _hoisted_12, _toDisplayString(_ctx.$t('select')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryList, (country, index) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: country.id,
                          key: index
                        }, _toDisplayString(country.name), 9, _hoisted_13))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]))
              : (key === 'mcNumber' ||  key === 'dotNumber')
                ? (_openBlock(), _createBlock(_component_plan_input_text, {
                    key: 2,
                    "model-value": _ctx.contactInfoProxy[key],
                    "onUpdate:model-value": ($event: any) => ((_ctx.contactInfoProxy[key]) = $event),
                    onChange: _ctx.emitUpdate,
                    label: _ctx.$t(`contact-form.${key}`),
                    placeholder: _ctx.$t(`contact-form.${key}`)
                  }, null, 8, ["model-value", "onUpdate:model-value", "onChange", "label", "placeholder"]))
                : (key !== 'prefix' && key !== 'userType')
                  ? (_openBlock(), _createBlock(_component_plan_input_text, {
                      key: 3,
                      "model-value": _ctx.contactInfoProxy[key],
                      "onUpdate:model-value": ($event: any) => ((_ctx.contactInfoProxy[key]) = $event),
                      onChange: _ctx.emitUpdate,
                      label: _ctx.$t(`contact-form.${key}`),
                      placeholder: _ctx.$t(`contact-form.${key}`),
                      validations: ['empty']
                    }, null, 8, ["model-value", "onUpdate:model-value", "onChange", "label", "placeholder"]))
                  : _createCommentVNode("", true)
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_plan_new_contact_tags, {
          onChange: _ctx.changeCompanyTags,
          "previous-tags": _ctx.company.tags
        }, null, 8, ["onChange", "previous-tags"])
      ])
    ]),
    (_ctx.currentCompany.loadNotificationEmails)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("h3", _hoisted_16, _toDisplayString(_ctx.$t('notify-emails-edit')), 1),
          _createVNode(_component_smart_tagz, {
            theme: {
        primary: '#2563EB',
        background: '#FFFFFF',
        tagTextColor: '#FFFFFF',
      },
            autosuggest: "",
            editable: "",
            "on-changed": _ctx.checkEmail,
            inputPlaceholder: _ctx.$t('add-new-email'),
            allowPaste: { delimiter: ',' },
            allowDuplicates: false,
            maxTags: 8,
            defaultTags: _ctx.currentCompany.loadNotificationEmails
          }, null, 8, ["on-changed", "inputPlaceholder", "defaultTags"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isMexico)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("h3", _hoisted_18, _toDisplayString(_ctx.$t('load-type')), 1),
          _createElementVNode("div", _hoisted_19, [
            _withDirectives(_createElementVNode("input", {
              id: "usaDomestic",
              name: "usaDomestic",
              type: "checkbox",
              onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.companyProxy.usaDomestic) = $event)),
              class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            }, null, 544), [
              [_vModelCheckbox, _ctx.companyProxy.usaDomestic]
            ]),
            _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('loads-domestic-usa')), 1)
          ]),
          _createElementVNode("div", _hoisted_21, [
            _withDirectives(_createElementVNode("input", {
              id: "mexDomestic",
              name: "mexDomestic",
              type: "checkbox",
              onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.companyProxy.mexDomestic) = $event)),
              class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            }, null, 544), [
              [_vModelCheckbox, _ctx.companyProxy.mexDomestic]
            ]),
            _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('loads-domestic-mex')), 1)
          ]),
          _createElementVNode("div", _hoisted_23, [
            _withDirectives(_createElementVNode("input", {
              id: "transferLoad",
              name: "transferLoad",
              type: "checkbox",
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.companyProxy.transferLoad) = $event)),
              onChange: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
              class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            }, null, 544), [
              [_vModelCheckbox, _ctx.companyProxy.transferLoad]
            ]),
            _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('transfer-loads')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.user?.company?.type === 'CARRIER' || _ctx.user?.company?.type === 'BROKER')
      ? (_openBlock(), _createElementBlock("h3", _hoisted_25, _toDisplayString(_ctx.$t('special-permits')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        (!_ctx.isEuropeCompany())
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _createElementVNode("input", {
                id: "alcoholic-beverages",
                name: "alcoholicBeverages",
                type: "checkbox",
                checked: _ctx.specialPermitsProxy.hasTransportAndDeliveryOfAlcoholicBeveragesPermit,
                onInput: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
                onClick: _cache[13] || (_cache[13] = ($event: any) => (
            _ctx.specialPermitsProxy.hasTransportAndDeliveryOfAlcoholicBeveragesPermit =
              !_ctx.specialPermitsProxy.hasTransportAndDeliveryOfAlcoholicBeveragesPermit
          )),
                class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              }, null, 40, _hoisted_29),
              _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t('alcoholic-beverages')), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ErrorMessage, {
          name: "alcoholicBeverages",
          class: "text-rose-500"
        }),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("input", {
            id: "hazardous-materials",
            name: "hazardous-materials",
            type: "checkbox",
            checked: _ctx.specialPermitsProxy.hasHazardousMaterialPermit,
            onInput: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
            onClick: _cache[15] || (_cache[15] = ($event: any) => (
            _ctx.specialPermitsProxy.hasHazardousMaterialPermit =
              !_ctx.specialPermitsProxy.hasHazardousMaterialPermit
          )),
            class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          }, null, 40, _hoisted_32),
          _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('hazardous-materials')), 1)
        ]),
        _createVNode(_component_ErrorMessage, {
          name: "hazardous-materials",
          class: "text-rose-500"
        }),
        (_ctx.isEuropeCompany())
          ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
              _createElementVNode("input", {
                id: "tir",
                name: "tir",
                type: "checkbox",
                checked: _ctx.specialPermitsProxy.hasTirPermit,
                onInput: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
                onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.specialPermitsProxy.hasTirPermit = !_ctx.specialPermitsProxy.hasTirPermit)),
                class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              }, null, 40, _hoisted_35),
              _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t('tir')), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ErrorMessage, {
          name: "tir",
          class: "text-rose-500"
        }),
        (!_ctx.isEuropeCompany())
          ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
              _createElementVNode("input", {
                id: "TWIC-card",
                name: "TWICcard",
                type: "checkbox",
                checked: _ctx.specialPermitsProxy.hasTWICCard,
                onInput: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
                onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.specialPermitsProxy.hasTWICCard = !_ctx.specialPermitsProxy.hasTWICCard)),
                class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              }, null, 40, _hoisted_38),
              _createElementVNode("label", _hoisted_39, _toDisplayString(_ctx.$t('twic-card')), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ErrorMessage, {
          name: "TWICcard",
          class: "text-rose-500"
        }),
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("input", {
            id: "has-oversize",
            name: "has-oversize",
            type: "checkbox",
            checked: _ctx.specialPermitsProxy.hasOversizeLoadPermit,
            onInput: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
            onClick: _cache[21] || (_cache[21] = ($event: any) => (
            _ctx.specialPermitsProxy.hasOversizeLoadPermit = !_ctx.specialPermitsProxy.hasOversizeLoadPermit
          )),
            class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          }, null, 40, _hoisted_41),
          _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t('has-oversize')), 1)
        ]),
        _createVNode(_component_ErrorMessage, {
          name: "has-oversize",
          class: "text-rose-500"
        }),
        _createElementVNode("div", _hoisted_43, [
          _createElementVNode("input", {
            id: "hasMovePartialPermit",
            name: "hasMovePartialPermit",
            type: "checkbox",
            checked: _ctx.specialPermitsProxy.hasMovePartialPermit,
            onInput: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
            onClick: _cache[23] || (_cache[23] = ($event: any) => (
            _ctx.specialPermitsProxy.hasMovePartialPermit = !_ctx.specialPermitsProxy.hasMovePartialPermit
          )),
            class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          }, null, 40, _hoisted_44),
          _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t('move-partial-permit')), 1)
        ]),
        _createVNode(_component_ErrorMessage, {
          name: "hasMovePartialPermit",
          class: "text-rose-500"
        }),
        _createElementVNode("div", _hoisted_46, [
          _createElementVNode("input", {
            id: "hasMoveFullTruckPermit",
            name: "hasMoveFullTruckPermit",
            type: "checkbox",
            checked: _ctx.specialPermitsProxy.hasMoveFullTruckPermit,
            onInput: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
            onClick: _cache[25] || (_cache[25] = ($event: any) => (
            _ctx.specialPermitsProxy.hasMoveFullTruckPermit = !_ctx.specialPermitsProxy.hasMoveFullTruckPermit
          )),
            class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          }, null, 40, _hoisted_47),
          _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t('move-full-track-permit')), 1)
        ]),
        _createVNode(_component_ErrorMessage, {
          name: "hasMoveFullTruckPermit",
          class: "text-rose-500"
        }),
        (!_ctx.isEuropeCompany())
          ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
              _createElementVNode("input", {
                id: "TSA-card",
                name: "TSAcard",
                type: "checkbox",
                checked: _ctx.specialPermitsProxy.hasTSA,
                onInput: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
                onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.specialPermitsProxy.hasTSA = !_ctx.specialPermitsProxy.hasTSA)),
                class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              }, null, 40, _hoisted_50),
              _createElementVNode("label", _hoisted_51, _toDisplayString(_ctx.$t('tsa')), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ErrorMessage, {
          name: "TSAcard",
          class: "text-rose-500"
        })
      ]),
      _createElementVNode("div", _hoisted_52, [
        (!_ctx.isEuropeCompany())
          ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
              _createElementVNode("input", {
                id: "overweight",
                name: "overweight",
                type: "checkbox",
                checked: _ctx.specialPermitsProxy.hasOverweightLoadPermit,
                onClick: _cache[28] || (_cache[28] = ($event: any) => (
            _ctx.specialPermitsProxy.hasOverweightLoadPermit =
              !_ctx.specialPermitsProxy.hasOverweightLoadPermit
          )),
                onInput: _cache[29] || (_cache[29] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
                class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              }, null, 40, _hoisted_54),
              _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.$t('overweight')), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ErrorMessage, {
          name: "overweight",
          class: "text-rose-500"
        }),
        _createElementVNode("div", _hoisted_56, [
          _createElementVNode("input", {
            id: "drivers-team",
            name: "driversTeam",
            type: "checkbox",
            checked: _ctx.specialPermitsProxy.hasTeamDriverPermit,
            onInput: _cache[30] || (_cache[30] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
            onClick: _cache[31] || (_cache[31] = ($event: any) => (
            _ctx.specialPermitsProxy.hasTeamDriverPermit = !_ctx.specialPermitsProxy.hasTeamDriverPermit
          )),
            class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          }, null, 40, _hoisted_57),
          _createElementVNode("label", _hoisted_58, _toDisplayString(_ctx.$t('drivers-team')), 1)
        ]),
        _createVNode(_component_ErrorMessage, {
          name: "driversTeam",
          class: "text-rose-500"
        }),
        (!_ctx.isEuropeCompany())
          ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
              _createElementVNode("input", {
                id: "bond-permit",
                name: "bondPermit",
                type: "checkbox",
                onInput: _cache[32] || (_cache[32] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
                checked: _ctx.specialPermits.hasBondPermit,
                onClick: _cache[33] || (_cache[33] = ($event: any) => (_ctx.specialPermitsProxy.hasBondPermit = !_ctx.specialPermitsProxy.hasBondPermit)),
                class: "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              }, null, 40, _hoisted_60),
              _createElementVNode("label", _hoisted_61, _toDisplayString(_ctx.$t('bond-permit')), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ErrorMessage, {
          name: "bondPermit",
          class: "text-rose-500"
        })
      ])
    ])
  ], 64))
}