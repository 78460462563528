
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import { defineComponent, reactive, toRefs, computed } from 'vue';
import { ActionContext, useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import PlansNewLoadHeader from './_components/PlansNewLoadHeader.vue';
import PlanStepsTab from './_components/PlanStepsTab.vue';
import PlanShipmentData from './steps/PlanShipmentData.vue';
import PlanQuoteStep from '@/modules/planning/newLoad/steps/PlanQuoteStep.vue';

interface newLoad {
  deleteItem: any;
  currentView: string;
  loadTemplate: object;
}

export default defineComponent({
  name: 'NewLoadCrossBorder',
  components: {
    PlanDefaultView,
    PlansNewLoadHeader,
    PlanShipmentData,
    PlanStepsTab,
    PlanQuoteStep,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const data = reactive<newLoad>({
      deleteItem: null,
      currentView: 'shipment',
      loadTemplate: {
        observations: '',
        poNumber: '',
        stops: [
          {
            type: 'PICKUP_INITIAL',
            executionDate: '2024-08-11',
            location: {
              lat: 37.7030051,
              long: -85.8647201,
              zip: '42701',
              city: 'Elizabethtown',
              googlePlaceId: 'ChIJ28mWXr7oaIgRqDn6wy6K16g',
              address: '',
            },
            portStop: false,
            airportStop: false,
            observations: '',
            deliveryStop: null,
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            multiStopIdentifier: '',
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            defaultCountrySelected: {
              id: 1,
              name: 'United States',
              iso: 'US',
              contextUse: ['COMPANY_COUNTRY'],
              phonePrefix: '1',
              region: {
                id: 1,
                name: 'NORTH_AMERICA',
              },
            },
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
          {
            type: 'DELIVERY_FINAL',
            executionDate: '2024-08-16',
            location: {
              lat: 38.9120932,
              long: -94.3773126,
              zip: '64002',
              city: "Lee's Summit",
              googlePlaceId: 'ChIJoc7sS_MfwYcR3AYp9BuHWcM',
              address: '',
            },
            portStop: false,
            airportStop: false,
            multiStopIdentifier: '',
            observations: '',
            lumperFeePrice: 0,
            multiStopIdentifierArray: [],
            lumperFee: false,
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            deliveryStop: null,
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            defaultCountrySelected: {
              id: 1,
              name: 'United States',
              iso: 'US',
              contextUse: ['COMPANY_COUNTRY'],
              phonePrefix: '1',
              region: {
                id: 1,
                name: 'NORTH_AMERICA',
              },
            },
            poNumber: '',
            contact: '',
            warehouseTemplate: null,
            commodities: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
          },
        ],
        commodityMaster: {
          id: null,
          nPallets: 4,
          packageType: {
            id: 3,
          },
          weight: 2100,
          commodityName: 'bikes',
        },
        status: 'DRAFT',
        trailerType: {
          id: 1,
        },
        commodityMasters: [
          {
            id: null,
            nPallets: 4,
            packageType: {
              id: 3,
            },
            weight: 2100,
            commodityName: 'bikes',
          },
        ],
        specialRequirement: {
          teamDriver: false,
          bondedPermission: false,
          TWICCard: false,
          tsa: false,
          hazardousLoad: false,
          specialPermissions: false,
          specialPermissionsDescription: '',
          tir: false,
        },
        legs: [
          {
            observations: '',
            poNumber: '',
            stops: [
              {
                type: 'PICKUP_INITIAL',
                executionDate: '2024-08-11',
                location: {
                  lat: 37.7030051,
                  long: -85.8647201,
                  zip: '42701',
                  city: 'Elizabethtown',
                  googlePlaceId: 'ChIJ28mWXr7oaIgRqDn6wy6K16g',
                  address: '',
                },
                portStop: false,
                airportStop: false,
                observations: '',
                deliveryStop: null,
                lumperFeePrice: 0,
                multiStopIdentifierArray: [],
                lumperFee: false,
                multiStopIdentifier: '',
                executionWindowEndTime: null,
                executionWindowStartTime: null,
                companyName: '',
                address: '',
                phoneNumber: '',
                phonePrefix: '',
                defaultCountrySelected: {
                  id: 1,
                  name: 'United States',
                  iso: 'US',
                  contextUse: ['COMPANY_COUNTRY'],
                  phonePrefix: '1',
                  region: {
                    id: 1,
                    name: 'NORTH_AMERICA',
                  },
                },
                poNumber: '',
                contact: '',
                warehouseTemplate: null,
                commodities: [
                  {
                    id: null,
                    nPallets: 4,
                    packageType: {
                      id: 3,
                    },
                    weight: 2100,
                    commodityName: 'bikes',
                  },
                ],
              },
              {
                type: 'DELIVERY_FINAL',
                executionDate: '2024-08-16',
                location: {
                  lat: 38.9120932,
                  long: -94.3773126,
                  zip: '64002',
                  city: "Lee's Summit",
                  googlePlaceId: 'ChIJoc7sS_MfwYcR3AYp9BuHWcM',
                  address: '',
                },
                portStop: false,
                airportStop: false,
                multiStopIdentifier: '',
                observations: '',
                lumperFeePrice: 0,
                multiStopIdentifierArray: [],
                lumperFee: false,
                executionWindowEndTime: null,
                executionWindowStartTime: null,
                companyName: '',
                deliveryStop: null,
                address: '',
                phoneNumber: '',
                phonePrefix: '',
                defaultCountrySelected: {
                  id: 1,
                  name: 'United States',
                  iso: 'US',
                  contextUse: ['COMPANY_COUNTRY'],
                  phonePrefix: '1',
                  region: {
                    id: 1,
                    name: 'NORTH_AMERICA',
                  },
                },
                poNumber: '',
                contact: '',
                warehouseTemplate: null,
                commodities: [
                  {
                    id: null,
                    nPallets: 4,
                    packageType: {
                      id: 3,
                    },
                    weight: 2100,
                    commodityName: 'bikes',
                  },
                ],
              },
            ],
            commodityMaster: {
              id: null,
              nPallets: 4,
              packageType: {
                id: 3,
              },
              weight: 2100,
              commodityName: 'bikes',
            },
            status: 'DRAFT',
            trailerType: {
              id: 1,
            },
            commodityMasters: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
            specialRequirement: {
              teamDriver: false,
              bondedPermission: false,
              TWICCard: false,
              tsa: false,
              hazardousLoad: false,
              specialPermissions: false,
              specialPermissionsDescription: '',
              tir: false,
            },
            legOrder: 1,
          },
          {
            observations: '',
            poNumber: '',
            stops: [
              {
                type: 'PICKUP_INITIAL',
                executionDate: '2024-08-11',
                location: {
                  lat: 37.7030051,
                  long: -85.8647201,
                  zip: '42702',
                  city: 'Elizabethtown',
                  googlePlaceId: 'ChIJ28mWXr7oaIgRqDn6wy6K16g',
                  address: '',
                },
                portStop: false,
                airportStop: false,
                observations: '',
                deliveryStop: null,
                lumperFeePrice: 0,
                multiStopIdentifierArray: [],
                lumperFee: false,
                multiStopIdentifier: '',
                executionWindowEndTime: null,
                executionWindowStartTime: null,
                companyName: '',
                address: '',
                phoneNumber: '',
                phonePrefix: '',
                defaultCountrySelected: {
                  id: 1,
                  name: 'United States',
                  iso: 'US',
                  contextUse: ['COMPANY_COUNTRY'],
                  phonePrefix: '1',
                  region: {
                    id: 1,
                    name: 'NORTH_AMERICA',
                  },
                },
                poNumber: '',
                contact: '',
                warehouseTemplate: null,
                commodities: [
                  {
                    id: null,
                    nPallets: 4,
                    packageType: {
                      id: 3,
                    },
                    weight: 2100,
                    commodityName: 'bikes',
                  },
                ],
              },
              {
                type: 'DELIVERY_FINAL',
                executionDate: '2024-08-16',
                location: {
                  lat: 38.9120932,
                  long: -94.3773126,
                  zip: '64002',
                  city: "Lee's Summit",
                  googlePlaceId: 'ChIJoc7sS_MfwYcR3AYp9BuHWcM',
                  address: '',
                },
                portStop: false,
                airportStop: false,
                multiStopIdentifier: '',
                observations: '',
                lumperFeePrice: 0,
                multiStopIdentifierArray: [],
                lumperFee: false,
                executionWindowEndTime: null,
                executionWindowStartTime: null,
                companyName: '',
                deliveryStop: null,
                address: '',
                phoneNumber: '',
                phonePrefix: '',
                defaultCountrySelected: {
                  id: 1,
                  name: 'United States',
                  iso: 'US',
                  contextUse: ['COMPANY_COUNTRY'],
                  phonePrefix: '1',
                  region: {
                    id: 1,
                    name: 'NORTH_AMERICA',
                  },
                },
                poNumber: '',
                contact: '',
                warehouseTemplate: null,
                commodities: [
                  {
                    id: null,
                    nPallets: 4,
                    packageType: {
                      id: 3,
                    },
                    weight: 2100,
                    commodityName: 'bikes',
                  },
                ],
              },
            ],
            commodityMaster: {
              id: null,
              nPallets: 4,
              packageType: {
                id: 3,
              },
              weight: 2100,
              commodityName: 'bikes',
            },
            status: 'DRAFT',
            trailerType: {
              id: 1,
            },
            commodityMasters: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
            specialRequirement: {
              teamDriver: false,
              bondedPermission: false,
              TWICCard: false,
              tsa: false,
              hazardousLoad: false,
              specialPermissions: false,
              specialPermissionsDescription: '',
              tir: false,
            },
            legOrder: 2,
          },
          {
            observations: '',
            poNumber: '',
            stops: [
              {
                type: 'PICKUP_INITIAL',
                executionDate: '2024-08-11',
                location: {
                  lat: 37.7030051,
                  long: -85.8647201,
                  zip: '42702',
                  city: 'Elizabethtown',
                  googlePlaceId: 'ChIJ28mWXr7oaIgRqDn6wy6K16g',
                  address: '',
                },
                portStop: false,
                airportStop: false,
                observations: '',
                deliveryStop: null,
                lumperFeePrice: 0,
                multiStopIdentifierArray: [],
                lumperFee: false,
                multiStopIdentifier: '',
                executionWindowEndTime: null,
                executionWindowStartTime: null,
                companyName: '',
                address: '',
                phoneNumber: '',
                phonePrefix: '',
                defaultCountrySelected: {
                  id: 1,
                  name: 'United States',
                  iso: 'US',
                  contextUse: ['COMPANY_COUNTRY'],
                  phonePrefix: '1',
                  region: {
                    id: 1,
                    name: 'NORTH_AMERICA',
                  },
                },
                poNumber: '',
                contact: '',
                warehouseTemplate: null,
                commodities: [
                  {
                    id: null,
                    nPallets: 4,
                    packageType: {
                      id: 3,
                    },
                    weight: 2100,
                    commodityName: 'bikes',
                  },
                ],
              },
              {
                type: 'DELIVERY_FINAL',
                executionDate: '2024-08-16',
                location: {
                  lat: 38.9120932,
                  long: -94.3773126,
                  zip: '64002',
                  city: "Lee's Summit",
                  googlePlaceId: 'ChIJoc7sS_MfwYcR3AYp9BuHWcM',
                  address: '',
                },
                portStop: false,
                airportStop: false,
                multiStopIdentifier: '',
                observations: '',
                lumperFeePrice: 0,
                multiStopIdentifierArray: [],
                lumperFee: false,
                executionWindowEndTime: null,
                executionWindowStartTime: null,
                companyName: '',
                deliveryStop: null,
                address: '',
                phoneNumber: '',
                phonePrefix: '',
                defaultCountrySelected: {
                  id: 1,
                  name: 'United States',
                  iso: 'US',
                  contextUse: ['COMPANY_COUNTRY'],
                  phonePrefix: '1',
                  region: {
                    id: 1,
                    name: 'NORTH_AMERICA',
                  },
                },
                poNumber: '',
                contact: '',
                warehouseTemplate: null,
                commodities: [
                  {
                    id: null,
                    nPallets: 4,
                    packageType: {
                      id: 3,
                    },
                    weight: 2100,
                    commodityName: 'bikes',
                  },
                ],
              },
            ],
            commodityMaster: {
              id: null,
              nPallets: 4,
              packageType: {
                id: 3,
              },
              weight: 2100,
              commodityName: 'bikes',
            },
            status: 'DRAFT',
            trailerType: {
              id: 1,
            },
            commodityMasters: [
              {
                id: null,
                nPallets: 4,
                packageType: {
                  id: 3,
                },
                weight: 2100,
                commodityName: 'bikes',
              },
            ],
            specialRequirement: {
              teamDriver: false,
              bondedPermission: false,
              TWICCard: false,
              tsa: false,
              hazardousLoad: false,
              specialPermissions: false,
              specialPermissionsDescription: '',
              tir: false,
            },
            legOrder: 3,
          },
        ],
      },
    });

    const headers = computed(() => {
      return [
        { label: t('warehouse'), value: 'companyWarehouse', sortable: true },
        { label: t('address'), value: 'address' },
        { label: t('city'), value: 'zip', sortable: true },
        { label: t('country'), value: 'country', sortable: true },
        { label: t('contact-person'), value: 'contactName' },
        { label: t('phone'), value: 'phone' },
        { label: '', value: 'actions' },
      ];
    });

    // const crossBorderLoad = computed(()=> {})

    const handleChangeView = (view) => {
      data.currentView = view;
    };

    return {
      ...toRefs(data),
      handleChangeView,
    };
  },
});
