
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { StarIcon } from '@heroicons/vue/24/solid';
import Datepicker from '@vuepic/vue-datepicker';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';

interface User {
  id: number;
  name: string;
  company: string;
  owner: {
    name: string;
  };
  stats: {
    amountValorations: number;
    shipmentAmount: number;
    averageShipmentPrice: number;
  };
  source: string;
  LaneAssignedShipmentsFinishedCount: number;
  LaneAverageShipmentPrice: number;
}

export default defineComponent({
  name: 'RequestForQuote',
  components: {
    AddCarrierModal,
    Datepicker,
    ErrorMessage,
    Field,
    Form,
    StarIcon,
  },
  mixins: [BaseComponent],
  data() {
    return {
      isLoading: true,
      selectedContacts: [] as any,
      dateFormat: 'MM-dd-yyyy',
      showFilters: false,
      allowed: false,
      showSpinner: false,
      isValidating: false,
      modalVisible: false,
      useContactPage: 1,
      userType: 'CARRIER|BROKER',
      title: '',
      days: null as any,
      date: null as any,
      name: '',
      searchByCompanyName: '',
      searchByRating: '',
      searchByLoadsQuantity: '',
      searchByAverageBid: '',
      isSortByName: false,
      isSortByRating: false,
      isSortByQuantity: false,
      isSortByAverage: false,
      carrierAdded: {} as User,
      searchTimeout: null as any,
    };
  },
  beforeCreate() {
    this.$store.dispatch('LoadsStore/trailers');
  },
  created() {
    this.findUser('');
  },
  mounted() {
    this.title = this.$store.getters['LanesStore/getLanesTitle'];
    if (this.getFiltersLanes) {
      this.$store.dispatch('LanesStore/fetchLanes', { ...this.getFiltersLanes });
    } else {
      this.$router.push('/planning/lanesRFQ');
    }
  },
  computed: {
    currentContacts(): any {
      return this.$store.getters['UserStore/carrierContactList'].filter(
        (item) => item.source !== 'ALPINE_CARRIER_API',
      );
    },
    getFiltersLanes(): any {
      return this.$store.getters['LanesStore/getFiltersLanes'];
    },
    lanesList(): any {
      return this.$store.getters['LanesStore/getLanes'];
    },
    paginationMaxItems(): number {
      return (
        +this.$store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 0
      );
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
  },
  methods: {
    addSelectedContact(id: number) {
      let index = this.selectedContacts.map((e) => e.id).indexOf(id);
      if (index > -1) {
        this.selectedContacts.splice(index, 1);
      } else {
        this.selectedContacts.push({ id: id });
      }
    },
    dateDifference(type) {
      const currentDate = new Date();
      if (type === 'day') {
        if (this.days) {
          this.date = new Date(currentDate.setDate(currentDate.getDate() + this.days));
        }
      } else {
        if (this.date) {
          const timeDiff = this.date.getTime() - currentDate.getTime();
          this.days = Math.ceil(timeDiff / (1000 * 3600 * 24));
        }
      }
    },
    findUser(newValue: string) {
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.$store
          .dispatch('UserStore/carrierContactList', {
            page: this.useContactPage,
            name: newValue,
            type: 'CARRIER|BROKER',
          })
          .then(() => {
            if (this.sourceCarrier().length > 0) {
              if (!this.isSelected(this.sourceCarrier()[0].id)) {
                this.addSelectedContact(this.sourceCarrier()[0].id);
              }
            }
            this.isLoading = false;
          })
          .finally(() => {
            this.allowed = true;
          });
      }, 1000);
    },
    hideModal(carrier: any) {
      if (carrier === 0) {
        this.modalVisible = !this.modalVisible;
      } else {
        this.carrierAdded = carrier.company;
        this.selectedContacts.push(carrier.company.id);
        this.modalVisible = !this.modalVisible;
      }
    },
    isSelected(id) {
      return this.selectedContacts.some((user) => user.id === id);
    },
    async lanesRFQRequestBids() {
      this.isValidating = true;
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid || this.selectedContacts.length === 0) {
        if (this.selectedContacts.length === 0) {
          BaseComponent.methods?.showToastError(this.$t('required-one-carrier'));
        }
        this.isValidating = false;
        return;
      }
      const lanes = this.lanesList.map((lane) => {
        const newObj = { ...lane };
        ['loadTemplate', 'loadsPerWeek'].forEach((key) => delete newObj[key]);
        return newObj;
      });
      this.$store
        .dispatch('LanesStore/lanesRFQRequestBids', {
          companies: this.selectedContacts,
          lanes: lanes,
          title: this.title,
          quotationDateLimitTZ: this.date,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
          this.$store.commit('LanesStore/setShowModalStarted', true);
          this.$router.push('/planning/lanesRFQ');
        })
        .finally(() => {
          this.isValidating = false;
        });
    },
    loadMoreContacts() {
      this.showSpinner = true;
      this.useContactPage += 1;
      this.findUser(this.name);
    },
    searchCompany() {
      this.useContactPage = 1;
      if (this.name.length > 2) {
        this.findUser(this.name);
      } else {
        this.findUser('');
      }
    },
    selectAll(isChecked: boolean) {
      if (isChecked) {
        let newIds = this.currentContacts
          .map((item) => {
            if (!this.isSelected(item.id)) return item.id;
          })
          .filter((id) => id !== undefined);
        this.selectedContacts = [...this.selectedContacts, ...newIds] as Array<number>;
      } else if (this.selectedContacts.length) {
        this.selectedContacts = [];
        if (!this.selectedContacts.some((item) => item === this.sourceCarrier()[0].id)) {
          this.selectedContacts.push(this.sourceCarrier()[0].id);
        }
      }
    },
    setCompanySearch(company) {
      this.$store.commit('UserStore/unsiftCarrierContactList', company);
    },
    sourceCarrier() {
      let source: any = this.currentContacts.filter(
        (item: any) => item.source === 'CARGOBOT' || item.source === 'CARGOBOT_EU',
      );
      return source;
    },
    updateSearchByAverageBid() {
      this.carrierAdded = {} as User;

      this.isSortByName = false;
      this.isSortByRating = false;
      this.isSortByQuantity = false;
      this.isSortByAverage = true;

      if (this.searchByAverageBid === '') {
        this.searchByAverageBid = 'DESC';
      } else {
        if (this.searchByAverageBid === 'DESC') {
          this.searchByAverageBid = 'ASC';
        } else {
          this.searchByAverageBid = '';
        }
      }
    },
    updateSearchByCompanyName() {
      this.carrierAdded = {} as User;

      this.isSortByName = true;
      this.isSortByRating = false;
      this.isSortByQuantity = false;
      this.isSortByAverage = false;

      if (this.searchByCompanyName === '') {
        this.searchByCompanyName = 'DESC';
      } else {
        if (this.searchByCompanyName === 'DESC') {
          this.searchByCompanyName = 'ASC';
        } else {
          this.searchByCompanyName = '';
        }
      }
    },
    updateSearchByLoadsQuantity() {
      this.carrierAdded = {} as User;

      this.isSortByName = false;
      this.isSortByRating = false;
      this.isSortByQuantity = true;
      this.isSortByAverage = false;

      if (this.searchByLoadsQuantity === '') {
        this.searchByLoadsQuantity = 'DESC';
      } else {
        if (this.searchByLoadsQuantity === 'DESC') {
          this.searchByLoadsQuantity = 'ASC';
        } else {
          this.searchByLoadsQuantity = '';
        }
      }
    },
    updateSearchByRating() {
      this.carrierAdded = {} as User;

      this.isSortByName = false;
      this.isSortByRating = true;
      this.isSortByQuantity = false;
      this.isSortByAverage = false;

      if (this.searchByRating === '') {
        this.searchByRating = 'DESC';
      } else {
        if (this.searchByRating === 'DESC') {
          this.searchByRating = 'ASC';
        } else {
          this.searchByRating = '';
        }
      }
    },
  },
});
