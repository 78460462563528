export default {
  getAdminAgents(state: any): object {
    return state.adminAgents;
  },
  getCompany(state: any): object {
    return state.company;
  },
  getCompanyById(state: any): object {
    return state.companyById;
  },
  updatingInsurances(state: any): object {
    return state.updatingInsurances;
  },
  getDriversList(state: any): any {
    return state.driversList;
  },
  getContactsBlastEmail(state: any): any {
    return state.contactsBlastEmail;
  },
  getSelectedTemplate (state: any): any {
    return state.selectedTemplate
  },
  getSelectedTemplateCategory (state: any): any {
    return state.selectedTemplateCategory
  },
  getSearchCompany (state: any) {
    return state.searchCompany
  },
  getSelectedEmailToSend (state: any): any {
    return state.selectedEmailToSend
  },
  getSelectedTemplateToSend (state: any): any {
    return state.selectedTemplateToSend
  },
  getTemplateToClone (state: any) {
    return state.templateToClone
  },
  getVehiclesList(state: any): object {
    return state.vehiclesList;
  },
};
