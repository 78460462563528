
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { XCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import cloneDeep from 'lodash/cloneDeep';
// import TimePicker from 'vue3-timepicker';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import PlanInputTime from '@/components/forms/PlanInputTime.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins';
import StopData from '@/modules/planning/newLoad/_components/form/StopData.vue';

// Utils
import { locationString, locationObject } from '@/utils/formatLocation';
import { hourRange, minutesRange } from '@/utils/formatTime';

export default defineComponent({
  name: 'StopsSidebar',
  components: {
    ErrorMessage,
    Field,
    Form,
    GoogleAutocomplete,
    InputAmericanFormat,
    ModalConfirm,
    // TimePicker,
    XCircleIcon,
    StopData,
    XMarkIcon,
    PlanInputTime,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    stops: {
      type: Array,
      default: () => [{}] as any,
    },
  },
  data() {
    return {
      toggleSimpleModal: false,
      dateFormat: 'MM-dd-yyyy',
      notSaved: false,
      isStopValidating: [] as any,
      arrayStopSaved: [] as any,
      showTime: true,
      additionalStops: [
        {
          place: '',
          location: {
            lat: 0,
            long: 0,
            zip: 0,
            city: '',
            state: { id: 1 },
          },
          appoiment: 'NONE',
          executionWindowStartTime: null,
          executionWindowEndTime: null,
          type: 'PICKUP',
          portStop: false,
          airportStop: false,
          observations: '',
          initialObservations: '',
          lumperFeePrice: null as null | number,
          lumperFee: false,
        },
      ],
    };
  },
  mounted() {
    if (this.additionalStopsCurrentLane && this.additionalStopsCurrentLane.length > 0) {
      (this.additionalStops as any) = cloneDeep(this.additionalStopsCurrentLane);
      this.arrayStopSaved = [];
      this.additionalStops.forEach((element) => {
        this.arrayStopSaved.push(true);
      });
    }
  },
  computed: {
    additionalStopsCurrentLane(): any {
      return this.stops;
    },
    destiny(): any {
      if (this.stops && this.stops.length > 0) {
        return this.stops.find((obj: any) => obj.type === 'DELIVERY_FINAL');
      }
      return null;
    },
    hourRange(): any {
      return hourRange;
    },
    isStopSaved(): any {
      this.additionalStops.forEach((stop, i) => {
        if (stop.location.city === '') {
          this.arrayStopSaved[i] = false;
        } else {
          this.arrayStopSaved[i] = true;
        }
      });
      return this.arrayStopSaved;
    },
    locationObject(): any {
      return locationObject;
    },
    locationString(): any {
      return locationString;
    },
    minutesRange(): any {
      return minutesRange;
    },
    origin(): any {
      if (this.stops && this.stops.length > 0) {
        return this.stops.find((obj: any) => obj.type === 'PICKUP_INITIAL');
      }
      return null;
    },
  },
  methods: {
    updateWarehouse(
      stop,
      address,
      companyName,
      lumperFee,
      lumperFeePrice,
      observations,
      poNumber,
      phoneNumber,
      phonePrefix,
      contactName,
      warehouseId,
      executionWindowStartTime,
      executionWindowEndTime,
    ) {
      stop.companyName = companyName;
      stop.location.address = address;
      stop.lumperFee = lumperFee;
      stop.lumperFeePrice = lumperFeePrice;
      stop.observations = observations;
      stop.poNumber = poNumber;
      stop.phoneNumber = phoneNumber;
      stop.phonePrefix = phonePrefix;
      stop.contact = contactName;
      stop.executionWindowStartTime = executionWindowStartTime;
      stop.executionWindowEndTime = executionWindowEndTime;
      warehouseId !== null
        ? (stop.warehouseTemplate = { id: warehouseId })
        : (stop.warehouseTemplate = null);
    },
    updateHour(stop, startTime, endTime, selectedAppointment) {
      if (selectedAppointment !== 'NONE') {
        if (selectedAppointment === 'APPT') {
          stop.executionWindowStartTime = startTime ? startTime : null;
        } else {
          stop.executionWindowStartTime = startTime ? startTime : null;
          stop.executionWindowEndTime = endTime ? endTime : null;
        }
      } else {
        stop.executionWindowStartTime = null;
        stop.executionWindowEndTime = null;
      }
    },
    updateLumperFee(stop, isLumperFee, price) {
      if (isLumperFee) {
        stop.lumperFeePrice = price;
      } else {
        stop.lumperFeePrice = null;
      }
    },
    updateObservations(event, stop) {
      stop.observations = event.target.innerText;
    },
    updatePoNumber(event, stop) {
      stop.poNumber = event.target.innerText;
    },
    setStopLocation(stop, addressData) {
      stop.place = addressData.location;
      stop.location = this.locationObject(addressData);
      stop.location = { ...stop.location, state: { country: stop.defaultCountrySelected } };
    },
    countrySelected(country, stop) {
      stop.defaultCountrySelected = country;
    },
    addAdditionalStop() {
      this.additionalStops.splice(this.additionalStops.length - 1, 0, {
        place: '',
        appoiment: 'NONE',
        executionWindowStartTime: null,
        executionWindowEndTime: null,
        location: {
          lat: 0,
          long: 0,
          zip: 0,
          city: '',
          state: { id: 1 },
        },
        type: 'PICKUP',
        portStop: false,
        airportStop: false,
        observations: '',
        initialObservations: '',
        lumperFeePrice: null as null | number,
        lumperFee: false,
      });
    },
    clearInput(data: any) {
      data.place = '';
      data.location.lat = 0;
      data.location.long = 0;
      data.location.zip = 0;
      data.location.city = '';
      data.location.state.id = 1;
    },
    closeSidebar() {
      const notSaved = this.isStopSaved.some((stop) => stop === false);
      if (notSaved) {
        this.toggleSimpleModal = true;
      } else {
        this.$emit('close', false);
      }
    },
    getStop(addressData, place, id, index) {
      if (addressData.postal_code) {
        this.additionalStops[index].place = this.locationString(addressData) as any;
        this.additionalStops[index].location = this.locationObject(addressData) as any;
      } else {
        (this.$refs[id] as any)[0].clear();
      }
    },
    portOrAirport(pick, stop) {
      if (pick === 'port') {
        stop.airportStop = false;
        stop.portStop = !stop.portStop;
      }
      if (pick === 'airport') {
        stop.portStop = false;
        stop.airportStop = !stop.airportStop;
      }
    },
    removeAdditionalStop(index) {
      // Delete additional stops
      this.additionalStops.splice(index, 1);
      if (this.isStopSaved[index]) {
        // Delete from the saves stops
        this.isStopSaved.splice(index, 1);
        this.$emit('removeStop', index + 2);
      }
    },
    async saveAllStops() {
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid) {
        return;
      }
      if (this.additionalStops && this.additionalStops.length) {
        this.$emit('addStops', this.additionalStops);
      }
      this.$emit('close', false);
    },
    saveChanges(confirm) {
      this.toggleSimpleModal = false;
      if (confirm) {
        this.saveAllStops();
      } else {
        this.$emit('close', false);
      }
    },
    saveStop(index: number) {
      if (this.additionalStops[index]?.location?.city !== '') {
        this.isStopSaved[index] = true;
        this.isStopValidating[index] = false;
        this.$emit('addStop', this.additionalStops[index]);
      } else {
        this.isStopValidating[index] = true;
      }
    },
    updateHourStops(item) {
      if (item.executionWindowStartTime > item.executionWindowEndTime) {
        item.executionWindowEndTime = item.executionWindowStartTime;
        this.showTime = false;
        setTimeout(() => {
          this.showTime = true;
        }, 1);
      }
    },
    // updateObservations(event, data, stop) {
    //   if (data === 'stop') {
    //     stop.observations = event.target.innerText;
    //     stop.initialObservations = event.target.innerText;
    //   }
    // },
  },
  watch: {
    additionalStops: {
      handler(newVal, oldVal) {
        // Find the index of the changed object in the array
        this.additionalStops.forEach((stop, index) => {
          if (
            this.additionalStopsCurrentLane &&
            index <= this.additionalStopsCurrentLane.length - 1
          ) {
            const newObjStr = JSON.stringify(stop);
            const oldObjStr = JSON.stringify(this.additionalStopsCurrentLane[index]);
            this.arrayStopSaved[index] = newObjStr === oldObjStr;
          }
        });
      },
      deep: true,
    },
  },
});
