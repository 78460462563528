
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanInputLocation from '@/components/forms/PlanInputLocation.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanSearchLocationByCoordinates from '@/components/modals/locations/PlanSearchLocationByCoordinatesModal.vue';
import PlanSearchLocationByWarehouseModal from '@/components/modals/locations/PlanSearchLocationByWarehouseModal.vue';
import { useStore } from 'vuex';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'PlanInputLoadLocation',
  components: {
    PlanButton,
    PlanDefaultModal,
    PlanSearchLocationByWarehouseModal,
    PlanSearchLocationByCoordinates,
    PlanTextButton,
    PlanInputLocation,
  },
  props: {
    blockCountry: { type: Boolean, required: false, default: false },
    oldMode: { type: Boolean, required: false, default: false },
    country: { type: String, required: false, default: '' },
    label: String,
    location: { type: String, required: false, default: '' },
    preLocation: { type: String, required: false, default: '' },
  },
  emits: ['addFacility', 'searchWarehouse', 'getLocationFormated', 'deleteWarehouse'],
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      showErrorCoordinatesModal: false,
      showSearchByCoordinatesModal: false,
      showSearchByWarehouseModal: false,
      preCountry: '',
      preLocation: '',
      preWarehouse: -1,
      plus_code: '',
    });

    const changeInput = (newValue: any, isUpdate: boolean, fromClick: boolean) => {
      if (newValue.postal_code === '' && data.plus_code !== '') {
        newValue.postal_code = data.plus_code;
      }

      data.preCountry = newValue.country;
      data.preLocation = newValue.zipSelected;

      emit('getLocationFormated', newValue);
      if (fromClick) {
        emit('deleteWarehouse');
      }

      if (data.preCountry.length > 0 && data.preLocation) searchWarehouse();
    };

    const countries = computed(() => {
      let result: Array<any> = [];
      const countries = store.getters['CountryStore/getCountries'];

      result = countries
        .filter((item) => item?.region?.name.includes(store.getters['UserStore/getRegion']))
        .map((obj) => obj.iso.toLowerCase());

      return result;
    });

    const getPlaceIdData = async (placeId: string) => {
      let preCountryValue = '';
      let preLocationValue = '';

      await store
        .dispatch('LoadsStore/getPlaceDetailsGoogle', { placeId: placeId })
        .then((response) => {
          response.data['address_components'].forEach((addressComponents: any) => {
            if (addressComponents.types.includes('country')) {
              preCountryValue = addressComponents['short_name'].toLowerCase();
            }
            if (addressComponents.types.includes('postal_code')) {
              preLocationValue = addressComponents['short_name'];
            }

            if (addressComponents.types.includes('plus_code')) {
              data.plus_code = addressComponents['short_name'];
              preLocationValue = response.data.formatted_address || addressComponents['short_name'];
            }
          });
        });

      if (countries.value.includes(preCountryValue)) {
        data.preCountry = preCountryValue;
        data.preLocation = preLocationValue;
        searchWarehouse();
      } else {
        data.showErrorCoordinatesModal = true;
      }
    };

    const getLocationByCoordinates = async (coordinatesData: any) => {
      await getPlaceIdData(coordinatesData['place_id']);
      data.preWarehouse = -1;
      data.showSearchByCoordinatesModal = false;
    };

    const getLocationByWarehouse = (warehouseData: any) => {
      data.showSearchByWarehouseModal = false;
      data.preLocation = warehouseData.location?.zip;
      data.preCountry = warehouseData.location?.state?.country?.iso;
      data.preWarehouse = warehouseData.id;
      searchWarehouse();
    };

    const searchWarehouse = () => {
      emit('searchWarehouse', data.preLocation, data.preCountry, data.preWarehouse);
    };

    const openAddFacility = () => {
      data.showSearchByWarehouseModal = false;
      emit('addFacility');
    };

    if (props.country.length > 0) data.preCountry = props.country + '';
    if (props.location.length > 0) data.preLocation = props.location + '';

    watch(
      () => props.country,
      (newValue, OldValue) => {
        data.preCountry = props.country + '';
      },
    );

    watch(
      () => props.location,
      (newValue, OldValue) => {
        data.preLocation = props.location + '';
      },
    );

    return {
      ...toRefs(data),
      changeInput,
      getLocationByCoordinates,
      getLocationByWarehouse,
      openAddFacility,
    };
  },
});
