import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row justify-between items-baseline border-b border-gray-200 cursor-pointer" }
const _hoisted_2 = { class: "flex items-center space-x-10" }
const _hoisted_3 = {
  key: 0,
  class: "flex mb-4 items-center"
}
const _hoisted_4 = {
  for: "default-checkbox",
  class: "ml-2 text-sm font-medium text-black"
}
const _hoisted_5 = { style: {"font-size":"15px","padding":"14px"} }
const _hoisted_6 = { style: {"white-space":"nowrap","display":"flex","color":"#9ca3c1"} }
const _hoisted_7 = { style: 'font-weight: 700 !important;color: black;margin-left: 10px;' }
const _hoisted_8 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_9 = ["value"]
const _hoisted_10 = {
  for: "default-checkbox",
  class: "ml-2 text-sm font-medium text-black"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_InformationCircleIcon = _resolveComponent("InformationCircleIcon")!
  const _component_Popper = _resolveComponent("Popper")!
  const _component_MinusSmallIcon = _resolveComponent("MinusSmallIcon")!
  const _component_PlusSmallIcon = _resolveComponent("PlusSmallIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", {
        class: _normalizeClass(["text-2xl 2xl:text-3xl font-bold flex items-center opacity-30 hover:opacity-70 whitespace-nowrap", { 'font-bold cursor-pointer !opacity-100': _ctx.currentView === _ctx.NewLoadStep.DATA }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickTab(_ctx.NewLoadStep.DATA)))
      }, [
        _createTextVNode(_toDisplayString(`1. ${_ctx.$t('shipment-data')}`) + " ", 1),
        (_ctx.load)
          ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
              key: 0,
              class: "h-8 w-8"
            }))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.isAdditionalStops)
        ? (_openBlock(), _createElementBlock("h3", {
            key: 0,
            class: _normalizeClass(["text-2xl 2xl:text-3xl font-bold flex items-center opacity-30 hover:opacity-70 whitespace-nowrap", {
          'cursor-not-allowed': !_ctx.isAllStopsFilled,
          'font-bold cursor-pointer !opacity-100': _ctx.currentView === _ctx.NewLoadStep.FREIGHT_INFO
        }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickTab(_ctx.NewLoadStep.FREIGHT_INFO)))
          }, [
            _createTextVNode(_toDisplayString(`2. ${_ctx.$t('freight-info')}`) + " ", 1),
            (_ctx.isAllStopsFilled)
              ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                  key: 0,
                  class: "h-8 w-8"
                }))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("h3", {
        class: _normalizeClass(["text-2xl 2xl:text-3xl font-bold flex items-center opacity-30 hover:opacity-70 whitespace-nowrap", {
          'cursor-not-allowed': !_ctx.load,
          'font-bold cursor-pointer !opacity-100': (_ctx.currentView !== _ctx.NewLoadStep.DATA && _ctx.currentView !== _ctx.NewLoadStep.FREIGHT_INFO),
        }]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickTab(_ctx.NewLoadStep.QUOTE)))
      }, [
        _createTextVNode(_toDisplayString(`${_ctx.isAdditionalStops ? '3' : '2'}. ${_ctx.currentView !== _ctx.NewLoadStep.CARRIER ? _ctx.$t('quote') : _ctx.$t('direct-assign')}`) + " ", 1),
        (_ctx.load && _ctx.currentView !== _ctx.NewLoadStep.DATA && _ctx.currentView !== _ctx.NewLoadStep.FREIGHT_INFO)
          ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
              key: 0,
              class: "h-8 w-8"
            }))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    (_ctx.currentView === _ctx.NewLoadStep.DATA)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("input", {
            id: "default-checkbox",
            type: "checkbox",
            class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2",
            value: "true",
            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateMultipleLoad(0, ($event.target as any).checked)))
          }, null, 32),
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.allowMultipleLoad ? _ctx.$t('multiple') : _ctx.$t('multiple-load')), 1),
          (!_ctx.allowMultipleLoad)
            ? (_openBlock(), _createBlock(_component_Popper, {
                key: 0,
                class: "popper-no-padding",
                hover: "",
                arrow: ""
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('multiple-load-info')), 1)
                    ])
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_InformationCircleIcon, { class: "h-6 ml-2 w-6 text-black-500" })
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_MinusSmallIcon, {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.totalLoadsMultiple > 2 ? _ctx.updateMultipleLoad(-1, _ctx.allowMultipleLoad) : null)),
                  class: "h-11 w-6 border border-black rounded-l-lg bg-gray-200 text-black ml-2"
                }),
                _createElementVNode("input", {
                  type: "text",
                  name: "totalLoadsMultiple",
                  disabled: "",
                  id: "totalLoadsMultiple",
                  rules: "required|max:10",
                  class: "bg-gray-50 border border-black text-gray-900 text-sm focus:ring-blue-500 w-10 focus:border-blue-500 flex p-2.5 h-11 pac-target-input",
                  placeholder: "",
                  value: _ctx.totalLoadsMultiple
                }, null, 8, _hoisted_9),
                _createVNode(_component_PlusSmallIcon, {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.totalLoadsMultiple >= 10 ? null : _ctx.updateMultipleLoad(+1, _ctx.allowMultipleLoad))),
                  class: "h-11 w-6 border border-black rounded-r-lg bg-gray-200 text-black"
                }),
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('loads')), 1)
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}