
import { defineComponent } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { hide } from '@popperjs/core';

export default defineComponent({
  name: 'PlanDefaultDeleteModal',
  components: { PlanButton, PlanDefaultModal, PlanIcon },
  props: {
    title: { type: String, required: true, default: '' },
    message: { type: String, required: true, default: '' },
    loading: { type: Boolean, required: true, default: false },
    hideIcon: { type: Boolean, required: false, default: false }
  },
  emits: ['close', 'delete'],
  setup (props, { emit }) {
    const close = () => { emit('close') }

    const deleteItem = () => { emit('delete') }

    return {
      close,
      deleteItem
    }
  }
})
