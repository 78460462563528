
import { defineComponent, reactive, toRefs } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';
import PlanLoading from '@/components/spin/PlanLoading.vue';

interface loadAlertsInfo {
  firstLoadingComplete: boolean,
  loading: boolean,
  claims: Array<any>
}

export default defineComponent({
  name: 'LoadAlertsInfo',
  components: { PlanLoading, PlanButtonIcon },
  props: {
    loadId: { type: Number, required: true, default: 0 },
    placement: { type: String, required: false, default: 'left' },
    bigButton: { type: Boolean, required: false, default: false },
  },
  setup (props) {
    const store = useStore()

    const data = reactive<loadAlertsInfo>({
      firstLoadingComplete: false,
      loading: false,
      claims: []
    })

    const getClaimsTooltip = () => {
      if (!data.firstLoadingComplete) {
        data.loading = true;
        store.dispatch('LoadsStore/getClaimsTooltip', props.loadId).then((response) => {
          data.claims = countTypes(response.claims)
          data.firstLoadingComplete = true
          data.loading = false
        })
      }
    }

    const getClaimsTooltipExternal = () => {
        data.loading = true
        store.dispatch('LoadsStore/getClaimsTooltip', props.loadId).then((response) => {
          data.claims = countTypes(response.claims)
          data.firstLoadingComplete = true
          data.loading = false
        })
    }

    const countTypes = (data) => {
      let typeCounts = {};

      data.forEach(claim => {
        if (typeCounts[claim.type]) {
          typeCounts[claim.type]++;
        } else {
          typeCounts[claim.type] = 1;
        }
      });

      let result = [] as any;

      for (let type in typeCounts) {
        result.push({
          alertType: type,
          totalAlerts: typeCounts[type],
        });
      }

      return result;
    }

    return {
      ...toRefs(data),
      getClaimsTooltip,
      getClaimsTooltipExternal,
    }
  }
})
