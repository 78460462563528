
import { computed, defineComponent, ref, watch } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'PlanHeaderSearch',
  components: { PlanIcon },
  props: {
    placeholder: { type: String, required: true, default: '' },
    oldQuery: { type: String, required: true, default: '' },
    bgColor: { type: String, required: false, default: '' },
  },
  emits: ['changeQuery'],
  setup(props, { emit }) {
    const inputFocused = ref(false);
    const query = ref('');

    if (props.oldQuery !== '') query.value = props.oldQuery + '';




    const computedClasses = computed(() => {
      return {
        'plan-header-search-focused': inputFocused.value,
        'plan-header-search-active': !inputFocused.value && query.value.length > 0,
        [props.bgColor]: !!props.bgColor,
      };
    });

    const changeQuery = () => {
      emit('changeQuery', query.value);

    };

    const cleanQuery = () => {
      query.value = '';
      changeQuery();
    };

    watch(() => props.oldQuery, (newValue, oldValue) => {
      query.value = props.oldQuery + '';
    })

    return {
      inputFocused,
      query,
      changeQuery,
      cleanQuery,
      computedClasses,
    };
  },
});
