
import { defineComponent, computed } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'PlanRating',
  components: { PlanIcon },
  props: {
    rating: { type: Number, required: true, default: 0 }
  },
  setup (props) {
    const backgroundClass = computed(() => {
      return ''
      // if (props.rating < 3) return 'plan-b-red-100'
      // if (props.rating >= 3 && props.rating < 4) return 'plan-b-amber-100'
      // return 'plan-b-green-100'
    })

    return {
      backgroundClass
    }
  }
})
