
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Modules
import LoadDetails from '@/modules/planning/loadDetail/_components/LoadDetails.vue';
import CarrierValoration from '@/modules/planning/loadDetail/finished/CarrierValoration.vue';

export default defineComponent({
  name: 'ShipperLoadDetailFinished',
  components: {
    LoadDetails,
    CarrierValoration,
  },
  mixins: [BaseComponent],
  computed: {
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
  },
});
