
import { Field, ErrorMessage } from 'vee-validate';
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import TrackingHistoryModal from '@/modules/carrier/quotationRequest/_componentes/TrackingHistoryModal.vue';
import PlanInputLocation from '@/components/forms/PlanInputLocation.vue';
import PlanInputTextArea from '@/components/forms/PlanTextArea.vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanGoogleMap from '@/components/map/PlanGoogleMap.vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

// Mixins
import CountryMixins from '@/services/country/_mixins/index';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';
import { locationObject, locationString } from '@/utils/formatLocation';

interface updatePositionModal {
  initialLocation: {
    lat: number;
    lon: number;
    zip: string;
    country: string;
  };
  loadingInitialLocation: boolean;
  loadingPostNotifyPosition: boolean;
  observations: string;
  placeId: string;
  showTrackingHistoryModal: boolean;
  showUpdatePositionModal: boolean;
  showUpdateCarrierData: boolean;
}

export default defineComponent({
  mixins: [CountryMixins],
  name: 'UpdatePositionModal',
  components: {
    PhoneNumberSelector,
    PlanGoogleMap,
    PlanIcon,
    PlanInputTextArea,
    PlanInputLocation,
    TrackingHistoryModal,
    PlanDefaultModal,
    PlanButton,
    Field,
    ErrorMessage,
  },
  props: {
    isShipper: { type: Boolean, required: false, default: false },
  },
  emits: ['updateTrackingHistory'],
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const route = useRoute();
    const router = useRouter();

    const data = reactive({
      initialLocation: {
        lat: 0,
        lon: 0,
        zip: '',
        country: '',
      },
      driver: {} as any,
      loadingInitialLocation: false,
      loadingPostNotifyPosition: false,
      observations: '',
      placeId: '',
      showTrackingHistoryModal: false,
      showUpdatePositionModal: false,
      showUpdateCarrierData: false,
      newCoordinatesSelected: false,
    });

    const setTextValue = (location) => {
      data.initialLocation.zip = locationString(location);
    };

    const changeInputLocation = (newData: any) => {
      setTextValue(newData);
      getPlaceId(newData.placeId);
    };

    const changePhone = (type, phone) => {
      switch (type) {
        case 'prefix':
          data.driver.phonePrefix = phone.prefix;
          break;
        case 'number':
          data.driver.phone = phone.number;
          break;
        default:
          return;
      }
    };

    const handleCloseUpdate = () => {
      data.initialLocation = {
        lat: 0,
        lon: 0,
        zip: '',
        country: '',
      };
      data.newCoordinatesSelected = false;
      data.showUpdateCarrierData = false;
    };

    const getLocation = () => {
      const currentLoadShipment = store.getters['LoadsStore/currentLoad']?.shipment;
      const currentLoadTracking = currentLoadShipment?.currentTracking?.location;

      if (currentLoadTracking) {
        data.initialLocation.lat = currentLoadTracking.lat ? +currentLoadTracking.lat : 0;
        data.initialLocation.lon = currentLoadTracking.long ? +currentLoadTracking.long : 0;
        getLocationsByGoogle();
      } else if (navigator.geolocation) {
        data.loadingInitialLocation = true;
        navigator.geolocation.getCurrentPosition(getLatitudeLongitude, showError);
      }
    };

    const shipment = computed(() => {
      return store.getters['ShipmentStore/shipment'];
    });

    const isDriver = computed(() => {
      return route.path.includes('driver');
    });

    const checkDriver = computed(() => {
      return data.driver.email !== '' && data.driver.phonePrefix !== '' && data.driver.phone !== '';
    });

    const getLocationsByGoogle = () => {
      store
        .dispatch('LoadsStore/getCoordinatesGoogle', {
          lat: data.initialLocation.lat,
          lng: data.initialLocation.lon,
        })
        .then((response) => {
          if (response.data.length > 0) {
            data.placeId = response.data[0]['place_id'];
            response.data[0]['address_components'].forEach((addressComponent: any) => {
              if (addressComponent.types.includes('postal_code')) {
                data.initialLocation.zip = addressComponent['short_name'];
              }
              if (addressComponent.types.includes('country')) {
                data.initialLocation.country = addressComponent['short_name'];
              }
            });
          }
        })
        .finally(() => {
          data.loadingInitialLocation = false;
        });
    };

    const getLatitudeLongitude = (position: GeolocationPosition) => {
      data.initialLocation.lat = position.coords.latitude;
      data.initialLocation.lon = position.coords.longitude;
      getLocationsByGoogle();
    };

    const getPlaceId = (newPlaceId: string) => {
      store
        .dispatch('LoadsStore/getPlaceDetailsGoogle', {
          placeId: newPlaceId,
        })
        .then((response) => {
          data.placeId = response.data['place_id'];
          data.initialLocation.lat = response.data.geometry.location.lat;
          data.initialLocation.lon = response.data.geometry.location.lng;
          data.newCoordinatesSelected = true;
        });
    };

    const nextStopDocumentStep = computed(() => {
      if (
        shipment.value &&
        shipment.value.nextStopDocumentStep &&
        shipment.value.nextStopDocumentStep[0] &&
        typeof shipment.value.nextStopDocumentStep[0] !== 'undefined'
      ) {
        return shipment.value.nextStopDocumentStep[0].nextStepSequence;
      } else {
        return null;
      }
    });

    const handleShowRequestModal = () => {
      if (shipment.value?.driver?.email) {
        store
          .dispatch('LoadsStore/sendEmailDriverNotifyPosition', shipment.value.id)
          .then((response) => {
            store.dispatch('UserStore/showToast', translate.t('email-sended-driver'));
          });
      } else {
        //Check Pre registered data
        data.driver.phonePrefix = shipment.value?.driver?.prefix;
        data.driver.phone = shipment.value?.driver?.phone || '';
        data.driver.showContactInformation = shipment.value?.driver?.showContactInformation;

        data.showUpdatePositionModal = false;
        data.showUpdateCarrierData = true;
      }
    };

    const postNotifyPosition = () => {
      data.loadingPostNotifyPosition = true;
      const params = {
        shipment: { id: store.getters['LoadsStore/currentLoad'].shipment?.id },
        location: {
          googlePlaceId: data.placeId,
          language: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es',
        },
        observations: data.observations,
      };

      store
        .dispatch('LoadsStore/postNotifyPosition', params)
        .then((response) => {
          store.dispatch('UserStore/showToast', translate.t('success-update-position'));
        })
        .finally(() => {
          data.observations = '';
          data.loadingPostNotifyPosition = false;
          data.showUpdatePositionModal = false;
          data.showTrackingHistoryModal = false;
          emit('updateTrackingHistory');
        });
    };

    const showError = (error: GeolocationPositionError) => {
      let errorMessage = '';
      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = 'El usuario denegó la solicitud de geolocalización.';
          break;
        case error.POSITION_UNAVAILABLE:
          errorMessage = 'La información de ubicación no está disponible.';
          break;
        case error.TIMEOUT:
          errorMessage = 'La solicitud de ubicación ha expirado.';
          break;
      }
    };

    const UpdateCarrier = () => {
      store
        .dispatch('CompanyStore/putDriver', {
          id: shipment.value.driver.id,
          params: {
            email: data.driver.email,
            phonePrefix: data.driver.prefix || '1',
            phone: data.driver.phone,
            showContactInformation: data.driver.showContactInformation,
          },
        })
        .then(async () => {
          await store.dispatch('ShipmentStore/shipment', { id: shipment.value.id });
          store.dispatch('UserStore/showToast', translate.t('Updated Correctly.')).then(() => {
            data.showUpdateCarrierData = false;
          });
        });
    };

    const showUpdatePosition = () => {
      data.initialLocation = {
        lat: 0,
        lon: 0,
        zip: '',
        country: '',
      };

      data.showUpdatePositionModal = true;
      getLocation();
    };

    store.dispatch('CountryStore/getCountries');

    return {
      ...toRefs(data),
      changeInputLocation,
      getLocation,
      shipment,
      isDriver,
      checkDriver,
      handleCloseUpdate,
      handleShowRequestModal,
      showUpdatePosition,
      postNotifyPosition,
      UpdateCarrier,
      changePhone,
    };
  },
});
