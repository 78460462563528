import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "plan-t-prim-100"
}
const _hoisted_2 = {
  key: 1,
  class: "font-bold plan-t-prim-500"
}
const _hoisted_3 = {
  class: "plan-p-sm overflow-y-auto",
  style: {"max-height":"200px"}
}
const _hoisted_4 = { class: "plan-filter-items-title" }
const _hoisted_5 = { class: "font-semibold text-sm" }
const _hoisted_6 = { class: "plan-filter-items" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createBlock(_component_popper, {
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPopper = false)),
    arrow: "",
    show: _ctx.showPopper,
    offsetDistance: "0",
    style: {"--popper-theme-padding":"0px"}
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('tags')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
            return (_openBlock(), _createElementBlock("div", {
              key: tag,
              onClick: ($event: any) => (_ctx.selectTag(tag)),
              class: "plan-filter-item"
            }, [
              _createElementVNode("span", null, _toDisplayString(tag), 1)
            ], 8, _hoisted_7))
          }), 128))
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["plan-filter", { 'plan-filter-focus': _ctx.showPopper, 'plan-filter-active': _ctx.selectedTag.length > 0 }])
      }, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopper = true)),
          class: "flex justify-start items-center plan-gap-sm"
        }, [
          _createVNode(_component_plan_icon, {
            name: "tags",
            "icon-color": _ctx.selectedTag ? '#151830' : '#8A8B97',
            "is-svg": ""
          }, null, 8, ["icon-color"]),
          (_ctx.selectedTag === '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('tag')), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.selectedTag), 1))
        ]),
        (_ctx.selectedTag !== '')
          ? (_openBlock(), _createBlock(_component_plan_button_icon, {
              key: 0,
              onOnClick: _ctx.removeTag,
              icon: "close",
              size: "16",
              type: "transparent-gray-800",
              style: {"padding":"0.1rem"}
            }, null, 8, ["onOnClick"]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["show"]))
}