
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanSelectSearch from '@/components/forms/PlanSelectSearch.vue';
// Utils
import cloneDeep from 'lodash/cloneDeep';
import { timezones } from '@/services/timezones/index';
import ShipperChangePassword from '@/modules/planning/userProfile/_modals/ShipperChangePassword.vue';

interface ICompany {
  company: {
    type: string;
    name: string;
    accountingEmail: string;
    location: {
      address: string;
      city: string;
      state: {
        id: number;
      };
    };
  };
}

interface IUser {
  name: string;
  email: string;
  surname: string;
  phone: number;
  phonePrefix: string;
}

interface ILogo {
  number: string;
  limit: number;
  date: Date;
  file: File;
}

export default defineComponent({
  name: 'ShipperProfile',
  components: {
    ShipperChangePassword,
    CheckCircleIcon,
    ErrorMessage,
    Field,
    Form,
    ModalConfirm,
    PhoneNumberSelector,
    PlanButton,
    PlanSelectSearch,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  data() {
    return {
      dataUser: {} as any,
      dataCompany: {} as any,
      loading: false,
      isLoaded: false,
      // showPassword: 'password',
      // showEye: false,
      allowed: true,
      saving: `${this.$t('saving')}...`,
      oldUserMail: '',
      logo: {} as any,
      logoFile: {} as any,
      isNewLogo: false,
      currentView: 'profile',
      newView: '',
      terms: '',
      isNewTerms: false,
      isRestore: false,
      restoreModalText: this.$t('message-restore-terms-and-conditions'),
      defaultTerms: '',
      logoURL: '',
    };
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    company(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    countriesList(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    getLogo(): any {
      if (this.company?.mediaObjects !== undefined) {
        return this.company.mediaObjects.find((media) => media.type === 'COMPANY_LOGO');
      }
      return undefined;
    },
    getTerms(): any {
      if (this.terms !== this.company.rateConfirmationTermsAndConditions) {
        return true;
      } else {
        return false;
      }
    },
    isEuropeCompany(): any {
      const country = this.countriesList.find(
        (country) => this.company?.country?.id === country?.id,
      );
      return country?.region?.name === 'EUROPE';
    },
    isSuperShipper(): any {
      return this.user.roles.some((role) => role === 'ROLE_SUPER_SHIPPER');
    },
    isUpdated(): any {
      return (
        this.dataUser.name !== this.user.name ||
        this.dataUser.surname !== this.user.surname ||
        this.dataUser.email !== this.user.email ||
        this.dataUser.phone !== this.user.phone ||
        this.dataUser.phonePrefix !== this.user.phonePrefix ||
        this.dataUser.timeZone !== this.user.timeZone ||
        this.dataCompany.id !== this.user.company.id ||
        this.dataCompany.name !== this.user.company.name ||
        this.dataCompany.location.id !== this.user.company.location.id ||
        this.dataCompany.location.address !== this.user.company.location.address ||
        this.dataCompany.location.city !== this.user.company.location.city ||
        this.dataCompany.location.state?.id !== this.user.company.location.state?.id ||
        this.dataCompany.identificationNumber !== this.user.company.identificationNumber ||
        this.dataCompany.contactName !== this.user.company.contactName ||
        this.dataCompany.accountingEmail !== this.user.company.accountingEmail ||
        this.dataCompany.contactEmail !== this.user.company.contactEmail ||
        this.dataCompany.contactPhone !== this.user.company.contactPhone ||
        this.dataCompany.contactPhonePrefix !== this.user.company.contactPhonePrefix ||
        this.isNewLogo ||
        this.isNewTerms
      );
    },
    localTimezone(): any {
      const dtf = new Intl.DateTimeFormat();
      return dtf.resolvedOptions().timeZone;
    },
    timezones(): any {
      return timezones.map((time) => {
        return {
          label: time.label,
          value: time.tzCode,
        };
      });
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
    user(): any {
      return this.$store.getters['UserStore/getUser'];
    },
    userState(): any {
      if (this.states) {
        const name = this.states.find((item) => item.id === this.user.company.location.state?.id);
        return name;
      } else {
        return '...';
      }
    },
  },
  watch: {
    user() {
      this.setUserData();
      if (this.user.company.location.state?.id === undefined) {
        let userCopy = cloneDeep(this.user);
        userCopy.company.location.state = { id: 1 };
        this.$store.commit('UserStore/setUser', userCopy);
      }
    },
  },
  created() {
    this.$store.dispatch('UserStore/currentUser');
  },
  mounted() {
    if (this.currentUser.id) {
      this.getUserInfo();
    }
  },
  methods: {
    changePhoneUser(type, phone) {
      switch (type) {
        case 'prefix':
          this.user.phonePrefix = phone.prefix;
          break;
        case 'number':
          this.user.phone = phone.number;
          break;
        default:
          return;
      }
    },
    changePhoneCompany(type, phone) {
      switch (type) {
        case 'prefix':
          this.user.company.contactPhonePrefix = phone.prefix;
          break;
        case 'number':
          this.user.company.contactPhone = phone.number;
          break;
        default:
          return;
      }
    },
    getUserInfo() {
      this.$store.dispatch('UserStore/getUser', { id: this.currentUser.id }).then(() => {
        this.$store.dispatch('UserStore/currentUser');
        this.$store.dispatch('LoadsStore/states');

        // this.user.password = this.currentUser.password;
        this.$store.dispatch('CompanyStore/getCompany', this.user.company.id);

        // this.setUserData();
        this.$store.dispatch('CompanyStore/getCompany', this.user.company.id);
        this.terms = this.company.rateConfirmationTermsAndConditions;

        const data = 'DEFAULT_RATE_CONFIRMATION_TERMS_AND_CONDITIONS';

        this.$store.dispatch('UserStore/bigTexts', data).then((res) => {
          this.defaultTerms = res.text;
          this.setTerms();
        });

        // this.user.prefix = '+1';
        this.isLoaded = true;
        this.oldUserMail = this.user.email;
      });
    },
    showModalRestore() {
      this.isRestore = true;
    },
    restoreTerms(data) {
      if (data) {
        this.dataCompany = {
          id: this.user.company.id,
          rateConfirmationTermsAndConditions: this.defaultTerms,
        };
        this.isRestore = false;
        this.isNewTerms = false;
        this.loading = true;
        this.saveCompany();
      } else {
        this.isRestore = false;
        this.isNewTerms = false;
      }
    },
    changeCurrentView(view) {
      // if (this.shouldUpdateCompany || this.shouldUpdateUser) {
      //   this.showEditModal = true;
      //   this.newView = view;
      // } else {
      this.currentView = view;
      // }
    },
    async viewLogo() {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: this.company?.mediaObjects[0].id,
        class: this.company?.mediaObjects[0].entityClass,
      });

      this.logoURL = URL.createObjectURL(response);
      const newWindow = window.open(this.logoURL, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
    async updatePreviewLogo() {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: this.company?.mediaObjects[0].id,
        class: this.company?.mediaObjects[0].entityClass,
      });

      this.logoURL = URL.createObjectURL(response);
    },
    setTerms() {
      this.$store.dispatch('CompanyStore/getCompany', this.user.company.id).then(() => {
        this.getCompanyLogo();
        // this.terms = this.company.rateConfirmationTermsAndConditions
        if (
          this.company.rateConfirmationTermsAndConditions !== '' &&
          this.company.rateConfirmationTermsAndConditions !== undefined
        ) {
          this.terms = this.company.rateConfirmationTermsAndConditions;
        } else {
          this.terms = this.defaultTerms;
        }
      });
    },
    setUserData() {
      this.dataUser = {
        name: this.user.name,
        surname: this.user.surname,
        email: this.user.email,
        phone: this.user.phone,
        phonePrefix: this.user?.phonePrefix ? this.user.phonePrefix : '1',
        timeZone: this.user.timeZone ?? this.localTimezone,
      };
      this.dataCompany = {
        id: this.user.company.id,
        name: this.user.company.name,
        rateConfirmationTermsAndConditions: this.terms,
        location: {
          id: this.user.company.location.id,
          address: this.user.company.location.address,
          city: this.user.company.location.city,
          state: {
            id: this.user.company.location.state?.id,
          },
        },
        identificationNumber: this.user.company.identificationNumber,
        contactName: this.user.company.contactName,
        contactEmail: this.user.company.contactEmail,
        contactPhone: this.user.company.contactPhone,
        contactPhonePrefix: this.user.company.contactPhonePrefix,
        accountingEmail: this.user.company.accountingEmail,
      };

      this.getCompanyLogo();
    },
    async getCompanyLogo() {
      if (this.getLogo) {
        const media = this.getLogo;
        if (media && !this.logo.file) {
          const file = await this.getFileCompany(media);
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file as any);
          (this.$refs.logoFile as any).files = dataTransfer.files;
          if (file !== undefined) {
            this.logo.file = file as any;
            this.logoFile = file as any;
          }
        }
        this.getLogoPreview();
      }
    },
    async getLogoPreview() {
      const promises: Promise<any>[] = [];
      if (
        this.logoURL === '' &&
        this.company?.mediaObjects !== undefined &&
        this.company?.mediaObjects !== null
      ) {
        const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
          id: this.company?.mediaObjects[0]?.id,
          class: this.company?.mediaObjects[0]?.entityClass,
        });
        this.logoURL = URL.createObjectURL(result);
      }
      const media = this.company.mediaObjects;
      if (media && !this.logo.file) {
        const file = await this.getFileCompany(media);
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        (this.$refs.logoFile as any).files = dataTransfer.files;
        this.logo.file = file;
        this.logoFile = file;
      }

      promises.push(
        this.$store.dispatch('ShipmentStore/thumbnailBig', {
          id: this.company?.mediaObjects[0]?.id,
          class: this.company?.mediaObject[0]?.entityClass,
        }),
      );
    },
    async getFileCompany(media: any): Promise<File> {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFile', {
        id: media.id,
        class: 'COMPANY',
      });
      return new File([response], media.name, { type: media.mimeType });
    },
    handleChangeTerms() {
      this.isNewTerms = true;
    },
    handleChange(type: string) {
      this.isNewLogo = true;
      this.logo.file = (this.$refs.logoFile as any).files[0];
    },
    submitForm() {
      this.setUserData();
      this.validateForm();
    },
    saveTerms() {
      this.dataCompany = {
        id: this.user.company.id,
        rateConfirmationTermsAndConditions: this.terms,
      };
      this.isNewTerms = false;
      this.loading = true;
      this.saveCompany();
    },
    timezoneOnChange(value) {
      this.user.timeZone = value;
    },
    async validateForm() {
      // if (!this.allowed) return;
      // this.sending = true;
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid) {
        // this.sending = false;
        this.allowed = false;
        return;
      }
      this.allowed = true;

      if (this.allowed) {
        this.loading = true;
        this.saveUser();
        if (this.isSuperShipper) {
          this.saveCompany();
        }
        this.saveLogo();
        this.isNewLogo = false;
      }
      // this.sending = false;
      // this.allowed = false;
    },
    saveUser() {
      this.$store
        .dispatch('UserStore/putUser', {
          id: this.user.id,
          user: this.dataUser,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('toast-profile-updated'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.loading = false;
          // In case email updated... user must log in again
          if (this.user.email !== this.oldUserMail) {
            BaseComponent.methods?.showToastSuccess(this.$t('toast-email-updated'), () => {
              localStorage.removeItem('token');
              localStorage.removeItem('refresh_token');
              this.$router.push('/signin');
            });
          }
        });
    },
    saveCompany() {
      this.$store
        .dispatch('CompanyStore/putCompany', {
          id: this.user.company.id,
          company: this.dataCompany,
        })
        .then(() => {
          this.setTerms();
          BaseComponent.methods?.showToastSuccess(this.$t('toast-profile-updated'));
          this.loading = false;
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          this.loading = false;
        });
    },
    saveLogo() {
      if (this.logo.file !== this.logoFile) {
        if (this.getLogo !== undefined) {
          if (this.getLogo.id !== undefined) {
            this.postUpdateMediaObjectCompany('COMPANY_LOGO');
          } else {
            this.postMediaObjectCompany('COMPANY_LOGO');
          }
        } else {
          this.postMediaObjectCompany('COMPANY_LOGO');
        }
      }
    },
    postMediaObjectCompany(type: string) {
      // let logo = {} as any;
      // switch (type) {
      //   case 'logo':
      //     // logo = this.logo;
      //     break;
      // }
      // logo = this.logo
      if (this.logo.file) {
        this.$store.dispatch('CompanyStore/postMediaObjectCompany', {
          entityId: this.user.company.id,
          file: this.logo.file,
          type: type,
        });
      }
    },
    postUpdateMediaObjectCompany(type: string) {
      // let logo = {} as any;
      // let mediaId = 0 as any;
      // switch (type) {
      //   case 'logo':
      //     // logo = this.logo;
      //     // mediaId = this.getLogo !==undefined ? this.getLogo.id : null;
      //     break;
      // }
      if (this.getLogo.id !== undefined && this.logo.file !== undefined) {
        this.$store
          .dispatch('CompanyStore/postUpdateMediaObjectCompany', {
            id: this.getLogo.id,
            file: this.logo.file,
          })
          .then(() => {
            this.updatePreviewLogo();
          });
      }
    },
  },
});
