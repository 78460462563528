import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
    class: _normalizeClass(['flex justify-start items-center cursor-pointer', _ctx.gapClass])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["plan-input-checkbox", { 'plan-input-checkbox-active': _ctx.value }])
    }, [
      _createVNode(_component_plan_icon, {
        name: "check",
        "icon-color": "white",
        "is-svg": ""
      })
    ], 2),
    (_ctx.icon.length > 0)
      ? (_openBlock(), _createBlock(_component_plan_icon, {
          key: 0,
          name: _ctx.icon,
          size: "16",
          "icon-color": "black",
          "is-svg": ""
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(_ctx.value ? _ctx.labelClass : '')
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true)
  ], 2))
}