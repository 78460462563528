import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 h-full flex-1 xl:ml-90" }
const _hoisted_2 = { class: "w-full h-32 px-10 pt-10 pb-5 bg-white flex-col justify-start items-start gap-8 inline-flex" }
const _hoisted_3 = { class: "self-stretch h-12 flex-col justify-start items-start gap-6 flex" }
const _hoisted_4 = { class: "self-stretch h-12 flex-col justify-start items-start gap-10 flex" }
const _hoisted_5 = { class: "self-stretch justify-start items-center gap-96 inline-flex" }
const _hoisted_6 = { class: "text-slate-900 text-4xl font-bold font-['Nunito']" }
const _hoisted_7 = { class: "xl:container flex justify-center items-center bg-gray-100 text-gray-700" }
const _hoisted_8 = { class: "w-1/2 bg-gray-50 rounded-xl p-5 mt-20" }
const _hoisted_9 = { class: "mx-auto tabs-nav overflow-x-auto" }
const _hoisted_10 = { class: "flex justify-start 2xl:justify-start border-b-2 pb-3 space-x-6" }
const _hoisted_11 = { class: "flex cursor-pointer" }
const _hoisted_12 = { class: "whitespace-nowrap" }
const _hoisted_13 = { class: "flex cursor-pointer" }
const _hoisted_14 = { class: "whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RocketLaunchIcon = _resolveComponent("RocketLaunchIcon")!
  const _component_SubscriptionComponent = _resolveComponent("SubscriptionComponent")!
  const _component_PaymentsComponent = _resolveComponent("PaymentsComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('payment-subscription')), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("ul", _hoisted_10, [
            _createElementVNode("li", {
              class: _normalizeClass(["relative", { active: _ctx.typeSelected === _ctx.paymentOptions.SUBSCRIPTION }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeMenuSelected(_ctx.paymentOptions.SUBSCRIPTION)))
            }, [
              _createElementVNode("a", _hoisted_11, [
                _createVNode(_component_RocketLaunchIcon, { class: "h-6 w-6 text-corp mr-2" }),
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.accessPlanText), 1)
              ])
            ], 2),
            _createElementVNode("li", {
              class: _normalizeClass(["relative", { active: _ctx.typeSelected === _ctx.paymentOptions.PAYMENT }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeMenuSelected(_ctx.paymentOptions.PAYMENT)))
            }, [
              _createElementVNode("a", _hoisted_13, [
                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('payments-total')), 1)
              ])
            ], 2)
          ])
        ]),
        (_ctx.typeSelected === _ctx.paymentOptions.SUBSCRIPTION)
          ? (_openBlock(), _createBlock(_component_SubscriptionComponent, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.typeSelected === _ctx.paymentOptions.PAYMENT)
          ? (_openBlock(), _createBlock(_component_PaymentsComponent, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}