import store from '@/services/store/index';

function locationString(addressData: any) {
  let administrativeArea = getAddressComponent(addressData, ['administrative_area_level_1', 'administrative_area_level_2']);
  if (addressData.locality === addressData.administrative_area_level_3) {
    administrativeArea = addressData.administrative_area_level_2;
  }

  const addressComponents = [] as any;
  if (addressData.postal_code) {
    addressComponents.push(addressData.postal_code);
  }
  if (addressData.locality) {
    addressComponents.push(addressData.locality);
  }
  if (administrativeArea) {
    addressComponents.push(administrativeArea);
  }

  return addressComponents.join(', ');


}

function getAddressComponent(addressData: any, levels: string[]) {
  for (const level of levels) {
    if (addressData[level]) {
      return addressData[level];
    }
  }
  return '';
}

function objectLocationToAddress(item: any) {
  // 123, Miami, Florida
  return `${item?.zip}, ${item?.city}, ${item?.state?.iso}`;
}
function objectLocationToString(item: any) {
  // #1, 123, Miami, Florida
  return `#${item.sequence}, ${item.location.zip}, ${item.location.city}, ${item.location.state.isoGoogle}`;
}
function locationObject(addressData: any) {
  const states: any = store.getters['LoadsStore/getStates'];

  let stateObj = findState(states, addressData.administrative_area_level_1, addressData);
  
  if (!stateObj && addressData.administrative_area_level_2) {
    stateObj = findState(states, addressData.administrative_area_level_2, addressData, 'name');
  }


  const locationInfo = {
    lat: addressData.latitude,
    long: addressData.longitude,
    zip: addressData.postal_code || '',
    city: addressData.locality || addressData.administrative_area_level_3 || addressData.administrative_area_level_2 || addressData.country || '',
    googlePlaceId: addressData.place_id,
  } as any;

  if (stateObj?.id) {
    locationInfo.state = { id: stateObj.id };
  }

  return locationInfo;
}

function findState(states: any, value: string, addressData: any, property ='isoGoogle') {
  return states.find(state => state[property] === value && sameCountry(addressData, state));
}

function sameCountry(address, state) {
  if (address.country === 'España' && state.country.name === 'Spain') {
    return true;
  }
  if (address.country === 'Estados Unidos' && state.country.name === 'United States') {
    return true;
  }
  if (address.country === state.country.name) {
    return true;
  }
  return false;
}
function getStateById(id: any) {
  const states: any = store.getters['LoadsStore/getStates'];

  return states.find((state) => state.id === id);
}
export {
  locationString,
  locationObject,
  objectLocationToString,
  objectLocationToAddress,
  getStateById,
};
