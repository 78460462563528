
import { defineComponent, reactive, toRefs } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'PlanButtonDocumentation',
  components: { PlanButton, PlanButtonIcon },
  setup () {
    const data = reactive({
      showContent: false
    })

    return {
      ...toRefs(data)
    }
  }
})
