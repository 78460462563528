
import { computed, defineComponent, reactive, ref, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import { useI18n } from 'vue-i18n';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import moment from 'moment';
import PlanThumbnail from '@/components/forms/PlanThumbnail.vue';
export default defineComponent({
  name: 'PlanCarrierUploadCmr',
  components: { PlanThumbnail, PlanTextButton, PlanInputFile, PlanCarrierDefaultModal, PlanButton },
  props: {
    stop: { type: Object, required: true },
    isUploaded: { type: Boolean, required: true, default: false },
    isNotification: { type: Boolean, required: true, default: false },
  },
  setup(props) {
    const files = ref<File[]>([]);
    const store = useStore();
    const translate = useI18n();

    console.log(props.stop);

    const data = reactive({
      showModal: false,
      loading: false,
    });

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const changeFiles = (newFiles: File[]) => {
      files.value = newFiles;
    };

    const getFormattedDate = (date: string) => {
      moment.locale(translate.locale.value);
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? moment(date).format('MMM DD, YYYY')
        : moment(date).format('DD MMM, YYYY');
    };

    const getFormattedDateHour = (date: string) => {
      moment.locale(translate.locale.value);
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? moment(date).format('MMM DD, YYYY HH:mm')
        : moment(date).format('DD MMM, YYYY HH:mm');
    };

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    console.log(loadData.value, 'loadData');

    const handleShowRequestModal = () => {
      store.dispatch('LoadsStore/sendEmailDriver', props.stop.id).then((response) => {
        store.dispatch('UserStore/showToast', translate.t('email-sended-driver'));
      });
    };

    const getLabelsByRegion = computed(() => {
      const region = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'usa' : 'eu';
      if (region === 'eu') {
        return {
          upload: translate.t('upload-cmr'),
          uploaded: translate.t('uploaded-cmr'),
        };
      } else {
        return {
          upload:
            props.stop.type === 'PICKUP_INITIAL' || props.stop.type === 'PICKUP'
              ? translate.t('upload-bol')
              : translate.t('upload-pod'),
          uploaded:
            props.stop.type === 'PICKUP_INITIAL' || props.stop.type === 'PICKUP'
              ? translate.t('uploaded-bol')
              : translate.t('uploaded-pod'),
        };
      }
    });

    const postUploadCmr = async () => {
      data.loading = true;

      for (const file of files.value) {
        await store.dispatch('ShipmentStore/postMediaObjectStops', {
          entityId: props.stop.id,
          file: file,
        });
      }

      await store.dispatch(
        'CarrierStore/getLoadInfoForCarrier',
        store.getters['CarrierStore/getLoadData'].id,
      );
      await store.dispatch('UserStore/updateToast', {
        text: translate.t('documents-uploaded-done'),
        type: 'success',
        visible: true,
      });

      data.loading = false;
      data.showModal = false;
    };

    const titleAndSubtitle = computed(() => {
      const region = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'usa' : 'eu';
      let title = ' ';
      let subtitle = ' ';

      if (props.stop.type === 'PICKUP_INITIAL' || props.stop.type === 'PICKUP')
        title = `${translate.t('pick-up')} ${props.stop.sequence}`;
      if (props.stop.type === 'DELIVERY_FINAL' || props.stop.type === 'DELIVERY')
        title = `${translate.t('delivery')} ${props.stop.sequence}`;

      subtitle += `${props.stop.location?.city}, ${props.stop.location?.state?.iso} ${props.stop.location?.state?.country?.iso}`;

      let initialTitle = translate.t('upload-cmr');
      if (region === 'usa')
        initialTitle =
          props.stop.type === 'PICKUP_INITIAL' || props.stop.type === 'PICKUP'
            ? translate.t('upload-bol')
            : translate.t('upload-pod');
      if (props.isUploaded) initialTitle = `${translate.t('show')} / ${translate.t('update')}`;

      return {
        title: initialTitle + ' ' + title,
        subtitle: subtitle,
      };
    });

    return {
      ...toRefs(data),
      actualWidth,
      changeFiles,
      files,
      getFormattedDate,
      getFormattedDateHour,
      getLabelsByRegion,
      handleShowRequestModal,
      postUploadCmr,
      isDriver,
      loadData,
      titleAndSubtitle,
    };
  },
});
