
import { computed, defineComponent, onBeforeMount, reactive, toRefs, watch } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierRfpQuoteHeader from '@/components/carrier/_rfpQuote/PlanCarrierRfpQuoteHeader.vue';
import PlanCarrierRfpQuoteSubHeader from '@/components/carrier/_rfpQuote/PlanCarrierRfpQuoteSubheader.vue';
import PlanCarrierRfpQuotingPerLane from '@/components/carrier/_rfpQuote/PlanCarrierRfpQuotingPerLane.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ModalBasic from '@/components/modals/BasicModal.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

import PlanCarrierLoadHeaderNotification from '@/components/carrier/PlanCarrierLoadHeaderNotification.vue';
import moment from 'moment';

export default defineComponent({
  name: 'CarrierRfpQuote',
  components: {
    ModalBasic,
    PlanButtonIcon,
    PlanButton,
    PlanIcon,
    PlanCarrierLoadHeaderNotification,
    PlanCarrierRfpQuotingPerLane,
    PlanCarrierRfpQuoteSubHeader,
    PlanCarrierRfpQuoteHeader,
    PlanDefaultCarrierView,
  },
  props: {
    id: { type: Number, required: true, default: -1 },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();
    const data = reactive({
      currentPage: 1,
      itemsPerPage: 30,
      lanesList: store.getters['RFPStore/getLanes'],
      loading: true,
      loadingTable: false,
      quotesArray: [] as any,
      onlyDetails: false,
      showModalSuccess: false,
      isSendingQuotations: false,
    });

    const dispatchApiCall = async (actionName, param) => {
      try {
        await store.dispatch(actionName, param);
      } catch (error) {
        store.dispatch('UserStore/updateToast', {
          text: `Error  ${actionName}:${error}`,
          type: 'error',
          visible: true,
        });
      }
    };

    onBeforeMount(async () => {
      data.loading = true;
      await dispatchApiCall('RFPStore/getProjectByIdCarrier', {
        projectId: props.id,
        page: data.currentPage,
      });
      await dispatchApiCall('RFPStore/getLanesAndQuotesForCarrier', {
        projectId: props.id,
        page: data.currentPage,
      });
      await dispatchApiCall('RFPStore/getLanesByProjectId', {
        projectId: props.id,
        page: data.currentPage,
      }).then((response) => {
        data.lanesList = response;
      });

      await dispatchApiCall('RFPStore/getQuoteRFPCarrier', {
        projectId: props.id,
        page: data.currentPage,
      });

      data.loading = false;
    });

    const totalPages = computed(() => {
      return Math.ceil(currentProject.value.computed?.lanes?.totalAmount / data.itemsPerPage);
    });

    const formatDate = (date) => {
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? moment(date).format('MMM DD, YYYY')
        : moment(date).format('DD MMM, YYYY');
    };

    const changeMode = () => {
      data.onlyDetails = !data.onlyDetails;
    };

    const fetchQuotations = async () => {
      await dispatchApiCall('RFPStore/getLanesAndQuotesForCarrier', {
        projectId: props.id,
        page: data.currentPage,
      });
      await dispatchApiCall('RFPStore/getQuoteRFPCarrier', {
        projectId: props.id,
        page: data.currentPage,
      });
      await dispatchApiCall('RFPStore/getProjectByIdCarrier', {
        projectId: props.id,
        page: data.currentPage,
      });
      await dispatchApiCall('RFPStore/getLanesByProjectId', {
        projectId: props.id,
        page: data.currentPage,
      }).then((response) => {
        data.lanesList = response;
      });
    };

    const sendQuotation = async () => {
      const hasInvalidPrice = data.quotesArray.some(
        (item) => item.price === 0 || item.price === '',
      );
      const hasInvalidCapacity = data.quotesArray.some(
        (item) => item.capacity === 0 || item.capacity === null,
      );

      if (hasInvalidPrice) {
        store.dispatch('UserStore/updateToast', {
          text: translate.t('error.invalidPrice'),
          type: 'error',
          visible: true,
        });
        return;
      }

      if (hasInvalidCapacity) {
        store.dispatch('UserStore/updateToast', {
          text: translate.t('error.invalidCapacity'),
          type: 'error',
          visible: true,
        });
        return;
      }

      data.isSendingQuotations = true;
      await dispatchApiCall('RFPStore/postQuoteRFP', {
        quotes: data.quotesArray,
      })
        .then(() => {
          fetchQuotations();
          data.quotesArray = [];
          data.showModalSuccess = true;
        })
        .finally(() => {
          data.isSendingQuotations = false;
        });

      data.onlyDetails = !data.onlyDetails;
    };

    const nextPage = () => {
      if (data.currentPage < 10) {
        data.loadingTable = true;
        data.currentPage++;
        fetchQuotations().then(() => {
          data.loadingTable = false;
        });
      }
    };

    const previousPage = () => {
      if (data.currentPage > 1) {
        data.currentPage--;
        data.loadingTable = true;
        fetchQuotations().then(() => {
          data.loadingTable = false;
        });
      }
    };

    const deleteFromArray = (id) => {
      let index = data.quotesArray.findIndex((item) => item.lane.id === id);
      if (index !== -1) {
        data.quotesArray.splice(index, 1);
      }
    };

    const handleUpdateQuotation = (quotation) => {
      let quote = {
        price: quotation.price,
        capacity: quotation.maxCapacity,
        lane: {
          id: quotation.lane,
        },
      };

      // if (
      //   quotation.price === 0 ||
      //   quotation.price === null ||
      //   quotation.maxCapacity === 0 ||
      //   quotation.maxCapacity === null
      // ) {
      //   return;
      // }

      if (data.quotesArray.length === 0) {
        data.quotesArray.push(quote);
      } else {
        let findLane = data.quotesArray.find((item) => item.lane.id === quotation.lane);

        if (findLane) {
          findLane.price = quote.price;
          findLane.capacity = quote.capacity;
        } else {
          data.quotesArray.push(quote);
        }
      }
    };

    const quotationDate = computed(() => {
      if (currentCarrierRFPQuotes.value.length > 0) {
        return (
          currentCarrierRFPQuotes.value.reduce((prev, current) =>
            prev.quotationDate > current.quotationDate ? prev : current,
          ).quotationDate ?? null
        );
      }
      return null;
    });

    const handleDeleteQuote = async (id) => {
      await dispatchApiCall('RFPStore/deleteQuoteRFP', id).then(() => {
        fetchQuotations();
        data.onlyDetails = !data.onlyDetails;
        store.dispatch('UserStore/updateToast', {
          text: translate.t('withdraw-bid'),
          type: 'success',
          visible: true,
        });
      });
    };

    const confirmLane = async (laneId) => {
      await dispatchApiCall('RFPStore/postCarrierConfirm', laneId).then(() => {
        store.dispatch('UserStore/updateToast', {
          text: translate.t('bid-confirmed'),
          type: 'success',
          visible: true,
        });
        fetchQuotations();
      });
    };

    const getPositionPreAssigned = (item) => {
      const status = item?.quote?.quoteRequest?.status;
      const rankingOrder = item?.quote?.rankingOrder;

      if (rankingOrder && (status === 'CONFIRMED' || status === 'ACCEPTING')) {
        return rankingOrder;
      }

      if (status === 'REFUSED') {
        return 0;
      }

      return -1;
    };

    const getLanesAndQuotesForCarrier = computed(() => {
      return store.getters['RFPStore/getLanesAndQuotesForCarrier'].laneAndQuote;
    });

    const totalQuotes = computed(() => {
      return store.getters['RFPStore/getLanesAndQuotesForCarrier'].computed?.quotesOnPage;
    });

    if (totalQuotes?.value > 0) {
      data.onlyDetails = true;
    }

    const quotesOnPage = computed(() => {
      return store.getters['RFPStore/getLanesAndQuotesForCarrier']?.computed?.quotesOnPage;
    });

    const currentCarrierRFPQuotes = computed(() => {
      return store.getters['RFPStore/getCurrentCarrierRFPQuotes'];
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    watch(
      () => totalQuotes?.value,
      (total) => {
        if (total > 0) {
          data.onlyDetails = true;
        } else {
          data.onlyDetails = false;
        }
      },
    );

    return {
      ...toRefs(data),
      confirmLane,
      deleteFromArray,
      formatDate,
      currentCarrierRFPQuotes,
      changeMode,
      handleDeleteQuote,
      nextPage,
      quotationDate,
      totalPages,
      getPositionPreAssigned,
      previousPage,
      sendQuotation,
      getLanesAndQuotesForCarrier,
      handleUpdateQuotation,
      totalQuotes,
    };
  },
});
