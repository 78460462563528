
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import HeaderRFPLanes from '@/modules/rfp/_components/headers/HeaderRFPLanes.vue';
import NewProjectModal from '@/modules/rfp/lanesCreateProject/NewProjectModal.vue';
import ProjectCard from '@/modules/rfp/_components/cards/ProjectCard.vue';
import ProjectCardSkeleton from '@/modules/rfp/_components/skeleton/ProjectCardSkeleton.vue';
import ProjectsFilters from '@/modules/rfp/_components/ProjectsFilters.vue';
import WaitingLanesModal from '@/modules/rfp/_components/modals/WaitingLanesModal.vue';

export default defineComponent({
  mixins: [BaseComponent],
  components: {
    HeaderRFPLanes,
    NewProjectModal,
    ProjectCard,
    ProjectCardSkeleton,
    ProjectsFilters,
    WaitingLanesModal,
  },
  data() {
    return {
      activeStatus: null as any,
      defaultTypeCreation: 'scratch',
      isLoadingList: false,
      isLoadingMore: false,
      newProjectModalVisible: false,
      page: 1,
      scrollY: 0,
      searchFilters: null as any,
      searchTimeout: null as any,
      sortBy: 'id[DESC]',
      timeStamp: 0,
      refresh: false,
      createdProjectId: 0,
    };
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    hasMoreItems(): any {
      return this.page * this.maxResults <= this.projectsList.length;
    },
    maxResults(): any {
      return this.$store.getters['GlobalStore/getPaginationMaxResults']
        ? parseInt(this.$store.getters['GlobalStore/getPaginationMaxResults'])
        : 30;
    },
    projectsList(): any {
      return this.$store.getters['RFPStore/getProjectsRFPList'];
    },
    newProjectModalOptions(): any {
      return this.$store.getters['RFPStore/getNewProjectModalOptions'];
    },
    showSticky(): boolean {
      return this.scrollY > 0;
    },
    showWaitingLanes(): any {
      return this.$store.getters['RFPStore/getShowWaitingLanes'];
    },
  },
  created() {
    this.$store.commit('RFPStore/setNewProjectModalOptions', {
      type: 'scratch',
      opened: false,
      mode: 'creation',
    });
    this.$store.commit('RFPStore/setClaimProjectInfo', []);
    this.$store.commit('RFPStore/setProjectToDuplicate', {});
    this.$store.commit('RFPStore/setCurrentEditingLanes', []);

    if (!localStorage.getItem('projectStatusSearched')) {
      this.clearProjectStatus();
    } else if (localStorage.getItem('projectStatusSearched') === 'refresh') {
      this.refresh = true;
      this.clearProjectStatus();
    }

    this.activeStatus = localStorage.getItem('projectStatusSearched');

    this.getProjectsList();
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
  },
  methods: {
    clearProjectStatus() {
      localStorage.setItem('projectStatusSearched', '');
    },
    closeWaitingLanes() {
      this.$store.commit('RFPStore/setShowWaitingLanes', false);
    },
    displayModal(bool) {
      this.newProjectModalVisible = bool;
      this.$store.commit('RFPStore/setNewProjectModalOptions', {
        type: 'scratch',
        opened: bool,
        mode: 'creation',
      });
    },
    fetchAll() {
      this.getProjectsTotalsStatus();
      this.getProjectsList();
    },
    filterStatusProjectList(status) {
      this.activeStatus = status;
      this.getProjectsTotalsStatus();
      this.getProjectsList();
    },
    setProjectStatusSearched() {
      localStorage.setItem('projectStatusSearched', this.activeStatus);
    },
    getProjectsTotalsStatus() {
      (this.$refs.statusFilters as any).getProjectsTotalsStatus(this.searchFilters);
    },
    getProjectsList() {
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        if (
          !this.isLoadingMore &&
          (this.activeStatus !== localStorage.getItem('projectStatusSearched') || this.refresh)
        ) {
          this.isLoadingList = true;
        }
        this.$store
          .dispatch('RFPStore/getProjectsRFPList', {
            page: this.page,
            maxResults: this.maxResults,
            status: this.activeStatus,
            sortBy: this.sortBy,
            ...this.searchFilters,
          })
          .finally(() => {
            localStorage.setItem('projectStatusSearched', this.activeStatus);
            this.refresh = false;
            this.isLoadingList = false;
            this.isLoadingMore = false;
          });
      }, 500);
    },
    moreProjects() {
      if (this.isLoadingMore) {
        return;
      }
      this.isLoadingMore = true;
      this.page = this.page + 1;
      this.getProjectsTotalsStatus();
      this.getProjectsList();
    },
    sortProjectList(sort) {
      const filter = sort.split('-');
      this.sortBy = `${filter[0]}[${filter[1]}]`;
      this.getProjectsTotalsStatus();
      this.getProjectsList();
    },
    submitSearch(data) {
      this.isLoadingList = true;
      this.page = 1;
      this.searchFilters = data;
      this.getProjectsTotalsStatus();
      this.getProjectsList();
    },
    updateProject() {
      this.timeStamp = new Date().getTime();
      this.fetchAll();
      return this.timeStamp;
    },
  },
  // watch: {
  //   showWaitingLanes: function (newVal) {
  //     if (newVal === false) {
  //       BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
  //       this.$router.push({
  //         name: 'ProjectLaneDetail',
  //         params: { projectId: this.createdProjectId },
  //       });
  //     }
  //   },
  // },
});
