
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
// Services
import store from '@/services/store';

export default defineComponent({
  name: 'LoadsCreditModal',
  components: {
    BaseModal,
  },
  mixins: [BaseComponent],
  emits: ['callbackClose'],
  props: {
    companyId: Number,
    show: Boolean,
  },
  setup(props, { emit }) {
    /** Variables */
    const router = useRouter();
    const page = ref(1);
    const showSpinner = ref(false);

    // Computed
    const creditBreakdown = computed(() => {
      return store.getters['CreditStore/getCreditBreakdown'];
    });

    const companyUpdate = computed(() => {
      return store.getters['CompanyStore/getCompany'];
    });

    const userUpdate = computed(() => {
      return store.getters['UserStore/getUser'];
    });

    /** onMounted */
    onMounted(() => {
      if (props.companyId) {
        apiGetCreditBreakdown();
      }
    });

    /** Methods */
    const apiGetCreditBreakdown = () => {
      if (showSpinner.value === true) return;
      showSpinner.value = true;
      return store
        .dispatch('CreditStore/getCompanyCreditBreakdown', {
          id: props.companyId,
          params: { page: page.value },
        })
        .then(() => {
          showSpinner.value = false;
        });
    };

    const loadMore = () => {
      page.value = page.value + 1;
      apiGetCreditBreakdown();
    };

    const onClickClose = () => {
      emit('callbackClose');
    };

    const seeLoad = (id: any) => {
      router.push(`/superadmin/loadsList/${id}`);
    }

    /** Watchers */
    watch(
      () => props.companyId,
      (newValue) => {
        if (newValue) {
          apiGetCreditBreakdown();
        }
      },
    );

    return {
      creditBreakdown,
      companyUpdate,
      loadMore,
      onClickClose,
      showSpinner,
      userUpdate,
      seeLoad
    };
  },
});
