import CarrierBase from '@/modules/carrierViews/_views/CarrierBase.vue';
import CarrierAuthentication from '@/modules/carrierViews/_views/CarrierAuthentication.vue';
import CarrierLoadDetails from '@/modules/carrierViews/_views/CarrierLoadDetails.vue';
import CarrierLoadAcceptation from '@/modules/carrierViews/_views/CarrierLoadAcceptation.vue';
import CarrierLoadAccepted from '@/modules/carrierViews/_views/CarrierLoadAccepted.vue';
import CarrierSignOrder from '@/modules/carrierViews/_views/CarrierSignOrder.vue';
import CarrierLoadAssigned from '@/modules/carrierViews/_views/CarrierLoadAssigned.vue';
import CarrierRfpQuote from '@/modules/carrierViews/_views/CarrierRfpQuote.vue';
import CarrierRfpQuoteDetails from '@/modules/carrierViews/_views/CarrierRfpQuoteDetails.vue';
import CarrierNotifyPosition from '@/modules/carrierViews/_views/CarrierNotifyPosition.vue';
import CarrierBalancer from '@/modules/carrierViews/_views/CarrierBalancer.vue';

const CarrierViewsRouter = [
  {
    path: '/carrier/load/:chat?/:id/:hash/:code',
    name: 'CarrierAuthentication',
    component: CarrierAuthentication,
    props: true,
  },
  {
    path: '/carrier/rfp/quoting/:id/:chat?/:hash/:code',
    name: 'CarrierAuthenticationRFP',
    component: CarrierAuthentication,
    props: true,
  },
  {
    path: '/carrier/:id',
    name: 'CarrierBalancer',
    component: CarrierBalancer,
    props: true,
  },
  {
    path: '/driver/load/position/:id/:hash/:code',
    name: 'DriverUpdatePosition',
    component: CarrierAuthentication,
    props: true,
  },
  {
    path: '/carrier/load/position/:id/:hash/:code',
    name: 'CarrierUpdatePosition',
    component: CarrierAuthentication,
    props: true,
  },
  {
    path: '/driver/load/:id?/:hash?/:code?',
    name: 'DriverAuthentication',
    component: CarrierAuthentication,
    props: true,
  },
  {
    path: '/carrier/load-detail/:id?',
    name: 'CarrierLoadDetails',
    component: CarrierLoadDetails,
    props: true,
  },
  {
    path: '/carrier/load-acceptation/:id?',
    name: 'CarrierLoadAcceptation',
    component: CarrierLoadAcceptation,
    props: true,
  },
  {
    path: '/carrier-view/:id?/:hash?/:code?',
    name: 'CarrierBase',
    component: CarrierBase,
    props: true,
  },
  {
    path: '/carrier/load-accepted/:id?',
    name: 'CarrierLoadAccepted',
    component: CarrierLoadAccepted,
    props: true,
  },
  {
    path: '/carrier/sign-order/:id?',
    name: 'CarrierSignOrder',
    component: CarrierSignOrder,
    props: true,
  },
  {
    path: '/carrier/load-assigned/:id?',
    name: 'CarrierLoadAssigned',
    component: CarrierLoadAssigned,
    props: true,
  },
  {
    path: '/carrier/update-position/:id?',
    name: 'CarrierNotifyPosition',
    component: CarrierNotifyPosition,
    props: true,
  },
  {
    path: '/carrier/rfp/quoting/:id',
    name: 'CarrierRfpQuote',
    component: CarrierRfpQuote,
    props: true,
  },
  {
    path: '/carrier/rfp/quoting-details/:id?',
    name: 'CarrierRfpQuoteDetails',
    component: CarrierRfpQuoteDetails,
    props: true,
  },
];

export default CarrierViewsRouter;
