
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
import PlanLoading from '@/components/loadings/PlanLoading.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'PlanModalLocation',
  components: {
    BaseModal,
    ErrorMessage,
    Field,
    Form,
    PlanButton,
    PlanLoading,
  },
  mixins: [BaseComponent],
  emits: ['setLocation', 'close'],
  props: {
    lat: { type: String as any },
    long: { type: String as any },
    showModal: { type: Boolean },
  },
  data() {
    return {
      locations: [] as any,
      latitude: null as any,
      longitude: null as any,
      loading: false,
    };
  },
  created() {
    this.latitude = this.lat?.length > 0 && this.lat != '0' ? this.lat : null;
    this.longitude = this.long?.length > 0 && this.long != '0' ? this.long : null;
  },
  methods: {
    async findLocation() {
      this.loading = true;
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid) {
        this.loading = false;
        return;
      }
      const geocoder = new (window as any).google.maps.Geocoder();
      geocoder.geocode(
        { location: { lat: Number(this.latitude), lng: Number(this.longitude) } },
        (results, status) => {
          if (status === 'OK') {
            this.locations = results.filter((location) => location.address_components.length > 4);
          } else {
            this.locations = [];
          }
          this.loading = false;
        },
      );
    },
    setLocation(location) {
      const comesFromCoordinates = true;
      this.$emit('setLocation', location, this.latitude, this.longitude, comesFromCoordinates);
      this.onClickClose();
    },
    onClickClose() {
      this.$emit('close', false);
    },
  },
});
