import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "py-1"
}
const _hoisted_2 = {
  key: 1,
  class: "py-1"
}
const _hoisted_3 = {
  key: 2,
  class: "py-1"
}
const _hoisted_4 = {
  key: 3,
  class: "py-1"
}
const _hoisted_5 = {
  key: 4,
  class: "py-1"
}
const _hoisted_6 = {
  key: 5,
  class: "py-1"
}
const _hoisted_7 = {
  key: 6,
  class: "py-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EllipsisHorizontalCircleIcon = _resolveComponent("EllipsisHorizontalCircleIcon")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, {
    onClick: _withModifiers(_ctx.togglePopper, ["stop"]),
    class: "popper-padding-1 w-max",
    style: {"margin":"0px","border":"0px"},
    click: ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", {
        onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"])),
        class: "flex flex-col text-sm rounded-md"
      }, [
        (_ctx.load?.id && !_ctx.isShipperViewer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createTemplate(_ctx.load.id))),
                class: _normalizeClass(["block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100", { 'disable-button': _ctx.isTemplate(_ctx.load.id) }])
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('create-template')), 1)
              ], 2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isModify && !_ctx.isShipperViewer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('modifyLoad'))),
                class: _normalizeClass(["block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100", { 'cursor-not-allowed hover:text-gray-300': !_ctx.isModify }])
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('modify')), 1)
              ], 2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.statusToCancel)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("a", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.statusToCancel ? _ctx.$emit('cancelLoad') : '')),
                class: _normalizeClass(["block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100", { 'cursor-not-allowed hover:text-gray-300': !_ctx.statusToCancel }])
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('cancel')), 1)
              ], 2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.statusToDelete && !_ctx.isShipperViewer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.statusToDelete ? _ctx.$emit('deleteLoad') : '')),
                class: _normalizeClass(["block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100", { 'cursor-not-allowed hover:text-gray-300': !_ctx.statusToDelete }])
              }, _toDisplayString(_ctx.$t('delete')), 3)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isView)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("a", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isView ? _ctx.$emit('isView') : '')),
                class: _normalizeClass(["block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100", { 'cursor-not-allowed hover:text-gray-300': !_ctx.isView }])
              }, _toDisplayString(_ctx.$t('view')), 3)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isSend && !_ctx.isShipperViewer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("a", {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isSend ? _ctx.$emit('isSend') : '')),
                class: _normalizeClass(["block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100", { 'cursor-not-allowed hover:text-gray-300': !_ctx.isSend }])
              }, _toDisplayString(_ctx.$t('share')), 3)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isClone)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("a", {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('cloneLoad'))),
                class: "block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              }, _toDisplayString(_ctx.$t('clone')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_EllipsisHorizontalCircleIcon, { class: "h-6 w-6 text-corp mx-1 cursor-pointer" })
    ]),
    _: 1
  }, 8, ["onClick"]))
}