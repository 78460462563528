
import { defineComponent, ref, computed } from 'vue';
import PlanBadge from '@/components/badges/PlanBadge.vue';
export default defineComponent({
  name: 'PlanTabs',
  components: { PlanBadge },
  emits: ['changeTab'],
  props: {
    align: { type: String, required: false, default: 'left' },
    currentTab: { type: Number, required: false, default: 0 },
    tabs: { type: Array, required: true },
    showBadges: { type: Boolean, required: false, default: true },
    size: { type: String, required: false, default: 'plan-text-md' },
    startTab: { type: Number, required: false, default: 0 },
    tabsWidth: { type: String },
    columnsGap: { type: String },
  },
  setup(props, { emit }) {
    const selectedValue = ref(props.startTab);
    const selectedHover = ref(props.startTab);

    const tabsAlign = computed(() => {
      let result = 'justify-start';
      switch (props.align) {
        case 'center':
          result = 'justify-center';
          break;
        case 'right':
          result = 'justify-end';
          break;
        case 'between':
          result = 'justify-between';
          break;
        case 'around':
          result = 'justify-around';
          break;
      }
      return result;
    });

    const columnGap = computed(() => {
      return props.columnsGap || '';
    });

    const tabWidth = computed(() => {
      if (props.tabsWidth) {
        return props.tabsWidth;
      } else {
        return '';
      }
    });

    const badgeType = (index: number, quantity: number) => {
      let result = 'primary-100';
      if (selectedHover.value === index && quantity > 0) result = 'primary-dark';
      if (selectedValue.value === index) result = 'primary';
      return result;
    };

    const selTab = (index: number, quantity: number) => {
      if (props.showBadges && quantity > 0) {
        selectedValue.value = index;
        emit('changeTab', index);
      }

      if (!props.showBadges) {
        selectedValue.value = index;
        emit('changeTab', index);
      }
    };

    return {
      selectedHover,
      selectedValue,
      tabsAlign,
      tabWidth,
      columnGap,
      badgeType,
      selTab,
    };
  },
});
