import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex space-x-8 mb-8"
}
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = {
  for: "new-other-project",
  class: "ml-2"
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = {
  for: "new-project-scratch",
  class: "ml-2"
}
const _hoisted_6 = { class: "w-full flex justify-between items-center plan-gap-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScratchContainer = _resolveComponent("ScratchContainer")!
  const _component_ProjectContainer = _resolveComponent("ProjectContainer")!
  const _component_Form = _resolveComponent("Form")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleOpen(false))),
    title: _ctx.creationTypeModal === 'creation' ? _ctx.$t('new-project') : _ctx.$t('settings'),
    size: "sm-auto"
  }, {
    content: _withCtx(() => [
      _createVNode(_component_Form, {
        ref: "form",
        class: "mt-2 mr-4 space-y-4",
        sub: ""
      }, {
        default: _withCtx(() => [
          (_ctx.type && _ctx.projectsList.length !== 0 && _ctx.creationTypeModal === 'creation')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _withDirectives(_createElementVNode("input", {
                    class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:border-gray-600",
                    id: "new-other-project",
                    type: "radio",
                    value: "project",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentType) = $event)),
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetForm && _ctx.resetForm(...args)))
                  }, null, 512), [
                    [_vModelRadio, _ctx.currentType]
                  ]),
                  _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('new-from-other-project')), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _withDirectives(_createElementVNode("input", {
                    class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:border-gray-600",
                    id: "new-project-scratch",
                    type: "radio",
                    value: "scratch",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentType) = $event)),
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.resetForm && _ctx.resetForm(...args)))
                  }, null, 512), [
                    [_vModelRadio, _ctx.currentType]
                  ]),
                  _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('new-from-scratch')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.currentType === 'scratch')
            ? (_openBlock(), _createBlock(_component_ScratchContainer, {
                key: 1,
                newProject: _ctx.newProject,
                creationTypeModal: _ctx.creationTypeModal,
                "onUpdate:newProject": _ctx.handleUpdateNewProject
              }, null, 8, ["newProject", "creationTypeModal", "onUpdate:newProject"]))
            : _createCommentVNode("", true),
          (_ctx.currentType === 'project')
            ? (_openBlock(), _createBlock(_component_ProjectContainer, {
                key: 2,
                newProject: _ctx.newProject,
                "onUpdate:newProject": _ctx.handleUpdateNewProject
              }, null, 8, ["newProject", "onUpdate:newProject"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_plan_button, {
          onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleOpen(false))),
          disabled: _ctx.loading,
          type: "cancel",
          label: _ctx.$t('cancel'),
          class: "w-full"
        }, null, 8, ["disabled", "label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.creationTypeModal === 'creation' ? _ctx.createProject() : _ctx.editProject())),
          type: "primary",
          disabled: !_ctx.isFormValid,
          loading: _ctx.loading,
          label: _ctx.creationTypeModal === 'creation' ? _ctx.$t('create') : _ctx.$t('save'),
          class: "w-full"
        }, null, 8, ["disabled", "loading", "label"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}