
import { defineComponent, computed, onMounted, onUnmounted, ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import SidebarComponent from '@/components/sidebar/Sidebar.vue';

const scrollDirective = {
  mounted(el, binding) {
    el.addEventListener('scroll', binding.value);
  },
  unmounted(el, binding) {
    el.removeEventListener('scroll', binding.value);
  },
}
export default defineComponent({
  name: 'PlanDefaultView',
  components: { SidebarComponent, PlanIcon },
  props: {
    title: { type: String, required: false, default: '' },
    showGoTop: { type: Boolean, required: false, default: false },
    showBottomSelectionBar: { type: Boolean, required: false, default: false },
    showBottomToast: { type: Boolean, required: false, default: false },
    showFooter: { type: Boolean, required: false, default: false },
    maxWidthHeader: { type: Boolean, required: false, default: false },
    maxWidthContent: { type: Boolean, required: false, default: false },
  },
  directives: {
    scroll: scrollDirective,
  },
  setup () {
    const planDefaultViewRef = ref<Element | null>(null)
    const store = useStore()
    const windowWidth = ref(window.innerWidth)

    const planDefaultViewScroll = computed(() => {
      return store.getters['UserStore/getPlanDefaultViewScrollTop']
    })

    const planDefaultViewStyle = computed(() => {
      let sidebarWidth = windowWidth.value < 1280 ? '0rem' : store.getters['UserStore/getCollapsedSidebar'] ? '64px' : '274px';
      let contentWidth = windowWidth.value < 1280 ? '100vw' : `calc(100vw - ${sidebarWidth})`;

      return {
        'left': sidebarWidth,
        'width': contentWidth
      };
    })

    const planDefaultViewFooterStyle = computed(() => {
      let sidebarWidth = windowWidth.value < 1280 ? '0rem' : store.getters['UserStore/getCollapsedSidebar'] ? '64px' : '274px';
      let contentWidth = windowWidth.value < 1280 ? '100vw' : `calc(100vw - ${sidebarWidth})`;

      return {
        width: contentWidth
      }
    })

    const planBottomSpaceSelectionBar = computed(()=>{
      return store.getters['UserStore/getImpersonatedUserId'] ? 'mb-12' : '';

    });

    const planDefaultViewBottomSelectionBarStyle = computed(() => {
      let sidebarWidth = windowWidth.value < 1280 ? '0rem' : store.getters['UserStore/getCollapsedSidebar'] ? '64px' : '274px';
      let contentWidth = windowWidth.value < 1280 ? '100vw' : `calc(100vw - ${sidebarWidth})`;

      return {
        width: contentWidth
      }
    })

    const resizeWindow = () => {
      windowWidth.value = window.innerWidth
    }

    onMounted(() => {
      window.addEventListener('resize', resizeWindow);
      nextTick(() => {
        planDefaultViewRef.value = document.querySelector('.plan-default-view');
      })
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeWindow);
    });

    const changePlanDefaultViewScroll = () => {
      store.commit('UserStore/updatePlanDefaultViewScrollTop', planDefaultViewRef.value?.scrollTop)
    }

    const resetScrollVertical = () => {
      planDefaultViewRef.value?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    return {
      planBottomSpaceSelectionBar,
      planDefaultViewScroll,
      planDefaultViewStyle,
      planDefaultViewFooterStyle,
      planDefaultViewBottomSelectionBarStyle,
      changePlanDefaultViewScroll,
      resetScrollVertical
    }
  }
})
