import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "flex flex-col plan-gap-sm",
  style: {"max-height":"400px"}
}
const _hoisted_2 = { class: "flex justify-end items-center" }
const _hoisted_3 = { class: "flex flex-col justify-center items-center plan-gap-sm plan-p-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_header_search = _resolveComponent("plan-header-search")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.$t('search-by-warehouse'),
    size: "lg-auto",
    "content-without-padding": "",
    hideCloseButton: ""
  }, {
    "header-near-title": _withCtx(() => [
      _createVNode(_component_plan_header_search, {
        onChangeQuery: _ctx.changeQuery,
        "old-query": _ctx.query,
        placeholder: _ctx.$t('search-warehouse'),
        class: "plan-ml-md"
      }, null, 8, ["onChangeQuery", "old-query", "placeholder"])
    ]),
    "header-buttons": _withCtx(() => [
      _createElementVNode("div", {
        class: "plan-text-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openAddFacility && _ctx.openAddFacility(...args)))
      }, _toDisplayString(`+ ${_ctx.$t('add-warehouse')}`), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.warehouses.length > 0)
          ? (_openBlock(), _createBlock(_component_plan_default_table, {
              key: 0,
              items: _ctx.warehouses,
              headers: _ctx.headers
            }, {
              default: _withCtx((props) => [
                _createElementVNode("td", null, _toDisplayString(props.item.companyWarehouse), 1),
                _createElementVNode("td", null, _toDisplayString(props.item.address), 1),
                _createElementVNode("td", null, _toDisplayString(props.item.location?.city), 1),
                _createElementVNode("td", null, _toDisplayString(props.item.location?.state?.name), 1),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_plan_button, {
                      onOnClick: ($event: any) => (_ctx.getLocation(props.item)),
                      type: "primary",
                      label: _ctx.$t('select'),
                      style: {"min-width":"100px"}
                    }, null, 8, ["onOnClick", "label"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["items", "headers"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.loadingWarehouses)
            ? (_openBlock(), _createBlock(_component_plan_loading, { key: 0 }))
            : _createCommentVNode("", true),
          (
              !_ctx.loadingWarehouses && _ctx.warehouses.length > 0 && _ctx.warehouses.length < _ctx.totalWarehouses
            )
            ? (_openBlock(), _createBlock(_component_plan_button, {
                key: 1,
                onOnClick: _ctx.loadMoreWarehouses,
                loading: _ctx.loadingWarehouses,
                type: "primary-outline",
                "full-rounded": "",
                label: _ctx.$t('show-more-warehouses'),
                style: {"width":"328px"}
              }, null, 8, ["onOnClick", "loading", "label"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_plan_button, {
        onOnClick: _ctx.close,
        type: "primary",
        label: _ctx.$t('cancel')
      }, null, 8, ["onOnClick", "label"])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}