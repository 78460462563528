import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col justify-start items-start plan-gap-sm",
  style: {"width":"98%"}
}
const _hoisted_3 = { class: "flex justify-start items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_plan_icon, {
          key: 0,
          name: "spinner",
          "icon-color": "#8A8B97",
          rotate: "",
          "is-svg": ""
        }))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.ranking.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ranking, (company, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: company,
              class: _normalizeClass(["flex justify-between items-center plan-gap-sm", index === 1 ? 'opacity-50 plan-pl-sm':''])
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(index + 1), 1),
                _createVNode(_component_plan_avatar, {
                  "user-name": company.companyName,
                  "max-characters":  index === 0 ? 14 : 11
                }, null, 8, ["user-name", "max-characters"])
              ]),
              (company.chat !== null)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openChat && _ctx.openChat(...args)), ["stop"]))
                  }, [
                    _createVNode(_component_plan_button_icon, {
                      icon: "chat",
                      type: "transparent-gray-800",
                      size: "15",
                      "icon-color": "black",
                      "is-svg": ""
                    })
                  ]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}