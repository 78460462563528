import AddLanes from '@/modules/rfq/AddLanes.vue';
import LaneDetail from '@/modules/rfq/LaneDetail.vue';
import LanesRFQ from '@/modules/rfq/LanesRFQ.vue';
import LanesView from '@/modules/rfq/LanesView.vue';
import NewLoadLanes from '@/modules/rfq/newLoad/NewLoad.vue';
import Planification from '@/modules/planning/plannification/Planification.vue';
import RequestForQuote from '@/modules/rfq/RequestForQuote.vue';


const RFQRouter = [
  {
    path: 'lanesRFQ',
    name: 'LanesView',
    component: LanesView,
    redirect: { name: 'lanesRFQ' },
    children: [
      {
        path: '',
        name: 'lanesRFQ',
        component: LanesRFQ,
      },
      {
        path: 'group/:id/:hash/:code',
        name: 'lanesRFQIdCode',
        component: LanesRFQ,
        props: true,
      },
      {
        path: 'group/:id',
        name: 'lanesRFQId',
        component: LanesRFQ,
        props: true,
      },
      {
        path: ':id/addLanes',
        name: 'addLanes',
        component: AddLanes,
        props: true,
      },
      {
        path: 'requestforquote',
        name: 'RequestForQuote',
        component: RequestForQuote,
        props: true,
      },
      {
        path: 'newLoad/:laneId',
        name: 'NewLoadLanes',
        component: NewLoadLanes,
      },
      {
        path: 'laneDetail/:laneId',
        name: 'LaneDetail',
        component: LaneDetail,
        props: true,
        children: [
          {
            path: 'service/:id',
            name: 'laneService',
            component: Planification,
            props: true,
          },
        ],
      },
    ],
  },
];

export default RFQRouter;
