import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_load_acceptation_step = _resolveComponent("plan-carrier-load-acceptation-step")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_carrier_load_acceptation_step, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToStep('myData'))),
      "step-data": _ctx.$t('my-data'),
      "without-left": "",
      "is-active": _ctx.activeStep === 'carrierPackage' || _ctx.activeStep === 'myData' || _ctx.activeStep === 'driverAndVehicle'
    }, null, 8, ["step-data", "is-active"]),
    _createVNode(_component_plan_carrier_load_acceptation_step, {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToStep('carrierPackage'))),
      "step-data": _ctx.$t('carrier-package'),
      "is-active": _ctx.activeStep === 'carrierPackage' || _ctx.activeStep === 'driverAndVehicle'
    }, null, 8, ["step-data", "is-active"]),
    _createVNode(_component_plan_carrier_load_acceptation_step, {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToStep('driverAndVehicle'))),
      "step-data": _ctx.$t('driver-and-vehicle'),
      "without-right": "",
      "is-active": _ctx.activeStep === 'driverAndVehicle'
    }, null, 8, ["step-data", "is-active"])
  ]))
}