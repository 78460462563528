import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-white rounded-xl p-5 xl:p-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlansNewLoadHeader = _resolveComponent("PlansNewLoadHeader")!
  const _component_PlanStepsTab = _resolveComponent("PlanStepsTab")!
  const _component_PlanShipmentData = _resolveComponent("PlanShipmentData")!
  const _component_PlanQuoteStep = _resolveComponent("PlanQuoteStep")!
  const _component_plan_default_view = _resolveComponent("plan-default-view")!

  return (_openBlock(), _createBlock(_component_plan_default_view, {
    "show-go-top": true,
    "show-footer": true
  }, {
    "header-content": _withCtx(() => [
      _createVNode(_component_PlansNewLoadHeader)
    ]),
    "view-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PlanStepsTab, {
          activeTab: _ctx.currentView,
          onChange: _ctx.handleChangeView
        }, null, 8, ["activeTab", "onChange"]),
        (_ctx.currentView === 'shipment')
          ? (_openBlock(), _createBlock(_component_PlanShipmentData, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.currentView === 'quote')
          ? (_openBlock(), _createBlock(_component_PlanQuoteStep, {
              key: 1,
              loadTemplate: _ctx.loadTemplate
            }, null, 8, ["loadTemplate"]))
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => []),
    _: 1
  }))
}