
import { Field, ErrorMessage } from 'vee-validate';
import { ref, computed, watchEffect, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import i18n from '@/resources/locales';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';

export default {
  mixins: [BaseComponent, CountryMixins],
  components: {
    PhoneNumberSelector,
    Dialog,
    DialogPanel,
    ErrorMessage,
    Field,
    TransitionChild,
    TransitionRoot,
    PlanButton,
    PlanDefaultModal,
  },
  props: {
    loadSelected: {
      type: Object,
    },
    open: Boolean,
  },
  setup(props, { emit }) {
    // Global
    const store = useStore();
    const $t = i18n.global.t;
    // Data
    const allowUploadDocument = ref(true);
    const uploadDocumentAction = ref(false);
    const message = ref('');
    const length = 200;
    const showNotifyIncidence = ref(false);
    const FILES = ref({});
    const documentUploaded = ref(null);
    const documentUploadedURL = ref(null);
    const documentFile = ref(null);
    const route = useRoute();

    // Computed

    const data = reactive({
      driver: {} as any,
      showTrackingHistoryModal: false,
      showUpdatePositionModal: false,
      showUpdateCarrierData: false,
      loadingPostNotifyPosition: false,
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const isShipper = computed(() => {
      return currentUser.value?.roles?.some(
        (role) => role === 'ROLE_SUPER_SHIPPER' || role === 'SHIPPER',
      );
    });

    const handleShowRequestModal = () => {
      if (shipment.value?.driver?.email) {
        store
          .dispatch('LoadsStore/sendEmailDriverIncident', props?.loadSelected?.id)
          .then((response) => {
            store.dispatch('UserStore/showToast', $t('email-sended-driver'));
          });
      } else {
        //Check Pre registered data
        data.driver.phonePrefix = shipment.value?.driver?.prefix || '1';
        data.driver.phone = shipment.value?.driver?.phone || '';
        data.driver.showContactInformation = shipment.value?.driver?.showContactInformation;

        data.showUpdateCarrierData = true;
      }
    };

    // Methods

    const changePhone = (type, phone) => {
      switch (type) {
        case 'prefix':
          data.driver.phonePrefix = phone.prefix;
          break;
        case 'number':
          data.driver.phone = phone.number;
          break;
        default:
          return;
      }
    };

    const deleteFile = (url) => {
      delete FILES.value[url];
    };

    const fileChange = (event) => {
      for (const file of event.target.files) {
        if (file.type !== 'application/pdf') {
          FILES.value[URL.createObjectURL(file)] = file;
        }
      }
    };

    const toggleOpen = (isOpen) => {
      emit('setOpen', isOpen);
    };

    const isDriver = computed(() => {
      return route.path.includes('driver');
    });

    const shipment = computed(() => {
      return store.getters['ShipmentStore/shipment'];
    });

    const UpdateCarrier = () => {
      store
        .dispatch('CompanyStore/putDriver', {
          id: shipment.value.driver.id,
          params: {
            email: data.driver.email,
            phonePrefix: data.driver.prefix || '1',
            phone: data.driver.phone,
            showContactInformation: data.driver.showContactInformation,
          },
        })
        .then(async () => {
          await store.dispatch('ShipmentStore/shipment', { id: shipment.value.id });
          store.dispatch('UserStore/showToast', $t('Updated Correctly.')).then(() => {
            data.showUpdateCarrierData = false;
          });
        });
    };

    const checkDriver = computed(() => {
      return data.driver.email !== '' && data.driver.phonePrefix !== '' && data.driver.phone !== '';
    });

    const notifyIncidence = async () => {
      if (!uploadDocumentAction.value) {
        uploadDocumentAction.value = true;

        if (message.value !== '') {
          try {
            const response = await store.dispatch('UserStore/postIncidence', {
              description: message.value,
              stop: props.loadSelected,
            });
            if (Object.keys(FILES.value).length > 0) {
              let promises = [] as any;
              for (const file of Object.values(FILES.value)) {
                promises.push(
                  store.dispatch('UserStore/postIncidenceFile', {
                    entityId: response.id,
                    file: file,
                  }),
                );
              }
              try {
                await Promise.all(promises);
                BaseComponent.methods?.showToastSuccess($t('incidence-correctly-sent'));
                FILES.value = {};
              } catch (err) {
                let error = err as any
                BaseComponent.methods?.showToastError(error?.response?.data?.detail);
              } finally {
                uploadDocumentAction.value = false;
              }
            } else {
              BaseComponent.methods?.showToastSuccess($t('incidence-correctly-sent'));
              FILES.value = {};
            }
          } catch (err) {
            let error = err as any
            BaseComponent.methods?.showToastError(error?.response?.data?.detail);
          } finally {
            toggleOpen(false);
          }
        } else {
          BaseComponent.methods?.showToastError($t('information-required'));
        }
      }
    };

    return {
      ...toRefs(data),
      checkDriver,
      allowUploadDocument,
      uploadDocumentAction,
      message,
      handleShowRequestModal,
      length,
      showNotifyIncidence,
      FILES,
      isDriver,
      shipment,
      documentUploaded,
      documentUploadedURL,
      documentFile,
      currentUser,
      isShipper,
      deleteFile,
      fileChange,
      toggleOpen,
      notifyIncidence,
      UpdateCarrier,
      changePhone,
    };
  },
};
