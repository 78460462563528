import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-bold text-center md:text-2xl" }
const _hoisted_2 = { class: "text-center font-semibold" }
const _hoisted_3 = { class: "mt-4 flex flex-col justify-center items-center gap-4" }
const _hoisted_4 = { class: "text-center font-semibold bg-sky-200 w-fit rounded-full px-2" }
const _hoisted_5 = { class: "text-center px-10" }
const _hoisted_6 = { class: "text-center text-sm text-red-500 px-10" }
const _hoisted_7 = { class: "flex justify-between gap-4 mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    show: _ctx.showModal,
    modalSize: "small",
    onCallbackClose: _ctx.onClickClose
  }, {
    title: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('pre-assigned')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('(Needs tender’s confirmation)')), 1)
    ]),
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('position-remain-for-tender', {
              position: _ctx.numberSuffixAndColor(_ctx.ranking.quote.position).suffix,
            })), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('an-email-send-tender-selected-offer')), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('if-you-feel-can-revoke')), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_plan_button, {
          class: "w-full min-w-[10rem]",
          size: "md",
          type: "error-outline",
          label: _ctx.$t('revoke'),
          loading: _ctx.loading === 'revoke',
          onOnClick: _ctx.revoke
        }, null, 8, ["label", "loading", "onOnClick"]),
        _createVNode(_component_plan_button, {
          class: _normalizeClass(["w-full min-w-[10rem]", { uppercase: _ctx.ranking.status !== 'ACCEPTING' }]),
          "icon-type": "svg",
          size: "md",
          type: "primary",
          icon: _ctx.ranking.status === 'ACCEPTING' ? 'paper-airplane' : '',
          label: _ctx.ranking.status === 'ACCEPTING' ? _ctx.$t('remind') : _ctx.$t('ok'),
          loading: _ctx.loading === 'remind',
          rowReverse: true,
          "show-icon": _ctx.ranking.status === 'ACCEPTING',
          onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ranking.status === 'ACCEPTING' ? _ctx.confirmRanking('remind') : _ctx.onClickClose()))
        }, null, 8, ["class", "icon", "label", "loading", "show-icon"])
      ])
    ]),
    _: 1
  }, 8, ["show", "onCallbackClose"]))
}