
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanDefaultWindowsTab from '@/components/tabs/PlanDefaultWindowsTabs.vue';
import PlanLoading from '@/components/loadings/PlanLoading.vue';

export default defineComponent({
  name: 'PlanDefaultWindows',
  components: { PlanLoading, PlanDefaultWindowsTab, PlanButtonIcon },
  emits: ['close', 'changeTab'],
  props: {
    loading: { type: Boolean, required: false, default: false },
    tabs: { type: Array, required: false },
    showNavRightButtons: { type: Boolean, required: false, default: false },
    showNav: { type: Boolean, required: false, default: false },
    showHeader: { type: Boolean, required: false, default: false },
    showSticky: { type: Boolean, required: false, default: false },
    showNotification: { type: Boolean, required: false, default: false },
    showHeaderContentLeft: { type: Boolean, required: false, default: false },
    showContentLeft: { type: Boolean, required: false, default: false },
    showHeaderContentRight: { type: Boolean, required: false, default: false },
    showContentRight: { type: Boolean, required: false, default: false }
  },
  setup (props, { emit }) {
    const store = useStore()
    const previousSidebarState = store.getters['UserStore/getCollapsedSidebar']

    const collapsedSidebar = computed(() => {
      return store.getters['UserStore/getCollapsedSidebar']
    })

    const changeTab = (newIndex: number) => { emit('changeTab', newIndex) }

    const close = () => { emit('close') }

    const windowWidth = ref(window.innerWidth)

    const resizeWindow = () => {
      windowWidth.value = window.innerWidth
    }

    onMounted(() => {
      window.addEventListener('resize', resizeWindow);
    })

    onUnmounted(() => {
      window.removeEventListener('resize', resizeWindow);
    })

    return {
      collapsedSidebar,
      changeTab,
      close,
      windowWidth
    }
  }
})
