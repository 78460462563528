import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c213b194"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative inline-flex w-[4.5rem]" }
const _hoisted_2 = { class: "rounded-md shadow-sm" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "h-6 w-6"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spin = _resolveComponent("Spin")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, {
    arrow: "",
    placement: "right"
  }, {
    content: _withCtx(({ close }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["py-1", { 'flex flex-wrap w-[400px]': $setup.currentRegion === 'EUROPE' }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getDataCountriesFiltered(), (country, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex w-20 items-center justify-center space-x-2 px-4 py-2 text-sm text-black hover:bg-gray-100 cursor-pointer",
            onClick: ($event: any) => ($setup.handleClick(close, country))
          }, [
            country
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "h-6 w-6",
                  alt: "Burger RFP",
                  src: _ctx.getCountryFlag(country?.iso)
                }, null, 8, _hoisted_8))
              : _createCommentVNode("", true),
            _createElementVNode("p", null, _toDisplayString(country.iso), 1)
          ], 8, _hoisted_7))
        }), 128))
      ], 2)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass($props.countrySelectorClasses),
            disabled: $props.dataCountries.length === 0
          }, [
            ($props.dataCountries.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_Spin, { class: "h-6 w-6 !ml-0 !mr-1" })
                ]))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "h-6 w-6",
                  alt: "country",
                  src: _ctx.getCountryFlag($setup.countryValue)
                }, null, 8, _hoisted_5)),
            _createElementVNode("img", {
              alt: "Arrow down",
              class: "ml-2",
              src: _ctx.getAsset.icArrowDownChevron
            }, null, 8, _hoisted_6)
          ], 10, _hoisted_3)
        ])
      ])
    ]),
    _: 1
  }))
}