
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import { useStore } from 'vuex';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import { useI18n } from 'vue-i18n';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import { useRoute } from 'vue-router';

interface planHistoricalPaidRate {
  averageStats: Array<any>;
  goal: null | number;
  loadingAverageStats: boolean;
  type: string;
}

export default defineComponent({
  name: 'PlanHistoricalPaidRate',
  components: { PlanInputNumber, PlanLoading, PlanSelectSimple, PlanIcon },
  props: {
    titleRate: { type: String, required: true, default: '' },
    origin: { type: String, required: true, default: '' },
    destiny: { type: String, required: true, default: '' },
    trailer: { type: String, required: true, default: '' },
  },
  emits: ['changeGoal'],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const translate = useI18n();

    const currentLoad = computed(() => store.getters['LoadsStore/currentLoad']);

    const data = reactive<planHistoricalPaidRate>({
      averageStats: [],
      goal: null,
      loadingAverageStats: false,
      type: 'LAST_MONTH',
    });

    if (currentLoad.value?.rateGoal) {
      data.goal = currentLoad.value.rateGoal;
      emit('changeGoal', data.goal);
    }

    const changeGoal = () => {
      emit('changeGoal', data.goal);
    };

    const changePeriod = (newType: string) => {
      data.type = newType;
      getShipmentAverageStats();
    };

    const handleUpdateRate = () => {
      emit('changeGoal', data.goal);
      store.dispatch('LoadsStore/putRateGoal', {
        id: route.params.id,
        rateGoal: data.goal,
      });
    };

    const currencyToShow = computed(() => {
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? '$' : '€';
    });

    const getFormattedTotal = (total: number) => {
      const currency = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'USD' : 'EUR';

      const formatter = new Intl.NumberFormat(currency === 'USD' ? 'en' : 'es', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

      return formatter.format(total);
    };

    const getShipmentAverageStats = () => {
      data.loadingAverageStats = true;
      store
        .dispatch('LoadsStore/getShipmentAverageStats', {
          origin: props.origin,
          destiny: props.destiny,
          trailer: props.trailer,
          period: data.type,
        })
        .then((response) => {
          data.averageStats = response;
          if (response.length > 0 && data.goal === null) {
            if (response[0].average !== 0) {
              data.goal = Math.round(response[0].average);
              emit('changeGoal', data.goal);
            }
          }
        })
        .finally(() => {
          data.loadingAverageStats = false;
        });
    };

    const types = computed(() => {
      return [
        { label: translate.t('last-average-stats.LAST_MONTH'), value: 'LAST_MONTH' },
        { label: translate.t('last-average-stats.LAST_3_MONTHS'), value: 'LAST_3_MONTHS' },
        { label: translate.t('last-average-stats.LAST_6_MONTHS'), value: 'LAST_6_MONTHS' },
        { label: translate.t('last-average-stats.LAST_YEAR'), value: 'LAST_YEAR' },
      ];
    });

    getShipmentAverageStats();

    return {
      ...toRefs(data),
      changeGoal,
      changePeriod,
      currencyToShow,
      getFormattedTotal,
      getShipmentAverageStats,
      handleUpdateRate,
      types,
    };
  },
});
