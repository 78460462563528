import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-center plan-gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_loading_full_screen = _resolveComponent("plan-carrier-loading-full-screen")!
  const _component_plan_carrier_load_header = _resolveComponent("plan-carrier-load-header")!
  const _component_plan_carrier_load_sub_header = _resolveComponent("plan-carrier-load-sub-header")!
  const _component_plan_carrier_load_header_notification = _resolveComponent("plan-carrier-load-header-notification")!
  const _component_plan_carrier_load_details = _resolveComponent("plan-carrier-load-details")!
  const _component_plan_carrier_load_stop_data = _resolveComponent("plan-carrier-load-stop-data")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loadingLoad)
      ? (_openBlock(), _createBlock(_component_plan_carrier_loading_full_screen, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.loadingLoad && _ctx.loadData !== null)
      ? (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
          key: 1,
          sidebar: ""
        }, {
          "header-content": _withCtx(() => [
            _createVNode(_component_plan_carrier_load_header)
          ]),
          "subheader-content": _withCtx(() => [
            _createVNode(_component_plan_carrier_load_sub_header),
            false
              ? (_openBlock(), _createBlock(_component_plan_carrier_load_header_notification, {
                  key: 0,
                  message: "Header esto es un mensaje fleje de largo para comprobar que todo se esté viendo correctamente el mensaje Header esto es un mensaje fleje de largo para comprobar que todo se esté viendo correctamente el mensaje",
                  class: "plan-mt-md"
                }))
              : _createCommentVNode("", true)
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_plan_carrier_load_details),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stopsData, (stopData) => {
                return (_openBlock(), _createBlock(_component_plan_carrier_load_stop_data, {
                  key: stopData.stop,
                  "to-city": stopData.toCity,
                  stop: stopData.stop
                }, null, 8, ["to-city", "stop"]))
              }), 128))
            ])
          ]),
          footer: _withCtx(() => []),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}