
import { ref, computed, watchEffect, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import i18n from '@/resources/locales';
// Base
import BaseComponent from '@/base/BaseComponent';

export default {
  mixins: [BaseComponent],
  components: {},
  props: {
    loadSelected: Object,
    open: Boolean,
    stopType: String,
  },
  setup(props, { emit }) {
    //* Global /
    const store = useStore();
    const $t = i18n.global.t;
    const route = useRoute();
    const router = useRouter();

    const finishSuplanting = () => {
      const previousRoute = store.state.UserStore.previousRouteImpersonating;
      store.commit('UserStore/setUserIdentification', null);
      store.commit('UserStore/setIsTheAdminImpersonating', false);
      if (previousRoute) {
        router.push(previousRoute);
      } else {
        router.push({ name: 'shippersList' });
      }
    };

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed((): any => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const isSuplanting = computed((): any => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    return { currentUser, isSuplanting, finishSuplanting };
  },
};
