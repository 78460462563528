<template>
  <TransitionRoot
    as="template"
    :show="true"
  >
    <Dialog
      as="div"
      class="relative z-[100]"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="px-8 py-6 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="flex flex-row justify-between">
                <h3 class="text-xl font-semibold mb-4">
                  {{ paymentMethod.length === 0 ? $t('add-credit-card') : '' }}
                </h3>

                <XCircleIcon
                  class="w-8 h-8 cursor-pointer"
                  @click="$emit('close')"
                />
              </div>

              <form
                @submit.prevent="submitPayment"
                id="payment-form"
              >
                <div class="mb-4">
                  <label
                    for="cardNumber"
                    class="block text-sm font-medium text-gray-600"
                    >{{ $t('card-number') }}</label
                  >
                  <div
                    id="card-element"
                    class="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  ></div>
                </div>

                <button
                  type="submit"
                  class="bg-sky-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                >
                  {{ $t('add-card') }}

                  <img
                    v-if="showSpinner"
                    class="inline w-4 h-4 mr-3 ml-2 text-white animate-spin"
                    :src="getAsset.icSpinner"
                    alt="spinner"
                  />
                </button>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { computed, defineComponent, onMounted, ref, nextTick } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';

import BaseComponent from '@/base/BaseComponent';
import i18n from '@/resources/locales';

export default defineComponent({
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    planSelected: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    /** Global */

    const $t = i18n.global.t;
    const store = useStore();
    const stripe = ref(null);
    const elements = ref(null);
    const cardElement = ref(null);
    const planSelected = ref(props.planSelected);
    const showSpinner = ref(false);

    /** Methods */

    const submitPayment = async () => {
      showSpinner.value = true;

      try {
        const { paymentMethod, error } = await stripe.value.createPaymentMethod({
          type: 'card',
          card: cardElement.value,
        });

        if (error) {
          console.error(error);
        } else {
          store
            .dispatch('PaymentStore/attachPayment', { paymentMethodId: paymentMethod.id })
            .then((response) => {
              BaseComponent.methods?.showToastSuccess($t('credit-card-added'));
              if (planSelected.value !== '') {
                store
                  .dispatch('PaymentStore/subscription', { type: planSelected.value })
                  .then((response) => {
                    BaseComponent.methods?.showToastSuccess(
                      $t('subscription-done', { plan: planSelected.value }),
                    );
                    store.dispatch('PaymentStore/paymentMethod');
                    showSpinner.value = false;
                    emit('close');
                    setTimeout(() => {
                      resetToken();
                    }, '1500');
                  });
              } else {
                store.dispatch('PaymentStore/paymentMethod');
                emit('close');
              }
            })
            .catch((err) => {
              BaseComponent.methods?.showToastError(err.response.data['hydra:description']);
            })
            .finally(() => {
              showSpinner.value = false;
            });
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    };

    const initializeStripe = async () => {
      stripe.value = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
      elements.value = stripe.value.elements();
      cardElement.value = elements.value.create('card', {
        hidePostalCode: true,
      });
      cardElement.value.mount('#card-element');
    };

    const resetToken = async () => {
      const refreshToken = localStorage.refresh_token;
      const refresh = await store.dispatch('AuthStore/refreshToken', refreshToken);
    };

    /** Computed */
    const paymentMethod = computed(() => {
      return store.getters['PaymentStore/getPaymentMethod'];
    });

    onMounted(async () => {
      await nextTick();
      initializeStripe();
    });

    return {
      initializeStripe,
      paymentMethod,
      resetToken,
      showSpinner,
      submitPayment,
    };
  },
});
</script>
