
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'incidenceTooltipContent',
  props: {
    currentProject: {
      type: Object,
      required: true,
    },
    fetchDataOnHover: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Global
    const store = useStore();

    // Variables
    const currentProject = ref(props.currentProject);
    const loading = ref(true);
    const testLanesTooltip = ref([] as any);

    // Methods
    const fetchData = async () => {
      const existingItemIndex = claimProjectInfo.value.findIndex(
        (item) => item.projectId === currentProject.value.project.id,
      );

      if (existingItemIndex === -1 || claimProjectInfo.value.length === 0) {
        const response = await store.dispatch(
          'RFPStore/getProjectClaims',
          currentProject.value.project.id,
        );
        testLanesTooltip.value = response;
      } else {

        const existingItem = claimProjectInfo.value[existingItemIndex];
        testLanesTooltip.value = existingItem.items;
      }

      loading.value = false;
    };

    const openLaneDetail = (object) =>
      emit('openLaneDetail', {
        laneId: object.lanes,
        loadId: object.load,
        projectId: currentProject.value.project.id,
      });

    // Computed

    const claimProjectInfo = computed(() => {
      return store.getters['RFPStore/getClaimProjectInfo'];
    });

    // Lifecycle

    onMounted(() => {
      if (props.fetchDataOnHover) {
        fetchData();
      }
    });

    // Watchers
    watch(
      () => props.fetchDataOnHover,
      (newValue) => {
        if (newValue) {
          fetchData();
        }
      },
    );

    return {
      loading,
      testLanesTooltip,
      openLaneDetail,
    };
  },
});
