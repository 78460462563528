
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';

interface Insurance {
  file: File;
}

export default defineComponent({
  name: 'DriverVehicle',
  components: {
    PhoneNumberSelector,
    ErrorMessage,
    Field,
    Form,
    ModalConfirm,
    Spin,
    PlanLoading,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    step: {
      type: Number,
    },
  },
  data() {
    return {
      errorOnSubmit: false,
      isSending: false,
      driverId: null,
      vehicleId: null,
      idToDelete: null,
      showDelete: false,
      addDriver: false,
      addVehicle: false,
      sending: false,
      driver: {
        name: null,
        surname: null,
        drivingLicense: null,
        email: null,
        phonePrefix: null,
        phone: null,
        showContactInformation: false,
      },
      driverSelected: null as any,
      trailer: {
        trailerType: { id: null },
        trailerPlate: null,
        licensePlate: null,
      },
      vehicleSelected: null,
      validating: false,
      isUpdatingVehicle: false,
      isUpdatingDriver: false,
      isCreatingVehicle: false,
      isCreatingDriver: false,
      licenseFrontFile: {} as File,
      licenseFrontInsurance: {} as Insurance,
      licenseFrontUrl: '',
      licenseFrontLoading: false,
      licenseBackFile: {} as File,
      licenseBackInsurance: {} as Insurance,
      licenseBackUrl: '',
      licenseBackLoading: false,
    };
  },
  beforeCreate() {
    this.$store.dispatch('LoadsStore/trailers');
  },
  created() {
    this.$store.dispatch('CompanyStore/driversList', this.company.id);
    this.$store.dispatch('CompanyStore/vehiclesList', this.company.id);

    if (
      this.selectedOption === 'BROKER' &&
      this.brokerVehicle !== null &&
      this.brokerDriver !== null
    ) {
      this.driverSelected =
        this.shipment.driver?.id && this.shipment.driver ? this.shipment.driver.id : null;
      this.vehicleSelected =
        this.shipment.vehicle?.id && this.shipment.vehicle ? this.shipment.vehicle.id : null;
    }
    this.getFile(this.driverSelectedMedia);
  },
  computed: {
    brokerDriver(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.driver !== 'undefined'
      ) {
        return [this.shipment.driver];
      } else {
        return null;
      }
    },
    brokerVehicle(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.vehicle !== 'undefined'
      ) {
        return [this.shipment.vehicle];
      } else {
        return null;
      }
    },
    company(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    drivers(): any {
      return this.$store.getters['CompanyStore/getDriversList'];
    },
    load(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.loads[0].isConsigneeFilled !== 'undefined'
      ) {
        return this.shipment.loads[0];
      } else {
        return null;
      }
    },
    selectedOption(): string {
      return this.company.type;
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
    shipmentId(): any {
      return this.$route.params.id;
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
    vehicles(): any {
      return this.$store.getters['CompanyStore/getVehiclesList'];
    },
    driverSelectedMedia(): any {
      if (this.driverSelected) {
        this.getFile(this.drivers.find((driver) => driver.id === this.driverSelected));
        return this.drivers.find((driver) => driver.id === this.driverSelected);
      }
      return undefined;
    },
  },
  methods: {
    changePhone(type, phone) {
      switch (type) {
        case 'prefix':
          this.driver.phonePrefix = phone.prefix;
          break;
        case 'number':
          this.driver.phone = phone.number;

          break;
        default:
          return;
      }
    },
    async getFile(driverSelectedMedia) {
      this.licenseFrontUrl = '';
      this.licenseBackUrl = '';
      const index = this.drivers.indexOf(driverSelectedMedia);
      if (
        driverSelectedMedia &&
        driverSelectedMedia.mediaObjects &&
        driverSelectedMedia.mediaObjects.length > 0
      ) {
        for (const mediaObject of driverSelectedMedia.mediaObjects) {
          if (mediaObject.type === 'DRIVER_LICENSE_BACK') {
            this.licenseBackLoading = true;
          } else if (mediaObject.type === 'DRIVER_LICENSE_FRONT') {
            this.licenseFrontLoading = true;
          }
        }

        await this.$store
          .dispatch('CompanyStore/getDownloadFileDriver', {
            id: driverSelectedMedia.mediaObjects[0].id,
            class: 'DRIVER',
          })
          .then(async (response) => {
            if (driverSelectedMedia.mediaObjects[0].type === 'DRIVER_LICENSE_FRONT') {
              this.licenseFrontInsurance.file = new File(
                [response],
                driverSelectedMedia.mediaObjects[0].name,
              );
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(this.licenseFrontInsurance.file);
              (this.$refs['licenseFrontFile' + index] as any)[0].files = dataTransfer.files;
              this.licenseFrontFile = this.licenseFrontInsurance.file;

              const result = await this.$store.dispatch(
                'CompanyStore/getDownloadThumbnailBigDriver',
                {
                  id: driverSelectedMedia.mediaObjects[0].id,
                  class: 'DRIVER',
                },
              );
              this.licenseFrontUrl = URL.createObjectURL(result);
              this.licenseFrontLoading = false;
            } else {
              this.licenseBackInsurance.file = new File(
                [response],
                driverSelectedMedia.mediaObjects[0].name,
              );
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(this.licenseBackInsurance.file);
              (this.$refs['licenseBackFile' + index] as any)[0].files = dataTransfer.files;
              this.licenseBackFile = this.licenseBackInsurance.file;

              const result = await this.$store.dispatch(
                'CompanyStore/getDownloadThumbnailBigDriver',
                {
                  id: driverSelectedMedia.mediaObjects[0].id,
                  class: 'DRIVER',
                },
              );
              this.licenseBackUrl = URL.createObjectURL(result);
              this.licenseBackLoading = false;
            }

            if (driverSelectedMedia.mediaObjects[1].type === 'DRIVER_LICENSE_FRONT') {
              this.licenseFrontInsurance.file = new File(
                [response],
                driverSelectedMedia.mediaObjects[1].name,
              );
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(this.licenseFrontInsurance.file);
              (this.$refs['licenseFrontFile' + index] as any)[0].files = dataTransfer.files;
              this.licenseFrontFile = this.licenseFrontInsurance.file;

              const result = await this.$store.dispatch(
                'CompanyStore/getDownloadThumbnailBigDriver',
                {
                  id: driverSelectedMedia.mediaObjects[1].id,
                  class: 'DRIVER',
                },
              );
              this.licenseFrontUrl = URL.createObjectURL(result);
              this.licenseFrontLoading = false;
            } else {
              this.licenseBackInsurance.file = new File(
                [response],
                driverSelectedMedia.mediaObjects[1].name,
              );
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(this.licenseBackInsurance.file);
              (this.$refs['licenseBackFile' + index] as any)[0].files = dataTransfer.files;
              this.licenseBackFile = this.licenseBackInsurance.file;

              const result = await this.$store.dispatch(
                'CompanyStore/getDownloadThumbnailBigDriver',
                {
                  id: driverSelectedMedia.mediaObjects[1].id,
                  class: 'DRIVER',
                },
              );
              this.licenseBackUrl = URL.createObjectURL(result);
              this.licenseBackLoading = false;
            }
          });
      }
    },
    async createDriver() {
      this.isCreatingDriver = true;
      await this.$store
        .dispatch('CompanyStore/postDriver', { ...this.driver, company: { id: this.company.id } })
        .then(async (response) => {
          BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
          this.isCreatingDriver = false;

          if (!this.addDriver) {
            await this.$store
              .dispatch('ShipmentStore/assignDriver', {
                id: this.shipmentId,
                driver: { id: response.id },
              })
              .catch((err) => {
                err.response.data.violations;
              });
          }

          this.driverId = response.id;
          this.addDriver = false;
          this.$store.dispatch('CompanyStore/driversList', this.company.id);

          this.$store
            .dispatch('CompanyStore/postMediaObjectDriver', {
              entityId: response.id,
              type: 'DRIVER_LICENSE_FRONT',
              file: this.licenseFrontInsurance.file,
            })
            .then(() => {
              BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
              this.addDriver = false;
              this.$store.dispatch('CompanyStore/driversList', this.company.id);
            })
            .catch((err) => {
              console.log(err);
            });
          this.$store
            .dispatch('CompanyStore/postMediaObjectDriver', {
              entityId: response.id,
              type: 'DRIVER_LICENSE_BACK',
              file: this.licenseBackInsurance.file,
            })
            .then(() => {
              BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
              this.addDriver = false;
              this.$store.dispatch('CompanyStore/driversList', this.company.id);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    async createVehicle() {
      this.isCreatingVehicle = true;
      await this.$store
        .dispatch('CompanyStore/postVehicle', { ...this.trailer, company: { id: this.company.id } })
        .then(async (response) => {
          BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
          this.isCreatingVehicle = false;
          if (!this.addVehicle) {
            await this.$store
              .dispatch('ShipmentStore/assignVehicle', {
                id: this.shipmentId,
                vehicle: { id: response.id },
              })
              .catch((err) => {
                err.response.data.violations;
              });
          }
          this.addVehicle = false;
          await this.$store.dispatch('CompanyStore/vehiclesList', this.company.id);
        });
    },
    async updateDriverMedia(driver, type, fileSelected) {
      if (!fileSelected) {
        return;
      }
      let licenseType = type === 'front' ? 'DRIVER_LICENSE_FRONT' : 'DRIVER_LICENSE_BACK';

      const { id } = driver.mediaObjects.find((obj) => obj.type === licenseType);
      const { file } = fileSelected;

      await this.$store.dispatch('CompanyStore/postUpdateMediaObjectDriver', {
        id,
        type: licenseType,
        file,
      });

      this.handleDriverUpdateSuccess();
    },

    handleDriverUpdateSuccess() {
      this.showToastSuccess(this.$t('created-successfully'));
      this.addDriver = false;
      this.$store.dispatch('CompanyStore/driversList', this.company.id);
    },

    async postDriverMedia(driver, type, fileSelected) {
      if (!fileSelected) {
        return;
      }
      const { id } = driver;
      const { file } = fileSelected;

      let licenseType = type === 'front' ? 'DRIVER_LICENSE_FRONT' : 'DRIVER_LICENSE_BACK';

      await this.$store.dispatch('CompanyStore/postMediaObjectDriver', {
        entityId: id,
        type: licenseType,
        file,
      });

      this.handleDriverUpdateSuccess();
    },
    async editDriver(driver) {
      this.isUpdatingDriver = true;

      if (!driver) return;

      const userHaveUploadedFrontMedia = driver.mediaObjects.some(
        (obj) => obj.type === 'DRIVER_LICENSE_FRONT',
      );
      const userHaveUploadedBackMedia = driver.mediaObjects.some(
        (obj) => obj.type === 'DRIVER_LICENSE_BACK',
      );

      const index = this.drivers.indexOf(this.driverSelectedMedia);

      try {
        if (userHaveUploadedFrontMedia && this.licenseFrontInsurance) {
          await this.updateDriverMedia(driver, 'front', this.licenseFrontInsurance);
        } else {
          await this.postDriverMedia(driver, 'front', this.licenseFrontInsurance);
        }

        if (userHaveUploadedBackMedia && this.licenseBackInsurance) {
          await this.updateDriverMedia(driver, 'back', this.licenseBackInsurance);
        } else {
          await this.postDriverMedia(driver, 'back', this.licenseBackInsurance);
        }
      } catch (error) {
        console.error(error);
      }

      this.$store
        .dispatch('CompanyStore/putDriver', {
          id: driver.id,
          params: {
            name: driver.name,
            surname: driver.surname,
            drivingLicense: driver.drivingLicense,
            email: driver.email,
            phonePrefix: driver.prefix,
            phone: driver.phone,
            showContactInformation: driver.showContactInformation,
          },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('updated-correctly'));
          this.isUpdatingDriver = false;
          this.addDriver = false;
          this.$store.dispatch('CompanyStore/driversList', this.company.id).catch((err) => {
            console.log(err);
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });

          this.isUpdatingDriver = false;
        });
    },
    deleteDriver(isDelete) {
      if (isDelete) {
        this.$store
          .dispatch('CompanyStore/deleteDriver', this.idToDelete)
          .then(() => {
            this.$store.dispatch('CompanyStore/driversList', this.company.id);
            this.driverSelected = 0;
          })
          .catch((err) => {
            if (err) {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            }
          })
          .finally(() => {
            this.showDelete = false;
          });
      } else {
        this.showDelete = false;
      }
    },
    deleteVehicle() {
      this.$store
        .dispatch('CompanyStore/deleteVehicles', {
          id: this.vehicleSelected,
        })

        .then(() => {
          this.$store.dispatch('CompanyStore/vehiclesList', this.company.id);
          this.vehicleSelected = null;
          BaseComponent.methods?.showToastSuccess(this.$t('updated-correctly'));
        })
        .catch((err) => {
          console.log(err);
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    },
    getLoadTemperature(load) {
      let temperatureString = '';
      if (
        (!isNaN(load?.maxTemp) &&
          (load?.minTemp
            ? load?.maxTemp !== load?.minTemp
            : load?.maxTemp !== load?.commodityMaster?.minTemp)) ||
        (load?.commodityMaster?.maxTemp &&
          (load?.minTemp
            ? load?.commodityMaster?.maxTemp !== load?.minTemp
            : load?.commodityMaster?.maxTemp !== load?.commodityMaster?.minTemp))
      ) {
        temperatureString =
          !isNaN(load.minTemp) || !isNaN(load.commodityMaster?.minTemp)
            ? this.$t('with-these-temperatures', {
                temp1: this.formatTemperature(load.minTemp ?? load.commodityMaster?.minTemp),
                temp2: this.formatTemperature(load.maxTemp ?? load.commodityMaster?.maxTemp),
              })
            : '';
      } else {
        temperatureString =
          !isNaN(load.minTemp) || !isNaN(load.commodityMaster?.minTemp)
            ? this.$t('with-this-temperature', {
                temp: this.formatTemperature(load.minTemp ?? load.commodityMaster?.minTemp),
              })
            : '';
      }

      return temperatureString;
    },
    omitDriverStep() {
      if (this.isSending) {
        return;
      }
      this.isSending = true;
      this.$store
        .dispatch('ShipmentStore/acceptService', { id: this.shipmentId })
        .then(async (response) => {
          this.isSending = false;
          const id = this.$route.params.id;
          this.$store.dispatch('ShipmentStore/shipment', { id: id });
          this.$store.dispatch('CompanyStore/getCompany', this.currentUser?.company?.id);

          if (!this.errorOnSubmit) {
            let isRFilled = await this.$store.dispatch('LoadsStore/checkRateConfirmation', {
              id: this.load.id,
            });

            if (isRFilled.isConsigneeFilled) {
              this.$emit('isFilledRC');
              this.$emit('setCompleted', true);
            } else {
              this.$emit('setCompleted', true);
            }
          }
        });
    },
    handleChange(type: string) {
      const index = this.drivers.indexOf(this.driverSelectedMedia);
      if (index !== -1) {
        switch (type) {
          case 'licenseFrontFile':
            this.licenseFrontInsurance.file = (
              this.$refs['licenseFrontFile' + index] as any
            )[0].files[0];
            this.licenseFrontUrl = URL.createObjectURL(this.licenseFrontInsurance.file);
            break;
          case 'licenseBackFile':
            this.licenseBackInsurance.file = (
              this.$refs['licenseBackFile' + index] as any
            )[0].files[0];
            this.licenseBackUrl = URL.createObjectURL(this.licenseBackInsurance.file);
            break;
        }
      } else {
        switch (type) {
          case 'licenseFrontFile':
            this.licenseFrontInsurance.file = (this.$refs.licenseFrontFile as any).files[0];
            this.licenseFrontUrl = URL.createObjectURL(this.licenseFrontInsurance.file);
            break;
          case 'licenseBackFile':
            this.licenseBackInsurance.file = (this.$refs.licenseBackFile as any).files[0];
            this.licenseBackUrl = URL.createObjectURL(this.licenseBackInsurance.file);
            break;
        }
      }
    },
    isPhoto(format) {
      return format === 'image/png' || format === 'image/jpg' || format === 'image/jpeg';
    },
    openModalDelete(id) {
      this.idToDelete = id;
      this.showDelete = true;
    },
    updateData() {
      this.isUpdatingVehicle = true;
      const vehicle = this.vehicles.find((v) => v.id === this.vehicleSelected);
      const newTrailer = this.trailerTypes.find((v) => v.id === vehicle.trailerType.id);

      this.$store
        .dispatch('CompanyStore/putVehicle', {
          id: this.vehicleSelected,
          trailerType: newTrailer,
          trailerPlate: vehicle.trailerPlate,
          licensePlate: vehicle.licensePlate,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('updated-correctly'));
          this.isUpdatingVehicle = false;
        })
        .catch((err) => {
          console.log(err);
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          this.isUpdatingVehicle = false;
        });
    },
    async validateForm() {
      if (this.sending) return;
      this.sending = true;
      this.validating = true;
      const validation = await (this.$refs.form as any).validate();

      if (!validation.valid) {
        this.sending = false;
        return;
      }

      // IF IS BROKER NOW JUST CREATE && ASSIGN

      if (this.selectedOption === 'BROKER') {
        if (this.driverSelected === null && this.vehicleSelected === null) {
          if (Object.values(this.driver).every((value) => value === null)) {
            BaseComponent.methods?.showToastError(this.$t('message-fill-fields-driver'));
            this.sending = false;
            return;
          }

          if (this.trailer.licensePlate === null || this.trailer.trailerType.id === null) {
            BaseComponent.methods?.showToastError(this.$t('message-fill-fields-vehicle'));
            this.sending = false;
            return;
          }
          await this.createDriver();
          await this.createVehicle();
          BaseComponent.methods?.showToastSuccess(this.$t('information-updated'));

          const id = this.$route.params.id;
          this.$store.dispatch('ShipmentStore/shipment', { id: id });
          this.$store
            .dispatch('ShipmentStore/acceptService', { id: this.shipmentId })
            .then(async (response) => {
              this.isSending = false;
              const id = this.$route.params.id;
              this.$store.dispatch('ShipmentStore/shipment', { id: id });
              this.$store.dispatch('CompanyStore/getCompany', this.currentUser?.company?.id);

              if (!this.errorOnSubmit) {
                let isRFilled = await this.$store.dispatch('LoadsStore/checkRateConfirmation', {
                  id: this.load.id,
                });

                if (isRFilled.isConsigneeFilled) {
                  this.$emit('isFilledRC');
                  this.$emit('setCompleted', true);
                } else {
                  this.$emit('setCompleted', true);
                }
              }
            });
        } else {
          BaseComponent.methods?.showToastSuccess(
            this.$t('message-carrier-and-broker-already-assigned'),
          );
          this.$store
            .dispatch('ShipmentStore/acceptService', { id: this.shipmentId })
            .then(async (response) => {
              this.isSending = false;
              const id = this.$route.params.id;
              this.$store.dispatch('ShipmentStore/shipment', { id: id });
              this.$store.dispatch('CompanyStore/getCompany', this.currentUser?.company?.id);

              if (!this.errorOnSubmit) {
                let isRFilled = await this.$store.dispatch('LoadsStore/checkRateConfirmation', {
                  id: this.load.id,
                });

                if (isRFilled.isConsigneeFilled) {
                  this.$emit('isFilledRC');
                  this.$emit('setCompleted', true);
                } else {
                  this.$emit('setCompleted', true);
                }
              }
            });
        }
      } else {
        if (
          !this.addVehicle &&
          !this.addDriver &&
          (this.drivers.length === 0 || this.vehicles.length === 0)
        ) {
          if (
            Object.values(this.driver).every((value) => value === null) &&
            this.drivers.length === 0
          ) {
            BaseComponent.methods?.showToastError(this.$t('message-fill-fields-driver'));
            this.sending = false;
            return;
          }

          if (
            (this.trailer.licensePlate === null || this.trailer.trailerType.id === null) &&
            this.vehicles.length === 0
          ) {
            BaseComponent.methods?.showToastError(this.$t('message-fill-fields-vehicle'));
            this.sending = false;
            return;
          }

          if (this.drivers.length === 0) {
            await this.createDriver();
          } else {
            await this.$store
              .dispatch('ShipmentStore/assignDriver', {
                id: this.shipmentId,
                driver: { id: this.driverSelected },
              })
              .catch((err) => {
                err.response.data.violations;
              });
          }

          if (this.vehicles.length === 0) {
            await this.createVehicle();
          } else {
            await this.$store
              .dispatch('ShipmentStore/assignVehicle', {
                id: this.shipmentId,
                vehicle: { id: this.vehicleSelected },
              })
              .catch((err) => {
                err.response.data.violations;
              });
          }

          // await Promise.all([this.createDriver(), this.createVehicle()]);
          BaseComponent.methods?.showToastSuccess(this.$t('information-updated'));
          const id = this.$route.params.id;
          this.$store.dispatch('ShipmentStore/shipment', { id: id });
          this.$store
            .dispatch('ShipmentStore/acceptService', { id: this.shipmentId })
            .then(async (response) => {
              this.isSending = false;
              const id = this.$route.params.id;
              this.$store.dispatch('ShipmentStore/shipment', { id: id });
              this.$store.dispatch('CompanyStore/getCompany', this.currentUser?.company?.id);

              if (!this.errorOnSubmit) {
                let isRFilled = await this.$store.dispatch('LoadsStore/checkRateConfirmation', {
                  id: this.load.id,
                });

                if (isRFilled.isConsigneeFilled) {
                  this.$emit('isFilledRC');
                  this.$emit('setCompleted', true);
                } else {
                  this.$emit('setCompleted', true);
                }
              }
            });
        } else {
          let error = false;
          await this.$store
            .dispatch('ShipmentStore/assignDriver', {
              id: this.shipmentId,
              driver: { id: this.driverSelected },
            })
            .catch((err) => {
              err.response.data.violations;
              error = true;
            });

          await this.$store
            .dispatch('ShipmentStore/assignVehicle', {
              id: this.shipmentId,
              vehicle: { id: this.vehicleSelected },
            })
            .catch((err) => {
              err.response.data.violations;
              error = true;
            });

          this.sending = false;
          if (!error) {
            BaseComponent.methods?.showToastSuccess(this.$t('information-updated'));
            const id = this.$route.params.id;
            this.$store.dispatch('ShipmentStore/shipment', { id: id });

            this.$store
              .dispatch('ShipmentStore/acceptService', { id: this.shipmentId })
              .then(async (response) => {
                this.isSending = false;
                const id = this.$route.params.id;
                this.$store.dispatch('ShipmentStore/shipment', { id: id });
                this.$store.dispatch('CompanyStore/getCompany', this.currentUser?.company?.id);

                if (!this.errorOnSubmit) {
                  let isRFilled = await this.$store.dispatch('LoadsStore/checkRateConfirmation', {
                    id: this.load.id,
                  });

                  if (isRFilled.isConsigneeFilled) {
                    this.$emit('isFilledRC');
                    this.$emit('setCompleted', true);
                  } else {
                    this.$emit('setCompleted', true);
                  }
                }
              });
          }
        }
      }
    },
  },
  watch: {
    vehicles() {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.loads[0].isConsigneeFilled !== 'undefined'
      ) {
        this.trailer.trailerType.id = this.shipment.loads[0].trailerType?.id;
      }
    },
    driverSelected() {
      if (this.driverSelected) {
        this.getFile(this.driverSelectedMedia);
      }
    },
  },
});
