<template>
  <div class="h-12 justify-start items-center gap-[17px] inline-flex">
    <div class="text-slate-900 text-[15px] font-normal font-['Nunito']">
      {{ $t('customers') }}
    </div>

    <Popper
      class="cursor-default right-20 max-h-[200px] overflow-y-scroll overflow-y-visible"
      placement="bottom"
      :append-to-body="true"
      @close:popper="onMouseLeavePopper"
    >
      <!-- Popper content closed -->
      <div class="flex inline-flex justify-center items-center gap-2">
        <button
          v-if="dataCustomersSelected.length === 0"
          id="customerButton"
          class="h-12 justify-center items-center bg-gray-50 rounded-lg border border-stone-300 truncate
            gap-2 px-3 py-2 hover:opacity-40"
          @click="onClickAddInternalReference"
        >
          <div class="flex grow shrink basis-0 text-neutral-400 text-[15px] font-normal font-['Nunito']">
            {{ $t('button.add-internal-reference') }}
          </div>
        </button>
        <!-- Customers selected -->
        <div
          v-else
          v-for="(item, i) in dataCustomersSelected"
          class="flex items-center"
          :key="i"
        >
          <div class="h-12 flex justify-between items-center rounded-lg border border-stone-300 gap-2 px-3 py-2">
            <div class="text-[15px] font-normal font-['Nunito'] truncate">
              {{ item.name }}
            </div>
            <img
              v-if="isLoadingDeleteId !== item.id"
              alt="Remove"
              class="h-4 w-4 hover:opacity-40 cursor-pointer"
              :src="getAsset.icXCircle"
              @click="onClickCustomerDelete(item)"
            />
            <SpinBlack
              v-else
              class="h-4 w-4 mr-0"
            />
          </div>
        </div>
        <div
          v-if="dataCustomersSelected.length > 0"
          class="flex justify-center items-center grow shrink basis-0 text-neutral-400 text-[15px] font-normal
            font-['Nunito'] cursor-pointer hover:opacity-40"
          @click="onClickAddInternalReference"
        >
          {{ `+${$t('add')}` }}
        </div>
      </div>

      <!-- Popper content opened -->
      <template
        v-if="isShowCustomerList"
        #content
      >
        <div>
          <!-- Search customer -->
          <div
            class="w-full !min-w-[250px] flex justify-between items-center border-b border-b-black"
            :style="{ width: customerButtonWidth }"
          >
<!--            <XCircleIcon-->
<!--              v-if="customerSearch !== ''"-->
<!--              class="w-4 h-4 text-corp absolute top-4 right-2 transform"-->
<!--              @click="clearSearch()"-->
<!--            />-->
            <input
              v-show="customerSearch || dataCustomers.length > 0"
              class="flex text-[15px] font-['Nunito'] placeholder-neutral-400 border-0 pl-2 pr-1 truncate
                focus:border-current focus:ring-0"
              type="text"
              v-model="customerSearch"
              :placeholder="$t('search')"
              @input="onInputCustomerSearch"
            />
            <SpinBlack
              v-if="customerSearch && isLoadingSearch"
              class="h-4 w-4 ml-4"
            />
          </div>

          <!-- Customer list -->
          <div
            class="flex h-10 !min-w-[250px] justify-between group items-center border-b cursor-pointer p-2"
            v-for="(item, i) in dataCustomers"
            :key="i"
            :style="{ width: customerButtonWidth }"
          >
            <div
              v-if="!item.isEditMode"
              class="w-full text-[15px] font-['Nunito'] truncate hover:opacity-40"
              :class="{ 'pointer-events-none': isLoadingId === item.id }"
              @click="onClickCustomerAdd(item)"
            >
              {{ item.name }}
            </div>
            <input
              v-else
              class="w-full text-[15px] font-['Nunito'] border-0 truncate p-0 focus:border-current focus:ring-0"
              type="text"
              v-model="item.name"
              :name="'customerName' + i"
              @keyup="onChangeCustomerName(item, i)"
            />
            <div
              v-if="!item.isEditMode"
              class="hidden"
              :class="{ 'group-hover:flex': isLoadingId !== item.id }"
            >
              <img
                alt="Edit"
                class="h-4 w-4 cursor-pointer hover:opacity-40"
                :src="getAsset.icEdit"
                @click="onClickCustomerEdit(item, i)"
              />
              <img
                alt="Remove"
                class="h-4 w-4 cursor-pointer ml-2 hover:opacity-40"
                :src="getAsset.icTrashRed"
                @click="onClickCustomerRemove(item, i)"
              />
            </div>
            <SpinBlack
              v-if="isLoadingId === item.id"
              class="h-4 w-4 mr-0"
            />
          </div>

          <!-- Create customer -->
          <div
            class="w-full flex justify-between items-center"
            :style="{ width: customerButtonWidth }"
          >
            <input
              class="flex text-[15px] font-['Nunito'] placeholder-neutral-400 border-0 pl-2 pr-1 truncate
                focus:border-current focus:ring-0"
              type="text"
              v-model="customerCreate"
              :placeholder="$t('create-customer')"
              @keyup.enter="onInputCustomerCreate"
            />
            <img
              v-if="!isLoadingCreate && customerCreate.length > 0"
              alt="Send"
              class="h-4 w-4 cursor-pointer ml-2 hover:opacity-40"
              :src="getAsset.icCheckGray"
              @click="onInputCustomerCreate"
            />
            <SpinBlack
              v-if="isLoadingCreate"
              class="h-4 w-4 mr-0"
            />
          </div>
        </div>
      </template>
    </Popper>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
// import { XCircleIcon } from '@heroicons/vue/24/solid';
import Popper from 'vue3-popper';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import SpinBlack from '@/components/spin/AnimateBlackSpin.vue';
// Resources
import i18n from '@/resources/locales';

export default defineComponent( {
  name: 'AddCustomer',
  components: {
    Popper,
    SpinBlack,
    // XCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    data: { type: Array, default: () => [] },
    loadId: { type: Number },
    type: { type: String },
  },
  setup(props) {
    const $t = i18n.global.t;
    const store = useStore();
    const customerButtonWidth = ref(`${176}px`);
    const customerCreate = ref('');
    const customerSearch = ref('');
    const customerSearchPrevious = ref('');
    const dataCustomers = ref([]);
    const isLoadingCreate = ref(false);
    const isLoadingDeleteId = ref(-1);
    const isLoadingId = ref(-1);
    const isLoadingSearch = ref(false);
    const isShowCustomerList = ref(false);
    let timer = null;

    /** Lifecycle */
    onBeforeMount(() => {
      clearCustomersStore();
    });

    onMounted(() => {
      // addPopperItemWidth();
      apiGetCustomers();
      if (props.type === 'MODIFY') {
        store.dispatch('CustomerStore/setCustomersSelectedData', props.data);
        updateDataCustomerList();
      }
    });

    /** Computed */
    const dataCustomersOriginal = computed(() =>
      store.getters['CustomerStore/getCustomersData']
    );

    const dataCustomersSelected = computed(() =>
      store.getters['CustomerStore/getCustomersSelectedData']
    );

    /** Methods */
    const addPopperItemWidth = () => {
      customerButtonWidth.value = `${document.getElementById("customerButton").offsetWidth}px`;
    };

    const apiDelCustomers = (customer, callbackSuccess, callbackError) => {
      store.dispatch('CustomerStore/apiDelCustomers', {
        id: customer.id,
      }).then((response) => {
        if (callbackSuccess) {
          callbackSuccess(response);
        }
      }).catch(() => {
        if (callbackError) {
          callbackError();
        }
      });
    };

    const apiGetCustomers = (callbackSuccess, callbackError) => {
      store.dispatch('CustomerStore/apiGetCustomers', {
        maxResults: 100,
        name: customerSearch.value,
        page: 1,
      }).then((response) => {
        if (callbackSuccess) {
          callbackSuccess(response);
        }
      }).catch(() => {
        if (callbackError) {
          callbackError();
        }
      });
    };

    const apiPostCustomers = (name, callbackSuccess, callbackError) => {
      setLoadingCreate(true);
      store.dispatch('CustomerStore/apiPostCustomers', {
        name: name,
      }).then((response) => {
        setLoadingCreate(false);
        if (callbackSuccess) {
          callbackSuccess(response);
        }
      }).catch(() => {
        setLoadingCreate(false);
        if (callbackError) {
          callbackError();
        }
      })
    };

    const apiPostLoadCustomerAssign = (loadId, customerId, callbackSuccess, callbackError) => {
      store.dispatch('LoadsStore/apiPostLoadCustomerAssign', {
        id: loadId,
        customerId: customerId,
      }).then((response) => {
        if (callbackSuccess) {
          callbackSuccess(response);
        }
      }).catch(() => {
        if (callbackError) {
          callbackError();
        }
      });
    };

    const apiPostLoadCustomerDeAssign = (loadId, customerId, callbackSuccess, callbackError) => {
      store.dispatch('LoadsStore/apiPostLoadCustomerDeAssign', {
        id: loadId,
        customerId: customerId,
      }).then((response) => {
        if (callbackSuccess) {
          callbackSuccess(response);
        }
      }).catch(() => {
        if (callbackError) {
          callbackError();
        }
      });
    };

    const apiPutCustomers = (customer, index, callbackSuccess, callbackError) => {
      setLoadingId(customer.id);
      store.dispatch('CustomerStore/apiPutCustomers', {
        id: customer.id,
        name: customer.name,
      }).then((response) => {
        setLoadingId(-1);
        if (callbackSuccess) {
          callbackSuccess(response);
        }
      }).catch(() => {
        setLoadingId(-1);
        if (callbackError) {
          callbackError();
        }
      });
    };

    const clearCustomersStore = () => {
      store.dispatch('CustomerStore/clearCustomersSelected');
    };

    const clearSearch = () => {
      customerSearch.value = '';
      setLoadingSearch(true);
      apiGetCustomers(
        () => setLoadingSearch(false),
        () => setLoadingSearch(false)
      );
    };

    const delay = (callback, ms = 500) => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }

      timer = setTimeout(() => {
        if (callback) {
          callback();
        }
      }, ms);
    };

    const isCustomerChecked = (customerId) => {
      return dataCustomersSelected.value.find((item) => item.id === customerId);
    };

    const onChangeCustomerName = (customer, index) => {
      delay(() => {
        apiPutCustomers(
          customer, index,
          () => {
            onClickCustomerEdit(customer, index)
          },
          () => {
            const oldCustomerData = dataCustomersOriginal.value.find((item) => item.id === customer.id);
            customer.name = oldCustomerData.name;
          }
        );
      }, 1000);
    }

    const onChangeIsShowCustomerList = () => {
      isShowCustomerList.value = !isShowCustomerList.value;
    };

    const onClickAddInternalReference = () => {
      if (dataCustomers?.value.length === 0) {
        apiGetCustomers();
      }
      isShowCustomerList.value = true;
    };

    const onClickCustomerAdd = (customer) => {
      const exist = dataCustomersSelected.value.find((item) => item.id === customer.id);
      if (exist) {
        return;
      }

      // Assign customer to the load
      if (props.type === 'MODIFY') {
        setLoadingId(customer.id);
        apiPostLoadCustomerAssign(
          props.loadId, customer.id,
          () => {
            store.dispatch('CustomerStore/pushCustomersSelectedData', customer);
            updateDataCustomerList();
            setLoadingId(-1);
          },
          () => setLoadingId(-1),
        );
        return;
      }

      store.dispatch('CustomerStore/pushCustomersSelectedData', customer);
      updateDataCustomerList();
    };

    const onClickCustomerDelete = (customer) => {
      const filteredCustomers = dataCustomersSelected.value.filter((item) => item.id !== customer.id);

      // De-Assign customer of the load
      if (props.type === 'MODIFY') {
        setLoadingDeleteId(customer.id);
        apiPostLoadCustomerDeAssign(
          props.loadId, customer.id,
          () => {
            store.dispatch('CustomerStore/setCustomersSelectedData', filteredCustomers);
            updateDataCustomerList();
            setLoadingDeleteId(-1);
          },
          () => setLoadingDeleteId(-1),
        );
        return;
      }

      store.dispatch('CustomerStore/setCustomersSelectedData', filteredCustomers);
      updateDataCustomerList();
    };

    const onClickCustomerEdit = (customer, index) => {
      dataCustomers.value[index].name = customer.name;
      dataCustomers.value[index].isEditMode = !customer.isEditMode;

      // Update original array
      dataCustomersOriginal.value.map((item) => {
        if (item.id === customer.id) {
          item.name = customer.name;
        }
        return item;
      });
    };

    const onClickCustomerRemove = (customer, index) => {
      setLoadingId(customer.id);
      apiDelCustomers(
        customer,
        () => {
          const filteredCustomersNotDeleted = dataCustomers.value.filter((item) => item.id !== customer.id);
          store.dispatch('CustomerStore/setCustomersData', filteredCustomersNotDeleted);
          setLoadingId(-1);
        },
        () => setLoadingId(-1),
      );
    };

    const onInputCustomerCreate = () => {
      if (!customerCreate.value) {
        BaseComponent.methods.showToastError($t('required-field'));
        return;
      }
      apiPostCustomers(
        customerCreate.value,
        () => {
          apiGetCustomers();
          customerCreate.value = '';
        },
      );
    };

    const onInputCustomerSearch = () => {
      delay(() => {
        if (customerSearch.value !== customerSearchPrevious.value) {
          customerSearchPrevious.value = customerSearch.value;
          setLoadingSearch(true);
          apiGetCustomers(
            () => setLoadingSearch(false),
            () => setLoadingSearch(false)
          );
        }
      });
    };

    const onMouseLeavePopper = () => {
      if (!isShowCustomerList.value) {
        return;
      }
      onChangeIsShowCustomerList();
    };

    const setLoadingCreate = (value) => isLoadingCreate.value = value;

    const setLoadingDeleteId = (value) => isLoadingDeleteId.value = value;

    const setLoadingId = (value) => isLoadingId.value = value;

    const setLoadingSearch = (value) => isLoadingSearch.value = value;

    const updateDataCustomerList = () => {
      // Return list with all the customers different from the selected list
      const dataCustomerSelectedIds = dataCustomersSelected.value.map((item) => item.id);
      dataCustomers.value = dataCustomersOriginal.value.filter((item) =>
        !dataCustomerSelectedIds.includes(item.id)
      );
    };

    /** Watchers */
    watch(
      () => dataCustomersOriginal.value,
      (value) => {
        // Clone array
        if (JSON.stringify(dataCustomersOriginal.value) !== JSON.stringify(dataCustomers.value)) {
          dataCustomers.value = value?.map((item) => {
            return {
              ...item,
              isEditMode: false
            }
          });
        }
      }
    );
    watch(
      () => props.data,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          store.dispatch('CustomerStore/setCustomersSelectedData', newValue);
          updateDataCustomerList();
        }
      },
    );

    return {
      customerButtonWidth,
      customerCreate,
      customerSearch,
      dataCustomers,
      dataCustomersOriginal,
      dataCustomersSelected,
      isLoadingCreate,
      isLoadingDeleteId,
      isLoadingId,
      isLoadingSearch,
      isShowCustomerList,
      clearSearch,
      isCustomerChecked,
      onChangeCustomerName,
      onChangeIsShowCustomerList,
      onClickAddInternalReference,
      onClickCustomerAdd,
      onClickCustomerDelete,
      onClickCustomerEdit,
      onClickCustomerRemove,
      onInputCustomerCreate,
      onInputCustomerSearch,
      onMouseLeavePopper,
    }
  }
});
</script>

<style scoped>
:deep(.popper) {
  min-width: 250px;
  max-height: 200px;
  overflow-y: scroll;
  padding: 5px;
}

:deep(.popper)::-webkit-scrollbar {
  width: 10px;
}

/* Track */
:deep(.popper)::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
:deep(.popper)::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

/* Handle on hover */
:deep(.popper)::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
