
import { defineComponent, reactive } from 'vue';
import { Form, Field } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import SidebarStops from '@/components/sidebar/StopsSidebar.vue';
import ModalTemperature from '@/components/modals/TemperatureModal.vue';
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import PlanCountrySelector from '@/components/forms/PlanCountrySelector.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import SpecialRequirementsModal from '@/modules/rfp/_components/modals/SpecialRequirements.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
import WaitingLanesModal from '@/modules/rfp/_components/modals/WaitingLanesModal.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
// Styles
import HorizontalScroll from '@/resources/assets/styles/animation/HorizontalScroll.vue';
// Utils
import { locationString, locationObject } from '@/utils/formatLocation';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanUpdateMultipleLanes from '@/components/rfp/PlanUpdateMultipleLanes.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanDefaultDeleteModal from '@/components/defaults/PlanDefaultDeleteModal.vue';

export default defineComponent({
  name: 'AddLanes',
  components: {
    PlanDefaultDeleteModal,
    PlanButtonIcon,
    PlanDefaultModal,
    PlanButton,
    PlanTextButton,
    PlanUpdateMultipleLanes,
    PlanInputCheckbox,
    PlanCountrySelector,
    DatePickerComponent,
    Field,
    Form,
    GoogleAutocomplete,
    InputAmericanFormat,
    ModalTemperature,
    PlanLoading,
    SidebarStops,
    SpecialRequirementsModal,
    WaitingLanesModal,
    XCircleIcon,
  },
  mixins: [BaseComponent, CountryMixins, HorizontalScroll],
  data() {
    return {
      allowed: true,
      dateFormat: 'MM-dd-yyyy',
      currentDate: new Date(),
      showTemperature: false,
      lanes: [] as any,
      stopsToSidebar: null as any,
      showSidebarStops: false,
      laneAdditionalActive: 0,
      activeLaneTemperature: null as any,
      isValidating: false,
      editedItems: [] as any,
      newItems: [] as any,
      showSpecialRequirements: false,
      loadToModal: null as any,
      itemsPerPage: 20,
      currentPage: 1,
      loadingLanes: false,
      selectedLanes: [] as any,
      showUpdateMultipleLanes: false,
      showNextPageAlertModal: false,
      showPreviousPageAlertModal: false,
      lanesToDelete: {
        lane: null,
        position: 0,
      },
      windowSize: reactive({
        width: window.innerWidth,
      }),
    };
  },
  beforeCreate() {
    this.$store.dispatch('CountryStore/getCountries');
    this.$store.dispatch('LoadsStore/trailers');
    this.$store.dispatch('LoadsStore/packageTypes');
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    // Fields validation when import an Excel
    if (this.$route.query.loadCreationMode === 'importExcel') {
      const validation = await (this.$refs.formLanes as any).validate();
      if (!validation.valid) {
        this.isValidating = false;
        return;
      }
      this.allowed = true;
    }
  },
  async created() {
    console.log('FORMOL');

    const loadCreationMode = this.$route.query.loadCreationMode;
    await this.$store.dispatch('RFPStore/getProjectById', { projectId: this.projectId });
    if (loadCreationMode === 'Editing') {
      await this.getLanesToEdit();
    }
    if (this.editingLanes.length > 0) {
      this.lanes = this.editingLanes;
      if (loadCreationMode === 'importExcel') {
        const addPlaceProperty = (stop) => `${stop.placeDetailsPickup.formatted_address}`;
        this.lanes.forEach((data, index) => {
          data.loadTemplate.stops.forEach((stop) => {
            if (stop?.placeDetailsPickup && stop?.placeDetailsPickup?.formatted_address) {
              stop.place = addPlaceProperty(stop);
            }
          });
        });
      }
      if (loadCreationMode === 'Editing' || loadCreationMode === 'Clone') {
        const addPlaceProperty = (stop) =>
          `${stop.location.zip !== '' ? stop.location.zip + ', ' : ''}${stop.location.city}, ${
            stop.location?.state?.iso
          }`;

        this.lanes.forEach((data, index) => {
          data.loadTemplate.stops.forEach((stop) => {
            stop.place = addPlaceProperty(stop);
            if (stop.type !== 'DELIVERY' && stop.type !== 'PICKUP') {
              stop.executionDate =
                loadCreationMode === 'Editing'
                  ? this.getDayAfter(stop.executionDate)
                  : stop.type === 'DELIVERY_FINAL'
                  ? this.getDayAfter(this.currentProject.project.periodEndDate)
                  : this.getDayAfter(this.currentProject.project.periodStartDate);
            }
          });
        });
      }
    } else {
      this.lanes = [
        {
          loadsFrequency: null as any,
          loadsFrequencyPeriod: 'WEEK',
          loadTemplate: {
            stops: [
              {
                type: 'PICKUP_INITIAL',
                executionDate:
                  this.getDayAfter(this.currentProject?.project?.periodStartDate) ?? '',
                place: '' as any,
                location: {
                  lat: 0,
                  long: 0,
                  zip: 0,
                  city: '',
                  state: { id: 1 },
                },
                portStop: false,
                airportStop: false,
                observations: '',
                lumperFeePrice: null as null | number,
                lumperFee: false,
              },
              {
                type: 'DELIVERY_FINAL',
                executionDate: this.getDayAfter(this.currentProject?.project?.periodEndDate) ?? '',
                place: '' as any,
                location: {
                  lat: 0,
                  long: 0,
                  zip: 0,
                  city: '',
                  state: { id: 1 },
                },
                portStop: false,
                airportStop: false,
                observations: '',
                lumperFeePrice: null as null | number,
                lumperFee: false,
              },
            ],
            commodityMaster: {
              weight: null as any,
              commodityName: '',
              nPallets: null as any,
              packageType: {
                id: 3,
              },
            },
            maxTemp: null as any,
            minTemp: null as any,
            trailerType: { id: '' as any },
            specialRequirement: {
              id: NaN,
              teamDriver: false,
              bondedPermission: false,
              tsa: false,
              TWICCard: false,
              hazardousLoad: false,
              tir: false,
              specialPermissions: false,
              specialPermissionsDescription: '',
            },
          },
        },
      ];
      this.lanes.forEach((lane) => {
        lane.loadTemplate.commodityMaster.packageType.id = this.packageTypes[0].id;
      });
    }
  },
  computed: {
    packageTypes() {
      return this.$store.getters['LoadsStore/packageTypes'].FTL;
    },
    footerStyle() {
      if (this.windowSize.width < 1280) {
        return {
          left: '0',
          width: '100vw',
        };
      } else {
        let leftToSend = '274px';
        let widthToSend = 'calc(100vw - 274px)';

        if (this.$store.getters['UserStore/getCollapsedSidebar']) {
          leftToSend = '64px';
          widthToSend = 'calc(100vw - 64px)';
        }

        return {
          left: leftToSend,
          width: widthToSend,
        };
      }
    },
    isSidebarCollapsed() {
      return this.$store.getters['UserStore/getCollapsedSidebar'];
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    countriesList(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    editingLanes(): any {
      return this.$store.getters['RFPStore/getCurrentEditingLanes'];
    },
    loadCreationMode(): any {
      return this.$route.query.loadCreationMode;
    },
    locationString(): any {
      return locationString;
    },
    locationObject(): any {
      return locationObject;
    },
    maxDate(): any {
      return this.getDayAfter(this.currentProject?.project?.periodEndDate);
    },
    minDate(): any {
      return this.getDayAfter(this.currentProject?.project?.periodStartDate);
    },
    projectId(): any {
      return this.$route.params.projectId;
    },
    showWaitingLanes(): any {
      return this.$store.getters['RFPStore/getShowWaitingLanes'];
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
    totalPages() {
      if (this.currentProject?.computed?.lanes?.totalAmount) {
        return Math.ceil(this.currentProject?.computed?.lanes?.totalAmount / this.itemsPerPage);
      } else {
        return 1;
      }
    },
  },
  methods: {
    handleResize() {
      this.windowSize.width = window.innerWidth;
    },
    deleteLanes() {
      if (this.lanesToDelete.lane !== null && this.lanesToDelete.lane.id) {
        this.deleteCurrentLane(this.lanesToDelete.lane, this.lanesToDelete.position);
      } else {
        this.lanes.splice(this.lanesToDelete.position, 1);
      }

      this.lanesToDelete = {
        lane: null,
        position: 0,
      };
    },
    showDeleteModal(lane, i) {
      this.lanesToDelete.lane = lane;
      this.lanesToDelete.position = i;
    },
    changeAllSelectedLanes(value) {
      if (value) {
        this.selectedLanes = this.lanes;
      } else {
        this.selectedLanes = [];
      }
    },
    changeSelectLane(inputData: any) {
      const position = this.selectedLanes.indexOf(this.lanes[inputData.id]);
      if (inputData.value) {
        if (position === -1) this.selectedLanes.push(this.lanes[inputData.id]);
      } else {
        if (position !== -1) {
          this.selectedLanes.splice(position, 1);
        }
      }
    },
    previousPage() {
      if (this.selectedLanes.length > 0) {
        this.showPreviousPageAlertModal = true;
        return;
      }

      if (this.currentPage > 1) {
        this.currentPage--;
        this.getLanesToEdit();
      }
    },
    nextPage() {
      if (this.selectedLanes.length > 0) {
        this.showNextPageAlertModal = true;
        return;
      }

      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getLanesToEdit();
      }
    },
    cleanAndPreviousPage() {
      this.selectedLanes = [];
      this.showPreviousPageAlertModal = false;

      if (this.currentPage > 1) {
        this.currentPage--;
        this.getLanesToEdit();
      }
    },
    cleanAndNextPage() {
      this.selectedLanes = [];
      this.showNextPageAlertModal = false;

      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getLanesToEdit();
      }
    },
    goToPage(page) {
      this.currentPage = page;
      this.getLanesToEdit();
    },
    async getLanesToEdit() {
      this.loadingLanes = true;
      await this.$store
        .dispatch('RFPStore/getLanesByProjectId', {
          projectId: this.projectId,
          page: this.currentPage,
          maxResults: this.itemsPerPage,
        })
        .then((resp) => {
          this.$store.commit('RFPStore/setCurrentEditingLanes', resp);
          this.lanes = resp;
          const addPlaceProperty = (stop) =>
            `${stop.location.zip !== '' ? stop.location.zip + ', ' : ''}${stop.location.city}, ${
              stop.location?.state?.iso
            }`;

          this.lanes.forEach((data, index) => {
            data.loadTemplate.stops.forEach((stop) => {
              stop.place = addPlaceProperty(stop);
            });
          });
        })
        .finally(() => {
          this.loadingLanes = false;
        });
    },
    addLane() {
      const index = this.lanes.length - 1;
      const initialStop = this.lanes[index]?.loadTemplate?.stops.find(
        (stop) => stop.type === 'PICKUP_INITIAL',
      );
      const finalStop = this.lanes[index]?.loadTemplate?.stops.find(
        (stop) => stop.type === 'DELIVERY_FINAL',
      );
      const defaultPackage = this.packageTypes[0].id;
      this.lanes.push({
        loadsFrequency: null as any,
        loadsFrequencyPeriod: 'WEEK',
        loadTemplate: {
          stops: [
            {
              type: 'PICKUP_INITIAL',
              executionDate: initialStop?.executionDate,
              place: '' as any,
              location: {
                lat: 0,
                long: 0,
                zip: 0,
                city: '',
                state: { id: 1 },
              },
              portStop: false,
              airportStop: false,
              observations: '',
              lumperFeePrice: null as null | number,
              lumperFee: false,
            },
            {
              type: 'DELIVERY_FINAL',
              executionDate: finalStop?.executionDate,
              place: '' as any,
              location: {
                lat: 0,
                long: 0,
                zip: 0,
                city: '',
                state: { id: 1 },
              },
              portStop: false,
              airportStop: false,
              observations: '',
              lumperFeePrice: null as null | number,
              lumperFee: false,
            },
          ],
          commodityMaster: {
            weight: null as any,
            commodityName: '',
            nPallets: null as any,
            packageType: {
              id: defaultPackage,
            },
          },
          maxTemp: null as any,
          minTemp: null as any,
          trailerType: { id: '' as any },
          specialRequirement: {
            id: NaN,
            teamDriver: false,
            bondedPermission: false,
            tsa: false,
            TWICCard: false,
            hazardousLoad: false,
            tir: false,
            specialPermissions: false,
            specialPermissionsDescription: '',
          },
        },
      });
    },
    addStops(stops) {
      this.lanes[this.laneAdditionalActive].loadTemplate.stops = [...stops];
    },
    checkDate(index) {
      this.lanes[index]?.loadTemplate?.stops.forEach((stop) => {
        if (
          this.lanes[index].loadTemplate.stops[0].executionDate > stop.executionDate &&
          stop.executionDate
        ) {
          stop.executionDate = this.lanes[index].loadTemplate.stops[0].executionDate;
        }
      });
    },
    clearInput(lane: any, type: string) {
      const data = lane?.loadTemplate?.stops?.find((stop) => stop.type === type);
      data.place = '';
      data.location.lat = 0;
      data.location.long = 0;
      data.location.zip = 0;
      data.location.city = '';
      data.location.state.id = 1;
    },
    async createBatchLanes() {
      if (!this.allowed) return;
      this.isValidating = true;
      const validation = await (this.$refs.formLanes as any).validate();

      if (!validation.valid) {
        this.isValidating = false;
        return;
      }
      this.allowed = false;

      this.lanes.forEach((lane) => {
        //TODO REFACTOR COMMODITYMASTER

        lane.loadTemplate.commodityMaster.weight = +lane.loadTemplate.commodityMaster.weight;
        lane.loadTemplate.commodityMasters = [lane.loadTemplate.commodityMaster];

        if (this.isVehicleRefrigerated(lane)) {
          if (lane.loadTemplate.minTemp !== undefined && lane.loadTemplate.minTemp !== null) {
            (lane.loadTemplate.minTemp as any) = parseFloat(lane?.loadTemplate?.minTemp);
          }
          if (lane.loadTemplate.maxTemp !== undefined && lane.loadTemplate.maxTemp !== null) {
            (lane.loadTemplate.maxTemp as any) = parseFloat(lane?.loadTemplate?.maxTemp);
          }
        } else {
          delete lane?.loadTemplate?.minTemp;
          delete lane?.loadTemplate?.maxTemp;
        }
        lane.loadsFrequency = Number(lane.loadsFrequency);
        lane.loadTemplate.stops.forEach((stop: any) => {
          delete stop?.location?.state;
          if (stop.type === 'PICKUP_INITIAL' || stop.type === 'DELIVERY_FINAL') {
            stop.executionDate = this.formatDateStringReverse(stop.executionDate);
          }
          if (stop.appoiment && stop.appoiment !== 'NONE') {
            if (stop.appoiment === 'APPT') {
              stop.executionWindowStartTime = this.formatTime(stop.executionWindowStartTime) as any;
              stop.executionWindowEndTime = null as any;
            } else {
              stop.executionWindowStartTime = this.formatTime(stop.executionWindowStartTime) as any;
              stop.executionWindowEndTime = this.formatTime(stop.executionWindowEndTime) as any;
            }
          } else {
            stop.executionWindowStartTime = null;
            stop.executionWindowEndTime = null;
          }
        });
      });

      if (this.$route.query.loadCreationMode === 'Editing') {
        /// REFACTOR THIS IN A FUTURE ANS JUST USE ONE ENDPOINT
        /// BECAUSE IS A LOT OF CODE JUST FOR EDITING / CREATE
        this.lanes.forEach((item) => {
          if ('id' in item) {
            this.editedItems.push(item);
          } else {
            this.newItems.push(item);
          }
        });

        if (this.newItems.length > 0) {
          this.$store
            .dispatch('RFPStore/postNewLanes', {
              id: this.$route.params.projectId,
              body: {
                lanes: this.newItems,
              },
            })
            .then(() => {
              this.$store
                .dispatch('RFPStore/updateLanes', {
                  id: +this.$route.params.projectId,
                  body: { lanes: this.editedItems },
                })
                .then(() => {
                  BaseComponent.methods?.showToastSuccess(this.$t('edited-successfully'));
                  const projectId = this.$route.params.projectId;
                  this.$router.push({ name: 'ProjectLaneDetail', params: { projectId } });
                })
                .finally(() => {
                  this.isValidating = false;
                  this.allowed = true;
                });
            })
            .finally(() => {
              this.isValidating = false;
            });
        } else {
          this.$store
            .dispatch('RFPStore/updateLanes', {
              id: +this.$route.params.projectId,
              body: { lanes: this.lanes },
            })
            .then(() => {
              BaseComponent.methods?.showToastSuccess(this.$t('edited-successfully'));
              const projectId = this.$route.params.projectId;
              this.$router.push({ name: 'ProjectLaneDetail', params: { projectId } });
            })
            .finally(() => {
              this.allowed = true;
              this.isValidating = false;
            });
        }
      } else {
        this.$store
          .dispatch('RFPStore/postNewLanes', {
            id: this.$route.params.projectId,
            body: {
              lanes: this.lanes,
            },
          })
          .then((response) => {
            if (response === 'async') {
              this.$store.commit('RFPStore/setShowWaitingLanes', true);
              return;
            }
            BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));

            // Create
            if (this.$route.query.cloneFrom) {
              this.$store
                .dispatch('RFPStore/cloneTendersFromProject', {
                  id: this.$route.params.projectId,
                  sourceProject: this.$route.query.cloneFrom,
                })
                .then(() => {
                  const projectId = this.$route.params.projectId;
                  this.$router.push({ name: 'ProjectLaneDetail', params: { projectId } });
                });
            }

            const projectId = this.$route.params.projectId;
            this.$router.push({ name: 'ProjectLaneDetail', params: { projectId } });
          })
          .finally(() => {
            this.isValidating = false;
            this.allowed = true;
          });
      }
    },
    deleteCurrentLane(lane, i) {
      this.removeLane(i);
      this.$store.dispatch('RFPStore/deleteLane', lane.id).then(() => {
        BaseComponent.methods?.showToastSuccess(this.$t('lane-deleted'));
      });
    },
    formatDate(inputDate) {
      const dateObj: any = new Date(inputDate);

      // Check if the date is valid
      if (isNaN(dateObj)) {
        return null; // Return null for Invalid Date
      }

      // Format the date in 'YYYY-MM-DD' format
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    getCountryIsoByLane(lane, type) {
      const stop = this.getStopByType(lane, type);

      if (stop?.location?.state?.country?.iso) {
        return stop?.location?.state?.country?.iso;
      } else if (stop?.location?.country) {
        return stop?.location?.country;
      }
    },
    getLocation(addressData, address, id, index, lane) {
      const stopType = index === 0 ? 'PICKUP_INITIAL' : 'DELIVERY_FINAL';
      const country = addressData;

      let stop = this.lanes[lane].loadTemplate.stops.find((stop) => stop.type === stopType);
      const stateBefore = this.deepClone(stop).location.state;
      stop.place = this.locationString(addressData) as unknown;
      stop.location = this.locationObject(addressData) as unknown;
      stop.location = { ...stop.location, state: stateBefore };
      if (this.$route.query.loadCreationMode === 'Editing') {
        stop.location.id = stop?.location?.id;
      }
    },
    getStopByType(lane: any, type: string) {
      return lane?.loadTemplate?.stops?.find((stop) => stop.type === type);
    },
    getTemperatures(lane) {
      const minTemp = lane.loadTemplate.minTemp;
      const maxTemp = lane.loadTemplate.maxTemp;
      if (!isNaN(maxTemp) && minTemp !== maxTemp) {
        return `(${this.formatTemperature(minTemp)} , ${this.formatTemperature(maxTemp)})`;
      } else {
        return `(${this.formatTemperature(minTemp)})`;
      }
    },
    handleClickCountryDelivery(country, lane) {
      let stop = lane?.loadTemplate?.stops.find((item) => item.type === 'DELIVERY_FINAL');
      stop.place = '';
      if (stop && stop.location && stop.location.state) {
        stop.location.state = {
          country: country,
        };
      } else {
        stop.location = {
          state: {
            country: country,
          },
        };
      }
    },
    handleClickCountryPickup(country, lane) {
      let stop = lane?.loadTemplate?.stops.find((item) => item.type === 'PICKUP_INITIAL');
      stop.place = '';
      if (stop && stop.location && stop.location.state) {
        stop.location.state = {
          country: country,
        };
      } else {
        stop.location = {
          state: {
            country: country,
          },
        };
      }
    },
    specialRequirementsCount(load): any {
      if (load && load.specialRequirement && typeof load.specialRequirement !== 'undefined') {
        return Object.values(load.specialRequirement).filter((value) => value === true).length;
      } else {
        return 0;
      }
    },
    isVehicleRefrigerated(lane) {
      const trailer = this.trailerTypes.find(
        (trail) => trail.id === lane.loadTemplate?.trailerType?.id,
      );
      const isRefree = trailer?.type === 'REFRIGERATED' || trailer?.type === 'REFRIGERATEDOPTIONAL';
      return isRefree;
    },
    removeLane(index) {
      this.lanes.splice(index, 1);
    },
    removeStop(index) {
      this.lanes[this.laneAdditionalActive].loadTemplate.stops.splice(index, 1);
    },
    removeTrailer(lane) {
      lane.loadTemplate.trailerType.id = '';
    },
    requiredDateFormat(value) {
      const date = new Date(value);

      if (!isNaN(date.getTime()) && date.toString() !== 'Invalid Date') {
        return true;
      }
      return false;
    },
    requiredStringWithFormat(value) {
      const commasCount = (value.match(/,/g) || []).length;
      return commasCount >= 1 ? true : '';
    },
    scrollToElement() {
      const scrollableElement: any = document.getElementById('table');
      // Calculate the maximum scroll value
      const maxScroll = scrollableElement.scrollWidth - scrollableElement.clientWidth;

      // Scroll the element to the maximum scroll value (scroll to the right end)
      scrollableElement.scrollTo({
        left: maxScroll,
        behavior: 'smooth',
      });
    },
    setTemperature(minTemp, maxTemp) {
      this.activeLaneTemperature.loadTemplate.minTemp = minTemp;
      this.activeLaneTemperature.loadTemplate.maxTemp = maxTemp;
    },
    setVehicleTemp(lane, toggle, change): any {
      if (
        lane.loadTemplate?.trailerType?.id !== '' &&
        lane.loadTemplate?.trailerType?.id !== undefined
      ) {
        const trailer = this.trailerTypes.find(
          (trail) => trail.id === lane.loadTemplate?.trailerType?.id,
        );

        // ADDED CHANGE PARAM FOR @CHANGE INPUT TO NOT OPEN WHEN IS REFFER OPTIONAL
        let isRefree = false;

        if (change) {
          isRefree = trailer.type === 'REFRIGERATED';
        } else {
          isRefree = trailer.type === 'REFRIGERATED' || trailer.type === 'REFRIGERATEDOPTIONAL';
        }

        this.activeLaneTemperature = lane;
        if (isRefree && toggle) {
          this.toggleTemperature(true);
        }
        if (!isRefree && toggle) {
          this.setTemperature(null, null);
        }
        return isRefree;
      } else {
        return false;
      }
    },
    sortStops(stops): any {
      const initialPickup = stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      const additionalStops = stops
        .filter((stop) => stop.type === 'PICKUP' || stop.type === 'DELIVERY')
        .sort((a, b) => a.id - b.id);
      const finalDelivery = stops.find((stop) => stop.type === 'DELIVERY_FINAL');
      return [initialPickup, ...additionalStops, finalDelivery];
    },
    toggleSidebarStops(toggle, stops, index) {
      if (stops) {
        this.stopsToSidebar = stops;
        this.stopsToSidebar = this.sortStops(stops);
      }
      this.showSidebarStops = toggle;
      this.laneAdditionalActive = index;
    },
    toggleSpecialRequirements(bool, load, index, requirements) {
      if (requirements) {
        this.lanes[this.laneAdditionalActive].loadTemplate.specialRequirement = requirements;
      }

      if (load && !load.hasOwnProperty('specialRequirement')) {
        const defaultRequirements = {
          specialRequirement: {
            id: NaN,
            teamDriver: false,
            bondedPermission: false,
            tsa: false,
            TWICCard: false,
            hazardousLoad: false,
            tir: false,
            specialPermissions: false,
            specialPermissionsDescription: '',
          },
        };

        load = Object.assign(load, defaultRequirements);
      }

      this.loadToModal = load;
      this.showSpecialRequirements = bool;
      this.laneAdditionalActive = index;
    },
    toggleTemperature(bool) {
      this.showTemperature = bool;
    },
  },
  watch: {
    showWaitingLanes: function (newVal) {
      if (newVal === false) {
        BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
        const projectId = this.$route.params.projectId;
        this.$router.push({ name: 'ProjectLaneDetail', params: { projectId } });
      }
    },
  },
});
