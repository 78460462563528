
import { defineComponent } from 'vue';
// Components
import ProjectFiltersSkeleton from '@/modules/rfp/_components/skeleton/ProjectFiltersSkeleton.vue';

export default defineComponent({
  components: {
    ProjectFiltersSkeleton,
  },
  props: {
    activeStatus: {
      type: String,
    },
    sortBy: {
      type: String,
    },
  },
  data() {
    return {
      isLoadingFilters: false,
      order: 'id-DESC',
      searchTimeout: null as any,
    };
  },
  computed: {
    projectsList(): any {
      return this.$store.getters['RFPStore/getProjectsRFPList'];
    },
    projectStatus(): any {
      return this.$store.getters['GlobalStore/getProjectStatus'];
    },
    projectTotalsStatus(): any {
      return this.$store.getters['RFPStore/getProjectsTotalsStatus'];
    },
    projectStatusSearched(){
      return !!localStorage.getItem('projectStatusSearched');
    }
  },
  created() {
    this.isLoadingFilters = true;
    this.getProjectsTotalsStatus(null);
  },
  methods: {
    filterProjects(status) {

      if (status === this.activeStatus) {
        return;
      }
      this.$emit('filterProjects', status);
    },
    sortProjects() {
      this.$emit('sortProjects', this.order);
    },
    getTotal(status) {
      if (this.projectTotalsStatus) {
        const statusTotal = this.projectTotalsStatus.find((obj) => obj.status === status);
        if (statusTotal) {
          return statusTotal.total;
        }
      }
      return 0;
    },
    getProjectsTotalsStatus(data) {
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.$store.dispatch('RFPStore/getProjectsTotalsStatus', data).finally(() => {
          this.isLoadingFilters = false;
        });
      }, 500);
    },
  },
});
