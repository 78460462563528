import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-col justify-center items-center text-center",
    style: _normalizeStyle(_ctx.planCarrierLoadAcceptationStepWidth)
  }, [
    _createElementVNode("div", {
      class: "flex justify-center items-center",
      style: _normalizeStyle(_ctx.planCarrierLoadAcceptationStepWidth)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["plan-carrier-load-acceptation-step-line", {'plan-carrier-load-acceptation-step-line-active': _ctx.isActive && !_ctx.withoutLeft, 'plan-carrier-load-acceptation-step-line-transparent': _ctx.withoutLeft }])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["plan-carrier-load-acceptation-step cursor-pointer", {'plan-carrier-load-acceptation-step-active': _ctx.isActive}])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["plan-carrier-load-acceptation-step-line", {'plan-carrier-load-acceptation-step-line-active': _ctx.isActive && !_ctx.withoutRight, 'plan-carrier-load-acceptation-step-line-transparent': _ctx.withoutRight }])
      }, null, 2)
    ], 4),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.actualWidth <= 640 ? 'plan-t-xs text-wrap':'plan-t-base')
    }, _toDisplayString(_ctx.stepData), 3)
  ], 4))
}