import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "plan-text-xl font-bold" }
const _hoisted_3 = { class: "flex justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.currentProject?.project?.name), 1),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.isDriver)
        ? (_openBlock(), _createBlock(_component_plan_button_icon, {
            key: 0,
            onOnClick: _ctx.showNotifications,
            size: "16",
            icon: "bell-claims",
            color: "white",
            type: "error",
            loading: _ctx.loadingActivitySummary,
            quantity: _ctx.notifications,
            style: _normalizeStyle(_ctx.bellButtonIconStyle)
          }, null, 8, ["onOnClick", "loading", "quantity", "style"]))
        : _createCommentVNode("", true)
    ])
  ]))
}