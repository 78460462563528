import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanButton = _resolveComponent("PlanButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, index) => {
      return (_openBlock(), _createBlock(_component_PlanButton, {
        onOnClick: ($event: any) => (_ctx.changeActiveButton(index)),
        size: _ctx.buttonSize(index),
        key: button,
        "show-icon": 'icon' in button,
        icon: 'icon' in button ? button.icon : '',
        "icon-color": _ctx.buttonColor(index),
        "icon-type": "svg",
        type: _ctx.selectedButton === index ? _ctx.type : _ctx.type + '-outline',
        label: button.label
      }, null, 8, ["onOnClick", "size", "show-icon", "icon", "icon-color", "type", "label"]))
    }), 128))
  ]))
}