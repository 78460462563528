
import { defineComponent, computed, ref, onMounted, nextTick, onUnmounted } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PlanImportContactsLoading',
  components: { PlanIcon },
  setup () {
    const store = useStore()
    const windowWidth = ref(window.innerWidth)

    const styles = computed(() => {
      let sidebarWidth = windowWidth.value < 1280 ? '0rem' : store.getters['UserStore/getCollapsedSidebar'] ? '64px' : '274px';
      let contentWidth = windowWidth.value < 1280 ? '100vw' : `calc(100vw - ${sidebarWidth})`;

      return {
        width: contentWidth,
        height: windowWidth.value < 1280 ? 'calc(100vh - 72px)' : '100vh',
        'margin-top': windowWidth.value < 1280 ? '72px' : '0',
        'z-index': 9999,
        transition: 'all ease 0.3s'
      }
    })

    const resizeWindow = () => {
      windowWidth.value = window.innerWidth
    }

    onMounted(() => {
      window.addEventListener('resize', resizeWindow);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeWindow);
    })

    return {
      styles
    }
  }
})
