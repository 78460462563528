
import { defineComponent, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
// Components
import PlanLoading from '@/components/loadings/PlanLoading.vue';

export default defineComponent({
  name: 'ShipperDashboard',
  components: {
    PlanLoading,
  },
  setup() {
    const store = useStore();

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const isSuperAdmin = computed(() => {
      return currentUser.value?.roles?.includes('ROLE_SUPER_ADMIN');
    });

    const shipperDashboardURL = computed(
      () => store.getters['ShipperDashboardStore/getShipperDashboardURL'],
    );

    const fetchShipperDashboard = (companyId) => {
      store.dispatch('ShipperDashboardStore/getShipperDashboard', companyId);
    };

    onMounted(() => {
      if (currentUser.value) {
        fetchShipperDashboard(currentUser.value.company.id ?? currentUser.value.companyId);
      }
    });

    watch(currentUser, (newUser) => {
      if (newUser) {
        fetchShipperDashboard(newUser.company.id ?? newUser.companyId);
      }
    });

    return {
      currentUser,
      isSuperAdmin,
      shipperDashboardURL,
    };
  },
});
