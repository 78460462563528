import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "w-full flex flex-col" }
const _hoisted_5 = { class: "flex justify-between items-center" }
const _hoisted_6 = { class: "w-full flex justify-start items-center" }
const _hoisted_7 = {
  class: "flex justify-start items-center",
  style: {"min-width":"100px"}
}
const _hoisted_8 = {
  key: 0,
  class: "w-full bg-plan-success-100 text-plan-success-400 rounded p-1 text-xs text-center"
}
const _hoisted_9 = {
  key: 1,
  class: "w-full bg-plan-error-100 text-plan-error-300 rounded p-1 text-xs text-center"
}
const _hoisted_10 = {
  key: 2,
  class: "w-full bg-plan-gray-100 text-plan-gray-600 rounded p-1 text-xs text-center",
  style: {"min-width":"100px"}
}
const _hoisted_11 = { class: "ml-2" }
const _hoisted_12 = {
  key: 1,
  class: "mt-2 mb-2"
}
const _hoisted_13 = {
  key: 1,
  class: "text-plan-gray-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_carrier_additional_fee_media_objects = _resolveComponent("carrier-additional-fee-media-objects")!

  return (_ctx.loadingGetShipmentFees)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_plan_icon, {
          name: "refresh-cw",
          isSvg: "",
          rotate: ""
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.fees.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fees, (fee, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: fee.id,
                  class: "w-full flex justify-between items-center text-plan-gray-800 mt-2"
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          (fee.status === 'STATUS_APPROVED')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('approved')), 1))
                            : (fee.status === 'STATUS_DENIED')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('rejected')), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('pending')), 1))
                        ]),
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getFeeTranslation(fee?.feeExplanation?.name)), 1)
                      ]),
                      _createElementVNode("span", null, _toDisplayString(_ctx.formatPriceDecimals(fee.value)), 1)
                    ]),
                    (fee.mediaObjects.length > 0)
                      ? (_openBlock(), _createBlock(_component_carrier_additional_fee_media_objects, {
                          key: 0,
                          "media-objects": fee.mediaObjects
                        }, null, 8, ["media-objects"]))
                      : _createCommentVNode("", true),
                    (index < _ctx.fees.length - 1)
                      ? (_openBlock(), _createElementBlock("hr", _hoisted_12))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('has-not-generated-additional-fees')), 1))
      ]))
}