
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import Spin from '@/components/spin/AnimateSpin.vue';

interface IQuotationRequest {
  file: any;
  files: any;
  price: string;
  sendingAction: boolean;
  allowed: boolean;
  showQuoteInput: boolean;
  successTexts: object;
  successButton: object;
  completed: boolean;
  deleteButtonClass: string;
  imagesContainerClass: string;
  stopDocumentsPending: any;
  billId: any;
  concept: string;
  totalCost: any;
  validating: boolean;
  allowUploadDocument: boolean;
  uploadDocumentAction: boolean;
}

export default defineComponent({
  name: 'UploadBill',
  components: {
    ErrorMessage,
    Field,
    Form,
    InputAmericanFormat,
    Spin,
  },
  mixins: [BaseComponent],
  data(): IQuotationRequest {
    return {
      allowUploadDocument: true,
      uploadDocumentAction: false,
      stopDocumentsPending: null,
      imagesContainerClass: '',
      file: null as any,
      files: [] as any,
      price: '',
      sendingAction: false,
      allowed: true,
      showQuoteInput: false,
      deleteButtonClass: 'hidden',
      successTexts: {
        header: this.$t('completed'),
        body: this.$t('all-is-done'),
      },
      successButton: {
        text: this.$t('close-window'),
        type: 'close',
        callback: () => (window as any).open('', '_self').close(),
      },
      completed: false,
      billId: null,
      concept: '',
      totalCost: null,
      validating: false,
    };
  },
  created() {
    const id = this.$route.params.id;
    this.totalCost = parseInt(this.shipment.assignedCompany.price);
    this.$store.dispatch('ShipmentStore/shipment', { id: id });

    if (this.currentLoad.shipment.type !== 'Alpine_LTL') {
      this.concept =
        `${this.$t('load')} #` +
        this.shipment.identifier +
        ' | ' +
        this.shipment.loads[0].commodityMaster.commodityName +
        ' | ' +
        this.shipment.loads[0].trailerType.description +
        ' | ' +
        this.formatDate(this.origin.executionDate) +
        ' - ' +
        this.origin.location.city +
        ', ' +
        this.origin.location.state.isoGoogle +
        ' - ' +
        this.formatDate(this.destiny.executionDate) +
        ' - ' +
        this.destiny.location.city +
        ', ' +
        this.destiny.location.state.isoGoogle;
    }
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    deliverCount(): any {
      if (!this.shipment.loads) {
        return '';
      }
      return this.shipment.loads[0].stops.filter((stop) => stop.type === 'DELIVERY').length + 1;
    },
    deliverFinalStop(): any {
      if (!this.shipment.loads) {
        return '';
      }
      const initialStop = this.shipment.loads[0].stops.find(
        (stop) => stop.type === 'DELIVERY_FINAL',
      );
      return initialStop ? initialStop : '';
    },
    destiny(): any {
      return this.shipment.loads[0].stops.find((obj) => obj.type === 'DELIVERY_FINAL');
    },
    origin(): any {
      return this.shipment.loads[0].stops.find((obj) => obj.type === 'PICKUP_INITIAL');
    },
    pickupInitialStop(): any {
      if (!this.shipment.loads) {
        return '';
      }
      const initialStop = this.shipment.loads[0].stops.find(
        (stop) => stop.type === 'PICKUP_INITIAL',
      );
      return initialStop ? initialStop : '';
    },
    pickUpsCount(): any {
      if (!this.shipment.loads) {
        return '';
      }
      return this.shipment.loads[0].stops.filter((stop) => stop.type === 'PICKUP').length + 1;
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
    stops(): any {
      const shipment = this.shipment;
      if (!shipment || !shipment.loads || shipment.loads.length === 0) {
        return [];
      }
      return shipment.loads[0].stops;
    },
    uploadText(): any {
      return this.files.length ? `${this.$t('files-selected', { count: this.files.length })}` : '';
    },
  },
  methods: {
    showFileInput() {
      (this.$refs.multiUploadInput as any).click();
    },
    async submitInvoice() {
      this.validating = true;

      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid || !this.file) {
        return;
      }

      if (this.allowUploadDocument) {
        this.allowUploadDocument = false;
        this.sendingAction = true;
        this.$store
          .dispatch('ShipmentStore/postInvoiceFTL', {
            number: this.billId,
            concept: this.concept,
            amount: Number(this.totalCost),
            shipmentId: this.shipment.id,
          })
          .then((resp) => {
            this.uploadDocument(resp.id);
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          })
          .finally(() => {
            this.allowUploadDocument = true;
            this.validating = false;
            this.sendingAction = false;
          });
      }
    },
    async uploadDocument(id) {
      await this.$store
        .dispatch('ShipmentStore/postMediaObjectInvoice', {
          entityId: id,
          file: this.file,
        })
        .then(() => {
          this.$emit('close');
          BaseComponent.methods?.showToastSuccess(this.$t('documents-uploaded-done'));
          this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
        });
    },
    uploadFile() {
      if ((this.$refs.invoiceFile as any).files) {
        this.file = (this.$refs.invoiceFile as any).files[0];
      }
    },
  },
});
