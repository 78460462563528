
import { defineComponent, reactive, toRefs, computed } from 'vue';
import { ActionContext, useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PlansNewLoadHeader',
  setup() {
    const store = useStore();
    const { t } = useI18n();

    return {};
  },
});
