import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-end w-fit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_select_simple = _resolveComponent("plan-select-simple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_select_simple, {
      onChange: _ctx.change,
      value: _ctx.value,
      items: _ctx.items,
      placeholder: "",
      label: _ctx.$t('sort-by'),
      placement: "bottom-end"
    }, null, 8, ["onChange", "value", "items", "label"])
  ]))
}