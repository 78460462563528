
import { computed, defineComponent, onBeforeMount, reactive, toRefs } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanToggleButton from '@/components/buttons/PlanToggleButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanRating from '@/components/badges/PlanRating.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import RequestQuotingView from '@/modules/rfp/_components/modals/RequestQuotingModal.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import cloneDeep from 'lodash/cloneDeep';

// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'PlanRfpAssignmentModal',
  components: {
    PlanInputNumber,
    PlanButtonIcon,
    PlanRating,
    PlanAvatar,
    PlanDefaultTable,
    PlanIcon,
    PlanToggleButton,
    PlanButton,
    PlanDefaultModal,
    PlanLoading,
    RequestQuotingView,
  },
  mixins: [BaseComponent],
  emits: ['close', 'createLoad', 'directAssign'],
  props: {
    modalTitle: { type: String, required: true },
    preSelectedWeek: { type: Number, required: true },
  },
  setup(props, { emit }) {
    const close = () => {
      emit('close');
    };
    const store = useStore();
    const translate = useI18n();
    const router = useRouter();

    /** onMounted */
    onBeforeMount(async () => {
      data.isLoading = true;
      await dispatchApiCall('RFPStore/getRankingList', {
        id: currentLane.value.id,
        period: props.preSelectedWeek,
      }).then(() => {
        data.items = currentRanking.value.map((item) => {
          return {
            position: item?.quote?.position,
            tender: item?.grouper?.company?.name,
            rating: item?.grouper?.company?.stats?.amountValorations,
            commited: item?.grouper?.company?.stats?.shipmentAmount,
            rate: item?.quote?.price ? item?.quote?.price : '-',
            ranking: item,
          };
        });

        data.isLoading = false;
      });

      await dispatchApiCall('RFPStore/getCarrierQuotetionList', {
        id: currentProject?.value?.project?.id,
        page: 1,
        maxResults: 30,
        type: 'CARRIER|BROKER',
      }).then(() => {
        data.manualItems = currentContacts.value.map((item) => {
          return {
            position: '',
            tender: `${item?.company?.owner?.name} ${item?.company?.owner?.surname}`,
            rating: item?.company?.stats?.amountValorations,
            commited: item?.company?.stats?.shipmentAmount,
            rate: '',
            id: item?.company?.id,
          };
        });
      });
    });

    const getCurrentLoadsToAddAssigment = computed(() => {
      return store.getters['RFPStore/getCurrentLoadsToAddAssigment'];
    });
    const getCurrentLoadsToAdd = computed(() => {
      const items = cloneDeep(store.getters['RFPStore/getCurrentLoadsToAdd']);

      const filteredItems = items.filter((item) =>
        item.stops.some((stop) => stop.executionDate !== ''),
      );

      return filteredItems;
    });

    const dispatchApiCall = async (actionName, param) => {
      try {
        await store.dispatch(actionName, param);
      } catch (error) {
        console.error(`Error en ${actionName}:`, error);
      }
    };

    const priceToNumber = (price) => {
      if (typeof price === 'number') {
        return price;
      }
      return parseFloat(price.replace(/[^\d.-]/g, ''));
    };

    const autoHeaders = computed(() => {
      return [
        { label: translate.t(''), value: '', sortable: false },
        { label: translate.t('tenders'), value: 'tenders', sortable: false },
        { label: translate.t('rating'), value: 'tenders', sortable: false },
        { label: translate.t('lane-history'), value: 'lane-history', sortable: false },
        {
          label: translate.t('rankingModal.assigned-week'),
          value: 'assigned-week',
          sortable: false,
        },
        { label: translate.t('load-max-capacity'), value: 'load-max-capacity', sortable: false },

        {
          label: translate.t('rate'),
          value: 'rate',
          sortable: false,
        },
        { label: '', value: 'actions', sortable: false },
        { label: '', value: 'actions', sortable: false },
      ];
    });

    const period = computed(() => {
      return store.getters['RFPStore/getCalendarIntervals'].intervals?.length;
    });

    const data = reactive({
      items: [],
      showModifyModal: false,
      totalDistance: 0,
      bidIdSelected: null,
      manualItems: [],
      mode: 'auto',
      price: null,
      isLoading: false,
      showDirectAssignModal: false,
      carrierToAssign: {} as any,
      directAssign: null as any,
      showContactsModal: false,
    });

    const currentLane = computed(() => {
      return store.getters['RFPStore/getCurrentLane'];
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    const origin = computed(() => {
      return currentLane?.value.loadTemplate?.stops?.find((obj) => obj.type === 'PICKUP_INITIAL');
    });

    const currentContacts = computed(() => {
      return store.getters['RFPStore/getCarrierQuotetionList'].filter(
        (item) => item.source !== 'ALPINE_CARRIER_API',
      );
    });

    const countNonEmptyExecutionDates = computed(() => {
      return getCurrentLoadsToAdd.value.reduce((count, load) => {
        const allNonEmpty = load.stops.every((stop) => stop.executionDate !== '');
        return count + (allNonEmpty ? 1 : 0);
      }, 0);
    });

    const handleShowContactsModal = () => {
      data.price = null;
      data.showContactsModal = true;
    };

    const destiny = computed(() => {
      return currentLane?.value.loadTemplate?.stops?.find((obj) => obj.type === 'DELIVERY_FINAL');
    });

    const currentRanking = computed(() => {
      return store.getters['RFPStore/getRankingList'];
    });

    const isOpenChat = computed(() => {
      return store.getters['GlobalStore/isOpenChat'];
    });

    const handleShowModifyModal = (ranking) => {
      data.price = ranking.quote.price;
      data.bidIdSelected = ranking.quote.id;
      data.showModifyModal = true;
    };

    const closeRequestQuoteModal = (carrier) => {
      data.showContactsModal = !data.showContactsModal;
      carrier.tender = carrier?.company?.name;
      data.carrierToAssign = carrier;
      data.showDirectAssignModal = !data.showDirectAssignModal;
    };

    const chatReaded = (user) => {
      return user.lanesChatMemberForShipper?.readed ?? true;
    };

    const pricePerMile = (quote, totalMiles) => {
      return (quote / totalMiles).toFixed(2);
    };

    const changeAssigmentMode = (mode: boolean) => {
      data.mode = mode ? 'auto' : 'manual';
    };

    const handleShowAssignModal = (carrier, rate) => {
      data.price = rate || 0;
      data.carrierToAssign = carrier;
      data.showDirectAssignModal = !data.showDirectAssignModal;
    };

    const userRegion = computed(() => {
      return currentUser.value.company?.country?.region?.name;
    });
    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    const formatDateValue = (dateString) => {
      const [day, month, year] = dateString.split('-');
      return new Date(year, month - 1, day);
    };

    const handleEmitModifyQuote = async () => {
      try {
        await store.dispatch('RFPStore/updatePrice', {
          id: data.bidIdSelected,
          price: data.price,
        });

        data.showModifyModal = false;
        data.isLoading = true;

        await dispatchApiCall('RFPStore/getRankingList', {
          id: currentLane.value.id,
        });

        data.items = currentRanking.value.map((item) => {
          return {
            position: item?.quote?.position,
            tender: item?.grouper?.company?.name,
            rating: item?.grouper?.company?.stats?.amountValorations,
            commited: item?.grouper?.company?.stats?.shipmentAmount,
            rate: item?.quote?.price ? item?.quote?.price : '-',
            ranking: item,
          };
        });

        data.bidIdSelected = null;
        data.price = null;
      } catch (error) {
        console.error('Error:', error);
      } finally {
        data.isLoading = false;
      }
    };

    const createLoad = () => {
      emit('createLoad');
    };

    const handleEmitUserSelected = () => {
      data.directAssign = {
        price: priceToNumber(data.price),
        company: {
          id:
            data?.carrierToAssign?.ranking?.grouper?.company?.id ||
            data?.carrierToAssign?.company?.id,
        },
      };

      emit('directAssign', data.directAssign);
    };

    const addOrdinalSuffix = (num) => {
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const v = num % 100;

      return num + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    };

    const openChat = (ranking) => {
      if (!isOpenChat.value) {
        store.commit('LoadsStore/loadId', null);
        store.commit('GlobalStore/setOpenChat', false);
        if (ranking.lanesChatMemberForShipper) {
          ranking.lanesChatMemberForShipper.readed = true;
        }
        setTimeout(() => {
          store.commit('ChatsStore/setRankingForChat', ranking);
          store.commit('ChatsStore/setUserForChat', {
            owner: { id: ranking.grouper.company.owner.id },
          });
          store.commit('ChatsStore/setLaneForChat', currentLane.value);
          store.commit(
            'ChatsStore/setChatId',
            ranking?.lanesChatMemberForShipper?.chat?.id ?? null,
          );
          store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    };

    return {
      ...toRefs(data),
      addOrdinalSuffix,
      createLoad,
      currentLane,
      handleEmitUserSelected,
      handleShowModifyModal,
      handleEmitModifyQuote,
      closeRequestQuoteModal,
      getCurrentLoadsToAddAssigment,
      countNonEmptyExecutionDates,
      getCurrentLoadsToAdd,
      autoHeaders,
      userRegion,
      openChat,
      period,
      currentProject,
      translate,
      origin,
      handleShowContactsModal,
      pricePerMile,
      formatDateValue,
      destiny,
      changeAssigmentMode,
      handleShowAssignModal,
      chatReaded,
      close,
    };
  },
});
