
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ShipperChangePassword',
  components: { PlanInputText, PlanDefaultModal, PlanButton },
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      loadingChangePassword: false,
      showModal: false,
      oldPassword: '',
      newPassword: '',
      repeatPassword: ''
    })

    const isFormComplete = computed(() => {
      let result = true
      if (data.oldPassword.length < 6) result = false
      if (data.newPassword.length < 6) result = false
      if (data.repeatPassword.length < 6) result = false
      if (data.newPassword !== data.repeatPassword) result = false
      return result
    })

    const changePassword = () => {
      data.loadingChangePassword = true
      store.dispatch('UserStore/changePassword', {
        id: store.getters['UserStore/getCurrentUser'].id,
        oldPassword: data.oldPassword,
        plainPassword: data.newPassword
      }).then((response) => {
        closeModal()
        store.dispatch('UserStore/showToast', translate.t('success-change-password'))
        data.loadingChangePassword = false
      }).catch(() => {
        data.loadingChangePassword = false
        store.dispatch('UserStore/updateToast', {
          text: translate.t('error-change-password'),
          type: 'error',
          visible: true
        })
      })

    }

    const closeModal = () => {
      data.showModal = false
      data.oldPassword = ''
      data.newPassword = ''
      data.repeatPassword = ''
    }

    const openModal = () => {
      data.showModal = true
      data.oldPassword = ''
      data.newPassword = ''
      data.repeatPassword = ''
    }

    return {
      ...toRefs(data),
      isFormComplete,
      changePassword,
      closeModal,
      openModal
    }
  }
})
