
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierLoadHeader from '@/components/carrier/PlanCarrierLoadHeader.vue';
import PlanCarrierLoadSubHeader from '@/components/carrier/PlanCarrierLoadSubHeader.vue';
import PlanCarrierLoadHeaderNotification from '@/components/carrier/PlanCarrierLoadHeaderNotification.vue';
import PlanCarrierLoadDetails from '@/components/carrier/loadDetail/PlanCarrierLoadDetails.vue';
import PlanCarrierLoadStopData from '@/components/carrier/loadDetail/PlanCarrierLoadStopData.vue';
import PlanCarrierLoadBid from '@/components/carrier/_quoting/PlanCarrierLoadBid.vue';
import { useStore } from 'vuex';
import PlanCarrierLoadingFullScreen from '@/components/carrier/PlanCarrierLoadingFullScreen.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useRouter } from 'vue-router';

interface carrierBase {
  load: any;
  loadingLoad: boolean;
}

export default defineComponent({
  name: 'CarrierLoadDetails',
  components: {
    PlanButton,
    PlanCarrierLoadBid,
    PlanCarrierLoadHeaderNotification,
    PlanCarrierLoadStopData,
    PlanCarrierLoadDetails,
    PlanCarrierLoadSubHeader,
    PlanCarrierLoadHeader,
    PlanDefaultCarrierView,
  },
  props: {
    id: { type: String, required: false, default: null },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    store.dispatch('PusherStore/initializePusher');

    const goToLoadAcceptation = () => {
      router.push({ name: 'CarrierLoadAcceptation', params: { id: props.id } });
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const stopsData = computed(() => {
      let result: Array<any> = [];

      loadData.value.stops.forEach((stop: any, index: number) => {
        let toCity = stop.location.city;
        if (index < loadData.value.stops.length - 1) {
          toCity = loadData.value.stops[index + 1].location.city;
        }

        result.push({
          stop: stop,
          toCity: toCity,
        });
      });

      return result;
    });

    const isCarrierAssignedToThisLoad = computed(() => {
      return (
        store.getters['UserStore/getCurrentUser'].company?.id ===
        loadData.value.shipment?.assignedCompany?.company?.id
      );
    });

    const shipperHasSelectedYouNotification = computed(() => {
      return (
        store.getters['UserStore/getCurrentUser'].company?.id ===
          loadData.value.shipment?.assignedCompany?.company?.id &&
        loadData.value.status === 'CONFIRMED'
      );
    });

    return {
      goToLoadAcceptation,
      loadData,
      stopsData,
      shipperHasSelectedYouNotification,
      isCarrierAssignedToThisLoad,
    };
  },
});
