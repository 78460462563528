import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_component = _resolveComponent("sidebar-component")!
  const _component_plan_tabs = _resolveComponent("plan-tabs")!
  const _component_ui_primitives_tab = _resolveComponent("ui-primitives-tab")!
  const _component_ui_defaults_tab = _resolveComponent("ui-defaults-tab")!
  const _component_ui_components_tab = _resolveComponent("ui-components-tab")!
  const _component_plan_default_view = _resolveComponent("plan-default-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_sidebar_component),
    _createVNode(_component_plan_default_view, { title: "UI Components" }, {
      "header-content": _withCtx(() => []),
      "view-content": _withCtx(() => [
        _createVNode(_component_sidebar_component),
        _createVNode(_component_plan_tabs, {
          tabs: _ctx.tabs,
          onChangeTab: _ctx.changeTab,
          class: "mb-8",
          "show-badges": false
        }, null, 8, ["tabs", "onChangeTab"]),
        (_ctx.selectedTab === 0)
          ? (_openBlock(), _createBlock(_component_ui_primitives_tab, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.selectedTab === 1)
          ? (_openBlock(), _createBlock(_component_ui_defaults_tab, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.selectedTab === 2)
          ? (_openBlock(), _createBlock(_component_ui_components_tab, { key: 2 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}