import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  credit: null as any,
  creditBreakdown: null as any,
  grantedCreditModal: false,
  grantedCreditInfo: null as any,
  noCreditModal: false,
  planimatikCreditModal: false,
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
