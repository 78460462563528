
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import MultiCommodityItem from '@/modules/planning/newLoad/_components/MultiCommodityItem.vue';
import SkeletonModifyLoad from '@/components/skeletons/ModifyLoadSkeleton.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
// import TimePicker from 'vue3-timepicker';
import PlanInputTime from '@/components/forms/PlanInputTime.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins';
// Utils
import { locationString, locationObject } from '@/utils/formatLocation';
import { compareTimes, formatTimeToObject, hourRange, minutesRange } from '@/utils/formatTime';

export default defineComponent({
  name: 'ShipmentDataComponent',
  components: {
    DatePickerComponent,
    ErrorMessage,
    Field,
    Form,
    GoogleAutocomplete,
    InputAmericanFormat,
    MultiCommodityItem,
    SkeletonModifyLoad,
    Spin,
    XCircleIcon,
    PlanInputTime,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    currentLoad: {
      type: Object,
    },
    page: {
      type: Number,
    },
  },
  data() {
    return {
      showTime: true,
      executionWindowStartTimeOrigin: null,
      executionWindowEndTimeOrigin: null,
      executionWindowStartTimeDestiny: null,
      executionWindowEndTimeDestiny: null,
      observationsOrigin: '',
      initialObservationsOrigin: '',
      initialObservationsDestiny: '',
      observationsDestiny: '',
      selectedAppointmentOrigin: '',
      selectedAppointmentDestiny: '',
      currentDate: new Date(),
      allowed: true,
      isAssigning: false,
      isCreating: false,
      address: '',
      originStop: '',
      destinationStop: '',
      newAdditionalStop: '',
      isAdditionalStops: false,
      specialRequirements: false,
      isValidating: false,
      getToData: null,
      additionalStops: [] as any,
      // additionalStops: [
      //   {
      //     id: null,
      //     valueOrder: '',
      //     location: {
      //       lat: 0,
      //       long: 0,
      //       zip: 0,
      //       city: '',
      //       state: { id: 1 },
      //     },
      //     appoiment: null as any,
      //     executionWindowStartTime: { hh: null, mm: null },
      //     executionWindowEndTime: { hh: null, mm: null },
      //     portStop: false,
      //     airportStop: false,
      //     type: 'PICKUP',
      //     executionDate: '',
      //     observations: '',
      //   },
      // ],
      load: {
        id: this.currentLoad ? this.currentLoad.id : NaN,
        observations: this.currentLoad?.observations,
        stops: [
          {
            id: null,
            type: 'PICKUP_INITIAL',
            appoiment: null as any,
            executionWindowStartTime: null,
            executionWindowEndTime: null,
            executionDate: '',
            location: {
              id: null,
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            observations: '',
            lumperFeePrice: null as null | number,
            lumperFee: false,
          },
          {
            id: null,
            appoiment: null as any,
            type: 'DELIVERY_FINAL',
            executionDate: '',
            executionWindowStartTime: null,
            executionWindowEndTime: null,
            location: {
              id: null,
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            observations: '',
            lumperFeePrice: null as null | number,
            lumperFee: false,
          },
        ],
        // executionWindowStartTime: null,
        // executionWindowEndTime: null,
        commodityMaster: {
          id: this.currentLoad ? this.currentLoad?.commodityMaster?.id : NaN,
          weight: this.currentLoad ? this.currentLoad?.commodityMaster?.weight : NaN,
          commodityName: this.currentLoad ? this.currentLoad?.commodityMaster?.commodityName : '',
          minTemp: this.currentLoad ? this.currentLoad?.commodityMaster?.minTemp : null,
          maxTemp: this.currentLoad ? this.currentLoad?.commodityMaster?.maxTemp : null,
        },
        commodityMasters: [] as any,
        specialRequirement: {
          id: this.currentLoad ? this.currentLoad?.specialRequirement?.id : NaN,
          teamDriver: this.currentLoad ? this.currentLoad?.specialRequirement?.teamDriver : false,
          bondedPermission: this.currentLoad
            ? this.currentLoad?.specialRequirement?.bondedPermission
            : false,
          tsa: this.currentLoad ? this.currentLoad?.specialRequirement?.tsa : false,
          TWICCard: this.currentLoad ? this.currentLoad?.specialRequirement?.TWICCard : false,
          hazardousLoad: this.currentLoad
            ? this.currentLoad?.specialRequirement?.hazardousLoad
            : false,
          specialPermissions: this.currentLoad
            ? this.currentLoad?.specialRequirement?.specialPermissions
            : false,
          specialPermissionsDescription: this.currentLoad
            ? this.currentLoad?.specialRequirement?.specialPermissionsDescription
            : '',
        },
        trailerType: {
          id: this.currentLoad ? this.currentLoad?.trailerType?.id : NaN,
        },
      },
      after: undefined as any,
      before: undefined as any,
      dateAfter: '',
      dateBefore: '',
      sum: 2,
      dateFormat: 'MM-dd-yyyy',
      timeFormat: 'hh:mm a',
      isStopValidating: [] as any,
      arrayStopSaved: [] as any,
      notSaved: false,
      obse: '',
      isLoading: true,
    };
  },
  created() {
    this.$store.dispatch('LoadsStore/trailers');
    // Skeleton
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
    if (this.load.stops?.length < this.currentLoad?.stops?.length) {
      const diff = this.currentLoad?.stops.length - this.load.stops.length;
      for (let index = 0; index < diff; index++) {
        this.addStopToLoad();
        this.isStopSaved[index] = true;
        this.isStopValidating[index] = false;
      }
    }

    this.setup();
  },
  computed: {
    loadCurrent(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    compareTimes(): any {
      return compareTimes;
    },
    // formatTimeToObject(): any {
    //   return formatTimeToObject;
    // },
    hasSpecialRequirementTrue(): any {
      if (
        this.load &&
        this.load.specialRequirement &&
        typeof this.load.specialRequirement !== 'undefined'
      ) {
        return Object.values(this.load.specialRequirement).some((value) => value === true);
      } else {
        return null;
      }
    },
    hasPortAirPortStopAdditionalTrue(): any {
      if (
        this.additionalStops &&
        this.additionalStops &&
        typeof this.additionalStops !== 'undefined'
      ) {
        return this.additionalStops.some(
          (item) => item.portStop === true || item.airportStop === true,
        );
      } else {
        return null;
      }
    },
    hasPortAirPortStopTrue(): any {
      if (this.load && this.load.stops && typeof this.load.stops !== 'undefined') {
        return this.load.stops.some((item) => item.portStop === true || item.airportStop === true);
      } else {
        return null;
      }
    },
    isStopSaved(): any {
      this.additionalStops.forEach((stop, i) => {
        if (stop.location.city === '' || stop.executionDate === '' || stop.executionDate === null) {
          this.arrayStopSaved[i] = false;
        }
      });
      return this.arrayStopSaved;
    },
    isVehicleRefrigerated(): any {
      if (this.load?.trailerType?.id !== null && this.trailerTypes.length > 0) {
        const trailer = this.trailerTypes.find((trail) => trail.id === this.load?.trailerType?.id);
        if (trailer) {
          return trailer.type === 'REFRIGERATED' || trailer.type === 'REFRIGERATEDOPTIONAL';
        }
        return false;
      } else {
        return false;
      }
    },
    hourRange(): any {
      return hourRange;
    },
    loadId(): any {
      return this.$store.getters['LoadsStore/loadId'];
    },
    locationString(): any {
      return locationString;
    },
    locationObject(): any {
      return locationObject;
    },
    minOtherSelectedDate(): any {
      return this.load.stops[0].executionDate;
    },
    minutesRange(): any {
      return minutesRange;
    },
    shipmentId(): any {
      return this.$store.getters['LoadsStore/loadShipmentId'];
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
  },
  watch: {
    loadCurrent() {
      this.setup();

      this.load.commodityMasters = this.loadCurrent?.commodityMasters;
    },
  },
  methods: {
    setup() {
      // Mira si hay special requirements
      if (
        this.currentLoad?.specialRequirement &&
        Object.values(this.currentLoad?.specialRequirement).some((item) => item === true)
      ) {
        this.specialRequirements = true;
      }

      // Order stops and save start and end points
      if (this.currentLoad !== undefined && this.currentLoad?.stops) {
        this.currentLoad.stops.forEach((item) => {
          if (item.type === 'PICKUP_INITIAL') {
            this.originStop = `${item.location.zip}, ${item.location.city}, ${item.location.state.name}`;
            this.load.stops[0].id = item.id;
            this.load.stops[0].type = item.type;
            this.load.stops[0].lumperFeePrice = item.lumperFeePrice;
            this.load.stops[0].lumperFee = item.lumperFeePrice ? true : false;
            this.load.stops[0].observations = item.observations;
            this.observationsOrigin = item.observations;
            this.initialObservationsOrigin = item.observations;
            this.load.stops[0].portStop = item.portStop;
            this.load.stops[0].airportStop = item.airportStop;
            this.after = this.getDayAfter(item.executionDate);
            this.load.stops[0].executionDate = item.executionDate;
            this.load.stops[0].observations = item.observationsOrigin;

            // this.load.stops[0].executionWindowEndTime = item.executionWindowEndTime;
            this.load.stops[0].appoiment =
              item.executionWindowStartTime === undefined &&
              item.executionWindowEndTime === undefined
                ? 'NONE'
                : item.executionWindowEndTime === undefined
                ? 'APPT'
                : 'FCFS';
            this.selectedAppointmentOrigin = this.load.stops[0].appoiment;
            this.load.stops[0].executionWindowStartTime =
              item.executionWindowStartTime !== undefined
                ? item.executionWindowStartTime
                : undefined;
            this.executionWindowStartTimeOrigin =
              item.executionWindowStartTime !== undefined ? item.executionWindowStartTime : null;
            this.load.stops[0].executionWindowEndTime =
              item.executionWindowEndTime !== undefined ? item.executionWindowEndTime : undefined;
            this.executionWindowEndTimeOrigin =
              item.executionWindowEndTime !== undefined ? item.executionWindowEndTime : null;
            this.load.stops[0].location.id = item.location.id;
            this.load.stops[0].location.lat = item.location.lat;
            this.load.stops[0].location.long = item.location.long;
            this.load.stops[0].location.zip = item.location.zip;
            this.load.stops[0].location.city = item.location.city;
            this.load.stops[0].location.state = { id: item.location.state.id };
          } else if (item.type === 'DELIVERY_FINAL') {
            this.destinationStop = `${item.location.zip}, ${item.location.city}, ${item.location.state.name}`;
            this.load.stops[1].id = item.id;
            this.load.stops[1].type = item.type;
            this.load.stops[1].lumperFeePrice = item.lumperFeePrice;
            this.load.stops[1].lumperFee = item.lumperFeePrice ? true : false;
            this.load.stops[1].observations = item.observations;
            this.observationsDestiny = item.observations;
            this.initialObservationsDestiny = item.observations;
            this.load.stops[1].appoiment =
              item.executionWindowStartTime === undefined &&
              item.executionWindowEndTime === undefined
                ? 'NONE'
                : item.executionWindowEndTime === undefined
                ? 'APPT'
                : 'FCFS';
            this.selectedAppointmentDestiny = this.load.stops[1].appoiment;
            this.load.stops[1].executionWindowStartTime =
              item.executionWindowStartTime !== undefined
                ? item.executionWindowStartTime
                : undefined;
            this.executionWindowStartTimeDestiny =
              item.executionWindowStartTime !== undefined ? item.executionWindowStartTime : null;
            this.load.stops[1].executionWindowEndTime =
              item.executionWindowEndTime !== undefined ? item.executionWindowEndTime : undefined;
            this.executionWindowEndTimeDestiny =
              item.executionWindowEndTime !== undefined ? item.executionWindowEndTime : null;
            this.load.stops[1].portStop = item.portStop;
            this.load.stops[1].airportStop = item.airportStop;
            this.before = this.getDayAfter(item.executionDate);
            this.load.stops[1].executionDate = item.executionDate;
            this.load.stops[1].observations = item.observationsDestiny;
            this.load.stops[1].location.id = item.location.id ? item.location.id : null;
            this.load.stops[1].location.lat = item.location.lat;
            this.load.stops[1].location.long = item.location.long;
            this.load.stops[1].location.zip = item.location.zip;
            this.load.stops[1].location.city = item.location.city;
            this.load.stops[1].location.state = { id: item.location.state.id };
          } else if (item.type === 'PICKUP' || item.type === 'DELIVERY') {
            // this.load.stops[this.sum].id = item.id;
            // this.load.stops[this.sum].type = item.type;
            // this.load.stops[this.sum].location.lat = item.location.lat;
            // this.load.stops[this.sum].location.long = item.location.long;
            // this.load.stops[this.sum].location.zip = item.location.zip;
            // this.load.stops[this.sum].location.city = item.location.city;
            // this.load.stops[this.sum].location.state = { id: item.location.state.id };

            if (this.sum >= 2 && this.load.stops.length > 2) {
              this.addAdditionalStop();
            }
            // Guarda aparte las paradas adicionales
            this.additionalStops[
              this.sum - 2
            ].valueOrder = `${item.location.zip}, ${item.location.city}, ${item.location.state.name}`;
            this.additionalStops[this.sum - 2].id = item.id !== null ? item.id : null;
            this.additionalStops[this.sum - 2].type = item.type;
            this.additionalStops[this.sum - 2].lumperFeePrice = item.lumperFeePrice;
            this.additionalStops[this.sum - 2].lumperFee = item.lumperFeePrice ? true : false;
            this.additionalStops[this.sum - 2].executionDate = item.executionDate;
            this.additionalStops[this.sum - 2].observations = item.observations;
            this.additionalStops[this.sum - 2].initialObservations = item.observations;
            // this.additionalStops[this.sum - 2].appoiment = item.appoiment;
            this.additionalStops[this.sum - 2].appoiment =
              item.executionWindowStartTime === undefined &&
              item.executionWindowEndTime === undefined
                ? 'NONE'
                : item.executionWindowEndTime === undefined
                ? 'APPT'
                : 'FCFS';
            this.additionalStops[this.sum - 2].executionWindowStartTime =
              item.executionWindowStartTime !== undefined ? item.executionWindowStartTime : null;
            this.additionalStops[this.sum - 2].executionWindowEndTime =
              item.executionWindowEndTime !== undefined ? item.executionWindowEndTime : null;
            this.additionalStops[this.sum - 2].portStop = item.portStop;
            this.additionalStops[this.sum - 2].airportStop = item.airportStop;
            this.additionalStops[this.sum - 2].location.id = item.location.id
              ? item.location.id
              : null;
            this.additionalStops[this.sum - 2].location.lat = item.location.lat;
            this.additionalStops[this.sum - 2].location.long = item.location.long;
            this.additionalStops[this.sum - 2].location.zip = item.location.zip;
            this.additionalStops[this.sum - 2].location.city = item.location.city;
            this.additionalStops[this.sum - 2].location.state = { id: item.location.state.id };
            this.sum++;
          }
        });
        // if (this.currentLoad.stops.length > 2) {
        //   this.addAdditionalStop();
        // }
        this.additionalStops = this.additionalStops.filter((item) => item.location.city !== '');
      }
      // Add additional stops to the load
      const initialStops = 2;
      this.additionalStops.forEach((stop: any, i) => {
        if (stop.id !== null) {
          this.load.stops[initialStops + i].id = stop.id !== null ? stop.id : null;
          this.load.stops[initialStops + i].type = stop.type;
          this.load.stops[initialStops + i].executionDate = stop.executionDate;
          this.load.stops[initialStops + i].lumperFeePrice = stop.lumperFeePrice;
          this.load.stops[initialStops + i].lumperFee = stop.lumperFeePrice ? true : false;
          this.load.stops[initialStops + i].observations = stop.observations;
          // this.load.stops[initialStops + i].initialObservations = stop.observations;
          this.load.stops[initialStops + i].appoiment = stop.appoiment;

          // this.load.stops[initialStops + i].appoiment =
          //   stop.executionWindowStartTime === undefined && stop.executionWindowEndTime === undefined
          //     ? 'NONE'
          //     : stop.executionWindowEndTime === undefined
          //     ? 'APPT'
          //     : 'FCFS';
          this.load.stops[initialStops + i].executionWindowStartTime = stop.executionWindowStartTime
            ? stop.executionWindowStartTime
            : null;
          this.load.stops[initialStops + i].executionWindowEndTime = stop.executionWindowEndTime
            ? stop.executionWindowEndTime
            : null;
          this.load.stops[initialStops + i].portStop = stop.portStop;
          this.load.stops[initialStops + i].airportStop = stop.airportStop;
          this.load.stops[initialStops + i].location.id = stop.location.id;
          this.load.stops[initialStops + i].location.lat = stop.location.lat;
          this.load.stops[initialStops + i].location.long = stop.location.long;
          this.load.stops[initialStops + i].location.zip = stop.location.zip;
          this.load.stops[initialStops + i].location.city = stop.location.city;
          this.load.stops[initialStops + i].location.state = { id: stop.location.state.id };
          this.isAdditionalStops = true;
        }
      });
    },
    updateCommodityMasters(data) {
      if (this.load.hasOwnProperty('commodityMasters')) {
        this.load.commodityMasters = [...data];
        return;
      }

      const commodityName = data[0]?.commodityName;
      const weight = Number(data[0]?.nPallets);
      const commodityMaster = {
        ...this.load.commodityMaster,
        commodityName: commodityName,
        weight: weight,
      };
      this.load = {
        ...this.load,
        commodityMaster,
        commodityMasters: [...data],
      };
    },
    addAdditionalStop() {
      this.additionalStops.push({
        id: null,
        type: 'PICKUP',
        valueOrder: '',
        appoiment: null,
        location: {
          lat: 0,
          long: 0,
          zip: 0,
          city: '',
          state: { id: 1 },
        },
        portStop: false,
        airportStop: false,
        executionDate: '',
        lumperFeePrice: null as null | number,
        lumperFee: false,
        observations: '',
        executionWindowStartTime: null,
        executionWindowEndTime: null,
      });
    },
    addStopToLoad() {
      this.load.stops.push({
        id: null,
        type: 'PICKUP',
        appoiment: null as any,
        location: {
          id: null,
          lat: 0,
          long: 0,
          zip: 0,
          city: '',
          state: { id: 1 },
        },
        portStop: false,
        airportStop: false,
        executionDate: '',
        lumperFeePrice: null as null | number,
        lumperFee: false,
        observations: '',
        executionWindowStartTime: null,
        executionWindowEndTime: null,
      });
    },
    calcHeight(value) {
      let numberOfLineBreaks = (value.match(/\n/g) || []).length;
      // min-height + lines x line-height + padding + border
      let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
      return newHeight;
    },
    checkDate() {
      if (this.after > this.before && this.before) {
        this.before = this.after;
      }
    },
    checkSameZip(currentStop) {
      const currentLocation = this.currentLoad?.stops.find(
        (stop) => stop.location.id === currentStop.location.id,
      );
      if (currentLocation?.location?.zip === currentStop?.location?.zip) {
        return true;
      } else {
        return false;
      }
    },
    clearInput(data: any, item: string) {
      if (item === 'origin') {
        this.originStop = '';
      }
      if (item === 'destination') {
        this.destinationStop = '';
      }
      if (item === 'additional') {
        data.valueOrder = '';
        data.location.lat = 0;
        data.location.long = 0;
        data.location.zip = 0;
        data.location.city = '';
        data.location.state.id = 1;
      }
    },
    exit() {
      const data = false;
      this.$emit('modify', data);
    },
    formatDateString() {
      if (this.after !== null) {
        this.dateAfter = new Intl.DateTimeFormat('en-GB', {
          dateStyle: 'short',
        }).format(this.after);
        const parts = this.dateAfter.split('/').reverse().join('-');
        this.dateAfter = parts;
      }
      if (this.before !== null) {
        this.dateBefore = new Intl.DateTimeFormat('en-GB', {
          dateStyle: 'short',
        }).format(this.before);
        const parts = this.dateBefore.split('/').reverse().join('-');
        this.dateBefore = parts;
      }
      // Cambia formato a la fecha de las paradas
      this.additionalStops.forEach((stop) => {
        if (stop.executionDate.length === undefined) {
          var date: any = stop?.executionDate;
          let day = new Intl.DateTimeFormat('en-GB', {
            dateStyle: 'short',
          }).format(date);
          const parts = day.split('/').reverse().join('-');
          stop.executionDate = parts;
          // this.formatDateStringReverse(stop.executionDate)
        }
      });
    },
    async formSubmit() {
      this.notSaved = false;

      if (this.isStopSaved.some((item) => item === false) && this.isAdditionalStops) {
        this.notSaved = true;
        return;
      }
      const isValid = await this.validate();
      if (!isValid) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
      if (!this.allowed) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
      this.allowed = false;
      this.isCreating = true;
      if (this.isVehicleRefrigerated) {
        if (
          this.load.commodityMaster.minTemp !== undefined &&
          this.load.commodityMaster.minTemp !== null
        ) {
          this.load.commodityMaster.minTemp = parseFloat(
            this.load?.commodityMaster?.minTemp.toString(),
          );
        }
        if (
          this.load.commodityMaster.maxTemp !== undefined &&
          this.load.commodityMaster.maxTemp !== null
        ) {
          this.load.commodityMaster.maxTemp = parseFloat(
            this.load?.commodityMaster?.maxTemp.toString(),
          );
        }
      } else {
        delete this.load?.commodityMaster?.minTemp;
        delete this.load?.commodityMaster?.maxTemp;
      }
      // Guarda la fecha de recogida y entrega en el objeto loads
      this.formatDateString();
      this.load.stops.forEach((stop) => {
        if (stop.type === 'PICKUP_INITIAL') {
          stop.executionDate = this.dateAfter;
        }
        if (stop.type === 'DELIVERY_FINAL') {
          stop.executionDate = this.dateBefore;
        }
      });

      // Modifica la info de las paradas adicionales
      this.updateAdditionalStops();

      // Cambio de varios campos del objeto a de tipo a número para la API
      this.load.commodityMaster.weight = Number(this.load.commodityMaster.weight);

      // Actualiza appointments y observaciones
      if (this.selectedAppointmentOrigin !== 'NONE') {
        if (this.selectedAppointmentOrigin === 'APPT') {
          this.load.stops[0].executionWindowEndTime = null as any;
          this.load.stops[0].executionWindowStartTime = this.executionWindowStartTimeOrigin
            ? this.executionWindowStartTimeOrigin
            : ({} as any);
        } else {
          this.load.stops[0].executionWindowStartTime = this.executionWindowStartTimeOrigin
            ? this.executionWindowStartTimeOrigin
            : ({} as any);

          this.load.stops[0].executionWindowEndTime = this.executionWindowEndTimeOrigin
            ? this.executionWindowEndTimeOrigin
            : ({} as any);
        }
      } else {
        this.load.stops[0].executionWindowStartTime = null as any;
        this.load.stops[0].executionWindowEndTime = null as any;
      }

      if (this.selectedAppointmentDestiny !== 'NONE') {
        if (this.selectedAppointmentDestiny === 'APPT') {
          this.load.stops[1].executionWindowEndTime = null as any;
          this.load.stops[1].executionWindowStartTime = this.executionWindowStartTimeDestiny
            ? this.executionWindowStartTimeDestiny
            : ({} as any);
        } else {
          this.load.stops[1].executionWindowStartTime = this.executionWindowStartTimeDestiny
            ? this.executionWindowStartTimeDestiny
            : ({} as any);

          this.load.stops[1].executionWindowEndTime = this.executionWindowEndTimeDestiny
            ? this.executionWindowEndTimeDestiny
            : ({} as any);
        }
      } else {
        this.load.stops[1].executionWindowStartTime = null as any;
        this.load.stops[1].executionWindowEndTime = null as any;
      }

      this.load.stops[0].observations = this.observationsOrigin;
      this.load.stops[1].observations = this.observationsDestiny;

      this.allowed = true;
      this.load.stops = this.load.stops.filter((item) => item.location.city !== '');

      this.load.commodityMasters.forEach((commodity) => {
        commodity.nPallets = Number(commodity.nPallets);
      });

      this.$emit('modify', this.load);
    },
    getAdditionalStop(addressData, place, id, index) {
      if (addressData.postal_code) {
        this.address = addressData;
        this.additionalStops[index].valueOrder = this.locationString(addressData) as any;
        this.additionalStops[index].location = this.locationObject(addressData) as any;
      } else {
        (this.$refs[id] as any)[0].clear();
      }
    },
    getDestination(addressData) {
      if (addressData.postal_code) {
        this.address = addressData;
        this.destinationStop = this.locationString(addressData) as any;
        this.load.stops[1].location = this.locationObject(addressData) as any;
      } else {
        (this.$refs.destiny as any).clear();
      }
    },
    getOrigin(addressData: any) {
      if (addressData.postal_code) {
        this.address = addressData;
        this.originStop = this.locationString(addressData) as any;
        this.load.stops[0].location = this.locationObject(addressData) as any;
      } else {
        (this.$refs.origin as any).clear();
      }
    },
    portOrAirport(pick, stop) {
      if (pick === 'port') {
        stop.airportStop = false;
        stop.portStop = !stop.portStop;
        if (!this.hasPortAirPortStopTrue && !this.hasPortAirPortStopAdditionalTrue) {
          this.load.specialRequirement.TWICCard = false;
          if (!this.hasSpecialRequirementTrue) {
            this.specialRequirements = false;
          }
        } else {
          this.specialRequirements = true;
          this.load.specialRequirement.TWICCard = true;
        }
      }

      if (pick === 'airport') {
        stop.portStop = false;
        stop.airportStop = !stop.airportStop;
        if (!this.hasPortAirPortStopTrue && !this.hasPortAirPortStopAdditionalTrue) {
          this.load.specialRequirement.TWICCard = false;
          if (!this.hasSpecialRequirementTrue) {
            this.specialRequirements = false;
          }
        } else {
          this.specialRequirements = true;
          this.load.specialRequirement.TWICCard = true;
        }
      }
    },
    removeAdditionalStop(index: number) {
      // Elimina el controlador de guardado de la parada
      this.isStopSaved.splice(index, 1);
      // Elimina las paradas adicionales
      this.additionalStops.splice(index, 1);
      // Elimina las paradas carga
      this.load.stops.splice(index + 2, 1);
    },
    saveStop(index: number) {
      if (
        this.additionalStops[index]?.location?.city !== '' &&
        this.additionalStops[index]?.executionDate !== '' &&
        this.additionalStops[index]?.executionDate !== null
      ) {
        this.isStopSaved[index] = true;
        this.isStopValidating[index] = false;
      } else {
        this.isStopValidating[index] = true;
      }
    },
    updateAdditionalStops(): any {
      // Actualiza los datos modificados en las paradas adicionales
      const initialStops = 2;
      // Añade paradas a load para igualar el número de paradas que tiene al
      // número de paradas adicionales que hay
      const diff = this.additionalStops.length - (this.load.stops.length - 2);
      for (let n = 0; n < diff; n++) {
        this.addStopToLoad();
      }

      this.additionalStops.forEach((stop: any, i) => {
        if (i < this.additionalStops.length) {
          this.load.stops[initialStops + i].id = stop.id !== null ? stop.id : null;
          this.load.stops[initialStops + i].type = stop.type;
          // Formato fecha string reverse para enviar al back
          const date: any = this.formatDateStringReverse(stop.executionDate);
          this.load.stops[initialStops + i].executionDate = date;
          this.load.stops[initialStops + i].lumperFeePrice = stop.lumperFeePrice;
          this.load.stops[initialStops + i].observations = stop.observations;
          // this.load.stops[initialStops + i].appoiment = stop.executionWindowStartTime === undefined && stop.executionWindowEndTime === undefined ? 'NONE' : stop.executionWindowEndTime === undefined ? 'APPT' : 'FCFS';
          this.load.stops[initialStops + i].executionWindowStartTime =
            stop.executionWindowStartTime !== undefined && stop.executionWindowStartTime?.hh
              ? stop.executionWindowStartTime
              : (null as any);
          this.load.stops[initialStops + i].executionWindowEndTime =
            stop.executionWindowEndTime !== undefined && stop.executionWindowEndTime?.hh
              ? stop.executionWindowEndTime
              : (null as any);
          this.load.stops[initialStops + i].portStop = stop.portStop;
          this.load.stops[initialStops + i].airportStop = stop.airportStop;
          if (this.checkSameZip(stop)) {
            this.load.stops[initialStops + i].location.id = stop.location.id;
          } else {
            this.load.stops[initialStops + i].location.id = null;
          }
          this.load.stops[initialStops + i].location.lat = stop.location.lat;
          this.load.stops[initialStops + i].location.long = stop.location.long;
          this.load.stops[initialStops + i].location.zip = stop.location.zip;
          this.load.stops[initialStops + i].location.city = stop.location.city;
          this.load.stops[initialStops + i].location.state = { id: stop.location.state.id };
          if (stop.appoiment && stop.appoiment !== 'NONE') {
            if (stop.appoiment === 'APPT') {
              (this.load.stops[initialStops + i].executionWindowStartTime =
                stop.executionWindowStartTime),
                (this.load.stops[initialStops + i].executionWindowEndTime = null as any);
            } else {
              this.load.stops[initialStops + i].executionWindowStartTime =
                stop.executionWindowStartTime;

              this.load.stops[initialStops + i].executionWindowEndTime =
                stop.executionWindowEndTime;
            }
          } else {
            this.load.stops[initialStops + i].executionWindowStartTime = null as any;
            this.load.stops[initialStops + i].executionWindowEndTime = null as any;
          }
          this.isAdditionalStops = true;
        }
      });
    },
    updateHour() {
      const originCompare = this.compareTimes(
        this.executionWindowStartTimeOrigin,
        this.executionWindowEndTimeOrigin,
      );
      if (originCompare) {
        this.executionWindowEndTimeOrigin = this.executionWindowStartTimeOrigin;
        this.showTime = false;
        setTimeout(() => {
          this.showTime = true;
        }, 1);
      }
      const destinyCompare = this.compareTimes(
        this.executionWindowStartTimeDestiny,
        this.executionWindowEndTimeDestiny,
      );
      if (destinyCompare) {
        this.executionWindowEndTimeDestiny = this.executionWindowStartTimeDestiny;
        this.showTime = false;
        setTimeout(() => {
          this.showTime = true;
        }, 1);
      }
    },
    updateHours(item) {
      if (item.executionWindowEndTime !== null) {
        if (typeof item.executionWindowEndTime === 'string')
          item.executionWindowEndTime = item.executionWindowEndTime;
        const itemCompare = this.compareTimes(
          item.executionWindowStartTime,
          item.executionWindowEndTime,
        );
        if (itemCompare) {
          item.executionWindowEndTime = item.executionWindowStartTime;
          this.showTime = false;
          setTimeout(() => {
            this.showTime = true;
          }, 1);
        }
      }
    },
    updateObservations(event, data, stop) {
      if (data === 'origin') {
        this.observationsOrigin = event.target.innerText;
      }
      if (data === 'destiny') {
        this.observationsDestiny = event.target.innerText;
      }
      if (data === 'stop') {
        stop.observations = event.target.innerText;
      }
    },
    async validate() {
      this.isValidating = true;
      let isValidAdditionalStop = true;
      const validation = await (this.$refs.form as any).validate();
      if (
        !validation.valid ||
        !this.originStop ||
        !this.destinationStop
        // !this.after ||
        // !this.before
      ) {
        return false;
      }
      this.additionalStops.forEach((stop, index) => {
        if (!stop.valueOrder && index + 1 !== this.additionalStops.length) {
          isValidAdditionalStop = false;
        }
      });
      if (!isValidAdditionalStop && this.isAdditionalStops) {
        return false;
      }
      return true;
    },
  },
});
