
import { defineComponent } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { Square2StackIcon } from '@heroicons/vue/24/outline';

interface Texts {
  header: string;
  body: string;
}

interface Button {
  text: string;
  type: string;
  callback: () => void;
}

export default defineComponent({
  name: 'ModalInvitation',
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Square2StackIcon,
  },
  emits: ['setOpen'],
  props: {
    open: Boolean,
    invitation: String,
    type: String,
  },
  methods: {
    toggleOpen(isOpen: boolean) {
      this.$emit('setOpen', isOpen);
    },
    async copy() {
      await navigator.clipboard.writeText(this.invitation as any);
    },
  },
});
