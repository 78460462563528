import globalAxios, { isAxiosError } from 'axios';
import { AxiosError } from 'axios';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import store from '@/services/store';
import i18n from '@/resources/locales';

interface ErrorMessage {
  title: string;
}

interface zip_code_search {
  zip_code_search?: boolean;
}

const annotations = async (data: any) => {
  const response = await globalAxios.put(
    `/loads/${data.id}/modifyAnnotations`,
    data.annotations,
    {},
  );
  return response.data;
};

const apiPostLoadCustomerAssign = async () => {
  // TODO
};

const apiPostLoadCustomerDeAssign = async () => {
  // TODO
};

const cancelLoad = async (id: any) => {
  const response = await globalAxios.put(`/loads/${id}/cancel`, {});
  return response.data;
};

const checkRateConfirmation = async (data: any) => {
  const response = await globalAxios.get(`/loads/${data.id}/check_consignee`);
  return Promise.resolve(response.data);
};

const quotesAverageStats = async (data: any) => {
  const response = await globalAxios.get('/shipment/quotesAverageStats', {
    params: {
      originZip: data.originZip,
      destinyZip: data.destinyZip,
      trailerType: data.trailerType,
      periodType: data.period,
    },
  });

  return Promise.resolve(response.data);
};

const createLoad = async (data: any) => {
  const response = await globalAxios.post('/loads', data.load, {
    params: {
      isTemplate: data.isMultiple,
    },
  });
  return Promise.resolve(response.data);
};

const createLoadLTL = async (data: any) => {
  const response = await globalAxios.post('/loads/LTL', data.load, {});
  return Promise.resolve(response.data);
};

const deleteEmailCategory = async (data: any) => {
  const response = await globalAxios.delete(`/emailCategory/${data.id}`);
  return Promise.resolve(response.data);
};

const deleteLoadMediaObject = async (id: any) => {
  const response = await globalAxios.delete(`/mediaObjects/load/${id}/delete`);
  return Promise.resolve(response.data);
};

const deleteLanes = async (data: any) => {
  const response = await globalAxios.delete('/lanes/removeLane/' + data.id);
  return Promise.resolve(response.data);
};

const deleteLoad = async (id: any) => {
  const response = await globalAxios.delete(`/loads/${id}`, {});
  return response.data;
};

const deleteSaveLoad = async (id: any) => {
  const response = await globalAxios.delete(`/save_loads/${id}`, {});
  return response.data;
};

const deleteWarehouse = async (id: any) => {
  const response = await globalAxios.delete(`/warehouses/${id}`, {});
  return response.data;
};

const directBatchAssign = async (data: any) => {
  const response = await globalAxios.post('/assigned_companies/direct/batch_assign', data);
  return Promise.resolve(response.data);
};

const downloadExcelTemplateImportWarehouses = async () => {
  const response = await globalAxios.get(
    '/company/excel/getDownloadExcelTemplateImportWarehouses',
    {
      responseType: 'blob',
    },
  );
  return Promise.resolve(response.data);
};

const draftConsignee = async (data) => {
  const response = await globalAxios.put(`/load/${data.id}/draftConsignee`, data.params);
  return response.data;
};

const fillLoadConsignee = async (data) => {
  try {
    const response = await globalAxios.put(`/load/${data.id}/fillConsignee`, data.params);
    return response;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        const errorMessage = axiosError.response.data as ErrorMessage;
        if (errorMessage.title) {
          throw errorMessage.title;
        }
      }
    }
    throw error;
  }
};

const putRateGoal = async (data) => {
  try {
    const response = await globalAxios.put(`/loads/${data.id}/update/rate_goal`, {
      rateGoal: data.rateGoal,
    });
    return response;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        const errorMessage = axiosError.response.data as ErrorMessage;
        if (errorMessage.title) {
          throw errorMessage.title;
        }
      }
    }
    throw error;
  }
};

const putBillContactInfo = async (data) => {
  try {
    const response = await globalAxios.put(
      `/loads/${data.id}/update/bill_contact_information`,
      data.params,
    );
    return response;
  } catch (error) {
    if (isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        const errorMessage = axiosError.response.data as ErrorMessage;
        if (errorMessage.title) {
          throw errorMessage.title;
        }
      }
    }
    throw error;
  }
};
const postMediaObjectLoad = async (load) => {
  const response = await globalAxios.post(
    '/mediaObjects/load',
    { entityId: load.id, file: load.file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

const findCarriers = async (query: any) => {
  const response = await globalAxios.get(`/load/findCarriers`, { params: query });
  return response;
};

const getClaimsTooltip = async (loadId: number) => {
  const response = await globalAxios.get(`/loads/${loadId}/claimsTooltip`, {});
  return response.data;
};

const getTotalClaims = async () => {
  const response = await globalAxios.get('/claims/totalClaims', {});
  return response.data;
};

const getAutocompleteGoogle = async (zip: string, country: string, zipCodeSearch: any) => {
  const now = new Date();
  const dateString = [
    now.getUTCMinutes().toString().padStart(2, '0'),
    now.getUTCHours().toString().padStart(2, '0'),
    now.getUTCDate().toString().padStart(2, '0'),
    (now.getUTCMonth() + 1).toString().padStart(2, '0'),
    now.getUTCFullYear().toString(),
  ].join('');

  const string = dateString + '731vCdqk5e62rAbzuvMuD2JlSBCXbnA7';
  const user = store.getters['UserStore/getCurrentUser'];
  const getLocale = i18n.global.locale.value as any ;



  const token = CryptoJS.SHA512(string).toString();

  return await axios.get(
    `${process.env.VUE_APP_TOOL_URL}/googleAutocomplete.php?zip_code=${zip}&zip_code_search=${zipCodeSearch}&country=${country}`,
    {
      headers: {
        'X-Token': token,
        'X-Company': 'planimatik',
        'X-Language': getLocale,
      },
    },
  );
};

const getCoordinatesGoogle = async (lat: string, lng: string) => {
  const now = new Date();
  const dateString = [
    now.getUTCMinutes().toString().padStart(2, '0'),
    now.getUTCHours().toString().padStart(2, '0'),
    now.getUTCDate().toString().padStart(2, '0'),
    (now.getUTCMonth() + 1).toString().padStart(2, '0'),
    now.getUTCFullYear().toString(),
  ].join('');

  const string = dateString + '731vCdqk5e62rAbzuvMuD2JlSBCXbnA7';
  const getLocale = i18n.global.locale.value as any;

  const token = CryptoJS.SHA512(string).toString();

  return await axios.get(
    `${process.env.VUE_APP_TOOL_URL}/googleCoordinates.php?lat=${lat}&lng=${lng}`,
    {
      headers: {
        'X-Token': token,
        'X-Company': 'planimatik',
        'X-Language': getLocale,
      },
    },
  );
};

const getCompanyTags = async () => {
  const response = await globalAxios.get('/company/tags', {});
  return response.data;
};

const getEmailCategories = async () => {
  const response = await globalAxios.get('/emailCategories', {});
  return response.data;
};

const getEmailCategoriesAndTemplates = async () => {
  const response = await globalAxios.get('/emailCategoriesAndTemplates', {});
  return response.data;
};

const getEmailTemplatesFromPlanimatik = async () => {
  const response = await globalAxios.get('/emailTemplatesFromPlanimatik', {});
  return response.data;
};

const getPlaceDetailsGoogle = async (placeId: string) => {
  const now = new Date();
  const dateString = [
    now.getUTCMinutes().toString().padStart(2, '0'),
    now.getUTCHours().toString().padStart(2, '0'),
    now.getUTCDate().toString().padStart(2, '0'),
    (now.getUTCMonth() + 1).toString().padStart(2, '0'),
    now.getUTCFullYear().toString(),
  ].join('');

  const string = dateString + '731vCdqk5e62rAbzuvMuD2JlSBCXbnA7';
  const getLocale = i18n.global.locale.value as any;
  const token = CryptoJS.SHA512(string).toString();

  return await axios.get(
    `${process.env.VUE_APP_TOOL_URL}/googlePlaceDetails.php?place_id=${placeId}`,
    {
      headers: {
        'X-Token': token,
        'X-Company': 'planimatik',
        'X-Language': getLocale,
      },
    },
  );
};

const getdensityClass = async (query: any) => {
  const response = await globalAxios.get('/density_classes', {});
  return response.data;
};

const getDocsList = async (id) => {
  const response = await globalAxios.get(`/loads/${id}/docs_list`, {});
  return response.data;
};

const getFeeExplanations = async () => {
  const response = await globalAxios.get('/fee_explanations', {});
  return response.data;
};

const getTrackingLogs = async (id: number) => {
  const response = await globalAxios.get(`/loads/${id}/tracking_and_media_information`, {});
  return response.data;
};

const getLanes = async (data: any) => {
  const response = await globalAxios.get('/lanes/company/' + data.id, {
    params: {
      page: 1,
      maxResults: 100,
    },
  });
  return Promise.resolve(response.data);
};

const getLimitedAcces = async (query: any) => {
  const response = await globalAxios.get('/limited_accesses', {});
  return response.data;
};

const getLoadFieldAutocomplete = async (params: any) => {
  const response = await globalAxios.get('/load/searchAutocomplete', {
    params: params,
  });
  return response.data;
};

const getLTLBOL = async (id: any) => {
  const response = await globalAxios.get(`/loads/${id}/LTL/BOL_autogenerate`, {
    responseType: 'blob',
  });
  return Promise.resolve(response.data);
};

const getLTLQuotes = async (body: any) => {
  const response = await globalAxios.post('/loads/LTL/get_quotes', body);
  return response.data;
};

const getLTLQuotesById = async (id: number) => {
  const response = await globalAxios.get(`/loads/${id}/LTL/get_quotes`);
  return response.data;
};

const getNMFC = async (query: any) => {
  const response = await globalAxios.get(`/NMFC_classes`, {});
  return response;
};

const getpackageTypes = async (query: any) => {
  const response = await globalAxios.get('/package_types', {});
  return response.data;
};

const getRankingRFP = async (query: any) => {
  const response = await globalAxios.get(`/load/${query.id}/rfp/ranking_load`, {
    params: query.data,
  });
  return response.data;
};

const getSaveLoads = async (query: any) => {
  const response = await globalAxios.get('/save_loads', query);
  return response.data;
};

const getSaveLoadsSearch = async (query: any) => {
  const response = await globalAxios.get('/save_loads/search', { params: query });
  return response.data;
};

const getSaveLoadById = async (id: any) => {
  const response = await globalAxios.get(`/save_load/${id}`);
  return response.data;
};

const getShipmentAverageStats = async (
  origin: string,
  destiny: string,
  trailer: string,
  period: string,
) => {
  const response = await globalAxios.get(
    `/shipment/averageStats?originZip=${origin}&destinyZip=${destiny}&trailerType=${trailer}&periodType=${period}`,
  );
  return response.data;
};

const getShipmentFees = async (id: any) => {
  const response = await globalAxios.get(`/shipments/${id}/additionalFee`);
  return response.data;
};

const getTrackingHistory = async (id: any) => {
  const response = await globalAxios.get(`/trackings/shipment/${id}`);
  return response.data;
};

const getWarehouses = async (params: any) => {
  const response = await globalAxios.get(`/warehouses`, {
    params: params,
  });
  return response.data;
};

const getWarehousesList = async (params: any) => {
  const response = await globalAxios.get(`/warehousesList`, {
    params: params,
  });
  return response.data;
};

const loads = async (query: any) => {
  const response = await globalAxios.get(`/loads`, { params: query });
  return response;
};

const loadInfo = async (id: any) => {
  const response = await globalAxios.get(`/loads/${id}/loadInfo`);
  return response;
};

const loadsInfo = async (query: any) => {
  const response = await globalAxios.post(`/loadsInfo`, query);
  return response;
};

const loadBatchesTemplate = async (data: any) => {
  const response = await globalAxios.post(
    '/load_batches/fromTemplate/' + data.id,
    {},
    {
      params: {
        amount: data.amount,
      },
    },
  );
  return Promise.resolve(response.data);
};

const loadsAdmin = async (query: any) => {
  const response = await globalAxios.get(`/load/superAdminLoads`, { params: query });
  return response;
};

const loadsById = async (id: number) => {
  const response = await globalAxios.get(`/loads/${id}`, {});
  return response.data;
};

const loadsByDates = async (params: any) => {
  const response = await globalAxios.get(`/load/plannerTotalsDates`, { params });
  return response.data;
};

const loadsByDatesAdmin = async (params: any) => {
  const response = await globalAxios.get(`/load/superAdminPlannerTotalsDates`, { params });
  return response.data;
};

const loadsByIdAdmin = async (id: number) => {
  const response = await globalAxios.get(`/loads/${id}/superAdmin`, {});
  return response.data;
};

const modifyLoad = async (data: any) => {
  const response = await globalAxios.put(`/loads/${data.id}`, data, {});
  return response.data;
};

const postCreateAdditionalFee = async (data: any) => {
  const response = await globalAxios.post('/shipments/' + data.shipment + '/additionalFee', data);
  return Promise.resolve(response.data);
};

const postSendLoadDocsEmail = async (params: any) => {
  const response = await globalAxios.post(
    '/load/' + params.id + '/sendLoadDocsEmail',
    params.object,
  );
  return Promise.resolve(response.data);
};


const postCreateAdditionalFeeMedia = async (data: any) => {
  const response = await globalAxios.post('/mediaObjects/additionalFee', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return Promise.resolve(response.data);
};

const postEmail = async (data: any) => {
  const response = await globalAxios.post(
    '/email/send-sign-rate-confirmation',
    {},
    {
      params: {
        shipment: data.shipment,
      },
    },
  );
  return Promise.resolve(response.data);
};

const postEmailCategory = async (data: any) => {
  return await globalAxios.post('/emailCategory', data);
};

const postLane = async (body: any) => {
  const response = await globalAxios.post('/lanes/addLanes', body);
  return response.data;
};

const postLoadCustomerAssign = async (id: number, params: object) => {
  const response = await globalAxios.post(`loads/${id}/customer/assign`, params);
  return Promise.resolve(response.data);
};

const postLoadCustomerDeAssign = async (id: number, params: object) => {
  const response = await globalAxios.post(`loads/${id}/customer/de_assign`, params);
  return Promise.resolve(response.data);
};

const postNotifyPosition = async (params: object) => {
  const response = await globalAxios.post(`/trackings/notify/manually/planimatik`, params);
  return Promise.resolve(response.data);
};

const plannerTotalsDayWeek = async (query: any) => {
  delete query.sortBy;
  const response = await globalAxios.get(`/load/plannerTotalsDayWeek`, { params: query });
  return response.data;
};

const getSharedDocuments = async (code: any) => {
  const response = await globalAxios.get(`/load/sharedDocuments`, {
    params: code,
    responseType: 'blob',
  });
  return response.data;
};


const plannerTotalsDayWeekAdmin = async (query: any) => {
  delete query.sortBy;
  const response = await globalAxios.get(`/load/superAdminPlannerTotalsDayWeek`, { params: query });
  return response.data;
};

const postWarehouse = async (body: any) => {
  const response = await globalAxios.post('/warehouses', body);
  return response.data;
};

const postWarehousesFromExcel = async (excel: any) => {
  try {
    const response = await globalAxios.post(`/company/excel/extractWarehouseFromExcel`, excel, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postWarehousesFromJson = async (data: any) => {
  const response = await globalAxios.post('/company/excel/createWarehouseFromJson', data);
  return response.data;
};

const postCreateWarehouse = async (body: any) => {
  const response = await globalAxios.post('/createWarehouse', body);
  return response.data;
};

const sendEmailDriver = async (id: any) => {
  const response = await globalAxios.post(`/stops/${id}/BOL_POD/sendEmailDriver`);
  return response.data;
};

const sendEmailDriverNotifyPosition = async (id: any) => {
  const response = await globalAxios.post(`/email/send-request-location-driver?shipment=${id}`);
  return response.data;
};

const sendEmailDriverIncident = async (id: any) => {
  const response = await globalAxios.post(`/email/send-request-incident-to-driver?stop=${id}`);
  return response.data;
};
const putWarehouse = async (data: any) => {
  const response = await globalAxios.put(`/warehouses/${data.id}`, data.body);
  return response.data;
};

const updateWarehouse = async (data: any) => {
  const response = await globalAxios.put(`/updateWarehouse/${data.id}`, data);
  return response.data;
};

const putCompanyTags = async (id: number, tags: Array<string>) => {
  const response = await globalAxios.put(`/company/${id}`, {
    tags: tags,
  });
  return Promise.resolve(response.data);
};

const putEmailCategory = async (data: any) => {
  return await globalAxios.put(`/emailCategory/${data.id}`, data);
};

const postSaveLoads = async (body: any) => {
  const response = await globalAxios.post('/save_loads', body);
  return response.data;
};

const putSaveLoads = async (data: any) => {
  const response = await globalAxios.put(`/save_loads/${data.id}`, data);
  return response.data;
};

const postValoration = async (body: any) => {
  const response = await globalAxios.post('/valoration/setValorationLoad', body, {});
  return response.data;
};

const putValoration = async (data: any) => {
  const response = await globalAxios.put(`/valoration/${data.id}`, data);
  return response.data;
};

const putApproveAdditionalFee = async (id: number) => {
  const response = await globalAxios.put(`/additional_fees/${id}/approve`);
  return Promise.resolve(response.data);
};

const putDenyAdditionalFee = async (id: number) => {
  const response = await globalAxios.put(`/additional_fees/${id}/deny`);
  return Promise.resolve(response.data);
};

const putLTLBol = async (data: any) => {
  const response = await globalAxios.put(`/loads/${data.id}/LTL/fill_BOL`, data.object);
  return response.data;
};

const relaunchRankingRFP = async (id: any) => {
  const response = await globalAxios.post(`/loads/${id}/rfp/ranking_relaunch`, {});
  return response.data;
};

const sendRequestBidEmail = async (data: any) => {
  const response = await globalAxios.post(`/load/${data.id}/sendRequestBillEmail`, data, {});
  return response.data;
};

const setBookNowPrice = async (query: any) => {
  const response = await globalAxios.put(`/loads/${query.id}/setBookNowPrice`, query.params);
  return response.data;
};

const shipmentRequestBatch = async (data: any) => {
  const response = await globalAxios.post('/shipment_bid_requests/batch', data);
  return Promise.resolve(response.data);
};

const states = async () => {
  const response = await globalAxios.get('/states', {});
  return Promise.resolve(response.data);
};

const totals = async (query: any) => {
  const response = await globalAxios.get(`/load/plannerTotals`, { params: query });
  return response.data;
};

const totalsAdmin = async (query: any) => {
  const response = await globalAxios.get(`/load/superAdminPlannerTotals`, { params: query });
  return response.data;
};

const totalSavedBidsLTL = async (id: number) => {
  const response = await globalAxios.get(`/shipment_bids/loads/${id}/LTL/saved_totals`, {});
  return response.data;
};

const trackingUpdateAlert = async (data: any) => {
  const response = await globalAxios.put(`/loads/${data.id}/TrackingUpdateAlert`, data.object, {});
  return response.data;
};

const trailers = async () => {
  const response = await globalAxios.get('/trailer_types', {});
  return Promise.resolve(response.data);
};

const updateETA = async (data: any) => {
  const response = await globalAxios.put(`/loads/${data.id}/update/eta_manually`, {
    manualDateTimeETA: data.manualDateTimeETA,
  });
  return response.data;
};

const updateLanes = async (data: any) => {
  const response = await globalAxios.post('/lanes/updateLanes/' + data.id, data);
  return Promise.resolve(response.data);
};

export default {
  annotations,
  cancelLoad,
  checkRateConfirmation,
  createLoad,
  createLoadLTL,
  deleteEmailCategory,
  deleteLanes,
  deleteLoad,
  deleteSaveLoad,
  deleteLoadMediaObject,
  deleteWarehouse,
  directBatchAssign,
  downloadExcelTemplateImportWarehouses,
  draftConsignee,
  fillLoadConsignee,
  findCarriers,
  getAutocompleteGoogle,
  getCoordinatesGoogle,
  getCompanyTags,
  getPlaceDetailsGoogle,
  getClaimsTooltip,
  getEmailCategories,
  getEmailCategoriesAndTemplates,
  getEmailTemplatesFromPlanimatik,
  getTotalClaims,
  getdensityClass,
  getDocsList,
  getFeeExplanations,
  getLanes,
  getTrackingLogs,
  getLimitedAcces,
  getLoadFieldAutocomplete,
  getLTLBOL,
  getLTLQuotes,
  getLTLQuotesById,
  getNMFC,
  getpackageTypes,
  getRankingRFP,
  getSaveLoads,
  getSaveLoadById,
  getSaveLoadsSearch,
  getShipmentAverageStats,
  getShipmentFees,
  getTrackingHistory,
  getWarehouses,
  getWarehousesList,
  loads,
  loadInfo,
  loadsInfo,
  loadsAdmin,
  loadBatchesTemplate,
  loadsByIdAdmin,
  loadsByDates,
  loadsByDatesAdmin,
  loadsById,
  modifyLoad,
  plannerTotalsDayWeek,
  plannerTotalsDayWeekAdmin,
  postCreateAdditionalFee,
  postCreateAdditionalFeeMedia,
  postEmail,
  postEmailCategory,
  postMediaObjectLoad,
  postLane,
  postLoadCustomerAssign,
  postLoadCustomerDeAssign,
  postNotifyPosition,
  postSaveLoads,
  postValoration,
  putValoration,
  postWarehouse,
  postWarehousesFromExcel,
  postWarehousesFromJson,
  postCreateWarehouse,
  postSendLoadDocsEmail,
  getSharedDocuments,
  putApproveAdditionalFee,
  putWarehouse,
  updateWarehouse,
  putCompanyTags,
  putEmailCategory,
  putSaveLoads,
  putDenyAdditionalFee,
  putLTLBol,
  putRateGoal,
  putBillContactInfo,
  quotesAverageStats,
  relaunchRankingRFP,
  sendRequestBidEmail,
  setBookNowPrice,
  shipmentRequestBatch,
  states,
  sendEmailDriverIncident,
  sendEmailDriver,
  sendEmailDriverNotifyPosition,
  totals,
  totalsAdmin,
  totalSavedBidsLTL,
  trackingUpdateAlert,
  trailers,
  updateETA,
  updateLanes,
};
