
import { defineComponent, nextTick, onBeforeMount, onUnmounted, reactive, toRefs } from 'vue';

import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  components: { PlanIcon, PlanInputCheckbox },
  props: {
    items: { type: Array, required: true },
    label: { type: String, required: false },
  },
  emit: ['change'],
  setup(props, { emit }) {
    const data = reactive({
      showSelectItems: false,
      id: 'plan-multiselect' + Math.random().toString(36).substr(2, 9),
      itemList: [],
      selectWidth: 0,
      selectedItems: [] as any,
    });

    const showPopper = () => {
      data.showSelectItems = true;
    };

    const closePopper = () => {
      data.showSelectItems = false;
    };

    onBeforeMount(async () => {
      changeWidth();
      document.addEventListener('click', handleClickOutside);
    });

    const handleClickOutside = (event) => {
      const popperElement = document.querySelector('#' + data.id);
      if (popperElement && !popperElement.contains(event.target)) closePopper();
    };

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    const changeWidth = () => {
      const planSelectWarehousePopper = document.getElementById(data.id);

      nextTick(() => {
        const widht = document.getElementById('showPopper');
        if (widht) {
          nextTick(() => {
            data.selectWidth = widht.offsetWidth;
          });
        }
      });
    };

    const toggleLaneSelection = (item) => {
      const index = data.selectedItems.findIndex(
        (selectedItem) => JSON.stringify(selectedItem) === JSON.stringify(item),
      );

      if (index === -1) {
        data.selectedItems.push(item);
      } else {
        data.selectedItems.splice(index, 1);
      }

      emit('change', data.selectedItems);
    };

    const isSelected = (item) => {
      return data.selectedItems.some((selectedLane) => selectedLane.identifier === item.identifier);
    };

    return {
      ...toRefs(data),
      isSelected,
      showPopper,
      toggleLaneSelection,
    };
  },
});
