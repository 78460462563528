import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center w-full h-full" }
const _hoisted_2 = { class: "bg-white flex rounded-lg p-4 w-full h-full" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_Loading = _resolveComponent("plan-Loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-screen", { 'xl:ml-90': !_ctx.isSuperAdmin }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.shipperDashboardURL)
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 0,
              src: _ctx.shipperDashboardURL.URL,
              frameborder: "0",
              allowtransparency: "",
              class: "w-full h-full"
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createBlock(_component_plan_Loading, { key: 1 }))
      ])
    ])
  ], 2))
}