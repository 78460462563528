import globalAxios from 'axios';

const cloneProjectWithLanes = async (data: any) => {
  const response = await globalAxios.post(`/project_rfp/cloneWithLanes`, data);
  return Promise.resolve(response.data);
};

const cloneTendersFromProject = async (id: number, sourceProject: number) => {
  const response = await globalAxios.post(
    `/project_rfp/${id}/cloneTendersFromProject/${sourceProject}`,
  );
  return Promise.resolve(response.data);
};

const deleteFromRanking = async (id: number) => {
  const response = await globalAxios.post(`/quote_rfp/${id}/remove`);
  return Promise.resolve(response.data);
};

const deleteProject = async (id: number) => {
  const response = await globalAxios.delete(`/project_rfp/${id}`);
  return Promise.resolve(response.data);
};
const deleteQuoteRFP = async (id: number) => {
  const response = await globalAxios.delete(`/quote_rfp/${id}/delete`);
  return Promise.resolve(response.data);
};
const deleteLane = async (id: number) => {
  const response = await globalAxios.delete(`/lanesRFP/${id}`);
  return Promise.resolve(response.data);
};

const getAsyncResultLanes = async (id: number) => {
  const response = await globalAxios.get(`/async_result_stores/${id}`);
  return Promise.resolve(response.data);
};

const getCalendar_intervals = async (id: number) => {
  const response = await globalAxios.get(`/lanesRFP/${id}/calendar_intervals`);
  return Promise.resolve(response.data);
};

const getLoadsInfo = async (body: object, id: number) => {
  const response = await globalAxios.get(`/lanesRFP/${id}/loadsInfo`, {
    params: {
      ...body,
    },
  });
  return Promise.resolve(response.data);
};

const getProjectInfoByWeek = async (id: number, week: number) => {
  const response = await globalAxios.get(`/project_rfp/${id}/info_week`, {
    params: {
      week: week,
      byLanes: true,
    },
  });
  return response.data;
};

const getAssignConfirmation = async (id: number) => {
  const response = await globalAxios.get(`/assign_confirmation_rfp/${id}`, {});
  return response.data;
};

const getProjectById = async (projectId: number) => {
  try {
    const response = await globalAxios.get(`/project_rfp/${projectId}`, {});
    return response.data;
  } catch (error) {
    console.error('Error fetching lanes:', error);
    throw error;
  }
};

const getProjectClaims = async (projectId: number) => {
  const response = await globalAxios.get(`/project_rfp/${projectId}/claims_tooltip`, {});
  return response.data;
};

const getProjectByIdCarrier = async (projectId: number) => {
  const response = await globalAxios.get(`/project_rfp/${projectId}/for_carrier`, {});
  return response.data;
};

const getLaneByIdCarrier = async (laneId: number) => {
  const response = await globalAxios.get(`/lanesRFP/${laneId}/for_carrier`, {});
  return response.data;
};

const getLaneById = async (laneId: number) => {
  try {
    const response = await globalAxios.get(`/lanesRFP/${laneId}`, {});
    return response.data;
  } catch (error) {
    console.error('Error fetching lanes:', error);
    throw error;
  }
};

const getLanesClaimsInfo = async (laneId: number, week: number) => {
  const response = await globalAxios.get(`/lanesRFP/${laneId}/claims_tooltip_by_week`, {
    params: {
      week: week,
    },
  });
  return response.data;
};

const getCarrierQuotetionList = async (
  id: number,
  name: string,
  orderType: string,
  page: number,
  maxResults: number,
  type: string,
  sortBy: string,
  period: string,
  tag: string
) => {
  try {
    const response = await globalAxios.get(`/companies/project_rfp/${id}/tenders`, {
      params: {
        maxResults: maxResults,
        name: name,
        orderType: orderType,
        page: page,
        period: period,
        sortBy: sortBy,
        type: type,
        tag: tag
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching lanes:', error);
    throw error;
  }
};

const getLanesByProjectId = async (
  projectId: number,
  orderField: string,
  orderType: string,
  page: number,
  maxResults: number,
) => {
  try {
    const response = await globalAxios.get(`/project_rfp/${projectId}/lanes`, {
      params: {
        orderField: orderField,
        orderType: orderType,
        page: page,
        maxResults: maxResults,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching lanes:', error);
    throw error;
  }
};
const getPlannedLanesWeeks = async (id) => {
  const response = await globalAxios.get(`/lanesRFP/${id}/planned_by_week`);
  return Promise.resolve(response.data);
};

const getLaneListFilter = async (projectId, query) => {
  const response = await globalAxios.get(`/project_rfp/${projectId}/allLanesBasicInfo`, {
    params: {
      orderType: query,
    },
  });
  return Promise.resolve(response.data);
};

const getProjectRFPSearcher = async (name: string, page: number, maxResults: number) => {
  const response = await globalAxios.get('/project_rfp/light/search', {
    params: { page: page, maxResults: maxResults, name: name },
  });
  return Promise.resolve(response.data);
};

const getRFPExcel = async () => {
  const response = await globalAxios.get('/lanesRFP/excel/template/download', {
    responseType: 'blob',
  });
  return Promise.resolve(response.data);
};

const getProjectsRFPList = async (data: any) => {
  const response = await globalAxios.get('/project_rfp', { params: data });
  return Promise.resolve(response.data);
};

const getProjectsRFPTotalsByStatus = async (data: any) => {
  const response = await globalAxios.get('/project_rfp/totals/by_status', { params: data });
  return Promise.resolve(response.data);
};

const getLanesAndQuotesForCarrier = async (projectId: number, page: number, maxResults: number) => {
  const response = await globalAxios.get(`project_rfp/${projectId}/lanes_and_quotes/for_carrier`, {
    params: {
      page: page,
      maxResults: maxResults,
    },
  });
  return Promise.resolve(response.data);
};

const getQuoteRFPCarrier = async (projectId: number, page: number, maxResults: number) => {
  const response = await globalAxios.get(`/quote_rfp/for_carrier`, {
    params: {
      page: page,
      maxResults: maxResults,
      ['project.id']: projectId,
    },
  });
  return Promise.resolve(response.data);
};

const getQuoteRFPCarrierById = async (quoteId: number) => {
  const response = await globalAxios.get(`/quote_rfp/${quoteId}/for_carrier`);
  return Promise.resolve(response.data);
};

const postNewLanes = async (object) => {
  try {
    const response = await globalAxios.post(
      `project_rfp/${object.id}/createBatchLanes`,
      object.body,
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const getRankingList = async (data: any) => {
  const response = await globalAxios.get(`/lanesRFP/${data.id}/ranking`, {
    params: { page: 1, period: data.period },
  });
  return Promise.resolve(response.data);
};

const getUnCompletedTootipInfo = async (projectId: number) => {
  const response = await globalAxios.get(`/project_rfp/${projectId}/uncompleted_tooltip`);
  return Promise.resolve(response.data);
};

const postAssignConfirmationRFP = async (confirmId) => {
  try {
    const response = await globalAxios.post(`/assign_confirmation_rfp/${confirmId}/action/accept`);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postAssignDeclineRFP = async (confirmId) => {
  try {
    const response = await globalAxios.post(`/assign_confirmation_rfp/${confirmId}/action/decline`);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postCarrierConfirm = async (data) => {
  try {
    const response = await globalAxios.post(`/quote_rfp/${data}/confirm`);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postImportLanesByExcel = async (excel) => {
  try {
    const response = await globalAxios.post(`/lanesRFP/excel/extract_lanes/format_light`, excel, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postCarrierRefuse = async (data) => {
  try {
    const response = await globalAxios.post(`/quote_rfp/${data}/refuse`);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postNewProject = async (data) => {
  try {
    const response = await globalAxios.post('/project_rfp', data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postMoveRankingPosition = async (data) => {
  try {
    const response = await globalAxios.post(`/quote_rfp/${data.id}/movePosition`, data.body);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postProjectRFPEndQuotation = async (projectId: number, data: any) => {
  try {
    const response = await globalAxios.post(`/project_rfp/${projectId}/end_quotation`, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postRevokeQuote = async (quoteId: number) => {
  const response = await globalAxios.post(`/quote_rfp/${quoteId}/revoke`, {});
  return response.data;
};

const postAssignedCapacity = async (quoteId: number, data: any) => {
  try {
    const response = await globalAxios.post(`/quote_rfp/${quoteId}/assignedCapacity`, {
      assignedCapacity: data,
    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postProjectRFPRequestAllQuotesConfirmation = async (projectId: number, data: any) => {
  try {
    const response = await globalAxios.post(
      `/project_rfp/${projectId}/requestAllQuotesConfirmation`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postQuoteRFP = async (quotesArray) => {
  try {
    const response = await globalAxios.post(`/quote_rfp`, quotesArray);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postRFPLoadsPlanningBatch = async (id, loads) => {
  try {
    const response = await globalAxios.post(`/lanesRFP/${id}/LoadsPlanningBatch`, {
      loads,
    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postRequestQuote = async (data) => {
  try {
    const response = await globalAxios.post(`/project_rfp/${data.id}/request_quote`, {
      companies: data.companies,
    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postRequestQuoteConfirmation = async (quoteId) => {
  try {
    const response = await globalAxios.post(`/quote_rfp/${quoteId}/requestQuoteConfirmation`);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postResendQuote = async (projectId: number, companyId: number) => {
  try {
    const response = await globalAxios.post(
      `/project_rfp/${projectId}/company/${companyId}/request_quotes/re_send`,
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const postResendQuoteEmail = async (quoteId: number) => {
  try {
    const response = await globalAxios.post(`/quote_request/${quoteId}/email/resend`);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const updatePrice = async (object) => {
  try {
    const response = await globalAxios.put(`quote_rfp/${object.id}/updatePrice`, {
      price: object.price,
    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const putProjectRFP = async (object) => {
  try {
    const response = await globalAxios.put(`project_rfp/${object.id}`, object.project);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const updateQuotationDateEnd = async (object) => {
  try {
    const response = await globalAxios.put(
      `project_rfp/${object.id}/update/assign_confirmation_expiration`,
      object.body,
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const putQuotationDateEnd = async (object) => {
  try {
    const response = await globalAxios.put(
      `project_rfp/${object.id}/update/quotation_date_end`,
      object.body,
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const updateLanes = async (object) => {
  try {
    const response = await globalAxios.put(`project_rfp/${object.id}/updateLanes`, object.body);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export default {
  cloneProjectWithLanes,
  cloneTendersFromProject,
  deleteFromRanking,
  deleteQuoteRFP,
  deleteLane,
  deleteProject,
  getAssignConfirmation,
  getAsyncResultLanes,
  getLaneById,
  getLaneListFilter,
  getLanesClaimsInfo,
  getCalendar_intervals,
  getLanesByProjectId,
  getLaneByIdCarrier,
  getLoadsInfo,
  getCarrierQuotetionList,
  getPlannedLanesWeeks,
  getProjectClaims,
  getLanesAndQuotesForCarrier,
  getProjectInfoByWeek,
  getProjectsRFPList,
  getProjectsRFPTotalsByStatus,
  getProjectById,
  getProjectByIdCarrier,
  getProjectRFPSearcher,
  getQuoteRFPCarrier,
  getQuoteRFPCarrierById,
  getRankingList,
  getRFPExcel,
  getUnCompletedTootipInfo,
  postAssignConfirmationRFP,
  postAssignDeclineRFP,
  postCarrierConfirm,
  postCarrierRefuse,
  postImportLanesByExcel,
  postNewProject,
  postNewLanes,
  postMoveRankingPosition,
  postProjectRFPEndQuotation,
  postProjectRFPRequestAllQuotesConfirmation,
  postResendQuoteEmail,
  postRevokeQuote,
  postRFPLoadsPlanningBatch,
  postQuoteRFP,
  postAssignedCapacity,
  postRequestQuote,
  postRequestQuoteConfirmation,
  postResendQuote,
  putQuotationDateEnd,
  putProjectRFP,
  updateLanes,
  updatePrice,
  updateQuotationDateEnd,
};
