
import { ref, computed, reactive, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
// Base
import BaseComponent from '@/base/BaseComponent';
// Utils
import { dateStringGetLocalHours } from '@/utils/formatTime';
import i18n from '@/resources/locales';
import { saveAs } from 'file-saver';

export default {
  name: 'LoadLogs',
  mixins: [BaseComponent],
  setup() {
    const $t = i18n.global.t;
    const store = useStore();
    const route = useRoute();
    const trackingLogs = computed(() => store.getters['LoadsStore/getTrackingLogs']);
    const currentLoad = computed(() => store.getters['LoadsStore/currentLoad']);

    const formatLogDate = (dateTimeString: string) => {
      const [datePart] = dateTimeString.split(' ');
      const [day, month, year] = datePart.split('-');
      const monthName = new Date(`${year}-${month}-${day}`).toLocaleString(
        i18n.global.locale.value,
        { month: 'long' },
      );
      return `${monthName} ${day}`;
    };

    const orderedTrackingLogs = computed(() => {
      let logs = [];
      if (trackingLogs.value.length > 0){
        logs = trackingLogs.value.slice().sort((a, b) => {
          const dateA = new Date(a.dateLastUpdated).getTime();
          const dateB = new Date(b.dateLastUpdated).getTime();
          return dateB - dateA;
        });
      }
      return logs;
    });

    const formatLogTime = (dateTimeString: string) => {
      const [, timePart] = dateTimeString.split(' ');
      const [hour, minute] = timePart.split(':');
      return `${hour}:${minute}`;
    };

    const formatLoadType = (type: string) => {
      return type === 'BOL' ? $t('pickup') : $t('delivery');
    };

    const formatCity = (type: string) => {
      const loadType = type === 'BOL' ? 'PICKUP_INITIAL' : 'DELIVERY_FINAL';
      const load = currentLoad.value.stops.find((obj: any) => obj.type === loadType);
      return load?.location?.city + ', ' + load?.location?.state?.iso;
    };

    const ViewDocuments = async (type: string, mediaObjectId: any, mediaObjectType: string) => {
      try {
        const response = await store.dispatch('ShipmentStore/getDownloadFileBlob', {
          id: mediaObjectId,
          class: mediaObjectType,
        });

        const url = URL.createObjectURL(response);
        const newWindow = window.open(url, '_blank');

        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          const fileType = type === 'BOL' ? $t('bol') : $t('pod');
          const fileName = `${fileType}-${mediaObjectId}`;
          const blob = new Blob([response], { type: 'application/pdf' });
          saveAs(blob, fileName, { autoBom: true });
        }
      } catch (error) {
        console.error('Error downloading or displaying file:', error);
      }
    };

    onBeforeMount(() => {
      store.dispatch('LoadsStore/getTrackingLogs', route.params.id);
    });

    return {
      dateStringGetLocalHours,
      formatCity,
      formatLogDate,
      formatLogTime,
      formatLoadType,
      trackingLogs,
      orderedTrackingLogs,
      ViewDocuments,
    };
  },
};
