import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "load-documents" }
const _hoisted_2 = { class: "text-xs text-plan-gray-600" }
const _hoisted_3 = {
  key: 0,
  class: "text-xs text-corp"
}
const _hoisted_4 = { class: "w-full flex justify-between items-start" }
const _hoisted_5 = { class: "flex justify-start items-center gap-2" }
const _hoisted_6 = { class: "load-document-icon" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "text-xs text-plan-gray-600" }
const _hoisted_10 = { class: "flex flex-col gap-2" }
const _hoisted_11 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanModalShare = _resolveComponent("PlanModalShare")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.seeSendModal)
      ? (_openBlock(), _createBlock(_component_PlanModalShare, {
          key: 0,
          deliveryStopID: _ctx.deliveryStopID,
          fileType: _ctx.fileType,
          stopID: _ctx.stopID,
          onClose: _ctx.toggleModalShare
        }, null, 8, ["deliveryStopID", "fileType", "stopID", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
        return (_openBlock(), _createElementBlock("div", {
          key: section,
          class: "section"
        }, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(section.title), 1),
          (!section.documents || section.documents.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('not-documents-uploaded')), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.documents, (document, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: document,
              class: "w-full flex flex-col gap-2"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_plan_icon, {
                      name: "checked-document",
                      "is-svg": "",
                      "icon-color": "black"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(document.displayName)), 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.dateTimeTZ(document.createdDate)), 1)
                  ])
                ]),
                _createVNode(_component_popper, { placement: "left" }, {
                  content: _withCtx(({ close }) => [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_plan_button, {
                        type: "white",
                        "justify-align": "justify-start",
                        size: "sm",
                        label: _ctx.$t('view'),
                        onOnClick: ($event: any) => (_ctx.viewDocumentUploaded(document.id, document.entityClass)),
                        onClick: close
                      }, null, 8, ["label", "onOnClick", "onClick"]),
                      _createVNode(_component_plan_button, {
                        type: "white",
                        "justify-align": "justify-start",
                        size: "sm",
                        label: _ctx.$t('download'),
                        onOnClick: ($event: any) => (_ctx.download(document)),
                        onClick: close
                      }, null, 8, ["label", "onOnClick", "onClick"]),
                      (
                    (document.type === 'BOL' || document.type === 'RATE_CONFIRMATION') &&
                    _ctx.currentLoad.shipment.type !== 'Alpine_LTL' && !_ctx.isCanceled
                  )
                        ? (_openBlock(), _createBlock(_component_plan_button, {
                            key: 0,
                            type: "white",
                            "justify-align": "justify-start",
                            size: "sm",
                            label: _ctx.$t('share'),
                            onOnClick: ($event: any) => (_ctx.toggleModalShare(true, document)),
                            onClick: close
                          }, null, 8, ["label", "onOnClick", "onClick"]))
                        : _createCommentVNode("", true),
                      false
                        ? (_openBlock(), _createBlock(_component_plan_button, {
                            key: 1,
                            type: "white",
                            "justify-align": "justify-start",
                            size: "sm",
                            label: _ctx.$t('reapply'),
                            onOnClick: ($event: any) => (_ctx.resendAction(document)),
                            onClick: close
                          }, null, 8, ["label", "onOnClick", "onClick"]))
                        : _createCommentVNode("", true),
                      (document.type === 'LOAD' && _ctx.currentLoad.shipment.type !== 'Alpine_LTL' && !_ctx.isCanceled)
                        ? (_openBlock(), _createBlock(_component_plan_button, {
                            key: 2,
                            type: "white",
                            "justify-align": "justify-start",
                            size: "sm",
                            label: _ctx.$t('delete'),
                            onOnClick: ($event: any) => (_ctx.deleteFile(document.id)),
                            onClick: close
                          }, null, 8, ["label", "onOnClick", "onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_plan_button_icon, { icon: "circle-options" })
                  ]),
                  _: 2
                }, 1024)
              ]),
              (index < section.documents.length - 1)
                ? (_openBlock(), _createElementBlock("hr", _hoisted_11))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ], 64))
}