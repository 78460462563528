
import i18n from '@/resources/locales';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'UnCompletedLoads',
  props: {
    currentProject: {
      type: Object,
      required: true,
    },
    fetchDataOnHover: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Global
    const store = useStore();
    const $t = i18n.global.t;

    // Variables
    const currentProject = ref(props.currentProject);
    const loading = ref(true);
    const testLanesTooltip = ref([] as any);

    // Methods
    const fetchData = async () => {
      const existingItemIndex = getUnCompletedTootipInfo.value.findIndex(
        (item) => item.projectId === currentProject.value.project.id,
      );

      if (existingItemIndex === -1 || getUnCompletedTootipInfo.value.length === 0) {
        const response = await store.dispatch(
          'RFPStore/getUnCompletedTootipInfo',
          currentProject.value.project.id,
        );
        testLanesTooltip.value = response;
      } else {

        const existingItem = getUnCompletedTootipInfo.value[existingItemIndex];
        testLanesTooltip.value = existingItem.items;
      }

      loading.value = false;
    };

    const incompleteType = (type) => {
      switch (type) {
        case 'cancelled':
          return $t('canceled-loads');
        case 'rebooks':
          return $t('rebooks');
        case 'incidences':
          return $t('pending-with-incidences');
      }
    };

    const openLaneDetail = (object) => {

      emit('openLaneDetail', {
        laneId: object.lanes,
        loadId: object.load,
        projectId: currentProject.value.project.id,
      });
    };

    // Computed

    const getUnCompletedTootipInfo = computed(() => {
      return store.getters['RFPStore/getUnCompletedTootipInfo'];
    });

    // Lifecycle

    onMounted(() => {
      if (props.fetchDataOnHover) {
        fetchData();
      }
    });

    // Watchers
    watch(
      () => props.fetchDataOnHover,
      (newValue) => {
        if (newValue) {
          fetchData();
        }
      },
    );

    return {
      loading,
      testLanesTooltip,
      incompleteType,
      openLaneDetail,
    };
  },
});
