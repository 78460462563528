import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col absolute plan-p-xs z-10"
}
const _hoisted_2 = {
  key: 1,
  class: "plan-rounded-sm overflow-y-auto",
  style: {"width":"140%","height":"312px","background":"rgba(255, 255, 255, 0.9)"}
}
const _hoisted_3 = {
  class: "flex justify-between items-center plan-p-xs bg-white sticky top-0",
  style: {"border-top-left-radius":"8px","border-top-right-radius":"8px"}
}
const _hoisted_4 = { class: "flex justify-start items-center plan-gap-xs plan-t-sec-500 plan-p-xs" }
const _hoisted_5 = {
  class: "plan-t-xxs p-0",
  style: {"max-width":"110px"}
}
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "truncate" }
const _hoisted_8 = { class: "truncate" }
const _hoisted_9 = { class: "plan-t-xxs p-0" }
const _hoisted_10 = { class: "flex flex-col" }
const _hoisted_11 = { class: "truncate" }
const _hoisted_12 = { class: "flex justify-between items-start" }
const _hoisted_13 = { class: "flex flex-col" }
const _hoisted_14 = { class: "font-bold" }
const _hoisted_15 = { class: "plan-t-xs plan-t-prim-100" }
const _hoisted_16 = { class: "plan-mt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_ctx.history.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.showTrackingHistoryModal)
          ? (_openBlock(), _createBlock(_component_plan_button, {
              key: 0,
              onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTrackingHistoryModal = !_ctx.showTrackingHistoryModal)),
              "show-icon": "",
              icon: "clock",
              "icon-color": "#0097FF",
              type: "primary-white",
              label: _ctx.$t('tracking-history')
            }, null, 8, ["label"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_plan_icon, {
                    name: "clock",
                    "icon-color": "#0097FF",
                    "is-svg": ""
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('tracking-history')), 1)
                ]),
                _createVNode(_component_plan_button_icon, {
                  onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTrackingHistoryModal = !_ctx.showTrackingHistoryModal)),
                  icon: "close",
                  type: "transparent-gray-800"
                })
              ]),
              _createVNode(_component_plan_default_table, {
                headers: [],
                items: _ctx.history
              }, {
                default: _withCtx((props) => [
                  _createElementVNode("td", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.moment(props.item.createdDate).format(_ctx.formatDate)), 1),
                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.moment(props.item.createdDate).format('HH:mm')), 1)
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("p", _hoisted_11, _toDisplayString(props.item.location?.zip) + " " + _toDisplayString(props.item.location?.city) + ", " + _toDisplayString(props.item.location?.state?.iso) + " " + _toDisplayString(props.item.location?.state?.country?.iso), 1),
                      (props.item.observations)
                        ? (_openBlock(), _createBlock(_component_plan_text_button, {
                            key: 0,
                            onOnClick: ($event: any) => (_ctx.selectedItem = props.item),
                            label: _ctx.$t('view-comments')
                          }, null, 8, ["onOnClick", "label"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["items"]),
              (_ctx.selectedItem !== null)
                ? (_openBlock(), _createBlock(_component_plan_default_modal, {
                    key: 0,
                    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedItem = null)),
                    size: "sm-auto",
                    "only-content": "",
                    "without-sidebar": ""
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.selectedItem.location?.zip) + " " + _toDisplayString(_ctx.selectedItem.location?.city) + ", " + _toDisplayString(_ctx.selectedItem.location?.state?.iso) + " " + _toDisplayString(_ctx.selectedItem.location?.state?.country?.iso), 1),
                          _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.moment(_ctx.selectedItem.createdDate).format(_ctx.formatDate)), 1)
                        ]),
                        _createVNode(_component_plan_button_icon, {
                          onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedItem = null)),
                          icon: "close",
                          type: "transparent-gray-800"
                        })
                      ]),
                      _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.selectedItem.observations), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
      ]))
    : _createCommentVNode("", true)
}