import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center font-bold space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.isSuplanting !== '')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "fixed bottom-0 left-0 w-full bg-gray-300 h-10 flex justify-center items-center gap-x-4",
        style: _normalizeStyle(
      _ctx.$route.name === 'planificationDetail' || _ctx.$route.name === 'RFP-laneService'
        ? 'z-index: 9400'
        : 'z-index: 9000'
    )
      }, [
        _createElementVNode("span", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('impersonating-to', { user: $setup.currentUser.email })), 1),
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.finishSuplanting && $setup.finishSuplanting(...args))),
            class: "cursor-pointer"
          }, _toDisplayString(_ctx.$t('impersonating-finish')), 1)
        ])
      ], 4))
    : _createCommentVNode("", true)
}