import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded-xl p-5 xl:p-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_new_load_templates_modal = _resolveComponent("new-load-templates-modal")!
  const _component_StepsTab = _resolveComponent("StepsTab")!
  const _component_ShipmentDataStep = _resolveComponent("ShipmentDataStep")!
  const _component_FreightInfoStep = _resolveComponent("FreightInfoStep")!
  const _component_quote_step = _resolveComponent("quote-step")!
  const _component_direct_assign_step = _resolveComponent("direct-assign-step")!
  const _component_FixedBottomBar = _resolveComponent("FixedBottomBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showTemplateModal)
      ? (_openBlock(), _createBlock(_component_new_load_templates_modal, {
          key: 0,
          templates: _ctx.newLoadTemplates,
          onCloseModal: _ctx.closeModal
        }, null, 8, ["templates", "onCloseModal"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["p-5 !pb-32 xl:px-9 xl:pt-0", { fixed: _ctx.showTemplateModal }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_StepsTab, {
          allowMultipleLoad: _ctx.allowMultipleLoad,
          currentView: _ctx.currentView,
          isAdditionalStops: _ctx.isAdditionalStops,
          load: _ctx.load,
          totalLoadsMultiple: _ctx.totalLoadsMultiple,
          onClose: _ctx.changeCurrentView,
          onHandleForm: _ctx.handleForm,
          onUpdateMultipleLoad: _ctx.updateMultipleLoad
        }, null, 8, ["allowMultipleLoad", "currentView", "isAdditionalStops", "load", "totalLoadsMultiple", "onClose", "onHandleForm", "onUpdateMultipleLoad"]),
        _withDirectives(_createVNode(_component_ShipmentDataStep, {
          ref: "shipmentData",
          warehousesAllowed: _ctx.warehousesAllowed,
          onUpdateWarehouse: _ctx.handleChangeWarehouses,
          onChangeAdditionalStops: _ctx.changeAdditionalStops,
          onChangeCurrentView: _ctx.changeCurrentView,
          onSetLoad: _ctx.setLoad
        }, null, 8, ["warehousesAllowed", "onUpdateWarehouse", "onChangeAdditionalStops", "onChangeCurrentView", "onSetLoad"]), [
          [_vShow, _ctx.isLoaded && _ctx.currentView === _ctx.NewLoadStep.DATA]
        ]),
        (_ctx.currentView === _ctx.NewLoadStep.FREIGHT_INFO)
          ? (_openBlock(), _createBlock(_component_FreightInfoStep, {
              key: 0,
              ref: "freightInfo",
              load: _ctx.load,
              dataCommodities: _ctx.load?.commodityMasters,
              dataStops: _ctx.load?.stops,
              onChangeCurrentView: _ctx.changeCurrentView,
              onOnUpdateStops: _ctx.updateStopsLoad
            }, null, 8, ["load", "dataCommodities", "dataStops", "onChangeCurrentView", "onOnUpdateStops"]))
          : _createCommentVNode("", true),
        (_ctx.currentView === _ctx.NewLoadStep.QUOTE)
          ? (_openBlock(), _createBlock(_component_quote_step, {
              key: 1,
              ref: "quotes",
              allowMultipleLoad: _ctx.allowMultipleLoad,
              load: _ctx.load,
              totalLoadsMultiple: _ctx.totalLoadsMultiple,
              warehousesAllowed: _ctx.warehousesAllowed,
              onUpdateSelectedContacts: _ctx.updateSelectedContacts
            }, null, 8, ["allowMultipleLoad", "load", "totalLoadsMultiple", "warehousesAllowed", "onUpdateSelectedContacts"]))
          : _createCommentVNode("", true),
        (_ctx.currentView === _ctx.NewLoadStep.CARRIER)
          ? (_openBlock(), _createBlock(_component_direct_assign_step, {
              key: 2,
              ref: "directAssign",
              allowMultipleLoad: _ctx.allowMultipleLoad,
              load: _ctx.load,
              totalLoadsMultiple: _ctx.totalLoadsMultiple,
              warehousesAllowed: _ctx.warehousesAllowed
            }, null, 8, ["allowMultipleLoad", "load", "totalLoadsMultiple", "warehousesAllowed"]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createVNode(_component_FixedBottomBar, {
      currentView: _ctx.currentView,
      isAdditionalStops: _ctx.isAdditionalStops,
      isSendingDirectAssign: _ctx.isSendingDirectAssign,
      isSendingQuotes: _ctx.isSendingQuotes,
      selectedContacts: _ctx.selectedContacts,
      onFormSubmit: _ctx.handleForm,
      onSendDirectAssign: _ctx.sendDirectAssign,
      onSendQuotes: _ctx.sendQuotes
    }, null, 8, ["currentView", "isAdditionalStops", "isSendingDirectAssign", "isSendingQuotes", "selectedContacts", "onFormSubmit", "onSendDirectAssign", "onSendQuotes"])
  ], 64))
}