import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-start items-center" }
const _hoisted_2 = { class: "w-full flex flex-col plan-gap-md" }
const _hoisted_3 = { class: "rounded plan-b-sec-50 plan-p-sm" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "flex justify-between items-center plan-gap-sm" }
const _hoisted_6 = { class: "flex justify-end items-center" }
const _hoisted_7 = {
  class: "font-bold text-right plan-pl-sm",
  style: {"border-top":"1px solid #151830"}
}
const _hoisted_8 = { class: "text-center plan-t-xs font-semibold plan-t-prim-200" }
const _hoisted_9 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_simple_load_details = _resolveComponent("plan-carrier-simple-load-details")!
  const _component_plan_formatted_number = _resolveComponent("plan-formatted-number")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_input_file = _resolveComponent("plan-input-file")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_button, {
      onOnClick: _ctx.openModal,
      type: "primary",
      label: _ctx.$t('upload-your-invoice')
    }, null, 8, ["onOnClick", "label"]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
          key: 0,
          onClose: _ctx.closeModal,
          title: _ctx.$t('upload-your-invoice'),
          size: "sm-auto",
          "hide-close-button": ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_plan_carrier_simple_load_details),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('upload-your-invoice-amount')), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('upload-your-invoice-service-cost')) + ":", 1),
                  _createVNode(_component_plan_formatted_number, {
                    number: _ctx.totals.price,
                    unit: "currency",
                    "fraction-digits": 2
                  }, null, 8, ["number"])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totals.additionalFees, (additionalFee) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: additionalFee.id,
                    class: "w-full flex justify-between items-center plan-gap-sm"
                  }, [
                    _createElementVNode("span", null, _toDisplayString(additionalFee.label) + ":", 1),
                    _createVNode(_component_plan_formatted_number, {
                      number: additionalFee.value,
                      unit: "currency",
                      "fraction-digits": 2
                    }, null, 8, ["number"])
                  ]))
                }), 128)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, [
                    _createVNode(_component_plan_formatted_number, {
                      number: _ctx.totals.total,
                      unit: "currency",
                      "fraction-digits": 2
                    }, null, 8, ["number"])
                  ])
                ]),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('important')) + ": " + _toDisplayString(_ctx.$t('upload-your-invoice-not-include-other-amounts')), 1)
              ]),
              _createVNode(_component_plan_input_text, {
                "model-value": _ctx.concept,
                "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.concept) = $event)),
                label: _ctx.$t('concept'),
                placeholder: _ctx.$t('include-your-concept')
              }, null, 8, ["model-value", "label", "placeholder"]),
              _createVNode(_component_plan_input_text, {
                "model-value": _ctx.billNumber,
                "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.billNumber) = $event)),
                label: _ctx.$t('bill-number'),
                placeholder: _ctx.$t('include-your-invoice-number')
              }, null, 8, ["model-value", "label", "placeholder"]),
              _createVNode(_component_plan_input_file, {
                onChange: _ctx.changeFiles,
                types: ['application/pdf', 'image/*'],
                maxSize: 10
              }, null, 8, ["onChange"])
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.closeModal,
                disabled: _ctx.loading,
                type: "cancel",
                label: _ctx.$t('cancel')
              }, null, 8, ["onOnClick", "disabled", "label"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.postUploadInvoice,
                loading: _ctx.loading,
                disabled: _ctx.concept.length === 0 || _ctx.billNumber.length === 0 || _ctx.files.length === 0,
                type: "primary",
                label: _ctx.$t('send'),
                style: {"min-width":"100px"}
              }, null, 8, ["onOnClick", "loading", "disabled", "label"])
            ])
          ]),
          _: 1
        }, 8, ["onClose", "title"]))
      : _createCommentVNode("", true)
  ]))
}