
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';

interface Insurance {
  file: File;
}

export default defineComponent({
  name: 'DriverVehicle',
  components: {
    PhoneNumberSelector,
    ErrorMessage,
    Field,
    Form,
    ModalConfirm,
    PlanLoading,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    shippmentId: String,
    mainBodyClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      optionSelected: -1,
      updatingDriverAction: false,
      updatingVehicleAction: false,
      createDriverAction: false,
      createVehicleAction: false,
      deleteVehicleAction: false,
      deleteDriverAction: false,
      unassignDriverAction: false,
      unassignVehicleAction: false,
      updateModalAction: false,
      assignModalAction: false,
      idToDelete: null,
      showDelete: false,
      addDriver: false,
      addVehicle: false,
      sending: false,
      driver: {
        name: null,
        surname: null,
        drivingLicense: null,
        email: null,
        phonePrefix: null,
        phone: null,
        showContactInformation: false,
      },
      driverSelected: null as any,
      trailer: {
        trailerType: { id: null },
        trailerPlate: null,
        licensePlate: null,
      },
      vehicleSelected: null,
      validating: false,
      licenseFrontFile: {} as File,
      licenseFrontInsurance: {} as Insurance,
      licenseFrontUrl: '',
      licenseFrontLoading: false,
      licenseBackFile: {} as File,
      licenseBackInsurance: {} as Insurance,
      licenseBackUrl: '',
      licenseBackLoading: false,
    };
  },
  computed: {
    hasDriver(): any {
      return (
        this.shipment.carrierValidation.hasDriver && this.shipment.carrierValidation.hasVehicle
      );
    },
    brokerDriver(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.driver !== 'undefined'
      ) {
        return [this.shipment.driver];
      } else {
        return null;
      }
    },
    brokerVehicle(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.vehicle !== 'undefined'
      ) {
        return [this.shipment.vehicle];
      } else {
        return null;
      }
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    selectedOption(): string {
      return this.company.type;
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
    drivers(): any {
      return this.$store.getters['CompanyStore/getDriversList'];
    },
    vehicles(): any {
      return this.$store.getters['CompanyStore/getVehiclesList'];
    },
    company(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
    driverSelectedMedia(): any {
      if (this.driverSelected) {
        this.getFile(this.drivers.find((driver) => driver.id === this.driverSelected));
        return this.drivers.find((driver) => driver.id === this.driverSelected);
      }
      return undefined;
    },
  },
  beforeCreate() {
    this.$store.dispatch('LoadsStore/trailers');
  },
  async created() {
    await this.$store.dispatch('ShipmentStore/shipment', { id: this.$route.params.id });
    await this.$store.dispatch('CompanyStore/getCompany', this.currentUser?.company?.id);
    await this.$store.dispatch('CompanyStore/driversList', this.currentUser?.company?.id);
    await this.$store.dispatch('CompanyStore/vehiclesList', this.currentUser?.company?.id);

    if (this.shipment.carrierValidation.hasDriver || this.shipment.carrierValidation.hasVehicle) {
      const driverId = this.shipment.driver?.id;
      const vehicleId = this.shipment.vehicle?.id;
      const driverFound = driverId ? this.drivers.find((item) => item.id === driverId) : null;
      const vehicleFound = vehicleId
        ? this.vehicles.find((item) => item.id === this.shipment.vehicle.id)
        : null;

      this.driverSelected = driverFound ? driverFound.id : null;
      this.vehicleSelected = vehicleFound ? vehicleFound.id : null;
    }
    this.getFile(this.driverSelectedMedia);
  },
  methods: {
    handleChangePhone(type, phone) {
      let searchArray = this.selectedOption === 'BROKER' ? this.brokerDriver : this.drivers;

      const result = searchArray.find((item) => item.id === this.driverSelected);

      switch (type) {
        case 'prefix':
          result.phonePrefix = phone.prefix;
          break;
        case 'number':
          result.phone = phone.number;
          break;
        default:
          return;
      }
    },
    resetInsurancesSelected() {
      this.licenseFrontFile = {} as any;
      this.licenseFrontInsurance = {} as any;
      this.licenseBackFile = {} as any;
      this.licenseBackInsurance = {} as any;

      console.log(
        this.licenseFrontFile,
        this.licenseFrontInsurance,
        this.licenseBackFile,
        this.licenseBackInsurance,
      );
    },
    async getFile(driverSelectedMedia) {
      this.licenseFrontUrl = '';
      this.licenseBackUrl = '';
      const index = this.drivers.indexOf(driverSelectedMedia);
      for (const mediaObject of driverSelectedMedia.mediaObjects) {
        if (mediaObject.type === 'DRIVER_LICENSE_BACK') {
          this.licenseBackLoading = true;
        } else if (mediaObject.type === 'DRIVER_LICENSE_FRONT') {
          this.licenseFrontLoading = true;
        }

        await this.$store
          .dispatch('CompanyStore/getDownloadFileDriver', {
            id: driverSelectedMedia.mediaObjects[0].id,
            class: 'DRIVER',
          })
          .then(async (response) => {
            if (driverSelectedMedia.mediaObjects[0].type === 'DRIVER_LICENSE_FRONT') {
              this.licenseFrontInsurance.file = new File(
                [response],
                driverSelectedMedia.mediaObjects[0].name,
              );
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(this.licenseFrontInsurance.file);
              (this.$refs['licenseFrontFile' + index] as any)[0].files = dataTransfer.files;
              this.licenseFrontFile = this.licenseFrontInsurance.file;

              const result = await this.$store.dispatch(
                'CompanyStore/getDownloadThumbnailBigDriver',
                {
                  id: driverSelectedMedia.mediaObjects[0].id,
                  class: 'DRIVER',
                },
              );
              this.licenseFrontUrl = URL.createObjectURL(result);
              this.licenseFrontLoading = false;
            } else {
              this.licenseBackInsurance.file = new File(
                [response],
                driverSelectedMedia.mediaObjects[0].name,
              );
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(this.licenseBackInsurance.file);
              if (this.$refs['licenseBackFile' + index]) {
                (this.$refs['licenseBackFile' + index] as any)[0].files = dataTransfer.files;
              }

              this.licenseBackFile = this.licenseBackInsurance.file;

              const result = await this.$store.dispatch(
                'CompanyStore/getDownloadThumbnailBigDriver',
                {
                  id: driverSelectedMedia.mediaObjects[0].id,
                  class: 'DRIVER',
                },
              );
              this.licenseBackUrl = URL.createObjectURL(result);
              this.licenseBackLoading = false;
            }

            if (driverSelectedMedia.mediaObjects[1].type === 'DRIVER_LICENSE_FRONT') {
              this.licenseFrontInsurance.file = new File(
                [response],
                driverSelectedMedia.mediaObjects[1].name,
              );
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(this.licenseFrontInsurance.file);
              if (this.$refs['licenseFrontFile' + index]) {
                (this.$refs['licenseFrontFile' + index] as any)[0].files = dataTransfer.files;
              }

              this.licenseFrontFile = this.licenseFrontInsurance.file;

              const result = await this.$store.dispatch(
                'CompanyStore/getDownloadThumbnailBigDriver',
                {
                  id: driverSelectedMedia.mediaObjects[1].id,
                  class: 'DRIVER',
                },
              );
              this.licenseFrontUrl = URL.createObjectURL(result);
              this.licenseFrontLoading = false;
            } else {
              this.licenseBackInsurance.file = new File(
                [response],
                driverSelectedMedia.mediaObjects[1].name,
              );
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(this.licenseBackInsurance.file);
              if (this.$refs['licenseBackFile' + index]) {
                (this.$refs['licenseBackFile' + index] as any)[0].files = dataTransfer.files;
              }

              this.licenseBackFile = this.licenseBackInsurance.file;

              const result = await this.$store.dispatch(
                'CompanyStore/getDownloadThumbnailBigDriver',
                {
                  id: driverSelectedMedia.mediaObjects[1].id,
                  class: 'DRIVER',
                },
              );
              this.licenseBackUrl = URL.createObjectURL(result);
              this.licenseBackLoading = false;
            }
          });
      }
    },
    handleChange(type: string) {
      const index = this.drivers.indexOf(this.driverSelectedMedia);
      if (index !== -1) {
        switch (type) {
          case 'licenseFrontFile':
            this.licenseFrontInsurance.file = (
              this.$refs['licenseFrontFile' + index] as any
            )[0].files[0];
            this.licenseFrontUrl = URL.createObjectURL(this.licenseFrontInsurance.file);
            break;
          case 'licenseBackFile':
            this.licenseBackInsurance.file = (
              this.$refs['licenseBackFile' + index] as any
            )[0].files[0];
            this.licenseBackUrl = URL.createObjectURL(this.licenseBackInsurance.file);
            break;
        }
      } else {
        switch (type) {
          case 'licenseFrontFile':
            this.licenseFrontInsurance.file = (this.$refs.licenseFrontFile as any).files[0];
            this.licenseFrontUrl = URL.createObjectURL(this.licenseFrontInsurance.file);
            break;
          case 'licenseBackFile':
            this.licenseBackInsurance.file = (this.$refs.licenseBackFile as any).files[0];
            this.licenseBackUrl = URL.createObjectURL(this.licenseBackInsurance.file);
            break;
        }
      }
    },
    isPhoto(format) {
      return format === 'image/png' || format === 'image/jpg' || format === 'image/jpeg';
    },
    async UnassignVehicle() {
      this.unassignVehicleAction = true;
      await this.$store
        .dispatch('UserStore/deAssignVehicle', {
          id: this.$route.params.id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('unassign-done'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.vehicleSelected = null;
          this.unassignVehicleAction = false;
        });
      await this.$store.dispatch('ShipmentStore/shipment', { id: this.$route.params.id });
    },
    async UnassignDriver() {
      this.unassignDriverAction = true;
      await this.$store
        .dispatch('UserStore/deAssignDriver', {
          id: this.$route.params.id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('unassign-done'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.driverSelected = null;
          this.unassignDriverAction = false;
        });
      await this.$store.dispatch('ShipmentStore/shipment', { id: this.$route.params.id });
    },
    async updateDriver() {
      console.log('this.driver', this.driver);

      if (this.selectedOption === 'BROKER') {
        if (Object.values(this.driver).every((value) => value === null)) {
          BaseComponent.methods?.showToastError(this.$t('empty-fields-driver'));
          this.sending = false;
          return;
        }
        if (this.trailer.licensePlate === null || this.trailer.trailerType.id === null) {
          BaseComponent.methods?.showToastError(this.$t('empty-fields-vehicle'));
          this.sending = false;
          return;
        }

        let driverID;
        let vehicleID;

        this.updateModalAction = true;
        this.assignModalAction = true;

        await this.$store
          .dispatch('CompanyStore/postDriver', { ...this.driver, company: { id: this.company.id } })
          .then((response) => {
            driverID = response.id;
            BaseComponent.methods?.showToastSuccess(this.$t('driver-created'));
            this.addDriver = false;
            this.$store.dispatch('CompanyStore/driversList', this.company.id);
          });

        await this.$store
          .dispatch('CompanyStore/postVehicle', {
            ...this.trailer,
            company: { id: this.company.id },
          })
          .then((response) => {
            vehicleID = response.id;
            BaseComponent.methods?.showToastSuccess(this.$t('vehicle-created'));
            this.addVehicle = false;
            this.$store.dispatch('CompanyStore/vehiclesList', this.company.id);
          });

        try {
          await Promise.all([
            this.$store.dispatch('ShipmentStore/assignDriver', {
              id: this.shippmentId,
              driver: { id: driverID },
            }),
            this.$store.dispatch('ShipmentStore/assignVehicle', {
              id: this.shippmentId,
              vehicle: { id: vehicleID },
            }),
          ]);
          BaseComponent.methods?.showToastSuccess(this.$t('assigned-correctly'));
          const id = this.$route.params.id;
          await this.$store.dispatch('ShipmentStore/shipment', { id: id });
          this.$emit('closeModal');
        } catch (err: any) {
          console.error(err?.response?.data?.violations);
        }

        this.updateModalAction = false;
        this.assignModalAction = false;
      } else {
        if (this.driverSelected === null || this.vehicleSelected === null) {
          BaseComponent.methods?.showToastError(this.$t('pending-select-create'));
          return;
        } else {
          this.updateModalAction = true;
          this.assignModalAction = true;

          try {
            await Promise.all([
              this.$store.dispatch('ShipmentStore/assignDriver', {
                id: this.shippmentId,
                driver: { id: this.driverSelected },
              }),
              this.$store.dispatch('ShipmentStore/assignVehicle', {
                id: this.shippmentId,
                vehicle: { id: this.vehicleSelected },
              }),
            ]);
            BaseComponent.methods?.showToastSuccess(this.$t('updated-correctly'));
            const id = this.$route.params.id;
            await this.$store.dispatch('ShipmentStore/shipment', { id: id });
            this.$emit('closeModal');
          } catch (err: any) {
            console.error(err?.response?.data?.violations);
          }

          this.updateModalAction = false;
          this.assignModalAction = false;
        }
      }
    },
    updateData() {
      const vehicle = this.vehicles.find((v) => v.id === this.vehicleSelected);
      const newTrailer = this.trailerTypes.find((v) => v.id === vehicle.trailerType.id);

      this.updatingVehicleAction = true;

      this.$store
        .dispatch('CompanyStore/putVehicle', {
          id: this.vehicleSelected,
          trailerType: newTrailer,
          licensePlate: vehicle.licensePlate,
          trailerPlate: vehicle.trailerPlate,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('assigned-correctly'));
        })
        .catch((err) => {
          console.log(err);
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.updatingVehicleAction = false;
        });
    },
    openModalDelete(id) {
      this.idToDelete = id;
      this.showDelete = true;
    },
    async createDriver() {
      this.createDriverAction = true;
      this.$store
        .dispatch('CompanyStore/postDriver', { ...this.driver, company: { id: this.company.id } })
        .then((response) => {
          BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
          this.addDriver = false;
          this.$store.dispatch('CompanyStore/driversList', this.company.id);

          this.$store
            .dispatch('CompanyStore/postMediaObjectDriver', {
              entityId: response.id,
              type: 'DRIVER_LICENSE_FRONT',
              file: this.licenseFrontInsurance.file,
            })
            .then(() => {
              BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
              this.addDriver = false;
              this.$store.dispatch('CompanyStore/driversList', this.company.id);
            })
            .catch((err) => {
              console.log(err);
            });
          this.$store
            .dispatch('CompanyStore/postMediaObjectDriver', {
              entityId: response.id,
              type: 'DRIVER_LICENSE_BACK',
              file: this.licenseBackInsurance.file,
            })
            .then(() => {
              BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
              this.addDriver = false;
              this.$store.dispatch('CompanyStore/driversList', this.company.id);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
      this.createDriverAction = false;
    },
    handleDriverUpdateSuccess() {
      this.showToastSuccess(this.$t('created-successfully'));
      this.addDriver = false;
      this.$store.dispatch('CompanyStore/driversList', this.company.id);
    },
    async updateDriverMedia(driver, type, fileSelected) {
      if (!fileSelected) {
        return;
      }
      let licenseType = type === 'front' ? 'DRIVER_LICENSE_FRONT' : 'DRIVER_LICENSE_BACK';

      const { id } = driver.mediaObjects.find((obj) => obj.type === licenseType);
      const { file } = fileSelected;

      await this.$store.dispatch('CompanyStore/postUpdateMediaObjectDriver', {
        id,
        type: licenseType,
        file,
      });

      this.handleDriverUpdateSuccess();
    },

    async postDriverMedia(driver, type, fileSelected) {
      if (!fileSelected) {
        return;
      }
      const { id } = driver;
      const { file } = fileSelected;

      let licenseType = type === 'front' ? 'DRIVER_LICENSE_FRONT' : 'DRIVER_LICENSE_BACK';

      await this.$store.dispatch('CompanyStore/postMediaObjectDriver', {
        entityId: id,
        type: licenseType,
        file,
      });

      this.handleDriverUpdateSuccess();
    },
    async editDriver(driver) {
      console.log('driver', driver);
      if (!driver) return;

      const userHaveUploadedFrontMedia = driver.mediaObjects.some(
        (obj) => obj.type === 'DRIVER_LICENSE_FRONT',
      );
      const userHaveUploadedBackMedia = driver.mediaObjects.some(
        (obj) => obj.type === 'DRIVER_LICENSE_BACK',
      );

      const index = this.drivers.indexOf(this.driverSelectedMedia);
      this.updatingDriverAction = true;

      try {
        if (userHaveUploadedFrontMedia && this.licenseFrontInsurance) {
          await this.updateDriverMedia(driver, 'front', this.licenseFrontInsurance);
        } else {
          await this.postDriverMedia(driver, 'front', this.licenseFrontInsurance);
        }

        if (userHaveUploadedBackMedia && this.licenseBackInsurance) {
          await this.updateDriverMedia(driver, 'back', this.licenseBackInsurance);
        } else {
          await this.postDriverMedia(driver, 'back', this.licenseBackInsurance);
        }
      } catch (error) {
        console.error(error);
      }

      await this.$store
        .dispatch('CompanyStore/putDriver', {
          id: driver.id,
          params: {
            name: driver.name,
            surname: driver.surname,
            drivingLicense: driver.drivingLicense,
            email: driver.email,
            phonePrefix: driver.phonePrefix,
            phone: driver.phone,
            showContactInformation: driver.showContactInformation,
          },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('updated-correctly'));
          this.addDriver = false;
          // this.$store.dispatch('CompanyStore/driversList', this.company.id);

          // if (driver.mediaObjects && driver.mediaObjects.length > 0) {
          //   if (
          //     this.isPhoto(this.licenseFrontInsurance.file.type) &&
          //     driver.mediaObjects[0].type === 'DRIVER_LICENSE_FRONT'
          //   ) {
          //     this.$store
          //       .dispatch('CompanyStore/postUpdateMediaObjectDriver', {
          //         id: driver.mediaObjects[0].id,
          //         type: 'DRIVER_LICENSE_FRONT',
          //         file: this.licenseFrontInsurance.file,
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   }
          //   if (
          //     this.isPhoto(this.licenseBackInsurance.file.type) &&
          //     driver.mediaObjects[0].type === 'DRIVER_LICENSE_BACK'
          //   ) {
          //     this.$store
          //       .dispatch('CompanyStore/postUpdateMediaObjectDriver', {
          //         id: driver.mediaObjects[0].id,
          //         type: 'DRIVER_LICENSE_BACK',
          //         file: this.licenseBackInsurance.file,
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   }

          //   if (
          //     this.isPhoto(this.licenseFrontInsurance.file.type) &&
          //     driver.mediaObjects[1].type === 'DRIVER_LICENSE_FRONT'
          //   ) {
          //     this.$store
          //       .dispatch('CompanyStore/postUpdateMediaObjectDriver', {
          //         id: driver.mediaObjects[1].id,
          //         type: 'DRIVER_LICENSE_FRONT',
          //         file: this.licenseFrontInsurance.file,
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   }
          //   if (
          //     this.isPhoto(this.licenseBackInsurance.file.type) &&
          //     driver.mediaObjects[1].type === 'DRIVER_LICENSE_BACK'
          //   ) {
          //     this.$store
          //       .dispatch('CompanyStore/postUpdateMediaObjectDriver', {
          //         id: driver.mediaObjects[1].id,
          //         type: 'DRIVER_LICENSE_BACK',
          //         file: this.licenseBackInsurance.file,
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //       });
          //   }
          // }
          this.updatingDriverAction = false;
        });
    },
    deleteVehicle() {
      this.deleteVehicleAction = true;
      this.$store
        .dispatch('CompanyStore/deleteVehicles', {
          id: this.vehicleSelected,
        })

        .then(() => {
          this.$store.dispatch('CompanyStore/vehiclesList', this.company.id);
          this.vehicleSelected = null;
          BaseComponent.methods?.showToastSuccess(this.$t('updated-correctly'));
        })
        .catch((err) => {
          console.log(err);
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.deleteVehicleAction = false;
        });
    },
    deleteDriver(isDelete) {
      if (isDelete) {
        this.$store
          .dispatch('CompanyStore/deleteDriver', this.idToDelete)
          .then(() => {
            this.$store.dispatch('CompanyStore/driversList', this.company.id);
            this.driverSelected = null;
          })
          .catch((err) => {
            if (err) {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            }
          })
          .finally(() => {
            this.showDelete = false;
          });
      } else {
        this.showDelete = false;
      }
    },
    createVehicle() {
      this.createVehicleAction = true;
      this.$store
        .dispatch('CompanyStore/postVehicle', { ...this.trailer, company: { id: this.company.id } })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
          this.addVehicle = false;
          this.$store.dispatch('CompanyStore/vehiclesList', this.company.id);
        })
        .catch((err) => {
          if (err) {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          }
        })
        .finally(() => {
          this.createVehicleAction = false;
        });
    },
    async validateForm() {
      if (this.sending) return;
      this.sending = true;
      this.validating = true;
      const validation = await (this.$refs.form as any).validate();

      if (!validation.valid) {
        this.sending = false;
        return;
      }
      let error = false;
      await this.$store
        .dispatch('ShipmentStore/assignDriver', {
          id: this.shippmentId,
          driver: { id: this.driverSelected },
        })
        .catch((err) => {
          err.response.data.violations;
          error = true;
        });

      // if (this.selectedOption === 'CARRIER') {
      await this.$store
        .dispatch('ShipmentStore/assignVehicle', {
          id: this.shippmentId,
          vehicle: { id: this.vehicleSelected },
        })
        .catch((err) => {
          err.response.data.violations;
          error = true;
        });
      // }

      this.sending = false;
      if (!error) {
        BaseComponent.methods?.showToastSuccess(this.$t('information-updated'));
        const id = this.$route.params.id;
        this.$store.dispatch('ShipmentStore/shipment', { id: id });
        this.$emit('updateStep');
        this.$emit('nextStep', 'ServiceInsurances');
      }
    },
  },
  watch: {
    driverSelected() {
      if (this.driverSelected) {
        this.getFile(this.driverSelectedMedia);
      }
    },
  },
});
