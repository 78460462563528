import globalAxios from 'axios';

const getGlobalConfig = async () => {
    const response = await globalAxios.get('/global-config');
    return Promise.resolve(response.data);
};

export default {
    getGlobalConfig
}
