
import { defineComponent, reactive, toRefs, computed, watch, onMounted } from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanTextArea from '@/components/forms/PlanTextArea.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

import { useStore } from 'vuex';
import i18n from '@/resources/locales';
import SelectRecipientsModal from '@/modules/planning/contacts/BlastEmail/SelectRecipientsModal.vue';
import PlanSelectedRecipientButton from '@/components/buttons/PlanSelectedRecipientButton.vue';

interface BlastEmailForm {
  FILES: Array<any>;
  subject: string;
  contactPerson: string;
  showCurrentContacts: boolean;
  additionalInfo: string;
  validationResults: object;
  senderIncluded: boolean;
  showContacts: boolean
}

export default defineComponent({
  name: 'BlastEmailForm',
  components: { PlanSelectedRecipientButton, SelectRecipientsModal, PlanInputText, PlanIcon, PlanTextArea, PlanButtonIcon },
  emits: ['update'],
  props: {
    preSubject: { type: String, required: false, default: '' },
    preMessage: { type: String, required: false, default: '' }
  },
  setup(props, { emit }) {
    const store = useStore();
    const $t = i18n.global.t;

    onMounted(() => {
      store.commit('LoadsStore/setLoadAditionalDocuments', []);
    });

    const data = reactive<BlastEmailForm>({
      FILES: [] as any,
      subject: '',
      showCurrentContacts: false,
      additionalInfo: '',
      contactPerson: '',
      validationResults: {
        subject: false,
        additionalInfoSecond: false,
      },
      senderIncluded: false,
      showContacts: false
    });

    if (props.preSubject.length > 0) data.subject = props.preSubject + ''
    if (props.preMessage.length > 0) data.additionalInfo = props.preMessage + ''

    const closeSelectRecipientsModal = () => {
      data.showContacts = false
    }

    const selectedContacts = computed(() => {
      return store.getters['CompanyStore/getContactsBlastEmail'];
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const additionalDocuments = computed(() => {
      return store.getters['LoadsStore/getLoadAditionalDocuments'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const getFileLink = (file) => {
      let fileLink;

      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'application/pdf'
      ) {
        fileLink = URL.createObjectURL(file);
      } else {
        fileLink = '#';
      }
      return fileLink;
    };

    const removeFile = (id, index) => {
      const filesArray = Array.from(store.getters['LoadsStore/getLoadAditionalDocuments']);
      filesArray.splice(index, 1);
      store.commit('LoadsStore/setLoadAditionalDocuments', filesArray);
    };

    const getDocumentsUploaded = () => {
      let getDocumentsUploaded = document.getElementById('getDocumentsUploaded');
      if (getDocumentsUploaded) getDocumentsUploaded.click();
    };

    const handleFileChange = (event) => {
      const files = event.target.files;
      const filesArray: File[] = Array.from(files);
      const acceptedTypes = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png',
      ];
      const maxSize = 100 * 1024 * 1024;

      const validFiles = filesArray.filter((file) => {
        if (!acceptedTypes.includes(file.type)) {
          store.dispatch('UserStore/updateToast', {
            text: $t('file-not-valid-type', { file: file.name }),
            type: 'error',
            visible: true,
          });
          return false;
        }

        if (file.size > maxSize) {
          store.dispatch('UserStore/updateToast', {
            text: $t('file-not-valid-size', { file: file.name }),
            type: 'error',
            visible: true,
          });

          return false;
        }

        return true;
      });

      data.FILES = validFiles;

      if (additionalDocuments.value.length > 0) {
        const existingFiles = store.getters['LoadsStore/getLoadAditionalDocuments'];
        const mergedFiles = [...existingFiles, ...validFiles];
        store.commit('LoadsStore/setLoadAditionalDocuments', mergedFiles);
      } else {
        store.commit('LoadsStore/setLoadAditionalDocuments', validFiles);
      }

      update();
    };

    const handleAddUser = () => {
      data.senderIncluded = !data.senderIncluded;
    };

    const deleteFromSelected = (_id: number) => {
      selectedContacts.value.splice(_id, 1);
    };

    const update = () => {
      emit('update', {
        additionalInfo: data.additionalInfo,
        subject: data.subject,
        selectedContacts: selectedContacts.value,
        files: data.FILES,
        senderIncluded: data.senderIncluded,
      });
    };

    watch(() => props.preSubject, (newValue, oldValue) => {
      if (props.preSubject.length > 0) data.subject = props.preSubject + ''
    })

    watch(() => props.preMessage, (newValue, oldValue) => {
      if (props.preMessage.length > 0) data.additionalInfo = props.preMessage + ''
    })

    update()

    return {
      ...toRefs(data),
      additionalDocuments,
      closeSelectRecipientsModal,
      deleteFromSelected,
      handleAddUser,
      handleFileChange,
      update,
      selectedContacts,
      getDocumentsUploaded,
      getFileLink,
      removeFile
    };
  },
});
