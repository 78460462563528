
import { defineComponent } from 'vue';
import { ChevronDownIcon, ChevronUpIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { StarIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
// Modules
import ConsigneeAccepted from '@/modules/planning/loadDetail/assignment/ConsigneeAccepted.vue';
import LoadDetails from '@/modules/planning/loadDetail/_components/LoadDetails.vue';
import LTLStopsForm from '@/modules/planning/loadDetail/assignment/LTLStopsForm.vue';
import StopsForm from '@/modules/planning/loadDetail/assignment/StopsForm.vue';
import UserSection from '@/modules/superAdmin/_components/UserSection.vue';

export default defineComponent({
  name: 'ShipperLoadDetailAssignment',
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    ConsigneeAccepted,
    LoadDetails,
    LTLStopsForm,
    ModalConfirm,
    StarIcon,
    StopsForm,
    XCircleIcon,
    UserSection,
  },
  mixins: [BaseComponent],
  props: {
    loadId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isModifying: false,
      stopToModify: 0,
      showDriverAndVehicle: false,
      isSending: false,
      // isDeAssign: false,
      showDeAssign: false,
      deassignModalText: this.$t('de-assign-confirm'),
      licenseFrontUrl: '',
      licenseBackUrl: '',
    };
  },
  computed: {
    isRCSigned(): boolean {
      if (
        this.currentLoad?.shipment?.mediaObjects &&
        this.currentLoad?.shipment?.mediaObjects.length > 0
      ) {
        return this.currentLoad?.shipment?.mediaObjects?.some(
          (item: any) => item.type === 'RATE_CONFIRMATION',
        );
      } else {
        return false;
      }
    },
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    currentStatus(): any {
      return this.currentLoad ? this.currentLoad.status : null;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    currentShipment(): any {
      return this.currentLoad.shipment;
    },
    isCanceled(): boolean {
      return this.currentLoad.status === 'CANCELED';
    },
    isInTransitOrFinished(): any {
      let status = this.currentStatus;
      return status === 'IN_PROGRESS' || status === 'FINISHED';
    },
    isConsigneeFilled(): any {
      return this.currentLoad.isConsigneeFilled;
    },
    isModify(): boolean {
      return true;
      // return this.currentStatus === 'CONFIRMED';
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    returnCompanyAssignedType(): any {
      return this.currentLoad?.shipment?.assignedCompany?.company?.type;
    },
    pickupsHasMultiStopsFilled() {
      const pickups = this.currentLoad.stops.filter(
        (stop: any) => stop.type === 'PICKUP' || stop.type === 'PICKUP_INITIAL',
      );
      let pickupsHasMultiStopsFilled = true;
      pickups.forEach((pickup: any) => {
        if (!pickup.multiStop.length) {
          pickupsHasMultiStopsFilled = false;
        }
      });

      return pickupsHasMultiStopsFilled;
    },
  },
  async created() {
    if (this.currentLoad?.shipment?.driver?.mediaObjects[0]?.type === 'DRIVER_LICENSE_FRONT') {
      const result = await this.$store.dispatch('CompanyStore/getDownloadThumbnailBigDriver', {
        id: this.currentLoad?.shipment?.driver?.mediaObjects[0].id,
        class: 'DRIVER',
      });
      this.licenseFrontUrl = URL.createObjectURL(result);
    }
    if (this.currentLoad?.shipment?.driver?.mediaObjects[1]?.type === 'DRIVER_LICENSE_FRONT') {
      const result = await this.$store.dispatch('CompanyStore/getDownloadThumbnailBigDriver', {
        id: this.currentLoad?.shipment?.driver?.mediaObjects[1].id,
        class: 'DRIVER',
      });
      this.licenseFrontUrl = URL.createObjectURL(result);
    }
    if (this.currentLoad?.shipment?.driver?.mediaObjects[0]?.type === 'DRIVER_LICENSE_BACK') {
      const result = await this.$store.dispatch('CompanyStore/getDownloadThumbnailBigDriver', {
        id: this.currentLoad?.shipment?.driver?.mediaObjects[0].id,
        class: 'DRIVER',
      });
      this.licenseBackUrl = URL.createObjectURL(result);
    }
    if (this.currentLoad?.shipment?.driver?.mediaObjects[1]?.type === 'DRIVER_LICENSE_BACK') {
      const result = await this.$store.dispatch('CompanyStore/getDownloadThumbnailBigDriver', {
        id: this.currentLoad?.shipment?.driver?.mediaObjects[1].id,
        class: 'DRIVER',
      });
      this.licenseBackUrl = URL.createObjectURL(result);
    }
  },
  methods: {
    async addMemberToChatLoad(chatId) {
      await this.$store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    },
    closeModifyConsignee() {
      this.isModifying = false;
    },
    modifyConsignee(stop) {
      this.stopToModify = stop ? stop : 0;
      if (this.isModify) {
        this.isModifying = true;
      }
    },
    async openChat() {
      const member = this.currentLoad?.chat?.chatMembers.find(
        (member) => member.user.id === this.currentUser?.id,
      );
      if (member) {
        member.readed = true;
      } else {
        if (this.currentLoad.chat?.id) {
          await this.addMemberToChatLoad(this.currentLoad.chat.id);
        }
      }
      this.$store.commit('GlobalStore/setOpenChat', true);
      this.$store.commit('LoadsStore/loadId', this.currentLoad.id);
      this.$store.commit(
        'ChatsStore/setChatId',
        this.currentLoad.chat ? this.currentLoad.chat.id : null,
      );
    },
    openImage(url) {
      const newWindow = window.open(url, '_blank');
    },
    showModalDeAssign() {
      this.showDeAssign = true;
    },
    submitDeAssign(data: boolean) {
      if (!data) {
        this.showDeAssign = false;
        return;
      } else {
        this.showDeAssign = false;
        this.isSending = true;
        this.$store
          .dispatch('UserStore/reAssignLoad', this.currentLoad.shipment.assignedCompany.id)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('message-unassigned-shipment'));
            this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id).then(() => {
              const query = { page: 1 };
              this.$store.dispatch('LoadsStore/loads', query).then(() => {
                BaseComponent.methods?.showToastSuccess(this.$t('message-load-modified'));
              });
              this.isSending = false;
            });
          })
          .catch((err) => {
            console.log(err);
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            this.isSending = false;
          });
      }
    },
  },
});
