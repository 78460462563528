
import { defineComponent, ref, computed } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PlanInputFile',
  components: { PlanButtonIcon, PlanIcon },
  props: {
    label: { type: String, required: false, default: '' },
    message: { type: String, required: false, default: '' },
    multiple: { type: Boolean, required: false, default: false },
    maxSize: { type: Number, required: false, default: 20 },
    types: { type: Array as () => string[], required: false, default: () => ['*'] },
    icon: { type: String, required: false, default: 'upload' },
    loading: { type: Boolean, required: false, default: false },
    hideLoadedItems: { type: Boolean, required: false, default: false }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const store = useStore()
    const fileInput = ref<HTMLInputElement | null>(null);
    const isDragging = ref(false);
    const files = ref<File[]>([]);
    const maxSizeInBytes = props.maxSize * 1024 * 1024;
    const acceptTypes = computed(() => props.types.join(','));
    const translate = useI18n()

    const onDragOver = () => {
      isDragging.value = true;
    };

    const onDragLeave = () => {
      isDragging.value = false;
    };

    const onDrop = (event: DragEvent) => {
      isDragging.value = false;
      if (event.dataTransfer && event.dataTransfer.files.length && !props.loading) {
        addFiles(event.dataTransfer.files);
      }
    };

    const onClick = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const onFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length) {
        addFiles(target.files);
      }
    };

    const addFiles = (fileList: FileList) => {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.size <= maxSizeInBytes && isValidType(file)) {
          if (props.multiple) {
            files.value.push(file);
          } else {
            files.value = [file];
            break;
          }
        } else if (file.size > maxSizeInBytes) {
          store.dispatch('UserStore/updateToast', {
            text: translate.t('plan-input-file.error-max-size', { fileName: file.name, size: props.maxSize }),
            type: 'error',
            visible: true
          })
        } else {
          store.dispatch('UserStore/updateToast', {
            text: translate.t('plan-input-file.error-allowed-types', { fileName: file.name, types: props.types.join(', ') }),
            type: 'error',
            visible: true
          })
        }
      }
      emit('change', files.value)
    };

    const isValidType = (file: File) => {
      if (props.types.includes('*')) return true;
      if (props.types.includes('image/*') && file.type.startsWith('image/')) return true;
      return props.types.includes(file.type);
    };

    const removeFile = (index: number) => {
      files.value.splice(index, 1);
      emit('change', files.value)
    };

    return {
      fileInput,
      isDragging,
      files,
      onDragOver,
      onDragLeave,
      onDrop,
      onClick,
      onFileChange,
      removeFile,
      acceptTypes
    };
  },
});
