
import { defineComponent, ref, computed, onMounted, reactive, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
// Components
import PlanIcon from '@/components/icons/PlanIcon.vue';

interface planFilter {
  isInputActive: boolean;
  selectedItem: string;
  showPopper: boolean;
  valueFilter: string;
}

export default defineComponent({
  name: 'SelectSearch',
  components: {
    PlanIcon,
  },
  emits: ['valueChange'],
  props: {
    items: { type: Array as any, required: true, default: [] as any },
    inputValue: { type: String, required: true, default: '' },
    placeholder: { type: String, required: true, default: '' },
    title: { type: String, required: true, default: '' },
    size: { type: String, required: false, default: 'medium' },
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive<planFilter>({
      isInputActive: false,
      selectedItem: '',
      showPopper: false,
      valueFilter: '',
    });

    const iconColor = computed(() => {
      return data.isInputActive ? '#0097FF' : '#4F5264';
    });

    const itemsFiltered = computed(() => {
      return props.items.filter((item) =>
        item.label.toLowerCase().includes(data.valueFilter.toLowerCase()),
      );
    });

    onMounted(() => {
      data.selectedItem = props.inputValue;
    });

    const cleanItem = () => {
      data.selectedItem = '';
      emitValue();
    };

    const emitValue = () => {
      emit('valueChange', data.selectedItem);
    };

    const handleInputFocus = () => {
      data.showPopper = true;
      data.isInputActive = true;
    };

    const handleInputBlur = () => {
      data.showPopper = false;
      data.isInputActive = false;
    };

    const selItem = (value: string) => {
      data.selectedItem = value;
      handleInputBlur();
      emitValue();
    };

    return {
      ...toRefs(data),
      cleanItem,
      handleInputBlur,
      handleInputFocus,
      iconColor,
      itemsFiltered,
      selItem,
    };
  },
});
