
import { defineComponent } from 'vue';
import { AdjustmentsVerticalIcon } from '@heroicons/vue/24/outline';
import { Bars3Icon, XCircleIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Calendar from '@/components/calendar/BaseCalendar.vue';
import CardComponent from '@/components/card/CardComponent.vue';
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import ModalBasic from '@/components/modals/BasicModal.vue';
import ModalHash from '@/components/modals/HashModal.vue';
import ShipperLoadDetailContent from '@/modules/planning/loadDetail/ShipperLoadDetailContent.vue';
import SkeletonCardComponent from '@/components/skeletons/CardSkeleton.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
//Mixins
import CountryMixins from '@/services/country/_mixins';
import PlanningFilters from '@/components/filters/PlanningFilters.vue';
import LoadFilterByIdPo from '@/components/filters/LoadFilterByIdPo.vue';
import PlanLoadsOrderFilter from '@/components/filters/PlanLoadsOrderFilter.vue';

export default defineComponent({
  name: 'PlanificationOld',
  components: {
    PlanLoadsOrderFilter,
    LoadFilterByIdPo,
    PlanningFilters,
    Bars3Icon,
    Calendar,
    CardComponent,
    DatePickerComponent,
    ModalBasic,
    ModalHash,
    ShipperLoadDetailContent,
    SkeletonCardComponent,
    Spin,
    XCircleIcon,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    id: String,
    code: String,
    hash: {
      type: String,
      default: null,
    },
    emailConfig: String,
  },
  data() {
    return {
      showDropdown: false,
      modalVisible: false,
      componentKeyUpdate: false as any,
      allowed: true,
      isLoading: true,
      isLoadingCalendar: true,
      isLoadingTotals: true,
      isFilteringCalendar: true,
      isFilteringByStatus: 0,
      isMoreLoads: false,
      currentDate: new Date(),
      isOpenModalBasic: false,
      textsModalBasic: {
        header: this.$t('load-included-planning'),
        body: this.$t('text-for-fill-consignee'),
      },
      textsModalBasicQuote: {
        header: this.$t('load-added-planning'),
        body: this.$t('load-quotes-coming-soon'),
      },
      buttonsModalQuote: [
        {
          text: this.$t('message-see-posted-load'),
          type: 'success',
          callback: () => this.seeLoad(),
        },
        {
          text: this.$t('continue'),
          type: 'normal',
          callback: () => this.$store.dispatch('LoadsStore/showModalQuoteSended', false),
        },
      ],
      buttonsModalBasic: [
        {
          text: this.$t('complete-consignee'),
          type: 'success',
          callback: () => this.completeRC(),
        },
        {
          text: this.$t('complete-later'),
          type: 'normal',
          callback: () => this.toggleModalBasic(),
        },
      ],
      filtersProp: {
        page: 1,
      },
      currentPage: undefined as any,
      idFilter: '',
      pickup: '',
      delivery: '',
      dateAfter: undefined as any,
      dateBefore: undefined as any,
      trailerTypeId: '',
      commodity: '',
      customer: '',
      querySearch: '',
      shipmentType: '',
      team: '',
      dateFormat: 'dd-MM-yyyy',
      loadsQuantity: [],
      isFilter: false,
      condition: false,
      sortBy: 'id-DESC',
      sortByExecutionDate: 'ASC',
      sortById: 'ASC',
      sortByQuery: '',
      showSpinner: false,
      page: 1,
      scrollY: 0,
      fixPlanning: true,
      searchTimeout: null as any,
      filterDate: null,
      isHashMatchLocal: true,
      isHashMatchUser: true,
      loadDetailType: '',
    };
  },
  created() {
    // Recover data from localStorage of stored filters
    this.idFilter = this.localStorageQuerySearch?.identifier
      ? this.localStorageQuerySearch?.identifier
      : '';
    this.commodity = this.localStorageQuerySearch?.commodity
      ? this.localStorageQuerySearch?.commodity
      : '';
    if (this.localStorageQuerySearch !== null) {
      if (
        this.localStorageQuerySearch['pickupInitial.place'] !== undefined &&
        this.localStorageQuerySearch['pickupInitial.place'] !== null
      ) {
        this.pickup = this.localStorageQuerySearch['pickupInitial.place'];
      }
      if (
        this.localStorageQuerySearch['deliveryFinal.place'] !== undefined &&
        this.localStorageQuerySearch['deliveryFinal.place'] !== null
      ) {
        this.delivery = this.localStorageQuerySearch['deliveryFinal.place'];
      }
      if (
        this.localStorageQuerySearch['trailerType.id'] !== undefined &&
        this.localStorageQuerySearch['trailerType.id'] !== null
      ) {
        this.trailerTypeId = this.localStorageQuerySearch['trailerType.id'];
      }
      if (
        this.localStorageQuerySearch['shipment.type'] !== undefined &&
        this.localStorageQuerySearch['shipment.type'] !== null
      ) {
        this.shipmentType = this.localStorageQuerySearch['shipment.type'];
      }
      if (
        this.localStorageQuerySearch['pickupInitial.date[after]'] !== undefined &&
        this.localStorageQuerySearch['pickupInitial.date[after]'] !== null
      ) {
        this.dateAfter = [
          this.getDayAfter(this.localStorageQuerySearch['pickupInitial.date[after]']),
          this.getDayAfter(this.localStorageQuerySearch['pickupInitial.date[before]']),
        ];
      }
      if (
        this.localStorageQuerySearch['deliveryFinal.date[before]'] !== undefined &&
        this.localStorageQuerySearch['deliveryFinal.date[before]'] !== null
      ) {
        this.dateBefore = [
          this.getDayAfter(this.localStorageQuerySearch['deliveryFinal.date[after]']),
          this.getDayAfter(this.localStorageQuerySearch['deliveryFinal.date[before]']),
        ];
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    });
    this.apiGetCustomers();
    this.apiGetUsersTeam();
    this.fetchAllInfo();
    this.clearFilters();
    if (this.currentRouteName === 'planificationEmail') {
      this.$store.commit('GlobalStore/setEmailConfigModal', true);
    }
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    isPROD(): any {
      return process.env.VUE_APP_URL.includes('api.planimatik.com');
    },
    activeStatus(): string {
      return this.$store.getters['GlobalStore/getActiveStatus'];
    },
    checkCarrierAssigned(): any {
      return this.$store.getters['LoadsStore/showModalCarrierAssigned'];
    },
    checkQuoteSent(): any {
      return this.$store.getters['LoadsStore/showModalQuoteSended'];
    },
    currentRouteName(): any {
      return this.$route.name;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    dataCustomers(): any {
      return this.$store.getters['CustomerStore/getCustomersData'];
    },
    dataTeam(): any {
      return this.$store.getters['UserStore/getTeamData'];
    },
    deliveryToday(): any {
      if (this.dateBefore) {
        return (
          this.dateBefore[0] === this.formatDateStringReverse(this.getDayAfter(new Date())) &&
          this.dateBefore[1] === this.formatDateStringReverse(this.getDayAfter(new Date()))
        );
      }
      return false;
    },
    deliveryThisWeek(): any {
      if (this.dateBefore) {
        const today = new Date();
        // Calculate the start date of the week (Monday)
        const monday = new Date(today);
        monday.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));

        // Calculate the end date of the week (Sunday)
        const sunday = new Date(today);
        sunday.setDate(monday.getDate() + 6);
        return (
          this.dateBefore[0] === this.formatDateStringReverse(this.getDayAfter(monday)) &&
          this.dateBefore[1] === this.formatDateStringReverse(this.getDayAfter(sunday))
        );
      }
      return false;
    },
    filters(): any {
      return this.$store.state.GlobalStore.planificationFilters;
    },
    hasMoreItems(): any {
      return this.page * this.maxResults <= this.loadsInfo.length;
    },
    isDevPreEnv(): any {
      return process.env.VUE_APP_URL.includes('dev') || process.env.VUE_APP_URL.includes('pre');
    },
    isFiltering(): any {
      const {
        idFilter,
        pickup,
        delivery,
        dateAfter,
        dateBefore,
        trailerTypeId,
        commodity,
        shipmentType,
        customer,
        team,
      } = this;
      return (
        !!idFilter ||
        !!pickup ||
        !!delivery ||
        (!!dateAfter && this.section === 'list') ||
        (!!dateBefore && this.section === 'list') ||
        !!trailerTypeId ||
        !!commodity ||
        !!shipmentType ||
        !!customer ||
        !!team
      );
    },
    isPickUpToday(): any {
      const today = new Date();
      return today === this.dateAfter[0];
    },
    isShowModal(): any {
      if (!this.isHashMatchLocal && !this.isHashMatchUser) {
        this.showHashModal();
      }
      return null;
    },
    loadId(): any {
      return this.$store.getters['LoadsStore/loadId'];
    },
    loadsCalendar(): any {
      return this.$store.getters['LoadsStore/getLoadsByDates'];
    },
    loadsInfo(): any {
      return this.$store.getters['LoadsStore/getLoads'];
    },
    localStorageQuerySearch(): any {
      return JSON.parse(localStorage.getItem('filterQuery') as any);
    },
    maxResults(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']
        ? parseInt(
            this.$store.getters['GlobalStore/getGlobalConfig'].PAGINATION_DEFAULT_MAX_RESULTS,
          )
        : 30;
    },
    numberOfPages(): any {
      return this.loadsInfo.length / this.maxResults;
    },
    pickUpThisWeek(): any {
      if (this.dateAfter) {
        const today = new Date();
        // Calculate the start date of the week (Monday)
        const monday = new Date(today);
        monday.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));

        // Calculate the end date of the week (Sunday)
        const sunday = new Date(today);
        sunday.setDate(monday.getDate() + 6);
        return (
          this.dateAfter[0] === this.formatDateStringReverse(this.getDayAfter(monday)) &&
          this.dateAfter[1] === this.formatDateStringReverse(this.getDayAfter(sunday))
        );
      }
      return false;
    },
    pickUpToday(): any {
      if (this.dateAfter) {
        return (
          this.dateAfter[0] === this.formatDateStringReverse(this.getDayAfter(new Date())) &&
          this.dateAfter[1] === this.formatDateStringReverse(this.getDayAfter(new Date()))
        );
      }
      return false;
    },
    plannerTotalsDayWeek(): any {
      return this.$store.state.LoadsStore.plannerTotalsDayWeek;
    },
    section(): string {
      return this.$store.getters['GlobalStore/getSection'];
    },
    shipmentTypes(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']
        ? this.$store.getters['GlobalStore/getGlobalConfig'].ShipmentType
        : null;
    },
    showSticky(): boolean {
      return this.scrollY > 0 && this.fixPlanning;
    },
    statusList(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']
        ? this.$store.getters['GlobalStore/getGlobalConfig'].LoadStatus.filter(
            (status) => status !== 'DELETED',
          )
        : null;
    },
    totalsList(): any {
      return this.$store.getters['LoadsStore/getTotals'].totals;
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
  },
  methods: {
    extractMonthAndYear(dateString) {
      const date = new Date(dateString);
      return { month: date.getMonth() + 1, year: date.getFullYear() };
    },

    apiGetCustomers() {
      this.$store.dispatch('CustomerStore/apiGetCustomers', {
        maxResults: 100,
        page: 1,
      });
    },
    apiGetUsersTeam() {
      this.$store.dispatch('UserStore/apiGetUsersTeam', {
        maxResults: 100,
        page: 1,
      });
    },
    clearFilterDates(type) {
      if (type === 'pickup') {
        this.dateAfter = undefined;
      } else {
        this.dateBefore = undefined;
      }
      this.filterDate = null;
      this.submitSearch();
    },

    clearFilters() {
      this.idFilter = '';
      this.pickup = '';
      this.delivery = '';
      this.dateAfter = undefined;
      this.dateBefore = undefined;
      this.trailerTypeId = '';
      this.commodity = '';
      this.shipmentType = '';
      this.customer = '';
      this.team = '';
      localStorage.removeItem('filterQuery');
      this.submitSearch();
    },
    clearInput(data) {
      this[data] = '';
      this.submitSearch();
    },
    completeRC() {
      this.$store.dispatch('LoadsStore/showModalCarrierAssigned', false).then(() => {
        this.modalVisible = true;
      });
      this.$router.push('/planning/service/' + this.loadId);
    },
    async fetchAllInfo() {
      if (this.hash !== localStorage.hash && this.hash) {
        this.isHashMatchLocal = false;
      }
      localStorage.setItem('hash', this.hash);
      if (this.hash && this.code) {
        await this.$store
          .dispatch('UserStore/hashValidation', {
            hashId: this.hash,
            secureCode: this.code,
          })
          .then(() => {
            this.$store.dispatch('PusherStore/initializePusher');
            this.$router.push('/planning/service/' + this.id);
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
            this.$store.commit('UserStore/setShowHash', true);
          });
      }

      if (this.hash && !this.code && this.currentUser?.id) {
        this.$router.push('/planning/service/' + this.id);
      }
      this.setFilter();
      if (this.section === 'list') {
        this.fetchFilterLoads();
        this.getPlannerTotalsDayWeek();
      } else {
        this.fetchLoadsCalendar(true, null);
      }
      if (this.id && this.currentUser?.id) {
        this.loadId = this.id;
        this.modalVisible = true;
      } else {
        this.$store.commit('UserStore/setShowHash', false);
      }
    },
    fetchAllLoads() {
      this.isLoading = true;
      const query = JSON.parse(localStorage.getItem('filterQuery') as any);
      query.page = this.page;

      // const query = {
      //   after: '',
      //   before: '',
      //   text: '',
      //   page: this.page,
      // };

      this.getFilterLoads(query);
      this.getFilterLoadsTotals(query);
    },
    fetchFilterLoadsByFilters() {
      this.page = 1;
      this.fetchFilterLoads();
    },
    fetchFilterLoads() {
      if (!this.allowed) return;
      let query = JSON.parse(localStorage.getItem('filterQuery') as any);
      query.page = this.page;
      query = Object.assign({}, query, this.$store.getters['LoadsStore/getAppliedFiltersQuery']);

      this.allowed = false;
      this.isLoading = true;

      this.getFilterLoads(query);
      this.getFilterLoadsTotals(query);
      this.getPlannerTotalsDayWeek();

      this.fetchLoadsCalendar(true, null);
    },

    fetchLoadsCalendar(loadCalendar, currentDate) {
      this.isLoadingCalendar = loadCalendar;
      let query = JSON.parse(localStorage.getItem('filterQuery') as any);
      query.page = this.page;
      if (
        !this.pickUpToday &&
        !this.pickUpThisWeek &&
        !this.deliveryToday &&
        !this.deliveryThisWeek
      ) {
        query = Object.fromEntries(
          Object.entries(query).filter(
            ([key, value]) =>
              key !== 'pickupInitial.date[after]' &&
              key !== 'deliveryFinal.date[before]' &&
              key !== 'pickupInitial.date[before]' &&
              key !== 'deliveryFinal.date[after]',
          ),
        ) as any;
      }

      query = Object.assign({}, query, this.$store.getters['LoadsStore/getAppliedFiltersQuery']);

      const hasPickupInitialFilter = Object.keys(query).some((key) =>
        key.includes('pickupInitial'),
      );

      const hasDeliveryFinal = Object.keys(query).some((key) => key.includes('deliveryFinal'));

      if (hasPickupInitialFilter && hasDeliveryFinal) {
        this.currentPage = this.extractMonthAndYear(query['pickupInitial.date[after]']);
      }

      if (
        (hasPickupInitialFilter && !hasDeliveryFinal) ||
        (!hasPickupInitialFilter && !hasDeliveryFinal)
      ) {
        this.currentPage = this.extractMonthAndYear(query['pickupInitial.date[after]']);
      }

      if (!hasPickupInitialFilter && hasDeliveryFinal) {
        this.currentPage = this.extractMonthAndYear(query['deliveryFinal.date[after]']);
      }

      if (!hasPickupInitialFilter && !hasDeliveryFinal) {
        this.currentPage = undefined;
      }
      if (!hasPickupInitialFilter && !hasDeliveryFinal) {
        let currentYear, currentMonth;

        if (currentDate) {
          currentYear = currentDate.year;
          currentMonth = currentDate.month;
        } else {
          const currentDate = new Date();
          currentYear = currentDate.getFullYear();
          currentMonth = currentDate.getMonth() + 1;
        }

        const currentMonthStartDate = `${currentYear}-${currentMonth}-01`;
        const currentMonthEndDate = `${currentYear}-${currentMonth}-30`;

        query['pickupInitial.date[after]'] = currentMonthStartDate;
        query['pickupInitial.date[before]'] = currentMonthEndDate;
        query['deliveryFinal.date[after]'] = currentMonthStartDate;
        query['deliveryFinal.date[before]'] = currentMonthEndDate;
      }

      this.$store
        .dispatch('LoadsStore/loadsByDates', query)
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.isLoadingCalendar = false;
          this.allowed = true;
        });
    },
    filterCalendar(date: any) {
      // if (this.isFilteringCalendar) return;
      // this.isFilteringCalendar = true;
      const longDate = new Date(date.year, date.month - 1, 1);
      const searchDate = this.formatDateStringReverse(longDate);
      // Esta query no está pasada por el localStore
      let query = {
        'pickupInitial.date[after]': searchDate,
        'pickupInitial.date[before]': searchDate?.slice(0, -2) + '31',
        sortBy: this.sortByQuery ? this.sortByQuery : 'id[DESC]',
        page: this.page,
        status: this.activeStatus,
      };
      query = Object.assign({}, query, this.$store.getters['LoadsStore/getAppliedFiltersQuery']);
      query = Object.fromEntries(
        Object.entries(query).filter(
          ([key, value]) => value !== undefined && value !== null && value !== '',
        ),
      ) as any;

      this.getFilterLoadsTotals(query);
    },
    filterLoads(status: string | null) {
      this.condition = true;
      this.page = 1;
      this.$store.commit('GlobalStore/setActiveStatus', status);
      this.setFilter();
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.getPlannerTotalsDayWeek();
        if (this.section !== 'calendar') {
          let query = JSON.parse(localStorage.getItem('filterQuery') as any);
          query.page = this.page;
          query = Object.assign(
            {},
            query,
            this.$store.getters['LoadsStore/getAppliedFiltersQuery'],
          );

          this.allowed = false;
          this.isFilteringByStatus++;

          this.getFilterLoads(query);
        } else {
          this.fetchLoadsCalendar(false, null);
        }
      }, 500);
    },
    async getFilterLoads(query: any) {
      // Guarda y coge las cargas del store
      await this.$store
        .dispatch('LoadsStore/loads', query)
        .then(() => {
          this.isFilter = true;
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.isLoading = false;
          this.isMoreLoads = false;
          this.allowed = true;
          if (this.isFilteringByStatus > 0) this.isFilteringByStatus--;
        });
    },
    getCustomClass(value) {
      return (
        'text-gray-950 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ' +
        (value ? 'bg-white' : 'bg-gray-50')
      );
    },
    getFilterLoadsTotals(query: any) {
      this.isLoadingTotals = true;
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => key !== 'sortBy' && key !== 'page'),
      ) as any;
      this.$store
        .dispatch('LoadsStore/loadsTotals', query)
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.isLoadingTotals = false;
          this.isFilteringCalendar = false;
        });
    },
    getPlannerTotalsDayWeek() {
      let query = JSON.parse(localStorage.getItem('filterQuery') as any);
      query = Object.assign({}, query, this.$store.getters['LoadsStore/getAppliedFiltersQuery']);
      this.$store.dispatch('LoadsStore/plannerTotalsDayWeek', query).catch((err) => {
        BaseComponent.methods?.showToastError(err?.response?.data?.detail);
      });
      this.$store.dispatch('LoadsStore/getTotalClaims');
    },
    getTotal(status) {
      if (this.totalsList) {
        const statusTotal = this.totalsList.find((obj) => obj.status === status);
        if (statusTotal) {
          return statusTotal.total;
        }
      }
      return 0;
    },
    goToCreateLoad() {
      this.$router.push('/planning/newLoad');
      this.$store.commit('LoadsStore/loadId', 0);
      this.$store.commit('LoadsStore/currentLoadEditing', null);
    },
    goToCreateLoadLTL() {
      this.$router.push('/planning/newLoad/ShipmentDataLTL');
      this.$store.commit('LoadsStore/loadId', 0);
      this.$store.commit('LoadsStore/currentLoadEditing', null);
    },
    moreLoads() {
      this.page++;
      this.isMoreLoads = true;
      this.fetchFilterLoads();
    },
    nextRoute(route: string) {
      this.$router.push(route);
    },
    seeLoad() {
      this.$store.dispatch('LoadsStore/showModalQuoteSended', false).then(() => {
        this.modalVisible = true;
      });
      this.$router.push('/planning/service/' + this.loadId);
    },
    setLoadTypeForDetail() {
      let prevInfoLoad = JSON.parse(localStorage.getItem('loadDetailPreviousData') || '[]');

      if (prevInfoLoad && prevInfoLoad.shipment) {
        this.loadDetailType = prevInfoLoad.shipment.type;
      }
    },
    setFilter() {
      let query = {
        identifier: this.idFilter,
        'pickupInitial.place': this.pickup,
        'pickupInitial.date[after]': this.dateAfter
          ? this.formatDateStringReverse(new Date(this.dateAfter[0]))
          : undefined,
        'pickupInitial.date[before]': this.dateAfter
          ? this.formatDateStringReverse(new Date(this.dateAfter[1]))
          : undefined,
        'deliveryFinal.place': this.delivery,
        'deliveryFinal.date[after]': this.dateBefore
          ? this.formatDateStringReverse(new Date(this.dateBefore[0]))
          : undefined,
        'deliveryFinal.date[before]': this.dateBefore
          ? this.formatDateStringReverse(new Date(this.dateBefore[1]))
          : undefined,
        'trailerType.id': this.trailerTypeId,
        'shipment.type': this.shipmentType,
        'customer.id': this.customer,
        'shipperOwner.id': this.team,
        commodity: this.commodity,
        sortBy: this.sortByQuery ? this.sortByQuery : 'id[DESC]',
        status: this.activeStatus,
      };
      query = Object.fromEntries(
        Object.entries(query).filter(
          ([key, value]) => value !== undefined && value !== null && value !== '',
        ),
      ) as any;
      localStorage.setItem('filterQuery', JSON.stringify(query));
    },
    setFilterDates(type) {
      this.filterDate = type;
      const today = new Date();
      // Calculate the start date of the week (Monday)
      const monday = new Date(today);
      monday.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));

      // Calculate the end date of the week (Sunday)
      const sunday = new Date(today);
      sunday.setDate(monday.getDate() + 6);
      switch (type) {
        case 'pickUpToday':
          if (!this.dateAfter) {
            this.dateAfter = [];
          }
          this.dateAfter[0] = this.formatDateStringReverse(this.getDayAfter(new Date()));
          this.dateAfter[1] = this.formatDateStringReverse(this.getDayAfter(new Date()));
          break;
        case 'pickUpThisWeek':
          if (!this.dateAfter) {
            this.dateAfter = [];
          }
          this.dateAfter[0] = this.formatDateStringReverse(this.getDayAfter(monday));
          this.dateAfter[1] = this.formatDateStringReverse(this.getDayAfter(sunday));
          break;
        case 'deliveryToday':
          if (!this.dateBefore) {
            this.dateBefore = [];
          }
          this.dateBefore[0] = this.formatDateStringReverse(this.getDayAfter(new Date()));
          this.dateBefore[1] = this.formatDateStringReverse(this.getDayAfter(new Date()));
          break;
        case 'deliveryThisWeek':
          if (!this.dateBefore) {
            this.dateBefore = [];
          }
          this.dateBefore[0] = this.formatDateStringReverse(this.getDayAfter(monday));
          this.dateBefore[1] = this.formatDateStringReverse(this.getDayAfter(sunday));
          break;
      }
      this.setFilter();
      this.fetchFilterLoads();
      this.getPlannerTotalsDayWeek();
      this.fetchLoadsCalendar(true, null);
    },
    setSection(value: string) {
      this.$store.commit('GlobalStore/setSection', value);
      // Al pasar de calendar a list se vuelven a cargar los totales
      if (value === 'list') {
        this.fetchFilterLoads();
      } else {
        this.fetchLoadsCalendar(true, null);
      }
    },
    showHashModal() {
      this.$store.commit('UserStore/setShowHash', false);
      localStorage.setItem('hash', this.hash);
      setTimeout(() => {
        this.$store.commit('UserStore/setShowHash', true);
      }, 500);
    },
    showLoadDetail() {
      this.modalVisible = false;
    },
    sortLoadsBy() {
      if (this.sortBy) {
        const filter = this.sortBy.split('-');
        this.sortByQuery = `${filter[0]}[${filter[1]}]`;
      }
      this.setFilter();
      this.fetchFilterLoads();
    },
    submitSearch() {
      this.setFilter();
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        this.page = 1;
        this.getPlannerTotalsDayWeek();
        if (this.section === 'list') {
          this.fetchFilterLoads();
        } else {
          this.fetchLoadsCalendar(false, null);
        }
      }, 500);
    },
    toggleModalBasic() {
      this.$store.dispatch('LoadsStore/showModalCarrierAssigned', false);
    },
  },
  watch: {
    currentRouteName() {
      if (this.currentRouteName === 'planificationDetail' && this.currentUser?.id) {
        this.modalVisible = true;
      } else {
        this.modalVisible = false;
      }
    },
    currentUser(): any {
      if (this.currentUser.hashId !== this.hash && this.hash) {
        this.isHashMatchUser = false;
      }
    },
    id() {
      this.setLoadTypeForDetail();

      if (this.id) {
        this.loadId = this.id;
      }
    },
  },
});
