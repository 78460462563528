
import { defineComponent, reactive, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AddTemplateCategoryModal',
  components: { PlanButton, PlanDefaultModal, PlanInputText },
  emits: ['close', 'newCategory'],
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive({
      loading: false,
      name: ''
    })

    const close = () => { emit('close') }

    const postCategory = () => {
      data.loading = true
      store.dispatch('LoadsStore/postEmailCategory', {
        name: data.name
      }).then((response) => {
        emit('newCategory', response)
      }).finally(() => {
        data.loading = false
      })
    }

    return {
      ...toRefs(data),
      close,
      postCategory
    }
  }
})
