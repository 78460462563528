
import { defineComponent } from 'vue';
import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
// Components
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import ModalNewUser from '@/components/modals/NewUserModal.vue';
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'TeamView',
  components: {
    ModalConfirm,
    ModalNewUser,
    PencilIcon,
    TrashIcon,
  },
  data() {
    return {
      page: 1,
      openNewUser: false,
      showDelete: false,
      userToEdit: null,
      userToDelete: null,
    };
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    maxResults(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']
        ? parseInt(
            this.$store.getters['GlobalStore/getGlobalConfig'].PAGINATION_DEFAULT_MAX_RESULTS,
          )
        : 30;
    },
    companyUsers(): any {
      return this.$store.getters['UserStore/getUsersCompany'];
    },
    companyUsersTotals(): any {
      return this.$store.getters['UserStore/getUsersCompanyTotals']?.amount;
    },
    hasMoreItems(): any {
      return this.page * this.maxResults <= this.companyUsers.length;
    },
  },
  watch: {
    currentUser(): any {
      this.fetchUsers();
    },
  },
  mounted() {
    if (this.currentUser?.company?.id) {
      this.fetchUsers();
    }
  },
  methods: {
    openOkModal(id) {
      this.showDelete = true;
      this.userToDelete = id;
    },
    toggleNewUser(bool) {
      this.openNewUser = bool;
      if (!bool) {
        this.userToEdit = null;
        this.fetchUsers();
      }
    },
    openEditUser(user) {
      this.userToEdit = user;
      this.toggleNewUser(true);
    },
    fetchUsers() {
      this.$store.dispatch('UserStore/getUsersCompany', {
        companyId: this.currentUser?.company?.id,
        params: {
          page: this.page,
          maxResults: this.maxResults,
        },
      });
      this.$store.dispatch('UserStore/getUsersCompanyTotals', this.currentUser?.company?.id);
    },
    moreUsers() {
      this.page = this.page + 1;
      this.$store.dispatch('UserStore/getUsersCompany', {
        companyId: this.currentUser?.company?.id,
        params: {
          page: this.page,
          maxResults: this.maxResults,
        },
      });
    },
    deleteUser(isDelete) {
      if (isDelete) {
        this.$store
          .dispatch('UserStore/deleteUser', this.userToDelete)
          .then(() => {
            this.fetchUsers();
          })
          .catch((err) => {
            if (err?.response?.data?.detail) {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            }
          })
          .finally(() => {
            this.showDelete = false;
          });
      } else {
        this.showDelete = false;
      }
    },
  },
});
