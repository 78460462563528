import globalAxios from 'axios';

const getCountryById = (id: any) => {
  return globalAxios.get('/countries/' + id);
};

const getCountriesList = (params: object) => {
  return globalAxios.get('/countries', { params });
};

export default {
  getCountryById,
  getCountriesList
}
