
import { computed, defineComponent, onMounted, ref } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
import { useRoute } from 'vue-router';
//Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
// Resources
import i18n from '@/resources/locales';
// Services
import store from '@/services/store';

export default defineComponent({
  name: 'SetQuotationTimeModal',
  components: {
    DatePickerComponent,
    ErrorMessage,
    Field,
  },
  mixins: [BaseComponent],
  props: {
    carrierList: {
      type: Array as any,
    },
  },
  setup(props, { emit }) {
    const $t = i18n.global.t;
    const carrierList = ref(props.carrierList);
    const date = ref(null as any);
    const dateFormat = ref('MM-dd-yyyy');
    const days = ref(null as any);
    let minutes = ref('60');
    const projectId = ref(null as any);
    const route = useRoute();
    const sendingAction = ref(false);
    const setQuotationTime = ref(false);

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    onMounted(() => {
      projectId.value = route.params.projectId;

      if (currentProject.value.project.assignConfirmationExpirationMinutes) {
        minutes.value = currentProject.value.project.assignConfirmationExpirationMinutes;
      }

      if (currentProject.value.project.quotationEndDate) {
        days.value = handleQuotationEndDate();
        dateDifference('day');
      }
    });

    const updateQuotationDateEnd = () => {
      sendingAction.value = true;

      if (currentProject.value.project.quoteRequestTotal > 0) {
        let dateConverted = new Date(date.value).toISOString();
        store
          .dispatch('RFPStore/putQuotationDateEnd', {
            id: route.params.projectId,
            body: { quotationEndDate: dateConverted },
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess($t('created-successfully'));
            setQuotationTime.value = true;
            emit('fetch');
          });
      } else {
        store
          .dispatch('RFPStore/postRequestQuote', {
            id: projectId.value,
            companies: carrierList.value,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess($t('created-successfully'));
            let dateConverted = new Date(date.value).toISOString();

            store
              .dispatch('RFPStore/putQuotationDateEnd', {
                id: route.params.projectId,
                body: {
                  quotationEndDate: dateConverted,
                },
              })
              .then(() => {
                store.dispatch('RFPStore/updateQuotationDateEnd', {
                  id: currentProject.value.project.id,
                  body: {
                    assignConfirmationExpirationMinutes: Number(minutes.value),
                  },
                });
                BaseComponent.methods?.showToastSuccess($t('created-successfully'));
                setQuotationTime.value = true;
                emit('fetch');
              });
          });
      }
    };

    const dateDifference = (_type: string) => {
      const currentDate = new Date();
      if (_type === 'day') {
        if (days.value) {
          date.value = new Date(currentDate.setDate(currentDate.getDate() + days.value));
        }
      } else {
        if (date.value) {
          const timeDiff = date.value.getTime() - currentDate.getTime();
          days.value = Math.ceil(timeDiff / (1000 * 3600 * 24));
        }
      }
    };

    const handleQuotationEndDate = () => {
      const quotationDate = new Date(currentProject.value.project.quotationEndDate);
      const currentDate = new Date();

      const timeDifference = quotationDate.getTime() - currentDate.getTime();

      console.log(timeDifference);

      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      return daysDifference + 1;
    };

    const closeModal = () => {
      //
    };

    return {
      closeModal,
      currentProject,
      days,
      date,
      dateDifference,
      dateFormat,
      minutes,
      sendingAction,
      setQuotationTime,
      updateQuotationDateEnd,
    };
  },
});
