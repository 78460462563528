
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanCarrierPreAssignedBox from '@/components/carrier/_rfpQuote/PlanCarrierPreAssignedBox.vue';
import moment from 'moment';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanFormattedNumber from '@/components/labels/PlanFormattedNumber.vue';

interface planCarrierRfpQuotingPerLane {
  price: null | number;
  loadsPerWeek: null | number;
}

export default defineComponent({
  name: 'PlanCarrierRfpQuotingPerLane',
  components: {
    PlanButtonIcon,
    PlanCarrierPreAssignedBox,
    PlanInputNumber,
    PlanIcon,
    PlanFormattedNumber,
  },
  emit: ['updateLanesQuotation', 'handleDeleteQuote', 'handleConfirmLane', 'deleteFromArray'],
  props: {
    onlyDetails: { type: Boolean, required: false, default: false },
    showRemove: { type: Boolean, required: false, default: false },
    positionPreAssigned: { type: Number, required: false, default: -1 },
    item: { type: Object, required: true },
    index: { type: Number, required: true },
    isAccepted: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive<planCarrierRfpQuotingPerLane>({
      price: null,
      loadsPerWeek: null,
    });

    const item = computed(() => props.item);

    if (item.value.quote) {
      data.price = item.value.quote.price;
      data.loadsPerWeek = item.value.quote.maxCapacity;
    }

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const isLaneFilled = computed(() => {
      return (
        data.price !== null && data.price > 0 && data.loadsPerWeek !== null && data.loadsPerWeek > 0
      );
    });

    const getLocationString = (load) => {
      const initialPick = load.stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      const finalDelivery = load.stops.find((stop) => stop.type === 'DELIVERY_FINAL');

      const initialPickLocation = [
        initialPick?.location?.city,
        initialPick?.location?.state?.isoGoogle,
        initialPick?.location?.state?.country?.iso,
      ]
        .filter(Boolean)
        .join(', ');

      const finalDeliveryLocation = [
        finalDelivery?.location?.city,
        finalDelivery?.location?.state?.isoGoogle,
        finalDelivery?.location?.state?.country?.iso,
      ]
        .filter(Boolean)
        .join(', ');

      return `${initialPickLocation} - ${finalDeliveryLocation}`;
    };

    const formatDate = (date) => {
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? moment(date).format('MMM DD, YYYY')
        : moment(date).format('DD MMM, YYYY');
    };

    const formatWeight = (weight) => {
      const weightUnit = store.getters['CountryStore/getMeasurementSystemWeightUnitIntl'];
      const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
      const options = {
        style: 'unit',
        unit: weightUnit || 'pound',
        useGrouping: true,
      };
      return new Intl.NumberFormat(locale, options).format(Number(weight));
    };

    const formatMiles = (miles) => {
      const distanceUnit = store.getters['CountryStore/getMeasurementSystemDistanceUnitIntl'];
      const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
      const options = {
        style: 'unit',
        unit: distanceUnit || 'mile',
        useGrouping: true, // To show thousands separator
      };
      return new Intl.NumberFormat(locale, options).format(Number(miles));
    };

    const isPreAcceptedQuote = (item) => {
      return item?.quote?.quoteRequest?.status === 'ACCEPTING';
    };

    const getDateString = (load) => {
      const initialPick = load.stops.find((stop) => stop.type === 'PICKUP_INITIAL').executionDate;
      const finalDelivery = load.stops.find((stop) => stop.type === 'DELIVERY_FINAL').executionDate;
      const formattedFirstDay = formatDate(initialPick);
      const formattedLastDay = formatDate(finalDelivery);

      return `${formattedFirstDay} - ${formattedLastDay}`;
    };

    const pricePerMile = (quote, totalMiles) => {
      return (quote / totalMiles).toFixed(2);
    };

    const deleteCurrentQuote = (id) => {
      data.price = null;
      data.loadsPerWeek = null;

      emit('deleteFromArray', props.item.lane.id);

      if (id) {
        emit('handleDeleteQuote', id);
      }
    };

    const handleUpdateQuotation = () => {
      emit('updateLanesQuotation', {
        price: data.price,
        maxCapacity: data.loadsPerWeek,
        lane: props.item.lane.id,
      });
    };

    const getLanesAndQuotesForCarrier = computed(() => {
      return store.getters['RFPStore/getLanesAndQuotesForCarrier'].laneAndQuote;
    });

    return {
      ...toRefs(data),
      actualWidth,
      isPreAcceptedQuote,
      formatDate,
      formatMiles,
      formatWeight,
      deleteCurrentQuote,
      getDateString,
      pricePerMile,
      handleUpdateQuotation,
      isLaneFilled,
      getLocationString,
      getLanesAndQuotesForCarrier,
    };
  },
});
