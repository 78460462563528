<template>
  <BaseModal
    :show="isNoCredit"
    modalSize="medium"
    @callbackClose="onClickClose"
  >
    <template v-slot:title>
      <div class="w-full flex justify-center">
        <h3 class="w-96 md:text-3xl font-extrabold leading-9 text-center">
          {{ $t('dont-have-credit-planimatik') }}
        </h3>
      </div>
    </template>
    <template v-slot:main>
      <div class="w-full flex flex-col justify-center">
        <div class="w-full flex flex-col items-center justify-center gap-8 py-5">
          <div class="w-[32rem] text-center text-slate-900 text-2xl font-semibold leading-7">
            {{ $t('apply-for-credit-coverage-loads') }}
          </div>
        </div>
        <div class="w-full justify-center items-center gap-4 inline-flex mt-8">
          <div
            class="h-9 px-2.5 py-2.5 rounded border border-neutral-400 justify-center items-center gap-2.5 flex cursor-pointer hover:opacity-70"
            @click="onClickClose"
          >
            <div class="text-slate-900 text-base font-normal">{{ $t('omit') }}</div>
          </div>
          <div
            class="h-9 px-2.5 py-2.5 bg-sky-500 rounded justify-center items-center gap-2.5 flex cursor-pointer hover:opacity-70"
            @click="toIncreaseCredit"
          >
            <div class="text-white text-base font-normal">{{ $t('apply-now') }}</div>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
// Services
import store from '@/services/store';
import router from '@/router';

export default defineComponent({
  name: 'NoCreditModal',
  components: {
    BaseModal,
  },
  mixins: [BaseComponent],
  emits: ['callbackClose', 'callbackSend'],
  setup(props, { emit }) {
    // Computed
    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const isNoCredit = computed(() => {
      return store.getters['CreditStore/getNoCreditModal'];
    });

    /** Methods */
    const onClickClose = () => {
      store.commit('CreditStore/setNoCreditModal', false);
    };
    const toIncreaseCredit = () => {
      router.push({ name: 'increaseCredit' });
      store.commit('CreditStore/setNoCreditModal', false);
    };
    return {
      currentUser,
      isNoCredit,
      onClickClose,
      toIncreaseCredit,
    };
  },
});
</script>

<style scoped></style>
