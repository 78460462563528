import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-center items-center plan-gap-sm" }
const _hoisted_2 = { class: "plan-text-xl text-center font-bold plan-mb-lg" }
const _hoisted_3 = { class: "w-full flex flex-col justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    size: "xs-auto",
    onlyContent: ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_icon, {
          name: "fill-circle-check",
          "icon-color": "#00DB50",
          "is-svg": "",
          size: "40"
        }),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.thereAreChangesInTheTemplate)
            ? (_openBlock(), _createBlock(_component_plan_button, {
                key: 0,
                onOnClick: _ctx.putEmailTemplate,
                loading: _ctx.loadingPut,
                "icon-color": "#0097FF",
                type: "primary-white",
                label: _ctx.$t('save-changes-in-the-template'),
                class: "w-full"
              }, null, 8, ["onOnClick", "loading", "label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.closeAndShowAdd,
            disabled: _ctx.loadingPut,
            type: "primary-white",
            label: _ctx.$t('save-as-new-template'),
            class: "w-full"
          }, null, 8, ["onOnClick", "disabled", "label"]),
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.close,
            disabled: _ctx.loadingPut,
            type: "primary",
            label: _ctx.$t('continue'),
            class: "w-full"
          }, null, 8, ["onOnClick", "disabled", "label"])
        ])
      ])
    ]),
    _: 1
  }))
}