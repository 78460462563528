import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "plan-button-icon-notification"
}
const _hoisted_2 = {
  key: 3,
  class: "plan-button-icon-quantity"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_PlanIcon = _resolveComponent("PlanIcon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    class: _normalizeClass(_ctx.buttonClasses)
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_plan_icon, {
          key: 0,
          name: "spinner",
          "is-svg": "",
          "icon-color": _ctx.color,
          size: _ctx.size,
          rotate: ""
        }, null, 8, ["icon-color", "size"]))
      : (_openBlock(), _createBlock(_component_PlanIcon, {
          key: 1,
          name: _ctx.icon,
          "is-svg": "",
          "icon-color": _ctx.color,
          size: _ctx.size
        }, null, 8, ["name", "icon-color", "size"])),
    (_ctx.withNotification)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.quantity >= 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.quantity), 1))
      : _createCommentVNode("", true)
  ], 2))
}