import ActivateAccount from '@/modules/auth/activateAccount/ActivateAccount.vue';
import ResetPassword from '@/modules/auth/resetPassword/ResetPassword.vue';
import SignInView from '@/modules/auth/login/SigninView.vue';
import SignUpView from '@/modules/auth/register/SignupView.vue';
import StartFreeTrial from '@/modules/auth/register/StartFreeTrial.vue';

const AuthRouter = [
  {
    path: '/signin',
    name: 'signin',
    component: SignInView,
  },
  {
    path: '/activate-account/:token',
    name: 'activateAccount',
    component: ActivateAccount,
    props: true,
  },
  {
    path: '/password-reset',
    name: 'resetPassword',
    component: ResetPassword,
    props: true,
  },
  {
    path: '/password-reset/:hashId/:code',
    name: 'resetPasswordCode',
    component: ResetPassword,
    props: true,
  },
  // Shipper SignUp
  {
    path: '/shipper',
    name: 'shipper',
    redirect: { name: 'shipperProfile' },
    children: [
      {
        path: 'signup/:token/:email',
        name: 'signup',
        component: SignUpView,
      },
    ],
  },
  {
    path: '/start-free-trial/:hashId/:code',
    name: 'startFreeTrial',
    component: StartFreeTrial,
    props: true,
  },
];

export default AuthRouter;
