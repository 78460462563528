
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DirectAssignStep from './steps/DirectAssignStep.vue';
import FixedBottomBar from '@/modules/planning/newLoad/_components/FixedBottomBar.vue';
import FreightInfoStep from '@/modules/planning/newLoad/steps/FreightInfoStep.vue';
import NewLoadTemplatesModal from './NewLoadTemplatesModal.vue';
import QuoteStep from './steps/QuoteStep.vue';
import ShipmentDataStep from './steps/ShipmentDataStep.vue';
import StepsTab from '@/modules/planning/newLoad/_components/StepsTab.vue';
// Types
import { NewLoadStep } from '@/modules/planning/newLoad/NewLoadTypes';
// Utils
import { convertObjectToArray } from '@/utils/formatData';

export default defineComponent({
  name: 'StepsContainer',
  components: {
    DirectAssignStep,
    FixedBottomBar,
    FreightInfoStep,
    NewLoadTemplatesModal,
    QuoteStep,
    ShipmentDataStep,
    StepsTab,
  },
  mixins: [BaseComponent],
  data() {
    return {
      allowMultipleLoad: false,
      currentView: NewLoadStep.DATA,
      isAdditionalStops: false,
      isLoaded: false,
      isSendingDirectAssign: false,
      isSendingQuotes: false,
      load: null as any,
      selectedContacts: [] as any,
      totalLoadsMultiple: 2,
      updateWeight: true,
      warehousesAllowed: true,
    };
  },
  created() {
    if (this.loadId && this.loadId !== 0) {
      this.$store.dispatch('LoadsStore/loadById', this.loadId).then((res) => {
        this.loadCloned = res;
        this.closeModal('clone', this.loadCloned);
      });
    }

    this.$store.dispatch('LoadsStore/trailers');
    if (this.states.length === 0) {
      this.$store.dispatch('LoadsStore/states');
    }

    // Fetch templates
    const query = {
      page: 1,
    };
    this.$store.dispatch('LoadsStore/getSaveLoads', query);
    this.isLoaded = true;
  },
  computed: {
    hasSpecialRequirement() {
      if (!this.load.specialRequirement) {
        return false;
      }
      return (
        Object.values(this.load.specialRequirement).some((value) => value === true) ||
        (this.load.specialRequirement.specialPermissionsDescription &&
          this.load.specialRequirement.specialPermissionsDescription.trim() !== '')
      );
    },

    NewLoadStep() {
      return NewLoadStep;
    },
    loadCloned(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    loadId(): any {
      return this.$store.getters['LoadsStore/loadId'];
    },
    loadTemplateById(): any {
      return this.$store.getters['LoadsStore/getSaveLoadId'];
    },
    newLoadTemplates(): any {
      return this.$store.getters['LoadsStore/getSaveLoads'];
    },
    showTemplateModal(): any {
      return this.$store.getters['LoadsStore/showTemplateModal'];
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
  },
  methods: {
    convertObjectToArray,
    handleChangeWarehouses() {
      if (!this.warehousesAllowed) {
        this.$store.commit('LoadsStore/setWarehousesItemsModified', []);
      }

      this.warehousesAllowed = !this.warehousesAllowed;
    },
    changeAdditionalStops(value) {
      this.isAdditionalStops = value;
    },
    changeCurrentView(view) {
      this.currentView = view;
      setTimeout(() => {
        if (view === NewLoadStep.DATA) {
          (this.$refs.shipmentData as any).setLoad(this.load);
        }
      }, 300);
    },
    checkWarehouseTemplatesId(newDataStops: any) {
      newDataStops.forEach((stop) => {
        let originalStop = this.load?.stops;
        if (
          stop?.warehouseTemplate?.id === null &&
          originalStop[stop.originalIndex]?.warehouseTemplate?.id
        ) {
          stop.warehouseTemplate.id = originalStop[stop.originalIndex]?.warehouseTemplate?.id;
        }
      });

      return newDataStops;
    },
    closeModal(action: any, data: any) {
      if (action === 'close') {
        this.$store.dispatch('LoadsStore/showTemplateModal', false);
        this.isLoaded = true;
      }
      if (action === 'delete') {
        this.$store
          .dispatch('LoadsStore/deleteSaveLoad', data)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('toast-template-deleted'));
            this.$store.dispatch('LoadsStore/getSaveLoads');
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          })
          .finally(() => {
            this.$store.dispatch('LoadsStore/showTemplateModal', false);
          });
      }
      if (action === 'fill') {
        this.updateWeight = false;
        // saveloadbyid
        this.$store
          .dispatch('LoadsStore/saveLoadId', data)
          .then(() => {
            this.load = this.loadTemplateById?.load;
            this.setLoadToShipmentData(action);
            this.$store.dispatch('LoadsStore/showTemplateModal', false);
            this.isLoaded = true;
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(
              err?.response?.data?.detail ?? this.$t('unexpected-error'),
            );
          });
        setTimeout(() => {
          this.updateWeight = true;
        }, 300);
      }
      if (action === 'clone') {
        data.stops.map((stop) => {
          delete stop.multiStop;
          stop.deliveryStop = null;
          stop.multiStopIdentifierArray = [];
          stop.multiStopIdentifier = '';
        });

        this.updateWeight = false;
        this.load = data;
        this.setLoadToShipmentData('');
        this.isLoaded = true;
        setTimeout(() => {
          this.updateWeight = true;
        }, 300);
      }
    },
    handleForm(type) {
      if (this.currentView === NewLoadStep.DATA) {
        (this.$refs.shipmentData as any).formSubmit(type);
        return;
      }
      if (this.currentView === NewLoadStep.FREIGHT_INFO) {
        (this.$refs.freightInfo as any).formSubmit(type);
        return;
      }
      this.changeCurrentView(type);
    },
    async sendDirectAssign() {
      const directAssign = this.$refs.directAssign as any;
      const userSelected = directAssign?.userSelected?.id;

      if (userSelected) {
        this.isSendingDirectAssign = true;
        await (this.$refs.directAssign as any).validateForm().finally(() => {
          this.isSendingDirectAssign = false;
        });
      } else {
        await BaseComponent.methods?.showToastError(this.$t('required-carrier'));
      }
    },
    async sendQuotes(bookNow, isBookNow, isCheckedShowQuotes) {
      this.isSendingQuotes = true;
      (this.$refs.quotes as any)
        .validateForm(bookNow, isBookNow, isCheckedShowQuotes)
        .finally(() => {
          this.isSendingQuotes = false;
        });
    },
    setLoad(load) {
      this.load = load;
      if (!this.hasSpecialRequirement) {
        delete this.load.specialRequirement;
      }
      this.$emit('setLoadHeader', this.load);
    },
    setLoadToShipmentData(action) {
      delete this.load.id;
      delete this.load?.commodityMaster?.id;
      delete this.load?.specialRequirement?.id;
      this.load.stops.forEach((stop) => {
        delete stop.id;
        if (action === 'fill') {
          delete stop.executionDate;
        }
        stop.commodities.forEach((commodity) => {
          delete commodity.id;
        });
        delete stop.location.id;
      });
      this.warehousesAllowed = this.load.stops.some((stop) => stop.warehouseTemplate);
      (this.$refs.shipmentData as any).setLoad(this.load);
    },
    updateMultipleLoad(total, isAllowed) {
      this.totalLoadsMultiple = total;
      this.allowMultipleLoad = isAllowed;
    },
    updateSelectedContacts(contacts) {
      this.selectedContacts = contacts;
    },
    updateStopsLoad(dataStops) {
      dataStops = this.checkWarehouseTemplatesId(dataStops);
      this.load.stops = dataStops;
    },
  },
  watch: {
    currentView() {
      this.$emit('selectedTab', this.currentView === NewLoadStep.DATA);
    },
  },
});
