import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = {
  value: "",
  disabled: ""
}
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "h-4 justify-center items-center gap-2.5 inline-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.prefixStyles)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["h-4 justify-center items-center gap-2.5 inline-flex", _ctx.classesForLabels])
      }, _toDisplayString(`${_ctx.$t('prefix')} *`), 3),
      _createVNode(_component_Field, {
        as: "select",
        id: 'phone-prefix' + _ctx.index,
        name: 'phone-prefix' + _ctx.index,
        modelValue: _ctx.phone.prefix,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone.prefix) = $event)),
        class: _normalizeClass(["self-stretch h-[42px] min-h-[42px] max-h-[42px] px-3 py-2 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 border justify-center items-center", [_ctx.fieldColor, _ctx.roundedBorders ? 'rounded-l-full' : 'rounded-l', _ctx.borderColor, _ctx.textStyles]]),
        onChange: _ctx.changePhonePrefix,
        rules: "required_valid_number|max:4"
      }, {
        default: _withCtx(() => [
          _createElementVNode("option", _hoisted_2, _toDisplayString(_ctx.$t('select')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prefixesByCountry, (prefix) => {
            return (_openBlock(), _createElementBlock("option", {
              value: prefix.phonePrefix,
              key: prefix
            }, " +" + _toDisplayString(prefix.phonePrefix), 9, _hoisted_3))
          }), 128))
        ]),
        _: 1
      }, 8, ["id", "name", "modelValue", "class", "onChange"]),
      _createVNode(_component_ErrorMessage, {
        name: 'phone-prefix' + _ctx.index,
        class: "text-rose-500"
      }, null, 8, ["name"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.numberStyles)
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["h-4 justify-center items-center gap-2.5 inline-flex", _ctx.classesForLabels])
        }, _toDisplayString(_ctx.label ? _ctx.label : _ctx.$t('phone') + ' *'), 3)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["relative w-full", _ctx.textStyles])
      }, [
        (_ctx.phone.number)
          ? (_openBlock(), _createBlock(_component_XCircleIcon, {
              key: 0,
              onClick: _ctx.clearInput,
              class: "w-4 h-4 text-corp absolute top-[12px] right-[8px]"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Field, {
          modelValue: _ctx.phone.number,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phone.number) = $event)),
          name: 'phone-number' + _ctx.index,
          type: "number",
          id: 'phone-number' + _ctx.index,
          class: _normalizeClass([[_ctx.fieldColor, _ctx.roundedBorders ? 'rounded-r-full' : 'rounded-r', _ctx.borderColor], "w-full custom-border-right self-stretch h-[42px] min-h-[42px] max-h-[42px] px-3 py-2 bg-gray-50 border text-sm justify-center items-center gap-2 inline-flex"]),
          rules: "required|min:9|max:16|numeric",
          onKeypress: _cache[2] || (_cache[2] = ($event: any) => (!/[0-9]/.test($event.key) ? $event.preventDefault() : '')),
          onPaste: _withModifiers(_ctx.pasteAction, ["prevent"]),
          onChange: _ctx.changePhoneNumber
        }, null, 8, ["modelValue", "name", "id", "class", "onPaste", "onChange"])
      ], 2),
      _createVNode(_component_ErrorMessage, {
        name: 'phone-number' + _ctx.index,
        class: "text-rose-500"
      }, null, 8, ["name"])
    ], 2)
  ]))
}