function hourRange(time) {
  if (time !== undefined && time !== null) {
    const hour = parseInt(time.HH, 10);
    const timeRange = [hour, 23];
    return [timeRange];
  }
}
function minutesRange(time, timeEnd) {
  if (time !== undefined && timeEnd !== undefined && time !== null && timeEnd !== null) {
    if (time.HH === timeEnd.HH) {
      let minute = parseInt(time.mm, 10);
      minute = Math.min(minute + 1, 59);
      const timeRange = [minute, 59];
      return [timeRange];
    } else {
      return [[0, 59]];
    }
  }
}
function formatTimeToObject(time: string) {
  const [hours, minutes] = time.split(':');
  return { HH: hours, mm: minutes };

  // if (time !== null) {
  //   const parts = time.split(':');
  //   const formatTime = {
  //     hh:
  //       +parts[0] > 12
  //         ? +parts[0] - 12 > 9
  //           ? (+parts[0] - 12).toString()
  //           : '0' + (+parts[0] - 12)
  //         : parts[0] === '00'
  //         ? '12'
  //         : parts[0],
  //     mm: parts[1],
  //     a: +parts[0] > 12 ? 'pm' : parts[0] === '00' ? 'am' : 'pm',
  //   };
  //   return formatTime;
  // }
}
function formatTime(time: any) {
  // if (time.hh && time.mm) {
  //   let hours = time.hh;
  //   const minutes = time.mm;
  //   if (time.a === 'pm' && hours < 12) hours = +hours + 12;
  //   if (time.a === 'am' && hours === 12) hours = '00';
  //   return hours + ':' + minutes;
  // }
  return time.HH + ':' + time.mm;
}

function dateStringGetHours(date) {
  date = new Date(date);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  let formattedHours = (hours % 12).toString().padStart(2, '0');
  if (formattedHours === '00') {
    formattedHours = '12';
  }

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const amPm = hours >= 12 ? 'PM' : 'AM';

  const time = `${formattedHours}:${formattedMinutes}${amPm}`;
  return time;
}

function dateStringGetLocalHours(date) {
  date = new Date(date);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  let formattedHours = (hours % 12).toString().padStart(2, '0');
  if (formattedHours === '00') {
    formattedHours = '12';
  }

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const amPm = hours >= 12 ? 'PM' : 'AM';

  const time = `${formattedHours}:${formattedMinutes}${amPm}`;
  return time;
}

function dateStringGetLocal24Hours(date) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

function convertTo24HourFormat(time) {
  let { hh } = time;
  const { mm, a } = time;
  hh = parseInt(hh);
  if (a.toLowerCase() === 'pm' && hh !== 12) {
    hh += 12;
  } else if (a.toLowerCase() === 'am' && hh === 12) {
    hh = 0;
  }
  return hh.toString().padStart(2, '0') + mm;
}

function compareTimes(time1, time2) {
  if (
    time1?.hh &&
    time1?.hh !== '' &&
    time1?.mm &&
    time1?.mm !== '' &&
    time2?.hh &&
    time2?.hh !== '' &&
    time2?.mm &&
    time2?.mm !== ''
  ) {
    // Convert times to 24-hour format
    time1 = convertTo24HourFormat(time1);
    time2 = convertTo24HourFormat(time2);
    // Compare times as numbers
    if (Number(time1) <= Number(time2)) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export {
  compareTimes,
  dateStringGetHours,
  dateStringGetLocalHours,
  dateStringGetLocal24Hours,
  formatTime,
  formatTimeToObject,
  hourRange,
  minutesRange,
};
