
import { defineComponent, computed, ref, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import DirectAssignModal from '@/modules/rfp/_components/modals/DirectAssignModal.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

import LoadAlertsInfo from '@/components/loads/LoadAlertsInfo.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import PlanRfpAssignmentModal from '@/components/rfp/PlanRfpAssignmentModal.vue';
// Base
import BaseComponent from '@/base/BaseComponent';
import { dateFormatLocale } from '@/utils/formatDates';
import i18n from '@/resources/locales';

export default defineComponent({
  name: 'PlanRfpTrackingPlannerTable',
  components: {
    PlanInputCalendar,
    PlanButtonIcon,
    PlanAvatar,
    PlanIcon,
    PlanButton,
    LoadAlertsInfo,
    PlanRfpAssignmentModal,
  },
  emits: ['fetch'],
  mixins: [BaseComponent],
  props: {
    week: { type: Number, required: true, default: 1 },
    weekFrom: { type: String, required: true, default: '' },
    weekTo: { type: String, required: true, default: '' },
    stopsHeaders: { type: Array, required: true },
    loads: { type: Array as any, required: false },
    locale: { type: String, required: false, default: 'en' },
    chats: { type: Array as any, required: false },
    getAssignedInfo: { type: Object as any, required: false, default: null },
    loadsAdd: { type: Number, required: true },
    planningPeriod: { type: Object, required: false, default: null },
    isSearchingFilter: { type: Boolean, required: true },
    preSelectedWeek: { type: Number, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();
    const leftLoads = ref(props.loadsAdd);
    const router = useRouter();
    const currentRoutePath = router.currentRoute.value.path;

    const currentLane = computed(() => {
      let currentLane = deepClone(store.getters['RFPStore/getCurrentLane']);
      currentLane.loadTemplate.stops = sortStops(currentLane.loadTemplate.stops);
      return currentLane;
    });

    const deepClone = (obj) => {
      return JSON.parse(JSON.stringify(obj));
    };

    const sortStops = (stops) => {
      const initialPickup = stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      const additionalStops = stops
        .filter((stop) => stop.type === 'PICKUP' || stop.type === 'DELIVERY')
        .sort((a, b) => a.id - b.id);
      const finalDelivery = stops.find((stop) => stop.type === 'DELIVERY_FINAL');
      return [initialPickup, ...additionalStops, finalDelivery];
    };

    const getCurrentLoadsToAdd = computed(() => {
      return store.getters['RFPStore/getCurrentLoadsToAdd'];
    });

    const getIndexLoadToRemove = computed(() => {
      return store.getters['RFPStore/getIndexLoadToRemove'];
    });

    const data = reactive({
      modalTitle: '',
      showAssignmentModal: false,
      loadToAdd: {} as any,
      loadsArray: [] as any,
      toggleDirectAssignModal: false,
      directAssignUser: {},
    });

    const directAssign = (item) => {
      if (!getCurrentLoadsToAddAssigment.value) {
        data.loadToAdd.load.autoAssignment = false;
        data.loadToAdd.load.directAssign = item;
      }

      createLoad(item);
    };

    const generateStops = () => {
      let items = [] as any;
      for (let index = 0; index < currentLane.value.loadTemplate.stops.length; index++) {
        items.push({
          loadTemplateStopId: currentLane?.value?.loadTemplate?.stops[index]?.id,
          executionDate: '',
        });
      }

      return items;
    };

    const createNewLoadTemplate = () => {
      return {
        week: props.week,
        po: '',
        autoAssignment: true,
        stops: generateStops(),
        loading: false,
        directAssign: {},
      };
    };

    // NOT SHOW LEFT LOAD IF IS FILTERING
    if (leftLoads.value > 0 && !props.isSearchingFilter) {
      for (let index = 0; index < leftLoads.value; index++) {
        data.loadsArray.push(createNewLoadTemplate());
      }
      store.commit('RFPStore/setCurrentLoadsToAdd', data.loadsArray);
    }

    /** Methods */

    const convertDate = (originalDate) => {
      return moment(originalDate).format('DD-MM-YYYY');
    };

    const areDatesNotEmpty = (loadToAdd) => {
      const allStopsHaveDates = loadToAdd.stops.every((stop) => stop.executionDate !== '');
      const hasValidAssignment =
        loadToAdd.autoAssignment === true || Object.keys(loadToAdd.directAssign).length > 0;

      return allStopsHaveDates && hasValidAssignment;
    };

    const getIconColor = (loadToAdd) => {
      const result = areDatesNotEmpty(loadToAdd);

      if (result) {
        return 'bg-blue-500';
      } else {
        return 'bg-gray-500';
      }
    };

    const showLoadDetails = (id) => {
      store.commit('GlobalStore/setSectionFromPlanification', true);
      router.push({ name: 'planificationDetail', params: { id: id } });
    };

    const handleOpenAssigmentModal = (load: any, index: number, title: string) => {
      if (!validateDates(load.stops)) {
        BaseComponent.methods?.showToastError(translate.t('invalid-dates'));
        return;
      }
      data.modalTitle = title;
      data.loadToAdd = { load: load, index: index };
      store.commit('RFPStore/setIndexLoadToRemove', [index]);
      data.showAssignmentModal = true;
    };

    const validateDates = (stops: any) => {
      return stops.every((date, index) => {
        if (index === 0) return true;
        return (
          moment(stops[index - 1].executionDate, 'DD-MM-YYYY').toDate() <=
          moment(date.executionDate, 'DD-MM-YYYY').toDate()
        );
      });
    };

    const closeDirectModal = () => {
      data.showAssignmentModal = false;
      store.commit('RFPStore/setCurrentLoadsToAddAssigment', false);
    };

    const createLoad = async (userSelected) => {
      if (getCurrentLoadsToAddAssigment.value) {
        let loads = [] as any;

        for (const loadFromArray of getCurrentLoadsToAdd.value) {
          if (
            loadFromArray.stops[0].executionDate !== '' &&
            loadFromArray.stops[1].executionDate !== ''
          ) {
            if (userSelected && userSelected !== undefined) {
              loadFromArray.autoAssignment = false;
              loadFromArray.directAssign = userSelected;
            } else {
              loadFromArray.autoAssignment = true;
            }
            loads.push(loadFromArray);
          }
        }

        await store
          .dispatch('RFPStore/postRFPLoadsPlanningBatch', {
            laneId: currentLane.value.id,
            loads,
          })
          .then(() => {
            store.commit('RFPStore/setCurrentLoadsToAddAssigment', false);
            store.commit('RFPStore/setCurrentLoadsToAdd', []);

            emit('fetch');
          });
      } else {
        const { load, index } = data.loadToAdd;

        data.showAssignmentModal = false;
        load.loading = true;
        try {
          await store.dispatch('RFPStore/postRFPLoadsPlanningBatch', {
            laneId: currentLane.value.id,
            loads: [load],
          });
        } catch (error) {
        } finally {
          load.loading = false;
          emit('fetch');
        }

        if (getCurrentLoadsToAdd.value.length === 1) {
          store.commit('RFPStore/setCurrentLoadsToAddAssigment', false);
          store.commit('RFPStore/setCurrentLoadsToAdd', []);
        } else {
          store.commit('RFPStore/setCurrentLoadsToAdd', []);
        }
      }
    };

    const handleChangeDate = (load: any, index: number, newDate: any) => {
      load.stops[index].executionDate = newDate;
    };

    const gotoDetail = (id) => {
      store.commit('UserStore/setPreviousRoute', currentRoutePath);
      router.push({ name: 'planificationDetail', params: { id: id } });
    };

    const openGeneralChat = (load) => {
      store.commit('LoadsStore/loadId', null);
      store.commit('GlobalStore/setOpenChat', false);

      setTimeout(() => {
        store.commit('ChatsStore/setUserForChat', {
          owner: { id: load.shipment.acceptingCompany[0].company.id },
        });
        store.commit('ChatsStore/setLaneForChat', currentLane.value);
        store.commit(
          'ChatsStore/setChatId',
          load?.shipment?.acceptingCompany[0]?.company?.laneChat?.chat?.id ?? null,
        );
        store.commit('GlobalStore/setOpenChat', true);
      }, 500);
    };

    const openChat = (chat: any, loadId) => {
      if (chat) {
        chat.readed = true;
      }
      store.commit('LoadsStore/loadId', loadId);
      store.commit('ChatsStore/setUserForChat', chat ?? null);
      store.commit('ChatsStore/setChatId', chat?.chat ?? null);
      store.commit('GlobalStore/setOpenChat', true);
    };

    /** Computeds */

    const getCurrentLoadsToAddAssigment = computed(() => {
      return store.getters['RFPStore/getCurrentLoadsToAddAssigment'];
    });

    const dates = computed(() => {
      const format =
        store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'MMM DD, YYYY' : 'DD MMM, YYYY';
      return {
        from: moment(props.weekFrom).locale(props.locale).format('LL'),
        to: moment(props.weekTo).locale(props.locale).format('LL'),
      };
    });

    const dateFormatLocaleTranslated = computed(() => {
      const dateFormat = dateFormatLocale().toLowerCase();
      let translatedFormat = dateFormat;

      if (dateFormat === 'mm-dd-yyyy') {
        translatedFormat = i18n.global.locale.value === 'es' ? 'mm-dd-aaaa' : translatedFormat;
      }
      if (dateFormat === 'dd-mm-yyyy') {
        translatedFormat = i18n.global.locale.value === 'es' ? 'dd-mm-aaaa' : translatedFormat;
      }

      return translatedFormat;
    });

    const chatsToShow = computed(() => {
      let unread = false;
      let result: Array<any> = [];

      if (props.chats.length > 0) {
        props.chats.forEach(function (chat: any) {
          if (props.loads.status === 'QUOTING') {
            if (!chat.readed) {
              result.push(chat);
              unread = true;
            }
          } else {
            if (!chat.readed) unread = true;
            result.push(chat);
          }
        });
      }

      return {
        chats: result,
        unread: unread,
      };
    });

    return {
      ...toRefs(data),
      createNewLoadTemplate,
      convertDate,
      dateFormatLocaleTranslated,
      closeDirectModal,
      getCurrentLoadsToAddAssigment,
      openGeneralChat,
      getCurrentLoadsToAdd,
      directAssign,
      currentLane,
      areDatesNotEmpty,
      getIconColor,
      handleOpenAssigmentModal,
      createLoad,
      dates,
      translate,
      leftLoads,
      openChat,
      handleChangeDate,
      showLoadDetails,
      gotoDetail,
    };
  },
});
