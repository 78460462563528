import globalAxios from 'axios';

export default {


    async getSession(params: object) {
      const response = await globalAxios.get(`/stripe/session`, { params });
      return Promise.resolve(response.data);
    },


    async getPaymentMethod() {
      const response = await globalAxios.get(`/stripe/paymentMethod`);
      return Promise.resolve(response.data);
    },

    async getPaymentInfo() {
      const response = await globalAxios.get(`/stripe/customerInvoices`);
      return Promise.resolve(response.data);
    },

    async getPortalSession() {
        const response = await globalAxios.get(`/stripe/portalSession`);
        return Promise.resolve(response.data);
    },

    async getSubcription() {
      const response = await globalAxios.get(`/stripe/subscription`);
      return Promise.resolve(response.data);
    },

    async getSubcriptionPrice() {
      const response = await globalAxios.get(`/stripe/subscriptionPrice`, {
        params: {
          page: 1,
        },
      });
      return Promise.resolve(response.data);
    },

    async getCustomer() {
      const response = await globalAxios.get(`/stripe/customer`);
      return Promise.resolve(response.data);
    },

    async getInvoice(data: any) {
      const response = await globalAxios.get(
        `/stripe/invoice/${data.id}/getDocument`,
        {
            responseType: 'blob',
        },
    );
    return Promise.resolve(response.data);
    },


    async postAttachPayment(params: object) {
      const response = await globalAxios.post(`/stripe/attachPaymentMethodToCustomer`, params);
      return Promise.resolve(response.data);
    },

    async postSubscription(params: object) {
      const response = await globalAxios.post(`/stripe/subscription`, params);
      return Promise.resolve(response.data);
    },
    async deleteCard(cardId: number) {
      const response = await globalAxios.delete(`/stripe/removePaymentMethod/${cardId}`, 
      );
      return Promise.resolve(response.data);
    },

    async deleteSubscription(type: string) {
      const response = await globalAxios.delete(`/stripe/subscription`, {
        params: {
          type: type,
        },
      } 
      );
      return Promise.resolve(response.data);
    },


    async postUpgradeSubscription(params: object) {
      const response = await globalAxios.post(`/stripe/upgradeSubscription`,  params );
      return Promise.resolve(response.data);
    },

    async postDefaultPayment(id: number) {
      const response = await globalAxios.put(`/stripe/setDefaultPaymentMethod/${id}`,{});
      return Promise.resolve(response.data);
    },

}
