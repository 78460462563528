
import { defineComponent } from 'vue';
import { ArrowLongRightIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import LoadsLanes from '@/modules/rfq/newLoad/LoadsLanes.vue';
import LoadsAssignation from '@/modules/rfq/newLoad/LoadsAssignation.vue';
// Router
import router from '@/router';

export default defineComponent({
  components: {
    ArrowLongRightIcon,
    LoadsAssignation,
    LoadsLanes,
  },
  mixins: [BaseComponent],
  data() {
    return {
      currentView: '',
      isStepOneCompleted: false,
      loadsCreation: null,
    };
  },
  computed: {
    currentRouteName(): any {
      return this.$route.name;
    },
    currentLane(): any {
      return this.$store.getters['LanesStore/getLaneData'];
    },
    initialPickUpStop(): any {
      let initialStop = null;
      if (this.currentLane?.loadTemplate?.stops) {
        this.currentLane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'PICKUP_INITIAL') {
            initialStop = stop;
          }
        });
      }
      return initialStop;
    },
    finalDeliveryStop(): any {
      let finalStop = null;
      if (this.currentLane?.loadTemplate?.stops) {
        this.currentLane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'DELIVERY_FINAL') {
            finalStop = stop;
          }
        });
      }
      return finalStop;
    },
  },
  methods: {
    goBack() {
      this.currentRouteName === 'LaneDetail'
        ? this.closeCreateLaneDetail()
        : router.push('/planning/lanesRFQ');
    },
    closeCreateLaneDetail() {
      this.$emit('onClickBack');
    },
    showTemplates() {
      this.$store.dispatch('LoadsStore/showTemplateModal', true);
    },
    nextRoute() {
      this.$router.push('quote');
    },
    goToStep(step) {
      this.currentView = step;
    },
    stepOneCompleted(loads) {
      this.currentView = 'Assign';
      this.isStepOneCompleted = true;
      this.loadsCreation = loads;
    },
  },
});
