import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-xl md:text-2xl font-bold py-2" }
const _hoisted_2 = { class: "w-full md:w-1/3 px-3 mb-6 md:mb-3" }
const _hoisted_3 = { class: "relative flex" }
const _hoisted_4 = { class: "w-full md:w-1/3 px-3 mb-6 md:mb-3" }
const _hoisted_5 = { class: "relative flex" }
const _hoisted_6 = { class: "w-full md:w-1/6 px-3 mb-6 md:mb-3" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "pt-10"
}
const _hoisted_9 = { class: "text-xl md:text-2xl font-bold py-2" }
const _hoisted_10 = { class: "w-full md:w-1/3 px-3 mb-6 md:mb-3" }
const _hoisted_11 = { class: "w-full md:w-1/3 px-3 mb-6 md:mb-3" }
const _hoisted_12 = { class: "w-full md:w-1/4 px-3 mb-6 md:mb-3" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 1,
  class: "fixed bottom-0 left-0 bg-blue-100 w-full xl:w-[calc(100%_-_300px)] z-10 xl:ml-90"
}
const _hoisted_15 = { class: "w-full xl:container flex justify-center md:justify-end items-center py-6 px-10 space-x-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanCountrySelector = _resolveComponent("PlanCountrySelector")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!
  const _component_google_autocomplete = _resolveComponent("google-autocomplete")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", null, [
      _createVNode(_component_Form, {
        ref: "form",
        class: "w-full"
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('lanes')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentLanes, (lane, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "flex flex-wrap -mx-3"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _withDirectives(_createElementVNode("label", {
                  for: "trailer-type",
                  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                }, _toDisplayString(_ctx.$t('origin')), 513), [
                  [_vShow, index === 0]
                ]),
                _createVNode(_component_Field, {
                  name: 'origin' + index,
                  rules: "required",
                  modelValue: lane.locationOrigin.city,
                  "onUpdate:modelValue": ($event: any) => ((lane.locationOrigin.city) = $event)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      (_openBlock(), _createBlock(_component_PlanCountrySelector, {
                        key: 'country0' + index,
                        country: _ctx.getCountryIsoByLane(lane, 'ORIGIN'),
                        countrySelectorClasses: 'bg-gray-50 mt-2 rounded rounded-r-none custom-border-left inline-flex border-gray-300 bg-gray-text-gray-900 h-[46px] items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150',
                        dataCountries: _ctx.countriesList,
                        name: 'country0' + index,
                        onCallbackClick: (country) => _ctx.handleClickCountry(country, 'ORIGIN', index)
                      }, null, 8, ["country", "dataCountries", "name", "onCallbackClick"])),
                      (lane.locationOrigin.city !== '')
                        ? (_openBlock(), _createBlock(_component_XCircleIcon, {
                            key: 0,
                            class: "w-4 h-4 text-corp absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2",
                            onClick: ($event: any) => (_ctx.clearInput(lane, 'origin'))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_google_autocomplete, {
                        id: 'origin-input' + index,
                        name: 'origin-input' + index,
                        ref_for: true,
                        ref: 'location0-input' + index,
                        value: lane.locationOrigin.city,
                        error: Object.keys(errors).includes('location0-input' + index),
                        class: "rounded rounded-l-none h-[46px] bg-gray-50 mt-2",
                        placeholder: _ctx.$t('placeholder-enter-your-origin-zip'),
                        types: '(regions)',
                        onPlacechanged: ($event: any) => (lane.locationOrigin = _ctx.getCityData($event)),
                        country: lane.originCountry?.iso,
                        onChange: _ctx.updateLanes,
                        onClearInput: _ctx.clearInput
                      }, null, 8, ["id", "name", "value", "error", "placeholder", "types", "onPlacechanged", "country", "onChange", "onClearInput"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["name", "modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: 'origin-input' + index,
                  class: "text-rose-500"
                }, null, 8, ["name"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _withDirectives(_createElementVNode("label", {
                  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                  for: "grid-last-name"
                }, _toDisplayString(_ctx.$t('destination')), 513), [
                  [_vShow, index === 0]
                ]),
                _createVNode(_component_Field, {
                  name: 'destiny' + index,
                  rules: "required",
                  modelValue: lane.locationDestiny.city,
                  "onUpdate:modelValue": ($event: any) => ((lane.locationDestiny.city) = $event)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(), _createBlock(_component_PlanCountrySelector, {
                        key: 'country1' + index,
                        country: _ctx.getCountryIsoByLane(lane, 'DESTINATION'),
                        countrySelectorClasses: 'bg-gray-50 mt-2 rounded rounded-r-none custom-border-left inline-flex border-gray-300 bg-gray-text-gray-900 h-[46px] items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150',
                        dataCountries: _ctx.countriesList,
                        name: 'country1' + index,
                        onCallbackClick: (country) => _ctx.handleClickCountry(country, 'DESTINATION', index)
                      }, null, 8, ["country", "dataCountries", "name", "onCallbackClick"])),
                      (lane.locationDestiny.city !== '')
                        ? (_openBlock(), _createBlock(_component_XCircleIcon, {
                            key: 0,
                            class: "w-4 h-4 text-corp absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2",
                            onClick: ($event: any) => (_ctx.clearInput(lane, 'destination'))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_google_autocomplete, {
                        id: 'destination-input' + index,
                        name: 'destination-input' + index,
                        ref_for: true,
                        ref: 'location1-input' + index,
                        value: lane.locationDestiny.city,
                        error: Object.keys(errors).includes('location1-input' + index),
                        class: "rounded rounded-l-none h-[46px] bg-gray-50 mt-2",
                        placeholder: _ctx.$t('placeholder-enter-your-destination-zip'),
                        types: '(regions)',
                        onPlacechanged: ($event: any) => (lane.locationDestiny = _ctx.getCityData($event)),
                        country: lane.destinationCountry?.iso,
                        onChange: _ctx.updateLanes,
                        onClearInput: _ctx.clearInput
                      }, null, 8, ["id", "name", "value", "error", "placeholder", "types", "onPlacechanged", "country", "onChange", "onClearInput"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["name", "modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: 'destination-input' + index,
                  class: "text-rose-500"
                }, null, 8, ["name"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createElementVNode("label", {
                  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                  for: "grid-last-name"
                }, _toDisplayString(_ctx.$t('radio')), 513), [
                  [_vShow, index === 0]
                ]),
                _createVNode(_component_Field, {
                  type: "number",
                  class: _normalizeClass(["datepicker mt-4 w-full border border-gray-300 bg-gray-text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-gray-50 flex p-2.5 pac-target-input", {
      'border-red-500': Object.keys(errors).includes('radiusDistance-input' + index),
    }]),
                  modelValue: lane.radiusDistance,
                  "onUpdate:modelValue": ($event: any) => ((lane.radiusDistance) = $event),
                  rules: "required|min_value:1|max_value:2147483647|integer",
                  name: 'radiusDistance-input' + index,
                  onChange: _ctx.updateLanes,
                  placeholder: '0 ' + _ctx.currentCountryDistanceUnit
                }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "onChange", "class", "placeholder"]),
                _createVNode(_component_ErrorMessage, {
                  name: 'radiusDistance-input' + index,
                  class: "text-rose-500"
                }, null, 8, ["name"])
              ]),
              _createElementVNode("p", {
                class: _normalizeClass(["p-1 h-fit bg-red-500 rounded cursor-pointer mx-auto", index === 0 ? 'mt-6' : 'mt-2']),
                onClick: ($event: any) => (_ctx.currentLanes.splice(index, 1))
              }, [
                _createVNode(_component_TrashIcon, { class: "h-6 w-6 text-white" })
              ], 10, _hoisted_7)
            ]))
          }), 128)),
          _createElementVNode("p", {
            class: "mx-3 border-b w-max border-corp cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (
      _ctx.currentLanes.push({
        originCountry: null,
        locationOrigin: {
          lat: 0,
          long: 0,
          city: '',
          state: { id: null },
        },
        destinationCountry: null,
        locationDestiny: {
          lat: 0,
          long: 0,
          city: '',
          state: { id: null },
        },
        radiusDistance: null,
      })
      ))
          }, _toDisplayString(`+ ${_ctx.$t('add-other')}`), 1)
        ]),
        _: 1
      }, 512)
    ]),
    (_ctx.currentCompany.lanes.length)
      ? (_openBlock(), _createElementBlock("main", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('current-lanes')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLanes, (lane, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "flex flex-wrap -mx-3"
            }, [
              _createElementVNode("div", _hoisted_10, [
                _withDirectives(_createElementVNode("label", {
                  for: "trailer-type",
                  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                }, _toDisplayString(_ctx.$t('origin')), 513), [
                  [_vShow, index === 0]
                ]),
                _createVNode(_component_Field, {
                  name: 'origin' + index,
                  rules: "required",
                  modelValue: lane.locationOrigin.city,
                  "onUpdate:modelValue": ($event: any) => ((lane.locationOrigin.city) = $event)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_google_autocomplete, {
                      onPlacechanged: ($event: any) => (lane.locationOrigin = _ctx.getCityData($event)),
                      id: 'origin' + index,
                      name: 'origin' + index,
                      value: lane.locationOrigin.city,
                      country: _ctx.defaultCountrySelected?.iso?.toLowerCase(),
                      types: '(regions)',
                      placeholder: _ctx.$t('placeholder-enter-your-origin-zip'),
                      disabled: ""
                    }, null, 8, ["onPlacechanged", "id", "name", "value", "country", "types", "placeholder"])
                  ]),
                  _: 2
                }, 1032, ["name", "modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: 'origin' + index,
                  class: "text-rose-500"
                }, null, 8, ["name"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _withDirectives(_createElementVNode("label", {
                  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                  for: "grid-last-name"
                }, _toDisplayString(_ctx.$t('destination')), 513), [
                  [_vShow, index === 0]
                ]),
                _createVNode(_component_Field, {
                  name: 'destination' + index,
                  rules: "required",
                  modelValue: lane.locationDestiny.city,
                  "onUpdate:modelValue": ($event: any) => ((lane.locationDestiny.city) = $event)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_google_autocomplete, {
                      onPlacechanged: ($event: any) => (lane.locationDestiny = _ctx.getCityData($event)),
                      id: 'destination' + index,
                      name: 'destination' + index,
                      value: lane.locationDestiny.city,
                      country: _ctx.defaultCountrySelected?.iso?.toLowerCase(),
                      types: '(regions)',
                      placeholder: _ctx.$t('placeholder-enter-your-destination-zip'),
                      disabled: ""
                    }, null, 8, ["onPlacechanged", "id", "name", "value", "country", "types", "placeholder"])
                  ]),
                  _: 2
                }, 1032, ["name", "modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: 'destination' + index,
                  class: "text-rose-500"
                }, null, 8, ["name"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createElementVNode("label", {
                  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                  for: "grid-last-name"
                }, _toDisplayString(_ctx.$t('radio')), 513), [
                  [_vShow, index === 0]
                ]),
                _createVNode(_component_Field, {
                  class: "datepicker w-full border border-gray-300 bg-gray-text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex p-2.5 pac-target-input",
                  style: {"background-color":"rgb(249, 250, 251)"},
                  modelValue: lane.radiusDistance,
                  "onUpdate:modelValue": ($event: any) => ((lane.radiusDistance) = $event),
                  name: 'radiusDistance' + index,
                  type: "text",
                  disabled: true
                }, null, 8, ["modelValue", "onUpdate:modelValue", "name"]),
                _createVNode(_component_ErrorMessage, {
                  name: 'radiusDistance' + index,
                  class: "text-rose-500"
                }, null, 8, ["name"])
              ]),
              true
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: _normalizeClass(["p-1 h-fit bg-red-500 rounded cursor-pointer mx-auto", index === 0 ? 'mt-6' : 'mt-2']),
                    onClick: ($event: any) => (_ctx.deleteLane(lane.id))
                  }, [
                    _createVNode(_component_TrashIcon, { class: "h-6 w-6 text-white" })
                  ], 10, _hoisted_13))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("a", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
              class: "cursor-pointer flex px-4 md:px-8 py-2 rounded-md text-black border border-corp text-center hover:opacity-70"
            }, _toDisplayString(_ctx.$t('add-lane')), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}