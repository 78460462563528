import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex justify-between items-start plan-gap-sm" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "font-bold plan-t-prim-200 plan-t-sm" }
const _hoisted_5 = { class: "plan-t-prim-100 plan-t-xs" }
const _hoisted_6 = { class: "flex justify-start items-center plan-gap-sm plan-t-prim-100 plan-t-xs" }
const _hoisted_7 = {
  key: 0,
  class: "flex justify-start items-center plan-gap-xs"
}
const _hoisted_8 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_9 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_10 = {
  key: 0,
  class: "flex justify-end items-start plan-gap-sm"
}
const _hoisted_11 = {
  key: 0,
  class: "flex flex-col items-end"
}
const _hoisted_12 = { class: "plan-t-xs" }
const _hoisted_13 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_14 = {
  key: 2,
  class: "plan-t-xxs plan-t-prim-100"
}
const _hoisted_15 = {
  key: 1,
  class: "flex flex-col items-end"
}
const _hoisted_16 = {
  key: 0,
  class: "plan-t-xs"
}
const _hoisted_17 = {
  key: 1,
  class: "plan-t-xs"
}
const _hoisted_18 = { key: 2 }
const _hoisted_19 = {
  key: 3,
  class: "font-bold"
}
const _hoisted_20 = { class: "plan-t-xxs plan-t-prim-100" }
const _hoisted_21 = {
  key: 0,
  class: "plan-t-xxs plan-t-prim-100 plan-mr-sm"
}
const _hoisted_22 = {
  key: 1,
  style: {"height":"18px"},
  class: "plan-t-xxs plan-t-prim-100 plan-mr-sm"
}
const _hoisted_23 = { class: "plan-t-xxs plan-t-prim-100 plan-mr-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_pre_assigned_box = _resolveComponent("plan-carrier-pre-assigned-box")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_formatted_number = _resolveComponent("plan-formatted-number")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["rounded border plan-border-prim-50 plan-b-white plan-p-md flex flex-col justify-start items-start plan-gap-sm", {
      'plan-border-red-500': _ctx.item?.quote?.quoteRequest?.status === 'REFUSED',
      'plan-border-sec-500': _ctx.isLaneFilled,
    }])
  }, [
    (_ctx.positionPreAssigned !== -1)
      ? (_openBlock(), _createBlock(_component_plan_carrier_pre_assigned_box, {
          key: 0,
          onHandleConfirmLane: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleConfirmLane', _ctx.item.quote.id))),
          position: _ctx.positionPreAssigned,
          isPreAcceptedQuote: _ctx.isPreAcceptedQuote(_ctx.item),
          currentStatus: _ctx.item?.quote?.quoteRequest?.status
        }, null, 8, ["position", "isPreAcceptedQuote", "currentStatus"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.getLocationString(_ctx.item?.lane?.loadTemplate)), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.item?.lane?.loadsFrequency) + " " + _toDisplayString(_ctx.$t('loads')) + "/" + _toDisplayString(_ctx.$t(_ctx.item?.lane?.loadsFrequencyPeriod.toLowerCase())) + " , " + _toDisplayString(_ctx.$t('total-trucks', {
              trucks:
                _ctx.getLanesAndQuotesForCarrier.length >= 1
                  ? _ctx.getLanesAndQuotesForCarrier[_ctx.index].lane.computed.totalTrucksPerLane
                  : 0,
            })), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.getDateString(_ctx.item?.lane?.loadTemplate)), 1),
        _createElementVNode("div", _hoisted_6, [
          (
              _ctx.item?.lane?.loadTemplate?.totalDistance && _ctx.item?.lane?.loadTemplate?.totalDistance > 0
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_plan_icon, {
                  name: "location",
                  size: "12",
                  "icon-color": "#8A8B97",
                  "is-svg": ""
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.formatMiles(_ctx.item?.lane?.loadTemplate.totalDistance)), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_plan_icon, {
              name: "icInRoute",
              size: "12",
              "icon-color": "#8A8B97",
              "is-svg": ""
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.item?.lane?.loadTemplate?.trailerType?.description), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_plan_icon, {
              name: "package",
              size: "12",
              "icon-color": "#8A8B97",
              "is-svg": ""
            }),
            _createElementVNode("span", null, _toDisplayString(`${_ctx.item?.lane?.loadTemplate.commodityMaster.commodityName} ${_ctx.formatWeight(
                  _ctx.item?.lane?.loadTemplate.commodityMaster.weight,
                )}`), 1)
          ])
        ])
      ]),
      (_ctx.onlyDetails || _ctx.positionPreAssigned !== -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_ctx.item?.quote?.price)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('price-per-load')), 1),
                  (_ctx.item?.quote?.price)
                    ? (_openBlock(), _createBlock(_component_plan_formatted_number, {
                        key: 0,
                        number: _ctx.item?.quote?.price,
                        unit: "currency",
                        "fraction-digits": 0,
                        customClass: "font-bold"
                      }, null, 8, ["number"]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString('x'))),
                  (
              _ctx.item?.lane?.loadTemplate?.totalDistance && _ctx.item?.lane?.loadTemplate?.totalDistance > 0
            )
                    ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
                        _createVNode(_component_plan_formatted_number, {
                          number: 
                Number(_ctx.pricePerMile(_ctx.item?.quote?.price, _ctx.item?.lane?.loadTemplate.totalDistance))
              ,
                          unit: "pricePerDistance",
                          "fraction-digits": 2,
                          customClass: "font-bold"
                        }, null, 8, ["number"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.item?.quote?.maxCapacity)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  (_ctx.item?.quote?.quoteRequest.status === 'CONFIRMED')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.$t('assigned')), 1))
                    : (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.$t('load-period', {
                period: _ctx.$t(`${_ctx.item?.lane?.loadsFrequencyPeriod}`).toLocaleLowerCase(),
              })), 1)),
                  (_ctx.item?.quote?.quoteRequest.status === 'CONFIRMED')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.item?.quote?.assignedCapacity || _ctx.item?.quote?.maxCapacity), 1))
                    : (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.item?.quote?.maxCapacity || 'x'), 1)),
                  _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('loads')) + " / " + _toDisplayString(_ctx.$t(`${_ctx.item?.lane?.loadsFrequencyPeriod}`).toLocaleLowerCase()), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["flex justify-end items-center plan-gap-sm", _ctx.actualWidth <= 640 ? 'w-full flex-col' : ''])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex flex-col items-end", _ctx.actualWidth <= 640 ? 'w-full' : ''])
            }, [
              _createVNode(_component_plan_input_number, {
                "model-value": _ctx.price,
                "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.price) = $event)),
                label: _ctx.$t('price-per-load'),
                onChange: _ctx.handleUpdateQuotation,
                placeholder: "0",
                "hide-validations": "",
                currency: "",
                style: _normalizeStyle(_ctx.actualWidth <= 640 ? 'width: 100%' : 'width: 150px')
              }, null, 8, ["model-value", "label", "onChange", "style"]),
              (
              _ctx.item?.lane?.loadTemplate?.totalDistance && _ctx.item?.lane?.loadTemplate?.totalDistance > 0
            )
                ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                    _createVNode(_component_plan_formatted_number, {
                      number: Number(_ctx.pricePerMile(_ctx.price, _ctx.item?.lane?.loadTemplate.totalDistance)),
                      unit: "pricePerDistance",
                      "fraction-digits": 2,
                      customClass: "font-bold"
                    }, null, 8, ["number"])
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_22))
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["flex flex-col items-end", _ctx.actualWidth <= 640 ? 'w-full' : ''])
            }, [
              _createVNode(_component_plan_input_number, {
                "model-value": _ctx.loadsPerWeek,
                "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.loadsPerWeek) = $event)),
                onChange: _ctx.handleUpdateQuotation,
                label: _ctx.$t('loads') + ' / ' + _ctx.$t(_ctx.item?.lane?.loadsFrequencyPeriod),
                placeholder: "0",
                "hide-validations": "",
                style: _normalizeStyle(_ctx.actualWidth <= 640 ? 'width: 100%' : 'width: 150px')
              }, null, 8, ["model-value", "onChange", "label", "style"]),
              _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('loads')) + " / " + _toDisplayString(_ctx.$t('week')), 1)
            ], 2),
            (_ctx.showRemove)
              ? (_openBlock(), _createBlock(_component_plan_button_icon, {
                  key: 0,
                  onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteCurrentQuote(_ctx.item?.quote?.id))),
                  disabled: _ctx.price === null || _ctx.loadsPerWeek === null,
                  icon: "close",
                  size: "15",
                  color: "white",
                  type: "error"
                }, null, 8, ["disabled"]))
              : _createCommentVNode("", true)
          ], 2))
    ])
  ], 2))
}