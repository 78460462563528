
import { defineComponent, reactive, toRefs, watch, computed } from 'vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

interface planLoadsOrderFilter {
  value: string
}

export default defineComponent({
  name: 'PlanLoadsOrderFilter',
  components: { PlanSelectSimple },
  emits: ['change'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive<planLoadsOrderFilter>({
      value: 'executionDate[DESC]'
    })

    const items = computed(() => {
      return [
        { label: translate.t('order-pickup-asc'), value: 'id[ASC]'},
        { label: translate.t('order-pickup-desc'), value: 'id[DESC]'},
        { label: translate.t('order-creation-asc'), value: 'executionDate[ASC]'},
        { label: translate.t('order-creation-desc'), value: 'executionDate[DESC]'}
      ]
    })

    const change = (newValue: string) => {
      data.value = newValue
      store.commit('LoadsStore/applyFilter', { id: 'SORT_BY', value: data.value, loadFilterParam: data.value })
      emit('change', { id: 'SORT_BY', value: data.value, loadFilterParam: data.value } )
    }

    watch(() => store.getters['LoadsStore/getAppliedFilters'], (newFilters, oldFilters) => {
      if (Object.keys(newFilters).length === 0) {
        data.value = 'executionDate[DESC]'
      }
    })

    return {
      ...toRefs(data),
      items,
      change
    }
  }
})
