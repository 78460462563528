import moment from 'moment';

export default {
  carrierBidData(state: any) {
    return state.carrierBidData;
  },
  currentBid(state: any) {
    return state.currentBid;
  },
  currentLoad(state: any) {
    return state.currentLoad;
  },
  quotesAverageStats(state: any) {
    return state.quotesAverageStats;
  },
  currentLoadEditing(state: any) {
    return state.currentLoadEditing;
  },
  filteredCarriers(state: any) {
    return state.filteredCarriers;
  },
  getAppliedFilters(state: any) {
    return state.appliedFilters;
  },
  getSelectedQuickFilter(state: any) {
    return state.selectedQuickFilter;
  },
  getTotalClaims(state: any) {
    return state.totalClaims;
  },
  getTrackingLogs(state: any) {
    return state.trackingLogs;
  },
  getAppliedFiltersQuery(state: any) {
    const result: Record<string, string | number> = {};

    if (state.selectedQuickFilter !== '') {
      if (state.selectedQuickFilter === 'claims') {
        result['claims'] = 'ALL';
      }

      if (state.selectedQuickFilter === 'pickupsToday') {
        result['pickupInitialDateAfter'] = moment().format('YYYY-MM-DD');
        result['pickupInitialDateBefore'] = moment().format('YYYY-MM-DD');
      }

      if (
        state.selectedQuickFilter === 'pickupsThisWeekEurope' ||
        state.selectedQuickFilter === 'pickupsThisWeekNorthAmerica'
      ) {
        let startDay = moment().startOf('isoWeek').format('YYYY-MM-DD');
        if (state.selectedQuickFilter === 'pickupsThisWeekNorthAmerica')
          startDay = moment().startOf('week').format('YYYY-MM-DD');
        result['pickupInitialDateAfter'] = startDay;
        result['pickupInitialDateBefore'] = moment(startDay).add(6, 'days').format('YYYY-MM-DD');
      }

      if (state.selectedQuickFilter === 'deliveriesToday') {
        result['deliveryFinalDateAfter'] = moment().format('YYYY-MM-DD');
        result['deliveryFinalDateBefore'] = moment().format('YYYY-MM-DD');
      }

      if (
        state.selectedQuickFilter === 'deliveriesThisWeekEurope' ||
        state.selectedQuickFilter === 'deliveriesThisWeekNorthAmerica'
      ) {
        let startDay = moment().startOf('isoWeek').format('YYYY-MM-DD');
        if (state.selectedQuickFilter === 'deliveriesThisWeekNorthAmerica')
          startDay = moment().startOf('week').format('YYYY-MM-DD');
        result['deliveryFinalDateAfter'] = startDay;
        result['deliveryFinalDateBefore'] = moment(startDay).add(6, 'days').format('YYYY-MM-DD');
      }

      return result;
    }

    if (state.appliedFilters['pickup-date']) {
      const dates = state.appliedFilters['pickup-date'].loadFilterParam.split('#');
      if (dates.length === 2) {
        result['pickupInitialDateAfter'] = dates[0];
        result['pickupInitialDateBefore'] = dates[1];
      }
    }

    if (state.appliedFilters['delivery-date']) {
      const dates = state.appliedFilters['delivery-date'].loadFilterParam.split('#');
      if (dates.length === 2) {
        result['deliveryFinalDateAfter'] = dates[0];
        result['deliveryFinalDateBefore'] = dates[1];
      }
    }

    if (state.appliedFilters['PICKUP_INITIAL_CITY']) {
      if (
        state.appliedFilters['PICKUP_INITIAL_CITY'].value.length > 0 &&
        state.appliedFilters['PICKUP_INITIAL_CITY'].loadFilterParam.length === 0
      ) {
        result['pickupInitialPlace'] = state.appliedFilters['PICKUP_INITIAL_CITY'].value;
      } else {
        result['pickupInitialPlace'] = state.appliedFilters['PICKUP_INITIAL_CITY'].loadFilterParam;
      }
    }

    if (state.appliedFilters['DELIVERY_FINAL_CITY']) {
      if (
        state.appliedFilters['DELIVERY_FINAL_CITY'].value.length > 0 &&
        state.appliedFilters['DELIVERY_FINAL_CITY'].loadFilterParam.length === 0
      ) {
        result['deliveryFinalPlace'] = state.appliedFilters['DELIVERY_FINAL_CITY'].value;
      } else {
        result['deliveryFinalPlace'] = state.appliedFilters['DELIVERY_FINAL_CITY'].loadFilterParam;
      }
    }

    if (state.appliedFilters['TRAILER_TYPE'])
      result['trailerTypeId'] = state.appliedFilters['TRAILER_TYPE'].loadFilterParam;
    if (state.appliedFilters['CUSTOMER'])
      result['customerId'] = state.appliedFilters['CUSTOMER'].loadFilterParam;
    if (state.appliedFilters['CARRIER_BROKER'])
      result['carrierBrokerId'] = state.appliedFilters['CARRIER_BROKER'].loadFilterParam;
    if (state.appliedFilters['WAREHOUSE'])
      result['warehouseId'] = state.appliedFilters['WAREHOUSE'].loadFilterParam;
    if (state.appliedFilters['RFP_PROJECT'])
      result['projectId'] = state.appliedFilters['RFP_PROJECT'].loadFilterParam;
    if (state.appliedFilters['TEAM_MEMBER'])
      result['shipperOwnerId'] = state.appliedFilters['TEAM_MEMBER'].loadFilterParam;
    if (state.appliedFilters['FTL_LOAD'] && !state.appliedFilters['LTL_LOAD'])
      result['shipmentType'] = state.appliedFilters['FTL_LOAD'].loadFilterParam;
    if (state.appliedFilters['LTL_LOAD'] && !state.appliedFilters['FTL_LOAD'])
      result['shipmentType'] = state.appliedFilters['LTL_LOAD'].loadFilterParam;
    if (state.appliedFilters['LTL_LOAD'] && state.appliedFilters['FTL_LOAD'])
      result['shipmentType'] = '';
    if (state.appliedFilters['LOAD_BY_ID_PO'])
      result['identifier'] = state.appliedFilters['LOAD_BY_ID_PO'].loadFilterParam;
    if (state.appliedFilters['LOAD_BY_ID_PO'])
      result['poNumber'] = state.appliedFilters['LOAD_BY_ID_PO'].loadFilterParam;
    if (state.appliedFilters['STATUS'])
      result['status'] = state.appliedFilters['STATUS'].loadFilterParam;

    if (state.appliedFilters['SORT_BY'])
      result['sortBy'] = state.appliedFilters['SORT_BY'].loadFilterParam;

    return result;
  },
  getdensityClass(state: any) {
    return state.densityClass;
  },
  getDocsList(state: any) {
    return state.docsList;
  },
  getFeeExplanations(state: any) {
    return state.feeExplanations;
  },
  getShipmentNextStep(state: any) {
    return state.shipmentNextStep;
  },
  getShipmentFees(state: any) {
    return state.shipmentFees;
  },
  getLimitedAcces(state: any) {
    return state.limitedAccessList;
  },
  getLoads(state: any) {
    return state.loads;
  },
  getLoadInfo(state: any) {
    return state.loadInfo;
  },
  getLoadsInfo(state: any) {
    return state.loadsInfo;
  },
  getLoadsByDates(state: any) {
    return state.loadsByDates;
  },
  getLTLQuotesById(state: any) {
    return state.getLTLQuotesById;
  },
  getPlanningType(state: any) {
    return state.planningType;
  },
  getPreviousFilters(state: any) {
    return state.previousFilters;
  },
  getRankingRFP(state: any) {
    return state.rankingRFP;
  },
  getSelectedWarehouse(state: any) {
    return state.selectedWarehouse;
  },
  getLoadAditionalDocuments(state: any) {
    return state.loadAditionalDocuments;
  },
  getSaveLoadId(state: any) {
    return state.saveLoadId;
  },
  getSaveLoads(state: any) {
    return state.saveLoads;
  },
  getSaveLoadsSearch(state: any) {
    return state.saveLoadsSearch;
  },
  getStates(state: any) {
    return state.states;
  },
  getTemporalLoadId(state: any) {
    return state.temporalLoadId;
  },
  getTotals(state: any) {
    return state.totals;
  },
  getTrailerTypes(state: any) {
    return state.trailerTypes.sort((a, b) => a.sequence - b.sequence);
  },
  lanes(state: any) {
    return state.lanes;
  },
  loadId(state: any) {
    return state.loadId;
  },
  loadShipmentId(state: any) {
    return state.loadShipmentId;
  },
  ltlQuotes(state: any) {
    return state.ltlQuotes;
  },
  NMFC(state: any) {
    return state.NMFC;
  },
  packageTypes(state: any) {
    return state.packageTypes;
  },
  showFreightInfoModal(state: any) {
    return state.showFreightInfoModal;
  },
  showIncidenceModal(state: any) {
    return state.showIncidenceModal;
  },
  showModalShareDocuments(state: any) {
    return state.showModalShareDocuments;
  },
  showModalCarrierAssigned(state: any) {
    return state.showModalCarrierAssigned;
  },
  showModalQuoteSended(state: any) {
    return state.showModalQuoteSended;
  },
  showTemplateModal(state: any) {
    return state.showTemplateModal;
  },
  stopSelected(state: any) {
    return state.stopSelected;
  },

  totalSavedBidsLTL(state: any) {
    return state.totalSavedBidsLTL;
  },
  warehousesItems(state: any) {
    return state.warehousesItems;
  },
  warehousesItemsModified(state: any) {
    return state.warehousesItemsModified;
  },
};
