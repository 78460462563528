import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 transition-opacity",
  style: {"z-index":"99999"}
}
const _hoisted_2 = { class: "bg-bg rounded-lg w-full max-w-md md:h-auto mx-auto my-auto md:mt-10 overflow-hidden" }
const _hoisted_3 = { class: "justify-center" }
const _hoisted_4 = { class: "text-center p-6 my-5" }
const _hoisted_5 = { class: "mb-7 text-lg" }
const _hoisted_6 = { class: "flex justify-center items-center" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.text), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: _normalizeClass(["text-white bg-gray-500 hover:opacity-70 focus:ring-3 focus:outline-none focus:ring-gray-300 rounded-md text-sm font-medium items-center px-5 py-2.5 text-center mr-3", _ctx.getButtonStyles]),
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal', false)))
            }, _toDisplayString(_ctx.textButtonCancel || _ctx.$t('no-cancel')), 3),
            _createElementVNode("button", {
              class: _normalizeClass(["text-white bg-green-500 hover:opacity-70 focus:ring-3 focus:outline-none focus:ring-green-300 rounded-md text-sm inline-flex items-center px-5 py-2.5 text-center", _ctx.getButtonStyles]),
              type: "button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal', true)))
            }, [
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "inline w-4 h-4 mr-3 text-white animate-spin",
                    src: _ctx.getAsset.icSpinner,
                    alt: "spinner"
                  }, null, 8, _hoisted_7))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.textButtonConfirm || _ctx.$t('yes-im-sure')), 1)
            ], 2)
          ])
        ])
      ])
    ])
  ]))
}