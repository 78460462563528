
import { defineComponent, reactive, ref, Ref, toRefs } from 'vue';
import { VueSignaturePad } from 'vue-signature-pad';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PlanCarrierSign',
  components: { PlanButton, VueSignaturePad },
  emits: ['cancel', 'save'],
  setup(props, { emit }) {
    const store = useStore()
    const signaturePad: Ref<VueSignaturePad | null> = ref(null);
    const router = useRouter()

    const data = reactive({
      loading: false,
      signedRate: false
    })

    const clear = () => {
      signaturePad.value?.undoSignature();
    };

    const undo = () => {
      clear();
      emit('cancel')
    };

    const onBegin = () => {
      data.signedRate = false;
    };

    const onEnd = () => {
      data.signedRate = true;
    };

    const save = async () => {
      data.loading = true
      if (signaturePad.value) {
        const { isEmpty, data } = signaturePad.value.saveSignature();
        if (!isEmpty) {
          await store
            .dispatch('ShipmentStore/signsRateConfirmation', {
              entityId: store.getters['CarrierStore/getLoadData'].shipment.id,
              file: data,
            }).then(() => {
              router.push({ name: 'CarrierLoadAssigned', params: { id: store.getters['CarrierStore/getLoadData'].id } })
            })
        }
      }
    };

    return {
      ...toRefs(data),
      signaturePad,
      clear,
      onBegin,
      onEnd,
      undo,
      save
    };
  }
});
