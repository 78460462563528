import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-1 xl:ml-90" }
const _hoisted_2 = { class: "min-h-screen h-auto p-10 pt-0 pl-7 pr-0" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  style: {"max-width":"1200px","margin":"0 auto","display":"block"}
}
const _hoisted_5 = {
  key: 1,
  class: "flex flex-col items-center text-center space-y-4 p-10"
}
const _hoisted_6 = {
  key: 1,
  style: {"max-width":"1200px","margin":"0 auto","display":"block"}
}
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "flex justify-center" }
const _hoisted_9 = { class: "text-center mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewProjectModal = _resolveComponent("NewProjectModal")!
  const _component_WaitingLanesModal = _resolveComponent("WaitingLanesModal")!
  const _component_HeaderRFPLanes = _resolveComponent("HeaderRFPLanes")!
  const _component_ProjectsFilters = _resolveComponent("ProjectsFilters")!
  const _component_ProjectCard = _resolveComponent("ProjectCard")!
  const _component_ProjectCardSkeleton = _resolveComponent("ProjectCardSkeleton")!
  const _component_Spin = _resolveComponent("Spin")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.newProjectModalOptions.opened)
      ? (_openBlock(), _createBlock(_component_NewProjectModal, {
          key: 0,
          creationTypeModal: _ctx.newProjectModalOptions.mode,
          type: _ctx.newProjectModalOptions.type,
          onSetOpen: _ctx.displayModal,
          onFetchProjects: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fetchAll())),
          onUpdateProject: _ctx.updateProject
        }, null, 8, ["creationTypeModal", "type", "onSetOpen", "onUpdateProject"]))
      : _createCommentVNode("", true),
    _createVNode(_component_WaitingLanesModal, {
      continueAllowed: true,
      showModal: _ctx.showWaitingLanes,
      onFetchProjects: _ctx.fetchAll,
      onClose: _ctx.closeWaitingLanes
    }, null, 8, ["showModal", "onFetchProjects", "onClose"]),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_HeaderRFPLanes, {
        showSticky: _ctx.showSticky,
        onDisplayModal: _ctx.displayModal,
        onSubmitSearch: _ctx.submitSearch
      }, null, 8, ["showSticky", "onDisplayModal", "onSubmitSearch"]),
      _createElementVNode("main", _hoisted_2, [
        _createVNode(_component_ProjectsFilters, {
          ref: "statusFilters",
          activeStatus: _ctx.activeStatus,
          sortBy: _ctx.sortBy,
          onFilterProjects: _ctx.filterStatusProjectList,
          onSortProjects: _ctx.sortProjectList
        }, null, 8, ["activeStatus", "sortBy", "onFilterProjects", "onSortProjects"]),
        (!_ctx.isLoadingList)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.projectsList.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectsList, (project, index) => {
                      return (_openBlock(), _createBlock(_component_ProjectCard, {
                        key: index + _ctx.timeStamp,
                        projectList: project,
                        onFetchProjects: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchAll())),
                        onClick: _ctx.setProjectStatusSearched
                      }, null, 8, ["projectList", "onClick"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('add-here-projects')), 1),
                    (!_ctx.isShipperViewer)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.displayModal(true))),
                          class: "bg-corp flex px-4 md:px-8 py-2 ml-3 w-fit rounded-md cursor-pointer text-white border border-corp text-center hover:opacity-70"
                        }, _toDisplayString(_ctx.$t('new-project')), 1))
                      : _createCommentVNode("", true)
                  ]))
            ]))
          : (_ctx.isLoadingList)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (item) => {
                  return _createVNode(_component_ProjectCardSkeleton, { key: item })
                }), 64))
              ]))
            : _createCommentVNode("", true),
        (_ctx.hasMoreItems || _ctx.isLoadingMore)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("tr", _hoisted_8, [
                _createElementVNode("td", _hoisted_9, [
                  _createElementVNode("button", {
                    class: _normalizeClass(["w-full inline-flex justify-center text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800", { 'cursor-not-allowed hover:bg-blue-400': _ctx.isLoadingMore }]),
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.moreProjects && _ctx.moreProjects(...args)))
                  }, [
                    (_ctx.isLoadingMore)
                      ? (_openBlock(), _createBlock(_component_Spin, { key: 0 }))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('load-more')), 1)
                  ], 2)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}