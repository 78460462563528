
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';

interface Item {
  id: string, value: string | number, load_filter_param: string | number
}

interface planFilterPickupAndDeliveryLocation {
  hoverIndex: number,
  inputValue: string,
  isFirstLoadingComplete: boolean,
  isInputActive: boolean,
  items: Array<Item>,
  loadingItems: boolean,
  page: number,
  maxResults: number,
  selectedItem: {
    value: string,
    loadFilterParam: string | number
  },
  showPopper: boolean,
  totalResults: number
}

export default defineComponent({
  name: 'PlanFilterPickupAndDeliveryLocation',
  components: { PlanButtonIcon, PlanIcon },
  emits: ['status'],
  props: {
    id: { type: String, required: true, default: '' },
    delivery: { type: Boolean, required: false, default: false },
    title: { type: String, required: true, default: '' }
  },
  setup (props, { emit }) {
    const store = useStore()
    let debounceTimer

    const data = reactive<planFilterPickupAndDeliveryLocation>({
      hoverIndex: -1,
      inputValue: '',
      isFirstLoadingComplete: false,
      isInputActive: false,
      items: [],
      loadingItems: false,
      maxResults: 100,
      page: 1,
      selectedItem: {
        value: '',
        loadFilterParam: ''
      },
      showPopper: false,
      totalResults: 0
    })

    const iconColor = computed(() => {
      if (data.inputValue.length > 0) return '#151830'
      return data.isInputActive ? '#35414D' : '#757D84';
    })

    const cleanInputValue = () => {
      data.inputValue = ''
      data.selectedItem.value = ''
      data.selectedItem.loadFilterParam = ''
      getLoadFieldAutocomplete()
      emitStatus()
    }

    const debounce = (func, delay) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(func, delay);
    }

    const deleteOnKeUp = () => {
      if (data.inputValue === '') {
        cleanInputValue()
        getLoadFieldAutocomplete()
      }
    }

    const emitStatus = () => {
      emit('status', { id: props.id, value: data.inputValue, loadFilterParam: data.selectedItem.loadFilterParam })
    }

    const getLoadFieldAutocomplete = async () => {
      try {
        data.loadingItems = true
        const response = await store.dispatch('LoadsStore/getLoadFieldAutocomplete', {
          page: data.page,
          maxResults: data.maxResults,
          type: props.id,
          search: data.inputValue
        });

        data.items = response.values
        data.totalResults = response.total[0]
      } catch (error) {

      } finally {
        data.loadingItems = false
      }
    }

    const handleInput = () => {
      debounce(() => {
        getLoadFieldAutocomplete();
      }, 1000);
    }

    const handleInputFocus = () => {
      data.showPopper = true;
      data.isInputActive = true;
    };

    const handleInputBlur = () => {
      data.showPopper = false;
      data.isInputActive = false;
    };

    const onOpenPopper = () => {
      if (!data.isFirstLoadingComplete) {
        data.isFirstLoadingComplete = true
        getLoadFieldAutocomplete()
      }
    }

    const selItem = (value: string, loadFilterParam: string | number) => {
      data.inputValue = value
      data.selectedItem.value = value
      data.selectedItem.loadFilterParam = loadFilterParam
      data.showPopper = false
      emitStatus()
    }

    if (store.getters['LoadsStore/getAppliedFilters'][props.id]) {
      data.inputValue = store.getters['LoadsStore/getAppliedFilters'][props.id].value
      data.selectedItem.value = store.getters['LoadsStore/getAppliedFilters'][props.id].value
      data.selectedItem.loadFilterParam = store.getters['LoadsStore/getAppliedFilters'][props.id].loadFilterParam
    }

    getLoadFieldAutocomplete()

    watch(() => store.getters['LoadsStore/getAppliedFilters'], (newFilters, oldFilters) => {
      if (Object.keys(newFilters).length === 0 && data.inputValue !== '') {
        data.inputValue = ''
        data.selectedItem.value = ''
        data.selectedItem.loadFilterParam = ''
        emitStatus()
        getLoadFieldAutocomplete()
      }
    })

    return {
      ...toRefs(data),
      iconColor,
      cleanInputValue,
      deleteOnKeUp,
      emitStatus,
      getLoadFieldAutocomplete,
      handleInput,
      handleInputFocus,
      handleInputBlur,
      onOpenPopper,
      selItem
    }
  }
})
