
import { defineComponent, computed } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PlanCarrierLoadHeaderNotification',
  props: {
    message: { type: String, required: true, default: '' },
    type: { type: String, required: false, default: 'sec' }
  },
  setup () {
    const store = useStore()

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    return {
      actualWidth
    }
  }
})
