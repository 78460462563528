
import { defineComponent, reactive, toRefs } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { hide } from '@popperjs/core';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import RenameTemplateCategoryModal from '@/modules/planning/contacts/BlastEmail/RenameTemplateCategoryModal.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PlanEmailTemplateCategoryTitle',
  components: { RenameTemplateCategoryModal, PlanButton, PlanButtonIcon },
  props: {
    category: { type: Object, required: true },
    title: { type: String, required: true, default: '' },
    hideRemove: { type: Boolean, required: false, default: false },
    templates: { type: Number, required: true, default: 0 }
  },
  emits: ['changeName', 'closeAndUpdate'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      categoryName: '',
      loadingDelete: false,
      showRenameCategoryName: false
    })

    const changeName = () => {
      data.showRenameCategoryName = false
      emit('changeName', data.categoryName)
    }

    const closeAndUpdate = () => {
      emit('closeAndUpdate')
    }

    const deleteCategory = () => {
      data.loadingDelete = true
      console.log('categories', props.category)
      store.dispatch('LoadsStore/deleteEmailCategory', props.category)
      .then(() => {
        store.dispatch('UserStore/showToast', translate.t('success-delete-template-category'))
        emit('closeAndUpdate')
      }).finally(() => {
        data.loadingDelete = false
      })
    }

    const renameOnClick = () => {
      store.commit('CompanyStore/selectTemplateCategory', props.category)
      data.showRenameCategoryName = true
    }

    return {
      ...toRefs(data),
      changeName,
      closeAndUpdate,
      deleteCategory,
      renameOnClick
    }
  }
})
