import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-start items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanIcon = _resolveComponent("PlanIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cursor-pointer rounded-lg flex justify-between items-center p-2", _ctx.whenActive !== '' && _ctx.activeButton === _ctx.whenActive ? 'bg-plan-primary-300 text-plan-primary-700':'hover:bg-plan-gray-100'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PlanIcon, {
        name: _ctx.icon,
        "icon-color": _ctx.whenActive !== '' && _ctx.activeButton === _ctx.whenActive ? '#151830':'#757D84',
        isSvg: "",
        class: "mr-2 plan-gray-400"
      }, null, 8, ["name", "icon-color"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
    ]),
    _renderSlot(_ctx.$slots, "content")
  ], 2))
}