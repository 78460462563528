
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

interface planInputText {
  thereAreChanges: boolean;
}

export default defineComponent({
  name: 'PlanInputTextArea',
  components: { PlanIcon },
  props: {
    mandatory: { type: Boolean, required: false, default: false },
    modelValue: { type: String, required: true },
    label: { type: String, required: false, default: '' },
    height: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    validations: { type: Array, required: false, default: () => [] },
    requirements: { type: Array, required: false, default: () => [] },
    hideLeft: { type: Boolean, required: false, default: false },
    hideRight: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    rows: { type: Number, required: false, default: 2 },
    maxCharacters: { type: Number, required: false, default: null }
  },
  setup(props, { emit }) {
    const data = reactive<planInputText>({
      thereAreChanges: false,
    });

    const changeTheValue = () => {
      data.thereAreChanges = true;
    };

    const handleInput = (event: Event) => {
      const target = event.target as HTMLTextAreaElement;
      if (props.maxCharacters !== null && target.value.length > props.maxCharacters) {
        target.value = target.value.slice(0, props.maxCharacters);
      }
      emit('update:modelValue', target.value);
    };

    const customHeight = computed(() => {
      if (props.height) {
        return props.height;
      } else {
        return '';
      }
    });

    const internalValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const isComplete = computed(() => {
      let result = 0;
      if (props.requirements?.length > 0 && data.thereAreChanges) {
        if (props.requirements?.includes('greaterThan0')) {
          result += props.modelValue.length > 0 ? 1 : 0;
        }
      }
      return result === props.requirements?.length;
    });

    const validationResult = computed(() => {
      let result = '';
      if (props.validations?.length > 0 && data.thereAreChanges) {
        if (props.validations?.includes('empty')) {
          result = props.modelValue.length > 0 ? '' : 'The field cannot be empty';
        }
      }
      return result;
    });

    return {
      ...toRefs(data),
      changeTheValue,
      handleInput,
      internalValue,
      isComplete,
      customHeight,
      validationResult,
    };
  },
});
