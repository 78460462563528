
import { defineComponent } from 'vue';
import { EllipsisHorizontalCircleIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import ModalConfirm from '@/components/modals/ConfirmModal.vue';

export default defineComponent({
  name: 'ProjectCardOptions',
  components: {
    EllipsisHorizontalCircleIcon,
    ModalConfirm,
  },
  mixins: [BaseComponent],
  props: {
    projectList: {
      type: Object as any,
    },
  },
  data() {
    return {
      isDeleting: false,
      isPopperVisible: false,
      showModalConfirmDelete: false,
    };
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
  },
  methods: {
    callbackModal(confirm) {
      // Close modal
      if (!confirm) {
        this.toggleDeleteModal();
        this.togglePopper();
        return;
      }

      this.deleteProject();
    },
    deleteProject() {
      if (this.projectList) {
        this.isDeleting = true;
        this.$store
          .dispatch('RFPStore/deleteProject', this.projectList?.project?.id)
          .then(() => {
            this.isDeleting = false;
            BaseComponent.methods?.showToastSuccess(this.$t('delete-action'));
            this.$emit('fetchProjects');
            this.toggleDeleteModal();
          })
          .catch((err) => {
            this.isDeleting = false;
            BaseComponent.methods?.showToastError(err?.response?.data?.details);
            this.toggleDeleteModal();
          });
      }
      this.togglePopper();
    },
    draftProject() {
      this.$store.commit('RFPStore/setNewProjectModalOptions', {
        type: 'project',
        opened: true,
        mode: 'creation',
      });
      this.$store.commit('RFPStore/setProjectToDuplicate', this.projectList?.project);
      this.togglePopper();
    },
    editCurrentProject() {
      this.$store.commit('RFPStore/setNewProjectModalOptions', {
        type: 'scratch',
        opened: true,
        mode: 'edition',
      });
      this.$store.commit('RFPStore/setProjectToDuplicate', this.projectList?.project);
      this.togglePopper();
    },
    routerToDetail() {
      if (this.projectList.project) {
        let projectId = this.projectList.project.id;
        this.$router.push({ name: 'ProjectLaneDetail', params: { projectId } });
      }
      this.togglePopper();
    },
    toggleDeleteModal() {
      this.showModalConfirmDelete = !this.showModalConfirmDelete;
    },
    togglePopper() {
      this.isPopperVisible = !this.isPopperVisible;
    },
  },
});
