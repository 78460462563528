import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_ChangePlanModal = _resolveComponent("ChangePlanModal")!
  const _component_ImpersonatingBar = _resolveComponent("ImpersonatingBar")!
  const _component_NoCreditModal = _resolveComponent("NoCreditModal")!
  const _component_PlanimatikCreditModal = _resolveComponent("PlanimatikCreditModal")!
  const _component_WelcomeTrialModal = _resolveComponent("WelcomeTrialModal")!
  const _component_SidebarChat = _resolveComponent("SidebarChat")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Sidebar, {
        hidden: _ctx.sideBarHidden,
        onForceUpdate: _ctx.forceUpdate,
        onToggleHidden: _ctx.toggleSideBarHidden
      }, null, 8, ["hidden", "onForceUpdate", "onToggleHidden"]),
      _createVNode(_component_ChangePlanModal, {
        showModal: _ctx.showModalPlans,
        withoutSidebar: _ctx.isLoadDetailRoute,
        onClose: _ctx.toggleChangePlanModal
      }, null, 8, ["showModal", "withoutSidebar", "onClose"]),
      _createVNode(_component_ImpersonatingBar),
      _createVNode(_component_NoCreditModal, { show: _ctx.isNoCredit }, null, 8, ["show"]),
      _createVNode(_component_PlanimatikCreditModal, { show: _ctx.isPlanimatikCreditModal }, null, 8, ["show"]),
      _createVNode(_component_WelcomeTrialModal, { show: _ctx.isWelcomeOpen }, null, 8, ["show"]),
      (_ctx.isOpenChat)
        ? (_openBlock(), _createBlock(_component_SidebarChat, { key: 0 }))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_component_router_view, { key: _ctx.updateKey }))
    ])
  ]))
}