import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-md" }
const _hoisted_2 = { class: "plan-t-prim-100 text-center" }
const _hoisted_3 = { class: "w-full flex flex-col" }
const _hoisted_4 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex justify-start items-center plan-gap-sm plan-t-h5 font-bold" }
const _hoisted_7 = { class: "truncate" }
const _hoisted_8 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_9 = {
  key: 0,
  class: "flex justify-start items-center plan-gap-xs"
}
const _hoisted_10 = { class: "flex justify-start items-center" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "flex justify-start items-start plan-gap-sm" }
const _hoisted_14 = { class: "flex flex-col justify-start items-start plan-gap-xs" }
const _hoisted_15 = {
  key: 0,
  class: "truncate plan-t-prim-100"
}
const _hoisted_16 = {
  key: 0,
  class: "flex justify-start items-center plan-gap-sm plan-t-h5 font-bold"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_tracking_map = _resolveComponent("plan-carrier-tracking-map")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_carrier_tracking_file_notification = _resolveComponent("plan-carrier-tracking-file-notification")!
  const _component_plan_carrier_tracking_element = _resolveComponent("plan-carrier-tracking-element")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_carrier_upload_cmr = _resolveComponent("plan-carrier-upload-cmr")!
  const _component_plan_carrier_tracking_element_notification = _resolveComponent("plan-carrier-tracking-element-notification")!
  const _component_plan_carrier_upload_invoice = _resolveComponent("plan-carrier-upload-invoice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_carrier_tracking_map),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('complete-following-steps-to-end-service')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_plan_carrier_tracking_element, {
        title: _ctx.$t('accepted-service'),
        "is-active": "",
        "with-blank-footer": _ctx.isNotificationStop !== 0
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.isDriver)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["flex justify-start plan-gap-sm", _ctx.actualWidth <= 640 ? 'flex-col items-start' : 'items-center'])
                }, [
                  _createVNode(_component_plan_text_button, {
                    onOnClick: _ctx.goToAcceptance,
                    label: _ctx.$t('update-my-data')
                  }, null, 8, ["onOnClick", "label"])
                ], 2))
              : _createCommentVNode("", true),
            (!_ctx.isDriver)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_plan_carrier_tracking_file_notification, {
                    label: _ctx.$t('notification-title-rc-sign'),
                    description: `${_ctx.$t('on-date')} ${_ctx.getFormattedDateHour(
                  _ctx.assignedServiceData.rateConfirmationDate,
                )}`,
                    id: _ctx.rateConfirmation.id,
                    "entity-class": _ctx.rateConfirmation.entityClass
                  }, null, 8, ["label", "description", "id", "entity-class"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["title", "with-blank-footer"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadData.stops, (stop, index) => {
        return (_openBlock(), _createBlock(_component_plan_carrier_tracking_element, {
          key: stop.id,
          "is-active": stop.mediaObjects && stop.mediaObjects.length > 0,
          "is-notification": _ctx.isNotificationStop === index,
          "with-blank-footer": _ctx.isNotificationStop !== index,
          "with-line": _ctx.isDriver && index !== _ctx.loadData.stops.length - 1 || !_ctx.isDriver
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["w-full flex flex-col plan-gap-sm", { 'plan-t-prim-100': _ctx.isNotificationStop < index }])
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.getStopTitle(stop)), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_plan_icon, {
                  name: "calendar",
                  "icon-color": "#8A8B97",
                  size: "15",
                  "is-svg": ""
                }),
                _createElementVNode("p", null, _toDisplayString(_ctx.getFormattedDate(stop.executionDate)), 1)
              ]),
              (stop.executionWindowStartTime || stop.executionWindowEndTime)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_plan_icon, {
                      name: "clock",
                      "icon-color": "#8A8B97",
                      size: "15",
                      "is-svg": ""
                    }),
                    _createElementVNode("div", _hoisted_10, [
                      (stop.executionWindowStartTime)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(stop.executionWindowStartTime), 1))
                        : _createCommentVNode("", true),
                      (stop.executionWindowEndTime)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, " - " + _toDisplayString(stop.executionWindowEndTime), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_plan_icon, {
                  name: "location",
                  "icon-color": "#8A8B97",
                  size: "15",
                  "is-svg": ""
                }),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("span", null, _toDisplayString(stop.companyName), 1),
                  (stop.contactAndLocationInfo?.location?.address)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(stop.contactAndLocationInfo?.location?.address), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createVNode(_component_plan_carrier_upload_cmr, {
                "is-uploaded": stop.mediaObjects && stop.mediaObjects.length > 0,
                "is-notification": _ctx.isNotificationStop === index,
                stop: stop
              }, null, 8, ["is-uploaded", "is-notification", "stop"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stop.incidents, (incident) => {
                return (_openBlock(), _createBlock(_component_plan_carrier_tracking_element_notification, {
                  key: incident,
                  "show-date": incident.updatedAt,
                  description: incident.description,
                  "media-objects": incident.mediaObjects ? incident.mediaObjects : [],
                  "is-notification": _ctx.isNotificationStop === index,
                  "is-incident": ""
                }, null, 8, ["show-date", "description", "media-objects", "is-notification"]))
              }), 128))
            ], 2)
          ]),
          _: 2
        }, 1032, ["is-active", "is-notification", "with-blank-footer", "with-line"]))
      }), 128)),
      (!_ctx.isDriver)
        ? (_openBlock(), _createBlock(_component_plan_carrier_tracking_element, {
            key: 0,
            "with-line": false,
            "is-active": _ctx.loadData.invoices.length > 0
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["w-full flex flex-col plan-gap-sm", { 'plan-t-prim-100': !_ctx.isLoadToBid }])
              }, [
                (!_ctx.isDriver)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('bill-the-service')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.isDriver)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoicesToShow, (invoice) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: invoice.id,
                          class: "flex justify-start items-center plan-gap-sm"
                        }, [
                          _createVNode(_component_plan_carrier_tracking_file_notification, {
                            label: invoice.label,
                            description: invoice.description,
                            id: invoice.id,
                            "entity-class": invoice.entityClass
                          }, null, 8, ["label", "description", "id", "entity-class"])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.isDriver)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.$t('upload-your-invoice-service-cost')) + " " + _toDisplayString(_ctx.formatPrice(_ctx.loadData.shipment.assignedCompany.price)), 1))
                  : _createCommentVNode("", true),
                (!_ctx.isDriver)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.additionalFees, (additionalFee) => {
                        return (_openBlock(), _createBlock(_component_plan_carrier_tracking_element_notification, {
                          key: additionalFee.id,
                          "show-date": additionalFee.createdDate,
                          description: additionalFee.description,
                          "media-objects": additionalFee.mediaObjects ? additionalFee.mediaObjects : [],
                          "addition-fee-quantity": additionalFee.value,
                          "addition-fee-status": additionalFee.status,
                          "is-notification": "",
                          "is-additional-fee": ""
                        }, null, 8, ["show-date", "description", "media-objects", "addition-fee-quantity", "addition-fee-status"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isLoadToBid && !_ctx.isDriver)
                  ? (_openBlock(), _createBlock(_component_plan_carrier_upload_invoice, { key: 4 }))
                  : _createCommentVNode("", true)
              ], 2)
            ]),
            _: 1
          }, 8, ["is-active"]))
        : _createCommentVNode("", true)
    ])
  ]))
}