import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-lg" }
const _hoisted_2 = { class: "w-full flex flex-col plan-gap-md rounded border plan-border-prim-50 bg-white plan-p-md" }
const _hoisted_3 = { class: "w-full flex flex-col" }
const _hoisted_4 = { class: "flex justify-start items-start plan-gap-xs" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "font-bold plan-t-h4" }
const _hoisted_7 = { class: "plan-t-prim-100 plan-t-xxs" }
const _hoisted_8 = {
  key: 0,
  class: "w-full overflow-x-auto"
}
const _hoisted_9 = { class: "whitespace-nowrap flex flex-col" }
const _hoisted_10 = { class: "whitespace-nowrap" }
const _hoisted_11 = { class: "whitespace-nowrap" }
const _hoisted_12 = { class: "whitespace-nowrap" }
const _hoisted_13 = { class: "whitespace-nowrap" }
const _hoisted_14 = { class: "whitespace-nowrap" }
const _hoisted_15 = { class: "whitespace-nowrap" }
const _hoisted_16 = { class: "whitespace-nowrap" }
const _hoisted_17 = { class: "whitespace-nowrap" }
const _hoisted_18 = {
  class: "sticky right-0 bg-white",
  style: {"width":"200px"}
}
const _hoisted_19 = { class: "flex justify-end items-center plan-gap-sm" }
const _hoisted_20 = {
  key: 2,
  class: "w-full flex flex-col plan-gap-md rounded border plan-border-prim-50 bg-white plan-p-md"
}
const _hoisted_21 = { class: "w-full flex justify-between items-start" }
const _hoisted_22 = { class: "flex justify-start items-start plan-gap-xs" }
const _hoisted_23 = { class: "flex flex-col" }
const _hoisted_24 = { class: "font-bold plan-t-h4" }
const _hoisted_25 = { class: "plan-t-prim-100 plan-t-xxs" }
const _hoisted_26 = {
  key: 0,
  class: "w-full overflow-x-auto"
}
const _hoisted_27 = { class: "whitespace-nowrap flex flex-col" }
const _hoisted_28 = { class: "whitespace-nowrap" }
const _hoisted_29 = { class: "whitespace-nowrap" }
const _hoisted_30 = { class: "whitespace-nowrap" }
const _hoisted_31 = { class: "whitespace-nowrap" }
const _hoisted_32 = { class: "whitespace-nowrap" }
const _hoisted_33 = { class: "whitespace-nowrap" }
const _hoisted_34 = { class: "whitespace-nowrap" }
const _hoisted_35 = { class: "whitespace-nowrap" }
const _hoisted_36 = {
  class: "sticky right-0 bg-white",
  style: {"width":"200px"}
}
const _hoisted_37 = { class: "flex justify-end items-center plan-gap-sm" }
const _hoisted_38 = {
  key: 3,
  class: "w-full flex flex-col plan-gap-md rounded border plan-border-prim-50 bg-white plan-p-md"
}
const _hoisted_39 = { class: "w-full flex justify-between items-center plan-gap-sm" }
const _hoisted_40 = { class: "flex justify-start items-start plan-gap-xs" }
const _hoisted_41 = { class: "flex flex-col" }
const _hoisted_42 = { class: "font-bold plan-t-h4" }
const _hoisted_43 = { class: "plan-t-xxs plan-t-prim-100" }
const _hoisted_44 = { class: "w-full relative overflow-x-auto" }
const _hoisted_45 = { class: "whitespace-nowrap flex flex-col" }
const _hoisted_46 = { class: "whitespace-nowrap" }
const _hoisted_47 = { class: "whitespace-nowrap" }
const _hoisted_48 = { class: "whitespace-nowrap" }
const _hoisted_49 = { class: "whitespace-nowrap" }
const _hoisted_50 = { class: "whitespace-nowrap" }
const _hoisted_51 = { class: "whitespace-nowrap" }
const _hoisted_52 = { class: "whitespace-nowrap" }
const _hoisted_53 = { class: "whitespace-nowrap" }
const _hoisted_54 = {
  class: "sticky right-0 bg-white",
  style: {"width":"200px"}
}
const _hoisted_55 = { class: "flex justify-end items-center" }
const _hoisted_56 = {
  key: 4,
  class: "w-full flex flex-col plan-gap-md rounded border plan-border-prim-50 bg-white plan-p-md"
}
const _hoisted_57 = { class: "w-full flex justify-between items-center plan-gap-sm" }
const _hoisted_58 = { class: "flex justify-start items-start plan-gap-xs" }
const _hoisted_59 = { class: "flex flex-col" }
const _hoisted_60 = { class: "font-bold plan-t-h4" }
const _hoisted_61 = { class: "plan-t-xxs plan-t-prim-100" }
const _hoisted_62 = { class: "w-full relative overflow-x-auto" }
const _hoisted_63 = { class: "whitespace-nowrap flex flex-col" }
const _hoisted_64 = { class: "whitespace-nowrap" }
const _hoisted_65 = { class: "whitespace-nowrap" }
const _hoisted_66 = { class: "whitespace-nowrap" }
const _hoisted_67 = { class: "whitespace-nowrap" }
const _hoisted_68 = { class: "whitespace-nowrap" }
const _hoisted_69 = { class: "whitespace-nowrap" }
const _hoisted_70 = { class: "whitespace-nowrap" }
const _hoisted_71 = { class: "whitespace-nowrap" }
const _hoisted_72 = {
  class: "sticky right-0 bg-white",
  style: {"width":"200px"}
}
const _hoisted_73 = { class: "flex justify-end items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditCurrentContactModal = _resolveComponent("EditCurrentContactModal")!
  const _component_plan_default_delete_modal = _resolveComponent("plan-default-delete-modal")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_contact_tags = _resolveComponent("plan-contact-tags")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showEditModal)
      ? (_openBlock(), _createBlock(_component_EditCurrentContactModal, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEditModal = !_ctx.showEditModal)),
          onUpdateContact: _ctx.handleUpdateContact,
          modalState: _ctx.showEditModal,
          contactToEdit: _ctx.selectedContact
        }, null, 8, ["onUpdateContact", "modalState", "contactToEdit"]))
      : _createCommentVNode("", true),
    (_ctx.contactToDelete !== -1)
      ? (_openBlock(), _createBlock(_component_plan_default_delete_modal, {
          key: 1,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.contactToDelete = -1)),
          onDelete: _ctx.removeContact,
          title: _ctx.$t('are-you-sure'),
          message: _ctx.$t('are-you-sure-message'),
          loading: false,
          "hide-icon": ""
        }, null, 8, ["onDelete", "title", "message"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_plan_icon, {
            name: "fill-circle-close",
            "icon-color": "#EF4444",
            "is-svg": "",
            class: "plan-mt-xs"
          }),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('import-contacts-warning-title', { quantity: _ctx.contactsToShow.warning.length })), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('import-contacts-warning-subtitle')), 1)
          ])
        ])
      ]),
      (_ctx.contactsToShow.warning.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_plan_default_table, {
              items: _ctx.contactsToShow.warning,
              headers: _ctx.headers,
              truncateHeaders: ""
            }, {
              default: _withCtx((props) => [
                _createElementVNode("td", null, [
                  _createElementVNode("span", null, _toDisplayString(props.item.type == 'CARRIER' || props.item.type == 'BROKER'? _ctx.$t(`${props.item.type.toLowerCase()}`).toUpperCase(): props.item.type), 1)
                ]),
                _createElementVNode("td", _hoisted_9, [
                  _createElementVNode("span", null, _toDisplayString(props.item.companyName), 1),
                  (props.item.tags)
                    ? (_openBlock(), _createBlock(_component_plan_contact_tags, {
                        key: 0,
                        "company-id": -1,
                        "actual-tags": props.item.tags ? JSON.stringify(props.item.tags) : '[]',
                        "only-label": ""
                      }, null, 8, ["actual-tags"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("td", _hoisted_10, _toDisplayString(props.item.identificationNumber), 1),
                _createElementVNode("td", _hoisted_11, _toDisplayString(props.item.firstName), 1),
                _createElementVNode("td", _hoisted_12, _toDisplayString(props.item.lastName), 1),
                _createElementVNode("td", _hoisted_13, _toDisplayString(props.item.email), 1),
                _createElementVNode("td", _hoisted_14, _toDisplayString(props.item.prefix), 1),
                _createElementVNode("td", _hoisted_15, _toDisplayString(props.item.phone), 1),
                _createElementVNode("td", _hoisted_16, _toDisplayString(props.item.mcNumber), 1),
                _createElementVNode("td", _hoisted_17, _toDisplayString(props.item.dotNumber), 1),
                _createElementVNode("td", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_plan_button_icon, {
                      onOnClick: ($event: any) => (_ctx.updateContact(props.item)),
                      icon: "edit",
                      type: "transparent-gray-800"
                    }, null, 8, ["onOnClick"]),
                    _createVNode(_component_plan_button_icon, {
                      onOnClick: ($event: any) => (_ctx.contactToDelete = props.item.frontIndex),
                      icon: "trash",
                      type: "transparent-gray-800"
                    }, null, 8, ["onOnClick"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["items", "headers"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.contactsToShow.duplicated.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_plan_icon, {
                name: "triangle-alert",
                "icon-color": "#F1AD02",
                "is-svg": "",
                class: "plan-mt-xs"
              }),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('import-contacts-duplicated-title', { quantity: _ctx.contactsToShow.duplicated.length })), 1),
                _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t('import-contacts-duplicated-subtitle')), 1)
              ])
            ]),
            _createVNode(_component_plan_button_icon, {
              onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDuplicatedContacts = !_ctx.showDuplicatedContacts)),
              icon: _ctx.showDuplicatedContacts ? 'chevron-up':'chevron-down',
              type: "transparent-gray-800"
            }, null, 8, ["icon"])
          ]),
          (_ctx.showDuplicatedContacts)
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _createVNode(_component_plan_default_table, {
                  items: _ctx.contactsToShow.duplicated,
                  headers: _ctx.headers,
                  truncateHeaders: ""
                }, {
                  default: _withCtx((props) => [
                    _createElementVNode("td", null, [
                      _createElementVNode("span", null, _toDisplayString(props.item.type == 'CARRIER' || props.item.type == 'BROKER'? _ctx.$t(`${props.item.type.toLowerCase()}`).toUpperCase(): props.item.type), 1)
                    ]),
                    _createElementVNode("td", _hoisted_27, [
                      _createElementVNode("span", null, _toDisplayString(props.item.companyName), 1),
                      (props.item.tags)
                        ? (_openBlock(), _createBlock(_component_plan_contact_tags, {
                            key: 0,
                            "company-id": -1,
                            "actual-tags": props.item.tags ? JSON.stringify(props.item.tags) : '[]',
                            "only-label": ""
                          }, null, 8, ["actual-tags"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", _hoisted_28, _toDisplayString(props.item.identificationNumber), 1),
                    _createElementVNode("td", _hoisted_29, _toDisplayString(props.item.firstName), 1),
                    _createElementVNode("td", _hoisted_30, _toDisplayString(props.item.lastName), 1),
                    _createElementVNode("td", _hoisted_31, _toDisplayString(props.item.email), 1),
                    _createElementVNode("td", _hoisted_32, _toDisplayString(props.item.prefix), 1),
                    _createElementVNode("td", _hoisted_33, _toDisplayString(props.item.phone), 1),
                    _createElementVNode("td", _hoisted_34, _toDisplayString(props.item.mcNumber), 1),
                    _createElementVNode("td", _hoisted_35, _toDisplayString(props.item.dotNumber), 1),
                    _createElementVNode("td", _hoisted_36, [
                      _createElementVNode("div", _hoisted_37, [
                        _createVNode(_component_plan_button_icon, {
                          onOnClick: ($event: any) => (_ctx.contactToDelete = props.item.frontIndex),
                          icon: "trash",
                          type: "transparent-gray-800"
                        }, null, 8, ["onOnClick"])
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["items", "headers"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.contactsToShow.update.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, [
              _createVNode(_component_plan_icon, {
                name: "fill-circle-check",
                "icon-color": "#00DB50",
                "is-svg": "",
                class: "plan-mt-xs"
              }),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.$t('import-contacts-update-title', { quantity: _ctx.contactsToShow.update.length })), 1),
                _createElementVNode("p", _hoisted_43, _toDisplayString(_ctx.selectedUpdateContacts.length) + " " + _toDisplayString(_ctx.$t('selected')), 1)
              ])
            ]),
            _createVNode(_component_plan_button_icon, {
              onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showUpdateContacts = !_ctx.showUpdateContacts)),
              icon: _ctx.showUpdateContacts ? 'chevron-up':'chevron-down',
              type: "transparent-gray-800"
            }, null, 8, ["icon"])
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_44, [
            _createVNode(_component_plan_default_table, {
              onSelectedItems: _ctx.changeSelectedUpdateContacts,
              items: _ctx.contactsToShow.update,
              headers: _ctx.headers,
              "truncate-headers": "",
              selectable: "",
              "start-all-selected": ""
            }, {
              default: _withCtx((props) => [
                _createElementVNode("td", null, [
                  _createElementVNode("span", null, _toDisplayString(props.item.type == 'CARRIER' || props.item.type == 'BROKER'? _ctx.$t(`${props.item.type.toLowerCase()}`).toUpperCase(): props.item.type), 1)
                ]),
                _createElementVNode("td", _hoisted_45, [
                  _createElementVNode("span", null, _toDisplayString(props.item.companyName), 1),
                  (props.item.tags)
                    ? (_openBlock(), _createBlock(_component_plan_contact_tags, {
                        key: 0,
                        "company-id": -1,
                        "actual-tags": props.item.tags ? JSON.stringify(props.item.tags) : '[]',
                        "only-label": ""
                      }, null, 8, ["actual-tags"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("td", _hoisted_46, _toDisplayString(props.item.identificationNumber), 1),
                _createElementVNode("td", _hoisted_47, _toDisplayString(props.item.firstName), 1),
                _createElementVNode("td", _hoisted_48, _toDisplayString(props.item.lastName), 1),
                _createElementVNode("td", _hoisted_49, _toDisplayString(props.item.email), 1),
                _createElementVNode("td", _hoisted_50, _toDisplayString(props.item.prefix), 1),
                _createElementVNode("td", _hoisted_51, _toDisplayString(props.item.phone), 1),
                _createElementVNode("td", _hoisted_52, _toDisplayString(props.item.mcNumber), 1),
                _createElementVNode("td", _hoisted_53, _toDisplayString(props.item.dotNumber), 1),
                _createElementVNode("td", _hoisted_54, [
                  _createElementVNode("div", _hoisted_55, [
                    _createVNode(_component_plan_button_icon, {
                      onOnClick: ($event: any) => (_ctx.contactToDelete = props.item.frontIndex),
                      icon: "trash",
                      type: "transparent-gray-800"
                    }, null, 8, ["onOnClick"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["onSelectedItems", "items", "headers"])
          ], 512), [
            [_vShow, _ctx.showUpdateContacts]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.contactsToShow.success.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
          _createElementVNode("div", _hoisted_57, [
            _createElementVNode("div", _hoisted_58, [
              _createVNode(_component_plan_icon, {
                name: "fill-circle-check",
                "icon-color": "#00DB50",
                "is-svg": "",
                class: "plan-mt-xs"
              }),
              _createElementVNode("div", _hoisted_59, [
                _createElementVNode("p", _hoisted_60, _toDisplayString(_ctx.$t('import-contacts-success-title', { quantity: _ctx.contactsToShow.success.length })), 1),
                _createElementVNode("p", _hoisted_61, _toDisplayString(_ctx.selectedSuccessContacts.length) + " " + _toDisplayString(_ctx.$t('selected')), 1)
              ])
            ]),
            _createVNode(_component_plan_button_icon, {
              onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showSuccessContacts = !_ctx.showSuccessContacts)),
              icon: _ctx.showSuccessContacts ? 'chevron-up':'chevron-down',
              type: "transparent-gray-800"
            }, null, 8, ["icon"])
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_62, [
            _createVNode(_component_plan_default_table, {
              onSelectedItems: _ctx.changeSelectedSuccessContacts,
              items: _ctx.contactsToShow.success,
              headers: _ctx.headers,
              "truncate-headers": "",
              selectable: "",
              "start-all-selected": ""
            }, {
              default: _withCtx((props) => [
                _createElementVNode("td", null, [
                  _createElementVNode("span", null, _toDisplayString(props.item.type == 'CARRIER' || props.item.type == 'BROKER'? _ctx.$t(`${props.item.type.toLowerCase()}`).toUpperCase(): props.item.type), 1)
                ]),
                _createElementVNode("td", _hoisted_63, [
                  _createElementVNode("span", null, _toDisplayString(props.item.companyName), 1),
                  (props.item.tags)
                    ? (_openBlock(), _createBlock(_component_plan_contact_tags, {
                        key: 0,
                        "company-id": -1,
                        "actual-tags": props.item.tags ? JSON.stringify(props.item.tags) : '[]',
                        "only-label": ""
                      }, null, 8, ["actual-tags"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("td", _hoisted_64, _toDisplayString(props.item.identificationNumber), 1),
                _createElementVNode("td", _hoisted_65, _toDisplayString(props.item.firstName), 1),
                _createElementVNode("td", _hoisted_66, _toDisplayString(props.item.lastName), 1),
                _createElementVNode("td", _hoisted_67, _toDisplayString(props.item.email), 1),
                _createElementVNode("td", _hoisted_68, _toDisplayString(props.item.prefix), 1),
                _createElementVNode("td", _hoisted_69, _toDisplayString(props.item.phone), 1),
                _createElementVNode("td", _hoisted_70, _toDisplayString(props.item.mcNumber), 1),
                _createElementVNode("td", _hoisted_71, _toDisplayString(props.item.dotNumber), 1),
                _createElementVNode("td", _hoisted_72, [
                  _createElementVNode("div", _hoisted_73, [
                    _createVNode(_component_plan_button_icon, {
                      onOnClick: ($event: any) => (_ctx.contactToDelete = props.item.frontIndex),
                      icon: "trash",
                      type: "transparent-gray-800"
                    }, null, 8, ["onOnClick"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["onSelectedItems", "items", "headers"])
          ], 512), [
            [_vShow, _ctx.showSuccessContacts]
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}