
import { defineComponent } from 'vue';
import { ArrowRightIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Modules
import ProjectCardOptions from '@/modules/rfp/_components/cards/ProjectCardOptions.vue';
import ProjectCardStatus from '@/modules/rfp/_components/cards/ProjectCardStatus.vue';

export default defineComponent({
  name: 'ProjectCard',
  components: {
    ArrowRightIcon,
    ProjectCardOptions,
    ProjectCardStatus,
  },
  mixins: [BaseComponent],
  props: {
    projectList: {
      type: Object as any,
    },
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
  },
  methods: {
    viewDetail() {
      if (this.projectList?.computed?.lanes.totalAmount > 0) {
        const projectId = this.projectList?.project?.id;
        this.$router.push({ name: 'ProjectLaneDetail', params: { projectId } });
        return;
      }

      // Open view to add lanes if no lanes
      const projectId = this.projectList?.project?.id;
      this.$router.push({ name: 'lanesRFPCreation', params: { projectId } });
    },
  },
});
