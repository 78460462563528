import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "py-1" }
const _hoisted_2 = { class: "py-1" }
const _hoisted_3 = {
  key: 0,
  class: "py-1"
}
const _hoisted_4 = { class: "py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalConfirm = _resolveComponent("ModalConfirm")!
  const _component_EllipsisHorizontalCircleIcon = _resolveComponent("EllipsisHorizontalCircleIcon")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showModalConfirmDelete)
      ? (_openBlock(), _createBlock(_component_ModalConfirm, {
          key: 0,
          isLoading: _ctx.isDeleting,
          "background-confirm": 'bg-red-500',
          text: _ctx.$t('message-remove-project', { name: _ctx.projectList?.project?.name }),
          onCloseModal: _ctx.callbackModal
        }, null, 8, ["isLoading", "text", "onCloseModal"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Popper, {
      onClick: _withModifiers(_ctx.togglePopper, ["stop"]),
      class: "h-fit w-max popper-padding-1",
      style: {"margin":"0px","border":"0px"},
      click: ""
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
          class: "flex flex-col text-sm rounded-md"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.routerToDetail && _ctx.routerToDetail(...args))),
              class: "block px-4 py-2 text-sm text-corp hover:bg-gray-100 cursor-pointer"
            }, _toDisplayString(_ctx.$t('view-detail')), 1)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("a", {
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.editCurrentProject && _ctx.editCurrentProject(...args)), ["stop"])),
              class: "block px-4 py-2 text-sm text-corp hover:bg-gray-100 cursor-pointer"
            }, _toDisplayString(_ctx.$t('settings')), 1)
          ]),
          (_ctx.projectList?.computed?.lanes?.totalAmount > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("a", {
                  class: "block px-4 py-2 text-sm text-corp hover:bg-gray-100 cursor-pointer",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.draftProject && _ctx.draftProject(...args)), ["stop"]))
                }, _toDisplayString(_ctx.$t('new-project')), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", {
              class: "block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 cursor-pointer",
              onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleDeleteModal && _ctx.toggleDeleteModal(...args)), ["stop"]))
            }, _toDisplayString(_ctx.$t('delete')), 1)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_EllipsisHorizontalCircleIcon, { class: "h-6 w-6 text-corp mx-1 cursor-pointer" })
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}