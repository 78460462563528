import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_component = _resolveComponent("sidebar-component")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_sidebar_component),
    _createElementVNode("div", {
      class: "fixed overflow-y-scroll top-0 h-screen",
      style: _normalizeStyle(_ctx.superAdminStyle)
    }, [
      _createVNode(_component_router_view)
    ], 4)
  ]))
}