import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-start items-center plan-gap-xs"
}
const _hoisted_2 = { class: "m-0 p-0" }
const _hoisted_3 = {
  key: 1,
  class: "flex justify-start items-center plan-gap-xs"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["plan-selected-recipient-button", {'add-plan-selected-recipient-button cursor-pointer': _ctx.addRecipients }])
  }, [
    (_ctx.addRecipients)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_plan_icon, {
            name: "plus",
            "icon-color": "white",
            size: "10",
            "is-svg": ""
          }),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('add-contact')), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.user.length > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.dataToShow.user), 1))
            : _createCommentVNode("", true),
          (_ctx.company.length > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.dataToShow.company), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_plan_icon, {
            onClick: _ctx.remove,
            name: "close",
            "icon-color": "#8A8B97",
            size: "15",
            "is-svg": "",
            class: "cursor-pointer"
          }, null, 8, ["onClick"])
        ]))
  ], 2))
}