
import { defineComponent } from 'vue';
import { Bars3Icon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  components: {
    PlanIcon,
    Bars3Icon,
  },
  mixins: [BaseComponent],
  props: {
    currentLane: Object,
    currentProject: Object,
    showSticky: Boolean,
    statusList: String,
    hash: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      idFilter: null,
      section: 'list',
      weeks: -1,
      weeklyDates: null as any,
    };
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    calculateWeeks(): any {
      if (this.initialPickUpStop?.executionDate && this.finalDeliveryStop?.executionDate) {
        const startDate = new Date(this.formatDate(this.initialPickUpStop?.executionDate, true));
        const endDate = new Date(this.formatDate(this.finalDeliveryStop?.executionDate, true));

        let startDayOfWeek = startDate.getDay();
        let endDayOfWeek = endDate.getDay();

        if (this.currentUser?.company?.country?.region?.name === 'EUROPE') {
          startDayOfWeek = (startDayOfWeek + 6) % 7;
          endDayOfWeek = (endDayOfWeek + 6) % 7;
        }

        const startAdjustment = -startDayOfWeek;
        const endAdjustment = 6 - endDayOfWeek;

        const firstDateOfWeek = new Date(startDate);
        firstDateOfWeek.setDate(startDate.getDate() + startAdjustment);

        const lastDateOfWeek = new Date(endDate);
        lastDateOfWeek.setDate(endDate.getDate() + endAdjustment);

        const oneDay = 24 * 60 * 60 * 1000;
        const daysDifference = Math.floor(
          (lastDateOfWeek.getTime() - firstDateOfWeek.getTime()) / oneDay,
        );

        const weeks = Math.ceil((daysDifference + 1) / 7);

        return weeks;
      } else {
        return null;
      }
    },
    finalDeliveryStop(): any {
      let finalStop = null;
      if (this.currentLane?.loadTemplate?.stops) {
        this.currentLane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'DELIVERY_FINAL') {
            finalStop = stop;
          }
        });
      }
      return finalStop;
    },
    initialPickUpStop(): any {
      let initialStop = null;
      if (this.currentLane?.loadTemplate?.stops) {
        this.currentLane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'PICKUP_INITIAL') {
            initialStop = stop;
          }
        });
      }
      return initialStop;
    },
    nPickUps(): any {
      return this.currentLane?.loadTemplate?.stops?.filter(
        (item) => item.type === 'PICKUP_INITIAL' || item.type === 'PICKUP',
      ).length;
    },
    nDeliveries(): any {
      return this.currentLane?.loadTemplate?.stops?.filter(
        (item) => item.type === 'DELIVERY_FINAL' || item.type === 'DELIVERY',
      ).length;
    },
    specialRequirements(): any {
      if (
        this.currentLane?.loadTemplate?.specialRequirement &&
        Object.keys(this.currentLane?.loadTemplate?.specialRequirement).length !== 0
      ) {
        const requirements = Object.keys(this.currentLane?.loadTemplate?.specialRequirement).filter(
          (key) => this.currentLane?.loadTemplate?.specialRequirement[key] === true,
        );
        requirements.forEach((req, index) => {
          if (req === 'specialPermissions') {
            requirements[index] =
              this.$t(req) +
              ': ' +
              this.currentLane?.loadTemplate?.specialRequirement.specialPermissionsDescription;
          } else {
            requirements[index] = this.$t(req);
          }
        });
        return requirements.join(', ');
      }
      return null;
    },
  },
  created() {
    if (this.currentLane?.loadsFrequencyPeriod === 'WEEK') {
      this.weekDates();
    } else {
      this.monthDates();
    }
  },
  methods: {
    filterId() {
      this.$emit('setLoadId', this.idFilter);
    },
    filterWeeks() {
      this.$emit('setWeeks', this.weeks, this.weeks !== -1 ? this.weeklyDates[this.weeks] : null);
    },
    loadsFrequencyPeriod(loadsFrequencyPeriod) {
      let translation = '';

      switch (loadsFrequencyPeriod) {
        case 'WEEK':
          translation = this.$t('frequency-week');
          break;
        case 'MONTH':
          translation = this.$t('frequency-month');
          break;
        case 'TOTAL':
          translation = this.$t('frequency-total');
          break;
      }

      return translation;
    },
    toggleOpenRanking() {
      this.$emit('toggleOpenRanking');
    },
    toggleAddLoad() {
      this.$emit('toggleAddLoad');
    },
    setSection(value: string) {
      this.section = value;
      this.$emit('setSection', value);
    },
    monthDates() {
      const start = this.initialPickUpStop?.executionDate;
      const endDate = this.finalDeliveryStop?.executionDate;

      const monthDates = [] as any;

      let currentDate = new Date(this.formatDate(start, true));
      let end = new Date(this.formatDate(endDate, true));
      let UTC = this.currentUser?.company?.country?.region?.name === 'EUROPE' ? false : true;

      while (currentDate <= end) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDayOfMonth = new Date(year, month, 0);

        monthDates.push({
          start: this.formatDate(firstDayOfMonth, true, UTC),
          end: this.formatDate(lastDayOfMonth, true, UTC),
        });

        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      monthDates[0].start = this.formatDate(this.initialPickUpStop?.executionDate, true);
      monthDates[monthDates.length - 1].end = this.formatDate(this.finalDeliveryStop?.executionDate, true);

      this.weeklyDates = monthDates;
    },
    weekDates() {
      const weekDates = [] as any;
      let UTC = this.currentUser?.company?.country?.region?.name === 'EUROPE' ? false : true;

      if (this.initialPickUpStop && this.finalDeliveryStop) {
        for (let i = 0; i < this.calculateWeeks; i++) {
          const start = new Date(this.formatDate(this.initialPickUpStop?.executionDate, true));
          const currentDate = new Date(this.formatDate(this.initialPickUpStop?.executionDate, true));
          currentDate.setDate(start.getDate() + i * 7);

          let dayOfWeek = currentDate.getDay();

          const startOfWeek = new Date(currentDate);
          const endOfWeek = new Date(currentDate);

          if (this.currentUser?.company?.country?.region?.name === 'EUROPE') {
            startOfWeek.setDate(currentDate.getDate() - dayOfWeek + 1);
            endOfWeek.setDate(currentDate.getDate() + (6 - dayOfWeek + 1));
            UTC = false;
          } else {
            startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
            endOfWeek.setDate(currentDate.getDate() + (6 - dayOfWeek));
          }

          weekDates.push({
            start: this.formatDate(startOfWeek, true, UTC),
            end: this.formatDate(endOfWeek, true, UTC),
          });
        }

        weekDates[0].start = this.formatDate(this.initialPickUpStop?.executionDate, true);
        weekDates[weekDates.length - 1].end = this.formatDate(this.finalDeliveryStop?.executionDate, true);
      }
      this.weeklyDates = weekDates;
    },
  },
});
