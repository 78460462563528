
import { defineComponent, computed, ref, onMounted, nextTick, onUnmounted } from 'vue';
// Services
import { UserRoles } from '@/services/user/UserTypes';
import SidebarComponent from '@/components/sidebar/Sidebar.vue';
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  name: 'PlanningView',
  components: { SidebarComponent },
  setup () {
    const store = useStore()
    const windowWidth = ref(window.innerWidth)

    const collapsedSidebar = computed(() => { return store.getters['UserStore/getCollapsedSidebar'] })

    const currentUser = computed(() => { return store.getters['UserStore/getCurrentUser'] })

    const isOpenChat = computed(() => { return store.state.GlobalStore.isOpenChat })

    const superAdminStyle = computed(() => {
      let sidebarWidth = windowWidth.value < 1280 ? '0rem' : store.getters['UserStore/getCollapsedSidebar'] ? '64px' : '274px';
      let contentWidth = windowWidth.value < 1280 ? '100vw' : `calc(100vw - ${sidebarWidth})`;
      let contentTop = windowWidth.value < 1280 ? '72px' : '0px'

      return {
        'left': sidebarWidth,
        'width': contentWidth,
        'top': contentTop,
        'z-index': 9200
      };
    })


    const checkUserType = () => {
      if (currentUser.value && currentUser.value.roles) {
        let isAdmin = false;
        currentUser.value.roles.forEach((role) => {
          if (role.includes(UserRoles.ADMIN)) {
            isAdmin = true;
          }
        });
        if (!isAdmin) {
          router.push('/signin');
        }
      }
    }

    const resizeWindow = () => { windowWidth.value = window.innerWidth }

    onMounted(() => { window.addEventListener('resize', resizeWindow); });

    onUnmounted(() => { window.removeEventListener('resize', resizeWindow); });

    store.dispatch('PusherStore/initializePusher');
    store.dispatch('LoadsStore/states');
    store.dispatch('LoadsStore/trailers').then().catch((err) => console.log(err));

    checkUserType();

    return {
      superAdminStyle
    }
  }
});
