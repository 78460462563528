import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeStatus && _ctx.changeStatus(...args))),
    class: _normalizeClass(["quick-filter-button", {
          'quick-filter-button-disabled': _ctx.quantity === 0,
          'quick-filter-button-red': _ctx.type === 'red-500',
          'quick-filter-button-red-filled': _ctx.type === 'red-500' && _ctx.isActive,
          'quick-filter-button-filled': _ctx.type === 'sec-500' && _ctx.isActive,
       }])
  }, [
    (_ctx.icon.length > 0)
      ? (_openBlock(), _createBlock(_component_plan_icon, {
          key: 0,
          name: _ctx.icon,
          "is-svg": "",
          size: _ctx.icon === 'bell-claims' ? '14':'20',
          "icon-color": _ctx.iconColor
        }, null, 8, ["name", "size", "icon-color"]))
      : _createCommentVNode("", true),
    (!_ctx.onlyIcon)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.quantity) + " ", 1),
          (_ctx.label.length > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isActive)
      ? (_openBlock(), _createBlock(_component_plan_icon, {
          key: 2,
          name: "close",
          "is-svg": "",
          size: "15",
          "icon-color": "white"
        }))
      : _createCommentVNode("", true)
  ], 2))
}