
import { defineComponent, reactive, toRefs, computed, watch } from 'vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import PlanFilter from '@/components/filters/PlanFilter.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

interface planFilterLtlFtlSelectorItems {
  label: string,
  value: string
}
interface planFilterLtlFtlSelector {
  region: string,
  value: string,
  showPopper: boolean,
  isHover: boolean
}

export default defineComponent({
  name: 'PlanFilterLtlFtlSelector',
  components: { PlanButtonIcon },
  emits: ['change'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive<planFilterLtlFtlSelector>({
      region: 'NORTH_AMERICA',
      value: 'all',
      showPopper: false,
      isHover: false
    })

    const selectItems = computed(() => {
      let result: Array<planFilterLtlFtlSelectorItems> = []

      if (data.region === 'NORTH_AMERICA') {
        result = [
          { label: 'FTL LTL', value: 'all' },
          { label: 'FTL', value: 'FTL' },
          { label: 'LTL', value: 'LTL' },
        ]
      } else if (data.region === 'EUROPE') {
        result = [
          { label: 'FTL', value: 'FTL' }
        ]
      }

      return result
    })

    const change = (newValue: string) => {
      if (newValue === 'FTL') {
        if ('LTL_LOAD' in store.getters['LoadsStore/getAppliedFilters']) store.commit('LoadsStore/applyFilter', { id: 'LTL_LOAD', value: '', loadFilterParam: '' })
        emit('change', { id: 'FTL_LOAD', value: true, loadFilterParam: 'FTL' } )
      }
      if (newValue === 'LTL') {
        if ('FTL_LOAD' in store.getters['LoadsStore/getAppliedFilters']) store.commit('LoadsStore/applyFilter', { id: 'FTL_LOAD', value: '', loadFilterParam: '' })
        emit('change', { id: 'LTL_LOAD', value: true, loadFilterParam: 'LTL' } )
      }

      if (newValue === 'all') {
        if ('FTL_LOAD' in store.getters['LoadsStore/getAppliedFilters']) emit('change', { id: 'FTL_LOAD', value: '', loadFilterParam: '' } )
        if ('LTL_LOAD' in store.getters['LoadsStore/getAppliedFilters']) emit('change', { id: 'LTL_LOAD', value: '', loadFilterParam: '' } )
      }

      data.value = newValue
      data.showPopper = false
    }

    const dataPreformatting = () => {
      if ('FTL_LOAD' in store.getters['LoadsStore/getAppliedFilters']) data.value = 'FTL'
      if ('LTL_LOAD' in store.getters['LoadsStore/getAppliedFilters']) data.value = 'LTL'

      if (store.getters['UserStore/getRegion'] !== '') {
        data.region = store.getters['UserStore/getRegion']
      } else {
        data.region = store.getters['UserStore/getCurrentUser'].company?.country?.region?.name
      }

      data.value = data.region === 'NORTH_AMERICA' ? 'all':'FTL'
      change(data.value)
    }

    watch(() => store.getters['LoadsStore/getAppliedFilters'], (newFilters, oldFilters) => {
      if (Object.keys(newFilters).length === 0) {
        data.value = data.region === 'NORTH_AMERICA' ? 'all':'FTL'
      }
    })

    watch(() => data.isHover, (newValue, oldValue) => {
      if (!data.isHover) data.showPopper = false
    })

    dataPreformatting()

    return {
      ...toRefs(data),
      selectItems,
      change
    }
  }
})

