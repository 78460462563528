import demoRequest from '../_api';

export default {
  async apiGetDemoRequestById(context: any, id: number) {
    const response = await demoRequest.getDemoRequestById(id);
    context.commit('setDemoRequestDataForm', response);
    return response;
  },
  async apiGetDemoRequests(context: any, params: any) {
    const response = await demoRequest.getDemoRequests(params);
    if (params.page === 1) {
      context.commit('setDemoRequestData', response);
    } else {
      context.commit('pushDemoRequestData', response);
    }
  },
  async apiGetDemoRequestsTotal(context: any) {
    const response = await demoRequest.getDemoRequestsTotal();
    context.commit('setDemoRequestDataTotals', response);
    if (response && response.length > 0) {
      const total = response.reduce((acc, curr) => curr.total + acc, 0);
      context.commit('setDemoRequestsTotals', total);
    }
  },
  async apiPostDemoRequests(context: any, data: any) {
    const response = await demoRequest.postDemoRequests(data);
    context.commit('setDemoRequestDataForm', response);
  },
  async apiPostDemoRequestsAccept(context: any, id: number) {
    return await demoRequest.postDemoRequestsAccept(id);
  },
  async apiPostDemoRequestsDecline(context: any, id: number) {
    return await demoRequest.postDemoRequestsDecline(id);
  },
  async apiPutDemoRequests(context: any, data: any) {
    return await demoRequest.putDemoRequests(data);
  },
  async clearDemoData(context: any) {
    context.commit('clearAllDemoData');
  },
  async clearDemoRequestDataForm(context: any) {
    context.commit('clearDemoRequestDataForm');
  },
  async clearDemoStore(context: any) {
    context.commit('clearDemoStore');
  },
  async updateDemoRequestById(context: any, payload: any) {
    context.commit('updateDemoRequest', payload);
  },
  async updateDemoRequestStatusById(context: any, payload: any) {
    context.commit('updateDemoRequestStatus', payload);
  },
};
