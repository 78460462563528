import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "fixed top-0 left-0 h-screen w-screen flex justify-center items-center",
  style: {"z-index":"99999"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
      class: _normalizeClass(["h-screen w-screen absolute z-10", _ctx.hideBackground ? '' : 'bg-black bg-opacity-50'])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass([[
            _ctx.size === 'xs' ? 'w-3/12' : '',
            _ctx.size === '2xs' ? 'w-4/12' : '',
            _ctx.size === 'md' ? 'w-6/12' : '',
            _ctx.size === 'lg' ? 'w-8/12' : '',
            _ctx.size === 'xl' ? 'w-10/12 h-1/2' : '',
            _ctx.size === '2xl' ? 'w-10/12 h-3/4' : '',
            _ctx.size === 'full' ? 'w-full h-full' : '',
            _ctx.withoutPadding ? '' : 'p-8',
            _ctx.animation ? 'flipInX' : ''
         ], "overflow-auto rounded shadow bg-white plan-modal-content z-50"])
    }, [
      _renderSlot(_ctx.$slots, "modalContent")
    ], 2)
  ]))
}