
import { defineComponent } from 'vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'SidebarChat',
  components: {
    XCircleIcon,
  },
  props: {
    carrier: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeChat() {
      this.$store.commit('GlobalStore/setOpenChat', false);
    },
  },
});
