
import { defineComponent, computed, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import { useStore } from 'vuex';
import PlanButton from '@/components/buttons/PlanButton.vue';
import i18n from '@/resources/locales';
import { setLocale } from '@vee-validate/i18n';
import SidebarUserButton from '@/components/sidebar/SidebarUserButton.vue';

export default defineComponent({
  name: 'CarrierSidebar',
  components: { PlanAvatar, PlanButton, PlanIcon, SidebarUserButton },
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive({
      selectedLanguage: i18n.global.locale.value,
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const actualUser = computed(() => {
      return store.getters['UserStore/getCurrentUser']
    })

    const changeLanguage = (type: any) => {
      data.selectedLanguage = type
      i18n.global.locale.value = data.selectedLanguage;
      localStorage.setItem('userLocale', data.selectedLanguage);
      setLocale(data.selectedLanguage);
    }

    return {
      ...toRefs(data),
      actualWidth,
      actualUser,
      changeLanguage
    }
  }
})
