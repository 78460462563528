
import { computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue';

import BaseComponent from '@/base/BaseComponent';
import CardMixing from '@/modules/payments/_mixins/index.js';
import AddCardModalVue from '@/components/modals/AddCardModal.vue';
// Components

// Types
import { useStore } from 'vuex';
import i18n from '@/resources/locales';

export default defineComponent({
  components: { AddCardModalVue },
  props: {},
  mixins: [BaseComponent, CardMixing],

  setup(props) {
    const store = useStore();
    const $t = i18n.global.t;
    const showCardModal = ref(false);
    const typeSelected = ref('subscription');
    const isSending = ref([] as any);
    const isSendingDefault = ref([] as any);

    /** onMounted */
    onMounted(() => {
      //
    });

    /** Methods **/

    const resetToken = async () => {
      const refreshToken = localStorage.refresh_token;
      const refresh = await store.dispatch('AuthStore/refreshToken', refreshToken);
    };

    const deleteCard = (cardId: number, _index: number) => {
      isSending.value[_index] = true;
      store.dispatch('PaymentStore/removeCard', cardId).then(() => {
        isSending.value[_index] = false;
        store.dispatch('PaymentStore/paymentMethod');
        BaseComponent.methods?.showToastSuccess($t('card-deleted'));
      });
    };

    const setDefaultCreditCard = (cardId: number, _index: number) => {
      isSendingDefault.value[_index] = true;
      store.dispatch('PaymentStore/defaultPaymentMethod', cardId).then(() => {
        if (currentUser.value.company?.accessPlanType === 'SUBSCRIPTION_NOT_PAID') {
          setTimeout(() => {
            resetToken();
          }, 1000);
        }
        isSendingDefault.value[_index] = false;
        store.dispatch('PaymentStore/paymentMethod');
        BaseComponent.methods?.showToastSuccess($t('mark-as-default-done'));
      });
    };

    /** Computed */
    const paymentMethods = computed(() => {
      return store.getters['PaymentStore/getPaymentMethod'];
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    return {
      deleteCard,
      isSending,
      isSendingDefault,
      paymentMethods,
      setDefaultCreditCard,
      showCardModal,
    };
  },
});
