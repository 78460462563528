
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanInputDate from '@/components/forms/PlanInputDate.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import { is } from '@vee-validate/rules';

export default defineComponent({
  name: 'PlanUpdateMultipleLanesVehicle',
  methods: { is },
  components: { PlanInputNumber, PlanSelectSimple, PlanInputCheckbox },
  emits: ['change'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      showForm: false,
      vehicle: '',
      vehicleName: '',
      isRefrigerated: {
        value: false,
        min: null,
        max: null
      }
    })

    const change = () => {
      if (data.isRefrigerated.min && data.isRefrigerated.max) {
        if (data.isRefrigerated.min > data.isRefrigerated.max) data.isRefrigerated.min = data.isRefrigerated.max
      }

      emit('change', {
        isActive: data.showForm,
        vehicle: data.vehicle,
        vehicleName: data.vehicleName,
        isRefrigerated: {
          value: data.isRefrigerated.value,
          min: data.isRefrigerated.min,
          max: data.isRefrigerated.max
        }
      })
    }

    const changeShowForm = (value: boolean) => {
      data.showForm = value
      change()
    }

    const changeVehicle = (newVehicle: any) => {
      data.vehicle = newVehicle
      vehicles.value.forEach((vehicle: any) => {
        if (vehicle.value === data.vehicle) {
          data.vehicleName = vehicle.label
          data.isRefrigerated.value = vehicle.type === 'REFRIGERATEDOPTIONAL' || vehicle.type === 'REFRIGERATED'
        }
      })
      change()
    }

    const vehicles = computed(() => {
      let result: Array<any> = []
      store.getters['LoadsStore/getTrailerTypes'].forEach((vehicle: any) => {
        result.push({ label: vehicle.description, value: vehicle.id.toString(), type: vehicle.type })
      })
      return result
    })

    return {
      ...toRefs(data),
      change,
      changeShowForm,
      changeVehicle,
      vehicles
    }
  }
})
