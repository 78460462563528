
import { computed, defineComponent, reactive, ref, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import { useI18n } from 'vue-i18n';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ShowImportFileContainer',
  components: {
    PlanLoading,
    PlanInputFile,
    PlanTextButton,
    PlanButton,
  },
  emits: ['hideImportFile', 'change'],
  setup: function (props, { emit }) {
    const store = useStore()
    const router = useRouter();
    const translate = useI18n()

    const data = reactive({
      loadingDownloadTemplate: false,
      loadingPostUploadExcelFile: false
    })

    const changeFile = async (files: File[]) => {
      if (files.length > 0) {
        data.loadingPostUploadExcelFile = true
        const formData = new FormData();
        formData.append('file', files[0])
        await store.dispatch('UserStore/postContactsExcel', formData)
          .then((response) => {
            if (response.length === 0) {
              store.dispatch('UserStore/updateToast', {
                text: translate.t('file-is-empty'),
                type: 'error',
                visible: true
              })
            }
          })
        data.loadingPostUploadExcelFile = false
      }
    }

    const downloadExcel = async () => {
      data.loadingDownloadTemplate = true
      let blob = await store.dispatch('UserStore/getContactsExcel');
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'contacts-template.xlsx';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      data.loadingDownloadTemplate = false
    };

    const currentContacts = computed(() => {
      return store.getters['UserStore/getCurrentContacts'];
    });

    return {
      ...toRefs(data),
      changeFile,
      router,
      downloadExcel
    };
  },
});
