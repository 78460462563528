import { IContact, ICompany, IState, IToast, IBillingCompany } from '@/services/user/interfaces';

export default {
  // getUser(state: IState): object {
  //   return state.getUser;
  // },
  carrierContactList(state: IState): IContact[] {
    return state.carrierContactList;
  },
  carrierContactListStatsByLoad(state: IState): IContact[] {
    return state.carrierContactListStatsByLoad;
  },
  carrierNetwork(state: IState): IContact[] {
    return state.carrierNetwork;
  },
  carrierNetworkTotal(state: IState): object {
    return state.carrierNetworkTotal;
  },
  carrierRequestedBid(state: IState): IContact[] {
    return state.carrierRequestedBid;
  },
  getBigText(state: IState): any {
    return state.bigTexts;
  },
  getCollapsedSidebar(state: IState): boolean {
    return state.collapsedSidebar;
  },
  getCompany(state: IState): ICompany[] {
    return state.company;
  },
  getCurrentContacts(state: IState): any[] {
    return state.currentContacts;
  },
  getCurrentUser(state: IState): object {
    return state.currentUser;
  },
  getIsTheAdminImpersonating(state: IState): any {
    return state.isTheAdminImpersonating;
  },
  getCurrentUserImpersonating(state: IState): object {
    return state.currentUserImpersonating;
  },
  getImpersonatedUserId(state: IState): string {
    return state.userIdentification;
  },
  getPlanDefaultViewScrollTop(state: IState): number {
    return state.planDefaultViewScrollTop;
  },
  getRegion(state: IState): string {
    return state.region;
  },
  getIsShipperViewer(state: IState): boolean {
    return state.isShipperViewer;
  },
  getImportingContacts (state: IState) {
    return state.importingContacts
  },
  getShowHash(state: IState): any {
    return state.showHash;
  },
  getTeamData(state: IState): any {
    return state.dataTeam;
  },
  getUser(state: IState): object {
    return state.user;
  },
  getUsersCompany(state: IState): any {
    return state.usersCompany;
  },
  getUsersCompanyTotals(state: IState): any {
    return state.usersCompanyTotals;
  },
  getUserEmailNotificationSetup(state: IState): any {
    return state.userEmailNotificationSetup;
  },
  notification(state: IState): any {
    return state.notification;
  },
  notificationItems(state: IState): object {
    return state.notificationItems;
  },
  requestBidStatsByLoad(state: IState): IContact[] {
    return state.requestBidStatsByLoad;
  },
  toast(state: IState): IToast {
    return state.toast;
  },
  getBillingCompany(state: IState): IBillingCompany {
    return state.billingCompany;
  },
  getShowModalTimezone(state: IState): any {
    return state.showModalTimezone;
  },
  getShowModalPlans(state: IState): any {
    return state.showModalPlans;
  },
};
