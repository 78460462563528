
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useStore } from 'vuex';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins';
// Resources
import i18n from '@/resources/locales';
// Utils
import { validPhoneNumberPaste } from '@/utils/validationForm';
import PhoneNumberSelector from "@/components/forms/PhoneNumberSelector.vue";

export default defineComponent({
  name: 'RequestDemoDetailModal',
  components: {
    PhoneNumberSelector,
    BaseModal,
    ErrorMessage,
    Field,
    Form,
    Spin,
  },
  mixins: [BaseComponent, CountryMixins],
  emits: ['callbackClose', 'callbackUpdate'],
  props: {
    demoRequestId: Number,
    show: Boolean,
  },
  setup(props, { emit }) {
    /** Global */
    const $t = i18n.global.t;
    const store = useStore();

    const city = ref('');
    const companyAddress = ref('');
    const companyAddressExtra = ref('');
    const companyEmail = ref('');
    const companyName = ref('');
    const companyURL = ref('');
    const country = ref('');
    const countryIso = ref(-1);
    const fein = ref('');
    const firstName = ref('');
    const form = ref(null);
    const lastName = ref('');
    const loading = ref(false);
    const phoneNumber = ref('');
    const phonePrefix = ref('');
    const phonePrefixData = ref([]);
    const phonePrefixIso = ref(-1);

    /** Lifecycle */
    onMounted(() => {
      apiGetCountries();
    });

    /** Computed */
    const countriesData = computed(() => {
      return store.getters['CountryStore/getCountries'];
    });

    const demoRequestDataForm = computed(() => {
      return store.getters['DemoStore/getDemoRequestDataForm'];
    });

    /** Methods */
    const apiGetCountries = () => {
      return store.dispatch('CountryStore/getCountries');
    };

    const apiGetDemoRequestById = () => {
      return store.dispatch('DemoStore/apiGetDemoRequestById', props.demoRequestId).then(() => {
        initializeFormData();
      });
    };

    const apiPutDemoRequestData = () => {
      const params = {
        name: firstName.value,
        surname: lastName.value,
        email: companyEmail.value,
        phonePrefix: phonePrefix.value,
        phone: phoneNumber.value,
        companyName: companyName.value,
        companyIdentificationNumber: fein.value,
        companyURL: companyURL.value,
        location: {
          address: companyAddress.value,
          city: city.value,
        },
        country: {
          id: country.value,
        },
      };
      setLoading(true);
      return store
        .dispatch('DemoStore/apiPutDemoRequests', {
          id: props.demoRequestId,
          body: params,
        })
        .then((response) => {
          setLoading(false);
          BaseComponent.methods?.showToastSuccess($t('user-updated'));
          store.dispatch('DemoStore/updateDemoRequestById', response);
          onClickClose();
        })
        .catch((err) => {
          setLoading(false);
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    };

    const changePhone = (type, phone) =>{
      switch (type){
        case 'prefix':
          phonePrefix.value = phone.prefix;
          break;
        case 'number':
          phoneNumber.value = phone.number;
          break;
        default:
          return
      }
    };

    const clearDemoRequestDataForm = () => {
      return store.dispatch('DemoStore/clearDemoRequestDataForm');
    };

    const clearForm = () => {
      firstName.value = '';
      lastName.value = '';
      phonePrefix.value = '';
      phoneNumber.value = '';
      companyName.value = '';
      companyEmail.value = '';
      companyAddress.value = '';
      // companyAddressExtra.value = ';
      fein.value = '';
      companyURL.value = '';
      country.value = '';
      countryIso.value = -1;
      city.value = '';
    };

    const initializeFormData = () => {
      firstName.value = demoRequestDataForm.value?.name;
      lastName.value = demoRequestDataForm.value?.surname;
      phonePrefix.value = demoRequestDataForm.value?.phonePrefix;
      phoneNumber.value = demoRequestDataForm.value?.phone;
      companyName.value = demoRequestDataForm.value?.companyName;
      companyEmail.value = demoRequestDataForm.value?.email;
      companyAddress.value = demoRequestDataForm.value?.location?.address;
      // companyAddressExtra.value = demoRequestDataForm.value?.location?.addressExtra;
      fein.value = demoRequestDataForm.value?.companyIdentificationNumber;
      companyURL.value = demoRequestDataForm.value?.companyURL;
      country.value = demoRequestDataForm.value?.country?.id;
      countryIso.value = demoRequestDataForm.value?.country?.iso;
      city.value = demoRequestDataForm.value?.location?.city;
    };

    const onChangeCountry = () => {
      const result = countriesData.value.find((item) => item.id === country.value);
      if (result) {
        countryIso.value = result.iso;
      }
    };

    const onChangePrefix = () => {
      const result = countriesData.value.find((item) => item.id === phonePrefix.value);
      if (result) {
        phonePrefixIso.value = result.iso;
      }
    };

    const onClickClose = () => {
      emit('callbackClose');
    };

    const onClickUpdate = async () => {
      // Check form
      const validation = await (form as any).value.validate();
      if (!validation.valid) {
        return;
      }

      apiPutDemoRequestData();
      // emit('callbackUpdate');
    };

    const setLoading = (value) => {
      loading.value = value;
    };

    /** Watchers */
    watch(
      () => props.show,
      (newValue) => {
        if (newValue === true) {
          apiGetDemoRequestById();
          return;
        }
        clearDemoRequestDataForm();
        clearForm();
      },
    );

    watch(
      () => countriesData.value,
      (countries) => {
        // Assign array
        if (JSON.stringify(countries) !== JSON.stringify(phonePrefixData.value)) {
          // Remove duplicate values
          const prefixes = countries.map((item) => item.phonePrefix);
          phonePrefixData.value = countries?.filter(
            (item, index) => prefixes?.indexOf(item.phonePrefix) === index,
          );
        }
      },
    );

    return {
      city,
      changePhone,
      companyAddress,
      companyAddressExtra,
      companyEmail,
      companyName,
      companyURL,
      countriesData,
      country,
      countryIso,
      fein,
      firstName,
      form,
      lastName,
      loading,
      phoneNumber,
      phonePrefix,
      phonePrefixData,
      phonePrefixIso,
      onChangeCountry,
      onChangePrefix,
      onClickClose,
      onClickUpdate,
      validPhoneNumberPaste,
    };
  },
});
