
import { defineComponent, computed } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import moment from 'moment';

export default defineComponent({
  name: 'PlanCarrierRfpQuoteSubHeader',
  components: { PlanIcon, PlanAvatar, PlanButtonIcon },
  setup() {
    const store = useStore();

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });
    const companyShipperOwner = computed(() => {
      return currentProject.value.project?.shipperOwner;
    });

    const userForChat = computed(() => {
      return store.state.ChatsStore.userForChat;
    });

    const openChat = () => {
      if (JSON.stringify(userForChat.value) !== JSON.stringify(companyShipperOwner.value)) {
        store.commit('LoadsStore/loadId', null);
        store.commit('GlobalStore/setOpenChat', false);
        if (
          getLanesAndQuotesForCarrier.value[0]?.lane?.chatInformation &&
          getLanesAndQuotesForCarrier.value[0]?.lane?.chatInformation[0] &&
          getLanesAndQuotesForCarrier.value[0]?.lane?.chatInformation[0][0]
        ) {
          getLanesAndQuotesForCarrier.value[0].lane.chatInformation[0][0].readed = true;
        }
        setTimeout(() => {
          store.commit('ChatsStore/setUserForChat', {
            owner: currentProject?.value.project?.shipperOwner,
          });
          store.commit('ChatsStore/setLaneForChat', getLanesAndQuotesForCarrier.value[0]?.lane);
          if (
            getLanesAndQuotesForCarrier.value[0]?.lane?.chatInformation &&
            getLanesAndQuotesForCarrier.value[0]?.lane?.chatInformation[0] &&
            getLanesAndQuotesForCarrier.value[0]?.lane?.chatInformation[0][0]
          ) {
            store.commit(
              'ChatsStore/setChatId',
              getLanesAndQuotesForCarrier.value[0]?.lane?.chatInformation[0][0]?.chat ?? null,
            );
          }
          store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }

      store.commit('CarrierStore/openChat', false);
    };

    const shipperOwner = computed(() => {
      return {};
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    const formatDate = (date) => {
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? moment(date).format('MMM DD, YYYY')
        : moment(date).format('DD MMM, YYYY');
    };

    const getLanesAndQuotesForCarrier = computed(() => {
      return store.getters['RFPStore/getLanesAndQuotesForCarrier'].laneAndQuote;
    });

    if (store.getters['CarrierStore/openChat']) {
      openChat();
    }

    return {
      actualWidth,
      currentProject,
      getLanesAndQuotesForCarrier,
      formatDate,
      openChat,
      shipperOwner,
    };
  },
});
