import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "md:text-3xl font-bold text-center" }
const _hoisted_2 = { class: "flex flex-col items-center justify-center gap-8 py-5" }
const _hoisted_3 = { class: "w-full flex justify-between space-x-2 border-y py-5" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_5 = { class: "w-40 flex flex-col ml-2" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "text-sm truncate max-w-[300px]" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "font-semibold" }
const _hoisted_10 = { class: "font-semibold" }
const _hoisted_11 = { class: "w-full overflow-x-auto flex flex-col justify-center space-y-8" }
const _hoisted_12 = {
  key: 0,
  class: "w-full font-semibold text-center"
}
const _hoisted_13 = {
  key: 1,
  class: "table-auto w-full text-left"
}
const _hoisted_14 = { class: "bg-gray-50 rounded-lg" }
const _hoisted_15 = { class: "rounded-lg text-center" }
const _hoisted_16 = { class: "px-2 py-3" }
const _hoisted_17 = { class: "px-2 py-3" }
const _hoisted_18 = { class: "px-2 py-3" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "font-medium text-gray-900 px-2 py-3" }
const _hoisted_21 = { class: "font-medium px-2 py-3" }
const _hoisted_22 = { class: "font-medium text-gray-900 px-2 py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spin = _resolveComponent("Spin")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    show: _ctx.show,
    modalSize: "medium",
    onCallbackClose: _ctx.onClickClose
  }, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("shipper's-credit")), 1)
    ]),
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.userUpdate)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", {
                  class: _normalizeClass(["flex justify-center items-center h-9 w-9 rounded-full ring-2 ring-white text-center text-white", _ctx.getUserDefaultImage(_ctx.userUpdate?.name).color])
                }, _toDisplayString(_ctx.getUserDefaultImage(_ctx.userUpdate?.name).name), 3),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(`${_ctx.userUpdate?.name} ${_ctx.userUpdate?.surname}` || '-'), 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.userUpdate?.company?.name || '-'), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_8, _toDisplayString(`${_ctx.$t('credit-limit')}:`) + " " + _toDisplayString(_ctx.formatPrice(_ctx.companyUpdate.creditGranted)), 1),
          _createElementVNode("p", _hoisted_9, _toDisplayString(`${_ctx.$t('credit-used')}:`) + " " + _toDisplayString(_ctx.formatPrice(_ctx.companyUpdate.creditLocked)), 1),
          _createElementVNode("p", _hoisted_10, _toDisplayString(`${_ctx.$t('available-credit')}:`) + " " + _toDisplayString(_ctx.formatPrice(_ctx.companyUpdate.creditGranted - _ctx.companyUpdate.creditLocked)), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          (_ctx.creditBreakdown.totalAmount === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.$t('no-results')), 1))
            : _createCommentVNode("", true),
          (_ctx.creditBreakdown && _ctx.creditBreakdown.totalAmount > 0)
            ? (_openBlock(), _createElementBlock("table", _hoisted_13, [
                _createElementVNode("thead", _hoisted_14, [
                  _createElementVNode("tr", _hoisted_15, [
                    _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.$t('load')), 1),
                    _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.$t('date')), 1),
                    _createElementVNode("th", _hoisted_18, _toDisplayString(_ctx.$t('total-amount')), 1)
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.creditBreakdown.loads, (load, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      class: _normalizeClass(["w-full text-center bg-white border-b last:border-0 hover:bg-slate-100 cursor-pointer", { 'animate-pulse cursor-not-allowed pointer-events-none': _ctx.loading }]),
                      key: index,
                      onClick: ($event: any) => (_ctx.seeLoad(load.id))
                    }, [
                      _createElementVNode("td", _hoisted_20, _toDisplayString(`#${load.identifier}`), 1),
                      _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.formatDate(load.date)), 1),
                      _createElementVNode("td", _hoisted_22, _toDisplayString(_ctx.formatPrice(load.creditLocked)), 1)
                    ], 10, _hoisted_19))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.creditBreakdown.loads.length < _ctx.creditBreakdown.totalAmount)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: _normalizeClass(["w-44 inline-flex justify-center self-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800", { 'cursor-not-allowed hover:bg-blue-400': _ctx.showSpinner }]),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadMore && _ctx.loadMore(...args)))
              }, [
                (_ctx.showSpinner)
                  ? (_openBlock(), _createBlock(_component_Spin, { key: 0 }))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.showSpinner ? _ctx.$t('loading') : _ctx.$t('load-more')), 1)
              ], 2))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["show", "onCallbackClose"]))
}