import { defineComponent } from 'vue';
// import { useStore } from 'vuex';
// Resources
import { localAssets } from '@/resources/assets/assets';

export default defineComponent({
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    dataCountries(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    isEurope(): boolean {
      const country = this.dataCountries?.find(
        (country) => this.currentUser?.company?.country?.id === country?.id,
      );
      return country?.region?.name === 'EUROPE';
    },
  },
  methods: {
    getCountryFlag(iso) {
      switch (iso) {
        case 'SE':
          return localAssets.icSEFlag;
        case 'FI':
          return localAssets.icFIFlag;
        case 'SK':
          return localAssets.icSKFlag;
        case 'SI':
          return localAssets.icSIFlag;
        case 'PL':
          return localAssets.icPLFlag;
        case 'AT':
          return localAssets.icATFlag;
        case 'MT':
          return localAssets.icMTFlag;
        case 'HU':
          return localAssets.icHUFlag;
        case 'LU':
          return localAssets.icLUFlag;
        case 'LT':
          return localAssets.icLTFlag;
        case 'DK':
          return localAssets.icDKFlag;
        case 'CZ':
          return localAssets.icCZFlag;
        case 'BG':
          return localAssets.icBGFlag;
        case 'GB':
          return localAssets.icGBFlag;
        case 'LV':
          return localAssets.icLVFlag;
        case 'CY':
          return localAssets.icCYFlag;
        case 'HR':
          return localAssets.icHRFlag;
        case 'GR':
          return localAssets.icGRFlag;
        case 'IE':
          return localAssets.icIEFlag;
        case 'EE':
          return localAssets.icEEFlag;
        case 'US':
          return localAssets.icUSFlag;
        case 'MX':
          return localAssets.icMXFlag;
        case 'CA':
          return localAssets.icCNFlag;
        case 'ES':
          return localAssets.icESFlag;
        case 'IT':
          return localAssets.icITFlag;
        case 'PT':
          return localAssets.icPTFlag;
        case 'DE':
          return localAssets.icDEFlag;
        case 'FR':
          return localAssets.icFRFlag;
        case 'NL':
          return localAssets.icNLFlag;
        case 'RO':
          return localAssets.icROFlag;
        case 'BE':
          return localAssets.icBEFlag;
        default:
          return (this.isEuropeCompany())
            ? localAssets.icESFlag
            : localAssets.icUSFlag;
      }
    },
    isEuropeCompany(): boolean {
      const country = this.dataCountries?.find(
        (country) => this.currentUser?.company?.country?.id === country?.id,
      );
      return country?.region?.name === 'EUROPE';
    },
  },
});
