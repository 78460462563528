
import { defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';

interface carrierAdditionalFeeMediaObjects {
  thumbnails: Array<any>
}

export default defineComponent({
  name: 'CarrierAdditionalFeeMediaObjects',
  props: {
    mediaObjects: { type: Array, required: true }
  },
  setup (props) {
    const store = useStore()
    const data = reactive<carrierAdditionalFeeMediaObjects>({
      thumbnails: []
    })
    const getMediaObjectOfAdditionalFee = async (id: number) => {
      const result = await store.dispatch('ShipmentStore/thumbnailBig', {
        id: id,
        class: 'ADDITIONAL_FEE',
      });

      data.thumbnails.push(URL.createObjectURL(result));
    }

    if (props.mediaObjects.length > 0) {
      props.mediaObjects.forEach(function (mediaObject: any) {
        getMediaObjectOfAdditionalFee(mediaObject.id);
      });
    }

    return {
      ...toRefs(data)
    }
  }
})
