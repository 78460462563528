
import { computed, defineComponent } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanThumbnail from '@/components/forms/PlanThumbnail.vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'PlanCarrierDocumentsTab',
  components: { PlanThumbnail, PlanIcon },
  setup () {
    const store = useStore()
    const translate = useI18n()

    const documents = computed(() => {
      let rateConfirmation = {
        date: '',
        id: '',
        entityClass: ''
      }

      store.getters['CarrierStore/getLoadData'].shipment.mediaObjects.forEach((mediaObject: any) => {
        if (mediaObject.type === 'RATE_CONFIRMATION') {
          rateConfirmation = {
            date: mediaObject.createdAt,
            id: mediaObject.id,
            entityClass: mediaObject.entityClass
          }
        }
      })

      return {
        rateConfirmation: rateConfirmation
      }
    })

    const getFormattedDate = (date: string) => {
      moment.locale(translate.locale.value)
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? moment(date).format('MMM DD, YYYY') : moment(date).format('DD MMM, YYYY')
    }

    const getFormattedDateHour = (date: string) => {
      moment.locale(translate.locale.value)
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? moment(date).format('MMM DD, YYYY HH:MM') : moment(date).format('DD MMM, YYYY HH:MM')
    }

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData']
    })

    return {
      documents,
      getFormattedDate,
      getFormattedDateHour,
      loadData
    }
  }
})
