
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'PlanFormattedNumber',
  props: {
    number: { type: Number, required: true, default: 0 },
    unit: { type: String, required: false, default: '' },
    fractionDigits: { type: Number, required: false, default: 0 },
    customClass: { type: String, required: false, default: '' },
  },
  setup(props) {
    const store = useStore();

    const formattedValue = computed(() => {
      const locale = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es';
      const currency = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'USD' : 'EUR';
      const weight = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'lb' : 'kg';
      const distance = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'mi' : 'km';
      const temperature = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'ºF' : 'ºC';
      const currencySymbol = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? '$' : '€';


      let result = '';

      if (props.unit === 'currency') {
        result = props.number.toLocaleString(locale, {
          style: 'currency',
          minimumFractionDigits: props.fractionDigits,
          maximumFractionDigits: props.fractionDigits,
          currency: currency
        });
      } else if (props.unit === 'pricePerDistance') {
        result = `${currencySymbol}${props.number.toFixed(props.fractionDigits)} / ${distance}`;
      } else {
        result = props.number.toLocaleString(locale, {
          minimumFractionDigits: props.fractionDigits,
          maximumFractionDigits: props.fractionDigits
        });
      }


      if (props.unit === 'weight') result = `${result} ${weight}`;
      if (props.unit === 'distance') result = `${result} ${distance}`;
      if (props.unit === 'temperature') result = `${result} ${temperature}`;

      return result;
    });

    return {
      formattedValue,
    };
  },
});
