import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full mr-1" }
const _hoisted_2 = { class: "plan-form" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "w-full flex justify-end items-center relative" }
const _hoisted_5 = ["placeholder", "rows", "disabled", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.mandatory)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("textarea", {
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeTheValue && _ctx.changeTheValue(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeTheValue && _ctx.changeTheValue(...args))),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.internalValue) = $event)),
        class: _normalizeClass(["plan-form w-full", [
          _ctx.customHeight,
          {
            'plan-form-error': _ctx.validationResult.length > 0,
            'plan-form-hide-left': _ctx.hideLeft,
            'plan-form-hide-right': _ctx.hideRight,
          },
        ]]),
        placeholder: _ctx.placeholder.length > 0 ? _ctx.placeholder : '',
        rows: _ctx.rows,
        disabled: _ctx.disabled,
        maxlength: _ctx.maxCharacters
      }, null, 42, _hoisted_5), [
        [_vModelText, _ctx.internalValue]
      ]),
      (_ctx.requirements.length > 0 && _ctx.isComplete)
        ? (_openBlock(), _createBlock(_component_plan_icon, {
            key: 0,
            style: {"position":"absolute","margin-right":"8px"},
            size: "15",
            name: "fill-circle-check",
            "is-svg": "",
            "icon-color": "#00DB50"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("span", {
      class: _normalizeClass(["text-xs text-plan-error-200", _ctx.validationResult.length > 0 ? 'text-plan-error-200' : 'text-transparent'])
    }, _toDisplayString(_ctx.validationResult), 3)
  ]))
}