export default {
    getPaymentMethod(state: any) {
      return state.paymentMethod;
    },

    getPaymentInfo(state: any) {
      return state.paymentInfo;
    },

    getSubscriptionType(state: any) {
      return state.subscriptionType;
    },
  };
  