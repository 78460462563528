import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "h-full w-full overflow-y-auto z-50 transition-opacity",
  id: "modal"
}
const _hoisted_2 = { class: "bg-white p-8 rounded-xl w-full md:w-3/5 mx-auto md:mt-16 md:mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarrierHeader = _resolveComponent("CarrierHeader")!
  const _component_NotFound = _resolveComponent("NotFound")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CarrierHeader),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NotFound)
      ])
    ])
  ], 64))
}