import paymentRequest from '../_api';

export default {
  async attachPayment(context: any, params: any) {
    const response = await paymentRequest.postAttachPayment(params);
    return response;
  },
  async clearPaymentStore(context: any) {
    context.commit('clearPaymentStore');
  },
  async customer(context: any) {
    const response = await paymentRequest.getCustomer();
    return response;
  },
  async deleteSubscription(context: any, params: any) {
    const response = await paymentRequest.deleteSubscription(params);
    return response;
  },
  async defaultPaymentMethod(context: any, id: number) {
    const response = await paymentRequest.postDefaultPayment(id);
    return response;
  },
  async paymentMethod(context: any) {
    const response = await paymentRequest.getPaymentMethod();
    context.commit('setPaymentMethod', response);
    return response;
  },
  async paymentInfo(context: any) {
    const response = await paymentRequest.getPaymentInfo();
    context.commit('setPaymentInfo', response);
    return response;
  },
  async portalSession(context: any) {
    const response = await paymentRequest.getPortalSession();
    return response;
  },
  async removeCard(context: any, params: any) {
    const response = await paymentRequest.deleteCard(params);
    return response;
  },
  async session(context: any, price: any) {
    const response = await paymentRequest.getSession(price);
    return response;
  },
  async subscription(context: any, params: any) {
    const response = await paymentRequest.postSubscription(params);
    return response;
  },
  async getSubscription(context: any, params: any) {
    const response = await paymentRequest.getSubcription();
    context.commit('setSubscriptionType', response);
    return response;
  },

  async getInvoice(context: any, params: any) {
    return await paymentRequest.getInvoice(params);
  },
  async subcriptionPrice(context: any) {
    const response = await paymentRequest.getSubcriptionPrice();
    return response;
  },
  async upgradeSubscription(context: any, params: object) {
    const response = await paymentRequest.postUpgradeSubscription(params);
    return response;
  },

};
