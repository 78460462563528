import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "plan-carrier-tracking-element" }
const _hoisted_2 = { class: "plan-carrier-tracking-element-left" }
const _hoisted_3 = {
  key: 0,
  style: {"height":"25px"}
}
const _hoisted_4 = {
  key: 1,
  class: "rounded border plan-border-prim-50 bg-white plan-p-sm"
}
const _hoisted_5 = {
  key: 0,
  class: "plan-t-h5 font-bold"
}
const _hoisted_6 = {
  key: 2,
  class: "flex flex-col"
}
const _hoisted_7 = {
  key: 0,
  class: "plan-t-h5 font-bold"
}
const _hoisted_8 = {
  key: 3,
  style: {"height":"25px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.withLine && _ctx.isNotification)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["plan-carrier-tracking-element-line", {'plan-carrier-tracking-element-line-active': _ctx.isActive || _ctx.isNotification }])
          }, null, 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["plan-carrier-tracking-element-circle", {'plan-carrier-tracking-element-circle-active': _ctx.isActive}])
      }, [
        (_ctx.isActive)
          ? (_openBlock(), _createBlock(_component_plan_icon, {
              key: 0,
              name: "check",
              "icon-color": "white",
              "is-svg": ""
            }))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.withLine)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["plan-carrier-tracking-element-line", {'plan-carrier-tracking-element-line-active': _ctx.isActive}])
          }, null, 2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["plan-carrier-tracking-element-content", {'grow': _ctx.isNotification }])
    }, [
      (_ctx.isNotification)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.isNotification)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.title.length > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "content")
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.title.length > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "content")
          ])),
      (_ctx.withBlankFooter || _ctx.isNotification)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}