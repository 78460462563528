
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import PlanTooltipDark from '@/components/tooltip/PlanTooltipDark.vue';
// Utils
import { locationString, locationObject } from '@/utils/formatLocation';

interface Location {
  googlePlaceId: string;
  lat: number;
  long: number;
  zip: string;
  city: string;
  state: {
    id: number;
  };
}

interface LimitedAccess {
  id: number;
}

interface Stop {
  inside: boolean;
  limitedDefault: boolean;
  limitedAccess: LimitedAccess;
  residential: boolean;
  liftgate: boolean;
  appointment: boolean;
  type: string;
  executionDate: any;
  executionDateField: any;
  location: Location;
  portStop: boolean;
  airportStop: boolean;
  observations: string;
  lumperFeePrice: number | null;
  lumperFee: boolean;
}

interface CommodityMaster {
  weight: number | null;
  commodityName: string;
  minTemp: number | null;
  maxTemp: number | null;
}

interface SpecialRequirement {
  teamDriver: boolean;
  bondedPermission: boolean;
  TWICCard: boolean;
  tsa: boolean;
  hazardousLoad: boolean;
  specialPermissions: boolean;
  specialPermissionsDescription: string;
}

interface TrailerType {
  id: number | null;
}

interface Load {
  observations: string;
  stops: Stop[];
  commodityMaster: CommodityMaster;
  specialRequirement: SpecialRequirement;
  trailerType: TrailerType;
  status: string;
}

export default defineComponent({
  components: {
    DatePickerComponent,
    ErrorMessage,
    Field,
    Form,
    GoogleAutocomplete,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    load: {
      type: Object as () => Load,
    },
  },
  data() {
    return {
      address: '',
      originStop: '',
      poNumber: '',
      destinationStop: '',
      dateFormat: 'MM-dd-yyyy',
    };
  },
  computed: {
    currentDate(): Date {
      return new Date();
    },
    delivery(): Stop | null {
      return this.getStopByType('DELIVERY_FINAL');
    },
    limitedAccessList(): any {
      return this.$store.getters['LoadsStore/getLimitedAcces'];
    },
    locationObject(): any {
      return locationObject;
    },
    locationString(): any {
      return locationString;
    },
    pickup(): Stop | null {
      return this.getStopByType('PICKUP_INITIAL');
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
  },
  methods: {
    checkDate() {
      const dateStr =
        this.pickup && this.pickup.executionDateField !== null
          ? this.pickup.executionDateField
          : '';
      const date = new Date(dateStr);
      if (this.pickup && this.pickup.executionDateField) {
        this.pickup.executionDate = this.formatDateStringReverse(date)
          ? this.formatDateStringReverse(date)
          : '';
      }
    },
    getDestination(addressData) {
      if (addressData.postal_code && addressData.locality) {
        this.address = addressData;
        this.destinationStop = this.locationString(addressData) as any;
        if (this.delivery) {
          const location = this.locationObject(addressData);
          this.delivery.location = location as Location;
          this.delivery.location.googlePlaceId = addressData.place_id;
        }
      } else {
        (this.$refs.destiny as any).clear();
      }
    },
    getOrigin(addressData) {
      if (addressData.postal_code && addressData.locality) {
        this.address = addressData;
        this.originStop = this.locationString(addressData) as any;
        if (this.pickup) {
          const location = this.locationObject(addressData);
          this.pickup.location = location as Location;
          this.pickup.location.googlePlaceId = addressData.place_id;
        }
      } else {
        (this.$refs.origin as any).clear();
      }
    },
    getStopByType(stopType: string): Stop | null {
      if (this.load && this.load.stops && this.load.stops.length > 0) {
        return this.load.stops.find((obj) => obj.type === stopType) || null;
      }
      return null;
    },
    async isValidForm() {
      const validation = await (this.$refs.form as any).validate();
      return validation.valid;
    },
    async submit() {
      if (await this.isValidForm()) {
        this.$emit('update', this.load);
        this.$emit('changeStep', 'step-two');
        this.$emit('setValidStep', 1);
      }
    },
    async submitTab() {
      if (await this.isValidForm()) {
        this.$emit('update', this.load);
        return true;
      }
      return false;
    },
    handleExtraFields(key, event) {
      this.$emit('updateExtraFields', key, event.target.value);
    },
    updateResidential(item) {
      item.limitedDefault = false;
      delete item.limitedAccess;
      // item.limitedAccess = {id:0};
      // this.$emit('update', this.load);
    },
    updateLimitedAccess(item) {
      if (this.pickup?.limitedDefault) {
        (item.limitedAccess = {
          id: null,
        }),
          (item.residential = false);
        this.$emit('update', this.load);
      }
      if (this.delivery?.limitedDefault) {
        (item.limitedAccess = {
          id: null,
        }),
          (item.residential = false);
        this.$emit('update', this.load);
      }
    },
  },
});
