
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import { useStore } from 'vuex';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'PlanCarrierUpdateVehicleModal',
  components: { PlanSelect, PlanInputText, PlanButton, PlanCarrierDefaultModal, PlanTextButton },
  emits: ['selectVehicle'],
  props: {
    vehicle: { type: Object, required: true }
  },
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      loadingPut: false,
      showModal: false,
      selectedTrailer: '',
      licensePlate: '',
      trailerPlate: '',
      emitId: null
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const changeSelectedTrailer = (newTrailerValue: any) => {
      data.selectedTrailer = newTrailerValue
    }

    const getVehicles = async () => {
      await store.dispatch('CompanyStore/vehiclesList', store.getters['UserStore/getCurrentUser'].company.id)
        .then((response) => {
          store.commit('CarrierStore/setVehicles', response)
        }).finally(() => {
          emit('selectVehicle', data.emitId)
          data.showModal = false
          data.loadingPut = false
        })
    }

    const putVehicle = async () => {
      if (!validateVehicle.value) {
        await store
          .dispatch('UserStore/updateToast', {
            text: translate.t('all-fields-required-notification'),
            type: 'error',
            visible: true
          })
        return
      }

      data.loadingPut = true
      await store.dispatch('CompanyStore/putVehicle', {
        id: props.vehicle.id,
        trailerType: { id: data.selectedTrailer },
        licensePlate: data.licensePlate,
        trailerPlate: data.trailerPlate
      }).then((response) => {
        data.emitId = response.id
      }).finally(() => {
        getVehicles()
      })
    }

    const trailerTypes = computed(() => {
      let result: Array<any> = []
      store.getters['LoadsStore/getTrailerTypes'].forEach((trailer: any) => {
        result.push({
          label: trailer.description,
          value: trailer.id.toString()
        })
      })
      return result
    })

    const validateVehicle = computed(() => {
      return data.selectedTrailer !== '' && data.trailerPlate.length > 0 && data.licensePlate.length > 0
    })

    data.selectedTrailer = props.vehicle.trailerType.id.toString()
    data.licensePlate = props.vehicle.licensePlate + ''
    data.trailerPlate = props.vehicle.trailerPlate + ''

    return {
      ...toRefs(data),
      actualWidth,
      changeSelectedTrailer,
      putVehicle,
      trailerTypes,
      validateVehicle
    }
  }
})
