
import { defineComponent, reactive, toRefs } from 'vue';
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import SidebarComponent from '@/components/sidebar/Sidebar.vue';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import UiComponentsTab from '@/components/uiComponents/UiComponentsTab.vue';
import UiPrimitivesTab from '@/components/uiComponents/UiPrimitivesTab.vue';
import UiDefaultsTab from '@/components/uiComponents/UiDefaultsTab.vue';
export default defineComponent({
  name: 'UiComponents',
  components: { UiDefaultsTab, UiPrimitivesTab, UiComponentsTab, PlanTabs, SidebarComponent, PlanDefaultView },
  setup () {
    const data = reactive({
      selectedTab: 0,
      tabs: [
        { label: 'Primitives' },
        { label: 'Defaults' },
        { label: 'Components' }
      ]
    })

    const changeTab = (index: number) => { data.selectedTab = index }

    return {
      ...toRefs(data),
      changeTab
    }
  }
})
