
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import store from '@/services/store';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
import PlanPeriodRangeFilter from '@/components/filters/PlanPeriodRangeFilter.vue';
import SortIcon from '@/components/filters/SortIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
// Resources
import i18n from '@/resources/locales';
// Types
import { PeriodRange } from '@/components/filters/FiltersType';
// Utils
import { formatValoration } from '@/utils/formatString';
import PlanFilterContactTag from '@/components/filters/PlanFilterContactTag.vue';
import PlanContactTags from '@/components/labels/PlanContactTags.vue';

const SortType = {
  LOADS: 'LOADS',
  RATING: 'RATING',
  TENDERS: 'TENDERS',
};

export default defineComponent({
  name: 'RequestQuotingModal',
  components: {
    PlanContactTags,
    PlanFilterContactTag,
    AddCarrierModal,
    PlanPeriodRangeFilter,
    SortIcon,
    PlanButton,
    PlanDefaultModal,
  },
  props: {
    directAssign: { type: Boolean, required: false },
  },
  mixins: [BaseComponent],
  setup(props, { emit }) {
    const route = useRoute();
    const $t = i18n.global.t;
    const allowed = ref(true);
    const carrierName = ref('');
    const historicalRange = ref(PeriodRange.LAST_3_MONTHS);
    const isLoading = ref(true);
    const isLoadingPeriod = ref(false);
    const isSending = ref([] as any);
    const isSortLoads = ref(false);
    const isSortRating = ref(false);
    const isSortTenders = ref(false);
    const modalVisible = ref(false);
    const paginationMaxItems = ref(
      store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 0,
    );
    const projectId = ref(null as any);
    const searchTimeout = ref(null as any);
    let selectedContacts = ref([] as any);
    const showFilters = ref(false);
    let showSpinner = ref(false);
    const sortBy = ref('');
    const sortTypeLoads = ref('');
    const sortTypeRating = ref('');
    const sortTypeTenders = ref('');
    const typeUser = ref('CARRIER|BROKER');
    const useContactPage = ref(0);

    const data = reactive({
      price: null,
      isLoading: false,
      showDirectAssignModal: false,
      carrierToAssign: {} as any,
      showContactsModal: false,
      contactTag: ''
    });

    onBeforeMount(() => {
      store.dispatch('LoadsStore/trailers');
    });

    onMounted(() => {
      findUser('');
      projectId.value = route.params.projectId || currentLane.value.projectId;
    });

    /** Computed */
    const collapsedSidebar = computed(() => {
      return store.getters['UserStore/getCollapsedSidebar'];
    });

    const currentContacts = computed(() => {
      return store.getters['RFPStore/getCarrierQuotetionList'].filter(
        (item) => item.source !== 'ALPINE_CARRIER_API',
      );
    });

    const currentLane = computed(() => {
      return store.getters['RFPStore/getCurrentLane'];
    });

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    const getSelectedContacts = computed(() => {
      let count = 0;
      selectedContacts?.value.map((item) => {
        currentContacts?.value.map((contact) => {
          if (
            contact.company?.id === item.id &&
            !contact.quoteRequested &&
            !contact.quoteEmailSent
          ) {
            count++;
          }
        });
      });
      return count;
    });

    const hasMoreItems = computed(() => {
      if (showSpinner.value) {
        return true;
      }
      return useContactPage.value * maxResults.value <= currentContacts.value.length;
    });

    const maxResults = computed(() => {
      return store.getters['GlobalStore/getGlobalConfig']
        ? parseInt(store.getters['GlobalStore/getGlobalConfig'].PAGINATION_DEFAULT_MAX_RESULTS)
        : 30;
    });

    const numberOfPages = computed(() => {
      return currentContacts.value.length / maxResults.value;
    });

    const userImage = computed(() => {
      return BaseComponent.methods?.getUserDefaultImage;
    });

    /** Methods */
    const addSelectedContact = (_id: number) => {
      const existingIndex = selectedContacts.value.findIndex((contact) => contact.id === _id);

      if (existingIndex !== -1) {
        selectedContacts.value.splice(existingIndex, 1);
      } else {
        selectedContacts.value.push({ id: _id });
      }
    };
    const apiGetCarrierQuotationList = () => {
      store
        .dispatch('RFPStore/getCarrierQuotetionList', {
          id: projectId.value,
          name: '',
          page: useContactPage.value,
          period: historicalRange.value,
          sortBy: sortBy.value,
          type: typeUser.value,
          tag: data.contactTag === '' ? undefined : data.contactTag
        })
        .finally(() => {
          setLoadingPeriod(false);
          allowed.value = true;
        });
    };

    const changeContactTag = (tag: string) => {
      data.contactTag = tag
      apiGetCarrierQuotationList()
    }

    const contactType = (type) => {
      let translation = type;

      if (translation === 'CARRIER') {
        translation = $t('carrier').toUpperCase();
      }
      if (translation === 'BROKER') {
        translation = $t('broker').toUpperCase();
      }

      return translation;
    };

    const findUser = (_newValue: string) => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value);
      }
      searchTimeout.value = setTimeout(() => {
        allowed.value = false;
        showSpinner.value = true;
        useContactPage.value += 1;
        store
          .dispatch('RFPStore/getCarrierQuotetionList', {
            id: projectId.value,
            page: useContactPage.value,
            name: _newValue,
            period: historicalRange.value,
            type: typeUser.value,
          })
          .then(() => {
            if (sourceCarrier().length > 0) {
              if (!isSelected(sourceCarrier()[0].id)) {
                addSelectedContact(sourceCarrier()[0].company.id);
              }
            }
            isLoading.value = false;
          })
          .finally(() => {
            allowed.value = true;
            showSpinner.value = false;
          });
      }, 500);
    };

    const onChangeHistoricalRangeFilter = (value) => {
      historicalRange.value = value;
      setLoadingPeriod(true);
      apiGetCarrierQuotationList();
    };

    const isModalNewContactVisible = (newCarrier) => {
      if (newCarrier) {
        currentContacts?.value.push(newCarrier);
      }
      modalVisible.value = !modalVisible.value;
    };

    const isSelected = (_id: number) => {
      return selectedContacts.value.some((user) => user.id === _id);
    };

    const loadMoreContacts = () => {
      if (!allowed.value) return;
      findUser(carrierName.value);
    };

    const onClickSortLoads = () => {
      resetFilters(SortType.LOADS);
      isSortLoads.value = true;

      if (sortTypeLoads.value === '') {
        sortTypeLoads.value = 'DESC';
        sortBy.value = 'loadsQuantity[DESC]';
      } else if (sortTypeLoads.value === 'DESC') {
        sortTypeLoads.value = 'ASC';
        sortBy.value = 'loadsQuantity[ASC]';
      } else {
        sortTypeLoads.value = '';
        sortBy.value = '';
      }
      apiGetCarrierQuotationList();
    };

    const onClickSortRating = () => {
      resetFilters(SortType.RATING);
      isSortRating.value = true;

      if (sortTypeRating.value === '') {
        sortTypeRating.value = 'DESC';
        sortBy.value = 'rating[DESC]';
      } else if (sortTypeRating.value === 'DESC') {
        sortTypeRating.value = 'ASC';
        sortBy.value = 'rating[ASC]';
      } else {
        sortTypeRating.value = '';
        sortBy.value = '';
      }
      apiGetCarrierQuotationList();
    };

    const onClickSortTenders = () => {
      resetFilters(SortType.TENDERS);
      isSortTenders.value = true;

      if (sortTypeTenders.value === '') {
        sortTypeTenders.value = 'DESC';
        sortBy.value = 'companyName[DESC]';
      } else if (sortTypeTenders.value === 'DESC') {
        sortTypeTenders.value = 'ASC';
        sortBy.value = 'companyName[ASC]';
      } else {
        sortTypeTenders.value = '';
        sortBy.value = '';
      }
      apiGetCarrierQuotationList();
    };

    const requestQuote = () => {
      if (selectedContacts.value.length !== 0) {
        if (currentProject?.value?.project?.quoteRequestTotal > 0) {
          store
            .dispatch('RFPStore/postRequestQuote', {
              id: projectId.value,
              companies: selectedContacts.value,
            })
            .then(() => {
              BaseComponent.methods?.showToastSuccess($t('created-successfully'));
              emit('fetch');
              emit('close');
            });
        } else {
          emit('setQuotationTime', selectedContacts.value);
        }
      }
    };

    const resendQuote = (_id: number, _index: number) => {
      isSending.value[_index] = true;
      store
        .dispatch('RFPStore/postResendQuote', {
          projectId: projectId.value,
          companyId: _id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess($t('toast-re-send-quote'));
          isSending.value[_index] = false;
        });
    };

    const resetFilters = (type) => {
      switch (type) {
        case SortType.LOADS:
          isSortRating.value = false;
          isSortTenders.value = false;
          sortTypeRating.value = '';
          sortTypeTenders.value = '';
          break;
        case SortType.RATING:
          isSortLoads.value = false;
          isSortTenders.value = false;
          sortTypeLoads.value = '';
          sortTypeTenders.value = '';
          break;
        case SortType.TENDERS:
          isSortLoads.value = false;
          isSortRating.value = false;
          sortTypeLoads.value = '';
          sortTypeRating.value = '';
          break;
        default:
          isSortLoads.value = false;
          isSortRating.value = false;
          isSortTenders.value = false;
          sortTypeLoads.value = '';
          sortTypeRating.value = '';
          sortTypeTenders.value = '';
      }
    };

    const searchCompany = () => {
      useContactPage.value = 0;
      if (carrierName.value.length > 2) {
        findUser(carrierName.value);
      } else {
        findUser('');
      }
    };

    const handleShowAssignModal = (carrier) => {
      emit('close', carrier);
      data.carrierToAssign = carrier;
    };

    const selectAll = (isChecked: boolean) => {
      if (isChecked) {
        currentContacts.value.map((item) => {
          if (!isSelected(item.company.id)) {
            addSelectedContact(item.company.id);
          }
        });
      } else if (selectedContacts.value.length) {
        selectedContacts.value = [];
      }
    };

    const setLoadingPeriod = (value) => (isLoadingPeriod.value = value);

    const sourceCarrier = () => {
      let source: any = currentContacts.value.filter((item: any) => item.autoSelected === true);
      return source;
    };

    const updateSearchTypeUser = (_userType: string) => {
      let searchArray = typeUser.value.split('|');
      if (searchArray.includes(_userType)) {
        searchArray = searchArray.filter((el) => el !== _userType);
        typeUser.value = searchArray.join('|');
      } else {
        if (typeUser.value === '') {
          typeUser.value = _userType;
        } else {
          typeUser.value += '|' + _userType;
        }
      }

      store.dispatch('RFPStore/getCarrierQuotetionList', {
        id: projectId.value,
        page: useContactPage.value,
        name: '',
        type: typeUser.value === '' ? 'CARRIER|BROKER' : typeUser.value,
      });
    };

    return {
      ...toRefs(data),
      handleShowAssignModal,
      allowed,
      carrierName,
      changeContactTag,
      collapsedSidebar,
      currentContacts,
      getSelectedContacts,
      hasMoreItems,
      historicalRange,
      isLoading,
      isLoadingPeriod,
      isSending,
      isSortLoads,
      isSortRating,
      isSortTenders,
      maxResults,
      modalVisible,
      numberOfPages,
      paginationMaxItems,
      projectId,
      selectedContacts,
      showFilters,
      showSpinner,
      sortTypeLoads,
      sortTypeRating,
      sortTypeTenders,
      userImage,
      addSelectedContact,
      contactType,
      formatValoration,
      onChangeHistoricalRangeFilter,
      isModalNewContactVisible,
      isSelected,
      loadMoreContacts,
      onClickSortLoads,
      onClickSortRating,
      onClickSortTenders,
      requestQuote,
      searchCompany,
      selectAll,
      resendQuote,
      updateSearchTypeUser,
    };
  },
});
