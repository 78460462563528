import RequestDemo from '@/modules/demo/shipper/RequestDemo.vue';

const DemoRouter = [
  {
    path: '/request-demo',
    name: 'requestDemo',
    component: RequestDemo,
  },
];

export default DemoRouter;
