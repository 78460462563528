
import { defineComponent } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import { SmartTagz } from 'smart-tagz';
// Styles
import 'smart-tagz/dist/smart-tagz.css';
export default defineComponent({
  name: 'ModalEmailConfig',
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XCircleIcon,
    SmartTagz,
  },
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      emailArray: [] as any,
      emailToAdd: '' as any,
      sending: false,
      settingsCopy: null as any,
      timeoutId: 0 as any,
    };
  },
  beforeCreate() {
    this.$store.dispatch('UserStore/getUserEmailNotificationSetup').then(() => {
      if (!this.userEmailNotificationSetup) {
        this.$store.dispatch('UserStore/postUserEmailNotificationSetup').then(() => {
          this.$store.dispatch('UserStore/getUserEmailNotificationSetup');
        });
      }
    });
  },
  async created() {
    await this.$store.dispatch('CompanyStore/getCompany', this.currentUser.company.id);
  },
  computed: {
    areAllEmailsValid(): any {
      return this.emailArray.every((email) => this.isValidEmail(email));
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    currentCompany(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    notificationEmailVariables(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']?.NotificationEmailVariables;
    },
    userEmailNotificationSetup(): any {
      return this.$store.getters['UserStore/getUserEmailNotificationSetup'];
    },
  },
  methods: {
    checkEmail(result) {
      this.emailArray = result;
      this.sendEmails();
    },
    getUserEmailNotificationSetup() {
      this.$store.dispatch('UserStore/getUserEmailNotificationSetup');
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    sendEmails() {
      if (this.areAllEmailsValid) {
        this.$store
          .dispatch('CompanyStore/updateLoadEmails', {
            id: this.currentCompany.id,
            loadNotificationEmails: this.emailArray,
          })
          .then(() => {
            this.$store.dispatch('UserStore/updateToast', {
              text: this.$t('message-updated-successfully'),
              type: 'success',
              visible: true,
            });
            this.$store.dispatch('CompanyStore/getCompany', this.currentUser.company.id);
          });
      } else {
        this.$store.dispatch('UserStore/updateToast', {
          text: this.$t('email-not-valid'),
          type: 'error',
          visible: true,
        });
      }
    },
    toggleEmailConfigModal(isOpen: boolean) {
      this.$store.commit('GlobalStore/setEmailConfigModal', isOpen);
    },
    async toggleSetting() {
      if (this.sending) {
        return;
      }
      // Clear previous timeout if it exists
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // Set a new timeout
      this.timeoutId = setTimeout(() => {
        this.sending = true;
        const id = this.settingsCopy.id;
        let copyObject = { ...this.settingsCopy };
        delete copyObject.id;
        delete copyObject.owner;
        copyObject = Object.fromEntries(
          Object.entries(copyObject).map(([key, value]) => [key.replace(/_/g, ''), value]),
        );
        this.$store
          .dispatch('UserStore/putUserEmailNotificationSetup', {
            id: id,
            body: copyObject,
          })
          .then(() => {
            this.$store.dispatch('UserStore/getUserEmailNotificationSetup');
            this.$store.dispatch('UserStore/updateToast', {
              text: this.$t('updated-correctly'),
              type: 'success',
              visible: true,
            });
          })
          .catch((err) => {
            this.$store.dispatch('UserStore/updateToast', {
              text: err?.response?.data?.title,
              type: 'error',
              visible: true,
            });
          })
          .finally(() => {
            this.sending = false;
          });
      }, 1000);
    },
  },
  watch: {
    userEmailNotificationSetup() {
      if (this.userEmailNotificationSetup) {
        this.settingsCopy = { ...this.userEmailNotificationSetup };
      }
    },
  },
});
