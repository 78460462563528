
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanInputDate from '@/components/forms/PlanInputDate.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PlanUpdateMultipleLanesLoadsFrequency',
  components: { PlanSelectSimple, PlanInputNumber, PlanInputCheckbox },
  emits: ['change'],
  setup(props, { emit }) {
    const translate = useI18n();

    const data = reactive({
      showForm: false,
      frequency: null,
      frequencyType: 'WEEK',
    });

    const change = () => {
      emit('change', {
        isActive: data.showForm,
        frequency: data.frequency,
        frequencyType: data.frequencyType,
      });
    };

    const changeFrequencyType = (newValue: any) => {
      data.frequencyType = newValue;
      change();
    };

    const changeShowForm = (value: boolean) => {
      data.showForm = value;
      change();
    };

    const frequencyItems = computed(() => {
      return [
        { label: translate.t('weekly'), value: 'WEEK' },
        { label: translate.t('monthly'), value: 'MONTH' },
      ];
    });

    return {
      ...toRefs(data),
      change,
      changeFrequencyType,
      changeShowForm,
      frequencyItems,
    };
  },
});
