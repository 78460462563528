
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
// Types
import { RFPStatus } from '@/modules/rfp/RFPTypes';

export default defineComponent({
  name: 'LoadsPlanningDates',
  components: {
    DatePickerComponent,
    Field,
    Form,
    Spin,
  },
  mixins: [BaseComponent],
  props: {
    currentLane: { type: Object },
    currentWeeksPlanning: { type: Array as any },
    currentPeriodTime: { type: String },
    currentPlanningLoads: { type: Number as any, default: 0 },
    loading: { type: Boolean, default: false },
    plannedAmount: { type: Array as any },
    weeklyDates: { type: Object as any },
  },
  data() {
    return {
      dateFormat: 'MM-dd-yyyy',
      isFeatureEnabled: false,
      periodDatesArray: [] as any,
    };
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    collapsedSidebar() {
      return this.$store.getters['UserStore/getCollapsedSidebar'];
    },
    currentPlanning(): any {
      return this.$store.getters['RFPStore/getCurrentQuotesPlanned'];
    },
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    finalDeliveryStop(): any {
      let finalStop = null;
      if (this.currentLane?.loadTemplate?.stops) {
        this.currentLane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'DELIVERY_FINAL') {
            finalStop = stop;
          }
        });
      }
      return finalStop;
    },
    initialPickUpStop(): any {
      let initialStop = null;
      if (this.currentLane?.loadTemplate?.stops) {
        this.currentLane.loadTemplate.stops.forEach((stop: any) => {
          if (stop.type === 'PICKUP_INITIAL') {
            initialStop = stop;
          }
        });
      }
      return initialStop;
    },
    isStatusDraft(): any {
      return this.currentProject?.project?.status === RFPStatus.DRAFT;
    },
    minDate(): any {
      const currentDate = new Date();
      const initialPickUpDate = new Date(this.initialPickUpStop.executionDate);
      return initialPickUpDate < currentDate
        ? currentDate.toISOString().split('T')[0]
        : this.initialPickUpStop.executionDate;
    },
    orderedStops(): any {
      let array = this.currentLane?.loadTemplate?.stops;
      return array.sort((a, b) => a.sequence - b.sequence);
    },
  },
  methods: {
    calculateMinDate(start, end, stops, x) {
      if (x === 0) {
        const currentDate = new Date();
        const startDate = new Date(start);
        const endDate = new Date(end);
        if (currentDate >= startDate && currentDate <= endDate) {
          return currentDate.toISOString().split('T')[0];
        }
      } else {
        return stops[x - 1]?.executionDate ? stops[x - 1]?.executionDate : this.getDayAfter(start);
      }
    },
    disableDatepickerIfPreviesWeekIsEmpty(index, stops) {
      if (index > 0) {
        return !stops[index - 1].executionDate;
      }
      return false;
    },
    addNewItem(week) {
      this.$emit('add-new-item', week);
    },
    areAllItemsEmpty(arr) {
      return arr.every((subArray) => subArray.every((item) => item.length === 0));
    },
    handleAssigment(item) {
      if (this.currentProject?.project?.quoteRequestTotal > 0) {
        if (item.autoAssignment) {
          item.autoAssignment = !item.autoAssignment;
          this.$emit('toggleDirectAssign', item);
        } else {
          item.autoAssignment = !item.autoAssignment;
        }
      } else {
        this.$emit('toggleDirectAssign', item);
      }
    },
    removeItem(position, week) {
      this.$emit('removeItem', position, week);
    },
    showAutoAssigment(indexWeek, indexLoad) {
      const poNumber = this.currentWeeksPlanning[indexWeek][indexLoad]?.po;
      const someDate = this.currentWeeksPlanning[indexWeek][indexLoad]?.stops.some(
        (stop) => stop.executionDate,
      );

      return poNumber || someDate;
    },
    showAutoAssigmentTitle(indexWeek) {
      const poNumber = this.currentWeeksPlanning[indexWeek].some((load) => load.po);
      const someDate = this.currentWeeksPlanning[indexWeek].some((load) =>
        load.stops?.some((stop) => stop.executionDate),
      );
      return poNumber || someDate;
    },
    async submit() {
      const allExecutionDatesEmpty = this.currentWeeksPlanning.every((week) => {
        return week.every((stop) => {
          return stop.stops.every((stopItem) => {
            return stopItem.executionDate === '';
          });
        });
      });

      if (allExecutionDatesEmpty) {
        BaseComponent.methods?.showToastError(this.$t('fill-dates'));
      } else {
        this.$emit('send');
      }
    },
  },
});
