
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import i18n from '@/resources/locales';
import * as Routing from '@/router/Routing';

import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { formatPriceDecimals } from '@/utils/formatString';

export default defineComponent({
  name: 'ChangePlanModal',
  components: { PlanDefaultModal, PlanButton },
  emits: ['close'],
  props: {
    showModal: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();
    const $t = i18n.global.t;

    const accept = () => {
      Routing.openPlans();
      emit('close', false);
    };

    const close = () => {
      if (planType.value === 'BASIC_PLUS' || planType.value === 'PREMIUM') {
        store.dispatch('CompanyStore/acceptFreeBolNotification').then(() => {
          store.dispatch('UserStore/currentUser').then(() => {
            emit('close', false);
          });
        });
        return;
      }
      emit('close', false);
    };

    const BOLCost = computed(() => {
      return currentUser.value.company?.bolCost ?? 2;
    });

    const currentUser = computed(() => {
      return store.getters['UserStore/getCurrentUser'];
    });

    const planType = computed(() => {
      return currentUser.value?.company?.accessPlanType;
    });

    const planTypeTraslated = computed(() => {
      return $t(currentUser.value?.company?.accessPlanType?.toLowerCase());
    });

    const nLoads = computed(() => {
      return currentUser.value.company?.freeBolAssigned ?? 25;
    });

    return {
      accept,
      BOLCost,
      close,
      formatPriceDecimals,
      nLoads,
      planType,
      planTypeTraslated,
    };
  },
});
