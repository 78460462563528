export default {
  getCredit(state: any) {
    return state.credit;
  },
  getCreditBreakdown(state: any) {
    return state.creditBreakdown;
  },
  getGrantedCreditModal(state: any) {
    return state.grantedCreditModal;
  },
  getGrantedCreditInfo(state: any) {
    return state.grantedCreditInfo;
  },
  getNoCreditModal(state: any) {
    return state.noCreditModal;
  },
  getPlanimatikCreditModal(state: any) {
    return state.planimatikCreditModal;
  },
};
