import country from '../_api';

export default {
  async clearCountryStore(context: any) {
    context.commit('clearCountryStore');
  },
  async getCountries(context: any, data: any) {
    const response: any = await country.getCountriesList(data);
    context.commit('setCountries', response.data);
    return response
  },
  async getCountryById(context: any, data: any) {
    const response: any = await country.getCountryById(data);
    context.commit('setCountry', response.data);

    const countries = context.rootGetters['CountryStore/getCountries'];
    const userCountrySelected = countries.find((country: any) => country.id === response.data.id);
    context.commit('setCurrentCountry', userCountrySelected);
  },
};
