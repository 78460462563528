import company from '../_api';

export default {
  async acceptFreeBolNotification(context: any) {
    return await company.acceptFreeBolNotification();
  },
  async apiPutCompanyCreditGranted(context: any, data: any) {
    return await company.putCompanyCreditGranted(data);
  },
  async assignAdminAgents(context: any, data: any) {
    return await company.assignAdminAgents(data);
  },
  async clearCompanyStore(context: any) {
    context.commit('clearCompanyStore');
  },
  async deassignAdminAgents(context: any, data: any) {
    return await company.deassignAdminAgents(data);
  },
  async deleteVehicles(context: any, data: any) {
    await company.deleteVehicles(data);
  },
  async deleteCompany(context: any, data: any) {
    await company.deleteCompany(data);
  },
  async deleteEmailTemplate(context: any, id: any) {
    await company.deleteEmailTemplate(id);
  },
  async deleteDriver(context: any, data: any) {
    await company.deleteDriver(data);
  },
  async deleteOnNetwork(context: any, data: any) {
    await company.deleteOnNetwork(data);
  },

  async postBlastEmail(context: any, data: any) {
    await company.postBlastEmail(data);
  },

  async driversList(context: any, companyId) {
    const response = await company.getDriversList(companyId);
    context.commit('setDriversList', response);
    return response
  },
  async getDownloadFileDriver(context: any, data: any) {
    return await company.getDownloadFileDriver(data);
  },
  async getDownloadThumbnailBigDriver(context: any, data: any) {
    return await company.getDownloadThumbnailBigDriver(data);
  },
  async getAdminAgents(context: any, data: any) {
    const response = await company.getAdminAgents(data);
    context.commit('setAdminAgents', response);
  },
  async getCompany(context: any, data: any) {
    const response = await company.getCompany(data);
    context.commit('setCompany', response);
    return response;
  },

  async getCompanyById(context: any, data: any) {
    const response = await company.getCompany(data);
    context.commit('setCompanyById', response);
    return response;
  },
  async getCompanyAdmin(context: any, data: any) {
    const response = await company.getCompanyAdmin(data);
    context.commit('setCompany', response);
    return response;
  },
  async getCompanies(context: any, data: any) {
    const response = await company.getCompanies(data);
    context.commit('setCompaniesList', { response: response, params: data });
    return response;
  },
  async getEmailTemplate (context: any, id: any) {
    const response = await company.getEmailTemplate(id)
    return response
  },
  async getEmailTemplates (context: any) {
    const response = await company.getEmailTemplates()
    return response
  },
  async getShippers(context: any, data: any) {
    return await company.getShippers(data);
  },
  async getCompaniesTotals(context: any, data: any) {
    const response = await company.getCompaniesTotals(data);
    context.commit('setCompaniesTotals', response);
    return response;
  },
  async postVehicle(context: any, data: any) {
    return await company.postVehicle(data);
  },
  async postMediaObjectCompany(context: any, data: any) {
    return await company.postMediaObjectCompany(data);
  },
  async postUpdateMediaObjectCompany(context: any, data: any) {
    return await company.postUpdateMediaObjectCompany(data);
  },
  async postAdminAgents(context: any, data: any) {
    return await company.postAdminAgents(data);
  },
  async postDriver(context: any, data: any) {
    return await company.postDriver(data);
  },
  async postEmailTemplate(context: any, data: any) {
    return await company.postEmailTemplate(data);
  },
  async postMediaObjectDriver(context: any, data: any) {
    return await company.postMediaObjectDriver(data);
  },
  async postUpdateMediaObjectDriver(context: any, data: any) {
    return await company.postUpdateMediaObjectDriver(data);
  },
  async putDriver(context: any, data: any) {
    return await company.putDriver(data);
  },
  async putEmailTemplate(context: any, data: any) {
    return await company.putEmailTemplate(data.id, data.category, data.templateName, data.subject, data.message);
  },
  async putAccountManager(context: any, data: any) {
    await company.putAccountManager(data);
  },
  async putCompany(context: any, data: any) {
    await company.putCompany(data);
  },
  async putCompanyUpdateType(context: any, data: any) {
    return await company.putCompanyUpdateType(data);
  },
  async putCompanyCountry(context: any, data: any) {
    return await company.putCompanyCountry(data);
  },
  async putVehicle(context: any, data: any) {
    return await company.putVehicle(data);
  },
  async putAdminAgents(context: any, data: any) {
    return await company.putAdminAgents(data);
  },
  async putAcquisitionSource(context: any, data: any) {
    return await company.putAcquisitionSource(data);
  },
  async updateLoadEmails(context: any, data: any) {
    await company.updateLoadEmails(data);
  },
  async putTrialEndDate(context: any, data: any) {
    return await company.putTrialEndDate(data);
  },
  async vehiclesList(context: any, id: any) {
    const response = await company.getVehiclesList(id);
    context.commit('setVehiclesList', response);
    return response
  },
};
