
import { defineComponent, computed, ref, watch, onMounted } from 'vue';

export default defineComponent({
  name: 'PlanBadge',
  props: {
    quantity: { type: Number, required: false, default: 0 },
    content: { type: String, required: false, default: '' },
    type: { type: String, required: false, default: 'gray-400' },
    duration: { type: Number, required: false, default: 500 },
  },
  setup(props) {
    const currentQuantity = ref(0);

    const badgeClass = computed(() => {
      const availableTypes = [
        'gray-800',
        'primary',
        'primary-dark',
        'primary-200',
        'primary-100',
        'success',
        'warning',
        'error',
      ];
      if (availableTypes.includes(props.type)) {
        return 'plan-badge-' + props.type;
      } else {
        return 'plan-badge-gray-400';
      }
    });

    const formattedQuantity = computed(() => {
      return currentQuantity.value.toString().padStart(2, '0');
    });

    watch(
      () => props.quantity,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          startIncrementAnimation(newValue);
        }
      },
    );

    const startIncrementAnimation = (newValue) => {
      currentQuantity.value = 0;
      if (newValue > 0) {
        const incrementAmount = newValue / (props.duration / 100);
        let currentAmount = 0;

        const incrementInterval = setInterval(() => {
          currentAmount += incrementAmount;
          currentQuantity.value = Math.round(currentAmount);

          if (currentQuantity.value >= newValue) {
            clearInterval(incrementInterval);
            currentQuantity.value = newValue;
          }
        }, 100);
      }
    };

    onMounted(() => {
      startIncrementAnimation(props.quantity);
    });

    return {
      badgeClass,
      formattedQuantity,
    };
  },
});
