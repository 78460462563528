
import { defineComponent, reactive, ref, toRefs } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import store from '@/services/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ImportWarehousesFromExcelModal',
  components: { PlanButton, PlanDefaultModal },
  emits: ['close', 'warehouses'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      loadingTemplate: false,
      loadingWarehouses: false
    })

    const selectedFile = ref('') as any;

    const close = () => { emit('close'); }

    const getTemplate = async () => {
      data.loadingTemplate = true
      let blob = await store.dispatch('LoadsStore/downloadExcelTemplateImportWarehouses');
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = translate.t('warehouses') + '.xlsx';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      data.loadingTemplate = false
      await store.dispatch('UserStore/showToast', translate.t('success-download-excel-warehouses'))
    }

    const handleFileChange = (event: any) => {
      selectedFile.value = event.target.files[0];
      postWarehousesFromExcel();
    }

    const onUploadFile = () => {
      const inputFile = document.getElementById('input-file-warehouses')
      if (inputFile) inputFile.click()
    }

    const postWarehousesFromExcel = () => {
      data.loadingWarehouses = true
      const formData = new FormData();
      formData.append('file', selectedFile.value);

      store.dispatch('LoadsStore/postWarehousesFromExcel', formData)
        .then((response) => {
          data.loadingWarehouses = false
          selectedFile.value = ''
          emit('warehouses', response)
        })
    }

    return {
      ...toRefs(data),
      close,
      getTemplate,
      handleFileChange,
      onUploadFile
    }
  }
})
