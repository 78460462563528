import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  dataDemoRequest: [],
  dataDemoRequestForm: null,
  dataDemoRequestTotals: [],
  demoRequestsTotals: 0,
  isWelcomeOpen: false,
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
