import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-start items-start plan-gap-sm" }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_3 = { class: "text-4xl font-bold" }
const _hoisted_4 = { class: "w-full flex justify-between items-center plan-gap-xl" }
const _hoisted_5 = { class: "w-full flex flex-col justify-start items-center plan-gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_tabs = _resolveComponent("plan-tabs")!
  const _component_shipper_load_detail_content = _resolveComponent("shipper-load-detail-content")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!
  const _component_plan_default_confirmation_modal = _resolveComponent("plan-default-confirmation-modal")!
  const _component_plan_default_delete_modal = _resolveComponent("plan-default-delete-modal")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_load_card = _resolveComponent("load-card")!
  const _component_plan_default_view = _resolveComponent("plan-default-view")!

  return (_openBlock(), _createBlock(_component_plan_default_view, null, {
    "header-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_text_button, {
          onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
          icon: "chevron-left",
          label: _ctx.$t('back')
        }, null, 8, ["label"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_plan_button_icon, {
            onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToDay('previous'))),
            icon: "chevron-left"
          }),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.dateToShow), 1),
          _createVNode(_component_plan_button_icon, {
            onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToDay('next'))),
            icon: "chevron-right"
          })
        ])
      ])
    ]),
    "header-sub-sticky": _withCtx(() => [
      (_openBlock(), _createBlock(_component_plan_tabs, {
        align: "center",
        tabs: _ctx.totalTabs,
        startTab: _ctx.selectedTotalTab.index,
        key: _ctx.totalTabs,
        onChangeTab: _ctx.changeTab,
        class: "plan-pt-md"
      }, null, 8, ["tabs", "startTab", "onChangeTab"]))
    ]),
    "view-content": _withCtx(() => [
      (_ctx.showModalUpdate !== -1 || _ctx.loadId !== '')
        ? (_openBlock(), _createBlock(_component_shipper_load_detail_content, {
            key: 0,
            onHideModal: _cache[3] || (_cache[3] = ($event: any) => {
          _ctx.showModalUpdate = -1;
          _ctx.loadId = '';
        }),
            isEditing: _ctx.isEditable,
            filters: {},
            "load-id": _ctx.loadId,
            "new-planning": true
          }, null, 8, ["isEditing", "load-id"]))
        : _createCommentVNode("", true),
      (_ctx.showModalTemplate !== -1)
        ? (_openBlock(), _createBlock(_component_plan_default_modal, {
            key: 1,
            onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showModalTemplate = -1)),
            title: _ctx.$t('create-template'),
            size: "xs-auto"
          }, {
            content: _withCtx(() => [
              _createVNode(_component_plan_input_text, {
                "model-value": _ctx.templateName,
                "onUpdate:model-value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.templateName) = $event)),
                label: "Name",
                placeholder: "Enter the template name"
              }, null, 8, ["model-value"])
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_plan_button, {
                  onOnClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showModalTemplate = -1)),
                  disabled: _ctx.loadingTemplate,
                  type: "cancel",
                  label: "Cancel",
                  class: "w-full"
                }, null, 8, ["disabled"]),
                _createVNode(_component_plan_button, {
                  onOnClick: _ctx.createTemplate,
                  loading: _ctx.loadingTemplate,
                  type: "primary",
                  label: "Create",
                  class: "w-full"
                }, null, 8, ["onOnClick", "loading"])
              ])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      (_ctx.showModalCancel !== -1)
        ? (_openBlock(), _createBlock(_component_plan_default_confirmation_modal, {
            key: 2,
            onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showModalCancel = -1)),
            onAccept: _ctx.cancelLoad,
            title: _ctx.$t('cancel-load'),
            message: _ctx.$t('are-you-sure-message'),
            loading: _ctx.loadingCancel
          }, null, 8, ["onAccept", "title", "message", "loading"]))
        : _createCommentVNode("", true),
      (_ctx.showModalDelete !== -1)
        ? (_openBlock(), _createBlock(_component_plan_default_delete_modal, {
            key: 3,
            onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showModalDelete = -1)),
            onDelete: _ctx.deleteLoad,
            title: "Delete Load",
            message: _ctx.$t('are-you-sure-message'),
            loading: _ctx.loadingDelete,
            "hide-icon": ""
          }, null, 8, ["onDelete", "message", "loading"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.loadingLoads)
          ? (_openBlock(), _createBlock(_component_plan_loading, {
              key: 0,
              class: "plan-mt-lg",
              message: _ctx.$t('getting-your-loads'),
              "sub-message": _ctx.$t('loading-ltl-loading')
            }, null, 8, ["message", "sub-message"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loads, (load) => {
          return (_openBlock(), _createBlock(_component_load_card, {
            key: load,
            onOnClick: ($event: any) => (_ctx.showLoadDetails(load.load.id)),
            onOpenDetail: ($event: any) => (_ctx.showLoadDetails(load.load.id)),
            onOnOptionsClick: _ctx.onOptionsClick,
            load: load.load,
            chats: load.chats,
            state: load.load.status
          }, null, 8, ["onOnClick", "onOpenDetail", "onOnOptionsClick", "load", "chats", "state"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}