import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "plan-filter-wrapper" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { style: {"--popper-theme-z-index":"9200"} }
const _hoisted_4 = { class: "plan-filter-items-title" }
const _hoisted_5 = { class: "font-semibold text-sm" }
const _hoisted_6 = {
  key: 1,
  class: "text-xs"
}
const _hoisted_7 = { class: "plan-filter-items" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "plan-filter-info"
}
const _hoisted_10 = {
  key: 1,
  class: "plan-filter-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_popper, {
      "onOpen:popper": _ctx.onOpenPopper,
      arrow: "",
      show: _ctx.showPopper,
      offsetDistance: "0",
      style: {"--popper-theme-padding":"0px"}
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.title), 1),
            (_ctx.loadingItems)
              ? (_openBlock(), _createBlock(_component_plan_icon, {
                  key: 0,
                  name: "refresh-cw",
                  "is-svg": "",
                  "icon-color": "#757D84",
                  size: "10",
                  rotate: ""
                }))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.totalResults) + " " + _toDisplayString(_ctx.$t('results')), 1))
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                onClick: ($event: any) => (_ctx.selItem(item.value, item.load_filter_param)),
                key: index,
                class: "plan-filter-item"
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass({ 'plan-filter-item-active': item.value === _ctx.selectedItem.value })
                }, _toDisplayString(item.value), 3),
                (item.value === _ctx.selectedItem.value && _ctx.selectedItem.value !== '')
                  ? (_openBlock(), _createBlock(_component_plan_icon, {
                      key: 0,
                      name: "check",
                      "is-svg": "",
                      "icon-color": "#151830"
                    }))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_8))
            }), 128))
          ]),
          (_ctx.loadingItems && _ctx.items.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('default-loading')), 1))
            : _createCommentVNode("", true),
          (!_ctx.loadingItems && _ctx.items.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('default-no-results')), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["plan-filter", { 'plan-filter-focus': _ctx.isInputActive, 'plan-filter-active': _ctx.inputValue.length > 0 }])
        }, [
          _createVNode(_component_plan_icon, {
            name: _ctx.icon,
            "is-svg": "",
            "icon-color": _ctx.iconColor,
            size: "16"
          }, null, 8, ["name", "icon-color"]),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInputFocus && _ctx.handleInputFocus(...args))),
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleInputBlur && _ctx.handleInputBlur(...args))),
            onKeyup: [
              _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.getLoadFieldAutocomplete && _ctx.getLoadFieldAutocomplete(...args)), ["enter"])),
              _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.deleteOnKeUp && _ctx.deleteOnKeUp(...args)), ["delete"])),
              _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
            ],
            style: _normalizeStyle(_ctx.inputStyle),
            type: "text",
            placeholder: _ctx.placeholder
          }, null, 44, _hoisted_2), [
            [_vModelText, _ctx.inputValue]
          ]),
          (_ctx.inputValue !== '')
            ? (_openBlock(), _createBlock(_component_plan_button_icon, {
                key: 0,
                onOnClick: _ctx.cleanItem,
                icon: "close",
                size: "16",
                type: "transparent-gray-800",
                style: {"padding":"0.1rem"}
              }, null, 8, ["onOnClick"]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["onOpen:popper", "show"])
  ]))
}