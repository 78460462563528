
import { defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';

export default defineComponent({
  name: 'LoadFilterByIdPo',
  components: { PlanHeaderSearch },
  emits: ['applyFilters'],
  props: {
    bgColor: { type: String, required: false, default: '' },
  },
  setup(props, { emit }) {
    const inputValue = ref('');
    const store = useStore();
    const changeInputStatus = (newValue: string) => {

      inputValue.value = newValue;
      const queryValue = {
        id: 'LOAD_BY_ID_PO',
        value: inputValue.value,
        loadFilterParam: inputValue.value,
      };



      store.commit('LoadsStore/applyFilter', queryValue);
      emit('applyFilters');
    };

    const deleteOnKeyUp = () => {
      if (inputValue.value === '') {
        const queryValue = {
          id: 'LOAD_BY_ID_PO',
          value: inputValue.value,
          loadFilterParam: inputValue.value,
        };
        store.commit('LoadsStore/applyFilter', queryValue);
        emit('applyFilters');
      }
    };

    if (store.getters['LoadsStore/getAppliedFilters']['LOAD_BY_ID_PO']) {
      inputValue.value = store.getters['LoadsStore/getAppliedFilters']['LOAD_BY_ID_PO'].value;
    }

    watch(
      () => store.getters['LoadsStore/getAppliedFilters'],
      (newFilters, oldFilters) => {

        if (Object.keys(newFilters).length === 0) {


          inputValue.value = '';
        }
      },
    );

    return {
      inputValue,
      changeInputStatus,
      deleteOnKeyUp,
    };
  },
});
