
import { defineComponent, ref } from 'vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Modules
import NotifyIncidence from '@/modules/carrier/modals/NotifyIncidence.vue';
import UploadBill from '@/modules/carrier/upload/UploadBill.vue';

export default defineComponent({
  name: 'SmallModalSuccess',
  components: {
    NotifyIncidence,
    UploadBill,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  props: {
    type: {
      type: String,
    },
    loadSelected: {
      type: Object,
    },
  },
  data() {
    return {
      deleteButtonClass: 'hidden',
      file: null as any,
      incidence: '',
      files: [] as any,
      incidenceFile: null as any,
      showUploadModal: false,
      allowed: true,
      isUploadingDoc: false,
    };
  },
  computed: {
    consigneeFilledValue(): any {
      if (
        this.shipment &&
        this.shipment.loads &&
        this.shipment.loads[0] &&
        typeof this.shipment.loads[0].isConsigneeFilled !== 'undefined'
      ) {
        return this.shipment.loads[0].isConsigneeFilled;
      } else {
        return null;
      }
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
  },
  methods: {
    removeMultiUpload() {
      this.files = [];
      this.deleteButtonClass = 'hidden';
      (this.$refs.multiUploadInput as any).value = '';
    },
    showFileInput() {
      (this.$refs.multiUploadInput as any).click();
    },
    showIncidenceInput() {
      (this.$refs.incidenceInput as any).click();
    },
    pushToHomeCarrier() {
      this.$emit('completed');
      this.$emit('close');
    },
    notifyIncidence() {
      if (!this.allowed) return;

      if (this.incidence !== '') {
        this.allowed = false;
        this.$store
          .dispatch('UserStore/postIncidence', {
            description: this.incidence,
            stop: this.loadSelected,
          })
          .then((response) => {
            if (this.files.length > 0) {
              let promises = [] as any;
              this.files.forEach((file) => {
                promises.push(
                  this.$store.dispatch('UserStore/postIncidenceFile', {
                    entityId: response.id,
                    file: file,
                  }),
                );
              });
              Promise.all(promises)
                .then(() => {
                  BaseComponent.methods?.showToastSuccess(this.$t('incidence-correctly-sent'));
                  this.files = [];
                })
                .catch((err) => {
                  console.log(err);
                  BaseComponent.methods?.showToastError(err?.response?.data?.detail);
                })
                .finally(() => {
                  this.allowed = true;
                });
            } else {
              BaseComponent.methods?.showToastSuccess(this.$t('incidence-correctly-sent'));
              this.files = [];
            }
          })
          .finally(() => {
            this.allowed = true;
          });
      } else {
        BaseComponent.methods?.showToastError(this.$t('information-required'));
      }
    },
    async uploadDocument() {
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid || this.isUploadingDoc) {
        return;
      }
      if (this.file) {
        if (this.showUploadModal) {
          this.notifyIncidence();
        }

        if (this.showUploadModal && this.incidence === '') {
          return;
        }
        this.isUploadingDoc = true;

        this.$store
          .dispatch('ShipmentStore/postMediaObjectStops', {
            entityId: this.loadSelected?.id,
            file: this.file,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('documents-uploaded-done'));
            const id = this.$route.params.id;
            this.$store.dispatch('ShipmentStore/shipment', { id: id });
            this.$emit('close');
          })
          .finally(() => {
            this.isUploadingDoc = false;
          });
      }
    },
    uploadFiles(type) {
      if (type === 'incidence') {
        this.incidenceFile = (this.$refs.incidenceInput as any).files[0];
        if (this.incidenceFile.type.trim() === 'application/pdf') {
          BaseComponent.methods?.showToastError(this.$t('message-cant-upload-pdf'));
          this.incidenceFile = null;
        } else {
          this.files.push(this.incidenceFile);
        }
      } else {
        this.file = (this.$refs.multiUploadInput as any).files[0];
      }
    },
    addMore() {
      this.incidenceFile = null;
    },
    updateIncidence(event) {
      this.incidence = event.target.value;
    },
    completeRC() {
      this.$emit('not');
    },
    sendEmail() {
      this.$store
        .dispatch('LoadsStore/postEmailForRate', {
          shipment: this.$route.params.id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('notification-rate-confirmation'));
          this.$emit('close');
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.log('');
        });
    },
  },
});
