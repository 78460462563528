import globalAxios from 'axios';

const getLoadInfoForCarrier = async (id: number) => {
  const response = await globalAxios.get(`loads/${id}/loadInfoForCarrier`)
  return response.data
}

export default {
  getLoadInfoForCarrier
}
