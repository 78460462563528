import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white min-h-screen h-auto flex-1 xl:ml-90" }
const _hoisted_2 = { class: "flex items-center justify-center mt-10 md:mt-[5vh] lg:mt-[10vh] bg-white" }
const _hoisted_3 = {
  key: 0,
  class: "max-w-[30rem] p-5"
}
const _hoisted_4 = { class: "mt-5" }
const _hoisted_5 = { class: "flex flex-col gap-2 items-start" }
const _hoisted_6 = { class: "flex mt-9" }
const _hoisted_7 = { class: "text-zinc-800 text-base font-extrabold font-['Nunito'] tracking-tight" }
const _hoisted_8 = { class: "w-[387px] text-zinc-800 text-[15px] font-normal font-['Nunito'] tracking-tight" }
const _hoisted_9 = { class: "flex flex-col mb-8" }
const _hoisted_10 = { class: "flex flex-row items-center" }
const _hoisted_11 = {
  for: "createFromInterface",
  class: "text-zinc-800 text-[15px] font-normal font-['Nunito'] tracking-tight ml-2"
}
const _hoisted_12 = { class: "flex flex-row items-center mt-1" }
const _hoisted_13 = {
  for: "createFromExel",
  class: "text-zinc-800 text-[15px] font-normal font-['Nunito'] tracking-tight ml-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderLaneDetails = _resolveComponent("HeaderLaneDetails")!
  const _component_FileContainer = _resolveComponent("FileContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderLaneDetails),
    _createElementVNode("section", _hoisted_2, [
      (!_ctx.ShowImportFile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('add-your-lanes')), 1)
                ]),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('choose-method')), 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _withDirectives(_createElementVNode("input", {
                      class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:border-gray-600",
                      id: "createFromInterface",
                      type: "radio",
                      value: "createFromInterface",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentType) = $event))
                    }, null, 512), [
                      [_vModelRadio, _ctx.currentType]
                    ]),
                    _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('creates-lines-interface')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _withDirectives(_createElementVNode("input", {
                      class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2",
                      id: "createFromExel",
                      type: "radio",
                      value: "createFromExel",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentType) = $event))
                    }, null, 512), [
                      [_vModelRadio, _ctx.currentType]
                    ]),
                    _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('import-lanes')), 1)
                  ])
                ]),
                _createElementVNode("a", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextStep && _ctx.nextStep(...args))),
                  class: "w-[202px] w-fit bg-blue-600 text-white text-base text-center font-medium font-['Roboto'] rounded-md py-2 px-6 cursor-pointer tracking-tight hover:opacity-70"
                }, _toDisplayString(_ctx.$t('continue')), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.ShowImportFile)
        ? (_openBlock(), _createBlock(_component_FileContainer, {
            key: 1,
            onHideImportFile: _ctx.hideImportFile
          }, null, 8, ["onHideImportFile"]))
        : _createCommentVNode("", true)
    ])
  ]))
}