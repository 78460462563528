
import { computed, defineComponent, onMounted, ref } from 'vue';
import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
import { useRoute } from 'vue-router';
// Base
import BaseComponent from '@/base/BaseComponent';
// Resources
import i18n from '@/resources/locales';
// Services
import store from '@/services/store';

export default defineComponent({
  name: 'SetMaxResponseTimeModal',
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  mixins: [BaseComponent],
  props: {
    carrierList: {
      type: Array as any,
    },
    isFirstTimeConfirming: Boolean,
    bidId: Number,
  },
  setup(props, { emit }) {
    const $t = i18n.global.t;
    const bidId = ref(props.bidId);
    const date = ref(null as any);
    const dateFormat = ref('MM-dd-yyyy');
    const days = ref(null as any);
    const form = ref(null);
    let hours = ref(0);
    let minutes = ref(30);
    const projectId = ref(null as any);
    const route = useRoute();
    const sendingAction = ref(false);
    const setQuotationTime = ref(false);

    const currentProject = computed(() => {
      return store.getters['RFPStore/getCurrentProject'];
    });

    onMounted(() => {
      projectId.value = route.params.projectId;
      let expirationTime = currentProject.value.project.assignConfirmationExpirationMinutes;
      if (expirationTime) {
        hours.value = Math.floor(expirationTime / 60);
        minutes.value = expirationTime % 60;
      }

      if (currentProject.value.project.quotationEndDate) {
        days.value = handleQuotationEndDate();
        dateDifference('day');
      }
    });

    const currentLane = computed(() => {
      return store.getters['RFPStore/getCurrentLane'];
    });

    const minutesValid = async () => {
      const validation = await (form as any).value.validate();
      return validation.valid;
    };

    const updateMaxResponseTimeFirstTime = async () => {
      if (await minutesValid()) {
        await updateQuotationDateEnd();
        sendingAction.value = true;
        store
          .dispatch('RFPStore/postRequestQuoteConfirmation', {
            quoteId: bidId.value,
          })
          .then(() => {
            store.dispatch('UserStore/updateToast', {
              text: $t('ranking-confirm'),
              type: 'success',
              visible: true,
            });
            store.dispatch('RFPStore/getRankingList', {
              id: currentLane.value.id,
            });

            updateQuotationDateEnd();
          });
      }
    };

    const updateQuotationDateEnd = async () => {
      if (await minutesValid()) {
        sendingAction.value = true;

        if (currentProject.value.project.quoteRequestTotal > 0) {
          store
            .dispatch('RFPStore/updateQuotationDateEnd', {
              id: currentProject.value.project.id,
              body: {
                assignConfirmationExpirationMinutes:
                  Number(hours.value * 60) + Number(minutes.value),
              },
            })
            .then(() => {
              BaseComponent.methods?.showToastSuccess($t('created-successfully'));
              setQuotationTime.value = true;
              emit('fetch');
              emit('close');
            });
        }
      }
    };

    const dateDifference = (_type: string) => {
      const currentDate = new Date();
      if (_type === 'day') {
        if (days.value) {
          date.value = new Date(currentDate.setDate(currentDate.getDate() + days.value));
        }
      } else {
        if (date.value) {
          const timeDiff = date.value.getTime() - currentDate.getTime();
          days.value = Math.ceil(timeDiff / (1000 * 3600 * 24));
        }
      }
    };

    const handleQuotationEndDate = () => {
      const quotationDate = new Date(currentProject.value.project.quotationEndDate);
      const currentDate = new Date();
      const timeDifference = quotationDate.getTime() - currentDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return daysDifference + 1;
    };

    return {
      currentProject,
      days,
      date,
      dateDifference,
      dateFormat,
      form,
      hours,
      minutes,
      sendingAction,
      setQuotationTime,
      updateQuotationDateEnd,
      updateMaxResponseTimeFirstTime,
    };
  },
});
