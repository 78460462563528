
import { defineComponent, reactive, toRefs, watch } from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputTextArea from '@/components/forms/PlanTextArea.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import AddTemplateCategoryModal from '@/modules/planning/contacts/BlastEmail/AddTemplateCategoryModal.vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';

interface Category {
  label: string,
  value: number
}

interface emailTemplateForm {
  category: number | null,
  categories: Array<Category>,
  loadingCategories: boolean,
  templateName: string,
  subject: string,
  message: string,
  showNewCategoryName: boolean
}

export default defineComponent({
  name: 'EmailTemplateForm',
  components: { PlanIcon, AddTemplateCategoryModal, PlanButton, PlanInputTextArea, PlanInputText },
  emits: ['change'],
  props: {
    preTemplateName: { type: String, required: false, default: '' },
    preSubject: { type: String, required: false, default: '' },
    preMessage: { type: String, required: false, default: '' }
  },
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive<emailTemplateForm>({
      category: null,
      categories: [],
      loadingCategories: false,
      templateName: '',
      subject: '',
      message: '',
      showNewCategoryName: false
    })

    const getEmailCategories = () => {
      data.loadingCategories = true
      data.showNewCategoryName = false

      store.dispatch('LoadsStore/getEmailCategories').then((response) => {
        let result: Array<Category> = []
        response.forEach((category: any) => {
          result.push({ label: category.name, value: category.id })
        })
        data.categories = result

        if (data.category === null && data.categories.length > 0) {
          data.category = data.categories[0].value
        }

        if (store.getters['CompanyStore/getSelectedTemplateCategory'] !== null) {
          data.category = store.getters['CompanyStore/getSelectedTemplateCategory'].id
        }
      }).finally(() => {
        data.loadingCategories = false
      })
    }

    const updateForm = () => {
      data.templateName = props.preTemplateName + ''
      data.subject = props.preSubject + ''
      data.message = props.preMessage + ''
    }

    const update = () => {
      const dataCategory = data.category
      data.categories.forEach((category: any) => {
        if (category.value === dataCategory) {
          store.commit('CompanyStore/selectTemplateCategory', {
            id: category.value,
            name: category.label
          })
        }
      })

      emit('change', {
        category: data.category,
        templateName: data.templateName,
        subject: data.subject,
        message: data.message
      })
    }

    const selectCategory = (category: any) => {
      data.category = category.id
      getEmailCategories()
    }

    watch(() => props.preTemplateName, (newValue, oldValue) => {
      updateForm()
    })

    watch(() => props.preSubject, (newValue, oldValue) => {
      updateForm()
    })

    watch(() => props.preMessage, (newValue, oldValue) => {
      updateForm()
    })

    updateForm()
    getEmailCategories()

    return {
      ...toRefs(data),
      update,
      selectCategory
    }
  }
})
