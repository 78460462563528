import { initialState } from '@/services/pusher/_store/index';

export default {
  clearPusherStore(state: any) {
    Object.assign(state, initialState);
  },
  SET_CURRENT_USER(state: any, payload: any){
    state.currentUserSubscribed = payload;
  },
  SET_CHANNEL(state: any, channel: any): void {
    state.channel = channel;
  },
  SET_PUSHER(state: any, pusher: any) {
    state.pusher = pusher;
  }
}
