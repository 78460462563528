import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex justify-end items-start relative rounded border plan-border-prim-50",
    style: _normalizeStyle(_ctx.isMinSize ? 'width: 75px':'width: 150px')
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToThumbnailBig && _ctx.goToThumbnailBig(...args))),
      class: "rounded flex justify-center items-center cursor-pointer",
      style: _normalizeStyle(_ctx.planThumbnailStyle)
    }, [
      (_ctx.loadingThumbnailSmall)
        ? (_openBlock(), _createBlock(_component_plan_icon, {
            key: 0,
            name: "spinner",
            "icon-color": "#8A8B97",
            "is-svg": "",
            rotate: ""
          }))
        : _createCommentVNode("", true)
    ], 4),
    (_ctx.loadingThumbnailBig)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle(_ctx.planThumbnailLoadingStyle),
          class: "absolute rounded flex justify-center items-center cursor-pointer"
        }, [
          _createVNode(_component_plan_icon, {
            name: "spinner",
            "icon-color": "white",
            "is-svg": "",
            rotate: ""
          })
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.showRemove)
      ? (_openBlock(), _createBlock(_component_plan_button_icon, {
          key: 1,
          onOnClick: _ctx.remove,
          icon: "close",
          size: "12",
          color: "#151830",
          type: "white",
          class: "absolute",
          style: {"padding":"2px","margin":"6px 6px 0 0"}
        }, null, 8, ["onOnClick"]))
      : _createCommentVNode("", true)
  ], 4))
}