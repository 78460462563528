import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col plan-p-md plan-gap-sm" }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-xs" }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-start items-center plan-gap-sm plan-pl-md"
}
const _hoisted_4 = { class: "w-[50%]" }
const _hoisted_5 = {
  class: "plan-div-form flex justify-end items-center",
  style: {"max-width":"120px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_checkbox = _resolveComponent("plan-input-checkbox")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_plan_select_simple = _resolveComponent("plan-select-simple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_plan_input_checkbox, { onChange: _ctx.changeShowForm }, null, 8, ["onChange"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('loads-frequency')), 1)
    ]),
    (_ctx.showForm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_plan_input_number, {
              onChange: _ctx.change,
              "model-value": _ctx.frequency,
              "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.frequency) = $event)),
              placeholder: "0",
              "right-text": _ctx.$t('loads'),
              "hide-validations": ""
            }, null, 8, ["onChange", "model-value", "right-text"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_plan_select_simple, {
              onChange: _ctx.changeFrequencyType,
              value: _ctx.frequencyType,
              items: _ctx.frequencyItems
            }, null, 8, ["onChange", "value", "items"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}