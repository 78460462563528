
import { defineComponent } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import draggable from 'vuedraggable';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'ModalQuotingLanes',
  components: {
    Dialog,
    DialogPanel,
    draggable,
    TransitionChild,
    TransitionRoot,
    XCircleIcon,
    XMarkIcon,
  },
  mixins: [BaseComponent],
  props: {
    open: Boolean,
    lane: Object as any,
    groupBidsStatus: String,
  },
  data() {
    return {
      activeTab: 'quotes',
      isLoading: false,
      quoteAccepting: null as any,
      automaticRanking: false,
      isMoving: false,
      drag: false,
      draggableList: [],
    };
  },
  computed: {
    deliveriesQuantity(): any {
      let sum = 1;
      this.lane.loadTemplate.stops.forEach((stop: any) => {
        if (stop.type === 'DELIVERY') {
          sum++;
        }
      });
      return sum;
    },
    dragOptions(): any {
      return {
        animation: 200,
        group: 'description',
        disabled: this.isMoving,
        ghostClass: 'ghost',
      };
    },
    groupsBids(): any {
      return this.$store.getters['LanesStore/getGroupBids'];
    },
    picksQuantity(): any {
      let sum = 1;
      this.lane.loadTemplate.stops.forEach((stop: any) => {
        if (stop.type === 'PICKUP') {
          sum++;
        }
      });
      return sum;
    },
    quotesList(): any {
      return this.$store.getters['LanesStore/getLaneQuotesList'];
    },
    rankingList(): any {
      return this.$store.getters['LanesStore/getLaneRankingList'];
    },
    totalQuotes(): any {
      return this.$store.getters['LanesStore/getLaneQuotesTotals'];
    },
  },
  methods: {
    acceptQuote(id) {
      if (!this.isLoading) {
        this.isLoading = true;
        this.quoteAccepting = id;
        this.$store
          .dispatch('LanesStore/acceptQuote', id)
          .then(() => {
            this.fetchQuotesRanking();
            this.$emit('fetchLanes');
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    fetchQuotesRanking() {
      // Get Quotes list
      this.$store.dispatch('LanesStore/getLaneQuotedRanking', {
        id: this.lane.id,
        params: { page: 1 },
      });
      // Get Total of quotes
      this.$store.dispatch('LanesStore/getLaneQuotedRanking', {
        id: this.lane.id,
        params: { page: 1, totals: 'quoted' },
      });
      // Get Ranking list
      this.$store.dispatch('LanesStore/getLaneQuotedRanking', {
        id: this.lane.id,
        params: { page: 1, ranking: 'ranking' },
      });
    },
    onDragEnd(event) {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store
          .dispatch('LanesStore/moveRankingPosition', {
            id: event.item.__draggable_context.element.id,
            params: { newPosition: event.newIndex },
          })
          .then(() => {
            this.fetchQuotesRanking();
            this.$emit('fetchLanes');
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    removeFromRanking(id) {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store
          .dispatch('LanesStore/removeFromRanking', id)
          .then(() => {
            this.fetchQuotesRanking();
            BaseComponent.methods?.showToastSuccess(this.$t('quote-removed-ranking'));
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    sendRequestEmail(carrier) {
      this.$store
        .dispatch('LanesStore/sendRequestEmail', {
          group: this.groupsBids.id,
          id: carrier.id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('toast-re-send-quote'));
        });
    },
    toggleOpen(isOpen: boolean) {
      this.$emit('setOpen', isOpen, this.lane);
    },
  },
  watch: {
    open() {
      if (this.open) {
        this.fetchQuotesRanking();
      }
    },
    rankingList(): any {
      this.draggableList = this.rankingList.map((quote) => {
        return { ...quote, order: quote.ranking.position };
      });
    },
  },
});
