import { setLocale } from '@vee-validate/i18n';
import { initialState } from '@/services/country/_store/index';

export default {
  clearCountryStore(state: any) {
    Object.assign(state, initialState);
  },
  setCurrentCountry(state: any, payload: any) {
    state.currentCountry = payload;
  },
  setCountries(state: any, payload: any) {
    state.countries = payload;
  },
  setCountry(state: any, payload: any) {
    state.country = payload;
    const lang = payload.defaultLanguageLocale.substring(0, 2);
    setLocale(lang);
  },
  setIsLoadingCountry(state: any, payload: any) {
    state.isLoadingCountry = payload;
  },
};
