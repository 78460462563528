
import { computed, defineComponent, onBeforeMount, reactive, toRefs, watch } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanTextArea from '@/components/forms/PlanTextArea.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'ShareDocumentsModal',

  mixins: [BaseComponent],
  components: {
    PlanIcon,
    PlanButton,
    PlanInputText,
    PlanTextArea,
    PlanDefaultModal,
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    const currentCompany = computed(() => {
      return store.getters['CompanyStore/getCompany'];
    });

    const currentLoad = computed(() => {
      return store.getters['LoadsStore/currentLoad'];
    });

    const docsList = computed(() => {
      let response = [] as any;
      const docs = store.getters['LoadsStore/getDocsList'];
      for (const key in docs) {
        if (Object.hasOwnProperty.call(docs, key)) {
          response.push({
            documents: docs[key],
            key: key,
            title: translate.t(key),
          });
        }
      }
      return response;
    });

    onBeforeMount(() => {
      store.dispatch('LoadsStore/getDocsList', currentLoad.value.id);
    });

    const totalDocumentsShared = computed(() => {
      const { load_docs, stop_docs, other_docs, invoice_docs } =
        store.getters['LoadsStore/getDocsList'];

      return (
        (load_docs?.length || 0) +
        (stop_docs?.length || 0) +
        (other_docs?.length || 0) +
        (invoice_docs?.length || 0)
      );
    });

    const data = reactive({
      iconColor: '#0097FF',
      showShareModal: false,
      isSendingEmails: false,
      recipientsInput: '',
      recipients: [] as string[],
      subject: '',
      message: '',
    });

    const isValid = computed(() => {
      return data.subject !== '' && validateEmails.value;
    });

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const validateEmails = computed(() => {
      if (data.recipientsInput.includes(',')) {
        const emails = data.recipientsInput.split(',').map((email) => email.trim());
        return emails.every((email) => emailPattern.test(email));
      } else {
        return emailPattern.test(data.recipientsInput);
      }
    });

    watch(
      () => data.recipientsInput,
      (newValue) => {
        if (newValue.includes(',')) {
          const emails = newValue.split(',').map((email) => email.trim());
          data.recipients = emails.filter((email) => emailPattern.test(email));
        } else {
          data.recipients = emailPattern.test(newValue) ? [newValue] : [];
        }
      },
    );

    const sendEmail = () => {
      data.isSendingEmails = true;
      let object = {
        recipients: data.recipients,
        subject: data.subject,
        message: data.message,
      };

      store
        .dispatch('LoadsStore/sendLoadDocsEmail', { object: object, id: currentLoad.value.id })
        .then(() => {
          store.commit('LoadsStore/showModalShareDocuments', false);
          store.dispatch('UserStore/showToast', translate.t('email-sent'));
        })
        .finally(() => {
          data.isSendingEmails = false;
        });
    };

    data.recipientsInput = currentCompany.value.accountingEmail ?? '';
    data.subject = translate.t('load-report-id', { id: currentLoad.value.identifier });
    data.message = translate.t('attaching-documentation-to-id', {
      id: currentLoad.value.identifier,
    });

    return {
      ...toRefs(data),
      sendEmail,
      docsList,
      validateEmails,
      translate,
      isValid,
      totalDocumentsShared,
    };
  },
});
