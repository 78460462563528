
import { computed, defineComponent, reactive, toRefs, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanCommodities from '@/modules/planning/newLoad/_components/PlanCommodities.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';

export default defineComponent({
  name: 'TrailerAndCommodity',
  components: { PlanSelectSimple, PlanCommodities, PlanInputNumber },
  props: {
    label: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    icon: { type: String, required: false, default: '' },
    preValue: { type: Boolean, required: false },
    id: { type: Number, required: false, default: 0 },
    legIndex: Number,
  },
  emits: ['changeTrailer', 'changeCommodityMasters', 'changeGeneralObservations'],
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      seePlaceHolder: false,
      load: null as any,
      isModify: false,
      value: false,
      vehicle: '',
      vehicleName: '',
      isRefrigerated: {
        value: false,
        min: null,
        max: null,
      },
    });

    const vehicles = computed(() => {
      let result: Array<any> = [];
      store.getters['LoadsStore/getTrailerTypes'].forEach((vehicle: any) => {
        result.push({
          label: vehicle.description,
          value: vehicle.id.toString(),
          type: vehicle.type,
        });
      });
      return result;
    });

    const changeTrailer = () => {
      if (data.isRefrigerated.min && data.isRefrigerated.max) {
        if (data.isRefrigerated.min > data.isRefrigerated.max)
          data.isRefrigerated.min = data.isRefrigerated.max;
      }

      emit('changeTrailer', {
        vehicle: data.vehicle,
        vehicleName: data.vehicleName,
        isRefrigerated: {
          value: data.isRefrigerated.value,
          min: data.isRefrigerated.min,
          max: data.isRefrigerated.max,
        },
      });
    };

    const changeVehicle = (newVehicle: any) => {
      data.vehicle = newVehicle;
      vehicles.value.forEach((vehicle: any) => {
        if (vehicle.value === data.vehicle) {
          data.vehicleName = vehicle.label;
          data.isRefrigerated.value =
            vehicle.type === 'REFRIGERATEDOPTIONAL' || vehicle.type === 'REFRIGERATED';
        }
      });
      changeTrailer();
    };

    const updateCommodityMasters = (data) => {
      emit('changeCommodityMasters', data);
    };

    const updateGeneralObservations = (data) => {
      emit('changeGeneralObservations', data);
    };

    const onFocus = () => {
      data.seePlaceHolder = false;
    };

    const onBlur = () => {
      if (data.load.observations.length === 0) {
        data.seePlaceHolder = true;
      }
    };

    return {
      ...toRefs(data),
      changeTrailer,
      changeVehicle,
      vehicles,
      updateCommodityMasters,
      updateGeneralObservations,
      onBlur,
      onFocus,
    };
  },
});
