
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateSpin.vue';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';

export default defineComponent({
  name: 'YourData',
  components: {
    PhoneNumberSelector,
    ErrorMessage,
    Field,
    Form,
    Spin,
  },
  mixins: [BaseComponent],
  props: {
    step: {
      type: Number,
    },
    shippmentId: {
      type: Number,
    },
    twiccRequired: {
      type: Boolean,
    },
  },
  data() {
    return {
      twiccCard: false,
      allowed: true,
      sending: false,
      user: {
        name: null,
        surname: null,
        phonePrefix: '1',
        phone: null,
        email: null,
      },
      company: {
        name: null,
        dotNumber: null,
        mcNumber: null,
        hasTWICCard: false,
        identificationNumber: null,
        location: {
          address: '',
        }
      },
      vehicleSelected: null,
      selectedOption: '',
      updateError: false,
    };
  },
  computed: {
    shipmentId(): any {
      return this.$route.params.id;
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    currentCompany(): any {
      return this.$store.getters['CompanyStore/getCompany'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
  },
  watch: {
    currentCompany() {
      this.company.dotNumber = this.currentCompany.dotNumber;
      this.company.mcNumber = this.currentCompany.mcNumber;
      this.company.hasTWICCard = this.currentCompany.hasTWICCard;
      if(this.currentCompany?.location?.id){
        this.company.location = { ...this.currentCompany.location };
      }
    },
  },
  async created() {
    this.setUserInfo();
    this.selectedOption = this.currentCompany.type;
  },
  methods: {
    async resetToken() {
      const refreshToken = localStorage.refresh_token;
      const refresh = await this.$store.dispatch('AuthStore/refreshToken', refreshToken);
    },
    changePhone(type, phone) {
      switch (type) {
        case 'prefix':
          this.user.phonePrefix = phone.prefix;
          break;
        case 'number':
          this.user.phone = phone.number;
          break;
        default:
          return;
      }
    },
    updateUserType() {
      const data = {
        id: this.currentCompany.id,
        type: {
          type: this.selectedOption,
        },
      };
      this.$store
        .dispatch('CompanyStore/putCompanyUpdateType', data)
        .then(() => {
          this.$store.dispatch('CompanyStore/getCompany', this.currentCompany.id);
          BaseComponent.methods?.showToastSuccess(this.$t('information-updated'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    },
    setUserInfo() {
      this.user.name = this.currentUser.name;
      this.user.surname = this.currentUser.surname;
      this.user.email = this.currentUser.email;
      this.user.phonePrefix = this.currentUser?.phonePrefix ? this.currentUser?.phonePrefix : '1';
      this.user.phone = this.currentUser.phone;
      this.company.name = this.currentUser?.company?.name;
      this.selectedOption = this.currentCompany.type;

      if (this.currentCompany) {
        this.company.dotNumber = this.currentCompany.dotNumber;
        this.company.mcNumber = this.currentCompany.mcNumber;
        this.company.hasTWICCard = this.currentCompany.hasTWICCard;
        this.company.identificationNumber = this.currentCompany.identificationNumber;
        if(this.currentCompany?.location?.id){
          this.company.location = { ...this.currentCompany.location };
        }
      }
    },
    async validateForm() {
      if (!this.allowed) return;
      this.sending = true;
      const validation = await (this.$refs.form as any).validate();

      if (!validation.valid) {
        this.sending = false;
        return;
      }
      let error = false;
      if (
        this.selectedOption !== '' &&
        this.selectedOption !== undefined &&
        this.selectedOption !== this.currentCompany.type
      ) {
        await this.updateUserType();
      }
      await this.$store
        .dispatch('UserStore/putUser', {
          id: this.currentUser.id,
          user: this.user,
        })
        .catch((err) => {
          var error = err.response.data.violations;
          var violationsMessages = error.map(
            (violation) => `${violation.propertyPath}: ${violation.message}`,
          );
          var violationsMessagesString = violationsMessages.join(', ');
          BaseComponent.methods?.showToastError(violationsMessagesString);
          this.updateError = true;
        });

      await this.$store
        .dispatch('CompanyStore/putCompany', {
          id: this.currentUser?.company?.id,
          company: this.company,
        })
        .catch((err) => {
          let error = err.response.data.violations;
          var violationsMessages = error.map(
            (violation) => `${violation.propertyPath}: ${violation.message}`,
          );
          var violationsMessagesString = violationsMessages.join(', ');
          BaseComponent.methods?.showToastError(violationsMessagesString);
          this.updateError = true;
        });

      await this.resetToken();
      this.sending = false;
      this.allowed = false;
      if (!this.updateError) {
        BaseComponent.methods?.showToastSuccess(this.$t('information-updated'));
        await this.$store.dispatch('ShipmentStore/shipment', { id: this.shipmentId });
        await this.$store
          .dispatch('CompanyStore/getCompany', this.currentUser?.company?.id)
          .then(() => {
            this.$emit('updateStep');
            this.$emit('nextStep', 'ServiceInsurances');
          });
      }
    },
  },
});
