<template>
  <div
    class="flex space-x-2 items-center justify-center self-center py-1"
    @click.stop="goToLoadRFPDetail"
  >
    <div
      v-if="load?.source || load?.shipment?.type"
      class="inline-block rounded-3xl px-2 py-1 mr-2"
      :class="[ShipmentTypeBackground[load?.shipment?.type]]"
    >
      <p class="text-white text-xs font-normal font-['Nunito']">
        {{ $t(load.shipment.type.toLowerCase()) }}
      </p>
    </div>
    <span class="text-sm opacity-70 whitespace-nowrap">
      {{ `#${load?.identifier}` }}
    </span>
    <span class="max-w-[300px] text-white text-sm font-normal font-['Nunito'] truncate">
      {{ load?.laneRFP?.project?.name }}
    </span>
    <span>
      <img
        alt="Arrow right"
        class="h-[14px] w-[14px]"
        :src="getAsset.icArrowRight"
      />
    </span>
    <span class="text-white text-sm font-normal font-['Nunito']">
      {{ load?.laneRFP?.identifier }}
    </span>
    <span>
      <img
        v-if="load?.poNumber && load?.poNumber !== ''"
        alt="Arrow right"
        class="h-[14px] w-[14px]"
        :src="getAsset.icArrowRight"
      />
    </span>
    <span v-if="load?.poNumber && load?.poNumber !== ''">
      {{ `${$t('label.po')} ${load?.laneRFP?.identifier}-${load?.poNumber}` }}
    </span>
  </div>
</template>

<script>
// Base
import BaseComponent from '@/base/BaseComponent';
// Types
import { ShipmentTypeBackground } from '@/services/shipment/ShipmentTypes';
// Services
import router from '@/router';

export default {
  mixins: [BaseComponent],
  props: {
    load: {
      type: Object,
    },
  },
  setup(props) {
    const goToLoadRFPDetail = () => {
      router.push({
        name: 'RFP-laneDetail',
        params: {
          projectId: props.load.laneRFP.project.id,
          laneId: props.load.laneRFP.id,
        },
      });
      setTimeout(() => {
        router.push({
          name: 'RFP-laneService',
          params: {
            projectId: props.load.laneRFP.project.id,
            laneId: props.load.laneRFP.id,
            id: props.load.id,
          },
        });
      }, 300);
    };

    return {
      goToLoadRFPDetail,
      ShipmentTypeBackground,
    };
  },
};
</script>

<style></style>
