
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { XCircleIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import PlanCountrySelector from '@/components/forms/PlanCountrySelector.vue';

export default defineComponent({
  name: 'ContactDetailLanes',
  components: {
    PlanCountrySelector,
    GoogleAutocomplete,
    Field,
    ErrorMessage,
    TrashIcon,
    Form,
    XCircleIcon,
  },
  emits: ['updateLanes'],
  mixins: [BaseComponent],
  data() {
    return {
      address: '',
      originStop: '',
      currentLanes: [] as any,
      validating: false,
      currentLanesArray: null as any,
      defaultCountrySelected: null as any,
    };
  },
  beforeMount() {
    this.defaultCountrySelected = this.currentCountry;
  },
  beforeCreate() {
    this.$store.dispatch('CountryStore/getCountries');
  },
  props: {
    lanes: { type: Array, required: true },
  },
  created() {
    this.currentLanes = this.lanes;
    this.$store.dispatch('LoadsStore/getLanes', { id: this.getUser.company.id });
  },
  computed: {
    countriesList(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    getLanes(): any {
      return this.$store.getters['LoadsStore/lanes'];
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
    getUser(): any {
      return this.$store.getters['UserStore/getUser'];
    },
    currentCompany(): any {
      return this.$store.getters['CompanyStore/getCompanyById'];
    },
  },
  methods: {
    async updateLanes() {
      const validation = await (this.$refs.form as any).validate();

      if (!validation.valid) {
        return;
      }

      this.$emit('updateLanes', this.currentLanes);
    },
    clearInput(data: any, item: string) {
      if (item === 'origin') {
        data.locationOrigin.city = '';
      }
      if (item === 'destination') {
        data.locationDestiny.city = '';
      }
    },
    deleteLane(id) {
      this.$store.dispatch('LoadsStore/deleteLanes', { id: id }).then(() => {
        this.$store.dispatch('CompanyStore/getCompany', this.getUser.company.id);
        this.$store.dispatch('LoadsStore/getLanes', { id: this.getUser.company.id });
        BaseComponent.methods?.showToastSuccess(this.$t('updated Correctly'));
      });
    },
    getCityData(addressData) {
      let location;

      let stateObj = this.states.find(
        (state) => state.iso === addressData.administrative_area_level_1,
      );
      if (!stateObj && addressData.administrative_area_level_2) {
        stateObj = this.states.find(
          (state) => state.name === addressData.administrative_area_level_2,
        );
      }

      let cityString = this.getCityString(addressData);
      if (stateObj?.id) {
        location = {
          lat: addressData.latitude,
          long: addressData.longitude,
          zip: addressData.postal_code,
          city: cityString,
          state: { id: stateObj.id },
          googlePlaceId: addressData.place_id,
        };
      } else {
        location = {
          lat: addressData.latitude,
          long: addressData.longitude,
          zip: addressData.postal_code,
          city: cityString,
          googlePlaceId: addressData.place_id,
        };
      }

      return location;
    },
    getCityString(location) {
      const zip = location?.postal_code || '';
      const city = location?.locality || '';
      const area1 = location?.administrative_area_level_1 || '';
      const area2 =
        location?.administrative_area_level_2 && !area1
          ? location?.administrative_area_level_2
          : '';
      const stateId = location?.state?.id;
      const stateName = stateId
        ? this.states.find((state) => state.id === stateId)?.name || ''
        : '';

      const locationParts = [zip, city, stateName, area1, area2].filter(Boolean);
      return locationParts.join(', ');
    },
    getCountryIsoByLane(lane, type) {
      let countryIso = null;
      switch (type) {
        case 'ORIGIN':
          countryIso = lane.originCountry?.iso;
          break;
        case 'DESTINATION':
          countryIso = lane.destinationCountry?.iso;
          break;
      }

      return countryIso;
    },
    getLocation(addressData, address, id, index, lane) {
      if (addressData.postal_code && addressData.locality) {
        const stopType = index === 0 ? 'PICKUP_INITIAL' : 'DELIVERY_FINAL';
        let stop = this.currentLanes[lane].loadTemplate.stops.find(
          (stop) => stop.type === stopType,
        );
        stop.place = this.locationString(addressData) as unknown;
        stop.location = this.locationObject(addressData) as unknown;

        if (this.$route.query.loadCreationMode === 'Editing') {
          stop.location.id = stop?.location?.id;
        }
      } else {
        (this.$refs[id] as any)[0].clear();
      }
    },
    handleClickCountry(country, type, index) {
      switch (type) {
        case 'ORIGIN':
          this.currentLanes[index].originCountry = country;
          break;
        case 'DESTINATION':
          this.currentLanes[index].destinationCountry = country;
          break;
      }
    },
    async submit() {
      this.validating = true;
      const validation = await (this.$refs.form as any).validate();

      if (!validation.valid) {
        return;
      }

      this.currentLanes = [];
    },
  },
  watch: {
    async getUser() {
      await this.$store.dispatch('CompanyStore/getCompany', this.getUser.company.id);
    },
    currentCompany() {
      this.currentLanesArray = this.currentCompany.lanes;
    },
    lanes: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        this.currentLanes = val;
      },
    },
  },
});
