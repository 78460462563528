<script>
// Instructions (https://codepen.io/toddwebdev/pen/yExKoj):
// Step 1 - Put id 'moveHorizontalScroll' in the main div.
// Step 2 - Add overflow-x-scroll.
// Optional styles: whitespace-nowrap transition-all transform will-change-transform select-none
// Step 3 - Add this class (HorizontalScroll.vue) as a mixin
// Step 4 - Enjoy!
export default {
  name: 'HorizontalScroll',
  mounted() {
    // const slider = document.querySelector('#moveHorizontalScroll');
    const slider = document.getElementById('moveHorizontalScroll');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider?.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider?.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider?.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider?.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      // console.log(walk);
    });
  },
  /*unmounted() {
    removeEventListener('mousedown', this);
    removeEventListener('mouseleave', this);
    removeEventListener('mouseup', this);
    removeEventListener('mousemove', this);
  },*/
};
</script>

<style scoped>

</style>
