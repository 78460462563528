
import { computed, defineComponent, ref, onMounted, watch } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
// Services
import store from '@/services/store';
import router from '@/router';

export default defineComponent({
  name: 'PlanimatikCreditModal',
  components: {
    BaseModal,
  },
  mixins: [BaseComponent],
  emits: ['callbackClose', 'callbackSend'],
  setup(props, { emit }) {
    // Variables
    const page = ref(1);
    const showSpinner = ref(false);
    const h3 = ref('<span class="text-xl">');

    // Computed
    const companyCredit = computed((): any => {
      return store.getters['CreditStore/getCredit'];
    });

    const creditAvailable = computed((): any => {
      return companyCredit?.value?.creditGranted - companyCredit?.value?.creditLocked;
    });

    const creditBreakdown = computed(() => {
      return store.getters['CreditStore/getCreditBreakdown'];
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const planimatikCreditModal = computed(() => {
      return store.getters['CreditStore/getPlanimatikCreditModal'];
    });

    /** onMounted */
    onMounted(() => {
      if (currentUser.value.companyId) {
        apiGetCreditBreakdown();
      }
    });

    /** Methods */
    const apiGetCreditBreakdown = () => {
      if (showSpinner.value === true) return;
      showSpinner.value = true;
      return store
        .dispatch('CreditStore/getCompanyCreditBreakdown', {
          id: currentUser.value.companyId,
          params: { page: page.value },
        })
        .then(() => {
          showSpinner.value = false;
        });
    };

    const loadMore = () => {
      page.value = page.value + 1;
      apiGetCreditBreakdown();
    };

    const onClickClose = () => {
      store.commit('CreditStore/setPlanimatikCreditModal', false);
    };

    const toIncreaseCredit = () => {
      router.push('/planning/increase-credit');
      onClickClose();
    };

    const toLoadDetail = (id) => {
      router.push({ name: 'planificationDetail', params: { id: id } });
      onClickClose();
    };

    return {
      companyCredit,
      creditAvailable,
      creditBreakdown,
      currentUser,
      h3,
      loadMore,
      onClickClose,
      planimatikCreditModal,
      showSpinner,
      toIncreaseCredit,
      toLoadDetail,
    };
  },
});
