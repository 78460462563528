
import { ref, computed, onMounted, watchEffect } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useStore } from 'vuex';
import i18n from '@/resources/locales';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import PlanButtonVue from '@/components/buttons/PlanButton.vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
// Services
import router from '@/router';

export default {
  components: {
    ErrorMessage,
    Field,
    Form,
    InputAmericanFormat,
    PlanButtonVue,
    XMarkIcon,
  },
  mixins: [BaseComponent],
  setup(props, { emit }) {
    // Global
    const store = useStore();
    const $t = i18n.global.t;
    // Variables
    const data = ref({
      accountPayableEmail: '',
      creditRequested: null as any,
      dunNumber: '',
      bradstreetNumber: '',
      taxId: '',
      suppliers: [
        {
          companyName: '',
          contactPerson: '',
          email: '',
        },
      ],
      financeDirectorEmail: '',
      companyOwnerEmail: '',
    });
    const form = ref(null);
    const sending = ref(false);

    // Computed
    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    /** onMounted */
    onMounted(() => {
      data.value.accountPayableEmail = currentUser.value.email;
    });

    // Methods
    const addReference = () => {
      data.value.suppliers.push({
        companyName: '',
        contactPerson: '',
        email: '',
      });
    };

    const goPrevRoute = () => {
      router.go(-1);
    };

    const postCreditRequests = async () => {
      if (sending.value === true) return;
      const validation = await (form as any).value.validate();
      if (!validation.valid) {
        return;
      }
      sending.value = true;
      return store
        .dispatch('CreditStore/postCreditRequests', data.value)
        .then(() => {
          BaseComponent.methods?.showToastSuccess($t('request-sent-correctly'));
          setTimeout(() => {
            router.push({ name: 'planification' });
          }, 600);
        })
        .finally(() => {
          sending.value = false;
        });
    };

    const removeReference = (index) => {
      data.value.suppliers.splice(index, 1);
    };

    return {
      addReference,
      currentUser,
      data,
      form,
      goPrevRoute,
      postCreditRequests,
      removeReference,
      sending,
    };
  },
};
