
import { defineComponent, reactive, toRefs, computed, onMounted } from 'vue';
import { ActionContext, useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import AddCustomer from '@/modules/planning/newLoad/_components/AddCustomer.vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanLegData from '../_components/PlanLegData.vue';
import PlanTrailerAndCommodity from '../_components/PlanTrailerAndCommodity.vue';
import PlanUploadFiles from '../_components/PlanUploadFiles.vue';

interface newLoad {
  load: any;
  poNumber: any;
}

export default defineComponent({
  name: 'PlanShipmentData',
  components: {
    AddCustomer,
    PlanInputCheckbox,
    PlanInputText,
    PlanLegData,
    PlanTrailerAndCommodity,
    PlanUploadFiles,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const data = reactive<newLoad>({
      load: null as any,
      poNumber: '',
    });

    const getLoadToSend = computed(() => {
      return store.getters['CrossBorderStore/getLoadToSend'];
    });

    const legs = computed(() => {
      return [
        {
          title: t('LEG 1 - MX domestic'),
          description:
            'Introduce la información relativa al tramo del transporte desde la recogida en MX hasta la entrega en el paso transfronterizo.',
          pickUpCountry: { id: 2, name: 'Mexico', iso: 'MX' },
          deliveryCountry: { id: 2, name: 'Mexico', iso: 'MX' },
        },
        {
          title: t('LEG 2 - Transfer'),
          description:
            'Introduce la información relativa al cruce de la frontera. Desde la recogida en el patio MX, hasta la entrega en patio USA.',
          pickUpIsText: 'Punto de entrega del LEG 1',
          deliveryCountry: { id: 1, iso: 'US', name: 'United States' },
        },
        {
          title: t('LEG 3 - USA domestic'),
          description:
            'Introduce la información relativa al tramo del transporte desde la recogida en USA hasta la entrega en las instalaciones de destino.',
          pickUpIsText: 'Punto de entrega del LEG 2',
          deliveryCountry: { id: 1, iso: 'US', name: 'United States' },
        },
      ];
    });

    onMounted(() => {
      // TODO: check if is Modify
      store.commit(
        'CrossBorderStore/updateLoadToSend',
        store.getters['CrossBorderStore/getDefaultLoad'],
      );
    });

    const removePopertyFromLoad = (key: string) => {
      store.commit('CrossBorderStore/removePopertyFromLoad', key);
    };

    const updatePopertyFromLoad = (value: any, key: string) => {
      store.commit('CrossBorderStore/updatePopertyFromLoad', {
        key: key,
        value: value,
      });
    };

    const updateCommodityMasters = (data: any) => {
      console.log('data: ', data);
      updatePopertyFromLoad([...data], 'commodityMasters');
    };

    const updateGeneralObservations = (event) => {
      const innerText = event.target.innerText;
      updatePopertyFromLoad(innerText, 'observations');
    };

    const updateVehicle = (newVehicle: any) => {
      console.log('newVehicle: ', newVehicle);
      updatePopertyFromLoad({ id: newVehicle.vehicle.id }, 'trailerType');
      if (newVehicle.isRefrigerated.value) {
        updatePopertyFromLoad(newVehicle.isRefrigerated.max, 'maxTemp');
        updatePopertyFromLoad(newVehicle.isRefrigerated.min, 'minTemp');
      } else {
        removePopertyFromLoad('maxTemp');
        removePopertyFromLoad('minTemp');
      }
    };

    return {
      ...toRefs(data),
      getLoadToSend,
      legs,
      updateCommodityMasters,
      updateGeneralObservations,
      updateVehicle,
    };
  },
});
