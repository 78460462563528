import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "load-card-data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_load_card_tag = _resolveComponent("load-card-tag")!
  const _component_load_card_initial_and_final_stop = _resolveComponent("load-card-initial-and-final-stop")!
  const _component_load_timeline = _resolveComponent("load-timeline")!
  const _component_load_card_footer_data = _resolveComponent("load-card-footer-data")!
  const _component_load_card_state = _resolveComponent("load-card-state")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'load-id-' + _ctx.loadCardTagData.identifier,
    class: _normalizeClass(["load-card", _ctx.loadCardStateBackground])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "w-full h-full absolute rounded-lg",
        style: _normalizeStyle(`background: url(${_ctx.loadCardBg}); background-position: center; background-size: contain;`)
      }, null, 4),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        class: "load-card-data-content",
        style: _normalizeStyle([{"overflow":"visible"}, _ctx.windowWidth < 832 ? 'padding-left: 60px; box-sizing: border-box;' : ''])
      }, [
        _createVNode(_component_load_card_tag, {
          "load-id": _ctx.loadCardTagData.identifier,
          "load-type": _ctx.loadCardTagData.type,
          "multi-stop-data": _ctx.loadCardTagData.multiStopData,
          "rfp-project-id": _ctx.loadCardTagData.rfp.projectId,
          "rfp-info": _ctx.loadCardTagData.rfp.info,
          "rfp-lane-id": _ctx.loadCardTagData.rfp.laneId,
          "rfp-lane": _ctx.loadCardTagData.rfp.lane,
          "rfp-po": _ctx.loadCardTagData.po,
          "right-items": _ctx.loadCardTagData.rightItems,
          source: _ctx.loadCardTagData.source,
          "ltl-identifier": _ctx.loadCardTagData.ltl.proNumber
        }, null, 8, ["load-id", "load-type", "multi-stop-data", "rfp-project-id", "rfp-info", "rfp-lane-id", "rfp-lane", "rfp-po", "right-items", "source", "ltl-identifier"]),
        _createVNode(_component_load_card_initial_and_final_stop, {
          "pickup-city": _ctx.loadCardPickupAndDelivery.pickup.city,
          "pickup-ct": _ctx.loadCardPickupAndDelivery.pickup.ct,
          "pickup-iso-code": _ctx.loadCardPickupAndDelivery.pickup.isoCode,
          "pickup-date": _ctx.loadCardPickupAndDelivery.pickup.date,
          "delivery-city": _ctx.loadCardPickupAndDelivery.delivery.city,
          "delivery-ct": _ctx.loadCardPickupAndDelivery.delivery.ct,
          "delivery-iso-code": _ctx.loadCardPickupAndDelivery.delivery.isoCode,
          "delivery-date": _ctx.loadCardPickupAndDelivery.delivery.date,
          dateTimeEta: _ctx.loadCardPickupAndDelivery.dateTimeEta
        }, null, 8, ["pickup-city", "pickup-ct", "pickup-iso-code", "pickup-date", "delivery-city", "delivery-ct", "delivery-iso-code", "delivery-date", "dateTimeEta"]),
        _createElementVNode("div", {
          style: _normalizeStyle(
            _ctx.windowWidth < 832
              ? 'height: calc(100% - 20px); position: absolute; top: 10px; left: 10px;'
              : 'width: 100%;'
          )
        }, [
          _createVNode(_component_load_timeline, {
            stops: _ctx.load.stops
          }, null, 8, ["stops"])
        ], 4),
        _createVNode(_component_load_card_footer_data, {
          distance: _ctx.loadCardFooterData.distance,
          vehicle: _ctx.loadCardFooterData.vehicle,
          "goods-type": _ctx.loadCardFooterData.goodsType,
          "special-requirements": _ctx.loadCardFooterData.specialRequirements
        }, null, 8, ["distance", "vehicle", "goods-type", "special-requirements"])
      ], 4)
    ]),
    _createVNode(_component_load_card_state, {
      onOnClick: _ctx.onClick,
      onOnOptionsClick: _ctx.onOptionsClick,
      onOpenDetail: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('openDetail'))),
      load: _ctx.load,
      chats: _ctx.chats,
      state: _ctx.state
    }, null, 8, ["onOnClick", "onOnOptionsClick", "load", "chats", "state"])
  ], 10, _hoisted_1))
}