
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import moment from 'moment'

export default defineComponent({
  name: 'TrackingHistoryModal',
  components: { PlanLoading, PlanButtonIcon, PlanTextButton, PlanDefaultTable, PlanDefaultModal },
  emits: ['close'],
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive({
      items: [],
      loading: false,
      selectedItem: null
    })

    const formatDate = computed(() => {
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'MMM DD, YYYY HH:MM':'DD MMM, YYYY HH:MM'
    })

    const getTrackingHistory = () => {
      data.loading = true
      store.dispatch('LoadsStore/getTrackingHistory', store.getters['LoadsStore/currentLoad'].shipment?.id)
        .then((response) => {
          data.items = response
        }).finally(() => {
          data.loading = false
      })
    }

    const close = () => { emit('close') }

    getTrackingHistory()

    return {
      ...toRefs(data),
      moment,
      close,
      formatDate
    }
  }
})
