
import { defineComponent, ref } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CarrierResume from '@/modules/carrier/modals/CarrierResumen.vue';
import SidebarUser from '@/components/sidebar/SidebarUser.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  components: {
    SidebarUser,
    PlanIcon,
    CarrierResume,
  },
  mixins: [BaseComponent],
  data() {
    return {
      showCarrierResume: false,
    };
  },
  computed: {
    carrierSummary(): any {
      return this.$store.getters['ShipmentStore/getActivitySummary'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    isDriver(): any {
      return this.$route.path.includes('driver');
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    user(): any {
      return this.$store.getters['UserStore/getUser'];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('PusherStore/disconnectPusher');
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      this.$router.push('/signIn');
    },
  },
});
