import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  appliedFilters: {
    SORT_BY: { id: 'SORT_BY', value: 'id[DESC]', loadFilterParam: 'id[DESC]' },
  },
  previousFilters: {},
  quotesAverageStats: null as any,
  carrierBidData: {},
  currentBid: null as any,
  docsList: null as any,
  feeExplanations: [],
  states: [],
  filteredCarriers: [],
  trailerTypes: [],
  loads: [] as object[],
  loadInfo: null as any,
  loadsInfo: [] as object[],
  loadsByDates: [] as object[],
  totals: {},
  trackingLogs: [],
  showModalCarrierAssigned: false,
  showModalQuoteSended: false,
  showModalShareDocuments: false,
  loadShipmentId: 0,
  loadId: 0,
  currentLoad: {},
  currentLoadEditing: {},
  lanes: {},
  loadAditionalDocuments: [],
  shipmentNextStep: false,
  saveLoads: [] as object[],
  saveLoadsSearch: [] as object[],
  saveLoadId: {},
  selectedQuickFilter: '',
  lastApiCallId: null,
  plannerTotalsDayWeek: null,
  showTemplateModal: false,
  showIncidenceModal: false,
  stopSelected: null as any,
  limitedAccessList: [],
  NMFC: [],
  densityClass: [],
  packageTypes: {},
  ltlQuotes: [],
  getLTLQuotesById: [],
  showFreightInfoModal: false,
  totalSavedBidsLTL: 0,
  totalClaims: 0,
  rankingRFP: null as any,
  shipmentFees: [],
  selectedWarehouse: {},
  warehousesItems: [],
  warehousesItemsModified: [],
  temporalLoadId: -1,
  planningType: 'cards',
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
