import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-md" }
const _hoisted_2 = { class: "flex justify-between items-center border-b plan-border-prim-50 plan-pb-xs" }
const _hoisted_3 = { class: "plan-text-lg font-bold" }
const _hoisted_4 = { class: "flex flex-col plan-gap-sm" }
const _hoisted_5 = { class: "m-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rename_template_category_modal = _resolveComponent("rename-template-category-modal")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showRenameCategoryName)
      ? (_openBlock(), _createBlock(_component_rename_template_category_modal, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showRenameCategoryName = false)),
          onCloseAndUpdate: _ctx.closeAndUpdate
        }, null, 8, ["onCloseAndUpdate"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_popper, {
        hover: "",
        placement: "left",
        offsetSkid: "0",
        offsetDistance: "0"
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_plan_button, {
              onOnClick: _ctx.renameOnClick,
              disabled: _ctx.loadingDelete,
              type: "cancel",
              size: "sm",
              "justify-align": "flex-start",
              label: _ctx.$t('rename')
            }, null, 8, ["onOnClick", "disabled", "label"]),
            (_ctx.templates === 0)
              ? (_openBlock(), _createBlock(_component_plan_button, {
                  key: 0,
                  onOnClick: _ctx.deleteCategory,
                  loading: _ctx.loadingDelete,
                  "icon-color": "#8A8B97",
                  type: "cancel",
                  size: "sm",
                  "justify-align": _ctx.loadingDelete ? 'justify-center':'justify-start',
                  label: _ctx.$t('delete')
                }, null, 8, ["onOnClick", "loading", "justify-align", "label"]))
              : _createCommentVNode("", true)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_plan_button_icon, {
            icon: "options",
            type: "transparent-gray-800"
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "category-content")
    ])
  ]))
}