import { ActionContext } from 'vuex';
import jwt_decode from 'jwt-decode';
import Pusher from 'pusher-js';
// Resources
import i18n from '@/resources/locales';
// Router
import router from '@/router';
import BaseComponent from '@/base/BaseComponent';

const $t = i18n.global.t;
let pusherInstance = null as any;
let channelInstance = null as any;

export default {
  async clearPusherStore(context: any) {
    context.commit('clearPusherStore');
  },
  initializePusher(context: ActionContext<any, any>): void {
    if (pusherInstance && channelInstance) {
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    let decodedToken = '' as any;

    decodedToken = jwt_decode(token);
    const channelName = `private-${decodedToken.hashId}`;

    const pusherKey = process.env.VUE_APP_PUSHER_KEY;
    const pusherCluster = process.env.VUE_APP_PUSHER_CLUSTER;

    const pusherConfig = {
      cluster: pusherCluster,
      authEndpoint: `${process.env.VUE_APP_URL}/pusher/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    };

    if (!pusherInstance) {
      pusherInstance = new Pusher(pusherKey, pusherConfig);
      context.commit('SET_PUSHER', pusherInstance);
    }

    if (!channelInstance) {
      channelInstance = pusherInstance.subscribe(channelName);

      channelInstance.bind('lanes_rfp_batch_quoted', (data) => {
        context.dispatch('RFP_BATCH_QUOTED', data);
      });

      channelInstance.bind('lanes_rfp_quote_confirmation_acceptance', (data) => {
        context.dispatch('RFP_RANKING_ACCEPTED', data);
      });

      channelInstance.bind('lanes_rfp_quote_confirmation_refusal', (data) => {
        context.dispatch('RFP_RANKING_REFUSED', data);
      });
      //RFP END

      channelInstance.bind('BOL_POD_upload', (data) => {
        context.dispatch('BOL_POD_upload', data);
      });
      channelInstance.bind('book_now_accepted', (data) => {
        context.dispatch('BOOK_NOW_ACCEPT', data);
      });
      channelInstance.bind('chat_new_message', (data) => {
        context.dispatch('CHAT_NEW_MESSAGE', data);
      });
      channelInstance.bind('consignee_filled_by_shipper', (data) => {
        context.dispatch('RC_FILLED', data);
      });
      channelInstance.bind('invoice_media_object_upload', (data) => {
        context.dispatch('INVOICE_UPLOAD', data);
      });
      channelInstance.bind('quote_made', (data) => {
        context.dispatch('QUOTE_MADE', data);
      });
      channelInstance.bind('quote_updated', (data) => {
        context.dispatch('QUOTE_MADE', data);
      });
      channelInstance.bind('quote_removed', (data) => {
        context.dispatch('QUOTE_MADE', data);
      });

      channelInstance.bind('activity_chat_new_message', (data) => {
        context.dispatch('ACTIVITY_CHAT_NEW_MESSAGE', data);
      });

      channelInstance.bind('notify_lanes_rfp_batch_created', (data) => {
        context.dispatch('NOTIFY_LANES_CREATED', data);
      });
      channelInstance.bind('notify_lanes_cloned_batch', (data) => {
        context.dispatch('NOTIFY_LANES_CLONED_BATCH', data);
      });
      channelInstance.bind('notify_lanes_rfp_excel_extracted', (data) => {
        context.dispatch('NOTIFY_LANES', data);
      });
      channelInstance.bind('rc_signed', (data) => {
        context.dispatch('RC_SIGNED', data);
      });
      channelInstance.bind('request_bid_accepted', (data) => {
        context.dispatch('BID_ACCEPTED', data);
      });
      channelInstance.bind('shipment_accept_completed', (data) => {
        context.dispatch('CARRIER_CONFIRM_INFO', data);
      });
      channelInstance.bind('shipment_status_changed', (data) => {
        context.dispatch('SHIPMENT_STATUS_CHANGED', data);
      });
      channelInstance.bind('update_tracking', (data) => {
        context.dispatch('UPDATE_TRACKING', data);
      });
      channelInstance.bind('notify_contacts_batch_created', (data) => {
        context.dispatch('NOTIFY_CONTACTS_BATCH_CREATED', data);
      });
    }

    context.commit('SET_CHANNEL', channelInstance);
  },

  disconnectPusher(context: ActionContext<any, any>) {
    const { pusher } = context.state;
    const { channel } = context.state;

    if (channel) {
      channel.unsubscribe();
    }
    if (pusher) {
      pusher.disconnect();
    }

    pusherInstance = null;
    channelInstance = null;
  },

  unsubscribeCurrentUserChannel(context: ActionContext<any, any>) {
    const { channel } = context.state;
    if (channel) {
      channel.unbind_all();
      channel.unsubscribe();
    }
  },

  NOTIFY_CONTACTS_BATCH_CREATED: ({ getters, commit, dispatch, rootGetters }) => {
    commit('UserStore/setImportingContacts', false, { root: true });
    dispatch(
      'UserStore/updateToast',
      {
        text: $t('notification-imported-contacts'),
        type: 'success',
        visible: true,
      },
      { root: true },
    );
  },

  BID_ACCEPTED: async ({ getters, commit, dispatch, rootGetters }, data) => {
    const routeId = router.currentRoute.value.params.id;

    if (Number(routeId) === data.load.id) {
      dispatch('CarrierStore/getLoadInfoForCarrier', data.load.id, { root: true });
    }
  },

  BOL_POD_upload: ({ getters, commit, dispatch, rootGetters }, data) => {
    const url = window.location.href;
    const load = data.load;
    const company = data.company;
    dispatch('UserStore/currentUser', {}, { root: true });
    if (url.includes('service')) {
      const match = url.match(/\/service\/(\d+)$/);
      const id = match ? match[1] : null;

      if (id && Number(load.id) === Number(id)) {
        dispatch('LoadsStore/loadById', data.load.id, { root: true });
      } else {
        dispatch(
          'UserStore/updateToast',
          {
            text: $t('notification-text-type-uploaded', {
              company: company.name,
              type: data.type,
              identifier: load.identifier,
            }),
            type: 'info',
            visible: true,
            id: load.id,
          },
          { root: true },
        );
      }
    } else {
      dispatch(
        'UserStore/updateToast',
        {
          text: $t('notification-text-type-uploaded', {
            company: company.name,
            type: data.type,
            identifier: load.identifier,
          }),
          type: 'info',
          visible: true,
          id: load.id,
        },
        { root: true },
      );
    }
  },
  BOOK_NOW_ACCEPT: ({ getters, commit, dispatch, rootGetters }, data) => {
    const url = window.location.href;
    const load = data.load;
    const company = data.company;
    if (url.includes('service')) {
      const match = url.match(/\/service\/(\d+)$/);
      const id = match ? match[1] : null;

      if (id && Number(load.id) === Number(id)) {
        dispatch('LoadsStore/loadById', load.id, { root: true });
      } else {
        dispatch(
          'UserStore/notificationItems',
          {
            text: '',
            type: 'general',
            visible: true,
            data: {
              loadId: load.id,
              status: load.status,
              company: company.name,
              title: $t('notification-title-book-now-accepted'),
              text: $t('book-now-done', { company: company.name, load: load.id }),
            },
          },
          { root: true },
        );
      }
    } else {
      dispatch(
        'UserStore/notificationItems',
        {
          text: '',
          type: 'general',
          visible: true,
          data: {
            loadId: load.id,
            status: load.status,
            company: company.name,
            title: $t('notification-title-book-now-accepted'),
            text: $t('book-now-done', { company: company.name, load: load.id }),
          },
        },
        { root: true },
      );
    }
  },
  CARRIER_CONFIRM_INFO: async ({ getters, commit, dispatch, rootGetters }, data) => {
    const url = window.location.href;
    const load = data.load;
    const company = data.company;
    const notificationData = {
      text: '',
      type: 'general',
      visible: true,
      data: {
        loadId: load.id,
        status: '',
        company: company.name,
        title: $t('notification-title-carrier-confirmed', { company: company.name }),
        text: $t('notification-text-load-completed', { id: `#23-00${load.id}` }),
      },
    };
    try {
      if (url.includes('service')) {
        const match = url.match(/\/service\/(\d+)$/);
        const id = match ? match[1] : null;

        if (id && Number(load.id) === Number(id)) {
          dispatch(
            'UserStore/updateToast',

            {
              text: $t('notification-title-carrier-confirmed', { company: company.name }),
              type: 'info',
              visible: true,
              id: load.id,
            },
            { root: true },
          );
        } else {
          dispatch(
            'UserStore/updateToast',

            {
              text: $t('notification-title-carrier-confirmed', { company: company.name }),
              type: 'info',
              visible: true,
              id: load.id,
            },
            { root: true },
          );
          // await dispatch('UserStore/notificationItems', notificationData, { root: true });
        }
      } else {
        dispatch(
          'UserStore/updateToast',
          {
            text: $t('notification-title-carrier-confirmed', { company: company.name }),
            type: 'info',
            visible: true,
            id: load.id,
          },
          { root: true },
        );
      }
    } catch (error) {
      notificationData.data.text = $t('notification-error-rc');
      await dispatch('UserStore/notificationItems', notificationData, { root: true });
    }
  },

  ACTIVITY_CHAT_NEW_MESSAGE: ({ getters, commit, dispatch, rootGetters }, obj) => {
    if (+rootGetters['ChatsStore/getChatId'] === +obj.activity.chat_id) {
      dispatch(
        'ChatsStore/getMessages',
        {
          id: obj.activity.chat_id,
          params: { page: 1, maxResults: 100 },
        },
        { root: true },
      );
    }

    const foundLoad = rootGetters['LoadsStore/getLoadsInfo'].find(
      (item) => item.load?.id === obj.activity.load_id,
    );

    if (foundLoad) {
      if (foundLoad.chats.length > 0) {
        const foundChat = foundLoad.chats.find((chat) => chat.chat === obj.activity.chat_id);

        if (foundChat) {
          foundChat.readed = false;
        }
      }
    }

    commit(
      'UserStore/setNewMessageContact',
      {
        userId: rootGetters['UserStore/getCurrentUser'].id,
        data: {
          loadId: obj.activity.load_id,
          id: obj.activity.chat_id,
          type: 'LOAD',
          chat_id: obj.activity.chat_id,
        },
      },
      { root: true },
    );

    commit(
      'LoadsStore/newMessageLoad',
      {
        data: { loadId: obj.activity.load_id, id: obj.activity.chat_id, type: 'LOAD' },
        userId: rootGetters['UserStore/getCurrentUser'].id,
        isReaded: false,
      },
      { root: true },
    );
  },

  CHAT_NEW_MESSAGE: ({ getters, commit, dispatch, rootGetters }, data) => {
    console.log('😎😎😎', data);

    const routeId = router.currentRoute.value.params.id;
    if (+rootGetters['ChatsStore/getChatId'] === +data.id) {
      commit('ChatsStore/pushNewMessage', data, { root: true });
    } else {
      dispatch(
        'UserStore/notificationItems',
        {
          text: '',
          type: 'message',
          visible: true,
          message: data,
        },
        { root: true },
      );
      dispatch('ChatsStore/getTotalUnreadChats', {}, { root: true });
      dispatch('ChatsStore/getUnreadMessages', { page: 1, maxResults: 1000 }, { root: true });
    }

    commit(
      'LoadsStore/newMessageLoad',
      { data, userId: rootGetters['UserStore/getCurrentUser'].id, isReaded: false },
      { root: true },
    );
    if (data.type === 'QUOTING') {
      commit(
        'LoadsStore/newMessageInLoad',
        { data, userId: rootGetters['UserStore/getCurrentUser'].id, isReaded: false },
        { root: true },
      );
    }

    if (data.type === 'LANES_RFP' && data.receiverUserId !== data.writer.id) {
      const currentChatId = data.id;
      const currentRanking = rootGetters['RFPStore/getRankingList'];
      const currentRankingDetailLTL = rootGetters['LoadsStore/getRankingRFP'];

      // IF ROUTE IS RFP DETAIL

      if (router.currentRoute.value.name === 'RFP-laneService') {
        if (currentRankingDetailLTL.length > 0) {
          const foundObject = currentRankingDetailLTL.find(
            (item) => item.lanesChat?.id === currentChatId,
          );

          if (foundObject) {
            const userFound = foundObject.lanesChat.chatMembers.find(
              (item) => item.users.id === rootGetters['UserStore/getCurrentUser'].id,
            );

            if (userFound.readed !== false) {
              userFound.readed = false;

              commit('LoadsStore/setRankingRFP', currentRankingDetailLTL, { root: true });
            }
          }
        }
      }

      // IF ROUTE IS RANKING RFP DETAIL

      if (currentRanking.length > 0) {
        const foundObject = currentRanking.find(
          (item) => item.lanesChatMemberForShipper?.chat?.id === currentChatId,
        );

        if (foundObject) {
          if (foundObject.lanesChatMemberForShipper.readed !== false) {
            foundObject.lanesChatMemberForShipper.readed = false;

            commit('RFPStore/setRankingList', currentRanking, { root: true });
          }
        }
      }

      commit(
        'LoadsStore/newMessageInLoad',
        { data, userId: rootGetters['UserStore/getCurrentUser'].id, isReaded: false },
        { root: true },
      );
    }

    if (window.location.href.includes('carrier')) {
      if (data.type === 'LANES_RFP') {
        if (Number(routeId) === data.projectId) {
          dispatch(
            'RFPStore/getLanesByProjectId',
            { projectId: router.currentRoute.value.params.id, page: 1 },
            { root: true },
          );
        }
      } else {
        if (Number(routeId) === data.loadId) {
          dispatch('CarrierStore/getLoadInfoForCarrier', data.loadId, { root: true });
        }
      }
    }

    commit(
      'UserStore/setNewMessageContact',
      { userId: rootGetters['UserStore/getCurrentUser'].id, data },
      { root: true },
    );
  },
  INVOICE_UPLOAD: ({ getters, commit, dispatch, rootGetters }, data) => {
    const url = window.location.href;
    const load = data.load;
    const company = data.company;
    if (url.includes('service')) {
      const match = url.match(/\/service\/(\d+)$/);
      const id = match ? match[1] : null;

      if (id && Number(load.id) === Number(id)) {
        dispatch('LoadsStore/loadById', load.id, { root: true });
        dispatch('LoadsStore/getDocsList', load.id, { root: true });
      } else {
        dispatch(
          'UserStore/updateToast',

          {
            text: $t('notification-text-invoice-uploaded', {
              company: company.name,
              identifier: load.identifier,
            }),
            type: 'info',
            visible: true,
            id: load.id,
          },
          { root: true },
        );
      }
    } else {
      dispatch(
        'UserStore/updateToast',
        {
          text: $t('notification-text-invoice-uploaded', {
            company: company.name,
            identifier: load.identifier,
          }),
          type: 'info',
          visible: true,
          id: load.id,
        },
        { root: true },
      );
    }
  },
  NOTIFY_LANES: ({ getters, commit, dispatch, rootGetters }, data) => {
    dispatch('RFPStore/getAsyncResultLanes', data.entityId, { root: true });
  },
  NOTIFY_LANES_CREATED: ({ getters, commit, dispatch, rootGetters }, data) => {
    commit('RFPStore/setShowWaitingLanes', false, { root: true });
  },
  NOTIFY_LANES_CLONED_BATCH: ({ getters, commit, dispatch, rootGetters }, data) => {
    console.log('NOTIFY_LANES_CLONED_BATCH');
    const url = window.location.href;
    if (url.includes('lanesRFP')) {
      if (url.includes('creation')) {
        router.push(`/planning/lanesRFP/${data.projectId}`);
      } else {
        dispatch('RFPStore/getProjectsRFPList', {
          page: 1,
          maxResults: 30,
        });
      }
    }
    dispatch(
      'UserStore/updateToast',
      {
        text: $t('notification-lanes-created'),
        type: 'success',
        visible: true,
      },
      { root: true },
    );
  },
  QUOTE_MADE: ({ getters, commit, dispatch, rootGetters }, data) => {
    const url = window.location.href;
    const load = data.load;
    const company = data.company;
    const currentLoad = rootGetters['LoadsStore/currentLoad'];
    const origin = currentLoad?.stops?.find((obj) => obj.type === 'PICKUP_INITIAL');
    const destiny = currentLoad?.stops?.find((obj) => obj.type === 'DELIVERY_FINAL');

    if (url.includes('service')) {
      const match = url.match(/\/service\/(\d+)$/);
      const id = match ? match[1] : null;

      if (id && Number(load.id) === Number(id)) {
        dispatch(
          'UserStore/requestBidStatsByLoad',
          {
            page: 1,
            shipment: currentLoad?.shipment?.id,
            sortBy: `rate[ASC]`,
            trailerTypeStats: currentLoad?.trailerType?.id,
            originZip: origin.location.zip,
            destinyZip: destiny.location.zip,
          },
          { root: true },
        );

        dispatch(
          'ShipmentStore/totalBids',
          {
            id: currentLoad?.shipment?.id,
          },
          { root: true },
        );
      } else {
        dispatch(
          'UserStore/updateToast',

          {
            text: $t('new-quote-made', {
              rate: BaseComponent.methods?.formatPrice(load.quote),
              company: company.name,
              load: load.id,
            }),
            type: 'info',
            visible: true,
            id: load.id,
          },
          { root: true },
        );
      }
    } else {
      const query = rootGetters['LoadsStore/getAppliedFiltersQuery'];
      query.page = 1;
      query.maxResults = 10;
      query.infoType = ['LOADS', 'TOTALS', 'TOTALS_DAY_WEEK', 'TOTALS_DATES'];

      //check
      dispatch('LoadsStore/getLoadsInfo', query, { root: true });

      dispatch(
        'UserStore/updateToast',

        {
          text: $t('new-quote-made', {
            rate: BaseComponent.methods?.formatPrice(load.quote),
            company: company.name,
            load: load.id,
          }),
          type: 'info',
          visible: true,
          id: load.id,
        },
        { root: true },
      );
    }
  },

  // RPF ACTIONS

  RFP_RANKING_ACCEPTED: async ({ getters, commit, dispatch, rootGetters }, data) => {
    const objetToFind = data;
    const currentRanking = rootGetters['RFPStore/getRankingList'];

    const index = currentRanking.findIndex((item) => item.id === objetToFind.quoteRequest.id);

    if (index !== -1) {
      currentRanking[index].status = objetToFind.quoteRequest.status;
      commit('RFPStore/setRankingList', currentRanking, { root: true });
    }
  },

  RFP_RANKING_REFUSED: async ({ getters, commit, dispatch, rootGetters }, data) => {
    const objetToFind = data;
    const currentRanking = rootGetters['RFPStore/getRankingList'];

    const index = currentRanking.findIndex((item) => item.id === objetToFind.quoteRequest.id);

    if (index !== -1) {
      currentRanking[index].status = objetToFind.quoteRequest.status;
      commit('RFPStore/setRankingList', currentRanking, { root: true });
    }
  },

  RFP_BATCH_QUOTED: async ({ getters, commit, dispatch, rootGetters }, data) => {
    const routeId = router.currentRoute.value.params.projectId;
    const projectId = data.project.id;

    if (Number(routeId) === projectId) {
      dispatch(
        'RFPStore/getLanesByProjectId',
        {
          projectId: projectId,
          page: 1,
        },
        { root: true },
      );
    }
  },

  RC_FILLED: async ({ getters, commit, dispatch, rootGetters }, data) => {
    const routeId = router.currentRoute.value.params.id;
    if (Number(routeId) === data.load.id) {
      dispatch('CarrierStore/getLoadInfoForCarrier', data.load.id, { root: true });
    }
  },

  RC_SIGNED: ({ getters, commit, dispatch, rootGetters }, data) => {
    const url = window.location.href;
    const load = data.load;
    const company = data.company;
    if (url.includes('service')) {
      const match = url.match(/\/service\/(\d+)$/);
      const id = match ? match[1] : null;

      if (id && Number(load.id) === Number(id)) {
        dispatch('LoadsStore/loadById', load.id, { root: true });
      } else {
        dispatch(
          'UserStore/updateToast',
          {
            text: $t('notification-title-rc-signed', { company: company.name, id: load.id }),
            type: 'info',
            visible: true,
            id: load.id,
          },
          { root: true },
        );
      }
    } else {
      dispatch(
        'UserStore/updateToast',

        {
          text: $t('notification-title-rc-signed', { company: company.name, id: load.id }),
          type: 'info',
          visible: true,
          id: load.id,
        },
        { root: true },
      );
    }
  },

  RFQ_QUOTED_RECEIVED: async ({ getters, commit, dispatch, rootGetters }, data) => {
    const [{ lanesRFQRequestBidsGroup }] = rootGetters['LanesStore/getLanes'];

    if (lanesRFQRequestBidsGroup === data.RFQRequestBidGroup.id) {
      dispatch('LanesStore/lanesRFQRequestBidsGroup', data.RFQRequestBidGroup.id, { root: true });
    }
  },

  SHIPMENT_STATUS_CHANGED: ({ getters, commit, dispatch, rootGetters }, data) => {
    const id = data.load.id;
    const load = data.load;
    const index = rootGetters['LoadsStore/getLoads'].findIndex((load) => load.id === +id);

    if (data.load.status === 'DELETED') return;

    if (index >= 0) {
      const url = window.location.href;
      if (url.includes('service')) {
        const match = url.match(/\/service\/(\d+)$/);
        const urlID = match ? match[1] : null;

        if (id === Number(urlID)) {
          dispatch('LoadsStore/loadById', load.id, { root: true });
        } else {
          dispatch(
            'UserStore/notificationItems',
            {
              text: '',
              type: 'generalButtons',
              visible: true,
              data: { loadId: load.id, status: load.status },
            },
            { root: true },
          );
        }
      } else {
        dispatch(
          'UserStore/notificationItems',
          {
            text: '',
            type: 'generalButtons',
            visible: true,
            data: { loadId: load.id, status: load.status },
          },
          { root: true },
        );
        commit('LoadsStore/updateLoadStatus', load, { root: true });
      }
    }
  },

  UPDATE_TRACKING: ({ getters, commit, dispatch, rootGetters }, data) => {
    const url = window.location.href;
    const load = data.load;
    const company = data.company;
    if (url.includes('service')) {
      const match = url.match(/\/service\/(\d+)$/);
      const id = match ? match[1] : null;

      if (id && Number(load.id) === Number(id)) {
        dispatch('LoadsStore/loadById', load.id, { root: true });
      } else {
        dispatch(
          'UserStore/notificationItems',
          {
            text: '',
            type: 'general',
            visible: true,
            data: {
              loadId: load.id,
              status: load.status,
              company: company.name,
              title: $t('truck-position-updated'),
              text: $t('company-notification', {
                companyName: company.name,
              }),
            },
          },
          { root: true },
        );
      }
    } else {
      dispatch(
        'UserStore/notificationItems',
        {
          text: '',
          type: 'general',
          visible: true,
          data: {
            loadId: load.id,
            status: load.status,
            company: company.name,
            title: $t('truck-position-updated'),
            text: $t('company-notification', {
              companyName: company.name,
            }),
          },
        },
        { root: true },
      );
    }
  },
};
