
import { defineComponent, reactive, toRefs } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanTemplateButton from '@/components/buttons/PlanTemplateButton.vue';
import { useStore } from 'vuex';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanEmailTemplateCategoryTitle from '@/components/titles/PlanEmailTemplateCategoryTitle.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

interface selectTemplateModal {
  loadingTemplates: boolean,
  templatesPlanimatik: Array<any>,
  templates: Array<any>
}

export default defineComponent({
  name: 'SelectTemplateModal',
  components: { PlanEmailTemplateCategoryTitle, PlanLoading, PlanTemplateButton, PlanButton, PlanDefaultModal },
  emits: ['close', 'addTemplate', 'viewTemplate'],
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive<selectTemplateModal>({
      loadingTemplates: false,
      templatesPlanimatik: [],
      templates: []
    })

    const addTemplate = () => { emit('addTemplate') }

    const close = () => { emit('close') }

    const getTemplates = () => {
      data.loadingTemplates = true
      store.dispatch('LoadsStore/getEmailCategoriesAndTemplates').then((response) => {
        data.templates = response

      }).finally(() => {
        data.loadingTemplates = false
      })
    }

    const getTemplatesFromPlanimatik = () => {
      data.loadingTemplates = true
      store.dispatch('LoadsStore/getEmailTemplatesFromPlanimatik').then((response) => {
        data.templatesPlanimatik = response

      }).finally(() => {
        data.loadingTemplates = false
      })
    }

    const viewTemplate = (template: any, category: any) => {
      store.commit('CompanyStore/selectTemplate', template)
      store.commit('CompanyStore/selectTemplateCategory', category)
      emit('viewTemplate', template.id)
    }

    getTemplates()
    getTemplatesFromPlanimatik()

    return {
      ...toRefs(data),
      addTemplate,
      close,
      getTemplates,
      viewTemplate
    }
  }
})
