import shipmentApi from '@/services/shipment/_api';

export default {
  async acceptBid(context: any, data: any) {
    await shipmentApi.acceptBid(data);
  },
  async acceptBookNow(context: any, data: any) {
    await shipmentApi.acceptBookNow(data);
  },
  async acceptLTLQuote(context: any, data: any) {
    await shipmentApi.acceptLTLQuote(data);
  },
  async acceptService(context: any, data: any) {
    await shipmentApi.acceptService(data);
  },
  async assignDriver(context: any, data: any) {
    await shipmentApi.assignDriver(data);
  },
  async assignInsurance(context: any, data: any) {
    await shipmentApi.assignInsurance(data);
  },
  async assignVehicle(context: any, data: any) {
    return await shipmentApi.assignVehicle(data);
  },
  async clearShipmentStore(context: any) {
    context.commit('clearShipmentStore');
  },
  async declineCurrentBid(context: any, data: any) {
    await shipmentApi.declineCurrentBid(data);
  },
  async downLoadAllDocuments(context: any, data: any) {
    return await shipmentApi.downLoadAllDocuments(data);
  },
  async getBOLAutogenerated(context: any, data: any) {
    return await shipmentApi.getBOLAutogenerated(data);
  },
  async getActivitySummary(context: any, data: any) {
    const response = await shipmentApi.getActivitySummary(data);
    context.commit('setActivitySummary', response);
    return response;
  },
  async getDownloadFile(context: any, data: any) {
    return await shipmentApi.getDownloadFile(data);
  },
  async getDownloadFileBlob(context: any, data: any) {
    return await shipmentApi.getDownloadFileBlob(data);
  },
  async getLTLShipmentBid(context: any, data: any) {
    const response = await shipmentApi.getLTLShipmentBid(data);
    context.commit('companyAccepted', response);
    return response;
  },
  async getSavedShipmentBids(context: any, data: any) {
    const response = await shipmentApi.getSavedShipmentBids(data);
    context.commit('getSavedShipmentBids', response);
  },
  async notifyPosition(context: any, data: any) {
    await shipmentApi.notifyPosition(data);
  },
  async postCargobotUpload(context: any, data: any) {
    return await shipmentApi.postCargobotUpload(data);
  },
  async postInsurances(context: any, data: any) {
    return await shipmentApi.postInsurances(data);
  },
  async postInvoiceFTL(context: any, data: any) {
    return await shipmentApi.postInvoiceFTL(data);
  },
  async postMediaObjectStops(context: any, data: any) {
    await shipmentApi.postMediaObjectStops(data);
  },
  async postMediaObjectInsurance(context: any, data: any) {
    return await shipmentApi.postMediaObjectInsurance(data);
  },
  async postMediaObjectInvoice(context: any, data: any) {
    await shipmentApi.postMediaObjectInvoice(data);
  },
  async postUpdateMediaObjectInsurance(context: any, data: any) {
    return await shipmentApi.postUpdateMediaObjectInsurance(data)
  },
  async putInsurances(context: any, data: any) {
    await shipmentApi.putInsurances(data);
  },
  async requestBid(context: any, data: any) {
    await shipmentApi.requestBid(data);
  },
  async requestPosition(context: any, data: any) {
    await shipmentApi.requestPosition(data);
  },
  async reSendRequestBid(context: any, data: any) {
    const response = await shipmentApi.reSendRequestBid(data);
    return response;
  },
  async saveShipmentBids(context: any, data: any) {
    await shipmentApi.saveShipmentBids(data);
  },
  async shipment(context: any, data: any) {
    const response = await shipmentApi.getShipment(data);
    context.commit('shipment', response);
  },
  async signsRateConfirmation(context: any, data: any) {
    return await shipmentApi.signsRateConfirmation(data);
  },
  async thumbnailBig(context: any, data: any) {
    const response = await shipmentApi.getDownloadThumbnailBig(data);
    context.commit('getImage', response);
    return response;
  },
  async thumbnailSmall(context: any, data: any) {
    return await shipmentApi.getDownloadThumbnailSmall(data);
  },
  async thumbnailWithoutSign(context: any, data: any) {
    return await shipmentApi.thumbnailWithoutSign(data);
  },
  async totalBids(context: any, data: any) {
    const response = await shipmentApi.totalBids(data);
    context.commit('totalBids', response);
  },
  async updateBid(context: any, data: any) {
    await shipmentApi.updateBid(data);
  },
  async updateInvoiceStatus(context: any, data: any) {
    return await shipmentApi.updateInvoiceStatus(data);
  },
  async updateTrackingSubStatus(context: any, data: any) {
    return await shipmentApi.updateTrackingSubStatus(data);
  },
  async sendDocumentToEmail(context: any, data: any) {
    return await shipmentApi.sendDocumentToEmail(data);
  },
};
