
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateSpin.vue';
// Services
import * as Global from '@/services/global/GlobalConst';
// Types
import { UserRoles } from '@/services/user/UserTypes';
import PhoneNumberSelector from "@/components/forms/PhoneNumberSelector.vue";

export default defineComponent({
  name: 'ActivateAccountView',
  components: {
    PhoneNumberSelector,
    ErrorMessage,
    Field,
    Form,
    Spin,
  },
  mixins: [BaseComponent],
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      name: '',
      surname: '',
      prefix: '1',
      phone: '',
      password: '',
      repeatPassword: '',
      error: false,
      loading: false,
      differentPassword: false,
      privacyTerms: false,
      URLPrivacy: Global.URL_PRIVACY,
      URLTerms: Global.URL_TERMS,
    };
  },
  async beforeCreate(): Promise<any> {
    if (this.token) {
      localStorage.token = this.token;
      await this.$store.dispatch('UserStore/currentUser');
      this.$store.dispatch('UserStore/getUser', { id: this.currentUser.id }).then((resp) => {
        this.name = resp.name;
        this.surname = resp.surname;
        this.prefix = resp.phonePrefix;
        this.phone = resp.phone;
      });
    }
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
  },
  methods: {
    changePhone(type, phone){
      switch (type){
        case 'prefix':
          this.prefix = phone.prefix;
          break;
        case 'number':
          this.phone = phone.number;
          break;
        default:
          return
      }
    },
    checkUserType() {
      if (this.currentUser && this.currentUser.roles) {
        let isShipper = false;
        this.currentUser.roles.forEach((role) => {
          if (role.includes(UserRoles.SHIPPER)) {
            isShipper = true;
          }
        });
        if (isShipper) {
          this.$router.push('/planning');
        }
      }
    },
    async validateForm() {
      if (!this.privacyTerms) {
        return;
      }
      this.loading = true;
      this.differentPassword = false;
      const validation = await (this.$refs.registerForm as any).validate();
      if (this.password !== this.repeatPassword) {
        this.differentPassword = true;
      }
      if (!validation.valid || this.differentPassword) {
        this.loading = false;
        return;
      }
      await this.$store
        .dispatch('UserStore/putUser', {
          id: this.currentUser.id,
          user: {
            name: this.name,
            surname: this.surname,
            phonePrefix: this.prefix,
            phone: this.phone,
          },
        })
        .catch((err) => (this.loading = false));
      await this.$store
        .dispatch('UserStore/registrationUpdatePassword', {
          id: this.currentUser.id,
          password: this.password,
        })
        .catch((err) => (this.loading = false));

      if (this.loading) {
        this.$store
          .dispatch('AuthStore/signIn', {
            username: this.currentUser.email,
            password: this.password,
          })
          .catch((err) => {
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
            this.checkUserType();
          });
      }
    },
  },
});
