
import { defineComponent} from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'PlanLoading',
  components: { PlanIcon },
  props: {
    message: { type: String, required: false, default: '' },
    subMessage: { type: String, required: false, default: '' },
  }
})
