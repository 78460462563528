import { initialState } from '@/services/notification/_store/index';

export default {
  clearNotificationStore(state: any) {
    Object.assign(state, initialState);
  },
  setEntryNotifications(state: any, payload: any) {
    state.entryNotifications = payload;
  },
};
