
import { defineComponent } from 'vue';
import { StarIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import draggable from 'vuedraggable';
import RankingRefusedItemsLeft from '@/modules/rfp/_components/ranking/RankingRefusedItemsLeft.vue';
// Utils
import { numberSuffixAndColor } from '@/utils/formatString';

export default defineComponent({
  components: {
    draggable,
    StarIcon,
    RankingRefusedItemsLeft,
  },
  mixins: [BaseComponent],
  props: {
    currentLane: { type: Object },
    filters: { type: Object },
    finalList: { type: Array as any },
    getRowStyle: { type: Function, required: true },
    getUserDefaultImage: { type: Function, required: true },
    isLastInGroup: { type: Function, required: true },
    isReaded: { type: Function, required: true },
    loads: { type: Object, default: null },
    openChat: { type: Function, required: true },
    quotes: { type: Array },
    statusDeclined: { type: Boolean as any, required: true },
  },
  data() {
    return {
      isDragging: false,
      startX: 0,
      currentX: 0,
      dragX: 0,
      scrollY: 0,
    };
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    numberSuffixAndColor(): any {
      return numberSuffixAndColor;
    },
  },
  methods: {
    start(event) {
      if (this.isShipperViewer) {
        return;
      } else {
        this.$emit('onDragStart', event);
      }
    },
    checkMove(evt) {
      return this.statusDeclined(evt.draggedContext.element.status);
    },
    onDragEnd(event) {
      if (this.isShipperViewer) {
        return;
      } else {
        this.$emit('onDragEnd', event);
      }
    },
    onDragMove(event) {
      if (this.isShipperViewer) {
        return;
      } else {
        if (this.isDragging) {
          this.currentX = event.clientX;
          this.dragX = this.currentX - this.startX;
          const widthTable = this.$el.querySelector('#rankingTable').offsetWidth;
          const maxDragX = widthTable - this.$el.offsetWidth;
          this.dragX = Math.min(Math.max(this.dragX, 0), maxDragX);
        }
      }
    },
    onDragStart(event) {
      if (this.isShipperViewer) {
        return;
      } else {
        const widthTable = this.$el.querySelector('#rankingTable').offsetWidth;
        this.isDragging = true;
        this.startX = event.clientX;
      }
    },
  },
});
