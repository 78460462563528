
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'PlanCarrierShareLoad',
  components: { PlanAvatar, PlanButtonIcon, PlanCarrierDefaultModal, PlanInputText, PlanButton },
  setup () {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      loading: false,
      showModal: false,
      email: '',
      emailToDelete: ''
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const getCompany = async () => {
      await store.dispatch('CompanyStore/getCompany', store.getters['UserStore/getCurrentUser'].company.id)
    }

    const hideShareLoad = () => {
      store.commit('CarrierStore/setShowShareLoad', false)
    }

    const isValidEmail = computed(() => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(data.email) && !loadNotificationEmails.value.includes(data.email);
    })

    const loadNotificationEmails = computed(() => {
      return store.getters['CompanyStore/getCompany'].loadNotificationEmails
    })

    const putCompanyLoadNotificationEmails = async () => {
      data.loading = true
      const currentEmails = store.getters['CompanyStore/getCompany'].loadNotificationEmails;
      const updatedEmails = [...currentEmails, data.email];

      await store.dispatch('CompanyStore/updateLoadEmails', {
        id: store.getters['CompanyStore/getCompany'].id,
        loadNotificationEmails: updatedEmails
      })
      await getCompany()
      data.email = ''
      await store
        .dispatch('UserStore/updateToast', {
          text: translate.t('success-load-company-email-notification-add'),
          type: 'success',
          visible: true
        })
      data.loading = false
    }

    const removeCompanyLoadNotificationEmails = async (emailToRemove: string) => {
      data.emailToDelete = emailToRemove
      const currentEmails = store.getters['CompanyStore/getCompany'].loadNotificationEmails;
      let updatedEmails = [...currentEmails];
      updatedEmails.splice(currentEmails.indexOf(emailToRemove), 1)

      await store.dispatch('CompanyStore/updateLoadEmails', {
        id: store.getters['CompanyStore/getCompany'].id,
        loadNotificationEmails: updatedEmails
      })
      await getCompany()
      data.email = ''
      await store
        .dispatch('UserStore/updateToast', {
          text: translate.t('success-load-company-email-notification-delete'),
          type: 'success',
          visible: true
        })
      data.emailToDelete = ''
    }

    getCompany()

    return {
      ...toRefs(data),
      actualWidth,
      hideShareLoad,
      loadNotificationEmails,
      isValidEmail,
      putCompanyLoadNotificationEmails,
      removeCompanyLoadNotificationEmails
    }
  }
})
