
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DirectAssignModal from '@/modules/rfp/_components/modals/DirectAssignModal.vue';
import HeaderNewLoad from '@/modules/rfp/_components/headers/HeaderNewLoad.vue';
import LoadsPlanningDates from '@/modules/rfp/lanesCreateLoads/LoadsPlannigDates.vue';
import RankingRFPModal from '@/modules/rfp/_components/modals/RankingRFPModal.vue';
// Utils
import moment from 'moment';
import { getDayBeforeFilters } from '@/utils/formatDates';
// Mixins
import CountryMixins from '@/services/country/_mixins';

export default defineComponent({
  name: 'NewLoads',
  components: {
    DirectAssignModal,
    Field,
    HeaderNewLoad,
    LoadsPlanningDates,
    RankingRFPModal,
  },
  mixins: [BaseComponent, CountryMixins],
  data() {
    return {
      dateFormat: 'MM-dd-yyyy',
      currentWeeksPlanning: [] as any,
      currentWeek: null,
      currentDay: null,
      currentPlanningLoads: 2,
      directAssignUser: {},
      loading: false,
      plannedAmount: [] as any,
      hasGeneratedPeriods: false,
      showRankingModal: false,
      showAutoFill: false,
      toggleDirectAssignModal: false,
      weeklyDates: [] as any,
    };
  },
  created() {
    this.$store.dispatch('RFPStore/getPlannedLanesWeeks', this.$route.params.laneId);
    this.$store.dispatch('RFPStore/getLaneById', this.$route.params.laneId);
    this.$store.dispatch('RFPStore/getProjectById', {
      projectId: this.$route.params.projectId,
      page: 1,
    });
  },
  beforeUnmount() {
    this.currentWeeksPlanning = [];
  },
  computed: {
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    getDayOptions() {
      const options = [] as any;
      const daysOfWeek = {
        europe: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        usa: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      };

      const currentLocale = this.isEurope ? 'europe' : 'usa';

      daysOfWeek[currentLocale].forEach((day, index) => {
        options.push({
          text: this.$t(`daysOfWeek.${day.toLowerCase()}`),
          value: currentLocale === 'europe' ? index + 1 : index,
        });
      });

      return options;
    },
    calculatedValue(): any {
      return this.currentLane.loadsFrequencyPeriod === 'WEEK'
        ? this.calculateWeeks * this.currentLane.loadsFrequency - this.totalPlannedAmount
        : this.calculateTotalMonths * this.currentLane.loadsFrequency - this.totalPlannedAmount;
    },
    calculateTotalMonths(): any {
      const startDate = this.formatDate(this.origin?.executionDate, true);
      const endDate = this.formatDate(this.destiny?.executionDate, true);

      const start = new Date(startDate);
      const end = new Date(endDate);

      start.setDate(1);
      end.setMonth(end.getMonth() + 1, 0);

      const monthsBetween =
        (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

      let actualMonths = 0;

      for (let i = 0; i <= monthsBetween; i++) {
        const currentMonth = new Date(start.getFullYear(), start.getMonth() + i, 1);
        if (currentMonth >= start && currentMonth <= end) {
          actualMonths++;
        }
      }
      return actualMonths;
    },
    calculateWeeks(): any {
      if (this.origin?.executionDate && this.destiny?.executionDate) {
        const startDate = new Date(this.formatDate(this.origin?.executionDate, true));
        const endDate = new Date(this.formatDate(this.destiny?.executionDate, true));

        let startDayOfWeek = startDate.getDay();
        let endDayOfWeek = endDate.getDay();

        if (this.currentUser?.company?.country?.region?.name === 'EUROPE') {
          startDayOfWeek = (startDayOfWeek + 6) % 7;
          endDayOfWeek = (endDayOfWeek + 6) % 7;
        }

        const startAdjustment = -startDayOfWeek;
        const endAdjustment = 6 - endDayOfWeek;

        const firstDateOfWeek = new Date(startDate);
        firstDateOfWeek.setDate(startDate.getDate() + startAdjustment);

        const lastDateOfWeek = new Date(endDate);
        lastDateOfWeek.setDate(endDate.getDate() + endAdjustment);

        const oneDay = 24 * 60 * 60 * 1000;
        const daysDifference = Math.floor(
          (lastDateOfWeek.getTime() - firstDateOfWeek.getTime()) / oneDay,
        );

        const weeks = Math.ceil((daysDifference + 1) / 7);

        return weeks;
      } else {
        return null;
      }
    },
    currentLane(): any {
      return this.$store.getters['RFPStore/getCurrentLane'];
    },
    currentPlanning(): any {
      return this.$store.getters['RFPStore/getCurrentQuotesPlanned'];
    },
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    destiny(): any {
      if (this.currentLane && this.currentLane.loadTemplate) {
        const deliveryFinalStop = this.currentLane.loadTemplate.stops.find(
          (obj) => obj.type === 'DELIVERY_FINAL',
        );
        return deliveryFinalStop || null;
      }
      return null;
    },
    getDayBeforeFilters(): any {
      return getDayBeforeFilters;
    },
    origin(): any {
      if (this.currentLane && this.currentLane.loadTemplate) {
        const pickupInitialStop = this.currentLane.loadTemplate.stops.find(
          (obj) => obj.type === 'PICKUP_INITIAL',
        );
        return pickupInitialStop || null;
      }
      return null;
    },
    totalPlannedAmount(): any {
      return this.currentPlanning.reduce((total, item) => {
        const plannedAmount = Math.min(item.plannedAmount, this.currentLane.loadsFrequency);
        return total + plannedAmount;
      }, 0);
    },
  },
  methods: {
    generateObjectPerPeriod() {
      let period =
        this.currentLane.loadsFrequencyPeriod === 'WEEK'
          ? this.calculateWeeks
          : this.calculateTotalMonths;

      for (let i = 0; i < period; i++) {
        const totalLoadsLeft = this.currentPlanning[i]?.plannedAmount ?? 0;

        const weekObjects = [] as any;
        for (let j = totalLoadsLeft; j < this.currentLane.loadsFrequency; j++) {
          const periodObject = {
            week: i + 1,
            po: '',
            autoAssignment: this.currentProject?.project?.quoteRequestTotal > 0 ? true : false,
            stops: this.orderedStops(this.currentLane.loadTemplate.stops).map((stop) => ({
              loadTemplateStopId: stop.id,
              executionDate: '',
            })),
            directAssign: {},
          };
          weekObjects.push(periodObject);
        }

        this.currentWeeksPlanning.push(weekObjects);
      }
    },
    handleAddNewItem(index) {
      this.currentWeeksPlanning[index].push({
        week: index + 1,
        po: '',
        autoAssignment: this.currentProject?.project?.quoteRequestTotal > 0 ? true : false,
        stops: this.orderedStops(this.currentLane.loadTemplate.stops).map((stop) => ({
          loadTemplateStopId: stop.id,
          executionDate: '',
        })),
        directAssign: {},
      });
    },
    handleDayUSA(dateString, weekToAdd, selectedDay, indexArray, weekItems) {
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      let date;
      if (indexArray !== 0) {
        date = new Date(this.formatDate(weekItems[indexArray - 1].executionDate));
      } else {
        date = new Date(this.formatDate(dateString));
      }

      date.setDate(date.getDate() + weekToAdd * 7);
      const selectedDayOfWeek = daysOfWeek[selectedDay];
      const selectedDayIndex = daysOfWeek.indexOf(selectedDayOfWeek);
      const daysDifference = selectedDayIndex - date.getDay();

      if (daysDifference < 0 && weekToAdd === 0) {
        date.setDate(date.getDate() + daysDifference);
      } else {
        const daysToAdd = (selectedDayIndex + 7 - date.getDay()) % 7;
        date.setDate(date.getDate() + daysToAdd);
      }

      if (indexArray > 0) {
        if (indexArray !== 0 && weekItems[indexArray - 1].executionDate > date) {
          date.setDate(date.getDate() + 7);
        }
      }

      return date;
    },
    handleDayEurope(dateString, weekToAdd, selectedDay, indexArray, weekItems) {
      let date;
      if (indexArray !== 0) {
        date = new Date(this.formatDate(weekItems[indexArray - 1].executionDate));
      } else {
        date = new Date(this.formatDate(dateString));
      }
      date.setDate(date.getDate() + weekToAdd * 7);

      const selectedDayIndex = selectedDay;

      let daysDifference = selectedDayIndex - date.getDay();

      if (daysDifference < 0 && weekToAdd === 0) {
        daysDifference += 7;
      }

      date.setDate(date.getDate() + daysDifference);

      if (indexArray > 0) {
        if (indexArray !== 0 && weekItems[indexArray - 1].executionDate > date) {
          date.setDate(date.getDate() + 7);
        }
      }

      return date;
    },
    handleDaySelection(dateString, weekToAdd, selectedDay, indexArray, weekItems) {
      if (this.isEurope) {
        return this.handleDayEurope(dateString, weekToAdd, selectedDay, indexArray, weekItems);
      } else {
        return this.handleDayUSA(dateString, weekToAdd, selectedDay, indexArray, weekItems);
      }
    },
    handleItemsChange(dateString, weekToAdd, selectedDay, stopPosition, inputPosition) {
      this.currentWeeksPlanning.forEach((item) => {
        item.forEach((stopItem, index) => {
          if (index === inputPosition) {
            stopItem.stops[stopPosition].executionDate = this.handleDaySelection(
              dateString,
              stopItem.week - 1,
              selectedDay,
              stopPosition,
              stopItem.stops,
            );
          }
        });
      });
    },
    handleRemoveItem(position, index) {
      this.currentWeeksPlanning[index].splice(position, 1);
    },
    monthDates() {
      const start = this.origin?.executionDate;
      const endDate = this.destiny?.executionDate;

      const monthDates = [] as any;

      let currentDate = new Date(this.formatDate(start, true));
      let end = new Date(this.formatDate(endDate, true));
      let UTC = this.currentUser?.company?.country?.region?.name === 'EUROPE' ? false : true;

      while (currentDate <= end) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDayOfMonth = new Date(year, month, 0);

        monthDates.push({
          start: this.formatDate(firstDayOfMonth, true, UTC),
          end: this.formatDate(lastDayOfMonth, true, UTC),
        });

        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      monthDates[0].start = this.formatDate(this.origin?.executionDate, true);
      monthDates[monthDates.length - 1].end = this.formatDate(this.destiny?.executionDate, true);

      this.weeklyDates = monthDates;
    },
    orderedStops(stops) {
      const initialPickup = stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      const pickups = stops.filter((stop) => stop.type === 'PICKUP');
      const deliveries = stops.filter((stop) => stop.type === 'DELIVERY');
      const finalDelivery = stops.find((stop) => stop.type === 'DELIVERY_FINAL');
      return [initialPickup, ...pickups, ...deliveries, finalDelivery].filter((stop) => stop);
    },
    sendLoads() {
      const loads = [] as any;
      const filteredData = this.currentWeeksPlanning.map((week) =>
        week.filter((stop) => !stop.stops.some((s) => s.executionDate === '')),
      );
      this.currentWeeksPlanning = filteredData;
      for (let j = 0; j < this.currentWeeksPlanning.length; j++) {
        for (let index = 0; index < this.currentWeeksPlanning[j].length; index++) {
          loads.push(this.currentWeeksPlanning[j][index]);
        }
      }

      if (this.currentProject?.project?.quoteRequestTotal > 0) {
        this.setLoading(true);
        this.$store
          .dispatch('RFPStore/postRFPLoadsPlanningBatch', {
            laneId: this.currentLane.id,
            loads,
          })
          .then(() => {
            this.setLoading(false);
            BaseComponent.methods?.showToastSuccess(this.$t('loads-created'));
            this.$store.commit('RFPStore/setCurrentQuotesPlanned', []);
            this.$router.push({
              name: 'RFP-laneDetail',
              params: {
                projectId: this.$route.params.projectId,
                laneId: this.$route.params.laneId,
              },
            });
          })
          .catch((err) => {
            this.setLoading(false);
            BaseComponent.methods?.showToastError(err?.response?.data?.details);
          });
      } else {
        const isEmptyDirectAssign = loads.some(
          (item) => !item.directAssign || Object.keys(item.directAssign).length === 0,
        );

        if (isEmptyDirectAssign) {
          BaseComponent.methods?.showToastError(this.$t('direct-assign-user'));
        } else {
          this.setLoading(true);
          this.$store
            .dispatch('RFPStore/postRFPLoadsPlanningBatch', {
              laneId: this.currentLane.id,
              loads,
            })
            .then(() => {
              this.setLoading(false);
              this.$store.dispatch('RFPStore/getPlannedLanesWeeks', this.$route.params.laneId);
              BaseComponent.methods?.showToastSuccess(this.$t('loads-created'));
              this.$router.push({
                name: 'RFP-laneDetail',
                params: {
                  projectId: this.$route.params.projectId,
                  laneId: this.$route.params.laneId,
                },
              });
            })
            .catch((err) => {
              this.setLoading(false);
              BaseComponent.methods?.showToastError(err?.response?.data?.details);
            });
        }
      }
    },
    setLoading(value) {
      this.loading = value;
    },
    setupPeriodDates() {
      if (this.currentLane.loadsFrequencyPeriod === 'WEEK') {
        this.weekDates();
      } else {
        this.monthDates();
      }
    },
    toggleDirectAssign(item) {
      this.toggleDirectAssignModal = !this.toggleDirectAssignModal;
      this.directAssignUser = item;
    },
    weekDates() {
      const weekDates = [] as any;
      let UTC = this.currentUser?.company?.country?.region?.name === 'EUROPE' ? false : true;

      for (let i = 0; i < this.calculateWeeks; i++) {
        const start = new Date(this.formatDate(this.origin?.executionDate, true));
        const currentDate = new Date(this.formatDate(this.origin?.executionDate, true));

        // This methods needs refactor with moment now is adapted for EU
        if (this.currentUser?.company?.country?.region?.name === 'EUROPE' && start.getDay() === 0) {
          if (i === 0) {
            currentDate.setDate(start.getDate() + 1);
          } else {
            currentDate.setDate(start.getDate() + (i - 1) * 7);
          }
        } else {
          currentDate.setDate(start.getDate() + i * 7);
        }

        let dayOfWeek = currentDate.getDay();

        const startOfWeek = new Date(currentDate);
        const endOfWeek = new Date(currentDate);

        if (this.currentUser?.company?.country?.region?.name === 'EUROPE') {
          if (start.getDay() == 0 && i == 0) {
            startOfWeek.setDate(start.getDate());
            endOfWeek.setDate(start.getDate());
          } else {
            startOfWeek.setDate(currentDate.getDate() - dayOfWeek + 1);
            endOfWeek.setDate(currentDate.getDate() + (6 - dayOfWeek + 1));
            UTC = false;
          }
        } else {
          startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
          endOfWeek.setDate(currentDate.getDate() + (6 - dayOfWeek));
        }

        weekDates.push({
          start: this.formatDate(startOfWeek, true, UTC),
          end: this.formatDate(endOfWeek, true, UTC),
        });
      }

      weekDates[0].start = this.formatDate(this.origin?.executionDate, true);
      weekDates[weekDates.length - 1].end = this.formatDate(this.destiny?.executionDate, true);

      this.weeklyDates = weekDates;
    },
  },
  watch: {
    destiny: function (newDestiny, oldDestiny) {
      if (!this.hasGeneratedPeriods && newDestiny !== oldDestiny) {
        this.hasGeneratedPeriods = true;
        this.setupPeriodDates();
        this.generateObjectPerPeriod();
      }
    },
    origin(newOrigin, oldOrigin) {
      if (!this.hasGeneratedPeriods && newOrigin !== oldOrigin) {
        this.hasGeneratedPeriods = true;
        this.setupPeriodDates();
        this.generateObjectPerPeriod();
      }
    },
  },
});
