import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "plan-t-prim-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_text_button = _resolveComponent("plan-text-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex justify-start plan-gap-sm", _ctx.actualWidth <= 640 ? 'flex-col items-start':'items-center'])
  }, [
    _createVNode(_component_plan_text_button, {
      onOnClick: _ctx.goToThumbnailBig,
      loading: _ctx.loading,
      label: _ctx.label,
      icon: "checked-document",
      "icon-size": "16"
    }, null, 8, ["onOnClick", "loading", "label"]),
    (_ctx.description.length > 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true)
  ], 2))
}