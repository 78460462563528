
import { defineComponent, reactive, toRefs } from 'vue';
import PlanFilterPickupAndDelivery from '@/components/filters/PlanFilterPickupAndDelivery.vue';
import PlanDefaultWindowsTab from '@/components/tabs/PlanDefaultWindowsTabs.vue';
import PlanFilter from '@/components/filters/PlanFilter.vue';
import LoadTimeline from '@/components/loads/LoadTimeline.vue';
import LoadCard from '@/components/loads/card/LoadCard.vue';
import PlanDefaultWindows from '@/components/defaults/PlanDefaultWindows.vue';
import PlanCalendar from '@/components/forms/PlanCalendar.vue';
import PlanBadge from '@/components/badges/PlanBadge.vue';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import PlanButtonGroup from '@/components/buttons/PlanButtonGroup.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonDocumentation from '@/components/uiComponents/components/PlanButtonDocumentation.vue';

export default {
  name: 'UiComponentsTab',
  components: { PlanButtonDocumentation, PlanFilterPickupAndDelivery, PlanDefaultWindowsTab, PlanFilter, LoadTimeline, LoadCard, PlanDefaultWindows, PlanCalendar, PlanBadge, PlanTabs, PlanButtonGroup, PlanIcon, PlanButtonIcon, PlanButton },
  setup () {
    const data = reactive({
      showPlanBadges: false,
      showPlanButtons: false,
      showPlanButtonGroup: false,
      showPlanButtonIcons: false,
      showPlanIcons: false,
      showPlanTabs: false,
      showPlanCalendar: false,
      showPlanDefaultWindows: false,
      showPlanDefaultWindowsTab: false,
      showPlanDefaultWindowsWithTwoColumns: false,
      showPlanDefaultWindowsWithOneColumns: false,
      showLoadCard: false,
      showLoadTimeline: false,
      showPlanFilter: false,
      showPlanFilterPickupAndDelivery: false,
      showFormScss: false,
      buttonGroup: [
        { label: '', icon: 'menu-ham' },
        { label: '', icon: 'calendar' }
      ],
      tabs: [
        { label: 'Total', quantity: 1789 },
        { label: 'Accepted', quantity: 1700 },
        { label: 'Rejected', quantity: 89 }
      ],
      windowsTab: [
        { label: 'Label', icon: 'circle-check' },
        { label: 'Label', icon: '' },
        { label: 'Label', icon: '' },
      ]
    })

    return {
      ...toRefs(data)
    }
  }
};
