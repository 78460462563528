
import { defineComponent, computed, reactive, toRefs } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SuccessBlastEmailModal',
  components: { PlanButton, PlanIcon, PlanDefaultModal },
  emits: ['close', 'closeAndShowAdd'],
  props: {
    title: { type: String, required: true, default: '' },
    templateId: { type: Number, required: false, default: -1 }
  },
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      loadingPut: false
    })

    const close = () => {
      store.commit('CompanyStore/clearSelectedTemplateToSend')
      store.commit('CompanyStore/clearSelectedEmailToSend')
      emit('close')
    }

    const closeAndShowAdd = () => {
      store.commit('CompanyStore/setTemplateToClone', {
        name: '',
        subject: store.getters['CompanyStore/getSelectedEmailToSend'].subject,
        message: store.getters['CompanyStore/getSelectedEmailToSend'].message
      })
      emit('closeAndShowAdd')
    }

    const putEmailTemplate = () => {
      data.loadingPut = true
      store.dispatch('CompanyStore/putEmailTemplate', {
        id: store.getters['CompanyStore/getSelectedTemplateToSend'].id,
        templateName: store.getters['CompanyStore/getSelectedTemplateToSend'].templateName,
        subject: store.getters['CompanyStore/getSelectedEmailToSend'].subject,
        message: store.getters['CompanyStore/getSelectedEmailToSend'].message
      }).then((response) => {
        store.commit('CompanyStore/selectTemplate', response)
        store.dispatch('UserStore/showToast', translate.t('success-template-updated'))
        store.commit('CompanyStore/clearSelectedTemplateToSend')
        store.commit('CompanyStore/clearSelectedEmailToSend')
        emit('close')
      }).finally(() => {
        data.loadingPut = false
      })
    }

    const selectedEmailToSend = computed(() => {
      return store.getters['CompanyStore/getSelectedEmailToSend']
    })

    const selectedTemplate = computed(() => {
      return store.getters['CompanyStore/getSelectedTemplateToSend']
    })

    const thereAreChangesInTheTemplate = computed(() => {
      if (store.getters['CompanyStore/getSelectedTemplateToSend'] !== null &&
          !store.getters['CompanyStore/getSelectedTemplateToSend'].readOnly)
      {
        const templateSubject = store.getters['CompanyStore/getSelectedTemplateToSend'].subject
        const emailSubject = store.getters['CompanyStore/getSelectedEmailToSend'].subject

        const templateMessage = store.getters['CompanyStore/getSelectedTemplateToSend'].message
        const emailMessage = store.getters['CompanyStore/getSelectedEmailToSend'].message

        return templateSubject !== emailSubject || templateMessage !== emailMessage
      }

      return false
    })

    return {
      ...toRefs(data),
      close,
      closeAndShowAdd,
      putEmailTemplate,
      selectedEmailToSend,
      selectedTemplate,
      thereAreChangesInTheTemplate
    }
  }
})
