import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white min-h-screen h-auto flex-1 xl:ml-90" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "w-full flex justify-center pb-4" }
const _hoisted_4 = {
  key: 1,
  class: "bg-plan-gray-100",
  style: {"padding":"24px"}
}
const _hoisted_5 = { class: "flex w-full justify-between items-start gap-2" }
const _hoisted_6 = {
  class: "flex justify-start items-center plan-gap-xs box-border flex-wrap",
  style: {"width":"100%","position":"relative"}
}
const _hoisted_7 = { class: "flex justify-center items-center plan-gap-sm" }
const _hoisted_8 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderLaneDetails = _resolveComponent("HeaderLaneDetails")!
  const _component_LanesList = _resolveComponent("LanesList")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_plan_filter = _resolveComponent("plan-filter")!
  const _component_loads_calendar = _resolveComponent("loads-calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderLaneDetails, {
      laneListObtained: _ctx.laneListObtained,
      onChangeView: _ctx.changeCurrentView,
      onFetchCalendarByLane: _ctx.fetchCalendarByLane,
      onCleanFilters: _ctx.cleanFilters
    }, null, 8, ["laneListObtained", "onChangeView", "onFetchCalendarByLane", "onCleanFilters"]),
    (_ctx.currentView === 'table')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_LanesList, { laneListObtained: _ctx.laneListObtained }, null, 8, ["laneListObtained"]),
          _createElementVNode("div", _hoisted_3, [
            (!_ctx.loading && _ctx.currentLanes.length > 0 && _ctx.currentLanes.length < _ctx.totalLanes)
              ? (_openBlock(), _createBlock(_component_plan_button, {
                  key: 0,
                  onOnClick: _ctx.fetchLanesDetail,
                  loading: _ctx.loading,
                  type: "primary-outline",
                  "full-rounded": "",
                  label: _ctx.$t('show-more'),
                  style: {"width":"328px"}
                }, null, 8, ["onOnClick", "loading", "label"]))
              : _createCommentVNode("", true),
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_plan_loading, {
                  key: 1,
                  class: "plan-t-prim-100"
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.currentView === 'calendar')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planFilters, (planFilter) => {
                return (_openBlock(), _createBlock(_component_plan_filter, {
                  key: planFilter,
                  onStatus: _ctx.changeFilterStatus,
                  id: planFilter.id,
                  rfpId: _ctx.rfpId,
                  resetSearch: _ctx.resetSearch,
                  projectId: _ctx.projectId,
                  icon: planFilter.icon,
                  title: planFilter.title,
                  placeholder: planFilter.placeholder,
                  "min-input-width": planFilter.min,
                  items: planFilter.items
                }, null, 8, ["onStatus", "id", "rfpId", "resetSearch", "projectId", "icon", "title", "placeholder", "min-input-width", "items"]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.cleanFilters,
                "show-icon": "",
                icon: "reset",
                "icon-type": "svg",
                "icon-color": "#0097FF",
                "icon-size": "16",
                type: "primary-light",
                label: _ctx.$t('clear-filters')
              }, null, 8, ["onOnClick", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.loadingCalendar)
              ? (_openBlock(), _createBlock(_component_plan_loading, {
                  key: 0,
                  class: "plan-mt-lg",
                  message: _ctx.$t('getting-your-loads'),
                  "sub-message": _ctx.$t('loading-ltl-loading')
                }, null, 8, ["message", "sub-message"]))
              : (_openBlock(), _createBlock(_component_loads_calendar, {
                  key: 1,
                  onChangeDate: _ctx.goToDay,
                  id: "loads-calendar",
                  events: _ctx.totalDates,
                  locale: _ctx.currentUserLocale,
                  max: _ctx.maxDateFilter,
                  min: _ctx.minDateFilter,
                  class: "w-full",
                  style: {"padding-bottom":"100px"}
                }, null, 8, ["onChangeDate", "events", "locale", "max", "min"]))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}