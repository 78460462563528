
import { defineComponent, computed, watch } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
export default defineComponent({
  name: 'PlanAvatar',
  components: { PlanButtonIcon },
  props: {
    logo: { type: String, required: false, default: '' },
    userName: { type: String, required: true, default: '' },
    onlyAvatar: { type: Boolean, required: false, default: false },
    description: { type: String, required: false, default: '' },
    withChat: { type: Boolean, required: false, default: false },
    maxCharacters: { type: Number, required: false, default: -1 },
    breakWord: { type: Boolean, required: false, default: false },
  },
  setup (props) {
    const userToShow = computed(() => {
      if (props.userName.length >= props.maxCharacters && props.maxCharacters !== -1) {
        return props.userName.slice(0, props.maxCharacters - 2) + '..'
      } else {
        return props.userName
      }
    })

    return {
      userToShow
    }
  }
})
