
import { defineComponent } from 'vue';
// Icons
import { ArrowLongRightIcon } from '@heroicons/vue/24/outline';
// Utils
import { getStateById } from '@/utils/formatLocation';

import BaseComponent from '@/base/BaseComponent';
export default defineComponent({
  mixins: [BaseComponent],

  components: {
    ArrowLongRightIcon,
  },
  data() {
    return {
      load: null as any,
      showTemplatesAllowed: true,
    };
  },
  computed: {
    commodityMasters(): any {
      let totalWeight = 0;
      let totalWeightStr = this.$t('total-weight');

      const commodityString = this.load.commodityMasters
        .map((item) => {
          totalWeight += item.weight;
          return `${item.commodityName} ${this.formatWeight(item.weight)}`;
        })
        .join(', ');

      return `${commodityString} - ${totalWeightStr}: ${this.formatWeight(`${totalWeight}`)}`;
    },
    currentRouteName(): any {
      return this.$route.name;
    },

    getStateById(): any {
      return getStateById;
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
    getTrailerSelected(): any {
      let Id = this.load?.trailerType?.id;
      return this.trailerTypes.find((trailer) => trailer.id === Id);
    },
    initialPickUpStop(): any {
      let initialStop = null;
      this.load?.stops?.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL') {
          initialStop = stop;
        }
      });
      return initialStop;
    },
    finalDeliveryStop(): any {
      let finalStop = null;
      this.load?.stops?.forEach((stop: any) => {
        if (stop.type === 'DELIVERY_FINAL') {
          finalStop = stop;
        }
      });
      return finalStop;
    },
    specialRequirements(): any {
      if (this.load) {
        if (
          this.load?.specialRequirement &&
          Object.keys(this.load?.specialRequirement).length !== 0
        ) {
          const requirements = Object.keys(this.load?.specialRequirement).filter(
            (key) => this.load?.specialRequirement[key] === true,
          );
          requirements.forEach((req, index) => {
            if (req === 'specialPermissions') {
              requirements[index] =
                this.$t(req) + ': ' + this.load.specialRequirement.specialPermissionsDescription;
            } else {
              requirements[index] = this.$t(req);
            }
          });
          return requirements.join(', ');
        }
      }
      return null;
    },
  },
  methods: {
    showTemplates() {
      this.$store.dispatch('LoadsStore/showTemplateModal', true);
    },
    nextRoute() {
      this.$router.push('quote');
    },
    selectedTab(currentTab) {
      this.showTemplatesAllowed = currentTab;
    },
    setLoadHeader(data) {
      this.load = data;
    },
  },
});
