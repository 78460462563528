import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "my-4 border rounded-lg p-4 shadow-lg space-y-2" }
const _hoisted_2 = { class: "text-xl font-bold" }
const _hoisted_3 = { class: "text-sm text-gray-400 font-semibold" }
const _hoisted_4 = { class: "flex flex-col lg:flex-row space-x-0 lg:space-x-10 space-y-5 lg:space-y-0" }
const _hoisted_5 = { class: "flex gap-8" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "plan-badge-primary ml-1" }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyModal = _resolveComponent("CompanyModal")!
  const _component_SpecialRequirementsModal = _resolveComponent("SpecialRequirementsModal")!
  const _component_StopData = _resolveComponent("StopData")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showCompanyModal)
      ? (_openBlock(), _createBlock(_component_CompanyModal, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCompanyModal = false))
        }))
      : _createCommentVNode("", true),
    (_ctx.showSpecialRequirements)
      ? (_openBlock(), _createBlock(_component_SpecialRequirementsModal, {
          key: 1,
          load: _ctx.loadToModal,
          showModal: _ctx.showSpecialRequirements,
          onClose: _ctx.toggleSpecialRequirements
        }, null, 8, ["load", "showModal", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.load.stops, (stop, index) => {
        return (_openBlock(), _createBlock(_component_StopData, {
          blockCountry: true,
          finalDate: _ctx.finalDate,
          labelLocation: index === 0 ? _ctx.$t('pickup') : _ctx.$t('delivery'),
          index: index,
          initialDate: _ctx.initialDate,
          key: stop.id || 0 + index,
          pickUpIsText: _ctx.pickUpIsText,
          ref_for: true,
          ref: (el) => (_ctx.stopsRefs[index] = el),
          showTime: true,
          stop: stop,
          warehousesAllowed: true,
          onCheckDate: _ctx.checkDate,
          onPortOrAirport: _ctx.portOrAirport,
          onSetStopLocation: _ctx.setStopLocation,
          onUpdateHour: _ctx.updateHour,
          onUpdateLumperFee: _ctx.updateLumperFee,
          onUpdateObservations: _ctx.updateObservations,
          onUpdatePoNumber: _ctx.updatePoNumber,
          onUpdateWarehouse: _ctx.updateWarehouse,
          onCountry: _ctx.countrySelected
        }, null, 8, ["finalDate", "labelLocation", "index", "initialDate", "pickUpIsText", "stop", "onCheckDate", "onPortOrAirport", "onSetStopLocation", "onUpdateHour", "onUpdateLumperFee", "onUpdateObservations", "onUpdatePoNumber", "onUpdateWarehouse", "onCountry"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", {
          for: "specialRequirements",
          class: "text-bold cursor-pointer hover:text-plan-primary-300",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleSpecialRequirements(true, null, null, null)))
        }, _toDisplayString(_ctx.$t('special-requirements')), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.nRequirements), 1)
      ]),
      false
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
            _createTextVNode(_toDisplayString(_ctx.$t('billing-data', { company: _ctx.currentUser.companyName })) + " ", 1),
            _createElementVNode("span", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCompanyModal = true)),
              class: "text-sky-500 cursor-pointer ml-2"
            }, " [" + _toDisplayString(_ctx.$t('see-modify')) + "] ", 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}