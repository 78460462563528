import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }
const _hoisted_2 = { class: "flex flex-col plan-gap-sm" }
const _hoisted_3 = { class: "plan-t-prim-100" }
const _hoisted_4 = { class: "w-full flex justify-between items-center plan-gap-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_button, {
      onOnClick: _ctx.openModal,
      type: "primary",
      label: _ctx.$t('change-password')
    }, null, 8, ["onOnClick", "label"]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_plan_default_modal, {
          key: 0,
          onClose: _ctx.closeModal,
          size: "sm-auto",
          title: _ctx.$t('change-password')
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('password-must-be-at-least-6-characters')), 1),
              _createVNode(_component_plan_input_text, {
                "model-value": _ctx.oldPassword,
                "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.oldPassword) = $event)),
                password: "",
                label: _ctx.$t('actual-password'),
                placeholder: _ctx.$t('enter-actual-password'),
                autocomplete: "off"
              }, null, 8, ["model-value", "label", "placeholder"]),
              _createVNode(_component_plan_input_text, {
                "model-value": _ctx.newPassword,
                "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPassword) = $event)),
                password: "",
                label: _ctx.$t('new-password'),
                placeholder: _ctx.$t('enter-new-password'),
                autocomplete: "off"
              }, null, 8, ["model-value", "label", "placeholder"]),
              _createVNode(_component_plan_input_text, {
                "model-value": _ctx.repeatPassword,
                "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.repeatPassword) = $event)),
                password: "",
                label: _ctx.$t('repeat-password'),
                placeholder: _ctx.$t('repeat-new-password'),
                autocomplete: "off"
              }, null, 8, ["model-value", "label", "placeholder"])
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.closeModal,
                type: "cancel",
                label: _ctx.$t('cancel'),
                class: "w-full"
              }, null, 8, ["onOnClick", "label"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.changePassword,
                loading: _ctx.loadingChangePassword,
                disabled: !_ctx.isFormComplete,
                type: "primary",
                label: _ctx.$t('change'),
                class: "w-full"
              }, null, 8, ["onOnClick", "loading", "disabled", "label"])
            ])
          ]),
          _: 1
        }, 8, ["onClose", "title"]))
      : _createCommentVNode("", true)
  ]))
}