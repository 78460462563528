import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "xl:container p-5 xl:p-10 pb-24" }
const _hoisted_2 = { class: "bg-white rounded-xl p-5 pb-24 xl:p-10 xl:pb-24" }
const _hoisted_3 = { class: "pt-5 flex gap-4" }
const _hoisted_4 = { class: "my-6" }
const _hoisted_5 = { class: "py-2 px-16" }
const _hoisted_6 = { class: "flex flex-col md:flex-row items-center md:w-full justify-between py-1 px-1" }
const _hoisted_7 = { class: "flex flex-col md:flex-row items-center relative pt-8" }
const _hoisted_8 = { class: "absolute truncate inset-x-0 top-0 -left-10 w-28 text-center" }
const _hoisted_9 = { class: "fixed bottom-0 left-0 bg-blue-100 w-full xl:w-[calc(100%_-_300px)] z-10 xl:ml-90" }
const _hoisted_10 = { class: "w-full xl:container flex flex-col sm:flex-row justify-center md:justify-between items-center py-6 px-10 space-x-6" }
const _hoisted_11 = { class: "flex justify-between items-center space-x-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_datepicker = _resolveComponent("datepicker")!
  const _component_Field = _resolveComponent("Field")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!
  const _component_Form = _resolveComponent("Form")!
  const _component_content = _resolveComponent("content")!
  const _component_Spin = _resolveComponent("Spin")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", {
            class: _normalizeClass(["text-xl md:text-2xl font-bold", { 'flex items-center': _ctx.isCompleted }])
          }, [
            _createTextVNode(" 1. " + _toDisplayString(_ctx.$t('shipment-data')) + " ", 1),
            (_ctx.isCompleted)
              ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                  key: 0,
                  class: "h-8 w-8"
                }))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("h3", {
            class: "text-xl md:text-2xl font-bold opacity-25 hover:opacity-100 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
          }, " 2. " + _toDisplayString(_ctx.$t('assignment')), 1)
        ]),
        _createVNode(_component_content, null, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('add-loads-dates')), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedStops, (stop, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(["relative items-center", { 'w-full': index + 1 < _ctx.orderedStops.length }])
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(stop.location.city), 1),
                      _createElementVNode("div", {
                        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                        class: _normalizeClass(["z-10 flex items-center justify-center border-2 rounded-full shrink-0", {
                    'border-corp': stop,
                    'w-6 h-6': index === 0 || index + 1 === _ctx.orderedStops.length,
                    'w-5 h-5': index > 0 && index + 1 !== _ctx.orderedStops.length,
                  }])
                      }, null, 2),
                      (index + 1 < _ctx.orderedStops.length)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["flex md:w-full", {
                    'h-44': _ctx.orderedStops.length === 2,
                    'h-16': _ctx.orderedStops.length > 2,
                    'bg-corp w-1 md:h-0.5': stop,
                  }])
                          }, null, 2))
                        : _createCommentVNode("", true)
                    ])
                  ], 2))
                }), 128))
              ])
            ]),
            _createVNode(_component_Form, { ref: "formLanes" }, {
              default: _withCtx(({ errors }) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loads, (i, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "flex justify-between"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.stops, (item, x) => {
                      return (_openBlock(), _createBlock(_component_Field, {
                        rules: "required",
                        modelValue: item.executionDate,
                        "onUpdate:modelValue": ($event: any) => ((item.executionDate) = $event),
                        key: x,
                        name: 'date' + index + x,
                        placeholder: _ctx.$t('placeholder-zip')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_datepicker, {
                            class: "w-full text-gray-900 bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500 block",
                            modelValue: item.executionDate,
                            "onUpdate:modelValue": ($event: any) => ((item.executionDate) = $event),
                            "auto-apply": "",
                            format: _ctx.dateFormatLocale(),
                            locale: _ctx.getLocale,
                            "min-date": i.stops[x - 1]?.executionDate ? i.stops[x - 1]?.executionDate : _ctx.minDate,
                            "max-date": _ctx.finalDeliveryStop.executionDate,
                            placeholder: _ctx.dateFormatLocale().toLowerCase(),
                            "enable-time-picker": false,
                            "input-class-name": 
                  Object.keys(errors).includes('date' + index + x) ? 'border-red-500' : ''
                
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "format", "locale", "min-date", "max-date", "placeholder", "input-class-name"])
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "name", "placeholder"]))
                    }), 128)),
                    _createVNode(_component_XCircleIcon, {
                      class: "w-12 h-12 text-gray-500 cursor-pointer",
                      onClick: ($event: any) => (_ctx.removeLoad(index))
                    }, null, 8, ["onClick"])
                  ]))
                }), 128))
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: "w-fit border fixed rounded-full bottom-28 cursor-pointer p-2 my-4 bg-gray-50",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addMore && _ctx.addMore(...args)))
        }, _toDisplayString(`+ ${_ctx.$t('add-load')}`), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", {
          class: "w-fit text-white bg-blue-500 border border-blue-500 rounded-lg cursor-pointer p-2",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.addMore && _ctx.addMore(...args)))
        }, " + " + _toDisplayString(_ctx.$t('add-load')), 1),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('loads-with-param', { n: _ctx.loads.length })), 1),
          _createElementVNode("div", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
            class: "bg-confirm cursor-pointer flex px-4 md:px-8 py-2 rounded-md text-white border border-confirm text-center font-bold hover:opacity-70"
          }, [
            (_ctx.showSpinner)
              ? (_openBlock(), _createBlock(_component_Spin, { key: 0 }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('continue')), 1)
          ])
        ])
      ])
    ])
  ], 64))
}