
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'assignedLaneComponent',
  mixins: [BaseComponent],
  beforeCreate() {
    this.$store.dispatch('PusherStore/initializePusher');
  },
  async created() {
    this.fetchAllInfo();
  },
  computed: {
    projectId(): any {
      return this.$route.params.id;
    },
  },
  methods: {
    async fetchAllInfo() {
      const id = this.$route.params.id;
      const hash: any = this.$route.params.hash;
      const code: any = this.$route.params.code;
      if (hash && code) {
        await this.$store
          .dispatch('UserStore/hashValidation', {
            hashId: hash,
            secureCode: code,
          })
          .then(() => {
            this.$store.dispatch('PusherStore/initializePusher');
            this.$store
              .dispatch('RFPStore/postCarrierRefuse', this.$route.params.quoteId)
              .then(() => {
                this.$store.dispatch('UserStore/updateToast', {
                  text: this.$t('confirmed'),
                  type: 'success',
                  visible: true,
                });
              })
              .catch((err) => {
                this.$store.dispatch('UserStore/updateToast', {
                  text: err.response.data.title,
                  type: 'error',
                  visible: true,
                });
              });
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
            this.$store.commit('UserStore/setShowHash', true);
          });
      }
    },
    routerRequestForQuote() {
      const projectId = this.projectId;
      this.$router.push({ name: 'CarrierRfpQuote', params: { projectId } });
    },
  },
});
