
import { defineComponent, computed } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import moment from 'moment';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PlanTemplateButton',
  components: { PlanIcon },
  props: {
    id: { type: String, required: false, default: '' },
    title: { type: String, required: false, default: '' },
    user: { type: String, required: false, default: '' },
    date: { type: String, required: false, default: '' },
    newTemplate: { type: Boolean, required: false, default: false },
    isPlanimatikTemplate: { type: Boolean, required: false, default: false }
  },
  emits: ['onClick'],
  setup (props, { emit }) {
    const store = useStore()
    const onClick = () => { emit('onClick') }

    const dateToShow = computed(() => {
      const region = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'

      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? moment(props.date).format('MMM DD, YYYY') : moment(props.date).format('DD MMM, YYYY')

    })

    return  {
      dateToShow,
      onClick
    }
  }
})
