
import { defineComponent } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { DocumentCheckIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
import PhoneNumberSelector from "@/components/forms/PhoneNumberSelector.vue";

export default defineComponent({
  name: 'ModalInvoices',
  components: {
    PhoneNumberSelector,
    Dialog,
    DialogPanel,
    DialogTitle,
    DocumentCheckIcon,
    ErrorMessage,
    Field,
    Form,
    TransitionChild,
    TransitionRoot,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  emits: ['setOpen'],
  props: {
    open: Boolean,
    invoices: [] as any,
  },
  data() {
    return {
      name: '',
      surname: '',
      prefix: '1',
      phone: null,
      email: '',
      loading: false,
      editing: false,
    };
  },
  computed: {
  },
  methods: {
    changePhone(type, phone){
      switch (type){
        case 'prefix':
          this.prefix = phone.prefix;
          break;
        case 'number':
          this.phone = phone.number;
          break;
        default:
          return
      }
    },
    async submitForm() {
      this.loading = true;
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid) {
        this.loading = false;
        return;
      }
    },
    toggleOpen(isOpen: boolean) {
      this.$emit('setOpen', isOpen);
    },
    async viewInvoice(invoice) {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: invoice.mediaObject.id,
        class: invoice.mediaObject.entityClass,
      });

      const url = URL.createObjectURL(response);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${invoice.mediaObject.id}`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
  },
});
