import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "pl-2 font-semibold pr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.badgeClass)
  }, [
    (_ctx.content.length === 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.formattedQuantity), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.content), 1))
  ], 2))
}