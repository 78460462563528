
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import { useStore } from 'vuex';
import moment from 'moment';

export default defineComponent({
  name: 'PlanRfpHistoryTracking',
  components: {
    PlanDefaultModal,
    PlanTextButton,
    PlanDefaultTable,
    PlanButtonIcon,
    PlanIcon,
    PlanButton,
  },
  props: {
    needUpdate: { type: Boolean, required: false, default: false },
  },
  emits: ['isUpdated'],
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      history: [],
      loadingHistory: false,
      selectedItem: null,
      showTrackingHistoryModal: false,
    });

    const formatDate = computed(() => {
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA'
        ? 'MMM DD, YYYY'
        : 'DD MMM, YYYY';
    });

    const getTrackingHistory = () => {
      data.loadingHistory = true;
      store
        .dispatch(
          'LoadsStore/getTrackingHistory',
          store.getters['LoadsStore/currentLoad'].shipment?.id,
        )
        .then((response) => {
          data.history = response;
          emit('isUpdated');
        })
        .finally(() => {
          data.loadingHistory = false;
        });
    };

    getTrackingHistory();

    watch(
      () => props.needUpdate,
      () => {
        getTrackingHistory();
      },
    );

    return {
      ...toRefs(data),
      moment,
      formatDate,
    };
  },
});
