
import { defineComponent } from 'vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import isEqual from 'lodash/isEqual';
// Base
import BaseComponent from '@/base/BaseComponent';
// Modules
import LoadInfoStepOneVue from '@/modules/planning/newLoadLTL/LoadInfoStepOne.vue';
import LoadFreightStepTwo from '@/modules/planning/newLoadLTL/LoadFreightStepTwo.vue';
import LoadQuoteStepThree from '@/modules/planning/newLoadLTL/LoadQuoteStepThree.vue';
// Utils
import { locationString } from '@/utils/formatLocation';

export default defineComponent({
  name: 'ShipmentDataLTL',
  components: {
    CheckCircleIcon,
    LoadFreightStepTwo,
    LoadInfoStepOneVue,
    LoadQuoteStepThree,
  },
  mixins: [BaseComponent],
  data() {
    return {
      alpineLoadId: 0,
      loadCreatedId: 0,
      creatingLoad: false,
      previousLoad: null as any,
      isTabToStepThree: false,
      isValidStepOne: false,
      isValidStepTwo: false,
      isValidStepThree: false,
      currentView: 'step-one',
      load: {
        loadId: 0,
        freezable: false,
        measurementSystem: 'IMPERIAL',
        poNumber: '',
        stops: [
          {
            type: 'PICKUP_INITIAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
              googlePlaceId: '',
            },
            airportStop: false,
            inside: false,
            limitedDefault: false,
            residential: false,
            liftgate: false,
            appointment: false,
          },
          {
            type: 'DELIVERY_FINAL',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
              googlePlaceId: '',
            },
            airportStop: false,
            inside: false,
            residential: false,
            liftgate: false,
            appointment: false, // check
            limitedDefault: false,
          },
        ],
        freight: [],
      },
      dateFormat: 'MM-dd-yyyy',
      serviceObject: {
        pickup: '',
        deliver: '',
        pickupDate: '',
        deliverDate: '',
      },
    };
  },
  computed: {
    currentDate(): any {
      return new Date();
    },
    stepOneAllowed() {
      if (this.load.stops[0].executionDate !== '' && this.currentView !== 'step-one') {
        return true;
      }
      return false;
    },
    stepTwoAllowed() {
      if (this.load.stops[0].executionDate !== '' && this.currentView !== 'step-two') {
        return true;
      }
      return false;
    },
    stepThreeAllowed() {
      if (
        this.isValidStepOne &&
        this.isValidStepTwo &&
        this.isLoadFreight &&
        this.currentView !== 'step-three'
      ) {
        return true;
      }
      return false;
    },
    locationString() {
      return locationString;
    },
    isPreviousLoadEqualToCurrent(): any {
      return (
        isEqual(this.load.freight, this.previousLoad.freight) &&
        isEqual(this.load.stops, this.previousLoad.stops)
      );
    },
    isLoadFreight(): any {
      return this.load?.freight?.length > 0;
    },
  },
  created() {
    this.$store.dispatch('LoadsStore/getNMFC', {});
    this.$store.dispatch('LoadsStore/getLimitedAcces');
    this.$store.dispatch('LoadsStore/getdensityClass');
    this.$store.dispatch('LoadsStore/packageTypes');
  },
  methods: {
    setLoadId(id) {
      this.load.loadId = id;
    },
    async getQuotes() {
      this.creatingLoad = true;
      await this.$store
        .dispatch('LoadsStore/getLTLQuotes', this.load)
        .then((response) => {
          this.load.loadId = response[0].alpineLoadId;
          this.creatingLoad = false;
          this.changeStep('step-three');
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(this.$t('message-error-getting-quotes'));
          this.creatingLoad = false;
        })
        .finally(() => {
          // this.allowed = true;
          this.creatingLoad = false;
        });
    },
    changeStep(step) {
      this.currentView = step;
    },
    setValidStep(step) {
      switch (step) {
        case 1:
          this.isValidStepOne = true;
          break;
        case 2:
          this.isValidStepTwo = true;
          break;

        default:
          break;
      }
    },
    updateLoad(updatedLoad) {
      this.previousLoad = JSON.parse(JSON.stringify(this.load));
      // this.previousLoad = cloneDeep({ ...this.load });
      this.load = JSON.parse(JSON.stringify(updatedLoad));
      this.$emit('setLoadHeader', this.load);
    },
    updateExtraFields(key, value){
      this.load[key] = value;
    },
    async goToStepOne() {
      if (this.stepOneAllowed) {
        this.currentView = 'step-one';
      }
    },
    async goToStepTwo() {
      if (this.currentView === 'step-three' || this.isValidStepOne) {
        this.isValidStepOne = await (this.$refs.stepOne as any).submitTab();
        if (this.isValidStepOne) {
          this.currentView = 'step-two';
        }
      }
    },
    async goToStepThree() {
      this.isTabToStepThree = true;
      if (this.stepThreeAllowed && this.isLoadFreight) {
        this.isValidStepOne = await (this.$refs.stepOne as any).submitTab();
        if (!this.isPreviousLoadEqualToCurrent) {
          await (this.$refs.stepTwo as any).submit();
        }
        if (this.currentView === 'step-one' || this.currentView === 'step-two') {
          this.currentView = 'step-three';
        }
        this.isTabToStepThree = false;
      }
    },
  },
});
