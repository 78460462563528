
import { computed, defineComponent, reactive, toRefs, PropType, ref, nextTick, watch } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PlanInputNumber',
  components: { PlanIcon },
  props: {
    phoneNumber: { type: Boolean, default: false },
    mandatory: { type: Boolean, required: false, default: false },
    modelValue: { type: [Number, null] as PropType<number | null>, required: true },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    validations: { type: Array, required: false, default: () => [] },
    currency: { type: Boolean, required: false, default: false },
    withCurrency: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    leftText: { type: String, required: false, default: '' },
    rightText: { type: String, required: false, default: '' },
    hideLeft: { type: Boolean, required: false, default: false },
    hideRight: { type: Boolean, required: false, default: false },
    validateFromStart: { type: Boolean, required: false, default: false },
    hideValidations: { type: Boolean, required: false, default: false },
    fractionDigits: { type: Number, required: false, default: 0 },
    showWeight: { type: Boolean, required: false, default: false },
    showDistance: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const inputRef = ref<HTMLInputElement | null>(null);

    const data = reactive({
      currency: store.getters['CountryStore/getCurrencySymbol'],
      thereAreChanges: false,
      isInputOnFocus: false,
    });

    const changeTheValue = () => {
      data.thereAreChanges = true;
      data.isInputOnFocus = false;
    };

    if (props.validateFromStart) changeTheValue();

    const internalValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const getFormattedValue = computed(() => {
      const locale = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es';
      const currency = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'USD' : 'EUR';
      const weight = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'lb' : 'kg';
      const distance = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'mi' : 'km';

      let result = '';

      if (internalValue.value) {
        if (props.phoneNumber) {
          return internalValue.value;
        }

        if (props.currency) {
          result = internalValue.value.toLocaleString(locale, {
            style: 'currency',
            minimumFractionDigits: props.fractionDigits,
            maximumFractionDigits: props.fractionDigits,
            currency: currency,
          });
        } else {
          console.log(result);

          result = internalValue.value.toLocaleString(locale, {
            minimumFractionDigits: props.fractionDigits,
            maximumFractionDigits: props.fractionDigits,
          });

          console.log(result);
        }

        if (props.showWeight) result = `${result} ${weight}`;
        if (props.showDistance) result = `${result} ${distance}`;
        return result;
      } else {
        return props.placeholder;
      }
    });

    const focusInput = () => {
      data.isInputOnFocus = true;
      nextTick(() => {
        if (inputRef.value) {
          inputRef.value.focus();
        }
      });
    };

    watch(
      () => data.isInputOnFocus,
      (newValue) => {
        if (newValue) {
          nextTick(() => {
            if (inputRef.value) {
              inputRef.value.focus();
            }
          });
        }
      },
    );

    const validationResult = computed(() => {
      let result = '';

      if (props.validations.length === 0) return result;

      if (props.validations.length > 0 && data.thereAreChanges) {
        if (props.validations.includes('greaterThan0')) {
          if (props.modelValue !== null && props.modelValue !== undefined) {
            result =
              (props.modelValue as number) > 0 ? '' : translate.t('input-validations.greaterThan0');
          }
        }
      }

      if (props.validations.includes('greaterThan9Digits')) {
        if (props.modelValue !== null && props.modelValue !== undefined) {
          const stringValue = props.modelValue.toString();
          result =
            stringValue.length >= 9 ? '' : translate.t('input-validations.greaterThan9Digits');
        }
      }
      return result;
    });

    return {
      ...toRefs(data),
      changeTheValue,
      internalValue,
      getFormattedValue,
      focusInput,
      validationResult,
      inputRef,
    };
  },
});
