
import { computed, defineComponent } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SidebarButton',
  components: { PlanIcon },
  props: {
    icon: { type: String, required: true, default: '' },
    label: { type: String, required: true, default: '' },
    withNotifications: { type: Boolean, required: false, default: false },
    whenActive: { type: String, required: false, default: '' }
  },
  setup (props) {
    const store = useStore()
    const route = useRouter()

    const activeButton = computed(() => {
      return route.currentRoute.value.meta.activeButton
    })

    const collapsed = computed(() => {
      return store.getters['UserStore/getCollapsedSidebar']
    })

    const labelToShow = computed(() => {
      if (!collapsed.value && props.label.length > 27) return props.label.slice(0, 27) + '..'
      if (collapsed.value && props.label.length > 18) return props.label.slice(0, 18) + '..'
      return props.label
    })

    return {
      activeButton,
      collapsed,
      labelToShow
    }
  }
})
