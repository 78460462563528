import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-3 bg-white w-full sticky top-0 z-20" }
const _hoisted_2 = { class: "flex flex-wrap justify-between items-center xl:container p-5 md:p-10 md:pb-5" }
const _hoisted_3 = { class: "px-1.5" }
const _hoisted_4 = { class: "text-corp text-2xl font-bold" }
const _hoisted_5 = { class: "justify-start items-center gap-2 flex pb-2" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "text-slate-900 text-2xl font-bold font-['Nunito'] tracking-tight" }
const _hoisted_8 = { class: "bg-white flex-wrap justify-start items-center inline-flex" }
const _hoisted_9 = { class: "pr-2.5 py-2 bg-white justify-start items-start flex flex-wrap" }
const _hoisted_10 = { class: "flex justify-between opacity-50 relative" }
const _hoisted_11 = { class: "text-slate-700 text-lg font-bold" }
const _hoisted_12 = { class: "text-slate-700 text-lg font-bold ml-2" }
const _hoisted_13 = { class: "self-stretch justify-start items-center gap-1.5 flex flex-wrap" }
const _hoisted_14 = { class: "text-gray-400 text-2xl font-bold" }
const _hoisted_15 = { class: "p-2 bg-white justify-start items-start gap-2 flex" }
const _hoisted_16 = { class: "text-slate-900 text-base font-bold" }
const _hoisted_17 = { class: "text-gray-400 text-2xl font-bold" }
const _hoisted_18 = { class: "p-2 bg-white justify-start items-start gap-2 flex" }
const _hoisted_19 = { class: "text-gray-400 text-base font-bold" }
const _hoisted_20 = { class: "flex flex-col gap-y-12" }
const _hoisted_21 = { class: "pl-2 justify-start items-center gap-2 inline-flex" }
const _hoisted_22 = { class: "text-sky-500 text-lg font-semibold whitespace-nowrap" }
const _hoisted_23 = { class: "w-8 h-8 p-1 bg-sky-500 rounded-3xl border border-white justify-center items-center gap-2 flex" }
const _hoisted_24 = {
  key: 1,
  class: "flex flex-wrap items-center gap-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SetMaxResponseTimeModal = _resolveComponent("SetMaxResponseTimeModal")!
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_StarIcon = _resolveComponent("StarIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showResponseMaxTimeModal)
      ? (_openBlock(), _createBlock(_component_SetMaxResponseTimeModal, {
          key: 0,
          carrierList: null,
          onClose: _ctx.callbackToggleResponseTimeModal,
          onFetch: _ctx.callbackFetchLanesDetail
        }, null, 8, ["onClose", "onFetch"]))
      : _createCommentVNode("", true),
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", {
            class: "justify-start items-center inline-flex mb-2 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickBack && _ctx.onClickBack(...args)))
          }, [
            _createVNode(_component_ChevronLeftIcon, { class: "w-3 h-3 text-corp" }),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('exit')), 1)
          ]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('new-load')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              class: "min-h-[1.5rem] min-w-[1.5rem] h-6 w-6 text-corp group-hover:transition-all",
              alt: "Burger RFP",
              src: _ctx.getAsset.icBurgerRFP
            }, null, 8, _hoisted_6),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.currentProject?.project?.name && _ctx.currentLane?.identifier
                ? `${_ctx.currentProject?.project?.name} - ${_ctx.currentLane?.identifier}`
                : ''), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.origin?.location?.city && _ctx.origin?.location?.state?.isoGoogle
                    ? `${_ctx.origin?.location?.city}, ${_ctx.origin?.location?.state?.isoGoogle}`
                    : ''), 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.destiny?.location?.city && _ctx.destiny?.location?.state?.isoGoogle
                    ? `${_ctx.destiny?.location?.city}, ${_ctx.destiny?.location?.state?.isoGoogle}`
                    : ''), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.currentLane?.loadsFrequency && _ctx.currentLane?.loadsFrequencyPeriod ? '·' : ''), 1),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.currentLane?.loadsFrequency && _ctx.currentLane?.loadsFrequencyPeriod
                    ? `${_ctx.currentLane?.loadsFrequency} ${_ctx.$t('loads')} ${_ctx.$t(
                        _ctx.currentLane?.loadsFrequencyPeriod?.toLowerCase(),
                      )}`
                    : ''), 1)
              ]),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.origin?.executionDate && _ctx.formatDate(_ctx.destiny?.executionDate) ? '·' : ''), 1),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.origin?.executionDate && _ctx.formatDate(_ctx.destiny?.executionDate)
                    ? `${_ctx.formatDate(_ctx.origin?.executionDate)} ${_ctx.$t('to')} ${_ctx.formatDate(
                        _ctx.destiny?.executionDate,
                      )}`
                    : ''), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          (!_ctx.isStatusDraft() && _ctx.currentProject?.project?.name && _ctx.currentLane?.identifier)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "p-2 rounded-3xl w-[160px] border border-sky-500 flex-col justify-start items-start gap-2.5 inline-flex cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('showRankingModal')))
              }, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('top-offers')), 1),
                  _createElementVNode("div", _hoisted_23, [
                    _createVNode(_component_StarIcon, { class: "h-6 w-6 text-white" })
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.currentProject?.project?.assignConfirmationExpirationMinutes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('max-response-time')), 1),
                _createElementVNode("p", {
                  class: "btn-gray-border text-black rounded-md py-0",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.callbackToggleResponseTimeModal && _ctx.callbackToggleResponseTimeModal(...args)))
                }, _toDisplayString(_ctx.$t('min-value', {
                value: _ctx.currentProject?.project?.assignConfirmationExpirationMinutes,
              })), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}