import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  asyncResultLanes: [],
  carrierQuotetionList: [],
  claimProjectInfo: [],
  currentEditingLanes: [],
  currentCarrierRFPQuotes: [],
  currentLane: {},
  currentProject: {},
  currentQuotesPlanned: [],
  currentLoadsToAdd: [],
  currentLoadsToAddAssigment: false,
  calendarIntervals: [],
  indexLoadToRemove: null as any,
  lanesAndQuotesForCarrier: [],
  lanes: [],
  lanesListFilter: [],
  loadsInfo: [],
  lanesArrayPerWeek: [],
  lanesClaimsInfo: [],
  newProjectModalOptions: {
    type: 'scratch',
    opened: false,
    mode: 'creation',
  },
  previousStatusLanes: 'table',
  previousStatusProject: 'table',
  projectsRFPList: [],
  projectsTotalsStatus: null,
  projectToduplicate: {},
  quoteForCarrier: {},
  rankingList: [],
  showWaitingLanes: false,
  unCompletedTootipInfo: [],
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
