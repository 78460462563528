
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import store from '@/services/store';
import { useI18n } from 'vue-i18n';

interface PlanInputTime {
  thereAreChanges: boolean;
  errors: any;
}

export default defineComponent({
  name: 'PlanInputTime',
  props: {
    id: { type: String, required: false, default: '' },
    mandatory: { type: Boolean, required: false, default: false },
    modelValue: { type: String, required: true, default: '' },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    validations: { type: Array, required: false, default: () => [] },
    disabled: { type: Boolean, required: false, default: false },
    min: { type: String, required: false, default: '' },
    max: { type: String, required: false, default: '' },
    innerClass: { type: String, required: false, default: '' },
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const store = useStore();
    const { t, locale } = useI18n();

    const data = reactive<PlanInputTime>({
      thereAreChanges: false,
      errors: [],
    });
    const minValue = computed(() => {
      return props.min;
    });

    const changeTheValue = () => {
      data.thereAreChanges = true;
    };

    const internalValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
        emit('change');
      },
    });

    const validationResult = computed(() => {
      let result = '';
      // if (data.thereAreChanges && props.mandatory) {
      //   result = props.modelValue && props.modelValue.length > 0 ? '' : 'Escoje una hora';
      // }

      if (data.errors.length > 0) {
        data.errors.forEach((item) => {
          result += item;
        });
      }
      return result;
    });

    function handleInput(event) {
      setTimeout(() => {
        const selectedTime = event.target.value;
        if (
          minValue.value.length > 0 &&
          selectedTime < minValue.value &&
          data.errors.length === 0
        ) {
          data.errors.push(t('time-end-validation'));
        }
        if (minValue.value.length > 0 && selectedTime > minValue.value) {
          data.errors = [];
        }
      }, 1000);
    }

    watch(
      () => props.min,
      (currentValue, oldValue) => {
        if (props.min.length > 0 && props.modelValue < props.min && data.errors.length === 0) {
          data.errors.push(t('time-end-validation'));
        }
        if (props.min.length > 0 && props.modelValue > props.min) {
          data.errors = [];
        }
      },
    );

    return {
      ...toRefs(data),
      changeTheValue,
      internalValue,
      validationResult,
      minValue,
      handleInput,
    };
  },
});
