export default {
  getFile(state: any) {
    return state.file;
  },
  getFilesToUpload(state: any) {
    return state.filesToUpload;
  },
  getSendingFile(state: any) {
    return state.sendingFile;
  },
};
