import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "md:text-3xl font-bold text-center" }
const _hoisted_2 = { class: "flex flex-col items-center justify-center gap-8 py-5" }
const _hoisted_3 = {
  key: 0,
  class: "flex text-slate-900 text-xl font-bold"
}
const _hoisted_4 = { class: "w-[25rem] text-center text-slate-900 text-base font-normal leading-normal" }
const _hoisted_5 = { class: "flex flex-row justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RocketLaunchIcon = _resolveComponent("RocketLaunchIcon")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    show: _ctx.isWelcomeOpen,
    modalSize: "medium",
    onCallbackClose: _ctx.onClickClose
  }, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.messages.title), 1)
    ]),
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.messages.secondTitle)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
              _createVNode(_component_RocketLaunchIcon, { class: "h-6 w-6 text-corp mr-2" }),
              _createTextVNode(" " + _toDisplayString(_ctx.messages.secondTitle), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.messages.body), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "w-48 bg-corp text-white text-sm text-center rounded-md px-6 py-2.5 hover:opacity-70 focus:ring-3 focus:outline-none focus:ring-corp",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickClose && _ctx.onClickClose(...args)))
        }, _toDisplayString(_ctx.messages.button), 1)
      ])
    ]),
    _: 1
  }, 8, ["show", "onCallbackClose"]))
}