
import { defineComponent } from 'vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';

interface ICarrierRateConfirmation {
  isEmptySign: boolean;
  signImage: string;
  rateConfirmationId: string;
  fetchedImageOriginal: any;
  fetchedImageWithoutSign: any;
  rcSigned: any;
  signedRate: any;
}

export default defineComponent({
  name: 'MySignaturePad',
  components: { XCircleIcon },
  mixins: [BaseComponent],
  data(): ICarrierRateConfirmation {
    return {
      fetchedImageOriginal: null,
      isEmptySign: false,
      signImage: '',
      rateConfirmationId: '',
      fetchedImageWithoutSign: null,
      rcSigned: false,
      signedRate: false,
    };
  },
  async created() {
    const id = this.$route.params.id;
    await this.$store.dispatch('ShipmentStore/shipment', { id: id });
    this.rateConfirmationId = this.rateConfirmation?.id;
    if (!this.rateConfirmationId) {
      const result = await this.$store.dispatch('ShipmentStore/thumbnailWithoutSign', {
        shipment: id,
      });

      if (result) {
        this.fetchedImageWithoutSign = URL.createObjectURL(result);
      }
    }
  },
  computed: {
    deliverCount(): any {
      if (!this.shipment.loads) {
        return '';
      }
      return this.shipment.loads[0].stops.filter((stop) => stop.type === 'DELIVERY').length + 1;
    },
    deliverFinalStop(): any {
      if (!this.shipment.loads) {
        return '';
      }
      const initialStop = this.shipment.loads[0].stops.find(
        (stop) => stop.type === 'DELIVERY_FINAL',
      );
      return initialStop ? initialStop : '';
    },
    getImage(): any {
      return this.$store.getters['ShipmentStore/getImage'];
    },
    pickupInitialStop(): any {
      if (!this.shipment.loads) {
        return '';
      }
      const initialStop = this.shipment.loads[0].stops.find(
        (stop) => stop.type === 'PICKUP_INITIAL',
      );
      return initialStop ? initialStop : '';
    },
    pickUpsCount(): any {
      if (!this.shipment.loads) {
        return '';
      }
      return this.shipment.loads[0].stops.filter((stop) => stop.type === 'PICKUP').length + 1;
    },
    rateConfirmation(): any {
      return this.shipment?.mediaObjects?.find((item: any) => item.type === 'RATE_CONFIRMATION');
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
  },
  methods: {
    async convertImg() {
      const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
        id: this.rateConfirmationId,
        class: 'SHIPMENT',
      });
      this.signImage = URL.createObjectURL(result);
    },
    async download() {
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: this.rateConfirmationId,
        class: 'SHIPMENT',
      });

      const url = URL.createObjectURL(response);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${this.rateConfirmationId}`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
    onBegin() {
      this.signedRate = false;
    },
    onEnd() {
      this.signedRate = true;
    },
    async save() {
      const { isEmpty, data } = (this.$refs.signaturePad as any).saveSignature();

      if (isEmpty) {
        this.isEmptySign = true;
        BaseComponent.methods?.showToastError(this.$t('message-error-sign-area'));
      } else {
        this.isEmptySign = false;
        const id = this.$route.params.id;

        this.$store
          .dispatch('ShipmentStore/signsRateConfirmation', {
            entityId: this.$route.params.id,
            file: data,
          })

          .then((response) => {
            this.rateConfirmationId = response.id;
            this.$store.dispatch('ShipmentStore/shipment', { id: id });
            BaseComponent.methods?.showToastSuccess(this.$t('message-signed-correctly'));
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            let token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
            this.convertImg();
            this.rcSigned = true;
            this.$router.push({
              name: 'carrierQuote',
              params: { id: this.$route.params.id, token: token },
            });
          });
      }
    },
    undo() {
      this.signedRate = false;
      (this.$refs.signaturePad as any).undoSignature();
    },
  },
});
