
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
export default defineComponent({
  name: 'PlanFilterContactTag',
  components: { PlanButtonIcon, PlanIcon },
  emit: ['change'],
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      loadingTags: false,
      selectedTag: '',
      showPopper: false,
      tags: [],
    });

    const getTags = async () => {
      data.loadingTags = true;
      store.dispatch('LoadsStore/getCompanyTags').then((response) => {
        data.loadingTags = false;
        data.tags = response;
      });
    };

    const removeTag = () => {
      data.selectedTag = '';
      emit('change', '');
    };

    const selectTag = (tag: string) => {
      data.selectedTag = tag;
      emit('change', tag);
      data.showPopper = false;
    };

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });



    getTags();

    return {
      ...toRefs(data),
      removeTag,
      selectTag,

    };
  },
});
