
import { defineComponent, ref } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
import Spin from '@/components/spin/AnimateSpin.vue';

interface User {
  id: number;
  name: string;
  company: string;
  owner: {
    name: string;
  };
}

export default defineComponent({
  name: 'ReassignComponent',
  components: {
    AddCarrierModal,
    Spin,
  },
  mixins: [BaseComponent],
  props: {
    currentLoad: {
      type: Object,
    },
  },
  data() {
    return {
      showSpinner: false,
      showSpinnerNet: false,
      useContactPage: 1,
      netWorkContactPage: 1,
      modalVisible: false,
      sendingAction: false,
      allowed: true,
      name: '',
      price: null,
      user: '',
      userSelected: ref<User | null>(null),
      validating: false,
      showSave: false,
      searchTimeout: null as any,
    };
  },
  computed: {
    currentContacts(): User[] {
      return this.$store.getters['UserStore/carrierContactList'].filter(
        (item) => item.source !== 'CARGOBOT',
      );
    },
    filteredItems(): User[] {
      if (this.currentContacts) {
        return this.currentContacts.filter((item) =>
          item.name.toLowerCase().includes(this.name.toLowerCase()),
        );
      }
      return [];
    },
    networtContacts(): User[] {
      return this.$store.getters['UserStore/carrierNetwork'];
    },
    paginationMaxItems(): number {
      return (
        +this.$store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 0
      );
    },
    shipmentId(): number {
      return this.$store.getters['LoadsStore/loadShipmentId'];
    },
  },
  methods: {
    exit() {
      const data = false;
      this.$emit('close', data);
    },
    findUser(newValue: string) {
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        if (newValue.length > 2) {
          this.$store.dispatch('UserStore/carrierContactList', {
            page: this.useContactPage,
            name: newValue,
            type: 'CARRIER|BROKER',
          });
          this.$store.dispatch('UserStore/carrierNetwork', {
            page: this.netWorkContactPage,
            name: newValue,
            type: 'CARRIER|BROKER',
          });
        }
      }, 500);
    },
    //   nextRoute(route: string) {
    //     this.$router.push(route);
    //   },
    loadMoreContacts(type) {
      if (type === 'contacts') {
        this.useContactPage += 1;
        this.showSpinner = true;
      } else if (type === 'networtContacts') {
        this.netWorkContactPage += 1;
        this.showSpinnerNet = true;
      }
      this.findUser(this.name);
    },
    toggleUser(user: User | null, add: boolean) {
      if (add) {
        this.userSelected = user;
        this.showSave = true;
      } else {
        this.userSelected = null;
        this.showSave = false;
      }
    },
    async validateForm(route: string) {
      if (!this.allowed) return;
      this.validating = true;
      // const validation = await (this.$refs.form as any).validate();
      // if (!validation.valid) {
      //   return;
      // }

      this.allowed = false;
      this.sendingAction = true;

      const data = {
        assignedCompanyId: this.currentLoad?.shipment.assignedCompany.id,
        companyId: { company: { id: this.userSelected?.id } },
      };
      this.allowed = true;
      this.$emit('close', data);
    },
  },
  watch: {
    name: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.findUser(newValue);
      },
    },
  },
});
