import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex flex-wrap lg:flex-nowrap lg:space-x-3 space-y-4 lg:space-y-0 mt-14" }
const _hoisted_2 = { class: "flex flex-col w-full md:w-6/12" }
const _hoisted_3 = {
  for: "trailer-type",
  class: "mb-0.5 text-bold"
}
const _hoisted_4 = { class: "plan-div-form flex justify-start items-center plan-pl-sm plan-pr-sm w-fit" }
const _hoisted_5 = {
  key: 0,
  class: "flex justify-between items-center plan-gap-sm"
}
const _hoisted_6 = { class: "flex flex-col w-full" }
const _hoisted_7 = {
  key: 0,
  class: "text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_select_simple = _resolveComponent("plan-select-simple")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_PlanCommodities = _resolveComponent("PlanCommodities")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(`${_ctx.$t('trailer-type')} *`), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_plan_select_simple, {
            onChange: _ctx.changeVehicle,
            items: _ctx.vehicles,
            placeholder: _ctx.$t('select-a-vehicle'),
            value: _ctx.vehicle
          }, null, 8, ["onChange", "items", "placeholder", "value"])
        ]),
        (_ctx.isRefrigerated.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_plan_input_number, {
                onChange: _ctx.changeTrailer,
                "model-value": _ctx.isRefrigerated.min,
                "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isRefrigerated.min) = $event)),
                label: _ctx.$t('min-temp'),
                "hide-validations": ""
              }, null, 8, ["onChange", "model-value", "label"]),
              _createVNode(_component_plan_input_number, {
                onChange: _ctx.changeTrailer,
                "model-value": _ctx.isRefrigerated.max,
                "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isRefrigerated.max) = $event)),
                label: _ctx.$t('max-temp'),
                "hide-validations": ""
              }, null, 8, ["onChange", "model-value", "label"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_PlanCommodities, {
          ref: "multiCommodity",
          load: _ctx.load,
          setModify: _ctx.isModify,
          onChangeDataForm: _ctx.updateCommodityMasters
        }, null, 8, ["load", "setModify", "onChangeDataForm"])
      ])
    ]),
    _createElementVNode("div", {
      class: "w-1/2 textarea text-sm text-gray-900 bg-white border-t border-gray-300 focus:ring-white focus:border-t-blue-500 focus:border-t-2 p-2.5 mt-6",
      role: "textbox",
      contenteditable: "",
      id: "general_observations",
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateGeneralObservations && _ctx.updateGeneralObservations(...args))),
      onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
    }, [
      (_ctx.seePlaceHolder)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('observations-ftl')), 1))
        : _createCommentVNode("", true)
    ], 32)
  ], 64))
}