import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 transition-opacity",
  style: {"z-index":"100"}
}
const _hoisted_2 = { class: "bg-bg rounded-lg w-full max-w-md md:h-auto mx-auto my-auto md:mt-10 overflow-hidden" }
const _hoisted_3 = {
  key: 0,
  class: "bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
}
const _hoisted_4 = { class: "mt-3 sm:mt-0 sm:ml-4 sm:text-center justify-center h-fit" }
const _hoisted_5 = { class: "md:text-3xl font-bold" }
const _hoisted_6 = { class: "flex flex-col mt-10 mb-8 text-center" }
const _hoisted_7 = { for: "days" }
const _hoisted_8 = { class: "flex flex-col max-w-[130px]" }
const _hoisted_9 = { class: "flex justify-center" }
const _hoisted_10 = { class: "h-[42px] ml-2 flex items-center" }
const _hoisted_11 = { class: "flex flex-col max-w-[130px]" }
const _hoisted_12 = { class: "flex justify-center" }
const _hoisted_13 = { class: "h-[42px] ml-2 flex items-center" }
const _hoisted_14 = { class: "flex justify-between space-x-2 px-4 pt-3 pb-6 sm:px-" }
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  class: "bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-"
}
const _hoisted_17 = { class: "mt-3 sm:mt-0 sm:ml-4 sm:text-center justify-center h-fit" }
const _hoisted_18 = { class: "md:text-3xl font-bold" }
const _hoisted_19 = { class: "py-2 mt-4 whitespace-pre-line pb-14" }
const _hoisted_20 = { class: "modal-footer flex flex-shrink-0 w-full flex-wrap items-center justify-center pt-4 border-t border-gray-200 rounded-b-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.setQuotationTime)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('max-response-time')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(`${_ctx.$t('set-a-max-response-time-limit')}*`), 1),
              _createVNode(_component_Form, {
                class: "flex flex-wrap justify-center space-x-3 mt-4",
                ref: "form"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_Field, {
                          name: "hours",
                          rules: "integer|min_value:0",
                          modelValue: _ctx.hours,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hours) = $event))
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("input", {
                              id: "hours",
                              type: "number",
                              class: "w-16 border border-gray-300 bg-gray-50 rounded-lg flex text-center items-center",
                              placeholder: "--",
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hours) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.hours]
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.hours')), 1)
                      ])
                    ]),
                    _createVNode(_component_ErrorMessage, {
                      name: "hours",
                      class: "text-red-500 mt-1"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_Field, {
                          name: "minutes",
                          rules: 
                      _ctx.hours === 0 ? 'required|integer|min_value:1' : 'required|integer|min_value:0'
                    ,
                          modelValue: _ctx.minutes,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.minutes) = $event))
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("input", {
                              id: "minutes",
                              type: "number",
                              class: "w-16 border border-gray-300 bg-gray-50 rounded-lg flex text-center items-center",
                              placeholder: "--",
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.minutes) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.minutes]
                            ])
                          ]),
                          _: 1
                        }, 8, ["rules", "modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.minutes')), 1)
                      ])
                    ]),
                    _createVNode(_component_ErrorMessage, {
                      name: "minutes",
                      class: "text-red-500 mt-1"
                    })
                  ])
                ]),
                _: 1
              }, 512)
            ]),
            _createElementVNode("footer", _hoisted_14, [
              _createElementVNode("button", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close'))),
                class: "inline-flex w-full border-corp justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-corp shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              }, _toDisplayString(_ctx.$t('cancel')), 1),
              _createElementVNode("button", {
                type: "button",
                class: "inline-flex w-full self-center items-center bg-corp border-corp justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (
              _ctx.isFirstTimeConfirming ? _ctx.updateMaxResponseTimeFirstTime() : _ctx.updateQuotationDateEnd()
            ))
              }, [
                (_ctx.sendingAction)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "inline w-4 h-4 mr-3 text-white animate-spin",
                      src: _ctx.getAsset.icSpinner,
                      alt: "Spinner"
                    }, null, 8, _hoisted_15))
                  : _createCommentVNode("", true),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('confirm')), 1)
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("h3", _hoisted_18, _toDisplayString(_ctx.$t('quotation-started')), 1),
              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('quotation-started-info')), 1)
            ]),
            _createElementVNode("footer", _hoisted_20, [
              _createElementVNode("button", {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close'))),
                type: "button",
                class: "inline-block px-2 py-2 bg-black text-white rounded shadow-md hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1 flex-grow"
              }, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('continue')), 1)
              ])
            ])
          ]))
    ])
  ]))
}