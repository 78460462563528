export default {
  getCurrentTendersByGroup(state) {
    return state.getCurrentTendersByGroup;
  },
  lastLaneListPeriod(state) {
    return state.lastLaneListPeriod;
  },
  editingLanes(state) {
    return state.editingLanes;
  },
  currentActiveGroup(state) {
    return state.currentActiveGroup;
  },
  getLaneData(state) {
    return state.laneData;
  },
  getLaneQuotesTotals(state) {
    return state.laneQuotesTotals;
  },
  getLaneQuotesList(state) {
    return state.laneQuotes;
  },
  getLaneRankingList(state) {
    return state.laneRanking;
  },
  getLanesGroups(state) {
    return state.lanesGroups;
  },
  getLanes(state) {
    return state.lanes;
  },
  getLanesTotals(state) {
    return state.lanesTotals;
  },
  getLanesTitle(state) {
    return state.lanesTitle;
  },
  getFiltersLanes(state) {
    return state.filtersLanes;
  },
  getActiveGroup(state) {
    return state.activeGroup;
  },
  getGroupBids(state) {
    return state.groupBids;
  },
  getShowModalStarted(state) {
    return state.showModalStarted;
  },
  getTotalTenders(state) {
    return state.totalTenders;
  },
  getBidsByCompany(state) {
    return state.bidsByCompany;
  },
};
