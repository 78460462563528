
import { defineComponent, computed } from 'vue';
import PlanFilterPickupAndDeliveryLocation from '@/components/filters/PlanFilterPickupAndDeliveryLocation.vue';
import PlanFilterPickupAndDeliveryDate from '@/components/filters/PlanFilterPickupAndDeliveryDate.vue';
import { useStore } from 'vuex';

export default {
  name: 'PlanFilterPickupAndDelivery',
  components: { PlanFilterPickupAndDeliveryDate, PlanFilterPickupAndDeliveryLocation },
  emits: ['status'],
  setup(props, { emit }) {
    const store = useStore();
    const changeStatus = (statusValue: any) => {
      emit('status', statusValue);
    };

    const planningModeType = computed(() => {
      return store.getters['LoadsStore/getPlanningType'];
    });

    return {
      changeStatus,
      planningModeType,
    };
  },
};
