import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-semibold plan-text-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex justify-center items-center plan-gap-xs rounded-full", _ctx.backgroundClass]),
    style: {"padding":"4px 8px"}
  }, [
    _createVNode(_component_plan_icon, {
      name: "fill-star",
      "is-svg": "",
      "icon-color": "black",
      size: "16"
    }),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.rating), 1)
  ], 2))
}