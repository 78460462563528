
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import { useStore } from 'vuex';
import moment from 'moment'
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanThumbnail from '@/components/forms/PlanThumbnail.vue';
export default defineComponent({
  name: 'PlanCarrierTrackingElementNotification',
  components: { PlanThumbnail, PlanCarrierDefaultModal, PlanTextButton, PlanIcon },
  props: {
    isNotification: { type: Boolean, required: false, default: false },
    isIncident: { type: Boolean, required: false, default: false },
    isAdditionalFee: { type: Boolean, required: false, default: false },
    showDate: { type: String, required: false, default: '' },
    description: { type: String, required: false, default: '' },
    mediaObjects: { type: Array, required: false },
    additionFeeQuantity: { type: Number, required: false, default: 0 },
    additionFeeStatus: { type: String, required: false, default: '' },
  },
  setup (props) {
    const store = useStore()

    const data = reactive({
      showModal: false,
      showAdditionalFeeModal: false,
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const dateFormatted = computed(() => {
      const regionFormat = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'MMM DD, YYYY HH:mm':'DD MMM, YYYY HH:mm'
      return props.showDate.length === 0 ? '' : moment(props.showDate).format(regionFormat)
    })

    function formatPrice (number: number) {
      const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
      const options = {
        style: 'currency',
        currency: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'USD':'EUR',
        maximumFractionDigits: 0,
        useGrouping: true
      };
      return new Intl.NumberFormat(locale, options).format(number);
    }

    return {
      ...toRefs(data),
      actualWidth,
      dateFormatted,
      formatPrice
    }
  }
})
