
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/outline';
// Components
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';

export default defineComponent({
  props: {
    carrier: {
      type: Object,
    },
    origin: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      required: true,
    },
  },
  components: {
    ErrorMessage,
    Field,
    Form,
    InputAmericanFormat,
    XCircleIcon,
  },
  data() {
    return {
      price: null,
    };
  },
});
