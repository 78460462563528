import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-full flex flex-col justify-center items-center plan-gap-md"
}
const _hoisted_2 = { class: "flex flex-col justify-center items-center plan-gap-xs" }
const _hoisted_3 = {
  key: 0,
  class: "w-full text-center truncate plan-text-xs plan-t-prim-100"
}
const _hoisted_4 = {
  key: 1,
  class: "plan-text-xs plan-t-prim-100"
}
const _hoisted_5 = {
  key: 2,
  class: "plan-text-xs plan-t-prim-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    class: _normalizeClass(["plan-template-button", { 'add-plan-template-button': _ctx.newTemplate }])
  }, [
    (_ctx.newTemplate)
      ? (_openBlock(), _createBlock(_component_plan_icon, {
          key: 0,
          name: "plus",
          "is-svg": "",
          "icon-color": "#0097FF",
          size: "30"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.title.length > 0)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass(["w-full plan-text-md font-bold truncate text-center", {'plan-t-sec-500': _ctx.isPlanimatikTemplate}])
              }, _toDisplayString(_ctx.title), 3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.user.length > 0 && !_ctx.isPlanimatikTemplate)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.user), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, "Planimatik")),
            (_ctx.date.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.dateToShow), 1))
              : _createCommentVNode("", true)
          ])
        ]))
  ], 2))
}