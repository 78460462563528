
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierLoadHeader from '@/components/carrier/PlanCarrierLoadHeader.vue';
import PlanCarrierLoadSubHeader from '@/components/carrier/PlanCarrierLoadSubHeader.vue';
import PlanCarrierLoadHeaderNotification from '@/components/carrier/PlanCarrierLoadHeaderNotification.vue';
import PlanCarrierLoadDetails from '@/components/carrier/loadDetail/PlanCarrierLoadDetails.vue';
import PlanCarrierLoadStopData from '@/components/carrier/loadDetail/PlanCarrierLoadStopData.vue';
import PlanCarrierLoadBid from '@/components/carrier/_quoting/PlanCarrierLoadBid.vue';
import { useStore } from 'vuex';
import PlanCarrierLoadingFullScreen from '@/components/carrier/PlanCarrierLoadingFullScreen.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanCarrierLoadAcceptationSteps from '@/components/carrier/PlanCarrierLoadAcceptationSteps.vue';
import PlanCarrierLoadAcceptationMyDataForm from '@/components/carrier/_forms/PlanCarrierLoadAcceptationMyDataForm.vue';
import PlanCarrierLoadAcceptationCarrierPackageForm
  from '@/components/carrier/_forms/PlanCarrierLoadAcceptationCarrierPackageForm.vue';
import { useRouter } from 'vue-router';
import moment from 'moment'
import PlanCarrierLoadAcceptationDriverAndVehicleForm
  from '@/components/carrier/_forms/PlanCarrierLoadAcceptationDriverAndVehicleForm.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useI18n } from 'vue-i18n';
import PlanCarrierSign from '@/components/carrier/PlanCarrierSign.vue';

interface carrierBase {
  load: any,
  loadingLoad: boolean
}

export default defineComponent({
  name: 'CarrierSignOrder',
  components: { PlanCarrierSign, PlanIcon, PlanButton, PlanDefaultCarrierView },
  props: {
    id: { type: String, required: false, default: null }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      loadingAcceptService: false,
      loadingData: false,
      loadingMyData: false,
      loadingCarrierPackage: false,
      activeStep: 'myData',
      rateConfirmationUrl: ''
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const documentWidth = computed(() => {
      let contentWidth = '100%'

      if (actualWidth.value > 640 && actualWidth.value <= 1536) {
        contentWidth = '90%'
      } else if (actualWidth.value > 1536) {
        contentWidth = '75%'
      }

      return contentWidth;
    });

    const getData = async () => {
      data.loadingData = true
      await getLoad()
      await getRateConfirmationWithoutSign()
      data.loadingData = false
    }

    const getLoad = async () => {
      await store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id)
    }

    const getRateConfirmationWithoutSign = async () => {
      await store.dispatch('ShipmentStore/thumbnailWithoutSign', {
        shipment: store.getters['CarrierStore/getLoadData'].shipment.id })
        .then((response) => {
          data.rateConfirmationUrl = URL.createObjectURL(response);
      })
    }

    const goToLoadAccepted = () => {
      router.push({ name: 'CarrierLoadAccepted', params: { id: loadData.value.id }})
    }

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData']
    })

    getData()

    return {
      ...toRefs(data),
      actualWidth,
      documentWidth,
      goToLoadAccepted,
      loadData
    }
  }
})
