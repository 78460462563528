
import { defineComponent, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline';
// import TimePicker from 'vue3-timepicker';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CustomMultiselect from '@/components/forms/CustomMultiselect.vue';
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import PlanInputTime from '@/components/forms/PlanInputTime.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins';
// Styles
import '@/resources/assets/styles/scss/custom.scss';
import '@vuepic/vue-datepicker/dist/main.css';
// Utils
import { compareTimes, formatTimeToObject, hourRange, minutesRange } from '@/utils/formatTime';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';

interface loadSaved {
  loadId: number;
  stops: any[];
}

export default defineComponent({
  name: 'LTLStopsForm',
  components: {
    PhoneNumberSelector,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CustomMultiselect,
    ErrorMessage,
    Field,
    Form,
    GoogleAutocomplete,
    InputAmericanFormat,
    // TimePicker,
    XCircleIcon,
    PlanInputTime,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    loadId: {
      type: Number,
      required: true,
    },
    stopToModify: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      errorsLog: '',
      clickConfirmed: false,
      loadSaved: {} as loadSaved,
      index: 0,
      currentIndex: 0,
      isSendingRequest: false,
      options: [],
      searchText: '',
      componentKeyUpdate: false,
      multiStopsSelected: [] as any[],
      stops: [] as any[],
      isInvalid: true,
      validating: false,
      isSending: false,
      isSendingSubmit: false,
      datesValid: [] as any[],
      validStops: [] as any,
      companyResults: [] as any[],
      selectedCompanys: [] as any[],
      query: '',
      timeouts: [] as any[],
      pendingRequests: 0,
      stopClicked: 0,
      updateWeight: true,
      timeFormat: 'hh:mm a',
      showTime: true,
      city: [] as any,
      palletsErrors: [] as any,
    };
  },
  setup() {
    const time = ref({
      hours: new Date().getHours(),
      minutes: new Date().getMinutes(),
    });
    return {
      time,
    };
  },
  mounted() {
    this.fetchValidStops();
  },
  created() {
    this.$store.dispatch('LoadsStore/packageTypes');
    let localStorageArray = JSON.parse(localStorage.getItem('loadStops') || '[]');
    let loadExistsInLocalStorage = localStorageArray.some(
      (item) => item.loadId === this.currentLoad.id,
    );

    if (loadExistsInLocalStorage && !this.currentLoad.isConsigneeFilled) {
      this.loadSaved = localStorageArray.find((item) => item.loadId === this.currentLoad.id);
      this.convertExecutionDatesToDates(this.loadSaved.stops);
      this.stops = this.loadSaved.stops;
      this.stopClicked = this.stopToModify;
    } else {
      if (this.currentLoad.isConsigneeFilled) {
        this.stops = this.getStops();
        this.stopClicked = this.stopToModify;
      } else {
        this.stops = this.getStops();
        this.stopClicked = this.stopToModify;
        let loadStops = {
          loadId: this.currentLoad.id,
          stops: this.stops,
        };
        localStorageArray.push(loadStops);
        localStorage.setItem('loadStops', JSON.stringify(localStorageArray));
      }
    }
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    actualDate(): any {
      const date = new Date();
      if (date.getTimezoneOffset() <= 0) {
        return new Date(date?.setDate(date.getDate() - 1) as any);
      }
      return date;
    },
    checkIsDone(): any {
      return (i) => {
        const item = this.stops[i];
        return (
          item &&
          item.companyName !== '' &&
          item.location &&
          item.location.zip &&
          item.location.address &&
          item.executionDate &&
          item.contact &&
          item.executionWindowStartTime &&
          item.executionWindowEndTime
        );
      };
    },
    commodityUnitTypes(): any {
      return this.$store.getters['LoadsStore/packageTypes'].FTL;
    },
    companyList(): any {
      return this.$store.getters['UserStore/getCompany'];
    },
    compareTimes(): any {
      return compareTimes;
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    deliveries(): any {
      return this.currentLoad.stops.filter(
        (stop) => stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL',
      );
    },
    deliveriesMulti(): any {
      return this.currentLoad.stops.filter(
        (stop) => stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL',
      );
    },
    deliveryList(): any {
      const array = [] as any;
      this.deliveries.forEach((stop) => {
        if (stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL') {
          array.push(stop.location.city);
        }
      });
      return array;
    },
    formatTimeToObject(): any {
      return formatTimeToObject;
    },
    hourRange(): any {
      return hourRange;
    },
    isCanceled(): boolean {
      return this.currentLoad.status === 'CANCELED';
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    maxDate(): any {
      const stop = this.stops.find((stop) => stop.type === 'DELIVERY_FINAL');
      return new Date(stop.executionDate);
    },
    minDate(): any {
      const initialPickup = this.stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      return new Date(initialPickup.executionDate);
    },
    minutesRange(): any {
      return minutesRange;
    },
    orderedStops(): any {
      const stops = this.stops;
      const initialPickup = stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      const finalDelivery = stops.find((stop) => stop.type === 'DELIVERY_FINAL');
      return [initialPickup, finalDelivery].filter((stop) => stop);
    },
    pickups(): any {
      return this.currentLoad.stops.filter(
        (stop) => stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP',
      );
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
  },
  methods: {
    changePhone(type, phone, index) {
      switch (type) {
        case 'prefix':
          this.orderedStops[index].phonePrefix = phone.prefix;
          break;
        case 'number':
          this.orderedStops[index].phoneNumber = phone.number;
          break;
        default:
          return;
      }
    },
    checkDates() {
      this.updateStops();
      const initialPickup = this.stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      this.stops.forEach((stop) => {
        if (initialPickup.executionDate > stop.executionDate) {
          stop.executionDate = initialPickup.executionDate;
        }
      });
    },
    convertExecutionDatesToDates(stops) {
      for (let obj of stops) {
        let executionDate = obj.executionDate;
        let date = new Date(executionDate);
        obj.executionDate = date;
      }

      return stops;
    },
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    deleteStop() {
      let localStorageArray = JSON.parse(localStorage.getItem('loadStops') || '[]');

      let indexToDelete = localStorageArray.findIndex(
        (item) => item.loadId === this.currentLoad.id,
      );

      if (indexToDelete !== -1) {
        localStorageArray.splice(indexToDelete, 1);
        localStorage.setItem('loadStops', JSON.stringify(localStorageArray));
      }
    },
    fetchValidStops() {
      this.orderedStops.forEach(async (item: any, index) => {
        this.validStops[index] = await this.stopValid(index);
      });
    },
    async findShipper(query, zip, index, item) {
      if (this.isSuperAdminRoute) {
        BaseComponent.methods?.showToastError(this.$t('access-denied-no-action'));
        return;
      }
      if (!query.text) return;
      if (this.timeouts[index]) {
        clearTimeout(this.timeouts[index]);
      }

      try {
        this.pendingRequests++;

        if (query.text) {
          item.companyName = query.text;
          this.isSendingRequest = true;
          const response = await this.$store.dispatch('UserStore/getCompany', {
            query: query.text,
            zip: zip,
          });

          const newData = response.map((obj) => {
            const { ...rest } = obj;
            return { value: `${obj.company} - ${obj.address}`, ...rest };
          });

          this.options = newData;

          this.companyResults[index] = newData;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.pendingRequests--;
        this.isSendingRequest = false;
      }
    },
    getDefaultDelivery(stop: any) {
      if (
        (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') &&
        this.deliveries.length === 1
      ) {
        return '/api/stops/' + this.deliveries[0].id;
      }
      return null;
    },
    getDefaultDeliveryObject(stop: any) {
      if (
        (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') &&
        this.deliveries.length === 1
      ) {
        return [this.deliveries[0]];
      }
      return [];
    },
    getStops(): any {
      let stopsArray: any[] = [];
      if (!this.currentLoad.stops) return;
      this.currentLoad.stops.forEach((stop) => {
        this.datesValid.push({
          date: false,
          timeStart: false,
          timeEnd: false,
        });

        let obj = {
          id: stop.id,
          type: stop.type,
          contact: '',
          portStop: stop?.portStop,
          airportStop: stop?.airportStop,
          multiStop:
            stop?.multiStop.length > 0 ? stop.multiStop : this.getDefaultDeliveryObject(stop),
          lumperFeePrice: stop?.lumperFeePrice ? stop.lumperFeePrice : null,
          lumperFee: stop.lumperFeePrice ? true : false,
          executionDate: this.currentLoad.isConsigneeFilled
            ? this.getDayAfter(stop.executionDate)
            : this.getDayAfter(stop.executionDate),
          displayDate: stop.executionDate ? new Date(stop.executionDate) : '',
          executionWindowStartTime: stop.executionWindowStartTime
            ? stop.executionWindowStartTime
            : undefined,
          executionWindowEndTime: stop.executionWindowEndTime ? stop.executionWindowEndTime : '',
          companyName: stop.companyName ? stop.companyName : '',
          observations: stop.observations ? stop.observations : '',
          phoneNumber: stop.phoneNumber ? stop.phoneNumber : '',
          phonePrefix: stop.phonePrefix ? stop.phonePrefix : '1',
          appointmentType: 'FCFS',
          location: {
            ...stop.location,
          },
          commodities: [
            {
              id: stop.commodities[0] ? stop.commodities[0].id : null,
              nPallets: stop.commodities[0] ? stop.commodities[0].nPallets : '',
              packageType: stop.commodities[0] ? stop.commodities[0].packageType : { id: null },
            },
          ],
          deliveryStop: stop.deliveryStop
            ? '/api/stops/' + stop.deliveryStop.id
            : this.getDefaultDelivery(stop),
          poNumber: stop.poNumber ? stop.poNumber : '',
        };

        stopsArray.push(obj);
      });
      return stopsArray;
    },
    handleShipperSelection(event, stop, i) {
      if (event !== null) {
        stop.companyName = event.item.company;
        stop.location.address = event.item.address;
        this.options = [];
        this.updateStops();
      }
    },
    handleStopClicked(id, index) {
      if (this.stopClicked === id) {
        this.stopClicked = 0;
      } else {
        this.stopClicked = id;
      }

      setTimeout(() => {
        (this.$refs['stop' + index] as any)[0].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 500);
      this.fetchValidStops();
    },
    openNext(id) {
      const stopId = id.split('/').pop();
      this.fetchValidStops();
      this.stopClicked = +stopId;
    },
    onPlaceChanged(place: any, stop: any) {
      stop.location = {
        address: stop.location.address,
        lat: place.latitude,
        long: place.longitude,
        zip: parseInt(place.postal_code),
        city: place.locality,
        state: {
          id: this.states.find((state) => state.iso === place.administrative_area_level_1).id,
          name: place.administrative_area_level_2,
          iso: place.administrative_area_level_1,
        },
      };
    },
    portOrAirport(pick, stop) {
      if (pick === 'port') {
        stop.airportStop = false;
      }
      if (pick === 'airport') {
        stop.portStop = false;
      }
    },
    setDeliveryData(stop: any) {
      this.updateWeight = false;
      const id = stop.deliveryStop.split('/').pop();
      const actualStop = this.orderedStops.find((stop) => +stop.id === +id);
      // if (!stop.multiStop.some((item) => item.id === actualStop.id)) {
      //   stop.multiStop.push(actualStop)
      // }
      actualStop.poNumber = stop.poNumber;
      actualStop.commodities = [
        {
          id: stop.commodities[0] ? stop.commodities[0].id : null,
          nPallets: stop.commodities[0] ? stop.commodities[0].nPallets : '',
          packageType: stop.commodities[0] ? stop.commodities[0].packageType : '',
          weight: stop.commodities[0]?.weight,
          commodityName: stop.commodities[0]
            ? stop.commodities[0].commodityName
            : this.currentLoad.commodityMaster.commodityName,
        },
      ];
      setTimeout(() => {
        this.updateWeight = true;
      }, 300);
    },
    setStops(item, city, idx) {
      if (item.multiStop.some((item) => item.id === city.id)) {
        // item.multiStop = item.multiStop.filter((item) => item.multiStop.id === city.id);
        if (item?.multiStop.length !== undefined) {
          if (item?.multiStop.length === 1) {
            item.deliveryStop = '';
          }
        }
        this.multiStopsSelected.splice(idx - 1, 1);
        item.multiStop.splice(idx - 1, 1);
      } else {
        const id = item.deliveryStop.split('/').pop();
        const actualStop = this.orderedStops.find((stop) => +stop.id === +id);

        if (!item.multiStop.some((item) => item.id === actualStop.id)) {
          item.multiStop.push(actualStop);
          this.multiStopsSelected.push(actualStop);
        }
      }
    },
    async stopValid(index) {
      if (this.$refs['form' + index]) {
        const validation = await (this.$refs['form' + index] as any)[0].validate();
        if (!validation?.valid) {
          return false;
        }
      }
      return true;
    },
    updateCompany(stop, company) {
      this.updateStops();
    },
    updateStops() {
      if (!this.currentLoad.isConsigneeFilled) {
        let localStorageArray = JSON.parse(localStorage.getItem('loadStops') || '[]');
        let findLoadTosave = localStorageArray.find((item) => item.loadId === this.currentLoad.id);
        if (findLoadTosave) {
          findLoadTosave.stops = this.stops;
          localStorage.setItem('loadStops', JSON.stringify(localStorageArray));
        }
      }
    },
    updateHour(item) {
      if (item.executionWindowEndTime !== null) {
        if (typeof item.executionWindowEndTime === 'string')
          item.executionWindowEndTime = item.executionWindowEndTime;
        const itemCompare = this.compareTimes(
          item.executionWindowStartTime,
          item.executionWindowEndTime,
        );
        if (itemCompare) {
          item.executionWindowEndTime = item.executionWindowStartTime;
          this.showTime = false;
          setTimeout(() => {
            this.showTime = true;
          }, 1);
        }
      }
      if (item.executionWindowStartTime !== null) {
        if (typeof item.executionWindowStartTime === 'string')
          item.executionWindowStartTime = item.executionWindowStartTime;
      }
      this.updateStops();
    },
    async validateConsignee() {
      if (this.isSuperAdminRoute) {
        return;
      }
      if (this.isSendingSubmit) return;
      if (this.pendingRequests !== 0) return;

      this.validating = true;
      this.clickConfirmed = true;
      this.isSendingSubmit = true;
      let clonedLoads = [] as any;
      this.fetchValidStops();
      for (let index = 0; index < this.orderedStops.length; index++) {
        const validation = await (this.$refs['form' + index] as any)[0].validate();

        if (!validation.valid) {
          this.isSendingSubmit = false;
        }
      }
      if (!this.isSendingSubmit) {
        BaseComponent.methods?.showToastError(this.$t('field-are-required'));
        return;
      }

      this.isInvalid = false;

      clonedLoads = this.deepClone(this.orderedStops);

      clonedLoads.forEach((stop) => {
        stop.commodities[0].nPallets = Number(stop.commodities[0].nPallets);
        stop.commodities[0].weight = Number(stop.commodities[0].weight);

        stop.executionDate = this.formatDateStringReverse(stop.executionDate);

        stop.executionWindowStartTime = stop.executionWindowStartTime;
        stop.executionWindowEndTime = stop.executionWindowEndTime;
      });

      await this.$store
        .dispatch('LoadsStore/putLTLBol', {
          id: this.loadId,
          object: { stops: clonedLoads },
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('consignee-validated'));
          this.deleteStop();
          this.$store.dispatch('LoadsStore/loadById', this.loadId);
          this.$emit('close');
        })
        .catch((err) => {
          this.errorsLog = err?.response?.data?.title;
          this.$emit('close');
        })
        .finally(() => {
          this.isSendingSubmit = false;
          this.validating = false;
        });
    },
  },
  watch: {
    currentLoad() {
      this.stops = this.getStops();
    },
  },
});
