
import { defineComponent } from 'vue';
import { Form, ErrorMessage } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import Spin from '@/components/spin/AnimateSpin.vue';
// Utils
import { objectLocationToString } from '@/utils/formatLocation';
import PlanCountrySelector from '@/components/forms/PlanCountrySelector.vue';

export default defineComponent({
  name: 'NotifyPosition',
  components: {
    PlanCountrySelector,
    DatePickerComponent,
    ErrorMessage,
    Form,
    GoogleAutocomplete,
    Spin,
    XCircleIcon,
  },
  mixins: [BaseComponent],
  data() {
    return {
      allowed: false,
      completed: false,
      defaultCountrySelected: '' as any,
      files: [] as any,
      file: null as any,
      location: '',
      googlePlaceId: '',
      isMacropointID: false,
      maxLength: 500,
      macropointID: null,
      manualDateTimeETA: '',
      observations: '',
      successTexts: {
        header: this.$t('completed'),
        body: this.$t('all-is-done'),
      },
      sending: false,
      seePlaceHolder: false,
      shipmentId: 0,
      status: 'IN_TRANSIT' as any,
      stop: null as any,
      truckLocation: {} as any,
      updatingEta: false,
    };
  },
  async created() {
    const id = this.$route.params.id;
    this.$store.dispatch('CountryStore/getCountries');
    this.$store.dispatch('LoadsStore/states');
    this.$store.dispatch('ShipmentStore/shipment', { id: id });
    if (this.load.manualDateTimeETA) {
      this.manualDateTimeETA = this.load.manualDateTimeETA;
    }
  },
  mounted() {
    if (this.shipment?.trackingSubStatus) {
      this.status = this.shipment?.trackingSubStatus;
    }
  },
  computed: {
    seePlaceHolderComputed(): any {
      return this.seePlaceHolder && this.observations === '';
    },
    actualDate() {
      return new Date();
    },
    countriesList(): any {
      return this.$store.getters['CountryStore/getCountries'];
    },
    currentCountryIso(): any {
      return this.defaultCountrySelected
        ? this.defaultCountrySelected?.iso
        : this.countriesList?.find((country) => country?.id === this.currentCountry?.id);
    },
    currentLoad(): any {
      return this.shipment.loads[0];
    },
    deliverCount(): any {
      if (!this.shipment.loads) {
        return '';
      }
      return this.shipment.loads[0].stops.filter((stop) => stop.type === 'DELIVERY').length + 1;
    },
    deliverFinalStop(): any {
      if (!this.shipment.loads) {
        return '';
      }
      const initialStop = this.shipment.loads[0].stops.find(
        (stop) => stop.type === 'DELIVERY_FINAL',
      );
      return initialStop ? initialStop : '';
    },
    load(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    objectLocationToString() {
      return objectLocationToString;
    },

    pickupInitialStop(): any {
      if (!this.shipment.loads) {
        return '';
      }
      const initialStop = this.shipment.loads[0].stops.find(
        (stop) => stop.type === 'PICKUP_INITIAL',
      );
      return initialStop ? initialStop : '';
    },
    pickUpsCount(): any {
      if (!this.shipment.loads) {
        return '';
      }
      return this.shipment.loads[0].stops.filter((stop) => stop.type === 'PICKUP').length + 1;
    },
    shipment(): any {
      return this.$store.getters['ShipmentStore/shipment'];
    },
    shipmentTrackingSubStatus(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']?.ShipmentTrackingSubStatus;
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
    stopsList(): any {
      let list = [] as any;
      switch (this.status) {
        case 'ON_SITE':
          list = this.currentLoad.stops;
          break;
        case 'LOADING':
          list = this.currentLoad.stops.filter(
            (stop) => stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP',
          );
          break;
        case 'UNLOADING':
          list = this.currentLoad.stops.filter(
            (stop) => stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL',
          );
          break;
        default:
          break;
      }
      return list;
    },
    stopSelected(): any {
      return this.stopsList.find((stop) => stop.id === this.stop);
    },
    stops(): any {
      const shipment = this.shipment;
      if (!shipment || !shipment.loads || shipment.loads.length === 0) {
        return [];
      }
      return shipment.loads[0].stops;
    },
    uploadText(): any {
      return this.files.length ? `${this.$t('files-selected', { count: this.files.length })}` : '';
    },
  },
  methods: {
    updateGeneralObservations(event) {
      const newText = event.target.innerText;
      const maxLength = 500;

      if (newText.length <= maxLength) {
        this.observations = newText;
      } else {
        // Truncate the text to the first maxLength characters
        this.observations = newText.substring(0, maxLength);

        // Optionally, you can prevent further input
        event.preventDefault();
      }
    },
    onFocus() {
      this.seePlaceHolder = false;
    },
    onBlur() {
      if (this.observations.length === 0) {
        this.seePlaceHolder = true;
      }
    },
    async onPlaceChanged(event) {
      const states: any = this.$store.getters['LoadsStore/getStates'];
      let stateObj = states.find((state) => state.iso === event.administrative_area_level_1);

      if (!stateObj && event.administrative_area_level_2) {
        stateObj = states.find((state) => state.name === event.administrative_area_level_2);
      }

      let location;

      if (stateObj?.id) {
        location = {
          lat: event.latitude,
          long: event.longitude,
          zip: event.postal_code,
          city: event.locality,
          state: { id: stateObj.id },
          googlePlaceId: event.place_id,
        };
      } else {
        location = {
          lat: event.latitude,
          long: event.longitude,
          zip: event.postal_code,
          city: event.locality,
          googlePlaceId: event.place_id,
        };
      }

      this.truckLocation = {
        shipment: {
          id: +this.$route.params.id,
        },
        location,
        macropointId: this.isMacropointID ? this.macropointID : null,
      };

      const truckLocation = this.truckLocation?.location;

      const zip = truckLocation?.zip || '';
      const city = truckLocation?.city || '';
      const stateId = truckLocation?.state?.id;
      const stateName = stateId
        ? this.states.find((state) => state.id === stateId)?.name || ''
        : '';

      const locationParts = [zip, city, stateName].filter(Boolean);
      this.location = locationParts.join(', ');

      this.allowed = true;
    },
    async sendLocation() {
      if (this.truckLocation !== null) {
        this.allowed = true;
        this.sending = true;

        this.truckLocation.observations = this.observations;

        await this.$store
          .dispatch('ShipmentStore/notifyPosition', this.truckLocation)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(
              this.$t('message-new-location-selected', { city: this.truckLocation?.location.city }),
            );

            if (this.status !== '') {
              this.$store
                .dispatch('ShipmentStore/updateTrackingSubStatus', {
                  id: this.shipment.id,
                  body: { trackingSubStatus: this.status },
                })
                .catch((err) => {
                  BaseComponent.methods?.showToastError(err?.response?.data?.detail);
                });
            }

            this.allowed = false;
            this.sending = false;
            this.$emit('close');
          })
          .catch((err) => {
            if (err.response.status === 422) {
              BaseComponent.methods?.showToastError(this.$t('incorrect-location'));
            } else {
              BaseComponent.methods?.showToastError(
                err?.response?.data?.detail ?? err?.response?.data?.details,
              );
            }
            this.allowed = false;
            this.sending = false;
          });
      }
    },
    setTruckLocation() {
      this.truckLocation = {
        shipment: {
          id: +this.$route.params.id,
        },
        macropointId: this.isMacropointID ? this.macropointID : null,
        location: {
          googlePlaceId: '',
          lat: this.stopSelected.location.lat,
          long: this.stopSelected.location.long,
          zip: this.stopSelected.location.zip,
          city: this.stopSelected.location.city,
          state: { id: this.stopSelected.location.state.id },
        },
      };
      this.allowed = true;
    },
    updateETA() {
      if (this.allowed) return;

      this.allowed = true;
      this.updatingEta = true;
      this.$store
        .dispatch('LoadsStore/updateETA', {
          id: this.currentLoad.id,
          manualDateTimeETA: this.manualDateTimeETA,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('success-update-ETA'));
        })
        .finally(() => {
          this.updatingEta = false;
          this.allowed = false;
        });
    },
    async validateForm() {
      if (!this.allowed) return;
      this.sending = true;
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid) {
        this.sending = false;
        return;
      }
      this.sendLocation();
    },
    handleClick(country) {
      this.defaultCountrySelected = country;
    },
    clearInput() {
      this.location = '';
    },
    typeStopObject(): any {
      let objectTypes = null as any;
      switch (this.stop.type) {
        default:
          objectTypes = {
            label: 'city-zip',
            placeholder: 'placeholder-enter-your-stop-zip',
          };
          break;
      }
      return objectTypes;
    },
  },
  watch: {
    stopsList() {
      if (this.stopsList.length > 0) {
        this.stop = this.stopsList[0].id;
        this.setTruckLocation();
      }
    },
  },
});
