import axios from 'axios';
import { AxiosHeaders } from 'axios';
// Base
import BaseComponent from '@/base/BaseComponent';
// Resources
import i18n from '@/resources/locales';
// Router
import router from '@/router';
// Services
import store from '@/services/store';

/** API Swagger: https://dev-api-planning.cargobot.io/api **/

let isRefreshing = false;

export default class InterceptorsSetup {
  i18n: any;
  constructor() {
    this.i18n = i18n;
    this.initDefault();
    this.request();
    this.response();
  }

  initDefault() {
    axios.defaults.baseURL = process.env.VUE_APP_URL;
    axios.defaults.headers.get.Accept = 'application/json';
    axios.defaults.headers.put['Content-Type'] = 'application/json';
  }

  request() {
    // let isAlreadyConfig = false;
    axios.interceptors.request.use(
      async (config) => {
        const token = localStorage.token;
        if (
          token &&
          token !== 'undefined' &&
          !(config as { url: string }).url.includes('token') &&
          !(config as { url: string }).url.includes('geocode') &&
          !(config as { url: string }).url.includes('send-reset-password')
        ) {
          const impersonatedUserId = store.getters['UserStore/getImpersonatedUserId'];
          if (impersonatedUserId && config.headers) {
            (config.headers as AxiosHeaders).set('X-Switch-User', impersonatedUserId);
          }

          (config.headers as AxiosHeaders).set('Authorization', `Bearer ${token}`);
        }

        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  response() {
    let reqUrl, reqMethod, reqData, reqParams;
    axios.interceptors.request.use((request) => {
      if (process.env.VUE_APP_SHOW_CALLS) {
        reqUrl = request.url;
        reqMethod = request.method;
        reqData = request.data;
        reqParams = request.params;
      }
      return request;
    });

    axios.interceptors.response.use(
      (response) => {
        if (process.env.VUE_APP_SHOW_CALLS) {
          console.log('API CALL SUCCESS', '\nResponse', {
            url: response.config.url,
            method: response.config.method,
            data: response.config.data,
            params: response.config.params,
            status: response.status,
            responseData: response.data,
          });
        }

        return response;
      },
      (error) => {
        const originalConfig = error.config;
        const { data, status } = error.response;
        const url = window.location.href;
        const route: any = router.currentRoute;
        if (status > 400) {
          if (
            data &&
            (data.message === 'Expired JWT Token' || data.message === 'Invalid JWT Token')
          ) {
            return this.refreshToken(originalConfig);
          }
          if (
            (data && data.message === 'JWT Token not found') ||
            data.message === 'Missing JWT Refresh Token' ||
            data.message === 'Invalid JWT Refresh Token' ||
            status === 403
          ) {
            // Waiting for client review
            if (
              (url.includes('carrier') && url.includes('hash')) ||
              route._value.name === 'planificationDetailTokenHash' ||
              route._value.name === 'lanesRFQId'
            ) {
              store.commit('UserStore/setShowHash', true);
            }
            setTimeout(async () => {
              const win: Window = window;
              if (
                !win.location.href.includes('activate-account') &&
                !win.location.href.includes('password-reset') &&
                !win.location.href.includes('carrier') &&
                (!win.location.href.includes('shipper') ||
                  win.location.href.includes('superadmin')) &&
                !win.location.href.includes('signin') &&
                route._value.name !== 'planificationDetailToken' &&
                route._value.name !== 'planificationDetailTokenHash' &&
                route._value.name !== 'lanesRFQId'
              ) {
                // win.location = '/signin';
              }
            }, 500);
          }
          if (data.title) {
            if (route._value.name !== 'plans' && route._value.name !== 'plansCode') {
              BaseComponent.methods?.showToastError(data?.title);
            }
          }
        }
        if (data?.errorCode === 'ALPINE_SYNC-13') {
          BaseComponent.methods?.showToastError(data?.title);
        }
        // if (data?.errorCode === 'C-18') {
        //   store.commit('CreditStore/setNoCreditModal', true);
        // }

        if (process.env.VUE_APP_SHOW_CALLS) {
          console.log('API CALL ERROR', {
            url: error.config.url,
            method: error.config.method,
            data: error.config.data,
            params: error.config.params,
            status: error.response.status,
            responseData: error.response.data,
          });
        }

        return Promise.reject(error);
      },
    );
  }

  async refreshToken(originalConfig) {
    const url = window.location.href;
    const route: any = router.currentRoute;
    const refreshToken = localStorage.refresh_token;
    const refresh = await store.dispatch('AuthStore/refreshToken', refreshToken);

    if (refresh) {
      isRefreshing = false;
      return axios(originalConfig);
    } else {
      // Waiting for client review
      if (
        (url.includes('carrier') && url.includes('hash')) ||
        route._value.name === 'planificationDetailTokenHash' ||
        route._value.name === 'lanesRFQId'
      ) {
        store.commit('UserStore/setShowHash', true);
      }
      setTimeout(async () => {
        const win: Window = window;
        if (
          !win.location.href.includes('activate-account') &&
          !win.location.href.includes('carrier')
        ) {
          // win.location = '/signin';
        }
      }, 500);
    }
  }
}
