
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import SendModal from '@/components/modals/Modal.vue';
// Resources
import i18n from '@/resources/locales';

export default defineComponent({
  name: 'PlanModalShare',
  mixins: [BaseComponent],
  components: { Form, Field, ErrorMessage, PlanButton, PlanButtonIcon, SendModal },
  props: { deliveryStopID: String, fileType: String, stopID: String },
  setup(props, { emit }) {
    const $t = i18n.global.t;
    const store = useStore();

    let emails = ref('');
    let loadingSendFiles = ref(false);

    // Computed
    const currentLoad: any = computed(() => {
      return store.getters['LoadsStore/currentLoad'];
    });

    // Methods
    const closeSendModal = () => {
      emails.value = '';
      emit('close');
    };

    const sendDocumentToEmail = (emails) => {
      loadingSendFiles.value = true;
      store
        .dispatch('ShipmentStore/sendDocumentToEmail', {
          loadId: currentLoad.value.id,
          type: props.fileType,
          emails: emails,
          stopID: props.stopID,
          deliveryStopID: props.deliveryStopID,
        })
        .then((response) => {
          loadingSendFiles.value = false;
          closeSendModal();
        })
        .catch((err) => {
          loadingSendFiles.value = false;
          console.log(err);
        });
    };
    return { closeSendModal, emails, loadingSendFiles, sendDocumentToEmail };
  },
});
