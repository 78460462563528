import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_add_template_modal = _resolveComponent("add-template-modal")!
  const _component_update_template_modal = _resolveComponent("update-template-modal")!
  const _component_template_detail = _resolveComponent("template-detail")!
  const _component_select_template_modal = _resolveComponent("select-template-modal")!
  const _component_blast_email_form = _resolveComponent("blast-email-form")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.$t('send-email'),
    size: "lg-auto",
    "hide-close-button": ""
  }, {
    "header-buttons": _withCtx(() => [
      _createVNode(_component_plan_button, {
        onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSelectTemplate = true)),
        type: "primary-transparent",
        label: _ctx.$t('select-template-button')
      }, null, 8, ["label"])
    ]),
    content: _withCtx(() => [
      (_ctx.showAddTemplate)
        ? (_openBlock(), _createBlock(_component_add_template_modal, {
            key: 0,
            onClose: _ctx.closeAddTemplateModal,
            onCloseAndShow: _ctx.closeAndShowTemplate
          }, null, 8, ["onClose", "onCloseAndShow"]))
        : _createCommentVNode("", true),
      (_ctx.showUpdateTemplate)
        ? (_openBlock(), _createBlock(_component_update_template_modal, {
            key: 1,
            onClose: _ctx.closeUpdateModal,
            id: _ctx.templateId
          }, null, 8, ["onClose", "id"]))
        : _createCommentVNode("", true),
      (_ctx.showViewTemplate)
        ? (_openBlock(), _createBlock(_component_template_detail, {
            key: 2,
            onClose: _ctx.closeViewTemplateModal,
            id: _ctx.templateId,
            onUpdateTemplate: _ctx.updateTemplateOnClick,
            onUseTemplate: _ctx.useTemplate,
            onCloneTemplate: _ctx.cloneTemplate
          }, null, 8, ["onClose", "id", "onUpdateTemplate", "onUseTemplate", "onCloneTemplate"]))
        : _createCommentVNode("", true),
      (_ctx.showSelectTemplate)
        ? (_openBlock(), _createBlock(_component_select_template_modal, {
            key: 3,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSelectTemplate = false)),
            onAddTemplate: _ctx.addTemplateOnClick,
            onViewTemplate: _ctx.viewTemplateOnClick
          }, null, 8, ["onAddTemplate", "onViewTemplate"]))
        : _createCommentVNode("", true),
      _createVNode(_component_blast_email_form, {
        onUpdate: _ctx.update,
        "pre-subject": _ctx.subject,
        "pre-message": _ctx.additionalInfo
      }, null, 8, ["onUpdate", "pre-subject", "pre-message"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          type: "cancel",
          label: _ctx.$t('cancel')
        }, null, 8, ["onOnClick", "label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.sendEmail,
          type: "primary",
          disabled: !_ctx.isValid,
          label: _ctx.$t('send'),
          loading: _ctx.isSendingEmails,
          style: {"width":"120px"}
        }, null, 8, ["onOnClick", "disabled", "label", "loading"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}