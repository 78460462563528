import globalAxios from 'axios';

const addMemberToChatLoad = (id: string) => {
  return globalAxios.post(`/chat/${id}/addMeToLoadChat`);
};

const addNewMember = (id: string, data: object) => {
  return globalAxios.post(`/chat/${id}/addNewMember`, data);
};

const createNewMessage = (id: string, data: object) => {
  return globalAxios.post(`/chat/${id}/createNewMessage`, data);
};

const createChat = (data: object) => {
  return globalAxios.post('/chat/createChat', data);
};

const createChatForLanesRFP = (data: object) => {
  return globalAxios.post('/chat/createChatForLanesRFP', data);
};

const createChatForLoad = (data: object) => {
  return globalAxios.post('/chat/createChatForLoad', data);
};

const createQuotingChatForLoad = (data: object) => {
  return globalAxios.post('/chat/createQuotingChatForLoad', data);
};

const createChatGeneral = (data: object) => {
  return globalAxios.post('/chat/createChatGeneral', data);
};

const getUnreadMessages = (params: object) => {
  const response = globalAxios.get('/chat/getNotReadMessages', { params });
  return response;
};

const getTotalUnreadChats = () => {
  const response = globalAxios.get('/chat/getTotalUnreadChats');
  return response;
};

const getConversations = (params: object) => {
  return globalAxios.get('/chat/getConversations', { params });
};

const getConversationById = (id: string) => {
  return globalAxios.get(`/chat/${id}`);
};

const getMessages = (data: any) => {
  return globalAxios.get(`/chat/${data.id}/messages`, { params: data.params });
};

const setChatToClose = (id: string, data: object) => {
  return globalAxios.post(`/chat/${id}/setChatToClose`, data);
};

const setChatToRead = (id: string) => {
  return globalAxios.post(`/chat/${id}/setChatToRead`);
};

export default {
  getUnreadMessages,
  getTotalUnreadChats,
  createChat,
  createChatForLanesRFP,
  createChatForLoad,
  createChatGeneral,
  createQuotingChatForLoad,
  getConversations,
  getConversationById,
  addNewMember,
  createNewMessage,
  getMessages,
  setChatToClose,
  setChatToRead,
  addMemberToChatLoad,
};
