
import { computed, defineComponent, onBeforeMount, reactive, toRefs, watch } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import PlanIcon from '@/components/icons/PlanIcon.vue';

// Base

export default defineComponent({
  name: 'CarrierBalancer',
  props: {
    id: { type: String, required: true },
  },
  components: { PlanIcon },

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      iconColor: '#0097FF',
      showShareModal: false,
      isSendingEmails: false,
      recipients: '',
      subject: '',
      message: '',
      isValid: false,
    });

    const isCarrierAssignedToThisLoad = computed(() => {
      return (
        store.getters['UserStore/getCurrentUser'].company?.id ===
        loadData.value.shipment?.assignedCompany?.company?.id
      );
    });

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const getData = () => {
      store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id).then((response) => {
        const actualState = store.getters['CarrierStore/getLoadData'].status;

        if (route.path.includes('rfp/quoting')) {
          router.push({ name: 'CarrierRfpQuote', params: { id: props.id } });
          return;
        }

        if (route.name === 'DriverUpdatePosition') {
          store.commit('CarrierStore/setIsDriver', true);
          router.push({ name: 'CarrierNotifyPosition', params: { id: props.id } });
          return;
        }

        if (route.name === 'CarrierUpdatePosition') {
          router.push({ name: 'CarrierNotifyPosition', params: { id: props.id } });
          return;
        }

        if (
          actualState === 'NORMAL_QUOTING' ||
          actualState === 'CONFIRMED' ||
          !isCarrierAssignedToThisLoad.value
        ) {
          router.push({ name: 'CarrierLoadDetails', params: { id: props.id } });
        } else if (actualState === 'ACCEPTED') {
          router.push({ name: 'CarrierLoadAccepted', params: { id: props.id } });
        } else if (
          actualState === 'ASSIGNED' ||
          actualState === 'IN_PROGRESS' ||
          actualState === 'FINISHED'
        ) {
          router.push({ name: 'CarrierLoadAssigned', params: { id: props.id } });
        } else {
          router.push({ name: 'CarrierLoadDetails', params: { id: props.id } });
        }
      });
    };

    getData();

    return {
      ...toRefs(data),
      translate,
    };
  },
});
