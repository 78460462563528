
import { defineComponent } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'LoadCardFooterData',
  components: { PlanIcon },
  props: {
    distance: { type: String, required: true, default: '' },
    vehicle: { type: String, required: true, default: '' },
    goodsType: { type: String, required: true, default: '' },
    specialRequirements: { type: String, required: true, default: '' },
  }
})
