
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useI18n } from 'vue-i18n';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanDefaultDeleteModal from '@/components/defaults/PlanDefaultDeleteModal.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
export default defineComponent({
  name: 'PlanContactTags',
  components: {
    PlanLoading,
    PlanDefaultDeleteModal,
    PlanButton,
    PlanInputText,
    PlanDefaultModal,
    PlanIcon,
  },
  props: {
    actualTags: { type: String, required: true, default: '' },
    companyId: { type: Number, required: true, default: -1 },
    loadingContacts: { type: Boolean, required: false, default: false },
    onlyLabel: { type: Boolean, required: false, default: false },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      loading: false,
      loadingDelete: false,
      newTag: '',
      showModal: false,
      tagToDelete: '',
    });

    const addTag = async () => {
      data.loading = true;
      let tagsToAdd = JSON.parse(props.actualTags);
      tagsToAdd.push(data.newTag);

      await store
        .dispatch('LoadsStore/putCompanyTags', {
          id: props.companyId,
          tags: tagsToAdd,
        })
        .then(() => {
          emit('update');
          data.newTag = '';
          data.loading = false;
        });
    };
    const deleteTag = async () => {
      data.loadingDelete = true;
      let tagsToDelete = JSON.parse(props.actualTags);
      tagsToDelete.splice(tagsToDelete.indexOf(data.tagToDelete), 1);

      await store
        .dispatch('LoadsStore/putCompanyTags', {
          id: props.companyId,
          tags: tagsToDelete,
        })
        .then(() => {
          emit('update');
          data.tagToDelete = '';
          data.loadingDelete = false;
        });
    };

    const isValidTag = computed(() => {
      return data.newTag !== '';
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const tagsToShow = computed(() => {
      let array = JSON.parse(props.actualTags);

      if (array.length === 0) {
        return translate.t('without-tags');
      } else if (array.length === 1) {
        return array[0];
      } else if (array.length === 2) {
        return array.join(` ${translate.t('and')} `);
      }

      return array.length + ' ' + translate.t('tags');
    });

    return {
      ...toRefs(data),
      addTag,
      deleteTag,
      isValidTag,
      tagsToShow,
    };
  },
});
