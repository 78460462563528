import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = {
  file: null as any,
  filesToUpload: [] as any,
  sendingFile: -1,
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
