import { initialState } from '@/services/loads/_store/index';

export default {
  applyFilter(state, newFilter) {
    const indexOfExistingFilter = Object.values(state.appliedFilters).findIndex(
      (filter: any) => filter.id === newFilter.id,
    );

    if (indexOfExistingFilter === -1 && newFilter.value !== '') {
      state.appliedFilters = {
        ...state.appliedFilters,
        [newFilter.id]: { ...newFilter },
      };
    } else if (indexOfExistingFilter !== -1 && newFilter.value !== '') {
      state.appliedFilters = {
        ...state.appliedFilters,
        [newFilter.id]: {
          ...state.appliedFilters[newFilter.id],
          value: newFilter.value,
          loadFilterParam: newFilter.loadFilterParam,
        },
      };
    } else if (indexOfExistingFilter !== -1 && newFilter.value === '') {
      const { [newFilter.id]: _, ...updatedFilters } = state.appliedFilters;
      state.appliedFilters = { ...updatedFilters };
    }
  },
  applyFilterPrevious(state: any, payload: object) {
    state.appliedFilters = payload;
  },
  carrierBidData(state: any, payload: object) {
    state.carrierBidData = payload;
  },
  cleanAppliedFilters(state: any) {
    state.appliedFilters = {};
  },
  cleanLoads(state: any) {
    state.loads = [];
  },
  clearLoadsStore(state: any) {
    Object.assign(state, initialState);
  },
  currentBid(state: any, payload: number) {
    state.currentBid = payload;
  },
  currentLoad(state: any, payload: any) {
    state.currentLoad = payload;
    state.loads.forEach((load, index) => {
      if (load.id === payload.id) {
        state.loads[index] = payload;
      }
    });
  },
  currentLoadEditing(state: any, payload: any) {
    state.currentLoadEditing = payload;
  },
  setShipmentNextStep(state, value) {
    state.shipmentNextStep = value;
  },
  densityClass(state: any, payload: any) {
    state.densityClass = payload;
  },
  feeExplanations(state: any, payload: any) {
    state.feeExplanations = payload;
  },
  filteredCarriers(state: any, payload: any) {
    state.filteredCarriers = payload;
  },
  getLanes(state: any, payload: any) {
    state.lanes = payload;
  },
  getLimitedAcces(state: any, payload: any) {
    state.limitedAccessList = payload;
  },
  getLTLQuotesById(state: any, payload: any) {
    state.getLTLQuotesById = payload;
  },
  lastApiCallId(state: any, payload: any) {
    state.lastApiCallId = payload;
  },
  loadId(state: any, payload: number) {
    state.loadId = payload;
  },
  loadShipmentId(state: any, payload: number) {
    state.loadShipmentId = payload;
  },
  ltlQuotes(state: any, payload: any) {
    state.ltlQuotes = payload;
  },
  newMessageLoad(state: any, payload: any) {
    state.loads.forEach((load, index) => {
      if (load.chat) {
        if (+load.id === +payload.data.loadId && +load.chat.id === +payload.data.id) {
          load.chat?.chatMembers.forEach((member) => {
            if (member.user.id === payload.userId) {
              member.readed = payload.isReaded;
              state.loads.splice(index, 1, load);
            }
          });
        }
      }
      if (payload.data.type === 'QUOTING' && +load.id === +payload.data.loadId) {
        load.quotingChats.forEach((chat) => {
          chat.chatMembers.forEach((member) => {
            if (member.user.id === payload.userId && +chat.id === +payload.data.id) {
              member.readed = payload.isReaded;
              state.loads.splice(index, 1, load);
            }
          });
        });
      }
    });
  },
  newMessageInLoadQuoting(state: any, payload: any) {
    state.currentLoad?.quotingChats?.forEach((chat) => {
      chat.chatMembers.forEach((member) => {
        if (member.user.id === payload.userId && +chat.id === +payload.data.id) {
          member.readed = payload.isReaded;
        }
      });
    });
  },
  newMessageInLoad(state: any, payload: any) {
    state.currentLoad?.chat?.chatMembers?.forEach((member) => {
      if (member.user.id === payload.userId) {
        member.readed = payload.isReaded;
      }
    });
  },
  NMFC(state: any, payload: any) {
    state.NMFC = payload;
  },
  packageTypes(state: any, payload: any) {
    state.packageTypes = payload;
  },
  plannerTotalsDayWeek(state: any, payload: any) {
    state.plannerTotalsDayWeek = payload;
  },
  setPlanningType(state: any, type: string) {
    state.planningType = type;
  },
  setSelectedQuickFilter(state: any, payload: string) {
    state.selectedQuickFilter = payload;
  },
  setTotalClaims(state: any, payload: number) {
    state.totalClaims = payload;
  },
  showFreightInfoModal(state: any, payload: boolean) {
    state.showFreightInfoModal = payload;
  },
  showTemplateModal(state: any, payload: any) {
    state.showTemplateModal = payload;
  },
  showIncidenceModal(state: any, payload: any) {
    state.showIncidenceModal = payload;
  },
  setAppliedFilters(state: any, payload: any) {
    state.appliedFilters = payload;
  },
  stopSelected(state: any, payload: any) {
    state.stopSelected = payload;
  },
  setDocsList(state: any, payload: any) {
    state.docsList = payload;
  },
  setQuotesAverageStats(state: any, payload: any) {
    state.quotesAverageStats = payload;
  },

  setPreviousFilters(state: any, payload: object) {
    state.previousFilters = payload;
  },
  setLoadAditionalDocuments(state: any, payload: object) {
    state.loadAditionalDocuments = payload;
  },
  setLoads(state: any, payload: any) {
    if (payload.config.params.page === 1) {
      state.loads = payload.data;
    } else {
      state.loads.push(...payload.data);
    }
  },
  setLoadInfo(state: any, load: any) {
    state.loadInfo = load;
  },
  setLoadsInfo(state: any, loads: any) {
    if (loads.length > 0) {
      state.loadsInfo = loads;
    } else {
      state.loadsInfo = [];
    }
  },
  setLoadsByDates(state: any, payload: object) {
    state.loadsByDates = payload;
  },
  setRankingRFP(state: any, payload: object) {
    state.rankingRFP = payload;
  },
  setStates(state: any, payload: any) {
    state.states = payload;
  },
  setSaveLoadId(state: any, payload: any) {
    state.saveLoadId = payload;
  },
  setSaveLoads(state: any, payload: any) {
    // Falta la paginación en el back
    // if (payload.config.params.page === 1) {
    //   state.saveLoads = payload.data;
    // } else {
    state.saveLoads = [];
    state.saveLoads.push(...payload);
    // }
  },
  setSaveLoadsSearch(state: any, payload: any) {
    // Falta la paginación en el back
    // if (payload.config.params.page === 1) {
    //   state.saveLoads = payload.data;
    // } else {
    state.saveLoadsSearch = [];
    state.saveLoadsSearch.push(...payload);
    // }
  },
  setTemporalLoadId(state: any, loadId: number) {
    state.temporalLoadId = loadId;
  },
  setTotals(state: any, payload: object) {
    state.totals = payload;
  },
  setTrackingLogs(state: any, payload: object) {
    state.trackingLogs = payload;
  },
  setTrailerTypes(state: any, payload: any) {
    state.trailerTypes = payload;
  },
  setValoration(state: any, payload: any) {
    state.valoration = payload;
  },

  setWarehouses(state: any, payload: any) {
    state.warehousesItems = payload;
  },
  setShipmentFees(state: any, payload: any) {
    state.shipmentFees = payload;
  },
  setSelectedWarehouse(state: any, payload: any) {
    state.selectedWarehouse = payload;
  },
  setWarehousesItemsModified(state: any, payload: any) {
    state.warehousesItemsModified = payload;
  },

  totalSavedBidsLTL(state: any, payload: any) {
    state.totalSavedBidsLTL = payload;
  },
  updateLoadStatus(state: any, load) {
    const loadIndex = state.loads.findIndex((stateLoad: any) => stateLoad.id === Number(load.id));
    if (loadIndex !== -1) {
      state.loads[loadIndex] = load;
    }
  },
  showModalShareDocuments(state: any, payload: boolean) {
    state.showModalShareDocuments = payload;
  },
  updateModalCarrierAssigned(state: any, payload: boolean) {
    state.showModalCarrierAssigned = payload;
  },
  updateModalQuoteSended(state: any, payload: boolean) {
    state.showModalQuoteSended = payload;
  },
};
