
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
//Base
import BaseComponent from '@/base/BaseComponent';
// Components
import ScratchContainer from '@/modules/rfp/_components/ScratchForm.vue';
import ProjectContainer from '@/modules/rfp/_components/ProjectForm.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

interface NewProject {
  name: string | null;
  periodStartDate: Date | any;
  periodEndDate: Date | any;
  timeZone: string;
}

export default defineComponent({
  name: 'NewProjectModal',
  components: {
    PlanButton,
    PlanDefaultModal,
    Form,
    ProjectContainer,
    ScratchContainer,
  },
  mixins: [BaseComponent],
  emits: ['setOpen', 'fetchProjects', 'updateProject'],
  props: {
    creationTypeModal: String,
    open: Boolean,
    type: String,
  },
  data() {
    return {
      allowed: true,
      currentType: null as any,
      loading: false,
      newProject: {
        name: null,
        periodStartDate: null as any,
        periodEndDate: null as any,
        timeZone: null,
      } as unknown as NewProject,
      projectSelected: null,
      sendingAction: false,
      convertedArray: [],
    };
  },
  watch: {
    type: function () {
      this.currentType = this.type;
    },
  },
  created() {
    this.currentType = this.type;
  },
  computed: {
    collapsedSidebar() {
      return this.$store.getters['UserStore/getCollapsedSidebar'];
    },
    isFormValid(): any {
      const commonConditions =
        this.newProject.periodStartDate && this.newProject.periodEndDate && this.newProject.name;

      if (this.currentType === 'scratch') {
        return commonConditions;
      } else {
        return commonConditions && this.projectToDuplicate.id;
      }
    },
    lanesList(): any {
      return this.$store.getters['RFPStore/getLanes'];
    },
    newProjectModalOptions(): any {
      return this.$store.getters['RFPStore/getNewProjectModalOptions'];
    },
    projectsList(): any {
      return this.$store.getters['RFPStore/getProjectsRFPList'];
    },
    projectToDuplicate(): any {
      return this.$store.getters['RFPStore/getProjectToduplicate'];
    },
    projectToDuplicated(): any {
      return this.$store.getters['RFPStore/getProjectToduplicate'];
    },
  },
  methods: {
    async convertLaneList(laneList) {
      let convertedArray = laneList.map((item) => {
        return {
          loadsFrequency: item.loadsFrequency,
          loadsFrequencyPeriod: item.loadsFrequencyPeriod,
          loadTemplate: {
            stops: item.loadTemplate.stops.map((stop) => {
              return {
                type: stop.type,
                place: `${stop.location.zip}, ${stop.location.city}, ${stop.location.state.isoGoogle}`,
                location: {
                  lat: stop.location.lat,
                  long: stop.location.long,
                  zip: stop.location.zip,
                  city: stop.location.city,
                  state: { id: stop.location.state.id, iso: stop.location.state.isoGoogle }, // Include iso here
                },
                portStop: false,
                airportStop: false,
                observations: '',
                lumperFeePrice: null as null | number,
                lumperFee: false,
              };
            }),
            commodityMaster: {
              weight: item.loadTemplate.commodityMaster.weight,
              commodityName: item.loadTemplate.commodityMaster.commodityName,
              minTemp: null,
              maxTemp: null,
            },
            trailerType: { id: item.loadTemplate.trailerType.id },
          },
        };
      });
      return convertedArray;
    },
    async createProject() {
      if (!this.allowed) return;
      const validation = await (this.$refs.form as any).validate();
      if (validation.valid) {
        this.allowed = false;
        this.sendingAction = true;
        this.loading = true;

        const dtf = new Intl.DateTimeFormat();
        this.newProject.timeZone = dtf.resolvedOptions().timeZone;

        this.newProject.periodStartDate = this.formatDateStringReverse(
          this.newProject.periodStartDate,
        );
        this.newProject.periodEndDate = this.formatDateStringReverse(this.newProject.periodEndDate);

        // IF TYPE IS PROJECT WE NEED TO DUPLICATE CURRENT LANES IF NOT JUST CREATE IN THE NEXT STEP
        if (this.currentType === 'project') {
          this.$store
            .dispatch('RFPStore/cloneProjectWithLanes', {
              ...this.newProject,
              projectId: this.projectToDuplicated.id,
            })
            .then(async (response) => {
              console.log('response: ', response);
              this.$emit('fetchProjects');
              if (response.message === 'async') {
                this.loading = false;
                this.$store.commit('RFPStore/setShowWaitingLanes', true);
                this.toggleOpen(false);
                return;
              }
              BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
            })
            .finally(() => {
              this.allowed = true;
              this.sendingAction = false;
              this.loading = false;
            });
        } else {
          this.$store
            .dispatch('RFPStore/postNewProject', this.newProject)
            .then(async (response) => {
              BaseComponent.methods?.showToastSuccess(this.$t('created-successfully'));
              let projectId = response.id;
              this.$router.push({ name: 'lanesRFPCreation', params: { projectId } });
            })
            .finally(() => {
              this.allowed = true;
              this.sendingAction = false;
            });
        }
      }
    },
    editProject() {
      if (!this.allowed) return;

      this.allowed = false;
      this.sendingAction = true;

      const startDateFormatted = this.formatDateStringReverse(this.newProject.periodStartDate);
      const endDateFormatted = this.formatDateStringReverse(this.newProject.periodEndDate);
      this.newProject.periodStartDate = startDateFormatted;
      this.newProject.periodEndDate = endDateFormatted;

      this.$store
        .dispatch('RFPStore/putProjectRFP', {
          id: this.projectToDuplicate.id,
          project: this.newProject,
        })
        .then(async () => {
          BaseComponent.methods?.showToastSuccess(this.$t('project-edited'));

          this.$store.commit('RFPStore/setNewProjectModalOptions', {
            type: 'scratch',
            opened: false,
            mode: 'creation',
          });

          this.$store.commit('RFPStore/setProjectRFPList', {
            id: this.projectToDuplicate.id,
            data: this.newProject,
          });
          this.$emit('updateProject');
        })
        .catch(async (err) => {
          this.$store.commit('RFPStore/setNewProjectModalOptions', {
            type: 'scratch',
            opened: false,
            mode: 'creation',
          });

          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        })
        .finally(() => {
          this.allowed = true;
          this.sendingAction = false;
        });
    },
    handleUpdateNewProject(updatedNewProject) {
      this.newProject = updatedNewProject;
    },
    async resetForm() {
      const validation = await (this.$refs.form as any);
      validation.resetForm();
    },
    toggleOpen(isOpen: any) {
      this.$store.commit('RFPStore/setProjectToDuplicate', {});
      this.$store.commit('RFPStore/setCurrentEditingLanes', []);
      this.resetForm();
      this.$emit('setOpen', isOpen);
    },
  },
});
