
import { defineComponent } from 'vue';
import { ArrowLeftCircleIcon, ArrowRightCircleIcon, BellIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import { projectStatusBackground } from '@/components/ComponentsTypes';
import IncidenceTooltipContent from '@/components/tooltip/IncidenceTooltipContent.vue';
import UnCompletedLoads from '@/components/tooltip/UnCompletedLoads.vue';
// Types
import { RFPStatus } from '@/modules/rfp/RFPTypes';
// Utils
import { roundToXDecimals } from '@/utils/formatString';

export default defineComponent({
  name: 'ProjectCardStatus',
  components: {
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
    BellIcon,
    IncidenceTooltipContent,
    UnCompletedLoads,
  },
  mixins: [BaseComponent],
  props: {
    projectList: {
      type: Object as any,
    },
  },
  data() {
    return {
      projectStatusBackground,
      fetchDataOnHover: false,
      incompletedList: [
        {
          type: 'cancelled',
          lanesList: [
            {
              id: '#24-004674',
              status: 'Undelivered',
              type: '',
            },
            {
              id: '#24-004676',
              status: 'Unpicked',
              type: 'PICK#1',
            },
          ],
        },
        {
          type: 'rebooks',
          lanesList: [
            {
              id: '#24-004674',
              status: 'Undelivered',
              type: '',
            },
            {
              id: '#24-004676',
              status: 'Unpicked',
              type: 'PICK#1',
            },
          ],
        },
        {
          type: 'incidences',
          lanesList: [
            {
              id: '#24-004674',
              status: 'Undelivered',
              type: '',
            },
            {
              id: '#24-004676',
              status: 'Unpicked',
              type: 'PICK#1',
            },
          ],
        },
      ],
    };
  },
  computed: {
    currentStatus(): any {
      return this.projectList.project?.status;
    },
    isActiveStatus(): any {
      return this.currentStatus === RFPStatus.IN_PROGRESS;
    },
    isDraftStatus(): any {
      return this.currentStatus === RFPStatus.DRAFT;
    },
    isFinishedStatus(): any {
      return (
        this.currentStatus === RFPStatus.FINISHED || this.currentStatus === RFPStatus.DELIVERED
      );
    },
    isFinishing(): any {
      return (
        this.currentStatus === RFPStatus.DELIVERED || this.currentStatus === RFPStatus.FINISHED
      );
    },
    isQuotedStatus(): any {
      return this.currentStatus === RFPStatus.QUOTED;
    },
    isQuotingStatus(): any {
      return (
        this.currentStatus === RFPStatus.QUOTING ||
        // this.currentStatus === RFPStatus.IN_PROGRESS ||
        this.currentStatus === RFPStatus.DELIVERED ||
        this.currentStatus === RFPStatus.FINISHED
      );
    },
    totalCompleted(): any {
      const { targetAmount, completedAmount } = this.projectList.computed?.loads;
      return ((completedAmount / targetAmount) * 100).toFixed(2);
    },
    totalPlanned(): any {
      let totalPlanned = '0';
      const { quotedAmount, totalAmount } = this.projectList.computed?.lanes;

      if (quotedAmount && totalAmount) {
        totalPlanned = ((quotedAmount / totalAmount) * 100).toFixed(2);
      }

      return totalPlanned;
    },
    totalUnassigned(): any {
      let totalUnassigned = '0';
      const { targetAmount, unassignedAmount } = this.projectList.computed?.loads;

      if (targetAmount && unassignedAmount) {
        totalUnassigned = ((unassignedAmount / targetAmount) * 100).toFixed(2);
      }

      return totalUnassigned;
    },
    totalUncompleted(): any {
      let totalUncompleted = '0';
      const { targetAmount, uncompletedAmount } = this.projectList.computed?.loads;

      if (targetAmount && uncompletedAmount) {
        totalUncompleted = ((uncompletedAmount / targetAmount) * 100).toFixed(2);
      }

      return totalUncompleted;
    },
    totalUnplanned(): any {
      let totalUnplanned = '0';

      const { targetAmount, unplannedAmount } = this.projectList.computed?.loads;

      if (targetAmount && unplannedAmount) {
        totalUnplanned = ((unplannedAmount / targetAmount) * 100).toFixed(2);
      }

      return totalUnplanned;
    },
    targetAmount(): any {
      return this.projectList.computed?.loads?.targetAmount ?? '-';
    },
    loadsCompletedAmount(): any {
      return this.projectList.computed?.loads?.completedAmount ?? '-';
    },
    loadsPlannedAmount(): any {
      return this.projectList.computed?.loads?.plannedAmount ?? '-';
    },
    loadsUnplannedAmount(): any {
      return this.projectList.computed?.loads?.unplannedAmount ?? '-';
    },
    loadsUnassignedAmount(): any {
      return this.projectList.computed?.loads?.unassignedAmount ?? '-';
    },
    loadsUncompletedAmount(): any {
      return this.projectList.computed?.loads?.uncompletedAmount ?? '-';
    },
    tendersAmount(): any {
      return this.projectList.computed?.tenders?.totalAmount ?? '-';
    },
    lanesQuotesAmount(): any {
      return this.projectList.computed?.lanes?.quotedAmount ?? '-';
    },
    lanesTotalAmount(): any {
      return this.projectList.computed?.lanes?.totalAmount ?? '-';
    },
  },
  methods: {
    roundToXDecimals,
    handlePopperHover() {
      this.fetchDataOnHover = true;
    },
    handlePopperLeave() {
      this.fetchDataOnHover = false;
    },
    openLaneDetail(item) {
      this.$router.push({
        name: 'RFP-laneService',
        params: { projectId: item.projectId, laneId: item.laneId, id: item.loadId },
      });
    },
  },
});
