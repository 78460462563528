
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'RankingRefusedItemsRight',
  components: {},
  mixins: [BaseComponent],
  props: { quotes: Object },
  data() {
    return {};
  },
  computed: {
    currentLane(): any {
      return this.$store.getters['RFPStore/getCurrentLane'];
    },
    period(): any {
      return this.$store.getters['RFPStore/getCalendarIntervals'].intervals?.length;
    },
  },
  methods: {
    deleteFromRanking(id) {
      this.$store.dispatch('RFPStore/deleteFromRanking', id).then(() => {
        BaseComponent.methods?.showToastSuccess(this.$t('ranking-quote-delete'));
        this.$store.dispatch('RFPStore/getRankingList', {
          id: this.currentLane?.id,
        });
      });
    },
  },
});
