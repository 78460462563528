import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col items-center justify-center plan-gap-sm max-width-carrier-content" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center plan-gap-sm"
}
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = {
  key: 1,
  class: "w-full flex flex-col items-center justify-center plan-gap-sm"
}
const _hoisted_5 = { class: "flex flex-col justify-center items-center plan-gap-sm" }
const _hoisted_6 = {
  key: 0,
  class: "flex justify-center items-center plan-gap-sm"
}
const _hoisted_7 = { class: "font-bold plan-t-h4" }
const _hoisted_8 = {
  key: 1,
  class: "flex justify-center items-center plan-gap-sm"
}
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { class: "w-full flex justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_submit_bid_modal = _resolveComponent("plan-carrier-submit-bid-modal")!
  const _component_plan_formatted_number = _resolveComponent("plan-formatted-number")!
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.userBid === null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('no-bids')), 1),
          _createVNode(_component_plan_carrier_submit_bid_modal)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.userBid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.$t('your-bid')) + ": ", 1),
                    _createVNode(_component_plan_formatted_number, {
                      number: _ctx.userBid.bid,
                      unit: "currency"
                    }, null, 8, ["number"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.loadData.competitiveQuoting)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_plan_icon, {
                    name: "flame",
                    "icon-color": "#151830",
                    "is-svg": ""
                  }),
                  _createElementVNode("p", _hoisted_9, [
                    _createTextVNode(_toDisplayString(_ctx.$t('best-bid')) + ": ", 1),
                    _createVNode(_component_plan_formatted_number, {
                      number: _ctx.loadData.shipment.bestBid,
                      unit: "currency"
                    }, null, 8, ["number"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_plan_carrier_submit_bid_modal, {
              "button-type": "primary-outline",
              update: ""
            })
          ])
        ]))
  ]))
}