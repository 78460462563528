
import { defineComponent } from 'vue';
// Components
import SkeletonTable from '@/components/skeletons/ChatTableSkeleton.vue';
// Icons
import Spin from '@/components/spin/AnimateSpin.vue';
// Utils
import { formatDateStringTodayYesterday } from '@/utils/formatDates';

export default defineComponent({
  name: 'ConversationsView',
  components: {
    SkeletonTable,
    Spin,
  },
  data() {
    return {
      isLoading: false,
      isLoadingTable: false,
      loadingMore: false,
      page: 1,
      previouslyLoaded: false,
      filters: {
        loadId: null,
        pickup: null,
        delivery: null,
        company: null,
        chatUser: null,
      },
    };
  },
  beforeCreate() {
    this.isLoading = !(this.conversations?.length > 0);
    this.$store
      .dispatch('ChatsStore/getConversations', { page: 1, maxResults: this.maxResults && 30 })
      .finally(() => {
        this.isLoading = false;
        this.previouslyLoaded = true;
      });
  },
  computed: {
    formatDateStringTodayYesterday(): any {
      return formatDateStringTodayYesterday;
    },
    maxResults(): any {
      return this.$store.getters['GlobalStore/getGlobalConfig']
        ? parseInt(
            this.$store.getters['GlobalStore/getGlobalConfig'].PAGINATION_DEFAULT_MAX_RESULTS,
          )
        : 30;
    },
    loadId(): any {
      return this.$store.state.LoadsStore.loadId;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    userForChat(): any {
      return this.$store.state.ChatsStore.userForChat;
    },
    conversations() {
      return this.$store.state.ChatsStore.conversations;
    },
    hasMoreItems(): any {
      return this.page * this.maxResults <= this.conversations.length;
    },
  },
  methods: {
    fetchConversations(pagination: boolean) {
      this.loadingMore = pagination;
      pagination && ++this.page;
      this.isLoadingTable = !this.loadingMore;
      this.$store
        .dispatch('ChatsStore/getConversations', {
          page: this.page,
          maxResults: this.maxResults,
          ...this.filters,
        })
        .finally(() => {
          this.isLoadingTable = false;
          this.loadingMore = false;
        });
    },
    getIdentifier(chat) {
      let identifier = '#';
      switch (chat.chatType) {
        case 'DIRECT':
        case 'GENERAL':
          identifier = this.$t('direct');
          break;
        case 'QUOTING':
          identifier = `#${chat.quotingLoadIdentifier}`;
          break;
        case 'LOAD':
          identifier = `#${chat.load.identifier}`;
          break;
        case 'LANES_RFP':
          identifier = chat.lanesRFP.identifier;
          break;
      }
      return identifier;
    },
    initialPickUpStop(load: any): any {
      let initialStop = null;
      load?.stops?.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL') {
          initialStop = stop;
        }
      });
      return initialStop;
    },
    finalDeliveryStop(load: any): any {
      let finalStop = null;
      load?.stops?.forEach((stop: any) => {
        if (stop.type === 'DELIVERY_FINAL') {
          finalStop = stop;
        }
      });
      return finalStop;
    },
    receiver(chat): any {
      return chat?.chatMembers?.find((member) => member.users.id !== this.currentUser?.id);
    },
    toggleChat(conver) {
      switch (conver.chatType) {
        case 'DIRECT':
        case 'GENERAL':
          this.openChat(conver);
          break;
        case 'QUOTING':
        case 'LOAD':
          this.openChatLoad(conver);
          break;
        case 'LANES_RFP':
          this.openChatRFP(conver);
          break;
      }
    },
    openChat(chat) {
      this.$store.commit('LoadsStore/loadId', null);
      if (JSON.stringify(this.userForChat) !== JSON.stringify(chat)) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        setTimeout(() => {
          this.$store.commit('ChatsStore/setUserForChat', chat);
          this.$store.commit('ChatsStore/setChatId', chat.id);
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    openChatLoad(chat) {
      if (chat.load.id !== this.loadId) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        setTimeout(() => {
          this.$store.commit('LoadsStore/loadId', chat.load?.id ?? chat.quotingLoad?.id);
          this.$store.commit('ChatsStore/setChatId', chat.id);
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    async openChatRFP(conver) {
      if (conver.lanesRFP) {
        const user = conver.chatMembers.find((member) => member.users.id !== this.currentUser.id);
        this.$store.commit('LoadsStore/loadId', null);
        this.$store.commit('GlobalStore/setOpenChat', false);
        setTimeout(() => {
          this.$store.commit('ChatsStore/setUserForChat', {
            owner: { id: user.id },
          });
          this.$store.commit('ChatsStore/setLaneForChat', conver.lanesRFP);
          this.$store.commit('ChatsStore/setChatId', conver.id ?? null);
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
  },
});
