import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "plan-rfp-selector-week w-full" }
const _hoisted_2 = { class: "w-full flex justify-around items-center plan-gap-sm" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_button_icon, {
      onOnClick: _ctx.goToThePreviousWeeks,
      disabled: _ctx.selectedWeek === 1,
      type: "transparent-gray-800",
      icon: "chevron-left"
    }, null, 8, ["onOnClick", "disabled"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weeksToShow, (week) => {
        return (_openBlock(), _createElementBlock("div", {
          onClick: ($event: any) => (_ctx.changeWeek(week.number)),
          key: week.number,
          class: _normalizeClass(["week-button", { 'week-active-button': _ctx.selectedWeek === week.number }])
        }, [
          _createElementVNode("span", {
            style: _normalizeStyle(_ctx.getSpanStyle(week.number))
          }, _toDisplayString(_ctx.currentPeriod === 'WEEK' ? _ctx.$t('week') : _ctx.$t('month')) + " " + _toDisplayString(week.number), 5),
          _createElementVNode("span", null, _toDisplayString(_ctx.getFormattedDate(week.start_date)) + " - " + _toDisplayString(_ctx.getFormattedDate(week.end_date)), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createVNode(_component_plan_button_icon, {
      onOnClick: _ctx.goToTheFollowingWeeks,
      disabled: _ctx.disabledNextButton,
      type: "transparent-gray-800",
      icon: "chevron-right"
    }, null, 8, ["onOnClick", "disabled"])
  ]))
}