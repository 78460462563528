<template>
  <div class="relative rounded-md shadow-sm">
    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
      <span class="text-gray-500 sm:text-sm">{{ symbol }}</span>
    </div>
    <input
      ref="inputRef"
      class="block w-full rounded-md border border-gray-300 px-2 py-3 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      :class="[classCustom, backgroundColor, classicInput ? 'text-center' : classicInputLeft ? 'pl-5' : { 'pl-11': hidden }]"
      :disabled="disabled"
      :hidden="hidden"
      :placeholder="seePlaceHolder ? placeholder : ''"
      v-model="formattedValue"
      @change="change"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useCurrencyInput } from 'vue-currency-input';

export default {
  name: 'InputAmericanFormat',
  props: {
    backgroundColor: { type: String, default: 'bg-gray-50' },
    change: Function,
    classCustom: String,
    disabled: Boolean,
    hidden: Boolean,
    modelValue: Number,
    symbol: String,
    classicInput: Boolean,
    classicInputLeft: { type: Boolean, required: false, default: false },
    placeholder: { type: String, required: false, default: '' }
  },
  setup(props) {
    const store = useStore();
    const seePlaceHolder = ref(true)

    let options = {
      currencyDisplay: props.hidden ? 'hidden' : 'narrowSymbol',
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      useGrouping: true,
      accountingSign: false,
    };

    const locale = computed(() => store.getters['CountryStore/getCountryDefaultLanguageLocale']);
    const currency = computed(() => store.getters['CountryStore/getCountryCurrency']);
    options = {
      ...options,
      locale: locale?.value,
      currency: currency?.value,
    };

    function onFocus() {
      seePlaceHolder.value = false;
    }

    function onBlur() {
      if (props.modelValue === null || props.modelValue.length === 0) {
        seePlaceHolder.value = true;
      }
    }

    const { inputRef, formattedValue, setValue, setOptions } = useCurrencyInput(options);

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value);
      },
    );

    watch(
      () => props.currency,
      (currency) => {
        setOptions({ currency });
      },
    );

    return { inputRef, formattedValue, onFocus, onBlur, seePlaceHolder };
  },
};
</script>
