export default {
  getActiveStatus(state: any) {
    return state.activeStatus;
  },
  getCurrency(state: any) {
    return state.globalConfig?.GLOBALCONFIG_DEFAULT_CURRENCY_SYMBOL;
  },
  getUserLanguageModal(state: any) {
    return state.userLanguageModal;
  },
  getEmailConfigModal(state: any) {
    return state.emailConfigModal;
  },
  getGlobalConfig(state: any) {
    return state.globalConfig;
  },
  getIsOpenChat(state: any) {
    return state.isOpenChat;
  },
  getPaginationMaxResults(state: any) {
    return state.globalConfig?.PAGINATION_DEFAULT_MAX_RESULTS;
  },
  getProjectStatus(state: any) {
    return state.globalConfig?.ProjectStatus;
  },
  getSection(state: any) {
    return state.section;
  },
  getSectionFromPlanification(state: any) {
    return state.sectionFromPlanification;
  },
};
