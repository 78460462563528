import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex h-full w-full top-0 left-0 bottom-0 right-0 z-50 bg-zinc-800 bg-opacity-40 items-center justify-center inset-0 fixed",
  style: {"z-index":"99999"}
}
const _hoisted_2 = { class: "relative sm:w-2/3 lg:w-2/4 xl:w-1/3 h-fit px-8 pt-8 pb-6 bg-white rounded shadow flex-col justify-start items-center gap-10 inline-flex my-0 mx-auto" }
const _hoisted_3 = { class: "self-stretch h-28 flex-col justify-start items-start gap-6 flex" }
const _hoisted_4 = { class: "self-stretch h-9 flex-col justify-start items-center gap-6 flex" }
const _hoisted_5 = { class: "text-center text-black text-lg font-semibold font-['Roboto']" }
const _hoisted_6 = { class: "self-stretch h-12 flex-col justify-start items-center gap-2.5 flex" }
const _hoisted_7 = { class: "self-stretch text-center text-black text-base font-normal font-['Roboto'] leading-normal" }
const _hoisted_8 = { class: "self-stretch justify-center items-start gap-6 inline-flex" }
const _hoisted_9 = { class: "grow shrink basis-0 h-12 px-6 py-4 bg-slate-700 rounded-md justify-center items-center gap-2.5 flex" }
const _hoisted_10 = { class: "text-center text-white text-base font-normal font-['Roboto']" }
const _hoisted_11 = { class: "grow shrink basis-0 h-12 px-6 py-4 bg-slate-700 rounded-md justify-center items-center gap-2.5 flex" }
const _hoisted_12 = { class: "flex text-center text-white text-base font-normal font-['Roboto']" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spin = _resolveComponent("Spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString('√ ' + _ctx.$t('quotation-finished')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('end-quotation-rfp')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          class: "grow shrink basis-0 h-12 bg-white justify-start items-start gap-4 flex cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickClose && _ctx.onClickClose(...args)))
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('rfp-dont-sent')), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["grow shrink basis-0 h-12 bg-white justify-start items-start gap-4 flex cursor-pointer", { 'pointer-events-none': _ctx.loading, 'cursor-pointer': !_ctx.loading }]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickSend && _ctx.onClickSend(...args)))
        }, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_Spin, { key: 0 }))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('send')), 1)
            ])
          ])
        ], 2)
      ])
    ])
  ]))
}