
import { computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue';
import BaseComponent from '@/base/BaseComponent';
// Resources
import i18n from '@/resources/locales';

// Components
import SubscriptionComponent from '@/modules/payments/SubscriptionComponent.vue';
import PaymentsComponent from '@/modules/payments/PaymentsComponent.vue';

// Types
import { paymentOptions } from '@/components/ComponentsTypes';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    PaymentsComponent,
    SubscriptionComponent,
  },
  props: {},
  mixins: [BaseComponent],

  setup(props) {
    /** Global */
    const $t = i18n.global.t;
    const store = useStore();
    const typeSelected = ref('subscription');

    /** onMounted */
    onMounted(() => {
      store.dispatch('PaymentStore/paymentInfo');
    });

    /** Methods **/
    const changeMenuSelected = async (type) => {
      typeSelected.value = type;
    };

    /** Computed */

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const accessPlanText = computed(() => {
      const plan = currentUser.value.company?.accessPlanType;

      switch (plan) {
        case 'TRIAL':
          return $t('free-trial-progress');
        case 'BASIC':
          return $t('basic-payment');
        case 'BASIC_PLUS':
          return $t('basic_plus');
        case 'PREMIUM':
          return $t('Premium');
        case 'SUBSCRIPTION_TERMINATED':
          return $t('subscription-ended');
        case 'SUBSCRIPTION_NOT_PAID':
          return $t('subscription-not-paid');

        default:
          return $t('...');
      }
    });

    const paymentInfo = computed(() => {
      return store.getters['PaymentStore/getPaymentInfo'];
    });

    return {
      accessPlanText,
      changeMenuSelected,
      currentUser,
      paymentOptions,
      paymentInfo,
      typeSelected,
    };
  },
});
