
import { defineComponent, ref, computed, watch, onMounted, nextTick } from 'vue';
import moment from 'moment';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useStore } from 'vuex';
import i18n from '@/resources/locales';

export default defineComponent({
  name: 'PlanCalendar',
  components: { PlanButton, PlanButtonIcon },
  emits: ['changeDate'],
  props: {
    allowFormatDate: { type: Boolean, required: false, default: false },
    id: { type: String, required: true, default: '' },
    locale: { type: String, required: false, default: 'es' },
    max: { type: String, required: false },
    min: { type: String, required: false },
    range: { type: Boolean, required: false, default: false },
    startWithMonday: { type: Boolean, required: false, default: false },
    full: { type: Boolean, required: false, default: false },
    events: { type: Object, required: false },
  },
  setup(props, { emit }) {
    const store = useStore();
    moment.updateLocale(props.locale, { week: { dow: i18n.global.locale.value === 'es' ? 1 : 0 } });

    const elementsToShow = ref('days');
    let minDate = ref(props.min ? moment(props.min, 'DD-MM-YYYY').startOf('day') : null);
    const maxDate = ref(props.max ? moment(props.max, 'DD-MM-YYYY').endOf('day') : null);

    const selectedDate = ref(moment().locale(props.locale));
    const selectedRange = ref<{ start: moment.Moment | null; end: moment.Moment | null }>({
      start: null,
      end: null,
    });

    if (store.getters['LoadsStore/getAppliedFilters'][props.id]) {
      const dates =
        store.getters['LoadsStore/getAppliedFilters'][props.id].loadFilterParam.split('#');
      if (dates.length === 2) {
        selectedRange.value = {
          start: moment(dates[0]).locale(props.locale),
          end: moment(dates[1]).locale(props.locale),
        };

        emit('changeDate', {
          start: selectedRange.value?.start ? selectedRange.value.start.format('DD-MM-YYYY') : null,
          end: selectedRange.value?.end ? selectedRange.value.end.format('DD-MM-YYYY') : null,
        });
      }
    }

    if (minDate.value && selectedDate.value.isBefore(minDate.value)) {
      selectedDate.value = minDate.value.clone();
    }

    if (maxDate.value && selectedDate.value.isAfter(maxDate.value)) {
      selectedDate.value = maxDate.value.clone();
    }

    const calendar = computed(() => {
      const firstDayOfMonth = selectedDate.value.clone().startOf('month').startOf('week');
      const lastDayOfMonth = selectedDate.value.clone().endOf('month').endOf('week');
      const calendar: Array<any> = [];
      let currentDay = firstDayOfMonth.clone();

      while (currentDay.isBefore(lastDayOfMonth)) {
        const week: Array<any> = [];
        for (let i = 0; i < 7; i++) {
          const isDisabled =
            (minDate.value && currentDay.isBefore(minDate.value)) ||
            (maxDate.value && currentDay.isAfter(maxDate.value));
          week.push({
            date: currentDay.clone(),
            formattedDate: currentDay.format('DD'),
            isDisabled: isDisabled,
          });
          currentDay.add(1, 'day');
        }
        calendar.push(week);
      }

      return calendar;
    });

    const currentDay = computed(() => {
      const formattedMonth = selectedDate.value.format('DD');
      return formattedMonth;
    });

    const currentMonth = computed(() => {
      const formattedMonth = selectedDate.value.format('MMMM');
      return formattedMonth;
    });

    const currentYear = computed(() => {
      const formattedMonth = selectedDate.value.format('YYYY');
      return formattedMonth;
    });

    const daysOfWeek = computed(() => {
      const weekdaysMin = moment.localeData(i18n.global.locale.value).weekdaysMin();

      if (store.getters['UserStore/getRegion'] === 'EUROPE' && weekdaysMin.length > 0) {
        return weekdaysMin.slice(1).concat(weekdaysMin[0]);
      }
      return weekdaysMin;
    });

    const monthsOfYear = computed(() => {
      const allMonths = moment.localeData(props.locale).months() as string[];
      const monthsInRows: string[][] = [];
      const monthsPerRow = 3;

      for (let i = 0; i < allMonths.length; i += monthsPerRow) {
        const row = allMonths.slice(i, i + monthsPerRow);
        monthsInRows.push(row);
      }

      return monthsInRows;
    });

    const titleToShow = computed(() => {
      if (elementsToShow.value === 'days') {
        return currentMonth.value.toUpperCase() + ' ' + currentYear.value;
      } else if (elementsToShow.value === 'months') {
        return currentYear.value;
      } else {
        const currentYear = selectedDate.value.year();
        const startYear = currentYear - 12;
        const endYear = currentYear + 12;

        return `${startYear} / ${endYear}`;
      }
    });

    const yearsRange = computed(() => {
      const currentYear = selectedDate.value.year();
      const startYear = currentYear - 12;
      const endYear = currentYear + 12;
      const range: Array<any> = [];
      for (let year = startYear; year <= endYear; year++) {
        range.push(year);
      }
      return range;
    });

    const changeView = (view) => {
      elementsToShow.value = view;
    };

    const hasEvents = (dayToCompare: string) => {
      if (props.events !== undefined) {
        return Object.keys(props.events).includes(dayToCompare);
      } else {
        return false;
      }
    };

    const onClickPrev = () => {
      if (elementsToShow.value === 'days') {
        selectedDate.value = selectedDate.value.clone().subtract(1, 'months');
      } else if (elementsToShow.value === 'months') {
        selectedDate.value = selectedDate.value.clone().subtract(1, 'years');
      } else {
        selectedDate.value = selectedDate.value.clone().subtract(12, 'years');
      }
    };

    const onClickNext = () => {
      if (elementsToShow.value === 'days') {
        selectedDate.value = selectedDate.value.clone().add(1, 'month');
      } else if (elementsToShow.value === 'months') {
        selectedDate.value = selectedDate.value.clone().add(1, 'year');
      } else {
        selectedDate.value = selectedDate.value.clone().add(12, 'year');
      }
    };

    const selectDate = (day) => {
      if (props.range) {
        if (!selectedRange.value.start) {
          selectedRange.value.start = day.date.clone();
        } else if (!selectedRange.value.end) {
          selectedRange.value.end = day.date.clone();

          if (selectedRange.value.start.isAfter(selectedRange.value.end)) {
            const temp = selectedRange.value.start;
            selectedRange.value.start = selectedRange.value.end;
            selectedRange.value.end = temp;
          }

          emit('changeDate', {
            start: selectedRange.value?.start
              ? selectedRange.value.start.format('DD-MM-YYYY')
              : null,
            end: selectedRange.value?.end ? selectedRange.value.end.format('DD-MM-YYYY') : null,
          });
        } else {
          selectedRange.value = { start: null, end: null };
          selectedRange.value.start = day.date.clone();
        }
      } else {
        let dateFormat = 'DD-MMM-YYYY';

        // if (props.allowFormatDate) {
        //   dateFormat = i18n.global.locale.value === 'es' ? 'DD-MM-YYYY' : 'MM-DD-YYYY';
        // }

        emit('changeDate', day.date.format('DD-MM-YYYY'));
        selectedDate.value = day.date;
      }
    };

    const selectMonth = (month) => {
      selectedDate.value = selectedDate.value.clone().month(month);
      elementsToShow.value = 'days';
    };

    const selectYear = (year) => {
      selectedDate.value = selectedDate.value.clone().year(year);
      elementsToShow.value = 'months';
    };

    watch(
      () => props.min,
      (date) => {
        minDate.value = moment(props.min, 'DD-MM-YYYY').startOf('day');
      },
      {
        deep: true,
        immediate: true,
      },
    );

    watch(
      () => props.locale,
      (newLocale) => {
        selectedDate.value.locale(newLocale);
      },
    );

    return {
      calendar,
      currentDay,
      currentMonth,
      currentYear,
      elementsToShow,
      selectedDate,
      selectedRange,
      daysOfWeek,
      monthsOfYear,
      titleToShow,
      yearsRange,
      changeView,
      hasEvents,
      selectDate,
      selectMonth,
      selectYear,
      onClickPrev,
      onClickNext,
    };
  },
});
