
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import { useStore } from 'vuex';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import { useI18n } from 'vue-i18n';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
import { debounce } from 'lodash';

export default defineComponent({
  components: { PlanHeaderSearch, PlanButton, PlanLoading, PlanDefaultTable, PlanDefaultModal },
  emit: ['close', 'getLocation'],
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      query: '',
      loadingWarehouses: false,
      page: 1,
      showAddWarehouse: false,
      totalWarehouses: -1,
      warehouses: [],
    });

    const changeQuery = (newQuery) => {
      data.query = newQuery;
      data.page = 1;
      debouncedWarehouses();
    };

    const close = () => {
      emit('close');
    };

    const getLocation = (warehouse: any) => {
      emit('getLocation', warehouse);
    };

    const getWarehouses = () => {
      data.loadingWarehouses = true;
      store
        .dispatch('LoadsStore/getWarehousesList', {
          page: data.page,
          multisearch: data.query,
          sortBy: '',
          maxResults: 10,
        })
        .then((response) => {
          if (data.page === 1) {
            data.warehouses = response.values;
          } else {
            data.warehouses = data.warehouses.concat(response.values);
          }
          data.totalWarehouses = response.total;
          data.loadingWarehouses = false;
        });
    };

    const headers = computed(() => {
      return [
        { label: translate.t('warehouse'), value: 'companyWarehouse' },
        { label: translate.t('address'), value: 'address' },
        { label: translate.t('city'), value: 'zip' },
        { label: translate.t('country'), value: 'country' },
        { label: '', value: 'actions' },
      ];
    });

    const loadMoreWarehouses = () => {
      data.page++;
      getWarehouses();
    };

    const openAddFacility = () => {
      emit('openAddFacility');
    };

    getWarehouses();

    const debouncedWarehouses = debounce(getWarehouses, 500);

    return {
      ...toRefs(data),
      changeQuery,
      close,
      getLocation,
      headers,
      loadMoreWarehouses,
      openAddFacility,
    };
  },
});
