import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex justify-start items-center" }
const _hoisted_2 = { class: "text-right plan-t-prim-100 plan-t-xs" }
const _hoisted_3 = { class: "flex flex-wrap plan-gap-sm" }
const _hoisted_4 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = {
  key: 0,
  class: "flex justify-start items-center",
  style: {"min-width":"100px"}
}
const _hoisted_7 = {
  key: 1,
  class: "plan-t-green-500"
}
const _hoisted_8 = {
  key: 2,
  class: "plan-t-red-500"
}
const _hoisted_9 = {
  key: 3,
  class: "plan-t-prim-100"
}
const _hoisted_10 = { class: "text-right plan-t-prim-100 plan-t-xs" }
const _hoisted_11 = { class: "flex flex-wrap plan-gap-sm" }
const _hoisted_12 = { class: "relative" }
const _hoisted_13 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_14 = {
  key: 0,
  class: "plan-t-prim-100"
}
const _hoisted_15 = {
  key: 1,
  class: "flex justify-start items-center plan-gap-sm",
  style: {"min-width":"100px"}
}
const _hoisted_16 = {
  key: 3,
  class: "plan-t-green-500"
}
const _hoisted_17 = {
  key: 4,
  class: "plan-t-red-500"
}
const _hoisted_18 = {
  key: 5,
  class: "plan-t-prim-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_thumbnail = _resolveComponent("plan-thumbnail")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false)),
          title: _ctx.$t('incidence-text'),
          size: "sm-auto"
        }, {
          content: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.description), 1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.dateFormatted), 1),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mediaObjects, (mediaObject) => {
                return (_openBlock(), _createBlock(_component_plan_thumbnail, {
                  key: mediaObject.id,
                  id: mediaObject.id,
                  "entity-class": mediaObject.entityClass
                }, null, 8, ["id", "entity-class"]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.showAdditionalFeeModal)
      ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
          key: 1,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAdditionalFeeModal = false)),
          title: _ctx.$t('additional-fee-reported'),
          size: "sm-auto"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.formatPrice(_ctx.additionFeeQuantity)), 1),
              (_ctx.isAdditionalFee)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_ctx.additionFeeStatus === 'STATUS_APPROVED' || _ctx.additionFeeStatus === 'STATUS_DENIED')
                      ? (_openBlock(), _createBlock(_component_plan_icon, {
                          key: 0,
                          name: _ctx.additionFeeStatus === 'STATUS_APPROVED' ? 'check':'close',
                          "icon-color": _ctx.additionFeeStatus === 'STATUS_APPROVED' ? '#00DB50':'#EF4444',
                          "is-svg": ""
                        }, null, 8, ["name", "icon-color"]))
                      : _createCommentVNode("", true),
                    (_ctx.additionFeeStatus === 'STATUS_APPROVED')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('accepted')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.additionFeeStatus === 'STATUS_DENIED')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('rejected')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.additionFeeStatus === 'STATUS_PENDING')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('pending')), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.description), 1),
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.dateFormatted), 1),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mediaObjects, (mediaObject) => {
                return (_openBlock(), _createBlock(_component_plan_thumbnail, {
                  key: mediaObject.id,
                  id: mediaObject.id,
                  "entity-class": mediaObject.entityClass
                }, null, 8, ["id", "entity-class"]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_12, [
      (!_ctx.isNotification)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: {"height":"11px","width":"11px","margin-top":"-4px","margin-left":"-30px"},
            class: _normalizeClass(["plan-b-prim-500 rounded-full absolute", {
         'plan-b-amber-500': _ctx.isAdditionalFee,
         'plan-b-red-500': _ctx.isIncident,
       }])
          }, null, 2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["flex justify-start plan-gap-xs", _ctx.actualWidth <= 640 ? 'flex-col items-start':'items-center'])
    }, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", {
          class: _normalizeClass(["rounded-full flex justify-center items-center", {'plan-b-red-500': _ctx.isIncident, 'plan-b-amber-500': _ctx.isAdditionalFee }]),
          style: {"min-width":"18px","min-height":"18px"}
        }, [
          (_ctx.isIncident)
            ? (_openBlock(), _createBlock(_component_plan_icon, {
                key: 0,
                name: "simple-alert",
                "icon-color": "white",
                size: "12",
                "is-svg": ""
              }))
            : _createCommentVNode("", true),
          (_ctx.isAdditionalFee)
            ? (_openBlock(), _createBlock(_component_plan_icon, {
                key: 1,
                name: "simple-dollar",
                "icon-color": "white",
                size: "14",
                "is-svg": ""
              }))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.isIncident)
          ? (_openBlock(), _createBlock(_component_plan_text_button, {
              key: 0,
              onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModal = true)),
              label: _ctx.$t('incidence-notified'),
              type: "red"
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (_ctx.isAdditionalFee)
          ? (_openBlock(), _createBlock(_component_plan_text_button, {
              key: 1,
              onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showAdditionalFeeModal = true)),
              label: _ctx.$t('additional-fee-reported'),
              type: "amber"
            }, null, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["flex justify-start plan-gap-sm", _ctx.actualWidth <= 640 ? 'flex-col items-start':'items-center'])
      }, [
        (_ctx.dateFormatted.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('on-date')) + " " + _toDisplayString(_ctx.dateFormatted), 1))
          : _createCommentVNode("", true),
        (_ctx.isAdditionalFee)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              (_ctx.additionFeeStatus === 'STATUS_PENDING')
                ? (_openBlock(), _createBlock(_component_plan_icon, {
                    key: 0,
                    name: "clock",
                    "icon-color": "#8A8B97",
                    size: "15",
                    "is-svg": ""
                  }))
                : _createCommentVNode("", true),
              (_ctx.additionFeeStatus === 'STATUS_APPROVED')
                ? (_openBlock(), _createBlock(_component_plan_icon, {
                    key: 1,
                    name: "check",
                    "icon-color": "#00DB50",
                    size: "15",
                    "is-svg": ""
                  }))
                : _createCommentVNode("", true),
              (_ctx.additionFeeStatus === 'STATUS_DENIED')
                ? (_openBlock(), _createBlock(_component_plan_icon, {
                    key: 2,
                    name: "close",
                    "icon-color": "#EF4444",
                    size: "15",
                    "is-svg": ""
                  }))
                : _createCommentVNode("", true),
              (_ctx.additionFeeStatus === 'STATUS_APPROVED')
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t('accepted')), 1))
                : _createCommentVNode("", true),
              (_ctx.additionFeeStatus === 'STATUS_DENIED')
                ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('rejected')), 1))
                : _createCommentVNode("", true),
              (_ctx.additionFeeStatus === 'STATUS_PENDING')
                ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t('pending')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.formatPrice(_ctx.additionFeeQuantity)), 1)
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ]))
}