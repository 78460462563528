import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-bg rounded-lg w-full max-w-md md:h-auto mx-auto my-auto md:mt-10 overflow-hidden" }
const _hoisted_2 = { class: "flex justify-end bg-corp py-2" }
const _hoisted_3 = { class: "menu-top flex justify-endright-0" }
const _hoisted_4 = { class: "justify-center" }
const _hoisted_5 = { class: "flex flex-row justify-center p-5 text-center mt-5" }
const _hoisted_6 = { class: "mb-0 text-xl md:text-2xl font-bold" }
const _hoisted_7 = { class: "text-center mb-6" }
const _hoisted_8 = { class: "flex flex-row justify-center" }
const _hoisted_9 = { class: "text-sm" }
const _hoisted_10 = { class: "relative w-2/3 my-2 justify-center mx-auto rounded-md shadow-sm" }
const _hoisted_11 = { class: "flex flex-row justify-center py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!
  const _component_InputAmericanFormat = _resolveComponent("InputAmericanFormat")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", {
    style: {"z-index":"180"},
    class: _normalizeClass(["fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-75 transition-opacity", _ctx.origin === 'detail' ? '' : ''])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("nav", _hoisted_3, [
          _createElementVNode("button", {
            class: "float-right flex items-start",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal', null)))
          }, [
            _createVNode(_component_XCircleIcon, { class: "w-8 h-8 text-white" })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.text), 1)
        ]),
        _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t('message-assign-load-to', { name: _ctx.carrier?.name, surname: _ctx.carrier?.surname })), 1),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Form, {
            ref: "form",
            onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeModal', _ctx.price)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('indicates-the-price-performance-service')), 1),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_Field, {
                  name: "Price",
                  rules: "required|min_value:1|max_value:2147483647",
                  modelValue: _ctx.price,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.price) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InputAmericanFormat, {
                      modelValue: _ctx.price,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.price) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createVNode(_component_ErrorMessage, {
                name: "Price",
                class: "text-rose-500"
              })
            ]),
            _: 1
          }, 512)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('closeModal', _ctx.price))),
            class: "text-white bg-green-500 hover:opacity-70 focus:ring-3 focus:outline-none focus:ring-green-300 rounded-md text-sm inline-flex items-center px-5 py-2.5 text-center mr-3"
          }, _toDisplayString(_ctx.$t('send')), 1)
        ])
      ])
    ])
  ], 2))
}