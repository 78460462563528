import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelRadio as _vModelRadio, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, vShow as _vShow, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "add-carrier-content-modal" }
const _hoisted_2 = { class: "sticky-top bg-white h-fi justify-end flex flex-row" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "sticky-top bg-white h-fit" }
const _hoisted_5 = { class: "md:text-3xl font-bold" }
const _hoisted_6 = { class: "py-2" }
const _hoisted_7 = { class: "flex-1 overflow-y-scroll overflow-x-hidden" }
const _hoisted_8 = { class: "text-xl md:text-2xl my-5 font-bold" }
const _hoisted_9 = { class: "pb-4 ml-1 flex" }
const _hoisted_10 = { class: "inline-flex items-center ml-4" }
const _hoisted_11 = { class: "ml-2" }
const _hoisted_12 = { class: "inline-flex items-center ml-4" }
const _hoisted_13 = { class: "ml-2" }
const _hoisted_14 = { class: "flex flex-wrap -mx-3 mb-6" }
const _hoisted_15 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-0" }
const _hoisted_16 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "name"
}
const _hoisted_17 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-0" }
const _hoisted_18 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "last-name"
}
const _hoisted_19 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-0" }
const _hoisted_20 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "company"
}
const _hoisted_21 = { class: "w-full md:w-6/12 px-3 mb-6 md:mb-0" }
const _hoisted_22 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "email"
}
const _hoisted_23 = { class: "w-full md:w-6/12 px-3 mb-6 md:mb-0" }
const _hoisted_24 = {
  key: 0,
  class: "w-full md:w-1/2 px-3 mb-6 md:mb-3"
}
const _hoisted_25 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "dot-number"
}
const _hoisted_26 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_27 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "mc-number"
}
const _hoisted_28 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_29 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "cif"
}
const _hoisted_30 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_31 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "address"
}
const _hoisted_32 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_33 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "address"
}
const _hoisted_34 = {
  value: "",
  disabled: ""
}
const _hoisted_35 = ["value"]
const _hoisted_36 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_37 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "address"
}
const _hoisted_38 = {
  value: "",
  disabled: ""
}
const _hoisted_39 = ["value"]
const _hoisted_40 = { class: "mb-3 pb-6 border-b" }
const _hoisted_41 = { class: "text-xl my-5 font-bold" }
const _hoisted_42 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_43 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-first-name"
}
const _hoisted_44 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_45 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-last-name"
}
const _hoisted_46 = { class: "w-full md:w-1/2 px-3 mb-6 md:mb-3" }
const _hoisted_47 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "grid-last-name"
}
const _hoisted_48 = { class: "w-8 md:justify-center" }
const _hoisted_49 = ["onClick"]
const _hoisted_50 = { key: 0 }
const _hoisted_51 = { class: "text-xl my-5 font-bold" }
const _hoisted_52 = { class: "w-full md:w-1/2 pr-3 mb-6 md:mb-3" }
const _hoisted_53 = {
  value: "",
  disabled: ""
}
const _hoisted_54 = ["value"]
const _hoisted_55 = { class: "w-full md:w-5/12 px-3 mb-6 md:mb-3" }
const _hoisted_56 = ["onClick"]
const _hoisted_57 = { class: "text-xl my-5 font-bold" }
const _hoisted_58 = {
  key: 1,
  class: "flex flex-wrap mb-6"
}
const _hoisted_59 = { class: "w-full text-lg my-4" }
const _hoisted_60 = { class: "w-full md:w-1/4 px-3 mb-6 md:mb-3" }
const _hoisted_61 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "cargoInsuranceNumber"
}
const _hoisted_62 = {
  key: 0,
  class: "text-rose-500"
}
const _hoisted_63 = { class: "w-full md:w-1/4 px-3 mb-6 md:mb-3" }
const _hoisted_64 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "cargoInsuranceLimit"
}
const _hoisted_65 = { class: "mt-1" }
const _hoisted_66 = {
  key: 0,
  class: "text-rose-500"
}
const _hoisted_67 = { class: "w-full md:w-1/4 px-3 mb-6 md:mb-3" }
const _hoisted_68 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "cargoInsuranceDate"
}
const _hoisted_69 = {
  key: 0,
  class: "text-rose-500"
}
const _hoisted_70 = { class: "w-full md:w-1/4 px-3 mb-6 md:mb-3" }
const _hoisted_71 = { class: "flex flex-wrap mb-6" }
const _hoisted_72 = { class: "w-full text-lg my-4" }
const _hoisted_73 = { class: "w-full md:w-1/4 px-3 mb-6 md:mb-3" }
const _hoisted_74 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "liabilityInsuranceNumber"
}
const _hoisted_75 = {
  key: 0,
  class: "text-rose-500"
}
const _hoisted_76 = { class: "w-full md:w-1/4 px-3 mb-6 md:mb-3" }
const _hoisted_77 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "liabilityInsuranceLimit"
}
const _hoisted_78 = { class: "mt-1" }
const _hoisted_79 = {
  key: 0,
  class: "text-rose-500"
}
const _hoisted_80 = { class: "w-full md:w-1/4 px-3 mb-6 md:mb-3" }
const _hoisted_81 = {
  class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  for: "liabilityInsuranceDate"
}
const _hoisted_82 = {
  key: 0,
  class: "text-rose-500"
}
const _hoisted_83 = { class: "w-full md:w-1/4 px-3 mb-6 md:mb-3" }
const _hoisted_84 = {
  key: 0,
  class: "px-3 text-rose-500"
}
const _hoisted_85 = { class: "flex flex-wrap" }
const _hoisted_86 = { class: "flex flex-wrap mb-6 md:w-1/2" }
const _hoisted_87 = { class: "w-full text-lg" }
const _hoisted_88 = { class: "w-full px-3 mb-6 md:mb-3" }
const _hoisted_89 = { class: "flex flex-wrap mb-6 md:w-1/2" }
const _hoisted_90 = { class: "w-full text-lg" }
const _hoisted_91 = { class: "w-full px-3 mb-6 md:mb-3" }
const _hoisted_92 = { class: "text-xl md:text-2xl my-5 font-bold" }
const _hoisted_93 = {
  key: 2,
  class: "mb-3 pb-6 border-b px-3"
}
const _hoisted_94 = { class: "flex items-center" }
const _hoisted_95 = {
  for: "usaDomestic",
  class: "ml-2 text-sm font-medium text-gray-900"
}
const _hoisted_96 = { class: "flex items-center" }
const _hoisted_97 = {
  for: "mexDomestic",
  class: "ml-2 text-sm font-medium text-gray-900"
}
const _hoisted_98 = { class: "flex items-center" }
const _hoisted_99 = {
  for: "transferLoad",
  class: "ml-2 text-sm font-medium text-gray-900"
}
const _hoisted_100 = { class: "text-xl my-5 font-bold" }
const _hoisted_101 = { class: "w-full md:w-1/3 px-3 mb-6 md:mb-3" }
const _hoisted_102 = { class: "relative flex" }
const _hoisted_103 = { class: "w-full md:w-1/3 px-3 mb-6 md:mb-3" }
const _hoisted_104 = { class: "relative flex" }
const _hoisted_105 = { class: "w-full md:w-1/6 px-3 mb-6 md:mb-3" }
const _hoisted_106 = ["onClick"]
const _hoisted_107 = { key: 3 }
const _hoisted_108 = { class: "text-xl my-5 font-bold" }
const _hoisted_109 = { class: "flex flex-row" }
const _hoisted_110 = { class: "flex flex-col w-1/2 px-3 mb-6" }
const _hoisted_111 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_112 = {
  for: "alcoholic-beverages",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_113 = { class: "flex items-center" }
const _hoisted_114 = {
  for: "hazardous-materials",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_115 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_116 = {
  for: "tir",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_117 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_118 = {
  for: "TWIC-card",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_119 = {
  key: 3,
  class: "flex items-center"
}
const _hoisted_120 = {
  for: "TSA-card",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_121 = { class: "flex items-center" }
const _hoisted_122 = ["checked"]
const _hoisted_123 = {
  for: "tir",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_124 = { class: "flex items-center" }
const _hoisted_125 = ["checked"]
const _hoisted_126 = {
  for: "hasOversizeLoadPermit",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_127 = { class: "flex items-center" }
const _hoisted_128 = ["checked"]
const _hoisted_129 = {
  for: "tir",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_130 = { class: "flex flex-col w-1/2 px-3 mb-6" }
const _hoisted_131 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_132 = {
  for: "overweight",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_133 = { class: "flex items-center" }
const _hoisted_134 = {
  for: "drivers-team",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_135 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_136 = {
  for: "bond-permit",
  class: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
}
const _hoisted_137 = { class: "modal-footer flex flex-shrink-0 flex-wrap items-center justify-end pt-4 border-t border-gray-200 rounded-b-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_phone_number_selector = _resolveComponent("phone-number-selector")!
  const _component_plan_new_contact_tags = _resolveComponent("plan-new-contact-tags")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_InputAmericanFormat = _resolveComponent("InputAmericanFormat")!
  const _component_DatePickerComponent = _resolveComponent("DatePickerComponent")!
  const _component_PlanCountrySelector = _resolveComponent("PlanCountrySelector")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!
  const _component_google_autocomplete = _resolveComponent("google-autocomplete")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["add-carrier-content", { 'add-carrier-content-collapsed': _ctx.collapsedSidebar }]),
    style: {"z-index":"99999"},
    id: "modal"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
          class: "text-gray-500 hover:text-gray-800"
        }, [
          _createElementVNode("img", {
            alt: "Close modal",
            class: "w-8 h-8 text-corp",
            src: _ctx.getAsset.icXCircleOutline
          }, null, 8, _hoisted_3)
        ])
      ]),
      _createElementVNode("header", _hoisted_4, [
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('new-contact')), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('add-carrier-info')), 1)
      ]),
      _createElementVNode("main", _hoisted_7, [
        _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('user-data')), 1),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "form-radio",
              name: "type",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedOption) = $event)),
              value: "BROKER",
              checked: ""
            }, null, 512), [
              [_vModelRadio, _ctx.selectedOption]
            ]),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('broker')), 1)
          ]),
          _createElementVNode("label", _hoisted_12, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              class: "form-radio",
              name: "type",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedOption) = $event)),
              value: "CARRIER"
            }, null, 512), [
              [_vModelRadio, _ctx.selectedOption]
            ]),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('carrier')), 1)
          ])
        ]),
        _createVNode(_component_Form, {
          ref: "form",
          class: "w-full"
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("label", _hoisted_16, _toDisplayString(`${_ctx.$t('firstname')} *`), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                  type: "text",
                  name: "name",
                  id: "name",
                  modelValue: _ctx.name,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.name) = $event)),
                  rules: "required|alpha_spaces|max:256"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "name",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("label", _hoisted_18, _toDisplayString(`${_ctx.$t('lastname')}*`), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                  id: "last-name",
                  type: "text",
                  modelValue: _ctx.lastname,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.lastname) = $event)),
                  name: "lastname",
                  rules: "required|max:256"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "lastname",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("label", _hoisted_20, _toDisplayString(`${_ctx.$t('company')}*`), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                  id: "company",
                  type: "text",
                  modelValue: _ctx.company,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.company) = $event)),
                  name: "company",
                  rules: "required|max:256"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "company",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("label", _hoisted_22, _toDisplayString(`${_ctx.$t('email')}*`), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                  id: "email",
                  rules: "email|required",
                  type: "text",
                  modelValue: _ctx.email,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.email) = $event)),
                  name: "email"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "email",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_phone_number_selector, {
                  borderColor: 'border-gray-300',
                  "classes-for-labels": 'block uppercase tracking-wide text-gray-700 text-xs font-bold',
                  textStyles: 'text-gray-700 leading-tight',
                  prefix: _ctx.prefix,
                  number: _ctx.phone,
                  onChangePhone: _ctx.changePhone
                }, null, 8, ["prefix", "number", "onChangePhone"])
              ]),
              (_ctx.selectedOption === 'CARRIER')
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createElementVNode("label", _hoisted_25, _toDisplayString(`${_ctx.$t('dot-number')}*`), 1),
                    _createVNode(_component_Field, {
                      class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                      id: "dot-number",
                      type: "text",
                      modelValue: _ctx.dotNumber,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dotNumber) = $event)),
                      name: "dotNumber",
                      rules: "max:256"
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_ErrorMessage, {
                      name: "dotNumber",
                      class: "text-rose-500"
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('mc-number')), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                  id: "mc-number",
                  type: "text",
                  modelValue: _ctx.mcNumber,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.mcNumber) = $event)),
                  name: "mcNumber",
                  rules: "max:256"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "mcNumber",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('cif-carrier')), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                  id: "cif-carrier",
                  type: "text",
                  modelValue: _ctx.identificationNumber,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.identificationNumber) = $event)),
                  name: "cif-carrier",
                  rules: "max:256"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "cif-carrier",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t('address')), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                  id: "address",
                  type: "text",
                  modelValue: _ctx.address,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.address) = $event)),
                  name: "address",
                  rules: "max:256"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  name: "address",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('timezone')) + " *", 1),
                _createVNode(_component_Field, {
                  as: "select",
                  id: 'timeZone',
                  name: 'timeZone',
                  modelValue: _ctx.timeZone,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.timeZone) = $event)),
                  class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                  onChange: _ctx.timezoneOnChange,
                  rules: "required"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("option", _hoisted_34, _toDisplayString(_ctx.$t('select')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timezonesFormated, (timezone, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: timezone.value,
                        key: index
                      }, _toDisplayString(timezone.label + timezone.value), 9, _hoisted_35))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"]),
                _createVNode(_component_ErrorMessage, {
                  name: "timeZone",
                  class: "text-rose-500"
                })
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t('country')) + " *", 1),
                _createVNode(_component_Field, {
                  as: "select",
                  id: 'country',
                  name: 'country',
                  modelValue: _ctx.country,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.country) = $event)),
                  class: "appearance-none mb-4 block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                  onChange: _ctx.countryOnchange,
                  rules: "required"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("option", _hoisted_38, _toDisplayString(_ctx.$t('select')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryList, (country, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: country.id,
                        key: index
                      }, _toDisplayString(country.name), 9, _hoisted_39))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"]),
                _createVNode(_component_ErrorMessage, {
                  name: "country",
                  class: "text-rose-500"
                })
              ])
            ]),
            _createVNode(_component_plan_new_contact_tags, { onChange: _ctx.changeCarrierTags }, null, 8, ["onChange"]),
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("h3", _hoisted_41, _toDisplayString(_ctx.$t('driver')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drivers, (driver, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "flex flex-wrap items-center mb-6"
                }, [
                  _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.$t('firstname')), 1),
                    _createVNode(_component_Field, {
                      class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white",
                      type: "text",
                      name: 'name' + index,
                      id: "name",
                      modelValue: driver.name,
                      "onUpdate:modelValue": ($event: any) => ((driver.name) = $event),
                      rules: "required|alpha_spaces|max:256"
                    }, null, 8, ["name", "modelValue", "onUpdate:modelValue"]),
                    _createVNode(_component_ErrorMessage, {
                      name: 'name' + index,
                      class: "text-rose-500"
                    }, null, 8, ["name"])
                  ]),
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t('lastname')), 1),
                    _createVNode(_component_Field, {
                      class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                      id: "grid-last-name",
                      type: "text",
                      modelValue: driver.surname,
                      "onUpdate:modelValue": ($event: any) => ((driver.surname) = $event),
                      name: 'lastname' + index,
                      rules: "required|alpha_spaces|max:256"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "name"]),
                    _createVNode(_component_ErrorMessage, {
                      name: 'lastname' + index,
                      class: "text-rose-500"
                    }, null, 8, ["name"])
                  ]),
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("label", _hoisted_47, _toDisplayString(_ctx.$t('driver-license')), 1),
                    _createVNode(_component_Field, {
                      class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                      id: "grid-last-name",
                      type: "text",
                      modelValue: driver.drivingLicense,
                      "onUpdate:modelValue": ($event: any) => ((driver.drivingLicense) = $event),
                      name: 'driverId' + index,
                      rules: "required|max:256"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "name"]),
                    _createVNode(_component_ErrorMessage, {
                      name: 'driverId' + index,
                      class: "text-rose-500"
                    }, null, 8, ["name"])
                  ]),
                  _createElementVNode("div", _hoisted_48, [
                    _withDirectives(_createElementVNode("p", {
                      class: "p-1 h-fit bg-red-500 rounded cursor-pointer mx-auto",
                      onClick: ($event: any) => (_ctx.drivers.splice(index, 1))
                    }, [
                      _createVNode(_component_TrashIcon, { class: "h-6 w-6 text-white" })
                    ], 8, _hoisted_49), [
                      [_vShow, index > 0]
                    ])
                  ])
                ]))
              }), 128)),
              _createElementVNode("p", {
                class: "mx-3 border-b w-max border-corp cursor-pointer",
                onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.drivers.push({ name: null, surname: null, drivingLicense: null })))
              }, _toDisplayString(`+ ${_ctx.$t('add-other')}`), 1)
            ]),
            (_ctx.selectedOption === 'CARRIER')
              ? (_openBlock(), _createElementBlock("main", _hoisted_50, [
                  _createElementVNode("h3", _hoisted_51, _toDisplayString(_ctx.$t('vehicle')), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vehicles, (vehicle, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "flex flex-wrap items-center mb-6"
                    }, [
                      _createElementVNode("div", _hoisted_52, [
                        _withDirectives(_createElementVNode("label", {
                          for: "trailer-type",
                          class: "mb-1 text-bold"
                        }, _toDisplayString(_ctx.$t('vehicle-type')), 513), [
                          [_vShow, index === 0]
                        ]),
                        _createVNode(_component_Field, {
                          as: "select",
                          modelValue: vehicle.trailerType.id,
                          "onUpdate:modelValue": ($event: any) => ((vehicle.trailerType.id) = $event),
                          name: 'vehicle' + index,
                          rules: "required",
                          id: "trailer-type",
                          class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("option", _hoisted_53, _toDisplayString(_ctx.$t('select')), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trailerTypes, (trailer) => {
                              return (_openBlock(), _createElementBlock("option", {
                                value: trailer.id,
                                key: trailer.id
                              }, _toDisplayString(trailer.description), 9, _hoisted_54))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "name"]),
                        _createVNode(_component_ErrorMessage, {
                          name: 'vehicle' + index,
                          class: "text-rose-500"
                        }, null, 8, ["name"])
                      ]),
                      _createElementVNode("div", _hoisted_55, [
                        _withDirectives(_createElementVNode("label", {
                          class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                          for: "license-plate"
                        }, _toDisplayString(_ctx.$t('license-plate')), 513), [
                          [_vShow, index === 0]
                        ]),
                        _createVNode(_component_Field, {
                          class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                          id: "license-plate",
                          type: "text",
                          modelValue: vehicle.licensePlate,
                          "onUpdate:modelValue": ($event: any) => ((vehicle.licensePlate) = $event),
                          name: 'licensePlate' + index,
                          rules: "required|max:256"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "name"]),
                        _createVNode(_component_ErrorMessage, {
                          name: 'licensePlate' + index,
                          class: "text-rose-500"
                        }, null, 8, ["name"])
                      ]),
                      _withDirectives(_createElementVNode("p", {
                        class: "p-1 h-fit bg-red-500 rounded cursor-pointer mx-auto",
                        onClick: ($event: any) => (_ctx.vehicles.splice(index, 1))
                      }, [
                        _createVNode(_component_TrashIcon, { class: "h-6 w-6 text-white" })
                      ], 8, _hoisted_56), [
                        [_vShow, index > 0]
                      ])
                    ]))
                  }), 128)),
                  _createElementVNode("p", {
                    class: "mx-3 border-b w-max border-corp cursor-pointer",
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.vehicles.push({ trailerType: { id: null }, licensePlate: null })))
                  }, _toDisplayString(`+ ${_ctx.$t('add-other')}`), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("h3", _hoisted_57, _toDisplayString(_ctx.selectedOption === 'CARRIER' ? _ctx.$t('insurances') : _ctx.$t('broker-package')), 1),
            (_ctx.selectedOption === 'CARRIER')
              ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                  _createElementVNode("p", _hoisted_59, _toDisplayString(_ctx.$t('cargo-insurance')), 1),
                  _createElementVNode("div", _hoisted_60, [
                    _createElementVNode("label", _hoisted_61, _toDisplayString(_ctx.$t('number')), 1),
                    _createVNode(_component_Field, {
                      class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                      type: "text",
                      name: "cargoInsuranceNumber",
                      id: "cargoInsuranceNumber",
                      modelValue: _ctx.cargoInsurance.number,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.cargoInsurance.number) = $event)),
                      rules: "max:256"
                    }, null, 8, ["modelValue"]),
                    (!_ctx.cargoInsurance.number && _ctx.isInvalidCargo)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_62, _toDisplayString(_ctx.$t('required-field')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_63, [
                    _createElementVNode("label", _hoisted_64, _toDisplayString(_ctx.$t('limit')), 1),
                    _createElementVNode("div", _hoisted_65, [
                      _createVNode(_component_Field, {
                        name: "cargoInsuranceLimit",
                        modelValue: _ctx.cargoInsurance.limit,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.cargoInsurance.limit) = $event)),
                        rules: "max_value:2147483647"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InputAmericanFormat, {
                            class: "mt-2",
                            modelValue: _ctx.cargoInsurance.limit,
                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.cargoInsurance.limit) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    (!_ctx.cargoInsurance.limit && _ctx.isInvalidCargo)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_66, _toDisplayString(_ctx.$t('required-field')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_67, [
                    _createElementVNode("label", _hoisted_68, _toDisplayString(_ctx.$t('expiration')), 1),
                    _createVNode(_component_Field, {
                      name: "cargoInsuranceDate",
                      modelValue: _ctx.cargoInsurance.date,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.cargoInsurance.date) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DatePickerComponent, {
                          modelValue: _ctx.cargoInsurance.date,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.cargoInsurance.date) = $event)),
                          customClass: 'w-full'
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    (!_ctx.cargoInsurance.date && _ctx.isInvalidCargo)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_69, _toDisplayString(_ctx.$t('required-field')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_70, [
                    _createVNode(_component_Field, {
                      name: "cargoInsuranceFile",
                      modelValue: _ctx.cargoInsurance.file,
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.cargoInsurance.file) = $event)),
                      rules: "size:10000"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("input", {
                          ref: "cargoInsuranceFile",
                          type: "file",
                          accept: "application/pdf, application/xpdf, image/jpeg, image/png, image/jpg",
                          class: "block cursor-pointer mt-6 file:cursor-pointer px-3 text-sm text-corp file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-corp file:text-white hover:file:bg-slate-700",
                          onChange: _cache[20] || (_cache[20] = ($event: any) => (_ctx.handleChange('cargoInsuranceFile')))
                        }, null, 544)
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_ErrorMessage, {
                      name: "cargoInsuranceFile",
                      class: "text-rose-500"
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_71, [
              _createElementVNode("p", _hoisted_72, _toDisplayString(_ctx.$t('liability-insurance')), 1),
              _createElementVNode("div", _hoisted_73, [
                _createElementVNode("label", _hoisted_74, _toDisplayString(_ctx.$t('number')), 1),
                _createVNode(_component_Field, {
                  class: "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ocus:border-gray-500",
                  type: "text",
                  name: "liabilityInsuranceNumber",
                  id: "liabilityInsuranceNumber",
                  modelValue: _ctx.liabilityInsurance.number,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.liabilityInsurance.number) = $event)),
                  rules: "max:256"
                }, null, 8, ["modelValue"]),
                (!_ctx.liabilityInsurance.number && _ctx.isInvalidInsurance)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_75, _toDisplayString(_ctx.$t('required-field')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_76, [
                _createElementVNode("label", _hoisted_77, _toDisplayString(_ctx.$t('limit')), 1),
                _createElementVNode("div", _hoisted_78, [
                  _createVNode(_component_Field, {
                    name: "liabilityInsuranceLimit",
                    modelValue: _ctx.liabilityInsurance.limit,
                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.liabilityInsurance.limit) = $event)),
                    rules: "max_value:2147483647"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.liabilityInsurance.limit !== undefined)
                        ? (_openBlock(), _createBlock(_component_InputAmericanFormat, {
                            key: 0,
                            class: "mt-2",
                            modelValue: _ctx.liabilityInsurance.limit,
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.liabilityInsurance.limit) = $event))
                          }, null, 8, ["modelValue"]))
                        : (_openBlock(), _createBlock(_component_InputAmericanFormat, {
                            key: 1,
                            class: "mt-2",
                            modelValue: _ctx.liabilityInsurance.limit,
                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.liabilityInsurance.limit) = $event))
                          }, null, 8, ["modelValue"]))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                (!_ctx.liabilityInsurance.limit && _ctx.isInvalidInsurance)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_79, _toDisplayString(_ctx.$t('required-field')) + " " + _toDisplayString(_ctx.isInvalidInsurance), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_80, [
                _createElementVNode("label", _hoisted_81, _toDisplayString(_ctx.$t('expiration')), 1),
                _createVNode(_component_Field, {
                  name: "liabilityInsuranceDate",
                  modelValue: _ctx.liabilityInsurance.date,
                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.liabilityInsurance.date) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DatePickerComponent, {
                      modelValue: _ctx.liabilityInsurance.date,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.liabilityInsurance.date) = $event)),
                      customClass: 'w-full'
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (!_ctx.liabilityInsurance.date && _ctx.isInvalidInsurance)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_82, _toDisplayString(_ctx.$t('required-field')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_83, [
                _createVNode(_component_Field, {
                  name: "liabilityInsuranceFile",
                  modelValue: _ctx.liabilityInsurance.file,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.liabilityInsurance.file) = $event)),
                  rules: "size:10000"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("input", {
                      ref: "liabilityInsuranceFile",
                      type: "file",
                      accept: "application/pdf, application/xpdf, image/jpeg, image/png, image/jpg",
                      class: "block cursor-pointer mt-6 file:cursor-pointer px-3 text-sm text-corp file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-corp file:text-white hover:file:bg-slate-700",
                      onChange: _cache[28] || (_cache[28] = ($event: any) => (_ctx.handleChange('liabilityInsuranceFile')))
                    }, null, 544)
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (!_ctx.liabilityInsurance.file && _ctx.isInvalidInsurance)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_84, _toDisplayString(_ctx.$t('required-field')), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_85, [
              _createElementVNode("div", _hoisted_86, [
                _createElementVNode("p", _hoisted_87, _toDisplayString(_ctx.$t('w9')), 1),
                _createElementVNode("div", _hoisted_88, [
                  _createVNode(_component_Field, {
                    name: "W9File",
                    modelValue: _ctx.W9Media.file,
                    "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.W9Media.file) = $event)),
                    rules: "size:10000"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("input", {
                        ref: "W9File",
                        type: "file",
                        accept: "application/pdf, application/xpdf, image/jpeg, image/png, image/jpg",
                        class: "block cursor-pointer mt-6 file:cursor-pointer text-sm text-corp file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-corp file:text-white hover:file:bg-slate-700",
                        onChange: _cache[30] || (_cache[30] = ($event: any) => (_ctx.handleChange('W9')))
                      }, null, 544)
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "W9File",
                    class: "text-rose-500"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_89, [
                _createElementVNode("p", _hoisted_90, _toDisplayString(_ctx.$t('dot-mc')), 1),
                _createElementVNode("div", _hoisted_91, [
                  _createVNode(_component_Field, {
                    name: "DOTMCFile",
                    modelValue: _ctx.DOTMCMedia.file,
                    "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.DOTMCMedia.file) = $event)),
                    rules: "size:10000"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("input", {
                        ref: "DOTMCFile",
                        type: "file",
                        accept: "application/pdf, application/xpdf, image/jpeg, image/png, image/jpg",
                        class: "block cursor-pointer mt-6 file:cursor-pointer text-sm text-corp file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-corp file:text-white hover:file:bg-slate-700",
                        onChange: _cache[32] || (_cache[32] = ($event: any) => (_ctx.handleChange('DOTMC')))
                      }, null, 544)
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "DOTMCFile",
                    class: "text-rose-500"
                  })
                ])
              ])
            ]),
            _createElementVNode("h3", _hoisted_92, _toDisplayString(_ctx.$t('service-profile')), 1),
            (_ctx.isMexico)
              ? (_openBlock(), _createElementBlock("div", _hoisted_93, [
                  _createElementVNode("div", _hoisted_94, [
                    _withDirectives(_createElementVNode("input", {
                      id: "usaDomestic",
                      name: "usaDomestic",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.usaDomestic) = $event)),
                      class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.usaDomestic]
                    ]),
                    _createElementVNode("label", _hoisted_95, _toDisplayString(_ctx.$t('loads-domestic-usa')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_96, [
                    _withDirectives(_createElementVNode("input", {
                      id: "mexDomestic",
                      name: "mexDomestic",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.mexDomestic) = $event)),
                      class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.mexDomestic]
                    ]),
                    _createElementVNode("label", _hoisted_97, _toDisplayString(_ctx.$t('loads-domestic-mex')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_98, [
                    _withDirectives(_createElementVNode("input", {
                      id: "transferLoad",
                      name: "transferLoad",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.transferLoad) = $event)),
                      class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.transferLoad]
                    ]),
                    _createElementVNode("label", _hoisted_99, _toDisplayString(_ctx.$t('transfer-loads')), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("h3", _hoisted_100, _toDisplayString(_ctx.$t('lanes')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lanes, (lane, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "flex flex-wrap -mx-3"
              }, [
                _createElementVNode("div", _hoisted_101, [
                  _withDirectives(_createElementVNode("label", {
                    for: "trailer-type",
                    class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  }, _toDisplayString(_ctx.$t('origin')), 513), [
                    [_vShow, index === 0]
                  ]),
                  _createVNode(_component_Field, {
                    name: 'origin' + index,
                    rules: "required|max:256",
                    modelValue: lane.locationOrigin.city,
                    "onUpdate:modelValue": ($event: any) => ((lane.locationOrigin.city) = $event)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_102, [
                        (_openBlock(), _createBlock(_component_PlanCountrySelector, {
                          key: 'country0' + index,
                          country: _ctx.getCountryIsoByLane(lane, 'ORIGIN'),
                          countrySelectorClasses: 'bg-gray-50 mt-2 rounded rounded-r-none custom-border-left inline-flex border-gray-300 bg-gray-text-gray-900 h-[46px] items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150',
                          dataCountries: _ctx.countriesList,
                          name: 'country0' + index,
                          onCallbackClick: (country) => _ctx.handleClickCountry(country, 'ORIGIN', index)
                        }, null, 8, ["country", "dataCountries", "name", "onCallbackClick"])),
                        (lane.locationOrigin.city !== '')
                          ? (_openBlock(), _createBlock(_component_XCircleIcon, {
                              key: 0,
                              class: "w-4 h-4 text-corp absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2",
                              onClick: ($event: any) => (_ctx.clearInput(lane, 'origin'))
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_google_autocomplete, {
                          placeholder: _ctx.$t('placeholder-enter-your-origin-zip-code'),
                          id: 'origin' + index,
                          name: 'origin' + index,
                          value: lane.locationOrigin.city,
                          error: Object.keys(errors).includes('location0-input' + index),
                          onPlacechanged: ($event: any) => (lane.locationOrigin = _ctx.getCityData($event)),
                          types: '(regions)',
                          country: lane.originCountry?.iso,
                          class: "rounded rounded-l-none h-[46px] bg-gray-50 mt-2"
                        }, null, 8, ["placeholder", "id", "name", "value", "error", "onPlacechanged", "types", "country"])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["name", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ErrorMessage, {
                    name: 'origin' + index,
                    class: "text-rose-500"
                  }, null, 8, ["name"])
                ]),
                _createElementVNode("div", _hoisted_103, [
                  _withDirectives(_createElementVNode("label", {
                    class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
                    for: "destination"
                  }, _toDisplayString(_ctx.$t('destination')), 513), [
                    [_vShow, index === 0]
                  ]),
                  _createVNode(_component_Field, {
                    name: 'destiny' + index,
                    rules: "required|max:256",
                    modelValue: lane.locationDestiny.city,
                    "onUpdate:modelValue": ($event: any) => ((lane.locationDestiny.city) = $event)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_104, [
                        (_openBlock(), _createBlock(_component_PlanCountrySelector, {
                          key: 'country1' + index,
                          country: _ctx.getCountryIsoByLane(lane, 'DESTINATION'),
                          countrySelectorClasses: 'bg-gray-50 mt-2 rounded rounded-r-none custom-border-left inline-flex border-gray-300 bg-gray-text-gray-900 h-[46px] items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150',
                          dataCountries: _ctx.countriesList,
                          name: 'country1' + index,
                          onCallbackClick: (country) => _ctx.handleClickCountry(country, 'DESTINATION', index)
                        }, null, 8, ["country", "dataCountries", "name", "onCallbackClick"])),
                        (lane.locationDestiny.city !== '')
                          ? (_openBlock(), _createBlock(_component_XCircleIcon, {
                              key: 0,
                              class: "w-4 h-4 text-corp absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2",
                              onClick: ($event: any) => (_ctx.clearInput(lane, 'destination'))
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_google_autocomplete, {
                          placeholder: _ctx.$t('placeholder-enter-your-destination-zip-code'),
                          id: 'destiny' + index,
                          name: 'destiny' + index,
                          value: lane.locationDestiny.city,
                          error: Object.keys(errors).includes('location1-input' + index),
                          onPlacechanged: ($event: any) => (lane.locationDestiny = _ctx.getCityData($event)),
                          types: '(regions)',
                          class: "rounded rounded-l-none h-[46px] bg-gray-50 mt-2",
                          country: lane.destinationCountry?.iso
                        }, null, 8, ["placeholder", "id", "name", "value", "error", "onPlacechanged", "types", "country"])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["name", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ErrorMessage, {
                    name: 'destiny' + index,
                    class: "text-rose-500"
                  }, null, 8, ["name"])
                ]),
                _createElementVNode("div", _hoisted_105, [
                  _withDirectives(_createElementVNode("label", {
                    class: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-4",
                    for: "radio"
                  }, _toDisplayString(_ctx.$t('radio')), 513), [
                    [_vShow, index === 0]
                  ]),
                  _createVNode(_component_Field, {
                    rules: "required|min_value:1|integer|max_value:2147483647",
                    modelValue: lane.radiusDistance,
                    "onUpdate:modelValue": ($event: any) => ((lane.radiusDistance) = $event),
                    id: 'radiusDistance' + index,
                    name: 'radiusDistance' + index
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InputAmericanFormat, {
                        class: "flex",
                        "class-custom": "!rounded-lg",
                        modelValue: lane.radiusDistance,
                        "onUpdate:modelValue": ($event: any) => ((lane.radiusDistance) = $event),
                        symbol: _ctx.currentCountryDistanceUnit,
                        hidden: true,
                        placeholder: '0',
                        style: {"height":"42px !important"}
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "symbol"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "id", "name"]),
                  _createVNode(_component_ErrorMessage, {
                    class: "text-sm text-rose-500",
                    name: 'radiusDistance' + index
                  }, null, 8, ["name"])
                ]),
                _withDirectives(_createElementVNode("p", {
                  class: "p-1 h-fit bg-red-500 rounded cursor-pointer mx-auto",
                  onClick: ($event: any) => (_ctx.lanes.splice(index, 1))
                }, [
                  _createVNode(_component_TrashIcon, { class: "h-6 w-6 text-white" })
                ], 8, _hoisted_106), [
                  [_vShow, index > 0]
                ])
              ]))
            }), 128)),
            _createElementVNode("p", {
              class: "mx-3 border-b w-max border-corp mb-6 cursor-pointer",
              onClick: _cache[37] || (_cache[37] = 
//@ts-ignore
(...args) => (_ctx.onClickAddOther && _ctx.onClickAddOther(...args)))
            }, _toDisplayString(`+ ${_ctx.$t('add-other')}`), 1),
            (_ctx.selectedOption === 'CARRIER')
              ? (_openBlock(), _createElementBlock("main", _hoisted_107, [
                  _createElementVNode("h3", _hoisted_108, _toDisplayString(_ctx.$t('permits')), 1),
                  _createElementVNode("div", _hoisted_109, [
                    _createElementVNode("div", _hoisted_110, [
                      (!_ctx.isEuropeCompany())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_111, [
                            _withDirectives(_createElementVNode("input", {
                              id: "alcoholic-beverages",
                              name: "alcoholicBeverages",
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.hasTransportAndDeliveryOfAlcoholicBeveragesPermit) = $event)),
                              class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.hasTransportAndDeliveryOfAlcoholicBeveragesPermit]
                            ]),
                            _createElementVNode("label", _hoisted_112, _toDisplayString(_ctx.$t('alcoholic-beverages')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ErrorMessage, {
                        name: "alcoholicBeverages",
                        class: "text-rose-500"
                      }),
                      _createElementVNode("div", _hoisted_113, [
                        _withDirectives(_createElementVNode("input", {
                          id: "hazardous-materials",
                          name: "hazardous-materials",
                          type: "checkbox",
                          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.hasHazardousMaterialPermit) = $event)),
                          class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.hasHazardousMaterialPermit]
                        ]),
                        _createElementVNode("label", _hoisted_114, _toDisplayString(_ctx.$t('hazardous-materials')), 1)
                      ]),
                      _createVNode(_component_ErrorMessage, {
                        name: "hazardous-materials",
                        class: "text-rose-500"
                      }),
                      (_ctx.isEuropeCompany())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_115, [
                            _withDirectives(_createElementVNode("input", {
                              id: "tir",
                              name: "tir",
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.hasTirPermit) = $event)),
                              class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.hasTirPermit]
                            ]),
                            _createElementVNode("label", _hoisted_116, _toDisplayString(_ctx.$t('tir')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ErrorMessage, {
                        name: "tir",
                        class: "text-rose-500"
                      }),
                      (!_ctx.isEuropeCompany())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_117, [
                            _withDirectives(_createElementVNode("input", {
                              id: "TWIC-card",
                              name: "TWICcard",
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.hasTWICCard) = $event)),
                              class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.hasTWICCard]
                            ]),
                            _createElementVNode("label", _hoisted_118, _toDisplayString(_ctx.$t('TWIC-card')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ErrorMessage, {
                        name: "TWICcard",
                        class: "text-rose-500"
                      }),
                      (!_ctx.isEuropeCompany())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_119, [
                            _withDirectives(_createElementVNode("input", {
                              id: "TSA-card",
                              name: "TSAcard",
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.hasTSACard) = $event)),
                              class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.hasTSACard]
                            ]),
                            _createElementVNode("label", _hoisted_120, _toDisplayString(_ctx.$t('tsa-card')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ErrorMessage, {
                        name: "TSAcard",
                        class: "text-rose-500"
                      }),
                      _createElementVNode("div", _hoisted_121, [
                        _createElementVNode("input", {
                          id: "hasMoveFullTruckPermit",
                          name: "hasMoveFullTruckPermit",
                          type: "checkbox",
                          checked: _ctx.hasMoveFullTruckPermit,
                          onClick: _cache[43] || (_cache[43] = ($event: any) => (_ctx.hasMoveFullTruckPermit = !_ctx.hasMoveFullTruckPermit)),
                          class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        }, null, 8, _hoisted_122),
                        _createElementVNode("label", _hoisted_123, _toDisplayString(_ctx.$t('move-full-track-permit')), 1)
                      ]),
                      _createVNode(_component_ErrorMessage, {
                        name: "hasMoveFullTruckPermit",
                        class: "text-rose-500"
                      }),
                      _createElementVNode("div", _hoisted_124, [
                        _createElementVNode("input", {
                          id: "hasOversizeLoadPermit",
                          name: "hasOversizeLoadPermit",
                          type: "checkbox",
                          checked: _ctx.hasOversizeLoadPermit,
                          onClick: _cache[44] || (_cache[44] = ($event: any) => (_ctx.hasOversizeLoadPermit = !_ctx.hasOversizeLoadPermit)),
                          class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        }, null, 8, _hoisted_125),
                        _createElementVNode("label", _hoisted_126, _toDisplayString(_ctx.$t('has-oversize')), 1)
                      ]),
                      _createVNode(_component_ErrorMessage, {
                        name: "hasOversizeLoadPermit",
                        class: "text-rose-500"
                      }),
                      _createElementVNode("div", _hoisted_127, [
                        _createElementVNode("input", {
                          id: "hasMovePartialPermit",
                          name: "hasMovePartialPermit",
                          type: "checkbox",
                          checked: _ctx.hasMovePartialPermit,
                          onClick: _cache[45] || (_cache[45] = ($event: any) => (_ctx.hasMovePartialPermit = !_ctx.hasMovePartialPermit)),
                          class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        }, null, 8, _hoisted_128),
                        _createElementVNode("label", _hoisted_129, _toDisplayString(_ctx.$t('move-partial-permit')), 1)
                      ]),
                      _createVNode(_component_ErrorMessage, {
                        name: "hasMovePartialPermit",
                        class: "text-rose-500"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_130, [
                      (!_ctx.isEuropeCompany())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_131, [
                            _withDirectives(_createElementVNode("input", {
                              id: "overweight",
                              name: "overweight",
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.hasOverweightLoadPermit) = $event)),
                              class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.hasOverweightLoadPermit]
                            ]),
                            _createElementVNode("label", _hoisted_132, _toDisplayString(_ctx.$t('overweight')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ErrorMessage, {
                        name: "overweight",
                        class: "text-rose-500"
                      }),
                      _createElementVNode("div", _hoisted_133, [
                        _withDirectives(_createElementVNode("input", {
                          id: "drivers-team",
                          name: "driversTeam",
                          type: "checkbox",
                          "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.hasTeamDriverPermit) = $event)),
                          class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.hasTeamDriverPermit]
                        ]),
                        _createElementVNode("label", _hoisted_134, _toDisplayString(_ctx.$t('drivers-team')), 1)
                      ]),
                      _createVNode(_component_ErrorMessage, {
                        name: "driversTeam",
                        class: "text-rose-500"
                      }),
                      (!_ctx.isEuropeCompany())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_135, [
                            _withDirectives(_createElementVNode("input", {
                              id: "bond-permit",
                              name: "bondPermit",
                              type: "checkbox",
                              "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.hasBondPermit) = $event)),
                              class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.hasBondPermit]
                            ]),
                            _createElementVNode("label", _hoisted_136, _toDisplayString(_ctx.$t('bond-permit')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ErrorMessage, {
                        name: "bondPermit",
                        class: "text-rose-500"
                      })
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 512)
      ]),
      _createElementVNode("footer", _hoisted_137, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[49] || (_cache[49] = ($event: any) => (_ctx.validateForm())),
          class: "inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
        }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.isSending ? _ctx.$t('send-action') : _ctx.$t('save')), 1)
        ])
      ])
    ])
  ], 2))
}