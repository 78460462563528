import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-md" }
const _hoisted_2 = { class: "flex justify-start items-center" }
const _hoisted_3 = { class: "flex justify-end items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_update_multiple_lanes_additional_stop_info = _resolveComponent("plan-update-multiple-lanes-additional-stop-info")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.$t('additional-stops'),
    size: "sm-auto",
    "hide-close-button": ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.additionalStops, (stop, index) => {
          return (_openBlock(), _createBlock(_component_plan_update_multiple_lanes_additional_stop_info, {
            key: stop,
            onUpdateLocation: _ctx.updateStop,
            onDelete: _ctx.deleteStop,
            position: index + 1,
            "location-object": stop
          }, null, 8, ["onUpdateLocation", "onDelete", "position", "location-object"]))
        }), 128)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_plan_button, {
            onOnClick: _ctx.addStop,
            type: "primary-outline",
            label: _ctx.$t('add-stop'),
            "show-icon": "",
            icon: "plus",
            "icon-color": "#0071BF",
            "full-rounded": ""
          }, null, 8, ["onOnClick", "label"])
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          type: "primary",
          label: _ctx.$t('continue')
        }, null, 8, ["onOnClick", "label"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}