import { initialState } from '@/modules/demo/_store/index';

export default {
  clearAllDemoData(state: any) {
    state.dataDemoRequest = [];
    state.dataDemoRequestForm = null;
    state.dataDemoRequestTotals = [];
    state.demoRequestsTotals = 0;
  },
  clearDemoRequestDataForm(state: any) {
    state.dataDemoRequestForm = null;
  },
  clearDemoStore(state: any) {
    Object.assign(state, initialState);
  },
  pushDemoRequestData(state: any, payload: any) {
    if (Array.isArray(payload)) {
      state.dataDemoRequest.push(...payload);
    } else {
      state.dataDemoRequest.push(payload);
    }
  },
  setDemoRequestData(state: any, payload: object) {
    state.dataDemoRequest = payload;
  },
  setDemoRequestDataForm(state: any, payload: object) {
    state.dataDemoRequestForm = payload;
  },
  setDemoRequestDataTotals(state: any, payload: any) {
    state.dataDemoRequestTotals = payload;
  },
  setDemoRequestsTotals(state: any, payload: number) {
    state.demoRequestsTotals = payload;
  },
  setIsWelcomeOpen(state: any, payload: any) {
    state.isWelcomeOpen = payload;
  },
  updateDemoRequest(state: any, payload: any) {
    const { id } = payload;
    state.dataDemoRequest = state.dataDemoRequest.map((item) =>
      item.id === id ? { ...payload } : { ...item },
    );
  },
  updateDemoRequestStatus(state: any, payload: any) {
    const { id, newStatus } = payload;
    state.dataDemoRequest = state.dataDemoRequest.map((item) =>
      item.id === id ? { ...item, status: newStatus } : { ...item },
    );
  },
};
