import { initialState } from '@/services/chats/_store/index';

export default {
  clearChatsStore(state: any) {
    Object.assign(state, initialState);
  },
  pushUnreadMessages(state: any, payload: any) {
    state.unreadMessages.push(...payload);
  },
  pushCurrentMessages(state: any, payload: any) {
    state.currentMessages.push(...payload);
  },
  pushConversations(state: any, payload: any) {
    state.conversations.push(...payload);
  },
  pushNewMessage(state: any, payload: any) {
    state.currentMessages.push(payload);
  },
  setChatId(state: any, payload: any) {
    state.chatId = payload;
  },
  setUnreadMessages(state: any, payload: any) {
    state.unreadMessages = payload;
  },
  setTotalUnreadChats(state: any, payload: any) {
    state.totalUnreadChats = payload;
  },
  setCurrentMessages(state: any, payload: any) {
    state.currentMessages = payload;
  },
  setConversation(state: any, payload: any) {
    state.currentConversation = payload;
  },
  setConversations(state: any, payload: any) {
    state.conversations = payload;
  },
  setUserForChat(state: any, payload: any) {
    state.userForChat = payload;
  },
  setLaneForChat(state: any, payload: any) {
    state.laneForLoad = payload;
  },
  setRankingForChat(state: any, payload: any) {
    state.rankingForChat = payload;
  },
};
