
import { computed, defineComponent, reactive, toRefs } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
import BaseModal from '@/components/modals/BaseModal.vue';

import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import { useStore } from 'vuex';
import PlanAdditionalFees from '@/components/defaults/PlanAdditionalFees.vue';
import shipmentData from '@/modules/planning/loadDetail/_components/ShipmentData.vue';
import store from '@/services/store';
import { useI18n } from 'vue-i18n';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

interface reportAdditionalFeeModal {
  comments: string;
  feeExplanationSelectedItem: number | null;
  feeValue: number | null;
  files: FileList | null;
  loadingPostCreateAdditionalFee: boolean;
  showModal: boolean;
  test: string;
}
export default defineComponent({
  name: 'ReportAdditionalFeeModal',
  mixins: [BaseComponent],
  computed: {
    shipmentData() {
      return shipmentData;
    },
  },
  components: { PlanButtonIcon, PlanAdditionalFees, PlanInputNumber, PlanButton, BaseModal },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const data = reactive<reportAdditionalFeeModal>({
      comments: '',
      feeExplanationSelectedItem: null,
      feeValue: null,
      files: null,
      loadingPostCreateAdditionalFee: false,
      showModal: false,
      test: '',
    });

    const feeExplanationItems = computed(() => {
      let result: Array<any> = [];
      store.getters['LoadsStore/getFeeExplanations'].forEach(function (feeExplanation: any) {
        result.push({
          label: feeExplanation.name,
          value: feeExplanation.id,
        });
      });

      return result;
    });

    const shipmentId = computed(() => {
      return store.getters['ShipmentStore/shipment'].id;
    });

    const changeModalState = () => {
      if (!data.showModal) {
        data.feeValue = null;
        data.feeExplanationSelectedItem = null;
      }
      if (!data.loadingPostCreateAdditionalFee) data.showModal = !data.showModal;
    };

    const getAdditionalFeeDocument = () => {
      let uploadAdditionalFeeDocumentInput = document.getElementById(
        'upload-additional-fee-document',
      );
      if (uploadAdditionalFeeDocumentInput) uploadAdditionalFeeDocumentInput.click();
    };

    const getFeeTranslation = (feeType) => {
      let label = feeType.replace(/[-\s]/g, '').toLowerCase();
      return t(`additional-fees-types.${label}`);
    };

    const handleFileChange = (event) => {
      data.files = event.target.files;
    };

    const postCreateAdditionalFee = async () => {
      if (data.feeValue === null || data.feeExplanationSelectedItem === null) {
        store.dispatch('UserStore/updateToast', {
          text: t('warning-post-create-additional-fee'),
          type: 'error',
          visible: true,
        });
        return;
      }

      try {
        data.loadingPostCreateAdditionalFee = true;
        const result = await store.dispatch('LoadsStore/postCreateAdditionalFee', {
          shipment: store.getters['ShipmentStore/shipment'].id,
          feeExplanation: { id: data.feeExplanationSelectedItem },
          description: data.comments,
          value: data.feeValue,
          status: 'STATUS_PENDING',
          type: 'TYPE_SHIPPER',
        });

        if (result && result.id && data.files !== null) {
          await postCreateAdditionalFeeMedia(result.id);
        }

        store.dispatch('UserStore/updateToast', {
          text: t('success-post-create-additional-fee'),
          type: 'success',
          visible: true,
        });
      } catch (error) {
        store.dispatch('UserStore/updateToast', {
          text: t('error-post-create-additional-fee'),
          type: 'error',
          visible: true,
        });
      } finally {
        data.loadingPostCreateAdditionalFee = false;
        data.showModal = false;
        data.feeValue = null;
        data.feeExplanationSelectedItem = null;
        data.comments = '';
        data.files = null;
      }
    };

    const postCreateAdditionalFeeMedia = async (additionalFeeId: number) => {
      try {
        if (data.files) {
          for (const file of data.files) {
            const formData = new FormData();
            formData.append('entityId', additionalFeeId.toString());
            formData.append('file', file);
            await store.dispatch('LoadsStore/postCreateAdditionalFeeMedia', formData);
          }
        }
      } catch (error) {
        store.dispatch('UserStore/updateToast', {
          text: t('error-post-create-additional-fee'),
          type: 'error',
          visible: true,
        });
      }
    };

    const removeFile = (index: number): void => {
      if (data.files instanceof FileList) {
        const filesArray: File[] = Array.from(data.files);
        if (index >= 0 && index < filesArray.length) {
          filesArray.splice(index, 1);
          const newFileList = new DataTransfer();
          filesArray.forEach((file) => newFileList.items.add(file));
          data.files = newFileList.files;
        }
      }
    };

    store.dispatch('LoadsStore/getFeeExplanations');

    return {
      ...toRefs(data),
      feeExplanationItems,
      shipmentId,
      changeModalState,
      getAdditionalFeeDocument,
      getFeeTranslation,
      handleFileChange,
      postCreateAdditionalFee,
      removeFile,
    };
  },
});
