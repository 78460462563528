import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_warehouse_form = _resolveComponent("warehouse-form")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.$t('update-warehouse'),
    size: "sm-auto",
    width: "467px",
    "without-sidebar": _ctx.withoutSidebar,
    "hide-close-button": ""
  }, {
    content: _withCtx(() => [
      _createVNode(_component_warehouse_form, {
        "is-update": "",
        onUpdate: _ctx.updateData
      }, null, 8, ["onUpdate"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          disabled: _ctx.loading,
          type: "primary-transparent",
          label: _ctx.$t('cancel')
        }, null, 8, ["onOnClick", "disabled", "label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.putWarehouse,
          loading: _ctx.loading,
          disabled: !_ctx.isFormComplete || _ctx.loading,
          type: "primary",
          label: _ctx.$t('update')
        }, null, 8, ["onOnClick", "loading", "disabled", "label"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title", "without-sidebar"]))
}