
import {defineComponent} from 'vue'
import PlanIcon from '@/components/icons/PlanIcon.vue'

export default defineComponent({
  name: "PlanLoading",
  components: {
    PlanIcon
  },
  props: {
    color: {type: String, required: false, default: 'transparent'},
    message: { type: String, required: false, default: '' },
    heavyLoading: {type: Boolean, required: false, default: false},
    iconColor: {type: String, required: false, default: '#8A8B97'},
    small: { type: Boolean, required: false, default: false }
  }
})
